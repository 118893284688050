import React, { Component } from 'reactn';
import './App.css';
import { Switch, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store, { loginUser } from './v2/core/store/.';
import { login } from './util/APIUtils';
import { ACCESS_TOKEN, setUserContext, setLocations, setLocation, POLL_CONFIG } from './constants';
import AuthContainer from './components/AuthContainer';
import { CustomChart } from './components/chart';
import ReactGA from 'react-ga4';
import { AuthProvider } from './v2/core/auth/providers';
import { Suspense } from 'react';
import { Loader } from '@orijinworks/frontend-commons';
import { renderRoutes } from 'react-router-config';
import { getPublicRoutes } from './routes';
import { isPublicRoute } from './v2/services/utilty';
import { establishSocketConnection } from './v2/socket';

class App extends Component {
  //Constructor turns props into states
  chartjs = new CustomChart();
  hitPollApi = true;
  constructor(props) {
    sessionStorage.setItem('isLogoutInitiated', 'true');
    super(props);
    this.state = {
      isLoggedin: false,
      context: 'admin',
      clientIP: '',
      internalIp: '',
      currentUser: null,
      isAuthenticated: false,
      isLoading: false,
      userContext: null,
      locationLoading: false,
      locations: [],
      error: false,
      receivedLocations: false,
      errorMessage: '',
    };

    // this.chartjs.setRoundedCornerForBars();

    //This binding is needed otherwise methods won't work within this constructor context
    this.handleLogout = this.handleLogout.bind(this);
  }

  /**
   * @name getDateRange
   * @param {number} noOfDays
   * @desc Prepares date range from past noOfDays till today.
   * @return {object}
   */
  getDateRange = (noOfDays) => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - noOfDays);
    return {
      startDate: startDate.toISOString(),
      endDate: new Date().toISOString(),
    };
  };

  initReactGA = () => {
    ReactGA.initialize(process.env.REACT_APP_GA);
    ReactGA.send('pageview');
  };

  componentDidMount() {
    this.initReactGA();
    this.setGlobal({
      permissionLoadingStack: 0,
      timeOnTaskView: 'facility',
      totSelectedFacility: null,
      openFacilityView: false,
      totSelectedUnit: null,
      openUnitView: false,
      selectedDateRange: 7,
      range: this.getDateRange(7),
      superLocationStore: [],
      locationDataStore: [],
      openProfileView: false,
      selectedUser: null,
      userData: [],
      myuser: null,
      isLoading: false,
      isDrawerOpen: true,
      messageAnnouncementCountObject: {},
      isAttorney: false,
      isTeacher: false,
      isSuperAdmin: false,
      role: null,
      permissions: [],
      permissionIds: [],
      cbacConfigFlag: false,
      staffProfileID: null,
      staffSelectedLocation: null,
      isStaffProfileOpen: false,
      showMessageTile: false,
      showLocationSettingsTile: false,
      jurisdictionWithChildren: [],
      isMessagingOnly: false,
    });
    setLocation({});
    setLocations([]);

    if (!POLL_CONFIG.USE_POLLING && !isPublicRoute(getPublicRoutes())) {
      establishSocketConnection();
    }
  }

  componentWillUnmount() {
    if (this.messagingIntervalRef) {
      clearInterval(this.messagingIntervalRef);
    }
  }

  ///Logs user out and redirects them back to home
  handleLogout(redirectTo = '/', notificationType = 'success', description = "You're successfully logged out.") {
    localStorage.removeItem(ACCESS_TOKEN);

    this.setState({
      currentUser: null,
      isAuthenticated: false,
    });

    this.props.history.push(redirectTo);
  }

  ///This is for the fake login
  handleLoggedin = (value) => {
    this.setState({ isLoggedin: value });
    const loggedinUser = { name: 'apdstest', id: 18278, clientIP: this.state.clientIP };
    store.dispatch(loginUser(loggedinUser));
  };

  ///This is the real login
  login = (requestObj) => {
    this.setState({
      isLoading: true,
    });
    login(requestObj).then((response) => {
      if (response.error) {
        this.setState({
          error: true,
          errorMessage: response.error_description,
          isLoading: false,
        });
        return;
      } else {
        let context = JSON.parse(response.apdsUserContext);
        setUserContext(context);
        localStorage.setItem(ACCESS_TOKEN, response.access_token);
        this.setState({
          isLoading: false,
          isAuthenticated: true,
          userContext: context,
          userRoles: context.userClaims,
        });
        this.fetchLocations();
      }
    });
  };

  ///Generic function to pass in context. Remove or extend once we can retreive user metadata
  handleContext = (value) => {
    this.setState({ context: value });
  };

  render() {
    return (
      <AuthProvider>
        <Provider store={store}>
          <div className="App">
            {!isPublicRoute(getPublicRoutes()) && <AuthContainer />}
            <Suspense fallback={<Loader isLoading={true} />}>
              <Switch>{renderRoutes(getPublicRoutes())}</Switch>
            </Suspense>
          </div>
        </Provider>
      </AuthProvider>
    );
  }
}

export default withRouter(App);
