
export default (theme) => ({
  main: {
    padding: '0px 0px 32px 0px',
  },
  breakCrumb :{
    color:theme.palette.primary.blue.main,
    fontWeight:700,
    fontSize:16
  }
});
