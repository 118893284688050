import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import Card from '@material-ui/core/Card'
import { Grid, Typography, Box } from '@material-ui/core';
import { ASSIGNMENT_TYPE } from '../constants';

const styles = theme => ({

  mainDialog: {
    height: '160px !important',
    width: 360,
    maxWidth: "100%",
    padding: 8,
  },
  subHeaderChildContainer: {
    alignItems: 'end',
    position: 'relative'
  },
  dialogTitle: {
    ...theme.typography.t16,
    height: 31,
    fontWeight: 501,
    fontStretch: 'normal;',
    fontStyle: 'normal;',
    lineHeight: 'normal;',
    letterSpacing: '0.07px;',

    marginTop: 8,
    marginLeft: 8
  },
  dialogcontent: {
    marginTop: 8,
    marginLeft: 8,
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 14
  },
  buttonStyle: {
    position: 'absolute',
    right: 23
  }
});



class DeleteUserConfirmationModal extends React.Component {



  /**
   * @name getString
   * @param {string} type Type of string required
   * @desc Returns string with respect to props.
   * @return {string}
   */
  getString = (type) => {
    if (!this.props.type) {
      return null; 
    }
    const _strings = {
      [ASSIGNMENT_TYPE.ASSIGNE]: {
        actionDesc: "Are you sure you want to do assignment?"
      },
      [ASSIGNMENT_TYPE.UNASSIGNE]: {
        actionDesc: "Are you sure you want to do unassignment?"
      },
      [ASSIGNMENT_TYPE.DELETE]: {
        actionDesc: "Are you sure you want to delete path?"
      }
    };

    return _strings[this.props.type][type];
  };

  render() {

    const { classes } = this.props;
    return (
      <Card style={{ cursor: 'pointer', width: '100%' }} elevation={7}>
        <Dialog
          open={this.props.open}
          onClose={() => { this.props.onDelete(false) }}>
          <Grid
            data-cy-name="redeem-dialog"
            container
            item
            className={classes.mainDialog}>

            <Grid
              container
              item
              direction="column"

            >
              <Typography id="heading" className={classes.dialogTitle}>
                Delete User?
                        </Typography>
              <Typography variant="p" className={classes.dialogcontent}>
                {this.getString('actionDesc')}
                        </Typography>
            </Grid>

            <Grid
              container
              item
              className={classes.subHeaderChildContainer}
              alignItems="end">
              <Box className={classes.buttonStyle}>
                <Button
                  color="primary"
                  data-cy-name="view-all-btn"
                  id="view-all-btn"
                  onClick={() => { this.props.onDelete(false) }}
                >
                  Cancel
                            </Button>
                <Button
                  color="primary"
                  data-cy-name="view-all-btn"
                  id="view-all-btn"
                  onClick={() => { this.props.onDelete(true) }}
                >
                  Ok
                            </Button>
              </Box>

            </Grid>
          </Grid>
        </Dialog>

      </Card>
    );
  }
}

DeleteUserConfirmationModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeleteUserConfirmationModal);