export default (theme) => ({
    dateSection: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 30
    },

    line: {
        height: 1.5,
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        flex: 1,
    },

    date: {
        fontSize: 12,
        letterSpacing: 0.4,
        lineHeight: 1.56,
        color: '#737982',
        margin: '0 10px'
    }
})