import { createMuiTheme } from '@material-ui/core';
import { CustomTheme } from '@orijinworks/frontend-commons';

export const RELATIVE_POSITIONS = {
  HEADER_HEIGHT: '80px',
  MESSAGE_MODULE_PADDING: 28,
  LOCATION_SELECTOR: 120
};

const fonts = {
  DM_SANA: 'DM Sans',
  OSWALD: 'Oswald',
  MONTSERRAT: 'Montserrat',
  INTER: 'Inter',
  RUBIK: 'Rubik'
};

const fontWeight = {
  BOLD: 700,
  MEDIUM: 600,
  REGULAR: 400,
  LIGHT: 300
};

const textTransform = {
  TITLE_CASE: 'capitalize',
  ALL_CAPS: 'uppercase'
};

const OVERRIDDEN_RADIUS = {
  root: {
    borderRadius: '24px !important'
  }
};

/**
 * Deprecated. Use v3StyleGuideTheme instead
 **/
export const v2StyleGuideTheme = {
  body: {
    background: '#0F445A'
  },
  palette: {
    primary: {
      main: '#0F445A',
      black: '#08090A',
      white: '#F7F8FA',
      blue: {
        main: '#154157',
        light: '#A7C4D3',
        dark: '#0B212C',
        background: '#0F48B8'
      },
      teal: {
        main: '#0A8180',
        light: '#CBDDDF',
        dark: '#066360'
      },
      sylonBlue: '#ECF1F4',
      pastel: '#E5F0F6'
    },
    secondary: {
      main: '#0A8180',
      white: 'white',
      purple: {
        main: '#250341',
        light: '#DFCFED',
        dark: '#723D9D'
      },
      coral: {
        main: '#E26451',
        light: '#F0D0C8',
        dark: '#BF4636'
      },
      yellow: {
        main: '#FFB22D',
        light: '#FFEDD9',
        dark: '#D1850B'
      },
      brightBlue: {
        main: '#1C79BE'
      }
    },
    custom: {
      light: '#ffa726',
      main: '#f57c00',
      dark: '#ef6c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    greyScale: {
      darkSilver: '#707070',
      platinum: '#DFE6E9',
      morningBlue: '#8E9EA0',
      weldonBlue: '#7896A2',
      greyBackground: '#F2F4F5'
    },
    button: {
      green: {
        default: '#0A8180',
        hover: '#066360',
        active: '#0A8180',
        disable: '#CBDDDF'
      },
      blue: {
        default: '#154157',
        hover: '#0B212C',
        active: '#154157',
        disable: '#A7C4D3'
      },
      grey: {
        main: '#E5ECEC',
        hover: '#CEDCDF',
        active: '#ECF0F0',
        disable: '#E5ECEC'
      },
      secondary: {
        main: '#CBDDDF00',
        hover: '#CBDDDF',
        active: '#CEDCDF',
        disable: '#E5ECEC34'
      },
      tertiary: {
        main: '#ECF0F0',
        dark: '#0F445A',
        light: '#0A8180'
      }
    }
  },
  fontFamily: {
    DM_SANA: fonts.DM_SANA,
    OSWALD: fonts.OSWALD,
    MONTSERRAT: fonts.MONTSERRAT
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    fontFamily: fonts.DM_SANA,

    h1: {
      fontSize: 48,
      fontWeight: fontWeight.BOLD,
      lineHeight: '60px',
      textTransform: textTransform.TITLE_CASE
    },
    h2: {
      fontSize: 34,
      fontWeight: fontWeight.BOLD,
      // lineHeight: '50px',
      textTransform: textTransform.TITLE_CASE
    },
    h3: {
      fontSize: 34,
      fontWeight: fontWeight.BOLD,
      // lineHeight: '42px',
      textTransform: textTransform.TITLE_CASE
    },
    h4: {
      fontSize: 24,
      fontWeight: fontWeight.BOLD,
      textTransform: textTransform.TITLE_CASE
    },
    h5: {
      fontSize: 24,
      fontWeight: fontWeight.MEDIUM,
      // lineHeight: '28px',
      textTransform: textTransform.ALL_CAPS
    },
    h6: {
      fontSize: 18,
      fontWeight: 500,
      textTransform: textTransform.TITLE_CASE
    },
    p1: {
      fontSize: 18,
      fontWeight: fontWeight.REGULAR
    },
    p2: {
      fontSize: 16,
      fontWeight: fontWeight.REGULAR
    },
    p3: {
      fontSize: 14,
      fontWeight: fontWeight.REGULAR
    },
    bold: {
      fontWeight: fontWeight.BOLD
    },
    medium: {
      fontWeight: fontWeight.MEDIUM
    },
    regular: {
      fontWeight: fontWeight.REGULAR
    },

    /// OLD
    t18: {
      fontSize: 18
    }
  }
};
/**
 * Origin theme 12/2023
 **/
export const v3StyleGuideTheme = {
  body: {
    background: '#061E44'
  },
  palette: {
    primary: {
      main: '#061E44',
      black: '#2E3032',
      white: '#F8F9FC',
      blue: {
        main: CustomTheme.palette.blue.main,
        light: '#CDD5ED',
        dark: '#0F2C54',
        medium: 'rgb(0,150,202)',
        background: CustomTheme.palette.blue.main,
        greyed: CustomTheme.palette.blue.greyed
      },
      teal: {
        main: '#624AC5',
        light: '#EAF2F4',
        dark: '#2A1E4F'
      },
      navy: {
        main: CustomTheme.palette.navy.main
      },
      sylonBlue: '#CDD5ED',
      pastel: '#F6FDFF'
    },
    secondary: {
      main: '#0096CA',
      white: '#F8F9FC',
      purple: {
        main: '#624AC5',
        light: '#AD9CE2',
        dark: '#2A1E4F'
      },
      coral: {
        main: '#FF824D',
        light: '#FFBEA3',
        dark: '#B1562F'
      },
      yellow: {
        main: '#FFC800',
        light: '#FFEA9D',
        dark: '#CEAB2E'
      },
      brightBlue: {
        main: '#0096CA'
      },
    },
    custom: {
      light: '#ffa726',
      main: '#f57c00',
      dark: '#ef6c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    greyScale: {
      darkSilver: '#77808D',
      platinum: '#CDD5ED',
      morningBlue: '#77808D',
      weldonBlue: '#A9B3CF',
      greyBackground: '#CDD4E3'
    },
    button: {
      green: {
        default: '#624AC5',
        hover: '#2A1E4F',
        active: '#624AC5',
        disable: '#EAF2F4'
      },
      blue: {
        default: '#0F48B8',
        hover: '#0F2C54',
        active: '#0F48B8',
        disable: '#CDD5ED'
      },
      grey: {
        main: '#E5ECEC',
        hover: '#CEDCDF',
        active: '#ECF0F0',
        disable: '#E5ECEC'
      },
      secondary: {
        default: '#CDD5ED',
        main: '#CBDDDF00',
        hover: '#A9B3CF',
        active: '#CDD5ED',
        disable: '#DBE0E8'
      },
      tertiary: {
        default: '#DBE0E8',
        main: '#ECF0F0',
        hover: '#061E44',
        dark: '#0F445A',
        light: '#0A8180',
        active: '#0096CA'
      }
    }
  },
  fontFamily: {
    DM_SANA: fonts.DM_SANA,
    OSWALD: fonts.OSWALD,
    MONTSERRAT: fonts.MONTSERRAT
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    fontFamily: fonts.INTER,

    h1: {
      fontSize: '42px',
      fontWeight: 800,
      lineHeight: '46px',
      textTransform: textTransform.TITLE_CASE
    },
    h2: {
      fontSize: '36px',
      fontWeight: 800,
      lineHeight: '44px',
      textTransform: textTransform.TITLE_CASE
    },
    h3: {
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: '40px',
      textTransform: textTransform.TITLE_CASE
    },
    h4: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '34px',
      textTransform: textTransform.TITLE_CASE
    },
    h5: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '28px',
      textTransform: textTransform.TITLE_CASE
    },
    h6: {
      fontSize: '22px',
      fontWeight: 500,
      textTransform: textTransform.TITLE_CASE
    },
    p1: {
      fontSize: 16,
      fontWeight: fontWeight.REGULAR
    },
    p2: {
      fontSize: 14,
      fontWeight: fontWeight.REGULAR
    },
    p3: {
      fontSize: 12,
      fontWeight: fontWeight.REGULAR
    },
    bold: {
      fontWeight: fontWeight.BOLD
    },
    medium: {
      fontWeight: fontWeight.MEDIUM
    },
    regular: {
      fontWeight: fontWeight.REGULAR
    },

    /// OLD
    t18: {
      fontSize: 18
    }
  },
  text: {
    green: CustomTheme.palette.green.dark,
    navy: CustomTheme.palette.navy.main,
    orange: CustomTheme.palette.orange.dark,
    red: CustomTheme.palette.red.dark
  }
};

export const theme = createMuiTheme({
  body: {
    background: 'blue'
  },

  palette: {
    colors: {
      grey: '#7896A2',
      borderGrey: {
        light: '#EFF0F4',
        dark: '#A5B4BB'
      },
      textGrey: '#8F9AA0',
      inputBackground: '#F3F6F9',
      paleBlue: '#EDF7FC',
      babyBlue: '#E4EFF5',
      lightGray: '#F6F8FA',
      slateGray: '#757F8B',
      lightBlueGrey: '#E2E9EF',
      grey100: 'var(--grey-100)',
      grey90: 'var(--grey-90)',
      grey80: 'var(--grey-80)',
      grey60: 'var(--grey-60)',
      grey50: 'var(--grey-50)',
      grey20: 'var(--grey-20)',
      grey110: 'var(--grey-110)',
      purpleMain: CustomTheme.palette.purple.main,
      ceruleanMain: CustomTheme.palette.cerulean.main,
      redMain: CustomTheme.palette.red.main,
      redDark: CustomTheme.palette.red.dark,
      greenMain: CustomTheme.palette.green.main,
      greenDeep: CustomTheme.palette.green.deep,
      greenPale: CustomTheme.palette.green.pale,
      greenDark: CustomTheme.palette.green.dark,
      red:{
        deep : CustomTheme.palette.red.deep,
      },
      // accentTeal:CustomTheme.palette['accent-teal'].dark,
      aquaDeep:'#007E87',
      aquaLight:'#0078A2',
      yellow: {
        pale: CustomTheme.palette.yellow.pale,
        dark: CustomTheme.palette.yellow.dark
      },
      blue: {
        pale: CustomTheme.palette.primary.pale,
      }, 
    },
    primary: {
      pastelBlue: '#a1c1ff',
      pastelBlueBg: 'rgba(65, 113, 210, 0.55)',
      battleshipGrey: '#737982',
      lipstick: '#c92563',
      scarlet: '#b00020',
      textGrey: '#d2d2d2',
      black: v3StyleGuideTheme.palette.primary.black,
      sylonBlue: v3StyleGuideTheme.palette.primary.sylonBlue,
      pastel: v3StyleGuideTheme.palette.primary.pastel,
      deep:CustomTheme.palette.primary.dark,
      grey: '#d8d8d8',
      main: v3StyleGuideTheme.palette.primary.main,
      sub: '#5FBFF9',
      darkBlue: '#021843',
      jetBlack: '#000000',
      green: '#66bb6a',
      cobalt: CustomTheme.palette.primary.main, //'#061E44',
      cobalt2: '#062561',
      white: v3StyleGuideTheme.palette.primary.white,
      success: '#0f5100',
      danger: '#840000',
      lightBlue: '#4171d2',
      lightGrey: '#9f9f9f',
      mainGradient:
        'linear-gradient(to top right,' + CustomTheme.palette.navy.main + ' 50%, ' + CustomTheme.palette.navy.main + ')',
      inverseGradient:
        'linear-gradient(to bottom left,' + CustomTheme.palette.navy.main + ' 50%, ' + CustomTheme.palette.navy.main + ')',
      textColor: 'white',
      background: '#f5f5f5',
      paleGrey: '#f0f3f6',
      calypso: '#3c6a91',
      biscay: '#173f62',
      grayDark: '#919191',
      hippieBlue: '#5686b0',
      analyticBg: '#b48fd2',
      alto: '#e0e0e0',
      blue: v3StyleGuideTheme.palette.primary.blue,
      teal: v3StyleGuideTheme.palette.primary.teal,
      mediumBlue: v3StyleGuideTheme.palette.primary.blue.medium,
      paleBlue: 'var(--blue-pale)',
      mainBlue: 'var(--blue-main)',
      skyBlue: '#87CEEB',
      navy: CustomTheme.palette.navy.main,
      cerulean: CustomTheme.palette.cerulean.main
    },
    contentAreas: {
      work: {
        borderColor: '#FACAC2',
        color: '#F14B32',
        backgroundColor: '#FDF4F2'
      },
      education: {
        borderColor: '#B9CAD9',
        color: '#396993',
        backgroundColor: '#E5F1FF'
      },
      rehab: {
        borderColor: '#B2DACF',
        color: '#EBF7F3',
        backgroundColor: '#017E5B'
      },
      reEntry: {
        borderColor: '#8743DF',
        color: '#D4BDF3',
        backgroundColor: '#F4EEFC'
      },
      enrichment: {
        borderColor: '#D2D4D7',
        color: '#696E77',
        backgroundColor: '#F4F5F6'
      },
      entertainment: {
        borderColor: '#F2C9D9',
        color: '#CB2061',
        backgroundColor: '#FCF3F6'
      }
    },
    secondary: {
      main: CustomTheme.palette.navy.main,
      sub: '#16BAC5',
      dark: CustomTheme.palette.navy.main,
      textColor: 'black',
      background: 'white',
      purple: v3StyleGuideTheme.palette.secondary.purple,
      mainPurple: 'var(--purple-main)',
      coral: v3StyleGuideTheme.palette.secondary.coral,
      yellow: v3StyleGuideTheme.palette.secondary.yellow,
      brightBlue: v3StyleGuideTheme.palette.secondary.brightBlue,
      red: {
        main: CustomTheme.palette.red.main,
        pale: CustomTheme.palette.red.pale,
      }
    },
    text: {
      primary: CustomTheme.palette.navy.main,
      secondary: '#98A6A8',
      sub: '#7E8995',
      green: v3StyleGuideTheme.text.green,
      navy: v3StyleGuideTheme.text.navy,
      mainNavy: 'var(--navy-main)',
      orange: v3StyleGuideTheme.text.orange,
      red: v3StyleGuideTheme.text.red
    },
    background: {
      blue: v3StyleGuideTheme.palette.primary.blue.background
    },
    custom: v3StyleGuideTheme.palette.custom,
    greyScale: v3StyleGuideTheme.palette.greyScale,
    button: {
      green: v3StyleGuideTheme.palette.button.green,
      blue: v3StyleGuideTheme.palette.button.blue,
      grey: v3StyleGuideTheme.palette.button.grey,
      secondary: v3StyleGuideTheme.palette.button.secondary,
      tertiary: v3StyleGuideTheme.palette.button.tertiary
    }
  },
  status: {
    danger: '#d32f2f',
    success: '#388e3c',
    warning: '#f57c00'
  },
  textWhite: {
    main: '#FFF'
  },
  MuiListItemText: {
    primary: 'white'
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    fontFamily: 'Roboto',

    h1: {
      fontSize: 44,
      fontWeight: 500,
      lineHeight: '0.64px'
    },
    h2: {
      fontSize: 36,
      fontWeight: 500
    },
    h3: {
      fontSize: 24,
      color: '#2d2d2d',
      fontWeight: 500
    },
    h4: {
      fontSize: 18,
      fontWeight: 500
    },
    h5: {
      fontSize: 16,
      fontWeight: 500
    },
    bolder: {
      fontWeight: 900
    },
    bold: {
      fontWeight: 700
    },
    light: {
      fontWeight: 300
    },
    medium: {
      fontWeight: 500
    },
    regular: {
      fontWeight: 400
    },
    t44: {
      fontSize: 44
    },
    t47: {
      fontSize: 47
    },
    t35: {
      fontSize: 35
    },
    t36: {
      fontSize: 36
    },
    t30: {
      fontSize: 30
    },
    t20: {
      fontSize: 20
    },
    t22: {
      fontSize: 22
    },
    t25: {
      fontSize: 25
    },
    t48: {
      fontSize: 48
    },
    t14: {
      fontSize: 14
    },
    t15: {
      fontSize: 15
    },
    t16: {
      fontSize: 16
    },
    t34: {
      fontSize: 34
    },
    t24: {
      fontSize: 24
    },
    t18: {
      fontSize: 18
    },
    t12: {
      fontSize: 12
    },
    t10: {
      fontSize: 10
    },
    t9: {
      fontSize: 9
    },
    t7: {
      fontSize: 7
    },
    ...v3StyleGuideTheme.typography
  },
  fontFamily: v3StyleGuideTheme.fontFamily,
  overrides: {
    MuiButton: {
      ...OVERRIDDEN_RADIUS,
      containedPrimary: {
        backgroundColor: CustomTheme.palette.primary.main
      }
    },
    MuiSwitch: {
      colorPrimary: {
        '&$checked + $track': {
          color: CustomTheme.palette.purple.main
        }
      }
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: `${CustomTheme.palette.purple.main} important`
        }
      }
    },
    MuiBadge: {
      backgroundColor: `${CustomTheme.palette.red.main} !important`
    },
    MuiInputBase: OVERRIDDEN_RADIUS,
    MuiDialog: {
      paper: {
        ...OVERRIDDEN_RADIUS.root
      }
    }
  }
});
