import React from "react";
import { withStyles } from "@material-ui/core/styles";


const styles = theme => ({
    logo: {
        position: "relative",
        width: 50,
        height: 50,
        border: "3px solid white"
      },
      logoText: {
        color: "#fff",
        fontWeight: 600,
        position: "absolute",
        left: -5,
        backgroundColor: "#0047ab",
        top: 13,
        fontSize: 24
      },
      logo: {
        margin: "0 auto",
        maxWidth: "100%",
        display: "block"
      }
});

const Logo = props => {
  const { classes } = props;

  return (
    <img
      src="./assets/logo/apds-logo.png"
      className={[classes.logo, props.className].join(" ")}
      alt="apds-logo.png"
    />
  );
};

export default withStyles(styles)(Logo);
