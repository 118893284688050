export default theme => ({
  tableWrapper: {
    maxHeight: 319,
    overflowY: 'auto'
  },
  locationsTable: {
    width: '100%',
    textAlign: 'left',
    borderCollapse: 'collapse',
    '& th': {
      position: 'sticky',
      top: 0,
      paddingTop: 10,
      paddingBottom: 19,
      backgroundColor: '#fff',
      color: '#747474',
      fontWeight: 'normal',
      fontSize: 12,
      '&:first-child': {
        paddingLeft: 27,
      },
      '&:last-child': {
        paddingRight: 27,
      }
    },
    '& td': {
      fontSize: 14,
      paddingTop: 19,
      paddingBottom: 19,
      '&:first-child': {
        paddingLeft: 27,
      },
      '&:last-child': {
        paddingRight: 27,
      }
    },
    '& tr': {
      borderBottom: '0.5px solid #979797',
      '&:last-of-type': {
        borderBottom: 'none'
      }
    }
  },
  wideColumn: {
    width: '30%'
  },
  narrowColumn: {
    width: '20%'
  },
  clickable: {
    cursor: 'pointer'
  },
});