import React from "react";

const ReentryIcon = props => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-Re-entey">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Re-entey" clipPath="url(#clip-Re-entey)">
        <g id="Group_1272" data-name="Group 1272" transform="translate(-1299.837 -524.178)">
          <path id="Fill_1" data-name="Fill 1" d="M1.051,16.814A1.089,1.089,0,0,1,0,15.694V10.088A1.089,1.089,0,0,1,1.051,8.968,1.089,1.089,0,0,1,0,7.847V1.121A1.089,1.089,0,0,1,1.051,0,1.089,1.089,0,0,1,2.1,1.121V7.847A1.089,1.089,0,0,1,1.051,8.968,1.089,1.089,0,0,1,2.1,10.088v5.605A1.089,1.089,0,0,1,1.051,16.814Z" transform="translate(1318.228 530.793)" fill={fill}/>
          <path id="Fill_2" data-name="Fill 2" d="M7.665.335a1.143,1.143,0,0,1,0,1.616L5.38,4.237a1.143,1.143,0,0,1-.447.276L1.5,5.656A1.143,1.143,0,1,1,.782,3.487L3.954,2.43,6.049.335A1.143,1.143,0,0,1,7.665.335Z" transform="translate(1312.408 530.464)" fill={fill}/>
          <path id="Fill_3" data-name="Fill 3" d="M0,1.143A1.143,1.143,0,0,1,1.143,0H5.714A1.143,1.143,0,0,1,6.857,1.143V3.429A1.143,1.143,0,0,1,5.714,4.571H1.143A1.143,1.143,0,0,1,0,3.429Z" transform="translate(1316.98 537.321)" fill={fill}/>
          <path id="Fill_4" data-name="Fill 4" d="M.955.016A1.143,1.143,0,0,1,2.27.955L3.413,7.812a1.143,1.143,0,0,1-2.255.376L.016,1.331A1.143,1.143,0,0,1,.955.016Z" transform="translate(1318.122 530.464)" fill={fill}/>
          <path id="Fill_5" data-name="Fill 5" d="M1.143,0A1.143,1.143,0,0,0,0,1.143V16a1.143,1.143,0,1,0,2.286,0V1.143A1.143,1.143,0,0,0,1.143,0Z" transform="translate(1302.123 530.464)" fill={fill}/>
          <path id="Fill_6" data-name="Fill 6" d="M3.277.01a1.143,1.143,0,0,0-.933.771L.059,7.639a1.143,1.143,0,0,0,2.168.723l1.547-4.64.99,1.484a1.143,1.143,0,0,0,.59.45L8.782,6.8A1.143,1.143,0,1,0,9.5,4.63L6.448,3.611,4.38.509A1.143,1.143,0,0,0,3.277.01Z" transform="translate(1299.837 530.464)" fill={fill}/>
          <path id="Fill_7" data-name="Fill 7" d="M4.571,2.286A2.286,2.286,0,1,0,2.286,4.571,2.286,2.286,0,0,0,4.571,2.286Z" transform="translate(1300.98 524.75)" fill={fill}/>
          <path id="Fill_8" data-name="Fill 8" d="M4.571,2.286A2.286,2.286,0,1,0,2.286,4.571,2.286,2.286,0,0,0,4.571,2.286Z" transform="translate(1316.98 524.75)" fill={fill}/>
        </g>
      </g>
    </svg>
  );
};

ReentryIcon.defaultProps = {
  size: 24,
  fill: "inherit"
}

export default ReentryIcon;
