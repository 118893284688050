export default (theme) => ({
  success: {
    color: theme.status.success,
    fontWeight: '700'
  },
  danger: {
    color: theme.status.danger,
    fontWeight: '700'
  },
  warning: {
    color: theme.status.warning,
    fontWeight: '700'
  },
});
