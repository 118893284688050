export default (theme) => ({
    courseList: {
        listStyleType: 'none',
        marginTop: 0,
        paddingLeft: '4px',

        '& li': {
            display: 'flex'
        }
    },
    label: {
        display: 'inline-block',
        fontSize: '12px',
        lineHeight: '2em',
        marginRight: '12px',
        width: '72px'
    },
});