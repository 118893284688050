import { useEffect, useState } from 'react';
import { MY_PROFILE_QUESTION_SECTIONS, SUCCESS } from '../../../services/constants';
import { getLearnerQuestionaire } from '../../../../util/APIUtils';

export const useInsights = props => {
  const [demographic, setDemographic] = useState([]);
  const [personality, setPersonality] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fullName = props.user && `${props.user.firstName || ''} ${props.user.lastName || ''}`;
  const { user } = props;
  
  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      setIsLoading(true);
      const response = await getLearnerQuestionaire(user.userId);
      if (response.status === SUCCESS) {
        const { payload } = response;
        setData(payload);
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  const setData = payload => {
    if (payload && payload.questions && payload.questions.length > 0) {
      const questionWithAnswers = setAnswers(payload.questions, payload.answers);
      const { demographic, personality } = getDemographicAndPersonalityData(questionWithAnswers);
      setDemographic(demographic);
      setPersonality(personality);
    }
  };

  const getDemographicAndPersonalityData = questionWithAnswers => {
    return {
      demographic: questionWithAnswers.filter(question => question.section === MY_PROFILE_QUESTION_SECTIONS.DEMOGRAPHIC),
      personality: questionWithAnswers.filter(question => question.section === MY_PROFILE_QUESTION_SECTIONS.PERSONALITY)
    };
  };

  const setAnswers = (questions, answers) => {
    return questions.map(question => {
      if (answers.length === 0) {
        delete question.answer;
      } else {
        setAnswerInQuestion(question, answers);
      }
      return question;
    });
  };

  const setAnswerInQuestion = (question, answers) => {
    const answer = answers.find(answer => answer.questionId === question.id);
    if (answer && answer.answerOptionIds) {
      question.answer = question.maxSelection > 1 ? answer.answerOptionIds : answer.answerOptionIds[0];
    }
  };

  const getAnswerText = (answer, options) => {
    const answerValues = getAnswerValues(answer, options);
    if (answerValues) {
      return answerValues;
    }
    return getSelectedOptions(answer, options);
  };

  const getAnswersLength = answer => {
    return Array.isArray(answer) ? answer.length : false;
  };

  const getAnswerValues = (answer, options) => {
    if (getAnswersLength(answer) > 0) {
      return getSelectedValues(answer, options);
    } else if (getAnswersLength(answer) === 0) {
      return 'N/A';
    }
  };
  const getSelectedValues = (answer, options) => {
    const selectedValues = answer.map(value => options.find(item => item.id === value).displayValue).join(', ');
    return selectedValues;
  };

  const getSelectedOptions = (answer, options) => {
    const selectedOption = options.find(option => option.id === answer);
    return selectedOption ? selectedOption.displayValue : 'N/A';
  };
  return {
    demographic,
    personality,
    getAnswerText,
    fullName,
    isLoading
  };
};
