import React, { useGlobal } from 'reactn';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import styles from './manage-balance.style';
import { withStyles } from '@material-ui/styles';
import { Grid, Box, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MANAGE_BALANCE_TYPE, ROLES } from '../../constants';
import { PermissionControl } from '../permission-control/permission-control'
import Autocomplete from '@material-ui/lab/Autocomplete';

const ManageBalance = (props) => {
    const [isSubmitted, setSubmitting] = React.useState(false);
    const [ global, setGlobal ] = useGlobal();
    const { classes } = props;
    return (


        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            classes={{
                paper: classes.paper
            }}
            scroll="paper"
        >
            <Formik
                initialValues={props.formData || [
                    {
                        amount: 0,
                        reason: "",
                        user: {
                            userName: ""
                        }
                    }
                ]}
                validate={(values) => {
                    const errors = {};
                    const emptyItemIndex = values.findIndex(
                        item => (item.amount <= 0 || !item.amount) || !item.reason
                    );
                    if (emptyItemIndex > -1) {
                        errors["items"] = [];
                        errors["items"][emptyItemIndex] = {}
                        if (parseInt(values[emptyItemIndex].amount) <= 0 || !values[emptyItemIndex].amount) {
                            errors["items"][emptyItemIndex]["amount"] = "Please enter amount greater than 0."
                        }
                        if (!values[emptyItemIndex].reason) {
                            errors["items"][emptyItemIndex]["reason"] = "Please enter reason."
                        }
                    }
                    return errors;
                }}
                onSubmit={(values) => props.onSubmit(values)}
            >
                {({ values, errors, touched, isSubmitting, handleBlur, handleSubmit, setFieldValue, setTouched }) => (

                    <form
                        id="main-form"
                        onSubmit={handleSubmit}
                    >

                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignitems="center"
                        >
                            <Box>
                                <DialogTitle>
                                    {props.title}
                                </DialogTitle>
                            </Box>
                            {props.toggleAction && (global.cbacConfigFlag || global.isSuperAdmin) && (
                                <Box display="flex">
                                    <Button
                                        onClick={() => props.toggleAction()}
                                        color="primary">
                                        {props.type === MANAGE_BALANCE_TYPE.ADD && "Swtich To Deduct Balance"}
                                        {props.type === MANAGE_BALANCE_TYPE.DEDUCT && "Swtich To Add Balance"}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        <PermissionControl
                            permissionName={props.type === MANAGE_BALANCE_TYPE.ADD ? "Grant_Tokens" : "Remove_Tokens"}
                            fallbackComponent={
                                <React.Fragment>
                                    <DialogContent dividers={true}>
                                        <DialogContentText>
                                            <div style={{ height: 275, display: 'flex', alignItems: 'center' }}>
                                                <p style={{ margin: '0 auto' }}>
                                                    {props.type === MANAGE_BALANCE_TYPE.ADD ? "You do not have permission for grant tokens to learners" : "You do not have permission for deduct tokens from learners"}
                                                </p>
                                            </div>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={props.onClose} color="primary">
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </React.Fragment>
                            }
                        >
                            <DialogContent dividers={true}>
                                <DialogContentText
                                >


                                    {values
                                        .map(

                                            (fieldGroup, index) => (
                                                <Accordion
                                                    defaultExpanded={index === 0}
                                                >

                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                    >
                                                        <Typography >
                                                            {fieldGroup.user.userName}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container item>
                                                            <Box width="100%" marginBottom="20px">
                                                                <TextField
                                                                    error={errors.items && errors.items[index] && errors.items[index].amount && (touched.amount || isSubmitted)}
                                                                    helperText={errors.items && errors.items[index] && errors.items[index].amount && (touched.amount || isSubmitted) ? errors.items[index].amount : null}
                                                                    className={classes.textField}
                                                                    label={props.amountLabel}
                                                                    name="amount"
                                                                    onChange={
                                                                        (event) => {
                                                                            values[index].amount = event.target.value;
                                                                            setFieldValue(values)
                                                                        }
                                                                    }
                                                                    onBlur={handleBlur}
                                                                    inputProps={{
                                                                        type: "number"
                                                                    }}
                                                                    value={values[index].amount}
                                                                    variant="outlined" />
                                                            </Box>
                                                            <Box width="100%">
                                                                <TextField
                                                                    error={errors.items && errors.items[index] && errors.items[index].reason && (touched.reason || isSubmitted)}
                                                                    helperText={errors.items && errors.items[index] && errors.items[index].reason && (touched.reason || isSubmitted) ? errors.items[index].reason : null}
                                                                    className={classes.textField}
                                                                    onChange={
                                                                        (event) => {
                                                                            values[index].reason = event.target.value;
                                                                            setFieldValue(values)
                                                                        }
                                                                    }
                                                                    onBlur={handleBlur}
                                                                    label={props.reasonLabel}
                                                                    name="reason"
                                                                    multiline
                                                                    rows={4}
                                                                    value={values[index].reason}
                                                                    defaultValue={values[index].reason}
                                                                    variant="outlined"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>

                                            )

                                        )}

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                id="cancel-btn"
                                onClick={props.onClose} color="primary">
                                    Cancel
                                </Button>
                                <Button
                                    id="submit-btn"
                                    onClick={() => setSubmitting(true)}
                                    type="submit" color="primary">
                                    Submit
                                </Button>
                            </DialogActions>
                        </PermissionControl>
                    </form>
                )}
            </Formik>
        </Dialog>

    );
};


export default withStyles(styles)(ManageBalance);