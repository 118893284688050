import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { UnleashService } from '../../../services';
import { Auth0Provider } from '@auth0/auth0-react';
import KeycloakProvider from './keycloak/keycloak-provider';
import { setUseOktaAuth0 } from '../utils';

const AuthProvider = ({ children }) => {
  const { flagsReady } = useFlagsStatus();
  const useOktaAuth0 = useFlag(UnleashService.FLAGS.USE_OKTA_AUTH0);

  useEffect(() => {
    setUseOktaAuth0(useOktaAuth0);
  }, [useOktaAuth0]);

  if (!flagsReady) {
    return null;
  }

  if (useOktaAuth0) {
    return (
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        useRefreshTokens
        authorizationParams={{
          redirect_uri: `${window.location.origin}/#`,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
      >
        {children}
      </Auth0Provider>
    );
  } else {
    return <KeycloakProvider>{children}</KeycloakProvider>;
  }
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
