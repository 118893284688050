import { Box, Typography, LinearProgress, withStyles } from '@material-ui/core';
import React from 'react';

import styles from './milestone-mapper.style';
import { PDQ_IN_PROGRESS_COPY, PDQ_NOT_STARTED_COPY, PDQ_STATUSES } from '../../../services/constants';
import { useMilestoneMapperHook } from './useMilestoneMapperHook';

const MilestoneMapper = (props) => {
  const { classes } = props;

  const { status, pdqScores } = useMilestoneMapperHook(props);

  return (
    <>
    <Typography className={classes.headerText} color='primary'>
      Milestone Mapper Results
    </Typography>
    <Box className={classes.scoresContainer}>
    {
        status === PDQ_STATUSES.NOT_STARTED ? 
          <Typography id="not-started-text">
            {PDQ_NOT_STARTED_COPY}
          </Typography>
      : 
        (status === PDQ_STATUSES.IN_PROGRESS || status === PDQ_STATUSES.SUBMITTED) ? 
          <Typography id="in-progress-text">
            {PDQ_IN_PROGRESS_COPY}
          </Typography>
      :
      <>
        {/* P SCORE PROGRESS BAR */}
        <Box className={classes.scoreProgressArea}>
          <Box className={classes.progressLabelsContainer}>
            <Typography color="secondary" className={classes.progressLabel}>
              Prose Literacy
            </Typography>
            <Typography color="primary" className={classes.progressLabel}>
              Level {pdqScores ? pdqScores.proseScore : 1}
            </Typography>
          </Box>
          <LinearProgress
            id="pdq-assessment-p-score-bar"
            variant="determinate"
            value={pdqScores ? Math.floor((pdqScores.proseScore / pdqScores.proseMaxScore) * 100) : 33}
            color="primary"
            classes={{
              root: classes.progressBarRoot,
            }}
          />
        </Box>

        <Box className={classes.scoreProgressArea}>
          <Box className={classes.progressLabelsContainer}>
            <Typography color="secondary" className={classes.progressLabel}>
              Document Literacy
            </Typography>
            <Typography color="primary" className={classes.progressLabel}>
              Level {pdqScores ? pdqScores.documentScore : 1}
            </Typography>
          </Box>
          <LinearProgress
            id="pdq-assessment-d-score-bar"
            variant="determinate"
            value={pdqScores ? Math.floor((pdqScores.documentScore / pdqScores.documentMaxScore) * 100) : 33}
            color="primary"
            classes={{
              root: classes.progressBarRoot,
            }}
          />
        </Box>

        <Box className={classes.scoreProgressArea}>
          <Box className={classes.progressLabelsContainer}>
            <Typography color="secondary" className={classes.progressLabel}>
              Quantitative Literacy
            </Typography>
            <Typography color="primary" className={classes.progressLabel}>
              Level {pdqScores ? pdqScores.quantitativeScore : 1}
            </Typography>
          </Box>
          <LinearProgress
            id="pdq-assessment-q-score-bar"
            variant="determinate"
            value={
              pdqScores
                ? Math.floor((pdqScores.quantitativeScore / pdqScores.quantitativeMaxScore) * 100)
                : 33
            }
            color="primary"
            classes={{
              root: classes.progressBarRoot,
            }}
          />
        </Box>
      </>
      }    
    </Box>
    </>

  );
};

export default withStyles(styles)(MilestoneMapper);
