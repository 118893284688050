import React from 'reactn';
import { useState, useEffect } from 'react';
import { ContactsListComponent } from '../../components';
import { LiveCallsList } from '../../components';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import { PendingContactsListComponent } from '../../components';
import CallHistoryList from '../../components/call-history/call-history';
import VoiceCallSettings from '../../components/voice-call-settings/voice-call-settings';
import LearnerManagementComponent from '../../components/learner-management/learner-management.component';
import { VoiceCallService } from '../../services/voice-call.service';
import { useDispatch } from 'react-redux';
import { setVoiceBaseKeywords } from '../../../../v2/core/store';

export const useContactsListContainerHook = (locationId) => {
  const [activeTab, setActiveTab] = useState(VOICE_CALL.TABS.PENDING_CONTACTS);
  const [isDataRefresh, setDataRefresh] = React.useState(false);
  const [shouldExportCallHistory, setShouldExportCallHistory] = React.useState(false);
  const dispatch = useDispatch();
  const voiceCallService = new VoiceCallService();

  useEffect(() => {
    const activeTabFromLocalStorage = localStorage.getItem('activeTab');
    if (activeTabFromLocalStorage && activeTabFromLocalStorage === 'LEARNER_PROFILE') {
      setActiveTab(VOICE_CALL.TABS.RESIDENT_MANAGEMENT);
      localStorage.removeItem('activeTab');
      localStorage.removeItem('selectedLearner');
    }
    loadBaseVoiceKeywordsJson();
  }, []);

  const renderTable = () => {
    switch (activeTab) {
      case VOICE_CALL.TABS.LIVE_CALLS:
        return <LiveCallsList isDataRefresh={isDataRefresh} setDataRefresh={setDataRefresh} locationId={locationId} />;
      case VOICE_CALL.TABS.CALL_HISTORY:
        return (
          <CallHistoryList
            isDataRefresh={isDataRefresh}
            setDataRefresh={setDataRefresh}
            shouldExportCallHistory={shouldExportCallHistory}
            setShouldExportCallHistory={setShouldExportCallHistory}
            locationId={locationId}
          />
        );
      case VOICE_CALL.TABS.ALL_CONTACTS:
        return (
          <ContactsListComponent
            activeTab={activeTab}
            locationId={locationId}
            isDataRefresh={isDataRefresh}
            setDataRefresh={setDataRefresh}
          />
        );
      case VOICE_CALL.TABS.RESIDENT_MANAGEMENT:
        return (
          <LearnerManagementComponent
            activeTab={activeTab}
            locationId={locationId}
            isDataRefresh={isDataRefresh}
            setDataRefresh={setDataRefresh}
            shouldExportCallHistory={shouldExportCallHistory}
            setShouldExportCallHistory={setShouldExportCallHistory}
          />
        );
      case VOICE_CALL.TABS.SETTINGS:
        return <VoiceCallSettings locationId={locationId} />;
      default:
        return (
          <PendingContactsListComponent
            activeTab={activeTab}
            locationId={locationId}
            isDataRefresh={isDataRefresh}
            setDataRefresh={setDataRefresh}
          />
        );
    }
  };

  const loadBaseVoiceKeywordsJson = async () => {
    try {
      const voiceBaseKeywords = await voiceCallService.getBaseVoiceKeywordsJson();
      dispatch(setVoiceBaseKeywords(voiceBaseKeywords));
    } catch (error) {
      console.error('Failed to fetch voice base keywords:', error);
    }
  }
  return {
    activeTab,
    setActiveTab,
    renderTable,
    setDataRefresh,
    setShouldExportCallHistory,
  };
};
