import React from 'reactn';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  DialogContent,
  Box,
  TextField,
  InputAdornment
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './index.styles';
import { withStyles } from '@material-ui/styles';
import MessagesContainer from '../../../../../v2/modules/messaging/message/messages-container';
import { MESSAGE_CONTAINER_FETCH_MSG_ENUM } from '../../../../../constants';
import { DIRECT_MESSAGE, LEARNER_ROLE } from '../../../../../v2/services/constants';
import { rsSetActiveThread } from '../../../../../v2/core/store/reducers/messageModuleReducer';
import { connect } from 'react-redux';

export class MessageView extends React.Component {
  getContactDetails = () => {
    const { data } = this.props;
    if (data) {
      const { senderRoleName } = data;
      if (senderRoleName === LEARNER_ROLE) {
        return {
          contactId: data.senderPersonId,
          contactName: data.senderPersonName,
          threadId: data.threadId
        };
      } else {
        return {
          contactId: data.recipientPersonId,
          contactName: data.recipientPersonName,
          threadId: data.threadId
        };
      }
    }
  };
  /**
   * @name getActiveLocation
   * @type GET
   * @return {string}
   */
  get getActiveLocation() {
    if (!this.props.location) {
      return null;
    }
    return this.props.location.locationName;
  }

  componentDidUpdate(prevProps) {
    if (this.props.rsActiveThread !== DIRECT_MESSAGE) {
      this.props.setTabActive(DIRECT_MESSAGE);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog fullScreen open={this.props.isOpen} onClose={this.props.onClose}>
        <Box display="flex" flexDirection="column" flex={1}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton id="close-btn" edge="start" color="inherit" onClick={this.props.onClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography id="title" variant="h6">
                {this.props.title}
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid container item className={classes.dialogContent}>
            <Grid container item md={12} className={classes.messageViewContainer}>
              <Box overflow="auto" width="100%">
                <Box id="info-header" className={[classes.infoHeader, classes.messageViewHead].join(' ')}>
                  {this.props.data && this.props.data.senderDocId && `DOCID# ${this.props.data.senderDocId}|`}
                  {this.getActiveLocation}
                </Box>
                <MessagesContainer
                  parentComponent={MESSAGE_CONTAINER_FETCH_MSG_ENUM.COMMUNICATION}
                  height="calc(100vh - 100px)"
                  activeThread={null}
                  timezoneName={this.props.timezoneName}
                  selectedContact={this.getContactDetails()}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setTabActive: tabName => dispatch(rsSetActiveThread({ rsActiveThread: tabName }))
});

const mapStateToProps = state => ({
  ...state.rsMessageModuleStore
});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MessageView));
