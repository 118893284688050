export default (theme) => ({
  main: {
    marginRight: 20,
    maxWidth: '100%',
    textAlign: 'left',
    position: 'relative',
    zIndex: 99,
  },
  contentMain: {
    backgroundColor: 'white',
  },
  loaderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    position: 'absolute',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: 'white',
  },
  heading: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    ...theme.typography.bold,
    color: theme.palette.text.primary,
    backgroundColor: theme.textWhite.main,
    textTransform: 'capitalize',
    margin: '-8px 0px 0px 25px',
    padding: '0px 6px 0px 6px',
    position: 'absolute',
  },
  backdrop: {
    backgroundColor: '#0000005c',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 98,
  },
  selectedLocation: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid var(--grey-70)',
    padding: '5px 16px',
    justifyContent: 'space-between',
    borderRadius: 24,
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'var(--grey-110)',
      color: 'var(--grey-90)',
      boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',
    },
  },
  unsetBackgroundColor: {
    backgroundColor: 'unset',
  },
  activeSelectedLocation: {
    border: `2px solid ${theme.palette.colors.ceruleanMain}`,
    boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',
    background: theme.textWhite.main,
  },
  locationIcon: {
    fill: theme.palette.colors.ceruleanMain,
    height: 14,
    width: 14,
    marginRight: 10,
    marginTop: 2,
  },
  colorGrey110: {
    color: theme.palette.colors.grey110,
  },
  colorGrey90: {
    color: theme.palette.colors.grey90,
  },
  mainText: {
    ...theme.typography.p2,
  },
  section: {
    padding: '20px 24px',
    borderTop: `1px solid ${theme.palette.colors.grey60}`,
  },
  borderTopNone: {
    borderTop: 'none',
  },
  searchWrapper: {
    position: 'relative',
  },
  searchIcon: {
    position: 'absolute',
    top: 8,
    left: 15,
    fontSize: 30,
    zIndex: 9999,
    color: theme.palette.colors.ceruleanMain,
  },
  searchInput: {
    width: '100%',
    height: 47,
    border: '1px solid #DFE6E9',
    fontSize: 16,
    borderRadius: 3,
    boxSizing: 'border-box',
    '& input': {
      paddingLeft: '45px !important',
    },
  },
  expandableBox: {
    boxShadow: '0px 3px 10px #00000029',
    borderRadius: 24,
    position: 'absolute',
    width: '100%',
    backgroundColor: 'white',
    zIndex: 99,
  },
  locationName: {
    ...theme.typography.p2,
    color: theme.palette.secondary.main,
    ...theme.typography.bold,
    display: 'flex',
    cursor: 'pointer',
    padding: '2px 4px 2px 4px',
    '&:hover': {
      backgroundColor: theme.palette.colors.paleBlue,
      borderRadius: 24,
    },
  },
  jurisdiction: {
    paddingLeft: 10,
    '& .sub-section': {
      paddingLeft: 14,
      '& p': {
        color: theme.palette.colors.grey110,
        fontWeight: 400,
        padding: '6px 12px 6px 12px',
      },
      '& p:hover': {
        color: theme.palette.primary.blue.main,
        backgroundColor: theme.palette.colors.paleBlue,
        borderRadius: 24,
      },
    },
  },
  accordionOpen: {
    transform: 'rotate(180deg) !important',
  },
  collapseIcon: {
    transition: 'all 0.2s',
    transform: 'initial',
    fill: theme.palette.secondary.main,
  },
  disabledIcon :{
    fill: theme.palette.colors.grey110,
    cursor: 'not-allowed'
  },
  overflowBox: {
    maxHeight: '50vh',
    overflow: 'auto',
    padding: '6px 24px',
  },
  collapseAllWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  collapseAllButton: {
    backgroundColor: theme.palette.primary.teal.main,
    ...theme.typography.p2,
    ...theme.typography.bold,
    height: 50,
    padding: '12px 16px',
    textTransform: 'none',
    fontSize: 16,
    color: theme.palette.primary.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.teal.main,
    },
  },

  contactNameStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  unreadIcon: {
    height: 14,
    color: theme.palette.colors.redMain,
  },
});
