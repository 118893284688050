import {
    SHOW_TOAST,
    HIDE_TOAST,
    SESSION_MODAL,
    SET_LOCATION,
    SET_HOUSING_UNIT,
    RELOAD_SERVICE_CODES,
    RESET_RELOAD_SERVICE_CODES,
    SET_CURRENT_USER,
    CHANGE_TOAST_TYPE,
    SET_VOICE_BASE_KEYWORDS
} from '../actions/type'

const initialState = {
    toast: '',
    session: { isOpen: false },
    location: null,
    reloadServiceCodes: false,
    userDetail: {},
    toastType: "error",
    voiceBaseKeywords : []
}

export default function(state=initialState, action){
    switch(action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                userDetail: action.currentUser
            };
        case SHOW_TOAST:
            return {
                ...state, 
                toast: action.payload,
            };
        case HIDE_TOAST:
            return {
                ...state, 
                toast: '',
            };
        case SESSION_MODAL: 
            return {
                ...state,
                session: action.payload,
            }
        case SET_LOCATION: 
            return {
                ...state,
                selectedLocation: action.payload,
            }
        case SET_HOUSING_UNIT: 
            return {
                ...state,
                selectedHousingUnit: action.payload,
            }
        case RELOAD_SERVICE_CODES: 
            return {
                ...state,
                reloadServiceCodes: true,
            }
        case RESET_RELOAD_SERVICE_CODES: 
            return {
                ...state,
                reloadServiceCodes: false,
            }

        case CHANGE_TOAST_TYPE:
            return {
                ...state, 
                toastType: action.payload,
            };
        
        case SET_VOICE_BASE_KEYWORDS:
            return {
                ...state,
                voiceBaseKeywords: action.payload
            }    
        default:
            return state
    }
}