export default (theme) => ({
    folderWrapper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F8F9FC',
        '& .MuiBox-root': {
            '& .MuiPaper-root': {
                padding: 0,
            }
        },
        '& > div > div > div > .Mui-selected': {
            '& svg': {
                fill: 'var(--yellow-main)',
            }
        }
    },

});