import React, { useGlobal } from 'reactn';
import { Link, withRouter } from "react-router-dom";
import { hasRole } from "../../services/utilty";
import { MainMenuTile } from '../../shared/components/MainMenuCard';
import {  Zoom, Grid } from '../../shared/components/MaterialUIComponents';
import { ROLES } from '../../../constants';
  
const RenderTile = ({cyprusId, name, url, description, img, classes, history}) => {
    const [ global ] = useGlobal();
    if(hasRole(global.roles, ROLES.ATTORNEY)) {
      history.push('/messages');
    } 

    return (
        <Grid key={name} item className={classes.mainGrid}>
        <Zoom in unmountOnExit className={classes.zoomEffect}>
          <Link to={url}>
            <MainMenuTile cardUID={cyprusId} header={name} description={description} icon={img} />
          </Link>
        </Zoom>
      </Grid>  
    )
};

export default withRouter(RenderTile)

  