import { Box, Typography, withStyles } from '@material-ui/core';
import DOMPurify from 'dompurify';
import moment from 'moment';
import React from 'react';
import { MESSAGE_CONTAINER_FETCH_MSG_ENUM } from '../../../../../../constants';

import styles from './single-message.style';
import { CustomIcons, icons } from '../../../../../shared/components/custom-icons';
import { withUnleashClient } from '../../../../../core/unleash';
import { UnleashService } from '../../../../../services';

/**
 * @name checkIsNotMyMsg
 * @param {object} props Component level props.
 * @desc Verifies if checking isNotMyMsg is required or not.
 * @return {boolean}
 */
const isCommunicationTab = (props) => {
  return props.parentComponent === MESSAGE_CONTAINER_FETCH_MSG_ENUM.COMMUNICATION;
};

const SingleMessage = (props) => {
  const {
    classes,
    body,
    createdDate,
    senderPersonId,
    selectedContact,
    messageRef,
    loggedInUserName,
    senderUserName,
    senderPersonName,
    readDate,
    recordStatus,
    unleashClient,
  } = props;
  const isSenderPersonIdFlagEnabled = unleashClient().isEnabled(UnleashService.FLAGS.SENDER_ID_OVER_SENDER_NAME);
  let isNotMyMessage = false;
  if (isSenderPersonIdFlagEnabled) {
    isNotMyMessage = selectedContact.threadId
      ? selectedContact.contactId === senderPersonId
      : senderUserName !== loggedInUserName;
  } else {
    isNotMyMessage = selectedContact.threadId
      ? selectedContact.contactName === senderPersonName
      : senderUserName !== loggedInUserName;
  }
  const cleanBody = DOMPurify.sanitize(body);
  const announcementId = props.announcementId || '';

  return (
    <Box
      ref={messageRef}
      className={[classes.messageBox, isNotMyMessage ? classes.leftMessage : classes.rightMessage].join(' ')}
    >
      <Box className={[classes.messsageMiscText, classes.senderPersonNameWrapper].join(' ')}>
        {isCommunicationTab(props) && (
          <>
            <Typography>{senderPersonName + ', '}</Typography>
            &nbsp;
          </>
        )}
        <Typography>{moment.parseZone(createdDate).format('LT')}</Typography>
      </Box>
      <Box className={classes.opponentMessageRow}>
        <Box
          className={[
            classes.message,
            isNotMyMessage ? [classes.opponentMessage, classes.rightMessage].join(' ') : classes.myMessage,
          ].join(' ')}
        >
          <Box className={classes.bodyLineBreak} component={'div'} dangerouslySetInnerHTML={{ __html: cleanBody }} />
        </Box>
        {!recordStatus && isNotMyMessage && (
          <Box className={classes.hiddenIcon} title={'Hidden by learner'}>
            <CustomIcons icon={icons.EyeSlashRegular} />
          </Box>
        )}
      </Box>
      {readDate && (
        <Box className={classes.messsageMiscText}>
          <Typography>Read at {moment.parseZone(readDate).format('LT')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(withUnleashClient(SingleMessage));
