export default (theme) => ({
    paper: {
        backgroundColor: "#e9edef"
    },
    tabContainer: {
        borderBottom: "2px solid #adaeaf"
    },
    tabButton: {
        ...theme.typography.t18,
        minWidth: 0,
    },
    selectedTabButton: {
        ...theme.typography.bold,
        minWidth: 0,
    },
    tabIndicator: {
        height: 5
    },
    userProfileWrapper: {
        padding: '25px 20px'
    },
    userProfileHeading: {
        ...theme.typography.t35,
        ...theme.typography.bold,
        letterSpacing: '-0.08px',
        color: theme.palette.primary.jetBlack,
        lineHeight: '46px'
    },
    blueIconContainer: {
        backgroundColor: theme.palette.primary.cobalt,
        borderRadius: '100%',
        padding: 15,
        width: 45,
        height: 45,
        "& svg": {
            fontSize: 45,
            color: theme.palette.primary.white
        }
    },
    inactiveAccordion: {
        flexDirection: 'column'
    },
    userId: {
        ...theme.typography.t16,
        ...theme.typography.medium,
        letterSpacing: '1.5px',
        color: theme.palette.primary.main,
        lineHeight: '21px'
    },
    userProfileInfoWrapper: {
        marginLeft: 20
    },
    metaMainText: {
        ...theme.typography.t25,
        letterSpacing: '1.15px',
        lineHeight: '33px',
        color: theme.palette.primary.jetBlack,
    },
    metaSubText: {
        ...theme.typography.t16,
        letterSpacing: '1.5px',
        lineHeight: '21px',
        ...theme.typography.medium,
        color: theme.palette.primary.main,
    },
    blueBtn: {
        marginTop: 10,
        minWidth: 150,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    contentAreaContainer: {
        borderBottom: `1px solid ${theme.palette.primary.grey}`
    },
    contentSectionPadding: {
        padding: '10px 30px'
    },
    contentAreaChip: {
        borderRadius: 4,
        padding: '0px 5px',
        ...theme.typography.t16,
        ...theme.typography.medium,
        letterSpacing: '1.43px',
        lineHeight: '15.7px'
    },
    blueBox: {
        backgroundColor: theme.palette.primary.main
    },
    blueBoxTitle: {
        ...theme.typography.t14,
        letterSpacing: '0.07px',
        color: theme.palette.primary.white,
        display: 'flex',
        alignItems: 'center'
    },
    blueBoxValue: {
        ...theme.typography.bold,
        ...theme.typography.t30,
        letterSpacing: '0.07px',
        color: theme.palette.primary.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    allActive: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    infographicBtn: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    daysSelectionList: {
        display: "flex"
    },
    daysSelectionItem: {
        width: "initial",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        height: 40,
        fontSize: 18,
        color: theme.palette.primary.cobalt,
        fontWeight: "bold",
        "&:first-child": {
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20
        },
        "&:last-child": {
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20
        }
    },
    activeDay: {
        backgroundColor: theme.palette.primary.cobalt,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.primary.cobalt
        }
    },
    profileMargin: {
        margin: '95px 15px 0px'
    }
});