import React from 'react';
import { useEffect, useState } from 'react';
import { getLearnerGroups, updateLearnerGroup } from '../../../../util/APIUtils';
import CustomDatePicker from '../../../users/datatable/customDatePicker';
import {
  Box,
  Button,
  Typography,
  Switch
} from '../../../../v2/shared/components/MaterialUIComponents';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons/CustomIcons';
import { isActiveOrEmpty, isInactiveAndNoMembers } from './util';


export const useLearnerGroupHook = props => {
  const [learnerGroups, setLearnerGroup] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isNewLearnerGroupDialogOpen, setNewLearnerGroupDialog] = useState(false);
  const [editLearnerGroupId, setLearnerGroupId] = useState(null);
  const {classes} = props;

  useEffect(() => {
    getLearnerData();        
  }, []);
  

  const onEditClick = (id) => {
    setLearnerGroupId(id);
    setNewLearnerGroupDialog(true);
  }

  const onDialogClose = () => {
    setNewLearnerGroupDialog(false); 
    setLearnerGroupId(null);
  }

  const updatedGroupData = () => {
    getLearnerData();
  }

  const onStatusChange = async (status,id) => {
    await updateLearnerGroup(id, { status: !status });
    const updatedGroups = learnerGroups.map(group => {
      if (group.id === id) {
        return { ...group, status: !group.status };
      }
      return group;
    });
    
    setLearnerGroup(updatedGroups);   
  }

  /**
   * @desc Fetching learner group columns with respect to feature flag
   * @return array
   */
  const getLearnerColumns = () => {
    const {unleashProps} = props;
    const columns = [
      {
        field: 'groupName',
        title: 'Group Name'
      },
      {
        field: 'createdDate',
        title: 'Date Created',
        type: 'date',
        filterComponent: props => <CustomDatePicker {...props} />
      },
      {
        field: 'status',
        title: 'Status',
        lookup: {
          true: 'Active',
          false: 'Suspended'
        },
        render: ({ sampleGroupMembers, status, id }) => {
          return (
            <Box className={classes.checkBoxWrapper}>
              <Typography className={classes.checkBoxText}>
                {status && 'ACTIVE'}
                {!status && 'SUSPENDED'}
              </Typography>
              <Switch 
                value={status} 
                onChange={() => onStatusChange(status, id)} 
                checked={status} 
                disabled={isInactiveAndNoMembers(status, sampleGroupMembers)}
              />
            </Box>
          );
        }
      },
      {
        field: 'sampleGroupMembers',
        title: 'Group Members (preview)',
        filtering: false,
        render: ({ sampleGroupMembers }) => (
          <Box className={classes.groupMemberNames}>{sampleGroupMembers.join(', ')}</Box>
        )
      },
      {
        field: 'Action',
        title: 'Action',
        filtering: false,
        render: ({ id , sampleGroupMembers, status}) => {
          if(isActiveOrEmpty(status,sampleGroupMembers)) {
            return (
              <Button onClick={() => onEditClick(id)} className={classes.editBtn}>
                <CustomIcons icon={icons.PencilIcon} />
                &nbsp; EDIT
              </Button>
            );  
          }
        },
        sorting: false
      }
    ];

    return columns;
  };
  
  /**
   * @desc Fetching learner group data from server
   *      returns empty array in case of data is not
   *      found
   * @returns
   */
  const getLearnerData = async () => {
    setLoading(true);
    const { payload } = await getLearnerGroups();
    setLearnerGroup(payload.learnerGroups || []);
    setLoading(false);
  };

  return {
    learnerGroups, 
    isLoading, 
    editLearnerGroupId, 
    isNewLearnerGroupDialogOpen, 
    setNewLearnerGroupDialog, 
    onEditClick,
    onDialogClose,
    getLearnerColumns,
    updatedGroupData
  }
};

