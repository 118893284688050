import { withStyles } from '@material-ui/styles';
import React from 'reactn';

import styles from './index.style';

class ProgramCourses extends React.Component {
    render() {
        const { classes, courses } = this.props;
        
        return (
            <ul className={classes.courseList}>
                {courses.map((course, index) => 
                    <li key={course.courseId + '-' + index}>
                        <span className={classes.label}>{'COURSE ' + (index + 1)}</span>
                        <span>{course.courseName}</span>
                    </li>)
                }          
            </ul>
        )
    };
};

export default withStyles(styles)(ProgramCourses);