import React, { Component } from 'reactn';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { callDummyApi } from '../../../util/APIUtils';
import store, { currentUser, showHideLoader } from '../../core/store';
import { CustomIcons, icons } from '../../shared/components/custom-icons';
import { CustomTheme, Typography } from '@orijinworks/frontend-commons';

import { Box, withStyles, Grid } from '../../shared/components/MaterialUIComponents';

import withUnleash from '../../../v2/core/unleash/withUnleash';
import { UnleashService } from '../../../v2/services';
import UnleashProvider from '../../core/unleash/unleashProvider';
import { USER_CONTEXT } from '../../../constants';
import RenderTile from './render-tile';

const styles = (theme) => ({
  mainGrid: {
    minWidth: 240,
    [theme.breakpoints.down(1150)]: {
      width: '100%',
    },
    [theme.breakpoints.up(1500)]: {
      width: '33.3333%',
    },
    width: '50%',
  },
  iconSizes: {
    fill: CustomTheme.palette.purple.main,
    width: '65px',
    height: '65px',
  },
  welcomeCard: {
    color: '#061E44',
  },
  zoomEffect: {
    transitionDelay: '600ms',
    height: '100%',
    display: 'block',
  },
  welcomeBox: {
    padding: '1rem 3rem',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  cardWrapper: {
    margin: '0rem 3rem',
  },
});

//Class START //
class Home extends Component {
  state = {
    user: null,
  };

  introCards = [
    {
      cyprusId: 'user-management',
      name: 'Location & User Management',
      description: 'Manage features and permissions for user accounts and locations',
      url: '/manage',
      img: <CustomIcons icon={icons.LocationUserManagement} className={this.props.classes.iconSizes} />,
    },
    {
      cyprusId: 'pathways',
      name: 'Outcomes & Progress',
      description: 'Track and analyze the progress and time spent by learners',
      url: '/outcomes-and-progress',
      img: <CustomIcons icon={icons.Outcomes} className={this.props.classes.iconSizes} />,
    },
    {
      cyprusId: 'analytics',
      name: 'Analytics',
      description: 'Discover dashboards and get insights for facilities and learners from data',
      url: '/analytics',
      img: <CustomIcons icon={icons.Analytics} className={this.props.classes.iconSizes} />,
      hidden: false,
    },
    {
      cyprusId: 'messages', // UNDERLYING LOGIC EXIST ON THIS ID
      name: 'Messages',
      description: 'Communicate with learners and share important announcements to engage and motivate learners',
      url: '/messages',
      img: <CustomIcons icon={icons.Messages} className={this.props.classes.iconSizes} />,
      hidden: false,
    },
    {
      cyprusId: 'voice-call', // UNDERLYING LOGIC EXIST ON THIS ID
      name: 'Voice Calls',
      description: 'Monitor and control voice call usage via auditing tools and contact management',
      url: '/voiceCalls',
      img: <CustomIcons icon={icons.VoiceCalls} className={this.props.classes.iconSizes} />,
      hidden: false,
      unleashFlagName: UnleashService.FLAGS.VOICE_CALL,
    },

    {
      cyprusId: 'Roles',
      name: 'Roles',
      description: 'Manage user roles and permissions',
      url: '/manageRoles',
      img: <CustomIcons icon={icons.Roles} className={this.props.classes.iconSizes} />,
      hidden: false,
    },
    {
      cyprusId: 'location-management',
      name: 'Location Management',
      description: 'Update and create locations and their settings',
      url: '/manageLocations',
      img: <CustomIcons icon={icons.Location} className={this.props.classes.iconSizes} />,
      hidden: false,
    },
  ];

  componentDidMount() {
    if (this.props.userDetail === null) {
      store.dispatch(this.qryuser());
    }
  }

  qryuser = () => {
    return async (dispatch, getState) => {
      try {
        dispatch(showHideLoader({ isLoading: true }));

        const data = await callDummyApi();
        dispatch(currentUser(data));
      } catch (error) {
      } finally {
        dispatch(showHideLoader({ isLoading: false }));
      }
    };
  };

  render() {
    const { showMessageTile, cbacConfigFlag, showLocationSettingsTile, permissionLoadingStack, showVoiceCallTile } =
      this.global;
    const { classes } = this.props;
    const Cards = this.introCards
      .filter((item) => permissionLoadingStack === 0)
      .filter((item) => {
        const isRouteAllowed = this.global.routes.findIndex((route) => route.path === item.url) !== -1;

        if (item.cyprusId === 'messages') {
          return isRouteAllowed && showMessageTile;
        } else if (item.cyprusId === 'location-management') {
          return isRouteAllowed && showLocationSettingsTile && this.global.isSuperAdmin;
        } else if (item.cyprusId === 'Roles') {
          return this.global.isSuperAdmin;
        } else if (item.cyprusId === 'analytics') {
          return !this.props.unleashProps.isFlagEnabled();
        } else if (item.cyprusId === 'voice-call') {
          return isRouteAllowed && showVoiceCallTile;
        }
        return isRouteAllowed;
      })
      .map((card) => {
        const { classes } = this.props;
        const { cyprusId, name, url, description, img, unleashFlagName } = card;
        // Render with or without UnleashProvider based on the flag
        return unleashFlagName ? (
          <UnleashProvider flagName={unleashFlagName}>
            <RenderTile cyprusId={cyprusId} name={name} url={url} description={description} img={img} classes={classes} />
          </UnleashProvider>
        ) : (
          <RenderTile cyprusId={cyprusId} name={name} url={url} description={description} img={img} classes={classes} />
        );
      });
    return (
      <div>
        <Box className={classes.welcomeBox}>
          <Typography variant="h1" className={classes.welcomeCard} gutterBottom>
            Welcome, {USER_CONTEXT?.given_name || USER_CONTEXT?.preferred_username || ''}
          </Typography>
        </Box>

        <div className={classes.cardWrapper}>
          <Grid id="hero-grid" container spacing={3}>
            {Cards}
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.currentUser;
};

export default connect(mapStateToProps)(
  withUnleash(withStyles(styles)(withRouter(Home)), UnleashService.FLAGS.SISENSE_DASHBOARD_SUBTAB)
);
