import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import styles from './add-contact-modal.component.style';
import { Autocomplete } from '@material-ui/lab';
import { Flag } from '@material-ui/icons';

const AddContactModal = (props) => {
  const { classes } = props;
  const {
    addContactModal,
    handleAddModalClose,
    learners,
    handleSearch,
    searchLearnerLoading,
    contact,
    handleContactChange,
    learnerListFetched,
    handleAddContact,
    selectedLearner,
  } = props;
  return (
    <>
      {contact && (
        <Dialog open={addContactModal} onClose={handleAddModalClose} fullWidth>
          <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>
            <Typography variant="h3">Add Contact</Typography>
            <IconButton onClick={handleAddModalClose}>
              <CloseIcon strokeWidth={2} fill="#ffffff" />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.addContactSection}>
            <Grid container spacing={1}>
              {selectedLearner ? (
                <Grid item xs={12}>
                  <TextField
                    label="Selected Learner"
                    variant="outlined"
                    fullWidth
                    value={selectedLearner.learnerUserName}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Autocomplete
                    id="learner"
                    options={learners}
                    getOptionLabel={(option) => option.userName}
                    onChange={(event, value) => handleSearch(value)}
                    disabled={!learnerListFetched}
                    loading={searchLearnerLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Resident"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {searchLearnerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={6}>
                <TextField
                  id="firstName"
                  name="firstName"
                  label="Contact First Name"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  value={contact.firstName}
                  onChange={handleContactChange}
                  error={contact.isSubmitted && contact.errors.hasOwnProperty('firstName')}
                  helperText={contact.isSubmitted ? contact.errors.firstName : ''}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Contact Last Name"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  value={contact.lastName}
                  onChange={handleContactChange}
                  error={contact.isSubmitted && contact.errors.hasOwnProperty('lastName')}
                  helperText={contact.isSubmitted ? contact.errors.lastName : ''}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Contact Phone Number"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  value={contact.phoneNumber}
                  onChange={handleContactChange}
                  error={contact.isSubmitted && contact.errors.hasOwnProperty('phoneNumber')}
                  helperText={contact.isSubmitted ? contact.errors.phoneNumber : ''}
                  placeholder="XXXXXXXXXX"
                  required
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Flag /> +1
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="relationshipType"
                  name="relationshipType"
                  label="Contact Relationship"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  select
                  value={contact.relationshipType}
                  onChange={handleContactChange}
                  error={contact.isSubmitted && contact.errors.hasOwnProperty('relationshipType')}
                  helperText={contact.isSubmitted ? contact.errors.relationshipType : ''}
                  required
                  fullWidth
                >
                  {VOICE_CALL.getRelationshipTypes().map((relationshipType) => (
                    <MenuItem key={relationshipType.id} value={relationshipType.backendValue}>
                      {relationshipType.value}
                      {VOICE_CALL.getPrivilegeRelationShipTypes().includes(relationshipType.value) && ' (Privileged)'}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <Typography id="privilegeText" className={classes.privilegeText}>
                  <ErrorIcon />
                  Privileged contacts cannot be monitored by admins.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="contactStatus"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  placeholder="Select"
                  label="Contact Status"
                  select
                  fullWidth
                  onChange={handleContactChange}
                  required
                  value={contact.contactStatus}
                >
                  <MenuItem value={VOICE_CALL.STATUS.APPROVED}>APPROVED</MenuItem>
                  <MenuItem value={VOICE_CALL.STATUS.PENDING}>PENDING</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="preferredLanguage"
                  name="preferredLanguage"
                  label="Preferred Language"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  select
                  value={contact.preferredLanguage}
                  onChange={handleContactChange}
                  error={contact.isSubmitted && contact.errors.hasOwnProperty('preferredLanguage')}
                  helperText={contact.isSubmitted ? contact.errors.preferredLanguage : ''}
                  required
                  fullWidth
                >
                  {VOICE_CALL.getLanguages().map((language) => (
                    <MenuItem key={language.value} value={language.value}>
                      {language.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              id="saveEdit"
              variant="contained"
              className={classes.saveButton}
              color="primary"
              onClick={handleAddContact}
            >
              Add Contact
            </Button>
            <Button onClick={handleAddModalClose} color="primary" aria-label="close">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default withStyles(styles)(AddContactModal);
