import React from "react";
import { ANNOUNCEMENT_TYPE } from "../../../../../../constants";
import { Box, Menu,  IconButton, Typography, MenuItem } from '../../../../../shared/components/MaterialUIComponents';
import { CustomIcons, icons } from "../../../../../shared/components/custom-icons";

export const RecipientChip = ({ 
    recipient, 
    onEditGroupClick, 
    announcementType, 
    hasUnreachableLearner, 
    removeSelectedUser,
    classes,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onEditGroup = () => {
    setAnchorEl(null);
    onEditGroupClick(recipient);
  }

    return (
      <Box
        id={`selectedUsers-${recipient.contactId}`}
        key={`selectedUsers-${recipient.contactId}`}
        className={classes.selectedChip}
      >
        {announcementType === ANNOUNCEMENT_TYPE.LEARNER_GROUP && hasUnreachableLearner && (
          <CustomIcons className={classes.warningIconInline} icon={icons.WarningIcon} />
        )}
        <Typography className={classes.selectedChipName}>{recipient.contactName || recipient.locationName}</Typography>
        {announcementType === ANNOUNCEMENT_TYPE.LEARNER && (
          <Typography className={classes.selectedChipSubText}>{recipient.locationName}</Typography>
        )}
        {announcementType === ANNOUNCEMENT_TYPE.LEARNER_GROUP && (
          <div>
            <IconButton
              onClick={event => setAnchorEl(event.currentTarget)}
              className={classes.ellipsesIconButton}
              id="main-ellipsis-icon"
              data-cy-name="main-ellipsis-icon"
            >
              <CustomIcons className={classes.ellipsesIconColor} icon={icons.Ellipsis} />
            </IconButton>
            <Menu
              className={classes.menuMargin}
              id={`ellipsis-menu-${recipient.contactId}`}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem key={recipient.contactId} id={`edit-group-item-${recipient.contactId}`} onClick={onEditGroup}>Edit Group</MenuItem>
            </Menu>
          </div>
        )}
        <IconButton
          onClick={() => removeSelectedUser(recipient)}
          className={[classes.selectedCloseBtn, 'remove-selected'].join(' ')}
        >
          <CustomIcons className={classes.selectedChipCloseIcon} icon={icons.CloseIcon} />
        </IconButton>
      </Box>
    );
  };
  