import { withStyles } from '@material-ui/styles';
import React from 'react';
import styles from './call-history-details-modal.style';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AudioPlayer from 'material-ui-audio-player';
import { calculateDurationInMins, formatDatetime } from '../../utils/utils';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import TranscriptPdf from '../transcript-pdf/transcript-pdf';
import moment from 'moment-timezone';
const CallHistoryDetailsModal = props => {
  const {
    classes,
    isDetailModalOpen,
    handleCloseHistoryDetailsModal,
    learnerFirstName,
    learnerLastName,
    learnerDocId,
    contactPhoneNumber,
    contactInfo,
    joinTimeUtc,
    endTimeUtc,
    recordingUrl,
    transcriptData,
    transcriptSummary,
    isPrivileged
  } = props;
  let contactInfoObj = contactInfo ? JSON.parse(contactInfo) : {};
  const hasTranscript = (transcriptData && transcriptData.transcript && transcriptData.transcript.length > 0) ? true : false;
  const hasSummary = (hasTranscript) && (transcriptSummary && transcriptSummary.main_topics && transcriptSummary.main_topics.length > 0) ? true : false;


  return (
    <Dialog open={isDetailModalOpen} onClose={handleCloseHistoryDetailsModal} fullWidth>
      <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>
        <Typography variant="h3">Call Details</Typography>
        <IconButton id="close-button"  aria-label="close" onClick={handleCloseHistoryDetailsModal}>
          <CloseIcon strokeWidth={2} fill="#ffffff" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid xs={12} className={classes.callDetailsSection}>
            <Grid item xs={6}>
              <Box className={classes.infoGroup}>
                <Typography id="learner-username"  variant="h6" className={classes.infoHeading}>
                  {learnerFirstName} {learnerLastName || 'N/A'}
                </Typography>
                <Typography  className={classes.identity}>Learner</Typography>
                <Typography id="learner-doc-id" className={classes.infoDetails}>{learnerDocId || 'N/A'}</Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box className={classes.infoGroup}>
                <Typography id="contact-full-name" variant="h6" className={classes.infoHeading}>
                  {(contactInfoObj.firstName || '') + (contactInfoObj.lastName ? ' ' + contactInfoObj.lastName : '') ||
                    'N/A'}
                </Typography>
                <Typography className={classes.identity}>Visitor</Typography>
                <Typography id="learner-contact-details" className={classes.infoDetails}>
                  {contactPhoneNumber} | {contactInfoObj.relationshipType || 'N/A'}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid xs={12} className={classes.recordingSection}>
            <Grid item xs={12}>
              <Box className={classes.recordingDetails}>
                <Typography className={classes.recordingLabel}>Recording</Typography>
                <Typography variant="body2" id="call_duration" className={classes.textGray}>
                  <span className={classes.callDurationText}>Call Duration</span>:{' '}
                  {calculateDurationInMins(joinTimeUtc, endTimeUtc)}
                </Typography>
                <Typography variant="body2" id="join_time" className={classes.textGray}>
                  {formatDatetime(endTimeUtc)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.audioPlayer} id="audio-player">
                {recordingUrl ? (
                  <AudioPlayer
                    elevation={0}
                    variation="primary"
                    download={true}
                    order="standart"
                    preload="auto"
                    rounded={true}
                    src={recordingUrl}
                  />
                ) : (
                  <Typography variant="body2" className={classes.noRecordingAvailable} id="noRecordingAvailable">
                    <CustomIcons icon={icons.NoRecordingIcon} className={classes.noRecordingAvailableIcon}></CustomIcons>
                    {isPrivileged ? 'Call Recording Unavailable for Privileged calls':'Audio Recording Unavailable'}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          {hasSummary && (
            <>
              <Grid xs={12} className={classes.transcriptSection}>
                <Grid item xs={12}>
                  <Typography className={classes.summaryTitle}>Summary</Typography>
                  <Box className={classes.summaryBox}>
                    <Typography variant="h6" className={classes.infoHeading}>
                      Main Topics
                    </Typography>
                    <Typography id="transcript-summary" variant="body1" className={classes.summaryText}>
                      {transcriptSummary.main_topics}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        {hasTranscript && (
          <DialogActions className={classes.dialogActions} id="transcript-pdf" >
            <>
              <PDFDownloadLink
                 
                document={
                  <TranscriptPdf
                    learnerName={learnerFirstName + ' ' + learnerLastName}
                    callerName={(contactInfoObj.firstName || '') + ' ' + (contactInfoObj.lastName || '')}
                    transcriptData={transcriptData}
                    callDuration={calculateDurationInMins(joinTimeUtc, endTimeUtc)}
                    dateTime={formatDatetime(endTimeUtc)}
                    transcriptSummary={transcriptSummary}
                  />
                }
                fileName={`${moment().format('YYYY-MM-DD')}-Transcript-${learnerLastName}-${contactInfoObj.lastName}.pdf`}

              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    'Loading document...'
                  ) : (
                    <Button id="downloadTranscript" variant="contained" className={classes.downloadTranscriptBtn}>
                      Download Transcript
                    </Button>
                  )
                }
              </PDFDownloadLink>
            </>
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(CallHistoryDetailsModal);
