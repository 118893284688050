import React from 'react';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  editIcon: {
    fontSize: 16
  }
}));

const EditButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick}>
      <EditIcon className={classes.editIcon} />
    </IconButton>
  );
}

export default EditButton;