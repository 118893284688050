import React, { Suspense } from 'reactn';
import { Grid, withStyles, Snackbar, Box } from '../../../shared/components/MaterialUIComponents';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { LOCATIONS, POLL_CONFIG, SOCKET_EVENT_TYPES } from '../../../../constants';
import MuiAlert from '@material-ui/lab/Alert';
import { setLocation as selectLocation, setHousingUnit as selectHousingUnit } from '../../../core/store';
import styles from './message.style';

import {
  fetchMessagingCharacterLimit,
  fetchSearchLearner,
  fetchContacts,
  fetchAnnouncementCharacterLimit,
  fetchSearchLocation,
  getUnreadMessagesCount,
  findUsers,
  getRelationshipUsersList,
  getLocationFeatureStatus
} from '../../../../util/APIUtils';
import { MESSAGING_POLL_INTERVAL, setJanusToken, ROLES } from '../../../../constants';
import { fetchJanusToken } from '../../../../util/APIUtils';
import {
  rsSetActiveThread,
  rsSetSelectedContent,
  rsSetUnReadMsgAnnouCount,
  setCharacterLimit,
  setMessageType,
  setNewMessage
} from '../../../core/store/reducers/messageModuleReducer/messageModuleActions';
import useStaffMessageSocket from '../../../shared/hooks/useStaffMessageSocket';
import { DIRECT_MESSAGE as Contacts, EVENTS } from '../../../services/constants';
import { Loader } from '@orijinworks/frontend-commons';
import NewAnnouncement from './v2/new-announcement/new-announcement';
import NewDirectMessage from './v2/new-message/new-message';
import { eventEmitter } from '../../../services/event-emitter-service';
import { withUnleash } from '../../../core/unleash';
import { UnleashService } from '../../../services';

const RelationshipViewer = React.lazy(() => import('./manage-relationship'));
const MessagesViewer = React.lazy(() => import('./v2/message-viewer/message-viewer'));
const SideBar = React.lazy(() => import('./sidebar'));
const NewMessageBtn = React.lazy(() => import('./new-message-btn'));

const DIRECT_MESSAGE = 1;
const ANNOUNCEMENT = 2;

class Message extends React.Component {
  hitPollApi = true;
  debounceTimerId = null;

  constructor() {
    super();
    this.state = {
      timezoneName: 'UTC',
      selectedContact: null,
      newSelectedContact: null,
      location: null,
      messageType: 0,
      messagingCharacterLimit: {
        messagingMinCharacterLimit: 30,
        messagingMaxCharacterLimit: 32000,
        messagingMaxRowLimit: 10
      },
      announcementCharacterLimit: {
        announcementMinCharacterLimit: 30,
        announcementMaxCharacterLimit: 32000,
        announcementMaxRowLimit: 10
      },
      showErrorMsg: false,
      errorMsg: '',
      activeThread: '',
      isLoading: false,
      locationsLoaded: false,

      relationShipUi: false,
      adminsOrAttorneys: [],
      learners: [],
      usersListForRelationship: [],
      manageRelationshipFeature: null,

      contacts: [],
      announcements: [],

      learnersLoading: false,
      adminsLoading: false
    };
  }

  async componentDidUpdate(prevProps) {
    const paramsChanged =
      prevProps.match.params.location !== this.props.match.params.location ||
      prevProps.match.params.threadId !== this.props.match.params.threadId;
    if (!this.state.locationsLoaded || paramsChanged) {
      if (this.props.match.params.location) {
        const location = LOCATIONS.find(item => item.locationId === parseInt(this.props.match.params.location));
        if (location) {
          if (prevProps.selectedLocation !== location) {
            await this.selectLocation(location);
            this.props.selectLocation(location);
          }
          if (this.props.match.params.threadId) {
            const contacts = await fetchContacts(location.locationId, 'recent_message');
            const selectedContact = contacts.messageThreads.find(
              contact => contact.threadId === parseInt(this.props.match.params.threadId)
            );
            if (selectedContact) {
              this.setState({
                location,
                selectedContact,
                locationsLoaded: true
              });
              this.getLocationTimezone(location);
            }
          }
        }
      }
    }
    if (this.props.selectedLocation && prevProps.selectedLocation !== this.props.selectedLocation) {
      await this.selectLocation(this.props.selectedLocation);
      this.setState({
        location: this.props.selectedLocation
      });
      this.getLocationTimezone(this.props.selectedLocation);
    }
  }

  /**
   * @name getLocationTimezone
   * @desc Fetches location timezone from saved locations and props active locations.
   * @return {void}
   */
  getLocationTimezone = location => {
    const { locationId } = location;
    const activeLocation = LOCATIONS.find(location => location.locationId === locationId) || {};
    const locationAttribute = (activeLocation.attributes || []).find(attr => attr.attributeName === 'TimeZone');
    if (locationAttribute) {
      this.setState({
        timezoneName: locationAttribute.attributeValue
      });
    }
  };

  async componentDidMount() {
    if (POLL_CONFIG.USE_POLLING) {
      this.pollGetMessageCount();
    }

    if (this.props.location.pathname === '/messages') {
      this.props.selectHousingUnit(null);
    }
    if (this.props.selectedLocation) {
      await this.selectLocation(this.props.selectedLocation);
      this.setState({
        location: this.props.selectedLocation
      });
      await this.getLocationTimezone(this.props.selectedLocation);
    }
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  MessagesHook = () => {
    useStaffMessageSocket();
    return null;
  };

  clearAllRelationshipUsers = () => {
    this.setState({
      adminsOrAttorneys: [],
      learners: []
    });
  };

  pollGetMessageCount = () => {
    this._interval = setInterval(() => {
      if (this.hitPollApi) {
        console.info(' ===================================== pollGetMessageCount ===================================== ');

        this.getUnreadMessagesAndAnnouncementsCount();
      }
    }, MESSAGING_POLL_INTERVAL);
  };

  setSelectedContactForNew = contact => {
    contact['messageRelationshipStatus'] = true;
    this.props.dispatch(
      rsSetSelectedContent({
        rsSelectedContact: contact,
        rsActiveThread: contact.contactId ? 'contacts' : 'announcements'
      })
    );

    this.setState({
      newSelectedContact: contact,
      selectedContact: contact || this.state.selectedContact,
      activeThread: contact.contactId ? 'contacts' : 'announcements'
    });
  };

  selectLocation = async location => {
    if (location !== this.state.location) {
      this.props.dispatch(setNewMessage(false));
      this.props.dispatch(rsSetSelectedContent({
        rsSelectedContact: null,
        rsActiveThread: Contacts
      }));
      if (this.global.cbacConfigFlag) {
        const janusToken = await fetchJanusToken(location.locationId);
        setJanusToken(janusToken.token || '');
        const { rolesTypes } = jwt_decode(janusToken.token);
        this.setGlobal({
          isSuperAdmin: rolesTypes.includes(ROLES.SUPER_ADMIN),
          isAttorney: rolesTypes.includes(ROLES.ATTORNEY),
          isTeacher: rolesTypes.includes(ROLES.TEACHER),
          isFacilityAdmin: rolesTypes.includes(ROLES.STAFF),
          isMessagingOnly: rolesTypes.includes(ROLES.MESSAGING_ONLY),
        });
      }
      this.setState(
        {
          location,
          isLoading: true,
          selectedContact: null
        },
        this.fetchCharacterLimits(location.locationId)
      );

      if (!this.global.isAttorney) {
        try {
          const manageRelationshipFeature = await getLocationFeatureStatus(location.locationId, 'Manage_Relationships');
          this.setState({
            manageRelationshipFeature
          });
        } catch (e) {
          console.log(e);
        }
      }
      this.setState({
        isLoading: false
      });
      this.getUnreadMessagesAndAnnouncementsCount(); //INFO cant delete right  now this  is  needed  for trouble shoot
    }
  };

  /* setLoading = value => {
    this.setState({
      isLoading: value
    });
  };
 */
  getUnreadMessagesAndAnnouncementsCount =  async () => {
    const currentPath = window.location.href;
    if (!this.state.location || !this.state.location.locationId || !currentPath.includes('messages')) {
      return;
    }
    this.hitPollApi = false;
    const _response = await getUnreadMessagesCount(this.state.location.locationId);
    const countObject = {
      totalUnreadAnnouncementCount: 0,
      totalUnreadMessageThreadCount: 0
    };

    if (_response) {
      countObject.totalUnreadAnnouncementCount = _response.totalUnreadAnnouncementCount;
      countObject.totalUnreadCount = _response.totalUnreadMessageThreadCount;
    }

    eventEmitter.emit( EVENTS.UNREAD_COUNT, countObject);

    this.props.dispatch(rsSetUnReadMsgAnnouCount({ rsUnreadMsgAnnoCountObj: countObject })); // INFO now we will be getting the data from  the redux store so, when pooling will be enable in that the data should get from redux-store
    this.hitPollApi = true;
  };

  fetchCharacterLimits = location => {
    Promise.all([fetchMessagingCharacterLimit(location), fetchAnnouncementCharacterLimit(location)]).then(_successLog => {
      const data = _successLog.reduce((item, newItem) => {
        return {
          ...item,
          ...newItem
        };
      });
      this.props.dispatch(setCharacterLimit(data));
    });
  };

  onSearch = async key => {
    if (this.state.messageType === DIRECT_MESSAGE) {
      return await fetchSearchLearner(key, this.state.location.locationId);
    } else if (this.state.messageType === ANNOUNCEMENT) {
      return await fetchSearchLocation(key, this.state.location.locationId);
    }
  };

  onDirectMessage = () => {
    this.props.dispatch(setMessageType(DIRECT_MESSAGE));
    this.props.dispatch(setNewMessage(true));

    this.setState({
      messageType: DIRECT_MESSAGE,
      selectedContact: {
        contactId: 0,
        contactName: 'New User Message',
        messageRelationshipStatus: true
      },
      newSelectedContact: null,
      activeThread: 'contacts'
    });
  };

  onAnnouncement = () => {
    this.props.dispatch(setMessageType(ANNOUNCEMENT));
    this.props.dispatch(setNewMessage(true));

    this.setState({
      messageType: ANNOUNCEMENT,
      selectedContact: {
        locationId: 0,
        locationName: 'New'
      },
      newSelectedContact: null,
      activeThread: 'announcements'
    });
  };

  getRelationshipList = query => {
    const filters = {
      adminFullName: '',
      learnerFullName: '',
      learnerDocId: '',
      createdDate: '',
      recordStatus: 'true, false'
    };
    query.filters.forEach(filter => {
      if (filter.column.field === 'relationshipStatus' && filter.value.length > 0) {
        filters['recordStatus'] = filter.value;
        return;
      }
      filters[filter.column.field] = filter.value;
    });

    return new Promise(async (resolve, reject) => {
      const { page, pageSize } = query;
      try {
        this.blurSearchInputs();
        const { totalRecordsCount, dataset } = await getRelationshipUsersList(
          this.state.location.locationId,
          pageSize,
          page,
          filters
        );
        if (!dataset || !totalRecordsCount) {
          resolve({
            data: [],
            totalCount: 0,
            page
          });
          return;
        }
        // this.setState({
        //   usersListForRelationship: dataset
        // });
        resolve({
          data: dataset,
          totalCount: totalRecordsCount,
          page
        });
      } catch (e) {
        reject({
          data: [],
          totalCount: 0,
          page
        });
      }
    });
  };

  openCloseRelationshipUi = async value => {
    this.setGlobal({ isLoading: true });
    this.setGlobal({ isLoading: false });
    this.setState({
      ...this.state,
      relationShipUi: value
    });
    this.setGlobal({
      hiddenBreadcrumbItems: value ? ['Manage Relationships'] : []
    });
  };

  handleCloseErrorMsg = () => {
    this.setState({
      showErrorMsg: false,
      errorMsg: ''
    });
  };

  blurSearchInputs = () => {
    const searchNodes = document.querySelectorAll('input[type=search]');
    searchNodes.forEach(node => {
      node.blur();
    });
  };

  onSearchUser = (name, isLearner) => {
    const { unleashProps } = this.props;
    if (!name || (name.length < 3 && unleashProps.isFlagEnabled())) {
      this.setState({
        learnersLoading: false,
        adminsLoading: false
      })
      return;
    }
    if (isLearner) {
      this.setState({
        ...this.state,                  
        learnersLoading: true
      });
    } else {
      this.setState({
        ...this.state,
        adminsLoading: true
      });
    }

    const delay = 3000;
    clearTimeout(this.debounceTimerId);
    this.debounceTimerId = setTimeout(async () => {
      try {
        const users = await findUsers(name, isLearner, this.state.location.locationId);
        if (users && users.length > 0) {
          if(unleashProps.isFlagEnabled()) {
            users.sort((a, b) => a.contactUsername.localeCompare(b.contactUsername));
          }
          if (isLearner) {
            this.setState({
              ...this.state,
              learners: users,
              learnersLoading: false
            });
          } else {
            this.setState({
              ...this.state,
              adminsOrAttorneys: users,
              adminsLoading: false
            });
          }
        }
      } catch (error) {
        console.error(error);
      }

    }, delay);
  };

  updateOrRefreshManageRelationshipListing = () => {
    this.setState({ ...this.state });
  };

  showMessageViewer = () => {
    const { rsMessageModuleStore = {} } = this.props;
    const { rsActiveThread, rsMessageThread, rsAnnouncementsThread, rsSelectedContact, newMessage } = rsMessageModuleStore;
    if (
      Object.keys(rsSelectedContact || {}).length === 0 && !newMessage
    ) {
      return false;
    }
    return true;
  };

  RenderNewConversation = () => {
    const { rsMessageModuleStore = {}, unleashProps } = this.props;
    const { messageType } = this.state;
    const { newMessage } = rsMessageModuleStore;

    if (!newMessage) {
        return <MessagesViewer {...this.global} timezoneName={this.state.timezoneName} />;
    }

    return messageType === ANNOUNCEMENT ? <NewAnnouncement /> : <NewDirectMessage />;
  }


  render() {
    const { classes, newMessage } = this.props;
    const { isLoading, location } = this.state;
    return (
      <Grid spacing={3} className={classes.mainGrid}>
        <Box component={"span"} id="messaging-event"/> 
        <Suspense fallback={<Loader isLoading={true} />}>
          <Loader isLoading={isLoading} />
          <Snackbar
            open={this.state.showErrorMsg}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={this.handleCloseErrorMsg}
          >
            <MuiAlert severity="error" elevation={6} variant="filled">
              {this.state.errorMsg}
            </MuiAlert>
          </Snackbar>

          {location && (
            <>
              {this.state.relationShipUi ? (
                <Suspense fallback={<Loader isLoading={true} />}>
                  <Grid item sm={12} md={12}>
                    <RelationshipViewer
                      location={location}
                      updateOrRefreshManageRelationshipListing={this.updateOrRefreshManageRelationshipListing}
                      manageRelationshipFeature={this.state.manageRelationshipFeature}
                      adminsOrAttorneys={this.state.adminsOrAttorneys}
                      learners={this.state.learners}
                      openCloseRelationshipUi={this.openCloseRelationshipUi}
                      onSearch={this.onSearchUser}
                      usersListing={this.state.usersListForRelationship}
                      dataresolver={this.getRelationshipList}
                      clearAllRelationshipUsers={this.clearAllRelationshipUsers}
                      learnersLoading={this.state.learnersLoading}
                      adminsLoading={this.state.adminsLoading}
                    />
                  </Grid>
                </Suspense>
              ) : (
                <Grid container className={classes.mainContainer}>
                  <Grid
                    item
                    sm={12}
                    md={6}
                    lg={4}
                    //  classes={{ root: classes.sidebar }
                    style={{
                      paddingRight: '1.2rem'
                    }}
                  >
                    {/* INFO messages left panal */}
                    <Box className={classes.messagePanel}>
                      <SideBar location={location} />
                      {location && !this.state.relationShipUi && (
                        // INFO New message/Relationship button
                        <NewMessageBtn
                          manageRelationshipFeature={this.state.manageRelationshipFeature}
                          isAttorney={this.global.isAttorney}
                          isTeacher={this.global.isTeacher}
                          onDirectMessage={this.onDirectMessage}
                          onAnnouncement={this.onAnnouncement}
                          openCloseRelationshipUi={this.openCloseRelationshipUi}
                        />
                      )}
                      <div className={`${classes.container} ${classes.messagesActions}`}></div>
                    </Box>
                  </Grid>
                  {(this.showMessageViewer() || newMessage) && (
                    <Grid
                      item
                      sm={12}
                      md={6}
                      lg={8}
                      className={classes.messageViewer}
                    >
                      <this.RenderNewConversation />

                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}
        </Suspense>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.app,
    rsMessageModuleStore: state.rsMessageModuleStore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectLocation: location => dispatch(selectLocation(location)),
    selectHousingUnit: hu => dispatch(selectHousingUnit(hu)),
    dispatch: action => dispatch(action)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withUnleash(Message,UnleashService.FLAGS.MANAGE_RELATIONSHIP_LIST_BY_USERNAME))));
