import { Box, Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import React from 'reactn';
import AddContactModal from '../add-contact-modal/add-contact-modal.component';
import styles from './pending-contacts-list.component.style';
import useContactsListComponentHook from './usePendingContactsListComponentHook';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import EditContactModal from '../edit-contact-modal/edit-contact-modal.component';
import useAddContactModalHook from '../add-contact-modal/useAddContactModalHook';

const PendingContactsListComponent = ({ activeTab, locationId, classes, isDataRefresh, setDataRefresh }) => {
  const {
    tableColumns,
    pageSize,
    openSnackbar,
    addContactSuccess,
    handleCloseSnackbar,
    snackbarMessage,
    snackbarAutoHideDuration,
    getContactsList,
    tableRef,
    handleExportCsv,
    shouldShowExportButton,
    editContact,
    setEditContact,
    setIsEditSucess,
    setIsEditError,
    isEditContactModalOpen,
    setIsEditContactModalOpen,
    handleEditContactModalClose
  } = useContactsListComponentHook(activeTab, locationId, classes, isDataRefresh, setDataRefresh);
  const {
    addContactModal,
    handleAddModalClose,
    handleAddModalOpen,
    learners,
    searchLearnerLoading,
    contact,
    handleContactChange,
    handleAddContact,
    learnerListFetched,
    handleAddModalCloseSnackbar,
    addContactSuccessSnackbar,
    showAddContactSnackBar,
    snackbarMessageSnackbar,
    handleSearch,
  } = useAddContactModalHook(locationId,null,setDataRefresh);
  const tableColumnsRef = React.useRef(tableColumns);

  return (
    <>
      <Box display="flex" width="100%" alignItems="center" justifyContent="right" marginBottom="10px">
    
        <Button
          variant="contained"
          color="primary"
          disableElevation
          data-id="add-contact-button"
          onClick={handleAddModalOpen}
        >
          Add Contact
        </Button>
      </Box>
      {locationId ? (
        <MaterialTable
          tableRef={tableRef}
          data-id="contacts-table"
          columns={tableColumnsRef.current}
          title={''}
          data={getContactsList}
          options={{
            filtering: true,
            pageSize: pageSize,
            search: false,
            thirdSortClick: false,
            exportButton: shouldShowExportButton(),
            exportFileName: `Location Name Voice Calls All Contacts`,
            exportCsv: () => handleExportCsv(tableColumnsRef.current)
          }}
          icons={{
            Export: SaveAltIcon
          }}
        />
      ) : null}
      <AddContactModal
        addContactModal={addContactModal}
        handleAddModalClose={handleAddModalClose}
        learners={learners}
        handleSearch={handleSearch}
        searchLearnerLoading={searchLearnerLoading}
        contact={contact}
        handleContactChange={handleContactChange}
        learnerListFetched={learnerListFetched}
        handleAddContact={handleAddContact}
      />

      <EditContactModal
        isEditContactModalOpen={isEditContactModalOpen}
        setIsEditContactModalOpen={setIsEditContactModalOpen}
        handleEditContactModalClose={handleEditContactModalClose}
        editContact={editContact}
        setEditContact={setEditContact}
        setIsEditSucess={setIsEditSucess}
        setIsEditError={setIsEditError}
      />
      <Snackbar open={openSnackbar} autoHideDuration={snackbarAutoHideDuration} onClose={handleCloseSnackbar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={addContactSuccess ? 'success' : 'error'}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Snackbar id='add-contact-modal-snackbar' open={showAddContactSnackBar} autoHideDuration={snackbarAutoHideDuration} onClose={handleAddModalCloseSnackbar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleAddModalCloseSnackbar}
          severity={addContactSuccessSnackbar ? 'success' : 'error'}
        >
          {snackbarMessageSnackbar}
        </MuiAlert>
      </Snackbar>
    </>
    // table work
  );
};
export default withStyles(styles)(PendingContactsListComponent);
