import { CustomTheme } from '@orijinworks/frontend-commons';

export default (theme) => ({
  builderContainer: {
    backgroundColor: 'var(--grey-30)',
    marginTop: 20,
  },
  allResumeBtn: {
    border: `1px solid ${theme.palette.primary.blue.main}`,
    color: theme.palette.primary.blue.main,
  },
  btn: {
    fontSize: 16,
    fontFamily: 'Inter',
    textTransform: 'capitalize',
    height: 40,
    padding: '0px 20px',
    fontWeight: 700,
    transition: 'all 0.2s'
  },
  backIcon: {
    fontSize: '16px !important',
  },
  editingBackground: {
    background: 'url(/assets/resume/background.png)',
    backgroundSize: '100%',
    color: theme.palette.primary.white,
    padding: 32,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  viewPdfBtn: {
    background: theme.palette.primary.white,
    color: theme.palette.primary.blue.main,
    '&:hover': {
      background: theme.palette.primary.white,
      color: theme.palette.primary.blue.main,
    },
  },
  resumeBuliderContents: {
    padding: '30px 15px',
  },
});
