import React from "react";
import moment from "moment";
import {Box, Grid, Typography} from '@material-ui/core';
import useStyles from "./report.styles";
import { toFixed } from "./utils";
import { CONTENT_AREAS_ENUM, CONTENT_AREA } from "../../../../constants";

export default function ProgressReport(props) {
  const classes = useStyles();

  if (!props.data) return null;
  const {
    docId,
    issueDate,
    totalHours,
    categoriesHours,
    transcriptListDtos
  } = props.data;
  const renderHeader = () => (
    <React.Fragment>
      <Box className={classes.reportHeader}>
        <img
          src="https://content.connectedcorrections.com/assets/logo/apds-logo-1.png"
          className={classes.brandLogo}
          alt="apds-logo.png"
        />
        <Box className={classes.titleContainer}>
          <Typography variant="h2" className={[classes.title, classes.fontDMSans].join(" ")}>
            {props.userDetail
              ? props.userDetail.firstName || props.userDetail.lastName
                ? `${props.userDetail.firstName} ${props.userDetail.lastName}`
                : props.userDetail && props.userDetail.userName
              : ""}
          </Typography>
          <Typography variant="h5" className={[classes.subTitle, classes.fontDMSans].join(" ")}>Progress Report</Typography>
        </Box>
        <Box className={classes.detailContainer}>
          <Box className={classes.detailLabels}>
            <Typography className={[classes.detailText, classes.bold, classes.fontDMSans].join(" ")}>
              Issued
            </Typography>
            <Typography className={[classes.detailText, classes.bold, classes.fontDMSans].join(" ")}>
              Doc Id
            </Typography>
            <Typography className={[classes.detailText, classes.bold, classes.fontDMSans].join(" ")}>
              Total Hours
            </Typography>
          </Box>
          <Box className={classes.detailValues}>
            <Typography className={[classes.detailText, classes.fontDMSans].join(" ")}>
              {issueDate ? moment(issueDate).format("LL") : ""}
            </Typography>
            <Typography className={[classes.detailText, classes.fontDMSans].join(" ")}>{docId}</Typography>
            <Typography className={[classes.detailText, classes.fontDMSans].join(" ")}>{totalHours.toFixed(1)}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.headerSeperator} />
    </React.Fragment>
  );

  const renderCourseTableHeader = () => (
    <Box className={classes.tableHeader}>
      <Typography variant="h5" className={[classes.width40p, classes.semiBold, classes.fontDMSans].join(" ")}>
        Path/Course
      </Typography>
      <Typography variant="h5" className={[classes.width40p, classes.semiBold, classes.fontDMSans].join(" ")}>
        Last Accessed
      </Typography>
      <Typography
        variant="h5"
        className={[
          classes.width10p,
          classes.textAlignRight,
          classes.semiBold,
          classes.fontDMSans
        ].join(" ")}
      >
        Hours*
      </Typography>
    </Box>
  );

  const renderCourseTable = obj => (
    <React.Fragment>
      <Box className={classes.tableSubHeader}>
        <Typography variant="h5" className={[classes.bold, classes.fontDMSans].join(" ")}>{obj.titleName}</Typography>
        <Typography variant="h5" className={[classes.bold, classes.fontDMSans].join(" ")}>{obj.hours.toFixed(1)}</Typography>
      </Box>
      {obj.children.map(item => (
        <Box key={item.id} className={classes.courseItem}>
          <Box className={classes.courseRow}>
            <Typography
              variant="body2"
              className={[
                classes.noMargin,
                classes.courseField,
                classes.width40p,
                classes.fontDMSans
              ].join(" ")}
            >
              {item.titleName}
            </Typography>
            <Typography
              variant="body2"
              className={[
                classes.noMargin,
                classes.courseField,
                classes.width40p,
                classes.fontDMSans
              ].join(" ")}
            >
              {item.completed ? "Complete" : "In Progress"}
            </Typography>
            <Typography
              variant="body2"
              className={[
                classes.noMargin,
                classes.courseField,
                classes.textAlignRight,
                classes.width10p,
                classes.fontDMSans
              ].join(" ")}
            >
              {item.hours.toFixed(1)}
            </Typography>
          </Box>
          <Box className={classes.courseSubRow}>
            <Box
              className={[
                classes.courseSubFieldContainer,
                classes.width40p
              ].join(" ")}
            >
              {CONTENT_AREA[item.contentAreaId].icons.mainCustom(15, CONTENT_AREA[item.contentAreaId].iconFill)}
              <Typography variant="body2" className={[classes.marginLeft2, classes.courseSubField, classes.fontDMSans].join(" ")}>
                {CONTENT_AREA[item.contentAreaId].name}
              </Typography>
            </Box>
            <Box
              className={[
                classes.courseSubFieldContainer,
                classes.width40p
              ].join(" ")}
            >
              <Typography variant="body2" className={[classes.courseSubField, classes.fontDMSans].join(" ")}>{item.lastDate}</Typography>
            </Box>
            <Box
              className={[
                classes.courseSubFieldContainer,
                classes.contentAlignRight,
                classes.width10p
              ].join(" ")}
            >
              {item.completed ? (
                <Typography variant="body2" className={[classes.courseSubField, classes.fontDMSans].join(" ")}>{item.score}</Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>
      ))}
      <Box className={classes.seperatorSubSpace} />
    </React.Fragment>
  );

  const renderEnrichment = obj => (
    <React.Fragment>
      <Box className={classes.tableHeader}>
        <Typography className={[classes.width40p, classes.semiBold, classes.fontDMSans].join(" ")}>Item</Typography>
        <Box className={[classes.width40p, classes.tableMidColumnContainer].join(" ")}>
          <Typography className={[classes.width50p, classes.semiBold, classes.fontDMSans].join(" ")}>
            Last Accessed
          </Typography>
          <Typography
            className={[
              classes.width50p,
              classes.textAlignCenter,
              classes.semiBold,
              classes.fontDMSans
            ].join(" ")}
          >
            Source
          </Typography>
        </Box>
        <Typography
          className={[
            classes.width10p,
            classes.textAlignRight,
            classes.semiBold,
            classes.fontDMSans
          ].join(" ")}
        >
          Hours*
        </Typography>
      </Box>
      <Box className={classes.tableSubHeader}>
        <Typography className={[classes.bold, classes.fontDMSans].join(" ")}>{obj.titleName}</Typography>
        <Typography className={[classes.bold, classes.fontDMSans].join(" ")}>{toFixed(obj.hours, 1)}</Typography>
      </Box>
      {obj.children.map(item => (
        <Box key={item.id} className={classes.courseItem}>
          <Box className={classes.courseRow}>
            <Typography
              variant="body2"
              className={[
                classes.noMargin,
                classes.courseField,
                classes.width40p,
                classes.fontDMSans
              ].join(" ")}
            >
              {item.titleName}
            </Typography>
            <Box
              className={[
                classes.courseField,
                classes.width40p,
                classes.tableMidColumnContainer,
                classes.fontDMSans
              ].join(" ")}
            >
              <Typography variant="body2" className={[classes.noMargin, classes.width50p, classes.fontDMSans].join(" ")}>
                {item.lastDate}
              </Typography>
              <Typography
                variant="body2"
                className={[
                  classes.noMargin,
                  classes.textAlignCenter,
                  classes.width50p,
                  classes.fontDMSans
                ].join(" ")}
              >
                {item.serviceName}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              className={[
                classes.noMargin,
                classes.courseField,
                classes.textAlignRight,
                classes.width10p,
                classes.fontDMSans
              ].join(" ")}
            >
              {item.hours.toFixed(1)}
            </Typography>
          </Box>
        </Box>
      ))}
    </React.Fragment>
  );

  const renderCategory = id => (
    <Box className={classes.categoryItem}>
      <Box className={classes.categoryIconFieldContainer}>
        {CONTENT_AREA[id].icons.mainCustom(24, CONTENT_AREA[id].iconFill)}
        <Typography
          variant="body2"
          className={[
            classes.semiBold,
            classes.categoryField,
            classes.marginLeft10,
            classes.fontDMSans
          ].join(" ")}
        >
          {CONTENT_AREA[id].name}
        </Typography>
      </Box>
      <Typography className={[classes.semiBold, classes.categoryField, classes.fontDMSans].join(" ")}>
        {categoriesHours[id].toFixed(1)}
      </Typography>
    </Box>
  );

  const renderCategories = () => (
    <React.Fragment>
      <Box className={classes.tableHeader}>
        <Typography variant="h5" className={[classes.semiBold, classes.fontDMSans].join(" ")}>Totals Per Category</Typography>
        <Typography variant="h5" className={[classes.semiBold, classes.fontDMSans].join(" ")}>Hours</Typography>
      </Box>
      <Box className={classes.categoryBox}>
        {renderCategory(CONTENT_AREAS_ENUM.Education)}
        <Box className={classes.categorySeperator} />
        {renderCategory(CONTENT_AREAS_ENUM.Rehab)}
        <Box className={classes.categorySeperator} />
        {renderCategory(CONTENT_AREAS_ENUM.Workforce)}
        <Box className={classes.categorySeperator} />
        {renderCategory(CONTENT_AREAS_ENUM.ReEntry)}
        <Box className={classes.categorySeperator} />
        {renderCategory(CONTENT_AREAS_ENUM.Enrichment)}
      </Box>
    </React.Fragment>
  );

  const renderBody = () => (
    <Grid className={classes.reportBody}>
      <Grid className={classes.reportLeftPane}>
        {transcriptListDtos.map((item, index) => {
          return (
            <React.Fragment key={item.id}>
              {(index === 0 && item.titleName !== "Enrichment") ? renderCourseTableHeader() : null}
              {item.titleName === "Enrichment"
                ? renderEnrichment(item)
                : renderCourseTable(item)}
            </React.Fragment>
          );
        })}
      </Grid>
      <Grid className={classes.reportRightPane}>{renderCategories()}</Grid>
    </Grid>
  );

  const renderFooter = () => (
    <React.Fragment>
      <Typography variant="body2" className={[classes.footerNote, classes.fontDMSans].join(" ")}>
        *Note: Hours may overlap. Not all activities are counted in the hours
        total.
      </Typography>
    </React.Fragment>
  );

  return (
    <Box 
    boxShadow={3}
    width="900px"
    margin="0 auto"
    maxWidth="100%"
    className={classes.root}>
      {renderHeader()}
      {renderBody()}
      {renderFooter()}
    </Box>
  );
}
