import React, { useState, useRef } from 'react';
import { VoiceCallService } from '../../../../services/voice-call.service';
import moment from 'moment-timezone';
import { formatDatetime, getSelectedLocationTimeZone } from '../../../../utils/utils';
import { DATE_FORMAT } from '../../../../../../constants';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';

const useKeywordDetailHook = ({ classes, keywordDictionaryId, selectedKeyword, onError }) => {
  const [isDetailModalOpen, setDetailModalState] = useState(false);
  const [callDetails, setCallDetails] = useState({});
  const [recordingUrl, setRecordingUrl] = useState('');
  const [transcriptData, setTranscriptData] = useState([]);
  const [detectedKeywords, setDetectedKeywords] = useState([]);
  const [isPrivileged, setIsPrivileged] = useState(false);
  const [transcriptSummary, setTranscriptSummary] = useState({
    main_topics: '',
    inappropriate_violent_themes: [],
  });
  const voiceCallService = new VoiceCallService();

  const tableRef = useRef();
  const tableColumnsRef = useRef([
    {
      title: 'Resident Name',
      field: 'residentUserName',
      sorting: true,
    },
    {
      title: 'DOC ID',
      field: 'docId',
      sorting: true,
    },
    {
      title: 'Contact Name',
      field: 'contactName',
      sorting: true,
    },
    {
      title: 'Phone Number',
      field: 'contactPhoneNumber',
      sorting: true,
    },
    {
      title: 'Call End Time',
      field: 'callEndTime',
      sorting: true,
      type: 'date',
      render: (rowData) => {
        if (rowData.callEndTime === null) {
          return '';
        }
        return formatDatetime(rowData.callEndTime);
      },
    },
    {
      title: '',
      render: (rowData) => (
        <IconButton onClick={() => onDetailsClick(rowData)}>
          <VisibilityIcon className={classes.visibilityIcon}/>
        </IconButton>
      ),
    },
  ]);

  const onDetailsClick = async (keywordData) => {
    setRecordingUrl('');
    setTranscriptData([]);
    setTranscriptSummary({
      main_topics: '',
      inappropriate_violent_themes: [],
    });

    const { callMeetingId } = keywordData;
    setDetailModalState(true);
    try {
      const { preSignedURLAudioRecording, preSignedURLTranscript, privileged, keywords, voiceCallInfoResponse } =
        await voiceCallService.getVoiceCallDetails(callMeetingId);
      setCallDetails(voiceCallInfoResponse);
      setDetectedKeywords(keywords);
      setIsPrivileged(privileged);
      if (!privileged) {
        setRecordingUrl(preSignedURLAudioRecording);
        getTranscriptData(preSignedURLTranscript);
      }
    } catch (error) {
      console.error('Error fetching call details', error);
      onError('Error fetching call details', 'error');
    }
  };

  const getTranscriptData = async (preSignedURLTranscript) => {
    const transcriptData = await voiceCallService.getVoiceCallTranscription(preSignedURLTranscript);
    if (transcriptData) {
      setTranscriptData(transcriptData);
      setTranscriptSummary({
        main_topics: transcriptData.summary?.main_topics || '',
        inappropriate_violent_themes: transcriptData.summary?.inappropriate_violent_themes || [],
      });
    }
  };

  const getKeywordDetails = async (query) => {
    const { page, pageSize, orderBy, orderDirection } = query;
    const filters = query?.filters?.reduce((acc, filter) => {
      acc[filter?.column?.field] =
        filter?.column?.field === 'callEndTime'
          ? moment.tz(filter.value, getSelectedLocationTimeZone()).utc().format(DATE_FORMAT.YYYY_MM_DD)
          : filter?.value;
      return acc;
    }, {});

    return new Promise((resolve, reject) => {
      voiceCallService
        .getKeywordDetails({
          dictionaryId: keywordDictionaryId,
          keyword: selectedKeyword,
          pageNumber: page,
          pageSize: pageSize,
          sortBy: orderBy?.field || 'callEndTime',
          sortOrder: orderDirection || 'DESC',
        }, filters)
        .then((data) => {
          const { content, pageable, totalElements } = data;
          resolve({
            data: content,
            page: pageable.pageNumber,
            totalCount: totalElements,
          });
        })
        .catch((error) => {
          reject(error);
          console.error('Error while fetching keyword details', error);
          onError('Error while fetching keyword details', 'error');
        });
    });
  };

  return {
    tableRef,
    tableColumnsRef,
    getKeywordDetails,
    isDetailModalOpen,
    handleCloseHistoryDetailsModal: () => setDetailModalState(false),
    callDetails,
    recordingUrl,
    isPrivileged,
    transcriptData,
    transcriptSummary,
    detectedKeywords,
  };
};

export default useKeywordDetailHook;
