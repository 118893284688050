import { useEffect, useState } from 'react';
import { VOICE_CALLS_FEATURE_KEY, VOICE_CALLS_FEATURE_NAME, VOICE_CALLS_LOADER } from '../../../../v2/services/constants';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import {
  getManagementAttributes,
  getFeatures,
  getSchedule,
  updateFeatures,
  updateSchedule,
} from '../../../../util/APIUtils';
import moment from 'moment';
export const useVoiceCallSettingsHook = (locationId) => {
  const [isLoading, setLoading] = useState(false);
  const [schedules, setVoiceCallsSchedules] = useState([]);
  const [allSchedules, setAllSchedules] = useState([]);
  const [voiceCallStatus, setStatus] = useState(false);
  const [attributeEnabledState, setAttributeEnabledState] = useState({});
  const [keywordToggle, setKeywordToggle] = useState(false);
  const toggleAttributeEnabled = (attributeKey, isEnabled) => {
    setAttributeEnabledState((prevState) => ({
      ...prevState,
      [attributeKey]: isEnabled,
    }));
  };

  const [maxMinutesAllowedPerCallAttribute, setMaxMinutesAllowedPerCallAttribute] = useState({
    attributeId: 0,
    attributeName: VOICE_CALL.MAX_MINUTES_ALLOWED_PER_CALL_ATTRIBUTE_KEY,
    attributeValue: 0,
  });

  const [maxMinPerDayPerLearnerAttribute, setMaxMinPerDayPerLearnerAttribute] = useState({
    attributeId: 0,
    attributeName: VOICE_CALL.MAX_MINUTES_ALLOWED_PER_DAY_PER_LEARNER_ATTRIBUTE_KEY,
    attributeValue: 0,
  });

  const attributeKeys = [
    {
      key: VOICE_CALL.MAX_MINUTES_ALLOWED_PER_CALL_ATTRIBUTE_KEY,
      stateSetter: setMaxMinutesAllowedPerCallAttribute,
      stateName: 'maxMinutesAllowedPerCallAttribute',
    },
    {
      key: VOICE_CALL.MAX_MINUTES_ALLOWED_PER_DAY_PER_LEARNER_ATTRIBUTE_KEY,
      stateSetter: setMaxMinPerDayPerLearnerAttribute,
      stateName: 'maxMinPerDayPerLearnerAttribute',
    },
  ];
  useEffect(() => {
    getScheduleData();
    getScheduleStatus();
    getVoiceCallSettingsAttributes();
  }, [locationId]);

  const getScheduleData = async () => {
    try {
      setVoiceCallsSchedules([]);
      setAllSchedules([]);
      setLoading(true);
      const response = await getSchedule(locationId);
      if (response && response.schedules && Array.isArray(response.schedules)) {
        const voiceCallSchedules = response.schedules.find((schedule) => schedule.featureKey === VOICE_CALLS_FEATURE_KEY);

        if (voiceCallSchedules && voiceCallSchedules.schedules) {
          const schedulesData = voiceCallSchedules.schedules;
          if (schedulesData && Array.isArray(schedulesData)) {
            setVoiceCallsSchedules(
              schedulesData.map((schedule) => {
                schedule.slots = schedule.slots.map((slot) => {
                  slot.startTimePickerOpen = false;
                  slot.endTimePickerOpen = false;
                  slot.startTime = slot.startTime.split('.')[0];
                  slot.endTime = slot.endTime.split('.')[0];
                  return slot;
                });
                return schedule;
              })
            );
          }
          setAllSchedules(response.schedules);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getScheduleStatus = async () => {
    const features = await getFeatures(locationId);
    if (features && features.locationFeatures && Array.isArray(features.locationFeatures)) {
      const voiceCallFeature = features.locationFeatures.find((feature) => feature.featureKey === VOICE_CALLS_FEATURE_KEY);
      if (voiceCallFeature) {
        setStatus(voiceCallFeature.status);
      }
    }
  };

  const updateSettingsState = async () => {
    try {
      const payload = {
        locationId: locationId,
        locationFeatures: [
          {
            featureKey: VOICE_CALLS_FEATURE_KEY,
            featureName: VOICE_CALLS_FEATURE_NAME,
            locationKey: locationId,
            status: !voiceCallStatus,
          },
        ],
      };
      await updateFeatures(payload);
      getScheduleStatus();
    } catch (error) {
      console.log(error);
    }
  };

  const postScheduleData = async (newSchedules) => {
    try {
      const features = [...allSchedules];
      features.splice(
        features.findIndex((item) => item.featureKey === VOICE_CALLS_FEATURE_KEY),
        1
      );
      features.push({
        featureKey: VOICE_CALLS_FEATURE_KEY,
        featureName: VOICE_CALLS_FEATURE_NAME,
        schedules: newSchedules,
      });

      features.map((_feature) => {
        _feature.schedules = _feature.schedules.map((_schedule) => {
          _schedule.slots = _schedule.slots.map((_slot) => {
            return formatTime(_slot);
          });
          return _schedule;
        });
        return _feature;
      });
      await updateSchedule({
        locationId: locationId,
        locationSchedule: features,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateSchedulesData = (featureName, newScheduleCollection) => {
    if (newScheduleCollection && newScheduleCollection.length) {
      setVoiceCallsSchedules([...newScheduleCollection]);
      postScheduleData(newScheduleCollection);
    }
  };

  const formatTime = (slot) => {
    slot.startTime = moment(slot.startTime).format('YYYY-MM-DDTHH:mm:ss');
    slot.endTime = moment(slot.endTime).format('YYYY-MM-DDTHH:mm:ss');
    return slot;
  };

  const getVoiceCallSettingsAttributes = async () => {
    attributeKeys.forEach(({ stateSetter, key }) => {
      stateSetter({
        attributeId: 0,
        attributeName: '',
        attributeValue: 0,
      });
      toggleAttributeEnabled(key, false);
    });

    const managementAttributes = await getManagementAttributes(locationId);
    if (
      managementAttributes &&
      managementAttributes.locationAttributes &&
      Array.isArray(managementAttributes.locationAttributes)
    ) {
      managementAttributes.locationAttributes.forEach((attribute) => {
        const matchedAttribute = attributeKeys.find((key) => key.key === attribute.attributeName);
        if (matchedAttribute) {
          matchedAttribute.stateSetter((prevState) => ({
            ...prevState,
            attributeId: attribute.attributeId,
            attributeName: attribute.attributeName,
            attributeValue: attribute.attributeValue,
          }));
          toggleAttributeEnabled(matchedAttribute.key, true);
        }
      });
    }
  };

  const openKeywordsDictionary = () => {
    setKeywordToggle(true);
  };
  const closeKeywordsDictionary = () => {
    setKeywordToggle(false);
  };

  return {
    isLoading,
    schedules,
    voiceCallStatus,
    maxMinutesAllowedPerCallAttribute,
    setMaxMinutesAllowedPerCallAttribute,
    maxMinPerDayPerLearnerAttribute,
    setMaxMinPerDayPerLearnerAttribute,
    updateSchedulesData,
    updateSettingsState,
    attributeEnabledState,
    keywordToggle,
    openKeywordsDictionary,
    closeKeywordsDictionary,
  };
};
