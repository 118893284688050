import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Link} from 'react-router-dom'

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});


const UserTypes = [
    {id: 0,
    name: 'Super Admin',
    description: 'Manage all settings, users, locations and programming.',
    users: ['create','edit','view','delete'],
    locations: ['create', 'edit','view','delete'],
    tablets: ['create', 'edit','view','delete'],
    programs: ['create', 'edit','view','delete'],
    system: ['create', 'edit','view','delete']
    },
    {id: 1,
    name: 'Admin',
    description: 'Can view and edit users, locations, and tablets.',
    users: ['edit','view'],
    locations: ['edit','view'],
    tablets: ['edit','view'],
    programs: ['edit','view'],
    system: ['view']
    },

]



class UserSettings extends Component {
  state = {
    expanded: null,
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    const UserRoles = UserTypes.map((role)=>
        <ExpansionPanel key={role.id} expanded={expanded === role.name} onChange={this.handleChange(role.name)}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{role.name}</Typography>
                <Typography className={classes.secondaryHeading}>{role.description}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container spacing={24}>
                    Details:
                    <Grid justify="flex-start"  container spacing={24}>
                        <Grid item xs={2}><Button>Users: </Button></Grid>
                        <Grid item xs={10}>
                        {role.users.map((_users)=><Button key={_users} >{_users}</Button>)}
                        </Grid>
                    </Grid>

                    <Grid justify="flex-start"  container spacing={24}>
                        <Grid item xs={2}><Button>Locations: </Button></Grid>
                        <Grid item xs={10}>
                        {role.locations.map((_locations)=><Button key={_locations}>{_locations}</Button>)}
                        </Grid>
                    </Grid>

                    <Grid justify="flex-start"  container spacing={24}>
                        <Grid item xs={2}><Button>Tablets: </Button></Grid>
                        <Grid item xs={10}>
                        {role.tablets.map((_tablets)=><Button key={_tablets}>{_tablets}</Button>)}
                        </Grid>
                    </Grid>

                    <Grid justify="flex-start"  container spacing={24}>
                        <Grid item xs={2}><Button>Programs: </Button></Grid>
                        <Grid item xs={10}>
                        {role.programs.map((_programs)=><Button key={_programs}>{_programs}</Button>)}
                        </Grid>
                    </Grid>

                    <Grid justify="flex-start"  container spacing={24}>
                        <Grid item xs={2}><Button>System: </Button></Grid>
                        <Grid item xs={10}>
                        {role.system.map((_system)=><Button key={_system}>{_system}</Button>)}
                        </Grid>
                    </Grid>
                </Grid>

            </ExpansionPanelDetails>
        </ExpansionPanel>)


    return (
      <div className={classes.root}>
        <h2>User Settings</h2>
        <div style={{float:'right', marginBottom:'2rem', marginRight:'2rem'}}>
            <Link to='/staff'><Button>Manage Staff</Button></Link>
            <Link to='/users'><Button>Manager Learners</Button></Link>
        </div>
        <div style={{padding:'1.5rem', marginTop:'5rem'}}>
            {UserRoles}
        </div>
      </div>
    );
  }
}

UserSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserSettings);
