import React from 'react';
import { Box } from '../../../../../shared/components/MaterialUIComponents';

export const RecipientView= ({classes, value}) => {
  return (
      <Box id="from-wrapper" className={classes.formGroup}>
          <Box className={classes.formLabel} component={'label'}>
          From:
          </Box>
          <Box>
              <Box
                  id="from-input"
                  value={value}
                  readonly
                  component={'input'}
                  type="text"
                  className={classes.formField}
              />
          </Box>
      </Box>
  )
}