import React from "react";

const BooksIcon = props => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-Books">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Books" clipPath="url(#clip-Books)">
        <g id="Group_1304" data-name="Group 1304" transform="translate(-2 -1.926)">
          <path id="Fill_1" data-name="Fill 1" d="M4.947,21.646H2.473A2.443,2.443,0,0,1,0,19.241V2.4A2.443,2.443,0,0,1,2.473,0H4.947A2.443,2.443,0,0,1,7.422,2.4V19.241A2.443,2.443,0,0,1,4.947,21.646ZM2.473,2.4V19.241H4.947V2.4Z" transform="translate(8.185 3.214)" fill={fill}/>
          <path id="Fill_2" data-name="Fill 2" d="M4.947,21.646H2.473A2.443,2.443,0,0,1,0,19.241V2.4A2.443,2.443,0,0,1,2.473,0H4.947A2.443,2.443,0,0,1,7.422,2.4V19.241A2.443,2.443,0,0,1,4.947,21.646ZM2.473,2.4V19.241H4.947V2.4Z" transform="translate(2 3.214)" fill={fill}/>
          <path id="Fill_3" data-name="Fill 3" d="M10.921,2.427H0V0H10.921Z" transform="translate(3.213 13.926)" fill={fill}/>
          <path id="Fill_4" data-name="Fill 4" d="M10.921,2.427H0V0H10.921Z" transform="translate(3.213 17.566)" fill={fill}/>
          <path id="Fill_5" data-name="Fill 5" d="M6.823,21.893a2.43,2.43,0,0,1-2.343-1.8L.083,3.684A2.431,2.431,0,0,1,1.8.711L4.143.083A2.426,2.426,0,0,1,7.116,1.8l4.4,16.409A2.432,2.432,0,0,1,9.8,21.182l-2.345.628A2.432,2.432,0,0,1,6.823,21.893ZM4.771,2.428h0l-2.344.628,4.4,16.409,2.344-.628L4.771,2.428Z" transform="translate(14.404 2.979)" fill={fill}/>
          <path id="Fill_6" data-name="Fill 6" d="M5.317,2.344.628,3.6,0,1.256,4.689,0Z" transform="translate(17.858 13.297)" fill={fill}/>
          <path id="Fill_7" data-name="Fill 7" d="M5.317,2.344.628,3.6,0,1.256,4.689,0Z" transform="translate(18.8 16.814)" fill={fill}/>
        </g>
      </g>
    </svg>
  );
};

BooksIcon.defaultProps = {
  size: 24,
  fill: "inherit"
}

export default BooksIcon;
