import React, { Component } from 'reactn';
import {
  withStyles,
  Typography,
  Box,
  Card,
  CardContent,
  Tooltip
} from '@material-ui/core';
import { Accordion, Loader } from '../../..';
import styles from '../index.style'
import { CustomCheckbox } from '../../../../custom-checkbox';
import { getManagementAttributes, getUserAttributes, updateUserAttributes } from '../../../../../util/APIUtils';
import { ATTRIBUTE_NAMES } from '../../../../../constants';

export class BiometricAuthentication extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      frStatus: true,
      frAttribute: {},
      tooltipText: "Biometric authentication is disabled for this learner's location.",
      isDisabled: false
    }
  }

  componentDidMount() {
    this.fetchAttributes()
  }

  fetchAttributes = () => {
    const userAttributes = this.props.userAttributes
    const userLevelFR = (userAttributes || []).find((item) => item.attributeName === ATTRIBUTE_NAMES.FACIAL_RECOGNITION)
    let frAttribute = {
      attributeName: 'Facial Recognition',
      attributeValue: '1'
    }
    if (userLevelFR) {
      frAttribute = userLevelFR
    }
    this.setState({
      frStatus: (frAttribute.attributeValue === "1"),
      frAttribute: frAttribute
    })
  }

  handleChange = () => {
    this.setState({ showLoader: true })
    let attributeValue = this.state.frStatus ? "0" : "1"
    let attribute = { ...this.state.frAttribute, attributeValue }
    let putBody = {
      personId: this.props.userId,
      personAttributes: [attribute]
    }
    updateUserAttributes(putBody)
      .then(updated => {
        this.setState({ showLoader: false, frStatus: (attributeValue === "1") })
      })
      .catch(e => {
        this.setState({ showLoader: false })
        console.log(e)
      })
  }

  render() {
    const { classes } = this.props
    const { isDisabled, tooltipText, frStatus } = this.state
    return (
      <Accordion id="fr-accordion" title="Biometric Authentication">
        <Card variant="outlined" className={[classes.outlinedComponent, isDisabled ? classes.disableFeature : ""].join(" ")}>
          <CardContent
            classes={{
              root: [classes.settingsRoot, classes.noPaddBottom].join(" ")
            }}
          >
            <Box
              component="div"
              justifyContent="space-between"
              className={[classes.level1Box, classes.noBorderBottom].join(" ")}
              display="flex"
            >
              <Box
                component="div"
                textAlign="left"
              >
                <Typography className={classes.boxHeading1}>
                  {"Biometric Authentication Required For Login"}
                </Typography>
                <Typography className={classes.subHeading1}>
                  {"If turned off, this learner will not be prompted to register for Biometric Authentication and will not be required to complete Biometric Authentication to log in to the Learner Portal."}
                </Typography>
              </Box>
              <Tooltip title={isDisabled ? tooltipText : ""}>
                <Box
                  display="flex"
                  alignItems="center"
                  component="div"
                >
                  {this.state.showLoader && (<Loader />)}
                  <CustomCheckbox
                    id="fr-status-checkbox"
                    className={isDisabled ? classes.cursorNotAllowed : ""}
                    onChange={this.handleChange}
                    checked={frStatus}
                    disabled={isDisabled}
                  />
                </Box>
              </Tooltip>
            </Box>
          </CardContent>
        </Card>
      </Accordion>
    );
  };
}

export default withStyles(styles)(BiometricAuthentication);
