import React from 'react';

export const useRedirectToMessagesHook = (props) => {
  const { isMessagingOnly, location, history } = props;
  const shouldRedirect = isMessagingOnly && location.pathname !== '/messages';
  const MESSAGES_ROUTE = '/messages';
  React.useEffect(() => {
    if (shouldRedirect) {
      history.push(MESSAGES_ROUTE);
    }
  }, [shouldRedirect, history]);

};
