import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import styles from './transcript-pdf.styles';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';
import { VOICE_CALL } from '../../constants/voice-call-constants';
const TranscriptPdf = ({ learnerName, callerName, transcriptData, transcriptSummary, callDuration, dateTime }) => {
  return (
    <Document>
      <Page size="A4">
        <View style={styles.header}>
          <Image
            id="brandLogo"
            src="/assets/logo/orijin-logo-black.png"
            allowDangerousPaths={true}
            alt="apds-logo.png"
            style={styles.brandLogo}
          />
        </View>
        <View style={styles.heading}>
          <Text style={styles.title}>Call Transcript</Text>
        </View>
        <View style={styles.callDetails}>
          <View id="callInfo" style={styles.callInfo}>
            <Text>{learnerName}</Text>
            <Text>{callerName}</Text>
            <Text>{dateTime}</Text>
          </View>

          <View id="callInfoSubFields" style={styles.callInfoSubFields}>
            <Text>Caller</Text>
            <Text>Contact</Text>
            <Text>Duration: {callDuration}</Text>
          </View>
        </View>
        {transcriptSummary && transcriptSummary.main_topics !== '' && (
          <View style={styles.callDetails}>
            <Text style={styles.title}>Summary</Text>
            <Text style={styles.mainTopicsHeading}>Main Topics</Text>
            <Text id="mainTopics" style={styles.mainTopicsText}>
              {transcriptSummary.main_topics}
            </Text>
            {transcriptSummary.inappropriate_violent_themes.length > 0 && (
              <>
                <Text id="inappropriateViolentThemesTitle" style={styles.mainTopicsHeading}>
                  Inappropriate/Violent Themes
                </Text>
                <View id="inappropriateViolentThemes">
                  {transcriptSummary.inappropriate_violent_themes.map((theme, index) => (
                    <Text key={index} style={styles.mainTopicsText}>
                      {theme}
                    </Text>
                  ))}
                </View>
              </>
            )}
          </View>
        )}
        {transcriptData &&
          transcriptData.transcript &&
          transcriptData.transcript.map((message, index) => {
            const userType = message.externalUserId.split('-')[0];
            let displayName = `${VOICE_CALL.TRANSCRIPT_DISPLAY_NAMES[userType]} (${
              userType === 'learner' ? learnerName : callerName
            })`;

            return (
              <View
                id="transcript-message"
                style={[styles.tableRow, index % 2 === 0 ? styles.secondCell : null]}
                key={index}
              >
                <Text id="timestamp" style={styles.timestamp}>
                  {message.timestamp}
                </Text>
                <Text id="externalUserId" style={styles.externalUserId}>
                  {displayName} : {message.transcript}
                </Text>
              </View>
            );
          })}
      </Page>
    </Document>
  );
};

export default TranscriptPdf;
