import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import styles from "./index.styles";
import { fetchRoleTypes } from "../../../util/APIUtils";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => styles(theme));
const RoleTypes = ({ handleRoleTypeChange }) => {
  const classes = useStyles();
  const [role_types, setRoleTypes] = useState([]);
  // below line has no use, remove in the next push
  // const [roleName, setRoleName] = useState("");
  const [role, setRole] = useState("");
  useEffect(() => {
    (async () => {
      try {
        const types = await fetchRoleTypes();
        setRoleTypes(types);
      } catch (error) {}
    })();
  }, []);
  const handleChange = (event) => {
    setRole(event.target.value);
    handleRoleTypeChange(event);
  };

  return (
    <div>
      <FormControl className={classes.selectInput}>
        <InputLabel id="demo-simple-select-label">Role Types</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          labelWidth={20}
          value={role}
          onChange={handleChange}
        >
          {role_types &&
            role_types.map(({ typeDisplayName, roleTypeId }) => (
              <MenuItem key={roleTypeId} value={roleTypeId}>
                {typeDisplayName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default RoleTypes;
