import React from 'reactn';
import {
    Box,
    Button,
    Chip,
    Grid,
    Typography,
    withStyles,
    Dialog,
    AppBar,
    Toolbar,
    IconButton
} from "@material-ui/core";
import styles from './meta-data.style';
import CloseIcon from '@material-ui/icons/Close';
import ReactJson from 'react-json-view';

const MetaDataComponent = (props) => {
    const {classes} = props;
    return (
        <Dialog open={props.isOpen} onClose={() => {
            props.onClose();
        }}>
           <>
                    <AppBar style={{
                        position: 'relative'
                    }}>

                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                marginLeft="15px"
                            >
                                <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" >
                                    Metadata
                            </Typography>
                            </Box>
                        </Box>

                    </AppBar>
                    <Grid
                        container
                        item
                        classes={{
                            root: classes.root
                        }}
                    >
                       <ReactJson
                       collapsed
                       src={props.jsonObject || {}} />

                    </Grid>
                </>
        </Dialog >
    );
};

export const MetaData = withStyles(styles)(MetaDataComponent);