import React from 'reactn';
import { withStyles, Box, Typography, Tooltip, Button, IconButton,Snackbar, Card, CardContent } from '../../../../../v2/shared/components/MaterialUIComponents';
import styles from '../index.style';
import { useResetDeviceHook } from './useResetDeviceHook';
import { Modal } from "@orijinworks/frontend-commons"
import { useRef } from 'react';
import { SNACK_BAR_SUCCESS, SNACK_BAR_TIMEOUT } from '../../../../../v2/services/constants';
import { CustomIcons, icons } from '../../../../../v2/shared/components/custom-icons';
import MuiAlert from '@material-ui/lab/Alert';
import { Accordion, Loader } from '../../..';

const ResetDevicePairing = props => {
  const { classes, learnerName, isFlagEnabled } = props;
  let featureData = { classes: [classes.level1Box, props.isSuperAdmin ? '' : classes.noBorderBottom], tooltipText: '' };
  const modalRef = useRef(null);
  const { isResetLoading, resetUserDevicePairing, toggleConfirmationModal,snackBar,setSnackBar, setError, error } = useResetDeviceHook({...props, modalRef});

  const RenderToggle = () => {
    return(
      <Box component="div" justifyContent="space-between" className={featureData.classes.join(' ')} display="flex">
        <Box component="div" textAlign="left">
          <Typography className={classes.boxHeading1}>Reset a learner's paired device</Typography>
          <Typography className={classes.subHeading1}>
            This will reset this learner's tablet association. The learner will then be paired with the next Orijin tablet they log into.
          </Typography>
        </Box>
        <Tooltip title={featureData.tooltipText}>
          <Box display="flex" alignItems="center" component="div">
            <Button id="reset-device-btn" onClick={toggleConfirmationModal} variant="outlined" disabled={isResetLoading}>
              {isResetLoading && (
                <>
                  <Loader /> &nbsp;
                </>
              )}
              Reset
            </Button>
          </Box>
        </Tooltip>
      </Box>
    );
  }

  return (
    <>

      <Modal
        ref={modalRef}
        isSnackBarShown={false}
        cancelBtnText="Cancel"
        actionBtnText="Reset"
        id="confirmation-modal"
        labelledBy="test"
        showCrossIcon={false}
        onClose={() => toggleConfirmationModal()}
        onContinue={() => resetUserDevicePairing()}
        modalType={{ type: 'confirmation', status: 'danger' }}
        Icon={<CustomIcons icon={icons.TabletAndroidIcon} />}
        styles={{
          mainWrapperStyles: { height: 'auto' },
        }}
      >
        <>
        {error &&
          <Box id="error-box" className={classes.errorWrapper}>
            <Box
              display="flex"
              alignItems={"center"}
              flex={1}>
              <CustomIcons icon={icons.ErrorIcon} className={classes.errorIcon} />
              <Typography>{error}</Typography>
            </Box>
            <Box>
              <IconButton onClick={() => setError('')}>
                <CustomIcons icon={icons.CloseIcon} className={classes.closeIcon} />
              </IconButton>
            </Box>
          </Box>
        }
          <Typography className={classes.noCapitalizeText} variant="h5">
            Are you sure you want to reset this learner's paired device?
          </Typography>

          <Typography className={classes.bodyMargin} variant="body1">
            They will be paired to a new device on their next login to the Learner Portal.
          </Typography>
        </>
      </Modal>
        {!isFlagEnabled ? 
          <Accordion id="accordion" title={'Device Pairing'}>
            <Card variant="outlined" className={classes.outlinedComponent}>
              <CardContent
                  classes={{
                    root: [classes.settingsRoot, classes.noPaddBottom].join(' ')
                  }}
                >
                  <RenderToggle />
              </CardContent>
            </Card>
          </Accordion>
        :
          <RenderToggle/>
        }


        <Snackbar
            open={!!snackBar}
            autoHideDuration={SNACK_BAR_TIMEOUT}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={() => setSnackBar(false)}
          >
            <MuiAlert severity={SNACK_BAR_SUCCESS} elevation={6} variant="filled">
              Paired device reset for {learnerName}.
            </MuiAlert>
        </Snackbar>
      </>
  );
};

export default withStyles(styles)(ResetDevicePairing);
