import React from "react";
import {
  Dialog,
  Grid,
  Typography,
  withStyles,
  Box
} from "@material-ui/core";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close';
import styles from "./index.styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import { getNameInitials } from '../common';
import { CustomIcons, icons } from "../../../../shared/components/custom-icons/CustomIcons";


const getIcon = (roleName, classes) => {
  switch (roleName) {
    case "Learner":
      return <CustomIcons icon={icons.UserIcon} />;

    default:
      return <CustomIcons icon={icons.VerifiedUserIcon} className={classes.verifiedIcon} />;
  };
};

const ViewAnnouncementDetail = props => {
  const [MAX_ITEM, setMaxItem] = React.useState(100);
  const { classes, closeModal, announcementDetail, location } = props;
  if (!announcementDetail)
    return ''

  const locationName = location && location.locationName ? location.locationName : '';
  return (
    <Dialog open={props.isDialogOpen} classes={{
      paper: classes.paper
    }}>
      <Grid
        container
        item
        className={classes.mainDialog}
      >
        {/* BEGIN: Header */}
        <Box container item className={classes.headerContainer}>
          <Typography className={classes.headerHeading} >
            Read Receipts
          </Typography>
          <IconButton
            onClick={() => {
              closeModal(true);
            }}
            className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>

        {
          /**
           * General Info Section
           */
        }

        <Box
          className={classes.singleMessage}>
          <Box className={classes.verifiedIconWrapper}>
            {getIcon(announcementDetail.senderPersonType, classes)}
          </Box>
          <Box className={classes.readReceiptDetails}>
            <Typography className={classes.mainHeading}>
              {announcementDetail.senderPersonName}
            </Typography>
            <Typography className={classes.subHeading}>
              {moment.parseZone(announcementDetail.createdDate).format('LT')}
            </Typography>
          </Box>
        </Box>
        <Box
          width="100%"
          className={[classes.margin].join(" ")}>
          <Typography className={classes.detailText}>
            <b>{announcementDetail.readCount} of {(announcementDetail.readCount || 0) + (announcementDetail.unreadCount || 0)}</b> Learner Read Your Message
          </Typography>
        </Box>
        {/* End General Info Section */}
        <Box className={classes.scrollable}
          onScroll={(e) => {
            const { scrollHeight, scrollTop, clientHeight } = e.target;
            if ((scrollHeight - (scrollTop + clientHeight)) < 500) {
              setMaxItem(MAX_ITEM + 100);
            }
          }}
        >
          <Box className={classes.section}>
            <Box >
              <Typography className={classes.sectionHeading}>
                READ
              </Typography>
            </Box>
            {/* Start Divider */}
            <Box className={classes.divider} />
            {/* End Divider */}
            <Box className={classes.sectionBody}>
              {announcementDetail.announcementUsersList
                .filter(
                  (item) => item.read
                )
                .splice(0, MAX_ITEM)
                .map(
                  (item) => (
                    <Box className={classes.sectionInfo}>
                      <CustomIcons icon={icons.CheckIcon} className={classes.sectionIcon} />
                      {item.userName}
                    </Box>
                  )
                )}
            </Box>
          </Box>
          <Box className={classes.section}>
            <Box >
              <Typography className={classes.sectionHeading}>
                UNREAD
              </Typography>
            </Box>
            {/* Start Divider */}
            <Box className={classes.divider} />
            {/* End Divider */}
            <Box className={classes.sectionBody}>
              {announcementDetail.announcementUsersList
                .filter(
                  (item) => !item.read
                )
                .splice(0, MAX_ITEM)
                .map(
                  (item) => (
                    <Box
                      key={item.userName}
                      className={[classes.sectionInfo, classes.inactive].join(" ")}>
                      <CustomIcons icon={icons.CloseIcon} className={classes.sectionIcon} />
                      {item.userName}
                    </Box>
                  )
                )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default React.memo(withStyles(styles)(ViewAnnouncementDetail));
