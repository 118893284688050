import React from "react";

const EntertainmentIcon = props => {
  const { size, stroke } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1119" data-name="Rectangle 1119" width="25" height="18.236" fill="none" stroke={stroke} strokeWidth="2"/>
        </clipPath>
        <clipPath id="clip-Entertainment_">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Entertainment_" data-name="Entertainment " clipPath="url(#clip-Entertainment_)">
        <g id="Group_1208" data-name="Group 1208" transform="translate(-0.5 2.882)">
          <g id="Group_1207" data-name="Group 1207" clipPath="url(#clip-path)">
            <path id="Path_508" data-name="Path 508" d="M17.589,17.736H28.311a.528.528,0,0,0,.528-.528V12.949H28.8a3.037,3.037,0,0,1,0-6.073h.043V2.528A.528.528,0,0,0,28.311,2H13.571" transform="translate(-5.089 -0.75)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Path_509" data-name="Path 509" d="M36.152,9.8h-.043a3.037,3.037,0,0,0,0,6.073h.043" transform="translate(-12.402 -3.676)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Path_510" data-name="Path 510" d="M13.25,17.736H2.528A.528.528,0,0,1,2,17.208V12.949h.043a3.037,3.037,0,0,0,0-6.073H2V2.528A.528.528,0,0,1,2.528,2H5.48" transform="translate(-0.75 -0.75)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Path_511" data-name="Path 511" d="M2,9.8h.043a3.037,3.037,0,0,1,0,6.073H2" transform="translate(-0.75 -3.676)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <line id="Line_200" data-name="Line 200" x2="5.732" transform="translate(9.634 7.263)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <line id="Line_201" data-name="Line 201" x2="5.732" transform="translate(9.634 7.263)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <line id="Line_202" data-name="Line 202" x2="5.732" transform="translate(9.634 10.972)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

EntertainmentIcon.defaultProps = {
  size: 24,
  stroke: "inherit"
}

export default EntertainmentIcon;
