import { Box, Tooltip } from '@material-ui/core';
import React, { Component } from 'reactn';

class Hoverable extends Component {
    id = Math.random();
    state = {
        isOverflowing: false
    }
    ref = null;

    checkOverflow(el) {
        var curOverflow = el.style.overflow;

        if (!curOverflow || curOverflow === "visible")
            el.style.overflow = "hidden";

        var isOverflowing = el.clientWidth < el.scrollWidth
            || el.clientHeight < el.scrollHeight;

        el.style.overflow = curOverflow;

        return isOverflowing;
    }

    componentDidMount() {
        this.setState({
            isOverflowing: this.checkOverflow(this.ref)
        });
    };

    /**
     * @name getOverflownJSX
     * @desc Prepares over-flown JSX which contains tooltip.
     * @return {JSX}
     */
    getOverflownJSX = () => {
        return (
            <Tooltip title={this.props.title}>
                <Box ref={ref => this.ref = ref} maxWidth="200px" overflow="hidden">
                    {this.props.children}
                </Box>
            </Tooltip>
        );
    };

    /**
     * @name getNormalJSX
     * @desc Prepares normal JSX which doesn't contains tooltip.
     * @return {JSX}
     */
    getNormalJSX = () => {
        return (
            <Box ref={ref => this.ref = ref} maxWidth="200px" overflow="hidden">
                {this.props.children}
            </Box>
        );
    };

    render() {
        if (this.state.isOverflowing) {
            return this.getOverflownJSX()
        } else {
            return this.getNormalJSX();
        };
    };
};

export default Hoverable;