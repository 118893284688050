import React from 'react';
import styles from './insights.style';
import { withStyles, Box, Divider, CircularProgress } from '../../../shared/components/MaterialUIComponents';
import { LearnerDemographic, LearnerPersonality } from '../';
import { useInsights } from './useInsightsHook';
import MilestoneMapper from '../milestone-mapper/milestone-mapper';
import UnleashProvider from '../../../core/unleash/unleashProvider';
import { UnleashService } from '../../../services';

const Insights = props => {
  const { classes } = props;
  const { demographic, personality, getAnswerText, isLoading, fullName } = useInsights(props);
  return (
    <Box
      classes={{
        root: classes.root
      }}
    >
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <LearnerDemographic getAnswerText={getAnswerText} name={fullName} questions={demographic} />
          <Divider className={classes.divider} />
          <LearnerPersonality questions={personality} getAnswerText={getAnswerText} />
          <UnleashProvider flagName={UnleashService.FLAGS.LEARNER_MILESTONE_MAPPER}>
            <MilestoneMapper user={props.user}/>
          </UnleashProvider>
        </>
      )}
    </Box>
  );
};

export default withStyles(styles)(Insights);
