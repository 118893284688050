import React, { Component, Suspense, useEffect } from 'reactn';
import { withUnleash } from '../../../../v2/core/unleash';
import Loader from '../../../loader';
import { ContactsTabComponent, ContactsListComponent } from '../../components';
import { UnleashService } from '../../../../v2/services';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import { fetchJanusToken } from '../../../../util/APIUtils';
import { setJanusToken } from '../../../../constants';
import { useContactsListContainerHook } from './useContactsListContainerHook';
import { Box, IconButton, Paper, Tooltip } from '../../../../v2/shared/components/MaterialUIComponents';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';

const ContactsListContainer = (props) => {
  const { selectedLocation } = props;
  const locationId = selectedLocation ? selectedLocation.locationId : 0;
  const fetchreleventdata = async () => {
    try {
      const janusToken = await fetchJanusToken(locationId);
      setJanusToken(janusToken.token || '');
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchreleventdata();
  }, [locationId]);

  const { activeTab, setActiveTab, renderTable, setDataRefresh, setShouldExportCallHistory } =
    useContactsListContainerHook(locationId);

  return (
    <Suspense fallback={<Loader isLoading={true} />}>
      <Box display="flex" justifyContent="flex-end">
        {!(activeTab === VOICE_CALL.TABS.SETTINGS) && (
          <Paper
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 60,
              margin: 0,
              marginBottom: '-38px',
              marginRight: '10px',
              height: 36,
            }}
          >
            <Tooltip title="Refresh Table">
              <IconButton id="refresh-table" onClick={() => setDataRefresh(true)} color="primary" size="small">
                <CustomIcons icon={icons.RefreshIcon} />
              </IconButton>
            </Tooltip>
          </Paper>
        )}
      </Box>
      {[VOICE_CALL.TABS.CALL_HISTORY, VOICE_CALL.TABS.RESIDENT_MANAGEMENT].includes(activeTab) && (
        <Box display="flex" justifyContent="flex-end">
          <Paper
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 60,
              margin: 0,
              marginBottom: '-38px',
              marginRight: '80px',
              height: 36,
            }}
          >
            <Tooltip title="Export Call History">
              <IconButton onClick={() => setShouldExportCallHistory(true)} color="primary" size="small">
                <CustomIcons icon={icons.ExportIcon} />
              </IconButton>
            </Tooltip>
          </Paper>
        </Box>
      )}
      <ContactsTabComponent activeTab={activeTab} setActiveTab={setActiveTab} />
      {renderTable()}
    </Suspense>
  );
};

export default withUnleash(ContactsListContainer, UnleashService.FLAGS.VOICE_CALL);
