import { useEffect } from "react";
import { useFlag, useFlagsStatus, useUnleashClient, useUnleashContext, useVariant } from "@unleash/proxy-client-react";

/**
 * @name useUnleashHook
 * @param {string} flagName 
 * @param {string} userName 
 * @param {string} userId 
 * @param {object} selectedLocation 
 * @returns {object} - { isFlagEnabled, getClient, getVariant, getFlagsStatus, getUnleashContextSetter }
 */

const useUnleashHook = (flagName, userName, userId = 0, selectedLocation = {}) => {
  const updateContext = useUnleashContext();
  const enabled = useFlag(flagName);
  const unleashClient = useUnleashClient();
  const variant = useVariant(flagName);
  const { flagsReady, flagsError } = useFlagsStatus();

  useEffect(() => {
    if (selectedLocation) {
      updateContext(getContext());
    }
  }, [selectedLocation]);

  const getContext = () => {
    return {
      userId: parseInt(userId),
      username: userName,
      facilityCode: selectedLocation.locationShortCode,
    };
  }

  const isFlagEnabled = () => enabled;
  const getVariant = () => variant;
  const getClient = () => unleashClient;
  const getFlagsStatus = () => ({ flagsReady, flagsError }); 
  const getUnleashContextSetter = () => updateContext;

  return {
    isFlagEnabled,
    getClient,
    getVariant,
    getFlagsStatus,
    getUnleashContextSetter
  }
};

export default useUnleashHook;
