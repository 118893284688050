import 'isomorphic-fetch';


//This little helper provides high-level support to the components that 
//need to perform fetches (particularly for hydrating components with initial data)

export default resourceType => {
    return fetch(`https://swapi.co/api/${resourceType}`)
    .then(res => {
        return res.json();
    })
    .then(data => {
        
        //// consolelog(data)

        return data;
    });
};