import React from 'reactn';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {
  withStyles,
  TextField,
  MenuItem,
  CircularProgress,
  ButtonBase,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Zoom,
  Slide,
  TableRow,
  List,
  ListItem,
  Box
} from '@material-ui/core';
import ReactChartkick, { LineChart, ColumnChart } from 'react-chartkick';
import TOTUSERS from './Test/MockData';
import Datatable from 'material-table';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SortIcon from '@material-ui/icons/Sort';
import FilterListIcon from '@material-ui/icons/FilterList';
import CompareIcon from '@material-ui/icons/Compare';
import UserTotModal from './UserTotModal';
import { Link, withRouter } from 'react-router-dom';
import { gettimeontask, getPermittedLocations, fetchJanusToken } from '../../util/APIUtils';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TimerIcon from '@material-ui/icons/Timer';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { defaultCipherList } from 'constants';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { CustomChart } from '../chart';
import moment from 'moment';
import { DateRangePicker } from 'materialui-daterange-picker';
import { LOCATIONS, TOT_DETAILS_ENUM, setJanusToken, getJanusToken, CONTENT_AREA } from '../../constants';
import TotDetails from '../tot-details';
import Hoverable from '../hoverable';
import { UserIcon } from '../UserIcon';
import { isContentAreaExempted } from '../users/learner/transcript/utils';
import { connect } from 'react-redux';
import { setLocation as selectLocation, setHousingUnit as selectHousingUnit } from '../../v2/core/store/.';
import { theme as AdminTheme } from '../../v2/core/containers/theme';
import { CustomTheme } from '@orijinworks/frontend-commons';

///Styles Constants
const styles = theme => ({
  daysSelectionList: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16
  },
  daysSelectionItem: {
    width: 'initial',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: 40,
    fontSize: 18,
    color: theme.palette.primary.cobalt,
    fontWeight: 'bold',
    '&:first-child': {
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20
    },
    '&:last-child': {
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20
    }
  },
  activeDay: {
    backgroundColor: theme.palette.primary.cobalt,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.cobalt
    }
  },
  root: {
    padding: theme.spacing.unit * 2,
    flexGrow: 1
  },
  appBar: {
    background: 'white'
  },
  timeOnTaskChart: {
    height: 280,
    position: 'relative'
  },
  totBtn: {
    width: '100%'
  },
  summaryCard: {
    height: 280,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center'
  },
  locationCard: {
    height: 210,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center'
  },
  locationHeader: {
    width: '100%',
    flexGrow: 1,
    height: '30%',
    background: theme.palette.background.blue,
    padding: '.5rem'
  },
  locationFooter: {
    width: '100%',
    height: '70%',
    flexGrow: 1
  },
  statisticsContainer: {
    flex: 1,
    backgroundColor: theme.palette.colors.purpleMain, 
    margin: 6,
    color: 'white',
    borderRadius: 4
  },
  timeSpentContainer: {
    width: '100%',
    backgroundColor: theme.palette.colors.purpleMain, 
    margin: 6,
    color: 'white',
    borderRadius: 4
  }
});

const ONE_HOUR = 60 * 60 * 1000;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const tot = TOTUSERS;

class Unit extends React.Component {
  columns = [
    {
      title: 'User Name',
      field: 'name',
      render: rowData => (
        <Hoverable title={rowData.name}>
          <Button
            color="primary"
            onClick={() => {
              this.setGlobal({ selectedUser: rowData.personId, openProfileView: true });
            }}
          >
            <UserIcon style={{ marginRight: 6 }} /> {rowData.name}
          </Button>
        </Hoverable>
      )
    },
    {
      title: 'DOC ID',
      field: 'docid',
      render: rowData => <Hoverable title={rowData.docid}>{rowData.docid}</Hoverable>
    },
    {
      title: 'Current Status',
      field: 'status',
      lookup: { active: 'active', inactive: 'inactive' },
      default: 'active',
      defaultSort: 'asc',
      render: rowData => (
        <Hoverable title={rowData.status}>
          <p style={{ color: rowData.status === 'active' ? 'black' : AdminTheme.palette.text.red }}>{rowData.status}</p>
        </Hoverable>
      )
    },
    {
      title: 'Total Time Spent (hours)',
      field: 'total',
      render: rowData => <Hoverable title={rowData.total.toFixed(2)}>{rowData.total.toFixed(2)}</Hoverable>
    },
    {
      title: 'Education',
      field: 'education',
      render: rowData => <Hoverable title={rowData.education.toFixed(2)}>{rowData.education.toFixed(2)}</Hoverable>
    },
    {
      title: 'Rehabilitation',
      field: 'rehab',
      render: rowData => <Hoverable title={rowData.rehab.toFixed(2)}>{rowData.rehab.toFixed(2)}</Hoverable>
    },
    {
      title: 'Workforce Skills',
      field: 'job',
      render: rowData => <Hoverable title={rowData.job.toFixed(2)}>{rowData.job.toFixed(2)}</Hoverable>
    },
    {
      title: 'Reentry',
      field: 'reentry',
      render: rowData => <Hoverable title={rowData.reentry.toFixed(2)}>{rowData.reentry.toFixed(2)}</Hoverable>
    },
    {
      title: 'Enrichment',
      field: 'enrichment',
      render: rowData => <Hoverable title={rowData.enrichment.toFixed(2)}>{rowData.enrichment.toFixed(2)}</Hoverable>
    },
    {
      title: 'Entertainment',
      field: 'entertainment',
      render: rowData => <Hoverable title={rowData.entertainment.toFixed(2)}>{rowData.entertainment.toFixed(2)}</Hoverable>
    },
    {
      title: 'Miscellaneous',
      field: 'misc',
      render: rowData => <Hoverable title={rowData.misc.toFixed(2)}>{rowData.misc.toFixed(2)}</Hoverable>
    }
  ];
  _chart = new CustomChart();
  state = {
    dateRangePicker: {
      isOpen: false
    },
    dateRange: this.global.selectedDateRange,
    userModalOpen: false,
    isLoading: false,
    isCustom: false,
    totDetailsDialog: {
      isOpen: false
    },
    locationName: null,
    isTablePermitted: false,
    token: ''
  };

  async componentDidMount() {
    if (this.global.cbacConfigFlag) {
      await this.getPermittedLocations();
    } else {
      this.setState({
        isTablePermitted: true
      });
    }

    window.scrollTo(0, 0);
    await this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    this.getData();
    if (
      this.props.selectedHousingUnit &&
      prevProps.selectedHousingUnit !== this.props.selectedHousingUnit &&
      this.props.selectedLocation
    ) {
      // console.log("CHANGED", this.props.location.pathname)
      const { selectedHousingUnit, selectedLocation } = this.props;
      // console.log(prevProps.selectedHousingUnit, selectedHousingUnit)
      this.props.history.push(
        `/outcomes-and-progress/${selectedLocation.locationName
          .split(' ')
          .join('-')
          .replace(/[^a-zA-Z0-9-_]/g, '')}/${selectedLocation.locationId}/${selectedHousingUnit.locationName
          .split(' ')
          .join('-')
          .replace(/[^a-zA-Z0-9-_]/g, '')}/${selectedHousingUnit.locationId}`
      );
      this.setState({
        locData: null
      });
    }
  }

  componentWillUnmount() {
    setJanusToken('');
  }

  getPermittedLocations = async () => {
    const permissionName = 'View_Individualized_Data_At_The_Hu_Level';
    const unitId = this.props.match.params.unitId;
    try {
      const locationId = this.props.match.params.locationId;
      const janusToken = getJanusToken();
      if (!janusToken) {
        const janusObj = await fetchJanusToken(locationId);
        setJanusToken(janusObj.token);
      }
      const units = await getPermittedLocations(locationId, permissionName);
      if (units.includes(parseInt(unitId))) {
        this.setState({ isTablePermitted: true });
      } else {
        this.setState({ isTablePermitted: false });
      }
    } catch (error) {
      this.setState({ isTablePermitted: false });
    }
  };
  getData = () => {
    const { locData, isLoading } = this.state;
    if (locData || isLoading || LOCATIONS.length === 0) {
      return null;
    }
    var myFacilities = [this.selectedUnitId];
    this.setState({
      facilities: myFacilities,
      dateRange: this.global.selectedDateRange
    });
    let locationData = LOCATIONS.filter(l => {
      return l.locationId === this.selectedUnit && l.userDetails;
    }).filter(i => i.dateRange === this.global.selectedDateRange);

    var now = Date.now();
    if (locationData.length > 0 && now - locationData[0].timestamp <= ONE_HOUR * 12) {
      this.createTotViewData(locationData[0].data);
      this.setState({
        locData: locationData[0].data
      });
    } else {
      this.fetchTimeonTask(myFacilities);
    }
  };

  fake__componentWillReceiveProps() {
    var myFacilities = [this.selectedUnitId];
    this.setState({
      facilities: myFacilities,
      dateRange: this.global.selectedDateRange
    });
    let locationData = LOCATIONS.filter(l => {
      return l.locationId === this.selectedUnitId && l.userDetails;
    }).filter(i => i.dateRange === this.global.selectedDateRange);

    var now = Date.now();
    if (locationData.length > 0 && now - locationData[0].timestamp <= ONE_HOUR * 12) {
      this.createTotViewData(locationData[0].data);
      this.setState({
        locData: locationData[0].data
      });
    } else {
      if (!this.state.isLoading) {
        // this.fetchTimeonTask(myFacilities);
      }
      // this.getTimeonTask(myFacilities, this.global.selectedDateRange)
    }
  }

  getDateRangeModelFromInteger = range => {
    var d = new Date();
    var _range = range || this.state.dateRange;
    d.setDate(d.getDate() - _range);
    return {
      startDate: d.toISOString(),
      endDate: new Date().toISOString()
    };
  };

  getTimeonTask = async (fac, range, isDefiniteRange = true) => {
    let pBody = '';
    this.setState({
      locData: null,
      isLoading: true
    });
    let filterDateRange = null;
    if (isDefiniteRange) {
      filterDateRange = this.getDateRangeModelFromInteger(range);
    } else {
      filterDateRange = range;
    }
    this.setGlobal({
      range: filterDateRange
    });
    let postBody = {
      requestorId: 169274,
      requestorLocationId: 1697386,
      filterLocationIds: fac,
      filterDateRange,
      retLearnerDetails: true
    };

    pBody = JSON.stringify(postBody);
    try {
      if (this.global.cbacConfigFlag) {
        await this.getPermittedLocations();
      }
      gettimeontask(postBody).then(data => {
        if (data.error) {
          this.setState({ error: data });
        } else var facilitiesAverages = [];
        var facilityAve = [];
        var locations = data.locations.map(l => {
          facilityAve = [];
          var name = l.locationName;
          var ave = l.totalTimespent;
          facilityAve.push(name, ave);
          facilitiesAverages.push(facilityAve);
          var contentAreasChart = [];
          var arr = [];
          var _arr = [];
          l.contentAreas.map(c => {
            if (isContentAreaExempted(c.contentAreaName)) {
              return;
            }
            _arr = [];
            var name = c.contentAreaName;
            var ave = c.totalTimespent;
            var id = c.contentAreaKey;
            _arr.push(name, ave, id);

            arr.push(_arr);
          });
          l.contentAverageChart = arr;
          data.contentAverageChart = l.contentAverageChart;
        });
        this.genTableData(data.locations[0].userDetails);
        this.genUserChart(data.locations[0].userDetails);
        data.facilityAverageChart = facilitiesAverages;
        var details = this.generateSummaryDetails(data.locations);
        data.totalTimespent = details.totalTimespent;
        data.numberOfUsers = details.numberOfUsers;
        data.averageTimespent = details.averageTimespent;
        this.setState({
          locData: data,
          isLoading: false
        });
        var globalLocationData = {
          locationId: this.selectedUnitId,
          dateRange: this.global.selectedDateRange,
          data: data,
          timestamp: Date.now()
        };
        var _globalDataStore = LOCATIONS;
        _globalDataStore.push(globalLocationData);
        this.setGlobal({
          locationDataStore: _globalDataStore
        });
      });
    } catch (error) {}
  };

  createTotViewData(data) {
    var facilitiesAverages = [];
    var facilityAve = [];
    var locations = data.locations.map(l => {
      facilityAve = [];
      var name = l.locationName;
      var ave = l.totalTimespent;
      facilityAve.push(name, ave);
      facilitiesAverages.push(facilityAve);
      var contentAreasChart = [];
      var arr = [];
      var _arr = [];
      l.contentAreas.map(c => {
        _arr = [];
        var name = c.contentAreaName;
        var ave = c.totalTimespent;
        var id = c.contentAreaKey;
        _arr.push(name, ave, id);

        arr.push(_arr);
      });
      l.contentAverageChart = arr;
    });
    this.genTableData(data.locations[0].userDetails);
    this.genUserChart(data.locations[0].userDetails);
    data.facilityAverageChart = facilitiesAverages;
    var details = this.generateSummaryDetails(data.locations);
    data.totalTimespent = details.totalTimespent;
    data.numberOfUsers = details.numberOfUsers;
    data.averageTimespent = details.averageTimespent;
  }

  genTableData(users) {
    var table = [];
    users.map(u => {
      var _user = {
        personId: u.personId,
        name: u.displayName,
        docid: u.docId,
        id: u.personId,
        enrichment: 0,
        education: 0,
        job: 0,
        misc: 0,
        person_key: 0,
        reentry: 0,
        entertainment: 0,
        rehab: 0,
        total: 0,
        status: u.currentStatusAtFacility
      };
      this._users = _user;
      u.contentAreas.map(c => {
        if (c.contentAreaName === 'Education') {
          this._users.education += c.totalTimespent;
        }
        if (c.contentAreaName === 'Enrichment') {
          this._users.enrichment += c.totalTimespent;
        }
        if (c.contentAreaName === 'Reentry') {
          this._users.reentry += c.totalTimespent;
        }
        if (c.contentAreaName === 'Rehabilitation') {
          this._users.rehab += c.totalTimespent;
        }
        if (c.contentAreaName === 'Miscellaneous') {
          this._users.misc += c.totalTimespent;
        }
        if (c.contentAreaName === 'Workforce Skills') {
          this._users.job += c.totalTimespent;
        }
        if (c.contentAreaName === 'Entertainment') {
          this._users.entertainment += c.totalTimespent;
        }
        this._users.total += c.totalTimespent;
      });
      table.push(_user);
    });
    this.setState({ totUserTableData: table });
  }

  generateSummaryDetails = loc => {
    var totalTimespent = 0;
    var numOfUsers = 0;
    var details = {};
    loc.map(l => {
      totalTimespent += l.totalTimespent;
      numOfUsers += l.numberOfUsers;
    });
    var ave = totalTimespent / numOfUsers;
    details.averageTimespent = ave.toFixed(1);
    details.totalTimespent = totalTimespent.toFixed(2);
    details.numberOfUsers = numOfUsers;
    return details;
  };

  /**
   * @name handleToTDetailsDialog
   * @param {boolean} isOpen
   * @desc Handles time on task dialog open flag.
   * @return {void}
   */
  handleToTDetailsDialog = isOpen => {
    this.setState({
      totDetailsDialog: {
        ...this.state.totDetailsDialog,
        isOpen
      }
    });
  };

  genUserChart(users) {
    var chartData = [];
    users.map(u => {
      var user = { name: u.displayName, data: {} };
      u.contentAreas.map(c => {
        if (isContentAreaExempted(c.contentAreaName)) {
          return;
        }
        var timelist = c.timeOnTaskList.map(t => {
          var date = t.date;
          var time = t.timespent;
          user.data[t.date] = t.timespent;
        });
      });
      chartData.push(user);
    });
    this.setState({ allUsers: chartData });
  }

  handleClose = () => {
    this.setGlobal({
      openUnitView: false
    });
  };

  handleCloseUserModal = () => {
    this.setState({
      userModalOpen: false
    });
  };

  getUnique(arr, comp) {
    //store the comparison  values in array
    const unique = arr
      .map(e => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & return unique objects
      .filter(e => arr[e])
      .map(e => arr[e]);
    return unique;
  }

  filterUser(id) {
    var _tot = TOTUSERS.filter((a, ind) => {
      return a.person_key === id;
    });
    return _tot;
  }

  tableToChart(arr) {
    var newArray = [];
    arr.map(u => {
      var user = {};
      user.name = u.name;
      for (var key in u) {
        // skip loop if the property is from prototype
        if (!u.hasOwnProperty(key)) continue;

        var obj = u[key];
        for (var prop in obj) {
          // skip loop if the property is from prototype
          if (!obj.hasOwnProperty(prop)) continue;

          // your code
          //alert(prop + " = " + obj[prop]);
        }
      }
    });
  }

  get selectedUnitId() {
    let unitId = this.props.match.params.unitId;
    return unitId.split('&')[0];
  }

  get selectedUnitName() {
    return this.props.match.params.unitName;
  }

  handleChange = value => {
    this.setState({
      dateRange: value,
      isCustom: false
    });
    var range = value;
    let locationData = LOCATIONS.filter(l => {
      return l.locationId === this.selectedUnitId && l.userDetails;
    }).filter(i => i.dateRange === range);
    var now = Date.now();
    if (locationData.length > 0 && now - locationData[0].timestamp <= ONE_HOUR * 12) {
      this.createTotViewData(locationData[0].data);
      this.setState({
        locData: locationData[0].data
      });
      this.setGlobal({
        selectedDateRange: range,
        range: this.getDateRangeModelFromInteger(range)
      });
    } else if (locationData.length <= 0) {
      var fac = this.state.facilities;
      this.setGlobal({
        selectedDateRange: range,
        range: this.getDateRangeModelFromInteger(range)
      });
      this.getTimeonTask(fac, range);
    }
  };

  getUserTot(user) {
    var _data = this.state.locData.locations[0].userDetails.filter(u => {
      return u.personId === user;
    });

    this.setState({
      userModalOpen: true,
      selectedUser: _data
    });
  }

  fetchTimeonTask = fac => {
    if ([7, 30, 90, 365].indexOf(this.global.selectedDateRange) === -1) {
      this.getTimeonTask(fac, this.global.range, false);
    } else {
      this.getTimeonTask(fac, this.global.selectedDateRange);
    }
  };

  handleDateRangePicker = isOpen => {
    this.setState({
      dateRangePicker: {
        ...this.state.dateRangePicker,
        isOpen
      }
    });
  };

  handleDateRangeChange = range => {
    range = {
      startDate: moment.utc(range.startDate),
      endDate: moment.utc(range.endDate)
    };
    const daysCount = moment.duration(range.endDate.diff(range.startDate)).asDays();

    const formattedRange = {
      startDate: range.startDate.format(),
      endDate: range.endDate.format()
    };
    this.setGlobal({
      selectedDateRange: daysCount,
      range: formattedRange
    });
    this.setState({
      dateRange: daysCount,
      isCustom: true
    });
    this.handleDateRangePicker(false);
    this.handleDateRangePicker(false);
    var fac = this.state.facilities;
    this.getTimeonTask(
      fac,
      {
        startDate: moment.utc(range.startDate).format(),
        endDate: moment.utc(range.endDate).format()
      },
      false
    );
  };

  render() {
    const { classes } = this.props;
    const columns = [
      {
        title: 'User Name',
        field: 'name',
        render: rowData => (
          <Button color="primary" onClick={() => this.getUserTot(rowData.id)}>
            <AccountCircleIcon style={{ marginRight: 6 }} /> {rowData.name}
          </Button>
        )
      },
      {
        title: 'DOC ID',
        field: 'docid'
      },
      {
        title: 'Current Status',
        field: 'status',
        lookup: { active: 'active', inactive: 'inactive' },
        default: 'active',
        defaultSort: 'asc',
        render: rowData => (
          <p style={{ color: rowData.status === 'active' ? 'black' : AdminTheme.palette.text.red }}>{rowData.status}</p>
        )
      },
      {
        title: 'Total Time Spent (hours)',
        field: 'total',
        render: rowData => rowData.total.toFixed(2)
      },
      {
        title: 'Education',
        field: 'education',
        render: rowData => rowData.education.toFixed(2)
      },
      {
        title: 'Rehabilitation',
        field: 'rehab',
        render: rowData => rowData.rehab.toFixed(2)
      },
      {
        title: 'Workforce Skills',
        field: 'job',
        render: rowData => rowData.job.toFixed(2)
      },
      {
        title: 'Reentry',
        field: 'reentry',
        render: rowData => rowData.reentry.toFixed(2)
      },
      {
        title: 'Enrichment',
        field: 'enrichment',
        render: rowData => rowData.enrichment.toFixed(2)
      },
      {
        title: 'Entertainment',
        field: 'entertainment',
        render: rowData => rowData.entertainment.toFixed(2)
      },
      {
        title: 'Miscellaneous',
        field: 'misc',
        render: rowData => rowData.misc.toFixed(2)
      }
    ];
    return (
      <div style={{ padding: '1rem' }}>
        {/* <Button onClick={this.handleClose} style={{ marginBottom: '1rem' }}><ArrowBackIcon /> back</Button> */}
        <Box display="flex" alignItems="center" justifyContent="center" position="relative" className={classes.actionBar}>
          <List className={classes.daysSelectionList}>
            <ListItem
              button
              onClick={() => this.handleChange(7)}
              className={[
                classes.daysSelectionItem,
                this.global.selectedDateRange === 7 && !this.state.isCustom ? classes.activeDay : null
              ].join(' ')}
            >
              7 Days
            </ListItem>
            <ListItem
              onClick={() => this.handleChange(30)}
              button
              className={[
                classes.daysSelectionItem,
                this.global.selectedDateRange === 30 && !this.state.isCustom ? classes.activeDay : null
              ].join(' ')}
            >
              30 Days
            </ListItem>
            <ListItem
              onClick={() => this.handleChange(90)}
              button
              className={[
                classes.daysSelectionItem,
                this.global.selectedDateRange === 90 && !this.state.isCustom ? classes.activeDay : null
              ].join(' ')}
            >
              90 Days
            </ListItem>
            <ListItem
              onClick={() => this.handleChange(365)}
              button
              className={[
                classes.daysSelectionItem,
                this.global.selectedDateRange === 365 && !this.state.isCustom ? classes.activeDay : null
              ].join(' ')}
            >
              365 Days
            </ListItem>
            <ListItem
              onClick={() => this.handleDateRangePicker(true)}
              button
              className={[classes.daysSelectionItem, this.state.isCustom ? classes.activeDay : null].join(' ')}
            >
              Custom
            </ListItem>
          </List>
          <Box position="absolute" top="0px">
            <DateRangePicker
              open={this.state.dateRangePicker.isOpen}
              closeOnClickOutside={true}
              maxDate={new Date()}
              toggle={() => this.handleDateRangePicker(!this.state.dateRangePicker.isOpen)}
              onChange={this.handleDateRangeChange}
            />
          </Box>
        </Box>
        {this.state.error && (
          <div>
            <h3>An error has occured.</h3>
            <h5>{this.state.error.error}</h5>
            <p>{this.state.error.error_description}</p>
          </div>
        )}

        <Grid container spacing={24}>
          {this.state.locData ? (
            <Grid item sm={3} xs={12}>
              <Zoom in unmountOnExit>
                <div style={{ display: 'flex', flex: 1, flexFlow: 'row wrap' }}>
                  <div style={{ flex: 1, display: 'flex', flexFlow: 'row wrap' }}>
                    <Paper style={{ width: '100%', borderRadius: 4, margin: 4 }}>
                      <Typography variant="h6" style={{ margin: 10, color: AdminTheme.palette.text.navy }}>
                        {this.selectedUnitName}
                      </Typography>
                    </Paper>
                    <Paper className={classes.statisticsContainer} >
                      <AvTimerIcon style={{ width: 18, height: 18, float: 'left', margin: 4 }} />
                      <p style={{ textAlign: 'left', margin: 6, marginTop: 3 }}> average</p>
                      <Typography variant="h5" style={{ margin: 8, textAlign: 'center', color: 'white' }}>
                        {this.state.locData.averageTimespent || 0}
                      </Typography>
                    </Paper>
                    <Paper className={classes.statisticsContainer}>
                      <SupervisedUserCircleIcon style={{ width: 18, height: 18, float: 'left', margin: 4 }} />
                      <p style={{ textAlign: 'left', margin: 6, marginTop: 3 }}> users</p>
                      <Typography variant="h5" style={{ margin: 8, color: 'white', textAlign: 'center' }}>
                        {this.state.locData.numberOfUsers}
                      </Typography>
                    </Paper>
                    <Paper className={classes.timeSpentContainer}>
                      <TimerIcon style={{ width: 22, height: 22, float: 'left', margin: 4 }} />
                      <p style={{ textAlign: 'left', margin: 6, marginTop: 7 }}> total hours spent</p>
                      <Typography variant="h6" style={{ margin: 10, color: 'white', textAlign: 'center' }}>
                        {this.state.locData.totalTimespent}
                      </Typography>
                    </Paper>
                    {this.state.isTablePermitted && (
                      <Button className={classes.totBtn} color="primary" onClick={() => this.handleToTDetailsDialog(true)}>
                        View Time On Task Details
                      </Button>
                    )}
                    <TotDetails
                      contentAreaVisible
                      exportFileName={this.selectedUnitName}
                      heading={`${this.selectedUnitName} Time on Task`}
                      locations={this.state.locData.locations}
                      open={this.state.totDetailsDialog.isOpen}
                      type={TOT_DETAILS_ENUM.Location}
                      closeHandler={this.handleToTDetailsDialog}
                      locationType="HU"
                      parentLocationId={this.props.match.params.locationId}
                    />
                  </div>
                </div>
              </Zoom>
            </Grid>
          ) : (
            <Paper style={{ padding: '1rem', width: '100%' }}>
              <CircularProgress className={classes.progress} />
              <h1>Loading time on task data...</h1>
            </Paper>
          )}
          <Grid item sm={9} xs={12}>
            {this.state.totData && this.state.totData.length > 0 && this.state.totData.length < 15 ? (
              <Slide in unmountOnExit direction="left">
                <Paper className={classes.timeOnTaskChart}>
                  <Typography variant="h6" style={{ textAlign: 'center', padding: '.5rem', fontSize: 18 }}>
                    Users Time on Task
                  </Typography>
                  <LineChart
                    library={{
                      ...this._chart.getDefaultOptions,
                      legend: {
                        display: true
                      }
                    }}
                    ytitle="Time Spent (hours)"
                    height={220}
                    stacked
                    adapter="chartjs"
                    data={this.state.totData}
                  />
                </Paper>
              </Slide>
            ) : this.state.locData ? (
              <Zoom in unmountOnExit>
                <Paper className={classes.timeOnTaskChart}>
                  <Typography variant="h6" style={{ textAlign: 'center', padding: '.5rem', fontSize: 18 }}>
                    Time on Task by Pathways
                  </Typography>

                  <ColumnChart
                    dataset={{
                      ...this._chart.getDefaultDatasetOptions,
                      backgroundColor: this.state.locData.contentAverageChart.map(data =>
                        CONTENT_AREA[data[2]] ? CONTENT_AREA[data[2]].bgColor : AdminTheme.palette.background.blue
                      )
                    }}
                    library={this._chart.getDefaultOptions}
                    stacked
                    ytitle="Time Spent (hours)"
                    height={220}
                    adapter="chartjs"
                    data={this.state.locData.contentAverageChart}
                  />
                </Paper>
              </Zoom>
            ) : null}
          </Grid>
        </Grid>
        {this.state.allUsers ? (
          <div>
            {this.state.locData && this.state.isTablePermitted ? (
              <Zoom in unmountOnExit>
                <Paper style={{ marginTop: '2rem' }}>
                  <p style={{ margin: '1rem', marginTop: '1rem' }}>
                    Select up to 15 learners to see their time on task details above.
                  </p>
                  <Datatable
                    title={`${this.selectedUnitName} Time on Task`}
                    data={this.state.totUserTableData}
                    columns={this.columns}
                    icons={{
                      Search: SearchIcon,
                      ResetSearch: CloseIcon,
                      FirstPage: FirstPageIcon,
                      LastPage: LastPageIcon,
                      NextPage: NavigateNextIcon,
                      PreviousPage: NavigateBeforeIcon,
                      Filter: FilterListIcon,
                      Sort: SortIcon,
                      SortArrow: ArrowDownwardIcon,
                      Export: SaveAltIcon,
                      Edit: EditIcon,
                      User: <UserIcon />
                    }}
                    options={{
                      pageSize: 20,
                      selection: true,
                      sorting: true,
                      exportButton: true,
                      exportAllData: true,
                      rowStyle: rowData => ({
                        backgroundColor: rowData.status === 'active' ? '#ffffff' : '#e8e8e8'
                      })
                    }}
                    onSelectionChange={rows => {
                      var usersArr = [];
                      var chartArr = [];
                      var chartData = {};
                      var _chartData = this.state.allUsers;
                      rows.map(r => {
                        usersArr.push(r);
                      });
                      usersArr.map(u => {
                        if (_chartData !== null) {
                          var user = _chartData.filter(o => o.name === u.name);
                          user.map(u => chartArr.push(u));
                        }
                      });
                      this.setState({
                        totData: chartArr
                      });
                    }}
                    actions={[
                      {
                        icon: CompareIcon,
                        onClick: (event, rowData) => {
                          var arr = Object.keys(rowData).map(key => rowData[key]);
                          this.tableToChart(arr);
                          this.setState({
                            selectedUsers: arr
                          });
                        }
                      }
                    ]}
                  />
                </Paper>
              </Zoom>
            ) : null}
          </div>
        ) : null}
        {this.state.userModalOpen && this.state.selectedUser ? (
          <UserTotModal user={this.state.selectedUser} open={this.state.userModalOpen} close={this.handleCloseUserModal} />
        ) : null}
      </div>
    );
  }
}

Unit.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return state.app;
};

const mapDispatchToProps = dispatch => {
  return {
    selectLocation: location => dispatch(selectLocation(location)),
    selectHousingUnit: hu => dispatch(selectHousingUnit(hu))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Unit)));
