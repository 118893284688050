import { useEffect } from 'react';

export const useResetBioAuthModal = (props, modalRef) => {
  const { isOpen, resetFaceAuth, failedUsers, onClose } = props;

  useEffect(() => {
    if (isOpen) {
        modalRef.current.toggleModal();
    }
  }, [isOpen]);
  /**
   * @name onClose
   * @description Callback fired when modal is closing
   * @return {void} 
   */
  const onCloseHandler = () => {
    modalRef.current.toggleModal();
    onClose();
  };

  /**
   * @name onContinue
   * @description Callback fired when "retry" button is clicked
   * @return {void} 
   */
  const onContinue = () => {
    onCloseHandler();
    resetFaceAuth(failedUsers.map(user => ({userName: user.username})));
  };
  return {
    onContinue,
    onCloseHandler
  };
};
