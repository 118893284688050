export default (theme) => ({
  header: {
    marginTop: '20px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  heading: {
    fontSize: '30px',
    fontWeight: 800,
    fontFamily: 'inter',
    lineHeight: '36.31px',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  divider: {
    width: '936px',
    height: '1px',
    background: theme.palette.text.divider,
  },
  dividerRow: {
    display: 'flex',
    padding: '6px 0px 6px 0px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 24,
  },
  stableTableCellOne: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '2px',
    textAlign: 'left',
  },
  stableTableCellTwo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginLeft: '70px',
    alignSelf: 'stretch',
  },
  label: {
    alignSelf: 'stretch',
    flex: 1,
    position: 'relative',
    letterSpacing: '0.02em',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxHeight: '24px',
    fontWeight: 'bold',
  },
  subText: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '2%',
    color: theme.palette.colors.grey110,
  },
  root: {
    width: '70px',
    height: '30px',
    padding: '0px',
  },
  switchBase: {
    color: theme.palette.colors.grey100,
    padding: '3px',
    paddingLeft: '5px',
  },
  thumb: {
    color: 'black',
    width: '30px',
    height: '30px',
  },
  trackOn: {
    borderRadius: '20px !important',
    backgroundColor: `${theme.palette.primary.blue.main} !important`,
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'white',
      fontSize: '15px',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      top: '6px',
    },
    '&:after': {
      content: "'Yes'",
      right: '10px',
    },
  },

  trackOff: {
    borderRadius: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
    opacity: '1 !important',
    '&:after, &:before': {
      color: theme.palette.colors.grey110,
      fontSize: '15px',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      top: '6px',
    },
    '&:before': {
      content: "'No'",
      left: '8px',
    },
  },
  checked: {
    color: 'white',
    left: '-62px !important',
    transform: 'translateX(100px) !important',
  },

  thumbIcon: {
    fontSize: '30px !important',
    color: 'white',
    width: '20px !important',
    padding: '2px !important',
    height: '20px !important',
  },
});
