export default theme => ({
  paper: {
    borderRadius: '10px',
    height: '100%'
  },
  mainDialog: {
    width: 612,
    maxWidth: '100%',
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
    flexDirection: 'row'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: 95,
    paddingLeft: 13,
    background: '#0F48B8',
    color: 'black'
  },
  headerHeading: {
    marginLeft: 17,
    fontSize: 24,
    color: "white",
    fontWeight: 'bold'
  },
  closeIcon: {
    marginLeft: 'auto',
    marginRight: 12,
    color: 'white',
    fontWeight: 'bold'
  },

  singleMessage: {
    display: 'flex',
    paddingLeft: 21,
    marginTop: 30,
    width: '100%',

    '&:last-of-type': {
      marginBottom: 10
    }
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 42,
    minWidth: 42,
    height: 42,
    borderRadius: '50%',
    backgroundColor: '#5d75d5',
    letterSpacing: 0.97,
    fontSize: 17.5,
    marginRight: 15,
    color: '#fff'
  },
  senderAvatarColor: {
    backgroundColor: '#8a51dd'
  },
  hiddenSenderAvatarColor: {
    backgroundColor: '#a6afd5'
  },
  hiddenLearnerAvatarColor: {
    backgroundColor: '#d1baf2'
  },
  hiddenFontColor: {
    color: '#a2a2a2 !important'
  },
  messageBodyWrapper: {
    display: 'flex'
  },
  messageInfo: {
    textAlign: 'left',
    width: '100%',
    lineHeight: 0
  },
  senderInfo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 9
  },
  senderName: {
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0.61,
    marginRight: 10
  },
  sendingTime: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: '#737982'
  },
  messageText: {
    display: 'block',
    fontSize: 14,
    letterSpacing: 0.39,
    wordBreak: 'break-word',
    width: '80%',
    lineHeight: 1
  },

  divider: {
    background: '#e7e7e7',
    width: '100%',
    height: 2,
    marginBottom: 10
  },
  checkIcon: {
    width: 12,
    height: 17,
    color: '#5d75d5'
  },

  verifiedIconWrapper: {
    height: 55,
    width: 55,
    background: '#624CA5',
    color: 'white',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  verifiedIcon: {
    fontSize: 30
  },
  readReceiptDetails: {
    marginLeft: 20
  },
  mainHeading: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  subHeading: {
    fontSize: 16
  },
  margin: {
    padding: '0px 20px'
  },
  detailText: {
    margin: '20px 0px'
  },
  scrollable: {
    padding: '0px 20px 20px',
    overflow: 'auto',
    width: '100%',
    height: '70%'
  },
  section: {
    width: '100%',
    marginTop: 15
  },
  sectionHeading: {
    fontWeight: 'bold',
    color: "white",
    marginBottom: 15
  },
  sectionInfo: {
    display: 'flex',
    alignItems: 'center'
  },
  sectionIcon: {
    marginRight: 10
  },
  inactive: {
    opacity: 0.5
  }
});
