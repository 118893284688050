import React from "react";

const EnrichmentIcon = props => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-Enrichment">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Enrichment" clipPath="url(#clip-Enrichment)">
        <g id="Group_1279" data-name="Group 1279" transform="translate(-2 -2.2)">
          <path id="Fill_1" data-name="Fill 1" d="M23.619.323a1.2,1.2,0,0,1,.058,1.7l-8.4,9a1.2,1.2,0,0,1-1.667.084L9.655,7.642,2.049,15.249a1.2,1.2,0,0,1-1.7-1.7l8.4-8.4A1.2,1.2,0,0,1,10.39,5.1l3.925,3.435L21.923.381A1.2,1.2,0,0,1,23.619.323Z" transform="translate(2 4)" fill={fill}/>
          <path id="Fill_2" data-name="Fill 2" d="M0,1.2A1.2,1.2,0,0,1,1.2,0H4.8A1.2,1.2,0,0,1,6,1.2V4.8a1.2,1.2,0,0,1-2.4,0V2.4H1.2A1.2,1.2,0,0,1,0,1.2Z" transform="translate(20 4)" fill={fill}/>
          <path id="Fill_3" data-name="Fill 3" d="M0,1.2A1.2,1.2,0,0,1,1.2,0H22.8a1.2,1.2,0,1,1,0,2.4H1.2A1.2,1.2,0,0,1,0,1.2Z" transform="translate(2 22)" fill={fill}/>
        </g>
      </g>
    </svg>
  );
};

EnrichmentIcon.defaultProps = {
  size: 24,
  fill: "inherit"
}

export default EnrichmentIcon;
