import React from 'react';
import { Typography, SchoolIcon, ScaleIcon, VerifiedUserIcon } from '@orijinworks/frontend-commons';

export const useOmniSearchHook = props => {
  const { classes } = props;

  const getIcon = roleName => {
    if (roleName === 'Learner') {
      return <SchoolIcon className={['learner', classes.learnerIcon].join(' ')} />;
    } else if (roleName === 'Attorney') {
      return <ScaleIcon className="attorney" />;
    } else {
      return <VerifiedUserIcon className={['staff', classes.staffIcon].join(' ')} />;
    }
  };

  const getLearnerLocationHistory = locationHistory => {
    return `${locationHistory[0].assignedLocationName}`;
  };

  const getStaffLocationHistory = locationHistory => {
    const firstLocation = locationHistory[0];
    const { assignedLocationParentName, assignedLocationName } = firstLocation;
    const additionalLocations = locationHistory.length > 1 ? `(+${locationHistory.length - 1})` : '';
    let locationString = '';
    if (assignedLocationParentName) {
      locationString += `${assignedLocationParentName}, `;
    }
    locationString += `${assignedLocationName} ${additionalLocations}`;
    return locationString.trim();
  };

  const getFlattenLocationText = (locationHistory, roleName) => {
    if (roleName == 'Learner') {
      return getLearnerLocationHistory(locationHistory);
    } else {
      return getStaffLocationHistory(locationHistory).trim();
    }
  };

  const getLearnerTextElement = ({ firstName, lastName, docId, locationHistory, roleName }) => {
    return (
      <Typography variant="body2">
        {firstName} {lastName} | DOC ID: {docId || 'N/A'} | Learner <br />
        {getFlattenLocationText(locationHistory, roleName)}
      </Typography>
    );
  };

  const getStaffTextElement = ({ firstName, lastName, locationHistory, roleName }) => {
    return (
      <Typography variant="body2">
        {firstName} {lastName} | {roleName} <br />
        {getFlattenLocationText(locationHistory, roleName)}
      </Typography>
    );
  };

  const getDescriptionElement = props => {
    const { roleName } = props;
    if (roleName === 'Learner') {
      return getLearnerTextElement(props);
    } else {
      return getStaffTextElement(props);
    }
  };

  return {
    getDescriptionElement,
    getIcon
  };
};
