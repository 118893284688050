import { withStyles } from '@material-ui/styles';
import { Modal, Typography } from '@orijinworks/frontend-commons';
import React, { useRef, useState } from 'react';
import styles from './communication-info-modal.styles';
import { Box, Grid } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { CustomChart } from '../../../../components/chart';
import { useCommunicationInfoModalHook } from './useCommunicationInfoModalHook';

const CommunicationInfoModal = (props) => {
  const modalRef = useRef(null);
  const { colors, chartData, groupedData, getDateRange } = useCommunicationInfoModalHook(props, modalRef);
  const _chart = new CustomChart();
  const { classes, locationName, isOpen, onClose } = props;
  const range = getDateRange(props.range);
  return (
    <Modal
      ref={modalRef}
      showFooter={false}
      heading="Message Volume"
      open={isOpen}
      onClose={onClose}
      styles={{
        mainWrapperStyles: {
          width: 780,
        },
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="flex">
          <Typography className={classes.label} variant="h6">
            LOCATION &nbsp; &nbsp;
          </Typography>
          <Typography id="location-name" variant="body1">
            {locationName}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography className={classes.label} variant="h6">
            TIME FRAME &nbsp; &nbsp;
          </Typography>
          <Typography id="time-frame" variant="body1">
            {range.startDate} - {range.endDate}
          </Typography>
        </Box>

        <Box flex={1}>
          <Bar options={_chart.getDefaultOptions} data={chartData} />
        </Box>
        <Grid container item className={classes.cardContainer}>
          <Grid
            container
            item
            md={3}
            direction="row"
            className={classes.cardWrapper}
            alignItems="flex-end"
            justify="space-between"
          >
            <Box className={classes.card} bgcolor={colors.totalMessages}>
              <Typography className={classes.cardMeta}>Messages Sent</Typography>
              <Typography className={classes.cardNumber} 
              id="total-msg-count"
              variant="h6">
                {groupedData.totalMsgs}
              </Typography>
            </Box>
          </Grid>
          <Grid
            container
            item
            md={3}
            direction="row"
            className={classes.cardWrapper}
            alignItems="flex-end"
            justify="space-between"
          >
            <Box className={classes.card} bgcolor={colors.sentByLearner}>
              <Typography className={classes.cardMeta}>Sent by Learners</Typography>
              <Typography className={classes.cardNumber} id="learner-count" variant="h6">
                {groupedData.sentByLearner}
              </Typography>
            </Box>
          </Grid>
          <Grid
            container
            item
            md={3}
            direction="row"
            className={classes.cardWrapper}
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Box className={classes.card} bgcolor={colors.sentByNonLearner}>
              <Typography className={classes.cardMeta}>Sent by Staff</Typography>
              <Typography id="non-learner-count" className={classes.cardNumber} variant="h6">
                {groupedData.sentByNonLearner}
              </Typography>
            </Box>
          </Grid>
          <Grid
            container
            item
            md={3}
            direction="row"
            className={classes.cardWrapper}
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Box className={classes.card} bgcolor={colors.msgsHidden}>
              <Typography className={classes.cardMeta}>Messages Hidden</Typography>
              <Typography id="hidden-count" className={classes.cardNumber} variant="h6">
                {groupedData.msgsHidden}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(CommunicationInfoModal);
