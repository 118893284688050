import React from "react";

const FileIcon = props => {
  const { size, stroke } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1122" data-name="Rectangle 1122" width="20" height="24" fill="none" stroke={stroke} strokeWidth="2"/>
        </clipPath>
        <clipPath id="clip-Forms_24x24">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Forms_24x24" data-name="Forms – 24x24" clipPath="url(#clip-Forms_24x24)">
        <g id="Group_1214" data-name="Group 1214" transform="translate(2 0.099)">
          <g id="Group_1213" data-name="Group 1213" transform="translate(0 -0.099)" clip-path="url(#clip-path)">
            <path id="Path_517" data-name="Path 517" d="M12.789,2,19.3,8.256V21.585a2.448,2.448,0,0,1-2.448,2.448H4.448A2.448,2.448,0,0,1,2,21.585V4.448A2.448,2.448,0,0,1,4.448,2Z" transform="translate(-0.776 -0.99)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Path_518" data-name="Path 518" d="M18.371,2.213v5.22a1.836,1.836,0,0,0,1.836,1.836H25.65" transform="translate(-7.128 -1.072)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

FileIcon.defaultProps = {
  size: 24,
  stroke: "inherit"
}

export default FileIcon;
