import React from 'react';
import { Loader, Transcript as TranscriptV2 } from '@orijinworks/frontend-commons';
import { useTranscriptHook } from './useTranscriptHook';
import TranscriptV1 from '../../../../components/users/learner/Transcript';
const Transcript = (props) => {
  const { user } = props;
  const { transcriptData, isLoading, isDisabled, onDownloadClick, setIsDisabled, onVersionChange } = useTranscriptHook(props);
  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      {!isLoading && (
        <TranscriptV2
          transcriptEmptyState={{
            title: 'No Data Available Yet',
            description:
              'Your report is currently empty. As you begin completing content, your progress will be recorded here. It may take up to 24 hours for your report to reflect the latest updates.',
          }}
          literals={{
            v1Title: 'New version Availble',
            v1Description:
              "Switch the toggle to view the new version with an updated look and functionality. You can switch back if you'd like later.",
            v2Title: 'Switch to the Old Version',
            v2Description: 'Go back to the previous version of the report any time.',
            transcriptTitle: 'Completion report',
            learnerDocId: 'Learner Doc Id',
            totalHours: 'Total Hours',
            noData: 'No Data',
            lastUpdated: 'Last Updated',
            footNote:
              '*Total hours is an approximation of time spent on programming based on data available to us from our partner sites.',
            transcriptContentMainTitle: 'Program And Certificates',
            additionalEducation: 'Additional Education',
            programsPlusClasses: 'Programs + Classes',
            percentComplete: '% Complete',
            totalStudyTime: 'Total Study Time',
            mostRecentActivity: 'Recent Activity',
            showMore: 'Show More',
            aboutName: `About ${user.userDetail.userName}`,
          }}
          transcriptData={transcriptData}
          TranscriptV1={() => <TranscriptV1
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            userInfo={user.userDetail} />}
          showDownloadButton 
          onDownloadClick={onDownloadClick}
          isDownloadButtonDisabled={isDisabled}
          onToggleVersionChange={onVersionChange}
        />
      )}
    </>
  );
};

export default Transcript;
