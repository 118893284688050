import React from 'react';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './voice-insight-chip.style';

const VoiceInsightChip = ({ label, classes }) => {
  return <Chip label={label} variant="outlined" className={classes.root} />;
};

VoiceInsightChip.propTypes = {
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(VoiceInsightChip);
