import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  sizeSmall: {
    fontSize: "20px",
  },
}));

const MessageTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <div>
      {title}
      <Tooltip
        title="Messages are stored for 90 days before being archived"
        placement="top-end"
      >
        <IconButton aria-label="message-info">
          <InfoIcon className={classes.sizeSmall} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default MessageTitle;
