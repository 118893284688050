import React from "react";

import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import Header from "./Header";
import Description from "./Description";
import AssignedLocations from "./AssignedLocations";
import Permissions from "./Permissions";
import RoleTypes from "./RoleTypes";
import styles from "./index.styles";
import { fetchPermissions, fetchRoleSummary } from "../../util/APIUtils";

const useStyles = makeStyles((theme) => styles(theme));

const RolesDetail = (props) => {
  const classes = useStyles();
  const { params } = props.match;
  const [roleDetail, setRoleDetail] = React.useState(null);
  const [allPermissions, setAllPermissions] = React.useState([]);
  const [groupedAllPermissions, setGroupedAllPermissions] = React.useState(
    null
  );
  const [mappedPermissions, setMappedPermissions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const getRoleDetail = async () => {
      const [roleDetail, allPermissions] = await Promise.all([
        fetchRoleSummary(params.roleId),
        fetchPermissions(),
      ]);

      const processedRoleDetail = makeGroupPermissions(
        roleDetail,
        allPermissions.permissions
      );

      const groupedAllPermissions = groupPermissions(
        allPermissions.permissions
      );

      setRoleDetail(processedRoleDetail);
      setAllPermissions(allPermissions.permissions);
      setGroupedAllPermissions(groupedAllPermissions);
      setLoading(false);
    };

    if (params.roleId.includes("state")) {
      return;
    }

    setLoading(true);
    getRoleDetail();
  }, [params.roleId]);

  const makeGroupPermissions = (roleDetail, allPermissions) => {
    if (roleDetail.permissionIds) {
      const permissionIds = roleDetail.permissionIds.split(",");
      const assignedPermissions = permissionIds.map((pid) =>
        allPermissions.find((perm) => perm.permissionId === parseInt(pid))
      );

      const groupedPermissions = groupPermissions(assignedPermissions);

      roleDetail.permissionIds = permissionIds.map((id) => parseInt(id));
      setMappedPermissions(groupedPermissions);
    }

    return roleDetail;
  };

  const fetchRoleDetail = async () => {
    const roleDetail = await fetchRoleSummary(params.roleId);
    const processedRoleDetail = makeGroupPermissions(
      roleDetail,
      allPermissions
    );

    setRoleDetail(processedRoleDetail);
  };

  const groupPermissions = (permissions) => {
    return permissions.reduce((acc, perm) => {
      if (acc[perm.groupDisplayName]) {
        acc[perm.groupDisplayName].push(perm);
      } else {
        acc[perm.groupDisplayName] = [perm];
      }

      return acc;
    }, {});
  };

  if (loading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={classes.rolesDetailContainer}>
      <Header roleDetail={roleDetail} fetchRoleDetail={fetchRoleDetail} />

      <Description
        description={roleDetail.roleDescription}
        roleId={roleDetail.roleId}
        isBaseRole={roleDetail.systemDefault}
        fetchRoleDetail={fetchRoleDetail}
      />
      <RoleTypes
        roleTypeKey={roleDetail.roleTypeKey}
        roleTypeName={roleDetail.roleTypeName}
        roleId={roleDetail.roleId}
        isBaseRole={roleDetail.systemDefault}
        fetchRoleDetail={fetchRoleDetail}
      />

      <AssignedLocations
        roleDetail={roleDetail}
        fetchRoleDetail={fetchRoleDetail}
      />

      <Permissions
        isBaseRole={roleDetail.systemDefault}
        roleId={roleDetail.roleId}
        permissionIds={roleDetail.permissionIds || []}
        permissions={mappedPermissions}
        allPermissions={groupedAllPermissions}
        fetchRoleDetail={fetchRoleDetail}
      />
    </div>
  );
};

export default withRouter(RolesDetail);
