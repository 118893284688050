import React from 'react';
import { Grid, List, ListItem, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './contacts-tab.component.style';
import { VOICE_CALL } from '../../constants/voice-call-constants';

const ContactsTabComponent = ({ activeTab, setActiveTab, classes }) => {
  const tabs = [
    { id: VOICE_CALL.TABS.PENDING_CONTACTS, label: 'PENDING CONTACTS' },
    { id: VOICE_CALL.TABS.ALL_CONTACTS, label: 'ALL CONTACTS' },
    { id: VOICE_CALL.TABS.LIVE_CALLS, label: 'LIVE CALLS' },
    { id: VOICE_CALL.TABS.CALL_HISTORY, label: 'CALL HISTORY' },
    { id: VOICE_CALL.TABS.RESIDENT_MANAGEMENT, label: 'RESIDENT MANAGEMENT' },
    { id: VOICE_CALL.TABS.SETTINGS, label: 'COMMUNICATIONS SETTINGS' },
  ];
  return (
    <Grid container item component="div" className={classes.content}>
      <Grid container item style={{ overflow: 'auto' }} xs={12}>
        <List className={classes.tabWrapper}>
          <Box component="div" display="flex" alignItems="flex-end" justifyContent="flex-end">
            {tabs.map((tab) => (
              <ListItem
                key={tab.id}
                id={`${tab.id.toLowerCase()}-tab-btn`}
                data-cy-name={`${tab.id.toLowerCase()}-tab-btn`}
                onClick={() => setActiveTab(tab.id)}
                button
                className={[classes.tabItem, activeTab === tab.id ? classes.activeTab : ''].join(' ')}
              >
                {tab.label}
              </ListItem>
            ))}
          </Box>
        </List>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ContactsTabComponent);
