export default (theme) => ({
  header: {
    padding: '15px 20px',
    borderBottom: '1px solid #a9a6a67a', //cc-3355 , is doing the rebrading on this modal already
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary.main,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.colors.grey50
  },
  headerText: {
    fontSize: 25,
    fontWeight: 'bold'
  },
  body: {
    padding: '15px 20px'
  },
  formGroup: {
    display: 'flex'
  },
  infoGroup: {
    flex: 1,
    padding: '16px 24px 16px 24px',
    border: `1px solid ${theme.palette.colors.grey50}`,
    borderRadius: '12px',
    marginRight: '10px',
    maxWidth: '100%',
    marginTop: '10px',
  },
  infoHeading: {
    color: theme.palette.navy,
    fontWeight: 700,
    fontSize: 16
  },
  identity: {
    color: theme.palette.colors.ceruleanMain,
    fontSize: 14,
    fontWeight:500,
    textTransform: 'uppercase'
  },

  infoDetails: {
    
  },

  modal: {
    maxWidth: 570,
    margin: '0 auto',
    marginTop: '10vh',
  },
  footer: {
    padding: '0px 20px 25px'
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  leaveBtn: {
    background: '#ff0000',
    borderRadius: 35,
    color: 'white',
    padding: 15,
    fontWeight: 'bold'
  },
  audioPlayer: {
    padding: '16px 24px 16px 24px',
    border: `1px solid ${theme.palette.colors.grey50}`,
    borderRadius: '12px',
    marginTop: 14,
    marginBottom: 14
  },
  textGray: {
    color: theme.palette.colors.grey10
  },
  callDurationText :{
    fontWeight: 'bold'
  },
  recordingSection:{
    paddingTop: '24px !important',
    marginTop: '12px',
  },
  recordingDetails : {
    paddingLeft:3
  },

  callDetailsSection:{
   display: 'flex',
  },
  recordingLabel : {
    fontSize:18,
    fontWeight:700,
    color: theme.palette.colors.ceruleanMain,
    textTransform: 'uppercase'
  },

  noRecordingAvailable: {
    color: theme.palette.greyScale.darkSilver,
    display: 'flex',
    fontWeight:700,
    alignItems: 'center',
    textTransform:'capitalize'
  },
  noRecordingAvailableIcon: {
    marginRight: '5px',
    fontWeight:700,
    color: theme.palette.greyScale.darkSilver
  },
  recordingItem: {
    paddingLeft: '14px'
  },

  transcriptSection:{
    marginTop: '10px',
    marginBottom: '10px'
  },
  transcriptPdf: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },
  transcriptTitle: {
    color: 'blue',
    fontSize: '20px',
    fontWeight: 'bold',
    paddingLeft: '22px',
    marginBottom: '5px'
  },
  summaryTitle: {
    fontSize:18,
    fontWeight:700,
    color: theme.palette.colors.ceruleanMain,
    textTransform: 'uppercase'
  },
  summaryBox: {
    padding: '16px 24px 16px 24px',
    border: `1px solid ${theme.palette.colors.grey50}`,
    borderRadius: '12px',
    marginTop: '10px',
    textAlign: 'justify'
  },
  summaryText: {
  },
  downloadTranscriptBtn:{
    textTransform: 'capitalize',
    borderRadius: 35,
    color:theme.palette.primary.white,
    fontWeight: 700,
    fontSize: 16,
    background: theme.palette.button.blue.default,
    '&:hover': {
      background: theme.palette.button.blue.hover
    }
    
  },
  dialogActions: {
    padding: '10px 0',
    justifyContent: 'left'
  },
});
