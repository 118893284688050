import React from 'react';

export const useDemographic = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverText, setPopoverText] = React.useState('');
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (event, text) => {
    setAnchorEl(event.currentTarget);
    setPopoverText(text);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


  return {
    openPopover,
    anchorEl,
    popoverText,
    handlePopoverOpen,
    handlePopoverClose
  };
};
