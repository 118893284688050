import React from 'react';
import {
  withStyles,
  Box,
  Typography,
  Grid,
  Popover
} from '../../../shared/components/MaterialUIComponents';

import styles from './demographic.style';
import { CustomIcons, icons } from '../../../shared/components/custom-icons/CustomIcons';
import { useDemographic } from './useDemogrpahicHook';

const Demographic = props => {
  const { classes, name, questions, getAnswerText } = props;
  const { openPopover, anchorEl, popoverText, handlePopoverOpen, handlePopoverClose } = useDemographic();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography id="demographic-title" color="primary" className={classes.title}>
          Basic Information
        </Typography>

        <Popover
          id="demographic-helper-text-popover"
          classes={{
            paper: classes.popoverPaper
          }}
          open={openPopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
        >
          <Typography>{popoverText}</Typography>
        </Popover>
        <Grid id="demographic-view-container" container spacing={2}>
          <Grid item xs={6} md={6} lg={6}>
            <Typography variant="h6"> 1. Name </Typography>
            <Typography variant="h6" id="demographic-username" gutterBottom className={classes.disabled}>
              {name || 'N/A'}
            </Typography>
          </Grid>
          {questions.map((question, index) => (
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="h6">
                {`${index + 2}. ` + question.question}
                {question.helpText && (
                  <CustomIcons
                    icon={icons.InfoOutlined}
                    className={classes.infoIcon}
                    aria-owns={openPopover ? 'demographic-helper-text-popover' : undefined}
                    aria-haspopup="true"
                    id={`demographic-help-icon-${index}`}
                    onClick={e => {
                      handlePopoverOpen(e, question.helpText);
                    }}
                  />
                )}
              </Typography>
              <Typography 
              id={`answer-${index + 2}`}
              variant="h6" gutterBottom className={classes.disabled}>
                {getAnswerText(question.answer, question.answerOptions)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Demographic);
