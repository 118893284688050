import React, { Component, Suspense } from 'reactn';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormService } from './form.service';
import { showHideLoader } from '../../../v2/core/store/.';
import { Loader } from '@orijinworks/frontend-commons';


const FormList = React.lazy(() => import('./forms-list'));
const FormSubmissions = React.lazy(() => import('./forms-submission'));

class Forms extends Component {

    state = {
        isManageFormOpen: false,
    };
    formService = new FormService();



    /**
     * @name initPropToState
     * @desc A wrapper method to initialize properties from prop to state.
     * @return {void}
     */
    initPropToState = () => {
        this.setState({
            location: this.props.location
        });
    };

    componentDidMount() {
        this.initPropToState();
    };

    componentDidUpdate() {
        if (this.props.location.active !== this.state.location.active) {
            this.initPropToState();
            this.forceUpdate();
        }
    };

    /**
     * @name manageLoader
     * @param {boolean} isLoading
     * @desc Manages loading state.
     * @return {void}
     */
    manageLoader = (isLoading) => {
        this.setGlobal({
            isLoading
        });
    };


    render() {
        return (
            <Suspense fallback={<Loader isLoading={true} />}>
                <Switch>
                    <Route path="/manage/:location/:formName">
                        <FormSubmissions {...this.props} />
                    </Route>
                    <Route exact path="/manage/:location">
                        <FormList
                            global={this.global}
                            cbacConfigFlag={this.global.cbacConfigFlag}
                            permissions={this.global.permissions}
                            toggleLoader={this.manageLoader}
                            props={{ ...this.props }} />
                    </Route>
                </Switch>
            </Suspense>
        );
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        toggleLoader: (isLoading) => dispatch(showHideLoader({ isLoading: isLoading }))
    };
};

export default connect(null, mapDispatchToProps)(Forms);