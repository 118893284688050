import React, { forwardRef } from 'react';
import { Modal, Typography } from '@orijinworks/frontend-commons';
import { withStyles } from '@material-ui/core';
import styles from './disable-voice-id-registration-modal.style';
import SecurityIcon from '@material-ui/icons/Security';
const DisableVoiceIdRegistrationModal = forwardRef(({ classes, open, onClose, onContinue }, ref) => {
  return (
    <Modal
      ref={ref}
      open={open}
      onClose={onClose}
      Icon={<SecurityIcon className={classes.icon} />}
      showCrossIcon={true}
      actionBtnText="Disable Voice ID"
      cancelBtnText="Cancel"
      continueButtonProps={{ color: 'destructive' }}
      onContinue={onContinue}
      styles={{
        mainWrapperStyles: {
          height: '319px',
          width: '420px',
        },
      }}
      data-testid="modal"
    >
      <Typography classes={classes.mainDescription} variant="h5" data-testid="main-description">
        Disable Voice ID Requirement?
      </Typography>
      <Typography classes={classes.description} variant="body" data-testid="description">
        Are you sure you want to disable the Voice ID requirement for this facility? Learners will be able to make calls
        without voice authentication.
      </Typography>
    </Modal>
  );
});

export default withStyles(styles)(DisableVoiceIdRegistrationModal);
