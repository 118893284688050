import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { VoiceCallService } from '../../services/voice-call.service';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import { columnSortByOrderDirection } from '../../../../util/Helpers';
import { parseRelationshipType } from '../../utils/utils';

export const useLearnerProfileContainerHook = () => {
  useEffect(() => {
    storeActiveTabToLocalStorage();
    getLearnerProfile();
    getContactsForLearner();
  }, []);
  const tableRef = useRef();
  const voiceCallService = new VoiceCallService();
  const defaultPageSize = 10;
  const stateFilters = {
    lastName: '',
    phoneNumber: '',
    relationshipType: '',
    lastContactedDate: '',
    numOfTimesContacted: '',
    createdDate: '',
    status: [],
  };
  const allowedFilters = [
    'lastName',
    'phoneNumber',
    'relationshipType',
    'lastContactedDate',
    'numOfTimesContacted',
    'createdDate',
    'status',
  ];
  const [items, setItems] = useState([]);
  const [learnerProfile, setLearnerProfile] = useState({});
  const [isDataRefresh, setDataRefresh] = React.useState(false);
  const storeActiveTabToLocalStorage = () => {
    localStorage.setItem('activeTab', 'LEARNER_PROFILE');
  };

  const getLearnerProfile = () => {
    const learnerProfile = localStorage.getItem('selectedLearner');
    if (learnerProfile) {
      const learnerName = JSON.parse(learnerProfile).learnerName;
      setLearnerProfile(JSON.parse(learnerProfile));
      setItems([{ label: 'Learner Management', href: '/voiceCalls' }, { label: learnerName }]);
    }
  };

  useEffect(() => {
    if (isDataRefresh) {
      refreshTable();
      setDataRefresh(false);
    }
  }, [isDataRefresh]);

  const refreshTable = () => {
    tableRef && tableRef.current && tableRef.current.onQueryChange();
  };

  let tableColumns = [
    {
      title: 'Contact Details',
      field: 'lastName',
      defaultSort: 'asc',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 150,
      },
      render: (rowData) => rowData.firstName + ' ' + rowData.lastName,
    },
    {
      title: 'Phone Number',
      field: 'phoneNumber',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 100,
      },
    },
    {
      title: 'Relationship',
      field: 'relationshipType',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 100,
      },
      render: (rowData) => {
        return parseRelationshipType(rowData.relationshipType);
      },
    },
    {
      title: 'Last Call',
      field: 'lastContactedDate',
      type: 'date',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 100,
      },
    },
    {
      title: 'Number of calls',
      field: 'numOfTimesContacted',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 100,
      },
    },
    {
      title: 'Date Added',
      field: 'createdDate',
      type: 'date',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 100,
      },
    },
    {
      title: 'Approval Status',
      field: 'status',
      filtering: true,
      sorting: false,
      lookup: (() => {
        const statusLookup = { ...VOICE_CALL.STATUS, INACTIVE: 'ARCHIVED' };
        return statusLookup;
      })(),
      cellStyle: {
        minWidth: 100,
      },
      render: (rowData) => {
        //if status inactive then show ARCHIVED
        if (rowData.status === 'INACTIVE') {
          return 'ARCHIVED';
        }
        return rowData.status;
      },
    },
  ];

  const setTableFilters = (query) => {
    let filters = { ...stateFilters };
    if (!query) return filters;
    if (!query.filters) return filters;
    for (let i = 0; i < query.filters.length; i++) {
      let item = query.filters[i];
      if (allowedFilters.includes(item.column.field)) {
        let value = item.value;
        if (Array.isArray(value)) value = [...item.value];
        filters[item.column.field] = value;
      }
    }
    return filters;
  };

  const createRequestPayload = (query) => {
    let selectedFilters = setTableFilters(query);
    columnSortByOrderDirection(tableColumns, query.orderBy, query.orderDirection);
    let requestPayload = {
      firstName: selectedFilters.lastName,
      relationshipType: selectedFilters.relationshipType,
      lastContactedDate: selectedFilters.lastContactedDate,
      numOfTimesContacted: selectedFilters.numOfTimesContacted,
      createdDate: selectedFilters.createdDate,
      status: selectedFilters.status.join(','), // status is an array
      phoneNumber: selectedFilters.phoneNumber,
    };
    return requestPayload;
  };

  const getContactsForLearner = async (query) => {
    if (!query) return;
    const requestPayload = createRequestPayload(query);
    const { page, pageSize, orderBy, orderDirection } = query;

    const learnerProfile = localStorage.getItem('selectedLearner');
    if (learnerProfile) {
      const learnerId = JSON.parse(learnerProfile).learnerId;
      return new Promise((resolve, reject) => {
        voiceCallService
          .getContactsByLearnerId({
            learnerId,
            pageSize,
            pageNumber: page,
            orderBy: orderBy ? orderBy.field : '',
            orderDirection,
            requestPayload,
          })
          .then((data) => {
            const { content, pageable, totalElements } = data;
            if (content) {
              resolve({
                data: content,
                page: (pageable && pageable.pageNumber) || 0,
                totalCount: totalElements,
              });
            } else {
              resolve({
                data: [],
                page: 0,
                totalCount: 0,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          })
          .finally(() => {
            // setLoading(false);
          });
      });
    }
  };
  return {
    items,
    learnerProfile,
    tableRef,
    tableColumns,
    defaultPageSize,
    getContactsForLearner,
    setDataRefresh,
  };
};
