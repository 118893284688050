import React from 'react';

import {
  Paper,
  Checkbox,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import EditButton from '../EditButton';
import { commonStyles } from '../common';
import { saveRoleDetails } from '../../../util/APIUtils';
import styles from './index.styles';

const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
  ...styles(theme)
}))

const Permissions = ({
  isBaseRole,
  roleId,
  permissions,
  permissionIds,
  allPermissions,
  fetchRoleDetail
}) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = React.useState(false);
  const [selectedPermissions, setSelectedPermissions] = React.useState(permissionIds);

  React.useEffect(() => {
    setSelectedPermissions(permissionIds);
  }, [permissionIds])

  const handleEditClick = () => {
    setIsEdit(true);
  }

  const handleCancelClick = () => {
    setIsEdit(false);
  }

  const handleSave = async () => {
    await saveRoleDetails({
      roleId,
      assignedPermissionIds: selectedPermissions
    });

    // setSelectedPermissions(permissionIds);
    await fetchRoleDetail();

    handleCancelClick();
  }

  const onCheckboxClick = (event) => {
    const id = parseInt(event.target.name);

    const permIds = [...selectedPermissions];

    const index = permIds.indexOf(id);

    if (index !== -1) {
      permIds.splice(index, 1);
    } else {
      permIds.push(id);
    }

    setSelectedPermissions(permIds);
  }

  return (
    <Paper elevation={3} classes={{
      root: `${classes.paperRoot} ${classes.noHorizontalPadding} ${classes.container}`
    }}>
      <div className={`${classes.subheadingWrapper} ${classes.horizontalPadding}`}>
        <h4 className={classes.subheading}>
          Permissions
        </h4>
        {!isEdit && !isBaseRole && <EditButton onClick={handleEditClick} />}
      </div>

      <div className={`${classes.headingRow} ${classes.firstHeadingRow}`}>
        <span className={classes.smallHeading}>
          All Permissions
        </span>
      </div>

      {selectedPermissions.length === 0 && !isEdit ?
        <div className={classes.notAvailable}>
          No permissions for this role
        </div> :
        Object.entries(isEdit ? allPermissions : permissions).map(([permKey, permValue]) => (
          <Accordion key={permKey} classes={{
            root: classes.accordion,
            expanded: classes.expanded
          }}>
            <AccordionSummary
              expandIcon={<KeyboardArrowDownIcon className={classes.chevronDownIcon} fontSize="small" />}
              classes={{
                root: classes.accordionSummary,
                expanded: classes.expanded,
                content: classes.accordionSummaryContentWrapper,
                expandIcon: classes.expandIcon
              }}
            >
              <div className={`${classes.headingRow} ${classes.accordionSummaryContent}`}>
                <span className={classes.boldHeading}>
                  {permKey !== "null" && permKey.split("_").join(" ")}
                </span>
              </div>
            </AccordionSummary>

            <AccordionDetails className={classes.accordionDetails}>
              {
                permValue.map(val => (
                  <div
                    key={val.permissionId}
                    className={!isEdit ?
                      `${classes.headingRow} ${classes.permissionRow}` :
                      classes.editPermissionRow}
                  >
                    <span className={classes.permissionText}>
                      {val.permissionDisplayName}
                    </span>
                    <Checkbox
                      disabled={!isEdit}
                      onChange={onCheckboxClick}
                      checked={selectedPermissions && selectedPermissions.includes(val.permissionId)}
                      name={val.permissionId}
                      classes={{
                        root: classes.permissionCheckbox
                      }}
                    />
                  </div>
                ))
              }
            </AccordionDetails>
          </Accordion>
        ))
      }

      {isEdit && <div className={classes.btnWrapper}>
        <Button onClick={handleCancelClick}>Cancel </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.saveBtn}
          onClick={handleSave}
        >Save</Button>
      </div>}
    </Paper>
  );
}

export default Permissions;