import { useState } from 'react';
import { VoiceCallService } from '../../../../services/voice-call.service';
const useCreateScheduleHook = ({ ref, locationId, isNewScheduleAdded, setIsNewScheduleAdded }) => {
  const [scheduleName, setScheduleName] = useState('');
  const [scheduleError, setScheduleError] = useState('');
  const [snackbarContent, setSnackbarContent] = useState({ message: '', severity: '' });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const voiceCallService = new VoiceCallService();
  const handleScheduleNameChange = (event) => {
    setScheduleError('');
    setScheduleName(event.target.value);
  };

  const createSchedule = async () => {
    try {
      const response = await voiceCallService.createSchedule({ locationId, scheduleName });
      if (response?.errorMessage) {
        setScheduleError(response.errorMessage);
      } else {
        ref.current.toggleModal();
        setScheduleError('');
        setScheduleName('');
        setIsNewScheduleAdded(!isNewScheduleAdded);
        setSnackbarContent({ message: 'Your group has been created successfully.', severity: 'success' });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setScheduleError(error.message || 'An unexpected error occurred');
      console.error(error); // Optionally log the error
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return {
    scheduleName,
    scheduleError,
    handleScheduleNameChange,
    createSchedule,
    snackbarOpen,
    snackbarContent,
    handleCloseSnackbar,
  };
};

export default useCreateScheduleHook;
