import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import store , { showHideSessionModal } from '../../v2/core/store/.';

let timerInterval = null;
function clearTimerInterval() {
  if (timerInterval) {
    clearInterval(timerInterval);
  }
}

function SessionOut(props) {
  const [timer, setTimer] = React.useState(5);

  React.useEffect(() => {
    timerInterval = setInterval(() => {
      if (!props.isOpen) {
        return;
      }

      if (timer > 0) {
        setTimer(timer - 1);
      }
      if (timer === 0) {
        clearTimerInterval();
        if (props.onConfirm) {
          props.onConfirm();
        }
      }
    }, 1000);
    return () => {
      clearTimerInterval();
    };
  });

  const handleConfirm = () => {
    clearTimerInterval();
    if (props.onConfirm) {
      props.onConfirm();
    }
  };

  const handleClose = () => {
    clearTimerInterval();
    store.dispatch(showHideSessionModal({ isOpen: false }));
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            Your session has been expired, You will be logout in {timer} seconds
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.onClose ? (
            <Button onClick={handleClose} color="primary">
             Close
            </Button>
          ) : null}
          {props.onConfirm ? (
            <Button onClick={handleConfirm} color="primary">
              Ok
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return state.app.session;
};

export default connect(mapStateToProps)(SessionOut);
