import { Grid } from "@material-ui/core";
import React, { Component, Suspense } from "react";
import { Loader } from '@orijinworks/frontend-commons';
import Hoverable from "../../../../hoverable";
import styles from "./messages.style";
import { withStyles } from "@material-ui/styles";
import Datatable, { MTableToolbar, MTablePagination } from "material-table";
import { FnFMessagingService } from "../../FnFMessagingService";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import { formatPhoneNumber } from "../../../../../util/Helpers";
import MessageSearch from "./message-search";
import MessageHistory from "./message-history";
import CustomDatePicker from "../../../../users/datatable/customDatePicker";
import {
  getFormattedDate,
  getFormattedTime,
  isDateSame,
} from "../../../../users/learner/transcript/utils";
import MessageTitle from "./MessageTitle";
class Messages extends Component {
  tableRef = React.createRef();
  FnFMessagingService = new FnFMessagingService();
  beforeId = null;
  afterId = null;
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      pageNo: 0,
      rowsPerPage: 20,
      messageKeyword: "",
      filters: {
        senderName: "",
        recipientName: "",
        createdDate: "",
      },
      allowedFilters: ["senderName", "recipientName", "createdDate"],
    };
  }
  
  getSender = function(rowData) {
    if (rowData.senderPersonId === rowData.learner.personId) {
      return rowData.learner;
    }
    return rowData.contact;
  };
  getReciever = function(rowData) {
    if (rowData.senderPersonId !== rowData.learner.personId) {
      return rowData.learner;
    }
    return rowData.contact;
  };
  handleChangeMessageKeyword = (value) => {
    this.setState({ messageKeyword: value });
  };
  tableColumns = [
    {
      title: "Sent by",
      field: "senderName",
      width: "20%",
      customFilterAndSearch: (terms, rowData) => {
        const sender = this.getSender(rowData);
        const name = sender.firstName + " " + sender.lastName;
        if (terms.length === 0) return true;
        return name.toLowerCase().includes(terms.toLowerCase());
      },
      render: (rowData) => {
        const sender = this.getSender(rowData);
        return (
          <Hoverable title={"Sent by"}>
            <div className={this.props.classes.capitalize}>
              {`${sender.firstName} ${sender.lastName}`}
            </div>
            <div className={this.props.classes.phone}>
              {formatPhoneNumber(sender.phone)}
            </div>
          </Hoverable>
        );
      },
    },
    {
      title: "Received by",
      field: "recipientName",
      width: "20%",
      customFilterAndSearch: (terms, rowData) => {
        const reciver = this.getReciever(rowData);
        const name = reciver.firstName + " " + reciver.lastName;
        if (terms.length === 0) return true;
        return name.toLowerCase().includes(terms.toLowerCase());
      },
      render: (rowData) => {
        const reciver = this.getReciever(rowData);
        return (
          <Hoverable title={"Received by"}>
            <div className={this.props.classes.capitalize}>
              {`${reciver.firstName} ${reciver.lastName}`}
            </div>
            <div className={this.props.classes.phone}>
              {formatPhoneNumber(reciver.phone)}
            </div>
          </Hoverable>
        );
      },
    },
    {
      title: "Date",
      field: "createdDate",
      width: "20%",
      customFilterAndSearch: (terms, rowData) => {
        if (terms.length === 0) return true;
        return isDateSame(terms, rowData.createdDate);
      },
      filterComponent: (props) => <CustomDatePicker {...props} />,
      render: (rowData) => (
        <Hoverable title={rowData.createdDate}>
          <span className={this.props.classes.capitalize}>
            {getFormattedDate(rowData.createdDate)}
          </span>
        </Hoverable>
      ),
    },
    {
      title: <MessageTitle title={"Message"} />,
      field: "body",
      width: "40%",
      filterComponent: (props) => {
        return (
          <MessageSearch
            {...props}
            messageKeyword={this.state.messageKeyword}
            onChange={this.handleChangeMessageKeyword}
          />
        );
      },
      render: (rowData) => (
        <div>
          <div className={[this.props.classes.capitalize, this.props.classes.messageWrapper].join(" ")}>
            {rowData.body.length > 300
              ? rowData.body.substring(0, 300) + "..."
              : rowData.body}
          </div>
          <div className={this.props.classes.flexEnd}>
            <div className={this.props.classes.timeDiv}>
              {getFormattedTime(rowData.createdDate)}
            </div>
            <MessageHistory
              locationId={this.props.locationId}
              learnerPersonId={rowData.learner.personId}
              contactPersonId={rowData.contact.personId}
              refreshTable={this.refreshTable}
              messageId={rowData.messageId}
              firstMessage={rowData}
              timezoneName={this.props.timezoneName}
            />
          </div>
        </div>
      ),
    },
  ];
  refreshTable = () => {
    this.tableRef && this.tableRef.onQueryChange(this.tableRef.state.query);
  };

  getFilters = (query) => {
    let filters = { ...this.state.filters };
    for (let i = 0; i < query.filters.length; i++) {
      let item = query.filters[i];
      if (this.state.allowedFilters.includes(item.column.field)) {
        let value = item.value;
        if (Array.isArray(value) && value.length > 0) {
          value = [...item.value];
        }
        filters[item.column.field] = value;
      }
    }
    return filters;
  };

  getFnFMessages = (query) => {
    this.setState({
      showEmptyDataSourceMessage: false
    });
    return new Promise((resolve, reject) => {
      let afterId = null;
      let beforeId = null;
      if (query.page !== 0) {
        if (this.state.pageNo > query.page) {
          afterId = this.afterId;
        } else if (this.state.pageNo < query.page) {
          beforeId = this.beforeId;
        }
      }
      const pBody = this.getFilters(query);
      this.FnFMessagingService.getMessaages(
        this.props.locationId,
        query.pageSize,
        beforeId,
        afterId,
        { ...pBody, messageKeyword: this.state.messageKeyword }
      ).then((data) => {
        const { payload } = data;
        if (payload && payload.length > 0) {
          this.afterId = payload[0].messageId;
          this.beforeId = payload[payload.length - 1].messageId;
        }
        resolve({
          data: payload,
          page: query.page,
        });
        this.setState({
          showEmptyDataSourceMessage: true
        });
      });
    });
  };
  render() {
    return (
      <Suspense fallback={<Loader isLoading={true} />}>
        <div style={{ width: "100%" }}>
          <Grid container item>
            <Datatable
              title="Messages"
              style={{ width: "100%" }}
              tableRef={(ref) => (this.tableRef = ref)}
              data={this.getFnFMessages}
              onChangePage={(pageNo) => this.setState({ pageNo })}
              onChangeRowsPerPage={(rowsPerPage) =>
                this.setState({ rowsPerPage })
              }
              columns={this.tableColumns}
              icons={{
                Search: SearchIcon,
                ResetSearch: CloseIcon,
                FirstPage: () => <div></div>,
                LastPage: () => <div></div>,
                NextPage: NavigateNextIcon,
                PreviousPage: NavigateBeforeIcon,
                Filter: FilterListIcon,
                SortArrow: ArrowDownwardIcon,
                Export: SaveAltIcon,
                Edit: EditIcon,
              }}
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: `Page: ${this.state.pageNo}`,
                  lastAriaLabel: "",
                  firstAriaLabel: "",
                  lastTooltip: "",
                  firstTooltip: "",
                },
              }}
              options={{
                showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
                search: false,
                sorting: false,
                pageSize: this.state.rowsPerPage,
                filtering: true,
                actionsColumnIndex: 0,
                pageSizeOptions: [20, 50, 100],
                tableLayout: "fixed",
              }}
            />
          </Grid>
        </div>
      </Suspense>
    );
  }
}

export default withStyles(styles)(Messages);
