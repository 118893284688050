import { Box, Typography, withStyles } from "@material-ui/core";
import DOMPurify from "dompurify";
import React from "react";

import styles from "./single-announcement.styles";

const SingleAnnouncement = (props) => {
    const {
        classes,
        body,
        messageRef,
        title,
        createdDate,
        senderPersonName,
        isNotMyMessage,
        onDetailClick
    } = props;
    const announcementId = props.announcementId || '';

    const cleanBody = DOMPurify.sanitize(body);
    return (
        <Box
            ref={messageRef}
            className={[classes.annoucementBox].join(" ")}>
            {title && (
                <Typography className={[classes.title, classes.textSpacing].join(" ")}>
                    {title}
                </Typography>
            )}
            <Typography
                dangerouslySetInnerHTML={{ __html: cleanBody }}
                className={[classes.description, classes.textSpacing, classes.bodyLineBreak, classes.bodyAutoPhrase].join(" ")}>
            </Typography>
            <Typography
                onClick={() => onDetailClick({
                    announcementId,
                    createdDate,
                    senderPersonName,
                    isNotMyMessage,
                    body,
                    isNotMyMessage
                })}
                className={[classes.readReceipt, classes.textSpacing].join(" ")}>
                View Read Receipts
            </Typography>
        </Box>
    );
};


export default withStyles(styles)(SingleAnnouncement);