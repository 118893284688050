export default (theme) => ({
  outlinedComponent: {
    borderRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "none",
    width: "100%",
  },
  settingsRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 10,
    "&:last-child": {
      paddingBottom: 0,
    },
    "& > div:last-child": {
      border: "none",
    },
  },
  level1Box: {
    paddingLeft: 50,
    paddingRight: 10,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    paddingBottom: 10,
    marginTop: 10,
  },
  level2Box: {
    marginLeft: 30,
    paddingLeft: 20,
    marginRight: 10,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    paddingBottom: 10,
    marginTop: 10,
  },
  level3BoxAlt: {
    padding: "10px 0 10px 30px",
  },
  level3Box: {
    marginLeft: 30,
    paddingLeft: 60,
    marginRight: 10,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    paddingBottom: 10,
    marginTop: 10,
  },
  boxHeading1: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#3d3d3d",
    letterSpacing: "0.36px",
  },
  subHeading1: {
    fontSize: 12,
    color: "#747474",
    letterSpacing: "0.21px"
  },
  dayOfWeek: {
    fontSize: 15,
    color: "#3d3d3d",
    textTransform: "uppercase",
    letterSpacing: "0.98px",
  },
  dayOfWeekWrapper: {
    minWidth: 125,
  },
  scheduleWrapper: {
    minHeight: 100,
  },
  scheduleTextInput: {
    maxWidth: 95.5,
  },
  divider: {
    width: 7.5,
    margin: "0px 10px",
    backgroundColor: "black",
    height: 1,
  },
  verticalDivider: {
    width: 1,
    height: 36,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: "#979797",
  },
  slotReadOnly: {
    minWidth: 104,
    paddingLeft: 20,
    boxSizing: "border-box",
  },
  readOnlyDivider: {
    margin: 0,
  },
  featureTextField: {
    width: "98%",
    margin: "10px 0px 10px",
  },
  selectLabel: {
    backgroundColor: "white",
  },
  level3FullBorderBox: {
    paddingLeft: 50,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    paddingBottom: 10,
    marginTop: 10,
    paddingRight: 10,
    textAlign: "left",
  },
  noPaddBottom: {
    paddingBottom: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  noBorderBottom: {
    borderBottom: "none",
  },
  [theme.breakpoints.down("md")]: {
    level1Box: {
      paddingLeft: 10,
    },
    level2Box: {
      marginLeft: 0,
      paddingLeft: 10,
    },
    level3Box: {
      marginLeft: 0,
      paddingLeft: 10,
    },
    level3FullBorderBox: {
      paddingLeft: 10,
    },
  },
  disableFeature: {
    backgroundColor: "#f2f2f2 !important",
    cursor: "not-allowed",
  },
  cursorNotAllowed: {
    cursor: "not-allowed !important"
  },
  accoridionDetailsWrapper: {
    padding: '0 0 18px 48px',
    marginTop: 10,
    width: 'max-content',
  },
  boxWrapper:{
    backgroundColor: '#e2e2e2',
    padding: "10px 0 15px 8px",
  },
  deviceInput: {
    backgroundColor: '#ffffff',
  },
  maxItemsScroll: {
     display: 'block', 
     maxHeight: '256px', 
     overflowY: 'auto', 
     overflowX: 'hidden',
  },
  listMaxWith: {
    maxWidth: '97.5%',
  },
  errorIcon:{
    margin: "4px 8px 0px 0px",
    color: theme.palette.secondary.red && theme.palette.secondary.red.main
  },
  nameText: {
    ...theme.typography.bold,
  },
  errorWrapper: {
    top: '-60px',
    position: "absolute",
    backgroundColor: theme.palette.secondary.red && theme.palette.secondary.red.pale,
    padding: "5px 10px",
    borderRadius: 10,
    color: theme.palette.secondary.red && theme.palette.secondary.red.main,
    width: '95%',
    right: 2,
    border: `1px solid ${theme.palette.secondary.red && theme.palette.secondary.red.main}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeIcon: {
    fontSize: 30,
    fontWeight: 700,
    color: "#154157"
  },
  bodyMargin: {
    marginTop: 24
  },
  noCapitalizeText: {
    textTransform: 'none'
  }
});
