import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import styles from './bread-crumb-v2.style';
const BreadCrumbsV2 = ({ classes, items }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" separator=">" className={classes.main}>
      {items.map((item, index) =>
        item.href ? (
          <Link key={index} className={classes.breakCrumb} to={item.href}>
            {item.label}
          </Link>
        ) : (
          <Typography className={classes.breakCrumb} key={index}>{item.label}</Typography>
        )
      )}
    </Breadcrumbs>
  );
};

export default withStyles(styles)(BreadCrumbsV2);
