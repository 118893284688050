import React from "react";
import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./voice-call-access-toggle.component.style";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import useVoiceCallAccessToggleHook from "./useVoiceCallAccessToggleHook";

const VoiceCallAccessToggle=({isToggleOn, isDisabled, onUpdateResponse, userData, classes})=>{

  const { handleToggle, toggleState, disabled} = useVoiceCallAccessToggleHook({
    isToggleOn,
    onUpdateResponse,
    userData,
    isDisabled,
    classes,
  });

  return (
    <div>
      <Switch
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: toggleState  ? classes.trackOn : classes.trackOff,
          checked: classes.checked,
        }}
        checked={toggleState}
        onChange={handleToggle}
        name="checkedA"
        inputProps={{ "aria-label": "Toggle voice call access"}}
        icon={<CancelRoundedIcon  className={classes.thumbIcon} />}
        checkedIcon={<CheckCircleIcon className={classes.thumbIcon} />}
        disabled={disabled}
      />
    </div>
  );
}

export default withStyles(styles)(VoiceCallAccessToggle);
