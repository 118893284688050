export default (theme) => ({
  selectInput: {
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  smallText: {
    display: "inline-block",
    fontSize: 12,
    color: "#747474",
    paddingBottom: 20,
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: 20,
    marginTop: 24,
  },
});
