export default theme => ({
  paperRoot: {
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    padding: '15px 27px 0 27px',
    marginBottom: 17,
    boxSizing: 'border-box'
  },
  subheadingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20
  },
  subheading: {
    fontSize: 20,
    fontWeight: 500,
    margin: 0,
  },
  noHorizontalPadding: {
    paddingLeft: 0,
    paddingRight: 0
  },
  horizontalPadding: {
    padding: '0 27px'
  },
  saveBtn: {
    marginLeft: 10,
    paddingLeft: 31,
    paddingRight: 31
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  field: {
    width: '100%',
    height: 63,

    '& .MuiInputLabel-outlined': {
      fontSize: 14,
      transform: 'translate(14px, 17px) scale(1)',

      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -5px) scale(0.75)'
      }
    }
  },
  outlinedField: {
    fontSize: 12.8,
  },
  input: {
    padding: '15px 14px'
  },
  notAvailable: {
    textAlign: 'center',
    padding: '50px 0'
  }
})