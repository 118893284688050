import React from 'react';
import { Box, Typography, withStyles, Switch, TextField, Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import styles from './contact-management.style';
import { useContactManagementHook } from './useContactManagementHook';
const ContactManagement = ({ classes, locationId }) => {
  const {
    handleAdminCanApproveContactsAttributeChange,
    adminCanApproveContactsAttribute,
    handleIsMaxContactsPerLearnerLimitEnabledChange,
    isMaxContactsPerLearnerLimitEnabled,
    maxContactsPerLearnerLimit,
    handleMaxContactsPerLearnerLimitChange,
    handleUpdateMaxContactsPerLearnerLimit,
    snackbarMessage,
    snackbarOpen,
    handleCloseSnackbar,
    isSuccessSnackbar,
  } = useContactManagementHook({
    locationId,
  });
  return (
    <>
      {(Object.keys(adminCanApproveContactsAttribute).length > 0 ||
        Object.keys(isMaxContactsPerLearnerLimitEnabled).length > 0) && (
        <Box className={classes.mainWrapper}>
          <Box className={classes.innerWrapperLeft}>
            <Typography id="contact-management-section-heading" className={classes.heading}>
              Contact Management
            </Typography>
          </Box>
          {Object.keys(adminCanApproveContactsAttribute).length > 0 && (
            <Box className={classes.innerWrapper}>
              <Box className={classes.innerWrapperLeft}>
                <Typography id="approval-status-required-heading" className={classes.innerWrapperLeftSubHeading}>
                  Require Contacts to be approved by an admin
                </Typography>
              </Box>
              <Box className={classes.switchSection}>
                <Switch
                  id="admin-contact-approval-switch"
                  onChange={handleAdminCanApproveContactsAttributeChange}
                  checked={adminCanApproveContactsAttribute.attributeValue}
                  name="checkedA"
                />
              </Box>
            </Box>
          )}
          {Object.keys(isMaxContactsPerLearnerLimitEnabled).length > 0 && (
            <Box className={classes.innerWrapper}>
              <Box className={classes.innerWrapperLeft}>
                <Typography id="max-contacts-per-learner-enabled-heading" className={classes.innerWrapperLeftSubHeading}>
                  Limit number of contacts per learner
                </Typography>
              </Box>
              <Box className={classes.switchSection}>
                <Switch
                  id="max-contacts-per-learner-enabled-switch"
                  onChange={handleIsMaxContactsPerLearnerLimitEnabledChange}
                  checked={isMaxContactsPerLearnerLimitEnabled.attributeValue}
                  name="checkedB"
                />
              </Box>
            </Box>
          )}
          {Object.keys(isMaxContactsPerLearnerLimitEnabled).length > 0 &&
            isMaxContactsPerLearnerLimitEnabled.attributeValue === true && (
              <Box className={classes.innerWrapper}>
                <Box className={classes.innerWrapperLeft}>
                  <Typography id="max-contacts-per-learner-limit-heading" className={classes.innerWrapperLeftSubHeading}>
                    Max number of contacts per learner.
                  </Typography>
                </Box>
                <Box className={classes.minutesSection}>
                  <TextField
                    id="max-contacts-per-learner-limit"
                    className={classes.minutesInput}
                    type="number"
                    variant="outlined"
                    size="small"
                    margin="normal"
                    value={maxContactsPerLearnerLimit.attributeValue}
                    onChange={handleMaxContactsPerLearnerLimitChange}
                    inputProps={{ min: 1 }}
                  />
                  <Button
                    id="save-max-contacts-per-learner-limit"
                    className={classes.saveButton}
                    variant="contained"
                    color="primary"
                    onClick={handleUpdateMaxContactsPerLearnerLimit}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            )}
        </Box>
      )}
      <Snackbar id={'call-history-snackbar'} open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={isSuccessSnackbar ? 'success' : 'error'}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
export default withStyles(styles)(ContactManagement);
