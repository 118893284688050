import React from "react";

const TranscriptIcon = props => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-Transcript_Icon">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Transcript_Icon" data-name="Transcript Icon" clipPath="url(#clip-Transcript_Icon)">
        <path id="file-invoice-solid" d="M3,0A3,3,0,0,0,0,3V21a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V7.5H12A1.5,1.5,0,0,1,10.5,6V0Zm9,0V6h6ZM3.75,3h3a.75.75,0,0,1,0,1.5h-3a.75.75,0,0,1,0-1.5Zm0,3h3a.75.75,0,0,1,0,1.5h-3a.75.75,0,0,1,0-1.5Zm.75,4.5h9A1.5,1.5,0,0,1,15,12v3a1.5,1.5,0,0,1-1.5,1.5h-9A1.5,1.5,0,0,1,3,15V12A1.5,1.5,0,0,1,4.5,10.5Zm0,1.5v3h9V12Zm6.75,7.5h3a.75.75,0,0,1,0,1.5h-3a.75.75,0,0,1,0-1.5Z" transform="translate(3)" fill={fill}/>
      </g>
    </svg>
  );
};

TranscriptIcon.defaultProps = {
  size: 24,
  fill: "inherit"
}

export default TranscriptIcon;
