import React from 'reactn';
import PropTypes from 'prop-types';
import { AppBar, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import { Grid, Typography, Box, Menu, MenuItem, Toolbar } from '@material-ui/core';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import FolderIcon from '@material-ui/icons/Folder';
import {
  assignProgramToUser,
  manageStudentServices,
  qrylocation,
  unassignProgramFromUser,
  getUsersList,
  getAssignablePrograms,
  getUnAssignablePrograms
} from '../util/APIUtils';
import AssignmentConfirmationModal from './AssignmentConfirmationModal';
import DeleteUserConfirmationModal from './DeleteUserConfirmationModal';
import ClearIcon from '@material-ui/icons/Clear';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import { ASSIGNMENT_TYPE, SERVICES_NAMES, SUPPORT_EMAIL, USER_CONTEXT } from '../constants';
import DeleteIcon from '@material-ui/icons/Delete';
import WidgetsIcon from '@material-ui/icons/Widgets';
import { CustomTheme } from '@orijinworks/frontend-commons';
import { AVAILABILITY_TYPES } from '../v2/services/constants';

const headerHeight = 50;

const styles = theme => ({
  toolBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
  formControl: {
    margin: 0,
    minWidth: '100%',
    float: 'left'
  },
  inputs: {
    fontSize: '1.7em',
    marginTop: '-.5rem'
  },
  paper: {
    maxWidth: '100%'
  },
  mainDialog: {
    width: 800,
    maxWidth: '100%',
    padding: 8
  },
  mainErrorDialog: {
    width: 800,
    maxWidth: '100%',
    padding: 8,
    overflow: 'hidden'
  },
  dialogHeader: {
    backgroundColor: theme.palette.primary.blue,
    height: headerHeight,
    position: 'relative'
  },
  dialogTitle: {
    ...theme.typography.t16,
    width: 80,
    height: 31,
    fontWeight: 500,
    fontStretch: 'normal;',
    fontStyle: 'normal;',
    lineHeight: 'normal;',
    letterSpacing: '1.07px;',
    color: '#ffffff;',
    marginTop: 15,
    marginLeft: 15
  },
  assignIcon: {
    color: theme.palette.primary.battleshipGrey,
    marginBottom: 5,
    marginTop: 13,
    marginLeft: 16,
    color: '#fff'
  },
  closeIcon: {
    color: '#ffffff;',
    fontSize: 24
  },
  subHeader: {
    padding: 10,
    borderBottom: '1px solid rgba(0, 0, 0, 0.2);'
  },
  subHeaderText: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.3,
    letterSpacing: 0.25,
    color: 'rgba(125, 0, 0, 0.6)'
  },
  topMessage: {
    fontSize: 12,
    letterSpacing: 0.4,
    textAlign: 'right',
    color: '#737982',
    marginTop: 30
  },
  subHeaderChildContainer: {
    alignItems: 'flex-end'
  },
  okButton: {
    width: 50,
    right: 11,
    color: CustomTheme.palette.primary.main
  },
  dialogBody: {
    flex: 1,
    borderBottom: '1px solid #a2a2a2',
    overflow: 'hidden'
  },
  searchParent: {
    position: 'relative',
    height: 56,
    borderRadius: 4,
    border: 'solid 1px #a2a2a2',
    margin: '20px auto 0px'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 10
  },
  grow: {
    flexGrow: 1
  },
  inputRoot: {
    width: '100%',
    top: 10,
    left: -46
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  pathsParent: {
    maxHeight: 506,
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  pathItem: {
    margin: 0,
    cursor: 'pointer',
    height: 'auto'
  },
  pathItemBackground: {
    background: 'rgba(0, 0, 0, 0.08);'
  },
  pathwayCheckbox: {
    padding: 0
  },

  pathwayName: {
    marginLeft: 10,
    fontSize: 18,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.53,
    color: '#3d3d3d',
    textTransform: 'capitalize'
  },

  userContainer: {
    marginTop: 15
  },
  assignToLabel: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.5,
    color: '#737982'
  },
  chipsParent: {
    display: 'flex',
    maxHeight: 390,
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    },
    overflowY: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxHeight: 40
    }
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: 4,
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    background: '#efefef !important'
  },
  clearIcon: {
    color: 'black !important',
    '&:hover': {
      color: 'black !important'
    }
  },
  assignButton: {
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 0.73,
    color: '#ffffff !important',
    width: 135,
    height: 36,
    maxWidth: '100%',
    borderRadius: 4
  },
  disableAssignButton: {
    background: '#737982'
  },
  enableAssignButton: {
    background: CustomTheme.palette.primary.main,
    '&:hover': {
      backgroundColor: CustomTheme.palette.primary.main
    }
  },
  mainCompleteContainer: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  successMessage: {
    backgroundColor: 'rgba(125, 209, 149, 0.59)',
    color: '#2d633c',
    fontSize: 15,
    letterSpacing: 0.5,
    fontWeight: 'normal',
    height: 60,
    paddingTop: 35,
    width: '100%',
    marginTop: 16
  },
  errorMessage: {
    backgroundColor: 'rgba(209, 125, 125, 0.59)',
    color: '#7c1818',
    fontSize: 15,
    letterSpacing: 0.5,
    fontWeight: 'normal',
    height: 60,
    paddingTop: 35,
    width: '100%',
    marginTop: 16
  },
  doneButton: {
    width: 95
  },
  courseWrapper: {
    color: '#3d3d3d'
  },
  courseName: {
    ...theme.typography.t18,
    ...theme.typography.bold,
    color: '#3d3d3d',
    textTransform: 'uppercase'
  },
  folderIcon: {
    ...theme.typography.t20,
    color: '#3d3d3d'
  },
  successBox: {
    backgroundColor: 'rgba(125, 209, 149, 0.59)',
    borderRadius: 4,
    padding: '5px 15px'
  },
  successInfoSmallText: {
    color: 'rgba(45, 99, 60, 0.7)',
    ...theme.typography.t12,
    letterSpacing: '0.4px'
  },
  successMainText: {
    color: '#2d633c',
    ...theme.typography.t15,
    letterSpacing: '0.5px',
    marginTop: 5
  },

  errorBox: {
    backgroundColor: '#e3b2b2',
    borderRadius: 4,
    padding: '5px 15px'
  },
  errorInfoSmallText: {
    color: '#a2272d',
    ...theme.typography.t12,
    letterSpacing: '0.4px'
  },
  errorMainText: {
    color: '#a2272d',
    ...theme.typography.t15,
    letterSpacing: '0.5px',
    marginTop: 5
  },
  noRecordsFound: {
    textAlign: 'center',
    marginBottom: 15
  }
});

class PathAssginmentToUser extends React.Component {
  isOperationDone = false;
  ITEM_TYPES = {
    Path: 0,
    Service: 1
  };
  state = {
    filteredPaths: [],
    filteredServices: [],
    services: [],
    filteredItems: [],
    fetchedUserPathIds: null,
    completedProcessResponse: [],
    pathUsers: [],
    userAnchorEl: null,
    selectedUsers: [],
    search: '',
    open: true,
    paths: [],
    showSubHeader: true,
    completedProcessScreen: false,
    error: false,
    showLoader: false,
    userDeleteIndex: -1,
    openAssignmentConfirmationModal: false,
    selectedItems: [],
    selectedUserNames: [],
    locationId: null,
    type: null,
    pathAndServicesError: false
  };
  constructor(props) {
    super(props);

    this.assignPathsToUser = this.assignPathsToUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  /**
   * @name getUserServices
   * @param {array} userCollection
   * @desc Fetches user services from Learner card API.
   * @return {void}
   */
  getUserServices = userCollection => {
    return new Promise((resolve, reject) => {
      const requestPayload = {
        studentIds: userCollection.map(user => user.personId),
        showUnAssignableServicesOnly: true
      };
      getUsersList(requestPayload).then(
        _successLog => {
          const groupedServices = [];
          // GROUPING SERVICES BY NAME
          // AND ADDING UP USER LIST IF
          // SAME SERVICE IS FOUND IN OTHER
          // USER TOO.
          _successLog.userCards.forEach(user => {
            user.userServices.forEach(service => {
              const groupedServiceIndex = groupedServices.findIndex(
                groupedService => groupedService.id === service.serviceName
              );
              if (groupedServiceIndex > -1) {
                const userExist = !!groupedServices[groupedServiceIndex].users.find(
                  _user => _user.userName === user.userName
                );
                if (!userExist) {
                  groupedServices[groupedServiceIndex].users.push({
                    userId: user.userDetail.userId,
                    userName: user.userDetail.userName
                  });
                }
              } else {
                groupedServices.push({
                  name: service.serviceDisplayName,
                  id: service.serviceName,
                  selected: false,
                  users: [
                    {
                      userId: user.userDetail.userId,
                      userName: user.userDetail.userName
                    }
                  ]
                });
              }
            });
          });
          resolve(groupedServices);
        },
        _errorLog => {
          resolve([]);
        }
      );
    });
  };

  /**
   * @name getUserPaths
   * @param {array} userCollection
   * @desc Fetches user's paths.
   * @return {Promise}
   */
  getUserPaths = (locationId, learnerKeys) => {
    return new Promise((resolve, reject) => {
      getUnAssignablePrograms(locationId, learnerKeys).then(
        _successLog => {
          resolve(
            _successLog.map(path => {
              return {
                id: path.programKey,
                name: path.programName,
                selected: false,
                programAssigness: path.programAssignees
              };
            })
          );
        },
        () => resolve([])
      );
    });
  };

  /**
   * @name getUsersItems
   * @param {array} usersCollection
   * @desc Fetches users paths for unassignement.
   * @return {void}
   */
  getUsersItems = async (userCollection, locationId) => {
    const users = userCollection.map(user => user.personId).join(',');
    if (this.state.fetchedUserPathIds === users) {
      return false; // DATA ALREADY EXIST
    }

    this.setGlobal({ isLoading: true });
    try {
      const successLog = await Promise.all([this.getUserPaths(locationId, users), this.getUserServices(userCollection)]);
      this.setState({ paths: successLog[0], fetchedUserPathIds: users, services: successLog[1] });
    } catch (e) {
      console.log(e);
    }
    this.setGlobal({ isLoading: false });
  };

  handleSearchChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.open && this.props.open !== prevProps.open) {
      let paths = this.state.paths;
      let services = this.state.services;
      if (
        this.props.type === ASSIGNMENT_TYPE.ASSIGNE &&
        (this.props.locationId !== this.state.locationId || paths.length === 0 || services.length === 0)
      ) {
        this.getLocationServicesAndPaths(this.props.locationId);
      } else if (this.props.type === ASSIGNMENT_TYPE.UNASSIGNE || this.props.type === ASSIGNMENT_TYPE.DELETE) {
        this.getUsersItems(this.props.selectedUser, this.props.locationId);
      }
      this.setState({ selectedUsers: this.props.selectedUser, paths, showSubHeader: this.props.showSubHeader });
    }
  }

  isValidateAssignment = () => {
    let validate = true;

    // if no users or content to assign
    if (!this.state.selectedUsers.length || (!this.state.paths.length && !this.state.services.length)) {
      validate = false;
      return validate;
    }
    const items = this.getListItems(this.state.paths, this.state.services);
    for (let i = 0; i < items.length; i++) {
      if (items[i].selected) {
        validate = true;
        break;
      }
      validate = false;
    }
    return validate;
  };

  handleSearch = event => {
    const value = event.target.value;
    if (!value) {
      this.setState({ ...this.state, filteredItems: [], paths: this.state.paths, search: value });
      return;
    }

    const filteredPaths = this.state.paths.filter(path => {
      if ((path.name || '').toLowerCase().includes(value)) {
        return path;
      }
    });
    const filteredServices = this.state.services.filter(service => {
      if ((service.name || '').toLowerCase().includes(value)) {
        return service;
      }
    });

    this.setState({ ...this.state, filteredPaths, filteredServices, search: value });
  };

  openAssignmentConfirmationModal() {
    const itemCollection = [];
    this.getListItems(this.state.paths, this.state.services).forEach(item => {
      if (item.selected) {
        itemCollection.push(item.name);
      }
    });

    const users = this.state.selectedUsers.map(selectedUser => {
      return selectedUser.userName;
    });

    this.setState({
      ...this.state,
      selectedItems: itemCollection,
      selectedUserNames: users,
      openAssignmentConfirmationModal: true
    });
  }

  /**
   * @name getLocationServicesAndPaths
   * @param {number} locationId
   * @desc It fetches services and paths at location level.
   * @return {Promise}
   */
  getLocationServicesAndPaths = async locationId => {
    this.setState({ locationId });
    this.setGlobal({ isLoading: true });
    const locationServicesPromise = qrylocation(
      JSON.stringify({
        requestorId: USER_CONTEXT.personId,
        filterLocationId: locationId
      })
    );
    let locationPathsPromise = getAssignablePrograms(locationId);
    Promise.all([locationServicesPromise, locationPathsPromise])
      .then(result => {
        const { locationServices } = result[0];
        const paths = result[1];
        this.setState({
          services: locationServices
            .filter(service => 
              [
                AVAILABILITY_TYPES.BY_ASSIGNMENT_DEACTIVATE_ACCOUNT_ON_MOVE,
                AVAILABILITY_TYPES.BY_ASSIGNMENT_RETAIN_ACCOUNT_ON_MOVE
              ].includes(service.availabilityType))
            .map(service => {
              return {
                name: service.serviceDisplayName,
                id: service.serviceName,
                selected: false
              };
            }),
          paths: paths.map(path => {
            return {
              id: path.bundleId,
              name: path.bundleName,
              selected: false,
              hiddenInPathways: false
            };
          })
        });

        this.setGlobal({
          isLoading: false
        });
      })
      .catch(e => {
        console.log(e);
        this.setState({ pathAndServicesError: true });
        this.setGlobal({
          isLoading: false
        });
      });
  };

  assignPathsToUser(confirm) {
    if (!confirm) {
      this.setState({
        openAssignmentConfirmationModal: false
      });
      return;
    }
    if (!this.isValidateAssignment()) {
      return;
    }
    this.setState({ ...this.state, openAssignmentConfirmationModal: false }, async () => {
      const _global = this.global;
      this.setGlobal({ ..._global, isLoading: true });
      // store.dispatch(showHideLoader({ isLoading: true }));
      const bundles = [];
      const services = [];
      this.getListItems(this.state.paths, this.state.services).forEach(item => {
        if (item.selected) {
          if (item.type === this.ITEM_TYPES.Path) {
            bundles.push(item.id);
          } else if (item.type === this.ITEM_TYPES.Service) {
            services.push(item.id);
          }
        }
      });
      const users = this.state.selectedUsers.map(selectedUser => {
        return selectedUser.personId;
      });

      const locationKey = this.props.locationId;

      const payload = {
        programKeys: bundles,
        learnerPersonKeys: users,
        locationKey
      };
      const servicePayload = {
        locationId: locationKey,
        provisionServiceRequests: []
      };

      let assignmentResponses = null;
      try {
        if (this.props.type === ASSIGNMENT_TYPE.ASSIGNE) {
          servicePayload['provisionServiceRequests'] = services.map(service => {
            return {
              requestorId: USER_CONTEXT.personId,
              serviceName: service,
              active: true,
              users: users.map(user => {
                return {
                  personId: user
                };
              })
            };
          });
          assignmentResponses = Promise.all([assignProgramToUser(payload), manageStudentServices(servicePayload)]);
        } else if (this.props.type === ASSIGNMENT_TYPE.UNASSIGNE) {
          servicePayload['provisionServiceRequests'] = services.map(service => {
            return {
              requestorId: USER_CONTEXT.personId,
              serviceName: service,
              active: false,
              users: users.map(user => {
                return {
                  personId: user
                };
              })
            };
          });
          assignmentResponses = Promise.all([unassignProgramFromUser(payload), manageStudentServices(servicePayload)]);
        } else if (this.props.type === ASSIGNMENT_TYPE.DELETE) {
          assignmentResponses = Promise.all([unassignProgramFromUser(payload)]);
        }
        assignmentResponses.then(data => {
          this.isOperationDone = true;
          if (data[0] || data[1]) {
            this.setState({
              ...this.state,
              completedProcessScreen: true,
              completedProcessResponse: this.getProcessingItems(
                data.length > 0 ? data[0].programs : [],
                data.length > 1 ? data[1].provisionServiceResponses : []
              ),
              error: false
            });
            this.setGlobal({ ..._global, isLoading: false });
            // store.dispatch(showHideLoader({ isLoading: false }));
            return;
          }
          this.setState({ ...this.state, completedProcessScreen: true, error: true });
          this.setGlobal({ ..._global, isLoading: false });
          // store.dispatch(showHideLoader({ isLoading: false }));
        });
      } catch (e) {
        console.error(e, 'ERROR WHILE MANAGING OPERATION.');
      }
    });
  }

  /**
   * @name getProcessingItems
   * @param {array} pathList
   * @param {array} servicesList
   * @desc Prepares data store model for processing item list view.
   * @return {array}
   */
  getProcessingItems = (pathList, serviceList) => {
    const processingModel = [];
    pathList.forEach(path => {
      processingModel.push({
        parentName: path.programName,
        processing: path.programAssignees,
        type: this.ITEM_TYPES.Path
      });
    });
    // SERVICE MODELLING
    serviceList.forEach(service => {
      if (service.statusCode === 0) {
        processingModel.push({
          parentName: service.serviceDisplayName,
          processing: service.provisionServiceResults.map(result => {
            return {
              statusCode: service.statusCode === 0 ? result.statusCode : service.statusCode,
              services: [service.serviceName],
              userName: result.userName,
              statusMessage: result.statusMessage
            };
          }),
          type: this.ITEM_TYPES.Service
        });
      } else {
        processingModel.push({
          parentName: service.serviceDisplayName,
          processing: [
            {
              statusCode: service.statusCode,
              statusMessage: service.statusMessage
            }
          ],
          type: this.ITEM_TYPES.Service
        });
      }
    });

    return processingModel;
  };

  async done() {
    await this.setState({
      filteredItems: [],
      fetchedUserPathIds: null,
      completedProcessResponse: [],
      pathUsers: [],
      userAnchorEl: null,
      selectedUsers: [],
      search: '',
      open: true,
      paths: [],
      showSubHeader: true,
      completedProcessScreen: false,
      error: false,
      showLoader: false,
      userDeleteIndex: -1,
      openAssignmentConfirmationModal: false,
      selectedItems: [],
      selectedUserNames: []
    });
    this.props.onClose(this.isOperationDone);
  }

  async deleteUser(confirm) {
    if (this.state.userDeleteIndex === -1 || !confirm) {
      await this.setState({ ...this.state, showUserDeletionConfirmationmodal: false });
      return;
    }

    const deletedUser = this.state.selectedUsers.splice(this.state.userDeleteIndex, 1);
    this.props.deletedUsers.push(deletedUser[0].personId);
    await this.setState({
      ...this.state,
      selectedUsers: this.state.selectedUsers,
      showUserDeletionConfirmationmodal: false
    });
  }

  onPathSelect = (id, type) => {
    if (type === this.ITEM_TYPES.Path) {
      const path = this.state.paths.filter(path => path.id === id)[0];
      path.selected = !path.selected;
      this.setState({ ...this.state, paths: this.state.paths });
    } else if (type === this.ITEM_TYPES.Service) {
      const service = this.state.services.filter(service => service.id === id)[0];
      service.selected = !service.selected;
      this.setState({ ...this.state, service: this.state.services });
    }
  };

  /**
   * @name getString
   * @param {string} type Type of string required
   * @desc Returns string with respect to props.
   * @return {string}
   */
  getString = type => {
    if (!this.props.type) {
      return null;
    }
    const { classes } = this.props;
    const _strings = {
      [ASSIGNMENT_TYPE.ASSIGNE]: {
        icon: <AssignmentReturnIcon className={classes.assignIcon} />,
        heading: 'Assign',
        description: `Assigning a path or service will add it to the learner's Pathway or Explore page. If the learner hasn't been provisioned in a partner system previously, then the learner's account will be created in the partner system. Additionally, this will enroll the learners into the courses assigned. Note: this only provisioned and enrolled learners for partner systems that are fully integrated.`,
        submitBtn: 'ASSIGN',
        toText: 'Assigning To:'
      },
      [ASSIGNMENT_TYPE.UNASSIGNE]: {
        icon: <LayersClearIcon className={classes.assignIcon} />,
        heading: 'Unassign',
        description: `Content will no longer be visible on the learner's Pathway.`,
        submitBtn: 'UNASSIGN',
        toText: 'Unassigning From:'
      },
      [ASSIGNMENT_TYPE.DELETE]: {
        icon: <DeleteIcon className={classes.assignIcon} />,
        heading: 'Delete',
        description: `Content will be permanently removed from the learner's Pathway. This should be used only for content that was added by mistake.`,
        submitBtn: 'DELETE',
        toText: 'Deleting From:'
      }
    };

    return _strings[this.props.type][type];
  };

  manageUserMenu = (anchorEl, users) => {
    this.setState({
      userAnchorEl: anchorEl,
      pathUsers: users
    });
  };

  getSuccessText = (processed, total, username, services, type) => {
    const servicesWithoutName = [SERVICES_NAMES.Paxen, SERVICES_NAMES.D2L, SERVICES_NAMES.EsentialED];
    const isServiceIndexed = services.some(service => servicesWithoutName.indexOf(service) > -1);
    if (isServiceIndexed) {
      if (this.props.type === ASSIGNMENT_TYPE.UNASSIGNE) {
        if (type === this.ITEM_TYPES.Path) {
          return `Program has been unassigned from ${username}.`;
        } else if (type === this.ITEM_TYPES.Service) {
          return `${
            services.length > 0
              ? `${services.join(',')} has been successfully unassigned from ${username}.`
              : `Successfully unassigned from ${username}`
          }`;
        }
      } else if (this.props.type === ASSIGNMENT_TYPE.DELETE) {
        return `Program has been deleted from ${username}.`;
      } else if (this.props.type === ASSIGNMENT_TYPE.ASSIGNE) {
        if (type === this.ITEM_TYPES.Path) {
          return `Program has been assigned to ${username}.`;
        } else if (type === this.ITEM_TYPES.Service) {
          return `${
            services.length > 0
              ? `${services.join(',')} has been successfully assigned to ${username}.`
              : `Successfully assigned to ${username}`
          }`;
        }
      }
    } else {
      if (this.props.type === ASSIGNMENT_TYPE.UNASSIGNE) {
        if (type === this.ITEM_TYPES.Path) {
          return `Program has been unassigned from ${username}.`;
        } else if (type === this.ITEM_TYPES.Service) {
          return `${
            services.length > 0
              ? `${services.join(',')} has been successfully unassigned from ${username}.`
              : `Successfully unassigned from ${username}`
          }`;
        }
      } else if (this.props.type === ASSIGNMENT_TYPE.DELETE) {
        return `${
          services.length > 0
            ? `${services.join(',')} has been successfully deleted from ${username}.`
            : `Successfully deleted from ${username}`
        }`;
      } else if (this.props.type === ASSIGNMENT_TYPE.ASSIGNE) {
        if (type === this.ITEM_TYPES.Path) {
          return `Program has been assigned to ${username}.`;
        } else if (type === this.ITEM_TYPES.Service) {
          return `${
            services.length > 0
              ? `${services.join(',')} has been successfully assigned to ${username}.`
              : `Successfully assigned to ${username}`
          }`;
        }
      }
    }
  };

  getErrorText = (processed, total, username, services, type) => {
    if (this.props.type === ASSIGNMENT_TYPE.UNASSIGNE) {
      if (type === this.ITEM_TYPES.Path) {
        return `Could not unassign program from ${username}.`;
      } else if (type === this.ITEM_TYPES.Service) {
        return `Something went wrong while unassigning service.`;
      }
    } else if (this.props.type === ASSIGNMENT_TYPE.DELETE) {
      return `Could not delete program from ${username}.`;
    } else if (this.props.type === ASSIGNMENT_TYPE.ASSIGNE) {
      if (type === this.ITEM_TYPES.Path) {
        return `Could not assign program to ${username}.`;
      } else if (type === this.ITEM_TYPES.Service) {
        return `Something went wrong while assigning ${
          services.length > 0
            ? `${services.join(',')} services to ${username}.`
            : `Something went wrong while assigning course to ${username} `
        }`;
      }
    }
  };

  /**
   * @name getListItems
   * @param {array} paths
   * @param {array} services
   * @desc Combine's paths & services and returns a single list item for rendering purpose.
   * @return {array}
   */
  getListItems = (paths, services, type) => {
    let items = [];
    if (type === 'paths') {
      items = [
        ...Object.assign([], paths).map(path => {
          path['type'] = this.ITEM_TYPES.Path;
          return path;
        })
      ];
    } else if (type === 'services') {
      items = [
        ...Object.assign([], services).map(service => {
          service['type'] = this.ITEM_TYPES.Service;
          return service;
        })
      ];
    } else {
      items = [
        ...Object.assign([], paths).map(path => {
          path['type'] = this.ITEM_TYPES.Path;
          return path;
        }),
        ...Object.assign([], services).map(service => {
          service['type'] = this.ITEM_TYPES.Service;
          return service;
        })
      ];
    }

    return items.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  render() {
    const isAssignmentValidate = this.isValidateAssignment();
    const { classes } = this.props;
    return (
      <Card style={{ cursor: 'pointer', width: '100%' }}>
        <DeleteUserConfirmationModal
          type={this.props.type}
          open={this.state.showUserDeletionConfirmationmodal}
          onDelete={this.deleteUser}
        />
        <AssignmentConfirmationModal
          type={this.props.type}
          open={this.state.openAssignmentConfirmationModal}
          onConfirm={this.assignPathsToUser}
          items={this.state.selectedItems}
          users={this.state.selectedUserNames}
        />
        <Dialog
          className={classes.dialogStyle}
          classes={{
            paper: classes.paper
          }}
          open={this.props.open}
          onClose={() => {
            this.done();
          }}
        >
          <AppBar
            style={{
              position: 'relative'
            }}
          >
            <Toolbar className={classes.toolBar}>
              <Box display="flex">
                {this.getString('icon')}
                <Typography id="heading" className={classes.dialogTitle}>
                  {this.getString('heading')}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    this.done();
                  }}
                  color="inherit"
                  aria-label="close"
                >
                  <CloseIcon data-cy-name="close-modal-btn" className={classes.closeIcon} />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <DialogContent
            data-cy-name="redeem-dialog"
            container
            item
            className={this.state.completedProcessScreen ? classes.mainErrorDialog : classes.mainDialog}
          >
            {this.state.completedProcessScreen ? (
              <Box className={classes.mainCompleteContainer}>
                <Typography id="p" className={classes.topMessage}>
                  For support, contact APDS at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> with the listed error
                  code(s).
                </Typography>
                <Box flex={1}>
                  {this.state.completedProcessResponse.map((parentItem, pIndex) => (
                    <Box>
                      <Box key={pIndex} marginTop={5} display="flex" alignItems="center" className={classes.courseWrapper}>
                        {parentItem.type === this.ITEM_TYPES.Path && <FolderIcon />}
                        {parentItem.type === this.ITEM_TYPES.Service && <WidgetsIcon />}
                        &nbsp;
                        <Typography className={classes.courseName} marginLeft="10px">
                          {parentItem.parentName}
                        </Typography>
                      </Box>
                      {parentItem.processing.map((item, index) => (
                        <Box
                          marginBottom="10px"
                          key={`${pIndex}-${index}`}
                          className={item.statusCode === 0 ? classes.successBox : classes.errorBox}
                        >
                          <Typography className={item.statusCode === 0 ? classes.successMainText : classes.errorMainText}>
                            {item.statusCode === 0 &&
                              this.getSuccessText(
                                item.processed,
                                item.totalAssignment,
                                item.userName || item.learnerUsername,
                                item.services || [],
                                parentItem.type
                              )}
                            {item.statusCode !== 0 && item.statusMessage}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>

                <Grid container item direction="column" justify="center" alignItems="center">
                  <Button
                    color="primary"
                    data-cy-name="assign-button"
                    id="assign-button"
                    onClick={() => {
                      this.done();
                    }}
                    className={`${classes.assignButton} ${classes.enableAssignButton} ${classes.doneButton}`}
                  >
                    Done
                  </Button>
                </Grid>
              </Box>
            ) : (
              // show error here

              <Box>
                {/*
                    Modal Sub Header
                */}

                <Grid id="dialog" className={classes.dialogBody}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Box display="block" className={classes.searchParent}>
                        <Box display="block" className={classes.grow} />
                        <Box display="block" className={classes.search}>
                          <Box display="block" className={classes.searchIcon}>
                            <SearchIcon />
                          </Box>
                          <InputBase
                            id="searchBar"
                            placeholder="Search…"
                            value={this.state.search}
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput
                            }}
                            onChange={this.handleSearch}
                            // onKeyPress={(ev) => {
                            //   if (ev.key === 'Enter') {
                            //     this.fetchOmnisearch()
                            //     ev.preventDefault();
                            //   }
                            // }}
                          />
                          {/* <div className={classes.searchLoad}>{this.state.loading?<CircularProgress style={{height:16, width:16, color: 'white'}}/>:null}</div> */}
                        </Box>
                      </Box>
                      <Grid id="selectedUserContainer" container className={classes.userContainer}>
                        <Box display="block" className={classes.assignToLabel}>
                          {this.getString('toText')}
                        </Box>

                        <Box display="block" className={classes.chipsParent}>
                          {this.state.selectedUsers.map(selectedUser => {
                            return (
                              <Chip
                                // key={data.key}
                                // icon={icon}
                                label={selectedUser.userName}
                                onDelete={async () => {
                                  const index = this.state.selectedUsers.findIndex(
                                    user => selectedUser.personId === user.personId
                                  );
                                  await this.setState({
                                    ...this.state,
                                    userDeleteIndex: index,
                                    showUserDeletionConfirmationmodal: true
                                  });
                                }}
                                className={classes.chip}
                                deleteIcon={<ClearIcon className={classes.clearIcon} />}
                              />
                            );
                          })}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      md={8}
                      xs={12}
                      id="bundlesContainer"
                      container
                      item
                      direction="row"
                      className={classes.pathsParent}
                    >
                      <Box width="100%">
                        <Box component="h2">Services</Box>
                        <Box style={{ width: '100%' }}>
                          {(this.state.search !== ''
                            ? this.state.filteredServices
                            : this.getListItems(this.state.paths, this.state.services, 'services')
                          ).map((path, index) => {
                            return (
                              <Grid
                                container
                                item
                                direction="row"
                                // alignItems="right"
                                className={`${classes.pathItem} ${
                                  index === 0 || index % 2 === 0 ? classes.pathItemBackground : ''
                                }`}
                              >
                                <Grid item xs={2} container alignItems="center">
                                  <Checkbox
                                    id={`path-checkbox-${path.id}`}
                                    checked={path.selected}
                                    className={classes.pathonPathSelectwayCheckbox}
                                    style={{ padding: 8 }}
                                    onChange={() => {
                                      this.onPathSelect(path.id, path.type);
                                    }}
                                    value={path.id}
                                  />
                                </Grid>
                                <Grid container item xs={10} className={classes.pathwayNameContainer}>
                                  <Grid container alignItems="center" xs={9}>
                                    <Typography className={classes.pathwayName}>{path.name}</Typography>
                                  </Grid>
                                  {/* {((this.props.type === ASSIGNMENT_TYPE.UNASSIGNE) || (this.props.type === ASSIGNMENT_TYPE.DELETE)) && (
                                          <Grid
                                            container
                                            alignItems="center" xs={3} >
                                            <Button
                                              color="primary"
                                              aria-controls="users" aria-haspopup="true" onClick={(event) => this.manageUserMenu(event.currentTarget, path.users)}>
                                              {path.users && `${path.users.length} User`}
                                            </Button>
                                          </Grid>
                                        )} */}
                                </Grid>
                              </Grid>
                            );
                          })}
                          <Menu
                            classes={{
                              paper: classes.menuPaper
                            }}
                            anchorEl={this.state.userAnchorEl}
                            keepMounted
                            open={!!this.state.userAnchorEl}
                            onClose={event => this.manageUserMenu(null, this.state.pathUsers)}
                          >
                            {this.state.pathUsers.map(user => (
                              <MenuItem onClick={() => this.manageUserMenu(null, this.state.pathUsers)}>
                                {user.userName}
                              </MenuItem>
                            ))}
                          </Menu>
                        </Box>
                      </Box>
                      <Box width="100%">
                        <Box component="h2">Programs</Box>
                        <Box style={{ width: '100%' }}>
                          {this.state.pathAndServicesError ? (
                            <Typography className={classes.noRecordsFound}>
                              Something went wrong, please try again.
                            </Typography>
                          ) : (this.state.search !== ''
                              ? this.state.filteredPaths
                              : this.getListItems(this.state.paths, this.state.services, 'paths')
                            ).length > 0 ? (
                            (this.state.search !== ''
                              ? this.state.filteredPaths
                              : this.getListItems(this.state.paths, this.state.services, 'paths')
                            ).map((path, index) => {
                              return (
                                <Grid
                                  container
                                  item
                                  direction="row"
                                  // alignItems="right"
                                  spacing={2}
                                  className={`${classes.pathItem} ${
                                    index === 0 || index % 2 === 0 ? classes.pathItemBackground : ''
                                  }`}
                                >
                                  <Grid item xs={2} container alignItems="center">
                                    <Checkbox
                                      id={`path-checkbox-${path.id}`}
                                      checked={path.selected}
                                      className={classes.pathwayCheckbox}
                                      onChange={() => {
                                        this.onPathSelect(path.id, path.type);
                                      }}
                                      value={path.id}
                                    />
                                  </Grid>
                                  <Grid container spacing={2} item xs={10} className={classes.pathwayNameContainer}>
                                    <Grid container alignItems="center" xs={9}>
                                      <Typography className={classes.pathwayName}>{path.name}</Typography>
                                    </Grid>
                                    {(this.props.type === ASSIGNMENT_TYPE.UNASSIGNE ||
                                      this.props.type === ASSIGNMENT_TYPE.DELETE) && (
                                      <Grid container alignItems="center" xs={3}>
                                        <Button
                                          color="primary"
                                          aria-controls="users"
                                          aria-haspopup="true"
                                          onClick={event => this.manageUserMenu(event.currentTarget, path.programAssigness)}
                                        >
                                          {path.programAssigness && `${path.programAssigness.length} User`}
                                        </Button>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              );
                            })
                          ) : (
                            <Typography className={classes.noRecordsFound}>No records found</Typography>
                          )}
                          <Menu
                            classes={{
                              paper: classes.menuPaper
                            }}
                            anchorEl={this.state.userAnchorEl}
                            keepMounted
                            open={!!this.state.userAnchorEl}
                            onClose={event => this.manageUserMenu(null, this.state.pathUsers)}
                          >
                            {this.state.pathUsers.map(user => (
                              <MenuItem onClick={() => this.manageUserMenu(null, this.state.pathUsers)}>
                                {user.learnerUsername}
                              </MenuItem>
                            ))}
                          </Menu>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  direction="column"
                  justify="center"
                  alignItems="center"
                  id="assignContainer"
                  style={{ marginTop: 10 }}
                >
                  <Button
                    disabled={!isAssignmentValidate}
                    color="primary"
                    data-cy-name="assign-button"
                    id="assign-button"
                    onClick={() => {
                      this.openAssignmentConfirmationModal();
                    }}
                    className={`${classes.assignButton} ${
                      !isAssignmentValidate ? classes.disableAssignButton : classes.enableAssignButton
                    }`}
                  >
                    {this.getString('submitBtn')}
                  </Button>
                </Grid>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Card>
    );
  }
}

PathAssginmentToUser.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PathAssginmentToUser);
