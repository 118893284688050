import React from 'react';
import { CustomIcons, icons } from '../../../../../shared/components/custom-icons/CustomIcons';
import styles from '../new-conversation.styles';
import SendEditor from '../send-editor/send-editor';
import LinkUser from '../../../../../../components/link-user';
import { UnleashService } from '../../../../../services';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Snackbar,
  Typography,
  withStyles,
} from '../../../../../shared/components/MaterialUIComponents';
import { useNewMessageHook } from './useNewMessageHook';
import { RecipientView } from '../recipient-view/recipient-view';
import { TitleView } from '../title-view/title-view';
import MuiAlert from '@material-ui/lab/Alert';
import withUnleash from '../../../../../core/unleash/withUnleash';
import { useGlobal } from 'reactn';
import { USER_CONTEXT } from '../../../../../../constants';

const NewDirectMessage = (props) => {
  const { classes } = props;
  const [global] = useGlobal();

  const {
    isLoading,
    isLinkUserOpen,
    search,
    recipients,
    filteredLearners,
    isMessageSending,
    learners,
    body,
    location,
    openSnackbar,
    snackbarMessage,
    snackbarAutoHideDuration,
    isSuccessSnackbar,
    getUnselectedUser,
    onUserSelect,
    setLinkUserOpen,
    onSearchChange,
    removeSelectedUser,
    setBody,
    resetComponent,
    onMessageClose,
    fetchLearners,
    onSend,
    handleCloseSnackbar,
  } = useNewMessageHook(props);

  return (
    <>
      <Box className={classes.newConversationWrapper}>
        {/* Render title */}
        <TitleView classes={classes} title={'New Learner Message'} onClose={onMessageClose} />

        <Box className={classes.body}>
          {/* Render From  */}
          <RecipientView classes={classes} value={USER_CONTEXT.name} />

          <Box id="to-wrapper" className={classes.formGroup}>
            <Box className={classes.formLabel} component={'label'}>
              {' '}
              To:{' '}
            </Box>

            <Box className={classes.toWrapper}>
              {recipients.map((item) => (
                <Box
                  id={`selectedUsers-${item.contactId}`}
                  key={`selectedUsers-${item.contactId}`}
                  className={classes.selectedChip}
                >
                  <Typography className={classes.selectedChipName}>{item.contactName || item.locationName}</Typography>

                  <IconButton
                    onClick={() => removeSelectedUser(item)}
                    className={[classes.selectedCloseBtn, 'remove-selected'].join(' ')}
                  >
                    <CustomIcons className={classes.selectedChipCloseIcon} icon={icons.CloseIcon} />
                  </IconButton>
                </Box>
              ))}
              {recipients.length === 0 && (
                <Box className={classes.suggestionWrapper}>
                  <Box
                    id="search-value"
                    component={'input'}
                    type="text"
                    value={search}
                    onChange={(e) => onSearchChange(e.target.value)}
                    className={classes.formField}
                  />

                  {search !== '' && (
                    <Box className={classes.suggestionBox}>
                      {isLoading && <CircularProgress className={classes.loader} />}
                      <List>
                        {(search !== '' ? getUnselectedUser(filteredLearners) : getUnselectedUser(learners)).map((item) => (
                          <ListItem
                            key={item.contactId}
                            id={`suggestedUser-${item.contactId}`}
                            onClick={() => onUserSelect(item)}
                            button
                            className={[classes.suggestedChip].join(' ')}
                          >
                            <Typography className={classes.suggestedChipText}>
                              {`${item.contactName} ${item.contactUsername ? `- ${item.contactUsername}` : ''}`}
                            </Typography>
                          </ListItem>
                        ))}
                        {getUnselectedUser(filteredLearners).length === 0 && !isLoading && (
                          <ListItem
                            disabled
                            button
                            className={[classes.suggestedChip, classes.notFoundChip, 'error-box'].join(' ')}
                          >
                            <>
                              <Typography className={classes.suggestedChipText}>{search}</Typography>
                              &nbsp;-&nbsp;
                              <Typography className={classes.notFoundText}>NOT FOUND</Typography>
                            </>
                          </ListItem>
                        )}
                      </List>
                    </Box>
                  )}
                </Box>
              )}
              {!global.isAttorney && (
                <Button className={classes.createRelShipStyle} onClick={() => setLinkUserOpen(true)}>
                  Create Relationships
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <SendEditor
          isSendDisabled={isLoading || recipients.length === 0 || body === ''}
          onSend={onSend}
          onChange={(value) => {
            setBody(value);
          }}
          onComplete={() => {
            resetComponent();
          }}
          selectedUsers={recipients}
          isLoading={isMessageSending}
        />

        <LinkUser
          isOpen={isLinkUserOpen}
          onClose={() => setLinkUserOpen(false)}
          locations={[location]}
          userInfo={{
            userId: USER_CONTEXT.personId,
            displayName: USER_CONTEXT.name,
            role: USER_CONTEXT.role,
          }}
          getRelationships={() => fetchLearners()}
        />
        <Snackbar
          id={'call-history-snackbar'}
          open={openSnackbar}
          autoHideDuration={snackbarAutoHideDuration}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={isSuccessSnackbar ? 'success' : 'error'}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Box>
    </>
  );
};

export default withStyles(styles)(withUnleash(NewDirectMessage, UnleashService.FLAGS.SHOW_SNACK_BAR_ON_MESSAGE_SENT));
