import React from 'react';

export default ({ staticContext = {} }) => {
  staticContext.status = 404;
  return ( 
    <div style={{marginTop:'7rem', textAlign:'center'}}>
      <h2>Hm... this page doesn't seem to exist.</h2>
      <span>Please check to make sure you have the right address, or try again.</span>
    </div>
  )
};