import React, { Component } from 'reactn';
import {
  withStyles,
  Typography,
  Box,
  Card,
  CardContent,
  Tooltip
} from '@material-ui/core';
import { Accordion, Loader } from '../../..';
import styles from '../index.style'
import { CustomCheckbox } from '../../../../custom-checkbox';
import { updateManagementAttributes, updateUserAttributes } from '../../../../../util/APIUtils';
import { ATTRIBUTE_NAMES } from '../../../../../constants';
import PostAccessConfirmation from "../../../../../components/post-release-confirmation/";
export class PostReleaseLearner extends Component {
  FACILITY_DEFAULT = "external";
  LEARNER_DEFAULT = "internal/external";

  FACILITY_DEFAULT_ATTR_VALUE = null;
  LEARNER_DEFAULT_ATTR_VALUE = "";

  constructor(props) {
    super(props)
    this.state = {
      showLoader: false,
      prStatus: false,
      postReleaseAttribute: {},
      tooltipText: "Turn on to give this learner account permission to access Learner Portal content prom non-whitelisted IP addresses. If turned off, access is determined by the location setting (internal or external).",
      isDisabled: false,
      showConfirmationModal: false
    }
  }

  toggleConfirmationModal = (showConfirmationModal) => {
    this.setState({
      showConfirmationModal: showConfirmationModal || !this.state.showConfirmationModal
    });
  };

  getString(id) {
    const { locationType } = this.props;
    const literals = {
      "facility": {
        "heading": "Post-Release Access",
        "description": "If turned on, learners at this location will only be able to access the Learner Portal from locations outside of a correctional facility. This setting is suitable for learners who should retain access to the Learner Portal after release."
      },
      "learner": {
        "heading": "Enable Access for Internal Testing",
        "description": "Turn on to give this learner account permission to access Learner Portal content from non-whitelisted IP addresses. If turned off, access is determined by the location setting (internal or external)."
      }
    };
    return literals[locationType][id];
  };

  componentDidMount() {
    this.fetchAttributes();
  }

  componentDidUpdate() {
    let { postReleaseAttribute } = this.state;
    if (Object.keys(postReleaseAttribute) === 0) {
      this.fetchAttributes();
    }
  };

  fetchAttributes = () => {
    const { attributes, locationType } = this.props;
    if (!attributes) {
      return;
    };

    const userLevelPR = attributes.find((item) => item.attributeName === ATTRIBUTE_NAMES.PORTAL_ACCESS)
    let postReleaseAttribute = {
      attributeName: 'Portal_Access',
      attributeValue: this[locationType.toUpperCase() + "_DEFAULT_ATTR_VALUE"]
    }
    if (userLevelPR) {
      postReleaseAttribute = userLevelPR
    }
    this.setState({
      prStatus: (postReleaseAttribute.attributeValue === this[locationType.toUpperCase() + "_DEFAULT"]),
      postReleaseAttribute: postReleaseAttribute
    });
  }

  handleChange = () => {
    const { locationType } = this.props;
    this.setState({ showLoader: true })
    let attribute = { ...this.state.postReleaseAttribute };
    attribute.attributeValue = this.state.prStatus ? this[locationType.toUpperCase() + "_DEFAULT_ATTR_VALUE"] : this[locationType.toUpperCase() + "_DEFAULT"];
    let callback = null;
    if (locationType === "learner") {
      let putBody = {
        personId: this.props.id,
        personAttributes: [attribute]
      };
      callback = updateUserAttributes(putBody);
    } else {
      let locationBody = {
        locationId: this.props.id,
        locationAttributes: [attribute]
      };
      callback = updateManagementAttributes(locationBody);
    };

    callback
      .then(updated => {
        this.setState({ showLoader: false, prStatus: !this.state.prStatus })
      })
      .catch(e => {
        this.setState({ showLoader: false })
        console.log(e)
      })
  }

  render() {
    const { classes, locationType } = this.props
    const { isDisabled, tooltipText, prStatus } = this.state;

    const MainContent = (
      <Card variant="outlined" className={[classes.outlinedComponent, isDisabled ? classes.disableFeature : ""].join(" ")}>
        <PostAccessConfirmation
          updatingValue={!this.state.prStatus}
          styles={{
            heading: {
              marginBottom: 20
            }
          }}
          isOpen={this.state.showConfirmationModal}
          onContinue={() => {
            this.toggleConfirmationModal(false);
            this.handleChange();
          }
          }
          onClose={() => {
            this.toggleConfirmationModal(false);
          }}
        />
        <CardContent
          classes={{
            root: [classes.settingsRoot, classes.noPaddBottom].join(" ")
          }}
        >
          <Box
            component="div"
            justifyContent="space-between"
            className={[classes.level1Box, classes.noBorderBottom].join(" ")}
            display="flex"
          >
            <Box
              component="div"
              textAlign="left"
            >
              <Typography className={classes.boxHeading1}>
                {this.getString("heading")}
              </Typography>
              <Typography className={classes.subHeading1}>
                {this.getString("description")}
              </Typography>
            </Box>
            <Tooltip title={isDisabled ? tooltipText : ""}>
              <Box
                display="flex"
                alignItems="center"
                component="div"
              >
                {this.state.showLoader && (<Loader />)}
                <CustomCheckbox
                  id="postrelease-status-checkbox"
                  className={isDisabled ? classes.cursorNotAllowed : ""}
                  onChange={() => {
                    const {locationType} = this.props;
                    if (locationType !== "learner") {
                      this.toggleConfirmationModal()
                    } else { //PROCEEDING W/O MODAL IN CASE OF LEARNER
                      this.handleChange();
                    };
                  }}
                  checked={prStatus}
                  disabled={isDisabled}
                />
              </Box>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    );
    if (locationType === "facility") {
      return MainContent;
    } else {
      return (
        <Accordion id="postrelease-accordion" title="Internal Testing Access">
          {MainContent}
        </Accordion>
      );
    };
  };
}

export default withStyles(styles)(PostReleaseLearner);
