import React, { useState } from 'react';
import DeleteFileDialog from '../../forms/delete-file-dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import styles from './index.style';
import { withStyles } from '@material-ui/styles';

const RoleTag = (props) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const {
        classes,
        children,
        roleId,
        locationId,
        isRemovable
    } = props;

    const handleDeleteDialogClose = () => {
        setIsOpen(false);
    }

    const onRemoveRole = () => {
        props.onRemoveRole(roleId, locationId);
    }

    return (
        <React.Fragment>
            <div className={classes.roleTag}>
                {children}
                {isRemovable && 
                    <div
                        className={classes.roleTagClose}
                        onClick={() => setIsOpen(true)}
                    >
                        <CancelIcon fontSize="small" />
                    </div>
                }
            </div>
            <DeleteFileDialog
                onAgree={onRemoveRole}
                isOpen={isOpen}
                handleClose={handleDeleteDialogClose}
            />
        </React.Fragment>
    );
};


export default withStyles(styles)(RoleTag);