import React from 'reactn';
import { Box, Typography, Button, Grid, Snackbar } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MuiAlert from '@material-ui/lab/Alert';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import Datatable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import SortIcon from '@material-ui/icons/Sort';
import RoleAssignmentDialog from '../roles-assignments-modal';
import styles from './index.style';
import { withStyles } from '@material-ui/styles';
import DeleteFileDialog from '../../forms/delete-file-dialog';
import {
  getRolesAssignmentList,
  deleteRoleAssignment,
  addRolesAssignment,
  deleteSingleRoleAssignment
} from '../../../../util/APIUtils';
import { PermissionControl } from '../../../permission-control/permission-control';
import Hoverable from '../../../hoverable';
import RoleTag from '../role-tag';
import { theme } from '../../../../v2/core/containers/theme';

class RolesAssignment extends React.Component {
  RolesAssignmentColumns = [
    {
      title: 'Facility',
      field: 'locationName',
      render: rowData => <Hoverable title={rowData.locationName}>{rowData.locationName}</Hoverable>
    },
    {
      title: 'Roles',
      field: 'roleNames',
      render: rowData => {
        const roleNames = rowData.roleNames.split(',');
        const roleIds = rowData.roleIds.split(',');
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {roleNames.map((role, index) => {
              const roleId = roleIds[index].trim();
              return (
                <RoleTag
                  key={roleId}
                  onRemoveRole={this.onRemoveRole}
                  roleId={roleId}
                  locationId={rowData.locationId}
                  isRemovable={roleNames.length > 1}
                >
                  {role}
                </RoleTag>
              );
            })}
          </div>
        );
      }
    },
    {
      field: 'delete',
      title: '',
      editable: false,
      filtering: false,
      export: false,
      render: rowData => {
        return (
          <PermissionControl
            permissionName="Unassign_Admin_From_Different_Location"
            forceRestriction={this.state.data.length < 2}
          >
            <DeleteIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.setState({ deleteData: rowData, isOpen: true });
              }}
            />
          </PermissionControl>
        );
      }
    }
  ];
  state = {
    data: null,
    isNewAssignmentDialogOpen: false,
    deleteData: null,
    isOpen: false,
    showErrorMsg: false,
    errorMsg: ''
  };

  /**
   * @name getRolesAssignmentList
   * @param {string} staffName
   * @desc Fetches details by staff name
   * @return {void}
   */
  getRolesAssignmentList = staffName => {
    this.setGlobal({
      isLoading: true
    });
    this.setState({
      showEmptyDataSourceMessage: false
    });
    getRolesAssignmentList(staffName).then(
      _successLog => {
        this.setGlobal({
          isLoading: false
        });
        this.setState({
          data: _successLog,
          showEmptyDataSourceMessage: true
        });
      },
      _errorLog => {
        this.setGlobal({
          isLoading: false
        });
        console.error('Something went wrong while fetching...');
      }
    );
  };

  componentDidMount() {
    if (!this.state.data) {
      this.getRolesAssignmentList(this.props.userDetail.userName);
    }
  }

  refreshStaffList = () => {
    const { refreshStaffList } = this.props;
    if (refreshStaffList) {
      refreshStaffList();
    }
  };

  handleNewAssignmentDialogClose = () => {
    this.setState({
      isNewAssignmentDialogOpen: false
    });
  };

  handleNewAssignmentDialogOpen = () => {
    this.setState({
      isNewAssignmentDialogOpen: true
    });
  };

  handleDeleteDialogClose = () => {
    this.setState({
      deleteData: null,
      isOpen: false
    });
  };

  handleCloseErrorMsg = () => {
    this.setState({
      showErrorMsg: false,
      errorMsg: ''
    });
  };
  onAgree = async () => {
    try {
      await deleteRoleAssignment(this.state.deleteData.locationId, this.props.userDetail.userName);
      this.getRolesAssignmentList(this.props.userDetail.userName);
      this.setState({ isOpen: false, deleteData: null });
      this.refreshStaffList();
    } catch {
      this.setState({
        showErrorMsg: true,
        errorMsg: 'Operation failed, please contact administrator.'
      });
    }
  };

  onRemoveRole = async (roleId, locationId) => {
    try {
      await deleteSingleRoleAssignment(roleId, locationId, this.props.userDetail.userName);
      this.getRolesAssignmentList(this.props.userDetail.userName);
      this.refreshStaffList();
    } catch {
      this.setState({
        showErrorMsg: true,
        errorMsg: 'Operation failed, please contact administrator.'
      });
    }
  };

  get isActionAllowed() {
    if (this.props.isActionAllowed.toString()) {
      // CHECKING IF BOOLEAN PROP EXIST OR NOT
      return this.props.isActionAllowed;
    }
    return true; // BACKWARD COMPATIBILITY IN CASE ITS NOT DEFINED.
  }

  submitAssignment = async values => {
    const payload = {
      locationId: values.location,
      userName: this.props.userDetail.userName,
      roleId: values.roles
    };
    this.setGlobal({
      isLoading: true
    });
    try {
      await addRolesAssignment(payload);
      this.getRolesAssignmentList(this.props.userDetail.userName);
      this.setGlobal({
        isLoading: false
      });
      this.refreshStaffList();
    } catch (error) {
      if (error.status === 409) {
        this.setState({
          showErrorMsg: true,
          errorMsg: `This role is already assigned to the staff`
        });
      } else {
        this.setState({
          showErrorMsg: true,
          errorMsg: 'Operation failed, please contact administrator.'
        });
      }
      this.setGlobal({
        isLoading: false
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Snackbar
          open={this.state.showErrorMsg}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={this.handleCloseErrorMsg}
        >
          <MuiAlert severity="error" elevation={6} variant="filled">
            {this.state.errorMsg}
          </MuiAlert>
        </Snackbar>
        <Box width="100%" boxShadow={3}>
          <Datatable
            style={{ width: '100%' }}
            columns={this.RolesAssignmentColumns}
            data={this.state.data || []}
            components={{
              Toolbar: props => (
                <div>
                  <Box className={classes.titleBox}>
                    <Typography component="span" className={classes.tabName}>
                      Roles
                    </Typography>
                    {this.isActionAllowed && (
                      <PermissionControl permissionName="Assign_Admin_To_Different_Location">
                        <Button
                          className={classes.addBtn}
                          color="primary"
                          variant="contained"
                          onClick={this.handleNewAssignmentDialogOpen}
                        >
                          <AddIcon />
                        </Button>
                      </PermissionControl>
                    )}
                  </Box>
                </div>
              )
            }}
            detailPanel={[
              {
                tooltip: 'Roles Details',
                icon: ExpandMoreIcon,
                render: rowData => {
                  return (
                    <div
                      style={{
                        fontSize: 100,
                        textAlign: 'center',
                        width: '100%',
                        height: '315',
                        color: theme.palette.text.red,
                        backgroundColor: 'red'
                      }}
                    >
                      {rowData.name}
                    </div>
                  );
                }
              }
            ]}
            options={{
              sorting: false,
              pageSize: 20,
              selection: false,
              showSelectAllCheckbox: true,
              filtering: false,
              Sort: SortIcon,
              showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
              SortArrow: ArrowDownwardIcon
            }}
            icons={{
              FirstPage: FirstPageIcon,
              LastPage: LastPageIcon,
              NextPage: NavigateNextIcon,
              PreviousPage: NavigateBeforeIcon
            }}
          />
          <RoleAssignmentDialog
            open={this.state.isNewAssignmentDialogOpen}
            handleClose={this.handleNewAssignmentDialogClose}
            userDetail={this.props.userDetail}
            submitAssignment={this.submitAssignment}
          />
          <DeleteFileDialog onAgree={this.onAgree} isOpen={!!this.state.isOpen} handleClose={this.handleDeleteDialogClose} />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(RolesAssignment);
