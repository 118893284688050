import React from "reactn";
import {
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Dialog,
  withStyles,
  Box,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Datatable from "material-table";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import SortIcon from "@material-ui/icons/Sort";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import moment from "moment";
import {
  getTimeOnTaskDetails,
  getTimeOnTaskDetailsAtLocations,
  exportTimeonTaskAtLocations,
  getPermittedFacilities,
  getPermittedLocations,
} from "../../util/APIUtils";
import { CONTENT_AREAS_ENUM, TOT_DETAILS_ENUM } from "../../constants";
import ContentAreas from "../content-areas";
import Hoverable from "../hoverable";
import { UserIcon } from "../UserIcon";

const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  notesHeading: {
    ...theme.typography.t20,
    ...theme.typography.medium,
    color: theme.palette.primary.jetBlack,
    letterSpacing: "0.15px",
    lineHeight: "24px",
  },
});

class ToTDetails extends React.Component {
  contentAreaRef = null;
  state = {
    data: null,
    pagination: {
      page: 0,
      pageSize: 10,
    },
    contentAreas: [],
  };
  tableRef = React.createRef();

  /**
   * @name resetPagination
   * @param {function} callback Callback fired when state updates.
   * @desc Sets pagination to initial stage.
   * @return {void}
   */
  resetPagination = (callback) => {
    this.tableRef.current.onChangePage(null, 0);
  };

  /**
   * @name checkScrollEnds
   * @desc Attaches listener and checks for scroll end.
   * @return {void}
   */
  checkScrollEnds = (event) => {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (this.tableRef && !this.state.isLoading && !this.noMoreData) {
        this.tableRef.current.onQueryChange();
      }
    }
  };

  /**
   * @name getDateRange
   * @desc prepares a date range from underlying logic.
   * @return {object}
   */
  getDateRange = () => {
    const { range } = this.global;
    if (!range) {
      return {
        startDate: "",
        endDate: "",
      };
    }
    return {
      startDate: range.startDate,
      endDate: range.endDate,
    };
  };

  /**
   * @name getServiceData
   * @param {number} userId
   * @param {array} contentAreas
   * @param {number} pageNo
   * @param {number} pageSize
   * @desc A wrapper method which determine's which method should be called
   * based upon this.props.type.
   * @return {Promise}
   */
  getServiceData = async (id, contentAreas, pageNo, pageSize) => {
    if (this.props.type === TOT_DETAILS_ENUM.Learner) {
      return getTimeOnTaskDetails(
        id,
        contentAreas.join(","),
        pageNo,
        pageSize,
        this.getDateRange().startDate,
        this.getDateRange().endDate,
      );
    } else if (this.props.type === TOT_DETAILS_ENUM.Location) {
      const allowedLocationIds = await this.getAllowedLocationIds(id);
      return getTimeOnTaskDetailsAtLocations(
        allowedLocationIds.join(","),
        contentAreas.join(","),
        pageNo,
        pageSize,
        this.getDateRange().startDate,
        this.getDateRange().endDate,
      );
    }
  };

  /**
   * @name getTimeOnTaskData
   * @param {object} query Pagination query
   * @param {number | array} id
   * @param {array} contentAreas
   * @desc Fetche's time on task details w.r.t user.
   * @return {void}
   */
  getTimeOnTaskData = (query, id, contentAreas = []) => {
    this.setState({
      isLoading: true,
      contentAreas: [...contentAreas],
    });
    const { pagination } = this.state;
    let { page, pageSize } = query;
    page++;
    return new Promise((resolve, reject) => {
      this.getServiceData(id, contentAreas, page, pageSize).then(
        (_successLog) => {
          const { timeOnTasks, totalRecordsCount } = _successLog;
          this.setState({
            isLoading: false,
            pagination: query,
          });
          resolve({
            data: timeOnTasks,
            page: query.page,
            totalCount: totalRecordsCount,
          });
        },
        (_errorLog) => {
          this.setState({
            isLoading: false,
          });
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
        },
      );
    });
  };

  /**
   * @name getString
   * @param {string} type
   * @desc Prepare's string based upon props and type of string.
   * @return {string}
   */
  getString = (type) => {
    if (this.props.type === TOT_DETAILS_ENUM.Learner) {
      switch (type) {
        case "heading":
          return (
            this.props.heading ||
            `${this.props.userDetail.firstName} ${this.props.userDetail.lastName}`
          );

        case "name":
          return `${this.props.userDetail.firstName} ${this.props.userDetail.lastName}`;

        case "id":
          return this.props.userDetail.userId;

        default:
          return null;
      }
    } else if (this.props.type === TOT_DETAILS_ENUM.Location) {
      switch (type) {
        case "heading":
        case "name":
          return this.props.heading;
        case "exportFileName":
          return this.props.exportFileName;
        case "id":
          return this.props.locations.map(({ locationId }) => locationId);
        default:
          return null;
      }
    }
  };

  /**
   * @name getAllowedIds
   * @param {array} locationIds
   * @desc This method fetches allowed locationIDS and returns filtered version of it.
   * @return {array}
   */
  getAllowedLocationIds = (locationIds) => {
    return new Promise(async (resolve, reject) => {
      try {
        let allowedLocationsIds = [];
        if (["HU", "FACILITY"].includes(this.props.locationType)) {
          const locationId =
            this.props.locationType === "HU"
              ? this.props.parentLocationId
              : this.props.activeLocationId;
          allowedLocationsIds = await getPermittedLocations(
            locationId,
            "View_Individualized_Data_At_The_Hu_Level",
          );
        } else {
          allowedLocationsIds = await getPermittedFacilities(
            "VIEW_INDIVIDUALIZED_DATA_AT_THE_HU_LEVEL",
          );
        }
        const filteredLocationIds = locationIds.filter((locationId) => {
          return !!allowedLocationsIds.find(
            (allowedLocationId) => locationId === allowedLocationId,
          );
        });
        resolve(filteredLocationIds);
      } catch (e) {
        resolve([]);
        console.error("Error While Fetching Permission.", e);
      }
    });
  };

  /**
   * @name exportToCsv
   * @param {array} columns
   * @param {array} data
   * @desc Exports data to CSV based upon CSV blob from server.
   * @return {void}
   */
  exportToCsv = (columns, data) => {
    this.setGlobal({
      isLoading: true,
    });
    const selectedContentAreas = this.getSelectedContentArea();
    exportTimeonTaskAtLocations(
      this.getString("id"),
      selectedContentAreas.join(","),
      this.getDateRange().startDate,
      this.getDateRange().endDate,
    ).then(
      (_successLog) => {
        this.setGlobal({
          isLoading: false,
        });
        const csvContent = `data:text/csv;charset=utf-8,${_successLog.csvString}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute(
          "download",
          `${this.getString(
            "exportFileName",
          )}-TimeOnTaskDetails-${moment().format("MM-DD-YYYY")}.csv`,
        );
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
      },
      (_errorLog) => {
        this.setGlobal({
          isLoading: false,
        });
      },
    );
  };

  /**
   * @name exportStudentsRecords
   * @desc Fetch's entire result and then exports it in CSV form.
   * @return {void}
   */
  exportStudentRecords = async () => {
    this.setGlobal({
      isLoading: true,
    });
    try {
      const { pagination } = this.state;
      const data = await this.getServiceData(
        this.getString("id"),
        this.getSelectedContentArea(),
        0,
        pagination.totalCount,
      );
      const columns = this.getColumns();
      let csvString = "";

      const columnString = columns.map((column) => column.title).join(",");

      // APPENDING HEADER
      csvString += columnString + "\r\n";

      data.timeOnTasks.forEach((row) => {
        columns.forEach((column) => {
          if (row[column.field]) {
            csvString += row[column.field] + ",";
          } else {
            csvString += "N/A,";
          }
        });
        csvString += "\r\n";
      });
      const csvContent = `data:text/csv;charset=utf-8,${csvString}`;
      this.exportFile(
        `${this.getString("name")}-TimeOnTaskDetails-${moment().format(
          "MM-DD-YYYY",
        )}`,
        csvContent,
      );
      this.setGlobal({
        isLoading: false,
      });
    } catch (e) {
      console.log(e);
      this.setGlobal({
        isLoading: false,
      });
    }
  };

  /**
   * @name exportFile
   * @param {string} fileName
   * @param {string} csvContent
   * @desc Handle's DOM file export functionality.
   * @return {void}
   */
  exportFile = (fileName, csvContent) => {
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "${fileName}.csv".
    document.body.removeChild(link);
  };

  /**
   * @name getSelectedContentArea
   * @desc Based upon props, this method decides
   * from where content area needs to be fetched.
   * @return {array} selectedContentArea
   */
  getSelectedContentArea = () => {
    if (this.props.contentAreaVisible) {
      return this.contentAreaRef.getSelectedContentAreas();
    } else {
      return this.props.contentAreas;
    }
  };

  /**
   * @name getColumns
   * @desc Based upon props.type column is being returned.
   * @return {array} columns
   */
  getColumns = () => {
    if (this.props.type === TOT_DETAILS_ENUM.Learner) {
      return [
        {
          title: "Date",
          field: "totDate",
          render: (rowData) => {
            return (
              <Hoverable
                title={moment.parseZone(rowData.totDate).format("MM-DD-YYYY")}
              >
                {moment.parseZone(rowData.totDate).format("MM-DD-YYYY")}
              </Hoverable>
            );
          },
        },
        {
          title: "Partner",
          field: "serviceName",
          render: (rowData) => {
            return (
              <Hoverable title={rowData.serviceName}>
                {rowData.serviceName}
              </Hoverable>
            );
          },
        },
        {
          title: "Content Area",
          field: "contentAreaName",
          render: (rowData) => {
            return (
              <Hoverable title={rowData.contentAreaName}>
                {rowData.contentAreaName}
              </Hoverable>
            );
          },
        },
        {
          title: "Course",
          field: "contentResourceName",
          render: (rowData) => (
            <Hoverable title={rowData.contentResourceName || "N/A"}>
              {rowData.contentResourceName || "N/A"}
            </Hoverable>
          ),
        },
        {
          title: "Resource",
          field: "resourceName",
          render: (rowData) => (
            <Hoverable title={rowData.resourceName || "N/A"}>
              {rowData.resourceName || "N/A"}
            </Hoverable>
          ),
        },
        {
          title: "Time on Task Amount",
          field: "timeSpentMinutes",
          render: (rowData) => (
            <Hoverable title={`${rowData.timeSpentMinutes} Minutes`}>
              {`${rowData.timeSpentMinutes} Minutes`}
            </Hoverable>
          ),
        },
      ];
    } else {
      return [
        {
          title: "Date",
          field: "totDate",
          render: (rowData) => {
            return (
              <Hoverable
                title={moment.parseZone(rowData.totDate).format("MM-DD-YYYY")}
              >
                {moment.parseZone(rowData.totDate).format("MM-DD-YYYY")}
              </Hoverable>
            );
          },
        },
        {
          title: "Learner",
          field: "userDisplayName",
          render: (rowData) => (
            <Hoverable title={rowData.userDisplayName}>
              {rowData.userDisplayName}
            </Hoverable>
          ),
        },
        {
          title: "Facility",
          field: "locationName",
          render: (rowData) => (
            <Hoverable title={rowData.locationName}>
              {rowData.locationName}
            </Hoverable>
          ),
        },
        {
          title: "Service",
          field: "serviceDisplayName",
          render: (rowData) => (
            <Hoverable title={rowData.serviceDisplayName}>
              {rowData.serviceDisplayName}
            </Hoverable>
          ),
        },
        {
          title: "Content Area",
          field: "contentAreaName",
          render: (rowData) => (
            <Hoverable title={rowData.contentAreaName}>
              {rowData.contentAreaName}
            </Hoverable>
          ),
        },
        {
          title: "Course",
          field: "contentResourceName",
          render: (rowData) => (
            <Hoverable title={rowData.contentResourceName || "N/A"}>
              {rowData.contentResourceName || "N/A"}
            </Hoverable>
          ),
        },
        {
          title: "Resource",
          field: "resourceName",
          render: (rowData) => (
            <Hoverable title={rowData.resourceName || "N/A"}>
              {rowData.resourceName || "N/A"}
            </Hoverable>
          ),
        },
        {
          title: "Time Spent",
          field: "timeSpentMinutes",
          render: (rowData) => (
            <Hoverable title={`${rowData.timeSpentMinutes} Minutes`}>
              {`${rowData.timeSpentMinutes} Minutes`}
            </Hoverable>
          ),
        },
      ];
    }
  };

  GetDatatableView = () => {
    const { classes } = this.props;
    return (
      <Box>
        <Datatable
          tableRef={this.tableRef}
          title="Time on Task Details"
          data={(query) =>
            this.getTimeOnTaskData(
              query,
              this.getString("id"),
              this.getSelectedContentArea(),
            )
          }
          columns={this.getColumns()}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          icons={{
            Search: SearchIcon,
            ResetSearch: CloseIcon,
            FirstPage: FirstPageIcon,
            LastPage: LastPageIcon,
            NextPage: NavigateNextIcon,
            PreviousPage: NavigateBeforeIcon,
            Filter: FilterListIcon,
            Sort: SortIcon,
            SortArrow: ArrowDownwardIcon,
            Export: SaveAltIcon,
            Edit: EditIcon,
            User: UserIcon,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: this.state.isLoading
                ? "Search in progress"
                : "No records to display",
            },
          }}
          options={{
            showFirstLastPageButtons: false,
            sorting: false,
            pageSize: this.state.pagination.pageSize,
            selection: false,
            showSelectAllCheckbox: true,
            filtering: false,
            exportButton: true,
            Sort: SortIcon,
            SortArrow: ArrowDownwardIcon,
            actionsColumnIndex: 0,
            search: false,
            selectionProps: (rowData) => ({
              id: `checkbox-${rowData.formId}`,
            }),
            exportFileName: `${this.getString(
              "name",
            )}-TimeOnTaskDetails-${moment().format("MM-DD-YYYY")}`,
            exportCsv:
              this.props.type === TOT_DETAILS_ENUM.Location
                ? this.exportToCsv
                : this.exportStudentRecords,
          }}
        />
      </Box>
    );
  };

  render() {
    const { classes } = this.props;
    return this.props.isInline ? (
      <this.GetDatatableView />
    ) : (
      <Dialog
        fullScreen
        classes={{
          paper: ["scroller-view-tot"].join(" "),
        }}
        open={this.props.open}
        onClose={() => {
          this.props.closeHandler(false);
        }}
      >
        <div>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.props.closeHandler(false);
                }}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ flexGrow: 1, color: "white" }}>
                {this.getString("heading")}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent className={classes.dialogContent}>
            <Box width="100%" className={classes.content}>
              {this.props.contentAreaVisible && (
                <Box margin="20px">
                  <ContentAreas
                    ref={(ref) => (this.contentAreaRef = ref)}
                    contentAreaUpdated={(selectedContentAreas) => {
                      if (this.tableRef) {
                        this.resetPagination(
                          this.tableRef.current.onQueryChange,
                        );
                      }
                    }}
                    selectedContentAreas={[
                      CONTENT_AREAS_ENUM.Education,
                      CONTENT_AREAS_ENUM.Rehab,
                      CONTENT_AREAS_ENUM.Workforce,
                      CONTENT_AREAS_ENUM.ReEntry,
                      CONTENT_AREAS_ENUM.Enrichment,
                    ]}
                  />
                </Box>
              )}
              <this.GetDatatableView />
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ToTDetails);
