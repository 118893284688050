export default theme => ({
    messages: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 18px',
        height: 'calc(100vh - 595px)',
        minHeight: 340,
        flex: 1,
        overflow: 'auto',

        '&::-webkit-scrollbar': {
            width: 0,  /* Remove scrollbar space */
            background: 'transparent'  /* Optional: just make scrollbar invisible */
        },
    },
    center: {
        display: "flex",
        alignItems:"center",
        justifyContent: "center"
    },
    loadingWrapper: {
        display: 'flex',
        flex: '1',
        justifyContent: 'center',
        alignItems: 'center',
    },
})