export default theme => ({
  scheduleSelect: {
    '& .MuiSelect-selectMenu': {
      paddingTop: 10,
      paddingBottom: 10
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0
    },
    minWidth: 100
  },
  error: {
    '& .MuiAutocomplete-inputRoot': {
      color: theme.palette.text.red
    }
  }
});
