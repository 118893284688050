import React from "reactn";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import styles from './time-on-task.styles';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography, Zoom } from "@material-ui/core";
import DaysFilter from "../../../days-filter";
import { CustomChart } from "../../../chart";
import { getStudentTimeOnTaskData, getUserAcheivements } from "../../../../util/APIUtils";
import { isPermitted } from "../../../permission-control/permission-control";
import ProgressByContentAreas from '../progress-content-area/';
import StudentTransaction from '../../../student-transaction/student-transaction';
import { CONTENT_AREA, CONTENT_AREAS_ENUM, getJanusToken, TOT_DETAILS_ENUM } from "../../../../constants";
import ContentAreas from "../../../content-areas";
import TimerIcon from '@material-ui/icons/Timer';
import { ColumnChart, LineChart } from 'react-chartkick';
import { withStyles } from "@material-ui/styles";
import TotDetails from "../../../tot-details";
import NotPermittedUI from "../NotPermittedUI";

import {CustomTheme} from "@orijinworks/frontend-commons";

class TimeOnTask extends React.Component {
    _chart = new CustomChart();
    contentAreaRef = null;
    totRef = null;
    state = {
        tokens: 0,
        hours: 0,
        tasks: 0,
        selectedView: "summary",
        graphData: {
            locations: [],
            selectedLocation: ""
        },
        chartData: [],
        linechartData: [],
        averageData: [],

        studentTransaction: {
            isOpen: false
        },
    };

    componentDidMount() {
        this.getTimeOnTaskData(this.props.user.userDetail.userId, this.global.range, this.contentAreaRef.getSelectedContentAreas());
    };

    handleProgressByContentAreas = (isDialogVisible) => {
        this.setState({
            showProgressByContentAreas: isDialogVisible
        });
    };


    get ctaList() {
        const View_Data_Reports_On_User_Profile = isPermitted(
            this.global.cbacConfigFlag,
            this.global.permissions,
            "View_Data_Reports_On_User_Profile"
        );
        const buttons = [];
        if (View_Data_Reports_On_User_Profile) {
            buttons.splice(1, 0, {
                text: "View Additional Content",
                onClick: () => this.handleProgressByContentAreas(true)
            });
        }
        return buttons;
    };

    /**
     * @name getColumnChartColors
     * @desc Returns column chart colors
     * @return {array}
     */
    get getColumnChartColors() {
        return [
            CustomTheme.palette.primary.main, // TOTAL COLOR
            CONTENT_AREA[CONTENT_AREAS_ENUM.Education].bgColor,
            CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].bgColor,
            CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].bgColor,
            CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].bgColor,
            CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].bgColor,
            CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].bgColor,
            CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].bgColor,
            CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].bgColor,
        ];
    };

    /**
    * @name getTimeOnTaskData
    * @param {number} userId
    * @desc Fetches times on task data.
    * @return {void}
    */
    getTimeOnTaskData = (userId, range, contentAreas) => {
        this.setState({
            totIsLoading: true
        });
        this.setGlobal({
            isLoading: true
        });
        this.setState({
            chartData: [],
            linechartData: [],
            averageData: [],
        });
        // FROM TOT REF., INITIATING
        // RE-FETCH TO GET UPDATED TOT
        if (this.totRef) {
            this.totRef.resetPagination();
            this.totRef.tableRef.current.onQueryChange();
        }
        Promise.allSettled([getStudentTimeOnTaskData(
            userId,
            contentAreas.join(","),
            range.startDate,
            range.endDate
        ), getUserAcheivements(userId, contentAreas.join(","), range.startDate, range.endDate)])
            .then(
                (_successLog) => {
                    if (_successLog[0].value) {
                        _successLog[0].value.object.map((l) => {
                            this.populateLinechart(l)
                            this.populateColumns(l)
                            this.populateAverage(l)
                        })
                        this.setState({
                            totIsLoading: false,
                            totDataFetched: true,
                        });
                    }
                    this.setState({
                        ..._successLog[1].value
                    });
                    this.setGlobal({
                        isLoading: false
                    });

                },
                (_errorLog) => {

                    this.setGlobal({
                        isLoading: false
                    });
                }
            );
    };

    populateAverage(user) {
        var _data = []
        var total = ["Overall Average"]
        var totalNum = 0
        var education = ["Education"]
        var rehab = ["Rehabilitation"]
        var job = ["Workforce Skills"]
        var reentry = ["Reentry"]
        var enrichment = ["Enrichment"]
        var entertainment = ["Entertainment"]
        var misc = ["Miscellaneous"]
        var communication = ["Communication"]
        //// consolelog(user)
        user.contentAreas.map((c) => {
            if (c.contentAreaName === "Education") {
                var ar = c.averageTimespent
                //// consolelog("Education average:", ar)
                totalNum += ar
                education.push(ar)
            }
            if (c.contentAreaName === "Rehabilitation") {
                var ar = c.averageTimespent
                totalNum += ar
                rehab.push(ar)
            }
            if (c.contentAreaName === "Workforce Skills") {
                var ar = c.averageTimespent
                totalNum += ar
                job.push(ar)
            }
            if (c.contentAreaName === "Reentry") {
                var ar = c.averageTimespent
                totalNum += ar
                reentry.push(ar)
            }
            if (c.contentAreaName === "Enrichment") {
                var ar = c.averageTimespent
                totalNum += ar
                enrichment.push(ar)
            }
            if (c.contentAreaName === "Miscellaneous") {
                var ar = c.averageTimespent
                totalNum += ar
                misc.push(ar)
            }
            if (c.contentAreaName === "Entertainment") {
                var ar = c.averageTimespent
                totalNum += ar
                entertainment.push(ar)
            }
            if (c.contentAreaName === "Communication") {
                var ar = c.averageTimespent
                totalNum += ar
                communication.push(ar)
            }
        })

        var totalAve = (totalNum / 6).toFixed(2)
        total.push(totalAve)
        _data.push(total, education, rehab, job, reentry, enrichment, entertainment, misc, communication)

        var locChart = {
            name: user.locationName,
            data: _data
        }

        var chartState = this.state.averageData
        chartState.push(locChart)
        this.setState({ averageData: chartState })
    }

    populateColumns(_user) {
        var chartData = []
        var total = 0
        var totalArr = ["Total"]
        var enrichment = 0
        var enrichmentArr = ["Enrichment"]
        var education = 0
        var educationArr = ["Education"]
        var rehab = 0
        var rehabArr = ["Rehab"]
        var reentryArr = ["Reentry"]
        var reentry = 0
        var miscArr = ["Misc."]
        var misc = 0
        var jobArr = ["Workforce Skills"]
        var job = 0
        var entertainment = 0
        var entArr = ["Entertainment"]
        var communication = 0
        var comArr = ["Communication"]



        _user.contentAreas.map((c) => {
            if (c.contentAreaName === "Enrichment") {
                enrichment += (c.totalTimespent)
            }
            if (c.contentAreaName === "Education") {
                education += (c.totalTimespent)
            }
            if (c.contentAreaName === "Rehabilitation") {
                rehab += (c.totalTimespent)
            }
            if (c.contentAreaName === "Workforce Skills") {
                job += (c.totalTimespent)
            }
            if (c.contentAreaName === "Miscellaneous") {
                misc += (c.totalTimespent)
            }
            if (c.contentAreaName === "Entertainment") {
                entertainment += (c.totalTimespent)
            }
            if (c.contentAreaName === "Reentry") {
                reentry += (c.totalTimespent)
            }
            if (c.contentAreaName === "Communication") {
                communication += (c.totalTimespent)
            }
            total += (c.totalTimespent)
        })

        enrichmentArr.push(enrichment)
        educationArr.push(education)
        jobArr.push(job)
        reentryArr.push(reentry)
        rehabArr.push(rehab)
        miscArr.push(misc)
        totalArr.push(total)
        entArr.push(entertainment)
        comArr.push(communication)

        chartData.push(totalArr)
        chartData.push(educationArr)
        chartData.push(rehabArr)
        chartData.push(jobArr)
        chartData.push(reentryArr)
        chartData.push(enrichmentArr)
        chartData.push(entArr)
        chartData.push(miscArr)
        chartData.push(comArr)
        var locChart = {
            name: _user.locationName,
            data: chartData,
        }
        var chartState = this.state.chartData
        chartState.push(locChart)
        this.setState({ chartData: chartData })
    }

    populateLinechart(u) {
        var user = []
        var education = { "name": "Education" }
        var job = { "name": "Workforce Skills" }
        var reentry = { "name": "Reentry" }
        var rehab = { "name": "Rehabilitation" }
        var enrichment = { "name": "Enrichment" }
        var entertainment = { "name": "Entertainment" }
        var misc = { "name": "Miscellaneous" }
        var communication = { "name": "Communication" }
        var singleObj = {}
        enrichment.data = {}
        job.data = {}
        reentry.data = {}
        rehab.data = {}
        entertainment.data = {}
        education.data = {}
        misc.data = {}
        communication.data = {}

        u.contentAreas.map((c) => {
            c.timeOnTaskList.map((t) => {
                singleObj[t.date] = t.timespent
                if (singleObj[t.date] === null || undefined) {
                    singleObj[t.date] = t.timespent
                } else {
                    var newVal = singleObj[t.date] + t.timespent
                    singleObj[t.date] = newVal
                }

                if (c.contentAreaName === "Enrichment") {
                    enrichment.data[t.date] = t.timespent
                }
                if (c.contentAreaName === "Education") {
                    education.data[t.date] = t.timespent
                }
                if (c.contentAreaName === "Rehabilitation") {
                    rehab.data[t.date] = t.timespent
                }
                if (c.contentAreaName === "Workforce Skills") {
                    job.data[t.date] = t.timespent
                }
                if (c.contentAreaName === "Miscellaneous") {
                    misc.data[t.date] = t.timespent
                }
                if (c.contentAreaName === "Reentry") {
                    reentry.data[t.date] = t.timespent
                }
                if (c.contentAreaName === "Education") {
                    education.data[t.date] = t.timespent
                }
                if (c.contentAreaName === "Communication") {
                    communication.data[t.date] = t.timespent
                }
            })
        })

        user.push(education, rehab, job, reentry, enrichment, entertainment, misc, communication)



        var locChart = {
            name: u.locationName,
            data: singleObj
        }

        var chartState = this.state.linechartData
        chartState.push(locChart)
        //console.log("Linechart data", chartState)
        this.setState({ linechartData: chartState })
    }

    handleDateRangeChange = (range) => {
        this.getTimeOnTaskData(this.props.user.userDetail.userId, this.global.range, this.contentAreaRef.getSelectedContentAreas());
    };

    /**
     * @name locationChangeHandler
     * @param {object} event
     * @desc Location change handler, responsible to update graph, location data.
     * @return {void}
     */
    locationChangeHandler = (event) => {
        const activeLocationId = event.target.value;
        this.setState({
            graphData: {
                ...this.state.graphData,
                selectedLocation: activeLocationId
            }
        });
        const activeLocationData = this.state.graphData.locations.find((location) => location.locationId === activeLocationId);
        this.populateLinechart(activeLocationData)
        this.populateColumns(activeLocationData)
        this.populateAverage(activeLocationData)
    };

    render() {
        const View_Data_Reports_On_User_Profile = isPermitted(
            this.global.cbacConfigFlag,
            this.global.permissions,
            "View_Data_Reports_On_User_Profile"
        );
        const { classes } = this.props;
        return (
            <>
                <ProgressByContentAreas
                    open={this.state.showProgressByContentAreas}
                    userDetail={this.props.user.userDetail}
                    closeHandler={this.handleProgressByContentAreas}
                />
                <StudentTransaction
                    onClose={() => this.handleTransactionDialog(false)}
                    userDetail={this.props.user.userDetail}
                    {...this.state.studentTransaction}
                />
                <Grid
                    container
                    item
                    md={12}
                    className={[classes.contentAreaContainer, classes.contentSectionPadding].join(" ")}
                    justify="space-between"
                >
                    <ContentAreas
                        ref={(ref) => this.contentAreaRef = ref}
                        contentAreaUpdated={(selectedContentAreas) => {
                            this.getTimeOnTaskData(this.props.user.userDetail.userId, this.global.range, selectedContentAreas);
                        }}
                        selectedContentAreas={[
                            CONTENT_AREAS_ENUM.Education,
                            CONTENT_AREAS_ENUM.Rehab,
                            CONTENT_AREAS_ENUM.Workforce,
                            CONTENT_AREAS_ENUM.ReEntry,
                            CONTENT_AREAS_ENUM.Enrichment,
                        ]}
                    />
                </Grid>
                <Box width="100%" marginTop="30px">
                    <Grid
                        container
                        item
                        xs={12}
                    >

                        <Grid
                            container
                            item
                            md={3}
                            xs={12}
                        >
                            <Box component="div"
                                display="flex"
                                width="100%"
                                justifyContent="space-between"
                                flexDirection="column"
                                borderRadius={5}
                                marginRight={2}
                                marginBottom={2}
                                padding="20px"
                                color="white"
                                className={classes.blueBox}>

                                <Typography className={classes.blueBoxTitle}>
                                    <TimerIcon />
                            &nbsp;
                                total hours spent
            </Typography>
                                <Typography
                                    className={classes.blueBoxValue}>
                                    {this.state.hours.toFixed(2)}
                                </Typography>
                            </Box>
                            <Box component="div"
                                display="flex"
                                width="100%"
                                justifyContent="space-between"
                                flexDirection="column"
                                borderRadius={5}
                                marginRight={2}
                                marginBottom={2}
                                padding="20px"
                                color="white"
                                className={classes.blueBox}>

                                <Typography className={classes.blueBoxTitle}>
                                    <CheckCircleIcon />
                            &nbsp;
                                total tasks completed
                 </Typography>
                                <Typography
                                    className={classes.blueBoxValue}>
                                    {this.state.tasks}
                                </Typography>
                            </Box>
                            {
                                this.ctaList.map(cta => (
                                    <Box
                                        width="100%"
                                        display="flex"
                                        justifyContent="flex-end"
                                        marginRight={2}>
                                        <Button
                                            onClick={cta.onClick}
                                            color="primary">
                                            {cta.text}
                                        </Button>
                                    </Box>
                                ))
                            }
                        </Grid>
                        <Grid item md={9} sm={12} xs={12} style={{ padding: 0 }}>
                            {!View_Data_Reports_On_User_Profile ? <NotPermittedUI text="You don’t have access to the data chart" /> : (
                                <Box
                                    boxShadow={3}
                                    bgcolor="white"
                                    style={{ width: '100%', height: 420 }}>
                                    <Box component="div" padding={2}>
                                        <div style={{ float: 'left' }}>
                                            <Button onClick={() => { this.setState({ selectedView: "summary" }) }} color={this.state.selectedView === "summary" ? "primary" : "inherit"}>Summary</Button>
                                            <Button onClick={() => { this.setState({ selectedView: "averages" }) }} color={this.state.selectedView === "averages" ? "primary" : "inherit"}>Average</Button>
                                            <Button onClick={() => { this.setState({ selectedView: "details" }) }} color={this.state.selectedView === "details" ? "primary" : "inherit"}>Details</Button>
                                        </div>
                                        <Box
                                            display="flex"
                                            position="relative"
                                            alignItems="center"
                                            justifyContent="center"
                                            position="relative"
                                            style={{ float: 'right', width: "60%", display: 'flex', justifyContent: "flex-end" }}>
                                            {this.state.graphData.locations.length > 0 && (
                                                <FormControl variant="outlined" className={classes.locationSelectionBox}>
                                                    <InputLabel>Location</InputLabel>
                                                    <Select
                                                        value={this.state.graphData.selectedLocation}
                                                        onChange={this.locationChangeHandler}
                                                        label="Location"
                                                    >
                                                        {this.state.graphData.locations
                                                            .map(
                                                                (location) => (
                                                                    <MenuItem value={location.locationId}>{location.locationName}</MenuItem>
                                                                )
                                                            )}

                                                    </Select>
                                                </FormControl>
                                            )}
                                            <DaysFilter
                                                dateChangeHandler={this.handleDateRangeChange}
                                                dateRangePickerProps={{
                                                    left: "-195px"
                                                }}
                                            />
                                        </Box>
                                        <Grid container item xs={12} spacing={24}>
                                            <Grid item xs={12}>
                                                {this.state.linechartData && this.state.selectedView === "details" ?

                                                    <Zoom in unmountOnExit>
                                                        <div>
                                                            <h3 style={{ margin: 0, marginLeft: 12 }}>Details Time Spent View </h3>
                                                            <LineChart
                                                                dataset={{
                                                                    backgroundColor: this.getColumnChartColors,
                                                                }}
                                                                library={this._chart.getDefaultOptions}
                                                                ytitle="Time Spent (hours)"
                                                                height="280px"
                                                                data={this.state.linechartData} />
                                                        </div>
                                                    </Zoom>
                                                    :
                                                    this.state.chartData && this.state.selectedView === "summary" ?
                                                        <Zoom in unmountOnExit>
                                                            <div>
                                                                <h3 style={{ margin: 0, marginLeft: 12, marginBottom: 20 }}>Summary Time Spent by Pathways</h3>
                                                                <ColumnChart
                                                                    library={new CustomChart(true).getDefaultOptions}
                                                                    dataset={{
                                                                        ...this._chart.getDefaultDatasetOptions,
                                                                        backgroundColor: this.getColumnChartColors,
                                                                    }}

                                                                    stacked={true} ytitle="Time Spent (hours)"
                                                                    height="280px" data={this.state.chartData} />
                                                            </div>
                                                        </Zoom>

                                                        :
                                                        this.state.averageData && this.state.selectedView === "averages" ?
                                                            <Zoom in unmountOnExit>
                                                                <div>
                                                                    <h3 style={{ margin: 0, marginLeft: 12 }}>Details Time Spent View </h3>
                                                                    <LineChart
                                                                        dataset={{
                                                                            backgroundColor: this.getColumnChartColors,
                                                                        }}
                                                                        library={this._chart.getDefaultOptions}
                                                                        ytitle="Time Spent (hours)"
                                                                        height="280px"
                                                                        data={this.state.linechartData} />
                                                                </div>
                                                            </Zoom>
                                                            :
                                                            this.state.chartData && this.state.selectedView === "summary" ?
                                                                <Zoom in unmountOnExit>
                                                                    <div>
                                                                        <h3 style={{ margin: 0, marginLeft: 12, marginBottom: 20 }}>Summary Time Spent by Pathways</h3>
                                                                        <ColumnChart
                                                                            library={this._chart.getDefaultOptions}
                                                                            dataset={{
                                                                                ...this._chart.getDefaultDatasetOptions,
                                                                                backgroundColor: this.getColumnChartColors,
                                                                            }}

                                                                            stacked={true} ytitle="Time Spent (hours)"
                                                                            height="280px" data={this.state.chartData} />
                                                                    </div>
                                                                </Zoom>

                                                                :
                                                                this.state.averageData && this.state.selectedView === "averages" ?
                                                                    <Zoom in unmountOnExit>
                                                                        <div>
                                                                            <h3 style={{ margin: 0, marginLeft: 12 }}>Average Total Time Spent by Pathways</h3>
                                                                            <ColumnChart
                                                                                library={this._chart.getDefaultOptions}
                                                                                dataset={{
                                                                                    ...this._chart.getDefaultDatasetOptions,
                                                                                    backgroundColor: this.getColumnChartColors,
                                                                                }}
                                                                                stacked={true} ytitle="Time Spent (hours)" height="280px" data={this.state.averageData} />
                                                                        </div>
                                                                    </Zoom>
                                                                    :
                                                                    <h5>There is no Time on Task information available for this learner for the date range provide.</h5>}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            )}

                        </Grid>

                    </Grid>
                </Box>
                {
                    this.contentAreaRef && View_Data_Reports_On_User_Profile && (
                        <Box width="100%" marginTop="50px">
                            <TotDetails
                                ref={(ref) => this.totRef = ref}
                                isInline
                                type={TOT_DETAILS_ENUM.Learner}
                                userDetail={this.props.user.userDetail}
                                contentAreas={this.contentAreaRef.getSelectedContentAreas()}
                            />
                        </Box>
                    )
                }
            </>
        );
    };
};

export default withStyles(styles)(TimeOnTask);