import { USER_CONTEXT } from "../../../../constants";
import store from "../../../../v2/core/store";
import useUnleashHook from "../../../../v2/core/unleash/useUnleashHook";
import { UnleashService } from "../../../../v2/services";
import useFeatureFlagOnHook from "../../../../v2/services/useFeatureFlagOnHook";

const useTabDetailsHook = ({profileId, allTabs, global}) => {
    const { app } = store.getState();
    const {userDetail , selectedLocation} = app;
    const unleash = useUnleashHook(
        UnleashService.FLAGS.SISENSE_DASHBOARD_SUBTAB,
        userDetail.userName,
        userDetail.userId,
        selectedLocation
    );

    const isSisenceFlagEnabled = useFeatureFlagOnHook(UnleashService.FLAGS.LAUNCH_SISENCE_FROM_SIDENAV);

    const TABS = [
        {
            id: allTabs.Details,
            label: "Details"
        },
        {
            id: allTabs.Relationship,
            label: "Relationship"
        },
        {
            id: allTabs.Roles,
            label: "Roles"

        }
    ];

    if (profileId === USER_CONTEXT.personId) {
        TABS.push({
            id: allTabs.Learner_Group,
            label: "Learner Groups"
        })
        if (unleash.isFlagEnabled() && !isSisenceFlagEnabled) {
            TABS.push({
                id: allTabs.Sisense,
                label: "Sisense Dashboards"
            });
        }
    }

    if(global.isSuperAdmin || global.isFacilityAdmin) {
        TABS.splice(1, 0, {
            id: allTabs.Communication,
            label: "Communication"
        });
    }

    return TABS;
}

export default useTabDetailsHook;