import { useState, useEffect } from 'react';
import { VoiceCallService } from '../../../../services/voice-call.service';
const useAddKeywordDictionaryModalHook = (
  isModalOpen,
  refreshTable,
  dictionaryId,
  handleAddKeywordDictionaryModal,
  handleSnackbarOpen
) => {
  useEffect(() => {
    setKeywords([]);
  }, [isModalOpen]);
  const [keywords, setKeywords] = useState([]);
  const voiceCallService = new VoiceCallService();
  const onKeywordChange = (newKeywords) => {
    setKeywords(newKeywords);
  };

  const saveKeywords = async () => {
    try {
      const keywordsPayload = {
        add: keywords,
        remove: [],
      };

      await voiceCallService.addKeywordsToDictionary(keywordsPayload, dictionaryId);
      refreshTable();
      handleAddKeywordDictionaryModal();
      handleSnackbarOpen('Words have been added succesfully', 'success');
      setKeywords([]);
    } catch (error) {
      console.log('Error in saveKeywords', error);
      handleSnackbarOpen(error, 'error');
      setKeywords([]);
    }
  };

  return {
    keywords,
    saveKeywords,
    onKeywordChange,
  };
};

export default useAddKeywordDictionaryModalHook;
