export default (theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#FFFFFF',
  },
  iconButton: {
    marginRight: theme.spacing(1),
    border: `1px solid ${theme.palette.colors.aquaDeep}`,
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    marginLeft: '20px',
    '&.MuiIconButton-root': {
      border: `1px solid ${theme.palette.colors.aquaDeep}`,
      borderRadius: '50%',
      padding: theme.spacing(0.5),
      marginLeft: '20px',
    },
    '&.MuiButtonBase-root': {
      border: `1px solid ${theme.palette.colors.aquaDeep}`,
      borderRadius: '50%',
      padding: theme.spacing(0.5),
      marginLeft: '20px',
    },
  },
  title: {
    '&.MuiTypography-root': {
      fontWeight: 'bold',
      marginLeft: '10px',
      color: theme.palette.primary.main,
    },
  },
  toolBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#FFFFFF',
  },
  searchBarContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexGrow: 1,
    marginLeft: '16px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  searchField: {
    width: '615px',
    height: '40px',
  },
  searchInputRoot: {
    borderRadius: '50px',
  },
  searchInputNotchedOutline: {
    borderRadius: '50px',
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    borderRadius: '50px',
  },
  jumpToField: {
    minWidth: 120,
  },
  jumpToInputRoot: {
    borderRadius: '50px',
  },
  jumpToInputNotchedOutline: {
    borderRadius: '50px',
  },
  addButton: {
    width: '212px',
    height: '40px',
  },
  exportButton: {
    borderRadius: '50px',
  },
  tableContainer: {
    backgroundColor: '#FFFFFF',
    maxHeight: '856px',
    padding: '0px 24px 0px 24px',
    gap: '10px',
  },
  keywordDetailLink: {
    color: theme.palette.colors.aquaLight,
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '700',
  },
  thumb: {
    color: 'black',
    width: '30px',
    height: '30px',
  },
  thumbIcon: {
    fontSize: '30px !important',
    color: 'white',
    width: '20px !important',
    padding: '2px !important',
    height: '20px !important',
  },
  checked: {
    color: 'white',
    left: '-62px !important',
    transform: 'translateX(100px) !important',
  },
  trackOff: {
    borderRadius: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
    opacity: '1 !important',
    '&:after, &:before': {
      color: theme.palette.colors.grey110,
      fontSize: '15px',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      top: '6px',
    },
    '&:before': {
      left: '8px',
    },
  },
  trackOn: {
    borderRadius: '20px !important',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'white',
      fontSize: '15px',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      top: '6px',
    },
    '&:after': {
      right: '10px',
    },
  },
  switchRoot: {
    width: '70px',
    height: '30px',
    padding: '0px',
    '& .MuiSwitch-switchBase.Mui-checked': {
      backgroundColor: '#2BA369',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#2BA369',
    },
  },
  switchBase: {
    padding: '3px',
    '&$checked': {
      color: 'white',
      left: '-62px !important',
      transform: 'translateX(100px) !important',
    },
    paddingLeft: '5px',
  },
});
