export default (theme) => ({
  Breadcrumbs: {
    marginLeft: '34px',
    marginTop: '16px',
    marginBottom: '16px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.colors.aquaDeep,
    fontSize: '16px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  current: {
    backgroundColor: theme.palette.colors.aquaDeep,
    color: '#fff',
    borderRadius: '12px',
    padding: theme.spacing(0.5, 2),
    display: 'inline-block',
  },
});
