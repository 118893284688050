export default (theme) => ({
  header: {
    padding: '15px 20px',
    borderBottom: '1px solid #a9a6a67a',
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerText: {
    fontSize: 25,
    fontWeight: 'bold'
  },
  body: {
    padding: '15px 0px'
  },
  callDetailsSections: {
    display: 'flex'
  },
  groupDetatilBox: {
    border: `1px solid ${theme.palette.greyScale.greyBackground}`,
    borderRadius: 5,
    paddingLeft: 15,
    marginRight: 10,
    borderRadius: 12,
    flex: 1
  },
  displayNameLabel: {
    color : theme.palette.button.blue.dark,
    fontWeight: 'bold',
    fontSize: 20,
  },
  learnerText:{
    color: theme.palette.primary.mediumBlue,
    fontWeight:700,
  },
  visitorText:{
    color: theme.palette.primary.mediumBlue,
    fontWeight:700,
  },
  inCallActionsText:{
    color : theme.palette.button.blue.dark,
    fontWeight:700,
    paddingTop:15
  },
  paper:{
    padding: '20px',
    borderRadius: '20px',
  },
  modal: {
    maxWidth: 600,
    margin: '0 auto',
    marginTop: '20vh',
    height: 'auto',
    padding: '0px 24px',
    borderRadius: '24px 24px 0px 0px',
    borderBottom: '1px solid #000000'
  },
  footer: {
    padding: '0px 20px 25px'
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  leaveBtn: {
    background: theme.palette.primary.blue.background,
    borderRadius: 35,
    color: 'white',
    fontWeight: 'bold',
    marginTop: 10,
    '&:hover': {
      background: theme.palette.primary.blue.background
    }
  },
  callActionsBtnGrid: {
    border: `1px solid ${theme.palette.greyScale.greyBackground}`,
    marginTop: '20px',
    padding: '20px',
    borderRadius: 12
  },
  speakButton: {
    borderRadius: '50',
    border:`1px solid ${theme.palette.button.blue.default}`,
    color : theme.palette.button.blue.default,
    textTransform:'capitalize',
    textTransform:'capitalize',
    fontWeight: 700,
    fontSize:16
  },
  blockButton: {
    color: 'black',
    borderColor: 'black',
    borderRadius: '50',
    border:`1px solid ${theme.palette.primary.blue.main}`,
    color : theme.palette.primary.blue.main,
    textTransform:'capitalize',
    fontWeight: 700,
    fontSize:16
  },
  terminateButton: {
    color:theme.palette.text.red,
    borderRadius: '50',
    border:'1px solid #7C0E22',
    textTransform:'capitalize',
    fontWeight: 700,
    fontSize:16
  },
  terminateCallSection: {
    width: 'auto',
    padding: '20px',
    border: '2px solid #7C0E22',
    borderRadius: '20px',
    margin: '0px auto'
  },
  terminateCallTitle: {
    fontWeight: 700,
    fontSize: 20,
    width: 465,
    paddgingTop:12,
    color: theme.palette.text.red
  },
  terminateCallDescription: {
    fontWeight: 430,
    fontSize: 16,
    width: 501,
    paddgingTop:14
  },
  
  terminateCallButtonsSection:{
    display:'flex',
    justifyContent:'center',
    width:'100%',
    paddingTop:'20px',
    marginLeft:'3px',
  },
  terminateCallButton: {
    background:theme.palette.text.red,
    width:'150px',
    height:'44px',
    padding:'8px',
    borderRadius:'50px',
    marginRight:'10px',
    textTransform:'capitalize',
    color:theme.palette.primary.white,
    '&:hover': {
      background:theme.palette.text.red,
    }
  },
});
