import React, { useGlobal } from 'reactn';
import initializePendo from '../../../pendo';
import { PENDO_CONFIG } from '../../../constants';

const usePendoInitialize = ({ locations = [], tokenParsed = {} }) => {
  const [global, setGlobal] = useGlobal();
  
  let Jurisdiction = {};
  if (locations && locations.length) {
    Jurisdiction = locations.find((value) => value.locationTypeId === 3); //  3 == Jurisdiction
  }
  const locationId = global.isSuperAdmin ? PENDO_CONFIG.SUPER_ADMIN_LOCATION_ID : Jurisdiction.parentLocationId;

  React.useEffect(() => {
    const isSuperAdmin = global.isSuperAdmin ? true : false;

    const info = {
      id: tokenParsed.personId,
      userFullName: tokenParsed.preferred_username,
      email: tokenParsed.email,
      isSuperAdmin,
      locationId,
      environment: PENDO_CONFIG.ENVIRONMENT,
    };

    if (PENDO_CONFIG.isEnable) {
      initializePendo({ info, apiKey: PENDO_CONFIG.API_KEY });
    }
  }, [locationId, PENDO_CONFIG.API_KEY]);
};

export default usePendoInitialize;
