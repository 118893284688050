export default (theme) => ({
    customCheckbox: {
        position: "relative",
        cursor: "pointer",
        margin: 5,
        fontSize: 12,
        '& input': {
            cursor: "pointer",
            position: "absolute",
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0
        },

        '& .toggle-container .off': {
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            backgroundColor: '#f3cccc',
            borderColor: '#d73434',
            fontWeight: 700,
            borderRight: 1,
            color: "#d73434"
        },
        '& .toggle-container .on': {
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderLeft: 0
        },
        '& > .toggle-container span': {
            border: '1px solid',
            borderColor: '#bbbbbb',
            color: "#bbbbbb",
            padding: '3px 10px',
            marginRight: -3,
            textTransform: 'uppercase',
            transition: 'all 0.2s'
        },
        '& input:checked + .toggle-container .off': {
            backgroundColor: 'initial',
            borderColor: '#bbbbbb',
            fontWeight: 500,
            borderRight: 0,
            color: "#bbbbbb"
        },
        '& input:checked + .toggle-container .on': {
            backgroundColor: '#d1f4cb',
            borderColor: '#76db50',
            fontWeight: 700,
            borderLeft: '1px solid',
            color: "#76db50"
        }
    }
});