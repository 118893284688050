import React from 'reactn'
import { Button, AppBar, Toolbar, IconButton, Typography, Dialog, withStyles, Box, DialogContent } from '@material-ui/core'
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from '@material-ui/icons/Close'
import Datatable from 'material-table'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import SortIcon from '@material-ui/icons/Sort'
import FilterListIcon from '@material-ui/icons/FilterList'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

const styles = (theme) => ({
    appBar: {
        position: 'relative'
    },
    dialogContent: {
        padding: 50
    },
    notesHeading: {
        ...theme.typography.t20,
        ...theme.typography.medium,
        color: theme.palette.primary.jetBlack,
        letterSpacing: '0.15px',
        lineHeight: '24px'
    }
});



class Notes extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <Dialog fullScreen open={this.props.open} onClose={() => this.props.closeHandler(false)}>
                <div>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={() => this.props.closeHandler(false)} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1, color: 'white' }}>
                                Adnan Siraj's Notes
                                       </Typography>
                            <IconButton edge="end" color="inherit" className={classes.downloadPDFBtn} >
                                <GetAppIcon color="white" />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent
                        className={classes.dialogContent}
                    >
                        <Box
                            width="100%"
                            boxShadow={3}
                            className={classes.content}>
                            <Box
                                alignItems="center"
                                padding="20px"
                                boxSizing="border-box"
                                display="flex"
                                width="100%"
                                justifyContent="space-between"
                            >
                                <Typography className={classes.notesHeading}>
                                    Notes
                                </Typography>
                                <IconButton color="primary">
                                    <NoteAddIcon color="primary" />
                                </IconButton>
                            </Box>
                            <Box>
                                <Datatable
                                    title="Notes"
                                    data={[
                                        {
                                            title: "James T. Kirk",
                                            notes: `Adam is doing an exceptional job of completing course items. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Vestibulum at varius mi, a tincidunt metus. Integer tincidunt
                                            viverra vehicula. Curabitur facilisis molestie dolor eget sodales. Donec in diam urna. Morbi
                                            efficitur quam ornare, tincidunt mi ornare, porttitor arcu.`,
                                            datetime: "2020-01-17 | 07:30AM to 09:30PM"
                                        },
                                        {
                                            title: "James T. Kirk",
                                            notes: `Adam is doing an exceptional job of completing course items. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Vestibulum at varius mi, a tincidunt metus. Integer tincidunt
                                            viverra vehicula. Curabitur facilisis molestie dolor eget sodales. Donec in diam urna. Morbi
                                            efficitur quam ornare, tincidunt mi ornare, porttitor arcu.`,
                                            datetime: "2020-01-17 | 07:30AM to 09:30PM"
                                        },
                                        {
                                            title: "James T. Kirk",
                                            notes: `Adam is doing an exceptional job of completing course items. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Vestibulum at varius mi, a tincidunt metus. Integer tincidunt
                                            viverra vehicula. Curabitur facilisis molestie dolor eget sodales. Donec in diam urna. Morbi
                                            efficitur quam ornare, tincidunt mi ornare, porttitor arcu.`,
                                            datetime: "2020-01-17 | 07:30AM to 09:30PM"
                                        },
                                        {
                                            title: "James T. Kirk",
                                            notes: `Adam is doing an exceptional job of completing course items. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Vestibulum at varius mi, a tincidunt metus. Integer tincidunt
                                            viverra vehicula. Curabitur facilisis molestie dolor eget sodales. Donec in diam urna. Morbi
                                            efficitur quam ornare, tincidunt mi ornare, porttitor arcu.`,
                                            datetime: "2020-01-17 | 07:30AM to 09:30PM"
                                        },
                                        {
                                            title: "James T. Kirk",
                                            notes: `Adam is doing an exceptional job of completing course items. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Vestibulum at varius mi, a tincidunt metus. Integer tincidunt
                                            viverra vehicula. Curabitur facilisis molestie dolor eget sodales. Donec in diam urna. Morbi
                                            efficitur quam ornare, tincidunt mi ornare, porttitor arcu.`,
                                            datetime: "2020-01-17 | 07:30AM to 09:30PM"
                                        },
                                        {
                                            title: "James T. Kirk",
                                            notes: `Adam is doing an exceptional job of completing course items. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Vestibulum at varius mi, a tincidunt metus. Integer tincidunt
                                            viverra vehicula. Curabitur facilisis molestie dolor eget sodales. Donec in diam urna. Morbi
                                            efficitur quam ornare, tincidunt mi ornare, porttitor arcu.`,
                                            datetime: "2020-01-17 | 07:30AM to 09:30PM"
                                        },
                                        {
                                            title: "James T. Kirk",
                                            notes: `Adam is doing an exceptional job of completing course items. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Vestibulum at varius mi, a tincidunt metus. Integer tincidunt
                                            viverra vehicula. Curabitur facilisis molestie dolor eget sodales. Donec in diam urna. Morbi
                                            efficitur quam ornare, tincidunt mi ornare, porttitor arcu.`,
                                            datetime: "2020-01-17 | 07:30AM to 09:30PM"
                                        },
                                    ]}
                                    columns={[
                                        {
                                            title: "Name",
                                            field: 'title'
                                        },
                                        {
                                            title: "Notes",
                                            field: 'notes'
                                        },
                                        {
                                            title: "Date/Time",
                                            field: 'datetime'
                                        }
                                    ]}
                                    icons={{
                                        FirstPage: FirstPageIcon,
                                        LastPage: LastPageIcon,
                                        NextPage: NavigateNextIcon,
                                        Filter: FilterListIcon,
                                        PreviousPage: NavigateBeforeIcon,
                                        Sort: SortIcon,
                                        SortArrow: ArrowDownwardIcon,
                                    }}
                                    options={{
                                        pageSize: 20,
                                        selection: true,
                                        sorting: true,
                                        toolbar: false,
                                        selection: false,
                                        filtering: true
                                    }}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                </div>


            </Dialog>
        )
    }
}

export default withStyles(styles)(Notes)