export default theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#F7F8FA !important',
    color: theme.palette.primary.main,
  },
  title: {
    marginLeft: 2,
    flex: 1,
  },
  saveBtn: {
    marginLeft: 10,
    paddingLeft: 31,
    paddingRight: 31,
    marginRight: 'auto',
  },
  dialogContent: {
    paddingTop: 40,
    paddingBottom: 40
  },
  dialogActions: {
    paddingBottom: 25,
    paddingTop: 0,
    justifyContent: 'center'
  },
  field: {
    width: '84%',
    height: 78,

    '& .MuiInputLabel-outlined': {
      fontSize: 14,
      transform: 'translate(14px, 17px) scale(1)',

      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -5px) scale(0.75)'
      }
    }
  },
  outlinedField: {
    fontSize: 12.8,
  },
  input: {
    padding: '15px 14px'
  },
  formField: {
    height: '40px !important',
    borderRadius: '5px',
    outline: 'none',
    border: '1px solid #d1cdcd',
    marginBottom: '15px',
    fontSize: '16px',
    fontFamily: "DM Sans",
    width: "100%",
  },
  suggestionWrapper: {
    position: "relative",
    "& > input": {
      height: 60
    }
  },
  suggestionBox: {
    position: "absolute",
    top: 32,
    background: "white",
    boxShadow: "0px 3px 10px #00000029",
    padding: 0,
    minWidth: 287,
    maxHeight: 450,
    height: '116px',
    zIndex: 99,
    overflow: "auto"
  },
  suggestedChip: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    padding: "10px 30px",
    flexDirection: "column"
  },
  addBtn: {
    marginTop: '18px',
    height: '43px',
    background: theme.palette.primary.teal && theme.palette.primary.teal.main || theme.palette.primary.main,
    color: '#FFFFFF',
    fontWeight: '500',
    fontWeight: 500,
    '&:hover': {
      background: theme.palette.primary.teal && theme.palette.primary.teal.main || theme.palette.primary.main
    },
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  learnersWrapper: {
    marginTop: '5px',
  },
  learnersTitle: {
    marginTop: '7px',
    marginBottom: '22px',
  },
  boxWrapper: {
    border: '1px solid lightgrey',
    borderRadius: 8,
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: 0,
    marginLeft: '5px',
    marginBottom: '6px'
  },
  boxText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px'
  },
  crossButton: {
    marginLeft: 6,
    padding: 0,
    minWidth: 'auto',
    borderRadius: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  boxMain: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  searchTitle: {
    fontWeight: '500'
  },
  groupTitle: {
    fontWeight: '500',
  },
  clearBtn: {
    fontWeight: '500',
  },
  loader: {
    display: "block",
    margin: "10px auto"
  },
  warningIcon: {
    margin: "0px 15px",
    color: "#BF4636"
  },
  closeIcon: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#BF4636"
  },
  warningBox: {
    border: '1px solid #BF4636',
    display: 'flex',
    padding: '5px 10px',
    maxWidth: '70%',
    alignItems: 'center',
    borderLeft: '10px solid #BF4636',
    borderRadius: '7px',
    justifyContent: 'center',
    backgroundColor: '#FFF3F2',
    placeSelf: 'center',
    marginTop: '12px',
  },
  learnerWarningIcon:{
    margin: "4px 8px 0px 0px",
    color: "#BF4636"
  },
  learnerNameText: {
    ...theme.typography.bold,
  }
})