import React from 'react';
import { withStyles, Chip, Grid } from '@material-ui/core';
import { CONTENT_AREA, CONTENT_AREAS_ENUM } from '../../constants';


const styles = (theme) => ({
    contentAreaChip: {
        borderRadius: 4,
        padding: '0px 5px',
        ...theme.typography.t16,
        ...theme.typography.medium,
        letterSpacing: '1.43px',
        lineHeight: '15.7px',
        marginTop: 10,
        marginLeft: 15
    },
    wrapperElem: {
        marginLeft: -15,
        marginTop: -10
    },
    allActive: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    allBtnInactive: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
    }
});

class ContentAreas extends React.Component {

    state = {
        selectedContentAreas: this.props.selectedContentAreas ? this.props.selectedContentAreas : [
            CONTENT_AREAS_ENUM.Education,
            CONTENT_AREAS_ENUM.Rehab,
            CONTENT_AREAS_ENUM.Workforce,
            CONTENT_AREAS_ENUM.ReEntry
        ],
    }


    componentDidMount() {
        if (this.props.onMount) {
            this.props.onMount(this.state.selectedContentAreas);
        }
    }

    /**
     * @name getSelectedContentAreas
     * @desc Returns selected content areas
     * @return {array}
     */
    getSelectedContentAreas = () => {
        return this.state.selectedContentAreas;
    };

    /**
     * @name selectContentArea
     * @param {number} contentAreaId
     * @desc Toggle content active / inactive state
     * @return {void}
     */
    selectContentArea = (contentAreaId) => {
        let { selectedContentAreas } = this.state;
        const indexOfSelectedContentArea = selectedContentAreas.indexOf(contentAreaId);
        if (contentAreaId === 0) { // 0 = ALL CONTENT AREAS
            // IF ITS ALREADY SELECTED
            if (selectedContentAreas.length >= Object.keys(CONTENT_AREAS_ENUM).length) {
                // THEN EMPTY ITS ARRAY
                selectedContentAreas = [];
            } else {
                // OTHERWISE FILL ALL 
                // CONTENT AREAS
                selectedContentAreas = Object.keys(CONTENT_AREAS_ENUM).map(contentAreaName => CONTENT_AREAS_ENUM[contentAreaName]);
            }
            this.setState({
                selectedContentAreas: selectedContentAreas
            }, () => {
                this.props.contentAreaUpdated(this.state.selectedContentAreas);
            });
            return;
        }
        // IF ITS NOT ALL
        // THEN OPERATE SELECTIVELY
        if (indexOfSelectedContentArea > -1) {
            selectedContentAreas.splice(indexOfSelectedContentArea, 1);
        } else {
            selectedContentAreas.push(contentAreaId);
        }
        this.setState({
            selectedContentAreas: selectedContentAreas
        }, () => {
            this.props.contentAreaUpdated(this.state.selectedContentAreas);
        });
    };

    /**
     * @name isSelected
     * @param {number} contentAreaId
     * @desc Checks if content area is selected or not.
     * @return {boolean}
     */
    isSelected = (contentAreaId) => {
        return this.state.selectedContentAreas.indexOf(contentAreaId) > -1;
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid
                container
                item
                md={12}
                className={classes.wrapperElem}
            >

                <Chip
                    clickable
                    className={[classes.contentAreaChip, this.state.selectedContentAreas.length >= (Object.keys(CONTENT_AREAS_ENUM).length - 1) ? classes.allActive : classes.allBtnInactive].join(" ")}
                    data-cy-name={`All-Chip`}
                    onClick={() => this.selectContentArea(0)}
                    label="All"
                />
                <Chip
                    clickable
                    onClick={() => this.selectContentArea(CONTENT_AREAS_ENUM.Education)}
                    className={classes.contentAreaChip}
                    key={CONTENT_AREA[CONTENT_AREAS_ENUM.Education].id}
                    style={{
                        color: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Education].id)
                            ? "white"
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Education].chip.color,
                        backgroundColor: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Education].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Education].bgColor
                            : "white"
                    }}
                    icon={
                        this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Education].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Education].icons.white
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Education].icons.dark
                    }
                    label={CONTENT_AREA[CONTENT_AREAS_ENUM.Education].name}
                />


                <Chip
                    clickable
                    onClick={() => this.selectContentArea(CONTENT_AREAS_ENUM.Rehab)}
                    className={classes.contentAreaChip}
                    key={CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].id}
                    style={{
                        color: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].id)
                            ? "white"
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].chip.color,
                        backgroundColor: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].bgColor
                            : "white"
                    }}
                    icon={
                        this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].icons.white
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].icons.dark
                    }
                    label={CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].name}
                />

                <Chip
                    clickable
                    onClick={() => this.selectContentArea(CONTENT_AREAS_ENUM.Workforce)}
                    className={classes.contentAreaChip}
                    key={CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].id}
                    style={{
                        color: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].id)
                            ? "white"
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].chip.color,
                        backgroundColor: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].bgColor
                            : "white"
                    }}
                    icon={
                        this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].icons.white
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].icons.dark
                    }
                    label={CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].name}
                />

                <Chip
                    clickable
                    onClick={() => this.selectContentArea(CONTENT_AREAS_ENUM.ReEntry)}
                    className={classes.contentAreaChip}
                    key={CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].id}
                    style={{
                        color: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].id)
                            ? "white"
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].chip.color,
                        backgroundColor: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].bgColor
                            : "white"
                    }}
                    icon={
                        this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].icons.white
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].icons.dark
                    }
                    label={CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].name}
                />

                <Chip
                    clickable
                    onClick={() => this.selectContentArea(CONTENT_AREAS_ENUM.Enrichment)}
                    className={classes.contentAreaChip}
                    key={CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].id}
                    style={{
                        color: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].id)
                            ? "white"
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].chip.color,
                        backgroundColor: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].bgColor
                            : "white"
                    }}
                    icon={
                        this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].icons.white
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].icons.dark
                    }
                    label={CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].name}
                />

                <Chip
                    clickable
                    onClick={() => this.selectContentArea(CONTENT_AREAS_ENUM.Entertainment)}
                    className={classes.contentAreaChip}
                    key={CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].id}
                    style={{
                        color: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].id)
                            ? "white"
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].chip.color,
                        backgroundColor: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].bgColor
                            : "white"
                    }}
                    icon={
                        this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].icons.white
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].icons.dark
                    }
                    label={CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].name}
                />

                <Chip
                    clickable
                    onClick={() => this.selectContentArea(CONTENT_AREAS_ENUM.Misc)}
                    className={classes.contentAreaChip}
                    key={CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].id}
                    style={{
                        color: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].id)
                            ? "white"
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].chip.color,
                        backgroundColor: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].bgColor
                            : "white"
                    }}
                    icon={
                        this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].icons.white
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].icons.dark
                    }
                    label={CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].name}
                />

                <Chip
                    clickable
                    onClick={() => this.selectContentArea(CONTENT_AREAS_ENUM.Communication)}
                    className={classes.contentAreaChip}
                    key={CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].id}
                    style={{
                        color: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].id)
                            ? "white"
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].chip.color,
                        backgroundColor: this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].bgColor
                            : "white"
                    }}
                    icon={
                        this.isSelected(CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].id)
                            ? CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].icons.white
                            : CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].icons.dark
                    }
                    label={CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].name}
                />
            </Grid>
        );
    };
};

export default withStyles(styles)(ContentAreas);