export class Storage {
  constructor() {}

  /**
   * @desc Sets item in local storage
   * @param {*} key
   * @param {*} value
   */
  setStorage(key, value) {
    localStorage.setItem(key, btoa(value));
  }

  /**
   * @desc Fetch's items from local storage
   * @param {*} key
   * @returns string
   */
  getStorage(key) {
    const value = localStorage.getItem(key);
    if (value) {
      return atob(value);
    }
    return null;
  }

  /**
   * @param {string} key
   * @desc Clear individual item but not the entire storage
   */
  clearItem(key) {
    localStorage.removeItem(key);
  }

  /**
   * @desc Clear's storage
   */
  clearStorage() {
    localStorage.clear();
  }
}
