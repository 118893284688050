import { fontFamily, fontSize, fontWeight, lineHeight } from "styled-system";

export default (theme) => ({
  root: {
    background: theme.textWhite ? theme.textWhite.main : '#FFF',
    display: 'flex',
    paddingTop: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    flex: '1 0 0',
    alignSelft: 'stretch'
  },
  heading: {
    display: 'flex',
    padding: '0px 0px 24px 24px',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
    fontSize: 24,
  },
  content: {
    display: 'flex',
    width: '960px',
    paddingTop: '24px',
    paddingLeft:'24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    
  },
  level3BoxAlt: {
    padding: '10px 0 10px 30px',
  },
  boxHeading1: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    letterSpacing: '0.36px',
  },
  subHeading1: {
    fontSize: 12,
    color: theme.palette.text.sub,
    letterSpacing: '0.21px',
    width: 'max-content',
  },
  dayOfWeek: {
    fontSize: 15,
    color: theme.pall,
    textTransform: 'uppercase',
    letterSpacing: '0.98px',
  },
  dayOfWeekWrapper: {
    minWidth: 125,
  },
  scheduleWrapper: {
    minHeight: 100,
  },
  level1Box: {
    paddingLeft: 50,
    paddingRight: 10,
    paddingBottom: 10,
    marginTop: 10,
  },
  level2Box: {
    marginLeft: 30,
    paddingLeft: 20,
    marginRight: 10,
    paddingBottom: 10,
    marginTop: 10,
  },
  level3Box: {
    marginLeft: 30,
    paddingLeft: 60,
    marginRight: 10,
    paddingBottom: 10,
    marginTop: 10,
  },
  scheduleTextInput: {
    maxWidth: 95.5,
  },
  slotReadOnly: {
    minWidth: 104,
    paddingLeft: 20,
    boxSizing: 'border-box',
  },
  [theme.breakpoints.down('md')]: {
    level1Box: {
      paddingLeft: 10,
    },
    level2Box: {
      marginLeft: 0,
      paddingLeft: 10,
    },
    level3Box: {
      marginLeft: 0,
      paddingLeft: 10,
    },
    level3FullBorderBox: {
      paddingLeft: 10,
    },
  },
  settingsWrapper: {
    backgroundColor: theme.textWhite ? theme.textWhite.main : '#FFF',
    margin: '15px 10px 0 40px',
  },

  h1HeadingStyles:{
    fontSize: '32px',
    fontWeight: 800,
    lineHeight: '38.73px',
    fontFamily: 'inter'
  }
});
