export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  deleteIcon: {
    color: '#737982'
  },
  select: {
    fontSize: 12.8,
    color: '#3d3d3d',
    paddingTop: 13.5,
    paddingBottom: 13.5,
  },
  label: {
    fontSize: 14
  },
  outlinedLabel: {
    transform: 'translate(14px, 17px) scale(1)',
    zIndex: 1
  },
  addBtnWrapper: {
    paddingLeft: 8
  },
  dialogContent: {
    maxHeight: 360,
    paddingTop: 40
  },
  noLoc: {
    textAlign: 'center',
    padding: '20px 0'
  }
})