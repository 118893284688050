import React from 'react';
import { Breadcrumbs, Link, Typography, withStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import styles from './keywords-dictionary-breadcrumbs.style';

const KeywordsDictionaryBreadCrumbs = ({ classes, closeKeywordsDictionary, closeKeywordDetail, selectedKeyword }) => {
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.Breadcrumbs}>
      <Link
        component="button"
        color="inherit"
        className={classes.link}
        onClick={closeKeywordsDictionary}
        style={{ cursor: 'pointer' }}
      >
        Communications Settings
      </Link>

      {selectedKeyword ? (
        <Link
          component="button"
          color="inherit"
          className={classes.link}
          onClick={closeKeywordDetail}
          style={{ cursor: 'pointer' }}
        >
          Keyword Dictionary
        </Link>
      ) : (
        <Typography color="textPrimary" className={classes.current}>
          Keyword Dictionary
        </Typography>
      )}

      {selectedKeyword && (
        <Typography color="textPrimary" className={classes.current}>
          {selectedKeyword}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default withStyles(styles)(KeywordsDictionaryBreadCrumbs);
