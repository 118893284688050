import React from "react";
import { FolderNav as FolderNavComponent, Loader } from '@orijinworks/frontend-commons';
import { Box, withStyles } from "@material-ui/core";
import styles from './index.style';
import { UseFriendsAndFamilyHook } from "./useFriendsAndFamilyHook";

const FriendsAndFamilyModule = ({ classes }) => {

  const {
    isLoading,
    tabConfig,
  } = UseFriendsAndFamilyHook();

  return (
    <Box className={classes.folderWrapper}>
      {!isLoading ? 
      <FolderNavComponent
        defaultActiveTab="1"
        color="var(--yellow-main)"
        tabs={tabConfig}
      />
        :
        <Loader isLoading={isLoading}/>
    }
    </Box>
  );    
};

export default withStyles(styles)(FriendsAndFamilyModule);
