import React from 'reactn';
import {
  withStyles,
  Box,
  Typography,
  Tooltip,
  Button,
  Snackbar,
  Fade,
  IconButton
} from '../../../../../v2/shared/components/MaterialUIComponents';

import MuiAlert from '@material-ui/lab/Alert';
import { Loader } from '../../..';
import styles from '../index.style';
import { useBulkResetDeviceHook } from './useBulkResetDeviceHook';
import { Modal } from "@orijinworks/frontend-commons"
import { useRef } from 'react';
import { SNACK_BAR_SUCCESS, SNACK_BAR_TIMEOUT } from '../../../../../v2/services/constants';
import { CustomIcons, icons } from '../../../../../v2/shared/components/custom-icons';

const BulkResetDevicePairing = props => {
  const { classes, locationName } = props;
  let featureData = { classes: [classes.level1Box, props.isSuperAdmin ? '' : classes.noBorderBottom], tooltipText: '' };
  const modalRef = useRef(null);
  const { isResetLoading, resetUserDevicePairing, toggleConfirmationModal, snackBar,setSnackBar, setError, error } = useBulkResetDeviceHook({...props, modalRef});

  return (
      <>
        <Modal
            ref={modalRef}
            isSnackBarShown={false}
            cancelBtnText="Cancel"
            actionBtnText="Reset"
            id="confirmation-modal"
            labelledBy="test"
            showCrossIcon={false}
            describedBy="dfdf"
            onClose={() => toggleConfirmationModal()}
            onContinue={() => resetUserDevicePairing()}
            modalType={{ type: 'confirmation', status: 'danger' }}
            Icon={<CustomIcons icon={icons.TabletAndroidIcon} />}
            styles={{
              mainWrapperStyles: { height: 'auto' },
            }}
          >
            <>
            {error &&
              <Box id="error-box" className={classes.errorWrapper}>
                <Box
                  display="flex"
                  alignItems={"center"}
                  flex={1}>
                  <CustomIcons icon={icons.ErrorIcon} className={classes.errorIcon} />
                  <Typography>{error}</Typography>
                </Box>
                <Box>
                  <IconButton onClick={() => setError('')}>
                    <CustomIcons icon={icons.CloseIcon} className={classes.closeIcon} />
                  </IconButton>
                </Box>
              </Box>
            }
              <Typography className={classes.noCapitalizeText} variant="h5">
                Are you sure you want to reset all paired devices for this location?
              </Typography>

              <Typography className={classes.bodyMargin} variant="body1">
                This will allow each learner to be paired to a new device on their next login to the Learner Portal.
              </Typography>
            </>
          </Modal>

              <Box component="div" justifyContent="space-between" className={featureData.classes.join(' ')} display="flex">
                <Box component="div" textAlign="left">
                  <Typography className={classes.boxHeading1}>Reset Paired Devices for All Learners</Typography>
                  <Typography className={classes.subHeading1}>
                    This will reset tablet asssociations for all learners at this location and any of its children. 
                    Each learner's account will be paired with the next Orijin tablet they log into.
                  </Typography>
                </Box>
                <Tooltip title={featureData.tooltipText}>
                  <Box display="flex" alignItems="center" component="div">
                    <Button id="reset-device-btn" onClick={toggleConfirmationModal} variant="outlined" disabled={isResetLoading}>
                      {isResetLoading && (
                        <>
                          <Loader /> &nbsp;
                        </>
                      )}
                      Reset
                    </Button>
                  </Box>
                </Tooltip>
              </Box>
              <Snackbar
                open={!!snackBar}
                autoHideDuration={SNACK_BAR_TIMEOUT}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setSnackBar(false)}
              >
                <MuiAlert severity={SNACK_BAR_SUCCESS} elevation={6} variant="filled">
                  Paired devices reset for all learners at {locationName}.
                </MuiAlert>
              </Snackbar>
          </>
  );
};

export default withStyles(styles)(BulkResetDevicePairing);
