import React, { useState } from "react";
import styles from "./index.styles";
import { Paper, Button } from "@material-ui/core";
import { saveRoleDetails } from "../../../util/APIUtils";
import EditButton from "../EditButton";
import { commonStyles } from "../common";
import { makeStyles } from "@material-ui/core/styles";
import DefinedRoleTypes from "../../manage-roles-permissions/add-new-role-dialog/RoleTypes";
const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

const RoleTypes = ({
  roleTypeKey,
  roleTypeName,
  roleId,
  fetchRoleDetail,
  isBaseRole,
}) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = React.useState(false);
  const [role, setRole] = useState(roleTypeName || "");
  const onEditClick = () => {
    setIsEdit(true);
  };

  const onCancelClick = () => {
    setRole(roleTypeName);
    setIsEdit(false);
  };

  const handleRoleTypeChange = (event) => setRole(event.target.value);

  const onSaveClick = async () => {
    await saveRoleDetails({
      roleTypeKey: role,
      roleId,
    });
    await fetchRoleDetail();
    setIsEdit(false);
  };
  return (
    <Paper
      elevation={3}
      classes={{
        root: classes.paperRoot,
      }}
    >
      <div className={classes.subheadingWrapper}>
        <h4 className={classes.subheading}>Role Type</h4>
        {!isEdit && !isBaseRole && <EditButton onClick={onEditClick} />}
      </div>
      <div>
        {!isEdit ? (
          <span className={classes.smallText}>{roleTypeName}</span>
        ) : (
          <>
            <DefinedRoleTypes handleRoleTypeChange={handleRoleTypeChange} />
            <div className={classes.buttonsWrapper}>
              <Button onClick={onCancelClick}>Cancel</Button>
              <Button
                onClick={onSaveClick}
                variant="contained"
                color="primary"
                disabled={role === "" ? true : false}
                classes={{
                  root: classes.saveBtn,
                }}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </div>
    </Paper>
  );
};

export default RoleTypes;
