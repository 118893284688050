export default theme => ({
  root: {
    backgroundColor: 'white',
    width: '100%',
    padding: 30,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  divider: {
    margin: '30px 0px'
  }
});
