import React from 'react'
import { InputBase, IconButton, Button, Select, MenuItem, CircularProgress } from '@material-ui/core'
import Datatable from 'material-table'
import { Dialog, DialogActions, AppBar, Toolbar, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import SortIcon from '@material-ui/icons/Sort'
import FilterListIcon from '@material-ui/icons/FilterList'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import SearchIcon from '@material-ui/icons/Search'
import { EVENTS } from './mockEventsList'
import Paper from '@material-ui/core/Paper'
import { USER_CONTEXT } from '../../constants'
import { listevents } from '../../util/APIUtils'
import { MetaData } from '../meta-data/meta-data';
import Hoverable from '../hoverable'
import { UserIcon } from '../UserIcon'



export default class CheckEvents extends React.Component {
  state = {
    open: false,
    search: "",
    loading: false,
    events: null,
    filterType: "Username",
    metaDataDialog: {
      isOpen: false
    }
  };
  columns = [
    {
      field: "eventId",
      title: "Event ID",
      defaultSort: "asc"
    },
    {
      field: "targetObjectName",
      title: "Target Name",
      defaultGroupOrder: 0
    },
    {
      field: "targetObjectKey",
      title: "Person Key"
    },
    {
      field: "statusCode",
      title: "Status Code"
    },
    {
      field: "statusMessage",
      title: "Status Message"
    },
    {
      field: "eventTimestamp",
      title: "Date"
    },
    {
      field: "locationKey",
      title: "Location Key"
    },
    {
      field: "eventTypeName",
      title: "Type"
    },
    {
      field: "transactionId",
      title: "Transaction"
    },
    {
      title: 'Metadata',
      field: "jsonData",
      render: rowData => (
        rowData.jsonData ?
          <Button onClick={() => this.handleMetaDataDialog(true, rowData.jsonData)} color="primary">
            {this.getMetadataValue(rowData.jsonData) ? this.getMetadataValue(rowData.jsonData) : "View Details"}
          </Button>
          : "N/A"
      )
    },
  ];


  /**
   * @param {object} json JSON object in which keys needs to be refined.
   * @desc Refines object and deletes unwanted keys from it.
   * @return {object} refinedObj
   */
  refineKey = (object) => {
    const unwantedKeys = ["sdkHttpMetadata", "sdkResponseMetadata", "formResponse"];
    for (let a in object) {
      if (unwantedKeys.indexOf(a) > -1) {
        delete object[a];
      }
      if (Array.isArray(object)) {
        for (let b = 0; b < object.length; b++) {
          this.refineKey(object[b]);
        }
      } else if (typeof object === "object" && object !== null) {
        this.refineKey(object[a]);
      };
    }
    return object;
  };

  handleMetaDataDialog = (isOpen, jsonObject) => {
    const obj = {
      metaDataDialog: {
        ...this.state.metaDataDialog,
        isOpen,
      }
    };
    if (jsonObject) {
      obj.metaDataDialog["jsonObject"] = this.refineKey(JSON.parse(jsonObject));
    }
    this.setState(obj);
  };

  componentDidMount() {
  }

  fetchEvents = () => {
    this.setState({ loading: true })
    let _filterType = this.state.filterType
    var _transactionStr = ""
    var _targetNameStr = ""
    if (_filterType === "Job ID") {
      _transactionStr = this.state.search
      _targetNameStr = ""
    }
    else {
      _transactionStr = ""
      _targetNameStr = this.state.search
    }
    let postBody = {
      requestorId: USER_CONTEXT.personId,
      filterTransactionId: _transactionStr,
      filterTargetName: _targetNameStr
    }

    //// consolelog(JSON.stringify(postBody))
    listevents(postBody)
      .then(data => {
        //// consolelog(data)
        this.setState({
          loading: false,
          events: data.events
        })
      }
      )
  }


  openResponseModal = () => {
    this.setState({ openResponseModal: true })
  }

  closeResponseModal = () => {
    this.setState({
      open: false
    })
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSearchChange = name => event => {
    this.setState({
      [name]: event.target.value.toLowerCase(),
    });
  };

  /**
    * @name findKey
    * @param {string} keyName
    * @return {object}
    */
  findKey = (keyName, object) => {
    for (let a in object) {
      if (a === keyName) {
        return object[a];
      }
      let foundObj = null;
      if (Array.isArray(object)) {
        for (let b = 0; b < object.length; b++) {
          foundObj = this.findKey(keyName, object[b]);
          if (foundObj) {
            break;
          }
        }
      } else if (typeof object === "object" && object !== null) {
        foundObj = this.findKey(keyName, object[a]);
      };

      if (foundObj) {
        return foundObj;
      }

    }
    return null;
  }

  /**
   * @name getMetadataValue
   * @param {string} jsonObj faceDetails key is searched in json object and appropriate data is returned. 
   * @return {string}
   */
  getMetadataValue = (jsonObj) => {
    try {
      const brightness = this.findKey("brightness", JSON.parse(jsonObj));
      const similarity = this.findKey("similarity", JSON.parse(jsonObj));
      if (brightness || similarity) {
        return `
              ${brightness ? `Brightness: ${brightness}` : ''}
              ${similarity ? `Similarity: ${similarity}` : ''}
              `;
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  };


  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {

    return (
      <div>
        <div>
          <MetaData
            {...this.state.metaDataDialog}
            onClose={() => this.handleMetaDataDialog(false)}
          />
          <Paper style={{
            marginTop: '2rem',
            padding: '2px 12px',
            display: 'flex',
            alignItems: 'center',
          }}>
            <Select
              value={this.state.filterType}
              onChange={this.handleChange}
              inputProps={{
                name: 'filterType',
                id: 'filled-type-simple',
              }}
              style={{ width: 120, background: 'light-gray' }}
            >
              <MenuItem value={"Job ID"}>Job ID</MenuItem>
              <MenuItem value={"Username"}>Username</MenuItem>
            </Select>
            <InputBase
              id="Enter JobId"
              style={{ flexGrow: 1, marginLeft: 6 }}
              placeholder="Search events"
              value={this.state.search}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  this.fetchEvents()
                  ev.preventDefault();
                }
              }}
              onChange={this.handleSearchChange('search')}

              inputProps={{ 'aria-label': 'Search' }}
            />
            <IconButton onClick={this.fetchEvents} aria-label="Search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        {
          this.state.loading ? <CircularProgress style={{ margin: 'auto', marginTop: '5rem' }} /> : null
        }
        {this.state.events ?
          <Paper>
            <Datatable
              data={this.state.events}
              columns={this.columns}
              icons={{
                Search: SearchIcon,
                ResetSearch: CloseIcon,
                FirstPage: FirstPageIcon,
                LastPage: LastPageIcon,
                NextPage: NavigateNextIcon,
                PreviousPage: NavigateBeforeIcon,
                Filter: FilterListIcon,
                Sort: SortIcon,
                SortArrow: SortIcon,
                Export: SaveAltIcon,
                Edit: EditIcon,
                User: UserIcon,
                DetailPanel: NavigateNextIcon
              }}
              options={{
                grouping: true,
                pageSize: 20,
              }}
            />
          </Paper>
          :
          <h5 style={{ color: "gray" }}>
            Please enter a Job ID or a username
              </h5>
        }

      </div>
    );
  }
}