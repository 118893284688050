import { ANNOUNCEMENT_TYPE } from "../../../../../constants";

export const getRecipientName = (announcementType, recipient) => {
    if (announcementType === ANNOUNCEMENT_TYPE.FACILITY) {
        return recipient.locationName || '';
    }
    
    if (announcementType === ANNOUNCEMENT_TYPE.LEARNER || announcementType === ANNOUNCEMENT_TYPE.LEARNER_GROUP) {
        const contactName = recipient.contactName || '';
        const locationName = recipient.locationName ? `| ${recipient.locationName}` : '';
        return `${contactName} ${locationName}`;
    }

    return '';
}