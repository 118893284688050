import React from 'reactn';
import { Button, AppBar, Toolbar, IconButton, Typography, Dialog, withStyles } from '@material-ui/core';
import moment from 'moment';

import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import { BlobProvider } from '@react-pdf/renderer';
import ProgressReport from './transcript/report';
import PDFProgressReport from './transcript/pdf/report';
import { getTranscriptData as getTranscriptDataAPI } from '../../../util/APIUtils';
import { CONTENT_AREAS_ENUM } from '../../../constants';
import { getCurrentDate } from './transcript/utils';
import { Loader } from '@orijinworks/frontend-commons';
import { UserStorage } from '../../../util/UserStorage';
import { withUnleash } from '../../../v2/core/unleash';
import { UnleashService } from '../../../v2/services';

const styles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  content: {
    backgroundColor: theme.palette.primary.white,
    padding: 10,
    margin: '0px auto',
  },
  downloadPDFBtn: {
    color: theme.palette.primary.white,
  },
});

const paxen_columns = [
  {
    title: 'Lesson',
    field: 'title',
  },
  {
    title: 'Item ID',
    field: 'itemid',
  },
  {
    title: 'Time Spent',
    field: 'seconds',
  },
  {
    title: 'Grade acheived',
    field: 'grades_achieved',
  },
  {
    title: 'Attempts',
    field: 'grades_completed',
  },
  {
    title: 'Activity Date',
    field: 'activity_date',
  },
];

class Transcript extends React.Component {
  state = {
    isTranscriptLoading: false,
    transcriptData: null,
  };
  userStorage = null;
  STORAGE_KEYS = {
    TRANSCRIPT_STORAGE_KEY: 'TRANSCRIPT',
    TRANSCRIPT_DATE_STORAGE_KEY: 'TRANSCRIPT_DATE',
  };

  componentDidMount() {
    this.userStorage = new UserStorage(this.props.userInfo.userId);
    if (this.props.unleashProps.isFlagEnabled()) {
      this.getTranscriptData(this.props.userInfo.userId);
    }
  }

  componentDidUpdate() {
    if (
      !this.props.unleashProps.isFlagEnabled() &&
      this.props.open &&
      !this.state.transcriptData &&
      !this.state.isTranscriptLoading
    ) {
      this.getTranscriptData(this.props.userInfo.userId);
    }
  }

  onClose = () => {
    this.setState({ transcriptData: null });
    this.props.closeHandler(false);
  };

  getScore = ({ possibleScore, achievedScore, grade }) => {
    if (possibleScore) {
      return `Score ${(achievedScore / possibleScore) * 100}%`;
    }

    if (achievedScore) {
      return `Score ${achievedScore}`;
    }

    if (grade) {
      return `Grade ${grade}`;
    }

    return 'N/A';
  };

  getLastAcessedDate = (obj) => {
    if (obj.completed) {
      return obj.completedDate ? moment(obj.completedDate).format('LL') : '';
    }
    return obj.lastAccessed ? moment(obj.lastAccessed).format('LL') : '';
  };

  /**
   * checkCacheDuration
   * @param {*} date Cached date
   * @desc Check if the cache duration is less than 12 hours
   * @returns {Boolean}
   */
  checkCacheDuration = (date) => {
    if (!date) {
      return false;
    }
    console.log(date);
    const currentDate = new Date();
    const cacheDate = new Date(date);
    const diff = currentDate - cacheDate;
    console.log(diff);
    return diff < 43200000; // 12 HOURS;
  };

  /**
   * getLatestTranscriptData
   * @param {*} personId
   * @desc Check from cache if the transcript data is available, if not then fetch from server
   * @returns {Promise}
   */
  getLatestTranscriptData = (personId) => {
    return new Promise(async (resolve) => {
      try {
        const isInCacheDuration = this.checkCacheDuration(
          this.userStorage.getItem(this.STORAGE_KEYS.TRANSCRIPT_DATE_STORAGE_KEY)
        );
        const transcriptData = this.userStorage.getItem(this.STORAGE_KEYS.TRANSCRIPT_STORAGE_KEY);
        if (!transcriptData || !isInCacheDuration) {
          const response = await getTranscriptDataAPI(personId);
          resolve(response);
        }
        resolve(JSON.parse(transcriptData));
      } catch (e) {
        resolve(null);
      }
    });
  };
  /**
   * @name getTranscriptData
   * @param {number} personId
   * @desc Fetches transcript data from server
   * @return {void}
   */
  getTranscriptData = (personId) => {
    this.setState({
      isTranscriptLoading: true,
    });
    
    const isFlagEnabled = this.props.unleashProps.isFlagEnabled();
    const TranscriptServerResponse = isFlagEnabled ? this.getLatestTranscriptData(personId) : getTranscriptDataAPI(personId);
    TranscriptServerResponse.then((_successLog) => {
      const data = _successLog;
      const issueDate = getCurrentDate();

      let categoriesHours = {
        [CONTENT_AREAS_ENUM.Education]: 0,
        [CONTENT_AREAS_ENUM.Rehab]: 0,
        [CONTENT_AREAS_ENUM.Workforce]: 0,
        [CONTENT_AREAS_ENUM.ReEntry]: 0,
        [CONTENT_AREAS_ENUM.Enrichment]: 0,
      };
      let totalHours = 0;
      this.userStorage.setItem(this.STORAGE_KEYS.TRANSCRIPT_DATE_STORAGE_KEY, new Date().toISOString());
      if ((data.transcriptListDtos || []).length === 0) {
        this.props.setIsDisabled(true);
      }
      if (!data) {
        const tempTranscriptData = {
          docId: '',
          issueDate,
          transcriptListDtos: [],
          totalHours,
          categoriesHours,
        };
        this.setState({
          transcriptData: tempTranscriptData,
          isTranscriptLoading: false,
        });

        this.userStorage.setItem(this.STORAGE_KEYS.TRANSCRIPT_STORAGE_KEY, JSON.stringify(tempTranscriptData));
        return;
      }
      data.issueDate = issueDate;

      data.transcriptListDtos.forEach((item) => {
        let bundleHours = 0;
        item.children.forEach((child) => {
          const childHours = parseFloat(child.hours.toFixed(1));
          child.score = this.getScore(child);
          child.lastDate = this.getLastAcessedDate(child);
          bundleHours += childHours;
          categoriesHours[child.contentAreaId] += childHours;
        });
        item.hours = bundleHours;
        totalHours += parseFloat(item.hours.toFixed(1));
      });

      const dataWithCalc = { ...data, totalHours, categoriesHours };

      this.setState({
        transcriptData: dataWithCalc,
        isTranscriptLoading: false,
      });
      this.userStorage.setItem(this.STORAGE_KEYS.TRANSCRIPT_STORAGE_KEY, JSON.stringify(dataWithCalc));
    });
  };

  LegacyTranscriptModal = ({ classes, isFlagEnabled }) => (
    <Dialog fullScreen open={this.props.open} onClose={this.onClose}>
      <div>
        <div className={classes.paper}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.onClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ flexGrow: 1, color: 'white' }}>
                Learner Progress Report
              </Typography>
              {this.state.transcriptData && (
                <BlobProvider
                  document={<PDFProgressReport data={this.state.transcriptData} userDetail={this.props.userInfo} />}
                >
                  {({ url }) => (
                    <a
                      style={{
                        display: isFlagEnabled ? 'none' : 'block',
                      }}
                      className={classes.toolbarBtn}
                      href={url}
                      download={`Transcript_${this.props.userInfo.userName}.pdf`}
                    >
                      <IconButton edge="end" color="inherit" className={classes.downloadPDFBtn}>
                        <GetAppIcon color="white" />
                      </IconButton>
                    </a>
                  )}
                </BlobProvider>
              )}
            </Toolbar>
          </AppBar>
          <div className={classes.content}>
            <ProgressReport data={this.state.transcriptData} userDetail={this.props.userInfo} />
          </div>
        </div>
      </div>
    </Dialog>
  );
  render() {
    const {
      classes,
      unleashProps: { isFlagEnabled },
    } = this.props;
    return (
      <>
        {!isFlagEnabled() && <this.LegacyTranscriptModal isFlagEnabled={isFlagEnabled()} classes={classes} />}
        {isFlagEnabled() && (
          <div className={classes.content}>
            <Loader isLoading={this.state.isTranscriptLoading} />
            <ProgressReport data={this.state.transcriptData} userDetail={this.props.userInfo} />
            {this.state.transcriptData && (
              <BlobProvider
                document={<PDFProgressReport data={this.state.transcriptData} userDetail={this.props.userInfo} />}
              >
                {({ url }) => (
                  <a
                    style={{
                      display: "none"
                    }}
                    id="legacy-transcript-download-btn"
                    href={url}
                    download={`Transcript_${this.props.userInfo.userName}.pdf`}
                  >
                    download transcript
                  </a>
                )}
              </BlobProvider>
            )}
          </div>
        )}
      </>
    );
  }
}

export default withStyles(styles)(withUnleash(Transcript, UnleashService.FLAGS.COMPLETION_REPORT));
