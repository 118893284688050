import React from 'react';
import { getResumesFromServer, getResumeFile } from '../../../../util/APIUtils';
import { getUSAFormattedDate, parseTimeToNewZone } from '../../../../components/users/learner/transcript/utils';
import { convertBase64ToBlob, downloadBlobFile, getQueryParamForServerSidePagination } from '../../../services/utilty';
import { LOCATIONS } from '../../../../constants';
import { RESUME_DOWNLOAD_FORMATS, SUCCESS, TIMEZONE } from '../../../services/constants';
import CustomDatePicker from '../../../../components/users/datatable/customDatePicker';
import CustomMenu from '../../../../components/management/menu/';
import store, { showToast } from '../../../core/store';
import useUnleashHook from '../../../core/unleash/useUnleashHook';
import { UnleashService } from '../../../services';

export const useResumeHook = (props) => {
  const { locationId, history, unleashProps } = props;
  const { isFlagEnabled: downloadDocxFlag } = useUnleashHook(UnleashService.FLAGS.RESUME_DOWNLOAD_DOCX_BTN);
  let TIMEZONE_NAME = 'US/Eastern';
  const [isLoading, setLoading] = React.useState(false);
  const [OPTIONS] = React.useState({
    sorting: true,
    pageSize: 20,
    selection: false,
    filtering: true,
    exportButton: false,
    debounceInterval: 1000,
    exportAllData: false,
    search: false,
    toolbar: false,
    draggable: false,
  });
  const COLUMNS = [
    {
      field: 'learnerName',
      title: 'Resident Name',
    },
    {
      field: 'docId',
      title: 'DOC ID',
    },
    {
      field: 'modifiedDate',
      title: 'Latest Edit',
      filtering: false,
      sorting: false,
      render: ({ modifiedDate, modifiedByUserType }) =>
        `Last edited by ${modifiedByUserType.toLowerCase()} ${parseTimeToNewZone(
          modifiedDate,
          TIMEZONE_NAME,
          'YYYY-MM-DD'
        )}`,
    },
    {
      field: 'action',
      title: 'Action',
      filtering: false,
      sorting: false,
      render: ({ personKey, learnerName }) => <CustomMenu options={getMenuOptions(personKey, learnerName)} />,
    },
  ];

  React.useEffect(() => {
    getLocationTimeZone();
  }, []);

  const getMenuOptions = (personKey, learnerName) => {
    const options = [
      {
        title: `Download ${RESUME_DOWNLOAD_FORMATS.PDF.toUpperCase()}`,
        onClick: () => onDownloadPdf(personKey, learnerName),
      },
    ];
    if (downloadDocxFlag()) {
      options.push({
        title: `Download .${RESUME_DOWNLOAD_FORMATS.DOCX}`,
        onClick: () => onDownloadDocx(personKey, learnerName),
      });
    }
    if (unleashProps.isFlagEnabled()) {
      options.push({
        title: 'Edit',
        onClick: () => history.push(`/resume/${personKey}?learnerName=${learnerName}`),
      });
    }
    return options;
  };

  const onDownloadDocx = async (personKey, learnerName) => {
    getDocumentBinary(
      () => getResumeFile(parseInt(personKey), RESUME_DOWNLOAD_FORMATS.DOCX),
      'application/vnd.openxmlformats-officedocument',
      `${learnerName}-resume.docx`
    );
  };

  const onDownloadPdf = async (personKey, learnerName) => {
    getDocumentBinary(() => getResumeFile(parseInt(personKey)), 'application/pdf', `${learnerName}-resume.pdf`);
  };

  const getDocumentBinary = async (apiCallback, format, fileName) => {
    setLoading(true);
    try {
      const { status, payload } = await apiCallback();
      if (status == SUCCESS) {
        if (downloadDocxFlag()) {
          downloadBlobFile(convertBase64ToBlob(payload, format), fileName);
        } else {
          window.open(convertBase64ToBlob(payload, 'application/pdf'), 'target=_blank');
        }
      } else {
        store.dispatch(showToast(`Something went wrong while fetching certificate.`));
      }
    } catch (e) {
      console.log(e);
      store.dispatch(showToast(`Something went wrong while fetching certificate.`));
    }

    setLoading(false);
  };
  const getLocationTimeZone = () => {
    const location = LOCATIONS.find((item) => item.locationId === locationId && item.active);
    const locationAttribute = (location.attributes || []).find((attr) => attr.attributeName === TIMEZONE);
    if (locationAttribute) {
      TIMEZONE_NAME = locationAttribute.attributeValue;
    }
  };

  const formatDateValue = (filter) => {
    const { field } = filter.column;
    if (field == 'modifiedDate') {
      filter.value = getUSAFormattedDate(filter.value, 'YYYY-MM-DD');
    }
    return filter;
  };

  const getFilterInQueryStringFormat = (filters) => {
    return filters.map(formatDateValue).reduce((initialValue, nextValue) => {
      return `${initialValue}${nextValue.column.field}=${nextValue.value}`;
    }, '&');
  };

  const getResumes = (query) => {
    return new Promise(async (resolve) => {
      try {
        const { page, filters } = query;
        const filteringQueryParams = getFilterInQueryStringFormat(filters);
        const {
          payload: { content, totalElements },
        } = await getResumesFromServer(locationId, getQueryParamForServerSidePagination(query, filteringQueryParams));
        resolve({
          data: content,
          totalCount: totalElements,
          page,
        });
      } catch (e) {
        resolve({
          data: [],
        });
      }
    });
  };

  return { OPTIONS, COLUMNS, isLoading, getResumes, getMenuOptions };
};
