import React from 'react';
import { downloadCertificates, getCertificatesFromServer, getIndividualCertificate } from '../../../../util/APIUtils';
import { getUSAFormattedDate } from '../../../../components/users/learner/transcript/utils';
import { getQueryParamForServerSidePagination } from '../../../services/utilty';
import CustomDatePicker from '../../../../components/users/datatable/customDatePicker';
import { LinkButton } from '@orijinworks/frontend-commons';
import store, { showToast } from '../../../core/store';
import { SUCCESS } from '../../../services/constants';
import { useTheme } from '@material-ui/core';

export const useCertificatesHook = props => {
  const { locationId } = props;
  const [isLoading, setLoading] = React.useState(false);
  const [selectedCertificates, setCertificates] = React.useState([]);
  const theme = useTheme();
  const [OPTIONS] = React.useState({
    sorting: true,
    pageSize: 20,
    selection: true,
    filtering: true,
    exportButton: false,
    debounceInterval: 1000,
    exportAllData: false,
    search: false,
    toolbar: false,
    draggable: false
  });
  const COLUMNS = [
    {
      field: 'learnerName',
      title: 'Learner Name'
    },
    {
      field: 'docId',
      title: 'DOC ID'
    },
    {
      field: 'resourceName',
      title: 'Program'
    },
    {
      field: 'issuedDate',
      title: 'Completion Date',
      defaultSort: 'desc',
      filterComponent: props => <CustomDatePicker {...props} isReadyOnly={true} />,
      render: ({ issuedDate }) => getUSAFormattedDate(issuedDate, 'YYYY-MM-DD')
    },
    {
      field: 'action',
      title: 'Action',
      filtering: false,
      sorting: false,
      render: ({ resourceKey, personKey }) => (
        <LinkButton
          style={{ color: theme.palette.primary.navy }}
          id={`link-button-${personKey}`}
          onClick={() => onViewCertificate(resourceKey, personKey)}
        >
          View
        </LinkButton>
      )
    }
  ];

  const onViewCertificate = async (resourceKey, personKey) => {
    setLoading(true);
    try {
      const { status, payload } = await getIndividualCertificate(parseInt(resourceKey), parseInt(personKey));
      if (status == SUCCESS) {
        window.open(payload.certificateUrl, '_blank');
      } else {
        store.dispatch(showToast(`Something went wrong while fetching certificate.`));
      }
    } catch (e) {
      console.log(e);
      store.dispatch(showToast(`Something went wrong while fetching certificate.`));
    }

    setLoading(false);
  };

  const formatDateValue = filter => {
    const { field } = filter.column;
    if (field == 'issuedDate') {
      filter.value = getUSAFormattedDate(filter.value, 'YYYY-MM-DD');
    }
    return filter;
  };

  const getFilterInQueryStringFormat = filters => {
    return filters.map(formatDateValue).reduce((initialValue, nextValue) => {
      return `${initialValue}${nextValue.column.field}=${nextValue.value}`;
    }, '&');
  };

  const getCertificates = query => {
    return new Promise(async resolve => {
      try {
        const { page, filters } = query;
        const filteringQueryParams = getFilterInQueryStringFormat(filters);
        const { content, totalElements } = await getCertificatesFromServer(
          locationId,
          getQueryParamForServerSidePagination(query, filteringQueryParams)
        );
        resolve({
          data: content,
          totalCount: totalElements,
          page
        });
      } catch (e) {
        resolve({
          data: []
        });
      }
    });
  };

  const handleDownload = async (event,rowData) => {
    setLoading(true);
    const response = await downloadCertificates(selectedCertificates.map(certificate => certificate.id));
    const url = window.URL.createObjectURL(response);

    const today = new Date();
    const dateString = today.toISOString().slice(0, 10);
    const filename = `certificates_${dateString}.zip`;

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);

    document.body.appendChild(link);

    link.click();
    URL.revokeObjectURL(url);
    setLoading(false);
  };

  return { OPTIONS, COLUMNS, getCertificates, isLoading, selectedCertificates,setCertificates, handleDownload };
};