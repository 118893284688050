import React from 'reactn'
import { Typography, Box } from '@material-ui/core'
const NotPermittedUI = ({text}) => {
    return (
        <Box 
            boxShadow={3}
            bgcolor="white"
            style={{ width: '100%', height: 420,display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h3" component="h3">{text}</Typography>
        </Box>
    )
}

export default NotPermittedUI
