import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getFormattedDate, parseTimeToNewZone } from "../../../../users/learner/transcript/utils";

const useStyles = makeStyles(() => ({
  messageBox: {
    border: "1px solid black",
    padding: "10px",
    borderRadius: "5px",
    marginLeft: "auto",
    width: "fit-content",
    marginBottom: "5px",
    textAlign: "left",
    fontSize: "14px",
    maxWidth: "75%",
    wordBreak: "break-word",
  },
  messageDiv: {
    margin: "15px 0",
    maxWidth: "90%",
    textAlign: "right",
  },
  messageFooter: {
    fontSize: "10px",
  },
  fnf: {
    marginLeft: "initial"
  },
  eventText: {
    fontSize: "14px",
  },
  eventBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px 0px"
  },
  eventBorder: {
    flex: 1,
    border: "1px solid rgba(0, 0, 0, 0.12)"
  },
  eventText: {
    textAlign: "center",
    color: "#737982",
    margin: "0px 10px"
  }
}));
const MessageBox = ({ message, timezoneName }) => {
  const classes = useStyles();
  const sender =
    message.senderPersonId === message.learner.personId
      ? message.learner
      : message.contact;

  const name = `${sender.firstName} ${sender.lastName}`;
  const date = parseTimeToNewZone(message.createdDate, timezoneName);
  const registeredEmail = sender.registeredEmail || null;
  return (
    <div className={classes.messageDiv}>
      {message.messageType === "EVENT" && (
        <div className={classes.eventBox}>
          <div className={classes.eventBorder}></div>
          <div className={classes.eventText}>{message.body} {date}</div>
          <div className={classes.eventBorder}></div>
        </div>
      )}

      {message.messageType !== "EVENT" && (
        <>
          <div className={[classes.messageBox, message.senderPersonId === message.contact.personId ? classes.fnf : null].join(" ")}>{message.body}</div>
          <div className={classes.messageFooter}
            style={{
              "textAlign": message.senderPersonId === message.contact.personId ? "left" : "right"
            }}
          >
            {registeredEmail && <div>registeredEmail</div>}
            <div>
              {name} <br /> {date}
            </div>
          </div>
        </>
      )}


    </div>
  );
};

export default MessageBox;
