import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import RoomIcon from '@material-ui/icons/Room';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import TabletAndroidIcon from '@material-ui/icons/TabletAndroid';
import SettingsSystemDaydreamIcon from '@material-ui/icons/SettingsSystemDaydream';
import UserSettings from './usersettings';
import TabletSettings from './tabletsettings';
import LocationSettings from './locationsettings';
import ProgramSettings from './programsettings';
import { theme } from '../../v2/core/containers/theme';

class Settings extends Component {
  render() {
    return (
      <div>
        <div style={{ padding: '1.5rem' }}>
          <Grid container spacing={24}>
            <Grid item sm={3} xs={6}>
              <Link to="/settings/users">
                <Card elevation={7}>
                  <CardActionArea
                    style={{ color: 'white', padding: '1rem', background: theme.palette.background.blue }}
                  >
                    <SupervisedUserCircleIcon style={{ fontSize: 30 }} />
                    <br />
                    User Settings
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Link to="/settings/locations">
                <Card elevation={7}>
                  <CardActionArea
                    style={{ color: 'white', padding: '1rem', background: theme.palette.background.blue }}
                  >
                    <RoomIcon style={{ fontSize: 30 }} />
                    <br />
                    Location Settings
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Link to="/settings/tablets">
                <Card elevation={7}>
                  <CardActionArea
                    style={{ color: 'white', padding: '1rem', background: theme.palette.background.blue }}
                  >
                    <TabletAndroidIcon style={{ fontSize: 30 }} />
                    <br />
                    Tablet Settings
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Link to="/settings/programs">
                <Card elevation={7}>
                  <CardActionArea
                    style={{ color: 'white', padding: '1rem', background: theme.palette.background.blue }}
                  >
                    <SettingsSystemDaydreamIcon style={{ fontSize: 30 }} />
                    <br />
                    Program Settings
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </div>
        <div>
          <Route path="/settings/users" component={UserSettings} />
          <Route path="/settings/locations" component={LocationSettings} />
          <Route path="/settings/tablets" component={TabletSettings} />
          <Route path="/settings/programs" component={ProgramSettings} />
        </div>
      </div>
    );
  }
}

export default Settings;
