import { DATE_FORMAT, LOCATIONS, USER_CONTEXT } from '../../../constants';
import { ManagementService } from '../../management/management.service';
import { VOICE_CALL } from '../constants/voice-call-constants';
import moment from 'moment-timezone';

import { LOCATION_CACHE_KEY } from '../../../v2/services/constants';
import { UserStorage } from '../../../util/UserStorage';
export const checkVoiceCallFeatureFlag = (setGlobal, locations = LOCATIONS) => {
  const isLocationHasActiveVoiceCall = locations.some((location) => {
    return (location.activeFeatures || []).indexOf(ManagementService.MANAGEMENT_FEATURE_ENUM.VoiceCalls) > -1;
  });
  setGlobal({
    showVoiceCallTile: isLocationHasActiveVoiceCall,
  });
};

/**
 * Calculates the duration in minutes between two UTC dates.
 *
 * @param {string} startTime - The start time in UTC format.
 * @param {string} endTime - The end time in UTC format.
 * @returns {number|string} - The duration in minutes or 'N/A' if either startTime or endTime is missing.
 */
export const calculateDurationInMins = (startTime, endTime) => {
  let duration;
  if (startTime && endTime) {
    const start = moment.utc(startTime);
    const end = moment.utc(endTime);
    duration = moment.duration(end.diff(start)).asMinutes().toFixed(1) + ' Mins';
  } else {
    duration = 'N/A';
  }
  return duration;
};

/**
 * Formats the join time in UTC to a specific date format.
 * @param {string} datetime - The date time in UTC.
 * @returns {string} - The formatted date time or 'N/A' if datetime is falsy.
 */

export const formatDatetime = (
  datetime,
  timeZone = getSelectedLocationTimeZone(),
  format = DATE_FORMAT.MM_DD_YYYY_HH_MM_A
) => {
  let date;
  if (datetime) {
    date = moment.utc(datetime).tz(timeZone).format(format);
  } else {
    date = 'N/A';
  }
  return date;
};
export const generateStatusChangeOptions = (rowData, updateContactStatus, handleEditContactModalOpen) => {
  const isApproved = rowData.status === VOICE_CALL.STATUS.APPROVED;
  const isRejected = rowData.status === VOICE_CALL.STATUS.REJECTED;
  const isBlocked = rowData.status === VOICE_CALL.STATUS.BLOCKED;
  const isPending = rowData.status === VOICE_CALL.STATUS.PENDING;
  const isArchived = rowData.status === VOICE_CALL.STATUS.INACTIVE;
  const options = [
    {
      title: 'Approve Request',
      id : VOICE_CALL.STATUS.APPROVED,
      onClick: () => {
        updateContactStatus(rowData, VOICE_CALL.STATUS.APPROVED);
      },
      disabled: isApproved || isBlocked
    },
    {
      title: 'Reject Request',
      id : VOICE_CALL.STATUS.REJECTED,
      onClick: () => {
        updateContactStatus(rowData, VOICE_CALL.STATUS.REJECTED);
      },
      disabled: (isRejected || isBlocked || !isPending) && !isArchived
    },
    {
      title: 'Archive Request',
      id : VOICE_CALL.STATUS.INACTIVE,
      onClick: () => {
        updateContactStatus(rowData, VOICE_CALL.STATUS.INACTIVE);
      },
      disabled: isPending || isBlocked || isRejected || isArchived
    }
  ];
  if (!isRejected) {
    options.push(
      {
        title: isBlocked ? 'Unblock Request' : 'Block Request',
        id : VOICE_CALL.STATUS.BLOCKED,
        onClick: () => {
          updateContactStatus(rowData, isBlocked ? VOICE_CALL.STATUS.APPROVED : VOICE_CALL.STATUS.BLOCKED);
        },
        disabled: isPending
      },
      {
        title: 'Edit',
        onClick: () => {
          handleEditContactModalOpen(rowData);
        },
        disabled: false, // Edit option is always enabled when status is not rejected
      }
    );
  }

  return options;
};

export const getSelectedLocationTimeZone = () => {
  const userStorage = new UserStorage(USER_CONTEXT.personId);
  let locationInformation = userStorage.getItem(LOCATION_CACHE_KEY);
  if (!locationInformation) {
    return moment.tz.guess();
  }

  locationInformation = JSON.parse(locationInformation);
  if (locationInformation && locationInformation.attributes) {
    const timeZoneAttr = locationInformation.attributes.find((attr) => attr.attributeName === 'TimeZone');
    if (timeZoneAttr) {
      return timeZoneAttr.attributeValue;
    }
  }
  return moment.tz.guess();
};

export const parseRelationshipType = (relationshipType) => {
  return relationshipType.replace(/_/g, ' ');
};

export const getFullName = (firstName, lastName) => `${firstName || ''} ${lastName || ''}`.trim();

export const formatTranscriptTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = secs.toString().padStart(2, '0');
  return `${paddedMinutes}:${paddedSeconds}`;
};

export const highlightKeywords = (text, keywords, renderHighlight) => {
  const regex = new RegExp(`(${keywords?.join('|')})`, 'gi');
  return text
    ?.split(regex)
    .map((part, index) => (keywords?.includes(part.toLowerCase()) ? renderHighlight(part, index) : part));
};
