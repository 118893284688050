import React from 'reactn';
import { Box, Typography, Button, Grid } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Datatable from 'material-table';
import moment from 'moment';
import SortIcon from '@material-ui/icons/Sort';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import styles from './index.style';
import { withStyles } from '@material-ui/styles';
import { getStaffDetails } from '../../../../util/APIUtils';
import { ROLES, setStaffDetails, STAFF_DETAILS } from '../../../../constants/';
import Hoverable from '../../../hoverable';
import { UserIcon } from '../../../UserIcon';
import { theme } from '../../../../v2/core/containers/theme';

class StaffDetails extends React.Component {
  staffDetailsColumns = [
    {
      title: 'Location Name',
      field: 'locationName',
      render: rowData => <Hoverable title={rowData.locationName}>{rowData.locationName}</Hoverable>
    },
    {
      title: 'Role Name',
      field: 'roleDisplayName',
      sorting: false,
      render: rowData => <Hoverable title={rowData.roleDisplayName}>{rowData.roleDisplayName}</Hoverable>
    },
    {
      title: 'Status',
      field: 'active',
      sorting: true,
      defaultSort: 'desc',
      render: rowData => (
        <Hoverable title={rowData.active ? 'Active' : 'Inactive'}>
          <p style={{ color: rowData.active ? 'black' : theme.palette.text.red }}>{rowData.active ? 'Active' : 'Inactive'}</p>
        </Hoverable>
      )
    }
  ];
  state = {
    data: STAFF_DETAILS
  };

  /**
   * @name getStaffDetails
   * @param {number} userId
   * @desc Fetches details by user ID.
   * @return {void}
   */
  getStaffDetails = userID => {
    this.setGlobal({
      isLoading: true
    });
    this.setState({
      showEmptyDataSourceMessage: false
    });
    getStaffDetails(userID).then(
      _successLog => {
        console.log('HEY');
        this.setGlobal({
          isLoading: false
        });
        this.setState({
          data: _successLog,
          showEmptyDataSourceMessage: true
        });
        console.log(this.state);
        setStaffDetails({
          ..._successLog,
          id: userID
        });
      },
      _errorLog => {
        this.setGlobal({
          isLoading: false
        });
        console.error('Something went wrong while staff details');
      }
    );
  };

  getAttorneyDetails(key) {
    if (!this.props.attorneyDetails) {
      return 'N/A';
    }
    return this.props.attorneyDetails[key] || 'N/A';
  }

  render() {
    const { classes, locations = [] } = this.props;
    locations.sort((a, b) => a.locationName.localeCompare(b.locationName));
    return (
      <>
        {/* <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    marginBottom="15px"
                >
                    <Button
                        onClick={() => null}
                        variant="contained" color="primary" disableElevation>
                        Create Relationship
                        </Button>
                </Box> */}
        <Box width="100%" boxShadow={3}>
          {this.props.roleType === ROLES.ATTORNEY && (
            <Box padding="20px" bgcolor="white">
              <Typography className={classes.attorneyDetailHead}>Attorney Details</Typography>
              <Grid container item className={classes.fieldGroup}>
                <Grid md={3}>Law Firm Name</Grid>
                <Grid md={3}>{this.getAttorneyDetails('lawFirmName')}</Grid>
                <Grid md={3}>Phone Number</Grid>
                <Grid md={3}>{this.getAttorneyDetails('telephoneNumber')}</Grid>
              </Grid>
              <Grid container item className={classes.fieldGroup}>
                <Grid md={2}>Address 1</Grid>
                <Grid md={2}>{this.getAttorneyDetails('address1')}</Grid>
                <Grid md={2}>Address 2</Grid>
                <Grid md={2}>{this.getAttorneyDetails('address2')}</Grid>
                <Grid md={2}>Zip Code</Grid>
                <Grid md={2}>{this.getAttorneyDetails('zipCode')}</Grid>
              </Grid>
              <Grid container item className={classes.fieldGroup}>
                <Grid md={2}>City</Grid>
                <Grid md={2}>{this.getAttorneyDetails('city')}</Grid>
                <Grid md={2}>State</Grid>
                <Grid md={2}>{this.getAttorneyDetails('stateName')}</Grid>
                <Grid md={2}>Country</Grid>
                <Grid md={2}>{this.getAttorneyDetails('country')}</Grid>
              </Grid>
            </Box>
          )}
          <Datatable
            title="Admin Locations"
            style={{ width: '100%' }}
            columns={this.staffDetailsColumns}
            data={(this.state.data && locations) || []}
            options={{
              sorting: true,
              showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
              pageSize: 20,
              selection: false,
              showSelectAllCheckbox: true,
              filtering: true,
              exportButton: true,
              Sort: SortIcon,
              SortArrow: ArrowDownwardIcon,
              actionsColumnIndex: 0,
              search: false,
              exportFileName: 'Relationships',
              selectionProps: rowData => ({
                id: `checkbox-${rowData.formId}`
              })
            }}
            icons={{
              Search: SearchIcon,
              ResetSearch: CloseIcon,
              FirstPage: FirstPageIcon,
              LastPage: LastPageIcon,
              NextPage: NavigateNextIcon,
              PreviousPage: NavigateBeforeIcon,
              Filter: FilterListIcon,
              SortArrow: ArrowUpwardIcon,
              Export: SaveAltIcon,
              Edit: EditIcon,
              User: UserIcon
            }}
          />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(StaffDetails);
