export default (theme) => ({
    successfulUserCount: {
        fontWeight: 'bold',
        color: theme.palette.text.green
    },
    failedUserCount: {
        fontWeight: 'bold',
        color: theme.palette.text.red
    },
    failedUserBox: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        border: `1px solid var(--grey-90)`,
        padding: 20,
        borderRadius: 5,
        marginTop: 20,
        overflow: "auto"
    },
    username: {
        textTransform: 'capitalize',
    },
    typo: {
        color: "var(--grey-100)"
    }
});