
export default theme => ({
  mainWrapper: {
    backgroundColor: theme.textWhite ? theme.textWhite.main : '#FFF',
    margin: '15px 10px 0 40px'
  },
  heading : {
    fontWeight:700,
  },
  innerWrapper: {
    justifyContent: 'space-between',
    display: 'flex'
  },
  innerWrapperLeft: {
    textAlign: 'left',
    margin: '20px 20px'
  },
  innerWrapperLeftHeading: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    letterSpacing: '0.36px'
  },
  innerWrapperLeftSubHeading: {
    fontSize: 20,
    color: theme.palette.text.sub,
    letterSpacing: '0.21px',
    width: 'max-content'
  },
  minutesSection: {
    display: 'flex',
    alignItems: 'center'
  },
  minutesInput: {
    width: '30%',
    marginLeft: 'auto'
  },
  minutesDropdown: {
    marginLeft: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
  },
  saveButton: {
    margin: '15px',
    padding: '8px'
  },
});
