import { StyleSheet, Font } from '@react-pdf/renderer';
import { theme } from '../../../../theme';
export default StyleSheet.create({
  heading: {
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.primary.main,
    paddingLeft: 10,
    borderBottomWidth: 1, // Add a bottom border to the container
    borderBottomColor: theme.palette.primary.pastelBlue, // Border color
    paddingTop: 10, // Add some padding at the top
    paddingBottom: 10 // Add some padding at the bottom
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  brandLogo: {
    width: 60,
    marginTop: 10
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 20
  },
  callDetails: {
    padding: 10,
    borderBottomWidth: 1, // Add a bottom border to the container
    borderBottomColor: theme.palette.primary.pastelBlue, // Border color
    paddingBottom: 10 // Add some padding at the bottom
  },
  callInfo: {
    marginTop: 10, // Add some margin between title and body
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary.main
  },
  callInfoSubFields: {
    marginTop: 10, // Add some margin between title and body
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: theme.palette.primary.pastelBlue
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 25
  },
  secondCell: {
    backgroundColor: theme.palette.colors.babyBlue
  },
  // Cell styles
  timestamp: {
    width: '10%', // Adjust the width as needed
    fontSize: 10, // Adjust the font size as needed
    textAlign: 'left',
    paddingLeft: 10
  },
  externalUserId: {
    width: '90%', // Adjust the width as needed
    fontSize: 10,
    textAlign: 'left',
    padding: '5px 15px',
    whiteSpace: 'break-spaces'
  },
  mainTopicsHeading: {
    fontSize: 12,
    marginRight: 20,
    marginTop: 10,
    fontWeight: 'bold',
    color: theme.palette.primary.pastelBlue
  },
  mainTopicsText: {
    fontSize: 10,
    marginTop: 5
  }
});
