import React from 'reactn'
import { Paper, AppBar, Toolbar, IconButton, Typography, Dialog, withStyles, Box, DialogContent } from '@material-ui/core'
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CloseIcon from '@material-ui/icons/Close'
import Datatable from 'material-table'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import SortIcon from '@material-ui/icons/Sort'
import FilterListIcon from '@material-ui/icons/FilterList'
import RefreshIcon from '@material-ui/icons/Refresh'
import ContentAreas from '../../../content-areas';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import EditIcon from '@material-ui/icons/Edit'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { getStudentAdditionalContent } from '../../../../util/APIUtils';
import { CONTENT_AREA, CONTENT_AREAS_ENUM } from '../../../../constants';
import Hoverable from '../../../hoverable';
import { UserIcon } from '../../../UserIcon';

const styles = (theme) => ({
    appBar: {
        position: 'relative'
    },
    dialogContent: {
        padding: 25
    },
    notesHeading: {
        ...theme.typography.t20,
        ...theme.typography.medium,
        color: theme.palette.primary.jetBlack,
        letterSpacing: '0.15px',
        lineHeight: '24px'
    }
});



class ProgressContentAreas extends React.Component {
    contentAreaRef = null;
    state = {
        additionalContents: null,
        additionalContentLoading: false
    };

    /**
     * @name getAdditionalContent
     * @param {number} userId
     * @desc Fetches additional content tagged to user from micro service.
     * @return {void}
     */
    getAdditionalContent = (userId, contentAreas) => {
        this.setState({
            additionalContentLoading: true,
            showEmptyDataSourceMessage: false
        });
        this.setGlobal({
            isLoading: true
        });
        getStudentAdditionalContent(userId, contentAreas.join(","), this.global.range.startDate, this.global.range.endDate)
            .then(
                (_successLog) => {
                    this.setState({
                        additionalContents: _successLog,
                        additionalContentLoading: false,
                        showEmptyDataSourceMessage: true
                    });
                    this.setGlobal({
                        isLoading: false
                    });
                },
                (_errorLog) => {
                    this.setState({
                        additionalContents: [],
                        additionalContentLoading: false
                    });
                    this.setGlobal({
                        isLoading: false
                    });
                }
            );
    };


    render() {
        const { classes } = this.props;
        return (
            <Dialog fullScreen open={this.props.open} onClose={() => this.props.closeHandler(false)}>
                <div>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={() => this.props.closeHandler(false)} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" style={{ flexGrow: 1, color: 'white' }}>
                                {this.props.userDetail.firstName} {this.props.userDetail.lastName}
                            </Typography>
                            <IconButton edge="end" color="inherit" className={classes.downloadPDFBtn} >
                                <RefreshIcon color="white" />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent
                        className={classes.dialogContent}
                    >
                        <ContentAreas
                            onMount={(selectedContentAreas) => {
                                this.getAdditionalContent(this.props.userDetail.userId, selectedContentAreas);
                            }}
                            selectedContentAreas={[
                                CONTENT_AREAS_ENUM.Education,
                                CONTENT_AREAS_ENUM.Rehab,
                                CONTENT_AREAS_ENUM.Workforce,
                                CONTENT_AREAS_ENUM.ReEntry,
                                CONTENT_AREAS_ENUM.Enrichment,
                            ]}
                            ref={(ref) => this.contentAreaRef = ref}
                            contentAreaUpdated={(selectedContentAreas) => {
                                console.log(selectedContentAreas)
                                this.getAdditionalContent(this.props.userDetail.userId, selectedContentAreas);
                            }}
                        />
                        <Box
                            width="100%"
                            className={classes.content}>
                            <Box marginTop="30px">
                                <Datatable
                                    title="Results"
                                    data={this.state.additionalContents || []}
                                    columns={[
                                        {
                                            title: 'Service Name',
                                            field: 'serviceDisplayName',
                                            render: rowData => (
                                                <Hoverable title={rowData.serviceDisplayName}>
                                                    {rowData.serviceDisplayName}
                                                </Hoverable>
                                            )
                                        },
                                        {
                                            title: 'Resource Name',
                                            field: 'resourceName',
                                            render: rowData => (
                                                <Hoverable title={rowData.resourceName}>
                                                    {rowData.resourceName}
                                                </Hoverable>
                                            )
                                        },
                                        {
                                            title: 'Completed',
                                            field: 'isCompleted',
                                            render: rowData => (
                                                <Hoverable title={rowData.isCompleted ? "Completed" : "In Progress..."}>
                                                    {rowData.isCompleted ? "Completed" : "In Progress..."}
                                                </Hoverable>
                                            )
                                        },
                                        {
                                            title: 'Content Area',
                                            field: 'contentAreaId',
                                            render: rowData => (
                                                <Hoverable title={CONTENT_AREA[rowData.contentAreaId].name}>
                                                    {CONTENT_AREA[rowData.contentAreaId].name}
                                                </Hoverable>
                                            )
                                        },

                                    ]}
                                    components={{
                                        Container: props => <Paper {...props} elevation={0} />
                                    }}
                                    icons={{
                                        Search: SearchIcon,
                                        ResetSearch: CloseIcon,
                                        FirstPage: FirstPageIcon,
                                        LastPage: LastPageIcon,
                                        NextPage: NavigateNextIcon,
                                        PreviousPage: NavigateBeforeIcon,
                                        Filter: FilterListIcon,
                                        Sort: SortIcon,
                                        SortArrow: ArrowDownwardIcon,
                                        Export: SaveAltIcon,
                                        Edit: EditIcon,
                                        User: UserIcon
                                    }}
                                    options={{
                                        showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
                                        pageSize: 20,
                                        sorting: true,
                                        exportButton: false,
                                        exportAllData: true,

                                    }}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                </div>


            </Dialog>
        )
    }
}

export default withStyles(styles)(ProgressContentAreas)