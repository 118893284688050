import React from "reactn";
import moment from 'moment';
import _ from "lodash";

import {
    Grid,
    TextField,
    withStyles,
  } from '../MaterialUIComponents';
import { getUSAFormattedDate } from '../../../../components/users/learner/transcript/utils'
import styles from './index.style';

const maxYear = new Date().getFullYear() - 1;

function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
}

class DatePickerInputs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            month: this.props.date ? getUSAFormattedDate(this.props.date , 'MM'): null,
            day: this.props.date ? getUSAFormattedDate(this.props.date , 'DD') : null,
            year: this.props.date ? getUSAFormattedDate(this.props.date , 'YYYY') : null,
        };
    }



    handleChange = _.debounce((value, field) => {
        let date;
        if (field === "month") {
            if (value > 12) {
                value = 12;
            }
        } else if (field === "day") {
            if (value > 31) {
                value = 31;
            }
        } else if (field === "year") {
            if (value > maxYear) {
                value = maxYear;
            } else if (value.toString().length === 4 && value < 1900) {
                value = 1900;
            }
        }

        this.setState({ [field]: value }, 
            () => {
                if (this.state.month && this.state.day && this.state.year && this.state.year.length === 4) {
                    date = moment(`${this.state.month}/${this.state.day}/${this.state.year}`, "MM/DD/YYYY");
                    this.props.change(isValidDate(date) ? date : "");
                } else if (this.props.checkErrors) {
                    this.props.change("");
                    this.props.checkErrors();
                }
            });
      }, 100);
  

  render() {
    const { classes } = this.props;

    return <Grid item xs={12} className={classes.dateWrapper} >
        <TextField
            id="month"
            label="MM"
            type="number"
            margin="normal"
            size="small"
            variant="outlined"
            value={this.state.month}
            required
            inputProps={{ min: 1, max: 12}}
            onChange={(e) => 
                this.handleChange(e.target.value, "month")
            }
        />
        <TextField
            id="day"
            label="DD"
            type="number"
            margin="normal"
            size="small"
            variant="outlined"
            value={this.state.day}
            required
            inputProps={{ min: 1, max: 31  }}
            onChange={(e) => 
                this.handleChange(e.target.value, "day")
            }
        />
        <TextField
            id="year"
            label="YYYY"
            type="number"
            margin="normal"
            size="small"
            variant="outlined"
            value={this.state.year}
            required
            inputProps={{ min: 1900, max: maxYear }}
            onChange={(e) => 
                this.handleChange(e.target.value, "year")
            }
        />
    </Grid>
  }
}


export default withStyles(styles)(DatePickerInputs);
