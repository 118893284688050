import React, { forwardRef } from 'react';
import { Modal, Typography, KeywordDictionary } from '@orijinworks/frontend-commons';
import styles from './add-keywords-dictionary-modal.style';
import { withStyles } from '@material-ui/core';
import useAddKeywordDictionaryModalHook from './useAddKeywordDictionaryModalHook';

const AddKeywordsDictionaryModal = forwardRef(
  (
    { classes, open, isModalOpen, onClose, refreshTable, dictionaryId, handleAddKeywordDictionaryModal, handleSnackbarOpen },
    ref
  ) => {
    const { keywords, onKeywordChange, saveKeywords } = useAddKeywordDictionaryModalHook(
      isModalOpen,
      refreshTable,
      dictionaryId,
      handleAddKeywordDictionaryModal,
      handleSnackbarOpen
    );

    return (
      <Modal
        ref={ref}
        open={open}
        onClose={onClose}
        heading="Add Keywords"
        showCrossIcon={true}
        actionBtnText="Save Keywords"
        cancelBtnText="Cancel"
        onContinue={saveKeywords}
        continueButtonProps={{
          disabled: keywords.length === 0,
        }}
        styles={{
          mainWrapperStyles: {
            height: 'auto',
          },
        }}
        data-testid="modal"
      >
        <Typography className={classes.description} data-testid="description">
          Add a keyword or phrase to flag in calls for this location.
        </Typography>
        <KeywordDictionary onKeywordChange={onKeywordChange} data-testid="keyword-dictionary" />
      </Modal>
    );
  }
);

export default withStyles(styles)(AddKeywordsDictionaryModal);
