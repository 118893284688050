import { useState,useRef } from 'react';
import { updateVoiceCallSchedule } from '../../../../../../util/APIUtils';
import moment from 'moment';
import { VOICE_CALL } from '../../../../constants/voice-call-constants';

const useScheduleAccordianHook = ({scheduleData, onDeleteSchedule}) => {
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [isActionButtonsDisabled, setDisableActionButton] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isScheduleFormValid, setIsScheduleFormValid] = useState(scheduleData);
  const scheduleRef = useRef(null);
  const transformScheduleDataForScheduleWrapper = (scheduleData) => {
    const callScheduleList = scheduleData?.callSchedule ?? [];
    const transformedSchedule = callScheduleList.map((schedule) => ({
      dayOfWeek: VOICE_CALL.DAY_OF_WEEK_MAP[schedule.dayOfWeek],
      slots: schedule.slots.map(slot => ({
        startTime : moment(slot.timeStart, 'HH:mm').isValid() ? moment(slot.timeStart, 'HH:mm').format('hh:mm a') : slot.timeStart,
        endTime : moment(slot.timeEnd, 'HH:mm').isValid() ? moment(slot.timeEnd, 'HH:mm').format('hh:mm a') : slot.timeEnd
      })),
    }));
  return {...scheduleData, callSchedule:transformedSchedule};
  };
  
  const [scheduleDataObj, setScheduleDataObj] = useState(transformScheduleDataForScheduleWrapper(scheduleData));
  
  const transformScheduleDataForAPI = (callSchedule) => {
    const callScheduleList = callSchedule ? callSchedule : [];
    const transformedSchedule = callScheduleList.map((schedule) => ({
      dayOfWeek: Object.keys(VOICE_CALL.DAY_OF_WEEK_MAP).find(key => VOICE_CALL.DAY_OF_WEEK_MAP[key] === schedule.dayOfWeek),
      slots: schedule.slots.map(slot => ({
        timeStart : moment(slot.startTime, 'hh:mm a').isValid() ? moment(slot.startTime, 'hh:mm a').format('HH:mm:ss') : slot.startTime,
        timeEnd : moment(slot.endTime, 'hh:mm a').isValid() ? moment(slot.endTime, 'hh:mm a').format('HH:mm:ss') : slot.endTime
      })),
    }));
    return transformedSchedule;
  }

  const handleExpand =  (event) => {
    setExpanded((prev) => !prev);
  }

  const handleEditSchedule = (event) => {
    event.stopPropagation();
    setEditMode(true);
  };

  const handleSaveChanges = () => {  
    setDisableActionButton(true);  
    const transformedSchedule = transformScheduleDataForAPI(scheduleRef.current.getSchedule());
    const updatedScheduleData = {...scheduleDataObj, callSchedule:transformedSchedule};
    submit(updatedScheduleData)
  };

  const submit = async (updatedScheduleData) => {
    try {
      const data = await updateVoiceCallSchedule(updatedScheduleData);
  
      if (data?.errorMessage) {
        handleSnackbarOpen(data.errorMessage?.[0], 'error');
      } else {
        handleSnackbarOpen('Schedule updated successfully', 'success');
        const updatedSchedule = transformScheduleDataForScheduleWrapper(updatedScheduleData);
        setScheduleDataObj(updatedSchedule);
        scheduleData.maxMinutesPerCall = updatedScheduleData.maxMinutesPerCall;
        scheduleData.maxMinutesPerDay = updatedScheduleData.maxMinutesPerDay;
        scheduleData.scheduleName = updatedScheduleData.scheduleName;
        setEditMode(false);
      }
      setDisableActionButton(false);
    } catch (error) {
      setMessageType('error');
      handleSnackbarOpen('Error while updating schedule', 'error');
      setDisableActionButton(false);
    }

  };
  
  const handleCancelEdit = () => {
    setScheduleDataObj({
      ...scheduleData,
      callSchedule:scheduleDataObj.callSchedule,
      maxMinutesPerCall: scheduleData.maxMinutesPerCall,
      maxMinutesPerDay: scheduleData.maxMinutesPerDay,
      scheduleName: scheduleData.scheduleName,
    });
    setEditMode(false);    

  }

  const handleDeleteSchedule = () => {
    const updatedCallSchedule = transformScheduleDataForAPI(scheduleDataObj.callSchedule);
    updateVoiceCallSchedule({...scheduleDataObj, active:false,callSchedule:updatedCallSchedule}).then((data) => {
      if(data?.errorMessage) {
        handleSnackbarOpen(data.errorMessage?.[0], 'error');
      }else{
        onDeleteSchedule();
      }
    }).catch((error)=>{
      setShowDeleteModal(false);
    });
    setShowDeleteModal(false);
    setEditMode(false);

  };

  const handleScheduleDataChange = (event, fieldName) => {
    const newValue = event.target
      ? event.target.value
      : parseInt(event);    
      
      setScheduleDataObj((prev) => ({ ...prev, [fieldName]: newValue }));
  }

  const handleSnackbarOpen = (message, messageType) => {  
    setMessage(message);
    setMessageType(messageType);
    setShowSnackBar(true);
  }


  return {
    scheduleDataObj,
    expanded,
    editMode,
    handleExpand,
    handleEditSchedule,
    handleSaveChanges,
    handleCancelEdit,
    handleDeleteSchedule,
    handleScheduleDataChange,
    message,
    messageType,
    showSnackBar,
    setShowSnackBar, 
    isActionButtonsDisabled,
    showDeleteModal,
    setShowDeleteModal,
    handleSnackbarOpen,
    scheduleRef,
    setIsScheduleFormValid,
    isScheduleFormValid
  };
};

export default useScheduleAccordianHook;
