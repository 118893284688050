import React from 'reactn';
import {
    Card,
    CardContent,
    withStyles,
    Box,
    Typography,
    Tooltip
} from '@material-ui/core';

import { Accordion, Loader } from '../../..';
import styles from '../index.style';
import { CustomCheckbox } from '../../../../custom-checkbox';

const ResumeBuilder = (props) => {
    const { classes } = props;
    let featureData = { classes: [classes.level1Box, props.isSuperAdmin ? "" : classes.noBorderBottom], tooltipText: ''}
    return (
        <Accordion 
        id="accordion"
        title={"Re-Entry Preparation"}>
        <Card variant="outlined" className={classes.outlinedComponent}>
                <CardContent
                    classes={{
                        root: [classes.settingsRoot, classes.noPaddBottom].join(" ")
                    }}>

                    <Box
                        component="div"
                        id="box-wrapper"
                        justifyContent="space-between"
                        className={featureData.classes.join(" ")}
                        display="flex"
                    >
                        <Box
                            component="div"
                            textAlign="left"
                        >
                            <Typography className={classes.boxHeading1}>
                                {"Resumé Builder Access"}
                            </Typography>
                            <Typography id="checkbox-text" className={classes.subHeading1}>
                                If turned on, learners and admins at this location will be able to use the resumé builder. 
                            </Typography>
                            <Typography id="checkbox-text" className={classes.subHeading1}>
                                For security, we strongly recommend only turning this on in combination with Biometric Authentication and/or User-Device Pairing.
                            </Typography>

                        </Box>
                        <Tooltip title={featureData.tooltipText}>
                            <Box
                                display="flex"
                                alignItems="center"
                                component="div">
                                {props.resumeBuilderStatusLoader && (<Loader />)}
                                <CustomCheckbox
                                   id="status-checkbox"
                                   onChange={() => {
                                        props.updateSettingsState('feature', {
                                            status: !props.status
                                        }, 'features', 'resumeBuilder', 'resumeBuilderStatusLoader')
                                    }}
                                   checked={props.status}
                                />
                            </Box>
                        </Tooltip>
                    </Box>

                </CardContent>
            </Card>
        </Accordion>
    );
};

export default withStyles(styles)(ResumeBuilder);
