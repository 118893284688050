import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import Moment from "react-moment";
import moment from "moment";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from "@material-ui/icons/Error";

import styles from "./index.style";
import TimeSelect from "./time-select/index";

/**
 * @name addSlot
 * @param {number} index Index at which slots needs to be added.
 * @param {array} scheduleCollection Collection of schedule in which mutation is required.
 * @param {Function} updateSchedule A callback method which will be called in order to update.
 * @desc Adds slot to that particular index
 * @return {void}
 */
const addSlot = (index, scheduleCollection, updateSchedule, status) => {
  const workingScheduleCollection = _.cloneDeep(scheduleCollection);
  workingScheduleCollection[index].slots.push({
    startTime: moment({ y: 1970, M: 0, d: 1 }).format(),
    endTime: moment({ y: 1970, M: 0, d: 1, H: 0, m: 30 }).format(),
    isActive: status,
    startTimePickerOpen: false,
    endTimePickerOpen: false,
  });
  updateSchedule(workingScheduleCollection);
};

/**
 * @name removeSlot
 * @param {number} index Index at which slots needs to be added.
 * @param {array} scheduleCollection Collection of schedule in which mutation is required.
 * @param {Function} updateSchedule A callback method which will be called in order to update.
 * @desc Adds slot to that particular index
 * @return {void}
 */
const removeSlot = (index, slotIndex, scheduleCollection, updateSchedule) => {
  const workingScheduleCollection = _.cloneDeep(scheduleCollection);
  workingScheduleCollection[index].slots.splice(slotIndex, 1);
  updateSchedule(workingScheduleCollection);
};

/**
 * @name toggleEdit
 * @param {array} scheduleCollection Collection of schedule in which mutation is required.
 * @param {Function} updateSchedule A callback method which will be called in order to update.
 * @desc Toggles slots to edit / read-only state.
 * @return {void}
 */
const toggleEdit = (
  scheduleCollection,
  updateSchedule,
  updateServerSchedule,
  loaderKey
) => {
  scheduleCollection = Object.assign([], scheduleCollection);
  updateSchedule(scheduleCollection);
  updateServerSchedule(loaderKey);
};

/**
 * @name toggleActiveState
 * @param {boolean} status
 * @param {array} scheduleCollection Collection of schedule.
 * @param {number} scheduleIndex Index of schedule that needs to be updated.
 * @param {Function} updateSchedule A callback method which will be called in order to update.
 * @desc Toggles time picker based upon its previous state.
 * @desc Toggles active status of slots.
 * @return {void}
 */
const toggleActiveState = (
  status,
  scheduleCollection,
  scheduleIndex,
  updateSchedule,
  updateServerSchedule,
  loaderKey
) => {
  scheduleCollection = _.cloneDeep(scheduleCollection);
  scheduleCollection[scheduleIndex]["slots"] = scheduleCollection[
    scheduleIndex
  ]["slots"].map((_slot) => {
    _slot["isActive"] = !status;
    return _slot;
  });
  updateSchedule(scheduleCollection);
  updateServerSchedule(loaderKey);
};

const Schedule = (props) => {
  const { classes } = props;
  const [canSave, setCanSave] = useState(true);
  const [showError, setShowError] = useState(true);
  const [workingSchedule, setWorkingSchedule] = useState(
    _.cloneDeep(props.schedule)
  );
  const [errorMessage, setErrorMessage] = useState(undefined);

  const updateWorkingSchedule = (schedule) => {
    setWorkingSchedule(_.cloneDeep(schedule));
  };

  const setSchedule = (schedule) => {
    props.updateSettingSchedule(props.featureName, schedule);
  };

  const [isEdit, setIsEdit] = useState(props.edit);
  const saveEdits = () => {
    toggleEdit(
      workingSchedule,
      setSchedule,
      props.updateSchedule,
      props.loaderKey
    );
    resetEditVars(false);
  };
  const resetEditVars = (resetWorkingSchedule) => {
    setIsEdit(false);
    setCopyBase(undefined);
    setCopyTarget([]);
    setErrorMessage(undefined);
    if (resetWorkingSchedule) updateWorkingSchedule(props.schedule);
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [copyBase, setCopyBase] = useState(undefined);
  const [copyTarget, setCopyTarget] = useState([]);
  const handleClickOpen = (index) => {
    setDialogOpen(true);
    setCopyBase(index);
    setCopyTarget([]);
  };
  const handleCopySubmit = async (event, scheduleCollection) => {
    event.preventDefault();
    handleScheduleCopy(scheduleCollection);
  };
  const handleScheduleCopy = (scheduleCollection) => {
    // copy the schedule
    const baseSchedule = scheduleCollection[copyBase];
    scheduleCollection = _.cloneDeep(scheduleCollection);
    copyTarget.forEach((target) => {
      // empty the target schedule
      scheduleCollection[target]["slots"] = [];

      // build the schedule
      baseSchedule["slots"].forEach((slot, index) => {
        scheduleCollection[target].slots.push({
          startTime: baseSchedule["slots"][index]["startTime"],
          endTime: baseSchedule["slots"][index]["endTime"],
          isActive: baseSchedule["slots"].every((slot) => slot.isActive),
          startTimePickerOpen: false,
          endTimePickerOpen: false,
        });
      });
    });
    updateWorkingSchedule(scheduleCollection);
    setDialogOpen(false);
    setCopyBase(undefined);
    setCopyTarget([]);
  };
  const updateCopyTarget = (index) => {
    setCopyTarget(
      copyTarget.includes(index)
        ? copyTarget.filter((c) => c !== index)
        : [...copyTarget, index]
    );
  };
  let daysOfTheWeek = [];

  useEffect(() => {
    setCanSave(
      (workingSchedule || []).every((scheduleData, scheduleIndex) =>
        scheduleData.slots.every((slot) => slot.isActive)
          ? scheduleData.slots.every((slotData) => !slotData.isInvalid)
          : true
      )
    );
  }, [workingSchedule]);

  useEffect(() => {
    setShowError(
      (workingSchedule || []).some((scheduleData, scheduleIndex) =>
        scheduleData.slots.some((slot) => slot.isActive)
          ? scheduleData.slots.some(
              (slotData) => slotData.isInvalid || slotData.isWarning
            )
          : false
      )
    );
  }, [workingSchedule]);

  return (
    <Box>
      <Box className={classes.scheduleHeader}>
        {showError && errorMessage && (
          <div className={classes.errorMessage}>
            <ErrorIcon />
            <p>{errorMessage}</p>
          </div>
        )}
        <Box className={classes.editButtons}>
          {isEdit && (
            <Button
              color="primary"
              onClick={() => resetEditVars(true)}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            id="schedule-save-button"
            onClick={() => (!isEdit ? setIsEdit(true) : saveEdits())}
            disabled={isEdit && !canSave}
          >
            {isEdit ? "Save" : "Edit"}
          </Button>
        </Box>
      </Box>
      {(workingSchedule || []).map((scheduleData, scheduleIndex) => (
        <Box
          component="div"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
          className={[props.className, classes.scheduleLayout]}
        >
          {isEdit ? (
            <Box component="div" className={classes.dayOfWeekWrapper}>
              <FormControlLabel
                classes={{
                  root: classes.label,
                }}
                control={
                  <Checkbox
                    onChange={() =>
                      toggleActiveState(
                        scheduleData.slots.every((slot) => slot.isActive),
                        isEdit ? workingSchedule : props.schedule,
                        scheduleIndex,
                        !isEdit ? setSchedule : setWorkingSchedule,
                        props.updateSchedule,
                        props.loaderKey
                      )
                    }
                    checked={scheduleData.slots.every((slot) => slot.isActive)}
                  />
                }
                label={
                  <Typography className={classes.dayOfWeek}>
                    {scheduleData.dayOfWeek}
                  </Typography>
                }
                {...daysOfTheWeek.push(scheduleData.dayOfWeek)}
              />
            </Box>
          ) : (
            <Box
              component="div"
              className={classes.dayOfWeekWrapper}
              style={{ textAlign: "left" }}
            >
              <Typography
                className={classes.dayOfWeek}
                style={{ paddingLeft: "24px" }}
              >
                {scheduleData.dayOfWeek}
              </Typography>
            </Box>
          )}
          {/* EDIT  */}
          {isEdit && (
            <Box
              component="div"
              data-test-class="edit-box"
              flex={1}
              display="flex"
              className={classes.scheduleWrapper}
            >
              {/* BEGIN: SLOT */}
              {scheduleData.slots.every((slot) => slot.isActive) ? (
                scheduleData.slots.map((slotData, slotIndex) => (
                  <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    className={classes.scheduleBox}
                  >
                    <Box>
                      <TimeSelect
                        label={"Start"}
                        initialTime={
                          slotData.startTime
                            ? slotData.startTime.split(".")[0]
                            : null
                        }
                        loaderKey = {props.loaderKey}
                        isInvalid={slotData.isInvalid && errorMessage}
                        isWarning={slotData.isWarning && errorMessage}
                        schedule={workingSchedule}
                        scheduleIndex={scheduleIndex}
                        setSchedule={updateWorkingSchedule}
                        setErrorMessage={setErrorMessage}
                        slotIndex={slotIndex}
                        slotKey={"startTime"}
                      />
                    </Box>
                    <Divider className={classes.divider} />
                    <Box>
                      <TimeSelect
                        label={"End"}
                        initialTime={
                          slotData.endTime
                            ? slotData.endTime.split(".")[0]
                            : null
                        }
                        loaderKey = {props.loaderKey}
                        isInvalid={slotData.isInvalid && errorMessage}
                        isWarning={slotData.isWarning && errorMessage}
                        schedule={workingSchedule}
                        scheduleIndex={scheduleIndex}
                        setSchedule={updateWorkingSchedule}
                        setErrorMessage={setErrorMessage}
                        slotIndex={slotIndex}
                        slotKey={"endTime"}
                      />
                    </Box>

                    {scheduleData.slots.length > 1 ? (
                      <IconButton
                        onClick={() =>
                          removeSlot(
                            scheduleIndex,
                            slotIndex,
                            workingSchedule,
                            updateWorkingSchedule
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : (
                      ""
                    )}
                    {slotIndex !== scheduleData.slots.length - 1 && (
                      <Divider className={classes.verticalDivider} />
                    )}
                  </Box>
                ))
              ) : (
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  className={classes.scheduleBox}
                >
                  <Typography className={classes.slotReadOnly}>
                    <span>Not Available</span>
                  </Typography>
                </Box>
              )}
              {/* END: SLOT */}
            </Box>
          )}
          {/* NOT EDIT */}
          {!isEdit && (
            <Box
              data-test-class="read-only-box"
              component="div"
              flex={1}
              alignItems="center"
              flexWrap="wrap"
              display="flex"
              className={classes.scheduleWrapper}
            >
              {/* BEGIN: SLOT */}
              {scheduleData.slots.every((slot) => slot.isActive) ? (
                scheduleData.slots.map((slotData, slotIndex) => (
                  <Box
                    component="div"
                    display="flex"
                    alignItems="center"
                    className={classes.scheduleBox}
                  >
                    <Typography className={classes.slotReadOnly}>
                      <Moment format="h:mm A">{slotData.startTime}</Moment>
                    </Typography>
                    <Divider
                      className={[
                        classes.divider,
                        classes.readOnlyDivider,
                      ].join(" ")}
                    />
                    <Typography className={classes.slotReadOnly}>
                      <Moment format="h:mm A">{slotData.endTime}</Moment>
                    </Typography>

                    {slotIndex !== scheduleData.slots.length - 1 && (
                      <Divider
                        className={[
                          classes.verticalDivider,
                          classes.readOnlyDivider,
                        ].join(" ")}
                      />
                    )}
                  </Box>
                ))
              ) : (
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  className={classes.scheduleBox}
                >
                  <Typography className={classes.slotReadOnly}>
                    <span style={{ marginLeft: "16px" }}>Not Available</span>
                  </Typography>
                </Box>
              )}

              {/* END: SLOT */}
            </Box>
          )}

          <Box
            component="div"
            className={classes.scheduleIconWrapper}
            display="flex"
          >
            {isEdit && scheduleData.slots.every((slot) => slot.isActive) && (
              <>
                {scheduleData.slots.length < 4 && (
                  <IconButton
                    onClick={() =>
                      addSlot(
                        scheduleIndex,
                        workingSchedule,
                        updateWorkingSchedule,
                        scheduleData.slots.every((slot) => slot.isActive)
                      )
                    }
                  >
                    <AddIcon />
                  </IconButton>
                )}
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => handleClickOpen(scheduleIndex)}
                >
                  Copy
                </Button>
              </>
            )}
          </Box>
        </Box>
      ))}
      <Dialog
        className={classes.copyDialog}
        hideBackdrop={true}
        open={dialogOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            setDialogOpen(false);
          }
        }}
      >
        <form onSubmit={(event) => handleCopySubmit(event, workingSchedule)}>
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <Typography>Copy schedule to</Typography>
            <FormGroup name="copyDays">
              {daysOfTheWeek.map((day, index) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={copyBase === index}
                      disabled={copyBase === index}
                      onChange={(event) => updateCopyTarget(index)}
                      name={day}
                      value={index}
                    />
                  }
                  label={day}
                />
              ))}
            </FormGroup>
            <Button color="primary" variant="contained" type="submit">
              Apply
            </Button>
          </FormControl>
        </form>
      </Dialog>
    </Box>
  );
};

export default withStyles(styles)(Schedule);
