import {
    Button,
    Dialog,
    Grid,
    Typography,
    withStyles
  } from "@material-ui/core";
  import React from "react";
  import styles from "./index.styles";
  
  const AnnouncementHideConfirmation = props => {
    const { classes, closeModal } = props;
    const title = 'Delete this Announcement?';
    const description = 'Are you sure you want to delete this announcement?';
    return (
      <Dialog open={props.isDialogOpen}>
        <Grid
          container
          item
          className={classes.mainDialog}
        >
          {/* BEGIN: Title */}
          <Grid container item className={classes.cartWrapper}>
            <Grid
              container
              item
              className={classes.keyValuePairText}
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.darkText}>
                {title}
              </Typography>
            </Grid>
          </Grid>
  
          {/* END: TITLE */}
  
          {/* BEGIN: BODY TEXT */}
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.bodyText}>
              {description}
            </Typography>
          </Grid>
  
          {/* END: BODY TEXT */}
          {/* BEGIN: FOOTER */}
          <Grid
            container
            item
            className={classes.dialogFooter}
            justify="flex-end"
          >
            <Button
              color="primary"
              className={classes.confirmBtn}
              onClick={() => {
                closeModal(false);
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              className={classes.confirmBtn}
              onClick={() => {
                closeModal(true);
              }}
            >
              Yes
            </Button>
          </Grid>
          {/* END: FOOTER */}
        </Grid>
      </Dialog>
    );
  };
  
  export default withStyles(styles)(AnnouncementHideConfirmation);
  