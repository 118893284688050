export default (theme) => ({
    root: {
        padding: "0px 20px",
        width: '100%'
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        padding: 20
    },
    heading: {
        ...theme.typography.t24,
        ...theme.typography.bold
    },
    tableWrapper: {
        boxShadow: 'none'
    },
    statusWrapper: {
        borderRadius: '6px',
        width: 'fit-content',
        padding: '0px 12px 0px 12px',
        fontSize: 16,
        fontWeight: 700
    },
    statusIcon: {
        height: '14px',
        marginLeft: '-5px',
        marginTop: '-5px',
    },
    activeBox: {
        border: `1px solid ${theme.palette.colors.greenDeep}`,
        background: `${theme.palette.colors.greenPale}`,
        '& span': {
            color: `${theme.palette.colors.greenDark}`,
        },
        '& svg': {
            color: `${theme.palette.colors.greenDark}`,
        }
    },
    deactivatedBox: {
        border: `1px solid ${theme.palette.secondary.red.main}`,
        background: `${theme.palette.secondary.red.pale}`,
        '& span': {
            color: `${theme.palette.colors.redDark}`,
        },
        '& svg': {
            color: `${theme.palette.secondary.red.main}`,
        }
    },
    movedBox: {
        background: `${theme.palette.colors.grey20}`,
        border: `1px solid ${theme.palette.colors.grey90}`,
        '& span': {
            color: `${theme.palette.colors.grey80}`,
        }
    }
});