import { useEffect, useState } from 'react';
import { addLearnerGroups, getLearnerGroupById, updateLearnerGroup } from "../../../../util/APIUtils";
import { MessageService } from "../../../../v2/services/message.service";
import { DUPLICATE_LEARNER_ERROR, FAILURE, INACTIVE_GROUP_ERROR, SUCCESS } from "../../../../v2/services/constants";
import { USER_CONTEXT } from '../../../../constants';

export const useAddNewLearnerGroupHook = props => {
    const [groupName, setGroupName] = useState("");
    const [groupMembers, setGroupMembers] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredLearners, setFilteredLearners] = useState([]);
    const [selectedLearner, setSelectedLearner] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [learnerGroupError, setError] = useState(null);
    const [isDuplicate, setDuplicate] = useState(false);
    const [showMessageMonitoredWarning, setShowMessageMonitoredWarning] = useState(true);
    const [previousGroupName, setPreviousGroupName] = useState("");
    const messageService = new MessageService();

    useEffect(() => {
      if (props.editLearnerGroupId) {
        getLearnerGroupDataById(props.editLearnerGroupId);
      }
    }, [props.editLearnerGroupId]);

    useEffect(() => {
      if (props.editLearnerGroupId && groupMembers.length === 0 && groupName) {
        setError(INACTIVE_GROUP_ERROR);
      } else {
        setError(null)
      }
    }, [props.editLearnerGroupId, groupMembers, groupName]);

    useEffect(() => {
      if(props.preSelectedLearners && props.preSelectedLearners.length) {
        addGroupMembers(props.preSelectedLearners);
      }
    },[props.preSelectedLearners]);

    const handleCancelClick = () => {
      setGroupName("");
      setSearch("");
      setGroupMembers([]);
      setError(null);
      setDuplicate(false);
      props.handleClose();
    };

    const getLearnerGroupDataById = async (groupId) => {
      try {
        const response = await getLearnerGroupById(groupId);
        if (response && response.status === SUCCESS) {
          const { groupName, learnerGroupMembers } = response.payload;
          setGroupName(groupName);
          setPreviousGroupName(groupName);
          addGroupMembers(learnerGroupMembers);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const addGroupMembers = (members = []) => {
      setGroupMembers(members.map(member => {
        const { personId, firstName, lastName, docId, reachable } = member;
        return { 
          contactId: personId, 
          contactName: `${firstName || ''} ${lastName || ''}`,
          reachable,
          docId
        }
      }));
    }

    const handleSaveClick = async () => {
      try {
        let response = null;
        let payload = {
          group_members: groupMembers.map(member => member.contactId),
          staff_person_key: USER_CONTEXT.personId
        };

        if(groupName !== previousGroupName) {
          payload.group_name = groupName;
        }

        if (props.editLearnerGroupId) {
          response = await updateLearnerGroup(props.editLearnerGroupId, payload);
        } else {
          response = await addLearnerGroups(payload);
        }
        if (response && response.status === FAILURE) {
          setError(DUPLICATE_LEARNER_ERROR);
          setDuplicate(true);
        } else {
          const updatedGroupData = {groupName};
          handleCancelClick();
          return updatedGroupData;
        }
      } catch (error) {
        setDuplicate(true);
        setError(DUPLICATE_LEARNER_ERROR);
      }
    };

    const onSearchChange = (value) => {
      if (value) {
        setLoading(true);
        messageService.getSuggestedUsers(value)
          .then((response) => {
            if (response && Array.isArray(response)) {
              setFilteredLearners((response || []).map((item) => {
                const { personId, firstName, lastName,docId } = item;
                return { contactId: personId, contactName: `${firstName} ${lastName}`, docId };
              }));
            }
            setLoading(false);
          });
      } else {
        setFilteredLearners([]);
      }
      setSearch(value);
    };
  
    const onUserSelect = async (data) => {
      setSearch(data.contactName);
      setFilteredLearners([]);
      setSelectedLearner(data);
    };
  
    const getUnselectedUser = (userList) => {
      return (userList || []).filter(user => {
        return !groupMembers.find(_groupMember => _groupMember.contactId === user.contactId);
      });
    }
  
    const handleAddLearner = () => {
      if (selectedLearner) {
        selectedLearner.reachable = true;
        setGroupMembers([...groupMembers, selectedLearner]);
        setSelectedLearner(null);
        setSearch('');
      }
    }
  
    const removeGroupMember = (contactId) => {
      const _groupMembers = groupMembers.filter((member) => member.contactId !== contactId);
      setGroupMembers(_groupMembers);
    }

    const isSubmitDisabled = () => {
      return isDuplicate || !groupName || (!props.editLearnerGroupId && groupMembers.length === 0);
    }

    const clearErrors = () => {
      setError(false);
      setShowMessageMonitoredWarning(false); 
    }

    const onGroupNameChange = (e) => {
      setDuplicate(false);
      setGroupName(e.target.value); 
    }

    const onSubmit = async () => {
      if (props.updatedGroupData && props.editLearnerGroupId) {
        props.updatedGroupData(await handleSaveClick());
      } else {
        await handleSaveClick();
        
        if (props.updatedGroupData) {
          props.updatedGroupData(null);
        }
      }
    };

    return {
      search,
      filteredLearners,
      isLoading,
      showMessageMonitoredWarning,
      learnerGroupError,
      groupName,
      selectedLearner,
      groupMembers,
      setDuplicate,
      setGroupMembers,
      setGroupName,
      removeGroupMember,
      handleAddLearner,
      getUnselectedUser,
      onUserSelect,
      onSearchChange,
      handleSaveClick,
      handleCancelClick,
      isSubmitDisabled,
      clearErrors,
      onGroupNameChange,
      onSubmit
    };
  };
