import React from "reactn";

import { Box, Typography, Tooltip } from "@material-ui/core";
import HelpOutlineOutlined from "@material-ui/icons/HelpOutlineOutlined";



export const CustomToolTip = ({ heading, title, displayIcon = true }) => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "unset"
      }}
    >
      <Box marginRight={1}>
        <Typography variant="h3" gutterBottom>
          {heading}
        </Typography>
      </Box>
      {displayIcon && (
        <Tooltip title={title} placement="top-end" interactive>
          <HelpOutlineOutlined />
        </Tooltip>
      )}
    </Box>
  );
};
