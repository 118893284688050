import React from "react";

import { FormControlLabel, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import EditButton from "../EditButton";
import AntSwitch from "../AntSwitch";
import RoleNameDialog from "../RoleNameDialog";
import ConfirmationDialog from "../ConfirmationDialog";
import DuplicateRoleDialog from "../DuplicateRoleDialog";
import styles from "./index.styles";
import { saveRoleDetails } from "../../../util/APIUtils";

const useStyles = makeStyles((theme) => styles(theme));

const Header = ({ roleDetail, fetchRoleDetail }) => {
  const classes = useStyles();
  const [isRoleNameEdit, setRoleNameEdit] = React.useState(false);
  const [isActive, setIsActive] = React.useState(roleDetail.active);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = React.useState(
    false
  );
  const [isDuplicateRoleDialogOpen, setDuplicatRoleDialogOpen] = React.useState(
    false
  );

  const handleActiveChange = async (event) => {
    if (event.target.checked) {
      onActiveChange(true);
    } else {
      handleConfirmationDialogOpen();
    }
  };

  const onActiveChange = async (state) => {
    await saveRoleDetails({
      active: state,
      roleId: roleDetail.roleId,
    });
    setIsActive(state);
  };

  const onDeactivate = async () => {
    onActiveChange(false);
  };

  const handleRoleNameEditClose = () => {
    setRoleNameEdit(false);
  };

  const handleRoleNameEditOpen = () => {
    setRoleNameEdit(true);
  };

  const handleConfirmationDialogOpen = () => {
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleDuplicateRoleDialogOpen = () => {
    setDuplicatRoleDialogOpen(true);
  };

  const handleDuplicateRoleDialogClose = () => {
    setDuplicatRoleDialogOpen(false);
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.roleNameWrapper}>
        <h1 className={classes.roleName}>{roleDetail.roleName}</h1>

        {!roleDetail.systemDefault && (
          <EditButton onClick={handleRoleNameEditOpen} />
        )}
      </div>

      <div>
        <FormControlLabel
          control={
            <AntSwitch
              checked={isActive}
              onChange={handleActiveChange}
              disabled={roleDetail.systemDefault}
            />
          }
          label={isActive ? "Active" : "Inactive"}
          labelPlacement="start"
          classes={{
            label: classes.switchLabel,
            root: classes.switchWrapper,
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleDuplicateRoleDialogOpen}
          disabled={!isActive && !roleDetail.systemDefault}
          startIcon={
            <img
              className={classes.duplicateIcon}
              src="/assets/icons/duplicate.svg"
            />
          }
        >
          Duplicate Role
        </Button>
      </div>

      <RoleNameDialog
        roleId={roleDetail.roleId}
        roleName={roleDetail.roleName}
        open={isRoleNameEdit}
        handleClose={handleRoleNameEditClose}
        onSave={fetchRoleDetail}
      />

      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        handleClose={handleConfirmationDialogClose}
        confirmationMode="deactivate"
        onSave={onDeactivate}
      />

      <DuplicateRoleDialog
        open={isDuplicateRoleDialogOpen}
        handleClose={handleDuplicateRoleDialogClose}
        roleId={roleDetail.roleId}
        roleName={roleDetail.roleName}
        roleTypeName={roleDetail.roleTypeName}
      />
    </div>
  );
};

export default Header;
