export default (theme) => ({
    locationName: {
        color: '#747474',
        letterSpacing: '1.25px',
        fontSize: 11,
        fontWeight: 700
    },
    datatable: {
        '& td': {
            fontSize: 14,
            letterSpacing: '0.04px',
            color: '#000000'
        }
    },
    topActionBar: {
        backgroundColor: '#f3f3f3'
    },
    tableStyles: {
        display: 'table',
        width: 'inherit',
        marginTop: '20px',
    },
    recipientRender: {
        '& div': {
            maxWidth: '100%',
            width: '400px'
        }
    },
    announcementView: {
        textDecoration: 'underline',
        cursor: 'pointer',
        ...theme.typography.bold,
    },
    iconMargin:{
        marginRight: 10
    }
});