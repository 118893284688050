import React from "react";

const ListIcon = props => {
  const { size, fill } = props;

  return (
    <svg id="explore_careers_med" data-name="explore careers_med" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24.258 24.258">
      <path 
        id="enquiries" 
        d="M3.032,24.258A3.036,3.036,0,0,1,0,21.226V3.032A3.036,3.036,0,0,1,3.032,0H21.226a3.036,3.036,0,0,1,3.032,3.032V21.226a3.036,3.036,0,0,1-3.032,3.032ZM2.022,3.032V21.226a1.012,1.012,0,0,0,1.011,1.011H21.226a1.012,1.012,0,0,0,1.011-1.011V3.032a1.012,1.012,0,0,0-1.011-1.011H3.032A1.012,1.012,0,0,0,2.022,3.032ZM5.347,18.911a1,1,0,0,1-.212-1.1,1.167,1.167,0,0,1,.212-.334,1.166,1.166,0,0,1,.334-.212.979.979,0,0,1,1.314.546.849.849,0,0,1,.081.384,1.015,1.015,0,0,1-1.728.718Zm4.761.293a1.011,1.011,0,1,1,0-2.021h8.086a1.011,1.011,0,0,1,0,2.021ZM5.347,12.846a1.063,1.063,0,0,1-.293-.718,1.527,1.527,0,0,1,.02-.2.641.641,0,0,1,.061-.182.765.765,0,0,1,.091-.182l.121-.152A1.167,1.167,0,0,1,5.68,11.2a1.011,1.011,0,0,1,.768,0,1.167,1.167,0,0,1,.334.212l.121.152a.766.766,0,0,1,.091.182.641.641,0,0,1,.061.182,1.535,1.535,0,0,1,.02.2A1,1,0,0,1,6.064,13.14,1.063,1.063,0,0,1,5.347,12.846Zm4.761.293a1.011,1.011,0,1,1,0-2.021h8.086a1.011,1.011,0,0,1,0,2.021ZM5.347,6.782a1.043,1.043,0,0,1-.212-.334.952.952,0,0,1,0-.768,1.043,1.043,0,0,1,.212-.334,1.168,1.168,0,0,1,.334-.212.966.966,0,0,1,.586-.061.642.642,0,0,1,.182.061.768.768,0,0,1,.182.091l.152.121a1.044,1.044,0,0,1,.212.334,1.009,1.009,0,0,1,.081.384,1.063,1.063,0,0,1-.293.718L6.631,6.9a.767.767,0,0,1-.182.091.641.641,0,0,1-.182.061,1.513,1.513,0,0,1-.2.02A1.063,1.063,0,0,1,5.347,6.782Zm4.761.293a1.011,1.011,0,1,1,0-2.021h8.086a1.011,1.011,0,0,1,0,2.021Z" 
        fill={fill}
        stroke="none"
      />
    </svg>
  );
};

ListIcon.defaultProps = {
  size: 24.258,
  fill: "inherit"
}

export default ListIcon;
