export default (theme) => ({
    annoucementBox: {
        margin: "15px 0px 0px"
    },
    title: {
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    textSpacing: {
        marginBottom: 15
    },
    description: {
        wordBreak: "break-all"
    },
    readReceipt: {
        display: "inline-block",
        color: theme.palette.primary.main,
        textDecoration: "underline",
        fontSize: 14,
        cursor: "pointer"
    },
    bodyLineBreak: {
        whiteSpace: "pre-wrap"
    },
    bodyAutoPhrase: {
        wordBreak: "auto-phrase"
    }
});