import React, { forwardRef } from 'react';
import { Modal, Typography } from '@orijinworks/frontend-commons';
import styles from './hide-keywords-modal.style';
import { withStyles } from '@material-ui/core';
import SecurityIcon from '@material-ui/icons/Security';
const HideKeywordsModal = forwardRef(({ classes, open, onClose, onContinue }, ref) => {
  return (
    <Modal
      Icon={<SecurityIcon className={classes.icon} />}
      ref={ref}
      open={open}
      onClose={onClose}
      showCrossIcon={true}
      actionBtnText="Disable Keyword"
      cancelBtnText="Cancel"
      onContinue={onContinue}
      continueButtonProps={{ color: 'destructive' }}
      styles={{
        mainWrapperStyles: {
          height: '350px',
          width: '420px',
          border: '1px solid ##DBE0E8',
        },
      }}
      data-testid="modal"
    >
      <Typography classes={classes.mainDescription} variant="h5" data-testid="main-description">
        Stop monitoring this keyword?
      </Typography>
      <Typography className={classes.description} variant="body" data-testid="description">
        Stopping monitoring of this keyword will stop tagging it in future calls and transcripts. Historical calls and
        transcripts will be unaffected. Are you sure you want to proceed?
      </Typography>
    </Modal>
  );
});

export default withStyles(styles)(HideKeywordsModal);
