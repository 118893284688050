import React from 'react';
import loadData from '../../helpers/loadData';
import CircularProgress from '@material-ui/core/CircularProgress';
import HomeIcon from '@material-ui/icons/Home'

class Todos extends React.Component {
  constructor(props) {
    super(props);

    if (props.staticContext && props.staticContext.data) {
      this.state = {
        data: props.staticContext.data
      };
    } else {
      this.state = {
        data: []
      };
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (window.__ROUTE_DATA__) {
        this.setState({
          data: window.__ROUTE_DATA__
        });
        delete window.__ROUTE_DATA__;
      } else {
        loadData('people').then(data => {
          this.setState({
            data
          });
        });
      }
    }, 0);
  }

  render() {
    const { data } = this.state;
    const results = data.results
    
    return <ul style={{marginTop:'4rem'}}><HomeIcon/>{results? <div><img height="300" src="https://www.bleedingcool.com/wp-content/uploads/2017/09/star-wars-the-force-awakens.jpeg?x70969"/>{results.map((p)=><li>{p.name}</li>)}</div> : <div><CircularProgress/><br/><span>Loading some friends...</span></div>}</ul>;
  }
}

export default Todos;