import React, { useGlobal } from 'reactn';

import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';

import { commonStyles } from '../common';
import styles from './index.styles';
import { UserIcon } from '../../UserIcon';

const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
  ...styles(theme)
}));

const UsersListDialog = ({ open, handleClose, userList, loading, mode = 'warning' }) => {
  const classes = useStyles();
  const [_, setGlobal] = useGlobal();

  let appBar = (
    <AppBar className={`${classes.appBar} ${classes.appBarBaseRules} ${classes.warningAppBar}`}>
      <Toolbar>
        <WarningIcon />
        <Typography variant="h6" className={classes.title}>
          Warning
        </Typography>
        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );

  if (mode === 'info') {
    appBar = (
      <AppBar className={`${classes.appBar} ${classes.appBarBaseRules}`}>
        <Toolbar>
          <IconButton className={classes.closeIcon} edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Assigned Users
          </Typography>
        </Toolbar>
      </AppBar>
    )
  }

  let dialogContent;

  if (loading) {
    dialogContent = (
      <div className={classes.loaderWrapper}>
        <CircularProgress size={30} />
      </div>
    );
  } else if (!loading && userList.length === 0) {
    dialogContent = (
      <div className={`${classes.prompt} ${classes.loaderWrapper}`}>
        Currently this role is assigned to no users.
      </div>
    );
  } else if (!loading && userList.length !== 0) {
    dialogContent = (
      <>
        <span className={classes.prompt}>
          {mode === 'info' ?
            "Following is the list of learners this role is assigned to." :
            "Please remove the role from these learners so that it can be removed from this location"
          }
        </span>

        {userList.map((user) => (
          <div
            key={user.personId}
            className={classes.userRow}
          >
            <UserIcon className={classes.userIcon} />
            <span className={classes.username}>{user.fullName}</span>
          </div>
        ))}
      </>
    )
  }

  return (
    <Dialog
      classes={{
        paperWidthXs: classes.dialog
      }}
      maxWidth="xs"
      scroll="paper"
      open={open}
      onClose={handleClose}
    >
      {appBar}

      <DialogContent className={classes.dialogContent}>
        <span className={classes.heading}>
          {mode === 'info' ?
            "List of users" :
            "Remove Role From Location"
          }
        </span>
        {dialogContent}
      </DialogContent>
    </Dialog>
  );
}

export default UsersListDialog;