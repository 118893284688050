import React from "react";

import { IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import EditButton from "../EditButton";
import AssignedLocationsEditDialog from "../AssignedLocationsEditDialog";
import UsersListDialog from "../UsersListDialog";
import { commonStyles } from "../common";
import styles from "./index.styles";
import { fetchUsersList } from "../../../util/APIUtils";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

const AssignedLocations = ({ roleDetail, fetchRoleDetail }) => {
  const { locations, roleId } = roleDetail;
  const classes = useStyles();
  const [isAssignedLocationEdit, setAssignedLocationsEdit] = React.useState(
    false
  );
  const [isUserListDialogOpen, setUserListDialogOpen] = React.useState(false);
  const [userList, setUserList] = React.useState([]);
  const [userListLoading, setUserListLoading] = React.useState(true);

  const handleAssignedLocationEditClose = () => {
    setAssignedLocationsEdit(false);
  };

  const handleAssignedLocationEditOpen = () => {
    setAssignedLocationsEdit(true);
  };

  const handleUserListDialogClose = () => {
    setUserListDialogOpen(false);
  };

  const handleUserListDialogOpen = ({ locationId }) => async () => {
    setUserListLoading(true);
    setUserListDialogOpen(true);

    const users = await fetchUsersList(roleId, locationId);

    setUserList(users);
    setUserListLoading(false);
  };

  return (
    <Paper
      elevation={3}
      classes={{
        root: `${classes.paperRoot} ${classes.noHorizontalPadding}`,
      }}
    >
      <div
        className={`${classes.subheadingWrapper} ${classes.horizontalPadding}`}
      >
        <h4 className={classes.subheading}>Assigned Locations</h4>
        {!roleDetail.systemDefault && (
          <EditButton onClick={handleAssignedLocationEditOpen} />
        )}
      </div>

      {locations.length === 0 ? (
        <div className={classes.notAvailable}>
          Not available at any location
        </div>
      ) : (
        <div className={classes.tableWrapper}>
          <table className={classes.locationsTable}>
            <tr>
              <th className={classes.wideColumn}>Facility</th>
              <th className={classes.narrowColumn}>N. of Users</th>
            </tr>

            {locations.map((loc) => (
              <tr>
                <td>{loc.locationName}</td>
                <td
                  className={classes.clickable}
                  onClick={handleUserListDialogOpen(loc)}
                >
                  {loc.noOfUsers}
                </td>
              </tr>
            ))}
          </table>
        </div>
      )}

      <AssignedLocationsEditDialog
        open={isAssignedLocationEdit}
        handleClose={handleAssignedLocationEditClose}
        locations={locations}
        roleId={roleId}
        fetchRoleDetail={fetchRoleDetail}
      />

      <UsersListDialog
        open={isUserListDialogOpen}
        handleClose={handleUserListDialogClose}
        mode="info"
        userList={userList}
        loading={userListLoading}
      />
    </Paper>
  );
};

export default AssignedLocations;
