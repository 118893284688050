export default (theme) => ({
  root :{
   background : theme.palette.secondary.background.white,
  },
  learnerProfileHeading: {
    margin: '10px',
    color: theme.palette.primary.main,
    fontSize: 24,
    padding: '0px 0px 12px 0px',
  },
  learnerProfileSection: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  learnerProfileIcon: {
    fontSize: 90,
    color: theme.palette.primary.main,
    width: 120,
    height: 120,
  },
  learnerProfileInfoSection: {
    width: '414px',
  },
  learnerProfileInfoHeading: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  learnerProfileInfo: {
    display: 'flex',
    marginTop: '10px',
    padding: '0px 0px 0px 40px',
  },
  contactsHeading: {
    margin: '10px',
    color: theme.palette.primary.main,
    fontSize: 24,
    padding: '24px 0px 12px 0px',
  },
});
