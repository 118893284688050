export default theme => ({
  title: {
    fontSize: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary.main,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.pastelBlue
  },
  learnerHeading: {
    fontSize: 20,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  learnerInfo: {
    marginTop: '8px',
    marginLeft: '10px',
    fontWeight: 'bold'
  },
  privilegeText: {
    marginLeft: '10px',
    fontSize: 13,
    display: 'flex',
    alignItems: 'center'
  },
  updatedDate: {
    marginTop: '8px',
    marginLeft: '10px',
    color: theme.palette.primary.pastelBlue
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    margin: '8px'
  }
});
