import React, { useEffect, useState } from "react";
import { MenuItem, TextField, withStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import _ from "lodash";

import styles from "./index.styles";
import { MINIMUM_ENTERTAINMENT_SCHEDULE_TIME, MINIMUM_VOICE_CALL_SCHEDULE_TIME, VOICE_CALLS_LOADER } from "../../../../v2/services/constants";

const timeOptions = [
  { value: "1970-01-01T00:00:00", label: "12:00 AM" },
  { value: "1970-01-01T00:30:00", label: "12:30 AM" },
  { value: "1970-01-01T01:00:00", label: "1:00 AM" },
  { value: "1970-01-01T01:30:00", label: "1:30 AM" },
  { value: "1970-01-01T02:00:00", label: "2:00 AM" },
  { value: "1970-01-01T02:30:00", label: "2:30 AM" },
  { value: "1970-01-01T03:00:00", label: "3:00 AM" },
  { value: "1970-01-01T03:30:00", label: "3:30 AM" },
  { value: "1970-01-01T04:00:00", label: "4:00 AM" },
  { value: "1970-01-01T04:30:00", label: "4:30 AM" },
  { value: "1970-01-01T05:00:00", label: "5:00 AM" },
  { value: "1970-01-01T05:30:00", label: "5:30 AM" },
  { value: "1970-01-01T06:00:00", label: "6:00 AM" },
  { value: "1970-01-01T06:30:00", label: "6:30 AM" },
  { value: "1970-01-01T07:00:00", label: "7:00 AM" },
  { value: "1970-01-01T07:30:00", label: "7:30 AM" },
  { value: "1970-01-01T08:00:00", label: "8:00 AM" },
  { value: "1970-01-01T08:30:00", label: "8:30 AM" },
  { value: "1970-01-01T09:00:00", label: "9:00 AM" },
  { value: "1970-01-01T09:30:00", label: "9:30 AM" },
  { value: "1970-01-01T10:00:00", label: "10:00 AM" },
  { value: "1970-01-01T10:30:00", label: "10:30 AM" },
  { value: "1970-01-01T11:00:00", label: "11:00 AM" },
  { value: "1970-01-01T11:30:00", label: "11:30 AM" },
  { value: "1970-01-01T12:00:00", label: "12:00 PM" },
  { value: "1970-01-01T12:30:00", label: "12:30 PM" },
  { value: "1970-01-01T13:00:00", label: "1:00 PM" },
  { value: "1970-01-01T13:30:00", label: "1:30 PM" },
  { value: "1970-01-01T14:00:00", label: "2:00 PM" },
  { value: "1970-01-01T14:30:00", label: "2:30 PM" },
  { value: "1970-01-01T15:00:00", label: "3:00 PM" },
  { value: "1970-01-01T15:30:00", label: "3:30 PM" },
  { value: "1970-01-01T16:00:00", label: "4:00 PM" },
  { value: "1970-01-01T16:30:00", label: "4:30 PM" },
  { value: "1970-01-01T17:00:00", label: "5:00 PM" },
  { value: "1970-01-01T17:30:00", label: "5:30 PM" },
  { value: "1970-01-01T18:00:00", label: "6:00 PM" },
  { value: "1970-01-01T18:30:00", label: "6:30 PM" },
  { value: "1970-01-01T19:00:00", label: "7:00 PM" },
  { value: "1970-01-01T19:30:00", label: "7:30 PM" },
  { value: "1970-01-01T20:00:00", label: "8:00 PM" },
  { value: "1970-01-01T20:30:00", label: "8:30 PM" },
  { value: "1970-01-01T21:00:00", label: "9:00 PM" },
  { value: "1970-01-01T21:30:00", label: "9:30 PM" },
  { value: "1970-01-01T22:00:00", label: "10:00 PM" },
  { value: "1970-01-01T22:30:00", label: "10:30 PM" },
  { value: "1970-01-01T23:00:00", label: "11:00 PM" },
  { value: "1970-01-01T23:30:00", label: "11:30 PM" },
];

let setErrorMessageHandler = null;

/**
 * @name changeSlotHandler
 * @param {array} scheduleCollection Collection of schedule.
 * @param {number} scheduleIndex Index of schedule that needs to be updated.
 * @param {number} slotIndex Index of slot that needs to be updated.
 * @param {Date} date Value that needs to be set for that slot
 * @param {string} key Key against which date (value) needs to be setted.
 * @param {Function} updateSchedule A callback method which will be called in order to update.
 * @desc Sets value for specific slot.
 * @return {void}
 */
const changeSlotHandler = (
  scheduleCollection,
  scheduleIndex,
  slotIndex,
  date,
  key,
  updateSchedule,
  loaderKey
) => {
  // CREATING DEEP COPY FOR REACT HOOK TO UPDATE FORCEFULLY
  const workingScheduleCollection = _.cloneDeep(scheduleCollection);
  workingScheduleCollection[scheduleIndex]["slots"][slotIndex][key] = moment(
    date
  ).format();
  const startTime = moment(
    workingScheduleCollection[scheduleIndex]["slots"][slotIndex]["startTime"]
  );
  const endTime = moment(
    workingScheduleCollection[scheduleIndex]["slots"][slotIndex]["endTime"]
  );

  // check that the time is valid
  const diff = endTime.diff(startTime, "minutes");

  if (diff < MINIMUM_ENTERTAINMENT_SCHEDULE_TIME && loaderKey !== VOICE_CALLS_LOADER) {
    workingScheduleCollection[scheduleIndex]["slots"][
      slotIndex
    ].isInvalid = true;

    setErrorMessageHandler(
      diff === 0
        ? "Entertainment schedule intervals must be at least 30 minutes in duration."
        : "End time must be at least 30 minutes later than start time."
    );
  } else if(diff < MINIMUM_VOICE_CALL_SCHEDULE_TIME && loaderKey === VOICE_CALLS_LOADER) {
    workingScheduleCollection[scheduleIndex]["slots"][
      slotIndex
    ].isInvalid = true;
    setErrorMessageHandler("Voice calls schedule intervals must be at least 30 minute in duration.");
  } else {
    let timeOverlap = false;
    // check if time overlaps
    workingScheduleCollection[scheduleIndex].slots.forEach((slot, index) => {
      if (
        index !== slotIndex &&
        ((endTime.diff(slot.startTime) >= 0 &&
          endTime.diff(slot.endTime) <= 0) ||
          (startTime.diff(slot.startTime) >= 0 &&
            startTime.diff(slot.endTime) <= 0) ||
          (startTime.diff(slot.startTime) <= 0 &&
            endTime.diff(slot.endTime) >= 0))
      )
        timeOverlap = true;
    });

    workingScheduleCollection[scheduleIndex]["slots"][
      slotIndex
    ].isInvalid = false;
    workingScheduleCollection[scheduleIndex]["slots"][
      slotIndex
    ].isWarning = timeOverlap;
    setErrorMessageHandler(
      timeOverlap
        ? "This time interval overlaps with an existing access period."
        : undefined
    );
  }
  updateSchedule(workingScheduleCollection);
};

const TimeSelect = (props) => {
  const {
    classes,
    label,
    initialTime,
    isInvalid,
    isWarning,
    schedule,
    scheduleIndex,
    setSchedule,
    setErrorMessage,
    slotIndex,
    slotKey,
    loaderKey
  } = props;
  setErrorMessageHandler = setErrorMessage;
  const [time, setTime] = useState(initialTime);
  const [timeLabel, setTimeLabel] = useState(
    moment(initialTime).format("h:mm A")
  );
  // update the displayed times if the schedule changes (i.e. schedule gets copied)
  useEffect(() => {
    setTime(initialTime);
    setTimeLabel(moment(initialTime).format("h:mm A"));
  }, [initialTime]);

  // initially validate time
  useEffect(() => {
    changeSlotHandler(
      schedule,
      scheduleIndex,
      slotIndex,
      time,
      slotKey,
      setSchedule,
      loaderKey
    );
  }, []);

  const handleChange = (event, option) => {
    const newTime =
      option && option.value
        ? moment(option.value)
        : moment("1970-01-01 " + event.target.value, "YYYY-DD-MM h:mm A");
    setTime(newTime.format());
    setTimeLabel(option && option.label ? option.label : event.target.value);

    changeSlotHandler(
      schedule,
      scheduleIndex,
      slotIndex,
      newTime.format(),
      slotKey,
      setSchedule,
      loaderKey
    );
  };

  return (
    <Autocomplete
      className={
        isInvalid || isWarning
          ? [classes.scheduleSelect, classes.error].join(" ")
          : classes.scheduleSelect
      }
      disableClearable
      disablePortal
      defaultValue={time}
      freeSolo
      getOptionLabel={(option, value) =>
        option.label ? option.label : timeLabel
      }
      getOptionSelected={(option, value) => option.value === value}
      onBlur={handleChange}
      onChange={handleChange}
      onKeyUp={handleChange}
      options={timeOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          defaultValue={time}
          error={isInvalid || isWarning}
          label={label}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      renderOption={(option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      )}
      type="time"
      value={time}
    />
  );
};

export default withStyles(styles)(TimeSelect);
