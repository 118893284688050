import Keycloak from 'keycloak-js';

export class KeycloakService {
  static Keycloak = Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: "apds",
    clientId: "apds-horizon",
    onLoad: "login-required",
    promiseType: "native",
    flow: "implicit"
  });

  /**
   * @name keyCloakEvent
   * @param {object} event Event received from key cloak.
   * @desc Key cloak event handling when fired by service.
   * @return {void}
   */
  keyCloakEvent = event => {
    switch (event) {
      case "onAuthSuccess":
        sessionStorage.removeItem('isLogoutInitiated');
        break;
    }
  };
}
