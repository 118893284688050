import React, { forwardRef } from 'reactn';
import Datatable, { MTableToolbar } from 'material-table';
import { Grid, withStyles, Paper } from '@material-ui/core';
import styles from './index.styles';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SortIcon from '@material-ui/icons/Sort';
import moment from 'moment';
import { Snippet, MessageViewDialog } from '../management/communications/containers';
import { UserIcon } from '../UserIcon';
import { withUnleash } from '../../v2/core/unleash';
import { UnleashService } from '../../v2/services';
import Audit from '../management/communications/containers/audit/audit';
import { Loader, Typography } from '@orijinworks/frontend-commons';
import CommunicationInfoModal from '../../v2/shared/components/communication-info-modal/communication-info-modal';
import { useCommunicationHook } from './useCommunicationHook';

const UserCommunication = (props) => {
    const tableRef = React.useRef();
    const { classes, selectedLocation, userActiveLocation } = props;
    const {
        isLoading,
        snippet,
        filters,
        messageViewDialog,
        location,
        timezoneName,
        global,
        pagination,
        selectedTab,
        showEmptyDataSourceMessage,
        messagesSummary,
        isGraphDetailOpen,
        exportPermission,
        filteredCommunicationColumn,
        handleSnippetDialog,
        handleDialog,
        getCommunicationList,
        exportCommunicationList,
        onTabChange,
        MessagesTable,
        onGraphToggleHandler,
        getDateRange,
        TabSelector,
        CustomToolbar
    } = useCommunicationHook({ ...props, tableRef });

    return (
        <>
            <Loader isLoading={isLoading} />
            <Snippet
                {...snippet}
                searchTerm={filters.searchTerm}
                onClose={() => handleSnippetDialog(false)}
            />
            <MessageViewDialog
                {...messageViewDialog}
                location={location}
                timezoneName={timezoneName}
                onClose={() => handleDialog(false)}
            />

            {!props.showAnnouncements && (
                <Grid container item className={classes.datatable}>
                    <CustomToolbar hideTabs={true}/>
                    <Datatable
                        tableRef={tableRef}
                        style={{ width: '100%' }}
                        columns={filteredCommunicationColumn}
                        data={getCommunicationList}
                        options={{
                            showEmptyDataSourceMessage: showEmptyDataSourceMessage,
                            pageSize: pagination.pageSize,
                            selection: false,
                            showSelectAllCheckbox: true,
                            filtering: false,
                            Sort: SortIcon,
                            SortArrow: ArrowDownwardIcon,
                            actionsColumnIndex: 0,
                            search: false,
                            exportCsv: (columns, data) =>
                                exportCommunicationList(columns, data, `MessageExport-${moment().format('MM-DD-YYYY')}`),
                            selectionProps: rowData => ({
                                id: `checkbox-${rowData.formId}`
                            })
                        }}
                        icons={{
                            Search: SearchIcon,
                            ResetSearch: CloseIcon,
                            FirstPage: FirstPageIcon,
                            LastPage: LastPageIcon,
                            NextPage: NavigateNextIcon,
                            PreviousPage: NavigateBeforeIcon,
                            Filter: FilterListIcon,
                            SortArrow: ArrowUpwardIcon,
                            Export: forwardRef((props, ref) => <SaveAltIcon data-cy-name="save-btn" {...props} ref={ref} />),
                            Edit: EditIcon,
                            User: UserIcon
                        }}
                        title={''}
                    />
                </Grid>
            )}
            {props.showAnnouncements && (
                <Audit
                    {...props}
                    exportCommunicationList={exportCommunicationList}
                    handleSnippetDialog={handleSnippetDialog}
                    selectedTab={selectedTab}
                    onTabChange={onTabChange}
                    filteredCommunicationColumn={filteredCommunicationColumn}
                    filters={filters}
                    exportPermission={exportPermission}
                    location={location}
                    timezoneName={timezoneName}
                    MessagesTable={MessagesTable}
                    userId={props.profileUser.userId}
                    range={getDateRange(global.range)}
                    tableRef={tableRef}
                    isTopBarShown={false}
                    TabSelector={<TabSelector />}
                />
            )}

            <CommunicationInfoModal
                messagesSummary={messagesSummary}
                isOpen={isGraphDetailOpen}
                range={global.range}
                locationName={selectedLocation ? selectedLocation.locationName : userActiveLocation.locationName}
                onClose={onGraphToggleHandler}
            />
        </>
    );
}

export default withStyles(styles)(withUnleash(UserCommunication, UnleashService.FLAGS.STAFF_PROFILE_UI_PAGE));
