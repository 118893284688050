import { StyleSheet, Font } from "@react-pdf/renderer";
import Roboto from "./fonts/Roboto/Roboto-Regular.ttf";
import RobotoBold from "./fonts/Roboto/Roboto-Bold.ttf";
import RobotoBlack from "./fonts/Roboto/Roboto-Black.ttf";
import RobotoMedium from "./fonts/Roboto/Roboto-Medium.ttf";

Font.register({
  family: "Roboto",
  src: Roboto
});

Font.register({
  family: "RobotoBold",
  src: RobotoBold
});

Font.register({
  family: "RobotoMedium",
  src: RobotoMedium
});

Font.register({
  family: "RobotoBlack",
  src: RobotoBlack
});

export default StyleSheet.create({
  root: {
    display: "flex",
    padding: 20,
    webkitPrintColorAdjust: "exact",
    colorAdjust: "exact",
    flexDirection: "column",
    fontFamily: "Roboto",
    color: "#0E445A"
  },
  reportHeader: {
    display: "flex",
    justifyContent: "left",
    width: "100%",
    flexDirection: "row"
  },
  brandLogo: {
    width: 60,
    height: 60,
    margin: 0
  },
  titleContainer: {
    marginLeft: 8,
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-end",
    flexGrow: 9
  },
  title: {
    fontSize: 34,
    fontFamily: "RobotoBold",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.25px",
    margin: 0
  },
  subTitle: {
    fontSize: 12,
    fontFamily: "RobotoBold",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    color: "#0E445A",
    margin: 0,
    marginTop: 5,
    marginBottom: 5,
    textTransform: "uppercase",
    letterSpacing: "1.21px"
  },
  detailContainer: {
    display: "flex",
    flexDirection: "row",
    marginRight: 35,
    alignSelf: "flex-end"
  },
  detailLabels: {
    marginRight: 10,
    fontSize: 7,
    fontFamily: "RobotoBlack",
    fontWeight: 900,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "1.05px"
  },
  detailValues: {
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.21px"
  },
  detailTextLabel: {
    margin: 0,
    height: 20,
    paddingTop: 5,
    display: "flex",
    textTransform: "uppercase"
  },
  detailTextValue: {
    margin: 0,
    paddingTop: 2,
    height: 20,
    display: "flex",
    verticalAlign: "center"
  },
  headerSeperator: {
    width: "100%",
    height: 1,
    backgroundColor: "#0F445A",
    marginTop: 8
  },
  reportBody: {
    display: "flex",
    flexDirection: "row",
    minHeight: 716
  },
  reportLeftPane: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
    paddingRight: 10,
    marginBottom: 5
  },
  reportRightPane: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#f0f3f6",
    paddingLeft: 10
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 7,
    fontFamily: "RobotoBlack",
    fontWeight: 900,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    textTransform: "uppercase",
    letterSpacing: "1.05px",
    paddingRight: 10,
    paddingLeft: 10,
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 5
  },
  tableSubHeader: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 12,
    fontFamily: "RobotoMedium",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.7px",
    color: "#3B928C",
    backgroundColor: "#E2E9EF",
    paddingRight: 10,
    paddingLeft: 10,
    flexDirection: "row",
    alignItems: "center",
    height: 21,
    borderRadius: 5
  },
  courseItem: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 5
  },
  courseRow: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 5
  },
  courseField: {
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    color: "#617C88",
    lineHeight: 1,
    letterSpacing: "0.21px"
  },
  courseSubFieldContainer: {
    display: "flex",
    width: 100,
    flexDirection: "row"
  },
  courseSubField: {
    fontSize: 9,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.16px",
    margin: 0,
    color: "#617C88"
  },
  textAlignRight: {
    textAlign: "right"
  },
  textAlignCenter: {
    textAlign: "center"
  },
  width70: {
    width: 70
  },
  width100: {
    width: 100
  },
  width150: {
    width: 150
  },
  width200: {
    width: 200
  },
  tableMidColumnContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  noMargin: {
    margin: 0
  },
  categoryBox: {
    backgroundColor: "#ffffff",
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 10,
    display: "flex",
    flexDirection: "column"
  },
  categoryItem: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 5,
    marginBottom: 5,
    flexDirection: "row"
  },
  categoryIconFieldContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row"
  },
  categoryField: {
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.21px",
    color: "#617C88"
  },
  categorySeperator: {
    width: "100%",
    height: 1,
    backgroundColor: "#e0e0e0",
    margin: 0
  },
  paddingLeft2: {
    paddingLeft: 2
  },
  marginLeft10: {
    marginLeft: 10
  },
  footer: {
    position: "absolute",
    bottom: 10,
    width: 575,
    paddingLeft: 20
  },
  footerSeperator: {
    width: "100%",
    height: 2,
    backgroundColor: "#0F445A"
  },
  footerNote: {
    fontSize: 10,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "0.14px",
    margin: 0
  },
  upperCase: {
    textTransform: "uppercase"
  },
  pageNumbers: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "right",
    fontFamily: "Roboto",
    fontSize: 9,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "0.18px",
    color: "#0E445A"
  },
  seperatorSubSpace: {
    height: 10,
    width: "100%"
  }
});
