import { withStyles } from '@material-ui/styles';
import React from 'react';
import styles from './index.style';

const customCheckbox = (props) => (
    <div
        className={props.classes.customCheckbox}
    >
        <input type="checkbox" 
            {...props}
        />
        <div className="toggle-container">
            <span className="off" >Off</span>
            <span className="on">On</span>
        </div>
    </div>
);

const CustomCheckbox = withStyles(styles)(customCheckbox);

export {
    CustomCheckbox
}; 