import React from "react";

const DashboardIcon = props => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <g id="learner_dashboard_med" data-name="learner  dashboard_med" style={{mixBlendMode: "normal", isolation: "isolate"}}>
        <rect id="Rectangle_5" data-name="Rectangle 5" width="11" height="11" rx="3" fill={fill} />
        <rect id="Rectangle_6" data-name="Rectangle 6" width="11" height="11" rx="3" transform="translate(13)" fill={fill} stroke="none"/>
        <rect id="Rectangle_7" data-name="Rectangle 7" width="11" height="11" rx="3" transform="translate(13 13)" fill={fill} stroke="none"/>
        <rect id="Rectangle_8" data-name="Rectangle 8" width="11" height="11" rx="3" transform="translate(0 13)" fill={fill} stroke="none"/>
      </g>
    </svg>
  );
};

DashboardIcon.defaultProps = {
  size: 24,
  fill: "inherit"
}

export default DashboardIcon;
