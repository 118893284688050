import React from 'react';
import { useResumeHook } from './useResumeHook';
import Datatable from 'material-table';
import { Box } from '@material-ui/core';
import {Loader} from '@orijinworks/frontend-commons';
import { withRouter } from 'react-router-dom';
import { withUnleash } from '../../../core/unleash';
import { UnleashService } from '../../../services';

const Resume = props => {
  const { OPTIONS, COLUMNS, isLoading, getResumes } = useResumeHook(props);
  return (
    <Box width="100%" padding="40px">
        <Loader isLoading={isLoading}/>
        <Datatable
        id="resume-table"
        data={getResumes} columns={COLUMNS} options={OPTIONS} />
    </Box>
  );
};

export default withRouter(withUnleash(Resume, UnleashService.FLAGS.EDIT_RESUME));
