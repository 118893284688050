import React from 'reactn';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Box, DialogActions, Button, withStyles, Typography, IconButton, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import FolderIcon from '@material-ui/icons/Folder';
import { unAssignPathToUser, assignPathToUser } from '../../util/APIUtils';
import { SUPPORT_EMAIL } from '../../constants';
const styles = (theme) => ({
    errorBox: {
        backgroundColor: '#e3b2b2',
        borderRadius: 4,
        padding: '5px 15px',
    },
    errorInfoSmallText: {
        color: '#a2272d',
        ...theme.typography.t12,
        letterSpacing: '0.4px',
    },
    errorMainText: {
        color: '#a2272d',
        ...theme.typography.t15,
        letterSpacing: '0.5px',
        marginTop: 5,
        textTransform: "capitalize"
    },
    removePathwayHeadWrapper: {
        backgroundColor: theme.palette.primary.main,
        paddingLeft: 10
    },
    removePathwayHeadWrapperLeft: {
        color: theme.palette.primary.white,
        width: '100%',

    },
    removePathCloseBtn: {
        color: theme.palette.primary.white
    },
    removePathHeading: {
        color: theme.palette.primary.white
    },
    supportText: {
        ...theme.typography.t12,
        color: theme.palette.primary.battleshipGrey,
        letterSpacing: '0.4px'
    },
    courseWrapper: {
        color: '#3d3d3d'
    },
    courseName: {
        ...theme.typography.t18,
        ...theme.typography.bold,
        color: '#3d3d3d',
        textTransform: 'uppercase'
    },
    folderIcon: {
        ...theme.typography.t20,
        color: '#3d3d3d'
    },
    successBox: {
        backgroundColor: 'rgba(125, 209, 149, 0.59)',
        borderRadius: 4,
        padding: '5px 15px',
    },
    successInfoSmallText: {
        color: 'rgba(45, 99, 60, 0.7)',
        ...theme.typography.t12,
        letterSpacing: '0.4px',
    },
    successMainText: {
        color: '#2d633c',
        ...theme.typography.t15,
        letterSpacing: '0.5px',
        marginTop: 5,
        textTransform: "capitalize"
    },
    removePathwayDialogContent: {
    },
    doneBtn: {
        minWidth: 116
    }
})

const responseBox = (props) => {

    const { classes } = props;
    return (
        <Dialog
            PaperProps={{
                style: {
                    width: 743.5,
                    maxWidth: '100%'
                }
            }}
            open={props.isOpen}
            onClose={() => {
                props.onClose();
            }}
        >
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.removePathwayHeadWrapper}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.removePathwayHeadWrapperLeft}>
                        <Typography
                        className={classes.removePathHeading}
                        variant="h3"
                    >
                        {props.heading}
                    </Typography>
                    <IconButton 
                    id="response-box-close-btn"
                    onClick={() => props.onClose()}
                    className={classes.removePathCloseBtn}>
                        <CloseIcon />
                    </IconButton>
                </Box>

            </Grid>
            <DialogContent className={classes.removePathwayDialogContent}>
                <Box width="100%"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                >

                    <Box width="100%" textAlign="right">
                        <Typography className={classes.supportText}>
                            For support, contact APDS at <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> with the listed error code(s).
                        </Typography>
                    </Box>
                    {props.groupedView &&
                        (props.data || []).map(
                            (group) => (
                                <Box >
                                    <Box
                                        marginTop={5}
                                        display="flex"
                                        alignItems="center"
                                        className={classes.courseWrapper}>
                                        <FolderIcon className={classes.folderIcon} />
                                        &nbsp;
                                    <Typography className={classes.courseName} marginLeft="10px">
                                            {group.heading}
                                        </Typography>
                                    </Box>
                                    {group.children.map(
                                        (groupItem) => (
                                            <Box
                                                marginBottom="10px"
                                                className={groupItem.statusCode === 0 ? classes.successBox : classes.errorBox}
                                            >
                                                <Typography className={groupItem.statusCode === 0 ? classes.successMainText : classes.errorMainText}>
                                                    {groupItem.text}
                                                </Typography>
                                            </Box>
                                        )
                                    )}

                                </Box>
                            )
                        )
                    }
                    {!props.groupedView && (
                        (props.data || []).map(
                            (_response) => (
                                <Box >
                                    <Box
                                        marginBottom="10px"
                                        className={_response.statusCode === 0 ? classes.successBox : classes.errorBox}
                                    >
                                       <Typography className={_response.statusCode === 0 ? classes.successMainText : classes.errorMainText}>
                                            {_response.text}
                                        </Typography>
                                    </Box>
                                </Box>
                            )
                        )

                    )}

                    <Box
                        width="100%"
                        display="flex"
                        marginBottom="10px"
                        alignItems="flex-end"
                        justifyContent="center"
                        flex={1}
                    >
                        <Button
                            id="response-box-done-btn"
                            onClick={() => {
                                if (props.onDone) {
                                    props.onDone();
                                } else {
                                    props.onClose();
                                }
                            }}
                            variant="contained" color="primary"
                            disableElevation
                            className={classes.doneBtn}>
                            Done
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};



export default withStyles(styles)(responseBox);