import React from "react";
import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import moment from "moment";
import styles from "./report.styles";
import { toFixed } from "./../utils";
import { CONTENT_AREA, CONTENT_AREAS_ENUM } from "../../../../../constants";

export default function ProgressReport(props) {
  if (!props.data) return null;
  const {
    docId,
    issueDate,
    totalHours,
    categoriesHours,
    transcriptListDtos
  } = props.data;

  const renderHeader = () => (
    <View fixed>
      <View style={styles.reportHeader}>
        <Image
          src="/assets/logo/apds-logo-1.png"
          style={styles.brandLogo}
          allowDangerousPaths={true}
          alt="apds-logo.png"
        />
        <View style={styles.titleContainer}>
          <Text style={styles.title}>
            {props.userDetail
              ? props.userDetail.firstName || props.userDetail.lastName
                ? `${props.userDetail.firstName} ${props.userDetail.lastName}`
                : props.userDetail && props.userDetail.userName
              : ""}
          </Text>
          <Text style={styles.subTitle}>Progress Report</Text>
        </View>
        <View style={styles.detailContainer}>
          <View style={styles.detailLabels}>
            <Text style={styles.detailTextLabel}>Issued</Text>
            <Text style={styles.detailTextLabel}>Doc Id</Text>
            <Text style={styles.detailTextLabel}>Total Hours</Text>
          </View>
          <View style={styles.detailValues}>
            <Text style={styles.detailTextValue}>
              {issueDate ? moment(issueDate).format("LL") : ""}
            </Text>
            <Text style={styles.detailTextValue}>{docId}</Text>
            <Text style={styles.detailTextValue}>{toFixed(totalHours, 1)}</Text>
          </View>
        </View>
      </View>
      <View style={styles.headerSeperator} />
    </View>
  );

  const renderCourseTableHeader = () => (
    <View style={styles.tableHeader}>
      <Text style={[styles.width200, styles.upperCase]}>Path/Course</Text>
      <Text style={[styles.width200, styles.upperCase]}>Last Accessed</Text>
      <Text style={[styles.width70, styles.textAlignRight, styles.upperCase]}>
        Hours*
      </Text>
    </View>
  );

  const renderCourseTableSubHeader = obj => (
    <View style={styles.tableSubHeader}>
      <Text>{obj.titleName}</Text>
      <Text>{obj.hours.toFixed(1)}</Text>
    </View>
  );

  const renderCourseTable = obj => (
    <View>
      {obj.children.map((item, index) => (
        <View key={item.id} wrap={false}>
          {index === 0 ? renderCourseTableSubHeader(obj) : null}
          <View style={styles.courseItem}>
            <View style={styles.courseRow}>
              <Text
                style={[styles.noMargin, styles.courseField, styles.width200]}
              >
                {item.titleName}
              </Text>
              <Text
                style={[styles.noMargin, styles.courseField, styles.width200]}
              >
                {item.completed ? "Complete" : "In Progress"}
              </Text>
              <Text
                style={[
                  styles.noMargin,
                  styles.courseField,
                  styles.textAlignRight,
                  styles.width70
                ]}
              >
                {item.hours.toFixed(1)}
              </Text>
            </View>
            <View style={styles.courseRow}>
              <View style={[styles.courseSubFieldContainer, styles.width200]}>
                {CONTENT_AREA[item.contentAreaId].icons.pdf(10)}
                <Text style={[styles.paddingLeft2, styles.courseSubField]}>
                  {CONTENT_AREA[item.contentAreaId].name}
                </Text>
              </View>
              <View style={[styles.width200]}>
                <Text style={styles.courseSubField}>{item.lastDate}</Text>
              </View>
              <View style={[styles.textAlignRight, styles.width70]}>
                {item.completed ? (
                  <Text style={styles.courseSubField}>{item.score}</Text>
                ) : null}
              </View>
            </View>
          </View>
        </View>
      ))}
      <View style={styles.seperatorSubSpace} />
    </View>
  );

  const renderEnrichmentHeader = obj => (
    <View>
      <View style={styles.tableHeader}>
        <Text style={[styles.width200, styles.upperCase]}>Item</Text>
        <View style={[styles.width200, styles.tableMidColumnContainer]}>
          <Text style={[styles.width100, styles.upperCase]}>Last Accessed</Text>
          <Text
            style={[styles.width100, styles.textAlignCenter, styles.upperCase]}
          >
            Source
          </Text>
        </View>
        <Text style={[styles.width70, styles.textAlignRight, styles.upperCase]}>
          Hours*
        </Text>
      </View>
      <View style={styles.tableSubHeader}>
        <Text>{obj.titleName}</Text>
        <Text>{obj.hours.toFixed(1)}</Text>
      </View>
    </View>
  );

  const renderEnrichment = obj => (
    <View>
      {obj.children.map((item, index) => (
        <View key={item.id} wrap={false}>
          {index === 0 ? renderEnrichmentHeader(obj) : null}
          <View style={styles.courseItem}>
            <View style={styles.courseRow}>
              <Text style={[styles.courseField, styles.width200]}>
                {item.titleName}
              </Text>
              <View
                style={[
                  styles.courseField,
                  styles.width200,
                  styles.tableMidColumnContainer
                ]}
              >
                <Text style={[styles.noMargin, styles.width100]}>
                  {item.lastAccessed
                    ? moment(item.lastAccessed).format("LL")
                    : ""}
                </Text>
                <Text
                  style={[
                    styles.courseField,
                    styles.textAlignCenter,
                    styles.width100
                  ]}
                >
                  {item.serviceName}
                </Text>
              </View>
              <Text
                style={[
                  styles.courseField,
                  styles.textAlignRight,
                  styles.width70
                ]}
              >
                {item.hours.toFixed(1)}
              </Text>
            </View>
          </View>
        </View>
      ))}
    </View>
  );

  const renderCategory = id => (
    <View style={styles.categoryItem}>
      <View style={styles.categoryIconFieldContainer}>
        {CONTENT_AREA[id].icons.pdf(20)}
        <Text
          style={[styles.noMargin, styles.categoryField, styles.marginLeft10]}
        >
          {CONTENT_AREA[id].name}
        </Text>
      </View>
      <Text style={[styles.noMargin, styles.categoryField]}>
        {categoriesHours[id].toFixed(1)}
      </Text>
    </View>
  );

  const renderCategories = () => (
    <View>
      <View style={styles.tableHeader}>
        <Text style={styles.upperCase}> Totals Per Category</Text>
        <Text style={styles.upperCase}> Hours</Text>
      </View>
      <View style={styles.categoryBox}>
        {renderCategory(CONTENT_AREAS_ENUM.Education)}
        <View style={styles.categorySeperator} />
        {renderCategory(CONTENT_AREAS_ENUM.Rehab)}
        <View style={styles.categorySeperator} />
        {renderCategory(CONTENT_AREAS_ENUM.Workforce)}
        <View style={styles.categorySeperator} />
        {renderCategory(CONTENT_AREAS_ENUM.ReEntry)}
        <View style={styles.categorySeperator} />
        {renderCategory(CONTENT_AREAS_ENUM.Enrichment)}
      </View>
    </View>
  );

  const renderBody = () => (
    <View style={styles.reportBody}>
      <View style={styles.reportLeftPane}>
        {transcriptListDtos.map((item, index) => {
          return (
            <View key={item.id}>
              {index === 0 && item.titleName !== "Enrichment"
                ? renderCourseTableHeader()
                : null}
              {item.titleName === "Enrichment"
                ? renderEnrichment(item)
                : renderCourseTable(item)}
            </View>
          );
        })}
      </View>
      <View style={styles.reportRightPane}>{renderCategories()}</View>
    </View>
  );

  const renderFooter = () => (
    <View style={styles.footer} fixed>
      <View style={styles.footerSeperator} />
      <Text style={styles.footerNote}>
        *Note: Hours may overlap. Not all activities are counted in the hours
        total.
      </Text>
      <Text
        style={styles.pageNumbers}
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} of ${totalPages}`
        }
        fixed
      />
    </View>
  );

  return (
    <Document
      author="APDS"
      title={`${
        props.userDetail
          ? props.userDetail.firstName || props.userDetail.lastName
            ? `${props.userDetail.firstName} ${props.userDetail.lastName}`
            : props.userDetail && props.userDetail.userName
          : ""
      }- Progress Report`}
    >
      <Page size="A4" style={styles.root}>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </Page>
    </Document>
  );
}
