import React from 'react';
import { Box, Card, withStyles } from '../../../../v2/shared/components/MaterialUIComponents';
import Datatable from 'material-table';
import styles from './index.style';
import { Typography } from '@orijinworks/frontend-commons';
import { useLocationHistoryHook } from './useLocationHistoryHook';

const LocationHistory = ({ classes,userLocations }) => {
  const { getLocationHistoryColumns} = useLocationHistoryHook({classes});

  return (
    <Card classes={{ root: classes.root }}>
      <Box className={classes.header}>
        <Box id="module-title">
          <Typography variant='h2' className={classes.heading}>Learner Location History</Typography>
        </Box>
      </Box>
      <Datatable
        data={userLocations || []}
        columns={getLocationHistoryColumns()}
        style={{
          boxShadow: 'none'
        }}
        options={{
          search: false,
          sorting: false,
          filtering: false,
          toolbar: false,
          pageSize: 20,
          headerStyle: {
            fontSize: 16,
            fontWeight: 700
          }
        }}
      />
    </Card>
  );
};

export default withStyles(styles)(LocationHistory);
