import { useState } from 'react';
import { bulkResetDevicePairing } from '../../../../../util/APIUtils';
import { SUCCESS, USER_PAIR_DEVICES_ERROR } from '../../../../../v2/services/constants';

export const useBulkResetDeviceHook = ({ locationId, modalRef }) => {
  const [isResetLoading, setResetLoading] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const [error, setError] = useState('');
  const resetUserDevicePairing = async () => {
    try {
      setResetLoading(true);
      const { status } = await bulkResetDevicePairing(locationId);
      if (status === SUCCESS) {
        toggleConfirmationModal();
        setSnackBar(!snackBar);
      } else {
        setError(USER_PAIR_DEVICES_ERROR);
      }
    } catch (e) {
      setError(USER_PAIR_DEVICES_ERROR);
    } finally {
      setResetLoading(false);
    }
  };

  const toggleConfirmationModal = () => {
    modalRef.current && modalRef.current.toggleModal();
  };

  return {
    isResetLoading,
    resetUserDevicePairing,
    toggleConfirmationModal,
    setSnackBar,
    setError,
    snackBar,
    error,
  };
};
