import { getEnv } from '../utilty';

class UnleashService {
  static CONFIG = {
    url: getEnv('REACT_APP_UNLEASH_URL'),
    clientKey: getEnv('REACT_APP_UNLEASH_CLIENT_KEY'),
    refreshInterval: getEnv('REACT_APP_UNLEASH_REFRESH_INTERVAL'),
    appName: getEnv('REACT_APP_UNLEASH_APP_NAME'),
    environment: getEnv('REACT_APP_UNLEASH_ENVIRONMENT'),
  };

  static FLAGS = {
    TEST_RELEASE: 'TestRelease',
    AUTHORIZATION: 'authorization',
    RESPONSE_NEEDED_WARNING: 'responseNeededWarning',
    ANNOUNCEMENT_AUDIT: 'announcementAudit',
    INSIGHT_TAB_IN_LEARNER_PROFILE: 'insightsTabInLearnerProfile',
    NEW_CONVERSATION_REFACTORING: 'newConversationRefactoring',
    SISENSE_DASHBOARD_SUBTAB: 'sisenseDashboardSubtab',
    VOICE_CALL: 'manageVoiceCallFeature',
    CREATE_SUPER_ADMIN: 'createSuperAdmin',
    ENFORCE_DOCID_CHARACTER_RULES: 'enforceDocIdCharacterRules',
    LOCATION_PERSIST_FLOW: 'locationPersistenceFlow',
    V2_OMNI_SEARCH: 'v2OmniSearch',
    STAFF_PROFILE_UI_PAGE: 'staffProfileUiToPage',
    LEARNER_MILESTONE_MAPPER: 'learnerMilestoneMapper',
    SHOW_SNACK_BAR_ON_MESSAGE_SENT: 'showSnackBarOnMessageSent',
    USERS_MODULE_BTN_ALIGNMENT_FIX: 'usersModuleBtnAlignmentFix',
    COMMUNICATION_TAB_FOR_ADMIN_ONLY: 'communicationTabForAdminsOnly',
    SHOW_CERTIFICATE_IN_ADMIN_PORTAL: 'showCertificatesInAdminPortal',
    FORM_ACTION_FOR_SUPER_ADMIN_ONLY: 'formActionForSuperAdminOnly',
    VIEW_PROFILE_FROM_MESSAGES: 'viewProfileFromMessages',
    HIDE_HOUSING_UNITS_VOICE_CALL_MODULE: 'HideHousingUnitsVoiceCallModule',
    RESUME_BUILDER_TOGGLE: 'resumeBuilderToggle',
    CAREER_COMPASS_ACCESS_TOGGLE: 'careerCompassAccessToggle',
    LEARNER_TOGGLE_RESUME_BUILDER: 'learnerToggleResumeBuilder',
    RESUME_MODULE: 'resumeModule',
    EDIT_RESUME: 'editLearnerResume',
    CLEAR_DEVICE_PAIRING_TOGGLE: 'clearDevicePairingOnLocationChangeToggle',
    HU_DATA_CACHE_FIX: 'locationPickerHuDataFix',
    ARCHIVE_UNARCHIVE_TOGGLE: 'archiveUnarchiveContactsToggle',
    RESUME_DOWNLOAD_DOCX_BTN: 'resumeDownloadDocxButton',
    RESTORE_FA_ACCESS: 'restoreFAToSeeActions',
    RESTRUCTURE_COMMUNICATION_TAB: 'restructureCommunicationTab',
    BYPASS_HOME_PAGE: 'byPassHomePage',
    CALL_HISTORY_LIVE_CALLS_DESIGN_V2: 'callHistoryLiveCallsDesignV2',
    RECLASSIFY_FEATURED_CONTENT: 'reclassifyFeaturedContent',
    RECLASSIFY_MILESTONE_MAPPER: 'reclassifyMilestoneMapper',
    BULK_RESET_PAIRED_DEVICES: 'bulkResetPairedDevices',
    UPDATE_LEARNER_PAIRED_DEVICE_RESET: 'updateLearnerPairedDeviceReset',
    TEACHER_LEARNER_SETTING: 'teacherLearnerSetting',
    FNF_FOR_ADMIN_ONLY: 'fnfForAdminsOnly',
    DELETE_FRIENDS_AND_FAMILY_CONTACT: 'deleteFriendsAndFamilyContact',
    VOICE_GROUP_SCHEDULE: 'voiceGroupSchedule',
    RELAX_SECURITY_TOGGLE: 'relaxSecurityToggle',
    DATE_FILTER_SNACKBAR: 'dateFilterSnackbar',
    COMPLETION_REPORT: 'completionReport',
    EXTEND_TAB_RESTRUCTURING_TO_PROFILE_PAGES: 'extendTabRestructuringToProfilesPage',
    LEARNERS_LOCATION_HISTORY: 'learnersLocationHistory',
    OPTIMIZE_USER_LISTING_ENDPOINT: 'optimizeUserListingEndpoint',
    DISABLE_DOUBLE_CLICK: 'disableDoubleClick',
    SENDER_ID_OVER_SENDER_NAME: 'useSenderIdOverSenderName_CC-4255',
    CONDENSE_SUPER_ADMIN_INTO_SINGLE_MODULE: 'condenseSuperAdminIntoSingleModule',
    OPTIMIZE_SOCKET: 'optimizedSocket',
    LAUNCH_SISENCE_FROM_SIDENAV: 'launchSisenceFromSideNav',
    KEYWORD_MATCH_CONFIG: 'keywordMatchConfig',
    MOVE_FRIENDS_AND_FAMILY_INTO_SEPARATE_MODULE: 'moveFriendsAndFamilyIntoSeparateModule',
    ADMIN_BIOMETRIC_AUTH_RESET_FOR_NOT_REGISTERED: 'adminBiometricAuthResetForNotRegistered',
    COMMUNICATION_BREAK_FIX: 'communicationBreakFix',
    USE_OKTA_AUTH0: 'useAuth0',
    MANAGE_RELATIONSHIP_LIST_BY_USERNAME: 'manageRelationshipListByUsername',
    V2_RELATIONSHIP: 'v2Relationship',
    UPDATE_AVAILABILITY_TYPE: 'updateAvailabilityType',
    COMMUNICATION_SECURITY_SETTINGS: 'communicationSecuritySettings',
  };
}

export default UnleashService;
