import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Dialog,
  TextField,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import styles from './edit-contact-modal.component.style.js';
import useEditContactModalComponentHook from './useEditContactModalComponentHook.js';
const EditContactModal = ({
  classes,
  editContact,
  setEditContact,
  setIsEditSucess,
  setIsEditError,
  isEditContactModalOpen,
  setIsEditContactModalOpen,
  handleEditContactModalClose,
}) => {
  const { error, isSubmitted, handleChange, updateContact } = useEditContactModalComponentHook({
    editContact,
    setEditContact,
    setIsEditContactModalOpen,
    setIsEditSucess,
    setIsEditError,
  });
  return (
    <Dialog id="dialog" open={isEditContactModalOpen} onClose={handleEditContactModalClose} fullWidth>
      <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>
        <Typography variant="h3">Edit Contact</Typography>
        <IconButton onClick={handleEditContactModalClose}>
          <CloseIcon strokeWidth={2} fill="#ffffff" />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: '0 24px' }}>
        <Box className={classes.learnerHeading}>
          <Typography id="learner-info-title" className={classes.learnerInfo}>
            Resident Information
          </Typography>
          <Typography id="updated-date" className={classes.updatedDate}>
            Last Updated {moment(editContact.modifiedDate).format('MM/DD/YYYY')}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="learnerUserName"
              name="learnerUserName"
              label="Resident"
              variant="outlined"
              margin="normal"
              size="small"
              className={classes.learnerField}
              value={editContact.learnerUserName}
              disabled
            />
          </Grid>
          <Grid item xs={12} spacing={2}>
            <Typography id="contact-info-title" className={classes.learnerInfo}>
              Contact Information
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="firstName"
              name="firstName"
              label="First Name"
              variant="outlined"
              size="small"
              margin="normal"
              value={editContact.firstName}
              error={isSubmitted && error.hasOwnProperty('firstName')}
              helperText={isSubmitted ? error.firstName : ''}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="lastName"
              label="Last Name"
              name="lastName"
              variant="outlined"
              size="small"
              margin="normal"
              value={editContact.lastName}
              onChange={handleChange}
              error={isSubmitted && error.hasOwnProperty('lastName')}
              helperText={isSubmitted ? error.lastName : ''}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="relationshipType"
              name="relationshipType"
              label="Relationship Type"
              variant="outlined"
              margin="normal"
              size="small"
              select
              value={editContact.relationshipType}
              onChange={handleChange}
              required
              fullWidth
            >
              {VOICE_CALL.getRelationshipTypes().map((relationshipType) => (
                <MenuItem key={relationshipType.id} value={relationshipType.backendValue}>
                  {relationshipType.value}
                  {VOICE_CALL.getPrivilegeRelationShipTypes().includes(relationshipType.value) && ' (Privileged)'}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              variant="outlined"
              size="small"
              margin="normal"
              value={editContact.phoneNumber}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Typography id="privilegeText" className={classes.privilegeText}>
              <ErrorIcon />
              Privileged contacts cannot be monitored by admins.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="status"
              name="status"
              label="Status"
              variant="outlined"
              size="small"
              margin="normal"
              value={editContact.status}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="preferredLanguage"
              name="preferredLanguage"
              label="Preferred Language"
              variant="outlined"
              size="small"
              margin="normal"
              select
              value={editContact.preferredLanguage}
              onChange={handleChange}
              fullWidth
            >
              {VOICE_CALL.getLanguages().map((language) => (
                <MenuItem key={language.value} value={language.value} style={{ textTransform: 'capitalize' }}>
                  {language.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button
              id="save-button"
              className={classes.saveButton}
              variant="contained"
              color="primary"
              onClick={updateContact}
            >
              Save Changes
            </Button>
            <Button id="close-button" color="primary" onClick={handleEditContactModalClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default withStyles(styles)(EditContactModal);
