import React from 'react';

class IncentiveCount extends React.Component {
    constructor() {
        super();
        this.state = {  
          percent: 25, 
          data: this.getData(0),
          count: 0,
          currentCount:0
        };
      }
    
      componentDidMount() {

      }
    
      componentWillUnmount() {
        window.clearInterval(this.setStateInterval);
      }
    
      getData(percent) {
        return [{ x: 1, y: percent }, { x: 2, y: 100-percent }];
      }
    
      render() {
        return (
          <div>
          
          </div>
        );
      }
  }
  
  export default IncentiveCount