import React, { useGlobal } from 'reactn';
import jwt_decode from "jwt-decode";
import { getJanusToken } from '../../constants'


export const isPermitted = (cbacConfigFlag, permissions, permissionName, permissionIds) => {
    const token = getJanusToken();

    if (cbacConfigFlag) {
        if (!permissionIds) {
            if (!token) {
                return false;
            }
            const { permissions: tokenPermissionIds } = jwt_decode(token);
            permissionIds = tokenPermissionIds;
        }
        if (permissionIds.length === 0) {
            return false;
        }
        const permission = permissions.find(
            permission => permission.permissionName === permissionName
        );

        if (!permission || !permissionIds.includes(permission.permissionId)) {
            return false;
        }
    }

    return true;
}

export const PermissionControl = React.memo((props) => {

    const {
        forceRestriction,
    } = props;
    const token = getJanusToken();
    const [ global, setGlobal ] = useGlobal();
    const {
        cbacConfigFlag,
        permissions
    } = global;
    const fallbackComponent = props.fallbackComponent || null;
    if (forceRestriction) {
        return fallbackComponent;
    }

    if (cbacConfigFlag) {
        let permissionIds = props.permissionIds;
        if (!permissionIds) {
            if (!token) {
                return fallbackComponent;
            }
            const { permissions: tokenPermissionIds } = jwt_decode(token);
            permissionIds = tokenPermissionIds;
        }
        if (props.permissionNames) {
            for (let permissionName of props.permissionNames) {
                const permission = permissions.find(
                    permission => permission.permissionName === permissionName
                );
                if (permission && permissionIds.includes(permission.permissionId)) {
                    return props.children;
                }
            }
        }

        const permission = permissions.find(
            permission => permission.permissionName === props.permissionName
        );

        if (!permission || !permissionIds.includes(permission.permissionId)) {
            return fallbackComponent;
        }
    }

    return props.children;
});


export default PermissionControl;