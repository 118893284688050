import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, CardContent, Grid } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';

const useStyle = makeStyles(theme => ({
  cardStyle: {
    padding: '1rem',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    borderRadius: '24px',
    display: 'flex',
    paddingRight: 20,
    paddingLeft: '40px',
    border: `1px solid var(--grey-40)`,
    height: '100%'
  },

  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headingSection: {
    flex: 2,
    textAlign: 'left',
    minHeight: 122,
    marginTop: 5,
    color: theme.palette.text.navy
    // wordBreak: 'break-word'
  },
  heading: {
    color: theme.palette.text.navy
  },
  iconStyle: {
    width: '122px',
    height: '122px',
    [theme.breakpoints.down(800)]: {
      width: '110px',
      height: '110px'
    },
    [theme.breakpoints.down(700)]: {
      width: '100px',
      height: '100px'
    },
    [theme.breakpoints.down(650)]: {
      width: '90px',
      height: '90px'
    },

    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    border: `1px solid var(--grey-40)`,
    borderRadius: '24px',
    background: theme.palette.colors.paleBlue
  },
  paragraphStyle: {
    color: theme.palette.text.navy,
    marginTop: '10px !important'
  }
}));

export const MainMenuTile = props => {
  const classes = useStyle();
  const { cardUID, header, description, icon } = props;
  return (
    <Box data-cy-name={cardUID} className={classes.cardStyle}>
      <Box className={classes.cardContent}>
        <Box className={classes.iconStyle}>{icon}</Box>
        <CardContent className={classes.headingSection}>
          <Typography variant="h3" className={classes.heading} gutterBottom>
            {header}
          </Typography>
          <Typography variant="body1" className={classes.paragraphStyle}>
            {description}
          </Typography>
        </CardContent>
      </Box>
    </Box>
  );
};
