export default (theme) => ({
    capitalize: {
        textTransform: 'capitalize'
    },
    success: {
        color: theme.status.success
    },
    danger: {
        color: theme.status.danger
    },
    warning: {
        color: theme.status.warning
    },
    customEmailWidth: {
        width: 100,
    },
    errorIcon:{
        margin: "4px 8px 0px 0px",
        color: theme.palette.secondary.red && theme.palette.secondary.red.main
      },
      nameText: {
        ...theme.typography.bold,
      },
      errorWrapper: {
        top: '-60px',
        position: "absolute",
        backgroundColor: theme.palette.secondary.red && theme.palette.secondary.red.pale,
        padding: "5px 10px",
        borderRadius: 10,
        color: theme.palette.secondary.red && theme.palette.secondary.red.main,
        width: '95%',
        right: 2,
        border: `1px solid ${theme.palette.secondary.red && theme.palette.secondary.red.main}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      closeIcon: {
        fontSize: 30,
        fontWeight: 700,
        color: "#154157"
      },
      contactWrapper: {
        justifyContent:'space-evenly',
        marginLeft:'50px',
        marginRight:'50px',
      },
      contactHeader: {
        fontWeight: '500',
        color: theme.palette.colors.ceruleanMain,
        textTransform: 'uppercase',
        fontSize: '14px',
      },
      arrowIconColor: {
        color: theme.palette.colors.ceruleanMain,
      },
      contactText: {
        fontWeight: '700',
        color: theme.palette.colors.grey110,
        fontSize: '14px',
      },
      namesWrapper: {
        justifyContent: 'space-around',
        paddingLeft:'30px',
        paddingRight:'30px',
      }
});
