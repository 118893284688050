import React from 'react';
import { useCertificatesHook } from './useCertificatesHook';
import Datatable from 'material-table';
import { Box } from '@material-ui/core';
import { Loader } from '@orijinworks/frontend-commons';

const Certificates = props => {
  const { OPTIONS, COLUMNS, isLoading, getCertificates, handleDownload, setCertificates,selectedCertificates } = useCertificatesHook(props);

  return (
    <Box width="100%" padding="40px">
        <Loader isLoading={isLoading} />
        <Datatable
          id="certificates-table"
          data={getCertificates} columns={COLUMNS} options={{...OPTIONS , toolbar: !!selectedCertificates.length}} 
          onSelectionChange={rows => setCertificates(rows) }
          actions={[{
              tooltip: 'Download Certificates',
              icon: 'download',
              ariaLabel: "Download Certificates",
              onClick: (evt, data) => handleDownload(evt, data)
          }]}
        />
    </Box>
  );
};

export default Certificates;