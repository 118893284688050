/*
TYPES
*/

// Mock Post Actions
export const FETCH_POSTS = 'FETCH_POSTS'
export const NEW_POST = 'NEW_POST'

// User Actions
export const LOGIN_USER = 'LOGIN_USER'
export const CREATE_USER = 'CREATE_USER'
export const FETCH_USERS = 'FETCH_USERS'

// Todo Actions
export const ADD_TODO = 'ADD_TODO'
export const TOGGLE_TODO = 'TOGGLE_TODO'
export const SET_DATE_FILTER = 'SET_DATE_FILTER'

// Location Related Actions
export const SET_LOCATION = "SET_LOCATION"
export const SET_HOUSING_UNIT = 'SET_HOUSING_UNIT'

// Loader Actions
export const LOADER = 'LOADER';

// User Action
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// Service Codes Actions
export const RELOAD_SERVICE_CODES = 'RELOAD_SERVICE_CODES';
export const RESET_RELOAD_SERVICE_CODES = 'RESET_RELOAD_SERVICE_CODES';


export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const SESSION_MODAL = 'SESSION_MODAL';
export const CHANGE_TOAST_TYPE = 'CHANGE_TOAST_TYPE';

export const SET_VOICE_BASE_KEYWORDS = 'SET_VOICE_BASE_KEYWORDS';

/* 
OTHER CONSTANTS
*/
export const DateFilters = {
    SHOW_WEEK: 'SHOW_WEEK',
    SHOW_MONTH: 'SHOW_MONTH',
    SHOW_YEAR: 'SHOW_YEAR'
  }

/*
ACTION CREATORS
*/
///Users
export function createUser(user) {
    return { type: CREATE_USER, user }
}

export function loginUser(user) {
    return { type: LOGIN_USER, user }
}

///Todos
export function addTodo(text) {
    return { type: ADD_TODO, text }
  }
  
  export function toggleTodo(index) {
    return { type: TOGGLE_TODO, index }
  }
  
  export function setDateFilter(filter) {
    return { type: SET_DATE_FILTER, filter }
  }

//Locations
export function setLocation(location){
  return { type: SET_LOCATION, payload: location}
}

export function setHousingUnit (hu) {
  return { type: SET_HOUSING_UNIT, payload: hu }
}

// Loader
export function showHideLoader(loaderState){
  return {type: LOADER, loaderState};
}

// Service Codes
export function reloadServiceCodes() {
  return { type: RELOAD_SERVICE_CODES }
}

export function resetReloadServiceCodes() {
  return { type: RESET_RELOAD_SERVICE_CODES }
}

export function currentUser(currentUser){
  return {type: SET_CURRENT_USER, currentUser};
}

export function showToast(message) {
  return { type: SHOW_TOAST, payload: message };
}

export function hideToast() {
  return { type: HIDE_TOAST };
}

export function changeToastType(type) {
  return { type: CHANGE_TOAST_TYPE, payload: type };
}

export function showHideSessionModal(metaInfo) {
  return { type: SESSION_MODAL, payload: metaInfo };
}

export function setVoiceBaseKeywords(keywords) {
  return { type: SET_VOICE_BASE_KEYWORDS, payload: keywords };
}