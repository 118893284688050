import { Box, Button, CircularProgress, withStyles } from '@material-ui/core';
import { CustomTheme, Loader, ResumeBuilder as ResumeBuilderContent, Typography } from '@orijinworks/frontend-commons';
import React from 'react';
import styles from './resume-builder.styles';
import { CustomIcons, icons } from '../../../shared/components/custom-icons';
import { useResumeBuilderHook } from './useResumeBuilderHook';
import { withRouter } from 'react-router-dom';
import UnleashProvider from '../../../core/unleash/unleashProvider';
import { UnleashService } from '../../../services';

const ResumeBuilder = (props) => {
  const { classes } = props;
  const {
    data,
    isLoading,
    isFetchingPDF,
    stateOptions,
    skillOptions,
    degreeOptions,
    learnerName,
    onViewPDFClick,
    onSave,
    onGoBackClick,
  } = useResumeBuilderHook(props);
  return (
    <Box>
      <UnleashProvider flagName={UnleashService.FLAGS.EDIT_RESUME}>
        <Loader isLoading={isLoading} />
        {!isLoading && (
          <>
            <Box>
              <Button id="go-back-btn" onClick={onGoBackClick} className={[classes.allResumeBtn, classes.btn].join(' ')}>
                <CustomIcons icon={icons.ArrowBackIosNewIcon} className={classes.backIcon} />
                &nbsp; All Resumes
              </Button>
            </Box>
            <Box className={classes.builderContainer}>
              <Box className={classes.editingBackground}>
                <Box>
                  <Box>
                    <Typography variant='overlineLarge'>You're Editing</Typography>
                    <Typography variant="h1" id="resume-title">
                      {`${learnerName}'s `}
                      Resumé
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    id="view-pdf-btn"
                    onClick={onViewPDFClick}
                    disabled={isFetchingPDF}
                    className={[classes.viewPdfBtn, classes.btn].join(' ')}
                  >
                    {isFetchingPDF && <CircularProgress size={20} color="inherit" />}
                    {!isFetchingPDF && 'View as PDF'}
                  </Button>
                </Box>
              </Box>
              <Box className={classes.resumeBuliderContents}>
                <ResumeBuilderContent
                  {...data}
                  skillsOptions={skillOptions}
                  stateOptions={stateOptions}
                  degreeOptions={degreeOptions}
                  onSave={onSave}
                  allowReordering
                />
              </Box>
            </Box>
          </>
        )}
      </UnleashProvider>
    </Box>
  );
};

export default withStyles(styles)(withRouter(ResumeBuilder));
