import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    textAlign: 'center',
    paddingTop: 5,
    overflow: 'auto'
  },
  loaderWrapper: {
    padding: 20
  },
  nested: {
    marginLeft: 20
  },
  topBarWrapper: {
    alignItems: 'center',
    margin: '25px 0px',
    marginRight: 25
  },

  topBarImg: {
    width: 24
  },
  topBarText: {
    ...theme.typography.t18,
    ...theme.typography.bold,
    color: theme.palette.primary.cobalt,
    letterSpacing: '1px',
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center'
  },
  iconWrapper: {
    opacity: 0.5,
    ...theme.typography.t18,
    ...theme.typography.medium,
    color: theme.palette.primary.black
  },
  icon: {
    marginLeft: 5
  },
  fabButton: {
    width: '36px',
    height: '30px',
    background: 'white',
    color: theme.palette.primary.cobalt,
    boxShadow: '0px 1px 1.5px #9E9E9E'
  },
  listHeaderSection: {
    cursor: 'pointer'
  },
  expandCollapseContent: {
    margin: '0',
    position: 'absolute',
    top: '60%',
    transform: 'translateY(-50%)',
    color: theme.palette.text.navy,
    fontSize: theme.typography.t18.fontSize,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    }
  },
  courseName: {
    fontSize: theme.typography.t18.fontSize,
    fontWeight: theme.typography.medium.fontWeight
  },
  itemText: {
    padding: '15px'
  },
  notFound: {
    fontWeight: 'bold'
  },
  detailsList: {
    position: 'initial'
  },
  divider: {
    position: 'absolute',
    left: 0,
    right: 0
  },
  listHeader: {
    top: -7,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: 'white'
  },
  eyeIcon: {
    width: '25px'
  },
  gridWrapper: {
    marginLeft: -50,
    marginRight: -50
  },
  gridLayout: {
    background: theme.palette.primary.white,
    'box-shadow': '0px -1px 2px 1px #ada4a4ab'
  },
  downSortIcon: {
    marginTop: -10,
    [theme.breakpoints.down('md')]: {
      marginTop: -20
    }
  },
  cardActionBtn: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    fontSize: theme.typography.t18.fontSize,
    textTransform: 'capitalize',
    letterSpacing: '1px'
  },
  assignedText: {
    fontSize: theme.typography.t12.fontSize,
    fontWeight: theme.typography.medium.fontWeight,
    textTransform: 'capitalize'
  },
  dateText: {
    textTransform: 'uppercase'
  },
  snackBar: {
    width: '98%',
    marginBottom: '25px',
    marginLeft: '10px'
  }
}));
