import React from 'react';
import { Box, IconButton } from '../../../../../shared/components/MaterialUIComponents';
import { Typography } from '@orijinworks/frontend-commons';
import { CustomIcons, icons } from '../../../../../shared/components/custom-icons';
export const TitleView = ({classes, title, onClose}) => {
    return (
        <Box className={classes.newConvoHeader}>
        <Typography 
        variant="h3"
        id="new-learner-title" className={classes.newConvoHeading}>
          {title}
        </Typography>
        <IconButton
          id="cancel-btn-title"
          onClick={onClose}
        >
          <CustomIcons className={classes.closeIcon} icon={icons.CloseIcon} />
        </IconButton>
      </Box>
    )
}
