import React from 'react';
import Datatable, { MTableToolbar } from 'material-table';
import styles from './index.styles';
import { Box, Grid, withStyles } from '@material-ui/core';
import { useAnnouncementsHook } from './useAnnouncementHook';
import moment from 'moment';
import { withUnleash } from '../../../../../v2/core/unleash';
import { UnleashService } from '../../../../../v2/services';

const CommunicationAnnouncements = (props) => {
  const { classes, range, TabSelector, unleashProps } = props;
  const { limit, page, announcementCount, getAnnouncementColumns, getAnnouncements, getDataForExportCsv, onPageChange } =
    useAnnouncementsHook(props);

  return (
    <div className={classes.tableStyles} key={JSON.stringify(range)}>
      <Datatable
        className={classes.tableStyles}
        columns={getAnnouncementColumns()}
        data={getAnnouncements}
        components={{
          Toolbar: (props) => (
            <div>
              {unleashProps.isFlagEnabled() ? (
                <Grid container>
                  <Grid item xs={6}>
                    <Box padding="30px">{TabSelector}</Box>
                  </Grid>
                  <Grid item xs={6}>
                    <MTableToolbar {...props} />
                  </Grid>
                </Grid>
              ) : (
                <MTableToolbar {...props} />
              )}
            </div>
          ),
        }}
        onChangePage={(currentPage) => onPageChange(currentPage)}
        page={page}
        totalCount={announcementCount}
        options={{
          pageSize: limit,
          filtering: false,
          exportButton: true,
          actionsColumnIndex: 0,
          search: false,
          showTitle: false,
          exportFileName: `AnnouncementsExport-${moment().format('MM-DD-YYYY')}`,
          exportCsv: (columns, data) =>
            props.exportCommunicationList(
              columns,
              data,
              `AnnouncementsExport-${moment().format('MM-DD-YYYY')}`,
              'Announcements',
              getDataForExportCsv
            ),
        }}
        icons={{
          FirstPage: () => <div></div>,
          LastPage: () => <div></div>,
        }}
      />
    </div>
  );
};

export default withStyles(styles)(
  withUnleash(CommunicationAnnouncements, UnleashService.FLAGS.RESTRUCTURE_COMMUNICATION_TAB)
);
