import React from "react";

const MessageIcon = props => {
  const { fill } = props;

  return (
    <svg id="messages_med" xmlns="http://www.w3.org/2000/svg" width="24.258" height="22.884" viewBox="0 0 24.258 22.884">
      <path 
        id="messages" 
        d="M18.572,33.641h-7.2a.948.948,0,1,1,0-1.9h7.2a3.8,3.8,0,0,0,3.79-3.79v-7.77a3.8,3.8,0,0,0-3.79-3.79H5.685a3.8,3.8,0,0,0-3.79,3.79v7.3a.474.474,0,0,0,.474.474H5.212a.948.948,0,0,1,0,1.9H2.369A2.372,2.372,0,0,1,0,27.482v-7.3A5.692,5.692,0,0,1,5.685,14.5H18.572a5.692,5.692,0,0,1,5.685,5.685v7.77A5.692,5.692,0,0,1,18.572,33.641ZM8.528,32.692a.948.948,0,0,0-.948-.948H.948a.948.948,0,0,0,0,1.9H7.581A.948.948,0,0,0,8.528,32.692Zm2.843,3.744a.948.948,0,0,0-.948-.948H.948a.948.948,0,0,0,0,1.9h9.476A.948.948,0,0,0,11.371,36.436ZM15.6,25.75l4.589-3.483a.948.948,0,0,0-1.146-1.51l-4.591,3.484a3.8,3.8,0,0,1-4.595,0L5.412,20.809a.948.948,0,1,0-1.159,1.5L8.7,25.745l0,0a5.7,5.7,0,0,0,6.894,0Z" 
        transform="translate(0 -14.5)" 
        fill={fill}
        stroke="none"
      />
    </svg>
  );
};

MessageIcon.defaultProps = {
  fill: "inherit"
}

export default MessageIcon;
