export default (theme) => ({
    label: {
        color: theme.palette.colors.ceruleanMain,
        fontWeight: 'bold'
    },
    cardContainer: {
        "& > div:first-child": {
            paddingLeft: 0
        },
        "& > div:last-child": {
            paddingRight: 0
        }
    },
    cardWrapper: {
        padding: "0px 10px",
    },
    card: {
        color: theme.palette.primary.white,
        borderRadius: 3,
        height: 80,
        width: "100%",
        padding: "5px 10px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px"
    },
    cardMeta: {
        fontSize: `${theme.typography.t14.fontSize} !important`,
        fontWeight: `${theme.typography.medium.fontWeight} !important`
    },
    cardNumber: {
        ...theme.typography.t30,
        ...theme.typography.bold,
    },
});