export default theme => ({
  root: {
    width: 28,
    height: 20,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  switchBase: {
    padding: 2,
    paddingTop: 6,
    color: '#9e9e9e',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&$checked': {
      transform: 'translateX(14px)',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#19ab15',
        borderColor: '#19ab15',
      },
      '&:hover': {
        backgroundColor: 'transparent'
      },
    },
  },
  colorSecondary: {
    '&$checked': {
      color: '#fff',
    }
  },
  checked: {},
  thumb: {
    width: 8,
    height: 8, 
    boxShadow: 'none'
  },
  track: {
    border: '1px solid #9e9e9e',
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#fff',
    height: 10,
    width: 24
  }
});