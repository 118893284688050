import { withStyles } from '@material-ui/styles';
import React, { useGlobal } from 'reactn';
import { Box, Typography } from '../MaterialUIComponents';
import styles from './breadcrumbs.style';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Breadcrumbs = props => {
  const ROUTE_MAPPING = {
    manage: 'Location & User Management',
    manageRoles: 'Roles & Permissions',
    'outcomes-and-progress': 'Outcomes & Progress',
    messages: 'Messages',
    manageLocations: 'Location Management',
    users: 'Users'
  };
  const [global, setGlobal] = useGlobal();
  const { classes, selectedLocation, selectedHousingUnit, history, shouldShowLocations } = props;
  const [activePageName, setActivePageName] = React.useState(null);

  React.useEffect(() => {
    setActivePageName(getPageName(1, props.location));
    history.listen((location, action) => {
      if (getPageName(1, location) !== activePageName) {
        setGlobal({
          hiddenBreadcrumbItems: []
        });
      }
      setActivePageName(getPageName(1, location));
    });
  }, []);

  const getPageName = (level, location) => {
    const { pathname } = location;
    const pathArray = pathname.split('/');
    const pageName = pathArray[level];
    if (ROUTE_MAPPING.hasOwnProperty(pageName)) {
      return ROUTE_MAPPING[pageName];
    }
    return pageName;
  };
  return (
    <>
      <Box className={classes.breadcrumbRoot}>
        <Typography className={classes.text}>Home</Typography>
        <Typography className={classes.divider}>/</Typography>
        <Typography className={[classes.text]}>{activePageName}</Typography>
        {shouldShowLocations && (
          <>
            {selectedLocation && (
              <>
                <Typography className={classes.divider}>/</Typography>
                <Typography className={[classes.text]}>{selectedLocation.locationName}</Typography>
              </>
            )}

            {selectedHousingUnit && (
              <>
                <Typography className={classes.divider}>/</Typography>
                <Typography className={[classes.text]}>{selectedHousingUnit.locationName}</Typography>
              </>
            )}
          </>
        )}
        {(global.hiddenBreadcrumbItems || []).map(item => (
          <>
            <Typography className={classes.divider}>/</Typography>
            <Typography className={[classes.text]}>{item}</Typography>
          </>
        ))}
      </Box>
    </>
  );
};

const mapStateToProps = state => {
  return state.app;
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Breadcrumbs)));
