export default theme => ({
  root: {
    width: '100%',
    borderRadius: 0,
    marginBottom: 1
  },
  cardContent: {
    padding: '10px 25px',
    '&:last-child': {
      paddingBottom: 10
    }
  },
  collapseBtn: {
    color: theme.palette.text.red,
    borderColor: '#d73434'
  },
  accordionHeading: {
    fontWeight: 700,
    fontSize: 20,
    color: '#3d3d3d',
    letterSpacing: '0.35px'
  },
  icon: {
    transition: 'all 0.2s',
    transform: 'initial'
  },
  outlinedComponent: {
    borderRadius: 0,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: 'none',
    width: '100%',
    marginTop: 10,
    marginBottom: 10
  },
  expanded: {
    transform: 'rotate(90deg)'
  },
  accrodionContent: {
    width: '100%'
  },
  accordionItem: {
    cursor: 'pointer',
    '& p': { margin: 'auto 0' }
  }
});
