import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'

const ConfirmationDialog = ({ open, handleClose, confirmationMode, onSave }) => {

  const onSaveClick = () => {
    onSave();
    handleClose();
  }
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {confirmationMode === 'delete' ? "Are you sue you want to delete this facility from this role?" :
            `Are you sure you want to deactivate this role?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={onSaveClick} color="primary" variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog;