import React from "react";
import { withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ErrorIcon from "@material-ui/icons/Error";

import styles from "./index.styles";

const ErrorMessages = (props) => {
  const { classes, errorMessages, style } = props;

  return (
    <>
      {errorMessages && errorMessages.length > 0 && (
        <Box className={classes.errorSection} style={style}>
          <ErrorIcon />
          <ul className={classes.errorList}>
            {errorMessages.map((errorMessage, index) => (
              <li key={index}>{errorMessage}</li>
            ))}
          </ul>
        </Box>
      )}
    </>
  );
};

export default withStyles(styles)(ErrorMessages);
