import { KeycloakService } from '../../../../keycloak';
import { AUTH_CONSTANTS } from '../constants/auth-constants';

/**
 * Variable to store the useOktaAuth0 flag
 */
let useOktaAuth0 = false;

/**
 * Variable to store the auth0 client
 */
let auth0Client = null;

/**
 * Function to set the useOktaAuth0 flag
 *
 * @param {boolean} value - The value to set the useOktaAuth0 flag
 */
export const setUseOktaAuth0 = (value) => {
  useOktaAuth0 = value;
};

/**
 * Function to get the useOktaAuth0 flag
 *
 * @returns {boolean} The useOktaAuth0 flag
 */
export const getUseOktaAuth0 = () => {
  return useOktaAuth0;
};
/**
 * Function to get the access token.
 * It returns the access token based on the useOktaAuth0 flag.
 * If the useOktaAuth0 flag is true, it returns the auth0 access token.
 * If the useOktaAuth0 flag is false, it returns the Keycloak access token.
 *
 * @returns {string} The access token.
 */
export const getAccessToken = () => {
  if (useOktaAuth0) {
    return auth0Client.getAccessTokenSilently();
  }

  return KeycloakService.Keycloak.token;
};

/**
 * Function to handle the authentication based on the provider.
 * If the useOktaAuth0 flag is true, it returns the auth0 response.
 * If the useOktaAuth0 flag is false, it returns the Keycloak response.
 *
 * @param {Object} keycloak - The Keycloak object
 * @param {Object} auth0 - The Auth0 object
 * @returns
 */
export const handleAuthentication = (keycloak, auth0) => {
  return new Promise((resolve, reject) => {
    const response = {
      isAuthenticated: false,
      isAuthorized: false,
      user: {
        personId: '', // NEW
        preferred_username: '',
        auth_time: '',
        isSuperAdmin: false,
        isFacilityAdmin: false,
        isAttorney: false,
        isTeacher: false,
      },
      redirectToLogin: () => {},
    };

    if (useOktaAuth0) {
      auth0Client = auth0;
      response.isAuthenticated = auth0.isAuthenticated;

      if (auth0.isAuthenticated) {
        response.isAuthorized = validateRole(auth0.user.user_roles, AUTH_CONSTANTS.AUTH0_ROLES);
        response.user = auth0.user;
        response.user.auth_time = auth0.user.user_auth_time;
        response.user.isSuperAdmin = hasRole(auth0.user.user_roles, AUTH_CONSTANTS.AUTH0_ROLES.SUPER_ADMIN);
        response.user.isFacilityAdmin = hasRole(auth0.user.user_roles, AUTH_CONSTANTS.AUTH0_ROLES.FACILITY_ADMIN);
        response.user.isAttorney = hasRole(auth0.user.user_roles, AUTH_CONSTANTS.AUTH0_ROLES.ATTORNEY);
        response.user.isTeacher = hasRole(auth0.user.user_roles, AUTH_CONSTANTS.AUTH0_ROLES.TEACHER);
      }
      response.redirectToLogin = auth0.loginWithRedirect;
      return resolve(response);
    }

    if (keycloak) {
      response.isAuthenticated = keycloak.authenticated;
      if (keycloak.authenticated) {
        response.isAuthorized = validateRole(keycloak.realmAccess.roles, AUTH_CONSTANTS.KEYCLOAK_ROLES);
        response.user = keycloak.tokenParsed;
        response.user.isSuperAdmin = hasRole(keycloak.realmAccess.roles, AUTH_CONSTANTS.KEYCLOAK_ROLES.SUPER_ADMIN);
        response.user.isFacilityAdmin = hasRole(keycloak.realmAccess.roles, AUTH_CONSTANTS.KEYCLOAK_ROLES.FACILITY_ADMIN);
        response.user.isAttorney = hasRole(keycloak.realmAccess.roles, AUTH_CONSTANTS.KEYCLOAK_ROLES.ATTORNEY);
        response.user.isTeacher = hasRole(keycloak.realmAccess.roles, AUTH_CONSTANTS.KEYCLOAK_ROLES.TEACHER);
      }
      response.redirectToLogin = keycloak.login;
    }

    resolve(response);
  });
};

/**
 * Function to handle the logout based on the provider.
 * If the useOktaAuth0 flag is true, it calls the auth0 logout function.
 * If the useOktaAuth0 flag is false, it calls the Keycloak logout function.
 *
 * @param {Object} keycloak - The Keycloak object
 * @param {Object} auth0 - The Auth0 object
 */
export const handleLogout = (keycloak, auth0 = auth0Client) => {
  if (useOktaAuth0) {
    auth0.logout({ logoutParams: { returnTo: window.location.origin } });
  } else {
    keycloak.logout({ redirectUri: `${window.location.origin}/` });
  }
};

/**
 * Function to check if the user is authenticated based on the provider.
 * If the useOktaAuth0 flag is true, it returns the auth0 isAuthenticated flag.
 * If the useOktaAuth0 flag is false, it returns the Keycloak authenticated flag.
 *
 * @param {Object} keycloak - The Keycloak object
 * @param {Object} auth0 - The Auth0 object
 * @returns {boolean} The isAuthenticated flag
 */
export const isAuthenticated = (keycloak, auth0) => {
  if (useOktaAuth0) {
    return auth0.isAuthenticated;
  }
  return keycloak.authenticated;
};

/**
 * Function to get the token claims based on the provider.
 *
 * @param {Object} keycloak - The Keycloak object
 * @param {Object} auth0 - The Auth0 object
 * @returns {Object} The token claims
 */
export const getTokenClaims = (keycloak, auth0) => {
  if (useOktaAuth0) {
    return auth0.user;
  }
  return keycloak.tokenParsed;
};

/**
 * Function to validate the role based on the ROLES_CONSTANTS.
 * If the role is ADMIN, it returns false.
 * If the role is LEARNER, it returns true.
 *
 * @param {Array} roles - The roles array
 * @param {Array} ROLES_CONSTANTS - The roles constants
 * @returns {boolean} The authorize flag
 */
const validateRole = (roles, ROLES_CONSTANTS) => {
  const authorizedRoles = [
    ROLES_CONSTANTS.ADMIN,
    ROLES_CONSTANTS.FACILITY_ADMIN,
    ROLES_CONSTANTS.PROGRAMMING_STAFF,
    ROLES_CONSTANTS.SUPER_ADMIN,
    ROLES_CONSTANTS.ATTORNEY,
    ROLES_CONSTANTS.TEACHER,
  ];
  let authorize = false;
  for (let i = 0; i < roles.length; i++) {
    if (authorizedRoles.includes(roles[i])) {
      authorize = true;
    }
  }

  return authorize;
};

/**
 * Function to check if the roles array has the role.
 *
 * @param {Array} roles - The roles array
 * @param {String} role - The role to check
 * @returns {boolean} The hasRole flag
 */
const hasRole = (roles, role) => {
  return roles.includes(role);
};
