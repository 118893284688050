export default (theme) => ({
  header: {
    marginTop: 10,
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  mainHeading: {
    marginTop: 10,
  },
  divider: {
    width: '936px',
    height: '1px',
    background: theme.palette.text.divider,
  },
  dividerRow: {
    display: 'flex',
    padding: '6px 0px 6px 0px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 24,
  },
  stableTableCellOne: {
    height: '100%',
    flex: 1,
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '2px',
    textAlign: 'left',
  },
  stableTableCellTwo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flex: '1 0 0',
    alignSelf: 'stretch',
    paddingLeft: 64,
  },
  label: {
    alignSelf: 'stretch',
    flex: 1,
    position: 'relative',
    letterSpacing: '0.02em',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxHeight: '24px',
    fontWeight: 'bold !important',

  },
  subText: {
    fontStyle: 'italic',
    color: theme.palette.colors.grey110,
  },

  scheduleDropdown: {
    width: '80%',
  },

  boldText: {
    fontWeight: 'bold',
  },

  infoText: {
    fontSize: '12px !important',
    gap: '4px',
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'self-start',
    lineHeight: '22px',
    letterSpacing: '0.24px',
    paddingLeft: 64,
  },

  createNewScheuleBox: {
    display: 'flex',
    paddingLeft: '128px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flex: '1 0 0',
    alignSelf: 'stretch',
  },
  scheduleLabel:{
    color: `${theme.palette.colors.aquaLight} !important`,
    fontSize: '16px !important',
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    lineHeight: 'normal',
    letterSpacing: '1.6px',
    textTransform: 'uppercase',

  },
  createNewScheuleBtn:{
    background:`${theme.palette.colors.aquaDeep} !important`,
  }
});
