import React, { useGlobal } from 'reactn';


const ManageStaff = React.lazy(() => import("../manage-staff"));
const ManageStaffV2 = React.lazy(() => import("../manage-staff-v2"));

export const ManageStaffContainer = (props) => {
    const [ global ] = useGlobal();
    if (global.cbacConfigFlag) {
        return <ManageStaffV2 {...props} />
    }

    return <ManageStaff {...props} />
}


export default ManageStaffContainer;