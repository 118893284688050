export default theme => ({
  errorMessage: {
    fontSize: 12.8,
    color: '#d0021b',
    backgroundColor: 'rgba(208, 2, 27, 0.1)',
    padding: '7px 8px',
    marginTop: 12
  },
  dialogActions: {
    justifyContent: 'center',
    paddingBottom: 25,
    marginTop: 40
  },
  title: {
    color: theme.palette.primary.navy,
  }
});
