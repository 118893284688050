import React, { forwardRef, Component } from 'reactn';
import Datatable, { MTableToolbar } from 'material-table'
import {
    Grid,
    withStyles,
    Typography,
    Box,
    Button,
    IconButton,
    Card,
    Fade,
} from '@material-ui/core';
import styles from './index.style';
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RefreshIcon from '@material-ui/icons/Refresh';
import FilterListIcon from '@material-ui/icons/FilterList';
import { withRouter } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import SortIcon from '@material-ui/icons/Sort'
import { Loader, Menu } from '..';
import { USER_CONTEXT, COMMUNICATION_TAB_TYPE, COMMUNICATION_FILTER, LOCATIONS } from "../../../constants/";
import request, { getCommunicationListAtLocation, listUsers, qrylocation, getStaffList, logMsgEvent, getFAEvents, getFACollection } from '../../../util/APIUtils';
import DaysFilter from '../../days-filter';
import moment from 'moment';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { Filter } from '../communications/components/';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Visualization } from '../../communication';
import Hoverable from '../../hoverable';
import { ImageViewer } from '../../image-viewer';
import { MetaData } from '../../meta-data/meta-data';
import { UserIcon } from '../../UserIcon';
const StaffProfile = React.lazy(() => import('../staff/staff-profile/staff-profile'));


class FacialAuth extends Component {
    tableRef = React.createRef();
    faColumns = [

        {
            title: 'Event',
            field: "eventTypeName",
            render: rowData => (
                <Hoverable title={rowData.eventTypeName}>
                    {rowData.eventTypeName ? rowData.eventTypeName : "N/A"}
                </Hoverable>
            )
        },
        {
            title: 'Username',
            field: "targetObjectName",
            render: rowData => (
                <Hoverable title={rowData.targetObjectName}>
                    {rowData.targetObjectName ? rowData.targetObjectName : "N/A"}
                </Hoverable>
            )
        },
        {
            title: 'Status Code',
            field: 'statusCode',
            render: rowData => (
                <Hoverable title={`${rowData.statusCode}`}>
                    {rowData.statusCode ? rowData.statusCode : "N/A"}
                </Hoverable>
            )
        },
        {
            title: 'Status Message',
            field: 'statusMessage',
            render: rowData => (
                <Hoverable title={`${rowData.statusMessage}`}>
                    {rowData.statusMessage ? rowData.statusMessage : "N/A"}
                </Hoverable>
            )
        },
        {
            title: 'Location',
            field: "locationKey",
            render: rowData => (
                <Hoverable title={rowData.locationKey}>
                    {rowData.locationKey ? this.getLocationName(rowData.locationKey) : "N/A"}
                </Hoverable>
            )
        },
        {
            title: 'Date',
            field: "eventTimestamp",
            render: rowData => (
                <Hoverable title={rowData.eventTimestamp}>
                    {rowData.eventTimestamp ? rowData.eventTimestamp : "N/A"}
                </Hoverable>
            )
        },
        {
            title: 'Metadata',
            field: "faceDetailsMetadata",
            render: rowData => (
                rowData.faceDetailsMetadata && this.getMetadataValue(rowData.faceDetailsMetadata) ?
                    (
                        <Button onClick={() => this.handleMetaDataDialog(true, rowData.faceDetailsMetadata)} color="primary">
                            <Hoverable title={rowData.faceDetailsMetadata}>
                                {this.getMetadataValue(rowData.faceDetailsMetadata)}
                            </Hoverable>
                        </Button>
                    ) : "N/A"

            )
        },

    ];
    state = {
        location: null,
        faCollection: [],
        filters: {
            eventType: "",
            location: "",
            people: ""
        },
        metaDataDialog: {
            isOpen: false
        },
        filterData: {
            locations: [],
            users: [],
            staff: []
        },
        pagination: {
            currentPage: 0,
            pageSize: 20
        },
    };

    /**
     * @name findKey
     * @param {string} keyName
     * @return {object}
     */
    findKey = (keyName, object) => {
        for (let a in object) {
            if (a === keyName) {
                return object[a];
            }
            let foundObj = null;
            if (Array.isArray(object)) {
                for (let b = 0; b < object.length; b++) {
                    foundObj = this.findKey(keyName, object[b]);
                    if (foundObj) {
                        break;
                    }
                }
            } else if (typeof object === "object" && object !== null) {
                foundObj = this.findKey(keyName, object[a]);
            };

            if (foundObj) {
                return foundObj;
            }

        }
        return null;
    }

    /**
     * @name getMetadataValue
     * @param {string} jsonObj faceDetails key is searched in json object and appropriate data is returned. 
     * @return {string}
     */
    getMetadataValue = (jsonObj) => {
        try {
            const brightness = this.findKey("brightness", JSON.parse(jsonObj));
            const similarity = this.findKey("similarity", JSON.parse(jsonObj));
            if (brightness || similarity) {
                return `
                ${brightness ? `Brightness: ${brightness}` : ''}
                ${similarity ? `Similarity: ${similarity}` : ''}
                `;
            }
        } catch (e) {
            console.log(e);
        }
        return null;
    };

    /**
     * @param {object} json JSON object in which keys needs to be refined.
     * @desc Refines object and deletes unwanted keys from it.
     * @return {object} refinedObj
     */
    refineKey = (object) => {
        const unwantedKeys = ["sdkHttpMetadata", "sdkResponseMetadata", "formResponse"];
        for (let a in object) {
            if (unwantedKeys.indexOf(a) > -1) {
                delete object[a];
            }
            if (Array.isArray(object)) {
                for (let b = 0; b < object.length; b++) {
                    this.refineKey(object[b]);
                }
            } else if (typeof object === "object" && object !== null) {
                this.refineKey(object[a]);
            };
        }
        return object;
    };

    handleMetaDataDialog = (isOpen, jsonObject) => {
        const obj = {
            metaDataDialog: {
                ...this.state.metaDataDialog,
                isOpen,
            }
        };
        if (jsonObject) {
            obj.metaDataDialog["jsonObject"] = this.refineKey(JSON.parse(jsonObject));

        }
        this.setState(obj);
    };


    getStaffs = (locationID) => {
        return new Promise(
            (resolve, reject) => {
                getStaffList(locationID)
                    .then(_successLog => resolve(_successLog.staffList), () => resolve([]))
            }
        );
    }

    getUsers = (locationID) => {
        return new Promise(
            (resolve, reject) => {
                let postBody = {
                    requestorId: 191439,
                    requestorLocationId: locationID,
                    filterLocationTypeIds: [],
                    filterRoleTypeIds: [19, 30],
                    filterLocationIds: [],
                    filterUserStatus: 'all'
                }
                listUsers(postBody)
                    .then(data => resolve(data.users), () => resolve([]))
            }
        );
    }

    getLocations = (locationID) => {
        return new Promise(
            (resolve, reject) => {
                const pBody = JSON.stringify({
                    requestorId: USER_CONTEXT.personId,
                    filterLocationId: locationID
                })
                qrylocation(pBody)
                    .then(data => {
                        if (data.locationChildren.length === 0) {
                            resolve([])
                        } else {
                            resolve(data.locationChildren)
                        };
                    }, (_errorLog) => resolve([]))
            }
        );
    }

    /**
     * @name getFiltersData
     * @desc A wrapper method used to fetch filters data.
     * @return {void}
     */
    getFiltersData = (locationID) => {
        this.setGlobal({
            isLoading: true
        });
        Promise.all([this.getUsers(locationID), this.getLocations(locationID), this.getStaffs(locationID), getFAEvents()])
            .then(
                (dataCollection) => {
                    this.setState({
                        filterData: {
                            ...this.state.filterData,
                            users: [...dataCollection[0], ...dataCollection[2]],
                            locations: dataCollection[1],
                            eventTypes: dataCollection[3].eventTypes
                        }
                    });
                    this.setGlobal({
                        isLoading: false
                    });
                },
                (_errorLog) => {
                    this.setGlobal({
                        isLoading: false
                    });
                    console.error(_errorLog);
                }
            );
    };

    /**
     * @name handleDialog
     * @param {string} parentStateKey
     * @param {boolean} isOpen
     * @desc Handle's all dialog toggle state.
     * @return {void}
     */
    handleDialog = (parentStateKey, isOpen, additionalData) => {
        const parentStateData = this.state[parentStateKey];
        this.setState({
            [parentStateKey]: {
                ...parentStateData,
                ...additionalData,
                isOpen
            }
        });
    };

    /**
     * @name initPropToState
     * @desc A wrapper method to initialize properties from prop to state.
     * @return {void}
     */
    initPropToState = () => {
        this.setState({
            location: this.props.location,
            faCollection: []
        });
        this.getFaCollection(this.props.location.active, true);
    };

    getDateDateRange = (range) => {
        return {
            startDate: moment(range.startDate).format("YYYY-MM-DD"),
            endDate: moment(range.endDate).format("YYYY-MM-DD")
        };
    };

    /**
     * @name getFaCollection
     * @param {number} locationId
     * @param {boolean} isFetchFilters Default false, if true filters call will be initiated.
     * @desc A method responsible to fetch FA collection item.
     * @return {void}
     */
    getFaCollection = (locationId, isFetchFilters = false) => {
        const { filters } = this.state;
        const date = this.getDateDateRange(this.global.range);
        const locationIds = filters.location !== "" && filters.location ? filters.location : locationId;
        this.setGlobal({
            isLoading: true
        });
        this.setState({
            showEmptyDataSourceMessage: false
        });
        const requestPayload = {
            "requestorId": USER_CONTEXT.personId,
            "locationIds": locationIds ? [locationIds] : [],
            "eventTypeIds": filters.eventType ? [parseInt(filters.eventType)] : [],
            "userName": filters.people,
            "startDate": date.startDate,
            "endDate": date.endDate
        };
        getFACollection(requestPayload)
            .then(
                (_successLog) => {
                    this.setGlobal({
                        isLoading: false
                    });
                    this.setState({
                        faCollection: _successLog.facialAuthEvents,
                        showEmptyDataSourceMessage: true
                    });
                    if (isFetchFilters) {
                        this.getFiltersData(this.props.location.active);
                    }
                },
                (_errorLog) => {
                    this.setGlobal({
                        isLoading: false
                    });
                }
            )
    };

    /**
     * @name filterUpdateHandler
     * @param {object} filterData
     * @desc Callback fire's when filter is updated.
     * @return {void}
     */
    filterUpdateHandler = (filters) => {
        this.setState({
            filters,
            faCollection: []
        }, () => {
            this.getFaCollection(this.props.location.active);
        });
    };

    /**
     * @name dateChangeHandler
     * @param {object} range
     * @desc Fire's when date change is done.
     * @return {void} 
     */
    dateChangeHandler = (range) => {
        this.setState({
            faCollection: []
        }, () => {
            this.getFaCollection(this.props.location.active);
        });
    };

    componentDidMount() {
        this.initPropToState();
    };

    componentDidUpdate() {
        if (this.props.location.active !== this.props.location.active) {
            this.initPropToState();
            this.forceUpdate();
        }
    };

    /**
     * @name getLocationName
     * @param {number} locationId
     * @desc Based upon locationId it finds in LOCATIONS collection
     * for matching location and shows locationName instead.
     * @return {string}
     */
    getLocationName = (locationId) => {
        const location = LOCATIONS.find(location => location.locationId == locationId);
        if (location) {
            return location.locationName;
        } else {
            return locationId;
        };
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid container item>
                <MetaData
                    {...this.state.metaDataDialog}
                    onClose={() => this.handleMetaDataDialog(false)}
                />
                <Grid container item>
                    <Grid md={6}>
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            padding="20px">
                            <Filter
                                defaultFilters={[COMMUNICATION_FILTER.EventType]}
                                allowedFilters={[COMMUNICATION_FILTER.EventType, COMMUNICATION_FILTER.People, COMMUNICATION_FILTER.Location]}
                                filterUpdateHandler={this.filterUpdateHandler}
                                {...this.state.filterData}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={6}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            marginTop="20px"
                            width="100%">
                            <DaysFilter
                                dateChangeHandler={this.dateChangeHandler}
                                dateRangePickerProps={{
                                    left: "-195px"
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    className={classes.datatable}>
                    <Datatable
                        tableRef={this.tableRef}
                        style={{ width: '100%' }}
                        columns={this.faColumns}
                        data={this.state.faCollection}
                        components={{
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar

                                        {...props} />
                                </div>
                            )
                        }}
                        options={{
                            sorting: true,
                            pageSize: this.state.pagination.pageSize,
                            paging: true,
                            showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
                            selection: true,
                            showSelectAllCheckbox: true,
                            filtering: true,
                            exportButton: true,
                            Sort: SortIcon,
                            SortArrow: ArrowDownwardIcon,
                            actionsColumnIndex: 0,
                            search: false,
                            exportFileName: `FAExport-${moment().format("MM-DD-YYYY")}`,
                            selectionProps: rowData => ({
                                id: `checkbox-${rowData.formId}`
                            })
                        }}
                        icons={{
                            Search: SearchIcon,
                            ResetSearch: CloseIcon,
                            FirstPage: FirstPageIcon,
                            LastPage: LastPageIcon,
                            NextPage: NavigateNextIcon,
                            PreviousPage: NavigateBeforeIcon,
                            Filter: FilterListIcon,
                            SortArrow: ArrowUpwardIcon,
                            Export: SaveAltIcon,
                            Edit: EditIcon,
                            User: UserIcon
                        }}
                        title={(
                            <Box>
                                <Typography
                                    component="p"
                                    className={classes.locationName}>
                                    {this.state.location && this.state.location.collection.find(
                                        (location) => location.locationId === this.props.location.active
                                    ) ?
                                        this.state.location.collection.find(
                                            (location) => location.locationId === this.props.location.active
                                        ).locationName : ""}
                                </Typography>
                                <Typography
                                    component="p"
                                    className={classes.tabName}>
                                    Biometric Authentication
                                </Typography>
                            </Box>

                        )}
                    />
                </Grid>
            </Grid >
        );
    };
};

export default withStyles(styles)(FacialAuth);