import {CustomTheme} from "@orijinworks/frontend-commons"

export default (theme) => ({
    newConversationWrapper: {
        backgroundColor: "white",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        height: "100%"
    },
    newConvoHeading: {
        color: "white",
        flex: 1
    },
    newConvoHeader: {
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary.mainBlue,
        padding: "10px 25px",
        borderRadius: "10px 10px 0px 0px",
        "& p": {
            flex: "1",
            fontSize: 24,
            fontWeight: 500,
            color: "#FFF"
        }
    },
    body: {
        flex: 1
    },
    closeIcon: {
        fontSize: 30,
        fontWeight: 700,
        color: "#154157"
    },
    formGroup: {
        display: "flex",
        alignItems: "flex-start",
        margin: "30px 25px",
        paddingBottom: 10,
        borderBottom: "1px solid #DFE6E9",
    },
    formLabel: {
        fontSize: 18,
        color: theme.palette.colors.grey100,
        fontFamily: CustomTheme.typography.fontFamily,
        marginRight: 10
    },
    formField: {
        border: "none",
        outline: "none",
        height: 20,
        fontSize: 16,
        fontFamily: CustomTheme.typography.fontFamily,
        width: "100%",
    },

    sendEditorWrapper: {
        border: "1px solid #DFE6E9",
        margin: 20,
        display: "flex",
        flexDirection: "column",
        borderRadius: 5,
        alignItems: "flex-end",

    },
    sendEditor: {
        border: "none",
        flex: "1",
        outline: "none",
        padding: 20,
        fontFamily: CustomTheme.typography.fontFamily,
        borderRadius: "5px 5px 0px 0px",
        minHeight: 200,
        borderBottom: "1px solid #DFE6E9",
        width: "100%",
        boxSizing: "border-box",
        resize: "none"
    },
    sendBtnWrapper: {
        display: "flex",
        alignItems: "center"
    },
    sendBtn: {
        padding: "10px 20px",
        backgroundColor: "#0A8180",
        color: "white",
        margin: 20,
        "&[disabled], &:hover": {
            backgroundColor: "#488B86",
        }
    },
    sendIcon: {
        marginRight: 10
    },
    toWrapper: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        flex: 1
    },
    suggestionWrapper: {
        position: "relative",
        "& > input": {
            height: 27
        }
    },
    suggestionBox: {
        position: "absolute",
        top: 32,
        background: "white",
        boxShadow: "0px 3px 10px #00000029",
        padding: 0,
        minWidth: 287,
        maxHeight: 450,
        zIndex: 99,
        overflow: "auto"
    },
    suggestedChip: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        padding: "10px 30px",
        flexDirection: "column"
    },
    successChip: {
        "&:hover": {
            backgroundColor: "#E5ECEC",
            color: "#0A8180",
            "& *": {
                fontWeight: "bold"
            }
        }
    },
    relNotEstabText: {
        "& p:nth-child(1)": {
            color: "##707070"
        },
        "& p:nth-child(2)": {
            color: "#BF4636",
            fontStyle: "italic"
        }
    },
    loader: {
        display: "block",
        margin: "10px auto"
    },
    selectedChip: {
        display: "flex",
        alignItems: "center",
        border: `1px solid ${theme.palette.secondary.mainPurple}`,
        borderRadius: "24px",
        padding: "0px 15px",
        marginRight: 10,
        marginBottom: 10
    },
    selectedChipName: {
        marginRight: 10,
        fontWeight: "bold",
        color: theme.palette.secondary.mainPurple
    },
    selectedChipSubText: {
        fontSize: 14,
        color: "#7896A2"
    },
    selectedCloseBtn: {
        padding: "5px"
    },
    selectedChipCloseIcon: {
        color: "#154157",
        fontSize: 22,
        fontWeight: "bold",
        color: theme.palette.secondary.mainPurple
    },
    notFoundText: {
        fontWeight: "bold",
        color: "#BF4636"
    },
    notFoundChip: {
        flexDirection: "row",
        "&:hover": {
            cursor: "not-allowed",
            backgroundColor: "transparent"
        }
    },
    selectRoot: {
        "&:before, &:after": {
            borderBottom: "none !important"
        },
        "&:hover:before, &:hover:after": {
            borderBottom: "none !important"
        }
    },
    announcementType: {
        fontWeight: "bold",
        textDecoration: "underline",
        fontSize: 18,
        display: "flex",
        paddingBottom: 0,
        justifyContent: "center",
        "&:focus": {
            backgroundColor: "transparent"
        }

    },
    menuItem: {
        display: "flex",
        justifyContent: "left",
    },
    ellipsesIconButton: {
        width: '28px',
        height: '12px',
    },
    ellipsesIconColor: {
        '& #ellipsis-vertical-solid-2': {
            fill: '#000',
        }
    },
    menuMargin: {
        marginTop: '40px',
    },
    warningIconInline:{
        color: "#D1850B",        
        fontSize: '20px',
        margin: '-1px -3px 0px -8px',
        paddingRight: '7px',
    },
    warningIcon: {
        margin: "0px 15px",
        color: "#D1850B"
    },
    closeIcon: {
        fontSize: 14,
        fontWeight: "bold",
        color: "#FFF"
    },
    warningBox: {
        top: 0,
        position: "absolute",
        marginTop: -75,
        backgroundColor: "#FFEDD9",
        padding: "5px 10px",
        borderRadius: 10,
        maxWidth: "70%",
        borderLeft: "10px solid #FFB22D",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #FFB22D"    
    },
    createRelShipStyle: {
        background: theme.palette.primary.sylonBlue,
        color: theme.palette.primary.blue.main,
        paddingInline: '10px',
        height: '50px',
        marginLeft: 'auto',
        marginTop: '-20px'
    },
    createGroupButton: {
        background: theme.palette.primary.sylonBlue,
        color: theme.palette.primary.blue.main,
        paddingInline: '22px',
        height: '40px',
        marginLeft: 'auto',
        marginTop: '-12px'
    }
});