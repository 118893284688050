import React from 'react';
import { Box, makeStyles } from '../MaterialUIComponents';
import { CDN_BASE_URL } from '../../../../constants';
import { ReactComponent as Home } from '../icons/home.svg';
import { ReactComponent as Location } from '../icons/location.svg';
import { ReactComponent as Analytics } from '../icons/analytics.svg';
import { ReactComponent as LocationUserManagement } from '../icons/locationnUser.svg';
import { ReactComponent as Logout } from '../icons/logout.svg';
import { ReactComponent as Roles } from '../icons/roles.svg';
import { ReactComponent as VoiceCalls } from '../icons/call-icon.svg';

import { ReactComponent as Outcomes } from '../icons/outcomes.svg';
import { ReactComponent as Magnifier } from '../icons/magnifier.svg';
import { ReactComponent as Profile } from '../icons/profile.svg';
import { ReactComponent as ProfileIconGray } from '../icons/profile-icon-gray.svg';
import { ReactComponent as Messages } from '../icons/messages.svg';
import { ReactComponent as MessagesSolid } from '../icons/messages-solid.svg';
import { ReactComponent as Announcements } from '../icons/announcements.svg';
import { ReactComponent as PlusRegular } from '../icons/plus-regular.svg';
import { ReactComponent as EllipsisVerticalSolid } from '../icons/ellipsis-vertical-solid.svg';
import { ReactComponent as PencilIcon } from '../icons/pen-to-square-solid-3.svg';

import { ReactComponent as GraduationCap } from '../icons/graduation-cap.svg';
import { ReactComponent as JusticeScales } from '../icons/justice-scales.svg';
import { ReactComponent as VerifiedUser } from '../icons/verifiedUser.svg';
import { ReactComponent as ChevronsRightSolid } from '../icons/chevrons-right-solid.svg';
import { ReactComponent as AttorneyIcon } from '../icons/attorney.svg';
import { ReactComponent as GenericStaffIcon } from '../icons/generic-staff.svg';
import { ReactComponent as OrijinKeywords } from '../icons/orijin-keywords.svg';
import { ReactComponent as bellSolid } from '../icons/bell-solid.svg';
import WarningIcon from '@material-ui/icons/Warning';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import HeadphonesIcon from '@material-ui/icons/Headset';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import BackArrowIcon from '@material-ui/icons/ArrowBackIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import BlockIcon from '@material-ui/icons/Block';
import CancelIcon from '@material-ui/icons/Cancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import BarChartIcon from '@material-ui/icons/BarChart';

import TextsmsIcon from '@mui/icons-material/Textsms';

import { ReactComponent as CircleEllipsisVerticalSolid } from '../icons/circle-ellipsis-vertical-solid.svg';
import { ReactComponent as EyeSlashRegular } from '../icons/eye-slash-regular.svg';
import {
  ContactsIcon,
  ErrorIcon,
  TabletAndroidIcon,
  ExpandLess,
  Search,
  FiberManualRecordIcon,
  AddIcon,
  GroupAdd,
  GroupIcon,
  UserIcon,
  FilterAltIcon,
  LaunchIcon,
  EventNoteIcon,
} from '../MaterialUIIcons';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const LOGOS = CDN_BASE_URL + '/assets/logo/';
const PNG_LOGOS = `./assets/logo/`;

export const icons = {
  APDSTagline: { src: PNG_LOGOS + `APDS-tagline-blue.png`, alt: 'Analytics Icon', title: 'Analytics' },
  APDSTaglineBlue: { src: PNG_LOGOS + `APDS-tagline-blue.png`, alt: 'Analytics Icon', title: 'Analytics' },
  APDSWhiteLogoIconOnly: { src: PNG_LOGOS + `apds-logo.png`, alt: 'APDS Logo White', title: 'Apds Logo White' },
  APDSFullLogoWhite: {
    src: PNG_LOGOS + `apds-logo-horizontal-white.png`,
    alt: 'APDS Full Logo White',
    title: 'Apds Full Logo White',
  },
  OrijinFullLogoWhite: {
    src: PNG_LOGOS + `Orijin-former-whitelogo.png`,
    alt: 'Orijin Full Logo White',
    title: 'Orijin Full Logo White',
  },
  OrijinThreeDots: {
    src: PNG_LOGOS + `orijin-three-dots.png`,
    alt: 'orijin-three-dots.png',
    title: 'orijin-three-dots.png',
  },
  Home: { element: Home, alt: 'Home Icon', title: 'Home' },
  Location: { element: Location, alt: 'Location Icon', title: 'Location' },
  Analytics: { element: Analytics, alt: 'Analytics Icon', title: 'Analytics' },
  LocationUserManagement: {
    element: LocationUserManagement,
    alt: 'Location & User Management Icon',
    title: 'Location & User Management',
  },

  VoiceCalls: { element: VoiceCalls, alt: 'Voice Calls Icon', title: 'Voice Calls' },
  GenericStaffIcon: { element: GenericStaffIcon, alt: 'Generic Staff Icon', title: 'Generic Staff' },
  OrijinKeywords: { element: OrijinKeywords, alt: 'Orijin Keywords Icon', title: 'Orijin Keywords' },
  Roles: { element: Roles, alt: 'Roles Icon', title: 'Roles' },
  FiberManualRecordIcon: { element: FiberManualRecordIcon, alt: '', title: '' },
  Messages: { element: Messages, alt: 'Messages Icon', title: 'Messages' },
  MessagesSolid: { element: MessagesSolid, alt: 'MessagesSolid Icon' },
  Announcements: { element: Announcements, alt: 'Announcements Icon' },
  AddIcon: { element: PlusRegular, alt: 'AddIcon Icon' },
  Ellipsis: { element: EllipsisVerticalSolid, alt: 'Ellipsis Icon' },
  Outcomes: { element: Outcomes, alt: 'Outcomes & Progress Icon', title: 'Outcomes & Progress' },
  Logout: { element: Logout, alt: 'Logout Icon', title: 'Logout' },
  Profile: { element: Profile, alt: 'Profile Icon', title: 'Profile' },
  ProfileIconGray: { element: ProfileIconGray, alt: 'ProfileIconGray Icon' },
  Magnifier: { element: Magnifier, alt: 'Magnifier Icon', title: 'Magnifier' },
  GraduationCap: { element: GraduationCap, alt: 'GraduationCap Icon', title: 'GraduationCap' },
  JusticeScales: { element: JusticeScales, alt: 'JusticeScales Icon', title: 'JusticeScales' },
  VerifiedUser: { element: VerifiedUser, alt: 'VerifiedUser Icon', title: 'VerifiedUser' },
  ChevronsRightSolid: { element: ChevronsRightSolid, alt: 'ChevronsRightSolid Icon' },
  ExpandLess: { element: ExpandLess, alt: 'ExpandLess Icon' },
  SearchIcon: { element: Search, alt: 'Search Icon' },
  bellSolid: { element: bellSolid, alt: 'bellSolid Icon' },
  CircleEllipsisVerticalSolid: { element: CircleEllipsisVerticalSolid, alt: 'CircleEllipsisVerticalSolid Icon' },
  ExpandLess: { element: ExpandLess, alt: 'ExpandLess Icon' },
  SearchIcon: { element: Search, alt: 'Search Icon' },
  WarningIcon: { element: WarningIcon, alt: 'WarningIcon Icon' },
  SendIcon: { element: SendIcon, alt: 'SendIcon Icon' },
  CloseIcon: { element: CloseIcon, alt: 'CloseIcon Icon' },
  VerifiedUserIcon: { element: VerifiedUserIcon, alt: 'Verified User Icon' },
  CheckIcon: { element: CheckIcon, alt: 'Check Icon' },
  AddIcon: { element: AddIcon, alt: 'Add Icon' },
  EyeSlashRegular: { element: EyeSlashRegular, alt: 'Hidden Icon' },
  PencilIcon: { element: PencilIcon, alt: 'Pencil Icon' },
  GroupAdd: { element: GroupAdd, alt: 'Add Group Icon' },
  GroupIcon: { element: GroupIcon, alt: 'Group Icon' },
  UserIcon: { element: UserIcon, alt: 'User Icon' },
  InfoOutlined: { element: InfoOutlined, alt: 'Info Outlined' },
  KeyIcon: { element: VpnKeyIcon, alt: 'Key Icon' },
  HeadphoneIcon: { element: HeadphonesIcon, alt: 'Headphone icon' },
  RefreshIcon: { element: RefreshIcon, alt: 'Refresh Icon' },
  ExportIcon: { element: SaveAltIcon, alt: 'Export Icon' },
  AddCircleIcon: { element: AddCircleIcon, alt: 'Add Circle Icon' },
  DeleteIcon: { element: DeleteIcon, alt: 'Delete Icon' },
  BackArrowIcon: { element: BackArrowIcon, alt: 'Back Arrow Icon' },
  MicIcon: { element: MicIcon, alt: 'Mic Icon', title: 'Mic On', id: 'mic-on-icon' },
  MicOffIcon: { element: MicOffIcon, alt: 'Mic Off Icon', title: 'Mic Off', id: 'mic-off-icon' },
  BlockIcon: { element: BlockIcon, alt: 'Block Icon' },
  CancelIcon: { element: CancelIcon, alt: 'Cancel Icon' },
  NoRecordingIcon: { element: RadioButtonCheckedIcon, alt: 'No Recording Icon', title: 'No Recording' },
  FilterAltIcon: { element: FilterAltIcon, alt: 'Filter Icon' },
  LaunchIcon: { element: LaunchIcon, alt: 'Launch Icon' },
  ArrowBackIosNewIcon: { element: ArrowBackIosNewIcon, alt: 'Arrow Back Icon' },
  TabletAndroidIcon: { element: TabletAndroidIcon, alt: 'Tablet Android Icon' },
  ContactsIcon: { element: ContactsIcon, alt: 'Contacts Icon' },
  ArrowRightIcon: { element: ArrowRightAltIcon, alt: 'Arrow Right Icon' },
  ErrorIcon: { element: ErrorIcon, alt: 'Error Icon' },
  AttorneyIcon: { element: AttorneyIcon, alt: 'Attorney Icon' },
  EventNoteIcon: { element: EventNoteIcon, alt: 'Event Note Icon' },
  BarChartIcon: { element: BarChartIcon, alt: 'Bar Chart Icon' },
  TextsmsIcon: { element: TextsmsIcon, alt: 'Text SMS Icon' },
};

export const CustomIcons = (props) => {
  const { icon, alt, title } = props;
  if (!icon) return <></>;

  const attribute = {
    ...props,
    alt: alt || icon.alt,
    title: title || icon.title,
  };

  if (icon.src) {
    attribute.src = icon.src;
    return <img {...attribute} />;
  } else if (icon.element) {
    const Element = icon.element;
    return <Element {...attribute} />;
  } else {
    return <></>;
  }
};

CustomIcons.defaultProps = {
  icon: icons.home,
  className: '',
  alt: '',
  title: '',
};

const useStyle = makeStyles((theme) => ({
  borderStyleIcon: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    background: theme.palette.primary.teal.main, // '#E5F0F6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 10px 10px 0px',
  },
}));

export const GetIconWithBorder = ({ classes, width = 24, height = 16, fill = '#FFF', icon }) => {
  const styleClass = useStyle();

  if (!Boolean(icon)) {
    return <></>;
  }
  return (
    <Box className={styleClass.borderStyleIcon}>
      <CustomIcons
        icon={icon} // className={this.props.classes.customIconsStyle}
        style={{ width, height, fill }}
      />
    </Box>
  );
};
