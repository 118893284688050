import React from "reactn";
import { TextField, InputAdornment, Menu, MenuItem, Box, Button, FormControl, Select, InputLabel, IconButton } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import FilterListSharpIcon from '@material-ui/icons/FilterListSharp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';

import styles from './index.styles';
import { withStyles } from "@material-ui/styles";
import { COMMUNICATION_FILTER } from "../../../../../constants";
import { isPermitted, PermissionControl } from '../../../../permission-control/permission-control'

const Filter = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [debounceRef, setDebounceRef] = React.useState(null);
    const [filters, setFilters] = React.useState(props.defaultFilters ? props.defaultFilters : [COMMUNICATION_FILTER.Communication]);
    const [filterData, setFilterData] = React.useState(props.filters || {
        searchTerm: "",
        location: "",
        people: "",
        eventType: "",
        status: ""
    });
    const { classes } = props;

    const onChangeHandler = (value, fieldKey) => {
        const _data = Object.assign({}, filterData);
        _data[fieldKey] = value;
        if (debounceRef) {
            clearTimeout(debounceRef);
        }
        setDebounceRef(setTimeout(
            () => {
                if (props.filterUpdateHandler) {
                    props.filterUpdateHandler(_data);
                }
            }, 1000
        ));
        setFilterData(_data);
    };

    const handleMenuButton = (event = {
        currentTarget: null
    }) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * @name isAllowed
     * @param {number} filterID
     * @desc Checks if filter is required by parent or not.
     * @return {boolean} 
     */
    const isAllowed = (filterID) => {
        if (!props.allowedFilters || props.allowedFilters.indexOf(filterID) > -1) {
            return true;
        }
        return false;
    };

    /**
     * @name toggleFilters
     * @param {number} filterID
     * filtering process.
     * @desc Toggles filter based upon collection created.
     * @return {void}
     */
    const toggleFilter = (filterID) => {
        const newFilterIndex = filters.indexOf(filterID);
        if (newFilterIndex > -1) {
            if (filters.length > 1) {
                filters.splice(newFilterIndex, 1);
            }
        } else {
            filters.push(filterID);
        };
        setFilters(filters);
    };
    return (
        <PermissionControl
            permissionName="View_Student_Messages_In_Other_Relationships">
            <Box display="flex" alignItems="center" width="100%">
                {filters.map(
                    (filter) => (
                        <>
                            {filter === COMMUNICATION_FILTER.EventType && (
                                <Box className={classes.fieldWrapper}>
                                    <Autocomplete
                                        onChange={(event, value) => {
                                            if (value) {
                                                onChangeHandler(value.id, "eventType");
                                            } else {
                                                onChangeHandler("", "eventType");
                                            };
                                        }}
                                        options={props.eventTypes || []}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} className={classes.filterField} label="Events" variant="outlined" />}
                                    />
                                </Box>
                            )}
                            {filter === COMMUNICATION_FILTER.Communication && (
                                <Box className={classes.fieldWrapper}>
                                    <TextField
                                        label="Search Term"
                                        value={filterData.searchTerm}
                                        className={classes.filterField}
                                        onChange={(event) => onChangeHandler(event.target.value, "searchTerm")}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: filterData.searchTerm && (
                                                <InputAdornment position="start">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => onChangeHandler("", "searchTerm")} >
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />

                                </Box>
                            )}
                            {filter === COMMUNICATION_FILTER.People && (isPermitted(props.cbacConfigFlag, props.permissions, "View_User_List") || isPermitted(props.cbacConfigFlag, props.permissions, "Access_Staff_List")) && (
                                <Box className={classes.fieldWrapper}>
                                    <Autocomplete
                                        onChange={(event, value) => {
                                            if (value) {
                                                onChangeHandler(value.userName, "people");
                                            } else {
                                                onChangeHandler("", "people");
                                            };
                                        }}
                                        options={props.users || []}
                                        getOptionLabel={(option) => option.displayName}
                                        renderInput={(params) => <TextField {...params} className={classes.filterField} label="User" variant="outlined" />}
                                    />
                                </Box>
                            )}
                            {filter === COMMUNICATION_FILTER.Location && (
                                <Box className={classes.fieldWrapper}>
                                    <Autocomplete
                                        onChange={(event, value) => {
                                            if (value) {
                                                onChangeHandler(value.locationId, "location");
                                            } else {
                                                onChangeHandler("", "location");
                                            };
                                        }}
                                        options={props.locations || []}
                                        getOptionLabel={(option) => option.locationName}
                                        renderInput={(params) => <TextField {...params} className={classes.filterField} label="Location" variant="outlined" />}
                                    />
                                </Box>

                            )}
                            {filter === COMMUNICATION_FILTER.Status && (
                                <Box className={classes.fieldWrapper}>
                                    <Select
                                        endAdornment={filterData.status.toString() && (<InputAdornment className={classes.statusAdornment} position="end">
                                        <IconButton
                                            size="small"
                                            onClick={() => onChangeHandler("", "status")} >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>)}
                                        value={filterData.status}
                                        className={classes.filterField}
                                        variant="outlined"
                                        onChange={(event, value) => {
                                            if (value) {
                                                onChangeHandler(event.target.value, "status");
                                            } else {
                                                onChangeHandler("", "status");
                                            };
                                        }}
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>
                                            Status
                                         </MenuItem>
                                        <MenuItem value={true}>Not Hidden</MenuItem>
                                        <MenuItem value={false}>Hidden</MenuItem>
                                    </Select>
                                </Box>

                            )}
                        </>
                    )
                )}

                <Box height="100%">
                    <Button
                        color="primary"
                        className={classes.filterBtn}
                        onClick={handleMenuButton}>
                        <FilterListSharpIcon /> &nbsp; Filter
                </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={!!anchorEl}
                        onClose={() => handleMenuButton()}

                    >

                        {isAllowed(COMMUNICATION_FILTER.EventType) && (
                            <MenuItem onClick={() => {
                                toggleFilter(COMMUNICATION_FILTER.EventType);
                                handleMenuButton();
                            }}>Events</MenuItem>
                        )}
                        {isAllowed(COMMUNICATION_FILTER.SearchTerm) && isPermitted(props.cbacConfigFlag, props.permissions, "View_Student_Messages_In_Other_Relationships") && (
                            <MenuItem onClick={() => {
                                toggleFilter(COMMUNICATION_FILTER.Communication);
                                handleMenuButton();
                            }}>Communications</MenuItem>
                        )}
                        {isAllowed(COMMUNICATION_FILTER.People) && (isPermitted(props.cbacConfigFlag, props.permissions, "View_User_List") || isPermitted(props.cbacConfigFlag, props.permissions, "Access_Staff_List")) && (
                            <MenuItem onClick={() => {
                                toggleFilter(COMMUNICATION_FILTER.People);
                                handleMenuButton();
                            }}>User</MenuItem>
                        )}
                        {isAllowed(COMMUNICATION_FILTER.Location) && (
                            <MenuItem onClick={() => {
                                toggleFilter(COMMUNICATION_FILTER.Location);
                                handleMenuButton();
                            }}>Location</MenuItem>
                        )}
                        {isAllowed(COMMUNICATION_FILTER.Status) && (
                            <MenuItem onClick={() => {
                                toggleFilter(COMMUNICATION_FILTER.Status);
                                handleMenuButton();
                            }}>Status</MenuItem>
                        )}
                    </Menu>
                </Box>
            </Box>
        </PermissionControl >
    );
};

export default withStyles(styles)(Filter);