import React from "react";
import { FolderNav as FolderNavComponent } from '@orijinworks/frontend-commons';
import { Box, withStyles } from "@material-ui/core";
import styles from './index.style';
import ManageLocations from "../manage-locations/manageLocations";
import ManageRoleAndPermission from "../manage-roles-permissions/manageRoleAndPermission";
import CheckEvents from "../users/checkEvents";
import { VerifiedUserIcon} from "../../v2/shared/components/MaterialUIIcons";
import { EventNote, LocationOnOutlined } from "@material-ui/icons";

const SuperAdminModule = ({classes}) => {
  return (
    <Box className={classes.folderWrapper}>
      <FolderNavComponent
        defaultActiveTab="1"
        color="var(--yellow-main)"
        tabs={[
          {
            label: 'Location Management',
            value: '1',
            component: () => (
              <ManageLocations />
            ),
            Icon: LocationOnOutlined,
          },
          {
            label: 'Roles',
            value: '2',
            component: () => (
              <ManageRoleAndPermission />
            ),
            Icon: VerifiedUserIcon,
          },
          {
            label: 'Check Events',
            value: '3',
            component: () => (
              <CheckEvents />
            ),
            Icon: EventNote,
          },
        ]}
      />
    </Box>
  );
};

export default withStyles(styles)(SuperAdminModule);