export default (theme) => ({
    daysSelectionList: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 16
    },
    daysSelectionItem: {
        width: "initial",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        height: 40,
        fontSize: 18,
        color: theme.palette.primary.navy,
        fontWeight: "bold",
        "&:first-child": {
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20
        },
        "&:last-child": {
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20
        }
    },
    activeDay: {
        backgroundColor: theme.palette.primary.navy,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.primary.navy
        }
    },
    dateSnackbar: {
        display: 'flex',
        backgroundColor: theme.palette.colors.yellow.pale,
        border: `1px solid ${theme.palette.colors.yellow.dark}`,
        borderRadius: 12,
        padding: '8px 16px 8px 16px',
        width: '65%',
    },
    dateAlert: {
        fontSize: '16px',
        fontWeight: '400',
        color: theme.palette.colors.grey110,
        lineHeight: '24px'
    },
    warningIcon: {
        color: theme.palette.colors.yellow.dark,
        margin: '6px 15px 0 0',
    },
    errorIcon: {
        margin: '6px 15px 0 0',
        cursor: 'pointer',
    }
});