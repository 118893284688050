import { color, lineHeight } from 'styled-system';

export default (theme) => ({
  container: {
    padding: '16px 12px 16px 12px',
    borderRadius: '12px',
    border: `1px solid ${theme.palette.greyScale.greyBackground}`,
    width: '696px',
    height: '322px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  messageBox: {
    padding: '16px',
    gap: '12px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'flex-start',
    opacity: 1,
  },
  messageBoxColored: {
    padding: '16px',
    gap: '8px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'flex-start',
    opacity: 1,
    backgroundColor: theme.palette.colors.blue.pale,
  },
  messageHeader: {
    display: 'flex',
  },
  timestamp: {
    '&.MuiTypography-root':{
      width: '35px',
      height: '9px',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '22px',
      color: theme.palette.greyScale.darkSilver,  
    }
  },
  sender: {
    '&.MuiTypography-root':{
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '22px',
      whiteSpace: 'nowrap',
      marginLeft: '8px', // Added margin to separate it from timestamp
    }
  },
  message: { 
    '&.MuiTypography-root':{
      fontWeight: 400,
      fontSize: '12px',
      display: 'inline',
      lineHeight: '22px',
      wordBreak: 'break-word',
      flex: 1,
    }
  },
  highlighted: {
    color: theme.palette.colors.redMain,
  },
});
