import React from 'react';

import {
    Dialog,
    DialogContent,
    DialogActions,
    AppBar,
    Toolbar,
    Typography,
    TextField,
    Button,
    IconButton,
    InputLabel,
    Select,
    FormControl,
    OutlinedInput,
    FormHelperText,

} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from '@material-ui/icons/Close';
import { LOCATIONS } from '../../../../constants';
import { getAvailableAssignedRoles, addRolesAssignment } from '../../../../util/APIUtils';
import styles from './index.styles';

const useStyles = makeStyles(theme => styles(theme));

const RoleAssignmentDialog = ({ open, handleClose, userDetail, submitAssignment }) => {
    const classes = useStyles();
    const [availableRoles, setAvailableRoles] = React.useState([]);

    let sortedLocation = [];
    sortedLocation = LOCATIONS
        .filter((l) => { return l.locationTypeId === 3 || l.locationTypeId === parseInt(process.env.REACT_APP_APDS_JURISDICTION_ID) })
        .sort(function (a, b) {
            var keyA = a.locationName,
                keyB = b.locationName;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });

    const getAvailableRoles = async (location) => {
        if (location) {
            const roles = await getAvailableAssignedRoles(location)
            roles.sort((a, b) => {
                if (a.roleName < b.roleName) return -1
                return a.roleName > b.roleName ? 1 : 0
            })
            setAvailableRoles(roles)
        }
    };
    React.useEffect(() => { setAvailableRoles([]) }, [])
    return (
        <Dialog
            fullWidth open={open} onClose={handleClose}
        >
            <Formik

                initialValues={{
                    location: "",
                    roles: "",
                }}
                validate={(values) => {
                    const errors = {};

                    if (values.location.length < 1) {
                        errors["location"] = "At least 1 facility is required";
                    }
                    if (values.roles.length < 1) {
                        errors["roles"] = "At least 1 role is required";
                    }
                    return errors;
                }}
                onSubmit={(values, userDetails) => {
                    submitAssignment(values, userDetails)
                    handleClose();

                }}
            >
                {
                    ({ values, handleChange, handleSubmit, setFieldValue, errors, handleBlur, touched }) => (
                        <form
                            id="main-form"
                            onSubmit={handleSubmit}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <Typography variant="h6" className={classes.title}>
                                        Assign Roles
                                    </Typography>
                                    <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>

                            <DialogContent className={classes.dialogContent}>
                                <Typography>
                                    LOCATIONS
                                </Typography>
                                <FormControl variant="outlined" className={classes.field} error={errors.location && touched.location}>
                                    <InputLabel>
                                        Facility
                                    </InputLabel>
                                    <Select
                                        label="location"
                                        name="location"
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            getAvailableRoles(event.target.value)
                                            handleChange(event)
                                        }}
                                    >
                                        {sortedLocation
                                            .map(
                                                (location) => (
                                                    <MenuItem

                                                        value={location.locationId}>
                                                        {location.locationName}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                    {errors.location && touched.location && (
                                        <FormHelperText>{errors.location}</FormHelperText>
                                    )}
                                </FormControl>
                                <Typography style={{ paddingTop: '12px' }}>
                                    ROLE
                                </Typography>
                                <FormControl variant="outlined" className={classes.field} error={errors.roles && touched.roles}>
                                    <InputLabel>
                                        Roles
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        onChange={handleChange}
                                        disabled={!availableRoles.length}
                                        label="roles"
                                        name="roles"
                                    >
                                        {availableRoles
                                            .map(
                                                (roles) => (
                                                    <MenuItem
                                                        onClick={() => {
                                                            const roles = values.roles;
                                                            setFieldValue("roles", roles);
                                                        }}
                                                        value={roles.roleId}>
                                                        {roles.roleName}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                    {errors.roles && touched.roles && (
                                        <FormHelperText>{errors.roles}</FormHelperText>
                                    )}
                                </FormControl>
                            </DialogContent>

                            <DialogActions className={classes.dialogActions}>
                                <Button onClick={handleClose} color="primary">Cancel</Button>
                                <Button
                                    // onClick={handleClose}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    className={classes.saveBtn}
                                >Save</Button>
                            </DialogActions>
                        </form>
                    )
                }
            </Formik >

        </Dialog >
    );
}

export default RoleAssignmentDialog;