export default (theme) => ({
  errorSection: {
    color: "#A30000",
    display: "flex",
    fontSize: 16,
    letterSpacing: "0.29px",
    lineHeight: 1.5,
    margin: "12px 0",

    "& svg": {
      color: "#C9190B",
      fontSize: "35px",
      height: "34.5px",
      margin: "0 15px auto 0",
      width: "40px",
    },
  },
  errorList: {
    margin: "4px 0 0",
    padding: 0,

    "& li": {
      listStyleType: "none",
    },
  },
});
