export default theme => ({
  roleNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '60%'
  },
  roleName: {
    fontSize: 25,
    margin: 0,
    marginRight: 5
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    marginBottom: 15
  },
  switchWrapper: {
    marginRight: 45
  },
  switchLabel: {
    fontSize: 13,
    marginRight: 5
  },
  duplicateIcon: {
    width: 19
  },
});