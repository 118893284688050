import React from 'react';
import { Box, Typography, Grid, Divider, Switch, withStyles } from '@material-ui/core';
import SecurityIcon from '@material-ui/icons/Security';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styles from './communication-security-settings.style';
import useCommunicationSecuritySettingsHook from './useCommunicationSecuritySettingsHook';
import DisableVoiceIdRegistrationModal from '../disable-voice-id-registration-modal/disable-voice-id-registration-modal';

const CommunicationSecuritySettings = ({ classes, locationId }) => {
  const {
    modalRef,
    isVoiceIdRegistrationEnabled,
    handleChangeVoiceIdRegistrationAttribute,
    handleDisableVoiceIdRegistrationModal,
    disableVoiceIdRegistrationConfirmation,
  } = useCommunicationSecuritySettingsHook({
    locationId,
  });

  if (Object.keys(isVoiceIdRegistrationEnabled).length === 0) {
    return null;
  }

  return (
    <>
      <Grid item xs={12}>
        <Box id="header" className={classes.header} data-testid="header">
          <SecurityIcon className={classes.icon} data-testid="security-icon" />
          <Typography className={classes.heading} variant="h2" data-testid="heading">
            Communications Security Settings
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.dividerRow} data-testid="divider-row">
          <Divider id="divider" className={classes.divider} data-testid="divider" />
        </Box>
      </Grid>

      <Grid item xs={12} className={classes.row}>
        <Box className={classes.stableTableCellOne} data-testid="stable-table-cell-one">
          <Typography variant="body1" className={classes.label} data-testid="voice-id-required-heading">
            Voice ID Required
          </Typography>
          <Typography variant="body2" className={classes.subText} data-testid="voice-id-required-subtext">
            Residents will be required to record their Voice ID before they can place calls to their contacts. Residents will
            still be able to place calls to privileged contacts and hotlines.
          </Typography>
        </Box>

        <Box className={classes.stableTableCellTwo} data-testid="stable-table-cell-two">
          <Switch
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: isVoiceIdRegistrationEnabled.attributeValue ? classes.trackOn : classes.trackOff,
              checked: classes.checked,
            }}
            inputProps={{ 'aria-label': 'limit contact numbers' }}
            onChange={handleChangeVoiceIdRegistrationAttribute}
            checked={isVoiceIdRegistrationEnabled.attributeValue === true}
            name="checkedA"
            icon={<CancelRoundedIcon className={classes.thumbIcon} />}
            checkedIcon={<CheckCircleIcon className={classes.thumbIcon} />}
            data-testid="voice-id-required-switch"
          />
        </Box>
      </Grid>

      <DisableVoiceIdRegistrationModal
        ref={modalRef}
        open={false}
        onContinue={disableVoiceIdRegistrationConfirmation}
        onClose={handleDisableVoiceIdRegistrationModal}
        data-testid="disable-voice-id-modal"
      />
    </>
  );
};

export default withStyles(styles)(CommunicationSecuritySettings);
