import React, { useGlobal, useState, useEffect } from 'reactn';
import { LOCATIONS, ROLES, setFacilityMessagePermission } from '../../constants';
import { fetchPermissions, getPermittedFacilities, noLocationAssociatedPermission } from '../../util/APIUtils';
import { Loader } from '@orijinworks/frontend-commons';
import { handleLocationSettingsPermission, handleMessageFacilityPermission } from '../users/learner/transcript/utils';

export const CbacContainer = React.memo(props => {
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const [global, setGlobal] = useGlobal();
  const { authorize, getRoutes } = props;

  useEffect(() => {
    async function getPermissions() {
      const { cbacConfigFlag, permissions } = await fetchPermissions();
      if (cbacConfigFlag) {
        const { permissions: permissionIds, roleTypeNames } = await noLocationAssociatedPermission();
        handleMessageFacilityPermission(LOCATIONS, setGlobal, global);
        handleLocationSettingsPermission(LOCATIONS, setGlobal, global, roleTypeNames.includes(ROLES.SUPER_ADMIN));
        setGlobal(
          {
            permissions,
            permissionIds,
            cbacConfigFlag,
            isSuperAdmin: roleTypeNames.includes(ROLES.SUPER_ADMIN),
            isAttorney: roleTypeNames.includes(ROLES.ATTORNEY),
            isTeacher: roleTypeNames.includes(ROLES.TEACHER),
            isFacilityAdmin: roleTypeNames.includes(ROLES.STAFF),
            isMessagingOnly: roleTypeNames.includes(ROLES.MESSAGING_ONLY),
            roles: roleTypeNames
          },
          () => {
            setGlobal({ routes: getRoutes() }, setPermissionsLoaded(true));
          }
        );
      } else {
        setPermissionsLoaded(true);
        setGlobal({
          showMessageTile: true
        });
      }
    }
    if (authorize) {
      getPermissions();
    }
  }, [authorize, setPermissionsLoaded, fetchPermissions, noLocationAssociatedPermission]);

  if (!authorize) {
    return null;
  }

  if (!permissionsLoaded) {
    return <Loader isLoading={true} />;
  }

  return props.children;
});

export default CbacContainer;
