import { useUnleashClient, useUnleashContext } from '@unleash/proxy-client-react';
import { useEffect } from 'react';

/**
 * Custom hook to get the unleash client
 * 
 * @param {string} username - The username of the logged in user
 * @param {number} userId - The user id of the logged in user
 * @param {object} selectedLocation - The selected location 
 * @returns {function} getClient - The function to get the unleash client
 */
const useUnleashClientHook = ({ username, userId = 0, selectedLocation = {} }) => {
  const unleashClient = useUnleashClient();
  const updateContext = useUnleashContext();

  /**
   * Effect to update the unleash context with the user id, username and facility code
   */
  useEffect(() => {
    updateContext({
      userId: parseInt(userId),
      username,
      facilityCode: selectedLocation.locationShortCode,
    });
  }, [userId, username, selectedLocation, updateContext]);

  /**
   * Function to get the unleash client
   * 
   * @returns {object} unleashClient - The unleash client
   */
  const getClient = () => unleashClient;

  return { getClient };
};

export default useUnleashClientHook;
