import { CustomTheme } from '@orijinworks/frontend-commons';

export default theme => ({
  heading: {
    display: 'block',
    fontSize: 16,
    color: CustomTheme.palette.navy.main,
    marginBottom: 10
  },
  prompt: {
    display: 'block',
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: 30
  },
  appBarBaseRules: {
    boxShadow: 'none',
    fontSize: 22,
    display: 'flex',
    justifyContent: 'center',
    height: 46
  },
  warningAppBar: {
    backgroundColor: 'rgba(208, 2, 27, 0.1)',
    color: '#d0021b',
  },
  dialog: {
    width: 360
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 25,
    height: 360
  },
  userRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25,

    '&:last-of-type': {
      marginBottom: 10
    }
  },
  userIcon: {
    color: '#5390e5'
  },
  username: {
    display: 'block',
    fontSize: 14,
    marginLeft: 13,
    wordBreak: 'break-word',
    wordWrap: 'break-word',
  },
  closeIcon: {
    paddingLeft: 0
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    margin: 0
  },
});