import React from "react";

const TrophyIcon = props => {
  const { size, stroke } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1120" data-name="Rectangle 1120" width="24.571" height="24" fill="none" stroke={stroke} strokeWidth="2"/>
        </clipPath>
        <clipPath id="clip-Achievements">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Achievements" clipPath="url(#clip-Achievements)">
        <g id="Group_1210" data-name="Group 1210" transform="translate(-0.285)">
          <g id="Group_1209" data-name="Group 1209" clipPath="url(#clip-path)">
            <path id="Path_512" data-name="Path 512" d="M11.915,2h8.6a1.9,1.9,0,0,1,1.9,1.9V9.577a7.391,7.391,0,0,1-7.1,7.432A7.391,7.391,0,0,1,8.2,9.577V3.084" transform="translate(-3.018 -0.736)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Path_513" data-name="Path 513" d="M21.622,34.071v.061a1.145,1.145,0,0,1-1.144,1.144H12.322a1.144,1.144,0,0,1-1.144-1.144v-.061" transform="translate(-4.114 -12.54)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <line id="Line_203" data-name="Line 203" y2="6.464" transform="translate(12.285 16.273)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <line id="Line_204" data-name="Line 204" y2="6.026" transform="translate(23.307 4.405)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <line id="Line_205" data-name="Line 205" y1="6.026" transform="translate(1.264 4.404)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

TrophyIcon.defaultProps = {
  size: 24,
  stroke: "inherit"
}

export default TrophyIcon;
