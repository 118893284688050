import { JURISDICTION_WITH_CHILDREN, LOCATIONS } from '../../constants';

class LocationPickerService {
  constructor() {}

  /**
   * Check if location exists in collection
   * @param {*} locationId
   * @param {*} collection
   * @returns
   */
  getIndexOfLocation = (locationId, collection) => {
    return collection.findIndex(location => location.locationId === locationId);
  };

  getSortedLocations = locations => {
    const sortedLocations = locations.sort(function(a, b) {
      var keyA = a.locationName,
        keyB = b.locationName;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    return sortedLocations;
  };

  /**
   * Checks if all sections are expanded or collapsed
   * @param {*} collection
   * @returns
   */
  isAllCollapsed = collection => {
    let isExpanded = false;
    for (const element of collection) {
      if (element.isExpanded) {
        isExpanded = true;
        break;
      }
      for (const _facility of element.children || []) {
        if (_facility.isExpanded) {
          isExpanded = true;
          break;
        }
      }
    }
    return isExpanded;
  };

  getLocationData = setAllSectionExpanded => {
    let locationData = (JURISDICTION_WITH_CHILDREN || []).filter(
      item => item.locationTypeId === parseInt(process.env.REACT_APP_APDS_JURISDICTION_ID)
    );
    if (locationData.length === 0) {
      // JURISDCITION NOT FOUND
      const facilities = LOCATIONS.filter(item => item.locationTypeId === 3);
      facilities.map(_f => {
        _f['isExpanded'] = false;
        _f['children'] = this.getSortedLocations(
          LOCATIONS.filter(_hu => {
            return _hu.parentLocationId === _f.locationId;
          })
        );
        return _f;
      });

      locationData.push({
        locationName: 'No Jurisdiction Found',
        locationId: 1,
        children: facilities
      });
    } else {
      // MAPPING WITH JURISDICTION
      locationData = locationData.map(_j => {
        _j.children = (_j.children || []).map(_f => {
          _f['isExpanded'] = false;
          _f['children'] = this.getSortedLocations(
            LOCATIONS.filter(_hu => {
              return _hu.parentLocationId === _f.locationId;
            })
          );
          return _f;
        });
        return _j;
      });
    }

    // SORTING & EXPANDING ALL JURISDICTION
    locationData.map(location => {
      // IF MORE THAN 1 JURISDICTION
      // THEN EXPAND ALL JURISDICTION
      // SECTIONS
      if (locationData.length > 1) {
        location['isExpanded'] = true;
        if (setAllSectionExpanded) {
          setAllSectionExpanded(true);
        }
      }
      return location;
    });

    return this.getSortedLocations(locationData);
  };

  /**
   * Method to toggle the expansion of particular section
   * @param {*} locationId
   * @param {*} locationType
   * @param {*} facilityId
   * @param {*} mainCollection
   * @param {*} searchCollection
   * @param {*} isSearchResult
   */
  toggleSection = (locationId, locationType, facilityId, mainCollection, searchCollection, isSearchResult) => {
    const _location = (isSearchResult ? searchCollection : mainCollection).find(
      _location => _location.locationId === locationId
    );
    if (locationType === 'jurisdiction') {
      _location.isExpanded = !_location.isExpanded;
    } else if (locationType === 'facility') {
      _location.children = _location.children.map(_facility => {
        if (_facility.locationId === facilityId) {
          _facility.isExpanded = !_facility.isExpanded;
        }
        return _facility;
      });
    }
  };

  /**
   * Method to collapse all sections
   * @param {*} mainCollection
   * @param {*} searchCollection
   * @returns
   */
  collapseAll = (mainCollection, searchCollection, isSearchResult, isExpanded) => {
    const _data = isSearchResult ? Object.assign([], searchCollection) : Object.assign([], mainCollection);

    for (const element of _data) {
      element.isExpanded = isExpanded;
      for (const _facility of element.children || []) {
        _facility.isExpanded = isExpanded;
      }
    }

    return _data;
  };

  /**
   * Search algorithm for location picker
   * @param {*} searchText
   * @param {*} collection
   * @returns
   */
  search = (searchText, collection) => {
    const searchResultsTemp = [];
    collection.forEach(_location => {
      _location.children.forEach(_facility => {
        const facilityChildren = [];
        // CHECKING IF FACILITY NAME MATCHES
        if (_facility.locationName.toLowerCase().includes(searchText.toLowerCase())) {
          const locationIndex = this.getIndexOfLocation(_location.locationId, searchResultsTemp);
          if (locationIndex > -1) {
            // IF JURISDICTION NODE ALREADY EXIST
            searchResultsTemp[locationIndex]['children'].push(_facility);
          } else {
            // ELSE CREATED JURISDICTION WITH INITIAL FACILITY
            searchResultsTemp.push({
              locationId: _location.locationId,
              locationName: _location.locationName,
              isExpanded: true,
              children: [_facility]
            });
          }
        } else {
          // NO FACILITY NAME MATCHES
          _facility.children.forEach(_hu => {
            if (_hu.locationName.toLowerCase().includes(searchText.toLowerCase())) {
              // GATHERING ALL MATCHING HUs IN ARRAY
              facilityChildren.push(_hu);
            }
          });

          if (facilityChildren.length > 0) {
            // IF ANY HU MATCHES
            const locationIndex = this.getIndexOfLocation(_location.locationId, searchResultsTemp);
            if (locationIndex > -1) {
              // IF JURISDICTION NODE ALREADY EXIST
              searchResultsTemp[locationIndex]['children'].push({
                locationId: _facility.locationId,
                locationName: _facility.locationName,
                isExpanded: true,
                children: facilityChildren
              });
            } else {
              // ELSE CREATED JURISDICTION WITH INITIAL FACILITY
              searchResultsTemp.push({
                locationId: _location.locationId,
                locationName: _location.locationName,
                isExpanded: true,
                children: [
                  {
                    locationId: _facility.locationId,
                    locationName: _facility.locationName,
                    isExpanded: true,
                    children: facilityChildren
                  }
                ]
              });
            }
          }
        }
      });
    });

    return searchResultsTemp;
  };
}

export default LocationPickerService;
