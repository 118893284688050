import React, { Component, Suspense } from 'reactn';
import {
    Grid,
    withStyles,

} from '@material-ui/core';
import styles from './manageRoleAndPermission.style';
import { withRouter } from 'react-router-dom';
import ManageRoles from './manage-role/index';
import { Loader } from '@orijinworks/frontend-commons';

import {
    getRolesList
} from "../../util/APIUtils";


export class ManageRoleAndPermission extends Component {

    constructor(props) {
        super(props)
        this.state = {
            roles: [],
            showEmptyDataSourceMessage: false
        }
    }

    async componentDidMount() {
        this.setGlobal({
            isLoading: true
        });
        const rolesDataList = await getRolesList()
        this.setState({ roles: rolesDataList, showEmptyDataSourceMessage: true });
        this.setGlobal({
            isLoading: false
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Suspense fallback={<Loader isLoading={true} />}>
                <Grid
                    container
                    item
                    component="div"
                    className={classes.content}>
                    <Grid
                        container
                        item
                        className={classes.tabContent}>
                        <ManageRoles
                            showSnackBar={this.toggleSnackBar}
                            data={this.state.roles}
                            showEmptyDataSourceMessage={this.state.showEmptyDataSourceMessage}
                        />
                    </Grid>
                </Grid>
            </Suspense>
        );
    };
};

export default withStyles(styles)(withRouter(ManageRoleAndPermission));