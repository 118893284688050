import React from 'reactn';

import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  CircularProgress
} from '@material-ui/core'
import Datatable, { MTableToolbar } from 'material-table'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import moment from 'moment';

import FormSubmissionDetailDialog from './FormSubmissionDetailDialog';
import { fetchFormSubmissions } from '../../../util/APIUtils';
import Hoverable from '../../hoverable';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContentRoot: {
    padding: 60,
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  }
}));

const icons = {
  Search: SearchIcon,
  ResetSearch: CloseIcon,
  FirstPage: FirstPageIcon,
  LastPage: LastPageIcon,
  NextPage: NavigateNextIcon,
  PreviousPage: NavigateBeforeIcon,
  Filter: FilterListIcon,
  SortArrow: ArrowDownwardIcon,
  Export: SaveAltIcon,
};

const components = {
  Toolbar: props => (
    <div>
      <MTableToolbar {...props} />
    </div>
  ),
};

const options = {
  search: false,
  sorting: true,
  pageSize: 10,
  filtering: true,
  exportButton: true,
  pageSizeOptions: [10, 30, 50],
  exportAllData: true,
};

const ViewFormsDialog = ({ isOpen, handleClose, user }) => {
  const classes = useStyles();
  const [selectedFormSubmission, setSelectedFormSubmission] = React.useState(null);
  const [isSubmissionDetailDialogOpen, setSubmissionDetailDialogOpen] = React.useState(false);
  const [formSubmissions, setFormSubmissions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const username = `${user.firstName}${user.lastName ? ` ${user.lastName}` : ''}`;

  const getFormsSubmissions = async () => {
    setLoading(true);
    const results = await fetchFormSubmissions(user.userId);
    setFormSubmissions(results);
    setLoading(false);
  }

  React.useEffect(() => {
    if (isOpen) {
      getFormsSubmissions();
    }
  }, [isOpen]);

  const onViewSubmissionClick = rowData => () => {
    setSelectedFormSubmission(rowData);
    setSubmissionDetailDialogOpen(true);
  }

  const onSubmissionDetailDialogClose = () => {
    setSubmissionDetailDialogOpen(false);
  }

  const columns = [
    {
      field: "name",
      title: "Name",
      cellStyle: {
        width: '50%',
      },
    },
    {
      field: "locationName",
      title: "Location",
      render: ({ facilityLocationName, housingUnitLocationName }) => (
        <Hoverable title={
          facilityLocationName ?
            `${facilityLocationName}${housingUnitLocationName ? ` - ${housingUnitLocationName}` : ''}` :
            'N/A'
        }>
          {
            facilityLocationName ?
              `${facilityLocationName}${housingUnitLocationName ? ` - ${housingUnitLocationName}` : ''}` :
              'N/A'
          }
        </Hoverable>
      )
    },
    {
      field: "submissionDate",
      title: "Submission Date",
      render: rowData => (
        <Hoverable title={moment(rowData.submissionDate).format('MM/DD/YYYY [at] hh:mmA')}>
          {moment(rowData.submissionDate).format('MM/DD/YYYY [at] hh:mmA')}
        </Hoverable>
      )
    },
    {
      field: "internalActionField",
      title: "Action",
      editable: false,
      filtering: false,
      export: false,
      render: rowData => (
        <Button color="primary" onClick={onViewSubmissionClick(rowData)}>
          View Submissions
        </Button>
      )
    }
  ];

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {`${username}'s Forms`}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={getFormsSubmissions}>
            <RefreshIcon color="white" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent classes={{
        root: classes.dialogContentRoot
      }}>
        {
          loading ? (
            <div className={classes.loaderWrapper}>
              <CircularProgress />
            </div>
          ) :
            (
              <Datatable
                title="Forms"
                data={formSubmissions}
                columns={columns}
                icons={icons}
                components={components}
                options={options}
              />
            )
        }
      </DialogContent>
      <FormSubmissionDetailDialog
        isOpen={isSubmissionDetailDialogOpen}
        handleClose={onSubmissionDetailDialogClose}
        selectedFormSubmission={selectedFormSubmission}
        username={username}
      />
    </Dialog>
  )
}

export default ViewFormsDialog;
