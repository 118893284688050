import { withStyles } from '@material-ui/styles';
import React, { useGlobal } from 'reactn';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { isPermitted } from '../../../../../../components/permission-control/permission-control';
import { fetchContacts, postAnnouncement, postMessage } from '../../../../../../util/APIUtils';
import {
  rsSetDirectMessages,
  rsSetSelectedContent,
  setNewMessage,
} from '../../../../../core/store/reducers/messageModuleReducer';
import { CustomIcons, icons } from '../../../../../shared/components/custom-icons';
import { Box, CircularProgress, Button, Typography, Snackbar } from '../../../../../shared/components/MaterialUIComponents';
import styles from './send-editor.style';
import {
  ANNOUNCEMENT,
  DIRECT_MESSAGE,
  UNBLOCK_LEARNER_AUTO_REPLY,
  SUCCESS_MESSAGE_SENT,
  FAILED_MESSAGE_SENT,
  RECENT_MESSAGE,
} from '../../../../../services/constants';
import { getMessagingSelectedTabType, transformMsg } from '../../../../../services/utilty';
import withUnleash from '../../../../../core/unleash/withUnleash';
import { UnleashService } from '../../../../../services';
import { emitMessageEvent } from '../../../../../../util/Helpers';
import { POLL_CONFIG } from '../../../../../../constants';

const SendEditor = ({
  unleashProps,
  classes,
  onSend,
  isSendDisabled = false,
  onChange,
  isLoading,
  onComplete,
  resetMsgs,
  setResetMsgs,
  selectedUsers,
}) => {
  const [editorValue, setEditorValue] = React.useState('');
  const [limitError, setLimitError] = React.useState(null);
  const {
    rsActiveThread: activeThread,
    rsSelectedContact: selectedContact,
    rsMessageThread,
    newMessage,
    messageType,
    characterLimit,
  } = useSelector((state) => state.rsMessageModuleStore);
  const [isMessageSending, setMessageSending] = React.useState(false);
  const dispatch = useDispatch();
  const location = useSelector((state) => state.app.selectedLocation);
  const [global, setGlobal] = useGlobal();
  const [messageCharacterLimit, setCharacterLimit] = React.useState({});
  const sendAnnouncement = isPermitted(global.cbacConfigFlag, global.permissions, 'Send_Announcements');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = React.useState(3000);
  const [isSuccessSnackbar, setIsSuccessSnackbar] = React.useState(true);

  const getCharacterLimit = () => {
    if ((selectedContact && selectedContact.threadId) || activeThread === DIRECT_MESSAGE) {
      return {
        minCharacterLimit: characterLimit.messagingMinCharacterLimit,
        maxCharacterLimit: characterLimit.messagingMaxCharacterLimit,
        maxRows: characterLimit.messagingMaxRowLimit,
      };
    } else if ((selectedContact && selectedContact.locationId) || activeThread === ANNOUNCEMENT) {
      return {
        minCharacterLimit: characterLimit.announcementMinCharacterLimit,
        maxCharacterLimit: characterLimit.announcementMaxCharacterLimit,
        maxRows: characterLimit.announcementMaxRowLimit,
      };
    }
    return {
      minCharacterLimit: 5,
      maxCharacterLimit: 32000,
      maxRows: 10,
    };
  };

  React.useEffect(() => {
    setCharacterLimit(getCharacterLimit());
    modifyLimitError();
  }, [selectedContact, activeThread]);

  React.useEffect(() => {
    if (resetMsgs === true) {
      resetUnblockedMsgs();
    }
  }, [resetMsgs]);

  const resetMessagesThreadBlockStatus = () => {
    const selectedThreadIndex = rsMessageThread.findIndex((thread) => thread.contactId === selectedContact.contactId);
    if (selectedThreadIndex > -1) {
      rsMessageThread[selectedThreadIndex].isLearnerBlocked = false;
      emitMessageEvent({
        ...rsMessageThread[selectedThreadIndex],
        resetMsg: true
      });
      dispatch(rsSetDirectMessages({ rsMessageThread }));
      dispatch(
        rsSetSelectedContent({ rsSelectedContact: rsMessageThread[selectedThreadIndex], rsActiveThread: activeThread })
      );
    }
  };

  const resetUnblockedMsgs = async () => {
    const response = await onSendAPI(
      UNBLOCK_LEARNER_AUTO_REPLY,
      selectedContact,
      newMessage,
      messageType,
      location,
      dispatch,
      setEditorValue,
      selectedUsers
    );
    try {
      if (response.status !== 200) {
        setResetMsgs(false);
      }
      if (!POLL_CONFIG.USE_POLLING) {
        resetMessagesThreadBlockStatus();
      }
    } catch (e) {
      setResetMsgs(false);
      console.error(e);
    }
  };

  const modifyLimitError = (value = editorValue) => {
    if (value.trim().length < getCharacterLimit().minCharacterLimit) {
      setLimitError(true);
    } else {
      setLimitError(false);
    }
  };

  const getIsSendDisabled = () => {
    if (isSendDisabled) return true;

    if (newMessage) {
      return limitError || isMessageSending;
    } else {
      const isRelationshipStatusTrue = selectedContact.messageRelationshipStatus === true;
      const isRelationshipStatusUndefined = typeof selectedContact.messageRelationshipStatus === 'undefined';

      const shouldEnableSend = isRelationshipStatusTrue || isRelationshipStatusUndefined;

      if (!shouldEnableSend) return true;

      if (getMessagingSelectedTabType(selectedContact) === DIRECT_MESSAGE) {
        return !selectedContact.contactId || limitError || isMessageSending;
      } else if (getMessagingSelectedTabType(selectedContact) === ANNOUNCEMENT) {
        return !selectedContact.id || limitError || isMessageSending || !sendAnnouncement;
      }
    }

    return false;
  };

  const handleSnackbar = (message, success, duration) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
    setSnackbarAutoHideDuration(duration);
    setIsSuccessSnackbar(success);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const onSendAPI = async (
    text,
    rsSelectedContact,
    newMessage,
    messageType,
    location,
    dispatch,
    setEditorValue,
    selectedUsers
  ) => {
    const contact = newMessage ? selectedUsers[0] : rsSelectedContact;
    try {
      const sendResponse = await postMessage({
        body: transformMsg(text),
        to_person_key: contact.contactId,
        to_location_key: location.locationId, //TODO
        is_announcement: false,
      });
      if (unleashProps.isFlagEnabled()) {
        handleSnackbar(`${SUCCESS_MESSAGE_SENT} ${contact.contactName}`, true, 3000);
        if (newMessage) {
          const contacts = await fetchContacts(location.locationId, RECENT_MESSAGE);
          const selectedContact = (contacts.messageThreads || []).find(
            (contact) => contact.contactId === sendResponse.recipientId
          );
          if (selectedContact) {
            const filteredContacts = (contacts.messageThreads || []).filter(
              (contact) => contact.contactId !== sendResponse.recipientId
            ); // REMOVING THE SEND FROM COLLECTION
            filteredContacts.unshift({
              ...selectedContact,
              isLearnerBlocked: false,
            }); // ADDING IT AGAIN WITH UPDATED VALUES

            dispatch(rsSetDirectMessages({ rsMessageThread: filteredContacts }));
            dispatch(
              rsSetSelectedContent({
                rsSelectedContact: selectedContact,
                rsActiveThread: DIRECT_MESSAGE,
              })
            );
            dispatch(setNewMessage(false));
          }
        }
      } else {
        const contacts = await fetchContacts(location.locationId, RECENT_MESSAGE);
        const selectedContact = contacts.messageThreads.find((contact) => contact.contactId === sendResponse.recipientId);
        if (selectedContact) {
          dispatch(
            rsSetSelectedContent({
              rsSelectedContact: selectedContact,
              rsActiveThread: DIRECT_MESSAGE,
            })
          );
          dispatch(setNewMessage(false));
        }
      }
      return sendResponse;
    } catch {
      if (unleashProps.isFlagEnabled()) {
        handleSnackbar(`${FAILED_MESSAGE_SENT} ${contact.contactName}`, false, 3000);
      }
    }
  };

  const handleSendClick = async () => {
    setMessageSending(true);
    if (onSend) {
      await onSend();
    } else {
      await onSendAPI(
        editorValue.trim(),
        selectedContact,
        newMessage,
        messageType,
        location,
        dispatch,
        setEditorValue,
        selectedUsers
      );
    }
    onComplete();
    setMessageSending(false);
    setEditorValue('');
    modifyLimitError('');
  };

  return (
    <>
      <Box className={classes.sendEditorWrapper}>
        <Box
          id={'send-editor-text-area'}
          className={classes.sendEditor}
          value={editorValue}
          onChange={(e) => {
            const { value } = e.target;
            setEditorValue(value);
            modifyLimitError(value);
            if (onChange) {
              onChange(value.trim());
            }
          }}
          component={'textarea'}
          placeholder="Write Your Message Here..."
        ></Box>
        <Box className={classes.sendBtnWrapper}>
          <Button id={'send-button'} disabled={getIsSendDisabled()} className={classes.sendBtn} onClick={handleSendClick}>
            {(isMessageSending || isLoading) && <CircularProgress color="white" size={25} />}
            {!isMessageSending && !isLoading && (
              <>
                <CustomIcons className={classes.sendIcon} icon={icons.SendIcon} />
                SEND
              </>
            )}
          </Button>
        </Box>
      </Box>
      <Box className={classes.characterLimitWrapper}>
        <Typography style={{ fontSize: 14 }}>
          {`Character Limits: ${messageCharacterLimit.minCharacterLimit} Min | ${messageCharacterLimit.maxCharacterLimit} Max`}
        </Typography>
      </Box>
      <Snackbar
        id={'call-history-snackbar'}
        open={openSnackbar}
        autoHideDuration={snackbarAutoHideDuration}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={isSuccessSnackbar ? 'success' : 'error'}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default withStyles(styles)(withUnleash(SendEditor, UnleashService.FLAGS.SHOW_SNACK_BAR_ON_MESSAGE_SENT));
