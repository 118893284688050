import { Button } from '@material-ui/core';
import React from "react";
import { ButtonBase, Box } from "@material-ui/core";

export class ImageViewer extends React.Component {
    ref = React.createRef();
    backDropRef = null;
    imageRef = null;

    /**
     * @name closeImage
     * @desc Remove's child elements & close's image.
     * @return {void}
     */
    closeImage = () => {
        this.duplicateImage();
        setTimeout(
            () => {
                this.backDropRef.remove();
                this.imageRef.remove();
                this.backDropRef = null;
                this.imageRef = null;
                this.ref.style.visibility = "visible";
            }, 500
        );
    };

    /**
     * @name createBackDrop
     * @desc Dynamically create backdrop.
     * @return {void}
     */
    createBackDrop = () => {
        // BACK-DROP
        this.backDropRef = document.createElement("DIV");
        this.backDropRef.setAttribute("class", "image-backdrop");
        this.backDropRef.style.position = "fixed";
        this.backDropRef.style.zIndex = "8888";
        this.backDropRef.style.backgroundColor = "white";
        this.backDropRef.style.transition = "all 0.5s";
        this.backDropRef.style.visibility = "hidden";
        this.backDropRef.style.top = "0px";
        this.backDropRef.style.left = "0px";
        this.backDropRef.style.width = "100vw";
        this.backDropRef.style.height = "100vh";
        this.backDropRef.style.cursor = "pointer";
        this.backDropRef.addEventListener("click", this.closeImage);
        document.body.appendChild(this.backDropRef);
        // ANIMATING BACKDROP
        this.backDropRef.style.backgroundColor = "#000000b3";
        this.backDropRef.style.visibility = "visible";
    };

    /**
     * @name animateImageViewer
     * @desc Animate's image viewer.
     * @return {void}
     */
    animateImageViewer = () => {
        const { innerWidth, innerHeight } = window;
        // const imageElem = document.querySelector("#imageViewer");
        const aspectRatio = this.calculateAspectRatioFit(this.imageRef.naturalWidth, this.imageRef.naturalHeight, innerWidth, innerHeight);
        this.imageRef.style.left = (innerWidth / 2) - (aspectRatio.width / 2) + "px";
        this.imageRef.style.top = (innerHeight / 2) - (aspectRatio.height / 2) + "px";
        this.imageRef.style.width = aspectRatio.width + "px";
        this.imageRef.style.maxWidth = "80vw";
        this.imageRef.style.height = aspectRatio.height + "px";
        this.createBackDrop();
    };

    calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {

        var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return { width: srcWidth * ratio, height: srcHeight * ratio };
    }

    /**
     * @name duplicateImage
     * @desc Create shallow copy of image.
     * @return {void}
     */
    duplicateImage = () => {
        this.imageRef.style.width = this.ref.clientWidth + "px";
        this.imageRef.style.height = this.ref.clientHeight + "px";
        this.imageRef.style.position = "fixed";
        this.imageRef.style.left = this.ref.x + "px";
        this.imageRef.style.top = this.ref.y + "px";
    };

    /**
     * @name openImage
     * @desc Open's image in big view.
     * @return {void}
     */
    openImage = () => {
        // IMAGE VIEWER DIV
        this.imageRef = document.createElement("IMG");
        this.imageRef.setAttribute("id", "imageViewer");
        this.imageRef.setAttribute("src", this.props.imageURL);
        this.duplicateImage(); // CREATE SHALLOW COPY OF IMAGE
        this.imageRef.style.transition = "all 0.5s";
        this.imageRef.style.zIndex = "9999";
        document.body.appendChild(this.imageRef);

        setTimeout(
            () => {
                this.ref.style.visibility = "hidden";
                this.animateImageViewer();
            }, 500
        );

    };

    render() {
        return (
            <>
                <ButtonBase onClick={() => this.openImage()}>
                    <img
                        ref={ref => this.ref = ref}
                        className={this.props.imageClass}
                        src={this.props.imageURL} alt="image" />
                </ButtonBase>
            </>
        );
    };
};

