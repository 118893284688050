export default (theme) => ({
  outlinedComponent: {
    borderRadius: 0,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow: "none",
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  settingsRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 10,
  },
  level1Box: {
    paddingLeft: 50,
    paddingRight: 10,
    borderBottom: "0.5px solid #979797",
    paddingBottom: 10,
    marginTop: 10,
  },
  level2Box: {
    marginLeft: 30,
    paddingLeft: 20,
    marginRight: 10,
    borderBottom: "0.5px solid #979797",
    paddingBottom: 10,
    marginTop: 10,
  },
  level3Box: {
    marginLeft: 30,
    paddingLeft: 60,
    marginRight: 10,
    borderBottom: "0.5px solid #979797",
    paddingBottom: 10,
    marginTop: 10,
  },
  boxHeading1: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#3d3d3d",
    letterSpacing: "0.36px",
  },
  subHeading1: {
    fontSize: 12,
    color: "#747474",
    letterSpacing: "0.21px",
  },
  dayOfWeek: {
    fontSize: 15,
    color: "#3d3d3d",
    textTransform: "uppercase",
    letterSpacing: "0.98px",
  },
  dayOfWeekWrapper: {
    minWidth: 125,
  },
  scheduleWrapper: {
    minHeight: 100,
  },
  scheduleTextInput: {
    maxWidth: 95.5,
  },
  divider: {
    width: 7.5,
    margin: "0px 10px",
    backgroundColor: "black",
    height: 1,
  },
  verticalDivider: {
    width: 1,
    height: 36,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: "#979797",
  },
  slotReadOnly: {
    minWidth: 104,
    paddingLeft: 20,
    boxSizing: "border-box",
  },
  readOnlyDivider: {
    margin: 0,
  },
  featureTextField: {
    width: "98%",
    margin: "10px 0px 20px",
  },
  level3FullBorderBox: {
    paddingLeft: 90,
    borderBottom: "0.5px solid #979797",
    paddingBottom: 10,
    marginTop: 10,
    paddingRight: 10,
  },
  [theme.breakpoints.down("md")]: {
    level1Box: {
      paddingLeft: 0,
    },
    level2Box: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    level3Box: {
      marginLeft: 0,
      paddingLeft: 0,
    },
    level3FullBorderBox: {
      paddingLeft: 0,
    },
  },
  mainAccordion: {
    background: "#fff",
    zIndex: 1,
    "& .MuiCard-root": {
      background: "transparent",
    },
  },
});
