export default theme => ({
  smallText: {
    display: 'inline-block',
    fontSize: 12,
    color: '#747474',
    paddingBottom: 20
  },
  textAreaRoot: {
    width: '100%',
    height: 135
  },
  outlinedTextArea: {
    borderRadius: 0
  },
  textAreaFont: {
    fontSize: 12,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 20,
    marginTop: 24
  },
})