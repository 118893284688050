import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

export const useCommunicationInfoModalHook = (props, modalRef) => {
  const INITIAL_GROUPDATA = {
    totalMsgs: 0,
    sentByLearner: 0,
    sentByNonLearner: 0,
    msgsHidden: 0,
  };

  const [groupedData, setGroupedData] = useState(INITIAL_GROUPDATA);
  const [chartData, setChartData] = useState([]);
  const colors = {
    msgsHidden: '#78889C',
    sentByNonLearner: '#0F48B8',
    sentByLearner: '#0096CA',
    totalMessages: '#624CA5',
  };
  const visualizationDataIndexes = {
    totalMsg: 0,
    sentByLearner: 1,
    sentByNonLearner: 2,
    msgsHidden: 3,
  };

  useEffect(() => {
    groupByDates(props.messagesSummary);
  }, [props.messagesSummary]);

  useEffect(() => {
    if (props.isOpen) {
      modalRef.current.toggleModal();
    }
  }, [props.isOpen]);

  const getDateRange = (range) => {
    return {
      startDate: moment(range ? range.startDate : new Date() - 7).format('YYYY-MM-DD'),
      endDate: moment(range ? range.endDate : new Date()).format('YYYY-MM-DD')
    };
  };

  /**
   * @name getVisualizationDefaultData
   * @param {object} defaultValues
   * @return {array}
   */
  const getVisualizationDefaultData = (defaultValues) => {
    return [
      {
        label: 'Messages Sent',
        data: [],
        type: 'line',
        backgroundColor: 'transparent',
        borderColor: colors.totalMessages,
      },
      {
        label: 'Messages Sent By Learner',
        data: [],
        type: 'line',
        backgroundColor: 'transparent',
        borderColor: colors.sentByLearner,
      },
      {
        label: 'Messages Sent By Non Learner',
        data: [],
        type: 'line',
        backgroundColor: 'transparent',
        borderColor: colors.sentByNonLearner,
      },
      {
        label: 'Messages Hidden',
        data: [],
        backgroundColor: colors.msgsHidden,
      },
    ];
  };

  /**
   * @name getGroupedData
   * @param {object} groupedData
   * @param {object} contentData
   * @desc Prepare's grouped data based upon parameters.
   * @return {object} groupedData
   */
  const getGroupedData = (groupedData, contentData) => {
    groupedData.totalMsgs += contentData.totalMsgSentByLearner + contentData.totalMsgSentByNonLearner;
    groupedData.sentByLearner += contentData.totalMsgSentByLearner;
    groupedData.sentByNonLearner += contentData.totalMsgSentByNonLearner;
    groupedData.msgsHidden += contentData.totalMsgHidden;
    return groupedData;
  };

  /**
   * @name groupByDates
   * @param {object} payload
   * @desc Groups entire data by date.
   * @return {object} data Data that can be further used in chart.js integration.
   */
  const groupByDates = (payload) => {
    let chartData = {
      labels: [],
      datasets: getVisualizationDefaultData(),
    };
    if (payload.length === 0) {
      return chartData;
    }
    let localGroupedData = INITIAL_GROUPDATA;
    payload.forEach((contentData) => {
      const date = contentData.date;
      const labelIndex = chartData.labels.indexOf(date);
      if (labelIndex === -1) {
        chartData.labels.push(date);
        chartData.datasets[visualizationDataIndexes.totalMsg].data.push(
          contentData.totalMsgSentByLearner + contentData.totalMsgSentByNonLearner
        );
        chartData.datasets[visualizationDataIndexes.sentByLearner].data.push(contentData.totalMsgSentByLearner);
        chartData.datasets[visualizationDataIndexes.sentByNonLearner].data.push(contentData.totalMsgSentByNonLearner);
        chartData.datasets[visualizationDataIndexes.msgsHidden].data.push(contentData.totalMsgHidden);

        localGroupedData = getGroupedData(localGroupedData, contentData);
      } else {
        // TOTAL MSGS
        chartData.datasets[visualizationDataIndexes.totalMsg].data[labelIndex] +=
          contentData.totalMsgSentByLearner + contentData.totalMsgSentByNonLearner;
        // SENT BY LEARNER
        chartData.datasets[visualizationDataIndexes.sentByLearner].data[labelIndex] += contentData.totalMsgSentByLearner;
        // SENT BY NON LEARNER
        chartData.datasets[visualizationDataIndexes.sentByNonLearner].data[labelIndex] +=
          contentData.totalMsgSentByNonLearner;
        // MSGS HIDDEN
        chartData.datasets[visualizationDataIndexes.msgsHidden].data[labelIndex] += contentData.totalMsgHidden;
        localGroupedData = getGroupedData(groupedData, contentData);
      }
    });
    setGroupedData(localGroupedData);
    setChartData(chartData);
  };

  return {
    chartData,
    groupedData,
    colors,
    modalRef,
    getDateRange,
  };
};
