export default (theme) => ({
    messageBox: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "1.5rem",
    },
    messsageMiscText: {
        color: '#7896A2',
        "& p"  :{
            fontSize :  14
        } 

    },
    opponentMessage: {
        backgroundColor: "#F7F8FA",
        color: "black"
    },
    message: {
        fontSize: 16,
        padding: "20px 20px",
        borderRadius: 10,
        "& p": {
            margin: "0px !important",
        }
    },
    myMessage: {
        background: theme.palette.primary.teal.main,
        color: "white"
    },
    leftMessage: {
        "& *": {
            display: "flex",
            marginRight: "auto",
            flexDirection: "column"
        }
    },
    rightMessage: {
        "& *": {
            display: "flex",
            marginLeft: "auto",
            flexDirection: "column"
        }
    },
    senderPersonNameWrapper: {
        display: "flex",
        flexDirection: "row"
    },
    hiddenIcon:{
        padding: 20        
    },
    opponentMessageRow:{
        display: 'flex', 
        flexDirection: 'row'
    },
    bodyLineBreak: {
        whiteSpace: "pre-wrap"
    }
});