import React from 'react';

import { Paper, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import EditButton from '../EditButton';
import { commonStyles } from '../common';
import styles from './index.styles';
import { saveRoleDetails } from '../../../util/APIUtils';


const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
  ...styles(theme),
}))

const Description = ({ roleId, isBaseRole, fetchRoleDetail, description = "" }) => {
  const classes = useStyles();
  const [editedDescription, setEditedDescription] = React.useState(description || "");
  const [isEdit, setIsEdit] = React.useState(false);

  const onEditClick = () => {
    setIsEdit(true);
  }

  const onCancelClick = () => {
    setEditedDescription(description);
    setIsEdit(false);
  }

  const handleDescriptionChange = (event) => {
    setEditedDescription(event.target.value);
  }

  const onSaveClick = async () => {
    await saveRoleDetails({
      roleDescription: editedDescription,
      roleId
    });

    fetchRoleDetail();
    setIsEdit(false);
  }
  
  const isError = editedDescription.length > 512;
  let helperText = "";

  if (isError) {
    helperText = "Description should not exceed 512 characters"
  }


  return (
    <Paper elevation={3} classes={{
      root: classes.paperRoot
    }}>
      <div className={classes.subheadingWrapper}>
        <h4 className={classes.subheading}>
          Description
        </h4>
        {!isEdit && !isBaseRole && <EditButton onClick={onEditClick} />}
      </div>
      {!isEdit ? (
        <span className={classes.smallText}>
          {editedDescription}
        </span>
      ) : (
        <>
          <TextField
            multiline
            rows={4}
            error={isError}
            helperText={helperText}
            value={editedDescription}
            variant="outlined"
            onChange={handleDescriptionChange}
            classes={{
              root: classes.textAreaRoot
            }}
            InputProps={{
              classes: {
                root: classes.outlinedTextArea,
                input: classes.textAreaFont
              }
            }}
          />

          <div className={classes.buttonsWrapper}>
            <Button onClick={onCancelClick}>Cancel</Button>
            <Button
              onClick={onSaveClick}
              variant="contained"
              color="primary"
              disabled={isError}
              classes={{
                root: classes.saveBtn
              }}
            >Save</Button>
          </div>
        </>
      )}
    </Paper>
  );
}

export default Description;