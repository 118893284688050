export default (theme) => ({
  capitalize: {
    textTransform: "capitalize",
  },
  success: {
    color: theme.status.success,
  },
  danger: {
    color: theme.status.danger,
  },
  warning: {
    color: theme.status.warning,
  },
  messageWrapper: {
    wordBreak: "break-word"
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "15px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  phone: { fontSize: "10px" },
  flexEnd: {
    fontSize: "12px",
    display: "flex",
    justifyContent: "flex-end",
  },
  timeDiv: {
    fontSize: "10px",
    color: "#B1B1B1",
    marginRight: "10px",
    marginTop: "2px",
  },
});
