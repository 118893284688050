import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import { commonStyles } from '../common';
import styles from './index.styles';
import UsersListDialog from '../UsersListDialog';
import ConfirmationDialog from '../ConfirmationDialog';
import { LOCATIONS } from '../../../constants';
import { fetchUsersList, saveRoleDetails } from '../../../util/APIUtils';

const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

const AssignedLocationsEditDialog = ({ open, handleClose, locations, roleId, fetchRoleDetail }) => {
  const classes = useStyles();
  const [isWarningDialogOpen, setWarningDialogOpen] = React.useState(false);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const [editedLocations, setEditedLocations] = React.useState(locations);
  const [userList, setUserList] = React.useState([]);
  const [deleteIndex, setDeleteIndex] = React.useState(null);
  const facilityList = LOCATIONS.filter(loc => loc.locationTypeId === 3).sort((a, b) => {
    if (a.locationName < b.locationName) return -1;
    if (a.locationName > b.locationName) return 1;
    return 0;
  });

  React.useEffect(() => {
    setEditedLocations(locations);
  }, [locations.length])

  const handleAddLocation = () => {
    const { locationId, locationName } = facilityList[0];
    setEditedLocations(prevState => ([
      ...prevState,
      {
        locationId: locationId,
        locationName,
        isNew: true
      }
    ]))
  }

  const handleChange = (event, index) => {
    const { value } = event.target;

    const copiedLocations = [...editedLocations];

    const location = {
      locationId: value.locationId,
      locationName: value.locationName,
      isNew: true
    };

    copiedLocations[index] = location;

    setEditedLocations(copiedLocations);
  }

  const handleDelete = async (index, locationId) => {
    const users = await fetchUsersList(roleId, locationId);

    if (users.length > 0) {
      setUserList(users);
      setWarningDialogOpen(true);
      return;
    }

    setDeleteIndex(index);
    setConfirmationDialogOpen(true);
  }

  const onDeactivate = async () => {
    const copiedLocations = [...editedLocations];

    copiedLocations.splice(deleteIndex, 1);

    setEditedLocations(copiedLocations);
  }

  const handleSave = async () => {
    await saveRoleDetails({
      roleId,
      assignedLocationIds: editedLocations.map(loc => loc.locationId)
    });
    
    await fetchRoleDetail();
    
    handleCloseClick();
  }

  const handleWarningDialogClose = () => {
    setWarningDialogOpen(false);
  }

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  }

  const handleCloseClick = () => {
    setEditedLocations(locations);
    setDeleteIndex(null);
    setUserList([]);
    handleClose();
  }

  return (
    <Dialog fullWidth open={open} onClose={handleCloseClick}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Edit Assigned Locations
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleCloseClick} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent classes={{
        root: classes.dialogContent
      }}>
        { editedLocations.length === 0 ? (
          <div className={classes.noLoc}>There are currently no locations</div>
        ) :
          editedLocations.map((loc, index) => (
            <div key={`${loc.locationId}-${index}`} className={classes.row}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel 
                  classes={{
                    root: classes.label,
                    outlined: classes.outlinedLabel
                  }}
                  id="demo-controlled-open-select-label">Facility</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={loc}
                  onChange={event => handleChange(event, index)}
                  renderValue={selected => selected.locationName}
                  classes={{
                    root: classes.select
                  }}
                  inputProps={{
                    name: 'jurisdiction'
                  }}
                  disabled={!loc.isNew}
                >
                  {
                    facilityList.map(facility => (
                      <MenuItem key={facility.locationId} value={facility}>
                        {facility.locationName}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

              <IconButton onClick={() => handleDelete(index, loc.locationId)}>
                <DeleteIcon className={classes.deleteIcon} />
              </IconButton>
            </div>
          ))
        }
        <div className={`${classes.row} ${classes.addBtnWrapper}`}>
          <Button
            onClick={handleAddLocation}
            variant="contained"
            color="primary"
          >Add Location</Button>
        </div>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={handleCloseClick}>
          Cancel
        </Button>
        <Button
          classes={{
           root: classes.saveBtn
          }}
          variant="contained"
          onClick={handleSave}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>

      <UsersListDialog
        handleClose={handleWarningDialogClose}
        open={isWarningDialogOpen}
        userList={userList}
      />

      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        handleClose={handleConfirmationDialogClose}
        confirmationMode="delete"
        onSave={onDeactivate}
      />
    </Dialog>
  )
}

export default AssignedLocationsEditDialog;