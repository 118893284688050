import React from "react";

const ExploreIcon = props => {
  const { size, stroke } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1121" dataName="Rectangle 1121" width="25" height="25" fill="none" stroke={stroke} strokeWidth="2"/>
        </clipPath>
        <clipPath id="clip-Explore">
          <rect width="48" height="48"/>
        </clipPath>
      </defs>
      <g id="Explore" clipPath="url(#clip-Explore)">
        <g id="Group_1211" dataName="Group 1211" clipPath="url(#clip-path)">
          <path id="Path_514" dataName="Path 514" d="M3.454,7.712A11.259,11.259,0,1,0,5.3,5.3" transform="translate(-0.75 -1.474)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <path id="Path_515" dataName="Path 515" d="M10.74,22.315l8.833-2.742,2.741-8.833-8.832,2.741Z" transform="translate(-4.028 -4.752)" fill="none" stroke={stroke} stroke-linecap="round" stroke-linejoin="round" strokeWidth="2"/>
          <path id="Path_516" dataName="Path 516" d="M20,20Z" transform="translate(-7.5 -8.224)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </g>
      </g>
    </svg>
  );
};

ExploreIcon.defaultProps = {
  size: 25,
  stroke: "inherit"
}

export default ExploreIcon;
