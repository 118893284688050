import { useEffect, useState } from 'react';
import { getLearnerMilestoneResults, getLearnerMilestoneStatus } from '../../../../util/APIUtils';
import { PDQ_STATUSES, SUCCESS } from '../../../services/constants';

export const useMilestoneMapperHook = props => {

  const [status,setStatus] = useState(false);
  const [pdqScores,setPdqScores] = useState({});
  const { user } = props;

  useEffect(() => {
    getMilestoneStatus();
  },[]);


  const getMilestoneStatus = async() => {
    try {
      if(user && user.userId) {
        const response = await getLearnerMilestoneStatus(props.user.userId);
        if(response && response.status === SUCCESS) {
          const { assessmentStatus } = response.payload;
          setStatus(assessmentStatus);
          if(assessmentStatus === PDQ_STATUSES.COMPLETED) {
            getMilestoneResults();
          }
        }  
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getMilestoneResults = async() => {
    try {
      const results = await getLearnerMilestoneResults(props.user.userId);
      if(results && results.status === SUCCESS) {
        setPdqScores(results.payload);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return { status, pdqScores };
};
