import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TextField,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { commonStyles } from '../common';
import styles from './index.styles';
import { saveRoleDetails } from '../../../util/APIUtils';

const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
  ...styles(theme)
}));

const RoleNameDialog = ({ roleName, roleId, open, handleClose, onSave }) => {
  const classes = useStyles();
  const [editedRoleName, setEditedRoleName] = React.useState(roleName);
  const [isDuplicateName, setDuplicateName] = React.useState(false);

  React.useEffect(() => {
    setEditedRoleName(roleName);
  }, [roleName])
  
  const handleRoleNameChange = (event) => {
    setEditedRoleName(event.target.value);
  }

  const handleCancelClick = () => {
    setEditedRoleName(roleName);
    setDuplicateName(false);
    handleClose();
  }

  const handleSaveClick = async () => {
    const res = await saveRoleDetails({
      roleId,
      roleName: editedRoleName
    });

    if (res.statusCode === 409) {
      setDuplicateName(true);
    } else {
      onSave(editedRoleName);
      handleCancelClick();
    }
  }

  const regex = /[^a-zA-z0-9()/ -]/g;
  const hasSpecialChar = regex.test(editedRoleName);
  let helperText = '';

  if (isDuplicateName) helperText = "Role name already exists. Please try a different name";

  if (editedRoleName.length > 128) helperText = "Role name should not exceed 128 characters";

  if (hasSpecialChar) helperText = "Role name cannot contain special characters";

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={handleCancelClick}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Edit Role Name
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleCancelClick} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent className={classes.dialogContent}>
        <TextField
          autoFocus
          className={classes.field}
          error={editedRoleName.length > 128 || isDuplicateName || hasSpecialChar}
          id="outlined-error-helper-text"
          label="Role"
          placeholder="Edit Role"
          helperText={helperText}
          variant="outlined"
          onChange={handleRoleNameChange}
          value={editedRoleName}
          InputProps={{
            classes: {
              root: classes.outlinedField,
              input: classes.input
            }
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={handleCancelClick}>Cancel</Button>
        <Button
          onClick={handleSaveClick}
          color="primary"
          variant="contained"
          className={classes.saveBtn}
          disabled={editedRoleName.length === 0 || editedRoleName.length > 128 || hasSpecialChar}
        >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default RoleNameDialog;