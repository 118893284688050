import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import Card from '@material-ui/core/Card'
import { Grid, Typography, Box } from '@material-ui/core';


const styles = theme => ({

    mainDialog: {
        width: 360,
        maxWidth: "100%",
        padding: 8,
    },
    subHeaderChildContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    dialogTitle: {
        ...theme.typography.t16,
        height: 31,
        fontWeight: 501,
        fontStretch: 'normal;',
        fontStyle: 'normal;',
        lineHeight: 'normal;',
        letterSpacing: '0.07px;',

        marginTop: 8,
        marginLeft: 8
    },
    dialogHeading: {
        margin: "0px 8px"
    },
    dialogcontent: {
        marginTop: 8,
        marginLeft: 8,
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: 14
    }
});

const getStyles = (styles, key) => styles ? styles[key] : {};

const confirmationModal = (props) => {
    const { classes } = props;
    const cancelText = props.cancelText || "Cancel";
    const continueText = props.continueText || "Continue";
    return (
        <Dialog
            id="confirmation-modal"
            open={props.isOpen}
            onClose={() => { props.onClose() }}>
            <Grid
                data-cy-name="redeem-dialog"
                container
                item
                className={classes.mainDialog}>

                <Grid
                    container
                    item
                    direction="column"

                >
                    {props.title && (
                        <Typography id="title" 
                        className={classes.dialogTitle}>
                            {props.title}
                        </Typography>

                    )}
                    <Typography
                     style={getStyles(props.styles, "heading")}
                    id="heading" className={classes.dialogHeading}>
                        {props.heading}
                    </Typography>
                    <Typography id="paragraph" variant="p"
                        dangerouslySetInnerHTML={{
                            __html: props.description
                        }}
                        className={classes.dialogcontent}>
                    </Typography>
                </Grid>

                <Grid
                    container
                    item
                    className={classes.subHeaderChildContainer}
                    style={{
                        justifyContent: props.alignActionButtons ? props.alignActionButtons : "flex-end"
                    }}
                >
                    <Box className={classes.buttonStyle}>
                        <Button
                            id="cancel"
                            style={{ ...props.cancelButtonStyles }}
                            color="primary"
                            data-cy-name="view-all-btn"
                            onClick={() => { props.onClose() }}
                        >
                            {cancelText}
                        </Button>
                        <Button
                            id="continue"
                            style={{ ...props.continueButtonStyles }}
                            color="primary"
                            data-cy-name="view-all-btn"
                            onClick={() => { props.onContinue() }}
                        >
                            {continueText}
                        </Button>
                    </Box>

                </Grid>
            </Grid>
        </Dialog>
    );
};


export default withStyles(styles)(confirmationModal);