import { useState } from 'react';
import { VoiceCallService } from '../../services/voice-call.service';

const useEditContactModalComponentHook = ({
  editContact,
  setEditContact,
  setIsEditError,
  setIsEditSucess,
  setIsEditContactModalOpen
}) => {
  const [error, setError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const voiceCallService = new VoiceCallService();
  const handleChange = event => {
    console.log('event', event);
    const { name, value } = event.target;
    setEditContact(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validation = () => {
    let isValid = true;
    setError({});
    if (editContact.firstName.trim() === '') {
      isValid = false;
      setError(prevError => ({
        ...prevError,
        firstName: 'First name cannot be empty'
      }));
    }
    if (editContact.lastName.trim() === '') {
      isValid = false;
      setError(prevError => ({
        ...prevError,
        lastName: 'Last name cannot be empty'
      }));
    }
    if (!isValid) {
      setIsSubmitted(true);
    }
    return isValid;
  };

  const updateContact = async () => {
    if (validation()) {
      const response = await voiceCallService.updateContact({
        id: editContact.id,
        firstName: editContact.firstName,
        lastName: editContact.lastName,
        phoneNumber: editContact.phoneNumber,
        learnerPersonKey: editContact.learnerPersonKey,
        relationshipType: editContact.relationshipType,
        preferredLanguage: editContact.preferredLanguage,
        status: editContact.status
      });
      if (response && response.errorMessage) {
        setIsEditError(response.errorMessage);
      } else {
        setIsSubmitted(false);
        setIsEditSucess(true);
        setIsEditContactModalOpen(false);
        setEditContact({});
      }
    }
  };
  return {
    error,
    isSubmitted,
    handleChange,
    updateContact
  };
};

export default useEditContactModalComponentHook;
