import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLearnerGroups as fetchLearnerGroup, getLearnerGroupById } from '../../../../../../util/APIUtils';
import { rsSetAnnouncements, rsSetSelectedContent, setNewMessage } from '../../../../../core/store/reducers/messageModuleReducer';
import { ANNOUNCEMENT_TYPE, ID_TYPES, LOCATIONS, MAX_ANNOUNCEMENT_LOCATIONS_THRESHOLD, NO_LEARNER_GROUPS_FOUND, SELECTED_HOUSING_UNIT, SELECTED_LOCATION, USER_CONTEXT } from '../../../../../../constants';
import { MessageService } from '../../../../../services/message.service';
import moment from 'moment';
import { Typography } from '../../../../../shared/components/MaterialUIComponents';
import { transformMsg } from '../../../../../services/utilty';

export const useNewAnnouncementHook = props => {
  const dispatch = useDispatch();
  const { classes } = props;
  const { rsMessageThread: contacts, rsAnnouncementsThread: announcementThread, rsSelectedContact} = useSelector(state => state.rsMessageModuleStore);
  const location = useSelector(state => state.app.selectedLocation);
  const [learners, setLearner] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredLearners, setFilteredLearners] = useState([]);
  const [recipients, setSelectedRecipients] = useState([SELECTED_HOUSING_UNIT || SELECTED_LOCATION]);
  const [announcementType, setAnnouncementType] = useState(ANNOUNCEMENT_TYPE.FACILITY);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [isMessageSending, setMessageSending] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(true);
  const [editLearnerGroupId, setLearnerGroupId] = useState(null);
  const [hasUnreachableLearner, setUnreachableLearner] = useState(false);
  const [showMessageMonitoredWarning, setShowMessageMonitoredWarning] = useState(false);
  const [newLearnerGroup, setNewLearnerGroup] = useState(false);
  const messageService = new MessageService();
  
  const onSearchChange = value => {
    if (value !== '') {
      getFilteredRecipients(value);
    } else {
      setFilteredLearners([]);
    }
    setSearch(value);
  };

  const getFilteredRecipients = (searchValue) => {
    switch(announcementType) {
      case ANNOUNCEMENT_TYPE.FACILITY:
        setFilteredLearners( 
          LOCATIONS.filter(item => {
            return item.locationName.toLowerCase().includes(searchValue.toLowerCase());
          })  
        );
        break;
      
      case ANNOUNCEMENT_TYPE.LEARNER_GROUP:
        setFilteredLearners(
          learners.filter(item => {
            return item.contactName.toLowerCase().includes(searchValue.toLowerCase());
          })
        );
        break;
      
      default: 
        searchLearners(searchValue);
    }
  }

  const searchLearners = async (searchValue) => {
    setLoading(true);
    const response = await messageService.getSuggestedUsers(searchValue);
    if(response) {
      setLoading(false);
      setFilteredLearners(response.map(item => {
        return {
          contactId: item.personId,
          contactName: `${item.firstName} ${item.lastName}`,
          locationName: item.assignedLocationName,
          locationId: item.assignedLocationId
        };
      }));
    }
  }

  const onUserSelect = async data => {
    const areMaxLocationsExceeded = recipients.length >= (MAX_ANNOUNCEMENT_LOCATIONS_THRESHOLD - 1) && announcementType === ANNOUNCEMENT_TYPE.FACILITY;
    setFilteredLearners([]);
    setSearch('');
    checkForUnreachableLearner(data);
    if (areMaxLocationsExceeded) {
      setShowSearchBox(false);
      setShowMessageMonitoredWarning(true);
    } else {
      setShowSearchBox(true);
    }
    setSelectedRecipients([...recipients, data]);
  };

  const onChange = (e, value) => {
    if (!value) {
      return;
    }
    // since announcements already have locationId, we just pass that as is
    if (value.locationId || value.recipientUserFullNames.length > 0 || value.recipientGroupsNames.length > 0) {
      dispatch(
        rsSetSelectedContent({
          rsSelectedContact: value,
          rsActiveThread: 'announcements'
        })
      );
      dispatch(setNewMessage(false));
      return;
    }

    // but contacts selected from 'To' text field do not have threadId,
    // so we find the required contact from threads from sidebar
    const contact = contacts.find(contact => contact.contactId === parseInt(value.contactId));
    dispatch(
      rsSetSelectedContent({
        rsSelectedContact: contact || {
          ...value,
          messageRelationshipStatus: true
        },
        rsActiveThread: 'contacts'
      })
    );
    dispatch(setNewMessage(false));
  };

  const getLearnerGroup = async () => {
    setLoading(true);
    try {
      const response = await fetchLearnerGroup(true);
      if (response.payload && response.payload.learnerGroups.length > 0) {
        setLearner(
          response.payload.learnerGroups.map(({ id, groupName }) => ({
            contactId: id,
            contactName: groupName
          }))
        );
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const removeSelectedUser = data => {
    setSelectedRecipients(
      recipients.filter(item => {
        if (announcementType === ANNOUNCEMENT_TYPE.LEARNER || announcementType === ANNOUNCEMENT_TYPE.LEARNER_GROUP) {
          return item.contactId !== data.contactId;
        } else {
          return item.locationName !== data.locationName;
        }
      })
    );
    setShowSearchBox(true);
    setShowMessageMonitoredWarning(false);
  };

  const isLocationChildren = (locationId) => {
    return (location.children || []).some(location => location.locationId === locationId);
  }
  const isShowInSideNav = (recipientList = []) => {
    return recipientList.some(recipient => {
      const {locationId} = recipient;
      return location.locationId === locationId || isLocationChildren(locationId);
    });
  }

  const onSend = () => {
    setMessageSending(true);
    return new Promise(async (resolve, reject) => {
      const payload = { 
        title, 
        body: transformMsg(body)
      };
      const promisses = [];
      if (announcementType === ANNOUNCEMENT_TYPE.FACILITY) {
        recipients.forEach((recipient) => {
          promisses.push(sendAnnouncement({ ...payload, locationId: recipient.locationId }, isShowInSideNav([recipient])));
        });
      } else if (announcementType === ANNOUNCEMENT_TYPE.LEARNER || announcementType === ANNOUNCEMENT_TYPE.LEARNER_GROUP) {
        const recipientKey = announcementType === ANNOUNCEMENT_TYPE.LEARNER ? 'recipientUsersPersonKeys' : 'recipientGroupsKeys';
        payload[recipientKey] = recipients.map((item) => item.contactId);
        promisses.push(sendAnnouncement(payload, isShowInSideNav(recipients)));
      }  
      
      await Promise.all(promisses);
      resolve(true);
    });
  };

  const sendAnnouncement = (payload, showInSideNav = true) => {
    return new Promise(async (resolve, reject) => {
      let senderPersonName = USER_CONTEXT.name;
      messageService.createAnnouncement(payload).then(_announcementResponse => {
        _announcementResponse['readDate'] = moment().format();
        _announcementResponse["senderPersonName"] = senderPersonName;
        if((announcementType !== ANNOUNCEMENT_TYPE.FACILITY || location.locationId === _announcementResponse.locationId) && showInSideNav) {
          announcementThread.unshift(_announcementResponse);          
          announcementThread[0].senderPersonName = senderPersonName;
          dispatch(
            rsSetAnnouncements({
              rsAnnouncementsThread: announcementThread,
              rsSelectedContact: { ...rsSelectedContact, senderPersonName }
            })
          );  
        }
        setMessageSending(false);
        setSearch('');
        onChange(null, _announcementResponse);
        setFilteredLearners([]);
        setBody('');
        setTitle('');
        resolve();
      });
    });
  }
  const getUnselectedUser = userList => {
    let id = announcementType === ANNOUNCEMENT_TYPE.FACILITY ? ID_TYPES.LOCATION_ID : ID_TYPES.CONTACT_ID;
    return (userList || []).filter(user => {
      return !recipients.find(_selectedUser => _selectedUser[id] === user[id]);
    });
  };

  const getErrorText = () => {
    if (announcementType === ANNOUNCEMENT_TYPE.LEARNER_GROUP) {
      return (
        <Typography className={classes.suggestedChipText}>
          {NO_LEARNER_GROUPS_FOUND}
        </Typography>
      );
    } else {
      return (
        <>
          <Typography className={classes.suggestedChipText}>{search}</Typography>
          &nbsp;-&nbsp;
          <Typography className={classes.notFoundText}>NOT FOUND</Typography>
        </>
      );
    }
  };

  const updatedGroupData = (data) => {
    if(data && recipients.length) {
      let allRecipients = [...recipients];
      allRecipients[ allRecipients.findIndex(recipient => recipient.contactId === editLearnerGroupId) ] = {
        contactId: editLearnerGroupId , contactName: data.groupName
      }
      setSelectedRecipients(allRecipients);
    } else {
      getLearnerGroup();
    }
  }

  const checkForUnreachableLearner = async (data) => {
    try {
      if(announcementType === ANNOUNCEMENT_TYPE.LEARNER_GROUP) {
        const response = await getLearnerGroupById(data.contactId);
        const { learnerGroupMembers } = response.payload;
        const hasUnreachableGroupLearner = learnerGroupMembers.some(member => !member.reachable);
        setUnreachableLearner(hasUnreachableGroupLearner);
        setShowMessageMonitoredWarning(hasUnreachableGroupLearner);  
      }
    } catch (error) {
      setUnreachableLearner(false);
    }
  }

  const resetComponent = () => {
    setSearch('');
    setSelectedRecipients([]);
    setShowSearchBox(true);
  };

  const onEditGroupClick = (learnerGroup) => {
    setLearnerGroupId(learnerGroup.contactId);
  }

  const onLearnerGroupClose = () => {
    setLearnerGroupId(null);
    setNewLearnerGroup(false);
  }

  const onAnnouncementClose = () => dispatch(setNewMessage(false));

  const onTypeChange = e => {
    setSelectedRecipients([]);
    setSearch('');
    setFilteredLearners([]);
    setAnnouncementType(e.target.value);
    setShowSearchBox(true);
  }

  const setupRecipient = () => {
    const recipientsTemp = [];
    if (SELECTED_HOUSING_UNIT && SELECTED_HOUSING_UNIT.locationId) {
      recipientsTemp.push(SELECTED_HOUSING_UNIT);
    } else if (SELECTED_LOCATION.locationId) {
      recipientsTemp.push(SELECTED_LOCATION);
    }
    setSelectedRecipients(recipientsTemp);
  };

  useEffect(() => {
    const isFacilityAnnouncement = announcementType === ANNOUNCEMENT_TYPE.FACILITY;
    if (!isFacilityAnnouncement) {
      setSearch('');
      setSelectedRecipients([]);
    } else {
      setupRecipient();
    }
  }, [location]);

  useEffect(() => {
    if (announcementType === ANNOUNCEMENT_TYPE.LEARNER_GROUP) {
      getLearnerGroup();
    }
  }, [announcementType]);
  
  return {
    isLoading,
    announcementType,
    recipients,
    isMessageSending,
    editLearnerGroupId,
    filteredLearners,
    search,
    showSearchBox,
    hasUnreachableLearner,
    showMessageMonitoredWarning,
    newLearnerGroup,
    learners,
    title,
    body,
    updatedGroupData,
    getUnselectedUser,
    onSearchChange,
    removeSelectedUser,
    setNewMessage,
    onEditGroupClick,
    getErrorText,
    setTitle,
    setBody,
    resetComponent,
    onUserSelect,
    onSend,
    setShowMessageMonitoredWarning,
    setNewLearnerGroup,
    onLearnerGroupClose,
    onAnnouncementClose,
    setSelectedRecipients,
    onTypeChange
  };
};
