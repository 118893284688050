import React from 'react';
import { Box, Typography, withStyles, TextField, Button, Select, MenuItem } from '@material-ui/core';
import { useMinuteManagementHook } from './useMinuteManagementHook';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import styles from './minute-management.style';

const MinuteManagement = ({
  classes,
  locationId,
  maxMinutesAllowedPerCallAttribute,
  maxMinPerDayPerLearnerAttribute,
  setMaxMinPerDayPerLearnerAttribute,
  setMaxMinutesAllowedPerCallAttribute,
  attributeEnabledState
}) => {
  const {
    openSnackbar,
    snackbarAutoHideDuration,
    snackbarMessage,
    isSuccessSnackbar,
    handleCloseSnackbar,
    onMaxMinutesAllowedChange,
    onMaxMinutesPerDayChange,
    onMaxMinutesAllowedSubmit,
    onMaxMinutesAllowedPerDaySubmit
  } = useMinuteManagementHook({
    locationId,
    maxMinutesAllowedPerCallAttribute,
    maxMinPerDayPerLearnerAttribute,
    setMaxMinPerDayPerLearnerAttribute,
    setMaxMinutesAllowedPerCallAttribute
  });

  const showMinuteManagementSectoin = Object.values(attributeEnabledState).some(val => val === true)

  return (
    <>
      {
        showMinuteManagementSectoin && (
          <>
            <Box className={classes.mainWrapper}>
              <Box className={classes.innerWrapperLeft}>
                <Typography id="minute-section-heading" className={classes.heading}>
                  Time Management
                </Typography>
              </Box>
              {attributeEnabledState[maxMinutesAllowedPerCallAttribute.attributeName] && (
                <Box className={classes.innerWrapper}>
                  <Box className={classes.innerWrapperLeft}>
                    <Typography id="max-minutes-per-call-heading" className={classes.innerWrapperLeftSubHeading}>
                      Max minutes per call
                    </Typography>
                  </Box>
                  <Box className={classes.minutesSection}>
                    <TextField
                      id="max-minutes-per-call"
                      className={classes.minutesInput}
                      type="number"
                      variant="outlined"
                      size="small"
                      margin="normal"
                      value={maxMinutesAllowedPerCallAttribute.attributeValue}
                      onChange={onMaxMinutesAllowedChange}
                      inputProps={{ min: 1 }}
                    />
                    <Button
                      id="save-max-minutes-per-call"
                      className={classes.saveButton}
                      variant="contained"
                      color="primary"
                      onClick={onMaxMinutesAllowedSubmit}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              )}

              {attributeEnabledState[maxMinPerDayPerLearnerAttribute.attributeName] && (
                <Box className={classes.innerWrapper}>
                  <Box className={classes.innerWrapperLeft}>
                    <Typography id="max-minutes-per-day-per-learner-heading" className={classes.innerWrapperLeftSubHeading}>
                      Max minutes per day per learner
                    </Typography>
                  </Box>
                  <Box className={classes.minutesSection}>
                    <Select
                      id="max-minutes-per-day-per-learner"
                      variant="outlined"
                      autoWidth={true}
                      value={maxMinPerDayPerLearnerAttribute.attributeValue}
                      onChange={onMaxMinutesPerDayChange}
                      inputProps={{ 'aria-label': 'max-minutes-per-day-per-learner',className: classes.minutesDropdown}}
                    >
                      <MenuItem value={30}>30 min</MenuItem>
                      <MenuItem value={60} selected>
                        1 hour
                      </MenuItem>
                      <MenuItem value={90}>1.5 hours</MenuItem>
                      <MenuItem value={120}>2 hours</MenuItem>
                    </Select>
                    <Button
                      id="save-max-minutes-per-day-per-learner"
                      className={classes.saveButton}
                      variant="contained"
                      color="primary"
                      onClick={onMaxMinutesAllowedPerDaySubmit}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            <Snackbar
              id={'call-history-snackbar'}
              open={openSnackbar}
              autoHideDuration={snackbarAutoHideDuration}
              onClose={handleCloseSnackbar}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleCloseSnackbar}
                severity={isSuccessSnackbar ? 'success' : 'error'}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          </>
        )
      }
    </>
  );


};

export default withStyles(styles)(MinuteManagement);
