import React from 'reactn';
import {
    Box,
    Button,
    Chip,
    Grid,
    Typography,
    withStyles,
    Dialog,
    AppBar,
    Toolbar,
    IconButton
} from "@material-ui/core";
import StarsIcon from "@material-ui/icons/Stars";
import Moment from "react-moment";
import momentTimeZone from "moment-timezone";
import styles from './student-transaction.style';
import CloseIcon from '@material-ui/icons/Close';
import { CONTENT_AREA, CONTENT_AREAS_ENUM } from '../../constants';
import { getStudentTransaction } from '../../util/APIUtils';

class StudentTransaction extends React.Component {

    state = {
        selectedUser: {},
        data: []
    };

    /**
   * @name isTokenAddOrSubtract
   * @param {string} type
   * @desc Based upon type add / subtract decision is made.
   * @return {string}
   */
    isTokenAddOrSubtract = type => {
        switch (type) {
            case "Reward":
            case "Credit":
                return "add";

            case "Redeem":
            case "Debit":
                return "subtract";
        }
    };

    componentDidUpdate() {
        if (this.props.isOpen && this.props.userDetail) {
            if (this.state.selectedUser.userId !== this.props.userDetail.userId) {
                this.setState({
                    selectedUser: this.props.userDetail
                }, () => {
                    this.getUserTransaction(
                        this.state.selectedUser.userId
                    );
                });
            }
        }
    };

    /**
     * @name getUserTransaction
     * @param {number} userId
     * @desc Fetches user data based upon user IDs passed into params.
     * @return {void}
     */
    getUserTransaction = (userId) => {
        this.setGlobal({
            isLoading: true
        });
        getStudentTransaction(userId)
            .then(
                (_successLog) => {
                    this.setGlobal({
                        isLoading: false
                    });
                    this.setState({
                        data: _successLog
                    });
                },
                (_errorLog) => {
                    console.error("Something went wrong while fetching learners list");
                    this.setGlobal({
                        isLoading: false
                    });
                    this.props.onClose();
                }
            );
    };

    render() {
        const { classes } = this.props;
        return (
            <Dialog open={this.props.isOpen} onClose={() => {
                this.props.onClose();
            }}>
                {!this.global.isLoading && (
                    <>
                        <AppBar style={{
                            position: 'relative'
                        }}>

                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    marginLeft="15px"
                                >
                                    <IconButton edge="start" color="inherit" onClick={this.props.onClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" >
                                        Student Transaction
                                </Typography>
                                </Box>
                            </Box>

                        </AppBar>
                        <Grid
                            container
                            item
                            classes={{
                                root: classes.root
                            }}
                        >
                            <Grid item xs={12}>
                                <Box
                                    component="div"
                                    display="flex"
                                    flexDirection="column"
                                    marginTop="20px"
                                    marginBottom="20px"
                                >
                                    <Grid
                                        container
                                        item
                                        className={[
                                            "grey-scrollbar",
                                            classes.listContainer,
                                            classes.contentGap
                                        ].join(" ")}
                                    >
                                        {/* BEGIN: ROW */}
                                        {this.state.data.map((row, index) => (
                                            <Grid
                                                key={index}
                                                container
                                                item
                                                className={[classes.listItem, "course-completed-item"].join(
                                                    " "
                                                )}
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={3}
                                                    alignItems="center"
                                                    className={classes.iconWrapper}
                                                >
                                                    <Typography
                                                        className={[
                                                            this.isTokenAddOrSubtract(row.transactionTypeName) ===
                                                                "add"
                                                                ? classes.success
                                                                : classes.danger
                                                        ].join(" ")}
                                                    >
                                                        {this.isTokenAddOrSubtract(row.transactionTypeName) ===
                                                            "add"
                                                            ? "+"
                                                            : "-"}
                                                        {row.tokenAmount}
                                                    </Typography>
                                                    <StarsIcon className={classes.icon} fontSize="small" />
                                                </Grid>
                                                <Grid container item xs={8}>
                                                    {row.initiatorPersonKey && (
                                                        <Box
                                                            component="div"
                                                            width="100%"
                                                            display="flex"
                                                            flexDirection="column"
                                                        >
                                                            <Typography className={classes.notifTitle}>
                                                                {row.initiatorPersonName}
                                                            </Typography>
                                                            <Typography className={classes.notifSubText}>
                                                                {row.note}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    {row.featureName && !row.resourceKey && (
                                                        <Chip
                                                            style={
                                                                CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment]
                                                                    ? CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].chip
                                                                    : null
                                                            }
                                                            className={[classes.chip, classes.workChip].join(" ")}
                                                            label={row.featureName}
                                                        />
                                                    )}

                                                    {row.resourceKey && (
                                                        <Chip
                                                            style={
                                                                CONTENT_AREA[row.contentAreaKey]
                                                                    ? CONTENT_AREA[row.contentAreaKey].chip
                                                                    : null
                                                            }
                                                            className={[classes.chip, classes.workChip].join(" ")}
                                                            label={row.resourceName}
                                                        />
                                                    )}

                                                    <Typography className={classes.listItemDateText}>
                                                        <Moment tz={momentTimeZone.tz.guess()} format="MMM D, YYYY">
                                                            {row.createdDate}
                                                        </Moment>
                                                        &nbsp; at &nbsp;
                                                    <Moment tz={momentTimeZone.tz.guess()} format="hh:mmA">
                                                            {row.createdDate}
                                                        </Moment>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                        {/* END: ROW */}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )}

            </Dialog >
        );
    };
};


export default withStyles(styles)(StudentTransaction);