import React from "react";

const BookmarkIcon = props => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-Bookmark">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Bookmark" clipPath="url(#clip-Bookmark)">
        <path id="Icon_awesome-bookmark" data-name="Icon awesome-bookmark" d="M0,24V2.25A2.25,2.25,0,0,1,2.25,0h13.5A2.25,2.25,0,0,1,18,2.25V24L9,18.75Z" transform="translate(3.352)" fill={fill} />
      </g>
    </svg>
  );
};

BookmarkIcon.defaultProps = {
  size: 24,
  fill: "inherit"
}

export default BookmarkIcon;
