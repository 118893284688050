export default (theme) => ({
    text: {
        color: theme.palette.text.primary,
        ...theme.typography.p2,
    },
    breadcrumbRoot: {
        display: "flex",
        "& p:last-child": {
            ...theme.typography.bold
        }
    },
    divider: {
        margin: "0 5px"
    }
});