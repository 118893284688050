import React, {Component} from 'react'


class TabletSettings extends Component{

    render(){

        return(

            <div>
                <h2>Tablet Settings</h2>
            </div>


        )
    }
}


export default TabletSettings