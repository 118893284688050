import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Typography, DialogActions, DialogContent, DialogTitle, Paper, Button, Box } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
  successType: {
    background: '#359A16',
    margin: '1rem'
  },
  iconClass: {
    float: 'left',
    margin: '.5rem',
    color: 'white',
    width: '1.2em',
    height: '1.2em'
  },
  errorType: {
    background: 'red',
    margin: '1rem'
  },
  warningType: {
    background: '#ff9100',
    margin: '1rem'
  },
  infoType: {
    background: theme.palette.background.blue,
    margin: '1rem'
  },
  statusMessage: {
    color: 'white',
    whiteSpace: 'pre-line',
    padding: '.5rem'
  }
});

const ResponseStatusModal = props => {
  const { classes } = props;
  const paperClass =
    props.type === 'Success'
      ? classes.successType
      : props.type === 'Error'
      ? classes.errorType
      : props.type === 'Warning'
      ? classes.warningType
      : classes.infoType;
  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>
        <Typography variant="h6">{props.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Paper className={paperClass}>
          <Box component="div" justifyContent="space-between" alignItems="center" display="flex">
            {props.type === 'Success' ? (
              <CheckCircleIcon className={classes.iconClass} />
            ) : props.type === 'Error' ? (
              <CancelIcon className={classes.iconClass} />
            ) : props.type === 'Warning' ? (
              <WarningIcon className={classes.iconClass} />
            ) : (
              <InfoIcon className={classes.iconClass} />
            )}
            <h3 className={classes.statusMessage}>{props.description}</h3>
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions style={{ marginTop: '.5rem' }}>
        <Button id="close-btn" color="secondary" onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ResponseStatusModal);
