import React from 'react';
import { withUnleash } from '../../v2/core/unleash';
import { UnleashService } from '../../v2/services';
import UserCommunicationV2 from './user-communication-v2';
import UserCommunication from './user-communication';

const UserCommunications = (props) => {
  const isFlagEnabled = props.unleashProps.isFlagEnabled();
  return (
    <>
      {isFlagEnabled ?
        <UserCommunicationV2 {...props} />
        :
        <UserCommunication {...props} />
      }
    </>
  );
}

export default withUnleash(UserCommunications, UnleashService.FLAGS.EXTEND_TAB_RESTRUCTURING_TO_PROFILE_PAGES);
