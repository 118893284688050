export default function initPendo({
  info, apiKey
}) {
  const { id, email, full_name, role, name, user_type, environment, userFullName, locationId, isSuperAdmin } = info;
  const pendoOptions = {
    visitor: {
      id, // "VISITOR-UNIQUE-ID" // Required if user is logged in, default creates anonymous ID
      person_ID: id, // "VISITOR-UNIQUE-ID" // Required if user is logged in, default creates anonymous ID
      visitor_ID: id, // "VISITOR-UNIQUE-ID" // Required if user is logged in, default creates anonymous ID
      email, // Recommended if using Pendo Feedback, or NPS Email
      full_name, // Recommended if using Pendo Feedback
      // environment,
      userFullName,
      role, // Optional
      isSuperAdmin,

      // You can add any additional visitor level key-values here, as long as it's not one of the above reserved names.
    },

    account: {
      id: locationId,
      location_ID: locationId, // "ACCOUNT-UNIQUE-ID" // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      name, // Optional
      // email,
      user_type,
      environment,
      //   is_paying, // Recommended if using Pendo Feedback
      //   monthly_value, // Recommended if using Pendo Feedback
      //   planLevel, // Optional
      //   planPrice, // Optional
      //   creationDate // Optional

      // You can add any additional account level key-values here, as long as it's not one of the above reserved names.
    },
  };

  let isPendoInitialize;
  try {
    // eslint-disable-next-line no-undef
    isPendoInitialize = pendo; // this pendo object is coming from  window
  } catch (ex) {
    isPendoInitialize = false;
  }

  if (!isPendoInitialize) {
    // if pendo is not initialize then initialize it
    (function (p, e, n, d, o) {
      let v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');

    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function after users are authenticated in your app and your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.
    // eslint-disable-next-line no-undef
    pendo.initialize(pendoOptions);
  } else {
    // eslint-disable-next-line no-undef
    pendo.identify(pendoOptions);
  }
}
