export default theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    field: {
        width: '100%',
        marginTop: 20
    },

    saveBtn: {
        marginLeft: 10,
        paddingLeft: 31,
        paddingRight: 31
    },
    dialogContent: {
        paddingTop: 40,
        paddingBottom: 40
    },
    dialogActions: {
        paddingBottom: 25,
        paddingTop: 0,
        justifyContent: 'center'
    }
})