
export default (theme) => ({
  root: {
    display: 'flex',
    paddingLeft: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    alignSelf: 'stretch',
    paddingBottom:64
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 24,
  },
  stableTableCellOne: {
    height: '100%',
    flex: 1,
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '2px',
    textAlign: 'left',
  },
  stableTableCellTwo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flex: '1 0 0',
    alignSelf: 'stretch',
    paddingLeft: 64,
  },
  label: {
    alignSelf: 'stretch',
    flex: 1,
    position: 'relative',
    letterSpacing: '0.02em',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxHeight: '24px',
    fontWeight: 'bold !important',
  },
  subText: {
    color: theme.palette.colors.grey110,
  },
});
