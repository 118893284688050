import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import styles from "./messages.style";
import { withStyles } from "@material-ui/styles";

class MessageSearch extends Component {
  render() {
    return (
      <Paper component="form" className={this.props.classes.root}>
        <IconButton
          type="submit"
          className={this.props.classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          className={this.props.classes.input}
          placeholder="Search Messages"
          value={this.props.messageKeyword}
          onChange={(e) => {
            this.props.onChange(e.target.value);
            this.props.onFilterChanged(
              this.props.columnDef.tableData.id,
              e.target.value
            );
          }}
          inputProps={{ "aria-label": "Search Messages" }}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(MessageSearch);
