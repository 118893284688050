import {
    getFnFConnections,
    changeConnectionStatus, 
    getFnFMessages,
    getLearnerMessageHistory,
    downloadMessageHistory,
    getRelationShipStatus
} from '../../../util/APIUtils';

export class FnFMessagingService {

    /**
     * @name getFnFConnections
     * @desc Fetch relationships by location.
     * @return {Promise}
     */
     getConnections = (locationId, pageSize, sortBy, page, data) => {
        return new Promise(
            (resolve, reject) => {
                getFnFConnections(locationId, pageSize, sortBy, page, data)
                    .then(data => { resolve(data) })
                    .catch(err => reject(err)) 
            }
        );
    };

    /**
     * @name getFnFMessages
     * @desc Fetch Messages by location and Filters.
     * @return {Promise}
     */
     getMessaages = (locationId, pageSize, beforeId, afterId, data) => {
        return new Promise(
            (resolve, reject) => {
                getFnFMessages(locationId, pageSize, beforeId, afterId, data)
                    .then(data => { resolve(data) })
                    .catch(err => reject(err)) 
            }
        );
    };
    /**
     * @name getLearnerMessages
     * @desc Fetch Messages by learner, contact, location and Filters.
     * @return {Promise}
     */
     getLearnerMessages = (locationId, learnerPersonId, contactPersonId, pageSize, beforeId, afterId) => {
        return new Promise(
            (resolve, reject) => {
                getLearnerMessageHistory(locationId, learnerPersonId, contactPersonId, pageSize, beforeId, afterId)
                    .then(data => { resolve(data) })
                    .catch(err => reject(err)) 
            }
        );
    };
    /**
     * @name downloadMessages
     * @desc Download csv based on locaiton, learner and contact.
     * @return {Promise}
     */
     downloadMessages = (locationId, learnerPersonId, contactPersonId) => {
        return new Promise(
            (resolve, reject) => {
                downloadMessageHistory(locationId, learnerPersonId, contactPersonId)
                    .then(data => { resolve(data) })
                    .catch(err => reject(err)) 
            }
        );
    };
    /**
     * @name getLearnerContactRelation
     * @desc Fetch relationship between learner and contact based on locaiton, learner and contact.
     * @return {Promise}
     */
     getLearnerContactRelation = (locationId, learnerPersonId, contactPersonId) => {
        return new Promise(
            (resolve, reject) => {
                getRelationShipStatus(locationId, learnerPersonId, contactPersonId)
                    .then(data => { resolve(data) })
                    .catch(err => reject(err)) 
            }
        );
    };

    /**
     * @name changeConnectionStatus
     * @param {number} locationId
     * @param {number} learnerPersonId
     * @param {number} contactPersonId
     * @param {object} data object containing new status
     * @desc Block/Unblock relationship.
     * @return {Promise}
     */
    changeConnectionStatus = (locationId, data) => {
        return new Promise(
            (resolve, reject) => {
                changeConnectionStatus(locationId, data)
                    .then(data => { resolve(data) })
                    .catch(err =>  { reject(err) })
            }
        );
    }

    /**
    * @name FNF_RELATIONSHIP_STATUS
    * @return {object}
    */
    get FNF_RELATIONSHIP_STATUS() {
        return {
            REQUESTED: "REQUESTED",
            ACCEPTED: "ACCEPTED",
            BLOCKED: "BLOCKED",
            REJECTED: "REJECTED",
            UNBLOCKED: "UNBLOCKED",
            CONNECTED: "CONNECTED"
        }
    }

    /**
     * @name getConnectionStatus
     * @param {string} status
     * @desc Get user readable connection status.
     * @return {string} status
     */
    getConnectionStatus = (status) => {
        const statusArray = [status.admin, status.learner, status.contact]
        if (statusArray.every((item) => item === this.FNF_RELATIONSHIP_STATUS.REQUESTED)) {
            return this.FNF_RELATIONSHIP_STATUS.REQUESTED
        } else if (status.contact === this.FNF_RELATIONSHIP_STATUS.REJECTED) {
            return this.FNF_RELATIONSHIP_STATUS.REJECTED
        } else if (status.admin === this.FNF_RELATIONSHIP_STATUS.BLOCKED) {
            return this.FNF_RELATIONSHIP_STATUS.BLOCKED
        } else {
            return this.FNF_RELATIONSHIP_STATUS.CONNECTED
        }
    }

    /**
     * @name getStatusAction
     * @param {string} status
     * @desc Get status action object.
     * @return {object} status action object
     */
    getStatusAction = (status) => {
        switch (status) {
            case 'ACCEPTED':
                return { show: true, label: 'Block', value: 'blocked' }
            case 'BLOCKED':
                return { show: true, label: 'Unblock', value: 'unblocked' }
            default:
                return { show: false, label: '' } 
        }
    }
};