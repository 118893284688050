import { useCallback, useEffect, useState } from 'react';
import {
  getResumeFile,
  saveResumeData,
  getResumeData as getResumeDataFromServer,
  getOptionsData,
} from '../../../../util/APIUtils';
import { convertBase64ToBlob } from '../../../services/utilty';
import store, { showToast } from '../../../core/store';
import { SUCCESS, TABS } from '../../../services/constants';

export const useResumeBuilderHook = (props) => {
  const { history } = props;
  const [learnerName, setLearnerName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isFetchingPDF, setFetchingPDF] = useState(false);
  const [data, setResumeData] = useState({
    personalInfo: {
      firstName: '',
      lastName: '',
      location: { city: '', state: '' },
      phoneNumber: '',
      email: '',
      aboutMeSummary: '',
    },
    workExperiences: [],
    achievements: [],
    educations: [],
    skills: [],
  });
  const [stateOptions, setStatesOptions] = useState([]);
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [skillOptions, setSkillsOptions] = useState([]);

  const getPersonId = () => {
    return props.match.params.id;
  };

  useEffect(() => {
    getResumeData();
    getLearnerName();
  }, []);

  /**
   * @name getLearnerName
   * @desc Gets learner name from query param
   * @return
   */
  const getLearnerName = useCallback(() => {
    const urlParams = new URLSearchParams(props.location.search);
    console.log(props.location)
    setLearnerName(urlParams.get('learnerName'));
  });

  /**
   * @name transformDataBeforeSave
   * @description Transform the data before saving it to the server
   * @param {Object} data - The data to be transformed
   * @returns {Object} - The transformed data
   */
  const transformDataBeforeSave = (data) => ({
    personalInfo: {
      firstName: data.personalInfo.firstName,
      lastName: data.personalInfo.lastName,
      city: data.personalInfo.location?.city,
      state: data.personalInfo.location?.state,
      email: data.personalInfo.email,
      phoneNumber: data.personalInfo.phoneNumber,
      aboutMe: data.personalInfo.aboutMeSummary,
    },
    workExperiences: data.workExperiences.map((item) => ({
      title: item.jobTitle,
      company: item.company,
      city: item.location?.city,
      state: item.location?.state,
      startDateMonth: item.startDate.month || null,
      startDateYear: item.startDate.year,
      endDateMonth: item.endDate?.month || null,
      endDateYear: item.endDate?.year || null,
      stillWorks: item.isCurrentlyWorking,
      jobDescription: item.jobDescription,
    })),
    educations: data.educations.map((item) => ({
      degree: item.degree,
      otherDegree: item.otherDegree,
      school: item.school,
      graduationYear: item.graduationYear,
      fieldOfStudy: item.fieldOfStudy,
    })),
    achievements: data.achievements.map((item) => ({
      title: item.name,
      issuingOrganization: item.organization,
      issuedDateMonth: item.issueDate?.month || null,
      issuedDateYear: item.issueDate?.year || null,
      description: item.description,
    })),
    skills: [...data.skills],
  });

  /**
   * @name transformDataAfterFetch
   * @description Transform the data after fetching it from the server
   * @param {Object} data - The data to be transformed
   * @returns {Object} - The transformed data
   */
  const transformDataAfterFetch = (data) => ({
    personalInfo: {
      firstName: data.personalInfo.firstName,
      lastName: data.personalInfo.lastName,
      location: {
        city: data.personalInfo.city || '',
        state: data.personalInfo.state || '',
      },
      email: data.personalInfo.email || '',
      phoneNumber: data.personalInfo.phoneNumber || '',
      aboutMeSummary: data.personalInfo.aboutMe || '',
    },
    workExperiences: data.workExperiences.map((item) => ({
      jobTitle: item.title,
      company: item.company,
      location: { city: item.city, state: item.state },
      startDate: { month: item.startDateMonth || '', year: item.startDateYear },
      endDate: { month: item.endDateMonth || '', year: item.endDateYear || '' },
      isCurrentlyWorking: item.stillWorks,
      jobDescription: item.jobDescription,
    })),
    educations: data.educations.map((item) => ({
      degree: item.degree,
      otherDegree: item.otherDegree || '',
      school: item.school,
      graduationYear: item.graduationYear,
      fieldOfStudy: item.fieldOfStudy || '',
    })),
    achievements: data.achievements.map((item) => ({
      name: item.title,
      organization: item.issuingOrganization,
      issueDate: { month: item.issuedDateMonth || '', year: item.issuedDateYear || '' },
      description: item.description,
    })),
    skills: [...data.skills],
  });

  const onViewPDFClick = async () => {
    setFetchingPDF(true);
    try {
      const { status, payload } = await getResumeFile(getPersonId());
      if (status == SUCCESS) {
        window.open(convertBase64ToBlob(payload, 'application/pdf'), 'target=_blank');
      } else {
        throw 'Error';
      }
    } catch (e) {
      console.log(e);
      store.dispatch(showToast(`Something went wrong while fetching certificate.`));
    }

    setFetchingPDF(false);
  };

  const onGoBackClick = () => {
    history.push(`/manage?tab=${TABS.RESUME}`);
  };

  const getResumeData = async () => {
    setLoading(true);
    try {
      const { payload: optionsData } = await getOptionsData();
      const { payload } = await getResumeDataFromServer(getPersonId());
      setResumeData(transformDataAfterFetch(payload));
      setStatesOptions(optionsData.states);
      setDegreeOptions(optionsData.degrees);
      setSkillsOptions(optionsData.skills);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  const onSave = async (data, callback) => {
    try {
      const { status } = await saveResumeData(getPersonId(), transformDataBeforeSave(data));
      if (status === SUCCESS) {
        callback();
        setResumeData(data);
      } else {
        throw Error('Something went wrong');
      }
    } catch (error) {
      console.log(error);
      callback('Something Went Wrong!');
    }
  };

  return {
    isLoading,
    isFetchingPDF,
    data,
    stateOptions,
    degreeOptions,
    skillOptions,
    learnerName,
    onSave,
    onViewPDFClick,
    onGoBackClick,
  };
};
