import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import BadgeIcon from '@mui/icons-material/Badge';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './keyword-detail.style';
import MaterialTable from 'material-table';
import useKeywordDetailHook from './useKeywordDetailHook';
import { formatDatetime, getSelectedLocationTimeZone } from '../../../../utils/utils';
import { DATE_FORMAT } from '../../../../../../constants';
import CallHistoryDetailsDrawer from '../../../call-history-details-v2/call-history-details-v2';

const KeywordDetail = ({
                         classes,
                         keywordDictionaryId,
                         selectedKeyword,
                         dateAdded,
                         staffName,
                         isMonitored,
                         onError,
                       }) => {
  const {
    tableRef,
    tableColumnsRef,
    getKeywordDetails,
    isDetailModalOpen,
    handleCloseHistoryDetailsModal,
    callDetails,
    recordingUrl,
    isPrivileged,
    transcriptData,
    transcriptSummary,
    detectedKeywords,
  } = useKeywordDetailHook(
    {
    classes,
    keywordDictionaryId,
    selectedKeyword,
    onError,
  });

  const formattedDate = dateAdded
    ? formatDatetime(dateAdded, getSelectedLocationTimeZone(), DATE_FORMAT.YYYY_MM_DD)
    : DATE_FORMAT.YYYY_MM_DD;

  return (
    <>
      <CallHistoryDetailsDrawer
        isDetailModalOpen={isDetailModalOpen}
        handleCloseHistoryDetailsModal={handleCloseHistoryDetailsModal}
        {...callDetails}
        recordingUrl={recordingUrl}
        isPrivileged={isPrivileged}
        transcriptData={transcriptData}
        transcriptSummary={transcriptSummary}
        detectedKeywords={detectedKeywords}
      />
      <Box className={classes.root}>
        <Box className={classes.keywordDetailContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography className={classes.keywordLabelText} variant="body2">
                Date Added:
              </Typography>
              <Typography className={classes.keywordLabelText} variant="body2">
                Added By:
              </Typography>
              <Typography className={classes.keywordLabelText} variant="body2">
                Monitored:
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography id="added-date" className={classes.keywordLabelValue} variant="body2">
                {formattedDate}
              </Typography>
              <Typography id="added-by" className={classes.keywordLabelValue} variant="body2">
                <BadgeIcon className={classes.icon} />
                {staffName ? staffName : 'staff name'}
              </Typography>
              <Typography id="monitor-status" className={classes.keywordLabelValue} variant="body2">
                {isMonitored === 'true' ? 'True' : 'False'}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.tableContainer}>
          <MaterialTable
            title=""
            tableRef={tableRef}
            columns={tableColumnsRef.current}
            data={getKeywordDetails}
            options={{
              debounceInterval: 1000,
              filtering: true,
              pageSize: 10,
              thirdSortClick: false,
              search: false,
              toolbar: false,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default withStyles(styles)(KeywordDetail);
