import React from 'reactn'
import { Paper, AppBar, Toolbar, IconButton, Typography, Dialog, withStyles } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DetailsListing from './details-listing/details-listing';
import CloseIcon from '@material-ui/icons/Close'
import { getResourceDetails } from '../../../util/APIUtils';

const styles = () => ({
    appBar: {
        position: 'relative'
    }
});



const paxen_columns = [
    {
        title: 'Lesson',
        field: 'title'
    },
    {
        title: 'Item ID',
        field: 'itemid'
    },
    {
        title: 'Time Spent',
        field: 'seconds'
    },
    {
        title: 'Grade acheived',
        field: 'grades_achieved'
    },
    {
        title: 'Attempts',
        field: 'grades_completed'
    },
    {
        title: 'Activity Date',
        field: 'activity_date'
    }
]

class CourseDetails extends React.Component {

    state = {
        courseDetails: {},
        isLoading: false,
        noData: false
    };

    /**
     * @name getCourseDetails
     * @param {number} userId
     * @param {number} resourceId
     * @desc Fetches course details on behalf of userId & resourceId
     * @return {void}
     */
    getCourseDetails = (userId, resourceId) => {
        this.setState({
            isLoading: true,
        });
        this.setGlobal({
            isLoading: true
        });
        getResourceDetails(userId, resourceId)
            .then(
                (_successLog) => {
                    if (!_successLog || Object.keys(_successLog).length === 0) {
                        this.setState({
                            courseDetails: {
                                id: resourceId,
                            },
                            noData: true,
                            isLoading: false
                        });
                        this.setGlobal({
                            isLoading: false
                        });
                        return;
                    }
                    this.setState({
                        courseDetails: _successLog,
                        isLoading: false,
                        noData: false
                    });
                    this.setGlobal({
                        isLoading: false
                    });
                }
            );
    };

    componentDidUpdate(props, state) {
        if (this.props.course && this.props.course.id !== this.state.courseDetails.id && !this.state.isLoading) {
            this.getCourseDetails(this.props.user.userId, this.props.course.id);
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <Dialog fullScreen open={this.props.open} onClose={this.props.close}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.props.close} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        {this.props.course ?
                            <Typography variant="h6" style={{ flexGrow: 1, color: 'white' }}>
                                {this.props.course.itemName}
                            </Typography>
                            :
                            <Typography variant="h6" style={{ flexGrow: 1, color: 'white' }}>
                                Loading Course Details ...
                        </Typography>
                        }
                    </Toolbar>
                </AppBar>
                <DetailsListing
                    isLoading={this.state.isLoading}
                    isActive={this.state.courseDetails.active}
                    listingItems={this.state.noData ? [] : [this.state.courseDetails]}
                    courseId={this.state.courseDetails.id}
                />
            </Dialog>
        )
    }
}

export default withStyles(styles)(CourseDetails)