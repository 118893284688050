import { withStyles } from '@material-ui/styles';
import React from 'react';

const styles = theme => ({
    icon: {
        fontSize: 22,
    },
    active: {
        color: theme.palette.primary.cobalt
    }
});

export const UserIcon = withStyles(styles)((props) => {
    return <i 
    {...props}
    className={["fas", "fa-user-graduate", props.classes.icon, props.isActive ? props.classes.active : null].join(" ")}></i>;
});

