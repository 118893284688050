import { StyleSheet, Font } from "@react-pdf/renderer";
import Roboto from "./../../users/learner/transcript/pdf/fonts/Roboto/Roboto-Regular.ttf";
import RobotoBold from "./../../users/learner/transcript/pdf/fonts/Roboto/Roboto-Bold.ttf";
import RobotoBlack from "./../../users/learner/transcript/pdf/fonts/Roboto/Roboto-Black.ttf";
import RobotoMedium from "./../../users/learner/transcript/pdf/fonts/Roboto/Roboto-Medium.ttf";

Font.register({
  family: "Roboto",
  src: Roboto
});

Font.register({
  family: "RobotoBold",
  src: RobotoBold
});

Font.register({
  family: "RobotoMedium",
  src: RobotoMedium
});

Font.register({
  family: "RobotoBlack",
  src: RobotoBlack
});

export default StyleSheet.create({
  root: {
    display: "flex",
    webkitPrintColorAdjust: "exact",
    colorAdjust: "exact",
    flexDirection: "column",
    fontFamily: "Roboto"
  },
  headerMgn: {
    padding: 10
  },
  brandLogo: {
    width: 80,
    margin: "10px 0px 0px 25px",
  },
  heading: {
    fontSize: 18,
    fontFamily: "RobotoMedium",
    textAlign: 'center',
    margin: "10px 0px",
  },
  username: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: "RobotoBold",
    textAlign: 'center',
    margin: "10px 0px"
  },
  userInfoHead: {
    fontSize: 10,
    fontFamily: "RobotoBold",
    margin: '5px 25px'
  },
  userInfoDetail: {
    fontSize: 12,
    fontFamily: "Roboto",
    margin: '5px 25px'
  },
  userInfoDate: {
    fontSize: 10,
    fontFamily: "Roboto",
    margin: '5px 25px'
  },
  pagination: {
    position: "absolute",
    bottom: 10,
    right: 20,
    fontSize: 12,
    fontFamily: "Roboto",
    margin: '5px 25px',
    textAlign: "right"
  },
});