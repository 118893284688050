import { alignItems, display, flexDirection, fontWeight, maxHeight, overflowX, width } from "styled-system";

export default (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
  },

  keywordDetailContainer: {
    width: '363px',
    height: '88px',
    gap: '12px',
    padding: '0px 24px 24px 116px',
  },

  keywordLabelText: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
  },

  keywordLabelValue: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },

  icon: {
    '&.MuiSvgIcon-root': {
      fontSize: '16px',
    },
    marginRight: theme.spacing(0.5),
  },

  tableContainer: {
    maxHeight: '856px',
    padding: '0px 24px 0px 24px',
    gap: '10px',
    overflowY: 'auto',
  },

  visibilityIcon: {
    color: theme.palette.primary.blue.main
  },
});