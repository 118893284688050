import React from 'react';
import { withUnleash } from '../../../../../v2/core/unleash';
import { UnleashService } from '../../../../../v2/services';
import { AppBar, Grid, Icon, Tab, Tabs, withStyles } from '@material-ui/core';
import UnleashProvider from '../../../../../v2/core/unleash/unleashProvider';
import { COMMUNICATION_TABS } from '../../../../../v2/services/constants';
import CommunicationAnnouncements from '../../components/announcements';
import styles from './audit.style';

const Audit = (props) => {
  const {
    unleashProps,
    classes,
    onTabChange,
    selectedTab,
    filters,
    location,
    timezoneName,
    exportCommunicationList,
    handleSnippetDialog,
    MessagesTable,
    filteredCommunicationColumn,
    exportPermission,
    userId,
    range,
    TabSelector,
    isTopBarShown = true
  } = props;
  return (
    <>
      <UnleashProvider flagName={UnleashService.FLAGS.ANNOUNCEMENT_AUDIT}>
        <Grid container item className={classes.content}>
          {isTopBarShown && (
            <AppBar position="static" color="default"
            id="top-bar"
            className={classes.appBar}>
              <Tabs
                value={selectedTab}
                onChange={onTabChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="FnF Tabs"
                className={classes.tabsContainer}
              >
                <Tab
                  label="Messages"
                  classes={{
                    wrapper: classes.tabWrapper,
                    labelIcon: classes.tabLabelIcon,
                  }}
                  icon={<Icon>contact_phone</Icon>}
                  id="FnF-messages-Tab"
                />
                <Tab
                  label="Announcements"
                  classes={{
                    wrapper: classes.tabWrapper,
                    labelIcon: classes.tabMessageIcon,
                  }}
                  icon={<Icon>message</Icon>}
                  id="FnF-Announcements-Tab"
                />
              </Tabs>
            </AppBar>
          )}
          {selectedTab === COMMUNICATION_TABS.messages && (
            <MessagesTable
              classes={classes}
              exportPermission={exportPermission}
              filteredCommunicationColumn={filteredCommunicationColumn}
            />
          )}
          {selectedTab === COMMUNICATION_TABS.announcement && (
            <CommunicationAnnouncements
              data-testid="communication-announcements"
              timezoneName={timezoneName}
              location={location}
              filters={filters}
              handleSnippetDialog={handleSnippetDialog}
              exportCommunicationList={exportCommunicationList}
              userId={userId}
              range={range}
              TabSelector={TabSelector}
            />
          )}
        </Grid>
      </UnleashProvider>

      {!unleashProps.isFlagEnabled() && (
        <MessagesTable
          classes={classes}
          exportPermission={exportPermission}
          filteredCommunicationColumn={filteredCommunicationColumn}
        />
      )}
    </>
  );
};

export default withStyles(styles)(withUnleash(Audit, UnleashService.FLAGS.ANNOUNCEMENT_AUDIT));
