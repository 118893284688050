import { useState, useEffect, useRef } from 'react';
import { VOICE_CALL } from '../../../../constants/voice-call-constants';
import { updateManagementAttributes, getManagementAttributes } from '../../../../../../util/APIUtils';

const useCommunicationSecuritySettingsHook = ({ locationId }) => {
  const [isVoiceIdRegistrationEnabled, setIsVoiceIdRegistrationEnabled] = useState({});
  const modalRef = useRef(null);
  useEffect(() => {
    fetchContactManagementAttributes();
  }, [locationId]);

  useEffect(() => {
    if (Object.keys(isVoiceIdRegistrationEnabled).length > 0 && isVoiceIdRegistrationEnabled.attributeValue !== undefined) {
      handleUpdateVoiceIdRegistrationAttribute();
    }
  }, [isVoiceIdRegistrationEnabled]);

  const fetchContactManagementAttributes = async () => {
    try {
      setIsVoiceIdRegistrationEnabled({});
      const managementAttributes = await getManagementAttributes(locationId);
      if (managementAttributes?.locationAttributes && Array.isArray(managementAttributes.locationAttributes)) {
        const foundAttribute = managementAttributes.locationAttributes.find(
          (attribute) => attribute.attributeName === VOICE_CALL.VOICE_ID_REGISTRATION_ATTRIBUTE_KEY
        );

        if (foundAttribute) {
          setIsVoiceIdRegistrationEnabled({
            attributeId: foundAttribute.attributeId,
            attributeName: foundAttribute.attributeName,
            attributeValue: foundAttribute.attributeValue === 'true',
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeVoiceIdRegistrationAttribute = (event) => {
    event.target.checked
      ? setIsVoiceIdRegistrationEnabled({
          ...isVoiceIdRegistrationEnabled,
          attributeValue: event.target.checked,
        })
      : handleDisableVoiceIdRegistrationModal();
  };
  const handleUpdateVoiceIdRegistrationAttribute = async () => {
    try {
      await updateManagementAttributes({
        locationId,
        locationAttributes: [isVoiceIdRegistrationEnabled],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const disableVoiceIdRegistrationConfirmation = () => {
    setIsVoiceIdRegistrationEnabled({
      ...isVoiceIdRegistrationEnabled,
      attributeValue: false,
    });
    handleDisableVoiceIdRegistrationModal();
  };
  const handleDisableVoiceIdRegistrationModal = () => {
    modalRef.current.toggleModal();
  };
  return {
    modalRef,
    isVoiceIdRegistrationEnabled,
    handleChangeVoiceIdRegistrationAttribute,
    handleDisableVoiceIdRegistrationModal,
    disableVoiceIdRegistrationConfirmation,
  };
};

export default useCommunicationSecuritySettingsHook;
