export default (theme) => ({
    datatableBlueBox: {
        color: theme.palette.primary.cobalt,
    },
    chip: {
        backgroundColor: theme.palette.primary.cobalt,
        padding: '5px 10px',
        borderRadius: 3,
        marginLeft: 5
    },
    chipText: {
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: theme.palette.primary.white
    },
    toolbarPaper: {
        backgroundColor: 'white',
        boxSizing: 'border-box',
        width: '100%',
        padding: 30
    },
    selectionList: {
        display: 'flex',
        marginBottom: 16,
      },
      selectionItem: {
        width: 'initial',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        height: 40,
        fontSize: 18,
        color: theme.palette.primary.navy,
        fontWeight: 'bold',
        '&:first-child': {
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
        },
        '&:last-child': {
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
        },
      },
      selectedItem: {
        backgroundColor: theme.palette.primary.navy,
        color: 'white',
        '&:hover': {
          backgroundColor: theme.palette.primary.navy,
        },
      },
      selectionListHeading: {
        color: theme.palette.colors.ceruleanMain
      }
});