export default (theme) => ({
  mainDescription: {
    '&.MuiTypography-root': {
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: '24.2px',
      color: theme.palette.primary.main,
      marginBottom: '5px',
    },
  },
  description: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.colors.grey110,
  },
  icon: {
    color: theme.palette.colors.redMain,
    width: '32px',
    height: '32px',
    marginTop: '6px',
  },
});
