import { FACILITY_LOCATION_SETTINGS_PERMISSIONS, FACILITY_MESSAGING_PERMISSIONS, LOCATIONS, setFacilityLocationSettingsPermission, setFacilityMessagePermission } from "../../../../constants";
import { getPermittedFacilities } from "../../../../util/APIUtils";
import { SETTINGS_LOCATION_TYPE } from "../../../../v2/services/constants";
import { ManagementService } from "../../../management/management.service";
import moment from 'moment-timezone';

export const toFixed = (num, fixed) => {
  // eslint-disable-next-line
  var re = new RegExp(`^-?\\d+(?:\.\\d{0,${fixed || -1}})?`);
  return num % 1 !== 0 ? num.toString().match(re)[0] : num.toFixed(fixed);
};

export const isContentAreaExempted = (contentAreaName) => {
  const exemptContentAreas = ["Communication", "General"];
  if (exemptContentAreas.indexOf(contentAreaName) > -1) {
    return true;
  } else {
    return false;
  };
};

export const handleMessageFacilityPermission = async (locations, setGlobal, global) => {
  let { isInitiallyFetched, facilities } = FACILITY_MESSAGING_PERMISSIONS;
  let allowedLocations = [];
  if (isInitiallyFetched) {
    allowedLocations = facilities;
  } else {
    setGlobal({
      permissionLoadingStack: global.permissionLoadingStack + 1
    });
    allowedLocations = await getPermittedFacilities("access_messages");
    setGlobal({
      permissionLoadingStack: global.permissionLoadingStack - 1
    });
  }
  FACILITY_MESSAGING_PERMISSIONS.isInitiallyFetched = true;
  setFacilityMessagePermission(allowedLocations);
  const isLocationAssignedToUser = !!allowedLocations.find(
    (locationId) => {
      return !!locations.find(
        (location) => location.locationId === locationId
      )
    }
  );
  if (isLocationAssignedToUser) {
    setGlobal({
      showMessageTile: true
    });
  }
  checkMessagingFeatureFlag(setGlobal);
};


export const handleLocationSettingsPermission = async (locations, setGlobal, global, isSuperAdmin = false) => {
  let { isInitiallyFetched, facilities } = FACILITY_LOCATION_SETTINGS_PERMISSIONS;
  let allowedLocations = [];
  if (isInitiallyFetched) {
    allowedLocations = facilities;
  } else {
    setGlobal({
      permissionLoadingStack: global.permissionLoadingStack + 1
    });
    allowedLocations = await getPermittedFacilities("access_location_settings");
    setGlobal({
      permissionLoadingStack: global.permissionLoadingStack - 1
    });
  }
  FACILITY_LOCATION_SETTINGS_PERMISSIONS.isInitiallyFetched = true;
  setFacilityLocationSettingsPermission(allowedLocations);
  const isLocationAssignedToUser = !!allowedLocations.find(
    (locationId) => {
      return !!locations.find(
        (location) => location.locationId === locationId
      )
    }
  );
  if (isLocationAssignedToUser || isSuperAdmin) {
    setGlobal({
      showLocationSettingsTile: true
    });
  } else {
    setGlobal({
      showLocationSettingsTile: false
    });
  };
};

export const checkMessagingFeatureFlag = (setGlobal, locations = LOCATIONS) => {
  const isLocationHasActiveMessage = locations.some(
    
    (location) => {
      return (location.activeFeatures || []).indexOf(ManagementService.MANAGEMENT_FEATURE_ENUM.Messaging) > -1
    }
  );
  if (isLocationHasActiveMessage) {
    setGlobal({
      showMessageTile: true
    });
  } else {
    setGlobal({
      showMessageTile: false
    });
  };
};



export default null;

export const parseTimeToNewZone = (actualTimestamp, newTimezone, format) => {
  if (format) {
    return `${moment.utc(actualTimestamp).tz(newTimezone).format(format)}`;
  } else {
    return `${moment.utc(actualTimestamp).tz(newTimezone).format('MMM D YYYY hh:mm A')} (${newTimezone})`;
  }
};

export const isDateSame = (date1, date2) => {
  const d1 = moment(date1).format('LL');
  const d2 = moment(date2).format('LL');
  return moment(d1).isSame(d2);
};

export const getUSAFormattedDate = (date, format = "MM/DD/YYYY") => moment(date).format(format) ;
export const getFormattedDate = date => moment(date).format("MMMM Do YYYY") ;
export const getFormattedTime = date => moment(date).format("h:mm A") ;
export const getCurrentDate = (format = "LL") => moment().format(format);

export const convertToID = (string) => {
  // Remove spaces
  let stringWithoutSpaces = string.replace(/\s+/g, '_');

  // Convert to lowercase
  let lowercaseString = stringWithoutSpaces.toLowerCase();

  // Replace special characters or symbols with an underscore
  let sanitizedString = lowercaseString.replace(/[^\w\s]/gi, '_'); // Replace non-alphanumeric characters with underscore

  return sanitizedString;
}

//Setting pre selected default location if admin has access to only one location.
export const setDefaultLocation = (locationData, callback) => {
  if(locationData.length === 1) {
    if(locationData[0].children && locationData[0].children.length === 1) {
      callback(locationData[0].children[0], SETTINGS_LOCATION_TYPE.FACILITY);
    }
  }
}
