import { Button } from '@material-ui/core';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';
import { useEffect, useState } from 'react';
import React from 'reactn';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';
import Hoverable from '../../../hoverable';
import { VoiceCallService } from '../../services/voice-call.service';
import { columnSortByOrderDirection } from '../../../../util/Helpers';
import VoiceInsightChip from '../voice-insight-chip/voice-insight-chip';
import { disconnectVoiceSocket, establishVoiceSocketConnection, joinVoiceRooms, registerVoiceAction } from '../../../../v2/socket/namespaces/voice-socket';
export const useLiveCallsListComponentHook = (locationId, isDataRefresh, setDataRefresh, isKeywordFlagEnabled) => {
  const defaultPageSize = 10;
  const voiceCallService = new VoiceCallService();
  const [isLoading, setLoading] = useState(false);
  const [isDetailModalOpen, setDetailModalState] = useState(false);
  const [callData, setCallData] = useState({});
  const [callStatus, setCallStatus] = useState('');
  const logger = new ConsoleLogger('thriveChime', LogLevel.ERROR);
  const deviceController = new DefaultDeviceController(logger);
  const [attendeeId, setAttendeeId] = useState('');
  const [meetingId, setMeetingId] = useState('');
  const [liveCallList, setLiveCallList] = useState([]);
  const [transactionId, setTransactionId] = useState('');
  const [joinedRooms, setJoinedRooms] = useState([]);
  const [stateFilters, setStateFilters] = useState({
    learnerFirstName: '',
    learnerDocId: '',
    relationshipType: '',
    contactName: '',
  });
  const [allowedFilters, setAllowedFilters] = useState([
    'learnerFirstName',
    'learnerDocId',
    'relationshipType',
    'contactName',
  ]);
  const tableRef = React.useRef();
  const [muteMic, setMuteMic] = useState(true);
  const meetingManager = useMeetingManager();

  useEffect(()=>{
    establishVoiceSocketConnection();
  },[])

  
  useEffect(() => {
    if (isDataRefresh) {
      refreshTable();
      setDataRefresh(false);
    }
  }, [isDataRefresh]);

  useEffect(() => {
    if (locationId) {
      refreshTable();
    }
  }, [locationId]);

  useEffect(() => {
    if (liveCallList && liveCallList.length > 0) {
      const meetingIds = liveCallList.map((call) => call.meetingId);
      // Find rooms that are not yet joined
      const roomsToJoin = meetingIds.filter((meetingId) => !joinedRooms.includes(meetingId));
      if (roomsToJoin.length > 0) {
        joinVoiceRooms(roomsToJoin); 
        setJoinedRooms((prevRooms) => [...prevRooms, ...roomsToJoin]);
      }
    }
  }, [liveCallList]);
  

  useEffect(() => {
    registerVoiceAction({
      event: 'voice_keyword_detected',
      action: handleVoiceKeywordDetection,
    });
     // Cleanup function to disconnect socket and perform any other cleanup on component unmount
     return () => {
      console.log('Cleaning up, disconnecting socket...');
      setJoinedRooms([]);
      disconnectVoiceSocket(); 
    };
  }, []);

  function handleVoiceKeywordDetection(data){
    const meetingId = data?.meeting_id;
    tableRef.current.onQueryChange({
      ...tableRef.current.state.query,
      isInternal: true,
      meetingId: meetingId,
    });
  }



  const onListenClick = async (contact) => {
    try {
      const { meetingId } = contact;
      setLoading(true);
      setCallData(contact);
      setDetailModalState(true);
      const response = await voiceCallService.adminJoinCall(meetingId);
      if (response.status === 'FAILURE') {
        setCallStatus('Call Ended');
      } else {
        const { meetingResponse, attendeeResponse, transactionId } = response;
        setAttendeeId(attendeeResponse.attendeeId);
        setMeetingId(meetingResponse.meetingId);
        setTransactionId(transactionId);
        const meetingSessionConfiguration = new MeetingSessionConfiguration(meetingResponse, attendeeResponse);
        const meetingSession = new DefaultMeetingSession(meetingSessionConfiguration, logger, deviceController, {
          video: false,
          audio: true,
        });
        const observer = {
          audioVideoDidStart: () => {
            console.log('Started');
          },
          audioVideoDidStop: (sessionStatus) => {
            // See the "Stopping a session" section for details.
            console.log('Stopped with a session status code: ', sessionStatus.statusCode());
            setDetailModalState(false);
            setDataRefresh(true);
            meetingManager.leave();
          },
          audioVideoDidStartConnecting: (reconnecting) => {
            if (reconnecting) {
              // e.g. the WiFi connection is dropped.
              console.log('Attempting to reconnect');
            }
          },
        };
        await meetingManager.join(meetingSessionConfiguration);
        meetingManager.meetingSession.audioVideo.realtimeMuteLocalAudio();
        await meetingManager.start();
        meetingManager.meetingSession.audioVideo.addObserver(observer);
      }
    } catch (error) {
      console.error(error);
      setDetailModalState(false);
    } finally {
      setLoading(false);
    }
  };

  const handleMuteUnmuteMic = (toggleMuteMic) => {
    setMuteMic(toggleMuteMic);
    if (toggleMuteMic) {
      meetingManager.meetingSession.audioVideo.realtimeMuteLocalAudio();
    } else {
      meetingManager.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    }
  };

  const setTableFilters = (query) => {
    let filters = { ...stateFilters };
    for (let i = 0; i < query.filters.length; i++) {
      let item = query.filters[i];
      if (allowedFilters.includes(item.column.field)) {
        let value = item.value;
        filters[item.column.field] = value;
      }
    }
    return filters;
  };
  const createRequestPayload = (query) => {
    const selectedFilters = setTableFilters(query);
    columnSortByOrderDirection(tableColumns, query.orderBy, query.orderDirection);
    const tbody = {
      learnerFirstName: selectedFilters.learnerFirstName,
      learnerDocId: selectedFilters.learnerDocId,
      relationshipType: selectedFilters.relationshipType,
      firstName: selectedFilters.contactName,
      lastName: selectedFilters.contactName,
    };
    return { tbody };
  };



  const getLiveCallsListData = async (query) => {
    if (!locationId) return;
  
    const { orderBy, orderDirection, page, pageSize, isInternal, meetingId } = query;
  
    // Handle internal query
    if (isInternal && meetingId) {
      const updatedCalls = liveCallList.map(call => {
        if (call.meetingId === meetingId) {
          return { ...call, voiceCallEvents: ['Keyword Detected'] }; // Avoid mutating objects directly
        }
        return call;
      });
      setLiveCallList(updatedCalls); // Update the live call list with updated data
  
      return {
        data: updatedCalls,
        page,
        totalCount: updatedCalls.length,
      };
    }
  
    // External data fetch from API
    const { tbody } = createRequestPayload(query);
    
    setLiveCallList([]); // Clear existing data
    setLoading(true); // Start loading state
  
    try {
      const data = await voiceCallService.getLiveCallsList({
        locationId,
        status: 'in_Progress',
        pageNumber: page,
        size: pageSize,
        sortBy: orderBy ? orderBy.field : '',
        sortOrder: orderDirection ? orderDirection : '',
        tbody,
      });
  
      const { content, pageable, totalElements } = data;
  
      if (content) {
        setLiveCallList(content); // Update live calls with fetched content
        setLoading(false); // End loading state
  
        return {
          data: content,
          page: pageable.pageNumber,
          totalCount: totalElements,
        };
      } else {
        throw new Error('Data fetch error');
      }
    } catch (error) {
      setLoading(false); // Ensure loading state ends on error
      console.error('Error fetching live calls:', error);
      throw error; // Throw the error to handle it in the calling function if needed
    }
  };
  

  const refreshTable = () => {
    tableRef && tableRef.current && tableRef.current.onQueryChange({ ...tableRef.current.state.query,isInternal: false });
  };

  let tableColumns = [
    {
      title: 'Resident Name',
      field: 'learnerFirstName',
      filtering: true,
      sorting: true,
      defaultSort: 'asc',
      cellStyle: {
        minWidth: 150,
      },
      render: (rowData) => (
        <Hoverable title={rowData.learnerFirstName}>{rowData.learnerFirstName + ' ' + rowData.learnerLastName}</Hoverable>
      ),
    },
    {
      title: 'DOC ID',
      field: 'learnerDocId',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 100,
      },
      render: (rowData) => <Hoverable title={rowData.learnerDocId}>{rowData.learnerDocId}</Hoverable>,
    },
    {
      title: 'Contact Name',
      field: 'contactName',
      filtering: true,
      sorting: true,
      render: (rowData) => {
        const { firstName, lastName } = JSON.parse(rowData.contactInfo);
        return <Hoverable title={firstName}>{firstName + ' ' + lastName}</Hoverable>;
      },
    },
    {
      title: 'Relationship',
      field: 'relationshipType',
      filtering: true,
      sorting: true,
      render: (rowData) => {
        const { relationshipType } = JSON.parse(rowData.contactInfo);
        return <Hoverable title={relationshipType}>{relationshipType}</Hoverable>;
      },
    },
    ...(isKeywordFlagEnabled
      ? [
          {
            title: 'Call Insights',
            field: 'voiceCallEvents',
            filtering: false,
            sorting: false,
            render: (rowData) => {
              const { voiceCallEvents } = rowData;
              return (
                voiceCallEvents &&
                voiceCallEvents.length > 0 && (
                  <>
                    {voiceCallEvents.map((event, index) => (
                      <VoiceInsightChip key={index} label={event} />
                    ))}
                  </>
                )
              );
            },
          },
        ]
      : []),
    {
      title: 'Actions',
      sorting: false,
      render: (rowData) => {
        const isPrivileged = JSON.parse(rowData.contactInfo).privileged || false;
        return (
          <>
            {isPrivileged ? (
              <Button disabled id="privileged_call">
                <CustomIcons icon={icons.HeadphoneIcon} />
                Privileged
              </Button>
            ) : (
              <Button onClick={() => onListenClick(rowData)} id="voice_call">
                <CustomIcons icon={icons.HeadphoneIcon} />
                Listen
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return {
    tableColumns,
    getLiveCallsListData,
    isLoading,
    defaultPageSize,
    isDetailModalOpen,
    callData,
    attendeeId,
    meetingId,
    callStatus,
    setDetailModalState,
    tableRef,
    handleMuteUnmuteMic,
    muteMic,
    transactionId,
    liveCallList
  };
};
