import React, { useEffect, useState } from 'react';
import Hoverable from '../../../../hoverable';
import { fetchAnnouncementsThreads, fetchAnnouncementsThreadsForUser } from '../../../../../util/APIUtils';
import { parseTimeToNewZone } from '../../../../users/learner/transcript/utils';
import { Button, Tooltip } from '@material-ui/core';
import { CustomIcons, icons } from '../../../../../v2/shared/components/custom-icons';

export const useAnnouncementsHook = props => {
  const { classes } = props;
  const [beforeId, setBeforeId] = useState(null);
  const [afterId, setAfterId] = useState(null);
  const [page, setPage] = useState(0);
  const [ limit, setLimit ] = useState(10);
  const [announcementCount, setAnnouncementCount] = useState(0);

  const announcementsOrderBy = 'recent_announcement';

  const getAnnouncements = query => {
    return new Promise((resolve, reject) => {
      let _afterId = null;
      let _beforeId = null;
      if (query.page !== 0) {
        if (page > query.page) {
          _afterId = afterId;
        } else if (page < query.page) {
          _beforeId = beforeId;
        }
      }
      const locationID = props.filters.location || props.location.active;
      const responsePromise = props.userId ?
      fetchAnnouncementsThreadsForUser(props.userId, announcementsOrderBy, limit, _beforeId, props.range) :
      fetchAnnouncementsThreads(locationID, announcementsOrderBy, limit, _beforeId, _afterId);
  
      responsePromise.then(
        allAnnouncements => {
          const { announcements, announcementCount } = allAnnouncements;
          setAnnouncementCount(announcementCount);
          if (announcements && announcements.length) {
            setBeforeId(announcements[announcements.length - 1].id);
            setAfterId(announcements[0].id);
          }
          resolve({
            data: announcements,
            page: query.page,
            totalCount: announcementCount
          });
        },
        error => {
          resolve({
            data: []
          });
        }
      );
    });
  };

  const getDataForExportCsv = async () => {
    const locationID = props.filters.location || props.location.active;
    return (await fetchAnnouncementsThreads(locationID, announcementsOrderBy, announcementCount, null, null, props.userId))
      .announcements;
  };

  const getIcon = ({ recipientGroupsNames, locationName }) => {
    return locationName
      ? icons.Location
      : recipientGroupsNames && recipientGroupsNames.length
      ? icons.GroupIcon
      : icons.UserIcon;
  };

  const viewFullAnnouncement = rowData => {
    props.handleSnippetDialog(true, { ...rowData, timezoneName: props.timezoneName }, true);
  };

  const onRowsChange = (rows) => {
    setLimit(rows);
  }

  const getRecipients = (locationName, recipientUserInfo) => {
    if (locationName) return locationName;
    //space available for 3,4 users on table to show
    let names = recipientUserInfo.slice(0, 4).map(user => `${user.firstname} ${user.lastname} (${user.docId})`);
    return names.join(', ');
  };

  const onPageChange = currentPage => {
    setPage(currentPage);
  };

  const getAnnouncementColumns = () => {
    return [
      {
        title: 'Sender',
        field: 'senderPersonName',
        render: rowData => (
          <Hoverable title={rowData.senderPersonName}>
            <Button color="primary">
              <CustomIcons icon={icons.VerifiedUserIcon} />
              {rowData.senderPersonName}
            </Button>
          </Hoverable>
        )
      },
      {
        title: 'Recipient',
        field: 'recipient',
        render: ({ senderPersonName, recipientGroupsNames, recipientUserInfo, locationName }) => (
          <div className={classes.recipientRender}>
            <Hoverable title={senderPersonName}>
              <Tooltip
                title={
                  locationName ||
                  recipientUserInfo.map(user => `${user.firstname} ${user.lastname} (${user.docId})`).join(',')
                }
              >
                <Button color="primary">
                  <CustomIcons className={classes.iconMargin} icon={getIcon({ recipientGroupsNames, locationName })} />
                  {getRecipients(locationName, recipientUserInfo)}
                </Button>
              </Tooltip>
            </Hoverable>
          </div>
        )
      },
      {
        title: 'Date',
        field: 'createdDate',
        render: rowData => (
          <Hoverable title={parseTimeToNewZone(rowData.createdDate, props.timezoneName)}>
            {parseTimeToNewZone(rowData.createdDate, props.timezoneName)}
          </Hoverable>
        )
      },
      {
        title: 'Title',
        field: 'title',
        render: rowData => <Hoverable title={rowData.title}>{rowData.title}</Hoverable>
      },
      {
        title: 'Announcement Preview',
        field: 'body',
        render: rowData => (
          <Hoverable className={classes.announcementBody} title={rowData.body}>
            {rowData.body && rowData.body.slice(0, 25)}...{' '}
            <span onClick={() => viewFullAnnouncement(rowData)} className={classes.announcementView}>
              Read Full Announcement
            </span>
          </Hoverable>
        )
      }
    ];
  };

  return {
    limit,
    page,
    announcementCount,
    onRowsChange,
    onPageChange,
    getAnnouncements,
    getAnnouncementColumns,
    getDataForExportCsv,
    setPage
  };
};
