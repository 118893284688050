import { useState } from 'react';
import { clearDevicePairing } from '../../../../../util/APIUtils';
import { SUCCESS, USER_PAIR_DEVICES_ERROR } from '../../../../../v2/services/constants';

export const useResetDeviceHook = ({modalRef, learnerId}) => {
  const [isResetLoading, setResetLoading] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const [error, setError] = useState('');

  /**
   * @desc Reset's user device pairing, a new pairing will be
   * created with user upon next login.
   * @return void
   */
  const resetUserDevicePairing = async () => {
    try {
      setResetLoading(true);
      const { status } = await clearDevicePairing(learnerId);
      if (status === SUCCESS) {
        toggleConfirmationModal();
        setSnackBar(!snackBar);
      } else {
       setError(USER_PAIR_DEVICES_ERROR);
      }
    } catch (e) {
      setError(USER_PAIR_DEVICES_ERROR);
    } finally {
      setResetLoading(false);
    }
  };

  /**
   * @desc Toggles confirmation modal dialog
   * @return void
   */
  const toggleConfirmationModal = () => {
    modalRef.current && modalRef.current.toggleModal();
  };

  return {
    isResetLoading,
    resetUserDevicePairing,
    toggleConfirmationModal,
    setSnackBar,
    setError,
    snackBar,
    error,
  };
};
