export default (theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        paddingLeft: 10
    },
    headLeft: {
        color: theme.palette.primary.white,
        width: '100%'
    },
    closeBtn: {
        color: theme.palette.primary.white
    },
    headHeading: {
        color: theme.palette.primary.white
    },
    formControl: {
        width: "100%"
    },
    blueIconContainer: {
        "& svg, & i": {
            marginRight: 10,
            color: theme.palette.primary.main
        }
    },
    dialog: {
        width: "100%"
    },
    formGroup: {
        marginTop: 10,
        position: 'relative'
    },
    divider: {
        backgroundColor: theme.palette.primary.battleshipGrey,
        margin: "20px -24px"
    },
    linkingText: {
        ...theme.typography.t12,
        ...theme.typography.medium,
        color: theme.palette.primary.battleshipGrey
    },
    selectedChip: {
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary.paleGrey,
        borderRadius: 3,
        marginRight: 10,
        padding: "5px 10px",
        marginBottom: 10
    },
    chipMainText: {
        ...theme.typography.t12,
        ...theme.typography.medium
    },
    chipSubText: {
        ...theme.typography.t9,
        ...theme.typography.medium
    },
    chipCancelBtn: {
        ...theme.typography.t14,
        marginLeft: 5
    },
    dialogActionRoot: {
        justifyContent: "center"
    },
    circularProgress: {
        position: 'absolute',
        right: 20,
        top: 20
    }
});