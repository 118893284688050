import { USER_CONTEXT } from "../../constants";
import { omnisearch, postAnnouncement } from "../../util/APIUtils";

export class MessageService {
    static SUGGESTED_USER_DEBOUNCE = null;
    static MESSAGE_ELEMENT = 'messaging-event';
    static SOCKET_DEBOUNCE_INTERVAL = 2000;
    static NEW_MSG_EVENT = 'new_staff_message';

    getSuggestedUsers = (searchText) => {
        return new Promise((resolve) => {
            clearTimeout(MessageService.SUGGESTED_USER_DEBOUNCE);
            MessageService.SUGGESTED_USER_DEBOUNCE = setTimeout(async () => {
                const { users } = await omnisearch({
                    requestorId: USER_CONTEXT.personId,
                    filterType: ['Person'],
                    filterRoles: ['Learner'],
                    filterString: searchText
                });
                resolve(users);
            }, 2000)

        });
    }

    createAnnouncement = (payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const announcementResponse = await postAnnouncement(payload);
                resolve(announcementResponse);
            } catch (e) {
                console.log(e);
            }
        });

    };

    getAnnouncementData = (messages, selectedContact = {}) => {
        return new Promise((r) => {
            setTimeout(() => {
                const { read, unread } = messages;
                r([{
                    ...selectedContact,
                    announcementId: selectedContact.id
                }]);
            });
        });
    }
}