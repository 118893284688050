import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import IdleTimer from 'react-idle-timer';
import { IDLE_TIMEOUT } from '../../constants';

let timerInterval = null;
function clearTimerInterval() {
  if (timerInterval) {
    clearInterval(timerInterval);
  }
}

function IdleTimeout({ handleLogout }) {
  const TIMEOUT_WAIT = 60; // SECONDS
  const [timer, setTimer] = React.useState(TIMEOUT_WAIT);
  const [isOpen, setIsOpen] = React.useState(false);
  const [idleTimeout, setIdleTimeout] = React.useState(IDLE_TIMEOUT * 60000);
  const idleTimer = React.useRef();

  React.useEffect(() => {
    setIdleTimeout(IDLE_TIMEOUT * 60000);
  });

  React.useEffect(() => {
    timerInterval = setInterval(() => {
      if (!isOpen) {
        return;
      }
      console.log({ timerInitiated: timer });

      if (timer > 0) {
        setTimer(timer - 1);
      }
      if (timer === 0) {
        handleConfirm();
      }
    }, 1000);
    return () => {
      clearTimerInterval();
    };
  });

  const handleConfirm = () => {
    clearTimerInterval();
    handleLogout();
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimer(TIMEOUT_WAIT);
  };

  const handleOnIdle = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <IdleTimer ref={idleTimer} timeout={idleTimeout} onIdle={handleOnIdle} />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">You will be logged out in {timer} seconds.</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            For your security, sessions automatically end after {idleTimeout / 60000} minutes of inactivity unless you choose
            to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Continue session
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Log Out
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default IdleTimeout;
