export default (theme) => ({
    container: {
        border: '1px solid #d8d8d8',
        borderTop: 'none',
        width: '100%'
    },
    details: {
        padding: '0 16px'
    },
    tableWrapper: {
        "& h3": {
            fontSize: "16.38px",
            marginTop: 2
        }
    },
    header: {
        background: 'white',
        border: '1px solid #d8d8d8',
        fontSize: '28px',
        width: '100%',

        '& h6': {
            margin: '16px'
        }
    },
    title: {
        margin: 'auto 0'
    },
    unassignButton: {
        fontSize: '12px',
        margin: '0 0 0 auto',

        '& .MuiButton-startIcon': {
            transform: "rotate(-90deg)"
        }
    },
    loaderContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
});