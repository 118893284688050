import React from 'react';
import { CustomIcons, icons } from '../../../../../shared/components/custom-icons/CustomIcons';
import styles from '../new-conversation.styles';
import { Fade, MenuItem, Select } from '@material-ui/core';
import { ANNOUNCEMENT_TYPE, MAX_ANNOUNCEMENT_THRESHOLD_ERROR, USER_CONTEXT } from '../../../../../../constants';
import SendEditor from '../send-editor/send-editor';
import AddNewLearnerGroupDialog from '../../../../../../components/management/staff/add-new-learner-group-dialog';
import { UNREACHABLE_MEMBER_MESSAGE_ERROR } from '../../../../../services/constants';
import {
  Box,
  CircularProgress,
  Button,
  IconButton,
  List,
  ListItem,
  Typography,
  withStyles,
} from '../../../../../shared/components/MaterialUIComponents';
import { useNewAnnouncementHook } from './useNewAnnouncementHook';
import { getRecipientName } from '../new-conversation-helper';
import { TitleView } from '../title-view/title-view';
import { RecipientView } from '../recipient-view/recipient-view';
import { RecipientChip } from '../recipient-chip/recipient-chip';
const NewAnnouncement = (props) => {
  const { classes } = props;

  const {
    isLoading,
    announcementType,
    recipients,
    isMessageSending,
    editLearnerGroupId,
    filteredLearners,
    search,
    showSearchBox,
    hasUnreachableLearner,
    showMessageMonitoredWarning,
    newLearnerGroup,
    learners,
    title,
    body,
    onUserSelect,
    updatedGroupData,
    getUnselectedUser,
    onSearchChange,
    removeSelectedUser,
    onEditGroupClick,
    getErrorText,
    setTitle,
    setBody,
    resetComponent,
    setShowMessageMonitoredWarning,
    onSend,
    onLearnerGroupClose,
    setNewLearnerGroup,
    onAnnouncementClose,
    onTypeChange,
  } = useNewAnnouncementHook(props);

  return (
    <>
      <Fade in={showMessageMonitoredWarning}>
        <Box className={classes.warningBox}>
          <Box display="flex" alignItems={'center'} flex={1}>
            <CustomIcons icon={icons.WarningIcon} className={classes.warningIcon} />
            <Typography>{!showSearchBox ? MAX_ANNOUNCEMENT_THRESHOLD_ERROR : UNREACHABLE_MEMBER_MESSAGE_ERROR}</Typography>
          </Box>
          <Box>
            <IconButton onClick={() => setShowMessageMonitoredWarning(false)}>
              <CustomIcons icon={icons.CloseIcon} className={classes.closeIcon} />
            </IconButton>
          </Box>
        </Box>
      </Fade>
      <Box className={classes.newConversationWrapper}>
        {/* Render title */}
        <TitleView classes={classes} title={'New Learner Announcement'} onClose={onAnnouncementClose} />

        <Box className={classes.body}>
          {/* Render From  */}
          <RecipientView classes={classes} value={USER_CONTEXT.name} />

          <Box id="to-wrapper" className={classes.formGroup}>
            <Box className={classes.formLabel} component={'label'}>
              {' '}
              To:{' '}
            </Box>
            <Box>
              <Select
                className={classes.selectRoot}
                classes={{ root: classes.announcementType }}
                id="announcement-type-selector"
                value={announcementType}
                onChange={(e) => onTypeChange(e)}
              >
                <MenuItem className={classes.menuItem} value={ANNOUNCEMENT_TYPE.FACILITY}>
                  Facility
                </MenuItem>
                <MenuItem className={classes.menuItem} value={ANNOUNCEMENT_TYPE.LEARNER}>
                  Learners
                </MenuItem>
                <MenuItem className={classes.menuItem} value={ANNOUNCEMENT_TYPE.LEARNER_GROUP}>
                  Learner Group(s)
                </MenuItem>
              </Select>
            </Box>
            <Box className={classes.toWrapper}>
              {recipients.map((recipient) => (
                <RecipientChip
                  key={recipient.contactId}
                  recipient={recipient}
                  classes={classes}
                  announcementType={announcementType}
                  hasUnreachableLearner={hasUnreachableLearner}
                  removeSelectedUser={removeSelectedUser}
                  onEditGroupClick={onEditGroupClick}
                />
              ))}
              <Box className={classes.suggestionWrapper}>
                {showSearchBox && (
                  <Box
                    id="search-value"
                    component={'input'}
                    type="text"
                    value={search}
                    autoComplete="off"
                    onChange={(e) => onSearchChange(e.target.value)}
                    className={classes.formField}
                  />
                )}

                {search !== '' && (
                  <Box className={classes.suggestionBox}>
                    {isLoading && <CircularProgress className={classes.loader} />}
                    <List>
                      {(search !== '' ? getUnselectedUser(filteredLearners) : getUnselectedUser(learners)).map((item) => (
                        <ListItem
                          key={item.contactId}
                          id={`suggestedUser-${item.contactId || item.locationId}`}
                          onClick={() => onUserSelect(item)}
                          button
                          className={[classes.suggestedChip].join(' ')}
                        >
                          <Typography className={classes.suggestedChipText}>
                            {getRecipientName(announcementType, item)}
                          </Typography>
                        </ListItem>
                      ))}
                      {getUnselectedUser(filteredLearners).length === 0 && !isLoading && (
                        <ListItem
                          disabled
                          button
                          className={[classes.suggestedChip, classes.notFoundChip, 'error-box'].join(' ')}
                        >
                          {getErrorText()}
                        </ListItem>
                      )}
                    </List>
                  </Box>
                )}
              </Box>
            </Box>
            {announcementType === ANNOUNCEMENT_TYPE.LEARNER_GROUP && (
              <Button className={classes.createGroupButton} onClick={() => setNewLearnerGroup(true)}>
                <CustomIcons icon={icons.GroupIcon} />
                Create Group
              </Button>
            )}
          </Box>
          <Box className={classes.formGroup}>
            <Box className={classes.formLabel} component={'label'}>
              Title:
            </Box>
            <Box width="100%">
              <Box
                id="title-input"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                component={'input'}
                type="text"
                maxLength={50}
                className={classes.formField}
              />
            </Box>
          </Box>
        </Box>
        <SendEditor
          isSendDisabled={isLoading || recipients.length === 0 || body === ''}
          onSend={onSend}
          onChange={(value) => {
            setBody(value);
          }}
          onComplete={() => {
            resetComponent();
          }}
          selectedUsers={recipients}
          isLoading={isMessageSending}
        />
        <AddNewLearnerGroupDialog
          open={editLearnerGroupId || newLearnerGroup}
          handleClose={() => onLearnerGroupClose()}
          editLearnerGroupId={editLearnerGroupId}
          updatedGroupData={updatedGroupData}
        />
      </Box>
    </>
  );
};

export default withStyles(styles)(NewAnnouncement);
