import React from 'reactn';
import { renderToString } from 'react-dom/server'
import { Dialog, Divider, Box, IconButton, AppBar, Toolbar, Typography, Button, DialogContent, Grid, CircularProgress } from '@material-ui/core';
import { Menu } from '../management';
import { getUsersList, submitEditUserCard } from '../../util/APIUtils';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import ReadView from './read-view/read-view';
import EditView from './edit-view/edit-view';
import SaveIcon from '@material-ui/icons/Save';
import { Formik } from 'formik';
import { BlobProvider, pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import moment from 'moment';
import { USER_CARD_DATETIME_FORMAT } from '../../constants';
import { Packer } from "docx";
import Pdf from './pdf'
import Word from './word'
import { Loader } from '@orijinworks/frontend-commons';
import { Alert } from '@material-ui/lab';
import { validatePassword } from '../../util/Helpers';
import { hydrate } from 'react-dom';


const CSV = 0;
const PDF = 1;
const WORD = 2;


class BulkUserCard extends React.Component {
    formRef = null;
    BATCH_SIZE = 100;
    CONCURRENT_REQUEST_COUNT = 5;
    cacheUserData = null;
    VIEW_TYPES = {
        READ_ONLY: 0,
        EDITABLE: 1
    };
    currentTime = null;
    FIELDS_CHANGE = {};
    state = {
        isCompleted: false,
        users: [],
        viewType: this.VIEW_TYPES.READ_ONLY,
        loading: false,
        triggerDownload: false,
        downloadType: "",
        PDFUrl: null,
        loaderText: "Please wait, content is being downloaded!",
        PDFError: null,
        PDFLoadedCount: 0,
    };

    componentDidUpdate() {
        if (this.props.isOpen && this.state.users.length === 0 && !this.global.isLoading) {
            this.getUserData(
                this.props.users
                    .map(user => user.personId)
            );
            this.setState({
                PDFUrl: null,
                PDFError: null,
                PDFLoadedCount: 0
            });
        }

        if (this.state.triggerDownload) {
            if (this.state.downloadType === CSV) {
                this.setState({
                    triggerDownload: false,
                }, () => { setTimeout(this.exportAsCsv, 0) });
            }
            if (this.state.downloadType === WORD) {
                this.setState({
                    triggerDownload: false,
                }, this.exportAsWord);
            }
            if (this.state.downloadType === PDF) {
                this.setState({
                    triggerDownload: false,
                }, this.exportAsPdf);
            }
        }

    };

    fieldChangeDetector = (userId, serviceId, field, value) => {
        if (this.FIELDS_CHANGE.hasOwnProperty(userId) && this.FIELDS_CHANGE[userId].hasOwnProperty(serviceId)) {
            this.FIELDS_CHANGE[userId][serviceId][field] = value;
        } else {
            this.FIELDS_CHANGE[userId] = {
                [serviceId]: {
                    [field]: value
                }
            };
        };
    };

    getStatusMsg = (statusCode, serviceId, userId) => {
        const userData = this.getServiceAndUsername(serviceId, userId);
        if (statusCode === 0) {
            return `${userData.serviceName} has been successfully updated for ${userData.userName}.`;
        } else {
            return `Something went wrong while updating ${userData.userName}.`;
        };
    };

    /**
     * @param {number} serviceKey
     * @param {number} userId
     * @desc Finds serivceName based upon userId & serviceKey
     * @return {string}
     */
    getServiceAndUsername = (serviceKey, userId) => {
        const user = this.state.users.find(
            _user => _user.userDetail.userId == userId
        );

        if (user) {
            const service = user.userServices.find(
                _service => _service.serviceKey === serviceKey
            );
            if (service) {
                return {
                    serviceName: service.serviceName,
                    userName: user.userDetail.userName
                };
            }
            // RETURNING ONLY 
            // USER DATA IF SERVICE
            // IS NOT FOUND
            return {
                userName: user.username
            };
        }
        return {};
    };

    /**
     * @param {string} msg
     * @desc Parses error msg based upon underlying logic.
     * @return {string}
     */
    parseErrorMsg = (msg, serviceKey, userId) => {
        const userData = this.getServiceAndUsername(serviceKey, userId);
        if (msg) {
            // REPLACING USERNAME
            msg = msg
                .split("null")
                .join(userData.userName);
            // REPLACING SERVICE NAME
            msg = msg
                .split("[Service_Name]")
                .join(userData.serviceName);
        }

        return msg;
    };

    /**
     * @name getResponseBoxData
     * @param {object} response
     * @desc Prepares response box data.
     * @return {object} data
     */
    getResponseData = (response) => {
        const data = {
            isOpen: true,
            data: []
        };
        response.successList.forEach(
            (_successNodes) => {
                _successNodes
                    .userServices
                    .forEach(
                        (_userServiceSuccess) => {
                            data.data.push({
                                statusCode: _userServiceSuccess.statusCode,
                                text: this.getStatusMsg(_userServiceSuccess.statusCode, _userServiceSuccess.serviceKey, _successNodes.UserId)
                            });
                        }
                    );
            }
        );
        response.errorList.forEach(
            (_errorNodes) => {
                _errorNodes.userServices
                    .forEach(
                        (_userServiceError) => {
                            data.data.push({
                                statusCode: _userServiceError.statusCode,
                                text: this.parseErrorMsg(_userServiceError.statusMessage, _userServiceError.serviceKey, _errorNodes.UserId)
                            });
                        }
                    );

            }
        );
        return data;
    };

    getCacheServiceCopy = (user, service) => {
        const originalUserData = this.cacheUserData.find(
            _user => _user.userDetail.userId == user.userDetail.userId
        );
        const originalServiceData = originalUserData.userServices.find(
            _service => _service.serviceKey == service.serviceKey
        )
        return originalServiceData;
    };
    /**
     * @name handleSubmit
     * @param {array} values
     * @desc Handles submit event of bulk Learner card
     * @return {void}
     */
    handleSubmit = (values) => {
        const requestPayload = values.users
            .filter(user => this.FIELDS_CHANGE.hasOwnProperty(parseInt(user.userDetail.userId)))
            .map(
                (user) => {
                    return {
                        UserId: parseInt(user.userDetail.userId),
                        userServices: user.userServices // FILTERING OUT NOT SSO ENABLED SERVICES
                            .filter(service => {
                                const userChangedObj = this.FIELDS_CHANGE[parseInt(user.userDetail.userId)];
                                const serviceExist = userChangedObj.hasOwnProperty(service.serviceKey);
                                if (serviceExist) {
                                    const originalServiceData = this.getCacheServiceCopy(user, service);
                                    const isFieldChanged = Object.keys(userChangedObj[service.serviceKey])
                                        .some(fieldKey => {
                                            if (originalServiceData[fieldKey] != userChangedObj[service.serviceKey][fieldKey]) {
                                                return true;
                                            }
                                            return false;
                                        });

                                    return isFieldChanged;
                                }
                                return false;
                            })
                            .map(
                                service => ({
                                    serviceKey: service.serviceKey,
                                    active: !!service.active || false,
                                    externalUserName: service.externalUserName,
                                    externalUserPassword: service.externalUserPassword,
                                    externalId: service.externalId,
                                })
                            )
                    };
                }
            );
        if (requestPayload.length === 0) {
            this.handleClose();
            return;
        }
        this.setGlobal({
            isLoading: true
        });
        this.toggleView();
        submitEditUserCard(requestPayload)
            .then(
                (_successLog) => {
                    this.setGlobal({
                        isLoading: false
                    });

                    this.props.setResponse({
                        heading: "Learner Card Update",
                        ...this.getResponseData(_successLog)
                    });
                    if (this.props.fetchUsers) {
                        this.props.fetchUsers();
                    }
                    this.handleClose();
                },
                (_errorLog) => {
                    this.setGlobal({
                        isLoading: false
                    });
                }
            );

        // this.toggleView()
    };

    /**
     * @name toggleView
     * @desc Toggles view based upon existing state & updates to new opposite one.
     * @return {void}
     */
    toggleView = () => {
        const { viewType } = this.state;
        let newType = null;
        if (viewType === this.VIEW_TYPES.READ_ONLY) {
            newType = this.VIEW_TYPES.EDITABLE;
        } else if (viewType === this.VIEW_TYPES.EDITABLE) {
            newType = this.VIEW_TYPES.READ_ONLY;
        }
        this.setState({
            viewType: newType
        }, () => {
            if (newType === this.VIEW_TYPES.EDITABLE) {
                const serviceContainerElem = document.querySelector(".service-container");
                if (serviceContainerElem) {
                    setTimeout(
                        () => {
                            serviceContainerElem
                                .scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                });
                        }, 500
                    );
                }

            }
        });
    };

    getPaginatedUserData = (userIds = [], initializeBatchCount = 0, concurrentRequestCount = this.CONCURRENT_REQUEST_COUNT) => {
        let batchesFetched = initializeBatchCount;
        const noOfBatches = Math.ceil(userIds.length / this.BATCH_SIZE);
        for (let a = initializeBatchCount; a < concurrentRequestCount; a++) {
            const requestPayload = {
                "studentIds": userIds.slice(a * this.BATCH_SIZE, (a + 1) * this.BATCH_SIZE)
            };
            if (requestPayload.studentIds.length > 0) {
                getUsersList(requestPayload)
                    .then(({ userCards }) => {
                        batchesFetched++;
                        const concattedUserList = this.state.users.concat(userCards);
                        const estimatedTimeInMins = ((moment().diff(this.currentTime, "seconds") / batchesFetched) * noOfBatches) / 60;

                        this.cacheUserData = JSON.parse(JSON.stringify(concattedUserList)); // MAINTAINING AN ORIGINAL CACHE VERSION SINCE STATE ONE IS BEING UPDATED.
                        this.setState({
                            users: concattedUserList,
                            loaderText: `Please wait, content is being downloaded. Estimated time ~ ${estimatedTimeInMins.toFixed(2)} minutes.`,
                            isCompleted: false
                        });
                        if (this.formRef) {
                            this.formRef.setFieldValue("users", concattedUserList);
                        }
                        this.setGlobal({
                            isLoading: false
                        });
                        if (batchesFetched === (concurrentRequestCount - 1) && concurrentRequestCount < noOfBatches) {
                            this.getPaginatedUserData(userIds, concurrentRequestCount, concurrentRequestCount + this.CONCURRENT_REQUEST_COUNT);
                        }

                        if (this.isDownloadCompleted) {
                            this.setState({
                                isCompleted: this.isDownloadCompleted,
                                loaderText: `All cards have been downloaded successfully!`
                            });
                        }
                    });
            }
        }
    };

    /**
     * @name getUserData
     * @param {array} userIds
     * @desc Fetches user data based upon user IDs passed into params.
     * @return {void}
     */
    getUserData = (userIds = []) => {
        this.setGlobal({
            isLoading: true
        });
        this.currentTime = moment();
        this.getPaginatedUserData(userIds);
    };

    /**
     * @name getUserLocationCSVString
     * @param {array} userLocations
     * @desc Prepares user location CSV string as per 
     * userLocations passed.
     * @return {string}
     */
    getUserLocationCSVString = (userLocations = [], userServices = []) => {
        let csvString = "";
        userLocations.forEach(
            (locationData, index) => {
                csvString += `name: ${locationData.locationName} role type: ${locationData.roleTypeName} active: ${locationData.active ? "active" : "inactive"} start date: ${locationData.startDate ? moment.parseZone(locationData.startDate).format(USER_CARD_DATETIME_FORMAT) : "n/a"}, ${this.getServiceCSVAtIndex(index, userServices)}
                , , , , , , ,`;
            }
        );
        return csvString;
    };

    /**
     * @name getServiceCSVAtIndex
     * @param {number} index
     * @param {array} services
     * @desc Prepares string for CSV at index provided in param.
     * @return {string}
     */
    getServiceCSVAtIndex = (index, services) => {
        if (services.length <= index) {
            return "";
        }
        let csvString = `service name: ${services[index].serviceName} service display name: ${services[index].serviceDisplayName} sso enabled: ${services[index].ssoEnabled ? "enabled" : "disabled"} automated provisioning: ${services[index].automatedProvisioning ? "Yes" : "No"} active: ${services[index].active} external username: ${services[index].externalUserName || "n/a"} external password: ${services[index].externalUserPassword || "n/a"} start date: ${services[index].startDate ? moment.parseZone(services[index].startDate).format(USER_CARD_DATETIME_FORMAT) : "n/a"}`;
        return csvString;
    };

    /**
     * @name getUserServiceCSVString
     * @param {array} userLocations
     * @param {number} startIndex Index from which this needs to be started from.
     * @desc Prepares data for CSV and it starts from last index of location in 
     * order to avoid duplicate data.
     * @return {string}
     */
    getUserServiceCSVString = (userServices = [], startIndex) => {
        let csvString = "";

        for (let a = startIndex; a < userServices.length; a++) {
            csvString += ` service name: ${userServices[a].serviceName} service display name: ${userServices[a].serviceDisplayName} sso enabled: ${userServices[a].ssoEnabled ? "enabled" : "disabled"} automated provisioning: ${userServices[a].automatedProvisioning ? "Yes" : "No"} active: ${userServices[a].active} external username: ${userServices[a].externalUserName || "n/a"} external password: ${userServices[a].externalUserPassword || "n/a"} start date: ${userServices[a].startDate ? moment.parseZone(userServices[a].startDate).format(USER_CARD_DATETIME_FORMAT) : "n/a"}
            , , , , , , , ,`;

        }
        return csvString;
    };

    /**
     * @name validateUserService
     * @param {object} user
     * @desc Validates user services based upon validation conditions.
     * @return {array | void}
     */
    validateUserService = (user) => {
        const errors = [];
        user
            .userServices
            .filter(service => service.active === "true")
            .forEach(
                (service, index) => {
                    const serviceError = {};
                    if ((service.serviceKey != (124) && service.serviceKey != (125)) && !service.ssoEnabled) { // SSO IS NOT ENABLED
                        const originalServiceData = this.getCacheServiceCopy(user, service);
                        if ((!service.externalUserName || service.externalUserName === "") && originalServiceData.externalUserName != service.externalUserName) {
                            serviceError["externalUserName"] = "Username is required.";
                        }
                        if ((!service.externalUserPassword || service.externalUserPassword === "") && originalServiceData.externalUserPassword != service.externalUserPassword) {
                            serviceError["externalUserPassword"] = "Password is required.";
                        }
                        if (service.externalUserPassword && originalServiceData.externalUserPassword != service.externalUserPassword) {
                            const passwordErrors = validatePassword(service.externalUserPassword);
                            if (passwordErrors.length > 0) {
                                serviceError["externalUserPassword"] = passwordErrors.join(", ");
                            }
                        }
                        if (Object.keys(serviceError).length > 0) { // ONLY PUSHING IF SERVICE HAS ERRROS
                            errors[index] = serviceError;
                        }
                    }
                }
            );
        return errors;
    };

    /**
     * @name validateForm
     * @param {object} values
     * @desc Validate's form against value and based upon certain conditions.
     * @return {object}
     */
    validateForm = (values) => {
        const errors = {};
        values.users
            .forEach(
                (user, index) => {
                    const userServiceErrors = this.validateUserService(user);
                    if (userServiceErrors.length > 0) {
                        if (!errors.hasOwnProperty("users")) {
                            errors["users"] = [];
                        }
                        errors["users"][index] = {
                            userServices: userServiceErrors
                        };
                    }

                }
            );
        return errors;

    };

    /**
     * @name exportAsCSV
     * @desc Exports entire data as CSV.
     * @return {void}
     */
    exportAsCsv = () => {
        try {
            const universalBOM = "\uFEFF";
            let csvContent = "";
            const mat = [];
            const headings = [
                "First Name",
                "Last Name",
                "Username",
                "Doc ID",
                "Active",
                "Location",
                "Service",
                "Username",
                "Password"
            ];

            mat.push(headings);

            this.state.users.forEach((user, index) => {
                const data = [
                    user.userDetail.firstName,
                    user.userDetail.lastName,
                    user.userDetail.userName,
                    user.userDetail.docId,
                    user.userDetail.active ? "active" : "inactive",
                    user.userLocations.length > 0 ? user.userLocations[0].locationName : "N/A",
                    "Connected Corrections",
                    user.userDetail.userName,
                    '\"' + (user.userDetail.password || "").split('"').join('\""') + '\"'
                ];
                mat.push(data);
                if (user.userServices.length > 0) {
                    user.userServices
                        .filter(service => service.active === "true" && !service.ssoEnabled)
                        .forEach((service) => {
                            if (!mat[0][mat[index + 1].length + 2]) {
                                mat[0] = [
                                    ...mat[0],
                                    "Service",
                                    "Username",
                                    "Password",
                                ]
                            }
                            mat[index + 1] = [
                                ...mat[index + 1],
                                service.serviceDisplayName,
                                service.externalUserName,
                                service.externalUserPassword,
                            ]
                        });

                }
            });
            for (let i = 0; i < mat.length; i++) {
                for (let j = 0; j < mat[0].length; j++) {
                    if (!mat[i][j]) {
                        mat[i][j] = "N/A"
                    }
                }
                csvContent += mat[i].join(",") + "\r\n";
            }
            var encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;
            var link = document.createElement("a");
            link.setAttribute("id", "bulk-user-download-link");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `User-Cards-${moment().format("YYYY-MM-DD")}.csv`);
            document.body.appendChild(link); // Required for FF
            this.setState({ loading: false }, () => {
                link.click();
                // REMOVING LINK CREATED ELEMENT
                document.body.removeChild(document.querySelector("#bulk-user-download-link"));
            });
        } catch (e) {
            console.log(e);
        }
    };

    delay = (t) => new Promise((resolve) => setTimeout(resolve, t));


    /**
     * @name exportAsPdf
     * @desc Exports entire data as set of PDF files in one single ZIP file.
     * @return {void}
     */
    exportAsPdf = () => {

        setTimeout(() => {
            // GENERATING PDF
            const link = (
                <PDFDownloadLink document={<Pdf users={this.state.users} fileName={`User-Cards-${moment().format("YYYY-MM-DD")}.pdf`} title={"Learner Cards"} />}>
                    {({ blob, url, loading, error }) => {
                        if (url && !loading) {
                            const element = document.createElement('a');
                            element.setAttribute('href', url);
                            element.setAttribute('download', `User-Cards-${moment().format("YYYY-MM-DD")}.pdf`);

                            element.style.display = 'none';
                            document.body.appendChild(element);

                            element.click();

                            document.body.removeChild(element);

                            this.setState({
                                loading: false
                            });
                        }
                    }}
                </PDFDownloadLink>
            );
            const elem = document.querySelector("#pdfDownloadLinkContainer");
            hydrate(link, elem)
        }, 50);
    }


    /**
 * @name exportAsWord
 * @desc Exports entire data as set of Word files in one single ZIP file.
 * @return {void}
 */
    exportAsWord = async () => {
        const doc = await Word(this.state.users);
        this.setState({ loading: false }, () => {
            Packer.toBuffer(doc).then((buffer) => {
                var blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
                saveAs(blob, `User-Cards-${moment().format("YYYY-MM-DD")}.docx`);

            });
        });
    }

    /**
     * @name handleClose
     * @desc Handles all clean up tasks while closing form.
     * @return {void}
     */
    handleClose = () => {
        this.props.onClose();
        this.setState({
            users: [],
            viewType: this.VIEW_TYPES.READ_ONLY
        });
        if (this.formRef) {
            this.formRef.resetForm();
        };
    };

    get isDownloadCompleted() {
        if (this.props.users.length === this.state.users.length) {
            return true;
        }
        return false;
    };

    render() {
        return (
            <Dialog open={this.props.isOpen} onClose={() => this.handleClose()}>
                <div id="pdfDownloadLinkContainer"></div>
                <Loader isLoading={this.state.loading} />
                {!this.global.isLoading && (
                    <>
                        <AppBar style={{
                            position: 'relative'
                        }}>
                            <Toolbar>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <IconButton
                                            id="user-card-close-btn"
                                            edge="start" color="inherit" onClick={() => this.handleClose()} aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography variant="h6" >
                                            {this.state.users.length} Learner Card{this.state.users.length > 1 ? "s" : null}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {this.state.viewType === this.VIEW_TYPES.READ_ONLY
                                            &&
                                            <IconButton
                                                onClick={() => this.toggleView()}
                                                type="button"
                                                style={{
                                                    color: 'white'
                                                }}>
                                                <EditIcon />
                                            </IconButton>
                                        }
                                        {this.state.viewType === this.VIEW_TYPES.EDITABLE
                                            &&
                                            <IconButton
                                                onClick={
                                                    () => {
                                                        this.formRef.setFieldTouched("users", true);
                                                        document.querySelector("#form-submit-btn").click();
                                                    }
                                                }
                                                type="submit"
                                                style={{
                                                    color: 'white'
                                                }}>
                                                <SaveIcon />
                                            </IconButton>
                                        }
                                        <Menu
                                            buttonIcon={<GetAppIcon style={{ color: 'white' }} />}
                                            options={[
                                                {
                                                    title: "Export As CSV",
                                                    onClick: () => this.setState({ triggerDownload: true, loading: true, downloadType: CSV })
                                                },
                                                {
                                                    title: "Export As PDF",
                                                    onClick: () => this.setState({ triggerDownload: true, loading: true, downloadType: PDF })
                                                },
                                                {
                                                    title: "Export As Word",
                                                    onClick: () => this.setState({ triggerDownload: true, loading: true, downloadType: WORD })
                                                }
                                            ]}
                                        >
                                        </Menu>
                                    </Box>
                                </Box>

                            </Toolbar>
                        </AppBar>

                        <Alert icon={this.state.isCompleted ? null : (<CircularProgress color="warning" size={20} />)} severity={this.state.isCompleted ? "success" : "warning"}>
                            {this.state.loaderText}
                        </Alert>
                        <DialogContent style={{
                            padding: 0
                        }}>
                            <Formik
                                validate={this.validateForm}
                                innerRef={ref => this.formRef = ref}
                                initialValues={{
                                    users: Object.assign([], this.state.users)
                                }}
                                onSubmit={this.handleSubmit}
                            >
                                {
                                    ({ values, setFieldValue, setFieldTouched, errors, touched, handleSubmit }) => (
                                        <form
                                            id="user-form"
                                            onSubmit={handleSubmit}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            {/* DUE TO FORMIK AND
                                            MATERIAL UI CONFLICT
                                            HIDDEN SUBMIT BUTTON
                                            IS BEING USED */}
                                            <input type="submit" style={{
                                                display: "none"
                                            }} id="form-submit-btn" />
                                            <div style={{ minWidth: 580 }}>
                                                <Grid container >
                                                    {this.isDownloadCompleted && (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Box
                                                                    width="100%"
                                                                    display="flex"
                                                                    justifyContent="space-between"
                                                                    component="div"
                                                                    alignItems="center"
                                                                >
                                                                    <img style={{ margin: '10px 0px 0px 25px' }} alt="logo" width="100px" src="https://content.connectedcorrections.com/assets/logo/orijin-logo.svg" />
                                                                </Box>
                                                            </Grid>
                                                            {this.state.viewType === this.VIEW_TYPES.READ_ONLY && (
                                                                <ReadView
                                                                    users={this.state.users}
                                                                />
                                                            )}
                                                            {this.state.viewType === this.VIEW_TYPES.EDITABLE && (
                                                                <EditView
                                                                    setFieldTouched={setFieldTouched}
                                                                    setFieldValue={setFieldValue}
                                                                    users={values.users}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    fieldChangeDetector={this.fieldChangeDetector}
                                                                />
                                                            )}
                                                        </>
                                                    )}

                                                </Grid>
                                            </div>

                                        </form>
                                    )
                                }
                            </Formik>
                        </DialogContent>
                    </>
                )
                }
            </Dialog>
        );
    };
};


export default BulkUserCard;