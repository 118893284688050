import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import StarsIcon from "@material-ui/icons/Stars";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import React from "react";
import Moment from "react-moment";
import { List as ListLoader } from "react-content-loader";
import DoneIcon from '@material-ui/icons/Done';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

import SnackBarToast from "../snack-bar-toast/snack-bar-toast";
import useStyles from "./details-listing.style";

function PathwayDetailListing(props) {
  const [showHideListing, setShowHideListing] = React.useState({});
  const [hideCompletedItems, setHideCompletedItems] = React.useState(true);
  const [showCompletedOnly, setShowCompletedOnly] = React.useState(false);
  const classes = useStyles();
  let showHideText = "Hide";
  let eyeIcon = `/assets/icons/visibility-off.svg`;
  if (hideCompletedItems) {
    showHideText = "Show";
    eyeIcon = `/assets/icons/visibility.svg`;
  }
  const showHideListingItems = itemId => {
    const showHideLists = { ...showHideListing };
    showHideLists[itemId] = !showHideLists[itemId];
    setShowHideListing(showHideLists);
  };
  const expandAndCollapse = isExpand => {
    let showHideLists = {};
    showHideLists = expandAndCollapseAllcollapseAll(
      props.listingItems,
      isExpand,
      showHideLists
    );
    setShowHideListing(showHideLists);
  };
  /**
   *
   * @param {detail List} pathwayDetails
   * @param {boolean} isExpand
   * @param {initial Object} showHideListing
   *
   * Apply recursive function for all child for expand collapse
   */
  const expandAndCollapseAllcollapseAll = (
    pathwayDetails,
    isExpand,
    showHideListing
  ) => {
    for (let i = 0; i < pathwayDetails.length; i++) {
      showHideListing[pathwayDetails[i].id] = isExpand;
      if (pathwayDetails[i].children) {
        showHideListing = expandAndCollapseAllcollapseAll(
          pathwayDetails[i].children,
          isExpand,
          showHideListing
        );
      }
    }
    return showHideListing;
  };
  const showHideCompletedItems = () => {
    setHideCompletedItems(!hideCompletedItems);
  };
  const isAllItemsCompleted = pathwayList => {
    return (
      pathwayList.length ===
      pathwayList.filter(pathway => pathway.isCompleted === true).length
    );
  };
  const getListing = (pathwayList, classes) => {
    const isCourseCompleted = pathwayList.isCompleted;
    if (hideCompletedItems && isCourseCompleted === true) {
      return "";
    };
    if (showCompletedOnly && !isCourseCompleted) {
      return "";
    };
    const isChildExist = pathwayList.children && pathwayList.children.length;
    let allItemsCompleted = false;
    if (isChildExist) {
      allItemsCompleted = isAllItemsCompleted(pathwayList.children);
    }
    const isItemDisplay = showHideListing[pathwayList.id];
    return (
      <Box component="div" className={classes.nested}>
        <List className={[classes.detailsList].join(" ")}>
          <ListItem
            data-cy-name={`listitem-${pathwayList.id}`}
            key={pathwayList.id}
          >
            <ListItemText>
              <Grid container spacing={3}>
                {/*
                      List Items Content
                    */}
                <Grid item xs={4} className={classes.courseName}>
                  {!isChildExist ? (
                    isCourseCompleted ? (
                      <Grid container spacing={3}>
                        <Grid
                          item
                          alignItems="center"
                          container
                          xs={1}
                          className={classes.courseName}
                        >
                          <img
                            src={`/assets/icons/action-ic-check-circle.png`}
                            alt="action-ic-check-circle"
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          xs={9}
                          className={classes.courseName}
                        >
                          {pathwayList.name}
                        </Grid>
                      </Grid>
                    ) : (
                        <Grid container spacing={3}>
                          <Grid
                            item
                            alignItems="center"
                            container
                            xs={1}
                            className={classes.courseName}
                          >
                            {/* Spacing */}
                          </Grid>
                          <Grid
                            item
                            container
                            xs={9}
                            className={classes.courseName}
                          >
                            {pathwayList.name}
                          </Grid>
                        </Grid>
                      )
                  ) : (
                      pathwayList.name
                    )}
                </Grid>

                {(pathwayList.tags || []).some(
                  tag => tag === "GRADABLE" || tag === "ACTIONABLE"
                ) ? (
                    <Grid
                      container
                      item
                      xs={3}
                      alignItems="center"
                      className={classes.iconWrapper}
                    >
                      {pathwayList.tokens ? (
                        <>
                          <Typography>+{pathwayList.tokens}</Typography>
                          <StarsIcon className={classes.icon} fontSize="small" />
                        </>
                      ) : null}
                    </Grid>
                  ) : (
                    ""
                  )}
                {/*
                        Progress Bar in items
                    */}
                <Grid item xs={4}>
                  {isChildExist ? (
                    <LinearProgress
                      color="secondary"
                      variant="determinate"
                      value={pathwayList.percentageCompleted}
                    />
                  ) : isCourseCompleted ? (
                    <Box component="span" display="block" textAlign="left">
                      <Box
                        component="p"
                        margin={0}
                        className={classes.assignedText}
                      >
                        {pathwayList.completedScore} / {pathwayList.totalScore}
                        {/* PIPE WILL ONLY APPEAR  */}
                        {/* WHEN THERE'S LETTER GRADE */}
                        {pathwayList.letterGrade && " | "}
                        {pathwayList.letterGrade}
                      </Box>
                      <Box component="span" className={classes.assignedText}>
                        Completed:{" "}
                      </Box>
                      <Moment
                        format=" MMM DD, YYYY"
                        className={[
                          classes.assignedText,
                          classes.dateText
                        ].join(" ")}
                      >
                        {pathwayList.completionDate}
                      </Moment>
                    </Box>
                  ) : (
                        pathwayList.startDate && (
                          <Box component="span" display="block" textAlign="left">
                            <Box component="span" className={classes.assignedText}>
                              Started:{" "}
                            </Box>
                            <Moment
                              format=" MMM DD, YYYY"
                              className={[
                                classes.assignedText,
                                classes.dateText
                              ].join(" ")}
                            >
                              {pathwayList.startDate}
                            </Moment>
                          </Box>
                        )
                      )}
                </Grid>
              </Grid>
            </ListItemText>
            {/*
                    Expand Collapse On Each Item
                */}
            <ListItemIcon>
              <LinearProgress
                color="secondary"
                variant="determinate"
                value={pathwayList.percentageCompleted}
              />
              {/**
               * If item has resource url then show launch button
               */

                (pathwayList.tags || []).some(
                  tag => tag === "GRADABLE" || tag === "ACTIONABLE"
                ) && !isChildExist ? null
                  : /**
               * If item has more child then show expand/collapse button
               */
                  isChildExist && (!allItemsCompleted || !hideCompletedItems) ? (
                    <Fab
                      className={classes.fabButton}
                      onClick={e => {
                        showHideListingItems(pathwayList.id);
                      }}
                    >
                      {isItemDisplay ? (
                        <ExpandLessRoundedIcon />
                      ) : (
                          <ExpandMoreRoundedIcon />
                        )}
                    </Fab>
                  ) : (
                      ""
                    )}
            </ListItemIcon>
          </ListItem>
          <Divider className={classes.divider} />
          {/**
           * Apply recursive call if more child exist
           */
            isItemDisplay && isChildExist
              ? pathwayList.children.map(item => {
                return getListing(item, classes);
              })
              : ""}
        </List>
      </Box>
    );
  };
  return (
    <Grid className={classes.root} id="detail-list-wrapper">
      {!props.isLoading && props.isActive === false ? (
        <SnackBarToast isCourseActive={false} />
      ) : null}
      {(props.listingItems && props.listingItems.length > 0) || props.isLoading ? (
        <Grid item xs={12} className={classes.gridLayout}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                className={classes.listHeader}
                component="div"
                id="nested-list-subheader"
              >
                <Grid container>
                  <Grid item lg={2}>
                    {/* {Spacing} */}
                  </Grid>
                  <Grid item xs={12} lg={10} container spacing={1}>
                    {/**
                     *  Collapse Buttons
                     */}
                    <Grid
                      className={classes.listHeaderSection}
                      container
                      justify="flex-end"
                      spacing={1}
                      item
                      xs={12}
                    >
                      <Box
                        onClick={() => {
                          expandAndCollapse(false);
                        }}
                        display="flex"
                        className={classes.topBarWrapper}
                      >
                        <img
                          src="/assets/misc/unfold-less.png"
                          alt="Unfold icon"
                          className={classes.topBarImg}
                        />
                        <Typography className={classes.topBarText}>
                          Collapse All
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => {
                          expandAndCollapse(true);
                        }}
                        display="flex"
                        className={classes.topBarWrapper}
                      >
                        <img
                          src="/assets/misc/unfold-more.png"
                          alt="Unfold icon"
                          className={classes.topBarImg}
                        />
                        <Typography className={classes.topBarText}>
                          Expand All
                        </Typography>
                      </Box>

                      <Box
                        onClick={() => {
                          showHideCompletedItems();
                        }}
                        display="flex"
                        className={classes.topBarWrapper}
                      >
                        <img
                          src={eyeIcon}
                          alt="Unfold icon"
                          className={classes.topBarImg}
                        />
                        <Typography className={classes.topBarText}>
                          {showHideText} Completed Items
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => {
                          setHideCompletedItems(false);
                          setShowCompletedOnly(!showCompletedOnly);
                        }}
                        display="flex"
                        className={classes.topBarWrapper}
                      >
                        <Typography className={classes.topBarText}>
                          {showCompletedOnly && (
                            <>
                              <AllInclusiveIcon className={classes.topBarImg} />
                              &nbsp;
                              Show All
                            </>
                          )}
                          {!showCompletedOnly && (
                            <>
                              <DoneIcon className={classes.topBarImg} />
                              &nbsp;
                              Show Completed Only
                            </>
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
              </ListSubheader>
            }
          >
            {props.isLoading && (
              <Box component="div" className={classes.loaderWrapper}>
                <ListLoader />
              </Box>
            )}
            {/**
              Listing
            */}
            {!props.isLoading &&
              props.listingItems.map(item => {
                return getListing(item, classes);
              })}
          </List>
        </Grid>
      ) : (
          <Typography className={classes.notFound} variant="p">
            {"No Items found"}
          </Typography>
        )}
    </Grid>
  );
}
export default PathwayDetailListing;
