import React, { Component, Suspense } from 'reactn';
import { withStyles, Box, Grid, List, ListItem, Snackbar } from '@material-ui/core';
import { connect } from 'react-redux';
import jwt_decode from 'jwt-decode';
import styles from './management.style';
import { Communications, FacialAuth } from './';
import { ManagementService } from './management.service';
import { LOCATIONS, ROLES, setJanusToken, SELECTED_HOUSING_UNIT } from '../../constants';
import { withRouter } from 'react-router-dom';
import { fetchJanusToken, getFeatures } from '../../util/APIUtils';
import { Loader } from '@orijinworks/frontend-commons';
import LocationDetailsModal from '../Locations/locationDetails';
import { setLocation as selectLocation } from '../../v2/core/store/.';
import { isPermitted, PermissionControl } from '../permission-control/permission-control';
import withUnleash from '../../v2/core/unleash/withUnleash';
import { UnleashService } from '../../v2/services';
import { Certificates } from '../../v2/modules/management/';
import UnleashProvider from '../../v2/core/unleash/unleashProvider';
import { Resume } from '../../v2/modules/management/resume';
import { TABS } from '../../v2/services/constants';
import useFeatureFlagOnHook from '../../v2/services/useFeatureFlagOnHook';

const Settings = React.lazy(() => import('./settings'));
const Forms = React.lazy(() => import('./forms'));
const Users = React.lazy(() => import('../../v2/shared/components/users/users'));
const Staff = React.lazy(() => import('./staff'));
const EditLocation = React.lazy(() => import('../manage-locations/edit/editLocation'));
const ManageHousingUnits = React.lazy(() => import('../manage-locations/edit/manageHousingUnits'));
const FacialAuthenticationSettings = React.lazy(() => import('../manage-locations/edit/facialAuthenticationSettings'));
const FriendsAndFamilyMessaging = React.lazy(() => import('./friends-and-family-messaging'));

const TABS_MAPPING = {
  [TABS.USER]: 'Learners',
  [TABS.PATHS]: 'Path',
  [TABS.FORMS]: 'Forms',
  [TABS.SETTINGS]: 'Settings',
  [TABS.SERVICES]: 'Services',
  [TABS.STAFF]: 'Staff',
  [TABS.FA]: 'Biometric Authentication',
  [TABS.FNF]: 'Friends & Family Messaging',
  [TABS.COMMUNICATIONS]: 'Communications',
};

export class Management extends Component {
  managementService = new ManagementService();
  NO_LOCATION = -1;
  state = {
    etag: Math.random(),
    snackBar: {
      open: false,
      snackBarMsg: null,
    },
    activeTab: TABS.USER,
    changingLocation: false,
    location: {
      active: this.NO_LOCATION,
      collection: LOCATIONS,
      locationName: null,
    },
    locationFeatures: [],
  };
  componentDidMount() {
    if (this.props.isTestSuite) {
      return;
    }
    if (this.props.selectedHousingUnit && this.props.selectedLocation) {
      this.changeLocationHandler(this.props.selectedHousingUnit, 'hu');
    } else if (this.props.selectedLocation) {
      this.changeLocationHandler(this.props.selectedLocation);
    }
    if (
      this.state.location.active === this.NO_LOCATION &&
      !this.props.selectedLocation &&
      this.props.location.pathname !== '/manage'
    ) {
      this.props.history.push('/manage');
      this.setGlobal({
        hiddenBreadcrumbItems: [],
      });
    }
  }

  isFeatureExist = (featureName) => {
    const feature = this.state.locationFeatures.find((feature) => feature.featureName === featureName);
    if (feature) {
      return feature.status;
    }
    return false;
  };

  getFacilityFeatures = async () => {
    const data = await getFeatures(this.state.location.active);
    if (data.statusCode === 200) {
      this.setState({
        locationFeatures: data.locationFeatures,
      });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    // SETTING ETAG SO THAT NEW
    // ROUTE IS REFLECTED AMONGST COMPONENTS
    if (prevProps.location !== this.props.location) {
      this.setState({
        etag: Math.random(),
      });
    }

    if (
      this.props.selectedLocation &&
      prevProps.selectedLocation !== this.props.selectedLocation &&
      !this.props.selectedHousingUnit
    ) {
      this.changeLocationHandler(this.props.selectedLocation);
    }
    if (
      this.props.selectedHousingUnit &&
      prevProps.selectedHousingUnit !== this.props.selectedHousingUnit &&
      this.props.selectedLocation
    ) {
      this.changeLocationHandler(this.props.selectedHousingUnit, 'hu');
    }
  }

  getDefaultTab = (queryString) => {
    const params = new URLSearchParams(queryString);
    if (params.has('tab')) {
      return params.get('tab');
    }
    return null;
  };
  /**
   * @name toggleSnackBar
   * @param {string} msg
   * @desc Shows / hides snack bar with relevant msg.
   * @return {void}
   */
  toggleSnackBar = (msg, state = true) => {
    this.setState({
      snackBar: {
        open: state,
        snackBarMsg: msg,
      },
    });
  };

  setLandingTab = (defaultTab) => {
    let activeTab = null;
    if (defaultTab) {
      activeTab = parseInt(defaultTab);
    } else if (isPermitted(this.global.cbacConfigFlag, this.global.permissions, 'View_User_List')) {
      activeTab = TABS.USER;
    } else if (isPermitted(this.global.cbacConfigFlag, this.global.permissions, 'Access_Staff_List')) {
      activeTab = TABS.STAFF;
    } else if (isPermitted(this.global.cbacConfigFlag, this.global.permissions, 'View_Form_List')) {
      activeTab = TABS.FORMS;
    } else if (isPermitted(this.global.cbacConfigFlag, this.global.permissions, 'Access_Communications_Tab')) {
      activeTab = TABS.COMMUNICATIONS;
    } else if (isPermitted(this.global.cbacConfigFlag, this.global.permissions, 'Access_Location_Settings')) {
      activeTab = TABS.SETTINGS;
    } else {
      activeTab = TABS.SERVICES;
    }
    this.setState({
      activeTab: activeTab,
    });
    this.setGlobal({
      hiddenBreadcrumbItems: [TABS_MAPPING[activeTab]],
    });
  };
  /**
   * @name setActiveTab
   * @param {number} activeTab
   * @desc Changes active tab state.
   * @return {void}
   */
  setActiveTab = (activeTab) => {
    this.setGlobal({
      hiddenBreadcrumbItems: [TABS_MAPPING[activeTab]],
    });
    this.setState({
      activeTab,
    });
  };

  get isServicesAvailable() {
    if (this.props.isTestSuite) {
      return true;
    }
    return !this.global.isTeacher && this.global.isSuperAdmin;
  }

  /**
   * @name changeLocationHandler
   * @param {object} event
   * @desc Fires when location change handler is fired.
   * @return {void}
   */
  changeLocationHandler = async (loc, type = 'facility') => {
    const { unleashProps } = this.props;

    if (this.state.changingLocation === true) {
      return;
    }

    this.setState({ changingLocation: true });
    const defaultTab = this.getDefaultTab(this.props.location.search);
    let parentLocationId = '';
    let parentLocationName = '';
    let route = `/manage/${loc.locationName.replace(/[^a-zA-Z0-9-_]/g, '')}`;
    if (type === 'hu') {
      if (SELECTED_HOUSING_UNIT) {
        parentLocationId = SELECTED_HOUSING_UNIT.parentLocationId;
      }
      parentLocationName = this.state.location.locationName;
      route = `/manage/${this.props.selectedLocation.locationName.replace(/[^a-zA-Z0-9-_]/g, '')}/${loc.locationName.replace(
        /[^a-zA-Z0-9-_]/g,
        ''
      )}`;
    }
    this.props.history.push(route);
    if (this.global.cbacConfigFlag) {
      const janusToken = await fetchJanusToken(loc.locationId);
      setJanusToken(janusToken.token || '');
      const { rolesTypes } = jwt_decode(janusToken.token);
      this.setGlobal({
        isSuperAdmin: rolesTypes.includes(ROLES.SUPER_ADMIN),
        isAttorney: rolesTypes.includes(ROLES.ATTORNEY),
        isTeacher: rolesTypes.includes(ROLES.TEACHER),
        isFacilityAdmin: rolesTypes.includes(ROLES.STAFF),
        isMessagingOnly: rolesTypes.includes(ROLES.MESSAGING_ONLY),
        selectedLocation: loc.locationId,
      });
    } else {
      this.setGlobal({
        selectedLocation: loc.locationId,
      });
    }
    this.setState(
      {
        location: {
          ...this.state.location,
          active: loc.locationId,
          locationName: loc.locationName,
          parentLocationId,
          parentLocationName,
          type,
        },
        changingLocation: false,
      },
      () => {
        if (unleashProps.isFlagEnabled() && (this.global.isSuperAdmin || this.global.isFacilityAdmin)) {
          this.getFacilityFeatures();
        }
      }
    );
    this.setLandingTab(defaultTab);
  };

  RenderFnFTab = ({ classes }) => {
    const isFlagEnabled = useFeatureFlagOnHook(UnleashService.FLAGS.MOVE_FRIENDS_AND_FAMILY_INTO_SEPARATE_MODULE);
    return (
      <>
        {!isFlagEnabled && (
          <UnleashProvider
            fallbackComponent={
              <PermissionControl permissionName="Access_Friendsandfamily_Tab">
                <ListItem
                  id="ff-tab-button"
                  data-cy-name="ff-tab-button"
                  onClick={() => {
                    this.setActiveTab(TABS.FNF);
                  }}
                  button
                  className={[classes.tabItem, this.state.activeTab === TABS.FNF ? classes.activeTab : null].join(' ')}
                >
                  {'FRIENDS & FAMILY MESSAGING'}
                </ListItem>
              </PermissionControl>
            }
            flagName={UnleashService.FLAGS.FNF_FOR_ADMIN_ONLY}
          >
            <PermissionControl
              forceRestriction={
                !this.isFeatureExist(this.managementService.FFMessaging) ||
                !(this.global.isSuperAdmin || this.global.isFacilityAdmin)
              }
              permissionName="Access_Friendsandfamily_Tab"
            >
              <ListItem
                id="ff-tab-button"
                data-cy-name="ff-tab-button"
                onClick={() => {
                  this.setActiveTab(TABS.FNF);
                }}
                button
                className={[classes.tabItem, this.state.activeTab === TABS.FNF ? classes.activeTab : null].join(' ')}
              >
                {'FRIENDS & FAMILY MESSAGING'}
              </ListItem>
            </PermissionControl>
          </UnleashProvider>
        )}
      </>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Suspense fallback={<Loader isLoading={true} />}>
        <Grid container item component="div" className={classes.content}>
          <br />
          <Snackbar
            onClose={() => this.toggleSnackBar(null, false)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={this.state.snackBar.open}
            autoHideDuration={2000}
            message={this.state.snackBar.snackBarMsg}
          />
          {/* BEGIN: SECTION */}
          {this.state.location.active !== this.NO_LOCATION && (
            <Grid container item style={{ overflow: 'auto' }} xs={12}>
              <List className={classes.tabWrapper}>
                <Box component="div" display="flex" alignItems="flex-end" justifyContent="flex-end">
                  <PermissionControl permissionName="View_User_List">
                    <ListItem
                      id="user-tab-btn"
                      data-cy-name="user-tab-btn"
                      onClick={() => this.setActiveTab(TABS.USER)}
                      button
                      className={[classes.tabItem, this.state.activeTab === TABS.USER ? classes.activeTab : null].join(' ')}
                    >
                      LEARNERS
                    </ListItem>
                  </PermissionControl>
                  <PermissionControl
                    forceRestriction={
                      !this.global.cbacConfigFlag && !(this.global.isSuperAdmin || this.global.isFacilityAdmin)
                    }
                    permissionName="Access_Staff_List"
                  >
                    <ListItem
                      id="staff-tab-btn"
                      data-cy-name="staff-tab-btn"
                      onClick={() => this.setActiveTab(TABS.STAFF)}
                      button
                      className={[classes.tabItem, this.state.activeTab === TABS.STAFF ? classes.activeTab : null].join(' ')}
                    >
                      STAFF
                    </ListItem>
                  </PermissionControl>
                  <PermissionControl
                    forceRestriction={
                      !this.global.cbacConfigFlag && !(this.global.isSuperAdmin || this.global.isFacilityAdmin)
                    }
                    permissionName="Access_Communications_Tab"
                  >
                    <ListItem
                      id="communication-tab-btn"
                      data-cy-name="paths-tab-btn"
                      onClick={() => {
                        this.setActiveTab(TABS.COMMUNICATIONS);
                      }}
                      button
                      className={[
                        classes.tabItem,
                        this.state.activeTab === TABS.COMMUNICATIONS ? classes.activeTab : null,
                      ].join(' ')}
                    >
                      COMMUNICATIONS
                    </ListItem>
                  </PermissionControl>
                  <UnleashProvider flagName={UnleashService.FLAGS.SHOW_CERTIFICATE_IN_ADMIN_PORTAL}>
                    {(this.global.isSuperAdmin || this.global.isFacilityAdmin) && this.state.location.type !== 'hu' && (
                      <ListItem
                        id="certificates-tab-btn"
                        data-cy-name="certificate-tab-btn"
                        onClick={() => {
                          this.setActiveTab(TABS.CERTIFICATES);
                        }}
                        button
                        className={[
                          classes.tabItem,
                          this.state.activeTab === TABS.CERTIFICATES ? classes.activeTab : null,
                        ].join(' ')}
                      >
                        CERTIFICATES
                      </ListItem>
                    )}
                  </UnleashProvider>
                  <UnleashProvider flagName={UnleashService.FLAGS.RESUME_MODULE}>
                    {(this.global.isSuperAdmin ||
                      (this.global.isFacilityAdmin && this.isFeatureExist(this.managementService.ResumeBuilder))) &&
                      this.state.location.type !== 'hu' && (
                        <ListItem
                          id="resume-tab-btn"
                          data-cy-name="resume-tab-btn"
                          onClick={() => {
                            this.setActiveTab(TABS.RESUME);
                          }}
                          button
                          className={[classes.tabItem, this.state.activeTab === TABS.RESUME ? classes.activeTab : null].join(
                            ' '
                          )}
                        >
                          RESUMES
                        </ListItem>
                      )}
                  </UnleashProvider>

                  {this.state.location.type !== 'hu' && (
                    <PermissionControl permissionName="View_Form_List">
                      <ListItem
                        id="forms-tab-btn"
                        data-cy-name="forms-tab-btn"
                        onClick={() => {
                          this.setActiveTab(TABS.FORMS);
                        }}
                        button
                        className={[classes.tabItem, this.state.activeTab === TABS.FORMS ? classes.activeTab : null].join(
                          ' '
                        )}
                      >
                        FORMS
                      </ListItem>
                    </PermissionControl>
                  )}
                  {this.isServicesAvailable && this.state.location.type !== 'hu' && (
                    <ListItem
                      id="services-tab-btn"
                      data-cy-name="services-tab-btn"
                      onClick={() => {
                        this.setActiveTab(TABS.SERVICES);
                      }}
                      button
                      className={[classes.tabItem, this.state.activeTab === TABS.SERVICES ? classes.activeTab : null].join(
                        ' '
                      )}
                    >
                      SERVICES
                    </ListItem>
                  )}
                  <PermissionControl permissionName="Access_Location_Settings">
                    <ListItem
                      id="settings-tab-btn"
                      data-cy-name="settings-tab-btn"
                      onClick={() => this.setActiveTab(TABS.SETTINGS)}
                      button
                      className={[classes.tabItem, this.state.activeTab === TABS.SETTINGS ? classes.activeTab : null].join(
                        ' '
                      )}
                    >
                      SETTINGS
                    </ListItem>
                  </PermissionControl>
                  {this.global.isSuperAdmin && (
                    <ListItem
                      id="fa-tab-btn"
                      data-cy-name="fa-tab-btn"
                      onClick={() => {
                        this.setActiveTab(TABS.FA);
                      }}
                      button
                      className={[classes.tabItem, this.state.activeTab === TABS.FA ? classes.activeTab : null].join(' ')}
                    >
                      BIOMETRIC AUTHENTICATION
                    </ListItem>
                  )}

                  <this.RenderFnFTab classes={classes} />
                </Box>
              </List>
            </Grid>
          )}
        </Grid>
        {/* END: SECTION */}
        {/* BEGIN: SECTION */}
        {this.state.location.active !== this.NO_LOCATION && (
          <Grid container item className={classes.tabContent}>
            {/* BEGIN: SETTINGS */}
            {this.state.activeTab === TABS.SETTINGS && (
              <div style={{ padding: '1.5rem 70px', width: '100%' }}>
                <EditLocation selectedLocation={{ ...this.state.location, locationId: this.state.location.active }} />
                <PermissionControl permissionName="Access_Location_Settings">
                  <Settings showSnackBar={this.toggleSnackBar} facility {...this.state} generalSectionTitle="Access" />
                </PermissionControl>
                {this.global.isSuperAdmin && <FacialAuthenticationSettings locationId={this.state.location.active} />}
                {this.state.location.type !== 'hu' && (
                  <ManageHousingUnits
                    locationId={this.state.location.active}
                    locationName={this.state.location.locationName}
                    showSnackBar={this.toggleSnackBar}
                  />
                )}
              </div>
            )}
            {/* END: SETTINGS */}
            {this.state.activeTab === TABS.CERTIFICATES && <Certificates locationId={this.state.location.active} />}
            {this.state.activeTab === TABS.RESUME && <Resume locationId={this.state.location.active} />}
            {/* BEGIN: SERVICES */}
            {this.state.activeTab === TABS.SERVICES && <LocationDetailsModal inLine />}
            {/* END: SERVICES */}
            {this.state.activeTab === TABS.USER && (
              <PermissionControl permissionName="View_User_List">
                <Users
                  history={this.props.history}
                  location={this.state.location.active}
                  locationName={this.state.location.locationName}
                  locationMeta={this.state.location}
                />
              </PermissionControl>
            )}
            {/* BEGIN: FORMS */}
            {this.state.activeTab === TABS.FORMS && (
              <PermissionControl permissionName="View_Form_List">
                <Forms showSnackBar={this.toggleSnackBar} {...this.state} />
              </PermissionControl>
            )}
            {/* END: FORMS */}
            {/* BEGIN: COMMUNICATIONS */}
            {this.state.activeTab === TABS.COMMUNICATIONS && (
              <PermissionControl
                forceRestriction={!this.global.cbacConfigFlag && !(this.global.isSuperAdmin || this.global.isFacilityAdmin)}
                permissionName="Access_Communications_Tab"
              >
                <Communications history={this.props.history} showSnackBar={this.toggleSnackBar} {...this.state} />
              </PermissionControl>
            )}
            {/* END: COMMUNICATIONS */}
            {/* BEGIN: STAFF */}
            {this.state.activeTab === TABS.STAFF && (
              <PermissionControl
                forceRestriction={!this.global.cbacConfigFlag && !(this.global.isSuperAdmin || this.global.isFacilityAdmin)}
                permissionName="Access_Staff_List"
              >
                <Staff showSnackBar={this.toggleSnackBar} {...this.state} history={this.props.history} />
              </PermissionControl>
            )}
            {/* END: STAFF */}
            {/* BEGIN: COMMUNICATIONS */}
            {this.state.activeTab === TABS.FA && <FacialAuth showSnackBar={this.toggleSnackBar} {...this.state} />}
            {/* END: COMMUNICATIONS */}

            {/* BEGIN: F&F MESSAGING */}
            {this.state.activeTab === TABS.FNF && (
              <PermissionControl permissionName="Access_Friendsandfamily_Tab">
                <FriendsAndFamilyMessaging {...this.state} locationId={this.state.location.active} />
              </PermissionControl>
            )}
            {/* END: F&F MESSAGING */}
          </Grid>
        )}

        {/* END: SECTION */}
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => {
  return state.app;
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectLocation: (location) => dispatch(selectLocation(location)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withUnleash(Management, UnleashService.FLAGS.RESUME_MODULE))));
