export default (theme) => ({
    content: {
        padding: '20px 30px 0px',
        textAlign: 'left',
        marginBottom: 30,
        backgroundColor: '#f3f3f3',
        [theme.breakpoints.down('md')]: {
            padding: '0px 30px'
        }
    },
    tabContent: {
        marginTop: 40,
        backgroundColor: '#f3f3f3'
    },

});