export default theme => ({
    dropDownBtn: {
      "& *": {
        fontSize: "inherit",
        fontWeight: "inherit",
        color: theme.palette.primary.cobalt,
        letterSpacing: "0.5px",
        textTransform: "capitalize"
      },
      borderRadius: 24,
      padding: "7px 25px"
    },
    root: {
      position: "relative",
      minWidth: 320,
      maxWidth: 320,
      minHeight: 200
    },
    earnTokenBtn: {
      ...theme.typography.t18,
      ...theme.typography.bold,
      lineHeight: "16px",
      letterSpacing: "1px",
      color: theme.palette.primary.cobalt,
      textAlign: "right",
      textTransform: "capitalize"
    },
    contentGap: {
      paddingLeft: 20,
      paddingRight: 20
    },
    activeBtn: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    },
    success: {
      color: theme.palette.primary.success,
      fontWeight: "inherit",
      fontSize: "inherit"
    },
    danger: {
      color: theme.palette.primary.danger,
      fontWeight: "inherit",
      fontSize: "inherit"
    },
    chipTitle: {
      maxWidth: 175
    },
    notifTitle: {
      ...theme.typography.medium,
      ...theme.typography.t14,
      marginBottom: 10,
      lineHeight: "18px",
      letterSpacing: "0.45px",
      color: theme.palette.primary.battleshipGrey
    },
    notifSubText: {
      ...theme.typography.medium,
      ...theme.typography.t12,
      marginBottom: 5,
      lineHeight: "16px",
      letterSpacing: "0.21px",
      color: theme.palette.primary.battleshipGrey
    },
    collapseView: {
      height: "0px !important", //USED IMPORTANT BECAUSE INLINE STYLE IS BEING EMBDDED TO INCREASE PROORITY
      bottom: 0,
      boxShadow: "none",
      padding: 0,
      overflow: "hidden"
    },
    iconWrapper: {
      opacity: 0.5,
      ...theme.typography.t18,
      ...theme.typography.medium,
      color: theme.palette.primary.black
    },
    icon: {
      marginLeft: 5
    },
    cardHeadingWrapper: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
      paddingBottom: 5,
      marginBottom: 5,
      paddingLeft: 15,
      paddingRight: 15
    },
    cardHeading: {
      ...theme.typography.medium,
      color: theme.palette.primary.jetBlack,
      letterSpacing: "1.5px",
      ...theme.typography.t10,
      lineHeight: "16px"
    },
    listItem: {
      marginTop: 20,
      borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
      paddingBottom: 5
    },
    listContainer: {
      overflow: "auto"
    },
    chip: {
      border: "1px solid",
      ...theme.typography.t14,
      ...theme.typography.medium,
      letterSpacing: "0.75px",
      lineHeight: "20px",
      "& > span": {
        maxWidth: 175
      }
    },
    listItemDateText: {
      marginTop: 3,
      ...theme.typography.t14,
      letterSpacing: "0.55px",
      color: theme.palette.primary.battleshipGrey,
      "& > time": {
        textTransform: "uppercase"
      }
    }
  });
  