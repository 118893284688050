import React from 'react';
import { Box, TextField, Snackbar } from '@material-ui/core';
import { Button, Typography } from '@orijinworks/frontend-commons';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './keywords-dictionary.style';
import KeywordsDictionaryBreadcrumbs from '../keywords-dictionary-breadcrumbs/keywords-dictionary-breadcrumbs';
import MaterialTable from 'material-table';
import useKeywordsDictionaryHook from './useKeywordsDictionaryHook';
import AddKeywordsDictionaryModal from '../add-keywords-dictionary-modal/add-keywords-dictionary-modal';
import KeywordDetail from '../keyword-detail/keyword-detail';
import HideKeywordsModal from '../hide-keywords-modal/hide-keywords-modal';

const KeywordsDictionary = ({ classes, locationId, closeKeywordsDictionary }) => {
  const {
    tableRef,
    tableColumns,
    getKeywordsList,
    modalRef,
    removeKeywordModalRef,
    isModalOpen,
    dictionaryId,
    refreshTable,
    handleAddKeywordDictionaryModal,
    handleHideKeywordsModal,
    disableKeyword,
    showSnackBar,
    snackbarMessage,
    snackbarType,
    handleSnackbarOpen,
    handleSnackbarClose,
    selectedKeywordData,
    closeKeywordDetail,
    handleSearchKeyword,
    onClickSearchKeyword,
    searchKeyword
  } = useKeywordsDictionaryHook({ classes, locationId });

  const tableColumnsRef = React.useRef(tableColumns);

  return (
    <>
      <Box className={classes.root} data-testid="keywords-dictionary-root">
        <KeywordsDictionaryBreadcrumbs
          closeKeywordsDictionary={closeKeywordsDictionary}
          selectedKeyword={selectedKeywordData?.keyword}
          closeKeywordDetail={closeKeywordDetail}
        />
      </Box>
      <Box className={classes.container} data-testid="keywords-dictionary-container">
        <IconButton
          className={classes.iconButton}
          onClick={selectedKeywordData ? closeKeywordDetail : closeKeywordsDictionary}
          data-testid="keywords-dictionary-back-button"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h1" className={classes.title} data-testid="keywords-dictionary-title">
          # {selectedKeywordData ? selectedKeywordData.keyword : 'Keyword Dictionary'}
        </Typography>
      </Box>
      {selectedKeywordData ? (
        <KeywordDetail
          keywordDictionaryId={dictionaryId}
          selectedKeyword={selectedKeywordData.keyword}
          dateAdded={selectedKeywordData.createdDate}
          staffName={selectedKeywordData.addedBy}
          isMonitored={selectedKeywordData.active}
          onError={handleSnackbarOpen}
        />
      ) : (
        <>
          <Box className={classes.toolBarContainer} data-testid="keywords-dictionary-toolbar">
            <Box className={classes.searchBarContainer} data-testid="keywords-dictionary-searchbar">
              <TextField
                className={classes.searchField}
                placeholder="Search Keywords"
                variant="outlined"
                size="small"
                value={searchKeyword}
                data-testid="keywords-dictionary-search-field"
                onChange={handleSearchKeyword}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.searchButton}
                data-testid="keywords-dictionary-search-button"
                onClick={onClickSearchKeyword}
              >
                Search
              </Button>
            </Box>
            <Box className={classes.buttonsContainer} data-testid="keywords-dictionary-buttons">
              <Button
                variant="contained"
                color="primary"
                className={classes.addButton}
                data-testid="keywords-dictionary-add-button"
                onClick={handleAddKeywordDictionaryModal}
              >
                + Add New Keyword
              </Button>
            </Box>
          </Box>
          <Box className={classes.tableContainer} data-testid="keywords-dictionary-table">
            <MaterialTable
              title=""
              tableRef={tableRef}
              columns={tableColumnsRef.current.map((column) => ({
                ...column,
                headerStyle: {
                  fontWeight: 'bold',
                  color: '#061E44',
                  borderBottom: '1px solid #061E44',
                },
              }))}
              data={getKeywordsList}
              options={{
                search: false,
                toolbar: false,
                pageSize: 10,
                thirdSortClick: false,
                rowStyle: (rowData, index) => ({
                  backgroundColor: index % 2 === 0 ? '#F8F9FC' : '#FFFFFF',
                }),
              }}
            />
          </Box>
          <AddKeywordsDictionaryModal
            open={isModalOpen}
            ref={modalRef}
            refreshTable={refreshTable}
            dictionaryId={dictionaryId}
            onClose={handleAddKeywordDictionaryModal}
            handleAddKeywordDictionaryModal={handleAddKeywordDictionaryModal}
            handleSnackbarOpen={handleSnackbarOpen}
          />
          <HideKeywordsModal
            open={false}
            ref={removeKeywordModalRef}
            onClose={handleHideKeywordsModal}
            onContinue={disableKeyword}
          />
        </>
      )}
      <Snackbar open={showSnackBar} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={snackbarType}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default withStyles(styles)(KeywordsDictionary);
