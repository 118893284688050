import { fontFamily, fontSize, lineHeight } from "styled-system";

export default (theme) => ({
    keywordsManagementSection: {
        display: 'flex',
        width: '960px',
        padding: '16px 12px',
        alignItems: 'center',
        gap: '128px',
        borderRadius: '16px',
        background: theme.palette.primary.paleBlue
    },
    keywordsManagementSectionHeading: {
        display: 'flex',
        gap: '10px',
        alignItems: 'baseline',
    },
    dictionaryBtn: {
        width: '220px',
        height: '40px'
    }, 

    keywordManagementHeading:{
        fontSize: '30px',
        fontWeight: 800,
        fontFamily: 'inter',
        lineHeight: '36.31px'
    }
});