
export default (theme) => ({
  Accordian: {
    '&:before': {
      display: 'none',
    },
    marginTop: '14px',
    width: '100%',
    padding: '14px',
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 6px 0px rgba(0, 0, 0, 0.12)',
    bordeRadius: "8px",
    "border": `1px solid ${theme.palette.colors.ceruleanMain}`,
  },
  Summary: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
  },
  ExpandIcon: {
    color: 'blue', // Change the color to blue
  },
  ScheduleName: {
    fontWeight: 'bold',
  },
  SaveButton: {
    marginRight: '8px !important',
  },
  CreationDate: {
    fontSize: 15,
    color: theme.palette.primary.black, // Adjust color
    marginTop: '5px',
  },
  Details: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '32px',
  },
  DetailItem: {
    marginBottom: '10px', // Space between detail items
    display: 'flex',
    flexDirection: 'column',
  },
  DetailTitle: {
    fontWeight: 'bold',
    width: '100%',
  },
  CallScheduleHeading: {
    fontWeight: 'bold',
    width: '100%',
    marginBottom: '14px',
  },
  DetailItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  ButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
  EditButton: {
    marginLeft: '16px !important',
    marginTop: '16px !important',
  },
  Divider: {
    margin: '16px 0',
  },
  DeleteContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  Modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  ModalIcon: {
    fontSize: '2rem',
    color: 'white',
  },

  ModalButton: {
    minWidth: '80px',
    marginRight: '8px !important',
  },

  DeleteAvatar: {
    backgroundColor: theme.palette.colors.redMain,
    width: '50px',
    height: '50px',
  },

  ModalButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(4),
  },
});
