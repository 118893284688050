import React from 'reactn';
import { DialogContentText, DialogContent, DialogTitle, Dialog, DialogActions, Button, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { getMsgSnippet } from '../../../../../util/APIUtils';
import CloseIcon from '@material-ui/icons/Close';
import { parseTimeToNewZone } from '../../../../users/learner/transcript/utils';
import { recipientUserFormat } from '../../../../../v2/services/utilty';


class Snippet extends React.Component {

    state = {
        isLoading: false,
        data: null,
        isRestrictedView: false
    };

    componentDidUpdate() {
        const { isLoading, data } = this.state;
        if (this.props.isOpen && !isLoading && !data && !this.props.isAnnouncement) {
            this.getMsgSnippet(this.props.messageId);
        } else if(this.props.isAnnouncement && !data) {
            this.setAnnouncementSnippet();
        }
    };


    /**
     * @name getMsgSnippet
     * @param {number} messageID
     * @desc Fetch's msg snippet based upon messageID
     * @return {void}
     */
    getMsgSnippet = (messageID) => {

        this.setGlobal({
            isLoading: true
        });
        this.setState({
            isLoading: true,
            data: null
        });

        const requestPayload = {
            "userAgentInfo": window.navigator.userAgent
        };

        getMsgSnippet(requestPayload, messageID)
            .then(
                (_successLog) => {
                    this.setGlobal({
                        isLoading: false
                    });
                    this.setState({
                        isLoading: false,
                        data: _successLog || {},
                        isRestrictedView: !!!_successLog
                    });
                },
                (_errorLog) => {
                    this.setGlobal({
                        isLoading: false
                    });
                    this.setState({
                        isLoading: false,
                        data: null
                    });
                }
            );
    };

    setAnnouncementSnippet = () => {
        const { createdDate, senderPersonName, recipientGroupsNames, recipientUserFullNames, locationName, body, title} = this.props;
        this.setState({
            data: {
                recipientPersonName: recipientUserFormat(locationName, recipientUserFullNames, recipientGroupsNames,3) || 'N/A',
                senderPersonName,
                body,         
                createdDate,            
                title,
            }
        });
    }

    /**
     * @name highlightSearchString
     * @param {string} sentence
     * @param {string} searchWord
     * @desc Highlight's first occurrence of searchWord in sentence.
     * @return {HTML}
     */
    highlightSearchString = (sentence, searchWord) => {
        if (searchWord !== "" && searchWord) {
            return sentence
                .toLowerCase()
                .replace(new RegExp(searchWord.toLowerCase(), 'g'), `<span style="background-color: #ffeea7; color: black; font-weight: 500; padding: 0px 5px;">${searchWord.toLowerCase()}</span>`);
        }
        return sentence;
    };


    /**
     * @name closeHandler
     * @dsec Fire's when dialog box is closed.
     * @return {void}
     */
    closeHandler = () => {
        this.props.onClose();
        this.setState({
            data: null
        });
    };

    render() {
        const { isAnnouncement } = this.props;
        return (
            <Dialog onClose={this.closeHandler} aria-labelledby="Message Text Snippet" open={this.props.isOpen}>
                {this.state.data && (
                    <>
                        <AppBar style={{ position: "relative" }}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={this.closeHandler} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6">
                                    {this.state.isRestrictedView && (
                                        "Restricted View"
                                    )}
                                    {!this.state.isRestrictedView && !isAnnouncement ? "Message Snippet" : 'Announcement Details'}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <DialogContentText>
                                {this.state.isRestrictedView && (
                                    "No data found."
                                )}

                                {!this.state.isRestrictedView && (
                                    <>
                                        <table width="100%">
                                            <tr>
                                                <th style={{textAlign: "left"}}>
                                                    Sender Name:
                                                </th>
                                                <td>
                                                    {this.state.data.senderPersonName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{textAlign: "left"}}>
                                                    {isAnnouncement ? 'Recipient(s)' : 'Recipient Name'}:
                                                </th>
                                                <td>
                                                    {this.state.data.recipientPersonName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th style={{textAlign: "left"}}>
                                                    Date:
                                                </th>
                                                <td>
                                                    {parseTimeToNewZone(this.state.data.createdDate, this.props.timezoneName)}
                                                </td>
                                            </tr>
                                            {isAnnouncement &&
                                                <tr>
                                                    <th style={{textAlign: "left"}}>
                                                        title:
                                                    </th>
                                                    <td>
                                                        {this.state.data.title}
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <th style={{textAlign: "left"}}>
                                                    {isAnnouncement ? 'Announcement' : 'Message'}:
                                                </th>
                                                <td>
                                                    <div dangerouslySetInnerHTML={{ __html: this.highlightSearchString(this.state.data.body, this.props.searchTerm) }}></div>
                                                </td>
                                            </tr>
                                        </table>
                                    </>
                                )}
                            </DialogContentText>
                        </DialogContent>
                        {!isAnnouncement && 
                            <DialogActions>
                                <Button onClick={this.closeHandler} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        }
                    </>
                )}
            </Dialog>
        );
    };
};

export default Snippet;