import React, { useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  IconButton,
  Box,
  CircularProgress,
  List,
  ListItem,
  Divider,
  Grid,
  Fade,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import styles from './index.styles';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';
import { useAddNewLearnerGroupHook } from './useAddNewLearnerGroupHook';
import { UNREACHABLE_MEMBER_ERROR } from '../../../../v2/services/constants';

const AddNewLearnerGroupDialog = ({
  open,
  handleClose,
  classes,
  editLearnerGroupId,
  updatedGroupData,
  preSelectedLearners,
}) => {
  const {
    search,
    filteredLearners,
    isLoading,
    showMessageMonitoredWarning,
    learnerGroupError,
    groupName,
    selectedLearner,
    groupMembers,
    setGroupMembers,
    removeGroupMember,
    handleAddLearner,
    getUnselectedUser,
    onUserSelect,
    onSearchChange,
    handleCancelClick,
    isSubmitDisabled,
    clearErrors,
    onGroupNameChange,
    onSubmit,
  } = useAddNewLearnerGroupHook({ handleClose, editLearnerGroupId, updatedGroupData, preSelectedLearners });

  return (
    <Dialog fullWidth open={open} onClose={handleCancelClick}>
      <AppBar className={classes.appBar}>
        {learnerGroupError && (
          <Fade in={showMessageMonitoredWarning}>
            <Box id="warning-box" className={classes.warningBox}>
              <Box display="flex" alignItems={'center'} flex={1}>
                <CustomIcons icon={icons.WarningIcon} className={classes.warningIcon} />
                <Typography>{learnerGroupError}</Typography>
              </Box>
              <Box>
                <IconButton onClick={clearErrors}>
                  <CustomIcons icon={icons.CloseIcon} className={classes.closeIcon} />
                </IconButton>
              </Box>
            </Box>
          </Fade>
        )}
        <Toolbar>
          <Typography id="dialog-title" variant="h6" className={classes.title}>
            {editLearnerGroupId ? 'Edit' : 'Create'} Learner Group
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleCancelClick} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.groupTitle} variant="subtitle2">
          {' '}
          Group Name
        </Typography>
        <TextField
          autoFocus
          className={classes.field}
          id="group-name-field"
          label="Group"
          variant="outlined"
          onChange={(e) => {
            onGroupNameChange(e);
          }}
          value={groupName}
          InputProps={{
            classes: {
              root: classes.outlinedField,
              input: classes.input,
            },
          }}
        />

        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography className={classes.searchTitle} variant="subtitle2">
              Search Learners (by Name or DOC ID)
            </Typography>

            <Box className={classes.suggestionWrapper}>
              <Box
                component={'input'}
                type="text"
                value={search}
                id="learners-field"
                onChange={(e) => onSearchChange(e.target.value)}
                className={classes.formField}
              />

              {!selectedLearner && search !== '' && (
                <Box className={classes.suggestionBox}>
                  {isLoading && <CircularProgress className={classes.loader} />}
                  <List>
                    {getUnselectedUser(filteredLearners).map((item) => (
                      <ListItem
                        key={item.contactId}
                        onClick={() => onUserSelect(item)}
                        button
                        className={[classes.suggestedChip].join(' ')}
                      >
                        <Typography className={classes.suggestedChipText}>
                          {item.contactName} {item.contactUsername ? `- ${item.contactUsername}` : ''}{' '}
                          {`(DOC ID: ${item.docId} )`}
                        </Typography>
                      </ListItem>
                    ))}

                    {getUnselectedUser(filteredLearners).length === 0 && !isLoading && (
                      <ListItem disabled button className={[classes.suggestedChip, classes.notFoundChip].join(' ')}>
                        <Typography className={classes.suggestedChipText}> {search}</Typography>
                        &nbsp;-&nbsp;
                        <Typography className={classes.notFoundText}>NOT FOUND</Typography>
                      </ListItem>
                    )}
                  </List>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Button id="add-learner-btn" className={classes.addBtn} onClick={handleAddLearner} variant="contained">
              Add
            </Button>
          </Grid>
        </Grid>

        <Divider />

        <Grid className={classes.learnersWrapper} container spacing={2}>
          <Grid className={classes.learnersTitle} item xs={10}>
            <Typography variant="subtitle2">Group Members ({groupMembers.length})</Typography>
          </Grid>

          <Grid item xs={2}>
            <Button id="clear-learners-btn" className={classes.clearBtn} onClick={() => setGroupMembers([])}>
              Clear
            </Button>
          </Grid>
        </Grid>

        <div className={classes.boxMain}>
          {(groupMembers || []).map((member) => (
            <Box className={classes.boxWrapper}>
              {!member.reachable && (
                <Tooltip title={UNREACHABLE_MEMBER_ERROR}>
                  <Box>
                    <CustomIcons icon={icons.WarningIcon} className={classes.learnerWarningIcon} />
                  </Box>
                </Tooltip>
              )}
              <div>
                <div className={[classes.boxText, classes.learnerNameText].join(' ')}>{member.contactName}</div>
                <div className={classes.boxText}>DOC ID: {member.docId}</div>
              </div>
              <Button onClick={() => removeGroupMember(member.contactId)} className={classes.crossButton}>
                <span className={classes.boxText}>X</span>
              </Button>
            </Box>
          ))}
        </div>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={onSubmit}
          id="create-group-btn"
          color="primary"
          variant="contained"
          className={classes.saveBtn}
          disabled={isSubmitDisabled()}
        >
          {editLearnerGroupId ? 'Save Changes' : 'Create Group'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(AddNewLearnerGroupDialog);
