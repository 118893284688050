import useUnleashHook from "./useUnleashHook";
import { connect } from "react-redux";

/**
 * @name UnleashProvider
 * @param {*} children
 * @param {string} flagName
 * @param {object} userDetail
 * @param {object} selectedLocation
 * @description - Provider to wrap around a component to check if a flag is enabled 
 * @returns {component} - children if flag is enabled, null otherwise
 */

const UnleashProvider = ({children, flagName, userDetail, selectedLocation, fallbackComponent = null}) => {
  const { isFlagEnabled } = useUnleashHook(
    flagName,
    userDetail.userName, 
    userDetail.userId,
    selectedLocation
  );
  return isFlagEnabled() && Boolean(children) ? children : fallbackComponent;
};

const mapStateToProps = ({ app }) => ({
  userDetail: app.userDetail,
  selectedLocation: app.selectedLocation
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UnleashProvider);
