import React ,{ useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContacts, fetchSearchLearner, postMessage } from '../../../../../../util/APIUtils';
import { rsSetDirectMessages, rsSetSelectedContent, setNewMessage} from '../../../../../core/store/reducers/messageModuleReducer';
import { ID_TYPES,SELECTED_HOUSING_UNIT, SELECTED_LOCATION } from '../../../../../../constants';
import { transformMsg } from '../../../../../services/utilty';
import { FAILED_MESSAGE_SENT, SUCCESS_MESSAGE_SENT } from '../../../../../services/constants';

export const useNewMessageHook = props => {

  const dispatch = useDispatch();
  const { unleashProps } = props;
  const { newMessage, rsSelectedContact } = useSelector(state => state.rsMessageModuleStore);
  const location = useSelector(state => state.app.selectedLocation);
  const [learners, setLearner] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredLearners, setFilteredLearners] = useState([]);

  const [recipients, setSelectedRecipients] = useState([SELECTED_HOUSING_UNIT || SELECTED_LOCATION]);
  const [body, setBody] = useState('');
  const [isMessageSending, setMessageSending] = useState(false);
  const [isLinkUserOpen, setLinkUserOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = React.useState(3000);
  const [isSuccessSnackbar, setIsSuccessSnackbar] = React.useState(true);

  const onSearchChange = value => {
    if (value !== '') {
      setFilteredLearners(learners.filter(item => {
        return item.contactName.toLowerCase().includes(value.toLowerCase());
      }));
    } else {
      setFilteredLearners([]);
    }
    setSearch(value);
  };

  const onUserSelect = async data => {
    setFilteredLearners([]);
    setSearch('');
    setSelectedRecipients([...recipients, data]);
  };

  const onSearch = async key => {
    return await fetchSearchLearner(key, location.locationId);
  };

  async function fetchLearners() {
    setLoading(true);
    const res = (await onSearch('%25')) || [];
    res.sort((a, b) => {
      const nameA = a.contactName.toLowerCase();
      const nameB = b.contactName.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    setLearner(res);
    setLoading(false);
  }


  const removeSelectedUser = data => {
    setSelectedRecipients(
      recipients.filter(item => item.contactId !== data.contactId)
    );
  };

  const onSend = () => {
    setMessageSending(true);
    const contactName = newMessage ? recipients[0].contactName : rsSelectedContact.contactName;
    return new Promise(async (resolve, reject) => {
      const sendResponse = await postMessage({
        body: transformMsg(body),
        to_person_key: newMessage ? recipients[0].contactId : rsSelectedContact.contactId,
        to_location_key: location.locationId,
        is_announcement: false
      });
      if (unleashProps.isFlagEnabled()) {
        handleSnackbar(`${SUCCESS_MESSAGE_SENT} ${contactName}`, true, 3000);
      }
      const contacts = await fetchContacts(location.locationId, 'recent_message');
      const selectedContact = (contacts.messageThreads || []).find(
        contact => contact.contactId === sendResponse.recipientId
      );
      
      if (selectedContact) {
        const filteredContacts = (contacts.messageThreads || []).filter(contact => contact.contactId !== sendResponse.recipientId); // REMOVING THE SEND FROM COLLECTION
        filteredContacts.unshift({
          ...selectedContact,
          isLearnerBlocked: false
        }); // ADDING IT AGAIN WITH UPDATED VALUES

        dispatch(rsSetDirectMessages({ rsMessageThread: filteredContacts }));
        dispatch(
          rsSetSelectedContent({
            rsSelectedContact: selectedContact,
            rsActiveThread: 'contacts'
          })
        );
        dispatch(setNewMessage(false));
      }
      resolve(true);
    }).catch(error => {
      if (unleashProps.isFlagEnabled()) {
        handleSnackbar(`${FAILED_MESSAGE_SENT} ${contactName}`, false, 3000);
      }
    });
  };

  const getUnselectedUser = userList => {    
    return (userList || []).filter(user => {
      return !recipients.find(_selectedUser => _selectedUser[ID_TYPES.CONTACT_ID] === user[ID_TYPES.CONTACT_ID]);
    });
  };

  const resetComponent = () => {
    setSearch('');
    setSelectedRecipients([]);
  };

  const onMessageClose = () => dispatch( setNewMessage(false) )

  useEffect(() => {
    setSearch('');
    setSelectedRecipients([]);
    fetchLearners();
  }, [location]);

  const handleSnackbar = (message, success, duration) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
    setSnackbarAutoHideDuration(duration);
    setIsSuccessSnackbar(success);
  };

  const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnackbar(false);
  };

  return {
    recipients,
    isLoading,
    search,
    isLinkUserOpen,
    filteredLearners,
    isMessageSending,
    learners,
    body,
    location,
    learners,
    openSnackbar,
    snackbarMessage,
    snackbarAutoHideDuration,
    isSuccessSnackbar,
    getUnselectedUser,
    onUserSelect,
    setLinkUserOpen,
    onSearchChange,
    removeSelectedUser,
    setBody,
    resetComponent,
    setNewMessage,
    fetchLearners,
    onSend,
    onMessageClose,
    setSelectedRecipients,
    handleCloseSnackbar,
  };
};
