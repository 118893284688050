import { CustomTheme } from '@orijinworks/frontend-commons';

export default theme => ({
  locationName: {
    color: '#747474',
    letterSpacing: '1.25px',
    fontSize: 11,
    fontWeight: 700
  },
  datatableBlueBox: {
    color: theme.palette.primary.cobalt
  },
  faImage: {
    maxWidth: 250
  },
  tabName: {
    letterSpacing: '0.15px',
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 500
  },
  datatable: {
    '& td': {
      fontSize: 14,
      letterSpacing: '0.04px',
      color: '#000000'
    }
  },
  topActionBar: {
    backgroundColor: '#f3f3f3'
  },
  card: {
    borderRadius: 0,
    marginLeft: 30
  },
  reloadIcon: {
    color: CustomTheme.palette.navy.main
  },
  chip: {
    backgroundColor: '#d3d3d3',
    padding: '5px 10px',
    borderRadius: 3,
    marginLeft: 5
  },
  chipText: {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#6f6f6f'
  },
  submissionText: {
    fontSize: 14,
    letterSpacing: '0.04px',
    fontWeight: 700,
    color: theme.palette.text.navy
  },
  formName: {
    fontSize: 18,
    textAlign: 'left',
    maxWidth: 200,
    color: theme.palette.text.navy,
    letterSpacing: '0.43px',
    textTransform: 'capitalize'
  }
});
