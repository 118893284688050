import { useState, useRef, useEffect } from 'react';
import { VoiceCallService } from '../../../../services/voice-call.service';
import { deleteManagementAttributes, updateManagementAttributes } from '../../../../../../util/APIUtils';
import { useContactManagementHook } from '../../../contact-management/useContactManagementHook';
import { VOICE_CALL } from '../../../../constants/voice-call-constants';

const fetchGroupScheduleSettings = async ({ locationId, scheduleId }) => {
  const voiceCallService = new VoiceCallService();

  try {
    const data = await voiceCallService.getScheduledList({ locationId, scheduleId });
    return data.content.flatMap(content => content.schedule);
  } catch (error) {
    throw new Error(error.message);
  }
};
const useGroupScheduleSettingsHook = ({ locationId }) => {
  const { groupScheduleDefault } = useContactManagementHook({locationId});
  const [isCreateScheduleModalOpen, setIsCreateScheduleModalOpen] = useState(false);
  const [isNewScheduleAdded, setIsNewScheduleAdded] = useState(false);
  const [groupScheduleSettings, setGroupScheduleSettings] = useState([]);
  const [allSchedulesOptions, setAllSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSnackBar, setShowSnackBar] = useState(false); 
 const modalRef = useRef(null);
  const handleCreateScheduleModalOpen = () => {
    modalRef.current.toggleModal();
  };

  useEffect(() => {
    if(groupScheduleDefault && groupScheduleDefault.attributeValue){
      const defaultGroupSchedule = allSchedulesOptions.find(schedule => schedule.value == groupScheduleDefault.attributeValue);
      setSelectedSchedule(defaultGroupSchedule ?  defaultGroupSchedule : allSchedulesOptions[0]);
    }
    else{
      setSelectedSchedule(allSchedulesOptions[0]);
    }
  },[groupScheduleDefault,allSchedulesOptions])

  const handleDefaultScheduleChange = (event, defaultSchedule) => {
    handleUpdate(defaultSchedule);
  };

  const handleUpdate = async (defaultSchedule) => {
    try {
      setSelectedSchedule(defaultSchedule);
      const obj = {
        attributeId: groupScheduleDefault && groupScheduleDefault.attributeId ? groupScheduleDefault.attributeId : null,
        attributeName: VOICE_CALL.GROUP_SCHEDULE_DEFAULT_ATTRIBUTE_KEY,
        attributeValue: defaultSchedule.value,
      };
      if(defaultSchedule.value === -1){
        await deleteManagementAttributes({
          locationId: locationId,
          locationAttributes: [obj],
        });
      }
      else{
        await updateManagementAttributes({
          locationId: locationId,
          locationAttributes: [obj],
        });
      }

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const loadGroupScheduleSettings = async () => {
      setLoading(true);
      setError(null);
      setAllSchedules([]);
      try {
        const data = await fetchGroupScheduleSettings({ locationId });
        if (data) {
          const sortedData = data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          setGroupScheduleSettings(sortedData);
          populateDefaultScheduleOptions(sortedData);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadGroupScheduleSettings();
  }, [locationId, isNewScheduleAdded]);

  const onDeleteSchedule = () => {
    setLoading(true);
    fetchGroupScheduleSettings({ locationId })
      .then((data) => {
        if (data) {
          const sortedData = data.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          setGroupScheduleSettings(sortedData);
          populateDefaultScheduleOptions(sortedData);
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setShowSnackBar(true);
      });
  };

  const populateDefaultScheduleOptions = (sortedData)=>{
    const optionsData = sortedData.map((schedule) => ({
      value: schedule.id,
      label: schedule.scheduleName,
    }));
    optionsData.unshift({ value: -1, label: 'No Schedule' });
    setAllSchedules(optionsData);
  };

  const handleCreateScheduleModalClose = () => {
    setIsCreateScheduleModalOpen(false);
  };
  return {
    isCreateScheduleModalOpen,
    handleCreateScheduleModalOpen,
    handleCreateScheduleModalClose,
    modalRef,
    isNewScheduleAdded,
    groupScheduleSettings,
    loading,
    error,
    showSnackBar,
    setShowSnackBar,
    onDeleteSchedule,
    setIsNewScheduleAdded,
    handleDefaultScheduleChange,
    allSchedulesOptions,
    selectedSchedule,
  };
};

export default useGroupScheduleSettingsHook;
