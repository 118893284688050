import { withStyles } from '@material-ui/styles';
import React from 'reactn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ProgramCourses from '../program-courses/'
import { getLearnerPrograms, unassignProgramFromUser, getProgramCourses } from '../../../../util/APIUtils';

import styles from './index.style';
import store, { showToast } from '../../../../v2/core/store/.';

import Datatable, { MTableToolbar } from "material-table";
import { CustomToolTip } from '../../../CustomToolTip';
import Hoverable from '../../../hoverable';




const availabilityToolTip = () => (
  <Box>
    <Typography component="p">
      <b>All Learners</b> : This program appears in the Program Catalog for all
      learners at this location.
    </Typography>
    <Typography component="p">
      <b>Assigned</b> : This program appears in this learner's Program Catalog
      because it has been assigned by staff.
    </Typography>
    <Typography component="p">
      <b>Not Assigned</b> : This program does not currently appear in the
      learner's Program Catalog."
    </Typography>
  </Box>
);

const enrollmentTooltipContent = () => (
  <Box>
    <Typography component="p">
      <b>ENROLLED</b>: The learner has enrolled themself into the courses
      comprising this program.
    </Typography>
    <Typography component="p">
      <b>UNENROLLED</b>: The learner has unenrolled themself from this program.
    </Typography>
    <Typography component="p">
      <b>PENDING</b>: The program is available to the learner, but the learner
      has not enrolled yet.
    </Typography>
  </Box>
);





class Programs extends React.Component {
  state = {
    data: null,
    showEmptyDataSourceMessage: false,
    errorMsg: "",
    courses: {},
    dialogProperty: {
      bundleName: "",
      descriptionShort: "",
      dialogOpen: false,
      bundleId: null
    }
  };

  componentDidMount() {
    if (!this.state.data) {
      this.getPrograms(this.global.selectedUser);
    }
  };

  getPrograms = async (personId) => {
    this.setGlobal({
      isLoading: true
    });
    this.setState({
      showEmptyDataSourceMessage: false
    });
    await getLearnerPrograms(personId)
      .then(
        (_successLog) => {
          this.setGlobal({
            isLoading: false
          });
          this.setState({
            data: _successLog,
            showEmptyDataSourceMessage: true
          });
        },
        (_errorLog) => {
          this.setGlobal({
            isLoading: false
          });
        }
      );
  };

  unassign = async (bundle) => {
    try {
      this.setGlobal({ isLoading: true });

      const body = {
        programKeys: [bundle.bundleId],
        locationKey: this.props.locationId,
        learnerPersonKeys: [this.global.selectedUser]
      }
      const response = await unassignProgramFromUser(body);

      this.setGlobal({ isLoading: false });

      if (!response) {
        this.showErrorMessage()
      }

      const processing = response.programs[0].programAssignees
      if (processing[0].statusCode === 0) {
        this.getPrograms(this.global.selectedUser);
      } else {
        this.showErrorMessage()
      }
    } catch (e) {
      this.setGlobal({ isLoading: false });
      this.showErrorMessage()
    }
  };

  showErrorMessage = () => {
    store.dispatch(
      showToast("Sorry, something went wrong. Please try again! ")
    )
  }

  handleChange = (programId) => (event, isExpanded) => {
    if (isExpanded && !this.state.courses[programId]) {
      this.getCourses([programId])
    }
  }

  getCourses = async (programId) => {
    try {
      const response = await getProgramCourses(this.global.selectedUser, programId)

      if (!response) {
        this.setState({
          courses: {
            ...this.state.courses,
            [programId]: []
          }
        });
      }

      this.setState({
        courses: {
          ...this.state.courses,
          [programId]: response.courses
        }
      });

    } catch (e) {
      this.setState({
        courses: {
          ...this.state.courses,
          [programId]: []
        }
      });
    }
  };


  handleChangeDialog = (programId) => {
    if (!this.state.courses[programId]) {
      this.getCourses([programId]);
    }
  };


  handleDialogClose = () => {
    this.setState({ dialogProperty: { dioladOpen: false } });
  };

  openDialog = async (rowData) => {
    const { bundleId } = rowData;
    this.setState({
      dialogProperty: {
        ...rowData,
        dialogOpen: true
      }
    });

    this.handleChangeDialog(bundleId);
  };

  checkAvailabilityStatus = (rowData) => {
    let value = "";
    const { autoAssign, assigned } = rowData;
    if (autoAssign) {
      value = "All Learners";
    } else if (!autoAssign) { //  UNENROLLED , ENROLLED, PENDING
      value = assigned ? "Assigned" : "Not Assigned"
    }
    return value;
  };

  render() {
    const { classes } = this.props;

    if (this.state.data) {
      return this.state.data && this.state.data.length > 0 ? (
        <>
          <Box width="100%" className={classes.tableWrapper}>
            {this.state.dialogProperty.dialogOpen && (
              <DialogBox
                dialogProperty={this.state.dialogProperty}
                handleChange={this.handleDialogClose}
                courses={
                  this.state.courses[this.state.dialogProperty.bundleId]
                }
              />
            )}

            <Datatable
              columns={[
                {
                  field: "bundleName",
                  title: (
                    <CustomToolTip heading={"Program"} displayIcon={false} />
                  ),
                  render: (rowData) => {
                    return (
                      <Hoverable title={"Program"}>
                        <Button
                          style={{ textAlign: "left" }}
                          color="primary"
                          onClick={() => this.openDialog(rowData)}
                        >
                          {rowData.bundleName}
                        </Button>
                      </Hoverable>
                    );
                  }
                },
                {
                  field: "",
                  title: (
                    <CustomToolTip
                      heading={"Availability"}
                      title={availabilityToolTip()}
                    />
                  ),
                  render: (rowData) => {
                    const availabilityStatus = this.checkAvailabilityStatus(rowData);
                    return (
                      <Hoverable title={availabilityStatus}>
                        <Typography component="p">{availabilityStatus}</Typography>
                      </Hoverable>
                    );
                  }
                },
                {
                  field: "enrollmentState",
                  title: (
                    <CustomToolTip
                      heading={"Enrollment State"}
                      title={enrollmentTooltipContent()}
                    />
                  ),
                  render: (rowData) => {
                    const { enrollmentState, autoAssign, assigned } = rowData;
                    let value = enrollmentState;

                    if (!autoAssign && !enrollmentState && !assigned) {
                      value = "N/A";
                    }

                    return (
                      <Hoverable title={value}>
                        <Typography component="p" style={{ textTransform: "capitalize" }}>
                          {value.toLowerCase()}
                        </Typography>
                      </Hoverable>
                    );
                  }
                },
                {
                  field: "",
                  title: "",
                  render: (rowData) => {
                    const status = this.checkAvailabilityStatus(rowData)
                    return (
                      status === "Assigned" && (
                        <Button
                          className={classes.unassignButton}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.unassign(rowData);
                          }}
                          color="primary"
                          startIcon={<RotateLeftIcon />}
                        >
                          Unassign
                        </Button>
                      )
                    );
                  }
                }
              ]}
              data={this.state.data}
              options={{
                sorting: true,
                exportButton: false,
                search: false,
                pageSize: 10,
                rowStyle: (rowData) => {
                  const { enrollmentState, autoAssign } = rowData;
                  return !autoAssign && !enrollmentState ? { background: "#e6e6e6" } : null;
                },
              }}
              components={{
                Toolbar: (props) => null,
              }}
            />
          </Box>
        </>
      ) : (
        this.state.showEmptyDataSourceMessage && (
          <Box width="100%">
            <p>This learner is not enrolled in any programs.</p>
          </Box>
        )
      );
    } else {
      return <></>;
    }
  }
}

const DialogBox = ({ dialogProperty, handleChange, courses = [] }) => {
  const { bundleName, descriptionShort, dialogOpen } = dialogProperty;

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleChange}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{bundleName}</DialogTitle>
      <DialogContent>
        <DialogContentText>{descriptionShort}</DialogContentText>

        <Box width="100%">
          <Box>
            {courses.length ? (
              <ProgramCourses courses={courses} />
            ) : (
              <Box>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};


export default withStyles(styles)(Programs);
