import React, { Suspense } from 'reactn';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  ButtonBase,
  Paper,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  MenuItem,
  FormHelperText,
  Zoom,
  Slide,
  InputBase,
  IconButton,
  List,
  ListItem,
  Box
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ReactChartkick, { ColumnChart } from 'react-chartkick';
import { setLocation, LOCATIONS, CONTENT_AREAS_ENUM, CONTENT_AREA, TOT_DETAILS_ENUM, setHousingUnit } from '../../constants';
import styled, { css, keyframes } from 'styled-components';
import { gettimeontask, getPermittedFacilities } from '../../util/APIUtils';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import TimerIcon from '@material-ui/icons/Timer';
import { CustomChart } from '../chart';
import { DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment';
import APDSLoader from '../loader';
import TotDetails from '../tot-details';
import { isContentAreaExempted } from '../users/learner/transcript/utils';
import { setLocation as selectLocation, setHousingUnit as selectHousingUnit } from '../../v2/core/store/.';
import { withRouter } from 'react-router-dom';
import { theme as AdminTheme } from '../../v2/core/containers/theme';

// const Facility = React.lazy(() => import('./facility'));

///Styles Constants
const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    flexGrow: 1
  },
  timeOnTaskChart: {
    height: 280
  },
  summaryCard: {
    height: 280,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center'
  },
  locationCard: {
    height: 300,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  locationHeader: {
    width: '100%',
    flexGrow: 1,
    background: theme.palette.background.blue,
    // background: '#40AFFF',
    padding: '.5rem'
  },
  locationFooter: {
    width: '100%',
    height: '70%',
    flexGrow: 1,
    padding: 15,
    '& canvas': {
      width: '100% !important'
    }
  },
  daysSelectionList: {
    display: 'flex'
  },
  daysSelectionItem: {
    width: 'initial',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: 40,
    fontSize: 18,
    color: theme.palette.primary.cobalt,
    fontWeight: 'bold',
    '&:first-child': {
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20
    },
    '&:last-child': {
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20
    }
  },
  activeDay: {
    backgroundColor: theme.palette.primary.cobalt,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.cobalt
    }
  }
});

const ONE_HOUR = 60 * 60 * 1000;

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const animation = css`
  ${pulse} 3s infinite alternate;
`;

const Loader = styled.button`
  animation: ${animation};
`;
var facilities = [];

class Locations extends React.Component {
  _chart = new CustomChart();
  NO_LOCATION = -1;
  state = {
    dateRangePicker: {
      isOpen: false
    },
    dateRange: this.global.selectedDateRange,
    selectedFacility: {},
    search: '',
    selectedView: 'totals',
    isCustom: false,
    totDetailsDialog: {
      isOpen: false
    },
    facilityPermission: [],
    location: {
      active: this.NO_LOCATION,
      locationName: null
    }
  };

  fetchTimeonTask = () => {
    if ([7, 30, 90, 365].indexOf(this.global.selectedDateRange) === -1) {
      this.getTimeonTask(this.global.range, false);
    } else {
      this.getTimeonTask();
    }
  };

  componentDidMount() {
    if (
      !this.props.selectedLocation ||
      (typeof this.props.selectedLocation === 'object' && Object.keys(this.props.selectedLocation).length === 0)
    ) {
      this.props.selectLocation(null);
    } else if (this.props.selectedHousingUnit && this.props.selectedLocation) {
      const { selectedHousingUnit, selectedLocation } = this.props;
      // this.changeLocationHandler(this.props.selectedHousingUnit);
      this.props.selectHousingUnit(selectedHousingUnit);
      this.props.history.push(
        `/outcomes-and-progress/${selectedLocation.locationName
          .split(' ')
          .join('-')
          .replace(/[^a-zA-Z0-9-_]/g, '')}/${selectedLocation.locationId}/${selectedHousingUnit.locationName
          .split(' ')
          .join('-')
          .replace(/[^a-zA-Z0-9-_]/g, '')}/${selectedHousingUnit.locationId}`
      );
      // this.openFacilityView(this.props.selectedHousingUnit);
    } else {
      this.changeLocationHandler(this.props.selectedLocation);
    }
    // if (this.global.cbacConfigFlag) {
    //   this.getPermittedFacilities();
    // }
    // this.setState({
    //   dateRange: this.global.selectedDateRange
    // })
    // facilities = []
    // var _facilities = LOCATIONS.filter((i) => { return i.locationTypeId === 3 })
    // _facilities.map((f) => {
    //   facilities.push(f.locationId)
    // })

    // let locationData = this.global.superLocationStore.filter((l) => {
    //   return l.dateRange === this.global.selectedDateRange
    // })

    // var now = Date.now()
    // if (locationData.length > 0 && (now - locationData[0].timestamp) <= (ONE_HOUR * 12)) {
    //   this.createTotViewData(locationData[0].data)
    //   this.setState({
    //     locData: locationData[0].data
    //   })
    // } else {
    //   this.fetchTimeonTask();
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedLocation && prevProps.selectedLocation !== this.props.selectedLocation) {
      this.changeLocationHandler(this.props.selectedLocation);
    }
  }

  componentWillReceiveProps() {
    // this.setState({
    //   dateRange: this.global.selectedDateRange
    // })
    // facilities = []
    // var _facilities = LOCATIONS.filter((i) => { return i.locationTypeId === 3 })
    // _facilities.map((f) => {
    //   facilities.push(f.locationId)
    // })
    // let locationData = this.global.superLocationStore.filter((l) => {
    //   return l.dateRange === this.global.selectedDateRange
    // })
    // var now = Date.now()
    // if (locationData.length > 0 && (now - locationData[0].timestamp) <= (ONE_HOUR * 12)) {
    //   this.createTotViewData(locationData[0].data)
    //   this.setState({
    //     locData: locationData[0].data
    //   })
    // } else {
    //   this.fetchTimeonTask();
    // }
  }

  /**
   * @name changeLocationHandler
   * @param {object} event
   * @desc Fires when location change handler is fired.
   * @return {void}
   */
  changeLocationHandler = async loc => {
    this.openFacilityView(loc);
    this.setState({
      location: {
        ...this.state.location,
        active: loc.locationId,
        locationName: loc.locationName
      }
    });
  };

  getDateRangeModelFromInteger = range => {
    var d = new Date();
    var _range = range || this.state.dateRange;
    d.setDate(d.getDate() - _range);
    return {
      startDate: d.toISOString(),
      endDate: new Date().toISOString()
    };
  };

  getTimeonTask = (range, isDefiniteRange = true) => {
    let pBody = '';
    var qryDate;
    this.setState({
      locData: null
    });
    let filterDateRange = null;
    if (isDefiniteRange) {
      filterDateRange = this.getDateRangeModelFromInteger(range);
    } else {
      filterDateRange = range;
    }
    this.setGlobal({
      range: filterDateRange
    });
    let postBody = {
      requestorId: 169274,
      requestorLocationId: 1697386,
      filterLocationIds: facilities,
      filterDateRange,
      retLearnerDetails: false
    };

    pBody = JSON.stringify(postBody);
    gettimeontask(postBody).then(data => {
      if (data) {
        if (data.error) {
          this.setState({ error: data });
        } else {
          var facilitiesTotals = this.generateLocationTotals(data.locations);
          var facilitiesAverages = this.generateLocationAverages(data.locations);
          var details = this.generateSummaryDetails(data.locations);
          data.facilitiesAveragesChart = facilitiesAverages;
          data.facilityTotalsChart = facilitiesTotals;
          data.totalTimespent = details.totalTimespent;
          data.numberOfUsers = details.numberOfUsers;

          var _superLocation = {
            dateRange: this.global.selectedDateRange,
            data: data,
            timestamp: Date.now()
          };

          var _superLocationStore = this.global.superLocationStore;
          _superLocationStore.push(_superLocation);
          this.setState({
            locData: data
          });
          this.setGlobal({
            locationsTotData: data,
            prevLocationsTotRange: this.global.selectedDateRange,
            superLocationStore: _superLocationStore
          });
        }
      }
    });
  };
  getPermittedFacilities = () => {
    const permissionName = 'View_Aggregate_Data_At_Facility';
    getPermittedFacilities(permissionName)
      .then(_success => {
        if (_success && _success.length) {
          this.setState({ facilityPermission: _success });
        }
      })
      .catch(_error => {
        this.setState({ facilityPermission: [] });
      });
  };

  createTotViewData(data) {
    var facilitiesTotals = this.generateLocationTotals(data.locations);
    var facilitiesAverages = this.generateLocationAverages(data.locations);
    var details = this.generateSummaryDetails(data.locations);
    data.facilitiesAveragesChart = facilitiesAverages;
    data.facilityTotalsChart = facilitiesTotals;
    data.totalTimespent = details.totalTimespent;
    data.numberOfUsers = details.numberOfUsers;
  }

  generateSummaryDetails = loc => {
    var totalTimespent = 0;
    var numOfUsers = 0;
    var details = {};
    loc.map(l => {
      totalTimespent += l.totalTimespent || 0;
      numOfUsers += l.numberOfUsers;
    });
    details.totalTimespent = totalTimespent.toFixed(2);
    details.numberOfUsers = numOfUsers;
    return details;
  };

  generateLocationTotals = loc => {
    var facilitiesTotals = [];
    var facilityTot = [];
    var locations = loc.map(l => {
      facilityTot = [];
      var name = l.locationName;
      var ave = l.totalTimespent;
      facilityTot.push(name, ave);
      facilitiesTotals.push(facilityTot);
      var contentAreasChart = [];
      var arr = [];
      var _arr = [];
      l.contentAreas.map(c => {
        if (isContentAreaExempted(c.contentAreaName)) {
          return;
        }
        _arr = [];
        var name = c.contentAreaName;
        var ave = c.totalTimespent;
        var id = c.contentAreaKey;
        _arr.push(name, ave, id);

        arr.push(_arr);
      });
      l.contentAverageChart = arr;
    });
    return facilitiesTotals;
  };

  generateLocationAverages = loc => {
    var facilitiesAverages = [];
    var numOfLocations = loc.length;
    var edTotals = 0;
    var rehabTotals = 0;
    var jobTotals = 0;
    var reentryTotals = 0;
    var miscTotals = 0;
    var enrichmentTotals = 0;
    var entertainmentTotals = 0;
    var education = ['Education'];
    var rehab = ['Rehab'];
    var job = ['Job'];
    var reentry = ['Reentry'];
    var enrichment = ['Enrichment'];
    var entertainment = ['Entertainment'];
    var misc = ['Miscellaneous'];

    var locations = loc.map(l => {
      l.contentAreas.map(c => {
        var name = c.contentAreaName;
        var ave = c.averageTimespent;
        if (name === 'Education') {
          edTotals += ave;
        }
        if (name === 'Rehabilitation') {
          rehabTotals += ave;
        }
        if (name === 'Workforce Skills') {
          jobTotals += ave;
        }
        if (name === 'Reentry') {
          reentryTotals += ave;
        }
        if (name === 'Enrichment') {
          enrichmentTotals += ave;
        }
        if (name === 'Entertainment') {
          entertainmentTotals += ave;
        }
        if (name === 'Miscellaneous') {
          miscTotals += ave;
        }
      });
    });

    var edAve = edTotals / numOfLocations;
    var rehabAve = rehabTotals / numOfLocations;
    var jobAve = jobTotals / numOfLocations;
    var reentryAve = reentryTotals / numOfLocations;
    var enrichmentAve = enrichmentTotals / numOfLocations;
    var miscAve = miscTotals / numOfLocations;
    var entAve = entertainmentTotals / numOfLocations;

    education.push(edAve);
    rehab.push(rehabAve);
    job.push(jobAve);
    reentry.push(reentryAve);
    enrichment.push(enrichmentAve);
    misc.push(miscAve);
    entertainment.push(entAve);

    facilitiesAverages.push(education, rehab, job, reentry, enrichment, entertainment, misc);

    return facilitiesAverages;
  };

  handleSearchChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChange = dateRange => {
    this.setState({
      dateRange: dateRange,
      isCustom: false
    });
    var range = dateRange;
    let locationData = this.global.superLocationStore.filter(l => {
      return l.dateRange === range;
    });

    this.setGlobal({
      selectedDateRange: range,
      range: this.getDateRangeModelFromInteger(range)
    });
    var now = Date.now();

    if (locationData.length > 0 && now - locationData[0].timestamp <= ONE_HOUR * 12) {
      this.createTotViewData(locationData[0].data);
      this.setState({
        locData: locationData[0].data
      });
    } else {
      this.getTimeonTask(range);
    }
  };

  handleDateRangePicker = isOpen => {
    this.setState({
      dateRangePicker: {
        ...this.state.dateRangePicker,
        isOpen
      }
    });
  };

  handleDateRangeChange = range => {
    range = {
      startDate: moment.utc(range.startDate),
      endDate: moment.utc(range.endDate)
    };
    const daysCount = moment.duration(range.endDate.diff(range.startDate)).asDays();

    const formattedRange = {
      startDate: range.startDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
      endDate: range.endDate.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
    };
    this.setGlobal({
      selectedDateRange: daysCount,
      range: formattedRange
    });
    this.setState({
      dateRange: daysCount,
      isCustom: true
    });
    this.handleDateRangePicker(false);
    this.getTimeonTask(formattedRange, false);
  };

  showUnit() {
    return <div>SOMETHING HERE!!!!</div>;
  }

  openFacilityView = fac => {
    this.props.history.push(
      `/outcomes-and-progress/${fac.locationName
        .split(' ')
        .join('-')
        .replace(/[^a-zA-Z0-9-_]/g, '')}/${fac.locationId}`
    );
    // this.setGlobal({
    //   openFacilityView: true,
    //   totSelectedFacility: fac
    // })
    // setLocation(fac)
  };

  /**
   * @name handleToTDetailsDialog
   * @param {boolean} isOpen
   * @desc Handles time on task dialog open flag.
   * @return {void}
   */
  handleToTDetailsDialog = isOpen => {
    if (isOpen) {
      this.setState({ selectedView: 'tot' });
    } else {
      this.setState({ selectedView: 'totals' });
    }
    this.setState({
      totDetailsDialog: {
        ...this.state.totDetailsDialog,
        isOpen
      }
    });
  };

  handleClose = () => {
    this.setGlobal({
      openFacilityView: false
    });
  };

  render() {
    const { classes } = this.props;
    let filteredLocations = this.state.locData
      ? this.state.locData.locations.sort().filter(location => {
          return location.locationName.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        })
      : [];
    const sortedLocations = filteredLocations.sort(function(a, b) {
      var keyA = a.locationName,
        keyB = b.locationName;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    const sortedAndPermittedLocations = sortedLocations.map(f => {
      return { ...f, disableFacility: false };
    });

    if (this.global.cbacConfigFlag) {
      sortedAndPermittedLocations.forEach(l => {
        l.disableFacility = !this.state.facilityPermission.includes(Number(l.locationId));
      });
    }

    return (
      <Suspense fallback={<APDSLoader isLoading={true} />}>
        {this.state.location.active !== this.NO_LOCATION && (
          <div className={classes.root}>
            <Box
              width="100%"
              display="flex"
              position="relative"
              marginBottom={2}
              alignItems="center"
              justifyContent="center"
              className={classes.actionBar}
            >
              <List className={classes.daysSelectionList}>
                <ListItem
                  onClick={() => this.handleChange(7)}
                  button
                  className={[
                    classes.daysSelectionItem,
                    this.state.dateRange === 7 && !this.state.isCustom ? classes.activeDay : null
                  ].join(' ')}
                >
                  7 Days
                </ListItem>
                <ListItem
                  onClick={() => this.handleChange(30)}
                  button
                  className={[
                    classes.daysSelectionItem,
                    this.state.dateRange === 30 && !this.state.isCustom ? classes.activeDay : null
                  ].join(' ')}
                >
                  30 Days
                </ListItem>
                <ListItem
                  onClick={() => this.handleChange(90)}
                  button
                  className={[
                    classes.daysSelectionItem,
                    this.state.dateRange === 90 && !this.state.isCustom ? classes.activeDay : null
                  ].join(' ')}
                >
                  90 Days
                </ListItem>
                <ListItem
                  data-cy-name="all-time-btn"
                  id="all-time-btn"
                  onClick={() => this.handleChange(365)}
                  button
                  className={[
                    classes.daysSelectionItem,
                    this.state.dateRange === 365 && !this.state.isCustom ? classes.activeDay : null
                  ].join(' ')}
                >
                  365 Days
                </ListItem>
                <ListItem
                  onClick={() => this.handleDateRangePicker(true)}
                  button
                  className={[classes.daysSelectionItem, this.state.isCustom ? classes.activeDay : null].join(' ')}
                >
                  Custom
                </ListItem>
              </List>
              <Box position="absolute" top="0px">
                <DateRangePicker
                  open={this.state.dateRangePicker.isOpen}
                  closeOnClickOutside={true}
                  maxDate={new Date()}
                  toggle={() => this.handleDateRangePicker(!this.state.dateRangePicker.isOpen)}
                  onChange={this.handleDateRangeChange}
                />
              </Box>
            </Box>
            {this.state.error ? (
              <div>
                <h4>An error has occurred</h4>
                <p>Please refresh your page and try again. If error persists, please contact support@apdscorproate.com</p>
                <p>Error Code: {this.state.error.status}</p>
                <p>Error Message: {this.state.error.message}</p>
              </div>
            ) : null}
            {this.state.locData ? (
              <Zoom in unmountOnExit>
                <div>
                  <Grid container spacing={24}>
                    <Grid item sm={3} xs={12}>
                      <div style={{ display: 'flex', flex: 1, flexFlow: 'row wrap' }}>
                        <div style={{ flex: 1, display: 'flex' }}>
                          <Paper
                            style={{
                              flex: 1,
                              backgroundColor: AdminTheme.palette.background.blue,
                              margin: 6,
                              color: 'white',
                              borderRadius: 4
                            }}
                          >
                            <LocationOnIcon style={{ width: 18, height: 18, float: 'left', margin: 4 }} />
                            <p style={{ textAlign: 'left', margin: 6, marginTop: 3 }}> facilities</p>
                            <Typography variant="h5" style={{ margin: 8, color: 'white' }}>
                              {this.state.locData.locations.length}
                            </Typography>
                          </Paper>
                          <Paper
                            style={{
                              flex: 1,
                              backgroundColor: AdminTheme.palette.background.blue,
                              margin: 6,
                              color: 'white',
                              borderRadius: 4
                            }}
                          >
                            <SupervisedUserCircleIcon style={{ width: 18, height: 18, float: 'left', margin: 4 }} />
                            <p style={{ textAlign: 'left', margin: 6, marginTop: 3 }}> users</p>
                            <Typography variant="h5" style={{ margin: 8, color: 'white' }}>
                              {this.state.locData.numberOfUsers}
                            </Typography>
                          </Paper>
                        </div>

                        <Paper
                          style={{
                            width: '100%',
                            backgroundColor: AdminTheme.palette.background.blue,
                            color: 'white',
                            borderRadius: 4,
                            margin: 4
                          }}
                        >
                          <TimerIcon style={{ width: 22, height: 22, float: 'left', margin: 4 }} />
                          <p style={{ textAlign: 'left', margin: 6, marginTop: 7 }}> total hours spent</p>
                          <Typography variant="h6" style={{ margin: 10, color: 'white' }}>
                            {this.state.locData.totalTimespent}
                          </Typography>
                        </Paper>

                        <Box width="100%" display="flex" justifyContent="flex-end">
                          <Button
                            style={{ width: '100%' }}
                            onClick={() => this.handleToTDetailsDialog(true)}
                            color="primary"
                          >
                            View Time On Task Details
                          </Button>
                          <TotDetails
                            heading="Time on Task"
                            exportFileName="Locations"
                            contentAreaVisible
                            locations={this.state.locData.locations}
                            open={this.state.totDetailsDialog.isOpen}
                            type={TOT_DETAILS_ENUM.Location}
                            closeHandler={this.handleToTDetailsDialog}
                          />
                        </Box>
                      </div>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                      <Paper className={classes.timeOnTaskChart}>
                        <div style={{ float: 'left' }}>
                          <Button
                            onClick={() => {
                              this.setState({ selectedView: 'totals' });
                            }}
                            color={this.state.selectedView === 'totals' ? 'primary' : 'inherit'}
                          >
                            Totals
                          </Button>
                          <Button
                            onClick={() => {
                              this.setState({ selectedView: 'averages' });
                            }}
                            color={this.state.selectedView === 'averages' ? 'primary' : 'inherit'}
                          >
                            Average
                          </Button>
                        </div>
                        {this.state.selectedView === 'totals' ? (
                          <div>
                            <Typography variant="h6" style={{ textAlign: 'center', padding: '.5rem', fontSize: 18 }}>
                              Total Time on Task by Facility
                            </Typography>
                            <ColumnChart
                              library={this._chart.getDefaultOptions}
                              height={220}
                              dataset={{
                                ...this._chart.getDefaultDatasetOptions,
                                backgroundColor: this.state.locData.facilityTotalsChart.map(
                                  data => AdminTheme.palette.background.blue
                                )
                              }}
                              adapter="chartjs"
                              data={this.state.locData.facilityTotalsChart}
                            />
                          </div>
                        ) : (
                          <div>
                            <Typography variant="h6" style={{ textAlign: 'center', padding: '.5rem', fontSize: 18 }}>
                              Average Time Spent by Facility per Pathway
                            </Typography>
                            <ColumnChart
                              library={this._chart.getDefaultOptions}
                              dataset={{
                                ...this._chart.getDefaultDatasetOptions,
                                backgroundColor: [
                                  CONTENT_AREA[CONTENT_AREAS_ENUM.Education].bgColor,
                                  CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].bgColor,
                                  CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].bgColor,
                                  CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].bgColor,
                                  CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].bgColor,
                                  CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].bgColor,
                                  CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].bgColor,
                                  CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].bgColor
                                ]
                              }}
                              height={220}
                              adapter="chartjs"
                              data={this.state.locData.facilitiesAveragesChart}
                            />
                          </div>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <div className={classes.locationsContainer}>
                    {this.state.locData ? (
                      <div>
                        <h3>Facilities</h3>
                        <Paper
                          style={{
                            float: 'right',
                            padding: '2px 12px',
                            display: 'flex',
                            alignItems: 'center',
                            width: 320
                          }}
                        >
                          <InputBase
                            id="search"
                            style={{ flexGrow: 1 }}
                            placeholder="Search Facility"
                            value={this.state.search}
                            onChange={this.handleSearchChange('search')}
                            inputProps={{ 'aria-label': 'Search Facility' }}
                          />
                          <IconButton className={classes.iconButton} aria-label="Search">
                            <SearchIcon />
                          </IconButton>
                        </Paper>
                        <Grid container spacing={24}>
                          {sortedAndPermittedLocations.map(l => (
                            <Slide key={l.locationId} direction="up" in unmountOnExit style={{ transitionDelay: 500 }}>
                              <Grid item sm={6} xs={12} md={4} style={{ padding: 6 }}>
                                <ButtonBase
                                  disabled={l.disableFacility}
                                  onClick={() => {
                                    setHousingUnit(l);
                                    this.props.selectHousingUnit(l);
                                    this.openFacilityView(l);
                                  }}
                                  style={{ width: '100%' }}
                                >
                                  <Paper className={classes.locationCard} style={{ width: '100%' }}>
                                    <div className={classes.locationHeader}>
                                      <Typography variant="body1" style={{ color: 'white', textAlign: 'left' }}>
                                        <strong>{l.locationName}</strong>
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        style={{ color: 'white', textAlign: 'left', fontSize: 12 }}
                                      >{`Total Hours Spent: ${
                                        l.totalTimespent ? l.totalTimespent.toFixed(2) : (0).toFixed(2)
                                      }`}</Typography>
                                    </div>
                                    <div className={classes.locationFooter}>
                                      <ColumnChart
                                        library={this._chart.getDefaultOptions}
                                        dataset={{
                                          ...this._chart.getDefaultDatasetOptions,
                                          backgroundColor: l.contentAverageChart.map(data =>
                                            CONTENT_AREA[data[2]]
                                              ? CONTENT_AREA[data[2]].bgColor
                                              : AdminTheme.palette.background.blue
                                          )
                                        }}
                                        height={200}
                                        data={l.contentAverageChart}
                                      />
                                    </div>
                                  </Paper>
                                </ButtonBase>
                              </Grid>
                            </Slide>
                          ))}
                        </Grid>
                      </div>
                    ) : (
                      <span>loading...</span>
                    )}
                  </div>
                </div>
              </Zoom>
            ) : (
              <Paper style={{ padding: '1rem' }}>
                <CircularProgress className={classes.progress} />

                <h1>Loading time on task data...</h1>
              </Paper>
            )}
          </div>
        )}
      </Suspense>
    );
  }
}

Locations.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return state.app;
};

const mapDispatchToProps = dispatch => {
  return {
    selectLocation: location => dispatch(selectLocation(location)),
    selectHousingUnit: hu => dispatch(selectHousingUnit(hu))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Locations)));
