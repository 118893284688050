import React from 'reactn';
import { Box, Typography, withStyles, Grid, Paper } from "@material-ui/core";
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

import styles from './index.style';
import { CustomChart } from '../../chart';
import { CHART_FORMAT } from '../../../constants';
import { getCommunicationVisualization } from '../../../util/APIUtils';

export class Visualization extends React.Component {
    _chart = new CustomChart();
    colors = {
        msgsHidden: "#8E8E8E",
        sentByNonLearner: "#7D6FCB",
        sentByLearner: "#4BA1FF",
        totalMessages: "#93D254"
    };
    visualizationDataIndexes = {
        totalMsg: 0,
        sentByLearner: 1,
        sentByNonLearner: 2,
        msgsHidden: 3
    };
    state = {
        chartData: null,
        groupedData: {
            totalMsgs: 0,
            sentByLearner: 0,
            sentByNonLearner: 0,
            msgsHidden: 0
        }
    };

    /**
     * @name getVisualizationDefaultData
     * @param {object} defaultValues
     * @return {array}
     */
    getVisualizationDefaultData = (defaultValues) => {
        return [
            {
                label: 'Messages Sent',
                data: [],
                type: 'line',
                backgroundColor: "transparent",
                borderColor: this.colors.totalMessages
            },
            {
                label: 'Messages Sent By Learner',
                data: [],
                type: 'line',
                backgroundColor: "transparent",
                borderColor: this.colors.sentByLearner
            },
            {
                label: 'Messages Sent By Non Learner',
                data: [],
                type: 'line',
                backgroundColor: "transparent",
                borderColor: this.colors.sentByNonLearner
            },
            {
                label: 'Messages Hidden',
                data: [],
                backgroundColor: this.colors.msgsHidden
            }
        ];
    };

    /**
     * @name getGroupedData
     * @param {object} groupedData
     * @param {object} contentData
     * @desc Prepare's grouped data based upon parameters.
     * @return {object} groupedData
     */
    getGroupedData = (groupedData, contentData) => {
        groupedData.totalMsgs += (contentData.totalMsgSentByLearner + contentData.totalMsgSentByNonLearner);
        groupedData.sentByLearner += (contentData.totalMsgSentByLearner);
        groupedData.sentByNonLearner += (contentData.totalMsgSentByNonLearner);
        groupedData.msgsHidden += (contentData.totalMsgHidden);
        return groupedData;
    };

    /**
     * @name groupByDates
     * @param {object} payload
     * @desc Groups entire data by date.
     * @return {object} data Data that can be further used in chart.js integration.
     */
    groupByDates = (payload, groupFormat) => {
        let chartData = {
            labels: [],
            datasets: this.getVisualizationDefaultData()
        };
        if (payload.length === 0) {
            return chartData;
        }
        let { groupedData } = this.state;
        payload.forEach((contentData, index) => {
            const date = contentData.date;
            const labelIndex = chartData.labels.indexOf(date);
            if (labelIndex === -1) {
                chartData.labels.push(date);
                chartData.datasets[this.visualizationDataIndexes.totalMsg]
                    .data
                    .push(contentData.totalMsgSentByLearner + contentData.totalMsgSentByNonLearner);
                chartData.datasets[this.visualizationDataIndexes.sentByLearner]
                    .data
                    .push(contentData.totalMsgSentByLearner);
                chartData.datasets[this.visualizationDataIndexes.sentByNonLearner]
                    .data
                    .push(contentData.totalMsgSentByNonLearner);
                chartData.datasets[this.visualizationDataIndexes.msgsHidden]
                    .data
                    .push(contentData.totalMsgHidden);

                groupedData = this.getGroupedData(groupedData, contentData);
            } else {
                // TOTAL MSGS
                chartData.datasets[this.visualizationDataIndexes.totalMsg]
                    .data[labelIndex] += contentData.totalMsgSentByLearner + contentData.totalMsgSentByNonLearner;
                // SENT BY LEARNER
                chartData.datasets[this.visualizationDataIndexes.sentByLearner]
                    .data[labelIndex] += contentData.totalMsgSentByLearner;
                // SENT BY NON LEARNER
                chartData.datasets[this.visualizationDataIndexes.sentByNonLearner]
                    .data[labelIndex] += contentData.totalMsgSentByNonLearner;
                // MSGS HIDDEN
                chartData.datasets[this.visualizationDataIndexes.msgsHidden]
                    .data[labelIndex] += contentData.totalMsgHidden;
                groupedData = this.getGroupedData(groupedData, contentData);
            }
        });
        this.setState({
            groupedData
        });
        return chartData;
    };

    componentDidMount() {
        this.getVisualizationData();
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.active !== prevProps.location.active) {
            this.getVisualizationData();
        }
    };

    getDateDateRange = (range) => {
        return {
            startDate: moment(range.startDate).format("YYYY-MM-DD"),
            endDate: moment(range.endDate).format("YYYY-MM-DD")
        };
    };

    /**
     * @name getVisualizationData
     * @desc Fetches visualization data w.r.t to type props.
     * @return {void}
     */
    getVisualizationData = () => {
        this.setGlobal({
            isLoading: true
        });
        this.setState({
            groupedData: {
                totalMsgs: 0,
                sentByLearner: 0,
                sentByNonLearner: 0,
                msgsHidden: 0
            }
        });
        const { locationID, userName } = this.getPayloadData();
        getCommunicationVisualization(
            locationID,
            this.getDateDateRange(this.global.range),
            this.props.filters.searchTerm,
            userName || ''
        )
            .then(
                (_successLog) => {
                    const chartData = this.groupByDates(
                        _successLog.messagesSummary,
                        CHART_FORMAT.FORMAT_DEFAULT
                    )
                    this.setState({
                        chartData
                    });
                    this.setGlobal({
                        isLoading: false
                    });

                },
                (_errorLog) => {
                    this.setGlobal({
                        isLoading: false
                    });
                    console.error("Something went wrong", _errorLog);
                }
            );
    };

    getPayloadData = () => {
        const { currentLocations, userDetail } = this.props;
        if(currentLocations && currentLocations.length) {
            return { 
                locationID: (currentLocations.map(location => location.locationId)).join(','), 
                userName: userDetail && userDetail.userName 
            };
        }
        return { locationID: this.props.filters.location || this.props.location.active};
    }

    render() {
        const { classes } = this.props;
        return (
            <Box textAlign="left" >
                <Box boxShadow={3} marginBottom="20px">
                    <Paper className={classes.paper} >
                        <Box>
                            <Typography className={classes.heading}>
                                Message Activity Summary
                            </Typography>
                        </Box>
                        <Box height="300px">
                            {this.state.chartData && (
                                <Bar
                                    options={this._chart.getDefaultOptions}
                                    data={this.state.chartData}
                                />
                            )}
                        </Box>
                    </Paper>
                </Box>
                <Grid container item className={classes.cardContainer}>
                    <Grid container item md={3}
                        direction="row"
                        className={classes.cardWrapper}
                        alignItems="flex-end"
                        justify="space-between">
                        <Box className={classes.card} bgcolor={this.colors.totalMessages}>
                            <Typography className={classes.cardMeta}>
                                Total Messages Sent
                            </Typography>
                            <Typography className={classes.cardNumber}>
                                {this.state.groupedData.totalMsgs}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container item md={3}
                        direction="row"
                        className={classes.cardWrapper}
                        alignItems="flex-end"
                        justify="space-between">
                        <Box className={classes.card} bgcolor={this.colors.sentByLearner}>
                            <Typography className={classes.cardMeta}>
                                Total Messages Sent By Learners
                            </Typography>
                            <Typography className={classes.cardNumber}>
                                {this.state.groupedData.sentByLearner}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container item md={3}
                        direction="row"
                        className={classes.cardWrapper}
                        alignItems="flex-end"
                        justify="space-between">
                        <Box className={classes.card} bgcolor={this.colors.sentByNonLearner} >
                            <Typography className={classes.cardMeta}>
                                Total Messages Sent By Staff
                            </Typography>
                            <Typography className={classes.cardNumber}>
                                {this.state.groupedData.sentByNonLearner}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid container item md={3}
                        direction="row"
                        className={classes.cardWrapper}
                        alignItems="flex-end"
                        justify="space-between">
                        <Box className={classes.card} bgcolor={this.colors.msgsHidden}>
                            <Typography className={classes.cardMeta}>
                                Messages Hidden
                            </Typography>
                            <Typography className={classes.cardNumber}>
                                {this.state.groupedData.msgsHidden}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    };
};

export default withStyles(styles, { withTheme: true })(Visualization);