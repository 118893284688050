import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';


class CustomLabel extends React.Component {
    render() {
      return (
        <div></div>
      );
    }
  }

  
  class IncentiveOverall extends React.Component {
    render() {
      return (
         <div></div>
      );
    }
  }
  
  export default IncentiveOverall