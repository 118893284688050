import React from 'reactn';
import moment from 'moment';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Tooltip,
  Button,
  Typography,
  MenuItem,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { LOCATIONS, SUPPORT_EMAIL, USER_CONTEXT } from '../../../../constants';
import { Loader } from '@orijinworks/frontend-commons';
import { updateUsers, updateWhitelisting } from '../../../../util/APIUtils';
import { autogeneratePassword, validatePassword } from '../../../../util/Helpers';
import store, { showToast } from '../../../../v2/core/store/.';
import DatePickerInputs from '../../../../v2/shared/components/datePickerInputs/index.js';
import ErrorMessages from '../../errorMessages';
import styles from './edit-user.style';
import { theme } from '../../../../v2/core/containers/theme';
import { getDocIdErrors, trimAndReplaceAll } from '../../../../v2/services/utilty.js';
import withUnleash from '../../../../v2/core/unleash/withUnleash.js';
import { UnleashService } from '../../../../v2/services/index.js';

const PermissionControl = React.lazy(() => import('../../../permission-control/permission-control'));

const editInfoText = `Some learner fields cannot be edited manually. If you wish to edit a field which is disabled, please contact ${SUPPORT_EMAIL}.`;

class EditUser extends React.Component {
  state = {
    loading: false,
    locations: [],
    resultOpen: true,
    disableOpen: false,
    reactivateOpen: false,
    whitelistStatus: 0,
    isLocationChanged: false,
    errorMessages: undefined,
    selectedUnitId: '',
  };

  componentDidMount() {
    if (USER_CONTEXT != null || USER_CONTEXT.roleAtLocation.length > 1) {
      this.setState((prevState) => {
        return {
          locations: LOCATIONS.filter((l) => {
            return l.locationTypeId === 3;
          }),
        };
      });
    }
    this.setState({
      editPassword: '',
      dateOfBirth: this.props.user.dateOfBirth,
      editDocId: this.props.user.docId,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.docId != this.props.user.docId || prevProps.user.userName != this.props.user.userName) {
      this.setState({
        editDocId: this.props.user.docId,
        dateOfBirth: this.props.user.dateOfBirth,
      });
    }
  }

  handleOpenDisable = () => {
    this.setState({
      disableOpen: true,
    });
  };

  handleOpenReactivate = () => {
    this.setState({
      reactivateOpen: true,
    });
  };

  reactivateUser = () => {
    const currentLocationId = this.props.user.assignedLocationId || this.props.location.locationId;
    const newLocationId = this.state.selectedUnitId || this.state.selectedLocationId;
    let whitelistStatus = 0;
    let isLocationChanged = false;
    var bodyData = {
      requestorId: this.props.requestorId,
      requestorLocationId: this.props.requestorLocationId,
      users: [
        {
          requestId: 1,
          personId: this.props.user.userId,
          currentLocationId: currentLocationId,
          newLocationId: newLocationId,
          newUserStatus: 'active',
        },
      ],
    };
    var body = JSON.stringify(bodyData);
    if (this.props.unleashProps.isFlagEnabled()) {
      this.setState({ loading: true });
    } else {
      this.setGlobal({ isLoading: true });
    }
    updateUsers(body)
      .then(async (data) => {
        this.setState({
          reactivateData: data,
        });
        const { userResults } = data;
        if (userResults.length > 0) {
          this.props.updateUser({
            active: 'active',
          });
          if (currentLocationId !== newLocationId) {
            isLocationChanged = true;
          }
          if (userResults[0].statusCode === 0) {
            try {
              await updateWhitelisting({
                person_key: userResults[0].personId,
                location_key: userResults[0].locationId || currentLocationId,
              });
              whitelistStatus = 1;
            } catch (e) {
              if (e.status === 400) {
                whitelistStatus = 2;
              }
            }
          }
        }
        if (this.props.unleashProps.isFlagEnabled()) {
          this.setState({ loading: false });
        } else {
          this.setGlobal({ isLoading: false });
        }
        this.setState({
          whitelistStatus,
          isLocationChanged,
        });
      })
      .catch((error) => {
        if (this.props.unleashProps.isFlagEnabled()) {
          this.setState({ loading: false });
        } else {
          this.setGlobal({ isLoading: false });
        }
        console.log({ error });
        if (error && error.status && error.status === 422) {
          store.dispatch(
            showToast(
              'Cannot activate this profile. Please make sure the learner portal access toggle is enabled on the location of this learner account.'
            )
          );
        }
      });
  };

  handleCloseDisable = () => {
    this.setState({
      disableOpen: false,
    });
  };

  checkDateOfBirthErrors = () => {
    let errors = [];
    // check dateOfBirth
    if (!this.state.dateOfBirth || this.state.dateOfBirth.length === 0) {
      errors.push('Please enter a date of birth.');
    } else {
      let today = new Date();
      let birthdate = new Date(this.state.dateOfBirth);
      if (isNaN(birthdate) || birthdate > today) {
        errors.push('Please enter a valid date of birth.');
      }
    }
    this.setState({ errorMessages: errors });
  };

  handleChange = (name) => (event) => {
    if (name === 'dateOfBirth') {
      let date = moment(event).format('YYYY-MM-DD');
      this.setState(
        {
          [name]: date,
        },
        function () {
          this.checkDateOfBirthErrors();
        }
      );
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }

    if (name === 'selectedLocation') {
      var result = this.state.locations.filter((obj) => {
        return obj.locationName === event.target.value && obj.locationTypeId === 3;
      });
      this.setState({
        selectedLocationId: result[0].locationId,
        selectedLocation: result[0].locationName,
        selectedUnitId: '',
        selectedUnit: '',
      });
    } else if (name === 'selectedUnit') {
      var _unit = LOCATIONS.filter((obj) => {
        return obj.locationName === event.target.value;
      });
      let thisUnit = _unit.filter((u) => {
        return u.parentLocationId === this.state.selectedLocationId;
      });
      this.setState({ selectedUnitId: thisUnit[0].locationId, selectUnit: thisUnit[0].locationName });
    }
  };

  handlePasswordChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ errorMessages: validatePassword(e.target.value) });
  };

  handleResultClose = () => {
    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
    this.setState({
      open: false,
      editOpen: false,
      resultOpen: false,
    });
  };

  autogenPassword = () => {
    const password = autogeneratePassword();
    this.setState({ editPassword: password }, function () {
      this.setState({ errorMessages: validatePassword(password) });
    });
  };

  handleCloseReactivate = () => {
    this.setState({
      reactivateOpen: false,
    });
  };

  submitEdit = async () => {
    const currentLocationId = this.props.user.assignedLocationId || this.props.location.locationId;
    const newLocationId = this.state.selectedUnitId || this.state.selectedLocationId;
    let isLocationChanged = false;
    let whitelistStatus = 0;
    const bodyData = {
      requestorId: this.props.requestorId,
      requestorLocationId: this.props.requestorLocationId,
      users: [
        {
          requestId: 1,
          personId: this.props.user.userId,
          userName: this.props.user.userName,
          newPassword: this.state.editPassword,
          currentLocationId: currentLocationId,
          newLocationId: newLocationId,
          newDocId: trimAndReplaceAll(this.state.editDocId, ' ', '-'),
          dateOfBirth: this.state.dateOfBirth,
        },
      ],
    };

    if (this.props.unleashProps.isFlagEnabled()) {
      this.setState({ loading: true });
    } else {
      this.setGlobal({ isLoading: true });
    }
    const body = JSON.stringify(bodyData);
    const responseData = await updateUsers(body);
    if (responseData) {
      this.setState({
        responseData,
      });
      const { userResults } = responseData;
      if (userResults.length > 0) {
        this.props.updateUser({
          userId: userResults[0].personId,
          userName: userResults[0].userName,
          firstName: userResults[0].userDisplayName.split(' ')[0],
          lastName: userResults[0].userDisplayName.split(' ')[1],
          dateOfBirth: userResults[0].dateOfBirth,
        });
        if (userResults[0].statusCode === 0 && userResults[0].locationId && currentLocationId !== newLocationId) {
          isLocationChanged = true;
          try {
            await updateWhitelisting({
              person_key: userResults[0].personId,
              location_key: userResults[0].locationId,
            });
            whitelistStatus = 1;
          } catch (e) {
            if (e.status === 400) {
              whitelistStatus = 2;
            }
          }
        }
      }
    }
    if (this.props.unleashProps.isFlagEnabled()) {
      this.setState({ loading: false });
    } else {
      this.setGlobal({ isLoading: false });
    }
    this.setState({
      resultOpen: true,
      whitelistStatus,
      isLocationChanged,
    });
  };

  handleDocIdChange = (e) => {
    const docIdErrors = getDocIdErrors(e.target.value);
    this.setState({ [e.target.name]: e.target.value, errorMessages: docIdErrors });
  };

  disableUser = () => {
    var bodyData = {
      requestorId: this.props.requestorId,
      requestorLocationId: this.props.requestorLocationId,
      users: [
        {
          requestId: 1,
          personId: this.props.user.userId,
          currentLocationId: this.props.user.assignedLocationId || this.props.location.locationId,
          newUserStatus: 'inactive',
        },
      ],
    };
    var body = JSON.stringify(bodyData);

    if (this.props.unleashProps.isFlagEnabled()) {
      this.setState({ loading: true });
    } else {
      this.setGlobal({ isLoading: true });
    }
    updateUsers(body)
      .then((data) => {
        this.setState({
          disableData: data,
        });

        this.props.updateUser({
          active: 'inactive',
        });

        if (this.props.unleashProps.isFlagEnabled()) {
          this.setState({ loading: false });
        } else {
          this.setGlobal({ isLoading: false });
        }
      })
      .catch((error) => {
        if (this.props.unleashProps.isFlagEnabled()) {
          this.setState({ loading: false });
        } else {
          this.setGlobal({ isLoading: false });
        }
        console.log({ error });
      });
  };

  render() {
    const { classes, user } = this.props;
    return (
      <Dialog open={this.props.open} onClose={() => this.props.closeHandler(false)} aria-labelledby="edit-user">
        <Dialog open={this.state.disableOpen} onClose={this.handleCloseDisable}>
          <DialogTitle>
            {' '}
            <Typography variant="h6">Disable User</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Disabling a learner will remove their access to Connected Corrections programming, and sign them out of their
              current session. You may choose to disable them right away, or set an end date.
            </DialogContentText>
            {this.state.disableData ? (
              <div>
                {this.state.disableData.userResults.map((r) =>
                  r.statusCode === 0 ? (
                    <div>
                      <h3>Disable Learner Success!</h3>
                      <p> {this.props.user.displayName} has been set inactive</p>
                    </div>
                  ) : (
                    <div style={{ color: theme.palette.text.red }}>
                      <p>Something went wrong trying to disable learner {this.props.user.displayName}</p>
                      <p>Status Message: {r.statusMessage}</p>
                    </div>
                  )
                )}
              </div>
            ) : (
              <div>
                <Typography variant="p">Disable user:</Typography>
                <div
                  style={{
                    width: 'auto',
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    id="disable-user-btn"
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={this.disableUser}
                  >
                    Disable User
                  </Button>
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions style={{ marginTop: '.5rem' }}>
            <Button id="close-btn" color="secondary" onClick={this.handleCloseDisable}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.reactivateOpen} onClose={this.handleCloseReactivate}>
          <DialogTitle>
            {' '}
            <Typography variant="h6">Reactivate Learner</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Reactivating a learner will reenable the user. The learner will gain access to Connected Corrections again.
              Educational services will still need to be added for this user.
            </DialogContentText>
            {this.state.reactivateData ? (
              <div>
                {this.state.reactivateData.userResults.map((r) =>
                  r.statusCode === 0 ? (
                    <div>
                      <h3>Reactivate Learner Success!</h3>
                      <p> {this.props.user.userName} has been set active.</p>
                    </div>
                  ) : (
                    <div style={{ color: theme.palette.text.red }}>
                      <p>Something went wrong trying to reactivate learner {this.props.user.userName}.</p>
                      <p>Status message: {r.statusMessage}</p>
                    </div>
                  )
                )}
              </div>
            ) : (
              <div>
                <Typography variant="p">Reactivate learner:</Typography>
                <div
                  style={{
                    width: 'auto',
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    id="reactivate-user-btn"
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={this.reactivateUser}
                  >
                    Reactivate learner
                  </Button>
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions style={{ marginTop: '.5rem' }}>
            <Button id="close-btn" color="secondary" onClick={this.handleCloseReactivate}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.loading ? (
          <Loader isLoading={this.state.loading} />
        ) : (
          <DialogContent key={this.props.user.userName}>
            <h4>{this.props.user.userName}</h4>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip onClose={this.handleTooltipClose} title={editInfoText} style={{ maxWidth: 500 }}>
                <Button
                  style={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    width: 45,
                    height: 45,
                  }}
                >
                  <InfoIcon />
                </Button>
              </Tooltip>
            </div>

            <Grid container spacing={24}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  label="Username"
                  type="text"
                  fullWidth
                  disabled
                  defaultValue={this.props.user.userName}
                  margin="normal"
                  variant="outlined"
                />
                <Typography variant="body1" className={classes.dateHeader}>
                  Date of Birth
                </Typography>
                <DatePickerInputs date={this.state.dateOfBirth} change={this.handleChange('dateOfBirth')} />
                <TextField
                  autoFocus
                  id="docid"
                  label="DOC ID"
                  type="text"
                  name="editDocId"
                  value={this.state.editDocId}
                  onChange={this.handleDocIdChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <PermissionControl permissionName="Move_Learner_From_One_Location_To_Another">
                  <Typography variant="body1" className={classes.fieldHeader}>
                    Change Location
                  </Typography>
                  <Grid container spacing={24} item xs={12} style={{ width: 'auto', margin: 'auto' }}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        id="filled-select-location"
                        select
                        label="Select"
                        fullWidth
                        variant="outlined"
                        value={this.state.selectedLocation}
                        onChange={this.handleChange('selectedLocation')}
                        SelectProps={{
                          MenuProps: {
                            //className: classes.menu,
                          },
                        }}
                        helperText="Choose building location"
                        margin="normal"
                        required
                      >
                        {this.state.locations
                          .sort((a, b) => {
                            var keyA = a.locationName,
                              keyB = b.locationName;
                            // Compare the 2 dates
                            if (keyA < keyB) return -1;
                            if (keyA > keyB) return 1;
                            return 0;
                          })
                          .map((option) => (
                            <MenuItem key={option.locationId} value={option.locationName}>
                              {option.locationName}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        disabled={this.state.selectedLocationId ? false : true}
                        id="filled-select-unit"
                        select
                        label="Select"
                        variant="outlined"
                        fullWidth
                        value={this.state.selectedUnit}
                        onChange={this.handleChange('selectedUnit')}
                        SelectProps={{
                          MenuProps: {
                            //className: classes.menu,
                          },
                        }}
                        helperText="Choose optional housing units"
                        margin="normal"
                        required
                      >
                        {this.state.selectedLocationId ? (
                          LOCATIONS.filter((fil) => {
                            return fil.parentLocationId === this.state.selectedLocationId;
                          }).map((option) => (
                            <MenuItem key={option.locationId} value={option.locationName}>
                              {option.locationName}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>Please choose primary location first</MenuItem>
                        )}
                      </TextField>
                    </Grid>
                  </Grid>
                </PermissionControl>
                <PermissionControl permissionName="Reset_Learner_Password">
                  <Typography variant="body1" className={classes.fieldHeader} style={{ float: 'left' }}>
                    Password
                  </Typography>
                  <Button color="primary" onClick={this.autogenPassword} size="small" style={{ float: 'right' }}>
                    Suggest Password
                  </Button>
                  <TextField
                    id="outlined-password-input"
                    label="Request New Password"
                    type="text"
                    fullWidth
                    name="editPassword"
                    margin="normal"
                    variant="outlined"
                    onChange={this.handlePasswordChange}
                    helperText="IMPORTANT: Leave blank to keep current password"
                    value={this.state.editPassword}
                  />
                </PermissionControl>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <ErrorMessages errorMessages={this.state.errorMessages} style={{ margin: '0 20px 10px 16px' }} />
        {this.state.loading ? null : (
          <DialogActions>
            <Button
              id="user-edit-submit-btn"
              variant={this.state.errorMessages && this.state.errorMessages.length > 0 ? 'disabled' : 'contained'}
              onClick={this.submitEdit}
              color="primary"
            >
              Submit Changes
            </Button>
            {this.props.user.active === 'active' ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <PermissionControl permissionName="Inactivate_Learners">
                  <Button
                    variant="outlined"
                    onClick={this.handleOpenDisable}
                    style={{
                      borderStyle: 'solid',
                      borderColor: 'red',
                      borderWidth: '2px',
                      borderRadius: 30,
                      color: theme.palette.text.red,
                    }}
                  >
                    Disable User
                  </Button>
                </PermissionControl>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="outlined"
                  id="reactivate-user-btn"
                  onClick={this.handleOpenReactivate}
                  style={{
                    borderStyle: 'solid',
                    borderColor: 'blue',
                    borderWidth: '2px',
                    borderRadius: 30,
                    color: 'blue',
                  }}
                >
                  Reactivate Learner
                </Button>
              </div>
            )}
            <Button id="edit-box-close-btn" onClick={() => this.props.closeHandler(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        )}
        <div>
          {this.state.responseData ? (
            <div>
              {this.state.responseData.userResults ? (
                <Dialog
                  open={this.state.resultOpen}
                  style={{ padding: '1rem', minWidth: 320 }}
                  onClose={this.handleResultClose}
                >
                  {this.state.responseData.userResults.map((i) => (
                    <div key={i.userName} style={{ padding: '1rem' }}>
                      <h3 style={{ textAlign: 'center' }}>Update Learner Request</h3>
                      <h1 style={{ textAlign: 'center' }}>{this.props.user[1]}</h1>
                      {this.state.isLocationChanged && this.state.whitelistStatus === 0 && (
                        <Paper style={{ background: 'red', margin: '1rem' }}>
                          <CancelIcon
                            style={{
                              float: 'left',
                              margin: '.5rem',
                              color: 'white',
                            }}
                          />
                          <h3
                            style={{
                              color: 'white',
                              textAlign: 'center',
                              padding: '.5rem',
                            }}
                          >
                            Update messaging relationships Failed
                          </h3>
                        </Paper>
                      )}
                      {this.state.isLocationChanged && this.state.whitelistStatus === 1 && (
                        <Paper style={{ background: '#359A16', margin: '1rem' }}>
                          <CheckCircleIcon
                            style={{
                              float: 'left',
                              margin: '.5rem',
                              color: 'white',
                            }}
                          />
                          <h3
                            style={{
                              color: 'white',
                              textAlign: 'center',
                              padding: '.5rem',
                            }}
                          >
                            Messaging relationships updated!
                          </h3>
                        </Paper>
                      )}
                      {this.state.isLocationChanged && this.state.whitelistStatus === 2 && (
                        <Paper style={{ background: theme.palette.background.blue, margin: '1rem' }}>
                          <InfoIcon
                            style={{
                              float: 'left',
                              margin: '.5rem',
                              color: 'white',
                            }}
                          />
                          <h3
                            style={{
                              color: 'white',
                              textAlign: 'center',
                              padding: '.5rem',
                            }}
                          >
                            Messaging relationships can only be updated manually for this user
                          </h3>
                        </Paper>
                      )}
                      {i.statusCode === 0 ? (
                        <div
                          style={{
                            width: 'auto',
                            padding: '.5rem',
                            border: '1px solid #ccc',
                            borderRadius: '.5rem',
                          }}
                        >
                          <p>
                            {i.username ? (
                              <span>
                                <strong>New Learner Name: </strong>
                                {i.userName}
                              </span>
                            ) : null}
                          </p>
                          <p>
                            {i.docId ? (
                              <span>
                                <strong>New DOC ID: </strong>
                                {i.docId}
                              </span>
                            ) : null}
                          </p>
                          <p>
                            {i.email ? (
                              <span>
                                <strong>New Email: </strong>
                                {i.email}
                              </span>
                            ) : null}
                          </p>
                          <p>
                            {i.sflocationName ? (
                              <span>
                                <strong>New Facility: </strong>
                                {i.sflocationName}
                              </span>
                            ) : null}
                          </p>
                          <p>
                            {i.locationId ? (
                              <span>
                                <strong>New Location Id: </strong>
                                {i.locationId}
                              </span>
                            ) : null}
                          </p>
                          <p>
                            {i.password ? (
                              <span>
                                <strong>New Password: </strong>
                                {i.password}
                              </span>
                            ) : null}
                          </p>
                          <p>
                            {i.dateOfBirth ? (
                              <span>
                                <strong>New DOB: </strong>
                                {i.dateOfBirth}
                              </span>
                            ) : null}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <h6>Something went wrong trying to update learner {i.userName}.</h6>
                          <p>Status Message: {i.statusMessage}</p>
                        </div>
                      )}
                    </div>
                  ))}
                  <DialogActions>
                    <Button variant="contained" onClick={this.handleResultClose} color="primary">
                      Okay
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : (
                <Dialog open={this.state.resultOpen} style={{ padding: '1rem' }} onClose={this.handleResultClose}>
                  <div style={{ padding: '1rem' }}>
                    <h1>Something went wrong.</h1>
                    <p>
                      <strong>{this.state.responseData.error}</strong>
                    </p>
                    <p>Status: {this.state.responseData.status}</p>
                    <p>Error Message: {this.state.responseData.message}</p>
                  </div>
                </Dialog>
              )}
            </div>
          ) : null}
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withUnleash(EditUser, UnleashService.FLAGS.DISABLE_DOUBLE_CLICK));
