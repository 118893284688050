import React, { Component, Suspense } from 'reactn';
import { withStyles, Zoom, Grid, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Datatable, { MTableToolbar } from 'material-table';
import { withRouter } from 'react-router-dom';
import { Loader } from '@orijinworks/frontend-commons';
import styles from './manageLocations.style';
import { USER_CONTEXT, LOCATION_TYPES, LOCATIONS, setLocations } from '../../constants';
import { listLocations } from '../../util/APIUtils';
import { UserIcon } from '../UserIcon';
import { theme } from '../../v2/core/containers/theme';

const Hoverable = React.lazy(() => import('../hoverable/'));
const AddLocation = React.lazy(() => import('./add/addLocation'));

export class ManageLocations extends Component {
  columns1 = [
    {
      field: 'locationId',
      title: 'ID',
      hidden: true,
      cellStyle: {
        width: 20
      }
    },
    {
      field: 'locationName',
      title: 'Location Name',
      render: rowData => <Hoverable title={rowData.locationName}>{rowData.locationName}</Hoverable>
    },
    {
      field: 'shortcode',
      title: 'Shortcode',
      render: rowData => <Hoverable title={rowData.shortcode}>{rowData.shortcode}</Hoverable>,
      cellStyle: {
        width: 50
      }
    },
    {
      field: 'locationId',
      title: 'Location ID',
      render: rowData => <Hoverable title={rowData.locationId}>{rowData.locationId}</Hoverable>
    },
    {
      field: 'locationType',
      title: 'Location Type',
      render: rowData => <Hoverable title={rowData.locationType}>{rowData.locationType}</Hoverable>
    },
    {
      field: 'parent',
      title: 'Parent',
      render: rowData => <Hoverable title={rowData.parent}>{rowData.parent}</Hoverable>
    },
    {
      field: 'children',
      title: 'Children',
      render: rowData => <Hoverable title={rowData.children}>{rowData.children}</Hoverable>,
      cellStyle: {
        minWidth: 150
      }
    },
    {
      field: 'active',
      title: 'Status',
      default: 'active',
      defaultSort: 'asc',
      render: rowData => (
        <Hoverable title={rowData.active}>
          <p
            id={`location-status-${rowData.locationId}`}
            style={{ color: rowData.active === 'active' ? 'black' : theme.palette.text.red }}
          >
            {rowData.active}
          </p>
        </Hoverable>
      ),
      lookup: { active: 'active', inactive: 'inactive' }
    },
    {
      field: 'actions',
      title: 'Actions',
      filtering: false,
      sorting: false,
      render: rowData => (
        <Hoverable title="Click to edit">
          <Button
            id={`location-btn-${rowData.locationId}`}
            color="primary"
            onClick={() => {
              this.goToLocationSettings(rowData);
            }}
          >
            Edit
          </Button>
        </Hoverable>
      )
    }
  ];
  tableRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      dataLoading: true,
      pageNo: 0,
      rowsPerPage: 50,
      locations: [],
      tableData: [],
      selectedLocations: []
    };
  }

  componentDidMount() {
    this.fetchLocations();
  }

  fetchLocations = () => {
    let pBody = '';
    let postBody = {
      requestorId: USER_CONTEXT.personId,
      filterLocationTypeIds: [],
      filterLocationIds: []
    };

    pBody = JSON.stringify(postBody);
    listLocations(pBody).then(data => {
      if (!this.global.isSuperAdmin) data.locations = data.locations.filter(d => d.active);
      const locations = data.locations
        .sort((a, b) => {
          if (a.locationName < b.locationName) return -1;
          if (a.locationName > b.locationName) return 1;
          return 0;
        })
        .map(item => {
          return {
            ...item,
            parent: this.getParentName(item.parentLocationId, data.locations),
            children: this.getChildren(item.locationId, data.locations),
            locationType: this.getLocationType(item.locationTypeId),
            shortcode: item.locationShortCode,
            active: item.active === true ? 'active' : 'inactive'
          };
        });
      this.setState({
        dataLoading: false,
        locations: data.locations,
        tableData: locations,
        showEmptyDataSourceMessage: true
      });
    });
  };

  getParentName = (parentId, locations) => {
    let parentName = '';
    if (locations && locations.length > 0) {
      const parent = locations.find(item => item.locationId === parentId);
      if (parent) {
        parentName = parent.locationName;
      }
    }
    return parentName;
  };

  getChildren = (locationId, locations) => {
    let children = 'none';
    if (locations && locations.length > 0) {
      let childLocations = locations.filter(item => item.parentLocationId === locationId);
      if (childLocations && childLocations.length > 0) {
        childLocations = childLocations.map(item => item.locationName);
        children = childLocations.slice(0, 3).join(', ') + (childLocations.length > 3 ? ' .... [more]' : '');
      }
    }
    return children;
  };

  getLocationType = typeId => {
    let locationType = '';
    const type = LOCATION_TYPES.find(item => item.id === typeId);
    if (type) {
      locationType = type.type;
    }
    return locationType;
  };

  goToLocationSettings = data => {
    this.props.history.push({
      pathname: `/manageLocations/${data.locationName.replace(/[^a-zA-Z0-9-_]/g, '')}`,
      state: [data]
    });
  };

  get getDatatableActions() {
    return [];
  }

  updateLocations = location => {
    const locations = [...this.state.locations, location];
    let tableData = [...this.state.tableData];
    tableData.push({
      ...location,
      parent: this.getParentName(location.parentLocationId, locations),
      children: this.getChildren(location.locationId, locations),
      locationType: this.getLocationType(location.locationTypeId),
      shortcode: location.locationShortCode,
      active: location.active === true ? 'active' : 'inactive'
    });
    tableData = tableData.sort((a, b) => {
      if (a.locationName < b.locationName) return -1;
      if (a.locationName > b.locationName) return 1;
      return 0;
    });
    if (location.locationTypeId === 3) {
      const dropdownLocations = [...LOCATIONS];
      dropdownLocations.push(location);
      setLocations(dropdownLocations);
    }
    this.setState({ tableData, locations });
  };

  render() {
    const { classes } = this.props;
    return (
      <Suspense fallback={<Loader isLoading={true} />}>
        <Grid container item component="div" className={classes.content}>
          <Grid container spacing={3}>
            <Grid item container>
              <Loader isLoading={this.state.dataLoading} />
            </Grid>
            <Grid container item justify="space-between">
              <AddLocation updateLocations={this.updateLocations} />
            </Grid>
            {
              <Grid item container>
                <Zoom style={{ width: '100%' }} in unmountOnExit>
                  <div>
                    <Datatable
                      style={{ width: '100%' }}
                      tableRef={ref => (this.tableRef = ref)}
                      onChangePage={pageNo => this.setState({ pageNo })}
                      onChangeRowsPerPage={rowsPerPage => this.setState({ rowsPerPage })}
                      title="Locations"
                      data={this.state.tableData}
                      columns={this.columns1}
                      icons={{
                        Search: SearchIcon,
                        ResetSearch: CloseIcon,
                        FirstPage: FirstPageIcon,
                        LastPage: LastPageIcon,
                        NextPage: NavigateNextIcon,
                        PreviousPage: NavigateBeforeIcon,
                        Filter: FilterListIcon,
                        SortArrow: ArrowDownwardIcon,
                        Export: SaveAltIcon,
                        Edit: EditIcon,
                        User: <UserIcon />
                      }}
                      components={{
                        Toolbar: props => (
                          <div>
                            <MTableToolbar {...props} />
                          </div>
                        )
                      }}
                      options={{
                        search: false,
                        sorting: true,
                        pageSize: this.state.rowsPerPage,
                        showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
                        filtering: true,
                        actionsColumnIndex: 0,
                        pageSizeOptions: [20, 50, 100],
                        rowStyle: rowData => ({
                          backgroundColor: rowData.active === 'active' ? '#ffffff' : '#e8e8e8'
                        }),
                        selectionProps: rowData => ({
                          id: `checkbox-${rowData.userName}`
                        })
                      }}
                      actions={this.getDatatableActions}
                      onSelectionChange={rows => {
                        this.setState({ selectedLocations: rows });
                      }}
                    />
                  </div>
                </Zoom>
              </Grid>
            }
          </Grid>
        </Grid>
      </Suspense>
    );
  }
}

export default withStyles(styles)(withRouter(ManageLocations));
