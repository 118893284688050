import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';


const loader = () => {
    return (
        <CircularProgress style={{width: 20, height: 20}}  />
    );
};

export default loader;