export default (theme) => ({
    tabName: {
        letterSpacing: '0.15px',
        fontSize: 20,
        lineHeight: '24px',
        fontWeight: 500,

    },
    tabsContainer: {
        background: 'white',
        padding: '8px 0 8px 40px',
    },
    tabWrapper: {
        flexDirection: "row"
    },
    appBar:{
        zIndex: 9
    },
    tabLabelIcon: {
        minHeight: "auto",
        '& $tabWrapper > *:first-child': {
            margin: '0 auto'
        }
    },
    tabMessageIcon: {
        minHeight: "auto",
        '& $tabWrapper > *:first-child': {
            margin: '0 auto',
            marginRight: "10px"
        }
    }
});