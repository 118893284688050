import {
    Document,
    Media,
    Paragraph,
    HeadingLevel,
    AlignmentType,
    TextRun,
    Footer,
    PageNumber,
    Header
} from "docx";
import { USER_CARD_DATETIME_FORMAT } from "../../../constants";
import moment from "moment";

export default async (users) => {
    const doc = new Document({
        creator: "APDS",
        description: `User-Card`,
        title: "Learner Card",
        styles: {
            paragraphStyles: [
                {
                    id: "Heading2",
                    name: "Heading 2",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        size: 24,
                        bold: true,
                        color: "000000",
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120
                        },
                    },
                },
                {
                    id: "Heading1",
                    name: "Heading 1",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        size: 28,
                        bold: true,
                        color: "000000",
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120
                        },
                    },
                },
            ]
        }
    });
    const blob = await fetch("/assets/logo/orijin-logo-blue.png").then(r => r.blob());

    const logo = Media.addImage(doc, blob , [140], [80]);
    for (const user of users) {
        let children = [
            new Paragraph({
                text: `Learner Login Information`,
                heading: HeadingLevel.HEADING_1,
                alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
                text: user.userDetail.firstName + " " + user.userDetail.lastName,
                heading: HeadingLevel.HEADING_2,
                alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
                text: `Current Location: ${user.userLocations.length > 0 ? (user.userLocations[0].locationTypeId === 15 ? `${user.userLocations[0].parentLocationName}, ${user.userLocations[0].locationName}` : `${user.userLocations[0].locationName}`) : "Inactive User"}`,
                heading: HeadingLevel.HEADING_2,
                alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
                children: [new TextRun({
                    text: "Connected Corrections",
                    bold: true,
                })],
                spacing: {
                    before: 200,
                    after: 200,
                },
            }),
            new Paragraph({
                text: `\tUsername: ${user.userDetail.userName || "n/a"}`,
            }),
            new Paragraph({
                text: `\tPassword: ${user.userDetail.password || "n/a"}`,
            }),
            new Paragraph({
                children: [new TextRun({
                    text: "The following educational resources require a login and password. After logging into Connected Corrections click on the corresponding tile/button and you will be prompted to enter a username and password.",
                    bold: true,
                })],
                spacing: {
                    before: 200,
                    after: 200,
                },
            }),
        ];

        (user.userServices || [])
            .filter(service => service.active === "true")
            .map((s, i) => {
                if (!s.ssoEnabled && (s.serviceKey != (124) && s.serviceKey != (125))) {
                    children = [
                        ...children,
                        new Paragraph({
                            children: [new TextRun({
                                text: s.serviceDisplayName,
                                bold: true,
                            })],
                            spacing: {
                                before: 200,
                                after: 200,
                            },
                        }),
                        new Paragraph({
                            text: `\tUsername: ${s.externalUserName || "n/a"}`,
                        }),
                        new Paragraph({
                            text: `\tPassword: ${s.externalUserPassword || "n/a"}`,
                        })
                    ]
                }
            })

        doc.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({
                            children: [logo],
                            spacing: {
                                before: 200,
                                after: 200,
                            },
                        })
                    ],
                })
            },
            children,
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                new TextRun({
                                    children: [PageNumber.CURRENT, "/", PageNumber.TOTAL_PAGES],
                                })
                            ],
                        }),
                    ],
                }),
            },

        });
        children = [];
    }

    return doc
}