import { MESSAGES_TYPE } from './messageModuleActions';

const initialState = {
  rsSelectedContact: {},
  rsActiveThread: 'contacts',
  rsMessageThread: [],
  rsAnnouncementsThread: [],
  rsUnreadMsgAnnoCountObj: { totalUnreadAnnouncementCount: 0, totalUnreadCount: 0 },
  messageType: 1,
  characterLimit: {},
  newMessage: false
};

export const rsMessageModuleStore = (state = initialState, { type, payload }) => {
  switch (type) {
    case MESSAGES_TYPE.SET_DIRECT_MESSAGES:
      return { ...state, rsMessageThread: payload.rsMessageThread };

    case MESSAGES_TYPE.SET_ANNOUNCEMENTS:
      return { ...state, rsAnnouncementsThread: payload.rsAnnouncementsThread };

    case MESSAGES_TYPE.SET_MSG_ANNOUNCEMENT_COUNT:
      return { ...state, rsUnreadMsgAnnoCountObj: payload.rsUnreadMsgAnnoCountObj };

    case MESSAGES_TYPE.SET_SELECTED_CONTACT:
      const { rsSelectedContact, rsActiveThread } = payload;
      return { ...state, rsSelectedContact, rsActiveThread };

    case MESSAGES_TYPE.SET_ACTIVE_THREAD: {
      const { rsActiveThread } = payload;
      return { ...state, rsActiveThread};
    }

    // case MESSAGES_TYPE.SET_ACTIVE_THREAD:
    //   const { rsActiveThread } = payload;
    //   return { ...state, rsActiveThread };

    case MESSAGES_TYPE.SET_MESSAGE_TYPE:
      return { ...state, messageType: payload };

    case MESSAGES_TYPE.SET_CHARACTER_LIMIT:
      return {
        ...state,
        characterLimit: payload
      };

    case MESSAGES_TYPE.SET_NEW_MESSAGE:
      return {
        ...state,
        newMessage: payload
      };

    default:
      return state;
  }
};
