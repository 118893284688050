import moment from "moment";
import {
  getFormList,
  toggleFormActivation,
  setAuthCode,
  createForm,
  updateForm,
  deleteForm,
  shareForm,
  getFormSubmissions,
  getSubmissionFormExport,
  deleteFormSubmissions,
  getFormAssemblyFormsList,
  importFormInSelectedLocation,
} from "../../../util/APIUtils";

export class FormService {
  ADD_FORM = "ADD_FORM";
  EDIT_FORM = "EDIT_FORM";
  SHARE_FORM = "SHARE_FORM";
  TOGGLE_FORM_ACTIVE = "TOGGLE_FORM_ACTIVE";
  REMOVE = "REMOVE";
  IMPORT = "IMPORT";
  static FORM_ASSEMBLY_EDIT_URL =
    "https://apds.tfaforms.net/forms/builder/5.0.0/";

  /**
   * @name getFormList
   * @param {number} locationId
   * @desc List of form created by user.
   * @return {Promise}
   */
  getFormList = (locationId) => {
    return getFormList(locationId).then(
      (_successLog) => {
        return _successLog;
      },
      (_errorLog) => {
        return [];
      }
    );
  };

  /**
   * @name deleteSubmissions
   * @param {number} formId
   * @param {array} submissionIds
   * @desc Deletes submission.
   * @return {Promise}
   */
  deleteSubmissions = (formId, submissionIds) => {
    return deleteFormSubmissions(formId, submissionIds);
  };

  /**
   * @name exportSubmissionToExcel
   * @param {number} formId
   * @param {number} locationId
   * @param {array} submissionIds
   * @desc Gets file from server and exports on client.
   * @return {void}
   */
  exportSubmissionToExcel = (formId, locationId, submissionIds, formName) => {
    getSubmissionFormExport(formId, locationId, submissionIds).then(
      (_successLog) => {
        var reader = new FileReader();
        var exportedFilename = `${formName}-submission.csv`;
        var blob = new Blob([_successLog], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilename);
        } else {
          reader.readAsDataURL(blob);

          reader.onloadend = function (e) {
            const a = document.createElement("a");
            a.href = reader.result;
            a.download = exportedFilename;
            a.target = "_blank";
            a.id = "download-link";

            var evt = document.createEvent("MouseEvents");
            evt.initMouseEvent(
              "click",
              true,
              true,
              window,
              1,
              0,
              0,
              0,
              0,
              false,
              false,
              false,
              false,
              0,
              null
            );

            document.body.appendChild(a);

            a.dispatchEvent(evt);

            document.body.removeChild(document.getElementById("download-link"));
          };
        }
      }
    );
  };

  /**
   * @name getFormSubmissions
   * @param {number} formId
   * @desc Fetches form submissions based upon form ID passed to it.
   * @return {Promise}
   */
  getFormSubmissions = (formId, locationId) => {
    return getFormSubmissions(formId, locationId).then((_successLog) => {
      return _successLog.map((form) => {
        form.submissionDate = `${moment
          .utc(form.submissionDate)
          .format("YYYY-MM-DD")} at ${moment
          .utc(form.submissionDate)
          .format("hh:mm A")}`;
        return form;
      });
    });
  };

  /**
   * @name getFormAssemblyCode
   * @desc Gets code from form assembly.
   * @return {Promise}
   */
  getFormAssemblyCode = () => {
    return new Promise((resolve, reject) => {
      const redirectURI = `${window.location.origin}/third-party-redirect.html`;
      const thirdPartyRedirectWindow = window.open(
        `https://apds.tfaforms.net/oauth/login?type=web&client_id=6PYdjEpOOphMgQAyoTuD&redirect_uri=${redirectURI}&response_type=code`,
        "_blank",
        "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=500"
      );
      const windowCheckIntervalRef = setInterval(() => {
        if (thirdPartyRedirectWindow.closed) {
          clearInterval(windowCheckIntervalRef);
          window.removeEventListener("message", redirectHandler);
          reject("Dialog closed by user.");
        }
      }, 500);
      const redirectHandler = (event) => {
        if (event.data.hasOwnProperty("code")) {
          thirdPartyRedirectWindow.close();
          clearInterval(windowCheckIntervalRef);
          window.removeEventListener("message", redirectHandler);
          setAuthCode(event.data.code, redirectURI).then(
            (_successLog) => {
              resolve(_successLog);
            },
            (_errorLog) => {
              reject(_errorLog);
            }
          );
        }
      };

      window.addEventListener("message", redirectHandler);
    });
  };

  /**
   * @name addUpdateShareForm
   * @param {object} payload
   * @param {string} type
   * @desc Add's / update's form based upon type passed.
   * @return {Promise}
   */
  addUpdateShareForm = (payload, type, showSnackBar) => {
    let rowData = payload.row;
    let selectedLocations = payload.locationName;
    let locationIds;
    let sharePayload;
    if (selectedLocations) {
      locationIds = selectedLocations.map((a) => a.locationId);
      sharePayload = {
        formId: rowData.id,
        locationIds,
      };
    }

    payload = {
      id: payload.id,
      formId: payload.formId,
      name: payload.name,
      description: payload.description,
      required: payload.required,
      active: payload.active,
      locationId: payload.locationId,
      locationIds: payload.locationIds,
    };

    const APIService =
      // type === this.ADD_FORM ? createForm(payload) : updateForm(payload);
      type === this.ADD_FORM
        ? createForm(payload)
        : type === this.EDIT_FORM
        ? updateForm(payload)
        : shareForm(sharePayload);
    return APIService.then(
      (_successLog) => {
        if (type === this.ADD_FORM) {
          const formId = _successLog ? _successLog.formId : payload.formId;
          window.open(
            `${FormService.FORM_ASSEMBLY_EDIT_URL}${formId}`,
            "_blank"
          );
        }
        return _successLog;
      },
      (_errorLog) => {
        if (!_errorLog.status) {
          return;
        }
        switch (_errorLog.status) {
          case 401:
            return this.getFormAssemblyCode()
              .then((_successLog) => this.addUpdateShareForm(payload, type))
              .then((_successLog) => _successLog)
              .catch((_errorLog) => {
                showSnackBar(
                  "Operation failed, please contact programming staff."
                );
                return _errorLog;
              });
          default:
            showSnackBar("Operation failed, please contact programming staff.");
            return false;
        }
      }
    );
  };

  /**
   * @name toggleFormActivation
   * @param {object} payload
   * @desc Toggles form activation as per payload passed.
   * @return {Promise}
   */
  toggleFormActivation = (payload, showSnackBar) => {
    return toggleFormActivation(payload).then(
      (_successLog) => {
        return _successLog;
      },
      (_errorLog) => {
        if (!_errorLog.status) {
          return false;
        }
        switch (_errorLog.status) {
          case 401:
            return this.getFormAssemblyCode()
              .then((_successLog) =>
                this.toggleFormActivation(payload, showSnackBar)
              )
              .then((_successLog) => _successLog)
              .catch((_errorLog) => {
                showSnackBar(
                  "Operation failed, please contact programming staff."
                );
                return _errorLog;
              });
          default:
            showSnackBar("Operation failed, please contact programming staff.");
            return false;
        }
      }
    );
  };

  /**
   * @name manageForm
   * @param {object} payload
   * @param {string} type Type of payload add / edit
   * @desc Manage's form add / update cycle within service to avoid
   *       unnecessary template logic.
   * @return {Promise}
   */
  manageForm = (payload, type, showSnackBar) => {
    return new Promise(async (resolve, reject) => {
      switch (type) {
        case this.ADD_FORM:
        case this.EDIT_FORM:
          try {
            const formData = await this.addUpdateShareForm(
              payload,
              type,
              showSnackBar
            );
            resolve(formData);
          } catch (e) {
            reject(e);
          }
          // this.getFormAssemblyCode()
          //     .then(
          //         (_successLog) => {
          //             resolve({
          //                 url: _successLog.code
          //             });
          //         }
          //     )
          //     .catch(
          //         (_errorLog) => {
          //             resolve(null);
          //         }
          //     );
          break;
        case this.SHARE_FORM:
          try {
            const formData = await this.addUpdateShareForm(
              payload,
              type,
              showSnackBar
            );
            resolve(formData);
          } catch (e) {
            reject(e);
          }
          break;

        case this.TOGGLE_FORM_ACTIVE:
          return this.toggleFormActivation(payload, showSnackBar);

        case this.REMOVE:
          // TODO: ADD LOGIC
          deleteForm(payload.locationId, payload.forms).then(
            (_successLog) => resolve(true),
            (_errorLog) => resolve(true)
          );
          break;
        case this.IMPORT:
          const locationId = payload.locationId;
          importFormInSelectedLocation(locationId, payload).then(
            (_successLog) => resolve(true),
            (_errorLog) => resolve(false)
          );
          break;
      }
    });
  };

  getFormAssemblyForms = () => {
    return getFormAssemblyFormsList().then((_successLog) => {
      return _successLog;
    });
  };
}
