import { CustomTheme } from '@orijinworks/frontend-commons';

export default (theme) => ({
  locationName: {
    color: '#747474',
    letterSpacing: '1.25px',
    fontSize: 11,
    fontWeight: 700,
  },
  datatableBlueBox: {
    color: theme.palette.primary.cobalt,
  },
  tabName: {
    letterSpacing: '0.15px',
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 500,
  },
  datatable: {
    '& td': {
      fontSize: 14,
      letterSpacing: '0.04px',
      color: '#000000',
    },
  },
  topActionBar: {
    backgroundColor: '#f3f3f3',
  },
  card: {
    borderRadius: 0,
    marginLeft: 30,
  },
  reloadIcon: {
    color: CustomTheme.palette.primary.main,
  },
  chip: {
    backgroundColor: theme.palette.primary.cobalt,
    padding: '5px 10px',
    borderRadius: 3,
    marginLeft: 5,
  },
  chipText: {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: theme.palette.primary.white,
  },
  submissionText: {
    fontSize: 14,
    letterSpacing: '0.04px',
    fontWeight: 700,
    color: theme.palette.text.navy,
  },
  formName: {
    fontSize: 18,
    textAlign: 'left',
    maxWidth: 200,
    color: theme.palette.text.navy,
    letterSpacing: '0.43px',
    textTransform: 'capitalize',
  },
  content: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      padding: '0px 30px',
    },
  },
  toolbarPaper: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    width: '100%',
    padding: 30
  },
  selectionList: {
    display: 'flex',
    marginBottom: 16,
  },
  selectionItem: {
    width: 'initial',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: 40,
    fontSize: 18,
    color: theme.palette.primary.navy,
    fontWeight: 'bold',
    '&:first-child': {
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    '&:last-child': {
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
    },
  },
  selectedItem: {
    backgroundColor: theme.palette.primary.navy,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.navy,
    },
  },
  selectionListHeading: {
    color: theme.palette.colors.ceruleanMain
  }
});
