import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import React from 'reactn';
import { columnSortByOrderDirection } from '../../../../util/Helpers';
import Hoverable from '../../../hoverable';
import CustomMenu from '../../../management/menu';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import { VoiceCallService } from '../../services/voice-call.service';
import { LOCATIONS } from '../../../../constants';
import { generateStatusChangeOptions, getSelectedLocationTimeZone, parseRelationshipType } from '../../utils/utils';
import isFeatureFlagOn from '../../../../v2/services/useFeatureFlagOnHook';
import UnleashService from '../../../../v2/services/unleash/unleash.service';
const useContactsListComponentHook = (activeTab, locationId, classes, isDataRefresh, setDataRefresh) => {
  const voiceCallService = new VoiceCallService();
  const [isFeatureFlagEnabledForArchiveUnArchiveToggle] = useState(isFeatureFlagOn(UnleashService.FLAGS.ARCHIVE_UNARCHIVE_TOGGLE));
  const defaultPageSize = 10;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(6000);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [addContactSuccess, setAddContactSuccess] = useState(false);
  const [stateFilters, setStateFilters] = useState({
    learnerFirstName: '',
    learnerDocId: '',
    firstName: '',
    phoneNumber: '',
    relationshipType: '',
    status: [],
  });
  const [allowedFilters, setAllowedFilters] = useState([
    'learnerFirstName',
    'learnerDocId',
    'firstName',
    'phoneNumber',
    'relationshipType',
    'createdDate',
    'status',
  ]);
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [editContact, setEditContact] = useState({
    id: '',
    firstName: '',
    lastName: '',
    preferredLanguage: '',
    relationshipType: '',
    learnerUserName: '',
    phoneNumber: '',
    learnerPersonKey: '',
    status: '',
    modifiedDate: '',
  });

  const [isEditSucess, setIsEditSucess] = useState(false);
  const [isEditError, setIsEditError] = useState('');
  const tableRef = React.useRef();

  const STATUSES = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    BLOCKED: 'BLOCKED',
    REJECTED: 'REJECTED',
  };
  function getRelationshipTypes() {
    return VOICE_CALL.getRelationshipTypes();
  }

  useEffect(() => {
    if (isDataRefresh) {
      refreshTable();
      setDataRefresh(false);
    }
  }, [isDataRefresh]);
  useEffect(() => {
    refreshTable();
    setDataRefresh(false);
  }, [locationId, activeTab, addContactSuccess]);

  useEffect(() => {
    if (isEditSucess) {
      handleSnackbar('Contact updated successfully');
      setDataRefresh(true);
      setAddContactSuccess(true);
      setIsEditSucess(false);
    } else if (isEditError !== '') {
      handleSnackbar(isEditError);
      setIsEditError('');
      setAddContactSuccess(false);
    }
  }, [isEditSucess, isEditError]);
  const handleSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
    setSnackbarAutoHideDuration(3000);
  };

  const showSnackBarAfterContactExport = (message, success, duration) => {
    handleSnackbar(message);
    setSnackbarAutoHideDuration(duration);
    setAddContactSuccess(success);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  let tableColumns = [
    {
      title: 'Resident Name',
      field: 'learnerFirstName',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 150,
      },
      render: (rowData) => (
        <Hoverable title={rowData.learnerFirstName}>{rowData.learnerFirstName + ' ' + rowData.learnerLastName}</Hoverable>
      ),
    },
    {
      title: 'DOC ID',
      field: 'learnerDocId',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 100,
      },
      render: (rowData) => <Hoverable title={rowData.learnerDocId}>{rowData.learnerDocId}</Hoverable>,
    },
    {
      title: 'Contact Name',
      field: 'firstName',
      filtering: true,
      sorting: true,
      render: (rowData) => <Hoverable title={rowData.firstName}>{rowData.firstName + ' ' + rowData.lastName}</Hoverable>,
    },
    {
      title: 'Phone Number',
      field: 'phoneNumber',
      cellStyle: {
        minWidth: 200,
      },
      render: (rowData) => <Hoverable title={rowData.phoneNumber}>{rowData.phoneNumber}</Hoverable>,
    },
    {
      title: 'Relationship',
      field: 'relationshipType',
      render: (rowData) => (
        <Hoverable title={parseRelationshipType(rowData.relationshipType)}>
          {parseRelationshipType(rowData.relationshipType)}
        </Hoverable>
      ),
    },
    {
      title: 'Date created',
      field: 'createdDate',
      defaultSort: 'desc',
      cellStyle: {
        minWidth: 250,
      },
      type: 'date',
      render: rowData => {
        const timeZone = getSelectedLocationTimeZone();
        const formattedDate = moment.utc(rowData.createdDate).tz(timeZone).format('MM-DD-YYYY hh:mm:ss A');
        return (
          <Hoverable title={formattedDate}>
            {formattedDate}
          </Hoverable>
        );
      }

    },
    {
      title: 'Connection status',
      field: 'status',
      render: ((rowData) => {
          return <Typography className={getStatusClassName(rowData, classes)}>{rowData.status === VOICE_CALL.STATUS.INACTIVE ? 'ARCHIVED' : rowData.status}</Typography>;
      }),
      filtering: true,
      sorting: false,
      lookup: (() => {
        const statusLookup = { ...VOICE_CALL.STATUS, INACTIVE: 'ARCHIVED' }        
        if (!isFeatureFlagEnabledForArchiveUnArchiveToggle) {
          delete statusLookup.INACTIVE;
        }
        return statusLookup;
      })()
    },
    {
      title: 'Action',
      field: 'action',
      sorting: false,
      filtering: false,
      render: (rowData) => {
        const options = generateStatusChangeOptions(rowData, updateContactStatus, handleEditContactModalOpen);
        if(isFeatureFlagEnabledForArchiveUnArchiveToggle){
          return <CustomMenu options={options} />;
        }
        else{
          const filteredOptions = options.filter(option => option.id !== VOICE_CALL.STATUS.INACTIVE);
          return <CustomMenu options={filteredOptions} />;
        }
      }
    }
  ];

  const setTableFilters = (query) => {
    let filters = { ...stateFilters };
    for (let i = 0; i < query.filters.length; i++) {
      let item = query.filters[i];
      if (allowedFilters.includes(item.column.field)) {
        let value = item.value;
        if (Array.isArray(value)) value = [...item.value];
        filters[item.column.field] = value;
      }
    }
    return filters;
  };
  const createRequestPayload = (query) => {
    const selectedFilters = setTableFilters(query);
    columnSortByOrderDirection(tableColumns, query.orderBy, query.orderDirection);
    const tbody = {
      createdDate: selectedFilters.createdDate ? moment(selectedFilters.createdDate).format('YYYY-MM-DD') : '',
      firstName: selectedFilters.firstName,
      learnerDocId: selectedFilters.learnerDocId,
      learnerFirstName: selectedFilters.learnerFirstName,
      phoneNumber: selectedFilters.phoneNumber,
      relationshipType: selectedFilters.relationshipType,
    };
    let callStatus = selectedFilters.status.join();
    return { tbody, callStatus };
  };

  const getContactsList = (query) => {
    if (!locationId) return;
    const { orderBy, orderDirection } = query;
    const { tbody, callStatus } = createRequestPayload(query);
    return new Promise((resolve, reject) => {
      voiceCallService
        .getContactsList({
          locationId,
          pageNo: query.page,
          pageSize: query.pageSize,
          sortBy: orderBy ? orderBy.field : '',
          sortOrder: orderDirection ? orderDirection : '',
          callStatus,
          tbody,
        })
        .then((data) => {
          const { content, pageable, totalElements } = data;
          if (content) {
            resolve({
              data: content,
              page: pageable.pageNumber,
              totalCount: totalElements,
            });
            setTotalCount(totalElements);
            //setLoading(false);
          } else reject(new Error('Something went wrong'));
        });
    });
  };

  const refreshTable = () => {
    tableRef && tableRef.current && tableRef.current.onQueryChange();
  };

  const updateContactStatus = async (contact, status) => {
    const data = await voiceCallService.updateContact({
      id: contact.id,
      firstName: contact.firstName,
      lastName: contact.lastName,
      phoneNumber: contact.phoneNumber,
      learnerPersonKey: contact.learnerPersonKey,
      relationshipType: contact.relationshipType,
      preferredLanguage: contact.preferredLanguage,
      status: status,
    });
    if (data && data.errorMessage) {
      setAddContactSuccess(false);
      handleSnackbar(data.errorMessage);
    } else {
      setAddContactSuccess(true);
      handleSnackbar(`Contact status updated to ${status === VOICE_CALL.STATUS.INACTIVE ? 'ARCHIVED' : status} successfully!`);
      refreshTable();
    }
  };

  const getStatusClassName = (rowData, classes) => {
    switch (rowData.status) {
      case 'APPROVED':
        return classes.success;
      case 'PENDING':
        return classes.pending;
      case 'BLOCKED':
        return classes.warning;
      case 'REJECTED':
        return classes.danger;
      default:
        return '';
    }
  };

  const shouldShowExportButton = () => {
    return Boolean(activeTab === VOICE_CALL.TABS.ALL_CONTACTS);
  };

  const fetchAllContactsForExport = async (locationId, totalCount) => {
    try {
      if (tableRef && tableRef.current && tableRef.current.state) {
        const { query } = tableRef.current.state;
        const { orderBy, orderDirection } = query;
        const { tbody, callStatus } = createRequestPayload(query);
        setLoading(true);
        const allContacts = await voiceCallService.getContactsList({
          locationId,
          pageNo: 0,
          pageSize: totalCount,
          callStatus: callStatus,
          tbody: tbody,
          sortBy: orderBy ? orderBy.field : '',
          sortOrder: orderDirection ? orderDirection : '',
        });
        if (allContacts) {
          return allContacts['content'];
        }
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportCsv = async (tableColumns) => {
    if (totalCount === 0) {
      showSnackBarAfterContactExport('No contacts found to be exported', false, 2000);
      return;
    }
    const allContacts = await fetchAllContactsForExport(locationId, totalCount);
    const exemptedColumnsList = ['Action'];
    let csvContent = '';
    const columnString = tableColumns
      .filter((column) => exemptedColumnsList.indexOf(column.title) === -1)
      .map((column) => column.title)
      .join(',');
    csvContent += columnString + '\r\n';
    if (allContacts && allContacts.length > 0) {
      const domParser = new DOMParser();
      allContacts.forEach((item) => {
        const rowString = tableColumns
          .map((column) => {
            if (column.field && item[column.field] !== undefined) {
              let value = '';
              if (column.title === 'Learner Name') {
                value = `${item['learnerFirstName']} ${item['learnerLastName']}`;
              } else if (column.title === 'Contact Name') {
                value = `${item['firstName']} ${item['lastName']}`;
              } else {
                value = item[column.field];
              }
              const document = domParser.parseFromString(value, 'text/html');
              return '"' + document.body.innerText + '"';
            }
          })
          .join(',');
        csvContent += rowString + '\r\n';
      });
      if (csvContent && csvContent !== '') {
        generateCsv(csvContent);
        showSnackBarAfterContactExport('CSV file has been exported successfully', true, 2000);
      }
    } else {
      showSnackBarAfterContactExport('No contacts found to be exported', false, 2000);
    }
  };

  const generateCsv = (csvContent) => {
    let locationName = getLocationName(locationId);
    const exportedFilename = `${locationName} Voice Calls All Contacts.csv`;
    const universalBOM = '\uFEFF';
    const downloadLink = document.createElement('a');
    downloadLink.download = exportedFilename;
    downloadLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;
    downloadLink.click();
  };

  /**
   * @name getLocationName
   * @param {number} locationId
   * @desc Based upon locationId it finds in LOCATIONS collection
   * for matching location and shows locationName instead.
   * @return {string}
   */
  const getLocationName = (locationId) => {
    const location = LOCATIONS.find((location) => location.locationId === locationId);
    if (location) {
      return location.locationName;
    } else {
      return '';
    }
  };

  /**
   *  Edit Contact Work
   */

  const handleEditContactModalOpen = (contact) => {
    setEditContact({
      id: contact.id,
      firstName: contact.firstName,
      lastName: contact.lastName,
      preferredLanguage: contact.preferredLanguage,
      relationshipType: contact.relationshipType,
      learnerPersonKey: contact.learnerPersonKey,
      learnerUserName: contact.learnerFirstName + ' ' + contact.learnerLastName,
      phoneNumber: contact.phoneNumber,
      status: contact.status,
      modifiedDate: contact.modifiedDate,
    });
    setIsEditContactModalOpen(true);
  };

  const handleEditContactModalClose = () => {
    setIsEditContactModalOpen(false);
  };
  return {
    isLoading,
    tableColumns,
    pageSize,
    getStatusClassName,
    updateContactStatus,
    STATUSES,
    openSnackbar,
    handleCloseSnackbar,
    snackbarMessage,
    snackbarAutoHideDuration,
    getContactsList,
    tableRef,
    handleExportCsv,
    shouldShowExportButton,
    fetchAllContactsForExport,
    getRelationshipTypes,
    editContact,
    setEditContact,
    isEditSucess,
    addContactSuccess,
    setIsEditSucess,
    setIsEditError,
    isEditContactModalOpen,
    setIsEditContactModalOpen,
    handleEditContactModalOpen,
    handleEditContactModalClose,
  };
};

export default useContactsListComponentHook;
