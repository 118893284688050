import React from 'reactn';
import {
  Dialog,
  Paper,
  IconButton,
  Typography,
  ButtonBase,
  Card,
  CardActionArea,
  Toolbar,
  AppBar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Box,
  FormControlLabel,
  Button,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tooltip,
} from '@material-ui/core';
import { qrylocation, updateServiceLocationFlag } from '../../util/APIUtils';
import { USER_CONTEXT } from '../../constants';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SELECTED_LOCATION } from '../../constants';
import moment from 'moment';
import PermissionControl from '../permission-control/permission-control';
import ConfirmationModal from '../confirmation-modal';
import Datatable, { MTableHeader } from 'material-table';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SortIcon from '@material-ui/icons/Sort';
import FilterListIcon from '@material-ui/icons/FilterList';
import CompareIcon from '@material-ui/icons/Compare';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { UserIcon } from '../UserIcon';
import CustomMenu from '../management/menu';
import { withStyles } from '@material-ui/styles';
import { theme as AdminTheme } from '../../v2/core/containers/theme';
import { AVAILABILITY_TYPES } from '../../v2/services/constants';
import withUnleashClient from '../../v2/core/unleash/withUnleashClient/withUnleashClient';
import { UnleashService } from '../../v2/services';

const styles = (theme) => ({
  infoBar: {
    border: '1px solid black',
    borderRadius: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    width: 13,
    height: 13,
    fontSize: 12,
    '&:hover + div': {
      opacity: 1,
    },
  },
  tooltip: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    top: 85,
    left: -118,
    width: 400,
    background: 'white',
    border: '1px solid black',
    borderRadius: 10,
    padding: 10,
    transition: 'all 0.2s',
  },
});
class LocationDetailsModal extends React.Component {
  state = {
    showEmptyDataSourceMessage: false,
    detailsModal: {
      title: null,
      contentArea: null,
      sso: null,
      modified: null,
      open: false,
      handleClose: () => null,
    },
    open: false,
    isPropogateChangeToChildLocation: false,
    isConfirmationBoxOpen: false,
    confirmProps: {
      continueText: 'Yes',
      cancelText: 'No',
      isOpen: false,
      onContinue: null,
      onClose: null,
      heading: 'Caution!',
      description:
        'Are you sure you want to push these changes to all housing units? Once you have made the changes you won’t be able to revert them in bulk.',
    },
  };
  coreServiceColumns = [
    {
      field: 'partnerName',
      title: 'Partner Name',
      render: (row) => {
        return row.partnerName ? row.partnerName : row.serviceName;
      },
    },
    {
      field: 'serviceDisplayName',
      title: 'Offering',
    },
    {
      field: 'autoProvisionCapable',
      title: 'Auto Provision Capable',
      render: (row) => (row.autoProvisionCapable || 'false').toString(),
    },
    {
      field: 'automatedProvisioning',
      title: 'Automated Provisioning',
      render: (row) => (
        <PermissionControl
          permissionName="Edit_Service_Settings"
          fallbackComponent={(row.autoProvisionCapable ? row.automatedProvisioning || 'false' : 'N/A').toString()}
          forceRestriction={!row.autoProvisionCapable || !this.global.isSuperAdmin}
        >
          <Switch
            checked={row.automatedProvisioning || row.automatedProvisioning == null}
            onChange={(event) => {
              if (this.state.isPropogateChangeToChildLocation) {
                const { checked } = event.target;
                this.toggleConfirmationBox({
                  isOpen: true,
                  onClose: () => this.toggleConfirmationBox({ isOpen: false }),
                  onContinue: () => {
                    this.updateServiceLocationToggle(
                      'automatedProvisioning',
                      checked,
                      row,
                      row.serviceId,
                      this.state.locationDetails.locationDetails.locationId
                    );
                    this.toggleConfirmationBox({ isOpen: false });
                  },
                });
                return;
              }
              this.updateServiceLocationToggle(
                'automatedProvisioning',
                event.target.checked,
                row,
                row.serviceId,
                this.state.locationDetails.locationDetails.locationId
              );
            }}
            color="primary"
          />
        </PermissionControl>
      ),
    },
    {
      title: (
        <p>
          Availability
          <span className={this.props.classes.infoBar}>?</span>
          <div className={this.props.classes.tooltip}>
            All learners: The offering will be available on the Explore Page for all learners
            <br />
            <br />
            Select learners: The offering must be assigned to individual learners.
          </div>
        </p>
      ),
      render: (rowData) => this.getAvailabilityText(rowData),
    },
    {
      title: 'Status',
      render: (rowData) => {
        return (
          <Typography
            variant="p"
            style={{
              color:
                (rowData.isAvailable == null || rowData.isAvailable) && rowData.endDate == null
                  ? AdminTheme.palette.text.green
                  : AdminTheme.palette.text.orange,
            }}
          >
            {(rowData.isAvailable == null || rowData.isAvailable) && rowData.endDate == null ? 'Active' : 'Suspended'}
          </Typography>
        );
      },
    },
    {
      cellStyle: {
        pointerEvents: 'all',
      },
      render: (rowData) => {
        const isActive = (rowData.isAvailable == null || rowData.isAvailable) && rowData.endDate == null;
        return (
          <CustomMenu
            options={[
              {
                title: 'View Service Details',
                onClick: () =>
                  this.toggleDetailsModal({
                    title: rowData.serviceDisplayName,
                    contentArea: rowData.contentArea,
                    open: true,
                    sso: rowData.ssoEnabled ? 'true' : 'false',
                    modified: moment(rowData.slaModifiedDate).format('MM/DD/YYYY'),
                    handleClose: () =>
                      this.toggleDetailsModal({
                        open: false,
                      }),
                  }),
              },
              {
                title: isActive ? 'Suspend Offering' : 'Activate Offering',
                onClick: () =>
                  this.toggleConfirmationBox({
                    continueText: isActive ? 'SUSPEND' : 'ACTIVATE',
                    cancelText: 'CANCEL',
                    isOpen: true,
                    onContinue: () => {
                      this.updateServiceLocationToggle(
                        'available',
                        !isActive,
                        rowData,
                        rowData.serviceId,
                        this.state.locationDetails.locationDetails.locationId
                      );
                      this.toggleConfirmationBox({ isOpen: false });
                    },
                    onClose: () => this.toggleConfirmationBox({ isOpen: false }),
                    heading: `Are you sure you want to ${isActive ? 'suspend' : 'activate'} this offering?`,
                    description: isActive
                      ? `
                <div style="margin-top: 10px">
                Suspending an offering will remove it from the Explore Page. It will not unenroll the learner from the course.
                </div>
              `
                      : `
              <div style="margin-top: 10px">
              Activating an offering will add the offering to the Learner Portal.
              </div>
              `,
                    continueButtonStyles: {
                      backgroundColor: isActive ? '#ea9999' : '#a4daa7',
                      borderColor: '#b07979',
                      color: 'black',
                      marginTop: 20,
                      padding: '5px 20px',
                    },
                    cancelButtonStyles: {
                      backgroundColor: '#dddddd',
                      borderColor: '#7d7d7d',
                      color: 'black',
                      marginRight: 10,
                      marginTop: 20,
                      padding: '5px 20px',
                    },
                    alignActionButtons: 'center',
                    title: rowData.serviceDisplayName,
                  }),
              },
            ]}
          />
        );
      },
    },
  ];
  premiumServiceColumns = [
    {
      field: 'partnerName',
      title: 'Partner Name',
      width: 220,
      render: (row) => {
        return row.partnerName ? row.partnerName : row.serviceName;
      },
    },
    {
      field: 'serviceDisplayName',
      title: 'Offering',
      width: 220,
    },
    {
      field: 'autoProvisionCapable',
      title: 'Auto Provision Capable',
      render: (row) => (row.autoProvisionCapable || 'false').toString(),
    },
    {
      field: 'automatedProvisioning',
      title: 'Automated Provisioning',
      render: (row) => (
        <PermissionControl
          permissionName="Edit_Service_Settings"
          fallbackComponent={(row.autoProvisionCapable ? row.automatedProvisioning || 'false' : 'N/A').toString()}
          forceRestriction={!row.autoProvisionCapable || !this.global.isSuperAdmin}
        >
          <Switch
            checked={row.automatedProvisioning || row.automatedProvisioning == null}
            onChange={(event) => {
              if (this.state.isPropogateChangeToChildLocation) {
                const { checked } = event.target;
                this.toggleConfirmationBox({
                  isOpen: true,
                  onClose: () => this.toggleConfirmationBox({ isOpen: false }),
                  onContinue: () => {
                    this.updateServiceLocationToggle(
                      'automatedProvisioning',
                      checked,
                      row,
                      row.serviceId,
                      this.state.locationDetails.locationDetails.locationId
                    );
                    this.toggleConfirmationBox({ isOpen: false });
                  },
                });
                return;
              }
              this.updateServiceLocationToggle(
                'automatedProvisioning',
                event.target.checked,
                row,
                row.serviceId,
                this.state.locationDetails.locationDetails.locationId
              );
            }}
            color="primary"
          />
        </PermissionControl>
      ),
    },
    {
      title: (
        <p>
          Availability
          <span className={this.props.classes.infoBar}>?</span>
          <div className={this.props.classes.tooltip}>
            All learners: The offering will be available on the Explore Page for all learners
            <br />
            <br />
            Select learners: The offering must be assigned to individual learners.
          </div>
        </p>
      ),
      width: 350,
      render: (rowData) => {
        return (
          <FormControl>
            <RadioGroup
              onChange={(e) => {
                const { value } = e.target;
                if (value == 'all') {
                  this.updateServiceLocationToggle(
                    'availabilityType',
                    AVAILABILITY_TYPES.ALL_LEARNERS,
                    rowData,
                    rowData.serviceId,
                    this.state.locationDetails.locationDetails.locationId
                  );
                } else if (value == 'selected') {
                  this.updateServiceLocationToggle(
                    'availabilityType',
                    AVAILABILITY_TYPES.BY_ASSIGNMENT_RETAIN_ACCOUNT_ON_MOVE,
                    rowData,
                    rowData.serviceId,
                    this.state.locationDetails.locationDetails.locationId,
                    {
                      explorable: false,
                    }
                  );
                }
              }}
              defaultValue={() => this.getDefaultAvailability(rowData)}
            >
              <FormControlLabel value="all" control={<Radio />} label="Available to all learners" />
              <FormControlLabel value="selected" control={<Radio />} label="Available by assignment" />
            </RadioGroup>
          </FormControl>
        );
      },
    },
    {
      title: 'Partner Status',
      render: (rowData) => {
        return (
          <Typography
            variant="p"
            style={{
              color:
                (rowData.isAvailable == null || rowData.isAvailable) && rowData.endDate == null
                  ? AdminTheme.palette.text.green
                  : AdminTheme.palette.text.orange,
            }}
          >
            {(rowData.isAvailable == null || rowData.isAvailable) && rowData.endDate == null ? 'Active' : 'Suspended'}
          </Typography>
        );
      },
    },
    {
      cellStyle: {
        pointerEvents: 'all',
      },
      render: (rowData) => {
        const isActive = (rowData.isAvailable == null || rowData.isAvailable) && rowData.endDate == null;
        return (
          <CustomMenu
            options={[
              {
                title: 'View Service Details',
                onClick: () =>
                  this.toggleDetailsModal({
                    title: rowData.serviceDisplayName,
                    contentArea: rowData.contentArea,
                    open: true,
                    sso: rowData.ssoEnabled ? 'true' : 'false',
                    modified: moment(rowData.slaModifiedDate).format('MM/DD/YYYY'),
                    handleClose: () =>
                      this.toggleDetailsModal({
                        open: false,
                      }),
                  }),
              },
              {
                title: isActive ? 'Suspend Offering' : 'Activate Offering',
                onClick: () =>
                  this.toggleConfirmationBox({
                    continueText: isActive ? 'SUSPEND' : 'ACTIVATE',
                    cancelText: 'CANCEL',
                    isOpen: true,
                    onContinue: () => {
                      this.updateServiceLocationToggle(
                        'available',
                        !isActive,
                        rowData,
                        rowData.serviceId,
                        this.state.locationDetails.locationDetails.locationId
                      );
                      this.toggleConfirmationBox({ isOpen: false });
                    },
                    onClose: () => this.toggleConfirmationBox({ isOpen: false }),
                    heading: `Are you sure you want to ${isActive ? 'suspend' : 'activate'} this offering?`,
                    description: isActive
                      ? `
                <div style="margin-top: 10px">
                Suspending an offering will remove it from the Explore Page. It will not unenroll the learner from the course.
                </div>
              `
                      : `
              <div style="margin-top: 10px">
              Activating an offering will add the offering to the Learner Portal.
              </div>
              `,
                    continueButtonStyles: {
                      backgroundColor: isActive ? '#ea9999' : '#a4daa7',
                      borderColor: '#b07979',
                      color: 'black',
                      marginTop: 20,
                      padding: '5px 20px',
                    },
                    cancelButtonStyles: {
                      backgroundColor: '#dddddd',
                      borderColor: '#7d7d7d',
                      color: 'black',
                      marginRight: 10,
                      marginTop: 20,
                      padding: '5px 20px',
                    },
                    alignActionButtons: 'center',
                    title: rowData.serviceDisplayName,
                  }),
              },
            ]}
          />
        );
      },
    },
  ];
  /// Required Props:
  /// LocationID - The ID of the location

  toggleConfirmationBox = (props) => {
    this.setState({
      confirmProps: {
        ...this.state.confirmProps,
        ...props,
      },
    });
  };

  toggleDetailsModal = (props) => {
    this.setState({
      detailsModal: {
        ...this.state.detailsModal,
        ...props,
      },
    });
  };

  getDefaultAvailability = ({ assignable, explorable, availabilityType }) => {
    console.log(availabilityType);
    const unleashClient = this.props.unleashClient();
    if (unleashClient.isEnabled(UnleashService.FLAGS.UPDATE_AVAILABILITY_TYPE)) {
      switch (availabilityType) {
        case AVAILABILITY_TYPES.ALL_LEARNERS:
          return 'all';
        case AVAILABILITY_TYPES.BY_ASSIGNMENT_DEACTIVATE_ACCOUNT_ON_MOVE:
        case AVAILABILITY_TYPES.BY_ASSIGNMENT_RETAIN_ACCOUNT_ON_MOVE:
          return 'selected';
        default:
          return null;
      }
    } else {
      if (assignable && explorable) {
        return 'all';
      } else if (assignable && !explorable) {
        return 'selected';
      } else {
        return null;
      }
    }
  };

  fetchLocationDetails = () => {
    this.setGlobal({
      isLoading: true,
    });
    let pBody = '';
    let postBody = {
      requestorId: USER_CONTEXT.personId,
      filterLocationId: SELECTED_LOCATION.locationId,
    };

    pBody = JSON.stringify(postBody);
    qrylocation(pBody)
      .then((data) => {
        data.locationServices = data.locationServices.sort((a, b) => {
          let first,
            second = null;
          // FOR "A"
          if (a.partnerName) {
            first = a.partnerName;
          } else {
            first = a.serviceName;
          }

          // FOR "B"
          if (b.partnerName) {
            second = b.partnerName;
          } else {
            second = b.serviceName;
          }

          return first.localeCompare(second);
        });
        this.setState({
          locationDetails: data,
          showEmptyDataSourceMessage: true,
        });
        this.setGlobal({
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ open: false });
        this.setGlobal({
          isLoading: false,
        });
      });
  };

  openModal = () => {
    this.fetchLocationDetails();
    this.setState({ open: true });
  };

  componentDidMount() {
    this.fetchLocationDetails();
  }

  closeModal = () => {
    this.setState({ open: false, locationDetails: null });
  };

  updateServiceLocationToggle = async (flag, flagValue, flags, serviceId, locationId, optionalUpdate = {}) => {
    try {
      const { isPropogateChangeToChildLocation } = this.state;
      this.setGlobal({
        isLoading: true,
      });
      let isAvailable = flags.available || flags.isAvailable;
      if (isAvailable || isAvailable == null) {
        isAvailable = true;
      }
      const payload = {
        requestorId: USER_CONTEXT.personId,
        locationIds: [locationId],
        serviceKey: serviceId,
        ssoEnabled: flags.ssoEnabled,
        automatedProvisioning: flags.automatedProvisioning,
        autoProvisionCapable: flags.autoProvisionCapable,
        assignable: flags.assignable,
        explorable: flags.explorable,
        available: isAvailable,
        // UPDATING REQUEST FLAG
        [flag]: flagValue,
        ...optionalUpdate,
      };

      if (!isAvailable && flags.endDate !== null) {
        payload['endDate'] = flags.endDate;
      }

      if (isPropogateChangeToChildLocation) {
        payload.locationIds = payload.locationIds.concat(
          this.state.locationDetails.locationChildren.map((location) => location.locationId)
        );
      }
      const response = await updateServiceLocationFlag(payload);
      if (response) {
        const updatedServices = this.state.locationDetails.locationServices.map((service) => {
          if (service.serviceId === serviceId) {
            if (flag === 'available') {
              flag = 'isAvailable'; //UPDATING THE KEY THAT'S BEING USED ON FE
              service['endDate'] = null;
            }
            service[flag] = flagValue;
          }
          return service;
        });
        this.setState({
          locationDetails: {
            ...this.state.locationDetails,
            locationServices: updatedServices,
          },
        });
      }
      this.setGlobal({
        isLoading: false,
      });
    } catch (e) {
      console.log(e);
      this.setGlobal({
        isLoading: false,
      });
    }
  };

  cloneFacilitySettingsToHU = async (services) => {
    this.toggleConfirmationBox({ isOpen: false });
    try {
      const locationChildIds = (this.state.locationDetails.locationChildren || []).map((location) => location.locationId);
      if (locationChildIds.length === 0) {
        return;
      }
      this.setGlobal({
        isLoading: true,
      });
      const promises = services.map((service) => {
        const payload = {
          requestorId: USER_CONTEXT.personId,
          locationIds: locationChildIds,
          serviceKey: service.serviceId,
          ssoEnabled: service.ssoEnabled,
          automatedProvisioning: service.automatedProvisioning,
          autoProvisionCapable: service.autoProvisionCapable,
          assignable: service.assignable,
          explorable: service.explorable,
        };
        return updateServiceLocationFlag(payload);
      });
      const responses = await Promise.all(promises);
      this.setGlobal({
        isLoading: false,
      });
    } catch (e) {
      this.setGlobal({
        isLoading: false,
      });
      console.log(e);
    }
  };

  /**
   * @name getAvailabilityText
   * @param {object} data
   */
  getAvailabilityText = (rowData) => {
    const { explorable, assignable, availabilityType } = rowData;
    const unleashClient = this.props.unleashClient();
    if (unleashClient.isEnabled(UnleashService.FLAGS.UPDATE_AVAILABILITY_TYPE)) {
      switch (availabilityType) {
        case AVAILABILITY_TYPES.ALL_LEARNERS:
          return 'Available to all learners.';
        case AVAILABILITY_TYPES.BY_ASSIGNMENT_DEACTIVATE_ACCOUNT_ON_MOVE:
        case AVAILABILITY_TYPES.BY_ASSIGNMENT_RETAIN_ACCOUNT_ON_MOVE:
          return 'Available by assignment.';
        default:
          return 'Hidden';
      }
    } else {
      if (explorable && assignable) {
        return 'Available and assignable to learners.';
      } else if (explorable) {
        return 'Available to all learners.';
      } else if (assignable) {
        return 'Available by assignment.';
      }
    }
  };

  /**
   * @name exportToCsv
   * @param {array} columns
   * @param {array} data
   * @desc Exports data to CSV based upon CSV blob from server.
   * @return {void}
   */
  exportToCsv = (columns, data, fileName, type) => {
    let csvContent = `data:text/csv;charset=utf-8,`;

    // HEADER
    csvContent += 'Partner Name, Offering, Auto Provision Capable, Automated Provisioning, Availability, Partner Status\r\n';
    data.forEach((row) => {
      // PARTNER NAME
      csvContent += '"' + (row.partnerName ? row.partnerName : row.serviceName) + '"' + ',';
      // SERVICE DISPLAY NAME
      csvContent += '"' + row.serviceDisplayName + '"' + ',';
      // AUTO PROVISIONING
      csvContent += '"' + (row.autoProvisionCapable || 'false').toString() + '"' + ',';
      // AUTOMATED PROVISIONING
      csvContent += '"' + (row.autoProvisionCapable ? row.automatedProvisioning || 'false' : 'N/A').toString() + '"' + ',';
      // AVAILABILITY
      if (type === 'core') {
        csvContent += '"' + this.getAvailabilityText(row) + '"' + ',';
      } else {
        const premiumOfferingAvail = this.getDefaultAvailability(row);
        csvContent += '" ';
        if (premiumOfferingAvail === 'all') {
          csvContent += 'Available to all learners.';
        } else if (premiumOfferingAvail === 'selected') {
          csvContent += 'Available by assignment.';
        }
        csvContent += ' "' + ',';
      }
      // PARTNER STATUS
      csvContent += '"' + (row.isAvailable == null || row.isAvailable) && row.endDate == null ? 'Active' : 'Suspended' + '"';
      // NEW LINE
      csvContent += '\r\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${this.state.locationDetails.locationDetails.locationName} - ${fileName}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  };

  DetailsModal = (props) => {
    return (
      <Dialog onClose={props.handleClose} open={props.open}>
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          {props.title}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Content Area: {props.contentArea}</Typography>
          <Typography gutterBottom>Single sign on: {props.sso}</Typography>
          <Typography gutterBottom>Date Modified: {props.modified}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  GetMainContent = () => {
    const filteredServices =
      this.state.locationDetails && this.state.locationDetails.locationServices
        ? this.state.locationDetails.locationServices.filter((l) => {
            return l.serviceName !== 'MongoDB' && l.serviceName !== 'Salesforce';
          })
        : [];
    return (
      <>
        <div style={{ padding: '1.5rem', width: '100%' }}>
          <ConfirmationModal {...this.state.confirmProps} />
          <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
            <p style={{ color: 'grey' }}>Location Id: {this.state.locationDetails.locationDetails.locationId}</p>
            <PermissionControl
              permissionNames={['Edit_Service_Settings']}
              forceRestriction={!this.global.cbacConfigFlag && !this.global.isSuperAdmin}
            >
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  this.toggleConfirmationBox({
                    isOpen: true,
                    onClose: () => this.toggleConfirmationBox({ isOpen: false }),
                    onContinue: () => {
                      this.cloneFacilitySettingsToHU(filteredServices);
                    },
                    continueText: 'Yes',
                    cancelText: 'No',
                    heading: 'Caution!',
                    description:
                      'Are you sure you want to push these changes to all housing units? Once you have made the changes you won’t be able to revert them in bulk.',
                    continueButtonStyles: null,
                    cancelButtonStyles: null,
                    alignActionButtons: null,
                    title: null,
                  });
                }}
              >
                Force Current Facility Settings To All Housing Units
              </Button>
            </PermissionControl>
          </Box>
        </div>

        <div style={{ padding: '0 1.5rem 1.5rem 1.5rem', width: '100%' }}>
          <Datatable
            title={`Core Offerings`}
            data={filteredServices.filter((item) => item.coreService)}
            columns={this.coreServiceColumns}
            icons={{
              Search: SearchIcon,
              ResetSearch: CloseIcon,
              FirstPage: FirstPageIcon,
              LastPage: LastPageIcon,
              NextPage: NavigateNextIcon,
              PreviousPage: NavigateBeforeIcon,
              Filter: FilterListIcon,
              Sort: SortIcon,
              SortArrow: ArrowDownwardIcon,
              Export: SaveAltIcon,
              Edit: EditIcon,
              User: <UserIcon />,
            }}
            options={{
              pageSize: 10,
              selection: false,
              showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
              sorting: false,
              exportButton: true,
              exportAllData: true,
              rowStyle: (rowData) => {
                const isActive = (rowData.isAvailable == null || rowData.isAvailable) && rowData.endDate == null;
                return !isActive
                  ? {
                      color: '#80808b',
                      pointerEvents: 'none',
                    }
                  : null;
              },
              exportCsv: (columns, data) => this.exportToCsv(columns, data, 'Core Offering', 'core'),
            }}
          />
          <div style={{ marginTop: '1.5rem' }} />
          <Datatable
            title={`Premium Offerings`}
            data={filteredServices.filter((item) => !item.coreService)}
            columns={this.premiumServiceColumns}
            icons={{
              Search: SearchIcon,
              ResetSearch: CloseIcon,
              FirstPage: FirstPageIcon,
              LastPage: LastPageIcon,
              NextPage: NavigateNextIcon,
              PreviousPage: NavigateBeforeIcon,
              Filter: FilterListIcon,
              Sort: SortIcon,
              SortArrow: ArrowDownwardIcon,
              Export: SaveAltIcon,
              Edit: EditIcon,
              User: <UserIcon />,
            }}
            options={{
              pageSize: 20,
              selection: false,
              sorting: false,
              showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
              exportButton: true,
              exportAllData: true,
              rowStyle: (rowData) => {
                const isActive = (rowData.isAvailable == null || rowData.isAvailable) && rowData.endDate == null;
                return !isActive
                  ? {
                      color: '#80808b',
                      pointerEvents: 'none',
                    }
                  : null;
              },
              exportCsv: (columns, data) => this.exportToCsv(columns, data, 'Premium Offering', 'premium'),
            }}
          />
          <div style={{ marginTop: '1.5rem' }} />
          {/*
                            LOCATION CHILDREN
                            */}
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h6">Location Children</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {this.state.locationDetails.locationChildren ? (
                <Datatable
                  style={{ width: '100%' }}
                  title={`Location Children`}
                  data={this.state.locationDetails.locationChildren}
                  columns={[
                    {
                      field: 'locationName',
                      title: 'Location Name',
                    },
                    {
                      field: 'locationId',
                      title: 'Location ID',
                    },
                    {
                      field: 'locationTypeKey',
                      title: 'Location Type',
                    },
                  ]}
                  icons={{
                    Search: SearchIcon,
                    ResetSearch: CloseIcon,
                    FirstPage: FirstPageIcon,
                    LastPage: LastPageIcon,
                    NextPage: NavigateNextIcon,
                    PreviousPage: NavigateBeforeIcon,
                    Filter: FilterListIcon,
                    Sort: SortIcon,
                    SortArrow: ArrowDownwardIcon,
                    Export: SaveAltIcon,
                    Edit: EditIcon,
                    User: <UserIcon />,
                  }}
                  options={{
                    pageSize: 20,
                    selection: false,
                    sorting: true,
                    showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
                    exportButton: true,
                    exportAllData: true,
                  }}
                />
              ) : (
                <h6>This Location has no Services Associated</h6>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </>
    );
  };

  render() {
    if (this.props.inLine && this.state.locationDetails) {
      return (
        <>
          <this.DetailsModal {...this.state.detailsModal} />
          <this.GetMainContent />
        </>
      );
    }
    return (
      <div style={{ height: '100%', width: '100%', backgroundColor: 'white', display: 'flex', alignItems: 'center' }}>
        {/* {
  "$id": "https://example.com/arrays.schema.json",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "QryLocationResponse",
  "type": "object",
  "properties": {
    "statusCode": {
      "type": "integer",
      "description": "A return status code."
    },
    "statusMessage": {
      "type": "string",
      "description": "A return status message."
    },
    "locationDetails": {
      "type": "object",
      "properties": {
        "locationName": {
          "type": "string",
          "description": "Name of location"
        },
        "locationId": {
          "type": "integer",
          "description": "The locationId"
        },
        "locationTypeKey": {
            "type": "integer",
            "description": "The locationTypeKey"
        }
      }
    },
    "locationServices": {
      "type": "array",
      "items": { "$ref": "#/definitions/locationServices" }
    },
    "locationAttributes": {
      "type": "array",
      "items": { "$ref": "#/definitions/locationAttributes" }
    },
    "locationChildren": {
      "type": "array",
      "items": { "$ref": "#/definitions/locationChildren" }
    }
  },
  "definitions": {
  "locationServices": {
      "type": "object",
      "properties": {
        "serviceName": {
          "type": "string",
          "description": "name of service"
        },
        "serviceDiplayName": {
          "type": "string",
          "description": "display name of service"
        },
        "contentArea": {
          "type": "string",
          "description": "content area name"
        },
        "ssoEnabled": {
          "type": "boolean",
          "description": "location ssoEnabled value"
        },
        "automatedProvisioning": {
          "type": "boolean",
          "description": "location automatedProvisioning value"
        },
        "externalRefs": {
          "type": "string",
          "description": "external_group jsonb value"
        }
      }
    },
    "locationAttributes": {
      "type": "object",
      "properties": {
        "attributeName": {
          "type": "string",
          "description": "The attributeName"
        },
        "attributeValue": {
          "type": "string",
          "description": "The attributeValue"
        }
      }
    },
    "locationChildren": {
      "type": "object",
      "properties": {
        "locationName": {
          "type": "string",
          "description": "Name of location"
        },
        "locationId": {
          "type": "integer",
          "description": "The locationId"
        },
        "locationTypeKey": {
            "type": "integer",
            "description": "The locationTypeKey"
        }
      }
    }
  }
} */}
        <div style={{ width: 1, height: '90%', backgroundColor: 'lightgrey' }}></div>
        <ButtonBase onClick={this.openModal}>
          {this.props.children ? this.props.children : <MoreVertIcon style={{ fontSize: 30, color: 'gray' }} />}
        </ButtonBase>

        <Dialog open={this.state.open} onClose={this.closeModal} maxWidth="md">
          {this.state.locationDetails ? (
            <div style={{}}>
              <Toolbar style={{ color: 'white', backgroundColor: AdminTheme.palette.background.blue }}>
                <IconButton style={{ color: 'white' }} onClick={this.closeModal}>
                  <CloseIcon />
                </IconButton>
                <Typography style={{ color: 'white' }} variant="h5" noWrap>
                  {this.state.locationDetails.locationDetails.locationName}
                </Typography>
              </Toolbar>
              <this.GetMainContent />
            </div>
          ) : (
            <h4>LOADING ... </h4>
          )}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(withUnleashClient(LocationDetailsModal));
