const TOTUSERS = [
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 24.42
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "African American Books",
    "resource_id": 7327,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 31.17
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 29.92
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 21.83
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 1.66
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 40.16
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1c: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-Reading for Writers",
    "resource_id": 6415,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "I AM NOT YOUR NEGRO",
    "resource_id": 7336,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 8.08
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 1.08
  },
  {
    "person_key": 170968,
    "user_name": "User170968",
    "first_name": "User",
    "last_name": 170968,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 0.92
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 30
  },
  {
    "person_key": 161009,
    "user_name": "User161009",
    "first_name": "User",
    "last_name": 161009,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 1.67
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "All Kinds of Minds",
    "resource_id": 4947,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 31.92
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 3.5
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 1 Solving Equations",
    "resource_id": 7905,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 61
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 107.59
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 54
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 36.66
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 2 - Developing Your Coaching Objectives",
    "resource_id": 6517,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 17.67
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 3.25
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 93.08
  },
  {
    "person_key": 170975,
    "user_name": "User170975",
    "first_name": "User",
    "last_name": 170975,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 2.08
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 2.66
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 38.92
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 51.08
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 12.25
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 0.16
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 47.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 2.75
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 34.75
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 1.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 29.75
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 9.42
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 61.25
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 182.75
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 1.83
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 90.5
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 29.92
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 5
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 30.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 2.25
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 3
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "Book Club Titles",
    "resource_id": 7324,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 3.67
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 2: The Building Blocks of Language and The Narrative Essay",
    "resource_id": 6416,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 3.08
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 30.25
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "When Sleeping Dogs Awaken Audiobook",
    "resource_id": 5827,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 30
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 15.5
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-16",
    "time_spent_min": 2.75
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Six:  Outlining Your Persuasive Speech: Chapters 24-26",
    "resource_id": 6835,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 29.91
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 14.75
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 1.25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 2.5
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 1.58
  },
  {
    "person_key": 170878,
    "user_name": "User170878",
    "first_name": "User",
    "last_name": 170878,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 12.91
  },
  {
    "person_key": 159492,
    "user_name": "User159492",
    "first_name": "User",
    "last_name": 159492,
    "doc_id": 12345,
    "learning_object": "Session One: WHAT IS ANGER?",
    "resource_id": 7668,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 1.25
  },
  {
    "person_key": 164909,
    "user_name": "User164909",
    "first_name": "User",
    "last_name": 164909,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7A",
    "location_id": 1697455,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Considerations for the Informative Speech: Chapters 14-16",
    "resource_id": 6833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 2.08
  },
  {
    "person_key": 170860,
    "user_name": "User170860",
    "first_name": "User",
    "last_name": 170860,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 12.75
  },
  {
    "person_key": 170963,
    "user_name": "User170963",
    "first_name": "User",
    "last_name": 170963,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 11.92
  },
  {
    "person_key": 170869,
    "user_name": "User170869",
    "first_name": "User",
    "last_name": 170869,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 13.5
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 5.75
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.160004
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 193.58
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.410004
  },
  {
    "person_key": 170880,
    "user_name": "User170880",
    "first_name": "User",
    "last_name": 170880,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 9
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Part Two: Writing Your Informative Speech",
    "resource_id": 6834,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 60.17
  },
  {
    "person_key": 170882,
    "user_name": "User170882",
    "first_name": "User",
    "last_name": 170882,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 10.08
  },
  {
    "person_key": 170969,
    "user_name": "User170969",
    "first_name": "User",
    "last_name": 170969,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 16.75
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 30.25
  },
  {
    "person_key": 170863,
    "user_name": "User170863",
    "first_name": "User",
    "last_name": 170863,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 14
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 29.92
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 6640,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.09
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 26.75
  },
  {
    "person_key": 170866,
    "user_name": "User170866",
    "first_name": "User",
    "last_name": 170866,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 15.34
  },
  {
    "person_key": 170960,
    "user_name": "User170960",
    "first_name": "User",
    "last_name": 170960,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 11
  },
  {
    "person_key": 170973,
    "user_name": "User170973",
    "first_name": "User",
    "last_name": 170973,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 14.59
  },
  {
    "person_key": 170858,
    "user_name": "User170858",
    "first_name": "User",
    "last_name": 170858,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 13.17
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 3.25
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.25
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.75
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 15.91
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 60.08
  },
  {
    "person_key": 170873,
    "user_name": "User170873",
    "first_name": "User",
    "last_name": 170873,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 13.58
  },
  {
    "person_key": 170966,
    "user_name": "User170966",
    "first_name": "User",
    "last_name": 170966,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 11.16
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 19.5
  },
  {
    "person_key": 170980,
    "user_name": "User170980",
    "first_name": "User",
    "last_name": 170980,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 12.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 170862,
    "user_name": "User170862",
    "first_name": "User",
    "last_name": 170862,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 12.5
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 1
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 85.75
  },
  {
    "person_key": 170968,
    "user_name": "User170968",
    "first_name": "User",
    "last_name": 170968,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 4.42
  },
  {
    "person_key": 170871,
    "user_name": "User170871",
    "first_name": "User",
    "last_name": 170871,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 12.5
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 20.84
  },
  {
    "person_key": 170982,
    "user_name": "User170982",
    "first_name": "User",
    "last_name": 170982,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 14.08
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 109
  },
  {
    "person_key": 159492,
    "user_name": "User159492",
    "first_name": "User",
    "last_name": 159492,
    "doc_id": 12345,
    "learning_object": "Relapse Prevention Videos",
    "resource_id": 8385,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 4.67
  },
  {
    "person_key": 168062,
    "user_name": "User168062",
    "first_name": "User",
    "last_name": 168062,
    "doc_id": 12345,
    "learning_object": "50 Cent",
    "resource_id": 6994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 4.75
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 8.25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 3.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 1.92
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 105.17
  },
  {
    "person_key": 170881,
    "user_name": "User170881",
    "first_name": "User",
    "last_name": 170881,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 8.08
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1c: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-Reading for Writers",
    "resource_id": 6415,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 1
  },
  {
    "person_key": 170981,
    "user_name": "User170981",
    "first_name": "User",
    "last_name": 170981,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 7.25
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 25.08
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 4.84
  },
  {
    "person_key": 170978,
    "user_name": "User170978",
    "first_name": "User",
    "last_name": 170978,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 16.58
  },
  {
    "person_key": 170983,
    "user_name": "User170983",
    "first_name": "User",
    "last_name": 170983,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 9.75
  },
  {
    "person_key": 170870,
    "user_name": "User170870",
    "first_name": "User",
    "last_name": 170870,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 4.08
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 12.58
  },
  {
    "person_key": 170872,
    "user_name": "User170872",
    "first_name": "User",
    "last_name": 170872,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 13.25
  },
  {
    "person_key": 159492,
    "user_name": "User159492",
    "first_name": "User",
    "last_name": 159492,
    "doc_id": 12345,
    "learning_object": "Session Two: THE NEUROSCIENCE OF ANGER",
    "resource_id": 7672,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 3.42
  },
  {
    "person_key": 170877,
    "user_name": "User170877",
    "first_name": "User",
    "last_name": 170877,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 18.42
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.09
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 86.67
  },
  {
    "person_key": 170976,
    "user_name": "User170976",
    "first_name": "User",
    "last_name": 170976,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 10.08
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 6.5
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 71.33
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 2: The Building Blocks of Language and The Narrative Essay",
    "resource_id": 6416,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 29.92
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 7.58
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 2 - Developing Your Coaching Objectives",
    "resource_id": 6517,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 1.08
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 155.08
  },
  {
    "person_key": 170967,
    "user_name": "User170967",
    "first_name": "User",
    "last_name": 170967,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 13
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 120.67
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 2.67
  },
  {
    "person_key": 170975,
    "user_name": "User170975",
    "first_name": "User",
    "last_name": 170975,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 6.59
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 45.17
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170864,
    "user_name": "User170864",
    "first_name": "User",
    "last_name": 170864,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 11.09
  },
  {
    "person_key": 170876,
    "user_name": "User170876",
    "first_name": "User",
    "last_name": 170876,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 17.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 2.25
  },
  {
    "person_key": 170972,
    "user_name": "User170972",
    "first_name": "User",
    "last_name": 170972,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-15",
    "time_spent_min": 14
  },
  {
    "person_key": 170882,
    "user_name": "User170882",
    "first_name": "User",
    "last_name": 170882,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170863,
    "user_name": "User170863",
    "first_name": "User",
    "last_name": 170863,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.58
  },
  {
    "person_key": 170861,
    "user_name": "User170861",
    "first_name": "User",
    "last_name": 170861,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.830002
  },
  {
    "person_key": 170975,
    "user_name": "User170975",
    "first_name": "User",
    "last_name": 170975,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 36.75
  },
  {
    "person_key": 170971,
    "user_name": "User170971",
    "first_name": "User",
    "last_name": 170971,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 21.67
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "Old Testament",
    "resource_id": 7385,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 1.33
  },
  {
    "person_key": 170941,
    "user_name": "User170941",
    "first_name": "User",
    "last_name": 170941,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 10.25
  },
  {
    "person_key": 170880,
    "user_name": "User170880",
    "first_name": "User",
    "last_name": 170880,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 12.17
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 36.59
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 29.92
  },
  {
    "person_key": 170873,
    "user_name": "User170873",
    "first_name": "User",
    "last_name": 170873,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 18.25
  },
  {
    "person_key": 170964,
    "user_name": "User170964",
    "first_name": "User",
    "last_name": 170964,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 20.75
  },
  {
    "person_key": 170944,
    "user_name": "User170944",
    "first_name": "User",
    "last_name": 170944,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 5.5
  },
  {
    "person_key": 170878,
    "user_name": "User170878",
    "first_name": "User",
    "last_name": 170878,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170859,
    "user_name": "User170859",
    "first_name": "User",
    "last_name": 170859,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170874,
    "user_name": "User170874",
    "first_name": "User",
    "last_name": 170874,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.58
  },
  {
    "person_key": 170964,
    "user_name": "User170964",
    "first_name": "User",
    "last_name": 170964,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.58
  },
  {
    "person_key": 170858,
    "user_name": "User170858",
    "first_name": "User",
    "last_name": 170858,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156647,
    "user_name": "User156647",
    "first_name": "User",
    "last_name": 156647,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 46.58
  },
  {
    "person_key": 374,
    "user_name": "User374",
    "first_name": "User",
    "last_name": 374,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 170973,
    "user_name": "User170973",
    "first_name": "User",
    "last_name": 170973,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170983,
    "user_name": "User170983",
    "first_name": "User",
    "last_name": 170983,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 23.5
  },
  {
    "person_key": 170866,
    "user_name": "User170866",
    "first_name": "User",
    "last_name": 170866,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.83
  },
  {
    "person_key": 170881,
    "user_name": "User170881",
    "first_name": "User",
    "last_name": 170881,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.75
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 29.08
  },
  {
    "person_key": 170868,
    "user_name": "User170868",
    "first_name": "User",
    "last_name": 170868,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.67
  },
  {
    "person_key": 170871,
    "user_name": "User170871",
    "first_name": "User",
    "last_name": 170871,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 15.75
  },
  {
    "person_key": 170873,
    "user_name": "User170873",
    "first_name": "User",
    "last_name": 170873,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 1.08
  },
  {
    "person_key": 171009,
    "user_name": "User171009",
    "first_name": "User",
    "last_name": 171009,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 3.75
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 170965,
    "user_name": "User170965",
    "first_name": "User",
    "last_name": 170965,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170979,
    "user_name": "User170979",
    "first_name": "User",
    "last_name": 170979,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 20.08
  },
  {
    "person_key": 170967,
    "user_name": "User170967",
    "first_name": "User",
    "last_name": 170967,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 18.83
  },
  {
    "person_key": 170971,
    "user_name": "User170971",
    "first_name": "User",
    "last_name": 170971,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170982,
    "user_name": "User170982",
    "first_name": "User",
    "last_name": 170982,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 25.17
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 30.75
  },
  {
    "person_key": 170973,
    "user_name": "User170973",
    "first_name": "User",
    "last_name": 170973,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 21.33
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 46.75
  },
  {
    "person_key": 170978,
    "user_name": "User170978",
    "first_name": "User",
    "last_name": 170978,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 61.59
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Part Two: Writing Your Informative Speech",
    "resource_id": 6834,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 72.92
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 148.83
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 124.33
  },
  {
    "person_key": 170982,
    "user_name": "User170982",
    "first_name": "User",
    "last_name": 170982,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170868,
    "user_name": "User170868",
    "first_name": "User",
    "last_name": 170868,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 5.67
  },
  {
    "person_key": 170975,
    "user_name": "User170975",
    "first_name": "User",
    "last_name": 170975,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 8.83
  },
  {
    "person_key": 170858,
    "user_name": "User170858",
    "first_name": "User",
    "last_name": 170858,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 170983,
    "user_name": "User170983",
    "first_name": "User",
    "last_name": 170983,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170948,
    "user_name": "User170948",
    "first_name": "User",
    "last_name": 170948,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 2.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 53.84
  },
  {
    "person_key": 170976,
    "user_name": "User170976",
    "first_name": "User",
    "last_name": 170976,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170872,
    "user_name": "User170872",
    "first_name": "User",
    "last_name": 170872,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 18.58
  },
  {
    "person_key": 170984,
    "user_name": "User170984",
    "first_name": "User",
    "last_name": 170984,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170864,
    "user_name": "User170864",
    "first_name": "User",
    "last_name": 170864,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 19.58
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 26.42
  },
  {
    "person_key": 170878,
    "user_name": "User170878",
    "first_name": "User",
    "last_name": 170878,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 19.67
  },
  {
    "person_key": 170881,
    "user_name": "User170881",
    "first_name": "User",
    "last_name": 170881,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 22.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Considerations for the Informative Speech: Chapters 14-16",
    "resource_id": 6833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 32.25
  },
  {
    "person_key": 170940,
    "user_name": "User170940",
    "first_name": "User",
    "last_name": 170940,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 2.92
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 1
  },
  {
    "person_key": 170960,
    "user_name": "User170960",
    "first_name": "User",
    "last_name": 170960,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 19.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 170867,
    "user_name": "User170867",
    "first_name": "User",
    "last_name": 170867,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 6.92
  },
  {
    "person_key": 170984,
    "user_name": "User170984",
    "first_name": "User",
    "last_name": 170984,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 8.75
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 6 - Motivation in Sport",
    "resource_id": 7065,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 18.92
  },
  {
    "person_key": 170969,
    "user_name": "User170969",
    "first_name": "User",
    "last_name": 170969,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 25.83
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 1.83
  },
  {
    "person_key": 170947,
    "user_name": "User170947",
    "first_name": "User",
    "last_name": 170947,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 6.25
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 5
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 170872,
    "user_name": "User170872",
    "first_name": "User",
    "last_name": 170872,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Friday",
    "resource_id": 5655,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170974,
    "user_name": "User170974",
    "first_name": "User",
    "last_name": 170974,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 40.83
  },
  {
    "person_key": 170966,
    "user_name": "User170966",
    "first_name": "User",
    "last_name": 170966,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 25.17
  },
  {
    "person_key": 170858,
    "user_name": "User170858",
    "first_name": "User",
    "last_name": 170858,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 18
  },
  {
    "person_key": 170869,
    "user_name": "User170869",
    "first_name": "User",
    "last_name": 170869,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 16.34
  },
  {
    "person_key": 170860,
    "user_name": "User170860",
    "first_name": "User",
    "last_name": 170860,
    "doc_id": 12345,
    "learning_object": "Part 2 - Conflict Resolution Exam Friday",
    "resource_id": 5642,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170959,
    "user_name": "User170959",
    "first_name": "User",
    "last_name": 170959,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 8.09
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 84.5
  },
  {
    "person_key": 170960,
    "user_name": "User170960",
    "first_name": "User",
    "last_name": 170960,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170976,
    "user_name": "User170976",
    "first_name": "User",
    "last_name": 170976,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 30.25
  },
  {
    "person_key": 170876,
    "user_name": "User170876",
    "first_name": "User",
    "last_name": 170876,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 14
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 30
  },
  {
    "person_key": 170937,
    "user_name": "User170937",
    "first_name": "User",
    "last_name": 170937,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 6.67
  },
  {
    "person_key": 170870,
    "user_name": "User170870",
    "first_name": "User",
    "last_name": 170870,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 31.42
  },
  {
    "person_key": 170963,
    "user_name": "User170963",
    "first_name": "User",
    "last_name": 170963,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 34.5
  },
  {
    "person_key": 170979,
    "user_name": "User170979",
    "first_name": "User",
    "last_name": 170979,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.5
  },
  {
    "person_key": 170879,
    "user_name": "User170879",
    "first_name": "User",
    "last_name": 170879,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 33.42
  },
  {
    "person_key": 170963,
    "user_name": "User170963",
    "first_name": "User",
    "last_name": 170963,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.5
  },
  {
    "person_key": 170980,
    "user_name": "User170980",
    "first_name": "User",
    "last_name": 170980,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 27
  },
  {
    "person_key": 170877,
    "user_name": "User170877",
    "first_name": "User",
    "last_name": 170877,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 15
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 12.16
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 3.92
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.58
  },
  {
    "person_key": 170972,
    "user_name": "User170972",
    "first_name": "User",
    "last_name": 170972,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 27.25
  },
  {
    "person_key": 170865,
    "user_name": "User170865",
    "first_name": "User",
    "last_name": 170865,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 5.33
  },
  {
    "person_key": 170970,
    "user_name": "User170970",
    "first_name": "User",
    "last_name": 170970,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 17.25
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 126
  },
  {
    "person_key": 170961,
    "user_name": "User170961",
    "first_name": "User",
    "last_name": 170961,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 35.92
  },
  {
    "person_key": 170956,
    "user_name": "User170956",
    "first_name": "User",
    "last_name": 170956,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 13.58
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 12.16
  },
  {
    "person_key": 170978,
    "user_name": "User170978",
    "first_name": "User",
    "last_name": 170978,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 21
  },
  {
    "person_key": 170862,
    "user_name": "User170862",
    "first_name": "User",
    "last_name": 170862,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 25.67
  },
  {
    "person_key": 170969,
    "user_name": "User170969",
    "first_name": "User",
    "last_name": 170969,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 141.66
  },
  {
    "person_key": 163074,
    "user_name": "User163074",
    "first_name": "User",
    "last_name": 163074,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8091,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 27.34
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 170866,
    "user_name": "User170866",
    "first_name": "User",
    "last_name": 170866,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 18.08
  },
  {
    "person_key": 170968,
    "user_name": "User170968",
    "first_name": "User",
    "last_name": 170968,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 11.33
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 90.17
  },
  {
    "person_key": 170882,
    "user_name": "User170882",
    "first_name": "User",
    "last_name": 170882,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 19
  },
  {
    "person_key": 170859,
    "user_name": "User170859",
    "first_name": "User",
    "last_name": 170859,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 11
  },
  {
    "person_key": 170872,
    "user_name": "User170872",
    "first_name": "User",
    "last_name": 170872,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Friday",
    "resource_id": 5729,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170869,
    "user_name": "User170869",
    "first_name": "User",
    "last_name": 170869,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 16.83
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 41.5
  },
  {
    "person_key": 170860,
    "user_name": "User170860",
    "first_name": "User",
    "last_name": 170860,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 16
  },
  {
    "person_key": 170981,
    "user_name": "User170981",
    "first_name": "User",
    "last_name": 170981,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170863,
    "user_name": "User170863",
    "first_name": "User",
    "last_name": 170863,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 22.08
  },
  {
    "person_key": 170977,
    "user_name": "User170977",
    "first_name": "User",
    "last_name": 170977,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170977,
    "user_name": "User170977",
    "first_name": "User",
    "last_name": 170977,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 32.42
  },
  {
    "person_key": 170874,
    "user_name": "User170874",
    "first_name": "User",
    "last_name": 170874,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 14.75
  },
  {
    "person_key": 170965,
    "user_name": "User170965",
    "first_name": "User",
    "last_name": 170965,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 19.08
  },
  {
    "person_key": 156445,
    "user_name": "User156445",
    "first_name": "User",
    "last_name": 156445,
    "doc_id": 12345,
    "learning_object": "Hindu Artharva Veda",
    "resource_id": 6167,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 29.92
  },
  {
    "person_key": 170966,
    "user_name": "User170966",
    "first_name": "User",
    "last_name": 170966,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170861,
    "user_name": "User170861",
    "first_name": "User",
    "last_name": 170861,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 16.5
  },
  {
    "person_key": 170974,
    "user_name": "User170974",
    "first_name": "User",
    "last_name": 170974,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Friday",
    "resource_id": 5655,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170963,
    "user_name": "User170963",
    "first_name": "User",
    "last_name": 170963,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Friday",
    "resource_id": 5729,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170974,
    "user_name": "User170974",
    "first_name": "User",
    "last_name": 170974,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Friday",
    "resource_id": 5729,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168536,
    "user_name": "User168536",
    "first_name": "User",
    "last_name": 168536,
    "doc_id": 12345,
    "learning_object": "Advocacy Session 1",
    "resource_id": 8428,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 0.67
  },
  {
    "person_key": 170981,
    "user_name": "User170981",
    "first_name": "User",
    "last_name": 170981,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 27
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 31
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 3.5
  },
  {
    "person_key": 170946,
    "user_name": "User170946",
    "first_name": "User",
    "last_name": 170946,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-14",
    "time_spent_min": 5.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.67
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Literacy Reading Formative Assessment",
    "resource_id": 9451,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 12
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 1",
    "resource_id": 9819,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 109
  },
  {
    "person_key": 160354,
    "user_name": "User160354",
    "first_name": "User",
    "last_name": 160354,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "02. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5026,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.66
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 18.5
  },
  {
    "person_key": 170935,
    "user_name": "User170935",
    "first_name": "User",
    "last_name": 170935,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "07. Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 5198,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.5
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 42
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Negin Farsad: A highly scientific taxonomy of haters",
    "resource_id": 7569,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 10
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Student Book - L7 Percent (CS) - Quiz",
    "resource_id": 10119,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 15
  },
  {
    "person_key": 160502,
    "user_name": "User160502",
    "first_name": "User",
    "last_name": 160502,
    "doc_id": 12345,
    "learning_object": "Latif Nasser: You have no idea where camels really come from",
    "resource_id": 7614,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 5.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.41
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Lisa Bu: How books can open your mind",
    "resource_id": 5183,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.59
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 63.33
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "10. Alberto Cairo: There are no scraps of men",
    "resource_id": 5230,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "T. Morgan Dixon and Vanessa Garrison: When Black women walk, things change",
    "resource_id": 7638,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "12. Jill Tarter: Join the SETI search",
    "resource_id": 5214,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.58
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Tomás Saraceno: Would you live in a floating city in the sky?",
    "resource_id": 7572,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 7.33
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 30
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 7.25
  },
  {
    "person_key": 160354,
    "user_name": "User160354",
    "first_name": "User",
    "last_name": 160354,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 9.5
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "02. Elizabeth Gilbert: Your elusive creative genius",
    "resource_id": 5182,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 11
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Stella Young: I’m not your inspiration, thank you very much",
    "resource_id": 5033,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.67
  },
  {
    "person_key": 170941,
    "user_name": "User170941",
    "first_name": "User",
    "last_name": 170941,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 21.33
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 6.75
  },
  {
    "person_key": 160354,
    "user_name": "User160354",
    "first_name": "User",
    "last_name": 160354,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 4.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Camille Seaman: Haunting photos of polar ice",
    "resource_id": 7679,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.25
  },
  {
    "person_key": 166912,
    "user_name": "User166912",
    "first_name": "User",
    "last_name": 166912,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 9.25
  },
  {
    "person_key": 170936,
    "user_name": "User170936",
    "first_name": "User",
    "last_name": 170936,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 4.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "12. Sarah Jones: A one-woman global village",
    "resource_id": 5037,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 3.92
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "08. Chip Conley: Measuring what makes life worthwhile",
    "resource_id": 5086,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 20.75
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "01. Paul Piff: Does money make you mean?",
    "resource_id": 5104,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 18.92
  },
  {
    "person_key": 168529,
    "user_name": "User168529",
    "first_name": "User",
    "last_name": 168529,
    "doc_id": 12345,
    "learning_object": "02. Russell Foster: Why do we sleep?",
    "resource_id": 4979,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ancora Psychiatric Hospital",
    "location_id": 1697442,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 21.17
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "11. Aziz Abu Sarah: For more tolerance, we need more ... tourism?",
    "resource_id": 5036,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 6
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "10. iO Tillett Wright: Fifty shades of gay",
    "resource_id": 5012,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Amy Tan: Where does creativity hide?",
    "resource_id": 5191,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Faith Jegede: What I've learned from my autistic brothers",
    "resource_id": 5062,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Johanna Blakley: Social media and the end of gender",
    "resource_id": 5010,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "02. Alice Dreger: Is anatomy destiny?",
    "resource_id": 5004,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.25
  },
  {
    "person_key": 170956,
    "user_name": "User170956",
    "first_name": "User",
    "last_name": 170956,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 23.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 5025,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.75
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: Love, no matter what",
    "resource_id": 7620,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.34
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Mundano: Trash cart superheroes",
    "resource_id": 7551,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 7
  },
  {
    "person_key": 162288,
    "user_name": "User162288",
    "first_name": "User",
    "last_name": 162288,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 4.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "10. Sally Kohn: Let’s try emotional correctness",
    "resource_id": 5035,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.92
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 86.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Susan Colantuono: The career advice you probably didn’t get",
    "resource_id": 5136,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.92
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Tasos Frantzolas: Everything you hear on film is a lie",
    "resource_id": 7565,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 17.83
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 4.58
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "10. Naomi Oreskes: Why we should trust scientists",
    "resource_id": 5212,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 5007,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 30.08
  },
  {
    "person_key": 164196,
    "user_name": "User164196",
    "first_name": "User",
    "last_name": 164196,
    "doc_id": 12345,
    "learning_object": "10. Edward Tenner: Unintended consequences",
    "resource_id": 5250,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "14. Melissa Marshall: Talk nerdy to me",
    "resource_id": 5139,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.17
  },
  {
    "person_key": 170950,
    "user_name": "User170950",
    "first_name": "User",
    "last_name": 170950,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 42.58
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Brené Brown: Listening to shame",
    "resource_id": 5014,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.59
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Kevin Breel: Confessions of a depressed comic",
    "resource_id": 7534,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 4.59
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Jamie Oliver: Teach every child about food",
    "resource_id": 4988,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 5018,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "12. Daniel Kahneman: The riddle of experience vs. memory",
    "resource_id": 5090,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.92
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 7775,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.83
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 49.25
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Titus Kaphar: Can art amend history?",
    "resource_id": 7573,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 10.58
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "Psychology of Incarceration Post Test",
    "resource_id": 8085,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "07. Aimee Mullins: The opportunity of adversity",
    "resource_id": 5227,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.84
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 7552,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 9.5
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 15.5
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "08. Johanna Blakley: Social media and the end of gender",
    "resource_id": 5010,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.92
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 12.42
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Considerations for the Informative Speech: Chapters 14-16",
    "resource_id": 6833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.42
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Julian Treasure: How to speak so that people want to listen",
    "resource_id": 7765,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 19.17
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "10. Naomi Oreskes: Why we should trust scientists",
    "resource_id": 5212,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 20.83
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.92
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Michael Sandel: The lost art of democratic debate",
    "resource_id": 7654,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 16.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "01. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5221,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "J.J. Abrams: The mystery box",
    "resource_id": 7606,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 3.58
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "02. Elizabeth Gilbert: Your elusive creative genius",
    "resource_id": 5182,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.41
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "10. Edward Tenner: Unintended consequences",
    "resource_id": 5250,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 30
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Safwat Saleem: Why I keep speaking up, even when people mock my accent",
    "resource_id": 7571,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 3.17
  },
  {
    "person_key": 160354,
    "user_name": "User160354",
    "first_name": "User",
    "last_name": 160354,
    "doc_id": 12345,
    "learning_object": "09. George Monbiot: For more wonder, rewild the world",
    "resource_id": 5122,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 7.67
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.580002
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "10. Daniel H. Cohen: For argument’s sake",
    "resource_id": 5088,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 13
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Tasos Frantzolas: Everything you hear on film is a lie",
    "resource_id": 7565,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 18.25
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "12. Colin Grant: How our stories cross over",
    "resource_id": 5232,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.42
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 7552,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 5.08
  },
  {
    "person_key": 168343,
    "user_name": "User168343",
    "first_name": "User",
    "last_name": 168343,
    "doc_id": 12345,
    "learning_object": "11. Norman Spack: How I help transgender teens become who they want to be",
    "resource_id": 5013,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 14.42
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 10.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "13. Kare Anderson: Be an opportunity maker",
    "resource_id": 5138,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 9.42
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "08. Johanna Blakley: Social media and the end of gender",
    "resource_id": 5010,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Alicia Garza, Patrisse Cullors and Opal Tometi: An interview with the founders of Black Lives Matter",
    "resource_id": 7640,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.75
  },
  {
    "person_key": 160502,
    "user_name": "User160502",
    "first_name": "User",
    "last_name": 160502,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 6.58
  },
  {
    "person_key": 160354,
    "user_name": "User160354",
    "first_name": "User",
    "last_name": 160354,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 5
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 5
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Nalini Nadkarni: Life science in prison",
    "resource_id": 7658,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 7
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "02. Alice Dreger: Is anatomy destiny?",
    "resource_id": 5004,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 3.17
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.08
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.669998
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Adam Driver: My journey from Marine to actor",
    "resource_id": 7559,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 19.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "01. Kelly McGonigal: How to make stress your friend",
    "resource_id": 4993,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "10. Kathryn Schulz: On being wrong",
    "resource_id": 5023,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "06. Brené Brown: The power of vulnerability",
    "resource_id": 4998,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Kiran Bedi: A police chief with a difference",
    "resource_id": 7660,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 10.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Carol Dweck: The power of believing that you can improve",
    "resource_id": 5024,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Sheryl Sandberg: Why we have too few women leaders",
    "resource_id": 5011,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "03. Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 5243,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 16.83
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Negin Farsad: A highly scientific taxonomy of haters",
    "resource_id": 7569,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.33
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Paul Nicklen: Animal tales from icy wonderlands",
    "resource_id": 7699,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 5.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5128,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.5
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 7547,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 5
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "09. Ben Goldacre: Battling bad science",
    "resource_id": 5211,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.34
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Brian Little: Who are you, really? The puzzle of personality",
    "resource_id": 7742,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.16
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170953,
    "user_name": "User170953",
    "first_name": "User",
    "last_name": 170953,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 32.33
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 25.33
  },
  {
    "person_key": 168343,
    "user_name": "User168343",
    "first_name": "User",
    "last_name": 168343,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 15.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Russell Foster: Why do we sleep?",
    "resource_id": 7615,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 5207,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.33
  },
  {
    "person_key": 160354,
    "user_name": "User160354",
    "first_name": "User",
    "last_name": 160354,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.83
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 49
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "08. Stephen Hawking: Questioning the universe",
    "resource_id": 5210,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 10.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.16
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 7549,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 8.25
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "10. Clint Smith: The danger of silence",
    "resource_id": 4975,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 10.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Ronald Sullivan: How I help free innocent people from prison",
    "resource_id": 7634,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.5
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "11. Barry Schwartz: Our loss of wisdom",
    "resource_id": 5089,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 25.83
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Margaret Heffernan: Dare to disagree",
    "resource_id": 5016,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172378,
    "user_name": "User172378",
    "first_name": "User",
    "last_name": 172378,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: BIOS Motherboards",
    "resource_id": 8226,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "05. McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 5007,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 25.83
  },
  {
    "person_key": 170958,
    "user_name": "User170958",
    "first_name": "User",
    "last_name": 170958,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 15
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "06. Amy Webb: How I hacked online dating",
    "resource_id": 5065,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Amy Cuddy: Your body language shapes who you are",
    "resource_id": 5126,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.41
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Joshua Prager: In search of the man who broke my neck",
    "resource_id": 7815,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 20.75
  },
  {
    "person_key": 164196,
    "user_name": "User164196",
    "first_name": "User",
    "last_name": 164196,
    "doc_id": 12345,
    "learning_object": "01. Eric Sanderson: New York -- before the City",
    "resource_id": 5241,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 17.33
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 13.83
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "05. McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 5007,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.67
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Salil Dudani: How jails extort the poor",
    "resource_id": 7655,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 19
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 20
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Dan Buettner: How to live to be 100+",
    "resource_id": 7624,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 30.83
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 9.83
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "06. Jill Bolte Taylor: My stroke of insight",
    "resource_id": 5226,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.34
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "13. AJ Jacobs: The world's largest family reunion … we're all invited!",
    "resource_id": 5038,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 11.33
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Adam Driver: My journey from Marine to actor",
    "resource_id": 7559,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 18.25
  },
  {
    "person_key": 170943,
    "user_name": "User170943",
    "first_name": "User",
    "last_name": 170943,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 10.33
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "10. Sally Kohn: Let’s try emotional correctness",
    "resource_id": 5035,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 7.33
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Toby Eccles: Invest in social change",
    "resource_id": 7661,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 17.33
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "04. Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 5006,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.83
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 5189,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 11.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Morgana Bailey: The danger of hiding who you are",
    "resource_id": 5029,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.33
  },
  {
    "person_key": 160354,
    "user_name": "User160354",
    "first_name": "User",
    "last_name": 160354,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 7
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "07. David Chalmers: How do you explain consciousness?",
    "resource_id": 5085,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 23.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "01. Tony Porter: A call to men",
    "resource_id": 5003,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 5181,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 4
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 7813,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.42
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Sarah Kay: If I should have a daughter ...",
    "resource_id": 7550,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 19.83
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "09. Onora O'Neill: What we don't understand about trust",
    "resource_id": 5087,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 10.58
  },
  {
    "person_key": 160354,
    "user_name": "User160354",
    "first_name": "User",
    "last_name": 160354,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 6.58
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "14. Hans and Ola Rosling: How not to be ignorant about the world",
    "resource_id": 5039,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 20
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "02. Michael Norton: How to buy happiness",
    "resource_id": 5105,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 11.33
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Damon Horowitz: Philosophy in prison",
    "resource_id": 7656,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 5.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "07. Anne Curzan: What makes a word \"real\"?",
    "resource_id": 5187,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.34
  },
  {
    "person_key": 170938,
    "user_name": "User170938",
    "first_name": "User",
    "last_name": 170938,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Sue Austin: Deep sea diving ... in a wheelchair",
    "resource_id": 5228,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "07. Aimee Mullins: The opportunity of adversity",
    "resource_id": 5227,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "12. Sarah Jones: A one-woman global village",
    "resource_id": 5037,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 23
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Chris Anderson: TED's secret to great public speaking",
    "resource_id": 7767,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 4.67
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 58.42
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 83.42
  },
  {
    "person_key": 170938,
    "user_name": "User170938",
    "first_name": "User",
    "last_name": 170938,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 30.75
  },
  {
    "person_key": 170944,
    "user_name": "User170944",
    "first_name": "User",
    "last_name": 170944,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170955,
    "user_name": "User170955",
    "first_name": "User",
    "last_name": 170955,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 5.75
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 20.25
  },
  {
    "person_key": 170940,
    "user_name": "User170940",
    "first_name": "User",
    "last_name": 170940,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 36.5
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "Agreement #3",
    "resource_id": 8088,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 4.34
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 12.92
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.84
  },
  {
    "person_key": 170953,
    "user_name": "User170953",
    "first_name": "User",
    "last_name": 170953,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 3
  },
  {
    "person_key": 170954,
    "user_name": "User170954",
    "first_name": "User",
    "last_name": 170954,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 46.33
  },
  {
    "person_key": 171009,
    "user_name": "User171009",
    "first_name": "User",
    "last_name": 171009,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 19
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "Agreement #2",
    "resource_id": 8055,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.42
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 15.33
  },
  {
    "person_key": 170957,
    "user_name": "User170957",
    "first_name": "User",
    "last_name": 170957,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.42
  },
  {
    "person_key": 170998,
    "user_name": "User170998",
    "first_name": "User",
    "last_name": 170998,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.92
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 10.91
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 32.17
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week 6 Unit 5 Beowulf",
    "resource_id": 6513,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170942,
    "user_name": "User170942",
    "first_name": "User",
    "last_name": 170942,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 20.92
  },
  {
    "person_key": 170937,
    "user_name": "User170937",
    "first_name": "User",
    "last_name": 170937,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 34
  },
  {
    "person_key": 170946,
    "user_name": "User170946",
    "first_name": "User",
    "last_name": 170946,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 26
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.92
  },
  {
    "person_key": 170941,
    "user_name": "User170941",
    "first_name": "User",
    "last_name": 170941,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154239,
    "user_name": "User154239",
    "first_name": "User",
    "last_name": 154239,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 12.83
  },
  {
    "person_key": 170956,
    "user_name": "User170956",
    "first_name": "User",
    "last_name": 170956,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 58.67
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.92
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.58
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 63.33
  },
  {
    "person_key": 170944,
    "user_name": "User170944",
    "first_name": "User",
    "last_name": 170944,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.58
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.75
  },
  {
    "person_key": 170948,
    "user_name": "User170948",
    "first_name": "User",
    "last_name": 170948,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 31.42
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 7.25
  },
  {
    "person_key": 170985,
    "user_name": "User170985",
    "first_name": "User",
    "last_name": 170985,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 14.41
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 51.67
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.84
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.92
  },
  {
    "person_key": 170999,
    "user_name": "User170999",
    "first_name": "User",
    "last_name": 170999,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 18.08
  },
  {
    "person_key": 170946,
    "user_name": "User170946",
    "first_name": "User",
    "last_name": 170946,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.5
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "Moorish American Booklet newspaper",
    "resource_id": 6293,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 36.08
  },
  {
    "person_key": 170948,
    "user_name": "User170948",
    "first_name": "User",
    "last_name": 170948,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Course Evaluation",
    "resource_id": 6430,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170945,
    "user_name": "User170945",
    "first_name": "User",
    "last_name": 170945,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 9.33
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "Divine Constitution and bylaws",
    "resource_id": 6295,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 28.67
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 61.58
  },
  {
    "person_key": 171007,
    "user_name": "User171007",
    "first_name": "User",
    "last_name": 171007,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 10.58
  },
  {
    "person_key": 171006,
    "user_name": "User171006",
    "first_name": "User",
    "last_name": 171006,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 19.75
  },
  {
    "person_key": 170928,
    "user_name": "User170928",
    "first_name": "User",
    "last_name": 170928,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.16
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 4.59
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 9.33
  },
  {
    "person_key": 170940,
    "user_name": "User170940",
    "first_name": "User",
    "last_name": 170940,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "Book Club 4",
    "resource_id": 8487,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 10.42
  },
  {
    "person_key": 170997,
    "user_name": "User170997",
    "first_name": "User",
    "last_name": 170997,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 33.17
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.25
  },
  {
    "person_key": 170939,
    "user_name": "User170939",
    "first_name": "User",
    "last_name": 170939,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 6.67
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 30
  },
  {
    "person_key": 170951,
    "user_name": "User170951",
    "first_name": "User",
    "last_name": 170951,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170957,
    "user_name": "User170957",
    "first_name": "User",
    "last_name": 170957,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 44.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 21.42
  },
  {
    "person_key": 170954,
    "user_name": "User170954",
    "first_name": "User",
    "last_name": 170954,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170955,
    "user_name": "User170955",
    "first_name": "User",
    "last_name": 170955,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170947,
    "user_name": "User170947",
    "first_name": "User",
    "last_name": 170947,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170943,
    "user_name": "User170943",
    "first_name": "User",
    "last_name": 170943,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170935,
    "user_name": "User170935",
    "first_name": "User",
    "last_name": 170935,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170942,
    "user_name": "User170942",
    "first_name": "User",
    "last_name": 170942,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170951,
    "user_name": "User170951",
    "first_name": "User",
    "last_name": 170951,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 38.75
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.67
  },
  {
    "person_key": 170928,
    "user_name": "User170928",
    "first_name": "User",
    "last_name": 170928,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "Agreement #4",
    "resource_id": 8089,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.42
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 36.5
  },
  {
    "person_key": 170959,
    "user_name": "User170959",
    "first_name": "User",
    "last_name": 170959,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 23.08
  },
  {
    "person_key": 170936,
    "user_name": "User170936",
    "first_name": "User",
    "last_name": 170936,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "FOUR AGREEMENT THOUGHTS",
    "resource_id": 8090,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 3.67
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 55.25
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Considerations for the Informative Speech: Chapters 14-16",
    "resource_id": 6833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 54.59
  },
  {
    "person_key": 170952,
    "user_name": "User170952",
    "first_name": "User",
    "last_name": 170952,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 12
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 48.59
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 3.59
  },
  {
    "person_key": 170947,
    "user_name": "User170947",
    "first_name": "User",
    "last_name": 170947,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 78.08
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 59.75
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 7.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 4
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "14. Rose George: Let's talk crap. Seriously.",
    "resource_id": 4991,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170993,
    "user_name": "User170993",
    "first_name": "User",
    "last_name": 170993,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 18.66
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "07. Hannah Brencher: Love letters to strangers",
    "resource_id": 5066,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.16
  },
  {
    "person_key": 162288,
    "user_name": "User162288",
    "first_name": "User",
    "last_name": 162288,
    "doc_id": 12345,
    "learning_object": "Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 7681,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 5.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.5
  },
  {
    "person_key": 170958,
    "user_name": "User170958",
    "first_name": "User",
    "last_name": 170958,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160354,
    "user_name": "User160354",
    "first_name": "User",
    "last_name": 160354,
    "doc_id": 12345,
    "learning_object": "12. Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 5125,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.92
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Sarah Lewis: Embrace the near win",
    "resource_id": 5022,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.41
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "14. Rose George: Let's talk crap. Seriously.",
    "resource_id": 4991,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 2.83
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "03. Andy Puddicombe: All it takes is 10 mindful minutes",
    "resource_id": 4995,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 17.67
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 30.59
  },
  {
    "person_key": 160354,
    "user_name": "User160354",
    "first_name": "User",
    "last_name": 160354,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 5.83
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 13.08
  },
  {
    "person_key": 160502,
    "user_name": "User160502",
    "first_name": "User",
    "last_name": 160502,
    "doc_id": 12345,
    "learning_object": "Mac Barnett: Why a good book is a secret door",
    "resource_id": 7598,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 18.92
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.92
  },
  {
    "person_key": 170939,
    "user_name": "User170939",
    "first_name": "User",
    "last_name": 170939,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 5068,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 4997,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.75
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 9.42
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.16
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "Lesson #3",
    "resource_id": 8457,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.5
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 85.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 1.83
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 8.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "02. Alice Dreger: Is anatomy destiny?",
    "resource_id": 5004,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 51 Quiz",
    "resource_id": 9759,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 123
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "eL Seed: Street art with a message of hope and peace",
    "resource_id": 7563,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 6.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Kelly McGonigal: How to make stress your friend",
    "resource_id": 4993,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.5
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 51",
    "resource_id": 9478,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 24
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Ruth Chang: How to make hard choices",
    "resource_id": 5059,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 7.58
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 24 Quiz",
    "resource_id": 9817,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 301
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 1 Quiz",
    "resource_id": 9820,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 302
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Jeff Smith: Lessons in business ... from prison",
    "resource_id": 7662,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 11.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Myriam Sidibe: The simple power of hand-washing",
    "resource_id": 4986,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 4.67
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 24",
    "resource_id": 9816,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 37
  },
  {
    "person_key": 164196,
    "user_name": "User164196",
    "first_name": "User",
    "last_name": 164196,
    "doc_id": 12345,
    "learning_object": "07. Jared Diamond: Why do societies collapse?",
    "resource_id": 5247,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 9.75
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Unit 2 Review: Part Two",
    "resource_id": 9818,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-13",
    "time_spent_min": 317
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.16
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Lesson 13 Quiz",
    "resource_id": 9610,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10B",
    "location_id": 1697462,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1286
  },
  {
    "person_key": 159492,
    "user_name": "User159492",
    "first_name": "User",
    "last_name": 159492,
    "doc_id": 12345,
    "learning_object": "Lesson 7: Quiz",
    "resource_id": 9466,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 321
  },
  {
    "person_key": 159492,
    "user_name": "User159492",
    "first_name": "User",
    "last_name": 159492,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Quiz",
    "resource_id": 9465,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 17
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 5: Quiz",
    "resource_id": 8907,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 301
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Melati and Isabel Wijsen: Our campaign to ban plastic bags in Bali",
    "resource_id": 7780,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 13.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Jim Holt: Why does the universe exist?",
    "resource_id": 5080,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 20
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "07. Jeff Smith: Lessons in business ... from prison",
    "resource_id": 5076,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 4.67
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167905,
    "user_name": "User167905",
    "first_name": "User",
    "last_name": 167905,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.42
  },
  {
    "person_key": 167905,
    "user_name": "User167905",
    "first_name": "User",
    "last_name": 167905,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 2.58
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 29.91
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 29.83
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "02. Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 5081,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 30
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 9.59
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "10. iO Tillett Wright: Fifty shades of gay",
    "resource_id": 5012,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 16.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "04. Dan Gilbert: The surprising science of happiness",
    "resource_id": 5083,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 3.58
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "06. Brené Brown: The power of vulnerability",
    "resource_id": 4998,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 2.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Alice Goffman: How we're priming some kids for college — and others for prison",
    "resource_id": 7636,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 3.92
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "08. Ruth Chang: How to make hard choices",
    "resource_id": 5059,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 4.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Susan Colantuono: The career advice you probably didn’t get",
    "resource_id": 5136,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167905,
    "user_name": "User167905",
    "first_name": "User",
    "last_name": 167905,
    "doc_id": 12345,
    "learning_object": "16. Michael Dickinson: How a fly flies",
    "resource_id": 5218,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 14.5
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "10. Toby Eccles: Invest in social change",
    "resource_id": 5079,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 2.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.25
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "09. Yoruba Richen: What the gay rights movement learned from the civil rights movement",
    "resource_id": 5034,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 20.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "05. Eleanor Longden: The voices in my head",
    "resource_id": 4960,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Salil Dudani: How jails extort the poor",
    "resource_id": 7655,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.92
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Chris Burkard: The joy of surfing in ice-cold wate",
    "resource_id": 7697,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 10.17
  },
  {
    "person_key": 159891,
    "user_name": "User159891",
    "first_name": "User",
    "last_name": 159891,
    "doc_id": 12345,
    "learning_object": "Managing Conflict",
    "resource_id": 8191,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 3
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.5
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "07. Michael Shermer: The pattern behind self-deception",
    "resource_id": 5209,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 7779,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Sangu Delle: There's no shame in taking care of your mental health",
    "resource_id": 7744,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "11. Norman Spack: How I help transgender teens become who they want to be",
    "resource_id": 5013,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 26.92
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "11. Norman Spack: How I help transgender teens become who they want to be",
    "resource_id": 5013,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 22.25
  },
  {
    "person_key": 167905,
    "user_name": "User167905",
    "first_name": "User",
    "last_name": 167905,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Neha Narula: The future of money",
    "resource_id": 7722,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 2.67
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.92
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "12. Laurie Garrett: Lessons from the 1918 flu",
    "resource_id": 5252,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 11.25
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "James Green: 3 moons and a planet that could have alien life",
    "resource_id": 7754,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 14.75
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 29.91
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "05. Malcolm Gladwell: Choice, happiness and spaghetti sauce",
    "resource_id": 5056,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 18.58
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "04. Barry Schwartz: The paradox of choice",
    "resource_id": 5055,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "Salil Dudani: How jails extort the poor",
    "resource_id": 7655,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "JD Schramm: Break the silence for suicide attempt survivors",
    "resource_id": 7557,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 7.25
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 16.08
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "02. Dan Ariely: Are we in control of our own decisions?",
    "resource_id": 5053,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 34.34
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "03. Renata Salecl: Our unhealthy obsession with choice",
    "resource_id": 5054,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167905,
    "user_name": "User167905",
    "first_name": "User",
    "last_name": 167905,
    "doc_id": 12345,
    "learning_object": "10. Elif Shafak: The politics of fiction",
    "resource_id": 5190,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 19.83
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "14. Rose George: Let's talk crap. Seriously.",
    "resource_id": 4991,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 7.75
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "01. Tony Porter: A call to men",
    "resource_id": 5003,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 11.17
  },
  {
    "person_key": 167905,
    "user_name": "User167905",
    "first_name": "User",
    "last_name": 167905,
    "doc_id": 12345,
    "learning_object": "08. Erin McKean: Go ahead, make up new words!",
    "resource_id": 5188,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 7.17
  },
  {
    "person_key": 159891,
    "user_name": "User159891",
    "first_name": "User",
    "last_name": 159891,
    "doc_id": 12345,
    "learning_object": "Personal Conflict Styles",
    "resource_id": 8188,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 38
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "06. Sugata Mitra: Build a School in the Cloud",
    "resource_id": 5045,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.84
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 12.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Jessica Jackley: Poverty, money -- and love",
    "resource_id": 7712,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167905,
    "user_name": "User167905",
    "first_name": "User",
    "last_name": 167905,
    "doc_id": 12345,
    "learning_object": "06. Parul Sehgal: An ode to envy",
    "resource_id": 5186,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 13.25
  },
  {
    "person_key": 167905,
    "user_name": "User167905",
    "first_name": "User",
    "last_name": 167905,
    "doc_id": 12345,
    "learning_object": "06. Phil Plait: How to defend Earth from asteroids",
    "resource_id": 5208,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 14.5
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Stephen Hawking: Questioning the universe",
    "resource_id": 7752,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 12.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Hannah Fry: The mathematics of love",
    "resource_id": 5091,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.92
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "James Veitch: This is what happens when you reply to spam email",
    "resource_id": 7716,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 2
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "01. Kelly McGonigal: How to make stress your friend",
    "resource_id": 4993,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 2.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.08
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "Kang Lee: Can you really tell if a kid is lying?",
    "resource_id": 7747,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 3.92
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 7.16
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 7657,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.83
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "02. Elizabeth Gilbert: Your elusive creative genius",
    "resource_id": 5182,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "04. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5143,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "11. Terry Moore: Why is 'x' the unknown?",
    "resource_id": 5102,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 3.92
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "08. Stella Young: I’m not your inspiration, thank you very much",
    "resource_id": 5033,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 29.58
  },
  {
    "person_key": 162633,
    "user_name": "User162633",
    "first_name": "User",
    "last_name": 162633,
    "doc_id": 12345,
    "learning_object": "02. Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 5081,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 7.91
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "09. Onora O'Neill: What we don't understand about trust",
    "resource_id": 5087,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.59
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Julian Treasure: How to speak so that people want to listen",
    "resource_id": 7765,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.42
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "06. Damon Horowitz: Philosophy in prison",
    "resource_id": 5075,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.59
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "07. Paul Bloom: Can prejudice ever be a good thing?",
    "resource_id": 5031,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 7566,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "07. Michael Shermer: The pattern behind self-deception",
    "resource_id": 5209,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 27.25
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 15.83
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 3.83
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 7566,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 19.25
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Thomas Insel: Toward a new understanding of mental illness",
    "resource_id": 7556,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.16
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 5140,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "02. Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 5071,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 21.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Guillaume Néry: The exhilarating peace of freediving",
    "resource_id": 7704,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 15.83
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "07. Dan Buettner: How to live to be 100+",
    "resource_id": 4984,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 21.25
  },
  {
    "person_key": 159891,
    "user_name": "User159891",
    "first_name": "User",
    "last_name": 159891,
    "doc_id": 12345,
    "learning_object": "Coping with Accusations in the Workplace",
    "resource_id": 8189,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 16.92
  },
  {
    "person_key": 171004,
    "user_name": "User171004",
    "first_name": "User",
    "last_name": 171004,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 32.42
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 29.91
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 6
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 6.25
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 30.17
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 3.59
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "Santos Podcast:  Jared",
    "resource_id": 6309,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.5
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 89.42
  },
  {
    "person_key": 166026,
    "user_name": "User166026",
    "first_name": "User",
    "last_name": 166026,
    "doc_id": 12345,
    "learning_object": "Unit 1c: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-Reading for Writers",
    "resource_id": 6415,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 15.75
  },
  {
    "person_key": 170995,
    "user_name": "User170995",
    "first_name": "User",
    "last_name": 170995,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 13.5
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 92.83
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 60.08
  },
  {
    "person_key": 170990,
    "user_name": "User170990",
    "first_name": "User",
    "last_name": 170990,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.83
  },
  {
    "person_key": 171010,
    "user_name": "User171010",
    "first_name": "User",
    "last_name": 171010,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.33
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 6.59
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170996,
    "user_name": "User170996",
    "first_name": "User",
    "last_name": 170996,
    "doc_id": 12345,
    "learning_object": "Part 1 - Conflict Resolution Exam THURSDAY - START HERE",
    "resource_id": 5743,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 5.75
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 41
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1c: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-Reading for Writers",
    "resource_id": 6415,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 23.42
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 60.5
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1 - Prison Life: Living in and Leaving Prison",
    "resource_id": 7507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.17
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 3.08
  },
  {
    "person_key": 171005,
    "user_name": "User171005",
    "first_name": "User",
    "last_name": 171005,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 2.66
  },
  {
    "person_key": 170997,
    "user_name": "User170997",
    "first_name": "User",
    "last_name": 170997,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 32.75
  },
  {
    "person_key": 171007,
    "user_name": "User171007",
    "first_name": "User",
    "last_name": 171007,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 17
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 30.17
  },
  {
    "person_key": 171000,
    "user_name": "User171000",
    "first_name": "User",
    "last_name": 171000,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171004,
    "user_name": "User171004",
    "first_name": "User",
    "last_name": 171004,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 8.09
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 6.25
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.59
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 31.25
  },
  {
    "person_key": 170897,
    "user_name": "User170897",
    "first_name": "User",
    "last_name": 170897,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.580002
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "The Quran",
    "resource_id": 6168,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 11.58
  },
  {
    "person_key": 170992,
    "user_name": "User170992",
    "first_name": "User",
    "last_name": 170992,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 35.25
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Lesson Two/Domain 1: Employment-Education",
    "resource_id": 8064,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.16
  },
  {
    "person_key": 170999,
    "user_name": "User170999",
    "first_name": "User",
    "last_name": 170999,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 170897,
    "user_name": "User170897",
    "first_name": "User",
    "last_name": 170897,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 41.91
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 55.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 60.58
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 31.66
  },
  {
    "person_key": 170993,
    "user_name": "User170993",
    "first_name": "User",
    "last_name": 170993,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 10.92
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.17
  },
  {
    "person_key": 170995,
    "user_name": "User170995",
    "first_name": "User",
    "last_name": 170995,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170999,
    "user_name": "User170999",
    "first_name": "User",
    "last_name": 170999,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 22.5
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 30
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "Agreement #1",
    "resource_id": 8046,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 2.84
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Weldon",
    "resource_id": 6301,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 2.75
  },
  {
    "person_key": 170991,
    "user_name": "User170991",
    "first_name": "User",
    "last_name": 170991,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 12.08
  },
  {
    "person_key": 171003,
    "user_name": "User171003",
    "first_name": "User",
    "last_name": 171003,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 11
  },
  {
    "person_key": 170987,
    "user_name": "User170987",
    "first_name": "User",
    "last_name": 170987,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 5.75
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170992,
    "user_name": "User170992",
    "first_name": "User",
    "last_name": 170992,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 2.83
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 114.67
  },
  {
    "person_key": 170998,
    "user_name": "User170998",
    "first_name": "User",
    "last_name": 170998,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.67
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 29.92
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 14.08
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 16.91
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 35.33
  },
  {
    "person_key": 171008,
    "user_name": "User171008",
    "first_name": "User",
    "last_name": 171008,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169395,
    "user_name": "User169395",
    "first_name": "User",
    "last_name": 169395,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 14.42
  },
  {
    "person_key": 170985,
    "user_name": "User170985",
    "first_name": "User",
    "last_name": 170985,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170991,
    "user_name": "User170991",
    "first_name": "User",
    "last_name": 170991,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.42
  },
  {
    "person_key": 153633,
    "user_name": "User153633",
    "first_name": "User",
    "last_name": 153633,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 13.67
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 42.67
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 2: The Building Blocks of Language and The Narrative Essay",
    "resource_id": 6416,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 9.5
  },
  {
    "person_key": 171000,
    "user_name": "User171000",
    "first_name": "User",
    "last_name": 171000,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 15.75
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 66
  },
  {
    "person_key": 171001,
    "user_name": "User171001",
    "first_name": "User",
    "last_name": 171001,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3 - Corrections: History, Institutions and Population",
    "resource_id": 7506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.25
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 29.92
  },
  {
    "person_key": 162330,
    "user_name": "User162330",
    "first_name": "User",
    "last_name": 162330,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 63
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "Santos Podcast:  King Tone",
    "resource_id": 6305,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 4.67
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.34
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 21.42
  },
  {
    "person_key": 170990,
    "user_name": "User170990",
    "first_name": "User",
    "last_name": 170990,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 42.92
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 4.91
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 22
  },
  {
    "person_key": 170994,
    "user_name": "User170994",
    "first_name": "User",
    "last_name": 170994,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170996,
    "user_name": "User170996",
    "first_name": "User",
    "last_name": 170996,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 5.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.25
  },
  {
    "person_key": 162330,
    "user_name": "User162330",
    "first_name": "User",
    "last_name": 162330,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170994,
    "user_name": "User170994",
    "first_name": "User",
    "last_name": 170994,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 7
  },
  {
    "person_key": 171001,
    "user_name": "User171001",
    "first_name": "User",
    "last_name": 171001,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 9.25
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 3.25
  },
  {
    "person_key": 171010,
    "user_name": "User171010",
    "first_name": "User",
    "last_name": 171010,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 6.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 170987,
    "user_name": "User170987",
    "first_name": "User",
    "last_name": 170987,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 30.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.83
  },
  {
    "person_key": 171008,
    "user_name": "User171008",
    "first_name": "User",
    "last_name": 171008,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 12.67
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2 - Juvenile Justice in the Twenty-First Century",
    "resource_id": 7508,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.75
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.910004
  },
  {
    "person_key": 171006,
    "user_name": "User171006",
    "first_name": "User",
    "last_name": 171006,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 119.67
  },
  {
    "person_key": 171006,
    "user_name": "User171006",
    "first_name": "User",
    "last_name": 171006,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 13.25
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.410004
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 170998,
    "user_name": "User170998",
    "first_name": "User",
    "last_name": 170998,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 38.33
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 78.75
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1 - Punishment and Sentencing",
    "resource_id": 7502,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.08
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 58.41
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Man's Search for Meaning",
    "resource_id": 8084,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170996,
    "user_name": "User170996",
    "first_name": "User",
    "last_name": 170996,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.42
  },
  {
    "person_key": 170986,
    "user_name": "User170986",
    "first_name": "User",
    "last_name": 170986,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 11.42
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 4.75
  },
  {
    "person_key": 170997,
    "user_name": "User170997",
    "first_name": "User",
    "last_name": 170997,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170988,
    "user_name": "User170988",
    "first_name": "User",
    "last_name": 170988,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 13
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 35.75
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 6
  },
  {
    "person_key": 171003,
    "user_name": "User171003",
    "first_name": "User",
    "last_name": 171003,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.08
  },
  {
    "person_key": 171009,
    "user_name": "User171009",
    "first_name": "User",
    "last_name": 171009,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1
  },
  {
    "person_key": 171005,
    "user_name": "User171005",
    "first_name": "User",
    "last_name": 171005,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 34.5
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.42
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "Man's Search for Meaning",
    "resource_id": 8084,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Considerations for the Informative Speech: Chapters 14-16",
    "resource_id": 6833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 30.83
  },
  {
    "person_key": 170985,
    "user_name": "User170985",
    "first_name": "User",
    "last_name": 170985,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 22.17
  },
  {
    "person_key": 171007,
    "user_name": "User171007",
    "first_name": "User",
    "last_name": 171007,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171009,
    "user_name": "User171009",
    "first_name": "User",
    "last_name": 171009,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6027,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 17.83
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 5.09
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.75
  },
  {
    "person_key": 170986,
    "user_name": "User170986",
    "first_name": "User",
    "last_name": 170986,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.5
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 29.91
  },
  {
    "person_key": 170988,
    "user_name": "User170988",
    "first_name": "User",
    "last_name": 170988,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Lesson Three/Domain 2: Marital-Family Relations",
    "resource_id": 8065,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 8
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 33
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2 - Probation, Sanctions and Restorative Justice",
    "resource_id": 7503,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.42
  },
  {
    "person_key": 170993,
    "user_name": "User170993",
    "first_name": "User",
    "last_name": 170993,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6026,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 1.92
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 3: The 5 Basic Sentence Patterns in American English and The Exemplification Essay",
    "resource_id": 6417,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 0.09
  },
  {
    "person_key": 166026,
    "user_name": "User166026",
    "first_name": "User",
    "last_name": 166026,
    "doc_id": 12345,
    "learning_object": "Unit 2: The Building Blocks of Language and The Narrative Essay",
    "resource_id": 6416,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 41
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-12",
    "time_spent_min": 6.16
  },
  {
    "person_key": 170905,
    "user_name": "User170905",
    "first_name": "User",
    "last_name": 170905,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 51.83
  },
  {
    "person_key": 170867,
    "user_name": "User170867",
    "first_name": "User",
    "last_name": 170867,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 13.58
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 59.92
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.58
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Prayers to the Orishas",
    "resource_id": 6135,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 4.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 44.58
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Considerations for the Informative Speech: Chapters 14-16",
    "resource_id": 6833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 31.83
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Communicating with Your Athletes",
    "resource_id": 7031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 24.75
  },
  {
    "person_key": 170897,
    "user_name": "User170897",
    "first_name": "User",
    "last_name": 170897,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 8.58
  },
  {
    "person_key": 170938,
    "user_name": "User170938",
    "first_name": "User",
    "last_name": 170938,
    "doc_id": 12345,
    "learning_object": "03 Housing Area Officer Duties Workbook",
    "resource_id": 5355,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.58
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 125
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2 - Issue in Policing: Professional, Social and Legal",
    "resource_id": 7500,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Siamak Hariri: How do you build a sacred space?",
    "resource_id": 7812,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168343,
    "user_name": "User168343",
    "first_name": "User",
    "last_name": 168343,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.5
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "08. Nalini Nadkarni: Life science in prison",
    "resource_id": 5077,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.58
  },
  {
    "person_key": 167152,
    "user_name": "User167152",
    "first_name": "User",
    "last_name": 167152,
    "doc_id": 12345,
    "learning_object": "Norman Spack: How I help transgender teens become who they want to be",
    "resource_id": 7594,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming Medium Correctional Institution",
    "location_id": 1697397,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 13.92
  },
  {
    "person_key": 166658,
    "user_name": "User166658",
    "first_name": "User",
    "last_name": 166658,
    "doc_id": 12345,
    "learning_object": "Hannah Fry: The mathematics of love",
    "resource_id": 7674,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Zaria Forman: Drawings that show the beauty and fragility of Earth",
    "resource_id": 7683,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 6.67
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Nabila Alibhai: Why people of different faiths are painting their houses of worship yellow",
    "resource_id": 7811,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.17
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "05. Marcus du Sautoy: Symmetry, reality's riddle",
    "resource_id": 5095,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.92
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 13.67
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "06. Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 5030,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 9.5
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.67
  },
  {
    "person_key": 167152,
    "user_name": "User167152",
    "first_name": "User",
    "last_name": 167152,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming Medium Correctional Institution",
    "location_id": 1697397,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.92
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "03. James A. White Sr.: The little problem I had renting a house",
    "resource_id": 5027,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 14.42
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 12.5
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "07. Paul Bloom: Can prejudice ever be a good thing?",
    "resource_id": 5031,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 28.5
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 3.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 7806,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168343,
    "user_name": "User168343",
    "first_name": "User",
    "last_name": 168343,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. Brené Brown: Listening to shame",
    "resource_id": 5014,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "04. Michael Sandel: The lost art of democratic debate",
    "resource_id": 5073,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "David Christian: The history of our world in 18 minutes",
    "resource_id": 7788,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.5
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "03. Daniel Reisel: The neuroscience of restorative justice",
    "resource_id": 5072,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 14.75
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 44.34
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 7593,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.17
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "04. Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 5195,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.5
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.67
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "07. Julian Treasure: How to speak so that people want to listen",
    "resource_id": 5132,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 11.58
  },
  {
    "person_key": 169744,
    "user_name": "User169744",
    "first_name": "User",
    "last_name": 169744,
    "doc_id": 12345,
    "learning_object": "CompTIA Server+ SK0-004: Networking and Disaster Recovery",
    "resource_id": 8275,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 5.75
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.59
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "11. Ben Saunders: To the South Pole and back — the hardest 105 days of my life",
    "resource_id": 5124,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 17.5
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 5.17
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Boaz Almog: The levitating superconductor",
    "resource_id": 7795,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 10
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "Robert Waldinger: What makes a good life? Lessons from the longest study on happiness",
    "resource_id": 7737,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 9.67
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Marlon Peterson: Am I not human? A call for criminal justice reform",
    "resource_id": 7646,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.58
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "04. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5143,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 10.58
  },
  {
    "person_key": 167152,
    "user_name": "User167152",
    "first_name": "User",
    "last_name": 167152,
    "doc_id": 12345,
    "learning_object": "iO Tillett Wright: Fifty shades of gay",
    "resource_id": 7592,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming Medium Correctional Institution",
    "location_id": 1697397,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 17.33
  },
  {
    "person_key": 157598,
    "user_name": "User157598",
    "first_name": "User",
    "last_name": 157598,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.25
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "06. Mundano: Pimp my ... trash cart?",
    "resource_id": 4971,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Brené Brown: The power of vulnerability",
    "resource_id": 7740,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "05. Morgana Bailey: The danger of hiding who you are",
    "resource_id": 5029,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 11
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 36.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Karen Armstrong: Let's revive the Golden Rule",
    "resource_id": 7807,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: On reading the Koran",
    "resource_id": 7810,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "10. Paul Nicklen: Tales of ice-bound wonderlands",
    "resource_id": 5123,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 18.83
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "01. Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 5025,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 18.75
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Richard Dawkins: Why the universe seems so strange",
    "resource_id": 7733,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Nalini Nadkarni: Life science in prison",
    "resource_id": 7658,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.42
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Andrés Ruzo: The boiling river of the Amazon",
    "resource_id": 7685,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "02. Robert Gupta: Music is medicine, music is sanity",
    "resource_id": 4949,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.83
  },
  {
    "person_key": 169744,
    "user_name": "User169744",
    "first_name": "User",
    "last_name": 169744,
    "doc_id": 12345,
    "learning_object": "Difficult People: Why They Act that way and How to Deal with Them",
    "resource_id": 8256,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 4.25
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. Tony Porter: A call to men",
    "resource_id": 5003,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.08
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.75
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 7791,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "T. Morgan Dixon and Vanessa Garrison: When Black women walk, things change",
    "resource_id": 7638,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.17
  },
  {
    "person_key": 166658,
    "user_name": "User166658",
    "first_name": "User",
    "last_name": 166658,
    "doc_id": 12345,
    "learning_object": "Rajiv Maheswaran: The math behind basketball's wildest moves",
    "resource_id": 7673,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 13.08
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "09. George Monbiot: For more wonder, rewild the world",
    "resource_id": 5122,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 15
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "12. Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 5125,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Joy Buolamwini: How I'm fighting bias in algorithms",
    "resource_id": 7721,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.83
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.67
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "02. Simon Sinek: How great leaders inspire action",
    "resource_id": 5127,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.67
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "02. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5026,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Sharon Brous: It's time to reclaim religion",
    "resource_id": 7803,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.92
  },
  {
    "person_key": 159358,
    "user_name": "User159358",
    "first_name": "User",
    "last_name": 159358,
    "doc_id": 12345,
    "learning_object": "Ronald Sullivan: How I help free innocent people from prison",
    "resource_id": 7634,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center Education",
    "location_id": 1697388,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 16.17
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Ben Goldacre: Battling bad science",
    "resource_id": 7793,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 8.08
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 11.5
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Guillaume Néry: The exhilarating peace of freediving",
    "resource_id": 7704,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 25.25
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "10. Joshua Walters: On being just crazy enough",
    "resource_id": 4965,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 11.67
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "08. Johanna Blakley: Social media and the end of gender",
    "resource_id": 5010,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Rebecca MacKinnon: Let's take back the Internet!",
    "resource_id": 7728,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 30
  },
  {
    "person_key": 162623,
    "user_name": "User162623",
    "first_name": "User",
    "last_name": 162623,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Alicia Garza, Patrisse Cullors and Opal Tometi: An interview with the founders of Black Lives Matter",
    "resource_id": 7640,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 3.75
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "06. Damon Horowitz: Philosophy in prison",
    "resource_id": 5075,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.08
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 24.59
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Kiran Bedi: A police chief with a difference",
    "resource_id": 7660,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.17
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "02. Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 5071,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.92
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "04. Shlomo Benartzi: Saving for tomorrow, tomorrow",
    "resource_id": 5107,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 14.66
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Tony Porter: A call to men",
    "resource_id": 7590,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.17
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 7702,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Shah Rukh Khan: Thoughts on humanity, fame and love",
    "resource_id": 7670,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 20.75
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "Pico Iyer: The art of stillness",
    "resource_id": 7738,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.33
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "04. Ash Beckham: We're all hiding something. Let's find the courage to open up",
    "resource_id": 5028,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 9.5
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "10. Vikram Patel: Mental health for all by involving all",
    "resource_id": 5002,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 6.25
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Thomas Insel: Toward a new understanding of mental illness",
    "resource_id": 7556,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 15.17
  },
  {
    "person_key": 170866,
    "user_name": "User170866",
    "first_name": "User",
    "last_name": 170866,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170865,
    "user_name": "User170865",
    "first_name": "User",
    "last_name": 170865,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170885,
    "user_name": "User170885",
    "first_name": "User",
    "last_name": 170885,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.17
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170870,
    "user_name": "User170870",
    "first_name": "User",
    "last_name": 170870,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 8.92
  },
  {
    "person_key": 170863,
    "user_name": "User170863",
    "first_name": "User",
    "last_name": 170863,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 49.67
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 3: The 5 Basic Sentence Patterns in American English and The Exemplification Essay",
    "resource_id": 6417,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.83
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.59
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 26.58
  },
  {
    "person_key": 170873,
    "user_name": "User170873",
    "first_name": "User",
    "last_name": 170873,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170878,
    "user_name": "User170878",
    "first_name": "User",
    "last_name": 170878,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 38.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.83
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 6.08
  },
  {
    "person_key": 170886,
    "user_name": "User170886",
    "first_name": "User",
    "last_name": 170886,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 37.58
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.83
  },
  {
    "person_key": 170884,
    "user_name": "User170884",
    "first_name": "User",
    "last_name": 170884,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 6.33
  },
  {
    "person_key": 170869,
    "user_name": "User170869",
    "first_name": "User",
    "last_name": 170869,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 17.58
  },
  {
    "person_key": 170883,
    "user_name": "User170883",
    "first_name": "User",
    "last_name": 170883,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 17.17
  },
  {
    "person_key": 170895,
    "user_name": "User170895",
    "first_name": "User",
    "last_name": 170895,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 9
  },
  {
    "person_key": 170901,
    "user_name": "User170901",
    "first_name": "User",
    "last_name": 170901,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 8.42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 4- Law Enforcement in Action",
    "resource_id": 7438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 5.17
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 3 - Victory with Honor",
    "resource_id": 6518,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.83
  },
  {
    "person_key": 170938,
    "user_name": "User170938",
    "first_name": "User",
    "last_name": 170938,
    "doc_id": 12345,
    "learning_object": "Part 1 - Conflict Resolution Exam MONDAY - START HERE",
    "resource_id": 5742,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 105.5
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 3.17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1 - Police: Role and Function",
    "resource_id": 7498,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.67
  },
  {
    "person_key": 170901,
    "user_name": "User170901",
    "first_name": "User",
    "last_name": 170901,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.08
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 30.5
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 136.92
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.91
  },
  {
    "person_key": 170885,
    "user_name": "User170885",
    "first_name": "User",
    "last_name": 170885,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 46.33
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.59
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 6 - Motivation in Sport",
    "resource_id": 7065,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 38
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 18.67
  },
  {
    "person_key": 170899,
    "user_name": "User170899",
    "first_name": "User",
    "last_name": 170899,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 16.67
  },
  {
    "person_key": 170866,
    "user_name": "User170866",
    "first_name": "User",
    "last_name": 170866,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 20
  },
  {
    "person_key": 170894,
    "user_name": "User170894",
    "first_name": "User",
    "last_name": 170894,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.25
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 4
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.92
  },
  {
    "person_key": 170906,
    "user_name": "User170906",
    "first_name": "User",
    "last_name": 170906,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 11.58
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.75
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 41.67
  },
  {
    "person_key": 170858,
    "user_name": "User170858",
    "first_name": "User",
    "last_name": 170858,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 33.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 52.08
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Strange But True Stories Book 1",
    "resource_id": 7132,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 4.33
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3 - Ethics in Criminal Justice",
    "resource_id": 7471,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.5
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 170869,
    "user_name": "User170869",
    "first_name": "User",
    "last_name": 170869,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170876,
    "user_name": "User170876",
    "first_name": "User",
    "last_name": 170876,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 48.75
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.91
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.34
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 21.42
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Considerations for the Informative Speech: Chapters 14-16",
    "resource_id": 6833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170863,
    "user_name": "User170863",
    "first_name": "User",
    "last_name": 170863,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.67
  },
  {
    "person_key": 170882,
    "user_name": "User170882",
    "first_name": "User",
    "last_name": 170882,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 56.75
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Part Two: Writing Your Informative Speech",
    "resource_id": 6834,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.58
  },
  {
    "person_key": 170893,
    "user_name": "User170893",
    "first_name": "User",
    "last_name": 170893,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 43.33
  },
  {
    "person_key": 170888,
    "user_name": "User170888",
    "first_name": "User",
    "last_name": 170888,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 26.33
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 4.42
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 30.58
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.34
  },
  {
    "person_key": 170891,
    "user_name": "User170891",
    "first_name": "User",
    "last_name": 170891,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 46.67
  },
  {
    "person_key": 170868,
    "user_name": "User170868",
    "first_name": "User",
    "last_name": 170868,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170897,
    "user_name": "User170897",
    "first_name": "User",
    "last_name": 170897,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 7.58
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 6.25
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 12.17
  },
  {
    "person_key": 170888,
    "user_name": "User170888",
    "first_name": "User",
    "last_name": 170888,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.75
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Marcus Garvey and Rasta Movement",
    "resource_id": 6144,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 9.5
  },
  {
    "person_key": 170903,
    "user_name": "User170903",
    "first_name": "User",
    "last_name": 170903,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 8.92
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2 - Criminal Law",
    "resource_id": 7492,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 4.33
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 158.92
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 22.5
  },
  {
    "person_key": 170892,
    "user_name": "User170892",
    "first_name": "User",
    "last_name": 170892,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.33
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 91.5
  },
  {
    "person_key": 166503,
    "user_name": "User166503",
    "first_name": "User",
    "last_name": 166503,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 6640,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170902,
    "user_name": "User170902",
    "first_name": "User",
    "last_name": 170902,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 11.25
  },
  {
    "person_key": 170889,
    "user_name": "User170889",
    "first_name": "User",
    "last_name": 170889,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170864,
    "user_name": "User170864",
    "first_name": "User",
    "last_name": 170864,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 11.58
  },
  {
    "person_key": 170872,
    "user_name": "User170872",
    "first_name": "User",
    "last_name": 170872,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 41.25
  },
  {
    "person_key": 153633,
    "user_name": "User153633",
    "first_name": "User",
    "last_name": 153633,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 8.5
  },
  {
    "person_key": 170896,
    "user_name": "User170896",
    "first_name": "User",
    "last_name": 170896,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 7
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.34
  },
  {
    "person_key": 170881,
    "user_name": "User170881",
    "first_name": "User",
    "last_name": 170881,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 37.5
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 3.58
  },
  {
    "person_key": 170876,
    "user_name": "User170876",
    "first_name": "User",
    "last_name": 170876,
    "doc_id": 12345,
    "learning_object": "Part 1 - Conflict Resolution Exam FRIDAY - START HERE",
    "resource_id": 5741,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170871,
    "user_name": "User170871",
    "first_name": "User",
    "last_name": 170871,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 31.42
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 15.66
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.92
  },
  {
    "person_key": 170900,
    "user_name": "User170900",
    "first_name": "User",
    "last_name": 170900,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 5.92
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 10.5
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.91
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 3.17
  },
  {
    "person_key": 170879,
    "user_name": "User170879",
    "first_name": "User",
    "last_name": 170879,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.84
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.33
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 18.92
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1 - Punishment and Sentencing",
    "resource_id": 7502,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.42
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 170884,
    "user_name": "User170884",
    "first_name": "User",
    "last_name": 170884,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 31.83
  },
  {
    "person_key": 170873,
    "user_name": "User170873",
    "first_name": "User",
    "last_name": 170873,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 15.75
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 2",
    "resource_id": 9588,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 204
  },
  {
    "person_key": 170862,
    "user_name": "User170862",
    "first_name": "User",
    "last_name": 170862,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170883,
    "user_name": "User170883",
    "first_name": "User",
    "last_name": 170883,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 1 Quiz",
    "resource_id": 9621,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 3
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 72.33
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 1",
    "resource_id": 9586,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 119
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 36.92
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 1 Quiz",
    "resource_id": 9587,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 647
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 30.33
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 3 Quiz",
    "resource_id": 9591,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 868
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 4",
    "resource_id": 9592,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 376
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.33
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 2 Quiz",
    "resource_id": 9589,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 152
  },
  {
    "person_key": 170861,
    "user_name": "User170861",
    "first_name": "User",
    "last_name": 170861,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 30.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 3",
    "resource_id": 9629,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 3
  },
  {
    "person_key": 170904,
    "user_name": "User170904",
    "first_name": "User",
    "last_name": 170904,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 8
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.08
  },
  {
    "person_key": 170877,
    "user_name": "User170877",
    "first_name": "User",
    "last_name": 170877,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 3",
    "resource_id": 9590,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 236
  },
  {
    "person_key": 170880,
    "user_name": "User170880",
    "first_name": "User",
    "last_name": 170880,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 41.58
  },
  {
    "person_key": 170894,
    "user_name": "User170894",
    "first_name": "User",
    "last_name": 170894,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 15.67
  },
  {
    "person_key": 170905,
    "user_name": "User170905",
    "first_name": "User",
    "last_name": 170905,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.67
  },
  {
    "person_key": 170938,
    "user_name": "User170938",
    "first_name": "User",
    "last_name": 170938,
    "doc_id": 12345,
    "learning_object": "03 Housing Area Officer Duties Workbook",
    "resource_id": 5354,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.58
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 4 Quiz",
    "resource_id": 9593,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 531
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.42
  },
  {
    "person_key": 170889,
    "user_name": "User170889",
    "first_name": "User",
    "last_name": 170889,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 5.5
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 9
  },
  {
    "person_key": 170872,
    "user_name": "User170872",
    "first_name": "User",
    "last_name": 170872,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 21.25
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 2 - Developing Your Coaching Objectives",
    "resource_id": 6517,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 13.83
  },
  {
    "person_key": 170859,
    "user_name": "User170859",
    "first_name": "User",
    "last_name": 170859,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 10.75
  },
  {
    "person_key": 170870,
    "user_name": "User170870",
    "first_name": "User",
    "last_name": 170870,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 4 - Coaching Philosophy",
    "resource_id": 6987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 31
  },
  {
    "person_key": 170861,
    "user_name": "User170861",
    "first_name": "User",
    "last_name": 170861,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 9.25
  },
  {
    "person_key": 170868,
    "user_name": "User170868",
    "first_name": "User",
    "last_name": 170868,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 38.67
  },
  {
    "person_key": 170860,
    "user_name": "User170860",
    "first_name": "User",
    "last_name": 170860,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 9.67
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 12.92
  },
  {
    "person_key": 170878,
    "user_name": "User170878",
    "first_name": "User",
    "last_name": 170878,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.08
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1 - Crime and Victimization",
    "resource_id": 7491,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Ingrid Betancourt: What six years in captivity taught me about fear and faith",
    "resource_id": 7804,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.25
  },
  {
    "person_key": 170874,
    "user_name": "User170874",
    "first_name": "User",
    "last_name": 170874,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "AJR Session 1",
    "resource_id": 8420,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.75
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 113.5
  },
  {
    "person_key": 170867,
    "user_name": "User170867",
    "first_name": "User",
    "last_name": 170867,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 4
  },
  {
    "person_key": 170898,
    "user_name": "User170898",
    "first_name": "User",
    "last_name": 170898,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 10.42
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "02. Alain de Botton: A kinder, gentler philosophy of success",
    "resource_id": 5015,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.5
  },
  {
    "person_key": 170907,
    "user_name": "User170907",
    "first_name": "User",
    "last_name": 170907,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.17
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.08
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1 - The Criminal Justice System",
    "resource_id": 7469,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.75
  },
  {
    "person_key": 170858,
    "user_name": "User170858",
    "first_name": "User",
    "last_name": 170858,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.25
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Diva",
    "resource_id": 7000,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.67
  },
  {
    "person_key": 170862,
    "user_name": "User170862",
    "first_name": "User",
    "last_name": 170862,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 19.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Kandice Sumner: How America's public schools keep kids in poverty",
    "resource_id": 7645,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 5.83
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2 - The Formal Criminal Justice Process",
    "resource_id": 7470,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.17
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 21.58
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 60
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.58
  },
  {
    "person_key": 170871,
    "user_name": "User170871",
    "first_name": "User",
    "last_name": 170871,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170890,
    "user_name": "User170890",
    "first_name": "User",
    "last_name": 170890,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 8.67
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 1",
    "resource_id": 8007,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 119.83
  },
  {
    "person_key": 170904,
    "user_name": "User170904",
    "first_name": "User",
    "last_name": 170904,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 13.58
  },
  {
    "person_key": 170874,
    "user_name": "User170874",
    "first_name": "User",
    "last_name": 170874,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 13.58
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 34.91
  },
  {
    "person_key": 170900,
    "user_name": "User170900",
    "first_name": "User",
    "last_name": 170900,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.75
  },
  {
    "person_key": 170859,
    "user_name": "User170859",
    "first_name": "User",
    "last_name": 170859,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.5
  },
  {
    "person_key": 170893,
    "user_name": "User170893",
    "first_name": "User",
    "last_name": 170893,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.5
  },
  {
    "person_key": 170876,
    "user_name": "User170876",
    "first_name": "User",
    "last_name": 170876,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170907,
    "user_name": "User170907",
    "first_name": "User",
    "last_name": 170907,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 16.25
  },
  {
    "person_key": 170879,
    "user_name": "User170879",
    "first_name": "User",
    "last_name": 170879,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 64.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3 - Police in Society",
    "resource_id": 7497,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.25
  },
  {
    "person_key": 170928,
    "user_name": "User170928",
    "first_name": "User",
    "last_name": 170928,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.09
  },
  {
    "person_key": 170903,
    "user_name": "User170903",
    "first_name": "User",
    "last_name": 170903,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.67
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 3.67
  },
  {
    "person_key": 170877,
    "user_name": "User170877",
    "first_name": "User",
    "last_name": 170877,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 42.75
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3 - Courts, Prosecutors, & Defense",
    "resource_id": 7501,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.42
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 77.33
  },
  {
    "person_key": 170882,
    "user_name": "User170882",
    "first_name": "User",
    "last_name": 170882,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170881,
    "user_name": "User170881",
    "first_name": "User",
    "last_name": 170881,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170865,
    "user_name": "User170865",
    "first_name": "User",
    "last_name": 170865,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 10.33
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.250002
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.25
  },
  {
    "person_key": 159358,
    "user_name": "User159358",
    "first_name": "User",
    "last_name": 159358,
    "doc_id": 12345,
    "learning_object": "David Christian: The history of our world in 18 minutes",
    "resource_id": 7788,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center Education",
    "location_id": 1697388,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 4.25
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.91
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "09. Margaret Wertheim: The beautiful math of coral",
    "resource_id": 5100,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 29.92
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "Andy Puddicombe: All it takes is 10 mindful minutes",
    "resource_id": 7736,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 8.25
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "05. David R. Dow: Lessons from death row inmates",
    "resource_id": 5074,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 30.08
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 7739,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.5
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 5.67
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "07. Oliver Sacks: What hallucination reveals about our minds",
    "resource_id": 4962,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 5.17
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160719,
    "user_name": "User160719",
    "first_name": "User",
    "last_name": 160719,
    "doc_id": 12345,
    "learning_object": "Mac Stone: Stunning photos of the endangered Everglades",
    "resource_id": 7698,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 15.83
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 157963,
    "user_name": "User157963",
    "first_name": "User",
    "last_name": 157963,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.58
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "JD Schramm: Break the silence for suicide attempt survivors",
    "resource_id": 7557,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 8.17
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Michael Dickinson: How a fly flies",
    "resource_id": 7796,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.42
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Oliver Sacks: What hallucination reveals about our minds",
    "resource_id": 7554,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 20.67
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.67
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Kwame Anthony Appiah: Is religion good or bad? (This is a trick question)",
    "resource_id": 7805,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Sangu Delle: There's no shame in taking care of your mental health",
    "resource_id": 7744,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "11. JD Schramm: Break the silence for suicide attempt survivors",
    "resource_id": 4966,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 2.5
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 14
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 8.75
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Norman Spack: How I help transgender teens become who they want to be",
    "resource_id": 7594,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 6.5
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Clint Smith: How to raise a black son in America",
    "resource_id": 7643,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.83
  },
  {
    "person_key": 157828,
    "user_name": "User157828",
    "first_name": "User",
    "last_name": 157828,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "02. Derek Sivers: How to start a movement",
    "resource_id": 5193,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 3
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 22.83
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 14.16
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "09. Thomas Insel: Toward a new understanding of mental illness",
    "resource_id": 4964,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.58
  },
  {
    "person_key": 159358,
    "user_name": "User159358",
    "first_name": "User",
    "last_name": 159358,
    "doc_id": 12345,
    "learning_object": "James A. White Sr.: The little problem I had renting a house",
    "resource_id": 7579,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center Education",
    "location_id": 1697388,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 48.58
  },
  {
    "person_key": 162633,
    "user_name": "User162633",
    "first_name": "User",
    "last_name": 162633,
    "doc_id": 12345,
    "learning_object": "02. Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 5081,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 20.92
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "05. Phil Hansen: Embrace the shake",
    "resource_id": 5225,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 9.83
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Kiran Bedi: A police chief with a difference",
    "resource_id": 7660,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 1.17
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "05. Tony Robbins: Why we do what we do",
    "resource_id": 5130,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 0.83
  },
  {
    "person_key": 162633,
    "user_name": "User162633",
    "first_name": "User",
    "last_name": 162633,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 23.66
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-11",
    "time_spent_min": 3.58
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "03. Andy Puddicombe: All it takes is 10 mindful minutes",
    "resource_id": 4995,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 11.08
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 32.16
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Phil Plait: How to defend Earth from asteroids",
    "resource_id": 7732,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 30.5
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 33.25
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: How the worst moments in our lives make us who we are",
    "resource_id": 7818,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 23.83
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Edith Widder: How we found the giant squid",
    "resource_id": 7691,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 8.75
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Alicia Garza, Patrisse Cullors and Opal Tometi: An interview with the founders of Black Lives Matter",
    "resource_id": 7640,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 19.33
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Ed Yong: Zombie roaches and other parasite tales",
    "resource_id": 7789,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 12.33
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Alice Dreger: Is anatomy destiny?",
    "resource_id": 7587,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168343,
    "user_name": "User168343",
    "first_name": "User",
    "last_name": 168343,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.25
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Beau Lotto: Optical illusions show how we see",
    "resource_id": 7790,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.67
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.5
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "David Casarett: A doctor's case for medical marijuana",
    "resource_id": 7626,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 8.33
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3.67
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Boaz Almog: The levitating superconductor",
    "resource_id": 7795,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 10.75
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "James A. White Sr.: The little problem I had renting a house",
    "resource_id": 7579,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.33
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "09. Cesar Kuriyama: One second every day",
    "resource_id": 4974,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 8.42
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.08
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Stella Young: I'm not your inspiration, thank you very much",
    "resource_id": 7584,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 16
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Richard Dawkins: Why the universe seems so strange",
    "resource_id": 7733,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 12.58
  },
  {
    "person_key": 168401,
    "user_name": "User168401",
    "first_name": "User",
    "last_name": 168401,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Roxbury Correctional Institution",
    "location_id": 1697402,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 12.5
  },
  {
    "person_key": 161676,
    "user_name": "User161676",
    "first_name": "User",
    "last_name": 161676,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Sheryl Sandberg: Why we have too few women leaders",
    "resource_id": 7591,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.58
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Eleanor Longden: The voices in my head",
    "resource_id": 7535,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.67
  },
  {
    "person_key": 166505,
    "user_name": "User166505",
    "first_name": "User",
    "last_name": 166505,
    "doc_id": 12345,
    "learning_object": "04. Edward Snowden: Here's how we take back the Internet",
    "resource_id": 5168,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 30.67
  },
  {
    "person_key": 157828,
    "user_name": "User157828",
    "first_name": "User",
    "last_name": 157828,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 35.08
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 4.25
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "John Green: The nerd's guide to learning everything online",
    "resource_id": 7715,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.33
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "07. John Wooden: The difference between winning and succeeding",
    "resource_id": 5020,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.58
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "05. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 4997,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.42
  },
  {
    "person_key": 162678,
    "user_name": "User162678",
    "first_name": "User",
    "last_name": 162678,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 4.33
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 15.58
  },
  {
    "person_key": 168343,
    "user_name": "User168343",
    "first_name": "User",
    "last_name": 168343,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "02. Alain de Botton: Atheism 2.0",
    "resource_id": 5234,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 30
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 7792,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 20.92
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 13.92
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "03. Dan Gilbert: The psychology of your future self",
    "resource_id": 5082,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 11.67
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "11. Ben Saunders: To the South Pole and back — the hardest 105 days of my life",
    "resource_id": 5124,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.42
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.42
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.41
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.17
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 8.33
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "AJ Jacobs: The world's largest family reunion ... we're all invited!",
    "resource_id": 7597,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 25.42
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "12. Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 5125,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.33
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.58
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5.42
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 18.5
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Phil Plait: How to defend Earth from asteroids",
    "resource_id": 7732,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 17.58
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "John Green: The nerd's guide to learning everything online",
    "resource_id": 7715,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 20
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 15.33
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 30
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3.83
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: Depression, the secret we share",
    "resource_id": 7536,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 30.42
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "06. David Brooks: Should you live for your résumé ... or your eulogy?",
    "resource_id": 5084,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 8.83
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "10. Clint Smith: The danger of silence",
    "resource_id": 4975,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.42
  },
  {
    "person_key": 168343,
    "user_name": "User168343",
    "first_name": "User",
    "last_name": 168343,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Lucianne Walkowicz: Let's not use Mars as a backup planet",
    "resource_id": 7757,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 6.83
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 7.67
  },
  {
    "person_key": 157828,
    "user_name": "User157828",
    "first_name": "User",
    "last_name": 157828,
    "doc_id": 12345,
    "learning_object": "09. Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 5134,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 30.58
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "06. Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 5030,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 17.75
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "T. Morgan Dixon and Vanessa Garrison: When Black women walk, things change",
    "resource_id": 7638,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 29.17
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 24.17
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Jackson Katz: Violence against women -- it's a men's issue",
    "resource_id": 7575,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 25.83
  },
  {
    "person_key": 162678,
    "user_name": "User162678",
    "first_name": "User",
    "last_name": 162678,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.17
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Mac Stone: Stunning photos of the endangered Everglades",
    "resource_id": 7698,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 22.33
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Sally Kohn: Let’s try emotional correctness",
    "resource_id": 7576,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.17
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 7580,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.58
  },
  {
    "person_key": 157050,
    "user_name": "User157050",
    "first_name": "User",
    "last_name": 157050,
    "doc_id": 12345,
    "learning_object": "Rachel Sussman: The world's oldest living things",
    "resource_id": 7682,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.67
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5.75
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "05. McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 5007,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.83
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "02. Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 5081,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 30.42
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 7580,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.92
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.08
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Dan Cobley: What physics taught me about marketing",
    "resource_id": 7797,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 4.42
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Stella Young: I'm not your inspiration, thank you very much",
    "resource_id": 7584,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 6.42
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 7564,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 6.75
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 6.75
  },
  {
    "person_key": 82,
    "user_name": "User82",
    "first_name": "User",
    "last_name": 82,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 7.58
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Clint Smith: How to raise a black son in America",
    "resource_id": 7643,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 18.42
  },
  {
    "person_key": 169743,
    "user_name": "User169743",
    "first_name": "User",
    "last_name": 169743,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 6.67
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "10. Paul Nicklen: Tales of ice-bound wonderlands",
    "resource_id": 5123,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.42
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.92
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 18.58
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 7680,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 12.5
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 16.08
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "14. Rose George: Let's talk crap. Seriously.",
    "resource_id": 4991,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.17
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 23.08
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 10.92
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 10.08
  },
  {
    "person_key": 166505,
    "user_name": "User166505",
    "first_name": "User",
    "last_name": 166505,
    "doc_id": 12345,
    "learning_object": "Simon Sinek: How great leaders inspire action",
    "resource_id": 7758,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.08
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3.75
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Kevin Briggs: The bridge between suicide and life",
    "resource_id": 7543,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 13.17
  },
  {
    "person_key": 166505,
    "user_name": "User166505",
    "first_name": "User",
    "last_name": 166505,
    "doc_id": 12345,
    "learning_object": "05. James Lyne: Everyday cybercrime -- and what you can do about it",
    "resource_id": 5169,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 29.92
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 7702,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 9.75
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Eric Whitacre: A virtual choir 2,000 voices strong",
    "resource_id": 7717,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 20.08
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Elif Shafak: The revolutionary power of diverse thought",
    "resource_id": 7601,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5.58
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Melati and Isabel Wijsen: Our campaign to ban plastic bags in Bali",
    "resource_id": 7780,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3.92
  },
  {
    "person_key": 168343,
    "user_name": "User168343",
    "first_name": "User",
    "last_name": 168343,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Curtis \"Wall Street\" Carroll: How I learned to read -- and trade stocks -- in prison",
    "resource_id": 7700,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 16.33
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 4.5
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "06. Mundano: Pimp my ... trash cart?",
    "resource_id": 4971,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2
  },
  {
    "person_key": 157828,
    "user_name": "User157828",
    "first_name": "User",
    "last_name": 157828,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.83
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5.17
  },
  {
    "person_key": 168343,
    "user_name": "User168343",
    "first_name": "User",
    "last_name": 168343,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.5
  },
  {
    "person_key": 162678,
    "user_name": "User162678",
    "first_name": "User",
    "last_name": 162678,
    "doc_id": 12345,
    "learning_object": "Sanford Biggers: An artist's unflinching look at racial violence",
    "resource_id": 7544,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.75
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 11.92
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Morgana Bailey: The danger of hiding who you are",
    "resource_id": 7582,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5.67
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Jackson Katz: Violence against women -- it's a men's issue",
    "resource_id": 7575,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 31.75
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Johanna Blakley: Social media and the end of gender",
    "resource_id": 7589,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "07. David Chalmers: How do you explain consciousness?",
    "resource_id": 5085,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 29.92
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Kevin Breel: Confessions of a depressed comic",
    "resource_id": 7534,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 6.58
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.75
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Robert Waldinger: What makes a good life? Lessons from the longest study on happiness",
    "resource_id": 7737,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 15.58
  },
  {
    "person_key": 162678,
    "user_name": "User162678",
    "first_name": "User",
    "last_name": 162678,
    "doc_id": 12345,
    "learning_object": "Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 7549,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5.17
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 7593,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.83
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 29.83
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "01. Jim Holt: Why does the universe exist?",
    "resource_id": 5080,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 30.25
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "02. Guy Winch: Why we all need to practice emotional first aid",
    "resource_id": 4994,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.5
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Michael Murphy: Architecture that's built to heal",
    "resource_id": 7642,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 16.75
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Christopher Bell: Bring on the female superheroes!",
    "resource_id": 7600,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3.42
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Michael Dickinson: How a fly flies",
    "resource_id": 7796,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 16.17
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "David Christian: The history of our world in 18 minutes",
    "resource_id": 7788,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 11.33
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 7566,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Camille Seaman: Haunting photos of polar ice",
    "resource_id": 7679,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 9.5
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Ed Yong: Zombie roaches and other parasite tales",
    "resource_id": 7789,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 14.33
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.5
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 7792,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 17.92
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 22.92
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3.92
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 87.5
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "04. Pico Iyer: The art of stillness",
    "resource_id": 4996,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.5
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "01. Hannah Fry: The mathematics of love",
    "resource_id": 5091,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 10.17
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 12.42
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "02. Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 5071,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.83
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Boaz Almog: The levitating superconductor",
    "resource_id": 7795,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 9.17
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 10.67
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 21.09
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 46.08
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 29.91
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "09. George Monbiot: For more wonder, rewild the world",
    "resource_id": 5122,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.75
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 17.42
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 7.5
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 11.92
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 49.84
  },
  {
    "person_key": 166505,
    "user_name": "User166505",
    "first_name": "User",
    "last_name": 166505,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.67
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Rich Benjamin: My road trip through the whitest towns in America",
    "resource_id": 7562,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 11.33
  },
  {
    "person_key": 168081,
    "user_name": "User168081",
    "first_name": "User",
    "last_name": 168081,
    "doc_id": 12345,
    "learning_object": "Five Percenter Anthology",
    "resource_id": 6300,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "6A",
    "location_id": 1697453,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 59.83
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 4.42
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Kevin Breel: Confessions of a depressed comic",
    "resource_id": 7534,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 12
  },
  {
    "person_key": 156306,
    "user_name": "User156306",
    "first_name": "User",
    "last_name": 156306,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.58
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.25
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 9.67
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 106.84
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "13. Nilofer Merchant: Got a meeting? Take a walk",
    "resource_id": 4990,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.92
  },
  {
    "person_key": 162330,
    "user_name": "User162330",
    "first_name": "User",
    "last_name": 162330,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 17.08
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 22.75
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Jedidah Isler: How I fell in love with quasars, blazars and our incredible universe",
    "resource_id": 7750,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 6.33
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.160004
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.25
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 82,
    "user_name": "User82",
    "first_name": "User",
    "last_name": 82,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 18.25
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Mellody Hobson: Color blind or color brave?",
    "resource_id": 7568,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5.33
  },
  {
    "person_key": 159455,
    "user_name": "User159455",
    "first_name": "User",
    "last_name": 159455,
    "doc_id": 12345,
    "learning_object": "James Veitch: This is what happens when you reply to spam email",
    "resource_id": 7716,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 12.67
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5.25
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 29.91
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.67
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 30.5
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.92
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.92
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3.67
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 4.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3.42
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "1.5 Youth and the Juvenile Justice System: Graded",
    "resource_id": 4763,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.5
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "15. Paul Pholeros: How to reduce poverty? Fix homes",
    "resource_id": 4992,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.58
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 16.25
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 30.17
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.33
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 89.25
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 21.92
  },
  {
    "person_key": 157050,
    "user_name": "User157050",
    "first_name": "User",
    "last_name": 157050,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168081,
    "user_name": "User168081",
    "first_name": "User",
    "last_name": 168081,
    "doc_id": 12345,
    "learning_object": "Marcus Garvey and Rasta Movement",
    "resource_id": 6144,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "6A",
    "location_id": 1697453,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.75
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 12.75
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 4.16
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 20.33
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 7.5
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Once Upon a Crime Audiobook",
    "resource_id": 5802,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.42
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 8.34
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.83
  },
  {
    "person_key": 166026,
    "user_name": "User166026",
    "first_name": "User",
    "last_name": 166026,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 32.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.91
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Kandice Sumner: How America's public schools keep kids in poverty",
    "resource_id": 7645,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.08
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "04. Dan Gilbert: The surprising science of happiness",
    "resource_id": 5083,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 22.17
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Sarah Jones: One woman, five characters, and a sex lesson from the future",
    "resource_id": 7599,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 11.42
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "02. Clint Smith: The danger of silence",
    "resource_id": 5141,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 30
  },
  {
    "person_key": 168081,
    "user_name": "User168081",
    "first_name": "User",
    "last_name": 168081,
    "doc_id": 12345,
    "learning_object": "History of Allah",
    "resource_id": 6299,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "6A",
    "location_id": 1697453,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 3
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 6706,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Ben Goldacre: Battling bad science",
    "resource_id": 7793,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 6.08
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 13.92
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.340027
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Gabriela González: How LIGO discovered gravitational waves -- and what might be next",
    "resource_id": 7773,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.5
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Kenneth Lacovara: Hunting for dinosaurs showed me our place in the universe",
    "resource_id": 7829,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 11.42
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Ash Beckham: We're all hiding something. Let's find the courage to open up",
    "resource_id": 7581,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 1.42
  },
  {
    "person_key": 166026,
    "user_name": "User166026",
    "first_name": "User",
    "last_name": 166026,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 5.67
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 35.83
  },
  {
    "person_key": 157931,
    "user_name": "User157931",
    "first_name": "User",
    "last_name": 157931,
    "doc_id": 12345,
    "learning_object": "Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 7681,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 4
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 38.08
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 43.25
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "07. Randall Munroe: Comics that ask \"what if?",
    "resource_id": 5098,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "James Green: 3 moons and a planet that could have alien life",
    "resource_id": 7754,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 10.67
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 19.42
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 10.17
  },
  {
    "person_key": 166505,
    "user_name": "User166505",
    "first_name": "User",
    "last_name": 166505,
    "doc_id": 12345,
    "learning_object": "Mac Barnett: Why a good book is a secret door",
    "resource_id": 7598,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 0.17
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-10",
    "time_spent_min": 2.58
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.83
  },
  {
    "person_key": 167102,
    "user_name": "User167102",
    "first_name": "User",
    "last_name": 167102,
    "doc_id": 12345,
    "learning_object": "Rachel Sussman: The world's oldest living things",
    "resource_id": 7682,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 30
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Rose George: Let's talk crap. Seriously.",
    "resource_id": 7635,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 14.75
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Paul Tasner: How I became an entrepreneur at 66",
    "resource_id": 7786,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 16.58
  },
  {
    "person_key": 167706,
    "user_name": "User167706",
    "first_name": "User",
    "last_name": 167706,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 27.92
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "10. iO Tillett Wright: Fifty shades of gay",
    "resource_id": 5012,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 23.67
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Boniface Mwangi: The day I stood up alone",
    "resource_id": 7823,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 9.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Johanna Blakley: Social media and the end of gender",
    "resource_id": 7589,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 30.67
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 7813,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 13.83
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 15.75
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 39.67
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 18.33
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Aimee Mullins: The opportunity of adversity",
    "resource_id": 7816,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.75
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "11. Ed Yong: Zombie roaches and other parasite tales",
    "resource_id": 5213,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 17.17
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "John Green: The nerd's guide to learning everything online",
    "resource_id": 7715,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 2.83
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 10.83
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 30.33
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 17.42
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "02. Alain de Botton: A kinder, gentler philosophy of success",
    "resource_id": 5015,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 8.67
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: How the worst moments in our lives make us who we are",
    "resource_id": 7818,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 13.08
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 7657,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 2.5
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 5140,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.83
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "02. Clint Smith: The danger of silence",
    "resource_id": 5141,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.67
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Sharon Brous: It's time to reclaim religion",
    "resource_id": 7803,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.17
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Elizabeth Lev: The unheard story of the Sistine Chapel",
    "resource_id": 7839,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 12.92
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 20.33
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "02. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5026,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "07. Aimee Mullins: The opportunity of adversity",
    "resource_id": 5227,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 12.17
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 3.92
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "09. Tony Porter: A call to men",
    "resource_id": 5148,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Rishi Manchanda: What makes us get sick? Look upstream",
    "resource_id": 7617,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 19
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 17.66
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.83
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "08. Richard St. John: Success is a continuous journey",
    "resource_id": 5021,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.08
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 30.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Ingrid Betancourt: What six years in captivity taught me about fear and faith",
    "resource_id": 7804,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.75
  },
  {
    "person_key": 172083,
    "user_name": "User172083",
    "first_name": "User",
    "last_name": 172083,
    "doc_id": 12345,
    "learning_object": "Josh Luber: Why sneakers are a great investment",
    "resource_id": 7695,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 6.5
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 2.17
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "03. Faith Jegede: What I've learned from my autistic brothers",
    "resource_id": 5062,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.33
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "iO Tillett Wright: Fifty shades of gay",
    "resource_id": 7592,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 19.08
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 7.67
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Marlon Peterson: Am I not human? A call for criminal justice reform",
    "resource_id": 7646,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 8.34
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 6
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Alice Dreger: Is anatomy destiny?",
    "resource_id": 7587,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 10.75
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "T. Morgan Dixon and Vanessa Garrison: When Black women walk, things change",
    "resource_id": 7638,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 24.66
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Derek Sivers: How to start a movement",
    "resource_id": 7777,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 4.17
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 5.42
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "14. Misha Glenny: Hire the hackers!",
    "resource_id": 5178,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 8.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 30.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 5.33
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "10. Rick Smolan: The story of a girl",
    "resource_id": 5069,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.5
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 17.58
  },
  {
    "person_key": 167706,
    "user_name": "User167706",
    "first_name": "User",
    "last_name": 167706,
    "doc_id": 12345,
    "learning_object": "Daniel Reisel: The neuroscience of restorative justice",
    "resource_id": 7652,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 4.67
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 7834,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.17
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. Kwame Anthony Appiah: Is religion good or bad? (This is a trick question)",
    "resource_id": 5233,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 3.58
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "James Green: 3 moons and a planet that could have alien life",
    "resource_id": 7754,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 11.42
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "04. Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 5206,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 5.08
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Onora O'Neill: What we don't understand about trust",
    "resource_id": 7666,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 5.83
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 13
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 15.92
  },
  {
    "person_key": 163225,
    "user_name": "User163225",
    "first_name": "User",
    "last_name": 163225,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.08
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "15. Anthony Atala: Printing a human kidney",
    "resource_id": 5217,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 17.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 5
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "01. Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 5025,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 11.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Mary Bassett: Why your doctor should care about social justice",
    "resource_id": 7639,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 16.67
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 7680,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.34
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "03. Daniel Reisel: The neuroscience of restorative justice",
    "resource_id": 5072,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "02. Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 5071,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 24.92
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 7739,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 30
  },
  {
    "person_key": 165533,
    "user_name": "User165533",
    "first_name": "User",
    "last_name": 165533,
    "doc_id": 12345,
    "learning_object": "16. Bruno Torturra: Got a smartphone? Start broadcasting",
    "resource_id": 5180,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 12.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.92
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Salil Dudani: How jails extort the poor",
    "resource_id": 7655,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 15.58
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 10.33
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Chetan Bhatt: Dare to refuse the origin myths that claim who you are",
    "resource_id": 7669,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 53.67
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Sarah Jones: One woman, five characters, and a sex lesson from the future",
    "resource_id": 7599,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 11.08
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 7
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Jackson Katz: Violence against women -- it's a men's issue",
    "resource_id": 7575,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 2.5
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 7779,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 19.92
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Rajiv Maheswaran: The math behind basketball's wildest moves",
    "resource_id": 7673,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 9.75
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "15. Paul Pholeros: How to reduce poverty? Fix homes",
    "resource_id": 4992,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 9
  },
  {
    "person_key": 52,
    "user_name": "User52",
    "first_name": "User",
    "last_name": 52,
    "doc_id": 12345,
    "learning_object": "03. Margaret Heffernan: Dare to disagree",
    "resource_id": 5016,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 6
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 6.42
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "02. Alain de Botton: Atheism 2.0",
    "resource_id": 5234,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.25
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.08
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Mac Stone: Stunning photos of the endangered Everglades",
    "resource_id": 7698,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 22.42
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 3.25
  },
  {
    "person_key": 52,
    "user_name": "User52",
    "first_name": "User",
    "last_name": 52,
    "doc_id": 12345,
    "learning_object": "06. Manal al-Sharif: A Saudi woman who dared to drive",
    "resource_id": 5019,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 11.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 7580,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 12.08
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "02. Clint Smith: The danger of silence",
    "resource_id": 5141,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "10. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 5149,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 18.67
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "03. Bill Davenhall: Your health depends on where you live",
    "resource_id": 4980,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "08. Brené Brown: Listening to shame",
    "resource_id": 5147,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 91.42
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Second Chance Pell Program Instructions",
    "resource_id": 7671,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.67
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2",
    "resource_id": 7988,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 3.42
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 29.67
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Odinism",
    "resource_id": 6785,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.5
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Whatever Happened to Megan Marie Audiobook",
    "resource_id": 5825,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 67.67
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 4.83
  },
  {
    "person_key": 171023,
    "user_name": "User171023",
    "first_name": "User",
    "last_name": 171023,
    "doc_id": 12345,
    "learning_object": "02 Role of the Correction Officer Workbook",
    "resource_id": 5353,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 5.33
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1",
    "resource_id": 7995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.83
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3",
    "resource_id": 7994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 3.25
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 5
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1",
    "resource_id": 7998,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 6.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 47.08
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 13.5
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "Islam The Hadith",
    "resource_id": 6170,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 88.75
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 33.34
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.08
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.92
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Communicating with Your Athletes",
    "resource_id": 7031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 4
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.339996
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 29.92
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 2: The Building Blocks of Language and The Narrative Essay",
    "resource_id": 6416,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 15.16
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.92
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 35.59
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 2
  },
  {
    "person_key": 171023,
    "user_name": "User171023",
    "first_name": "User",
    "last_name": 171023,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.67
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Kidnapped Audiobook",
    "resource_id": 5856,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 56.42
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Once Upon a Crime Audiobook",
    "resource_id": 5802,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 6.58
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2",
    "resource_id": 7993,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 30.75
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 10.67
  },
  {
    "person_key": 171023,
    "user_name": "User171023",
    "first_name": "User",
    "last_name": 171023,
    "doc_id": 12345,
    "learning_object": "02 Role of the Correction Officer Workbook",
    "resource_id": 5352,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.33
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 4.92
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.75
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.16
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 60.16
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 6.09
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 70.16
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 31.17
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.33
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.41
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 55.91
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.25
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 5.75
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3",
    "resource_id": 7997,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 4.25
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1",
    "resource_id": 7992,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 3.58
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 30
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 7.09
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 31.75
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 1.92
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 15.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 29.5
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 5.42
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2",
    "resource_id": 7999,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 5.67
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 30.5
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 91.5
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 5.67
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 3.33
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3",
    "resource_id": 7989,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 30.42
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 2.83
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 31.67
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 129.17
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 2.92
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 8.5
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.58
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 31.33
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171023,
    "user_name": "User171023",
    "first_name": "User",
    "last_name": 171023,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 3.92
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-09",
    "time_spent_min": 0.660004
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 19.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Part Two: Writing Your Informative Speech",
    "resource_id": 6834,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2.25
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 1
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 4- Law Enforcement in Action",
    "resource_id": 7438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 9.83
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.25
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 60.42
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 7.08
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 91.67
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 16.83
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 1.5
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 6.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 35.34
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 6 Unit 5 Beowulf",
    "resource_id": 6513,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 3: The 5 Basic Sentence Patterns in American English and The Exemplification Essay",
    "resource_id": 6417,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2.66
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 49.25
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 54.5
  },
  {
    "person_key": 161563,
    "user_name": "User161563",
    "first_name": "User",
    "last_name": 161563,
    "doc_id": 12345,
    "learning_object": "JORDAN RIDES THE BUS",
    "resource_id": 8496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.83
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 29.92
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 15.91
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30.33
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 32.5
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 45.67
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 1.75
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 76.75
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30.25
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.67
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.42
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30.5
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 38
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.17
  },
  {
    "person_key": 73,
    "user_name": "User73",
    "first_name": "User",
    "last_name": 73,
    "doc_id": 12345,
    "learning_object": "Prayers to the Orishas",
    "resource_id": 6135,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30.42
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 31
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 31.25
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 29.92
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 16.5
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 59.42
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 4
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 5.92
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 9
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2.58
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Latif Nasser: You have no idea where camels really come from",
    "resource_id": 7614,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2.25
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 3
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 12.92
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Toby Eccles: Invest in social change",
    "resource_id": 7661,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2.08
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "04. Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 5206,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 3.33
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 17.75
  },
  {
    "person_key": 163225,
    "user_name": "User163225",
    "first_name": "User",
    "last_name": 163225,
    "doc_id": 12345,
    "learning_object": "Jedidah Isler: How I fell in love with quasars, blazars and our incredible universe",
    "resource_id": 7750,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 4.5
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2.83
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "Damon Horowitz: Philosophy in prison",
    "resource_id": 7656,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 12
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5060,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 10.83
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 11.08
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 4.5
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Jim Holt: Why does the universe exist?",
    "resource_id": 7651,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 1.5
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 4.08
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.42
  },
  {
    "person_key": 172084,
    "user_name": "User172084",
    "first_name": "User",
    "last_name": 172084,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.42
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.5
  },
  {
    "person_key": 172084,
    "user_name": "User172084",
    "first_name": "User",
    "last_name": 172084,
    "doc_id": 12345,
    "learning_object": "Beau Lotto: Optical illusions show how we see",
    "resource_id": 7790,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 5207,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 5.67
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "06. David Brooks: Should you live for your résumé ... or your eulogy?",
    "resource_id": 5084,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 18.25
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Gabriela González: How LIGO discovered gravitational waves -- and what might be next",
    "resource_id": 7773,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 14.42
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "13. Rajesh Rao: A Rosetta Stone for a lost language",
    "resource_id": 5253,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167706,
    "user_name": "User167706",
    "first_name": "User",
    "last_name": 167706,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 31.83
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "14. Suzanne Talhouk: Don't kill your language",
    "resource_id": 5254,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 6.08
  },
  {
    "person_key": 167706,
    "user_name": "User167706",
    "first_name": "User",
    "last_name": 167706,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "16. Michael Dickinson: How a fly flies",
    "resource_id": 5218,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2.75
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Brian Little: Who are you, really? The puzzle of personality",
    "resource_id": 7742,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 7.5
  },
  {
    "person_key": 172084,
    "user_name": "User172084",
    "first_name": "User",
    "last_name": 172084,
    "doc_id": 12345,
    "learning_object": "Naomi Oreskes: Why we should trust scientists",
    "resource_id": 7794,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 1.58
  },
  {
    "person_key": 169810,
    "user_name": "User169810",
    "first_name": "User",
    "last_name": 169810,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2.92
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "07. Aimee Mullins: The opportunity of adversity",
    "resource_id": 5227,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 14.41
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "01. Jim Holt: Why does the universe exist?",
    "resource_id": 5080,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 17.67
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 6.08
  },
  {
    "person_key": 163225,
    "user_name": "User163225",
    "first_name": "User",
    "last_name": 163225,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 19.75
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "02. Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 5081,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 28.42
  },
  {
    "person_key": 172084,
    "user_name": "User172084",
    "first_name": "User",
    "last_name": 172084,
    "doc_id": 12345,
    "learning_object": "Rajiv Maheswaran: The math behind basketball's wildest moves",
    "resource_id": 7673,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 5.75
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "04. Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 5195,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.09
  },
  {
    "person_key": 163225,
    "user_name": "User163225",
    "first_name": "User",
    "last_name": 163225,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 1.42
  },
  {
    "person_key": 172084,
    "user_name": "User172084",
    "first_name": "User",
    "last_name": 172084,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.25
  },
  {
    "person_key": 73,
    "user_name": "User73",
    "first_name": "User",
    "last_name": 73,
    "doc_id": 12345,
    "learning_object": "07. Dan Buettner: How to live to be 100+",
    "resource_id": 4984,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 7.42
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 7657,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 9.5
  },
  {
    "person_key": 167879,
    "user_name": "User167879",
    "first_name": "User",
    "last_name": 167879,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 7
  },
  {
    "person_key": 52,
    "user_name": "User52",
    "first_name": "User",
    "last_name": 52,
    "doc_id": 12345,
    "learning_object": "04. Kathryn Schulz: Don't regret regret",
    "resource_id": 5017,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.17
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. Eric Sanderson: New York -- before the City",
    "resource_id": 5241,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 8.33
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "12. Laurie Garrett: Lessons from the 1918 flu",
    "resource_id": 5252,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 16.17
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "18. Dan Cobley: What physics taught me about marketing",
    "resource_id": 5220,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2.92
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "10. Daniel H. Cohen: For argument’s sake",
    "resource_id": 5088,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 10.08
  },
  {
    "person_key": 172084,
    "user_name": "User172084",
    "first_name": "User",
    "last_name": 172084,
    "doc_id": 12345,
    "learning_object": "Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 7792,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "11. Anupam Mishra: The ancient ingenuity of water harvesting",
    "resource_id": 5251,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.58
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "04. Dan Gilbert: The surprising science of happiness",
    "resource_id": 5083,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "11. Anupam Mishra: The ancient ingenuity of water harvesting",
    "resource_id": 5251,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 19
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 29.91
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "12. Laurie Garrett: Lessons from the 1918 flu",
    "resource_id": 5252,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "14. Boaz Almog: The levitating superconductor",
    "resource_id": 5216,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 1.83
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 2B: Final Draft of Narrative Essay",
    "resource_id": 6418,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.09
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 7.83
  },
  {
    "person_key": 155990,
    "user_name": "User155990",
    "first_name": "User",
    "last_name": 155990,
    "doc_id": 12345,
    "learning_object": "Islam The Hadith",
    "resource_id": 6170,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 20.08
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 32.08
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "Where's Dudley Audiobook",
    "resource_id": 5828,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 2: The Building Blocks of Language and The Narrative Essay",
    "resource_id": 6416,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 85.17
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.25
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.5
  },
  {
    "person_key": 163225,
    "user_name": "User163225",
    "first_name": "User",
    "last_name": 163225,
    "doc_id": 12345,
    "learning_object": "James Green: 3 moons and a planet that could have alien life",
    "resource_id": 7754,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 44.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 76.66
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2.5
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 3- Policing Issues",
    "resource_id": 7437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 20.42
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 32.59
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 3.75
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 4 : Midsemester",
    "resource_id": 6419,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171078,
    "user_name": "User171078",
    "first_name": "User",
    "last_name": 171078,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 4.84
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 9.17
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 44.33
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30.09
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "03. Dan Gilbert: The psychology of your future self",
    "resource_id": 5082,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 8.17
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 46.75
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 23.58
  },
  {
    "person_key": 73,
    "user_name": "User73",
    "first_name": "User",
    "last_name": 73,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 29.92
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30.25
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.419998
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 151.5
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Chris Brown",
    "resource_id": 6996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 5.08
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 183.17
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 24.17
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 29.92
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "11. Barry Schwartz: Our loss of wisdom",
    "resource_id": 5089,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 30.41
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 6640,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2.08
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 1.92
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 161563,
    "user_name": "User161563",
    "first_name": "User",
    "last_name": 161563,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 61.5
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 12.33
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 83.17
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "Letter to Your 10-Year-Old Self",
    "resource_id": 7970,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 7.58
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 29.91
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 8.25
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 80
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 20.08
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 2
  },
  {
    "person_key": 160302,
    "user_name": "User160302",
    "first_name": "User",
    "last_name": 160302,
    "doc_id": 12345,
    "learning_object": "Whatever Happened to Megan Marie Audiobook",
    "resource_id": 5825,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-08",
    "time_spent_min": 29.83
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "07. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5146,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Anand Giridharadas: A tale of two Americas. And the mini-mart where they collided",
    "resource_id": 7822,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.25
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 14.42
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "02. Russell Foster: Why do we sleep?",
    "resource_id": 4979,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 3.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Alain de Botton: A kinder, gentler philosophy of success",
    "resource_id": 7825,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 2.58
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Boniface Mwangi: The day I stood up alone",
    "resource_id": 7823,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 6.83
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Aimee Mullins: The opportunity of adversity",
    "resource_id": 7816,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 22
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "T. Morgan Dixon and Vanessa Garrison: When Black women walk, things change",
    "resource_id": 7638,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 29.92
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "23. Joseph Kim: The family I lost in North Korea. And the family I gained.",
    "resource_id": 5163,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 14.58
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "07. Sheena Iyengar: The art of choosing",
    "resource_id": 5058,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 3.08
  },
  {
    "person_key": 167805,
    "user_name": "User167805",
    "first_name": "User",
    "last_name": 167805,
    "doc_id": 12345,
    "learning_object": "02. Derek Sivers: How to start a movement",
    "resource_id": 5193,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 15.08
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "08. Mikko Hypponen: Three types of online attack",
    "resource_id": 5172,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "07. Oliver Sacks: What hallucination reveals about our minds",
    "resource_id": 4962,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "04. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5143,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 6.75
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Shah Rukh Khan: Thoughts on humanity, fame and love",
    "resource_id": 7670,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 3.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 7547,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 16.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 15.67
  },
  {
    "person_key": 167805,
    "user_name": "User167805",
    "first_name": "User",
    "last_name": 167805,
    "doc_id": 12345,
    "learning_object": "08. Ludwick Marishane: A bath without water",
    "resource_id": 5199,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 11.08
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 2.17
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "08. Brené Brown: Listening to shame",
    "resource_id": 5147,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 5249,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 4
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "J.J. Abrams: The mystery box",
    "resource_id": 7606,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 30.17
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "13. Sally Kohn: Don't like clickbait? Don't click",
    "resource_id": 5177,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.42
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 15.42
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Sharon Brous: It's time to reclaim religion",
    "resource_id": 7803,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 6.25
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. Jim Holt: Why does the universe exist?",
    "resource_id": 5080,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "08. Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 4985,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.58
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "David Christian: The history of our world in 18 minutes",
    "resource_id": 7788,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 3.67
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Curtis \"Wall Street\" Carroll: How I learned to read -- and trade stocks -- in prison",
    "resource_id": 7700,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 12.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "06. Sheena Iyengar: How to make choosing easier",
    "resource_id": 5057,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.33
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "09. Onora O'Neill: What we don't understand about trust",
    "resource_id": 5087,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 11.83
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "01. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5060,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 10.75
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 29.92
  },
  {
    "person_key": 451,
    "user_name": "User451",
    "first_name": "User",
    "last_name": 451,
    "doc_id": 12345,
    "learning_object": "04. Malcolm Gladwell: The strange tale of the Norden bombsight",
    "resource_id": 5244,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 18.75
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "09. Cesar Kuriyama: One second every day",
    "resource_id": 4974,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 5.08
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "22. Jill Bolte Taylor: My stroke of insight",
    "resource_id": 5162,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.17
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Intermediate Math Formative Assessment",
    "resource_id": 9446,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10B",
    "location_id": 1697462,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 37
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Lesson 2 Quiz",
    "resource_id": 9498,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10B",
    "location_id": 1697462,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 482
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Lesson 2",
    "resource_id": 9497,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "10B",
    "location_id": 1697462,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 92
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 36.17
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 29.91
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 17.92
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 18.33
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.58
  },
  {
    "person_key": 171150,
    "user_name": "User171150",
    "first_name": "User",
    "last_name": 171150,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 11.91
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Marcus Garvey and Rasta Movement",
    "resource_id": 6144,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.5
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 21.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.66
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 72.16
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: California",
    "resource_id": 6196,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.75
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 12.34
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.33
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Second Chance Pell Program Instructions",
    "resource_id": 7671,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.34
  },
  {
    "person_key": 171162,
    "user_name": "User171162",
    "first_name": "User",
    "last_name": 171162,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 12.84
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 12.08
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 160.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 19.25
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 26.33
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Bob Marley Book",
    "resource_id": 6137,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.5
  },
  {
    "person_key": 171159,
    "user_name": "User171159",
    "first_name": "User",
    "last_name": 171159,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 67.08
  },
  {
    "person_key": 171078,
    "user_name": "User171078",
    "first_name": "User",
    "last_name": 171078,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 27.91
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "Who Has Seen the Beast Audiobook",
    "resource_id": 5829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 30
  },
  {
    "person_key": 171142,
    "user_name": "User171142",
    "first_name": "User",
    "last_name": 171142,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 14.92
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 5.16
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 29.92
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 30.75
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 5.75
  },
  {
    "person_key": 171076,
    "user_name": "User171076",
    "first_name": "User",
    "last_name": 171076,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 3.92
  },
  {
    "person_key": 171156,
    "user_name": "User171156",
    "first_name": "User",
    "last_name": 171156,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 13.41
  },
  {
    "person_key": 153633,
    "user_name": "User153633",
    "first_name": "User",
    "last_name": 153633,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 29
  },
  {
    "person_key": 171152,
    "user_name": "User171152",
    "first_name": "User",
    "last_name": 171152,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 14.33
  },
  {
    "person_key": 171146,
    "user_name": "User171146",
    "first_name": "User",
    "last_name": 171146,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 23
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 11.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 25.25
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.25
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 120.25
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 56.83
  },
  {
    "person_key": 171163,
    "user_name": "User171163",
    "first_name": "User",
    "last_name": 171163,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 8.75
  },
  {
    "person_key": 171153,
    "user_name": "User171153",
    "first_name": "User",
    "last_name": 171153,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 10.59
  },
  {
    "person_key": 171149,
    "user_name": "User171149",
    "first_name": "User",
    "last_name": 171149,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 31.58
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.339996
  },
  {
    "person_key": 451,
    "user_name": "User451",
    "first_name": "User",
    "last_name": 451,
    "doc_id": 12345,
    "learning_object": "Fard Muhammad and Elijah Muhammad",
    "resource_id": 6288,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 17.83
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 15.83
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Book Reviews",
    "resource_id": 6195,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.59
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.16
  },
  {
    "person_key": 171147,
    "user_name": "User171147",
    "first_name": "User",
    "last_name": 171147,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 47.41
  },
  {
    "person_key": 171144,
    "user_name": "User171144",
    "first_name": "User",
    "last_name": 171144,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 10.58
  },
  {
    "person_key": 171075,
    "user_name": "User171075",
    "first_name": "User",
    "last_name": 171075,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 10.5
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Letter to Your 10-Year-Old Self",
    "resource_id": 7970,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.83
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 82.83
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.5
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section One",
    "resource_id": 7975,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 34.25
  },
  {
    "person_key": 171143,
    "user_name": "User171143",
    "first_name": "User",
    "last_name": 171143,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 40.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7976,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 53.75
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 3.59
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 38.84
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Prayers to the Orishas",
    "resource_id": 6135,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.5
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 5.08
  },
  {
    "person_key": 171160,
    "user_name": "User171160",
    "first_name": "User",
    "last_name": 171160,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 11.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "14. Suzanne Talhouk: Don't kill your language",
    "resource_id": 5254,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.75
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "03. Richard Turere: My invention that made peace with lions",
    "resource_id": 5194,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 10.83
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "24. Joshua Prager: In search of the man who broke my neck",
    "resource_id": 5164,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 19.42
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 10.33
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Ronald Sullivan: How I help free innocent people from prison",
    "resource_id": 7634,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 12.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Margaret Wertheim: The beautiful math of coral",
    "resource_id": 5100,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.17
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 7.83
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "14. Misha Glenny: Hire the hackers!",
    "resource_id": 5178,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "07. Rebecca MacKinnon: Let's take back the Internet!",
    "resource_id": 5171,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.42
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Rich Benjamin: My road trip through the whitest towns in America",
    "resource_id": 7562,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.17
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Kwame Anthony Appiah: Is religion good or bad? (This is a trick question)",
    "resource_id": 7805,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.25
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Paul Tudor Jones II: Why we need to rethink capitalism",
    "resource_id": 7710,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 6.75
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 12.33
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Dan Buettner: How to live to be 100+",
    "resource_id": 7624,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Ash Beckham: We're all hiding something. Let's find the courage to open up",
    "resource_id": 7581,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 30
  },
  {
    "person_key": 451,
    "user_name": "User451",
    "first_name": "User",
    "last_name": 451,
    "doc_id": 12345,
    "learning_object": "05. William Noel: Revealing the lost codex of Archimedes",
    "resource_id": 5245,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 15.92
  },
  {
    "person_key": 451,
    "user_name": "User451",
    "first_name": "User",
    "last_name": 451,
    "doc_id": 12345,
    "learning_object": "Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 7791,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.67
  },
  {
    "person_key": 451,
    "user_name": "User451",
    "first_name": "User",
    "last_name": 451,
    "doc_id": 12345,
    "learning_object": "Ed Yong: Zombie roaches and other parasite tales",
    "resource_id": 7789,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.25
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "05. Derek Sivers: Keep your goals to yourself",
    "resource_id": 5196,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 4.25
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Christopher Bell: Bring on the female superheroes!",
    "resource_id": 7600,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.08
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "05. William Li: Can we eat to starve cancer?",
    "resource_id": 4982,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 15.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 5134,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 7.5
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "07. David Chalmers: How do you explain consciousness?",
    "resource_id": 5085,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 22.58
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "10. Laurie Santos: A monkey economy as irrational as ours",
    "resource_id": 5113,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 8.33
  },
  {
    "person_key": 451,
    "user_name": "User451",
    "first_name": "User",
    "last_name": 451,
    "doc_id": 12345,
    "learning_object": "Beau Lotto: Optical illusions show how we see",
    "resource_id": 7790,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "07. Hannah Brencher: Love letters to strangers",
    "resource_id": 5066,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "12. Laurie Garrett: Lessons from the 1918 flu",
    "resource_id": 5252,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.59
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "22. Jill Bolte Taylor: My stroke of insight",
    "resource_id": 5162,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 8.75
  },
  {
    "person_key": 167805,
    "user_name": "User167805",
    "first_name": "User",
    "last_name": 167805,
    "doc_id": 12345,
    "learning_object": "Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 7813,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Faith Jegede Cole: What I've learned from my autistic brothers",
    "resource_id": 7623,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 30
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 25.08
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "10. Clint Smith: The danger of silence",
    "resource_id": 4975,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.17
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: The doubt essential to faith",
    "resource_id": 7809,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 2.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "14. Melissa Marshall: Talk nerdy to me",
    "resource_id": 5139,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.67
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: On reading the Koran",
    "resource_id": 7810,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.33
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "10. Paul Nicklen: Tales of ice-bound wonderlands",
    "resource_id": 5123,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 3.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 5068,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.08
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 11.83
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Ingrid Betancourt: What six years in captivity taught me about fear and faith",
    "resource_id": 7804,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.5
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Karen Armstrong: Let's revive the Golden Rule",
    "resource_id": 7807,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.58
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "06. Mundano: Pimp my ... trash cart?",
    "resource_id": 4971,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 8.42
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 7806,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.08
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "Kang Lee: Can you really tell if a kid is lying?",
    "resource_id": 7747,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 14.33
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "AJ Jacobs: The world's largest family reunion ... we're all invited!",
    "resource_id": 7597,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.5
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 15.67
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "07. Aimee Mullins: The opportunity of adversity",
    "resource_id": 5227,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 5.17
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "15. Paul Piff: Does money make you mean?",
    "resource_id": 5155,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 17.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Sue Austin: Deep sea diving ... in a wheelchair",
    "resource_id": 7817,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 9.83
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "03. Bill Davenhall: Your health depends on where you live",
    "resource_id": 4980,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "09. Alexis Ohanian: How to make a splash in social media",
    "resource_id": 5173,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.33
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Rishi Manchanda: What makes us get sick? Look upstream",
    "resource_id": 7617,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "12. Jean-Baptiste Michel: The mathematics of history",
    "resource_id": 5103,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "05. Eleanor Longden: The voices in my head",
    "resource_id": 4960,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "08. Ludwick Marishane: A bath without water",
    "resource_id": 5199,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 2.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Alain de Botton: Atheism 2.0",
    "resource_id": 7808,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.83
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "10. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 5149,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.42
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 4.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 2.09
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "02. Derek Sivers: How to start a movement",
    "resource_id": 5193,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 3.75
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Kathryn Schulz: Don't regret regret",
    "resource_id": 7826,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 19.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.17
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 57.92
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "07. Oliver Sacks: What hallucination reveals about our minds",
    "resource_id": 4962,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 14.25
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 12.42
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 23.83
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "09. Cesar Kuriyama: One second every day",
    "resource_id": 4974,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 9.25
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Chetan Bhatt: Dare to refuse the origin myths that claim who you are",
    "resource_id": 7669,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.83
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "15. Keren Elazari: Hackers: the Internet's immune system",
    "resource_id": 5179,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 2
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "12. Daniel Kahneman: The riddle of experience vs. memory",
    "resource_id": 5090,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 30
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "13. Kare Anderson: Be an opportunity maker",
    "resource_id": 5138,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 6
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "06. AJ Jacobs: How healthy living nearly killed me",
    "resource_id": 4983,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 9
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "08. Chip Conley: Measuring what makes life worthwhile",
    "resource_id": 5086,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 31.42
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 9.58
  },
  {
    "person_key": 451,
    "user_name": "User451",
    "first_name": "User",
    "last_name": 451,
    "doc_id": 12345,
    "learning_object": "03. Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 5243,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 17.08
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "22. Jill Bolte Taylor: My stroke of insight",
    "resource_id": 5162,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 4.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Ruth Chang: How to make hard choices",
    "resource_id": 5059,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.34
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 8.92
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 6.08
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 2.75
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "08. Nalini Nadkarni: Life science in prison",
    "resource_id": 5077,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 2
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "05. Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 5144,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.75
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Brené Brown: Listening to shame",
    "resource_id": 7824,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 32.42
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Nabila Alibhai: Why people of different faiths are painting their houses of worship yellow",
    "resource_id": 7811,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 3.17
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Alexis Ohanian: How to make a splash in social media",
    "resource_id": 7718,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.75
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 11.42
  },
  {
    "person_key": 160614,
    "user_name": "User160614",
    "first_name": "User",
    "last_name": 160614,
    "doc_id": 12345,
    "learning_object": "05. Phil Hansen: Embrace the shake",
    "resource_id": 5225,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 20.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Morgana Bailey: The danger of hiding who you are",
    "resource_id": 7582,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.75
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 5.83
  },
  {
    "person_key": 167805,
    "user_name": "User167805",
    "first_name": "User",
    "last_name": 167805,
    "doc_id": 12345,
    "learning_object": "Zak Ebrahim: I am the son of a terrorist. Here's how I chose peace.",
    "resource_id": 7814,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Hannah Fry: The mathematics of love",
    "resource_id": 5091,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 2.75
  },
  {
    "person_key": 167805,
    "user_name": "User167805",
    "first_name": "User",
    "last_name": 167805,
    "doc_id": 12345,
    "learning_object": "01. Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 5192,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 37.92
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 1.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 0.67
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "Carol Dweck: The power of believing that you can improve",
    "resource_id": 7748,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 8.5
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Alice Goffman: How we're priming some kids for college — and others for prison",
    "resource_id": 7636,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 17.75
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "06. Cameron Herold: Let's raise kids to be entrepreneurs",
    "resource_id": 5197,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 29.92
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "04. Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 5195,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 26.33
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 16.67
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Siamak Hariri: How do you build a sacred space?",
    "resource_id": 7812,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 3.75
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Sharon Brous: It's time to reclaim religion",
    "resource_id": 7803,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-07",
    "time_spent_min": 2.92
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 2.75
  },
  {
    "person_key": 157453,
    "user_name": "User157453",
    "first_name": "User",
    "last_name": 157453,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "9B",
    "location_id": 1697460,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 54.83
  },
  {
    "person_key": 171082,
    "user_name": "User171082",
    "first_name": "User",
    "last_name": 171082,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.33
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 45.66
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 3 Quiz",
    "resource_id": 9628,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 220
  },
  {
    "person_key": 171153,
    "user_name": "User171153",
    "first_name": "User",
    "last_name": 171153,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 19.08
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 3",
    "resource_id": 9629,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 787
  },
  {
    "person_key": 171151,
    "user_name": "User171151",
    "first_name": "User",
    "last_name": 171151,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 35.08
  },
  {
    "person_key": 171150,
    "user_name": "User171150",
    "first_name": "User",
    "last_name": 171150,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.42
  },
  {
    "person_key": 171160,
    "user_name": "User171160",
    "first_name": "User",
    "last_name": 171160,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 25
  },
  {
    "person_key": 171080,
    "user_name": "User171080",
    "first_name": "User",
    "last_name": 171080,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 116.58
  },
  {
    "person_key": 171078,
    "user_name": "User171078",
    "first_name": "User",
    "last_name": 171078,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 18.67
  },
  {
    "person_key": 171069,
    "user_name": "User171069",
    "first_name": "User",
    "last_name": 171069,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 32.25
  },
  {
    "person_key": 171158,
    "user_name": "User171158",
    "first_name": "User",
    "last_name": 171158,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.17
  },
  {
    "person_key": 166026,
    "user_name": "User166026",
    "first_name": "User",
    "last_name": 166026,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 30.08
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 6.5
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.5
  },
  {
    "person_key": 171160,
    "user_name": "User171160",
    "first_name": "User",
    "last_name": 171160,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 19.5
  },
  {
    "person_key": 171147,
    "user_name": "User171147",
    "first_name": "User",
    "last_name": 171147,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 15.17
  },
  {
    "person_key": 171147,
    "user_name": "User171147",
    "first_name": "User",
    "last_name": 171147,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 2
  },
  {
    "person_key": 171149,
    "user_name": "User171149",
    "first_name": "User",
    "last_name": 171149,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Crime in America Week 2",
    "resource_id": 8003,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 5
  },
  {
    "person_key": 162330,
    "user_name": "User162330",
    "first_name": "User",
    "last_name": 162330,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 54.92
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 9.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.66
  },
  {
    "person_key": 171081,
    "user_name": "User171081",
    "first_name": "User",
    "last_name": 171081,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 5.92
  },
  {
    "person_key": 171155,
    "user_name": "User171155",
    "first_name": "User",
    "last_name": 171155,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 5.75
  },
  {
    "person_key": 171142,
    "user_name": "User171142",
    "first_name": "User",
    "last_name": 171142,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Crime in America Week 4",
    "resource_id": 8005,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 3
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 24.09
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1
  },
  {
    "person_key": 171151,
    "user_name": "User171151",
    "first_name": "User",
    "last_name": 171151,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 4- Law Enforcement in Action",
    "resource_id": 7438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 12.83
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 2: The Building Blocks of Language and The Narrative Essay",
    "resource_id": 6416,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171157,
    "user_name": "User171157",
    "first_name": "User",
    "last_name": 171157,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 11.83
  },
  {
    "person_key": 171145,
    "user_name": "User171145",
    "first_name": "User",
    "last_name": 171145,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 5
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.09
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 12.41
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 35.83
  },
  {
    "person_key": 171082,
    "user_name": "User171082",
    "first_name": "User",
    "last_name": 171082,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 10.83
  },
  {
    "person_key": 171153,
    "user_name": "User171153",
    "first_name": "User",
    "last_name": 171153,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171083,
    "user_name": "User171083",
    "first_name": "User",
    "last_name": 171083,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.75
  },
  {
    "person_key": 171071,
    "user_name": "User171071",
    "first_name": "User",
    "last_name": 171071,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.33
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.42
  },
  {
    "person_key": 171159,
    "user_name": "User171159",
    "first_name": "User",
    "last_name": 171159,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 47.92
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 62.75
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 6.25
  },
  {
    "person_key": 171079,
    "user_name": "User171079",
    "first_name": "User",
    "last_name": 171079,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 17.67
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.92
  },
  {
    "person_key": 171148,
    "user_name": "User171148",
    "first_name": "User",
    "last_name": 171148,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 19.42
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 13.58
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 2.33
  },
  {
    "person_key": 171063,
    "user_name": "User171063",
    "first_name": "User",
    "last_name": 171063,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 94.25
  },
  {
    "person_key": 171071,
    "user_name": "User171071",
    "first_name": "User",
    "last_name": 171071,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 7.33
  },
  {
    "person_key": 171068,
    "user_name": "User171068",
    "first_name": "User",
    "last_name": 171068,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 6.75
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 8.33
  },
  {
    "person_key": 171152,
    "user_name": "User171152",
    "first_name": "User",
    "last_name": 171152,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 17.42
  },
  {
    "person_key": 171074,
    "user_name": "User171074",
    "first_name": "User",
    "last_name": 171074,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 13.25
  },
  {
    "person_key": 171143,
    "user_name": "User171143",
    "first_name": "User",
    "last_name": 171143,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 21
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 29.92
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 12.58
  },
  {
    "person_key": 171079,
    "user_name": "User171079",
    "first_name": "User",
    "last_name": 171079,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171075,
    "user_name": "User171075",
    "first_name": "User",
    "last_name": 171075,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 20.33
  },
  {
    "person_key": 171142,
    "user_name": "User171142",
    "first_name": "User",
    "last_name": 171142,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 21.75
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 120.66
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 14.58
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.58
  },
  {
    "person_key": 171066,
    "user_name": "User171066",
    "first_name": "User",
    "last_name": 171066,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.42
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.92
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 171156,
    "user_name": "User171156",
    "first_name": "User",
    "last_name": 171156,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 11.67
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 59.83
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 5.58
  },
  {
    "person_key": 171073,
    "user_name": "User171073",
    "first_name": "User",
    "last_name": 171073,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 12.17
  },
  {
    "person_key": 261,
    "user_name": "User261",
    "first_name": "User",
    "last_name": 261,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.5
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 52.67
  },
  {
    "person_key": 171068,
    "user_name": "User171068",
    "first_name": "User",
    "last_name": 171068,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 40.67
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 36.08
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 21.67
  },
  {
    "person_key": 171081,
    "user_name": "User171081",
    "first_name": "User",
    "last_name": 171081,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171064,
    "user_name": "User171064",
    "first_name": "User",
    "last_name": 171064,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 11.5
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.92
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 30.5
  },
  {
    "person_key": 171085,
    "user_name": "User171085",
    "first_name": "User",
    "last_name": 171085,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 6: Wrap-up and Re-cap of the Course",
    "resource_id": 6423,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 51.25
  },
  {
    "person_key": 171087,
    "user_name": "User171087",
    "first_name": "User",
    "last_name": 171087,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 6.08
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1c: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-Reading for Writers",
    "resource_id": 6415,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.09
  },
  {
    "person_key": 171141,
    "user_name": "User171141",
    "first_name": "User",
    "last_name": 171141,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 61.5
  },
  {
    "person_key": 171083,
    "user_name": "User171083",
    "first_name": "User",
    "last_name": 171083,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 7.83
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.16
  },
  {
    "person_key": 171140,
    "user_name": "User171140",
    "first_name": "User",
    "last_name": 171140,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Mac Barnett: Why a good book is a secret door",
    "resource_id": 7598,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 18
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 7813,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.5
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "10. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 5149,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 20
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 7831,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 7.17
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "11. Ken Robinson: How schools kill creativity",
    "resource_id": 5151,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 25.42
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "19. David Pogue: 10 top time-saving tech tips",
    "resource_id": 5159,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 9.75
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "16. David Gallo: Underwater astonishments",
    "resource_id": 5156,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 7.75
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Eduardo Sáenz de Cabezón: Math is forever",
    "resource_id": 7675,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 2.92
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 13.5
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Jessica Jackley: Poverty, money -- and love",
    "resource_id": 7712,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.5
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Faith Jegede Cole: What I've learned from my autistic brothers",
    "resource_id": 7623,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Joseph Ravenell: How barbershops can keep men healthy",
    "resource_id": 7637,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.67
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Student Book - L3 Integers",
    "resource_id": 9936,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 396
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 11.5
  },
  {
    "person_key": 166658,
    "user_name": "User166658",
    "first_name": "User",
    "last_name": 166658,
    "doc_id": 12345,
    "learning_object": "David Christian: The history of our world in 18 minutes",
    "resource_id": 7788,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 49
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 19.67
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 7768,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 29.92
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 7.58
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Rishi Manchanda: What makes us get sick? Look upstream",
    "resource_id": 7617,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 29.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "05. Eleanor Longden: The voices in my head",
    "resource_id": 4960,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.42
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "18. Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 5158,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 20.75
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: How the worst moments in our lives make us who we are",
    "resource_id": 7818,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 29.92
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Student Book - L3 Integers - Quiz",
    "resource_id": 9938,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 973
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Simon Sinek: How great leaders inspire action",
    "resource_id": 7758,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Christopher Bell: Bring on the female superheroes!",
    "resource_id": 7600,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 17.08
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "20. Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 5160,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 17.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 7834,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 15.75
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 7792,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Workbook - L3 Integers (CS)",
    "resource_id": 9939,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 10236
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Ben Wellington: How we found the worst place to park in New York City -- using big data",
    "resource_id": 7686,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 8
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 5.67
  },
  {
    "person_key": 157453,
    "user_name": "User157453",
    "first_name": "User",
    "last_name": 157453,
    "doc_id": 12345,
    "learning_object": "03. Brian Greene: Is our universe the only universe?",
    "resource_id": 5205,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "9B",
    "location_id": 1697460,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 29.67
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Laurie Santos: A monkey economy as irrational as ours",
    "resource_id": 7705,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Student Book - U1 L1 Whole Numbers (CS)",
    "resource_id": 9863,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 98
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 7791,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.5
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Hannah Fry: The mathematics of love",
    "resource_id": 7674,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Amy Cuddy: Your body language may shape who you are",
    "resource_id": 7769,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.5
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Aimee Mullins: The opportunity of adversity",
    "resource_id": 7816,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 6
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Angela Lee Duckworth: Grit: The power of passion and perseverance",
    "resource_id": 7760,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.58
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 31.75
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 19.75
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Melissa Marshall: Talk nerdy to me",
    "resource_id": 7771,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.17
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Susan Pinker: The secret to living longer may be your social life",
    "resource_id": 7641,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 30
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Adam Spencer: Why I fell in love with monster prime numbers",
    "resource_id": 7676,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 15
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Cédric Villani: What's so sexy about math?",
    "resource_id": 7678,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 2.42
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "12. Dan Ariely: Are we in control of our own decisions?",
    "resource_id": 5152,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.5
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Beau Lotto: Optical illusions show how we see",
    "resource_id": 7790,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.5
  },
  {
    "person_key": 160628,
    "user_name": "User160628",
    "first_name": "User",
    "last_name": 160628,
    "doc_id": 12345,
    "learning_object": "06. Andrew Solomon: Depression, the secret we share",
    "resource_id": 4961,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 2.42
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Tania Luna: How a penny made me feel like a millionaire",
    "resource_id": 7713,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.59
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 15.33
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.33
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Regina Hartley: Why the best hire might not have the perfect resume",
    "resource_id": 7762,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: Love, no matter what",
    "resource_id": 7620,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 24.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Myriam Sidibe: The simple power of hand-washing",
    "resource_id": 7631,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 21
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "David Casarett: A doctor's case for medical marijuana",
    "resource_id": 7626,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 15.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Celeste Headlee: 10 ways to have a better conversation",
    "resource_id": 7770,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.83
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Simon Sinek: How great leaders inspire action",
    "resource_id": 7758,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.09
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Yves Morieux: How too many rules at work keep you from getting things done",
    "resource_id": 7772,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Jamie Oliver: Teach every child about food",
    "resource_id": 7616,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 2.58
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Curtis \"Wall Street\" Carroll: How I learned to read -- and trade stocks -- in prison",
    "resource_id": 7700,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 11.67
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "17. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5157,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 5.75
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Utah Department of Corrections, Adult Probation and Parole - Lone Peak",
    "location_id": 1697491,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 9.58
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 4.41
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 10.67
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Dilip Ratha: The hidden force in global economics: sending money home",
    "resource_id": 7711,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 29.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Emilie Wapnick: Why some of us don't have one true calling",
    "resource_id": 7761,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Rose George: Let's talk crap. Seriously.",
    "resource_id": 7635,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 14.42
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "21. Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 5161,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 29.92
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "02. Robert Gupta: Music is medicine, music is sanity",
    "resource_id": 4949,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 15.58
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Alix Generous: How I learned to communicate my inner life with Asperger's",
    "resource_id": 7537,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 10.42
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Naomi Oreskes: Why we should trust scientists",
    "resource_id": 7794,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.17
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 8.17
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Russell Foster: Why do we sleep?",
    "resource_id": 7615,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 16.83
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Rick Smolan: The story of a girl",
    "resource_id": 7627,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 25.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 7629,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.5
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Julian Treasure: How to speak so that people want to listen",
    "resource_id": 7765,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 5.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 10.25
  },
  {
    "person_key": 171144,
    "user_name": "User171144",
    "first_name": "User",
    "last_name": 171144,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 21.5
  },
  {
    "person_key": 171138,
    "user_name": "User171138",
    "first_name": "User",
    "last_name": 171138,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 35.25
  },
  {
    "person_key": 157453,
    "user_name": "User157453",
    "first_name": "User",
    "last_name": 157453,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "9B",
    "location_id": 1697460,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 42.25
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "Agreement #1",
    "resource_id": 8046,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171070,
    "user_name": "User171070",
    "first_name": "User",
    "last_name": 171070,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 16.92
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Crime in America Week 5",
    "resource_id": 8006,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 36.75
  },
  {
    "person_key": 171163,
    "user_name": "User171163",
    "first_name": "User",
    "last_name": 171163,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 28.25
  },
  {
    "person_key": 171085,
    "user_name": "User171085",
    "first_name": "User",
    "last_name": 171085,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 2.17
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 8
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.17
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 24.09
  },
  {
    "person_key": 171150,
    "user_name": "User171150",
    "first_name": "User",
    "last_name": 171150,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 19.67
  },
  {
    "person_key": 171086,
    "user_name": "User171086",
    "first_name": "User",
    "last_name": 171086,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.5
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 30.67
  },
  {
    "person_key": 171063,
    "user_name": "User171063",
    "first_name": "User",
    "last_name": 171063,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 12.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.09
  },
  {
    "person_key": 171146,
    "user_name": "User171146",
    "first_name": "User",
    "last_name": 171146,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 8.58
  },
  {
    "person_key": 171161,
    "user_name": "User171161",
    "first_name": "User",
    "last_name": 171161,
    "doc_id": 12345,
    "learning_object": "Part 2 - Conflict Resolution Exam Thursday",
    "resource_id": 5641,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.5
  },
  {
    "person_key": 171064,
    "user_name": "User171064",
    "first_name": "User",
    "last_name": 171064,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.42
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 5
  },
  {
    "person_key": 157453,
    "user_name": "User157453",
    "first_name": "User",
    "last_name": 157453,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "9B",
    "location_id": 1697460,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 20.17
  },
  {
    "person_key": 171067,
    "user_name": "User171067",
    "first_name": "User",
    "last_name": 171067,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 10
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 16.5
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 81.25
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 38.67
  },
  {
    "person_key": 171076,
    "user_name": "User171076",
    "first_name": "User",
    "last_name": 171076,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 31.83
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171145,
    "user_name": "User171145",
    "first_name": "User",
    "last_name": 171145,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.33
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 37.08
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171158,
    "user_name": "User171158",
    "first_name": "User",
    "last_name": 171158,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 34.92
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 171066,
    "user_name": "User171066",
    "first_name": "User",
    "last_name": 171066,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 5.83
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 4
  },
  {
    "person_key": 171141,
    "user_name": "User171141",
    "first_name": "User",
    "last_name": 171141,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 8.83
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 72.42
  },
  {
    "person_key": 171155,
    "user_name": "User171155",
    "first_name": "User",
    "last_name": 171155,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 3- Policing Issues",
    "resource_id": 7437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 51.33
  },
  {
    "person_key": 171149,
    "user_name": "User171149",
    "first_name": "User",
    "last_name": 171149,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 15.67
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 48
  },
  {
    "person_key": 171139,
    "user_name": "User171139",
    "first_name": "User",
    "last_name": 171139,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 35.08
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 9.5
  },
  {
    "person_key": 171080,
    "user_name": "User171080",
    "first_name": "User",
    "last_name": 171080,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 8.83
  },
  {
    "person_key": 171156,
    "user_name": "User171156",
    "first_name": "User",
    "last_name": 171156,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.42
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 47
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 23.58
  },
  {
    "person_key": 171073,
    "user_name": "User171073",
    "first_name": "User",
    "last_name": 171073,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "LETTER TO MY 10-YEAR-OLD SELF",
    "resource_id": 8370,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 54.83
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 13.42
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 30
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Crime in America Week 3",
    "resource_id": 8004,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 3.75
  },
  {
    "person_key": 171074,
    "user_name": "User171074",
    "first_name": "User",
    "last_name": 171074,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.830002
  },
  {
    "person_key": 171138,
    "user_name": "User171138",
    "first_name": "User",
    "last_name": 171138,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "The Way of the Orisha",
    "resource_id": 6128,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 29.91
  },
  {
    "person_key": 162330,
    "user_name": "User162330",
    "first_name": "User",
    "last_name": 162330,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 53.09
  },
  {
    "person_key": 171163,
    "user_name": "User171163",
    "first_name": "User",
    "last_name": 171163,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 18.92
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 7.42
  },
  {
    "person_key": 171075,
    "user_name": "User171075",
    "first_name": "User",
    "last_name": 171075,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171161,
    "user_name": "User171161",
    "first_name": "User",
    "last_name": 171161,
    "doc_id": 12345,
    "learning_object": "Part 1 - Conflict Resolution Exam THURSDAY - START HERE",
    "resource_id": 5743,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.08
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 8.75
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171140,
    "user_name": "User171140",
    "first_name": "User",
    "last_name": 171140,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 31.58
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.419998
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 2",
    "resource_id": 9641,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 470
  },
  {
    "person_key": 171084,
    "user_name": "User171084",
    "first_name": "User",
    "last_name": 171084,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 2
  },
  {
    "person_key": 171086,
    "user_name": "User171086",
    "first_name": "User",
    "last_name": 171086,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 36.5
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 2 Quiz",
    "resource_id": 9627,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 737
  },
  {
    "person_key": 171161,
    "user_name": "User171161",
    "first_name": "User",
    "last_name": 171161,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 32.67
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 1.25
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 59.84
  },
  {
    "person_key": 171148,
    "user_name": "User171148",
    "first_name": "User",
    "last_name": 171148,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171146,
    "user_name": "User171146",
    "first_name": "User",
    "last_name": 171146,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171162,
    "user_name": "User171162",
    "first_name": "User",
    "last_name": 171162,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 21.58
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-06",
    "time_spent_min": 15.25
  },
  {
    "person_key": 346,
    "user_name": "User346",
    "first_name": "User",
    "last_name": 346,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.08
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 1",
    "resource_id": 9640,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 98
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Literacy Math Formative Assessment",
    "resource_id": 9445,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1504
  },
  {
    "person_key": 170144,
    "user_name": "User170144",
    "first_name": "User",
    "last_name": 170144,
    "doc_id": 12345,
    "learning_object": "Lesson 1 Quiz",
    "resource_id": 9621,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 2
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3",
    "resource_id": 7997,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.75
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 23.84
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2",
    "resource_id": 7993,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.34
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 11.67
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.08
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Considerations for the Informative Speech: Chapters 14-16",
    "resource_id": 6833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 12
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 16.83
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 6.34
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.5
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1",
    "resource_id": 7992,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.25
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 30.83
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 17.5
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3",
    "resource_id": 7989,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.91
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3",
    "resource_id": 7994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.92
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.84
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 22.33
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "Bus 99 Audiobook",
    "resource_id": 5886,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.5
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "The Kemetic Tree of Life",
    "resource_id": 6122,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.410004
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 27.09
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.33
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 15
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 6.33
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 19.67
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.34
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.08
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 41.17
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 26.25
  },
  {
    "person_key": 171023,
    "user_name": "User171023",
    "first_name": "User",
    "last_name": 171023,
    "doc_id": 12345,
    "learning_object": "01 Introduction to the DOC Workbook",
    "resource_id": 5351,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.67
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.67
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 40.41
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 118.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.33
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.5
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.59
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 8.75
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 5.91
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 16.83
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.33
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.580002
  },
  {
    "person_key": 171023,
    "user_name": "User171023",
    "first_name": "User",
    "last_name": 171023,
    "doc_id": 12345,
    "learning_object": "01 Introduction to the DOC Workbook",
    "resource_id": 5350,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 40.17
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.339996
  },
  {
    "person_key": 155786,
    "user_name": "User155786",
    "first_name": "User",
    "last_name": 155786,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 9
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 36
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson Six:  Outlining Your Persuasive Speech: Chapters 24-26",
    "resource_id": 6835,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 10.42
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.16
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "JORDAN RIDES THE BUS",
    "resource_id": 8496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.5
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Bob Marley Book",
    "resource_id": 6137,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7A",
    "location_id": 1697455,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.58
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 3.42
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.330002
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.58
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.41
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 19.92
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 4.42
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 8.25
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 11.58
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1",
    "resource_id": 7995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 2.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 6.67
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 5.5
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 8.92
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.41
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 75.33
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 134.84
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.25
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 13.25
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 19
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.419998
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 16.42
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 27.17
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 35.33
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Book Reviews",
    "resource_id": 6195,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7A",
    "location_id": 1697455,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.83
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 7.91
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 9.5
  },
  {
    "person_key": 171025,
    "user_name": "User171025",
    "first_name": "User",
    "last_name": 171025,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.33
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1",
    "resource_id": 7998,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 2.41
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 4.33
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 2.33
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 49.42
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 48.92
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 4
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 4.17
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 21.25
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson Seven: The Persuasive Speech - Writing Your Final Manuscript",
    "resource_id": 6836,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.83
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.34
  },
  {
    "person_key": 155786,
    "user_name": "User155786",
    "first_name": "User",
    "last_name": 155786,
    "doc_id": 12345,
    "learning_object": "Wicca: Book of Shadows",
    "resource_id": 6117,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 4.25
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 3
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 2
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 3.34
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "When Sleeping Dogs Awaken Audiobook",
    "resource_id": 5827,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 30
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.83
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 17.41
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.830002
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 29.67
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.42
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 29.91
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2",
    "resource_id": 7999,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 3.75
  },
  {
    "person_key": 171025,
    "user_name": "User171025",
    "first_name": "User",
    "last_name": 171025,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.17
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 20.91
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.33
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2",
    "resource_id": 7988,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.25
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "09. Ben Goldacre: Battling bad science",
    "resource_id": 5211,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 18.75
  },
  {
    "person_key": 157598,
    "user_name": "User157598",
    "first_name": "User",
    "last_name": 157598,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "07. Anne Curzan: What makes a word \"real\"?",
    "resource_id": 5187,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 13.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Ruth Chang: How to make hard choices",
    "resource_id": 5059,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 19.67
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.83
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Sally Kohn: Let’s try emotional correctness",
    "resource_id": 7576,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 161745,
    "user_name": "User161745",
    "first_name": "User",
    "last_name": 161745,
    "doc_id": 12345,
    "learning_object": "03. James A. White Sr.: The little problem I had renting a house",
    "resource_id": 5027,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Anand Giridharadas: A tale of two Americas. And the mini-mart where they collided",
    "resource_id": 7822,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.09
  },
  {
    "person_key": 167711,
    "user_name": "User167711",
    "first_name": "User",
    "last_name": 167711,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.92
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Emilie Wapnick: Why some of us don't have one true calling",
    "resource_id": 7761,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.5
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "08. Stephen Hawking: Questioning the universe",
    "resource_id": 5210,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 11.17
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "David Brooks: Should you live for your résumé ... or your eulogy?",
    "resource_id": 7664,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Rutger Bregman: Poverty isn't a lack of character; it's a lack of cash",
    "resource_id": 7696,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 2.67
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Kathryn Schulz: Don't regret regret",
    "resource_id": 7826,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Kelly McGonigal: How to make stress your friend",
    "resource_id": 4993,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "01. John McWhorter: Txtng is killing language. JK!!!",
    "resource_id": 5165,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 3.08
  },
  {
    "person_key": 161745,
    "user_name": "User161745",
    "first_name": "User",
    "last_name": 161745,
    "doc_id": 12345,
    "learning_object": "Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 7653,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: BIOS Motherboards",
    "resource_id": 8226,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 32.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 5181,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "06. Sheena Iyengar: How to make choosing easier",
    "resource_id": 5057,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 7775,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.67
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 27.58
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "13. Kare Anderson: Be an opportunity maker",
    "resource_id": 5138,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Josh Luber: Why sneakers are a great investment",
    "resource_id": 7695,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 12
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Shah Rukh Khan: Thoughts on humanity, fame and love",
    "resource_id": 7670,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Aimee Mullins: The opportunity of adversity",
    "resource_id": 7816,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 30
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 13.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Erin McKean: Go ahead, make up new words!",
    "resource_id": 5188,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 2.25
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 30.08
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Brené Brown: Listening to shame",
    "resource_id": 7824,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 6.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Frances Larson: Why public beheadings get millions of views",
    "resource_id": 7828,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 7.42
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "04. Edward Snowden: Here's how we take back the Internet",
    "resource_id": 5168,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 17
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 12.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 3
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Stella Young: I'm not your inspiration, thank you very much",
    "resource_id": 7584,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.5
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Shah Rukh Khan: Thoughts on humanity, fame and love",
    "resource_id": 7670,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 15.42
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Susan Colantuono: The career advice you probably didn’t get",
    "resource_id": 5136,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.33
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "05. McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 5007,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 8.58
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "02. David Pogue: 10 top time-saving tech tips",
    "resource_id": 5166,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 20.17
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Regina Hartley: Why the best hire might not have the perfect resume",
    "resource_id": 7762,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.83
  },
  {
    "person_key": 157598,
    "user_name": "User157598",
    "first_name": "User",
    "last_name": 157598,
    "doc_id": 12345,
    "learning_object": "08. Jessica Jackley: Poverty, money -- and love",
    "resource_id": 5111,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 2.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "02. Elizabeth Gilbert: Your elusive creative genius",
    "resource_id": 5182,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.5
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154915,
    "user_name": "User154915",
    "first_name": "User",
    "last_name": 154915,
    "doc_id": 12345,
    "learning_object": "Jeff Smith: Lessons in business ... from prison",
    "resource_id": 7662,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 9.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156293,
    "user_name": "User156293",
    "first_name": "User",
    "last_name": 156293,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 9.67
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 14.83
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Mellody Hobson: Color blind or color brave?",
    "resource_id": 7568,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.67
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "04. Edward Snowden: Here's how we take back the Internet",
    "resource_id": 5168,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 38.17
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "07. Michael Shermer: The pattern behind self-deception",
    "resource_id": 5209,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 14.92
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 7778,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Jean-Paul Mari: The chilling aftershock of a brush with death",
    "resource_id": 7659,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 7.17
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "03. David Pogue: Cool tricks your phone can do",
    "resource_id": 5167,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 30.58
  },
  {
    "person_key": 161745,
    "user_name": "User161745",
    "first_name": "User",
    "last_name": 161745,
    "doc_id": 12345,
    "learning_object": "02. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5026,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 32.17
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "10. Clint Smith: The danger of silence",
    "resource_id": 4975,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 8.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Renata Salecl: Our unhealthy obsession with choice",
    "resource_id": 5054,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 7.16
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "06. Brené Brown: The power of vulnerability",
    "resource_id": 4998,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.5
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "06. Mundano: Pimp my ... trash cart?",
    "resource_id": 4971,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 6.08
  },
  {
    "person_key": 161745,
    "user_name": "User161745",
    "first_name": "User",
    "last_name": 161745,
    "doc_id": 12345,
    "learning_object": "01. Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 5025,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 18.17
  },
  {
    "person_key": 154272,
    "user_name": "User154272",
    "first_name": "User",
    "last_name": 154272,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 31
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Kandice Sumner: How America's public schools keep kids in poverty",
    "resource_id": 7645,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.08
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 7739,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7A",
    "location_id": 1697455,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Dan Gilbert: The psychology of your future self",
    "resource_id": 7663,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 7.33
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Kang Lee: Can you really tell if a kid is lying?",
    "resource_id": 7747,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Andy Puddicombe: All it takes is 10 mindful minutes",
    "resource_id": 7736,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "05. James Lyne: Everyday cybercrime -- and what you can do about it",
    "resource_id": 5169,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Curtis \"Wall Street\" Carroll: How I learned to read -- and trade stocks -- in prison",
    "resource_id": 7700,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 14.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Myriam Sidibe: The simple power of hand-washing",
    "resource_id": 4986,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.75
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Amy Cuddy: Your body language may shape who you are",
    "resource_id": 7769,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 4
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Onora O'Neill: What we don't understand about trust",
    "resource_id": 5087,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Carol Dweck: The power of believing that you can improve",
    "resource_id": 7748,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.67
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 40.83
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154272,
    "user_name": "User154272",
    "first_name": "User",
    "last_name": 154272,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: Love, no matter what",
    "resource_id": 7620,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.5
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Jessica Jackley: Poverty, money -- and love",
    "resource_id": 7712,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.17
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Courtney E. Martin: The new American Dream",
    "resource_id": 7838,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Julian Treasure: How to speak so that people want to listen",
    "resource_id": 7765,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Alexis Ohanian: How to make a splash in social media",
    "resource_id": 7718,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 6
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "09. Cesar Kuriyama: One second every day",
    "resource_id": 4974,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 8.67
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 7566,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 161745,
    "user_name": "User161745",
    "first_name": "User",
    "last_name": 161745,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Sue Austin: Deep sea diving ... in a wheelchair",
    "resource_id": 7817,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 7.83
  },
  {
    "person_key": 172096,
    "user_name": "User172096",
    "first_name": "User",
    "last_name": 172096,
    "doc_id": 12345,
    "learning_object": "Kevin Breel: Confessions of a depressed comic",
    "resource_id": 7534,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 11.25
  },
  {
    "person_key": 111,
    "user_name": "User111",
    "first_name": "User",
    "last_name": 111,
    "doc_id": 12345,
    "learning_object": "Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 7806,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Leake & Watts: Carroll",
    "location_id": 1697478,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.33
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Morgana Bailey: The danger of hiding who you are",
    "resource_id": 7582,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.75
  },
  {
    "person_key": 161745,
    "user_name": "User161745",
    "first_name": "User",
    "last_name": 161745,
    "doc_id": 12345,
    "learning_object": "Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 7650,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.83
  },
  {
    "person_key": 154915,
    "user_name": "User154915",
    "first_name": "User",
    "last_name": 154915,
    "doc_id": 12345,
    "learning_object": "Jackson Katz: Violence against women -- it's a men's issue",
    "resource_id": 7575,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 2.42
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "12. Laurie Garrett: Lessons from the 1918 flu",
    "resource_id": 5252,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.17
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "T. Morgan Dixon and Vanessa Garrison: When Black women walk, things change",
    "resource_id": 7638,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.83
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 14.17
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "11. Anupam Mishra: The ancient ingenuity of water harvesting",
    "resource_id": 5251,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.33
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Sangu Delle: There's no shame in taking care of your mental health",
    "resource_id": 7744,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.67
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 17.5
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Anand Giridharadas: A tale of two Americas. And the mini-mart where they collided",
    "resource_id": 7822,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.33
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "08. Stephen Hawking: Questioning the universe",
    "resource_id": 5210,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.17
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Laurie Santos: A monkey economy as irrational as ours",
    "resource_id": 7705,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 4.83
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "14. Suzanne Talhouk: Don't kill your language",
    "resource_id": 5254,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.33
  },
  {
    "person_key": 167711,
    "user_name": "User167711",
    "first_name": "User",
    "last_name": 167711,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 4.83
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Celeste Headlee: 10 ways to have a better conversation",
    "resource_id": 7770,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 13.67
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Julian Treasure: How to speak so that people want to listen",
    "resource_id": 7765,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 5189,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 7.75
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Boniface Mwangi: The day I stood up alone",
    "resource_id": 7823,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.17
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Amy Tan: Where does creativity hide?",
    "resource_id": 7610,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 30.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Lisa Bu: How books can open your mind",
    "resource_id": 5183,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "LaToya Ruby Frazier: A visual history of inequality in industrial America",
    "resource_id": 7837,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 4.08
  },
  {
    "person_key": 328,
    "user_name": "User328",
    "first_name": "User",
    "last_name": 328,
    "doc_id": 12345,
    "learning_object": "Kiran Bedi: A police chief with a difference",
    "resource_id": 7660,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7A",
    "location_id": 1697455,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.5
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 8.75
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Angela Lee Duckworth: Grit: The power of passion and perseverance",
    "resource_id": 7760,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 1.25
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 5.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Amy Tan: Where does creativity hide?",
    "resource_id": 5191,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5128,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.33
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 7779,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 29.92
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-05",
    "time_spent_min": 0.16
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 35.33
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 19.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "04. Shlomo Benartzi: Saving for tomorrow, tomorrow",
    "resource_id": 5107,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 11.17
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "03. Brian Greene: Is our universe the only universe?",
    "resource_id": 5205,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 4.75
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Dilip Ratha: The hidden force in global economics: sending money home",
    "resource_id": 7711,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.75
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 15.16
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Beau Lotto: Optical illusions show how we see",
    "resource_id": 7790,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.75
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 13.67
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 18.08
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Camille Seaman: Haunting photos of polar ice",
    "resource_id": 7679,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169401,
    "user_name": "User169401",
    "first_name": "User",
    "last_name": 169401,
    "doc_id": 12345,
    "learning_object": "Unit 4:  30 Years of America's War on Drugs",
    "resource_id": 7530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.33
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 23.58
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 2.5
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Safwat Saleem: Why I keep speaking up, even when people mock my accent",
    "resource_id": 7571,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1
  },
  {
    "person_key": 168081,
    "user_name": "User168081",
    "first_name": "User",
    "last_name": 168081,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "6A",
    "location_id": 1697453,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 3.75
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 3 - Victory with Honor",
    "resource_id": 6518,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Amy Cuddy: Your body language shapes who you are",
    "resource_id": 5126,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.67
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 7693,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 5.08
  },
  {
    "person_key": 156586,
    "user_name": "User156586",
    "first_name": "User",
    "last_name": 156586,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 10.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 6.33
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 8.25
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Gabriela González: How LIGO discovered gravitational waves -- and what might be next",
    "resource_id": 7773,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 17.92
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Amy Tan: Where does creativity hide?",
    "resource_id": 7610,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 28.83
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 59.83
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Graham Hill: Less stuff, more happiness",
    "resource_id": 7709,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 5.67
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Lucianne Walkowicz: Let's not use Mars as a backup planet",
    "resource_id": 7757,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 14.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Edith Widder: How we found the giant squid",
    "resource_id": 7691,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 9.58
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "03. Stephen Hawking: Questioning the universe",
    "resource_id": 5142,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 11
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 20.25
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 5.67
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "09. Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 5189,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 2.83
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 5207,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 26.92
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 14.83
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.92
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Jack Conte: How artists can (finally) get paid in the digital age",
    "resource_id": 7785,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 15.33
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 6.08
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 5140,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 20.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "07. Sheena Iyengar: The art of choosing",
    "resource_id": 5058,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 13.83
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Michael Norton: How to buy happiness",
    "resource_id": 7706,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 8.83
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Courtney E. Martin: The new American Dream",
    "resource_id": 7838,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 7.5
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Joshua Prager: In search of the man who broke my neck",
    "resource_id": 7815,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 3.5
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "When Sleeping Dogs Awaken Audiobook",
    "resource_id": 5827,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 31.33
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 7.83
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Nalini Nadkarni: Life science in prison",
    "resource_id": 7658,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Tania Luna: How a penny made me feel like a millionaire",
    "resource_id": 5112,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 6.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.75
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 14.33
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "J.J. Abrams: The mystery box",
    "resource_id": 7606,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 19.58
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Mac Stone: Stunning photos of the endangered Everglades",
    "resource_id": 7698,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 30
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.42
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 19.5
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Andrés Ruzo: The boiling river of the Amazon",
    "resource_id": 7685,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 16.83
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Lucianne Walkowicz: Let's not use Mars as a backup planet",
    "resource_id": 7757,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 8.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Jessica Jackley: Poverty, money -- and love",
    "resource_id": 5111,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 13.42
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 15.92
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Elizabeth Lev: The unheard story of the Sistine Chapel",
    "resource_id": 7839,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.92
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.5
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 56.25
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 56.59
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 7657,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 30
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.67
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 31.34
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.42
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.329987
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.59
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.83
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Damon Horowitz: Philosophy in prison",
    "resource_id": 7656,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 4.17
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "06. Phil Plait: How to defend Earth from asteroids",
    "resource_id": 5208,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 3.08
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Edith Widder: How we found the giant squid",
    "resource_id": 7691,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 10.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Amy Cuddy: Your body language may shape who you are",
    "resource_id": 7769,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 3.5
  },
  {
    "person_key": 172033,
    "user_name": "User172033",
    "first_name": "User",
    "last_name": 172033,
    "doc_id": 12345,
    "learning_object": "Literacy Math Formative Assessment",
    "resource_id": 9735,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 3
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "08. Brené Brown: Listening to shame",
    "resource_id": 5147,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 25.92
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Part 1: Summative Assessment",
    "resource_id": 10344,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 400
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.08
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.58
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 79.17
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Part 2: Summative Assessment",
    "resource_id": 10362,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 903
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 5.75
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Rachel Sussman: The world's oldest living things",
    "resource_id": 7682,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 15.5
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Yves Morieux: How too many rules at work keep you from getting things done",
    "resource_id": 7772,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Sarah Lewis: Embrace the near win",
    "resource_id": 5022,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 26.5
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Workbook - L5: Movement on Maps",
    "resource_id": 10449,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 6154
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Tania Luna: How a penny made me feel like a millionaire",
    "resource_id": 7713,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Joy Buolamwini: How I'm fighting bias in algorithms",
    "resource_id": 7721,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 8.83
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Jim Holt: Why does the universe exist?",
    "resource_id": 7651,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 19
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Alexis Ohanian: How to make a splash in social media",
    "resource_id": 7718,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 119.08
  },
  {
    "person_key": 168081,
    "user_name": "User168081",
    "first_name": "User",
    "last_name": 168081,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "6A",
    "location_id": 1697453,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 6.08
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Quiz - Unit 1 Review",
    "resource_id": 10450,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 3422
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "04. Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 5206,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 16.42
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "09. Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 5068,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 2.25
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Curtis \"Wall Street\" Carroll: How I learned to read -- and trade stocks -- in prison",
    "resource_id": 7700,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 30.5
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.59
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 7609,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.42
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Theo E.J. Wilson: A black man goes undercover in the alt-right",
    "resource_id": 7729,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 18.58
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 2.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5128,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169401,
    "user_name": "User169401",
    "first_name": "User",
    "last_name": 169401,
    "doc_id": 12345,
    "learning_object": "Unit 1:  Monitoring the Future:  National Survey Result on Drug Use 1975-2016",
    "resource_id": 7527,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.5
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Brian Cox: Why we need the explorers",
    "resource_id": 7756,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 20
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Tania Luna: How a penny made me feel like a millionaire",
    "resource_id": 7713,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Negin Farsad: A highly scientific taxonomy of haters",
    "resource_id": 7569,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 8.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 7681,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 4.33
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.75
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.34
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 72.83
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "James Green: 3 moons and a planet that could have alien life",
    "resource_id": 7754,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.09
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "09. Cesar Kuriyama: One second every day",
    "resource_id": 4974,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 22.58
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 16.5
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 9.75
  },
  {
    "person_key": 169401,
    "user_name": "User169401",
    "first_name": "User",
    "last_name": 169401,
    "doc_id": 12345,
    "learning_object": "Unit 3:  10 Reasons to Oppose the Three Strike Law",
    "resource_id": 7529,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 3.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Carol Dweck: The power of believing that you can improve",
    "resource_id": 5024,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Sheryl Sandberg: Why we have too few women leaders",
    "resource_id": 7591,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.67
  },
  {
    "person_key": 160471,
    "user_name": "User160471",
    "first_name": "User",
    "last_name": 160471,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 31.58
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 27.58
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 14.58
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 7609,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 15.75
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Faith Jegede Cole: What I've learned from my autistic brothers",
    "resource_id": 7623,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 6.5
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 2.75
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 54
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 36.42
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.92
  },
  {
    "person_key": 166026,
    "user_name": "User166026",
    "first_name": "User",
    "last_name": 166026,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 60.08
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.92
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Kenneth Lacovara: Hunting for dinosaurs showed me our place in the universe",
    "resource_id": 7829,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 23
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Laurie Santos: A monkey economy as irrational as ours",
    "resource_id": 7705,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.75
  },
  {
    "person_key": 157453,
    "user_name": "User157453",
    "first_name": "User",
    "last_name": 157453,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "9B",
    "location_id": 1697460,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 11.33
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 12
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.42
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 19.75
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 2.84
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169369,
    "user_name": "User169369",
    "first_name": "User",
    "last_name": 169369,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.66
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 18.83
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.08
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 3.41
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 119.25
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 8.25
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.25
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.59
  },
  {
    "person_key": 169452,
    "user_name": "User169452",
    "first_name": "User",
    "last_name": 169452,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2",
    "resource_id": 7988,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155786,
    "user_name": "User155786",
    "first_name": "User",
    "last_name": 155786,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 18.67
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.5
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 27.75
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Amy Tan: Where does creativity hide?",
    "resource_id": 7610,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Zaria Forman: Drawings that show the beauty and fragility of Earth",
    "resource_id": 7683,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 2.83
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.83
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 12.08
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.91
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 75.83
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 2: The Building Blocks of Language and The Narrative Essay",
    "resource_id": 6416,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 161563,
    "user_name": "User161563",
    "first_name": "User",
    "last_name": 161563,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 24.41
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 17.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Toby Eccles: Invest in social change",
    "resource_id": 7661,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 9.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 7657,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Jeff Smith: Lessons in business ... from prison",
    "resource_id": 7662,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 5.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "10. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 5149,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 7.59
  },
  {
    "person_key": 172373,
    "user_name": "User172373",
    "first_name": "User",
    "last_name": 172373,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.42
  },
  {
    "person_key": 157453,
    "user_name": "User157453",
    "first_name": "User",
    "last_name": 157453,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "9B",
    "location_id": 1697460,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169401,
    "user_name": "User169401",
    "first_name": "User",
    "last_name": 169401,
    "doc_id": 12345,
    "learning_object": "Unit 2:  Understanding Drug Abuse and Addiction:  What Science Says",
    "resource_id": 7528,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Considerations for the Informative Speech: Chapters 14-16",
    "resource_id": 6833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 2.5
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Brian Cox: Why we need the explorers",
    "resource_id": 7756,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 19.16
  },
  {
    "person_key": 162330,
    "user_name": "User162330",
    "first_name": "User",
    "last_name": 162330,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 2.83
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 7.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Susan Colantuono: The career advice you probably didn’t get",
    "resource_id": 5136,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 166026,
    "user_name": "User166026",
    "first_name": "User",
    "last_name": 166026,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 46.67
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.17
  },
  {
    "person_key": 162330,
    "user_name": "User162330",
    "first_name": "User",
    "last_name": 162330,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 24.33
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 5.41
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 7.5
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 7791,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 30.33
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 56.08
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.67
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 2 - Developing Your Coaching Objectives",
    "resource_id": 6517,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 18.83
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 7580,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 31.25
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Jessica Jackley: Poverty, money -- and love",
    "resource_id": 7712,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 34
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.910004
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 32
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 12.5
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "02. Clint Smith: The danger of silence",
    "resource_id": 5141,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 16.33
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Johanna Blakley: Social media and the end of gender",
    "resource_id": 7589,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.16
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Laurie Santos: A monkey economy as irrational as ours",
    "resource_id": 7705,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "10. iO Tillett Wright: Fifty shades of gay",
    "resource_id": 5012,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 11.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Zaria Forman: Drawings that show the beauty and fragility of Earth",
    "resource_id": 7683,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 22.08
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Shah Rukh Khan: Thoughts on humanity, fame and love",
    "resource_id": 7670,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 24.09
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: Love, no matter what",
    "resource_id": 7620,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 4.58
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Rebecca MacKinnon: Let's take back the Internet!",
    "resource_id": 7728,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "01. Paul Piff: Does money make you mean?",
    "resource_id": 5104,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 12.58
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.42
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Melissa Marshall: Talk nerdy to me",
    "resource_id": 7771,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Curtis \"Wall Street\" Carroll: How I learned to read -- and trade stocks -- in prison",
    "resource_id": 7700,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 11.33
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "James Veitch: This is what happens when you reply to spam email",
    "resource_id": 7716,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 19.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "06. Sheena Iyengar: How to make choosing easier",
    "resource_id": 5057,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 20.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.33
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Keith Chen: Could your language affect your ability to save money?",
    "resource_id": 7707,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 14.92
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "George Takei: Why I love a country that once betrayed me",
    "resource_id": 7830,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 11.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "LaToya Ruby Frazier: A visual history of inequality in industrial America",
    "resource_id": 7837,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.5
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "04. Elyn Saks: A tale of mental illness -- from the inside",
    "resource_id": 4959,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 11.41
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "07. Mustafa Akyol: Faith versus tradition in Islam",
    "resource_id": 5239,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.92
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 13.33
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Brené Brown: Listening to shame",
    "resource_id": 7824,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 5134,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 7.75
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 7547,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Kiran Bedi: A police chief with a difference",
    "resource_id": 7660,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 30
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "10. Laurie Santos: A monkey economy as irrational as ours",
    "resource_id": 5113,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "07. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5146,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 7
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 7680,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "08. Jessica Jackley: Poverty, money -- and love",
    "resource_id": 5111,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 30
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Eleanor Longden: The voices in my head",
    "resource_id": 4960,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.42
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 7831,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 18
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Hannah Fry: The mathematics of love",
    "resource_id": 7674,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 30
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 7834,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 17.25
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Angela Lee Duckworth: Grit: The power of passion and perseverance",
    "resource_id": 7760,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.83
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Simon Sinek: How great leaders inspire action",
    "resource_id": 7758,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "04. Elyn Saks: A tale of mental illness -- from the inside",
    "resource_id": 4959,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 5.83
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Camille Seaman: Haunting photos of polar ice",
    "resource_id": 7679,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 5.67
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Chris Burkard: The joy of surfing in ice-cold wate",
    "resource_id": 7697,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 9.83
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "10. Kathryn Schulz: On being wrong",
    "resource_id": 5023,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Frances Larson: Why public beheadings get millions of views",
    "resource_id": 7828,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 4.5
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "09. Thomas Insel: Toward a new understanding of mental illness",
    "resource_id": 4964,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 5018,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.09
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "David Christian: The history of our world in 18 minutes",
    "resource_id": 7788,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 18.08
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "01. Ken Robinson: How schools kill creativity",
    "resource_id": 5040,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 21.92
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Salil Dudani: How jails extort the poor",
    "resource_id": 7655,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 13.58
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 7768,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "11. Norman Spack: How I help transgender teens become who they want to be",
    "resource_id": 5013,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 9.08
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Gabriela González: How LIGO discovered gravitational waves -- and what might be next",
    "resource_id": 7773,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 15.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Suzanne Simard: How trees talk to each other",
    "resource_id": 7687,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 19.92
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 1.59
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 18.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "13. Kare Anderson: Be an opportunity maker",
    "resource_id": 5138,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 2.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "03. Jackson Katz: Violence against women—it's a men's issue",
    "resource_id": 5005,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 13.58
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Rick Smolan: The story of a girl",
    "resource_id": 7627,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 30.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 7690,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 10.67
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 3.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Keren Elazari: Hackers: the Internet's immune system",
    "resource_id": 7724,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 17
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Josh Luber: Why sneakers are a great investment",
    "resource_id": 7695,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 14
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 4.92
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Brené Brown: Listening to shame",
    "resource_id": 5014,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.25
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 167840,
    "user_name": "User167840",
    "first_name": "User",
    "last_name": 167840,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-04",
    "time_spent_min": 7.17
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Angela Lee Duckworth: Grit: The power of passion and perseverance",
    "resource_id": 7760,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 6.58
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "03. Kevin Briggs: The bridge between suicide and life",
    "resource_id": 4958,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 17.17
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Stephen Hawking: Questioning the universe",
    "resource_id": 7752,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 11.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "13. Kare Anderson: Be an opportunity maker",
    "resource_id": 5138,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.58
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 59.92
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Michael Sandel: The lost art of democratic debate",
    "resource_id": 7654,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 30
  },
  {
    "person_key": 346,
    "user_name": "User346",
    "first_name": "User",
    "last_name": 346,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 6.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "14. Melissa Marshall: Talk nerdy to me",
    "resource_id": 5139,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 4.84
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 21.33
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Nabila Alibhai: Why people of different faiths are painting their houses of worship yellow",
    "resource_id": 7811,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "George Monbiot: For more wonder, rewild the world",
    "resource_id": 7694,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5128,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.25
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Julian Treasure: How to speak so that people want to listen",
    "resource_id": 7765,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 10.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Yoruba Richen: What the gay rights movement learned from the civil rights movement",
    "resource_id": 5034,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.92
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 34.75
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 29.58
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Stella Young: I’m not your inspiration, thank you very much",
    "resource_id": 5033,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 5.58
  },
  {
    "person_key": 157598,
    "user_name": "User157598",
    "first_name": "User",
    "last_name": 157598,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.33
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 39.83
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Salil Dudani: How jails extort the poor",
    "resource_id": 7655,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 15.67
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2",
    "resource_id": 7999,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 46.75
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 21.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 5181,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Morgana Bailey: The danger of hiding who you are",
    "resource_id": 5029,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.75
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 16.33
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 19.91
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.08
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Kang Lee: Can you really tell if a kid is lying?",
    "resource_id": 7747,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 12.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Marlon Peterson: Am I not human? A call for criminal justice reform",
    "resource_id": 7646,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 12.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 18.75
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "06. Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 5030,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 69.91
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Sarah Kay: If I should have a daughter ...",
    "resource_id": 7550,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 30
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 20.5
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 34.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 19.33
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "eL Seed: Street art with a message of hope and peace",
    "resource_id": 7563,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.5
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "Islam The Meanings of Nobel Quran",
    "resource_id": 6169,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.67
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.42
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 38.92
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 32.16
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "15. Keren Elazari: Hackers: the Internet's immune system",
    "resource_id": 5179,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.92
  },
  {
    "person_key": 346,
    "user_name": "User346",
    "first_name": "User",
    "last_name": 346,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 12.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "02. Rita Pierson: Every kid needs a champion",
    "resource_id": 5041,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.42
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.25
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 10.33
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "05. Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 5144,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.08
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "09. George Monbiot: For more wonder, rewild the world",
    "resource_id": 5122,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.83
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. J.J. Abrams: The mystery box",
    "resource_id": 5185,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson Six:  Outlining Your Persuasive Speech: Chapters 24-26",
    "resource_id": 6835,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.75
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 45.66
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.419998
  },
  {
    "person_key": 346,
    "user_name": "User346",
    "first_name": "User",
    "last_name": 346,
    "doc_id": 12345,
    "learning_object": "Native American Healing Traditions",
    "resource_id": 6155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.16
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 32.16
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Erin McKean: Go ahead, make up new words!",
    "resource_id": 5188,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.33
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Rachel Sussman: The world's oldest living things",
    "resource_id": 7682,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.17
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 13.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "02. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5026,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.67
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167788,
    "user_name": "User167788",
    "first_name": "User",
    "last_name": 167788,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 9.75
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.83
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "06. Neil MacGregor: 2600 years of history in one object",
    "resource_id": 5246,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.83
  },
  {
    "person_key": 166658,
    "user_name": "User166658",
    "first_name": "User",
    "last_name": 166658,
    "doc_id": 12345,
    "learning_object": "01. Jim Holt: Why does the universe exist?",
    "resource_id": 5080,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.83
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 9.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "11. Susan Colantuono: The career advice you probably didn’t get",
    "resource_id": 5136,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 7.67
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.16
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "06. Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 5145,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 12
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 7650,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 25.67
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Daniel Reisel: The neuroscience of restorative justice",
    "resource_id": 7652,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 15.67
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 4.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Amy Cuddy: Your body language shapes who you are",
    "resource_id": 5126,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 84.34
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "John Green: The nerd's guide to learning everything online",
    "resource_id": 7715,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "04. Kathryn Schulz: Don't regret regret",
    "resource_id": 5017,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.58
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "03. Richard Turere: My invention that made peace with lions",
    "resource_id": 5194,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.25
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 7.67
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 59.83
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 11.58
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 5134,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 11.5
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "02. Beau Lotto: Optical illusions show how we see",
    "resource_id": 5204,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 37.17
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Daniel Reisel: The neuroscience of restorative justice",
    "resource_id": 7652,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 10.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "14. Suzanne Talhouk: Don't kill your language",
    "resource_id": 5254,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.17
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "10. Paul Nicklen: Tales of ice-bound wonderlands",
    "resource_id": 5123,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 9.75
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "07. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5146,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 17.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "07. Aimee Mullins: The opportunity of adversity",
    "resource_id": 5227,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.83
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 7.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "13. Sally Kohn: Don't like clickbait? Don't click",
    "resource_id": 5177,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "02. Robert Gupta: Music is medicine, music is sanity",
    "resource_id": 4949,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 10.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5221,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.16
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 9.5
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "James Green: 3 moons and a planet that could have alien life",
    "resource_id": 7754,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 26.33
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 22.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Michael Sandel: The lost art of democratic debate",
    "resource_id": 7654,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 5249,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 7681,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "10. Sally Kohn: Let’s try emotional correctness",
    "resource_id": 5035,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 5025,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.5
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Nalini Nadkarni: Life science in prison",
    "resource_id": 7658,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 5.17
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 78.83
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Sue Austin: Deep sea diving ... in a wheelchair",
    "resource_id": 5228,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 30.16
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Safwat Saleem: Why I keep speaking up, even when people mock my accent",
    "resource_id": 7571,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.08
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 25.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "06. Sugata Mitra: Build a School in the Cloud",
    "resource_id": 5045,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 19
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 29.92
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Brian Cox: Why we need the explorers",
    "resource_id": 7756,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 22.92
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 29.91
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "07. Paul Bloom: Can prejudice ever be a good thing?",
    "resource_id": 5031,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 44.33
  },
  {
    "person_key": 166532,
    "user_name": "User166532",
    "first_name": "User",
    "last_name": 166532,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.25
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 68.58
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 146.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "10. Naomi Oreskes: Why we should trust scientists",
    "resource_id": 5212,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.66
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.58
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 21.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Damon Horowitz: Philosophy in prison",
    "resource_id": 7656,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 4.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "07. Rebecca MacKinnon: Let's take back the Internet!",
    "resource_id": 5171,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.17
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 37.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Carol Dweck: The power of believing that you can improve",
    "resource_id": 5024,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 41.84
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 4.75
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "08. Brené Brown: Listening to shame",
    "resource_id": 5147,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 30
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 6.17
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Frances Larson: Why public beheadings get millions of views",
    "resource_id": 7828,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 17.5
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Toby Eccles: Invest in social change",
    "resource_id": 7661,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 19.25
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Mellody Hobson: Color blind or color brave?",
    "resource_id": 7568,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 4.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Brené Brown: Listening to shame",
    "resource_id": 5014,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.33
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 29.91
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "14. Hans and Ola Rosling: How not to be ignorant about the world",
    "resource_id": 5039,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.5
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 23
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "10. Kathryn Schulz: On being wrong",
    "resource_id": 5023,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "13. Kare Anderson: Be an opportunity maker",
    "resource_id": 5138,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.34
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "14. Melissa Marshall: Talk nerdy to me",
    "resource_id": 5139,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 7650,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 7693,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.58
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 168261,
    "user_name": "User168261",
    "first_name": "User",
    "last_name": 168261,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.33
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 6.34
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Lisa Bu: How books can open your mind",
    "resource_id": 5183,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 5207,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.5
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 90.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 7653,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 14.75
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 25.75
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "02. Elizabeth Gilbert: Your elusive creative genius",
    "resource_id": 5182,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "04. Elyn Saks: A tale of mental illness -- from the inside",
    "resource_id": 4959,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 19.17
  },
  {
    "person_key": 171018,
    "user_name": "User171018",
    "first_name": "User",
    "last_name": 171018,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 38.16
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Sally Kohn: Let’s try emotional correctness",
    "resource_id": 7576,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.33
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 10.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "14. Misha Glenny: Hire the hackers!",
    "resource_id": 5178,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.08
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 6.17
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 15.25
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Margaret Heffernan: Dare to disagree",
    "resource_id": 5016,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "12. Laurie Garrett: Lessons from the 1918 flu",
    "resource_id": 5252,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 5.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Shah Rukh Khan: Thoughts on humanity, fame and love",
    "resource_id": 7670,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.33
  },
  {
    "person_key": 166648,
    "user_name": "User166648",
    "first_name": "User",
    "last_name": 166648,
    "doc_id": 12345,
    "learning_object": "Second Chance Pell Program Instructions",
    "resource_id": 7671,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "06. Jill Bolte Taylor: My stroke of insight",
    "resource_id": 5226,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.58
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168502,
    "user_name": "User168502",
    "first_name": "User",
    "last_name": 168502,
    "doc_id": 12345,
    "learning_object": "Nagin Cox: What time is it on Mars?",
    "resource_id": 7749,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center YA",
    "location_id": 1697496,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 11.58
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 45.41
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Sarah Lewis: Embrace the near win",
    "resource_id": 5022,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.5
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 8.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 5018,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.33
  },
  {
    "person_key": 166658,
    "user_name": "User166658",
    "first_name": "User",
    "last_name": 166658,
    "doc_id": 12345,
    "learning_object": "Guillaume Néry: The exhilarating peace of freediving",
    "resource_id": 7704,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "10. Annie Murphy Paul: What we learn before we're born",
    "resource_id": 5049,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "04. Ash Beckham: We're all hiding something. Let's find the courage to open up",
    "resource_id": 5028,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.83
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. Lesley Hazleton: The doubt essential to faith",
    "resource_id": 5237,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.75
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 24.83
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 7653,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 30.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.67
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.83
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Theo E.J. Wilson: A black man goes undercover in the alt-right",
    "resource_id": 7729,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.67
  },
  {
    "person_key": 156647,
    "user_name": "User156647",
    "first_name": "User",
    "last_name": 156647,
    "doc_id": 12345,
    "learning_object": "Book Club 4",
    "resource_id": 8487,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169954,
    "user_name": "User169954",
    "first_name": "User",
    "last_name": 169954,
    "doc_id": 12345,
    "learning_object": "Lesson 1",
    "resource_id": 9814,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 20
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 46.17
  },
  {
    "person_key": 169954,
    "user_name": "User169954",
    "first_name": "User",
    "last_name": 169954,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Quiz",
    "resource_id": 9815,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 372
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 22.58
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 8.83
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.339996
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "12. Jill Tarter: Join the SETI search",
    "resource_id": 5214,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 33.5
  },
  {
    "person_key": 167788,
    "user_name": "User167788",
    "first_name": "User",
    "last_name": 167788,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 5.5
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.92
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 114.58
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 28.17
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.83
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.42
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 4.33
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.75
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 54.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.5
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 10.42
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 12.33
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "02. Alain de Botton: A kinder, gentler philosophy of success",
    "resource_id": 5015,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 13.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 2.67
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Paul Nicklen: Animal tales from icy wonderlands",
    "resource_id": 7699,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 9.83
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 6
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "04. Karen Armstrong: Let's revive the Golden Rule",
    "resource_id": 5236,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.33
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.58
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 3
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "12. Sarah Jones: A one-woman global village",
    "resource_id": 5037,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1.33
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "The Way of the Orisha",
    "resource_id": 6128,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 29.92
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 56.41
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Sanford Biggers: An artist's unflinching look at racial violence",
    "resource_id": 7544,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Sharon Brous: It's time to reclaim religion",
    "resource_id": 7803,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: On reading the Koran",
    "resource_id": 7810,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 1
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.919998
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 87.5
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Jeff Smith: Lessons in business ... from prison",
    "resource_id": 7662,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 5.83
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 29.91
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Part Two: Writing Your Informative Speech",
    "resource_id": 6834,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 29.34
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Alexis Ohanian: How to make a splash in social media",
    "resource_id": 5173,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Kiran Bedi: A police chief with a difference",
    "resource_id": 7660,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 9.67
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 31.92
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 26.5
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 4.5
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "11. Susan Colantuono: The career advice you probably didn’t get",
    "resource_id": 5136,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.25
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Siamak Hariri: How do you build a sacred space?",
    "resource_id": 7812,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.5
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Guillaume Néry: The exhilarating peace of freediving",
    "resource_id": 7704,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 0.17
  },
  {
    "person_key": 166503,
    "user_name": "User166503",
    "first_name": "User",
    "last_name": 166503,
    "doc_id": 12345,
    "learning_object": "Empress Menen",
    "resource_id": 6138,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-03",
    "time_spent_min": 29.92
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 64.5
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 6.75
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 59.83
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 2.42
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 3 - Victory with Honor",
    "resource_id": 6518,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 10.58
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 32.83
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 86.67
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 9.75
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 7.75
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 30
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "LETTER TO MY 10-YEAR-OLD SELF",
    "resource_id": 8370,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 4 - Coaching Philosophy",
    "resource_id": 6987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 7.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 1.16
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 91.42
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 7.41
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 97
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 33.92
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 3.67
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Losing Your Way",
    "resource_id": 8509,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.75
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 29.58
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 2.42
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 30.08
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 30.91
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 59.5
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "${date}",
    "resource_id": 8524,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.75
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 60
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 29.92
  },
  {
    "person_key": 161563,
    "user_name": "User161563",
    "first_name": "User",
    "last_name": 161563,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 58.17
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 31
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 19
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 9.09
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Life Map",
    "resource_id": 8369,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 14.42
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 74.09
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 59.83
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 29.92
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 76.17
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Chris Anderson: TED's secret to great public speaking",
    "resource_id": 7767,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 27.33
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Melati and Isabel Wijsen: Our campaign to ban plastic bags in Bali",
    "resource_id": 7780,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 23
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Kandice Sumner: How America's public schools keep kids in poverty",
    "resource_id": 7645,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 30
  },
  {
    "person_key": 172094,
    "user_name": "User172094",
    "first_name": "User",
    "last_name": 172094,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 1.08
  },
  {
    "person_key": 167950,
    "user_name": "User167950",
    "first_name": "User",
    "last_name": 167950,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 12.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 14.67
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "06. Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 5030,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 10
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "11. Terry Moore: Why is 'x' the unknown?",
    "resource_id": 5102,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 9.58
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "12. Julian Treasure: 5 ways to listen better",
    "resource_id": 5137,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 18.17
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Test-taking Tips",
    "resource_id": 9786,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 46
  },
  {
    "person_key": 156515,
    "user_name": "User156515",
    "first_name": "User",
    "last_name": 156515,
    "doc_id": 12345,
    "learning_object": "05. Pearl Arredondo: My story, from gangland daughter to star teacher",
    "resource_id": 5044,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 2.92
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Sharon Brous: It's time to reclaim religion",
    "resource_id": 7803,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 29.92
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "About High School Equivalency Test Preparation",
    "resource_id": 9784,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 43
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Before You Begin Using Logic and Making Assumptions",
    "resource_id": 9788,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 153
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Kevin Breel: Confessions of a depressed comic",
    "resource_id": 7534,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "James A. White Sr.: The little problem I had renting a house",
    "resource_id": 7579,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 13.83
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Mellody Hobson: Color blind or color brave?",
    "resource_id": 7568,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.33
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Quiz",
    "resource_id": 9714,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 1142
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 19
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Rich Benjamin: My road trip through the whitest towns in America",
    "resource_id": 7562,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 13.5
  },
  {
    "person_key": 172092,
    "user_name": "User172092",
    "first_name": "User",
    "last_name": 172092,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.17
  },
  {
    "person_key": 172092,
    "user_name": "User172092",
    "first_name": "User",
    "last_name": 172092,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 17.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "07. Paul Bloom: Can prejudice ever be a good thing?",
    "resource_id": 5031,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 3.67
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 7653,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 13.42
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "05. McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 5007,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 2.75
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Richard Dawkins: Why the universe seems so strange",
    "resource_id": 7733,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 15.25
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "About High School Equivalency Test Preparation Science",
    "resource_id": 9785,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 31
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Julian Treasure: How to speak so that people want to listen",
    "resource_id": 7765,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 14.5
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Study Skills",
    "resource_id": 9787,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 41
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "About High School Equivalency Tests",
    "resource_id": 9713,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 42
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Lesson 16",
    "resource_id": 9488,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 90
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Lesson 17",
    "resource_id": 9490,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 55
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Lesson 17 Quiz",
    "resource_id": 9491,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 164
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Lesson 16 Quiz",
    "resource_id": 9489,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 798
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Alice Dreger: Is anatomy destiny?",
    "resource_id": 7587,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167950,
    "user_name": "User167950",
    "first_name": "User",
    "last_name": 167950,
    "doc_id": 12345,
    "learning_object": "Zak Ebrahim: I am the son of a terrorist. Here's how I chose peace.",
    "resource_id": 7814,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Kathryn Schulz: Don't regret regret",
    "resource_id": 7826,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 10.75
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Sarah Jones: One woman, five characters, and a sex lesson from the future",
    "resource_id": 7599,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 33
  },
  {
    "person_key": 172094,
    "user_name": "User172094",
    "first_name": "User",
    "last_name": 172094,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Boniface Mwangi: The day I stood up alone",
    "resource_id": 7823,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 8.17
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "Tim Ferriss: Why you should define your fears instead of your goals",
    "resource_id": 7745,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Morgana Bailey: The danger of hiding who you are",
    "resource_id": 7582,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.83
  },
  {
    "person_key": 156515,
    "user_name": "User156515",
    "first_name": "User",
    "last_name": 156515,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 2
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 7566,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Simon Sinek: How great leaders inspire action",
    "resource_id": 7758,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Camille Seaman: Haunting photos of polar ice",
    "resource_id": 7679,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 2.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "02. Robert Gupta: Music is medicine, music is sanity",
    "resource_id": 4949,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156515,
    "user_name": "User156515",
    "first_name": "User",
    "last_name": 156515,
    "doc_id": 12345,
    "learning_object": "05. Eleanor Longden: The voices in my head",
    "resource_id": 4960,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 1.33
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Theo E.J. Wilson: A black man goes undercover in the alt-right",
    "resource_id": 7729,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 24.75
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 5.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "14. Melissa Marshall: Talk nerdy to me",
    "resource_id": 5139,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 3.91
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Stella Young: I'm not your inspiration, thank you very much",
    "resource_id": 7584,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 10.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Celeste Headlee: 10 ways to have a better conversation",
    "resource_id": 7770,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 18
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Onora O'Neill: What we don't understand about trust",
    "resource_id": 7666,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.09
  },
  {
    "person_key": 156515,
    "user_name": "User156515",
    "first_name": "User",
    "last_name": 156515,
    "doc_id": 12345,
    "learning_object": "04. Elyn Saks: A tale of mental illness -- from the inside",
    "resource_id": 4959,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156515,
    "user_name": "User156515",
    "first_name": "User",
    "last_name": 156515,
    "doc_id": 12345,
    "learning_object": "10. iO Tillett Wright: Fifty shades of gay",
    "resource_id": 5012,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 9.33
  },
  {
    "person_key": 156515,
    "user_name": "User156515",
    "first_name": "User",
    "last_name": 156515,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 22.67
  },
  {
    "person_key": 156515,
    "user_name": "User156515",
    "first_name": "User",
    "last_name": 156515,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 1.67
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 29.92
  },
  {
    "person_key": 172094,
    "user_name": "User172094",
    "first_name": "User",
    "last_name": 172094,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 19.42
  },
  {
    "person_key": 156515,
    "user_name": "User156515",
    "first_name": "User",
    "last_name": 156515,
    "doc_id": 12345,
    "learning_object": "08. LZ Granderson: The myth of the gay agenda",
    "resource_id": 5067,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Frances Larson: Why public beheadings get millions of views",
    "resource_id": 7828,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 17.08
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "John Green: The nerd's guide to learning everything online",
    "resource_id": 7715,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 19
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "06. Neil MacGregor: 2600 years of history in one object",
    "resource_id": 5246,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 24.92
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "09. Yoruba Richen: What the gay rights movement learned from the civil rights movement",
    "resource_id": 5034,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "08. Stella Young: I’m not your inspiration, thank you very much",
    "resource_id": 5033,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 4.25
  },
  {
    "person_key": 172094,
    "user_name": "User172094",
    "first_name": "User",
    "last_name": 172094,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167950,
    "user_name": "User167950",
    "first_name": "User",
    "last_name": 167950,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 7.5
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 6.17
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.75
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Sally Kohn: Let’s try emotional correctness",
    "resource_id": 7576,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.58
  },
  {
    "person_key": 172094,
    "user_name": "User172094",
    "first_name": "User",
    "last_name": 172094,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 12.08
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 8.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "13. Kare Anderson: Be an opportunity maker",
    "resource_id": 5138,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.58
  },
  {
    "person_key": 167706,
    "user_name": "User167706",
    "first_name": "User",
    "last_name": 167706,
    "doc_id": 12345,
    "learning_object": "Rick Smolan: The story of a girl",
    "resource_id": 7627,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 51.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 6.34
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Sally Kohn: Let’s try emotional correctness",
    "resource_id": 7576,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.17
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 25.75
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 2.25
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "08. Johanna Blakley: Social media and the end of gender",
    "resource_id": 5010,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 8.58
  },
  {
    "person_key": 169852,
    "user_name": "User169852",
    "first_name": "User",
    "last_name": 169852,
    "doc_id": 12345,
    "learning_object": "01. Amy Cuddy: Your body language shapes who you are",
    "resource_id": 5126,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 2.83
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "AJ Jacobs: The world's largest family reunion ... we're all invited!",
    "resource_id": 7597,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Richard Turere: My invention that made peace with lions",
    "resource_id": 7776,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 7.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 7779,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 5.5
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 7831,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.75
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "01. Paul Piff: Does money make you mean?",
    "resource_id": 5104,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 21.25
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Morgana Bailey: The danger of hiding who you are",
    "resource_id": 7582,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.17
  },
  {
    "person_key": 167706,
    "user_name": "User167706",
    "first_name": "User",
    "last_name": 167706,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 6.42
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 48.25
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Derek Sivers: How to start a movement",
    "resource_id": 7777,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.17
  },
  {
    "person_key": 172097,
    "user_name": "User172097",
    "first_name": "User",
    "last_name": 172097,
    "doc_id": 12345,
    "learning_object": "Jeff Smith: Lessons in business ... from prison",
    "resource_id": 7662,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 6.67
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 7775,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 10.83
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 29.92
  },
  {
    "person_key": 167950,
    "user_name": "User167950",
    "first_name": "User",
    "last_name": 167950,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 17.58
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Temple Grandin: The world needs all kinds of minds",
    "resource_id": 7538,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 29.17
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 90.67
  },
  {
    "person_key": 171018,
    "user_name": "User171018",
    "first_name": "User",
    "last_name": 171018,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 21.67
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 44.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Communicating with Your Athletes",
    "resource_id": 7031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.75
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 35.5
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 1.5
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Writing Memoir",
    "resource_id": 8508,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 15.75
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 32.09
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 23.42
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 69.08
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 22
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 59.83
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 171031,
    "user_name": "User171031",
    "first_name": "User",
    "last_name": 171031,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 30
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 41.58
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 13.75
  },
  {
    "person_key": 171016,
    "user_name": "User171016",
    "first_name": "User",
    "last_name": 171016,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.92
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 3.67
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 72.75
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 29.58
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 10.08
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.159973
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 107
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 29.92
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 223.25
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 31.42
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 2.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 36.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 5.58
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 11.58
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 1.08
  },
  {
    "person_key": 171014,
    "user_name": "User171014",
    "first_name": "User",
    "last_name": 171014,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-02",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.17
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.67
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.25
  },
  {
    "person_key": 171029,
    "user_name": "User171029",
    "first_name": "User",
    "last_name": 171029,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 33.83
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3.25
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 9.58
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "08. Nalini Nadkarni: Life science in prison",
    "resource_id": 5077,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.33
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "James Green: 3 moons and a planet that could have alien life",
    "resource_id": 7754,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 11.58
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Gabriela González: How LIGO discovered gravitational waves -- and what might be next",
    "resource_id": 7773,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "06. Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 5030,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.58
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Jean-Paul Mari: The chilling aftershock of a brush with death",
    "resource_id": 7659,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.67
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 21
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "01. Jim Holt: Why does the universe exist?",
    "resource_id": 5080,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.75
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 59.92
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "08. Nalini Nadkarni: Life science in prison",
    "resource_id": 5077,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.17
  },
  {
    "person_key": 168024,
    "user_name": "User168024",
    "first_name": "User",
    "last_name": 168024,
    "doc_id": 12345,
    "learning_object": "01. Paul Piff: Does money make you mean?",
    "resource_id": 5104,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 17.25
  },
  {
    "person_key": 170931,
    "user_name": "User170931",
    "first_name": "User",
    "last_name": 170931,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.75
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Richard Dawkins: Why the universe seems so strange",
    "resource_id": 7733,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.33
  },
  {
    "person_key": 170924,
    "user_name": "User170924",
    "first_name": "User",
    "last_name": 170924,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 38.5
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 7768,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 9.42
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 18.83
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Yves Morieux: How too many rules at work keep you from getting things done",
    "resource_id": 7772,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.92
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 19.75
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Toby Eccles: Invest in social change",
    "resource_id": 7661,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.67
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.67
  },
  {
    "person_key": 168024,
    "user_name": "User168024",
    "first_name": "User",
    "last_name": 168024,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.75
  },
  {
    "person_key": 171011,
    "user_name": "User171011",
    "first_name": "User",
    "last_name": 171011,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 47.67
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Carol Dweck: The power of believing that you can improve",
    "resource_id": 7748,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.92
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "08. Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 4985,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.83
  },
  {
    "person_key": 431,
    "user_name": "User431",
    "first_name": "User",
    "last_name": 431,
    "doc_id": 12345,
    "learning_object": "02. Beau Lotto: Optical illusions show how we see",
    "resource_id": 5204,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 18.17
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "06. AJ Jacobs: How healthy living nearly killed me",
    "resource_id": 4983,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 5.67
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Jessica Jackley: Poverty, money -- and love",
    "resource_id": 7712,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 10
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Phil Plait: How to defend Earth from asteroids",
    "resource_id": 7732,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 19.5
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168502,
    "user_name": "User168502",
    "first_name": "User",
    "last_name": 168502,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center YA",
    "location_id": 1697496,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 7.25
  },
  {
    "person_key": 168024,
    "user_name": "User168024",
    "first_name": "User",
    "last_name": 168024,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.17
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "05. Morgana Bailey: The danger of hiding who you are",
    "resource_id": 5029,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.17
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Kang Lee: Can you really tell if a kid is lying?",
    "resource_id": 7747,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4
  },
  {
    "person_key": 171096,
    "user_name": "User171096",
    "first_name": "User",
    "last_name": 171096,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169885,
    "user_name": "User169885",
    "first_name": "User",
    "last_name": 169885,
    "doc_id": 12345,
    "learning_object": "Misha Glenny: Hire the hackers!",
    "resource_id": 7723,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170929,
    "user_name": "User170929",
    "first_name": "User",
    "last_name": 170929,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 16.75
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Richard Dawkins: Why the universe seems so strange",
    "resource_id": 7733,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 11.67
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Camille Seaman: Haunting photos of polar ice",
    "resource_id": 7679,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.33
  },
  {
    "person_key": 171099,
    "user_name": "User171099",
    "first_name": "User",
    "last_name": 171099,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168502,
    "user_name": "User168502",
    "first_name": "User",
    "last_name": 168502,
    "doc_id": 12345,
    "learning_object": "Lucianne Walkowicz: Let's not use Mars as a backup planet",
    "resource_id": 7757,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center YA",
    "location_id": 1697496,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.67
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Dan Buettner: How to live to be 100+",
    "resource_id": 7624,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 93.67
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 5: Data Visualization",
    "resource_id": 7499,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.67
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Celeste Headlee: 10 ways to have a better conversation",
    "resource_id": 7770,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 7629,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Faith Jegede Cole: What I've learned from my autistic brothers",
    "resource_id": 7623,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168502,
    "user_name": "User168502",
    "first_name": "User",
    "last_name": 168502,
    "doc_id": 12345,
    "learning_object": "Phil Plait: How to defend Earth from asteroids",
    "resource_id": 7732,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center YA",
    "location_id": 1697496,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.58
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Elyn Saks: A tale of mental illness -- from the inside",
    "resource_id": 7548,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.83
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: The doubt essential to faith",
    "resource_id": 7809,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.33
  },
  {
    "person_key": 172099,
    "user_name": "User172099",
    "first_name": "User",
    "last_name": 172099,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.67
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 15.25
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 7653,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.5
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Brian Greene: Is our universe the only universe?",
    "resource_id": 7751,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 18.58
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3.83
  },
  {
    "person_key": 171112,
    "user_name": "User171112",
    "first_name": "User",
    "last_name": 171112,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 22.58
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "02. Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 5071,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 21.33
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Amy Tan: Where does creativity hide?",
    "resource_id": 7610,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Nagin Cox: What time is it on Mars?",
    "resource_id": 7749,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 17.92
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "12. Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 5125,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 5.5
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "Angela Lee Duckworth: Grit: The power of passion and perseverance",
    "resource_id": 7760,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Susan Pinker: The secret to living longer may be your social life",
    "resource_id": 7641,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "07. Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 5198,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3.08
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "02. Russell Foster: Why do we sleep?",
    "resource_id": 4979,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 10.08
  },
  {
    "person_key": 170910,
    "user_name": "User170910",
    "first_name": "User",
    "last_name": 170910,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 36.08
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "10. Sally Kohn: Let’s try emotional correctness",
    "resource_id": 5035,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Rebecca Brachman: Could a drug prevent depression and PTSD?",
    "resource_id": 7619,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.75
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Amy Cuddy: Your body language may shape who you are",
    "resource_id": 7769,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.75
  },
  {
    "person_key": 171025,
    "user_name": "User171025",
    "first_name": "User",
    "last_name": 171025,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.83
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.5
  },
  {
    "person_key": 170909,
    "user_name": "User170909",
    "first_name": "User",
    "last_name": 170909,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 10.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 10
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Christopher Bell: Bring on the female superheroes!",
    "resource_id": 7600,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 15.92
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 22
  },
  {
    "person_key": 168024,
    "user_name": "User168024",
    "first_name": "User",
    "last_name": 168024,
    "doc_id": 12345,
    "learning_object": "02. Russell Foster: Why do we sleep?",
    "resource_id": 4979,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 18.42
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Mac Stone: Stunning photos of the endangered Everglades",
    "resource_id": 7698,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3.58
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Nalini Nadkarni: Life science in prison",
    "resource_id": 7658,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 9.08
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: Love, no matter what",
    "resource_id": 7620,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171033,
    "user_name": "User171033",
    "first_name": "User",
    "last_name": 171033,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 13.17
  },
  {
    "person_key": 171110,
    "user_name": "User171110",
    "first_name": "User",
    "last_name": 171110,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 15
  },
  {
    "person_key": 170911,
    "user_name": "User170911",
    "first_name": "User",
    "last_name": 170911,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Susan Pinker: The secret to living longer may be your social life",
    "resource_id": 7641,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 46.67
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Paul Nicklen: Animal tales from icy wonderlands",
    "resource_id": 7699,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.58
  },
  {
    "person_key": 171090,
    "user_name": "User171090",
    "first_name": "User",
    "last_name": 171090,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Faith Jegede Cole: What I've learned from my autistic brothers",
    "resource_id": 7623,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L4: Compare and Contrast (CS)",
    "resource_id": 10476,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 20
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L3: Summarize - Quiz",
    "resource_id": 10473,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 8
  },
  {
    "person_key": 171101,
    "user_name": "User171101",
    "first_name": "User",
    "last_name": 171101,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171028,
    "user_name": "User171028",
    "first_name": "User",
    "last_name": 171028,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 47.67
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Workbook - L4: Compare and Contrast (CS)",
    "resource_id": 10478,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 5118
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 8.83
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L4: Compare and Contrast (CS) - Quiz",
    "resource_id": 10477,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 572
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 25.58
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Susan Pinker: The secret to living longer may be your social life",
    "resource_id": 7641,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 50.25
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Brian Greene: Is our universe the only universe?",
    "resource_id": 7751,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 36.92
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 34.58
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.25
  },
  {
    "person_key": 169885,
    "user_name": "User169885",
    "first_name": "User",
    "last_name": 169885,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 7653,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169317,
    "user_name": "User169317",
    "first_name": "User",
    "last_name": 169317,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3.58
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Marlon Peterson: Am I not human? A call for criminal justice reform",
    "resource_id": 7646,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 170933,
    "user_name": "User170933",
    "first_name": "User",
    "last_name": 170933,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 7792,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 7650,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 38.92
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "12. Sarah Jones: A one-woman global village",
    "resource_id": 5037,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 17.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Amy Tan: Where does creativity hide?",
    "resource_id": 7610,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.83
  },
  {
    "person_key": 170927,
    "user_name": "User170927",
    "first_name": "User",
    "last_name": 170927,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 13.67
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "07. Julian Treasure: How to speak so that people want to listen",
    "resource_id": 5132,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 10.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 18.92
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.67
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Mary Bassett: Why your doctor should care about social justice",
    "resource_id": 7639,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.67
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Stella Young: I'm not your inspiration, thank you very much",
    "resource_id": 7584,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156515,
    "user_name": "User156515",
    "first_name": "User",
    "last_name": 156515,
    "doc_id": 12345,
    "learning_object": "03. Jackson Katz: Violence against women—it's a men's issue",
    "resource_id": 5005,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.92
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Salil Dudani: How jails extort the poor",
    "resource_id": 7655,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 13.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Mary Bassett: Why your doctor should care about social justice",
    "resource_id": 7639,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171035,
    "user_name": "User171035",
    "first_name": "User",
    "last_name": 171035,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Damon Horowitz: Philosophy in prison",
    "resource_id": 7656,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.33
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "11. Ben Saunders: To the South Pole and back — the hardest 105 days of my life",
    "resource_id": 5124,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Morgana Bailey: The danger of hiding who you are",
    "resource_id": 7582,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Susan Pinker: The secret to living longer may be your social life",
    "resource_id": 7641,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162633,
    "user_name": "User162633",
    "first_name": "User",
    "last_name": 162633,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 29.17
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Damon Horowitz: Philosophy in prison",
    "resource_id": 7656,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3
  },
  {
    "person_key": 156515,
    "user_name": "User156515",
    "first_name": "User",
    "last_name": 156515,
    "doc_id": 12345,
    "learning_object": "11. Norman Spack: How I help transgender teens become who they want to be",
    "resource_id": 5013,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.33
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3.58
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Sally Kohn: Let’s try emotional correctness",
    "resource_id": 7576,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.58
  },
  {
    "person_key": 166904,
    "user_name": "User166904",
    "first_name": "User",
    "last_name": 166904,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.75
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.5
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.58
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.5
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Naomi Oreskes: Why we should trust scientists",
    "resource_id": 7794,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.42
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Sangu Delle: There's no shame in taking care of your mental health",
    "resource_id": 7744,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Alice Dreger: Is anatomy destiny?",
    "resource_id": 7587,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Nalini Nadkarni: Life science in prison",
    "resource_id": 7658,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.92
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 7566,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.67
  },
  {
    "person_key": 168502,
    "user_name": "User168502",
    "first_name": "User",
    "last_name": 168502,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center YA",
    "location_id": 1697496,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Amy Cuddy: Your body language may shape who you are",
    "resource_id": 7769,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3
  },
  {
    "person_key": 171035,
    "user_name": "User171035",
    "first_name": "User",
    "last_name": 171035,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 32.58
  },
  {
    "person_key": 170908,
    "user_name": "User170908",
    "first_name": "User",
    "last_name": 170908,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 166904,
    "user_name": "User166904",
    "first_name": "User",
    "last_name": 166904,
    "doc_id": 12345,
    "learning_object": "Rishi Manchanda: What makes us get sick? Look upstream",
    "resource_id": 7617,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 5.58
  },
  {
    "person_key": 171094,
    "user_name": "User171094",
    "first_name": "User",
    "last_name": 171094,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171096,
    "user_name": "User171096",
    "first_name": "User",
    "last_name": 171096,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 14.83
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Nagin Cox: What time is it on Mars?",
    "resource_id": 7749,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 14.75
  },
  {
    "person_key": 171097,
    "user_name": "User171097",
    "first_name": "User",
    "last_name": 171097,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171023,
    "user_name": "User171023",
    "first_name": "User",
    "last_name": 171023,
    "doc_id": 12345,
    "learning_object": "01 Introduction to the DOC Workbook",
    "resource_id": 5350,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Jeff Smith: Lessons in business ... from prison",
    "resource_id": 7662,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.92
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Johanna Blakley: Social media and the end of gender",
    "resource_id": 7589,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 5
  },
  {
    "person_key": 169885,
    "user_name": "User169885",
    "first_name": "User",
    "last_name": 169885,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 7680,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 7.67
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Melissa Marshall: Talk nerdy to me",
    "resource_id": 7771,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.42
  },
  {
    "person_key": 170925,
    "user_name": "User170925",
    "first_name": "User",
    "last_name": 170925,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 29.92
  },
  {
    "person_key": 170918,
    "user_name": "User170918",
    "first_name": "User",
    "last_name": 170918,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 11.33
  },
  {
    "person_key": 171091,
    "user_name": "User171091",
    "first_name": "User",
    "last_name": 171091,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 33.67
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 60.42
  },
  {
    "person_key": 171101,
    "user_name": "User171101",
    "first_name": "User",
    "last_name": 171101,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 14.75
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "A Midsummers Night Dream Audiobook",
    "resource_id": 5781,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3.5
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Karen Armstrong: Let's revive the Golden Rule",
    "resource_id": 7807,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.33
  },
  {
    "person_key": 171095,
    "user_name": "User171095",
    "first_name": "User",
    "last_name": 171095,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 9.08
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 7792,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.83
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "Pride and Prejudice Audiobook",
    "resource_id": 5858,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 25.42
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "David Casarett: A doctor's case for medical marijuana",
    "resource_id": 7626,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 16.42
  },
  {
    "person_key": 170913,
    "user_name": "User170913",
    "first_name": "User",
    "last_name": 170913,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 53.42
  },
  {
    "person_key": 166904,
    "user_name": "User166904",
    "first_name": "User",
    "last_name": 166904,
    "doc_id": 12345,
    "learning_object": "Richard Dawkins: Why the universe seems so strange",
    "resource_id": 7733,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.83
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 37.75
  },
  {
    "person_key": 171018,
    "user_name": "User171018",
    "first_name": "User",
    "last_name": 171018,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 43.5
  },
  {
    "person_key": 171112,
    "user_name": "User171112",
    "first_name": "User",
    "last_name": 171112,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 9.08
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "09. Yoruba Richen: What the gay rights movement learned from the civil rights movement",
    "resource_id": 5034,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 171090,
    "user_name": "User171090",
    "first_name": "User",
    "last_name": 171090,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 34
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 143.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Mellody Hobson: Color blind or color brave?",
    "resource_id": 7568,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.67
  },
  {
    "person_key": 170915,
    "user_name": "User170915",
    "first_name": "User",
    "last_name": 170915,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 13.75
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: How the worst moments in our lives make us who we are",
    "resource_id": 7818,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169885,
    "user_name": "User169885",
    "first_name": "User",
    "last_name": 169885,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 10.25
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Phil Plait: How to defend Earth from asteroids",
    "resource_id": 7732,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 15.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Shah Rukh Khan: Thoughts on humanity, fame and love",
    "resource_id": 7670,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.42
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "Historical Books",
    "resource_id": 7384,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 13.42
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 30.25
  },
  {
    "person_key": 170926,
    "user_name": "User170926",
    "first_name": "User",
    "last_name": 170926,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170912,
    "user_name": "User170912",
    "first_name": "User",
    "last_name": 170912,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 171106,
    "user_name": "User171106",
    "first_name": "User",
    "last_name": 171106,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 37.92
  },
  {
    "person_key": 153671,
    "user_name": "User153671",
    "first_name": "User",
    "last_name": 153671,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 48.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Tania Luna: How a penny made me feel like a millionaire",
    "resource_id": 7713,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 4 : Midsemester",
    "resource_id": 6419,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "06. Sheena Iyengar: How to make choosing easier",
    "resource_id": 5057,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.83
  },
  {
    "person_key": 171102,
    "user_name": "User171102",
    "first_name": "User",
    "last_name": 171102,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 49.5
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 18.67
  },
  {
    "person_key": 171023,
    "user_name": "User171023",
    "first_name": "User",
    "last_name": 171023,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 13.83
  },
  {
    "person_key": 170931,
    "user_name": "User170931",
    "first_name": "User",
    "last_name": 170931,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 47.83
  },
  {
    "person_key": 170917,
    "user_name": "User170917",
    "first_name": "User",
    "last_name": 170917,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 13.67
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 16.58
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 63.25
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 15.42
  },
  {
    "person_key": 171089,
    "user_name": "User171089",
    "first_name": "User",
    "last_name": 171089,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171034,
    "user_name": "User171034",
    "first_name": "User",
    "last_name": 171034,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 35.83
  },
  {
    "person_key": 171093,
    "user_name": "User171093",
    "first_name": "User",
    "last_name": 171093,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 14.33
  },
  {
    "person_key": 171089,
    "user_name": "User171089",
    "first_name": "User",
    "last_name": 171089,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.83
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Emilie Wapnick: Why some of us don't have one true calling",
    "resource_id": 7761,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169885,
    "user_name": "User169885",
    "first_name": "User",
    "last_name": 169885,
    "doc_id": 12345,
    "learning_object": "Ed Yong: Zombie roaches and other parasite tales",
    "resource_id": 7789,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 29.92
  },
  {
    "person_key": 171095,
    "user_name": "User171095",
    "first_name": "User",
    "last_name": 171095,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171109,
    "user_name": "User171109",
    "first_name": "User",
    "last_name": 171109,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.33
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: License",
    "resource_id": 6211,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 2B: Final Draft of Narrative Essay",
    "resource_id": 6418,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171019,
    "user_name": "User171019",
    "first_name": "User",
    "last_name": 171019,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 11.25
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 22.42
  },
  {
    "person_key": 171111,
    "user_name": "User171111",
    "first_name": "User",
    "last_name": 171111,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 14.58
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.75
  },
  {
    "person_key": 168024,
    "user_name": "User168024",
    "first_name": "User",
    "last_name": 168024,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 14.67
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Brian Little: Who are you, really? The puzzle of personality",
    "resource_id": 7742,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171023,
    "user_name": "User171023",
    "first_name": "User",
    "last_name": 171023,
    "doc_id": 12345,
    "learning_object": "01 Introduction to the DOC Workbook",
    "resource_id": 5351,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171088,
    "user_name": "User171088",
    "first_name": "User",
    "last_name": 171088,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 12.75
  },
  {
    "person_key": 170925,
    "user_name": "User170925",
    "first_name": "User",
    "last_name": 170925,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 39
  },
  {
    "person_key": 171022,
    "user_name": "User171022",
    "first_name": "User",
    "last_name": 171022,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 7
  },
  {
    "person_key": 161180,
    "user_name": "User161180",
    "first_name": "User",
    "last_name": 161180,
    "doc_id": 12345,
    "learning_object": "Power Source: English",
    "resource_id": 5736,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Eric M. Taylor Center",
    "location_id": 1697414,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 29.92
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.33
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.83
  },
  {
    "person_key": 171100,
    "user_name": "User171100",
    "first_name": "User",
    "last_name": 171100,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 39.17
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 2B: Final Draft of Narrative Essay",
    "resource_id": 6418,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 106.17
  },
  {
    "person_key": 171098,
    "user_name": "User171098",
    "first_name": "User",
    "last_name": 171098,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 9.25
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 7792,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171111,
    "user_name": "User171111",
    "first_name": "User",
    "last_name": 171111,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171016,
    "user_name": "User171016",
    "first_name": "User",
    "last_name": 171016,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 24.58
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 195.58
  },
  {
    "person_key": 171105,
    "user_name": "User171105",
    "first_name": "User",
    "last_name": 171105,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170930,
    "user_name": "User170930",
    "first_name": "User",
    "last_name": 170930,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 23.58
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.83
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 34.33
  },
  {
    "person_key": 170922,
    "user_name": "User170922",
    "first_name": "User",
    "last_name": 170922,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 12.75
  },
  {
    "person_key": 171036,
    "user_name": "User171036",
    "first_name": "User",
    "last_name": 171036,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 30.5
  },
  {
    "person_key": 170934,
    "user_name": "User170934",
    "first_name": "User",
    "last_name": 170934,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 9.83
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 9.42
  },
  {
    "person_key": 171105,
    "user_name": "User171105",
    "first_name": "User",
    "last_name": 171105,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 14.33
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3.08
  },
  {
    "person_key": 168502,
    "user_name": "User168502",
    "first_name": "User",
    "last_name": 168502,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center YA",
    "location_id": 1697496,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.5
  },
  {
    "person_key": 171092,
    "user_name": "User171092",
    "first_name": "User",
    "last_name": 171092,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "03. Renata Salecl: Our unhealthy obsession with choice",
    "resource_id": 5054,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.25
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 17.5
  },
  {
    "person_key": 170913,
    "user_name": "User170913",
    "first_name": "User",
    "last_name": 170913,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.67
  },
  {
    "person_key": 170918,
    "user_name": "User170918",
    "first_name": "User",
    "last_name": 170918,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170928,
    "user_name": "User170928",
    "first_name": "User",
    "last_name": 170928,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.08
  },
  {
    "person_key": 170926,
    "user_name": "User170926",
    "first_name": "User",
    "last_name": 170926,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 9.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.08
  },
  {
    "person_key": 170928,
    "user_name": "User170928",
    "first_name": "User",
    "last_name": 170928,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 26.5
  },
  {
    "person_key": 170927,
    "user_name": "User170927",
    "first_name": "User",
    "last_name": 170927,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.08
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Jackson Katz: Violence against women -- it's a men's issue",
    "resource_id": 7575,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.75
  },
  {
    "person_key": 163074,
    "user_name": "User163074",
    "first_name": "User",
    "last_name": 163074,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8091,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 31.83
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170914,
    "user_name": "User170914",
    "first_name": "User",
    "last_name": 170914,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "04. Ash Beckham: We're all hiding something. Let's find the courage to open up",
    "resource_id": 5028,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 166904,
    "user_name": "User166904",
    "first_name": "User",
    "last_name": 166904,
    "doc_id": 12345,
    "learning_object": "Phil Plait: How to defend Earth from asteroids",
    "resource_id": 7732,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.92
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 50.08
  },
  {
    "person_key": 171020,
    "user_name": "User171020",
    "first_name": "User",
    "last_name": 171020,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 36.42
  },
  {
    "person_key": 171092,
    "user_name": "User171092",
    "first_name": "User",
    "last_name": 171092,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 8.5
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Angela Lee Duckworth: Grit: The power of passion and perseverance",
    "resource_id": 7760,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "iO Tillett Wright: Fifty shades of gay",
    "resource_id": 7592,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 26.33
  },
  {
    "person_key": 161180,
    "user_name": "User161180",
    "first_name": "User",
    "last_name": 161180,
    "doc_id": 12345,
    "learning_object": "Greek Orthodox Bible",
    "resource_id": 6165,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Eric M. Taylor Center",
    "location_id": 1697414,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 29.92
  },
  {
    "person_key": 170920,
    "user_name": "User170920",
    "first_name": "User",
    "last_name": 170920,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 16.58
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 15.5
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 8.33
  },
  {
    "person_key": 171024,
    "user_name": "User171024",
    "first_name": "User",
    "last_name": 171024,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 12.33
  },
  {
    "person_key": 170922,
    "user_name": "User170922",
    "first_name": "User",
    "last_name": 170922,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171015,
    "user_name": "User171015",
    "first_name": "User",
    "last_name": 171015,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 34.58
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "When Good Men Behave Badly",
    "resource_id": 5944,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.25
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "As You Like It Audiobook",
    "resource_id": 5782,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.42
  },
  {
    "person_key": 170917,
    "user_name": "User170917",
    "first_name": "User",
    "last_name": 170917,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171110,
    "user_name": "User171110",
    "first_name": "User",
    "last_name": 171110,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171026,
    "user_name": "User171026",
    "first_name": "User",
    "last_name": 171026,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 39.83
  },
  {
    "person_key": 170914,
    "user_name": "User170914",
    "first_name": "User",
    "last_name": 170914,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 13
  },
  {
    "person_key": 170933,
    "user_name": "User170933",
    "first_name": "User",
    "last_name": 170933,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 41.42
  },
  {
    "person_key": 170911,
    "user_name": "User170911",
    "first_name": "User",
    "last_name": 170911,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 41.5
  },
  {
    "person_key": 170920,
    "user_name": "User170920",
    "first_name": "User",
    "last_name": 170920,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.67
  },
  {
    "person_key": 171104,
    "user_name": "User171104",
    "first_name": "User",
    "last_name": 171104,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 10.5
  },
  {
    "person_key": 171108,
    "user_name": "User171108",
    "first_name": "User",
    "last_name": 171108,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170934,
    "user_name": "User170934",
    "first_name": "User",
    "last_name": 170934,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 5.17
  },
  {
    "person_key": 171093,
    "user_name": "User171093",
    "first_name": "User",
    "last_name": 171093,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 9.92
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 29.17
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.08
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171012,
    "user_name": "User171012",
    "first_name": "User",
    "last_name": 171012,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 32.83
  },
  {
    "person_key": 170908,
    "user_name": "User170908",
    "first_name": "User",
    "last_name": 170908,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 16.33
  },
  {
    "person_key": 169850,
    "user_name": "User169850",
    "first_name": "User",
    "last_name": 169850,
    "doc_id": 12345,
    "learning_object": "Overview of your Parole Agreement",
    "resource_id": 6274,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 16.5
  },
  {
    "person_key": 171016,
    "user_name": "User171016",
    "first_name": "User",
    "last_name": 171016,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170912,
    "user_name": "User170912",
    "first_name": "User",
    "last_name": 170912,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 14.58
  },
  {
    "person_key": 171030,
    "user_name": "User171030",
    "first_name": "User",
    "last_name": 171030,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 40.17
  },
  {
    "person_key": 171027,
    "user_name": "User171027",
    "first_name": "User",
    "last_name": 171027,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 40.5
  },
  {
    "person_key": 171108,
    "user_name": "User171108",
    "first_name": "User",
    "last_name": 171108,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 38.42
  },
  {
    "person_key": 171094,
    "user_name": "User171094",
    "first_name": "User",
    "last_name": 171094,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 9.33
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 107.75
  },
  {
    "person_key": 168024,
    "user_name": "User168024",
    "first_name": "User",
    "last_name": 168024,
    "doc_id": 12345,
    "learning_object": "Decision Making Worksheet Carey Guides",
    "resource_id": 6273,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.67
  },
  {
    "person_key": 390,
    "user_name": "User390",
    "first_name": "User",
    "last_name": 390,
    "doc_id": 12345,
    "learning_object": "APDS Issue Report",
    "resource_id": 5464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 5.25
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "02. Robert Gupta: Music is medicine, music is sanity",
    "resource_id": 4949,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Julian Treasure: How to speak so that people want to listen",
    "resource_id": 7765,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156515,
    "user_name": "User156515",
    "first_name": "User",
    "last_name": 156515,
    "doc_id": 12345,
    "learning_object": "10. Rick Smolan: The story of a girl",
    "resource_id": 5069,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 28.67
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Stephen Hawking: Questioning the universe",
    "resource_id": 7752,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 11
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Elizabeth Lev: The unheard story of the Sistine Chapel",
    "resource_id": 7839,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Laurie Santos: A monkey economy as irrational as ours",
    "resource_id": 7705,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.5
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Lucianne Walkowicz: Let's not use Mars as a backup planet",
    "resource_id": 7757,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Latif Nasser: You have no idea where camels really come from",
    "resource_id": 7614,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170909,
    "user_name": "User170909",
    "first_name": "User",
    "last_name": 170909,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 22.42
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "09. Myriam Sidibe: The simple power of hand-washing",
    "resource_id": 4986,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.25
  },
  {
    "person_key": 168502,
    "user_name": "User168502",
    "first_name": "User",
    "last_name": 168502,
    "doc_id": 12345,
    "learning_object": "David Christian: The history of our world in 18 minutes",
    "resource_id": 7788,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center YA",
    "location_id": 1697496,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.33
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "03. Joshua Prager: In search of the man who broke my neck",
    "resource_id": 5223,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.75
  },
  {
    "person_key": 171099,
    "user_name": "User171099",
    "first_name": "User",
    "last_name": 171099,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 32.67
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "06. Mundano: Pimp my ... trash cart?",
    "resource_id": 4971,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.17
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Michael Murphy: Architecture that's built to heal",
    "resource_id": 7642,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171031,
    "user_name": "User171031",
    "first_name": "User",
    "last_name": 171031,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 35.67
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 7609,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.17
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Anand Giridharadas: A tale of two Americas. And the mini-mart where they collided",
    "resource_id": 7822,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Jamie Oliver: Teach every child about food",
    "resource_id": 7616,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "12. Julian Treasure: 5 ways to listen better",
    "resource_id": 5137,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 7.92
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "LaToya Ruby Frazier: A visual history of inequality in industrial America",
    "resource_id": 7837,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: On reading the Koran",
    "resource_id": 7810,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Jedidah Isler: How I fell in love with quasars, blazars and our incredible universe",
    "resource_id": 7750,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "02. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5026,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 7.92
  },
  {
    "person_key": 169885,
    "user_name": "User169885",
    "first_name": "User",
    "last_name": 169885,
    "doc_id": 12345,
    "learning_object": "Camille Seaman: Haunting photos of polar ice",
    "resource_id": 7679,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.17
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "14. Melissa Marshall: Talk nerdy to me",
    "resource_id": 5139,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 34.67
  },
  {
    "person_key": 170916,
    "user_name": "User170916",
    "first_name": "User",
    "last_name": 170916,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 11.08
  },
  {
    "person_key": 166904,
    "user_name": "User166904",
    "first_name": "User",
    "last_name": 166904,
    "doc_id": 12345,
    "learning_object": "03. Kevin Briggs: The bridge between suicide and life",
    "resource_id": 4958,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 163598,
    "user_name": "User163598",
    "first_name": "User",
    "last_name": 163598,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Salil Dudani: How jails extort the poor",
    "resource_id": 7655,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 14
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "04. Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 5195,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169885,
    "user_name": "User169885",
    "first_name": "User",
    "last_name": 169885,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.83
  },
  {
    "person_key": 171013,
    "user_name": "User171013",
    "first_name": "User",
    "last_name": 171013,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 6.67
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2.42
  },
  {
    "person_key": 169885,
    "user_name": "User169885",
    "first_name": "User",
    "last_name": 169885,
    "doc_id": 12345,
    "learning_object": "Rachel Sussman: The world's oldest living things",
    "resource_id": 7682,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 22.67
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 3.83
  },
  {
    "person_key": 165126,
    "user_name": "User165126",
    "first_name": "User",
    "last_name": 165126,
    "doc_id": 12345,
    "learning_object": "Simon Sinek: How great leaders inspire action",
    "resource_id": 7758,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 34.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Kathryn Schulz: Don't regret regret",
    "resource_id": 7826,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.83
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Myriam Sidibe: The simple power of hand-washing",
    "resource_id": 7631,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4.33
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Eleanor Longden: The voices in my head",
    "resource_id": 7535,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 8.92
  },
  {
    "person_key": 167911,
    "user_name": "User167911",
    "first_name": "User",
    "last_name": 167911,
    "doc_id": 12345,
    "learning_object": "Jedidah Isler: How I fell in love with quasars, blazars and our incredible universe",
    "resource_id": 7750,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 5.67
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Dilip Ratha: The hidden force in global economics: sending money home",
    "resource_id": 7711,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "01. Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 5025,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.5
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Nalini Nadkarni: Life science in prison",
    "resource_id": 7658,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.75
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 28.75
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "02. Robert Gupta: Music is medicine, music is sanity",
    "resource_id": 4949,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 43.58
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "09. Alexis Ohanian: How to make a splash in social media",
    "resource_id": 5173,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 10.58
  },
  {
    "person_key": 169885,
    "user_name": "User169885",
    "first_name": "User",
    "last_name": 169885,
    "doc_id": 12345,
    "learning_object": "Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 7681,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 4
  },
  {
    "person_key": 171014,
    "user_name": "User171014",
    "first_name": "User",
    "last_name": 171014,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 31.33
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "A Buddhist Bible",
    "resource_id": 6160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 10.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Brené Brown: The power of vulnerability",
    "resource_id": 7740,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.92
  },
  {
    "person_key": 171024,
    "user_name": "User171024",
    "first_name": "User",
    "last_name": 171024,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.58
  },
  {
    "person_key": 172094,
    "user_name": "User172094",
    "first_name": "User",
    "last_name": 172094,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.58
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Melissa Marshall: Talk nerdy to me",
    "resource_id": 7771,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "11. Susan Colantuono: The career advice you probably didn’t get",
    "resource_id": 5136,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 12.75
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Regina Hartley: Why the best hire might not have the perfect resume",
    "resource_id": 7762,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Michael Sandel: The lost art of democratic debate",
    "resource_id": 7654,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 7650,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Kiran Bedi: A police chief with a difference",
    "resource_id": 7660,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 8.92
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "08. Stella Young: I’m not your inspiration, thank you very much",
    "resource_id": 5033,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 2
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 7739,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 5.08
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 7629,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 1.42
  },
  {
    "person_key": 172094,
    "user_name": "User172094",
    "first_name": "User",
    "last_name": 172094,
    "doc_id": 12345,
    "learning_object": "Stella Young: I'm not your inspiration, thank you very much",
    "resource_id": 7584,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-06-01",
    "time_spent_min": 7.33
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "07. Jared Diamond: Why do societies collapse?",
    "resource_id": 5247,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 4
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Student Book - L6 Decimals (CS) - Quiz",
    "resource_id": 10105,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 10235
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Workbook - L4 Fractions",
    "resource_id": 9916,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 316
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 7813,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 42.5
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 21.42
  },
  {
    "person_key": 172102,
    "user_name": "User172102",
    "first_name": "User",
    "last_name": 172102,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 4.17
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 14.5
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 14.91
  },
  {
    "person_key": 171212,
    "user_name": "User171212",
    "first_name": "User",
    "last_name": 171212,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 30.16
  },
  {
    "person_key": 169395,
    "user_name": "User169395",
    "first_name": "User",
    "last_name": 169395,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.17
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 2
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.08
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 15.59
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 37.83
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.42
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.34
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 49.16
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Introduction",
    "resource_id": 6210,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 59.83
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 44.5
  },
  {
    "person_key": 171215,
    "user_name": "User171215",
    "first_name": "User",
    "last_name": 171215,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 2.42
  },
  {
    "person_key": 171212,
    "user_name": "User171212",
    "first_name": "User",
    "last_name": 171212,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Wednesday",
    "resource_id": 5732,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.58
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 6",
    "resource_id": 8317,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.58
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 18
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 34.34
  },
  {
    "person_key": 171197,
    "user_name": "User171197",
    "first_name": "User",
    "last_name": 171197,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 13.92
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 10.58
  },
  {
    "person_key": 171200,
    "user_name": "User171200",
    "first_name": "User",
    "last_name": 171200,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 9.33
  },
  {
    "person_key": 171230,
    "user_name": "User171230",
    "first_name": "User",
    "last_name": 171230,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 2
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "The Kemetic Tree of Life",
    "resource_id": 6122,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 27.34
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 5
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.42
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.160004
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.16
  },
  {
    "person_key": 171088,
    "user_name": "User171088",
    "first_name": "User",
    "last_name": 171088,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6024,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson Six:  Outlining Your Persuasive Speech: Chapters 24-26",
    "resource_id": 6835,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.83
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 48.42
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 3",
    "resource_id": 8314,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.92
  },
  {
    "person_key": 171180,
    "user_name": "User171180",
    "first_name": "User",
    "last_name": 171180,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Half House",
    "resource_id": 6209,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 69.5
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 60.34
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 34.25
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 14.67
  },
  {
    "person_key": 171196,
    "user_name": "User171196",
    "first_name": "User",
    "last_name": 171196,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169395,
    "user_name": "User169395",
    "first_name": "User",
    "last_name": 169395,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 2.5
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.66
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 4.42
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.17
  },
  {
    "person_key": 171208,
    "user_name": "User171208",
    "first_name": "User",
    "last_name": 171208,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 34.42
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 40.33
  },
  {
    "person_key": 171209,
    "user_name": "User171209",
    "first_name": "User",
    "last_name": 171209,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 38.5
  },
  {
    "person_key": 169395,
    "user_name": "User169395",
    "first_name": "User",
    "last_name": 169395,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.33
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 44
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171192,
    "user_name": "User171192",
    "first_name": "User",
    "last_name": 171192,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 51.42
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.59
  },
  {
    "person_key": 171195,
    "user_name": "User171195",
    "first_name": "User",
    "last_name": 171195,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 16.92
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 10.5
  },
  {
    "person_key": 171204,
    "user_name": "User171204",
    "first_name": "User",
    "last_name": 171204,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 17.42
  },
  {
    "person_key": 171199,
    "user_name": "User171199",
    "first_name": "User",
    "last_name": 171199,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 22.83
  },
  {
    "person_key": 171202,
    "user_name": "User171202",
    "first_name": "User",
    "last_name": 171202,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 44.92
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 2.34
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 37
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.42
  },
  {
    "person_key": 171196,
    "user_name": "User171196",
    "first_name": "User",
    "last_name": 171196,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 4.58
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.75
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.34
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 39.17
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.58
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.25
  },
  {
    "person_key": 169395,
    "user_name": "User169395",
    "first_name": "User",
    "last_name": 169395,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.92
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 72.83
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 23.5
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 75.67
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 40.17
  },
  {
    "person_key": 171193,
    "user_name": "User171193",
    "first_name": "User",
    "last_name": 171193,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 21.08
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1
  },
  {
    "person_key": 171205,
    "user_name": "User171205",
    "first_name": "User",
    "last_name": 171205,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 8.83
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "A Midsummers Night Dream Audiobook",
    "resource_id": 5781,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 15.75
  },
  {
    "person_key": 171211,
    "user_name": "User171211",
    "first_name": "User",
    "last_name": 171211,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 4.92
  },
  {
    "person_key": 171210,
    "user_name": "User171210",
    "first_name": "User",
    "last_name": 171210,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 48.33
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 30.83
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.33
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 22.75
  },
  {
    "person_key": 169395,
    "user_name": "User169395",
    "first_name": "User",
    "last_name": 169395,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 10.42
  },
  {
    "person_key": 171194,
    "user_name": "User171194",
    "first_name": "User",
    "last_name": 171194,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.42
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 7",
    "resource_id": 8318,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.58
  },
  {
    "person_key": 171198,
    "user_name": "User171198",
    "first_name": "User",
    "last_name": 171198,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 16
  },
  {
    "person_key": 171201,
    "user_name": "User171201",
    "first_name": "User",
    "last_name": 171201,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 7.83
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "The Way of the Orisha",
    "resource_id": 6128,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 29.75
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171213,
    "user_name": "User171213",
    "first_name": "User",
    "last_name": 171213,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 9.5
  },
  {
    "person_key": 170880,
    "user_name": "User170880",
    "first_name": "User",
    "last_name": 170880,
    "doc_id": 12345,
    "learning_object": "Part 1 - Conflict Resolution Exam FRIDAY - START HERE",
    "resource_id": 5741,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171207,
    "user_name": "User171207",
    "first_name": "User",
    "last_name": 171207,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 55.33
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 21.67
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 14
  },
  {
    "person_key": 171233,
    "user_name": "User171233",
    "first_name": "User",
    "last_name": 171233,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 171212,
    "user_name": "User171212",
    "first_name": "User",
    "last_name": 171212,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 35.75
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.08
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 44.92
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.33
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 24.25
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 90
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 35.67
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 5.34
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.92
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.17
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 53.08
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1c: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-Reading for Writers",
    "resource_id": 6415,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.5
  },
  {
    "person_key": 171203,
    "user_name": "User171203",
    "first_name": "User",
    "last_name": 171203,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 33.83
  },
  {
    "person_key": 169567,
    "user_name": "User169567",
    "first_name": "User",
    "last_name": 169567,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 12.92
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.92
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 5.58
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 48.92
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 4.67
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Islam The Hadith",
    "resource_id": 6170,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 73.58
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 71.75
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169567,
    "user_name": "User169567",
    "first_name": "User",
    "last_name": 169567,
    "doc_id": 12345,
    "learning_object": "Unit 1:  Strategic Human Resources Management",
    "resource_id": 7472,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.5
  },
  {
    "person_key": 171206,
    "user_name": "User171206",
    "first_name": "User",
    "last_name": 171206,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 33.42
  },
  {
    "person_key": 171191,
    "user_name": "User171191",
    "first_name": "User",
    "last_name": 171191,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 34.67
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 13.08
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 4",
    "resource_id": 8315,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 48.17
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 19
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Session One: WHAT IS ANGER?",
    "resource_id": 7668,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 12.67
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Islam The Meanings of Nobel Quran",
    "resource_id": 6169,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 10.42
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 31.59
  },
  {
    "person_key": 171194,
    "user_name": "User171194",
    "first_name": "User",
    "last_name": 171194,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 19.42
  },
  {
    "person_key": 171190,
    "user_name": "User171190",
    "first_name": "User",
    "last_name": 171190,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 29.42
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.75
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 76.5
  },
  {
    "person_key": 171206,
    "user_name": "User171206",
    "first_name": "User",
    "last_name": 171206,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.83
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 22.92
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 2",
    "resource_id": 8313,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 8.66
  },
  {
    "person_key": 169567,
    "user_name": "User169567",
    "first_name": "User",
    "last_name": 169567,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.41
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1
  },
  {
    "person_key": 171216,
    "user_name": "User171216",
    "first_name": "User",
    "last_name": 171216,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.09
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 1",
    "resource_id": 8007,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.42
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Part Two: Writing Your Informative Speech",
    "resource_id": 6834,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 10.58
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 7.91
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 5",
    "resource_id": 8316,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 7.25
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 2.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 40.25
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "02. Rita Pierson: Every kid needs a champion",
    "resource_id": 5041,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 7.92
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.42
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "22. Jill Bolte Taylor: My stroke of insight",
    "resource_id": 5162,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.33
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.58
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "David Chalmers: How do you explain consciousness?",
    "resource_id": 7665,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 23.92
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 9.33
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "10. Clint Smith: The danger of silence",
    "resource_id": 4975,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 5.83
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 8.5
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "06. Mundano: Pimp my ... trash cart?",
    "resource_id": 4971,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 6.83
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "13. Dan Gilbert: The surprising science of happiness",
    "resource_id": 5153,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 7.17
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "12. Tyler DeWitt: Hey science teachers -- make it fun",
    "resource_id": 5051,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 14.09
  },
  {
    "person_key": 158224,
    "user_name": "User158224",
    "first_name": "User",
    "last_name": 158224,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 4.83
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "05. Eleanor Longden: The voices in my head",
    "resource_id": 4960,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 19.58
  },
  {
    "person_key": 156519,
    "user_name": "User156519",
    "first_name": "User",
    "last_name": 156519,
    "doc_id": 12345,
    "learning_object": "10. iO Tillett Wright: Fifty shades of gay",
    "resource_id": 5012,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 2.66
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.92
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Negin Farsad: A highly scientific taxonomy of haters",
    "resource_id": 7569,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 10.25
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Sarah Kay: If I should have a daughter ...",
    "resource_id": 7550,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 6.33
  },
  {
    "person_key": 167994,
    "user_name": "User167994",
    "first_name": "User",
    "last_name": 167994,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: BIOS Motherboards",
    "resource_id": 8226,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "7A",
    "location_id": 1697455,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 5.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Kevin Breel: Confessions of a depressed comic",
    "resource_id": 7534,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 2.42
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 12.17
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Michael Sandel: The lost art of democratic debate",
    "resource_id": 7654,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 25.34
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "05. Pearl Arredondo: My story, from gangland daughter to star teacher",
    "resource_id": 5044,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 8
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "10. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 5149,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.34
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 14.25
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 29.92
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "11. John Hunter: Teaching with the World Peace Game",
    "resource_id": 5050,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 9.5
  },
  {
    "person_key": 163028,
    "user_name": "User163028",
    "first_name": "User",
    "last_name": 163028,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 2.83
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "21. Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 5161,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 6.5
  },
  {
    "person_key": 157050,
    "user_name": "User157050",
    "first_name": "User",
    "last_name": 157050,
    "doc_id": 12345,
    "learning_object": "07. Oliver Sacks: What hallucination reveals about our minds",
    "resource_id": 4962,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 8.08
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Jim Holt: Why does the universe exist?",
    "resource_id": 7651,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 24.5
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "06. Sugata Mitra: Build a School in the Cloud",
    "resource_id": 5045,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 29.92
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "04. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5143,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 7.17
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 21.92
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "James Green: 3 moons and a planet that could have alien life",
    "resource_id": 7754,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 2.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "11. Ken Robinson: How schools kill creativity",
    "resource_id": 5151,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.08
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Chetan Bhatt: Dare to refuse the origin myths that claim who you are",
    "resource_id": 7669,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.25
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "04. Malcolm Gladwell: The strange tale of the Norden bombsight",
    "resource_id": 5244,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.83
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Chris Burkard: The joy of surfing in ice-cold wate",
    "resource_id": 7697,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.83
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "09. Cesar Kuriyama: One second every day",
    "resource_id": 4974,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 8.17
  },
  {
    "person_key": 156519,
    "user_name": "User156519",
    "first_name": "User",
    "last_name": 156519,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 30.33
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "03. Christopher Emdin: Teach teachers how to create magic",
    "resource_id": 5042,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 9.17
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.33
  },
  {
    "person_key": 172098,
    "user_name": "User172098",
    "first_name": "User",
    "last_name": 172098,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 4.25
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Amy Tan: Where does creativity hide?",
    "resource_id": 7610,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.75
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 28.33
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 4.75
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.75
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Camille Seaman: Haunting photos of polar ice",
    "resource_id": 7679,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 4.25
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.83
  },
  {
    "person_key": 167739,
    "user_name": "User167739",
    "first_name": "User",
    "last_name": 167739,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 28.5
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "03. Brian Greene: Is our universe the only universe?",
    "resource_id": 5205,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 2
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Euna Lee: What I learned as a prisoner in North Korea",
    "resource_id": 7667,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 18.25
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 31.5
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Rick Smolan: The story of a girl",
    "resource_id": 7627,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 9.33
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "08. Dan Meyer: Math class needs a makeover",
    "resource_id": 5047,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.09
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Chetan Bhatt: Dare to refuse the origin myths that claim who you are",
    "resource_id": 7669,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 24.92
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 7657,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 16.92
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 7680,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.66
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Dan Gilbert: The psychology of your future self",
    "resource_id": 7663,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 12.25
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.5
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 19.08
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Shah Rukh Khan: Thoughts on humanity, fame and love",
    "resource_id": 7670,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 23.83
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.67
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "03. Richard Turere: My invention that made peace with lions",
    "resource_id": 5194,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 10
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "04. Elyn Saks: A tale of mental illness -- from the inside",
    "resource_id": 4959,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.67
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Chris Burkard: The joy of surfing in ice-cold wate",
    "resource_id": 7697,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 11.67
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Onora O'Neill: What we don't understand about trust",
    "resource_id": 7666,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 15.67
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "08. Ludwick Marishane: A bath without water",
    "resource_id": 5199,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 6.67
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "05. Derek Sivers: Keep your goals to yourself",
    "resource_id": 5196,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.42
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "11. Anupam Mishra: The ancient ingenuity of water harvesting",
    "resource_id": 5251,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 29.92
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 3.17
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Onora O'Neill: What we don't understand about trust",
    "resource_id": 7666,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Jean-Paul Mari: The chilling aftershock of a brush with death",
    "resource_id": 7659,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 30
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "09. Peter Doolittle: How your \"working memory\" makes sense of the world",
    "resource_id": 5048,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 1.42
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-30",
    "time_spent_min": 12.75
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 24.08
  },
  {
    "person_key": 171164,
    "user_name": "User171164",
    "first_name": "User",
    "last_name": 171164,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171184,
    "user_name": "User171184",
    "first_name": "User",
    "last_name": 171184,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 23
  },
  {
    "person_key": 171124,
    "user_name": "User171124",
    "first_name": "User",
    "last_name": 171124,
    "doc_id": 12345,
    "learning_object": "Part 2 - Make-Up Use of Force Exam",
    "resource_id": 5639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 31.33
  },
  {
    "person_key": 171239,
    "user_name": "User171239",
    "first_name": "User",
    "last_name": 171239,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 14.33
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 13.42
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 60.75
  },
  {
    "person_key": 171260,
    "user_name": "User171260",
    "first_name": "User",
    "last_name": 171260,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 68.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 14.58
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "James A. White Sr.: The little problem I had renting a house",
    "resource_id": 7579,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 18.08
  },
  {
    "person_key": 171225,
    "user_name": "User171225",
    "first_name": "User",
    "last_name": 171225,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 31.08
  },
  {
    "person_key": 171180,
    "user_name": "User171180",
    "first_name": "User",
    "last_name": 171180,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 45.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.669998
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Morgana Bailey: The danger of hiding who you are",
    "resource_id": 7582,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.84
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.25
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Amy Cuddy: Your body language may shape who you are",
    "resource_id": 7769,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.84
  },
  {
    "person_key": 171252,
    "user_name": "User171252",
    "first_name": "User",
    "last_name": 171252,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 24
  },
  {
    "person_key": 170891,
    "user_name": "User170891",
    "first_name": "User",
    "last_name": 170891,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 46.17
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Faith Jegede Cole: What I've learned from my autistic brothers",
    "resource_id": 7623,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 6.17
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: First Mortgage",
    "resource_id": 6208,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 27.92
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 60.92
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 89.92
  },
  {
    "person_key": 171253,
    "user_name": "User171253",
    "first_name": "User",
    "last_name": 171253,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 13.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 87.17
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "05 Staff Disciplinary Procedures Workbook",
    "resource_id": 5316,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.17
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 38.08
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.75
  },
  {
    "person_key": 160469,
    "user_name": "User160469",
    "first_name": "User",
    "last_name": 160469,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Maryland Correctional Training Center",
    "location_id": 1697421,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 6.08
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "eL Seed: Street art with a message of hope and peace",
    "resource_id": 7563,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.67
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "White Fang Audiobook",
    "resource_id": 5870,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 30
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.09
  },
  {
    "person_key": 160469,
    "user_name": "User160469",
    "first_name": "User",
    "last_name": 160469,
    "doc_id": 12345,
    "learning_object": "Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 7775,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Maryland Correctional Training Center",
    "location_id": 1697421,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 11.42
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.160004
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 44.92
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 23.5
  },
  {
    "person_key": 171242,
    "user_name": "User171242",
    "first_name": "User",
    "last_name": 171242,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 17.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 7.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.66
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.67
  },
  {
    "person_key": 170893,
    "user_name": "User170893",
    "first_name": "User",
    "last_name": 170893,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 30.67
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 50.42
  },
  {
    "person_key": 170884,
    "user_name": "User170884",
    "first_name": "User",
    "last_name": 170884,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 19.17
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "Alicia Garza, Patrisse Cullors and Opal Tometi: An interview with the founders of Black Lives Matter",
    "resource_id": 7640,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.92
  },
  {
    "person_key": 161009,
    "user_name": "User161009",
    "first_name": "User",
    "last_name": 161009,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170897,
    "user_name": "User170897",
    "first_name": "User",
    "last_name": 170897,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 41.33
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 32.66
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "04. Joseph Kim: The family I lost in North Korea. And the family I gained.",
    "resource_id": 5224,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 14.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Mundano: Trash cart superheroes",
    "resource_id": 7551,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1",
    "resource_id": 7992,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 2.75
  },
  {
    "person_key": 171165,
    "user_name": "User171165",
    "first_name": "User",
    "last_name": 171165,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 53.67
  },
  {
    "person_key": 171235,
    "user_name": "User171235",
    "first_name": "User",
    "last_name": 171235,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 32.92
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4.75
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 8.08
  },
  {
    "person_key": 171185,
    "user_name": "User171185",
    "first_name": "User",
    "last_name": 171185,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 2
  },
  {
    "person_key": 171184,
    "user_name": "User171184",
    "first_name": "User",
    "last_name": 171184,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.33
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "07. Jared Diamond: Why do societies collapse?",
    "resource_id": 5247,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.08
  },
  {
    "person_key": 171242,
    "user_name": "User171242",
    "first_name": "User",
    "last_name": 171242,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.42
  },
  {
    "person_key": 171164,
    "user_name": "User171164",
    "first_name": "User",
    "last_name": 171164,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 59.5
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "09. George Monbiot: For more wonder, rewild the world",
    "resource_id": 5122,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 30
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "09. George Monbiot: For more wonder, rewild the world",
    "resource_id": 5122,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 2.16
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 30.17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.75
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2",
    "resource_id": 7993,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.66
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "10. Paul Nicklen: Tales of ice-bound wonderlands",
    "resource_id": 5123,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.5
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.92
  },
  {
    "person_key": 171172,
    "user_name": "User171172",
    "first_name": "User",
    "last_name": 171172,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 8.75
  },
  {
    "person_key": 171248,
    "user_name": "User171248",
    "first_name": "User",
    "last_name": 171248,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 9.92
  },
  {
    "person_key": 171228,
    "user_name": "User171228",
    "first_name": "User",
    "last_name": 171228,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 32.33
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "The Prince and the Pauper Audiobook",
    "resource_id": 5860,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5.92
  },
  {
    "person_key": 171257,
    "user_name": "User171257",
    "first_name": "User",
    "last_name": 171257,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171236,
    "user_name": "User171236",
    "first_name": "User",
    "last_name": 171236,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 33.92
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Amy Tan: Where does creativity hide?",
    "resource_id": 7610,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 30
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.67
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Clint Smith: How to raise a black son in America",
    "resource_id": 7643,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5.33
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Jean-Paul Mari: The chilling aftershock of a brush with death",
    "resource_id": 7659,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.92
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.330002
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 2.33
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 12.25
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.91
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 12.91
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "03. Arthur Benjamin: The magic of Fibonacci numbers",
    "resource_id": 5093,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.5
  },
  {
    "person_key": 171219,
    "user_name": "User171219",
    "first_name": "User",
    "last_name": 171219,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.33
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.09
  },
  {
    "person_key": 171249,
    "user_name": "User171249",
    "first_name": "User",
    "last_name": 171249,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 35
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1",
    "resource_id": 7995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 11.75
  },
  {
    "person_key": 171185,
    "user_name": "User171185",
    "first_name": "User",
    "last_name": 171185,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 37.08
  },
  {
    "person_key": 168502,
    "user_name": "User168502",
    "first_name": "User",
    "last_name": 168502,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center YA",
    "location_id": 1697496,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171219,
    "user_name": "User171219",
    "first_name": "User",
    "last_name": 171219,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 9.25
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "01. Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 5025,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 22.42
  },
  {
    "person_key": 171246,
    "user_name": "User171246",
    "first_name": "User",
    "last_name": 171246,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 61.67
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 15.83
  },
  {
    "person_key": 171179,
    "user_name": "User171179",
    "first_name": "User",
    "last_name": 171179,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 25.58
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "02. Adam Spencer: Why I fell in love with monster prime numbers",
    "resource_id": 5092,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.33
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.66
  },
  {
    "person_key": 171240,
    "user_name": "User171240",
    "first_name": "User",
    "last_name": 171240,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 42.67
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 1 Technology in Business",
    "resource_id": 7460,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 49.5
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 51.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Regina Hartley: Why the best hire might not have the perfect resume",
    "resource_id": 7762,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5.34
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 56.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 6.67
  },
  {
    "person_key": 171216,
    "user_name": "User171216",
    "first_name": "User",
    "last_name": 171216,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 33.08
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "03. Joshua Prager: In search of the man who broke my neck",
    "resource_id": 5223,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 19.17
  },
  {
    "person_key": 171181,
    "user_name": "User171181",
    "first_name": "User",
    "last_name": 171181,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 12.92
  },
  {
    "person_key": 170892,
    "user_name": "User170892",
    "first_name": "User",
    "last_name": 170892,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 18.67
  },
  {
    "person_key": 171257,
    "user_name": "User171257",
    "first_name": "User",
    "last_name": 171257,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 14.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 171245,
    "user_name": "User171245",
    "first_name": "User",
    "last_name": 171245,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 12.42
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 13.92
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 6.92
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "05 Staff Disciplinary Procedures Workbook",
    "resource_id": 5315,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.17
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4.33
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 31.75
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 90.66
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 16
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.91
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "06 General Office Workbook",
    "resource_id": 5317,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.17
  },
  {
    "person_key": 160469,
    "user_name": "User160469",
    "first_name": "User",
    "last_name": 160469,
    "doc_id": 12345,
    "learning_object": "Richard Turere: My invention that made peace with lions",
    "resource_id": 7776,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Maryland Correctional Training Center",
    "location_id": 1697421,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 7.08
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.5
  },
  {
    "person_key": 170904,
    "user_name": "User170904",
    "first_name": "User",
    "last_name": 170904,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 32.92
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "07. Aimee Mullins: The opportunity of adversity",
    "resource_id": 5227,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 22.83
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.42
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.42
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1",
    "resource_id": 7998,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 8.75
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.33
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 7680,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "EXAM 1: UNIT 1 and UNIT 2",
    "resource_id": 7509,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "TestPrep 220-901 CompTIA A+",
    "resource_id": 8252,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 59.92
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Dan Cobley: What physics taught me about marketing",
    "resource_id": 7797,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.75
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "UNIT 2: ANALYZING TRANSACTIONS",
    "resource_id": 7421,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171168,
    "user_name": "User171168",
    "first_name": "User",
    "last_name": 171168,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 35.92
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 2.25
  },
  {
    "person_key": 170886,
    "user_name": "User170886",
    "first_name": "User",
    "last_name": 170886,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 49.08
  },
  {
    "person_key": 170895,
    "user_name": "User170895",
    "first_name": "User",
    "last_name": 170895,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 25.33
  },
  {
    "person_key": 170906,
    "user_name": "User170906",
    "first_name": "User",
    "last_name": 170906,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 47.33
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 85.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 2 Marketing Segmentation, Targeting and Positioning",
    "resource_id": 7445,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171231,
    "user_name": "User171231",
    "first_name": "User",
    "last_name": 171231,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 8.58
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "The Kemetic Tree of Life",
    "resource_id": 6122,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.66
  },
  {
    "person_key": 171217,
    "user_name": "User171217",
    "first_name": "User",
    "last_name": 171217,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 33.25
  },
  {
    "person_key": 171261,
    "user_name": "User171261",
    "first_name": "User",
    "last_name": 171261,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 16.92
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.16
  },
  {
    "person_key": 171259,
    "user_name": "User171259",
    "first_name": "User",
    "last_name": 171259,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 25.25
  },
  {
    "person_key": 160469,
    "user_name": "User160469",
    "first_name": "User",
    "last_name": 160469,
    "doc_id": 12345,
    "learning_object": "Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 7778,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Maryland Correctional Training Center",
    "location_id": 1697421,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.33
  },
  {
    "person_key": 171251,
    "user_name": "User171251",
    "first_name": "User",
    "last_name": 171251,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.42
  },
  {
    "person_key": 171239,
    "user_name": "User171239",
    "first_name": "User",
    "last_name": 171239,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171223,
    "user_name": "User171223",
    "first_name": "User",
    "last_name": 171223,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 31.75
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 42.67
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.67
  },
  {
    "person_key": 171230,
    "user_name": "User171230",
    "first_name": "User",
    "last_name": 171230,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 32.42
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 48.5
  },
  {
    "person_key": 171186,
    "user_name": "User171186",
    "first_name": "User",
    "last_name": 171186,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171251,
    "user_name": "User171251",
    "first_name": "User",
    "last_name": 171251,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 42.75
  },
  {
    "person_key": 171180,
    "user_name": "User171180",
    "first_name": "User",
    "last_name": 171180,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170903,
    "user_name": "User170903",
    "first_name": "User",
    "last_name": 170903,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 19.33
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "03 Classification Workbook",
    "resource_id": 5307,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 2.17
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 14.42
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "08. Geoffrey West: The surprising math of cities and corporations",
    "resource_id": 5099,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 8.67
  },
  {
    "person_key": 171176,
    "user_name": "User171176",
    "first_name": "User",
    "last_name": 171176,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 11
  },
  {
    "person_key": 171188,
    "user_name": "User171188",
    "first_name": "User",
    "last_name": 171188,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 15.58
  },
  {
    "person_key": 171169,
    "user_name": "User171169",
    "first_name": "User",
    "last_name": 171169,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 18
  },
  {
    "person_key": 171264,
    "user_name": "User171264",
    "first_name": "User",
    "last_name": 171264,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 33.08
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.59
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.83
  },
  {
    "person_key": 171227,
    "user_name": "User171227",
    "first_name": "User",
    "last_name": 171227,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 34.83
  },
  {
    "person_key": 169395,
    "user_name": "User169395",
    "first_name": "User",
    "last_name": 169395,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.41
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 61.83
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.17
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 3- Policing Issues",
    "resource_id": 7437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 25.75
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5
  },
  {
    "person_key": 171258,
    "user_name": "User171258",
    "first_name": "User",
    "last_name": 171258,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 10
  },
  {
    "person_key": 171186,
    "user_name": "User171186",
    "first_name": "User",
    "last_name": 171186,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5.92
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 47.16
  },
  {
    "person_key": 171260,
    "user_name": "User171260",
    "first_name": "User",
    "last_name": 171260,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 19.34
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 7681,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4.92
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171249,
    "user_name": "User171249",
    "first_name": "User",
    "last_name": 171249,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170896,
    "user_name": "User170896",
    "first_name": "User",
    "last_name": 170896,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 20.83
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 63.58
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "07. Oliver Sacks: What hallucination reveals about our minds",
    "resource_id": 4962,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156519,
    "user_name": "User156519",
    "first_name": "User",
    "last_name": 156519,
    "doc_id": 12345,
    "learning_object": "10. iO Tillett Wright: Fifty shades of gay",
    "resource_id": 5012,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 9.92
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "01. Eric Sanderson: New York -- before the City",
    "resource_id": 5241,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 16.75
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "02 Conflict of Interest Workbook",
    "resource_id": 5312,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.75
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "12. Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 5125,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 8.75
  },
  {
    "person_key": 167953,
    "user_name": "User167953",
    "first_name": "User",
    "last_name": 167953,
    "doc_id": 12345,
    "learning_object": "08. Jessica Jackley: Poverty, money -- and love",
    "resource_id": 5111,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 23.67
  },
  {
    "person_key": 171241,
    "user_name": "User171241",
    "first_name": "User",
    "last_name": 171241,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171241,
    "user_name": "User171241",
    "first_name": "User",
    "last_name": 171241,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 58.25
  },
  {
    "person_key": 171243,
    "user_name": "User171243",
    "first_name": "User",
    "last_name": 171243,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 38.08
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.5
  },
  {
    "person_key": 156293,
    "user_name": "User156293",
    "first_name": "User",
    "last_name": 156293,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 170894,
    "user_name": "User170894",
    "first_name": "User",
    "last_name": 170894,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 45.5
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 45.09
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 22.42
  },
  {
    "person_key": 171255,
    "user_name": "User171255",
    "first_name": "User",
    "last_name": 171255,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 44.83
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "Sanford Biggers: An artist's unflinching look at racial violence",
    "resource_id": 7544,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2",
    "resource_id": 7988,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.84
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 12.75
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "03 Classification Workbook",
    "resource_id": 5308,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Alice Goffman: How we're priming some kids for college — and others for prison",
    "resource_id": 7636,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 16.5
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 14.67
  },
  {
    "person_key": 171189,
    "user_name": "User171189",
    "first_name": "User",
    "last_name": 171189,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 39.17
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Yves Morieux: How too many rules at work keep you from getting things done",
    "resource_id": 7772,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.67
  },
  {
    "person_key": 171167,
    "user_name": "User171167",
    "first_name": "User",
    "last_name": 171167,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 38.83
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Ronald Sullivan: How I help free innocent people from prison",
    "resource_id": 7634,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 13.67
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 43.58
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3",
    "resource_id": 7994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 11.33
  },
  {
    "person_key": 171171,
    "user_name": "User171171",
    "first_name": "User",
    "last_name": 171171,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 16.67
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 30.83
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "02. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5026,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 22.17
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "04. Malcolm Gladwell: The strange tale of the Norden bombsight",
    "resource_id": 5244,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 14.92
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "06 General Office Workbook",
    "resource_id": 5318,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 61.58
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3",
    "resource_id": 7997,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 8.25
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.5
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "Anand Giridharadas: A tale of two Americas. And the mini-mart where they collided",
    "resource_id": 7822,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.5
  },
  {
    "person_key": 171261,
    "user_name": "User171261",
    "first_name": "User",
    "last_name": 171261,
    "doc_id": 12345,
    "learning_object": "Part 1 - Conflict Resolution Exam WEDNESDAY - START HERE",
    "resource_id": 5745,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171124,
    "user_name": "User171124",
    "first_name": "User",
    "last_name": 171124,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Thursday",
    "resource_id": 5730,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.67
  },
  {
    "person_key": 171166,
    "user_name": "User171166",
    "first_name": "User",
    "last_name": 171166,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 37.33
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.67
  },
  {
    "person_key": 171182,
    "user_name": "User171182",
    "first_name": "User",
    "last_name": 171182,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 30.25
  },
  {
    "person_key": 171124,
    "user_name": "User171124",
    "first_name": "User",
    "last_name": 171124,
    "doc_id": 12345,
    "learning_object": "Part 2 - Conflict Resolution Exam Thursday",
    "resource_id": 5641,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Damon Horowitz: Philosophy in prison",
    "resource_id": 7656,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5.83
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Melissa Marshall: Talk nerdy to me",
    "resource_id": 7771,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.09
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "08. Sue Austin: Deep sea diving ... in a wheelchair",
    "resource_id": 5228,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 10
  },
  {
    "person_key": 171218,
    "user_name": "User171218",
    "first_name": "User",
    "last_name": 171218,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 8.25
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 51.33
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 63.42
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Christopher Bell: Bring on the female superheroes!",
    "resource_id": 7600,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 16.42
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.42
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "Mac Stone: Stunning photos of the endangered Everglades",
    "resource_id": 7698,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 21.42
  },
  {
    "person_key": 171187,
    "user_name": "User171187",
    "first_name": "User",
    "last_name": 171187,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 41.17
  },
  {
    "person_key": 168502,
    "user_name": "User168502",
    "first_name": "User",
    "last_name": 168502,
    "doc_id": 12345,
    "learning_object": "01. Dan Gilbert: Why we make bad decisions",
    "resource_id": 5052,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center YA",
    "location_id": 1697496,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.92
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 80.75
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Tomás Saraceno: Would you live in a floating city in the sky?",
    "resource_id": 7572,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.42
  },
  {
    "person_key": 170885,
    "user_name": "User170885",
    "first_name": "User",
    "last_name": 170885,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 48.17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2",
    "resource_id": 7999,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4.67
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 12.67
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Celeste Headlee: 10 ways to have a better conversation",
    "resource_id": 7770,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "10. Edward Tenner: Unintended consequences",
    "resource_id": 5250,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4.83
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Joy Buolamwini: How I'm fighting bias in algorithms",
    "resource_id": 7721,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "02. Zak Ebrahim: I am the son of a terrorist. Here's how I chose peace.",
    "resource_id": 5222,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 13.25
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "George Monbiot: For more wonder, rewild the world",
    "resource_id": 7694,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.25
  },
  {
    "person_key": 170905,
    "user_name": "User170905",
    "first_name": "User",
    "last_name": 170905,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 78.33
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.33
  },
  {
    "person_key": 171221,
    "user_name": "User171221",
    "first_name": "User",
    "last_name": 171221,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5.92
  },
  {
    "person_key": 170900,
    "user_name": "User170900",
    "first_name": "User",
    "last_name": 170900,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 36
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 19.67
  },
  {
    "person_key": 171247,
    "user_name": "User171247",
    "first_name": "User",
    "last_name": 171247,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 41.92
  },
  {
    "person_key": 170883,
    "user_name": "User170883",
    "first_name": "User",
    "last_name": 170883,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 45.42
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 11.08
  },
  {
    "person_key": 171238,
    "user_name": "User171238",
    "first_name": "User",
    "last_name": 171238,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 35
  },
  {
    "person_key": 171237,
    "user_name": "User171237",
    "first_name": "User",
    "last_name": 171237,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.83
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Second Chance Pell Program Instructions",
    "resource_id": 7671,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.08
  },
  {
    "person_key": 171234,
    "user_name": "User171234",
    "first_name": "User",
    "last_name": 171234,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 9.67
  },
  {
    "person_key": 160469,
    "user_name": "User160469",
    "first_name": "User",
    "last_name": 160469,
    "doc_id": 12345,
    "learning_object": "Derek Sivers: How to start a movement",
    "resource_id": 7777,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Maryland Correctional Training Center",
    "location_id": 1697421,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.83
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4.75
  },
  {
    "person_key": 167994,
    "user_name": "User167994",
    "first_name": "User",
    "last_name": 167994,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: BIOS Motherboards",
    "resource_id": 8226,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "7A",
    "location_id": 1697455,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 24.17
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 33.92
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 10.67
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 20.83
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.75
  },
  {
    "person_key": 171255,
    "user_name": "User171255",
    "first_name": "User",
    "last_name": 171255,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "02 Conflict of Interest Workbook",
    "resource_id": 5311,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.75
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Alexis Ohanian: How to make a splash in social media",
    "resource_id": 7718,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.09
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.16
  },
  {
    "person_key": 171220,
    "user_name": "User171220",
    "first_name": "User",
    "last_name": 171220,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 26.42
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "05. William Noel: Revealing the lost codex of Archimedes",
    "resource_id": 5245,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 12.5
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "01. Amy Cuddy: Your body language shapes who you are",
    "resource_id": 5126,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 8.67
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.92
  },
  {
    "person_key": 171263,
    "user_name": "User171263",
    "first_name": "User",
    "last_name": 171263,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 36.33
  },
  {
    "person_key": 171224,
    "user_name": "User171224",
    "first_name": "User",
    "last_name": 171224,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 35.42
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 30.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "OK Go: How to find a wonderful idea",
    "resource_id": 7561,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.67
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 23.83
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 34.41
  },
  {
    "person_key": 171222,
    "user_name": "User171222",
    "first_name": "User",
    "last_name": 171222,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 31.75
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.83
  },
  {
    "person_key": 170889,
    "user_name": "User170889",
    "first_name": "User",
    "last_name": 170889,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 61
  },
  {
    "person_key": 171254,
    "user_name": "User171254",
    "first_name": "User",
    "last_name": 171254,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 23.17
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Half House",
    "resource_id": 6209,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.92
  },
  {
    "person_key": 346,
    "user_name": "User346",
    "first_name": "User",
    "last_name": 346,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4.67
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Sarah Jones: One woman, five characters, and a sex lesson from the future",
    "resource_id": 7599,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 23.41
  },
  {
    "person_key": 170899,
    "user_name": "User170899",
    "first_name": "User",
    "last_name": 170899,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 25.83
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.92
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "Native American Healing Traditions",
    "resource_id": 6155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.59
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4.42
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 21.33
  },
  {
    "person_key": 171124,
    "user_name": "User171124",
    "first_name": "User",
    "last_name": 171124,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Thursday",
    "resource_id": 5654,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 273.08
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Gabriela González: How LIGO discovered gravitational waves -- and what might be next",
    "resource_id": 7773,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171173,
    "user_name": "User171173",
    "first_name": "User",
    "last_name": 171173,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 35.5
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5.17
  },
  {
    "person_key": 160469,
    "user_name": "User160469",
    "first_name": "User",
    "last_name": 160469,
    "doc_id": 12345,
    "learning_object": "05. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 4997,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Maryland Correctional Training Center",
    "location_id": 1697421,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.92
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "FINAL EXAM (UNIT 4 and UNIT 5)",
    "resource_id": 7533,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "Richard Turere: My invention that made peace with lions",
    "resource_id": 7776,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "04 Inmate Escorts Workbook",
    "resource_id": 5314,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 2.5
  },
  {
    "person_key": 171021,
    "user_name": "User171021",
    "first_name": "User",
    "last_name": 171021,
    "doc_id": 12345,
    "learning_object": "04 Inmate Escorts Workbook",
    "resource_id": 5313,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 2.5
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5.75
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Adam Savage: My love letter to cosplay",
    "resource_id": 7612,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 16.17
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "06. Neil MacGregor: 2600 years of history in one object",
    "resource_id": 5246,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 18.67
  },
  {
    "person_key": 171245,
    "user_name": "User171245",
    "first_name": "User",
    "last_name": 171245,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.42
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 16.92
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.75
  },
  {
    "person_key": 160469,
    "user_name": "User160469",
    "first_name": "User",
    "last_name": 160469,
    "doc_id": 12345,
    "learning_object": "Melati and Isabel Wijsen: Our campaign to ban plastic bags in Bali",
    "resource_id": 7780,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Maryland Correctional Training Center",
    "location_id": 1697421,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4.5
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "05. Phil Hansen: Embrace the shake",
    "resource_id": 5225,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.92
  },
  {
    "person_key": 171254,
    "user_name": "User171254",
    "first_name": "User",
    "last_name": 171254,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Neha Narula: The future of money",
    "resource_id": 7722,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5.5
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 7768,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5.67
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Salil Dudani: How jails extort the poor",
    "resource_id": 7655,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.92
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 30.33
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.16
  },
  {
    "person_key": 171256,
    "user_name": "User171256",
    "first_name": "User",
    "last_name": 171256,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 37.67
  },
  {
    "person_key": 159308,
    "user_name": "User159308",
    "first_name": "User",
    "last_name": 159308,
    "doc_id": 12345,
    "learning_object": "Letter to Your 10-Year-Old Self",
    "resource_id": 7970,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.92
  },
  {
    "person_key": 171246,
    "user_name": "User171246",
    "first_name": "User",
    "last_name": 171246,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 36.92
  },
  {
    "person_key": 171244,
    "user_name": "User171244",
    "first_name": "User",
    "last_name": 171244,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 24
  },
  {
    "person_key": 171174,
    "user_name": "User171174",
    "first_name": "User",
    "last_name": 171174,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 51.33
  },
  {
    "person_key": 171170,
    "user_name": "User171170",
    "first_name": "User",
    "last_name": 171170,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 28
  },
  {
    "person_key": 160469,
    "user_name": "User160469",
    "first_name": "User",
    "last_name": 160469,
    "doc_id": 12345,
    "learning_object": "Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 7779,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Maryland Correctional Training Center",
    "location_id": 1697421,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 13.08
  },
  {
    "person_key": 171178,
    "user_name": "User171178",
    "first_name": "User",
    "last_name": 171178,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 40.92
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5.67
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3",
    "resource_id": 7989,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.09
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 2 - Developing Your Coaching Objectives",
    "resource_id": 6517,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 47
  },
  {
    "person_key": 171215,
    "user_name": "User171215",
    "first_name": "User",
    "last_name": 171215,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 26.25
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 2.17
  },
  {
    "person_key": 171226,
    "user_name": "User171226",
    "first_name": "User",
    "last_name": 171226,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 33.17
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.33
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Stella Young: I'm not your inspiration, thank you very much",
    "resource_id": 7584,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170901,
    "user_name": "User170901",
    "first_name": "User",
    "last_name": 170901,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 44.83
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Michael Sandel: The lost art of democratic debate",
    "resource_id": 7654,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4.58
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 1.33
  },
  {
    "person_key": 170890,
    "user_name": "User170890",
    "first_name": "User",
    "last_name": 170890,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 46.58
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.5
  },
  {
    "person_key": 171182,
    "user_name": "User171182",
    "first_name": "User",
    "last_name": 171182,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 16.25
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 10.08
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 5
  },
  {
    "person_key": 167856,
    "user_name": "User167856",
    "first_name": "User",
    "last_name": 167856,
    "doc_id": 12345,
    "learning_object": "Rachel Sussman: The world's oldest living things",
    "resource_id": 7682,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 9.67
  },
  {
    "person_key": 168502,
    "user_name": "User168502",
    "first_name": "User",
    "last_name": 168502,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center YA",
    "location_id": 1697496,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 20.08
  },
  {
    "person_key": 171214,
    "user_name": "User171214",
    "first_name": "User",
    "last_name": 171214,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 7.25
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.08
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.16
  },
  {
    "person_key": 171250,
    "user_name": "User171250",
    "first_name": "User",
    "last_name": 171250,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 48.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 43.25
  },
  {
    "person_key": 171232,
    "user_name": "User171232",
    "first_name": "User",
    "last_name": 171232,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 8.33
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "09. Onora O'Neill: What we don't understand about trust",
    "resource_id": 5087,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Misha Glenny: Hire the hackers!",
    "resource_id": 7723,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.09
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Sanford Biggers: An artist's unflinching look at racial violence",
    "resource_id": 7544,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 7.67
  },
  {
    "person_key": 171233,
    "user_name": "User171233",
    "first_name": "User",
    "last_name": 171233,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 34.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.58
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 6.17
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 33.67
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 15.75
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 9.08
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 7.17
  },
  {
    "person_key": 171183,
    "user_name": "User171183",
    "first_name": "User",
    "last_name": 171183,
    "doc_id": 12345,
    "learning_object": "Part 2 - Suicide Prevention Exam",
    "resource_id": 7966,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 41.92
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 3.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170907,
    "user_name": "User170907",
    "first_name": "User",
    "last_name": 170907,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 42.42
  },
  {
    "person_key": 170902,
    "user_name": "User170902",
    "first_name": "User",
    "last_name": 170902,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 59.75
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 17.58
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Angela Lee Duckworth: Grit: The power of passion and perseverance",
    "resource_id": 7760,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 50.5
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 30
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 1 Marketing",
    "resource_id": 7444,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170898,
    "user_name": "User170898",
    "first_name": "User",
    "last_name": 170898,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 16.25
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 7609,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 19.83
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Latif Nasser: You have no idea where camels really come from",
    "resource_id": 7614,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 45.83
  },
  {
    "person_key": 170888,
    "user_name": "User170888",
    "first_name": "User",
    "last_name": 170888,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 52.75
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.16
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: Love, no matter what",
    "resource_id": 7620,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 28.75
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 7
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.589996
  },
  {
    "person_key": 162335,
    "user_name": "User162335",
    "first_name": "User",
    "last_name": 162335,
    "doc_id": 12345,
    "learning_object": "10. Alberto Cairo: There are no scraps of men",
    "resource_id": 5230,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 29.92
  },
  {
    "person_key": 171247,
    "user_name": "User171247",
    "first_name": "User",
    "last_name": 171247,
    "doc_id": 12345,
    "learning_object": "Part 1 - Suicide Prevention Exam - START HERE",
    "resource_id": 7965,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "UNIT 4: FIXED ASSESTS AND INTANGIBLE ASSETS",
    "resource_id": 7423,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.25
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "12. Laurie Garrett: Lessons from the 1918 flu",
    "resource_id": 5252,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 6.75
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-29",
    "time_spent_min": 4.17
  },
  {
    "person_key": 153633,
    "user_name": "User153633",
    "first_name": "User",
    "last_name": 153633,
    "doc_id": 12345,
    "learning_object": "07. Hannah Brencher: Love letters to strangers",
    "resource_id": 5066,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 4.83
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "12. Dan Ariely: Are we in control of our own decisions?",
    "resource_id": 5152,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 4.25
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 6640,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2.59
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "14. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5154,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 4.75
  },
  {
    "person_key": 346,
    "user_name": "User346",
    "first_name": "User",
    "last_name": 346,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 14.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 30.42
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Considerations for the Informative Speech: Chapters 14-16",
    "resource_id": 6833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 11.5
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.58
  },
  {
    "person_key": 166904,
    "user_name": "User166904",
    "first_name": "User",
    "last_name": 166904,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.42
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.08
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "07. Aimee Mullins: The opportunity of adversity",
    "resource_id": 5227,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.67
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.33
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 77
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.08
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.17
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "08. Brené Brown: Listening to shame",
    "resource_id": 5147,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2.42
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Fin Stability",
    "resource_id": 6205,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 23
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2.58
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.67
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 51.92
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Enlightenment",
    "resource_id": 6204,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 38.08
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.5
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.5
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 4.5
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.25
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 7775,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 10.83
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.25
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 25.25
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169846,
    "user_name": "User169846",
    "first_name": "User",
    "last_name": 169846,
    "doc_id": 12345,
    "learning_object": "Five Percenter Anthology",
    "resource_id": 6300,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 20.92
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.83
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.08
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 7
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 60.5
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 57.92
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 8.75
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 6.25
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.25
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 4.42
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.92
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Ronald Sullivan: How I help free innocent people from prison",
    "resource_id": 7634,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 4.75
  },
  {
    "person_key": 169395,
    "user_name": "User169395",
    "first_name": "User",
    "last_name": 169395,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 6.5
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.75
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "01. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5221,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 26.58
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 7547,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.33
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 12.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Tasos Frantzolas: Everything you hear on film is a lie",
    "resource_id": 7565,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 16.92
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "01. Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 5181,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.58
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 28
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "AJ Jacobs: The world's largest family reunion ... we're all invited!",
    "resource_id": 7597,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 18.42
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2.5
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 7609,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.5
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Credit",
    "resource_id": 6201,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Suzanne Simard: How trees talk to each other",
    "resource_id": 7687,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 20.92
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "06. Andrew Solomon: Depression, the secret we share",
    "resource_id": 4961,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 13.83
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 32.83
  },
  {
    "person_key": 166904,
    "user_name": "User166904",
    "first_name": "User",
    "last_name": 166904,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 13
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.58
  },
  {
    "person_key": 169395,
    "user_name": "User169395",
    "first_name": "User",
    "last_name": 169395,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 7.5
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.92
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 4.92
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "05. Pearl Arredondo: My story, from gangland daughter to star teacher",
    "resource_id": 5044,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.75
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "11. Susan Colantuono: The career advice you probably didn’t get",
    "resource_id": 5136,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.75
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 4- Law Enforcement in Action",
    "resource_id": 7438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 32.67
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "07. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5146,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 14.17
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.33
  },
  {
    "person_key": 153633,
    "user_name": "User153633",
    "first_name": "User",
    "last_name": 153633,
    "doc_id": 12345,
    "learning_object": "06. Amy Webb: How I hacked online dating",
    "resource_id": 5065,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.58
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "03. Lisa Bu: How books can open your mind",
    "resource_id": 5183,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.5
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.83
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Morgana Bailey: The danger of hiding who you are",
    "resource_id": 7582,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 11.83
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "03. Brian Greene: Is our universe the only universe?",
    "resource_id": 5205,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 19.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.92
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 4.08
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2.75
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "02. Rita Pierson: Every kid needs a champion",
    "resource_id": 5041,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.75
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 5207,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "09. Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 5134,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 4.67
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "eL Seed: Street art with a message of hope and peace",
    "resource_id": 7563,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 6.17
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "Macbeth Audiobook",
    "resource_id": 5874,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 30
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "05. Lesley Hazleton: The doubt essential to faith",
    "resource_id": 5237,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2.58
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "The Tempest Audiobook",
    "resource_id": 5879,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 30
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "10. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 5149,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.58
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "03. Christopher Emdin: Teach teachers how to create magic",
    "resource_id": 5042,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 6.92
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 8.08
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 40.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 33
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Negin Farsad: A highly scientific taxonomy of haters",
    "resource_id": 7569,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 8.5
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Kandice Sumner: How America's public schools keep kids in poverty",
    "resource_id": 7645,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.67
  },
  {
    "person_key": 458,
    "user_name": "User458",
    "first_name": "User",
    "last_name": 458,
    "doc_id": 12345,
    "learning_object": "Prayers to the Orishas",
    "resource_id": 6135,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "13. Kare Anderson: Be an opportunity maker",
    "resource_id": 5138,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.08
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.0899999
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 7831,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 30
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Earning Freedom",
    "resource_id": 6203,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 17.83
  },
  {
    "person_key": 346,
    "user_name": "User346",
    "first_name": "User",
    "last_name": 346,
    "doc_id": 12345,
    "learning_object": "01. Eric Sanderson: New York -- before the City",
    "resource_id": 5241,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.92
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Ludwick Marishane: A bath without water",
    "resource_id": 7781,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.67
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.92
  },
  {
    "person_key": 159891,
    "user_name": "User159891",
    "first_name": "User",
    "last_name": 159891,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.67
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.42
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 8.08
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "01. Amy Cuddy: Your body language shapes who you are",
    "resource_id": 5126,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2.25
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Ronald Sullivan: How I help free innocent people from prison",
    "resource_id": 7634,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 8.33
  },
  {
    "person_key": 153633,
    "user_name": "User153633",
    "first_name": "User",
    "last_name": 153633,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.42
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 60.5
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 53.75
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.75
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169373,
    "user_name": "User169373",
    "first_name": "User",
    "last_name": 169373,
    "doc_id": 12345,
    "learning_object": "Lesson Six:  Outlining Your Persuasive Speech: Chapters 24-26",
    "resource_id": 6835,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "02. Alain de Botton: Atheism 2.0",
    "resource_id": 5234,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.75
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 117.33
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2.33
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 56.5
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "T. Morgan Dixon and Vanessa Garrison: When Black women walk, things change",
    "resource_id": 7638,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 15.83
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 65.58
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "02. Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 5081,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "10B",
    "location_id": 1697462,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 16.67
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2.92
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 7680,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.75
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 14.08
  },
  {
    "person_key": 167953,
    "user_name": "User167953",
    "first_name": "User",
    "last_name": 167953,
    "doc_id": 12345,
    "learning_object": "10. William Kamkwamba: How I harnessed the wind",
    "resource_id": 5201,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 6.83
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 7566,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 30
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.33
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.92
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "06. Eric Whitacre: A virtual choir 2,000 voices strong",
    "resource_id": 5170,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 4.25
  },
  {
    "person_key": 159891,
    "user_name": "User159891",
    "first_name": "User",
    "last_name": 159891,
    "doc_id": 12345,
    "learning_object": "Jackson Katz: Violence against women -- it's a men's issue",
    "resource_id": 7575,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "05. James Lyne: Everyday cybercrime -- and what you can do about it",
    "resource_id": 5169,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 12.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "07. Dilip Ratha: The hidden force in global economics: sending money home",
    "resource_id": 5110,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.09
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "JORDAN RIDES THE BUS",
    "resource_id": 8496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 7.92
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.91
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "10. Annie Murphy Paul: What we learn before we're born",
    "resource_id": 5049,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 26.08
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Andrés Ruzo: The boiling river of the Amazon",
    "resource_id": 7685,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.25
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.92
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Rich Benjamin: My road trip through the whitest towns in America",
    "resource_id": 7562,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 13.33
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 19.67
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 119.91
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 31.5
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 17.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "OK Go: How to find a wonderful idea",
    "resource_id": 7561,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.75
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "03. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5128,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.42
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "03. Keith Chen: Could your language affect your ability to save money?",
    "resource_id": 5106,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.25
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Jack Conte: How artists can (finally) get paid in the digital age",
    "resource_id": 7785,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 10.75
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 11.67
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 8.92
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.75
  },
  {
    "person_key": 346,
    "user_name": "User346",
    "first_name": "User",
    "last_name": 346,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 18.75
  },
  {
    "person_key": 169452,
    "user_name": "User169452",
    "first_name": "User",
    "last_name": 169452,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.83
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.09
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 15.33
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 17.91
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.08
  },
  {
    "person_key": 172099,
    "user_name": "User172099",
    "first_name": "User",
    "last_name": 172099,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Alicia Garza, Patrisse Cullors and Opal Tometi: An interview with the founders of Black Lives Matter",
    "resource_id": 7640,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 6.58
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "07. Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 5198,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 8
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "08. Jessica Jackley: Poverty, money -- and love",
    "resource_id": 5111,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "17. Munir Virani: Why I love vultures",
    "resource_id": 5219,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.33
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.92
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "09. Tania Luna: How a penny made me feel like a millionaire",
    "resource_id": 5112,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 18.5
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 19.08
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "09. Andrew Solomon: How the worst moments in our lives make us who we are",
    "resource_id": 5229,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 30
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "James A. White Sr.: The little problem I had renting a house",
    "resource_id": 7579,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169373,
    "user_name": "User169373",
    "first_name": "User",
    "last_name": 169373,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "06. Colin Stokes: How movies teach manhood",
    "resource_id": 5008,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 10.83
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "01. Kelly McGonigal: How to make stress your friend",
    "resource_id": 4993,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 8.16
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "10. Annie Murphy Paul: What we learn before we're born",
    "resource_id": 5049,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 4.67
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Mellody Hobson: Color blind or color brave?",
    "resource_id": 7568,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Sarah Jones: One woman, five characters, and a sex lesson from the future",
    "resource_id": 7599,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 26.75
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.42
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "08. Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 4985,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.83
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Bob Marley Book",
    "resource_id": 6137,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "14. Melissa Marshall: Talk nerdy to me",
    "resource_id": 5139,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.08
  },
  {
    "person_key": 169395,
    "user_name": "User169395",
    "first_name": "User",
    "last_name": 169395,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 7564,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.33
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.589996
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Tomás Saraceno: Would you live in a floating city in the sky?",
    "resource_id": 7572,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 12.08
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 19.33
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.5
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Stella Young: I'm not your inspiration, thank you very much",
    "resource_id": 7584,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.58
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 7549,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.75
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 3- Policing Issues",
    "resource_id": 7437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 63.67
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 30
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 39.5
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "05. McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 5007,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 6.83
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "TestPrep 220-901 CompTIA A+",
    "resource_id": 8252,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 59.92
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "09. Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 5189,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.67
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "12. Jill Tarter: Join the SETI search",
    "resource_id": 5214,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.83
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Safwat Saleem: Why I keep speaking up, even when people mock my accent",
    "resource_id": 7571,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 11.25
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 96.92
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 30
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "03. Brian Greene: Is our universe the only universe?",
    "resource_id": 5205,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "10B",
    "location_id": 1697462,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 7.33
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.75
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 10.5
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 79.08
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 60
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "02. Elizabeth Gilbert: Your elusive creative genius",
    "resource_id": 5182,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction to Physical Sciences",
    "resource_id": 7464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.34
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Ash Beckham: We're all hiding something. Let's find the courage to open up",
    "resource_id": 7581,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.58
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 7834,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 30.75
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "10. Laurie Santos: A monkey economy as irrational as ours",
    "resource_id": 5113,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2.67
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "14. Boaz Almog: The levitating superconductor",
    "resource_id": 5216,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.83
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 175.25
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Find Markets",
    "resource_id": 6206,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Elif Shafak: The revolutionary power of diverse thought",
    "resource_id": 7601,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 26.33
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 12
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "06. Mundano: Pimp my ... trash cart?",
    "resource_id": 4971,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.42
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L3: Summarize",
    "resource_id": 10472,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 27
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L3: Summarize - Quiz",
    "resource_id": 10473,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1206
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "05. Lesley Hazleton: The doubt essential to faith",
    "resource_id": 5237,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 37.42
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Movement on Maps (CS) - Quiz",
    "resource_id": 10448,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 8430
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Workbook - L5: Movement on Maps",
    "resource_id": 10449,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Movement on Maps (CS)",
    "resource_id": 10447,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 70
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 30
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.92
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "09. Kenneth Shinozuka: My simple invention, designed to keep my grandfather safe",
    "resource_id": 5200,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.330002
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Sequence (CS) - Quiz",
    "resource_id": 10461,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 87
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L4: Categorize (CS) - Quiz",
    "resource_id": 10459,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 46
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 102.58
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L4: Categorize (CS)",
    "resource_id": 10458,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 12
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L3: Main Idea and Details - Quiz",
    "resource_id": 10457,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3012
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "09. Cesar Kuriyama: One second every day",
    "resource_id": 4974,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 2.17
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "04. Karen Armstrong: Let's revive the Golden Rule",
    "resource_id": 5236,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169373,
    "user_name": "User169373",
    "first_name": "User",
    "last_name": 169373,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.67
  },
  {
    "person_key": 169373,
    "user_name": "User169373",
    "first_name": "User",
    "last_name": 169373,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.58
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 29.91
  },
  {
    "person_key": 169846,
    "user_name": "User169846",
    "first_name": "User",
    "last_name": 169846,
    "doc_id": 12345,
    "learning_object": "Letter to Your 10-Year-Old Self",
    "resource_id": 7970,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.75
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.67
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 281.92
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.5
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.66
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Alicia Garza, Patrisse Cullors and Opal Tometi: An interview with the founders of Black Lives Matter",
    "resource_id": 7640,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 17.08
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "08. Brené Brown: Listening to shame",
    "resource_id": 5147,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.08
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 68.66
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 35.83
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "06. Michael Sandel: Why we shouldn't trust markets with our civic life",
    "resource_id": 5109,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.67
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.41
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.33
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Finding Markets",
    "resource_id": 6207,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 30.67
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "04. Shlomo Benartzi: Saving for tomorrow, tomorrow",
    "resource_id": 5107,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.17
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "18. Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 5158,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 14
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Alice Goffman: How we're priming some kids for college — and others for prison",
    "resource_id": 7636,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.83
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.67
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Sally Kohn: Let’s try emotional correctness",
    "resource_id": 7576,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 8
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 90.42
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 15.5
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Marlon Peterson: Am I not human? A call for criminal justice reform",
    "resource_id": 7646,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 13.25
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 134.66
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "02. Beau Lotto: Optical illusions show how we see",
    "resource_id": 5204,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.83
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 56.42
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "10. Naomi Oreskes: Why we should trust scientists",
    "resource_id": 5212,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.25
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.75
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Alicia Garza, Patrisse Cullors and Opal Tometi: An interview with the founders of Black Lives Matter",
    "resource_id": 7640,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.67
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Adam Driver: My journey from Marine to actor",
    "resource_id": 7559,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 24.42
  },
  {
    "person_key": 167953,
    "user_name": "User167953",
    "first_name": "User",
    "last_name": 167953,
    "doc_id": 12345,
    "learning_object": "11. Bunker Roy: Learning from a barefoot movement",
    "resource_id": 5202,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 22.08
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1 - The Criminal Justice System",
    "resource_id": 7469,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.09
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.67
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.75
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 37.17
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "01. Ken Robinson: How schools kill creativity",
    "resource_id": 5040,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "03. Lisa Bu: How books can open your mind",
    "resource_id": 5183,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 6.17
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.58
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 128.83
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 23.58
  },
  {
    "person_key": 155171,
    "user_name": "User155171",
    "first_name": "User",
    "last_name": 155171,
    "doc_id": 12345,
    "learning_object": "Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 7650,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.17
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 65.83
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 38.25
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.58
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "05. Graham Hill: Less stuff, more happiness",
    "resource_id": 5108,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 11.75
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 13.25
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.67
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "08. Ludwick Marishane: A bath without water",
    "resource_id": 5199,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.17
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.75
  },
  {
    "person_key": 169373,
    "user_name": "User169373",
    "first_name": "User",
    "last_name": 169373,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 1.67
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 3.84
  },
  {
    "person_key": 172086,
    "user_name": "User172086",
    "first_name": "User",
    "last_name": 172086,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Salil Dudani: How jails extort the poor",
    "resource_id": 7655,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 5.25
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "08. Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 5240,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 18.58
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Titus Kaphar: Can art amend history?",
    "resource_id": 7573,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 13.67
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "04. Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 5006,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 24.83
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-28",
    "time_spent_min": 9.67
  },
  {
    "person_key": 161180,
    "user_name": "User161180",
    "first_name": "User",
    "last_name": 161180,
    "doc_id": 12345,
    "learning_object": "Radio Survey",
    "resource_id": 5689,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Eric M. Taylor Center",
    "location_id": 1697414,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "09. George Monbiot: For more wonder, rewild the world",
    "resource_id": 5122,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2.5
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "03. Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 5243,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 13.5
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "Literacy Math Formative Assessment",
    "resource_id": 9445,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1508
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Creative Fin",
    "resource_id": 6200,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "Literacy Reading Formative Assessment",
    "resource_id": 9451,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 5432
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 1 Marketing",
    "resource_id": 7444,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "02. Derek Sivers: How to start a movement",
    "resource_id": 5193,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5221,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.67
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.33
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "09. Cesar Kuriyama: One second every day",
    "resource_id": 4974,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.17
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "10B",
    "location_id": 1697462,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 22.33
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 2",
    "resource_id": 8313,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.17
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Two: How to Succeed in College",
    "resource_id": 6440,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.83
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.5
  },
  {
    "person_key": 159587,
    "user_name": "User159587",
    "first_name": "User",
    "last_name": 159587,
    "doc_id": 12345,
    "learning_object": "Lesson Seven/Domain 6: Personal-Emotional Orientation",
    "resource_id": 8069,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.33
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Six: Career Planning",
    "resource_id": 6443,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "02. Alain de Botton: A kinder, gentler philosophy of success",
    "resource_id": 5015,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 24.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 12
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 52.75
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "05. William Noel: Revealing the lost codex of Archimedes",
    "resource_id": 5245,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.91
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.33
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.67
  },
  {
    "person_key": 154239,
    "user_name": "User154239",
    "first_name": "User",
    "last_name": 154239,
    "doc_id": 12345,
    "learning_object": "01. Brené Brown: Listening to shame",
    "resource_id": 5014,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 22.58
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 16.75
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Corp Sponsor",
    "resource_id": 6199,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 50.75
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 30.09
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "06. Amy Webb: How I hacked online dating",
    "resource_id": 5065,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Ludwick Marishane: A bath without water",
    "resource_id": 7781,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 10
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Credit",
    "resource_id": 6201,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.67
  },
  {
    "person_key": 161180,
    "user_name": "User161180",
    "first_name": "User",
    "last_name": 161180,
    "doc_id": 12345,
    "learning_object": "A Deadly Game Audiobook",
    "resource_id": 5795,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Eric M. Taylor Center",
    "location_id": 1697414,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 16.58
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "08. Stephen Hawking: Questioning the universe",
    "resource_id": 5210,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "10B",
    "location_id": 1697462,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Lesson 9- Plans of Action- Family Life, Finances & Work",
    "resource_id": 7896,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.5
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "African Cultural Values",
    "resource_id": 6125,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2.83
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "Mentoring 220-901 CompTIA A+",
    "resource_id": 8251,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157598,
    "user_name": "User157598",
    "first_name": "User",
    "last_name": 157598,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 5018,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.83
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "10. Edward Tenner: Unintended consequences",
    "resource_id": 5250,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 18.67
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "04. Rishi Manchanda: What makes us get sick? Look upstream.",
    "resource_id": 4981,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.41
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2.58
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 70.75
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1",
    "resource_id": 7998,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.42
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 9.25
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 30.58
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "Old Testament",
    "resource_id": 7385,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "11. Ben Saunders: To the South Pole and back — the hardest 105 days of my life",
    "resource_id": 5124,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 20.08
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 6.25
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "10. Paul Nicklen: Tales of ice-bound wonderlands",
    "resource_id": 5123,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 6.17
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.5
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.75
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "12. Laurie Garrett: Lessons from the 1918 flu",
    "resource_id": 5252,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.17
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2.92
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Conclusion",
    "resource_id": 6198,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 5.16
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "01. Kwame Anthony Appiah: Is religion good or bad? (This is a trick question)",
    "resource_id": 5233,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 15.33
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 2 - Developing Your Coaching Objectives",
    "resource_id": 6517,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 12.41
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "iO Tillett Wright: Fifty shades of gay",
    "resource_id": 7592,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 58.58
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "07. Jeff Smith: Lessons in business ... from prison",
    "resource_id": 5076,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.08
  },
  {
    "person_key": 157598,
    "user_name": "User157598",
    "first_name": "User",
    "last_name": 157598,
    "doc_id": 12345,
    "learning_object": "10. Kathryn Schulz: On being wrong",
    "resource_id": 5023,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.58
  },
  {
    "person_key": 160502,
    "user_name": "User160502",
    "first_name": "User",
    "last_name": 160502,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 16.5
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 7.34
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "03. Faith Jegede: What I've learned from my autistic brothers",
    "resource_id": 5062,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 8.83
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "LaToya Ruby Frazier: A visual history of inequality in industrial America",
    "resource_id": 7837,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.58
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 93.08
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.09
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "02. Siegfried Woldhek: The search for the true face of Leonardo",
    "resource_id": 5242,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 4
  },
  {
    "person_key": 143,
    "user_name": "User143",
    "first_name": "User",
    "last_name": 143,
    "doc_id": 12345,
    "learning_object": "06. Brené Brown: The power of vulnerability",
    "resource_id": 4998,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.34
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "08. Brené Brown: Listening to shame",
    "resource_id": 5147,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 25.75
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.160004
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2.5
  },
  {
    "person_key": 161180,
    "user_name": "User161180",
    "first_name": "User",
    "last_name": 161180,
    "doc_id": 12345,
    "learning_object": "Planet Doom Audiobook",
    "resource_id": 5803,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Eric M. Taylor Center",
    "location_id": 1697414,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 20.83
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.83
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5060,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 8.83
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 4.25
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "09. Myriam Sidibe: The simple power of hand-washing",
    "resource_id": 4986,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.5
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 7593,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2.25
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "01. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5221,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Sanford Biggers: An artist's unflinching look at racial violence",
    "resource_id": 7544,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 4.92
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 60.17
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "04. Malcolm Gladwell: The strange tale of the Norden bombsight",
    "resource_id": 5244,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 8.58
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "EXAM 1: UNIT 1 and UNIT 2",
    "resource_id": 7509,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 114.83
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 10.33
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.67
  },
  {
    "person_key": 154239,
    "user_name": "User154239",
    "first_name": "User",
    "last_name": 154239,
    "doc_id": 12345,
    "learning_object": "02. Alain de Botton: A kinder, gentler philosophy of success",
    "resource_id": 5015,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 21
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "06. Neil MacGregor: 2600 years of history in one object",
    "resource_id": 5246,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 10.67
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 30.08
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 8- Finisher Program",
    "resource_id": 7895,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.17
  },
  {
    "person_key": 413,
    "user_name": "User413",
    "first_name": "User",
    "last_name": 413,
    "doc_id": 12345,
    "learning_object": "JORDAN RIDES THE BUS",
    "resource_id": 8496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "9B",
    "location_id": 1697460,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 85.33
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 24.08
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 15.25
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 6.5
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Lucianne Walkowicz: Let's not use Mars as a backup planet",
    "resource_id": 7757,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.58
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "11. Anupam Mishra: The ancient ingenuity of water harvesting",
    "resource_id": 5251,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "08. Johanna Blakley: Social media and the end of gender",
    "resource_id": 5010,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 28.09
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 132.34
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 7831,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.17
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "The Way of the Orisha",
    "resource_id": 6128,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 154239,
    "user_name": "User154239",
    "first_name": "User",
    "last_name": 154239,
    "doc_id": 12345,
    "learning_object": "03. Margaret Heffernan: Dare to disagree",
    "resource_id": 5016,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "05. McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 5007,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 5.75
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.67
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "12. Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 5125,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.160004
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "10. Clint Smith: The danger of silence",
    "resource_id": 4975,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 4.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 7552,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 11.17
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 6.92
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.17
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 1",
    "resource_id": 8007,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Rich Benjamin: My road trip through the whitest towns in America",
    "resource_id": 7562,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 59.17
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 14.25
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 4.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.42
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.419998
  },
  {
    "person_key": 143,
    "user_name": "User143",
    "first_name": "User",
    "last_name": 143,
    "doc_id": 12345,
    "learning_object": "03. Jackson Katz: Violence against women—it's a men's issue",
    "resource_id": 5005,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 60.33
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 43.5
  },
  {
    "person_key": 157598,
    "user_name": "User157598",
    "first_name": "User",
    "last_name": 157598,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.67
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week One: The History of Wiley",
    "resource_id": 6439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159587,
    "user_name": "User159587",
    "first_name": "User",
    "last_name": 159587,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2.83
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 99.34
  },
  {
    "person_key": 153633,
    "user_name": "User153633",
    "first_name": "User",
    "last_name": 153633,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 25.08
  },
  {
    "person_key": 161180,
    "user_name": "User161180",
    "first_name": "User",
    "last_name": 161180,
    "doc_id": 12345,
    "learning_object": "APDS Issue Report",
    "resource_id": 5464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Eric M. Taylor Center",
    "location_id": 1697414,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2.08
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "08. Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 4985,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 5.92
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 56.5
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 4.67
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.83
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 33.08
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 5.25
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 4.33
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 81.17
  },
  {
    "person_key": 160502,
    "user_name": "User160502",
    "first_name": "User",
    "last_name": 160502,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 13.83
  },
  {
    "person_key": 159587,
    "user_name": "User159587",
    "first_name": "User",
    "last_name": 159587,
    "doc_id": 12345,
    "learning_object": "JORDAN RIDES THE BUS",
    "resource_id": 8496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2.67
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.17
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2",
    "resource_id": 7999,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 14.75
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Gabriela González: How LIGO discovered gravitational waves -- and what might be next",
    "resource_id": 7773,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.16
  },
  {
    "person_key": 166904,
    "user_name": "User166904",
    "first_name": "User",
    "last_name": 166904,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.17
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Lesson 10- Plans of Action- Family Life, Finances, and Work",
    "resource_id": 7904,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 7580,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.5
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "07. Jared Diamond: Why do societies collapse?",
    "resource_id": 5247,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 8.08
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.580002
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 413,
    "user_name": "User413",
    "first_name": "User",
    "last_name": 413,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "9B",
    "location_id": 1697460,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 41.83
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 12",
    "resource_id": 8050,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 54.58
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 30.16
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "09. Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 5249,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 27.83
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "02. Alice Dreger: Is anatomy destiny?",
    "resource_id": 5004,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 7.16
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Earning Freedom",
    "resource_id": 6203,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 14.42
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "05. Derek Sivers: Keep your goals to yourself",
    "resource_id": 5196,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 4.08
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "09. Ben Goldacre: Battling bad science",
    "resource_id": 5211,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "10B",
    "location_id": 1697462,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 30
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 49.83
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.17
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 30.08
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.58
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "09. Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 5068,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 9
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 46.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Alice Dreger: Is anatomy destiny?",
    "resource_id": 7587,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.25
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.58
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.67
  },
  {
    "person_key": 169564,
    "user_name": "User169564",
    "first_name": "User",
    "last_name": 169564,
    "doc_id": 12345,
    "learning_object": "Unit 1 Solving Equations",
    "resource_id": 7905,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 9.42
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 7547,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 11.17
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 93.91
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "Love Me Don't Leave Me",
    "resource_id": 5922,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.17
  },
  {
    "person_key": 390,
    "user_name": "User390",
    "first_name": "User",
    "last_name": 390,
    "doc_id": 12345,
    "learning_object": "When Good Men Behave Badly",
    "resource_id": 5944,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.330002
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction to Physical Sciences",
    "resource_id": 7464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.75
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 166904,
    "user_name": "User166904",
    "first_name": "User",
    "last_name": 166904,
    "doc_id": 12345,
    "learning_object": "Eleanor Longden: The voices in my head",
    "resource_id": 7535,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160502,
    "user_name": "User160502",
    "first_name": "User",
    "last_name": 160502,
    "doc_id": 12345,
    "learning_object": "Carol Dweck: The power of believing that you can improve",
    "resource_id": 7748,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 10.42
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.33
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Jackson Katz: Violence against women -- it's a men's issue",
    "resource_id": 7575,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2.08
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "02. Alain de Botton: Atheism 2.0",
    "resource_id": 5234,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 60
  },
  {
    "person_key": 143,
    "user_name": "User143",
    "first_name": "User",
    "last_name": 143,
    "doc_id": 12345,
    "learning_object": "Prayers to the Orishas",
    "resource_id": 6135,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 12.08
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "09. Sheryl Sandberg: Why we have too few women leaders",
    "resource_id": 5011,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.67
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "10. iO Tillett Wright: Fifty shades of gay",
    "resource_id": 5012,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.5
  },
  {
    "person_key": 143,
    "user_name": "User143",
    "first_name": "User",
    "last_name": 143,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 10.5
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 23.25
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 9.83
  },
  {
    "person_key": 161180,
    "user_name": "User161180",
    "first_name": "User",
    "last_name": 161180,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Eric M. Taylor Center",
    "location_id": 1697414,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.42
  },
  {
    "person_key": 161180,
    "user_name": "User161180",
    "first_name": "User",
    "last_name": 161180,
    "doc_id": 12345,
    "learning_object": "Othello Audiobook",
    "resource_id": 5787,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Eric M. Taylor Center",
    "location_id": 1697414,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "TestPrep 220-901 CompTIA A+",
    "resource_id": 8252,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.83
  },
  {
    "person_key": 80,
    "user_name": "User80",
    "first_name": "User",
    "last_name": 80,
    "doc_id": 12345,
    "learning_object": "Literacy Math Formative Assessment",
    "resource_id": 9735,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "9A",
    "location_id": 1697459,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 584
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "04. Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 5006,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159587,
    "user_name": "User159587",
    "first_name": "User",
    "last_name": 159587,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 17.08
  },
  {
    "person_key": 161180,
    "user_name": "User161180",
    "first_name": "User",
    "last_name": 161180,
    "doc_id": 12345,
    "learning_object": "Unbroken 1 Audiobook",
    "resource_id": 5777,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Eric M. Taylor Center",
    "location_id": 1697414,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 24.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "APDS Issue Report",
    "resource_id": 5464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 4.75
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "Suzanne Simard: How trees talk to each other",
    "resource_id": 7687,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165534,
    "user_name": "User165534",
    "first_name": "User",
    "last_name": 165534,
    "doc_id": 12345,
    "learning_object": "Module 11",
    "resource_id": 8042,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 2
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.58
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 30.58
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 24.25
  },
  {
    "person_key": 143,
    "user_name": "User143",
    "first_name": "User",
    "last_name": 143,
    "doc_id": 12345,
    "learning_object": "When Good Men Behave Badly",
    "resource_id": 5944,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 4.33
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3",
    "resource_id": 7997,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 1.08
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 3.25
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "01. Hannah Fry: The mathematics of love",
    "resource_id": 5091,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 17.92
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 94.92
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 29.92
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-27",
    "time_spent_min": 0.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 163074,
    "user_name": "User163074",
    "first_name": "User",
    "last_name": 163074,
    "doc_id": 12345,
    "learning_object": "Book Club Titles",
    "resource_id": 7324,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3.75
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.419998
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "10. Kathryn Schulz: On being wrong",
    "resource_id": 5023,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Robert Sapolsky: The biology of our best and worst selves",
    "resource_id": 7799,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.33
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Daniel Reisel: The neuroscience of restorative justice",
    "resource_id": 7652,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3.83
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.58
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Onora O'Neill: What we don't understand about trust",
    "resource_id": 7666,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "06. Sheena Iyengar: How to make choosing easier",
    "resource_id": 5057,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "07. Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 5198,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 9.5
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 7791,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 43.92
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 32
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Johanna Blakley: Social media and the end of gender",
    "resource_id": 7589,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Regina Hartley: Why the best hire might not have the perfect resume",
    "resource_id": 7762,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Camille Seaman: Haunting photos of polar ice",
    "resource_id": 7679,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Elizabeth Lev: The unheard story of the Sistine Chapel",
    "resource_id": 7839,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 36.83
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Dan Gilbert: Why we make bad decisions",
    "resource_id": 5052,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 7609,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 14.75
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 13
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 11.41
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 7.92
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Ingrid Betancourt: What six years in captivity taught me about fear and faith",
    "resource_id": 7804,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Amy Tan: Where does creativity hide?",
    "resource_id": 7610,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 25
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "02. David Pogue: 10 top time-saving tech tips",
    "resource_id": 5166,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 6.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "William Kamkwamba: How I harnessed the wind",
    "resource_id": 7783,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 6.5
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "07. Sheena Iyengar: The art of choosing",
    "resource_id": 5058,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Derek Sivers: How to start a movement",
    "resource_id": 7777,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Nabila Alibhai: Why people of different faiths are painting their houses of worship yellow",
    "resource_id": 7811,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.17
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: The doubt essential to faith",
    "resource_id": 7809,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "03. Renata Salecl: Our unhealthy obsession with choice",
    "resource_id": 5054,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 23.92
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "05. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 4997,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "Naomi Oreskes: Why we should trust scientists",
    "resource_id": 7794,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "08. Nalini Nadkarni: Life science in prison",
    "resource_id": 5077,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "05. Lesley Hazleton: The doubt essential to faith",
    "resource_id": 5237,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Achenyo Idachaba: How I turned a deadly plant into a thriving business",
    "resource_id": 7784,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 6.42
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 41.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Alice Goffman: How we're priming some kids for college — and others for prison",
    "resource_id": 7636,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.42
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 62.67
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.92
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "10. Vikram Patel: Mental health for all by involving all",
    "resource_id": 5002,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.67
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 23.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "12. Jill Tarter: Join the SETI search",
    "resource_id": 5214,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Hannah Fry: The mathematics of love",
    "resource_id": 7674,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3.08
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 32.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Angela Lee Duckworth: Grit: The power of passion and perseverance",
    "resource_id": 7760,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "13. Kare Anderson: Be an opportunity maker",
    "resource_id": 5138,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "04. Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 5195,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.5
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.92
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "11. Terry Moore: Why is 'x' the unknown?",
    "resource_id": 5102,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "04. Edward Snowden: Here's how we take back the Internet",
    "resource_id": 5168,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.92
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.75
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3.34
  },
  {
    "person_key": 156586,
    "user_name": "User156586",
    "first_name": "User",
    "last_name": 156586,
    "doc_id": 12345,
    "learning_object": "04. Rishi Manchanda: What makes us get sick? Look upstream.",
    "resource_id": 4981,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 11.92
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 32.67
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Frances Larson: Why public beheadings get millions of views",
    "resource_id": 7828,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Misha Glenny: Hire the hackers!",
    "resource_id": 7723,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "17. Munir Virani: Why I love vultures",
    "resource_id": 5219,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 34.5
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Naomi Oreskes: Why we should trust scientists",
    "resource_id": 7794,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Alexis Ohanian: How to make a splash in social media",
    "resource_id": 7718,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 34.58
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30.17
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 122.58
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Rebecca Brachman: Could a drug prevent depression and PTSD?",
    "resource_id": 7619,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3.33
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5.75
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Conclusion",
    "resource_id": 6198,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.92
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.5
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 11.5
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2",
    "resource_id": 7999,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 167950,
    "user_name": "User167950",
    "first_name": "User",
    "last_name": 167950,
    "doc_id": 12345,
    "learning_object": "Ronald Sullivan: How I help free innocent people from prison",
    "resource_id": 7634,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 12.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Euna Lee: What I learned as a prisoner in North Korea",
    "resource_id": 7667,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "09. Sarah Lewis: Embrace the near win",
    "resource_id": 5022,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "09. Myriam Sidibe: The simple power of hand-washing",
    "resource_id": 4986,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Margaret Wertheim: The beautiful math of coral",
    "resource_id": 7692,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.5
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Nalini Nadkarni: Life science in prison",
    "resource_id": 7658,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.17
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "13. Rajesh Rao: A Rosetta Stone for a lost language",
    "resource_id": 5253,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 18.5
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 17.5
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Melati and Isabel Wijsen: Our campaign to ban plastic bags in Bali",
    "resource_id": 7780,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 22
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Alicia Garza, Patrisse Cullors and Opal Tometi: An interview with the founders of Black Lives Matter",
    "resource_id": 7640,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.67
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "17. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5157,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.58
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 11.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 7593,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.08
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.59
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 4.42
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 13.83
  },
  {
    "person_key": 165126,
    "user_name": "User165126",
    "first_name": "User",
    "last_name": 165126,
    "doc_id": 12345,
    "learning_object": "Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 7775,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 28.58
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 7792,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 18.5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "08. Ruth Chang: How to make hard choices",
    "resource_id": 5059,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5.83
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "Ben Goldacre: Battling bad science",
    "resource_id": 7793,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.92
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "Michael Dickinson: How a fly flies",
    "resource_id": 7796,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 17.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 7739,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "02. Alice Dreger: Is anatomy destiny?",
    "resource_id": 5004,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5.75
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.25
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Ed Yong: Zombie roaches and other parasite tales",
    "resource_id": 7789,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.58
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Melissa Marshall: Talk nerdy to me",
    "resource_id": 7771,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 7778,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 35.25
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 7653,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 10
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Courtney E. Martin: The new American Dream",
    "resource_id": 7838,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 17.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 7831,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Joy Buolamwini: How I'm fighting bias in algorithms",
    "resource_id": 7721,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.92
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 7.5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Beau Lotto: Optical illusions show how we see",
    "resource_id": 7790,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 10.33
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5.67
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Rachel Sussman: The world's oldest living things",
    "resource_id": 7682,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 14.83
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.08
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Trippin The Lab",
    "resource_id": 7221,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5.67
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3",
    "resource_id": 7997,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 11.33
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 19.17
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "The Constitution of the Iroquois League of Nations",
    "resource_id": 6152,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "10. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 5149,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "Rich Benjamin: My road trip through the whitest towns in America",
    "resource_id": 7562,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.34
  },
  {
    "person_key": 172083,
    "user_name": "User172083",
    "first_name": "User",
    "last_name": 172083,
    "doc_id": 12345,
    "learning_object": "Brian Little: Who are you, really? The puzzle of personality",
    "resource_id": 7742,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 4.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "14. Rose George: Let's talk crap. Seriously.",
    "resource_id": 4991,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Rebecca MacKinnon: Let's take back the Internet!",
    "resource_id": 7728,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 24.42
  },
  {
    "person_key": 163074,
    "user_name": "User163074",
    "first_name": "User",
    "last_name": 163074,
    "doc_id": 12345,
    "learning_object": "Lesson 4:Chapter 4 - Be Aware of the Snowball Effect of Your Thinking",
    "resource_id": 7982,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3.25
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.34
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "11. Carol Dweck: The power of believing that you can improve",
    "resource_id": 5024,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 151.75
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Letter to Your 10-Year-Old Self",
    "resource_id": 7970,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.92
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 10.83
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "04. Kathryn Schulz: Don't regret regret",
    "resource_id": 5017,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 16.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 4.25
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 27.33
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.42
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 32.67
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "06. Damon Horowitz: Philosophy in prison",
    "resource_id": 5075,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 4.92
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "08. Nalini Nadkarni: Life science in prison",
    "resource_id": 5077,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.83
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "The Way of the Orisha",
    "resource_id": 6128,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.919998
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "The War of the Worlds Audiobook",
    "resource_id": 5836,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "12. Jean-Baptiste Michel: The mathematics of history",
    "resource_id": 5103,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Kandice Sumner: How America's public schools keep kids in poverty",
    "resource_id": 7645,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30.08
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.25
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5.58
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 59.83
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "11. Susan Colantuono: The career advice you probably didn’t get",
    "resource_id": 5136,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 92.33
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Suzanne Simard: How trees talk to each other",
    "resource_id": 7687,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Ludwick Marishane: A bath without water",
    "resource_id": 7781,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Siamak Hariri: How do you build a sacred space?",
    "resource_id": 7812,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 39.75
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 7650,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 8.17
  },
  {
    "person_key": 163028,
    "user_name": "User163028",
    "first_name": "User",
    "last_name": 163028,
    "doc_id": 12345,
    "learning_object": "Islam The Hadith",
    "resource_id": 6170,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.92
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Emilie Wapnick: Why some of us don't have one true calling",
    "resource_id": 7761,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 8.5
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 82.5
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "14. Suzanne Talhouk: Don't kill your language",
    "resource_id": 5254,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3.33
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.83
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 7792,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Brené Brown: Listening to shame",
    "resource_id": 7824,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.42
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "02. Rita Pierson: Every kid needs a champion",
    "resource_id": 5041,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.92
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 36.5
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Rajiv Maheswaran: The math behind basketball's wildest moves",
    "resource_id": 7673,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 13.33
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1",
    "resource_id": 7998,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 7.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Celeste Headlee: 10 ways to have a better conversation",
    "resource_id": 7770,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.83
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.25
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 7547,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 69.17
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Paul Knoepfler: The ethical dilemma of designer babies",
    "resource_id": 7801,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 17.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Rishi Manchanda: What makes us get sick? Look upstream",
    "resource_id": 7617,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 9.33
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "David Christian: The history of our world in 18 minutes",
    "resource_id": 7788,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.67
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Shah Rukh Khan: Thoughts on humanity, fame and love",
    "resource_id": 7670,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 8.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 8.5
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 22.5
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Quiz",
    "resource_id": 8811,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 702
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "08. Chip Conley: Measuring what makes life worthwhile",
    "resource_id": 5086,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 2: Quiz",
    "resource_id": 8827,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 603
  },
  {
    "person_key": 167950,
    "user_name": "User167950",
    "first_name": "User",
    "last_name": 167950,
    "doc_id": 12345,
    "learning_object": "Clint Smith: How to raise a black son in America",
    "resource_id": 7643,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 6.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.67
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Yves Morieux: How too many rules at work keep you from getting things done",
    "resource_id": 7772,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "Dr.Jekyll and Mr.Hyde Audiobook",
    "resource_id": 5809,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 7.17
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 7792,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 20.25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 14.92
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "04. Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 5006,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "A Very Good Year",
    "resource_id": 6899,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "06. AJ Jacobs: How healthy living nearly killed me",
    "resource_id": 4983,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.83
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Hannah Fry: The mathematics of love",
    "resource_id": 5091,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 23.33
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 6.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Courtney E. Martin: The new American Dream",
    "resource_id": 7838,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.92
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "iO Tillett Wright: Fifty shades of gay",
    "resource_id": 7592,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.42
  },
  {
    "person_key": 163074,
    "user_name": "User163074",
    "first_name": "User",
    "last_name": 163074,
    "doc_id": 12345,
    "learning_object": "Lesson 5: Chapter 5 - Develop Your Compassion",
    "resource_id": 8008,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 2: The Building Blocks of Language and The Narrative Essay",
    "resource_id": 6416,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 22.83
  },
  {
    "person_key": 163028,
    "user_name": "User163028",
    "first_name": "User",
    "last_name": 163028,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Christopher Bell: Bring on the female superheroes!",
    "resource_id": 7600,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 16.67
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 8.58
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Ben Goldacre: Battling bad science",
    "resource_id": 7793,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.08
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "05. Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 5144,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.33
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Jeff Smith: Lessons in business ... from prison",
    "resource_id": 7662,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 7768,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5.67
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 7629,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.16
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.59
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "08. Johanna Blakley: Social media and the end of gender",
    "resource_id": 5010,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Paul Knoepfler: The ethical dilemma of designer babies",
    "resource_id": 7801,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 19.5
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "07. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5146,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.5
  },
  {
    "person_key": 163074,
    "user_name": "User163074",
    "first_name": "User",
    "last_name": 163074,
    "doc_id": 12345,
    "learning_object": "African American Books",
    "resource_id": 7327,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.83
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 4.75
  },
  {
    "person_key": 163028,
    "user_name": "User163028",
    "first_name": "User",
    "last_name": 163028,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 8.5
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Naomi Oreskes: Why we should trust scientists",
    "resource_id": 7794,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 14.83
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "07. Dan Buettner: How to live to be 100+",
    "resource_id": 4984,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 23.92
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "Journey to the Center of the Earth Audiobook",
    "resource_id": 5820,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 158132,
    "user_name": "User158132",
    "first_name": "User",
    "last_name": 158132,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.08
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "06. Manal al-Sharif: A Saudi woman who dared to drive",
    "resource_id": 5019,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Amy Cuddy: Your body language may shape who you are",
    "resource_id": 7769,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 7.83
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "LaToya Ruby Frazier: A visual history of inequality in industrial America",
    "resource_id": 7837,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.92
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "09. Margaret Wertheim: The beautiful math of coral",
    "resource_id": 5100,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5.58
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 4.83
  },
  {
    "person_key": 165126,
    "user_name": "User165126",
    "first_name": "User",
    "last_name": 165126,
    "doc_id": 12345,
    "learning_object": "Derek Sivers: How to start a movement",
    "resource_id": 7777,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 6.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week 6 Unit 5 Beowulf",
    "resource_id": 6514,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Sangu Delle: There's no shame in taking care of your mental health",
    "resource_id": 7744,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "03. Christopher Emdin: Teach teachers how to create magic",
    "resource_id": 5042,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 6.67
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3
  },
  {
    "person_key": 167882,
    "user_name": "User167882",
    "first_name": "User",
    "last_name": 167882,
    "doc_id": 12345,
    "learning_object": "03. David Pogue: Cool tricks your phone can do",
    "resource_id": 5167,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 6.5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Anand Giridharadas: A tale of two Americas. And the mini-mart where they collided",
    "resource_id": 7822,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.5
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction to Physical Sciences",
    "resource_id": 7464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.92
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "09. Kiran Bedi: A police chief with a difference",
    "resource_id": 5078,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.75
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "22. Jill Bolte Taylor: My stroke of insight",
    "resource_id": 5162,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Jackson Katz: Violence against women -- it's a men's issue",
    "resource_id": 7575,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 23.17
  },
  {
    "person_key": 163074,
    "user_name": "User163074",
    "first_name": "User",
    "last_name": 163074,
    "doc_id": 12345,
    "learning_object": "Ethiopia Book",
    "resource_id": 6139,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.92
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Jack Conte: How artists can (finally) get paid in the digital age",
    "resource_id": 7785,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 11.08
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "LaToya Ruby Frazier: A visual history of inequality in industrial America",
    "resource_id": 7837,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.92
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 7779,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 3.34
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "09. Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 5134,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 16.5
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Trippin The Accident",
    "resource_id": 7220,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 5.58
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 89
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.419998
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: On reading the Koran",
    "resource_id": 7810,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.92
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 24.67
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Kathryn Schulz: Don't regret regret",
    "resource_id": 7826,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "09. Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 5068,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 18.5
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "04. Karen Armstrong: Let's revive the Golden Rule",
    "resource_id": 5236,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 2.5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 27
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 6.08
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.919998
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Adam Savage: My love letter to cosplay",
    "resource_id": 7612,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 15.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "T. Morgan Dixon and Vanessa Garrison: When Black women walk, things change",
    "resource_id": 7638,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.83
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.09
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.419998
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Norman Spack: How I help transgender teens become who they want to be",
    "resource_id": 7594,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "14. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5154,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.83
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Alain de Botton: Atheism 2.0",
    "resource_id": 7808,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "07. Julian Treasure: How to speak so that people want to listen",
    "resource_id": 5132,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "06. Amy Webb: How I hacked online dating",
    "resource_id": 5065,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Sharon Brous: It's time to reclaim religion",
    "resource_id": 7803,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.33
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 16.25
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.92
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 5018,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Kandice Sumner: How America's public schools keep kids in poverty",
    "resource_id": 7645,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1.92
  },
  {
    "person_key": 163028,
    "user_name": "User163028",
    "first_name": "User",
    "last_name": 163028,
    "doc_id": 12345,
    "learning_object": "Prayers to the Orishas",
    "resource_id": 6135,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 29.92
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Frances Larson: Why public beheadings get millions of views",
    "resource_id": 7828,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.58
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 1
  },
  {
    "person_key": 162714,
    "user_name": "User162714",
    "first_name": "User",
    "last_name": 162714,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Mac Barnett: Why a good book is a secret door",
    "resource_id": 7598,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 18
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1c: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-Reading for Writers",
    "resource_id": 6415,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 6.67
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 7.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 13.5
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "03. Margaret Heffernan: Dare to disagree",
    "resource_id": 5016,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "Twelfth Night Audiobook",
    "resource_id": 5878,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30
  },
  {
    "person_key": 165126,
    "user_name": "User165126",
    "first_name": "User",
    "last_name": 165126,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-26",
    "time_spent_min": 30.83
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 3.92
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.330002
  },
  {
    "person_key": 163028,
    "user_name": "User163028",
    "first_name": "User",
    "last_name": 163028,
    "doc_id": 12345,
    "learning_object": "07. Dan Buettner: How to live to be 100+",
    "resource_id": 4984,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 20.58
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Quiz",
    "resource_id": 8825,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 2425
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 1",
    "resource_id": 8824,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 18
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 4: Quiz",
    "resource_id": 8906,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 723
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 11.75
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 2: Quiz",
    "resource_id": 8905,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 9
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "11. Ben Saunders: To the South Pole and back — the hardest 105 days of my life",
    "resource_id": 5124,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 3.92
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1",
    "resource_id": 7995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 29.91
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "Arthur Benjamin: The magic of Fibonacci numbers",
    "resource_id": 7689,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.33
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Paul Piff: Does money make you mean?",
    "resource_id": 7703,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 18.17
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Richard Turere: My invention that made peace with lions",
    "resource_id": 7776,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 8
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 29.75
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 7593,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 47.25
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.08
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 13.83
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167950,
    "user_name": "User167950",
    "first_name": "User",
    "last_name": 167950,
    "doc_id": 12345,
    "learning_object": "Nabila Alibhai: Why people of different faiths are painting their houses of worship yellow",
    "resource_id": 7811,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 31.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.08
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 7813,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.75
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 20.17
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "12. Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 5125,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.92
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Sangu Delle: There's no shame in taking care of your mental health",
    "resource_id": 7744,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3",
    "resource_id": 7994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 23.5
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 3.58
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 15.75
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "The Way of the Orisha",
    "resource_id": 6128,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 29.91
  },
  {
    "person_key": 167776,
    "user_name": "User167776",
    "first_name": "User",
    "last_name": 167776,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 28.17
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 15.83
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 34.5
  },
  {
    "person_key": 167950,
    "user_name": "User167950",
    "first_name": "User",
    "last_name": 167950,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: On reading the Koran",
    "resource_id": 7810,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.58
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 12.42
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Alice Dreger: Is anatomy destiny?",
    "resource_id": 7587,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 6.75
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Kathryn Schulz: Don't regret regret",
    "resource_id": 7826,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 22.75
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 3.67
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Rutger Bregman: Poverty isn't a lack of character; it's a lack of cash",
    "resource_id": 7696,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 15.42
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.330002
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 3.92
  },
  {
    "person_key": 168081,
    "user_name": "User168081",
    "first_name": "User",
    "last_name": 168081,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "6A",
    "location_id": 1697453,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.25
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 5018,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.33
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 7778,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 42.08
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1",
    "resource_id": 7992,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 9.17
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 67.5
  },
  {
    "person_key": 159891,
    "user_name": "User159891",
    "first_name": "User",
    "last_name": 159891,
    "doc_id": 12345,
    "learning_object": "Rich Benjamin: My road trip through the whitest towns in America",
    "resource_id": 7562,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.42
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Latif Nasser: You have no idea where camels really come from",
    "resource_id": 7614,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 13
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Brené Brown: Listening to shame",
    "resource_id": 7824,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.08
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Arthur Benjamin: The magic of Fibonacci numbers",
    "resource_id": 7689,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 13.83
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.08
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.5
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 2.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 3.17
  },
  {
    "person_key": 169785,
    "user_name": "User169785",
    "first_name": "User",
    "last_name": 169785,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ancora Psychiatric Hospital",
    "location_id": 1697442,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 29.92
  },
  {
    "person_key": 410,
    "user_name": "User410",
    "first_name": "User",
    "last_name": 410,
    "doc_id": 12345,
    "learning_object": "Ed Yong: Zombie roaches and other parasite tales",
    "resource_id": 7789,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 410,
    "user_name": "User410",
    "first_name": "User",
    "last_name": 410,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.09
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 9.17
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 28.08
  },
  {
    "person_key": 160502,
    "user_name": "User160502",
    "first_name": "User",
    "last_name": 160502,
    "doc_id": 12345,
    "learning_object": "Shah Rukh Khan: Thoughts on humanity, fame and love",
    "resource_id": 7670,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 17.92
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "All Kinds of Minds",
    "resource_id": 4947,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.92
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 4.75
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 25.25
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Adam Savage: My love letter to cosplay",
    "resource_id": 7612,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 13.83
  },
  {
    "person_key": 169240,
    "user_name": "User169240",
    "first_name": "User",
    "last_name": 169240,
    "doc_id": 12345,
    "learning_object": "Dilip Ratha: The hidden force in global economics: sending money home",
    "resource_id": 7711,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Onora O'Neill: What we don't understand about trust",
    "resource_id": 7666,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1",
    "resource_id": 7995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 25.08
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "04. Kathryn Schulz: Don't regret regret",
    "resource_id": 5017,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 36.5
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 13.75
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 13.16
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 70.17
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Theo E.J. Wilson: A black man goes undercover in the alt-right",
    "resource_id": 7729,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 20.5
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2",
    "resource_id": 7993,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 410,
    "user_name": "User410",
    "first_name": "User",
    "last_name": 410,
    "doc_id": 12345,
    "learning_object": "Nation of Islam Information",
    "resource_id": 6286,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.58
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 8.92
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.17
  },
  {
    "person_key": 169240,
    "user_name": "User169240",
    "first_name": "User",
    "last_name": 169240,
    "doc_id": 12345,
    "learning_object": "Jessica Jackley: Poverty, money -- and love",
    "resource_id": 7712,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "07. John Wooden: The difference between winning and succeeding",
    "resource_id": 5020,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.17
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169240,
    "user_name": "User169240",
    "first_name": "User",
    "last_name": 169240,
    "doc_id": 12345,
    "learning_object": "01. Dan Gilbert: Why we make bad decisions",
    "resource_id": 5052,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 53.25
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 29.92
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 9.08
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 3.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.75
  },
  {
    "person_key": 165949,
    "user_name": "User165949",
    "first_name": "User",
    "last_name": 165949,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.92
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Anand Giridharadas: A tale of two Americas. And the mini-mart where they collided",
    "resource_id": 7822,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 16.5
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 9.92
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.5
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.58
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "06. Manal al-Sharif: A Saudi woman who dared to drive",
    "resource_id": 5019,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.92
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Tomás Saraceno: Would you live in a floating city in the sky?",
    "resource_id": 7572,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.5
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 17.67
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 30.17
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "Ladder Safety",
    "resource_id": 8289,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Nicki Minaj",
    "resource_id": 7005,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.33
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.08
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 12.75
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.75
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 12.59
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 7831,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.83
  },
  {
    "person_key": 169240,
    "user_name": "User169240",
    "first_name": "User",
    "last_name": 169240,
    "doc_id": 12345,
    "learning_object": "06. Sheena Iyengar: How to make choosing easier",
    "resource_id": 5057,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 19
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.84
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 15.5
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.83
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Sean Combs",
    "resource_id": 7009,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.25
  },
  {
    "person_key": 159891,
    "user_name": "User159891",
    "first_name": "User",
    "last_name": 159891,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Shah Rukh Khan: Thoughts on humanity, fame and love",
    "resource_id": 7670,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 15.17
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.5
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Safwat Saleem: Why I keep speaking up, even when people mock my accent",
    "resource_id": 7571,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.17
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 4
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Beyonce",
    "resource_id": 6995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "APDS Issue Report",
    "resource_id": 5464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.17
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 14.92
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Nagin Cox: What time is it on Mars?",
    "resource_id": 7749,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Mundano: Trash cart superheroes",
    "resource_id": 7551,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.5
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.160004
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 44.08
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1",
    "resource_id": 7992,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.33
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.160004
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 48.08
  },
  {
    "person_key": 165949,
    "user_name": "User165949",
    "first_name": "User",
    "last_name": 165949,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.08
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Rihanna",
    "resource_id": 7008,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 4.42
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "05. Derek Sivers: Keep your goals to yourself",
    "resource_id": 5196,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.67
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "03. David Pogue: Cool tricks your phone can do",
    "resource_id": 5167,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 3
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Old Testament",
    "resource_id": 7385,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.84
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 38.33
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.66
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "02. Alice Dreger: Is anatomy destiny?",
    "resource_id": 5004,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.75
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.33
  },
  {
    "person_key": 159891,
    "user_name": "User159891",
    "first_name": "User",
    "last_name": 159891,
    "doc_id": 12345,
    "learning_object": "Alix Generous: How I learned to communicate my inner life with Asperger's",
    "resource_id": 7537,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Jedidah Isler: How I fell in love with quasars, blazars and our incredible universe",
    "resource_id": 7750,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.25
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.5
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.67
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.25
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.92
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.330002
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 25.25
  },
  {
    "person_key": 159891,
    "user_name": "User159891",
    "first_name": "User",
    "last_name": 159891,
    "doc_id": 12345,
    "learning_object": "Oliver Sacks: What hallucination reveals about our minds",
    "resource_id": 7554,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 41.5
  },
  {
    "person_key": 160502,
    "user_name": "User160502",
    "first_name": "User",
    "last_name": 160502,
    "doc_id": 12345,
    "learning_object": "Chetan Bhatt: Dare to refuse the origin myths that claim who you are",
    "resource_id": 7669,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 22.75
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Anand Giridharadas: A tale of two Americas. And the mini-mart where they collided",
    "resource_id": 7822,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.58
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Josh Luber: Why sneakers are a great investment",
    "resource_id": 7695,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 12.58
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.5
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Pitbull",
    "resource_id": 7007,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.42
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 15.83
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 11.83
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: California",
    "resource_id": 6196,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 43.17
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 8.67
  },
  {
    "person_key": 168081,
    "user_name": "User168081",
    "first_name": "User",
    "last_name": 168081,
    "doc_id": 12345,
    "learning_object": "JORDAN RIDES THE BUS",
    "resource_id": 8496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "6A",
    "location_id": 1697453,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 8.92
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Negin Farsad: A highly scientific taxonomy of haters",
    "resource_id": 7569,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.75
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 15
  },
  {
    "person_key": 163028,
    "user_name": "User163028",
    "first_name": "User",
    "last_name": 163028,
    "doc_id": 12345,
    "learning_object": "09. Myriam Sidibe: The simple power of hand-washing",
    "resource_id": 4986,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 11.75
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 20.17
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 6.58
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 10.42
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Ben Wellington: How we found the worst place to park in New York City -- using big data",
    "resource_id": 7686,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 13.17
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 66
  },
  {
    "person_key": 169240,
    "user_name": "User169240",
    "first_name": "User",
    "last_name": 169240,
    "doc_id": 12345,
    "learning_object": "Rutger Bregman: Poverty isn't a lack of character; it's a lack of cash",
    "resource_id": 7696,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 20.33
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Chapter",
    "resource_id": 6197,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 22.58
  },
  {
    "person_key": 170417,
    "user_name": "User170417",
    "first_name": "User",
    "last_name": 170417,
    "doc_id": 12345,
    "learning_object": "Student Book - L15 Draw Conclusions - Quiz",
    "resource_id": 10365,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 4314
  },
  {
    "person_key": 166503,
    "user_name": "User166503",
    "first_name": "User",
    "last_name": 166503,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 15.42
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 2.41
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2",
    "resource_id": 7988,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "09. George Monbiot: For more wonder, rewild the world",
    "resource_id": 5122,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3",
    "resource_id": 7994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2",
    "resource_id": 7993,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.67
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 59.83
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 30
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 10.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "iO Tillett Wright: Fifty shades of gay",
    "resource_id": 7592,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 7580,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.419998
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "APDS Issue Report",
    "resource_id": 5464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 5.08
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Margaret Wertheim: The beautiful math of coral",
    "resource_id": 7692,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168081,
    "user_name": "User168081",
    "first_name": "User",
    "last_name": 168081,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "6A",
    "location_id": 1697453,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 8.67
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 8.42
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 4.17
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 37.16
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.34
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 7775,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.08
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "10. Paul Nicklen: Tales of ice-bound wonderlands",
    "resource_id": 5123,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 22.5
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L8 Pyramids, Cones, and Spheres",
    "resource_id": 10360,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 266
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L9 Composite Solids (CS)",
    "resource_id": 10361,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 139
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.419998
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 62.42
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 4.08
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 54.17
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Mac Barnett: Why a good book is a secret door",
    "resource_id": 7598,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 17.33
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.42
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 2.34
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Courtney E. Martin: The new American Dream",
    "resource_id": 7838,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 7.75
  },
  {
    "person_key": 165949,
    "user_name": "User165949",
    "first_name": "User",
    "last_name": 165949,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 8.58
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 20.67
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.67
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Lucianne Walkowicz: Let's not use Mars as a backup planet",
    "resource_id": 7757,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Derek Sivers: How to start a movement",
    "resource_id": 7777,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 3.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 10.83
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Gabriela González: How LIGO discovered gravitational waves -- and what might be next",
    "resource_id": 7773,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.58
  },
  {
    "person_key": 165949,
    "user_name": "User165949",
    "first_name": "User",
    "last_name": 165949,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 10.83
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "05. McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 5007,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 4.25
  },
  {
    "person_key": 157894,
    "user_name": "User157894",
    "first_name": "User",
    "last_name": 157894,
    "doc_id": 12345,
    "learning_object": "Santos Podcast: Book Reviews",
    "resource_id": 6195,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 23.75
  },
  {
    "person_key": 158092,
    "user_name": "User158092",
    "first_name": "User",
    "last_name": 158092,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 3.42
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3",
    "resource_id": 7989,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.67
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 8.67
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 21.75
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "14. Rose George: Let's talk crap. Seriously.",
    "resource_id": 4991,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 10
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Troubleshooting Networking Devices",
    "resource_id": 8250,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 30.75
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 23.66
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 100.5
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Curtis \"Wall Street\" Carroll: How I learned to read -- and trade stocks -- in prison",
    "resource_id": 7700,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 16.75
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.5
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Elizabeth Lev: The unheard story of the Sistine Chapel",
    "resource_id": 7839,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 19.08
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.5
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: How the worst moments in our lives make us who we are",
    "resource_id": 7818,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.17
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "15. Paul Pholeros: How to reduce poverty? Fix homes",
    "resource_id": 4992,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 6.5
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Mac Stone: Stunning photos of the endangered Everglades",
    "resource_id": 7698,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Alain de Botton: A kinder, gentler philosophy of success",
    "resource_id": 7825,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Haas&Hahn: How painting can transform communities",
    "resource_id": 7553,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.33
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 29.92
  },
  {
    "person_key": 163028,
    "user_name": "User163028",
    "first_name": "User",
    "last_name": 163028,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.75
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.5
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week 6 Unit 5 Beowulf",
    "resource_id": 6513,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169240,
    "user_name": "User169240",
    "first_name": "User",
    "last_name": 169240,
    "doc_id": 12345,
    "learning_object": "Tania Luna: How a penny made me feel like a millionaire",
    "resource_id": 7713,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Lesson 16",
    "resource_id": 9488,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 2
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Adam Savage: My love letter to cosplay",
    "resource_id": 7612,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 13.75
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Lesson 15",
    "resource_id": 9486,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 55
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 2.75
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 1.33
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Lesson 15 Quiz",
    "resource_id": 9487,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 430
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 10.58
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Troubleshooting Printers",
    "resource_id": 8128,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 30
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 16.58
  },
  {
    "person_key": 455,
    "user_name": "User455",
    "first_name": "User",
    "last_name": 455,
    "doc_id": 12345,
    "learning_object": "Intermediate Math Formative Assessment",
    "resource_id": 9446,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 946
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "04. Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 5195,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 39.67
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Latif Nasser: You have no idea where camels really come from",
    "resource_id": 7614,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 18.92
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-25",
    "time_spent_min": 20.17
  },
  {
    "person_key": 410,
    "user_name": "User410",
    "first_name": "User",
    "last_name": 410,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Mobile Devices",
    "resource_id": 8248,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 5.42
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 2
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "02. Elizabeth Gilbert: Your elusive creative genius",
    "resource_id": 5182,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.58
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Crime in America Week 1",
    "resource_id": 8002,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.16
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 8.83
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "The Way of the Orisha",
    "resource_id": 6128,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 42.17
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 29.92
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Amy Tan: Where does creativity hide?",
    "resource_id": 7610,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 42.75
  },
  {
    "person_key": 167377,
    "user_name": "User167377",
    "first_name": "User",
    "last_name": 167377,
    "doc_id": 12345,
    "learning_object": "Lesson 2: Chapter 2 Make Peace with Imperfection",
    "resource_id": 7984,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 16.58
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 34.17
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.91
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 58.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 92.25
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.160004
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "08. Erin McKean: Go ahead, make up new words!",
    "resource_id": 5188,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 11.75
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "07. Jared Diamond: Why do societies collapse?",
    "resource_id": 5247,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 2
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.92
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 6.92
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 46.58
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 22.67
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "01. Eric Sanderson: New York -- before the City",
    "resource_id": 5241,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 16.42
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Sarah Kay: If I should have a daughter ...",
    "resource_id": 7550,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "10. Edward Tenner: Unintended consequences",
    "resource_id": 5250,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 16.25
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.17
  },
  {
    "person_key": 261,
    "user_name": "User261",
    "first_name": "User",
    "last_name": 261,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.25
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Crime in America Week 2",
    "resource_id": 8003,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.58
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Ed Yong: Zombie roaches and other parasite tales",
    "resource_id": 7789,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 14.25
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "07. Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 5198,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.08
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 9.5
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Noah Feldman: Hamilton vs. Madison and the birth of American partisanship",
    "resource_id": 7832,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Eric Sanderson: New York -- before the City",
    "resource_id": 7827,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154239,
    "user_name": "User154239",
    "first_name": "User",
    "last_name": 154239,
    "doc_id": 12345,
    "learning_object": "JORDAN RIDES THE BUS",
    "resource_id": 8496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 55.5
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "12. Laurie Garrett: Lessons from the 1918 flu",
    "resource_id": 5252,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 24.75
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Thomas Insel: Toward a new understanding of mental illness",
    "resource_id": 7556,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "03. Renata Salecl: Our unhealthy obsession with choice",
    "resource_id": 5054,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.75
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "04. Edward Snowden: Here's how we take back the Internet",
    "resource_id": 5168,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 30
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 21.58
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "04. Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 5195,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 52.92
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "04. Rishi Manchanda: What makes us get sick? Look upstream.",
    "resource_id": 4981,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.67
  },
  {
    "person_key": 410,
    "user_name": "User410",
    "first_name": "User",
    "last_name": 410,
    "doc_id": 12345,
    "learning_object": "06. Andrew Solomon: Depression, the secret we share",
    "resource_id": 4961,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.33
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.17
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 30.08
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "11. Anupam Mishra: The ancient ingenuity of water harvesting",
    "resource_id": 5251,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 22.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.84
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "08. Nalini Nadkarni: Life science in prison",
    "resource_id": 5077,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.58
  },
  {
    "person_key": 165949,
    "user_name": "User165949",
    "first_name": "User",
    "last_name": 165949,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.42
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Jedidah Isler: How I fell in love with quasars, blazars and our incredible universe",
    "resource_id": 7750,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 4.42
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 2.75
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 29.91
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "06. Sheena Iyengar: How to make choosing easier",
    "resource_id": 5057,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.67
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.58
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "07. Anne Curzan: What makes a word \"real\"?",
    "resource_id": 5187,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.67
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 13.09
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "08. Ruth Chang: How to make hard choices",
    "resource_id": 5059,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Zaria Forman: Drawings that show the beauty and fragility of Earth",
    "resource_id": 7683,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 6.83
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Aimee Mullins: The opportunity of adversity",
    "resource_id": 7816,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "David Casarett: A doctor's case for medical marijuana",
    "resource_id": 7626,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "05. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 4997,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.75
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "05. William Noel: Revealing the lost codex of Archimedes",
    "resource_id": 5245,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 9.92
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "The Call of the Wild Audiobook",
    "resource_id": 5833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 30.75
  },
  {
    "person_key": 167377,
    "user_name": "User167377",
    "first_name": "User",
    "last_name": 167377,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Chapter 1 - Don't Sweat the Small Stuff",
    "resource_id": 7983,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 8.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 4.67
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "07. Rebecca MacKinnon: Let's take back the Internet!",
    "resource_id": 5171,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "08. George Takei: Why I love a country that once betrayed me",
    "resource_id": 5248,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 29.83
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "09. Myriam Sidibe: The simple power of hand-washing",
    "resource_id": 4986,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 4.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.92
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "The Great Adventures of Sherlock Holmes Audiobook",
    "resource_id": 5835,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 30
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.34
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 3.16
  },
  {
    "person_key": 410,
    "user_name": "User410",
    "first_name": "User",
    "last_name": 410,
    "doc_id": 12345,
    "learning_object": "Radio Survey",
    "resource_id": 5689,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 3.75
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Book Club Titles",
    "resource_id": 7324,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 6.58
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 31.33
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 3.75
  },
  {
    "person_key": 168502,
    "user_name": "User168502",
    "first_name": "User",
    "last_name": 168502,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center YA",
    "location_id": 1697496,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170963,
    "user_name": "User170963",
    "first_name": "User",
    "last_name": 170963,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Friday",
    "resource_id": 5729,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "The Kemetic Tree of Life",
    "resource_id": 6122,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 11.92
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "14. Suzanne Talhouk: Don't kill your language",
    "resource_id": 5254,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 5140,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.67
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 88.42
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 14.17
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 47.08
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "04. Malcolm Gladwell: The strange tale of the Norden bombsight",
    "resource_id": 5244,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 15.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 3.67
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "05. William Noel: Revealing the lost codex of Archimedes",
    "resource_id": 5245,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 15.17
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.58
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 29.92
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.5
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 30
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Mac Stone: Stunning photos of the endangered Everglades",
    "resource_id": 7698,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 14.75
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Nagin Cox: What time is it on Mars?",
    "resource_id": 7749,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 26.17
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "04. Malcolm Gladwell: The strange tale of the Norden bombsight",
    "resource_id": 5244,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.59
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Lucianne Walkowicz: Let's not use Mars as a backup planet",
    "resource_id": 7757,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 3.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 15.92
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.09
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7976,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.59
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.17
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.33
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 14.58
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 12.5
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 53.83
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Crime in America Week 1 Quiz",
    "resource_id": 8001,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 2.34
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Frances Larson: Why public beheadings get millions of views",
    "resource_id": 7828,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 10.08
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "06. Parul Sehgal: An ode to envy",
    "resource_id": 5186,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 6
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "04. Elyn Saks: A tale of mental illness -- from the inside",
    "resource_id": 4959,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 16
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "03. Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 5243,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 30
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "08. Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 4985,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.67
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3",
    "resource_id": 7997,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 26.58
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "06. Neil MacGregor: 2600 years of history in one object",
    "resource_id": 5246,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 16.92
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "01. Eric Sanderson: New York -- before the City",
    "resource_id": 5241,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 16.33
  },
  {
    "person_key": 157598,
    "user_name": "User157598",
    "first_name": "User",
    "last_name": 157598,
    "doc_id": 12345,
    "learning_object": "08. Jessica Jackley: Poverty, money -- and love",
    "resource_id": 5111,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.42
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 5.33
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "09. Kiran Bedi: A police chief with a difference",
    "resource_id": 5078,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.58
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "My Name",
    "resource_id": 8368,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.83
  },
  {
    "person_key": 261,
    "user_name": "User261",
    "first_name": "User",
    "last_name": 261,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.75
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 130.67
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.66
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Euna Lee: What I learned as a prisoner in North Korea",
    "resource_id": 7667,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 6.17
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "07. Jared Diamond: Why do societies collapse?",
    "resource_id": 5247,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 18.5
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "The Man in the Iron Mask Audiobook",
    "resource_id": 5844,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 10.33
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 3.33
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "Islam The Hadith",
    "resource_id": 6170,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 10.17
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 14.42
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "FINAL EXAM (UNIT 4 and UNIT 5)",
    "resource_id": 7533,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.08
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "03. Brian Greene: Is our universe the only universe?",
    "resource_id": 5205,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Brian Greene: Is our universe the only universe?",
    "resource_id": 7751,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 30
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "05. Eleanor Longden: The voices in my head",
    "resource_id": 4960,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "10. Elif Shafak: The politics of fiction",
    "resource_id": 5190,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 9
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 36.75
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "04. Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 5206,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 29.92
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 78.67
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.83
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "01. Kelly McGonigal: How to make stress your friend",
    "resource_id": 4993,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 4.84
  },
  {
    "person_key": 261,
    "user_name": "User261",
    "first_name": "User",
    "last_name": 261,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 9.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 42.92
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Anand Giridharadas: A tale of two Americas. And the mini-mart where they collided",
    "resource_id": 7822,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 20.25
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.160004
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "03. Lisa Bu: How books can open your mind",
    "resource_id": 5183,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.17
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 60.66
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Stephen Hawking: Questioning the universe",
    "resource_id": 7752,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.33
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "09. Alexis Ohanian: How to make a splash in social media",
    "resource_id": 5173,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "06. Neil MacGregor: 2600 years of history in one object",
    "resource_id": 5246,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 20.25
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.75
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 27.25
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "The Hunter Audiobook",
    "resource_id": 5818,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 24.17
  },
  {
    "person_key": 167840,
    "user_name": "User167840",
    "first_name": "User",
    "last_name": 167840,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 30.83
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 3.5
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1",
    "resource_id": 7998,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 29.83
  },
  {
    "person_key": 160041,
    "user_name": "User160041",
    "first_name": "User",
    "last_name": 160041,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 2
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "05. David R. Dow: Lessons from death row inmates",
    "resource_id": 5074,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 18.83
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 2
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "09. Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 5249,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 7.25
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 12.25
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 6.5
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 10.66
  },
  {
    "person_key": 163074,
    "user_name": "User163074",
    "first_name": "User",
    "last_name": 163074,
    "doc_id": 12345,
    "learning_object": "Book Club Titles",
    "resource_id": 7324,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 8.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "02. Siegfried Woldhek: The search for the true face of Leonardo",
    "resource_id": 5242,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 4.58
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 30.58
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 8.58
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "09. Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 5189,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 5.58
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168541,
    "user_name": "User168541",
    "first_name": "User",
    "last_name": 168541,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 16.25
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 17.75
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Quiz",
    "resource_id": 8820,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1382
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Quiz",
    "resource_id": 8902,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-24",
    "time_spent_min": 1240
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Lesson Five/Domain 4: Substance Abuse",
    "resource_id": 8067,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 13.25
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 7.5
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "01. Kelly McGonigal: How to make stress your friend",
    "resource_id": 4993,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "OK Go: How to find a wonderful idea",
    "resource_id": 7561,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 20.25
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Emilie Wapnick: Why some of us don't have one true calling",
    "resource_id": 7761,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "12. Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 5125,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 13.17
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "11. Ben Saunders: To the South Pole and back — the hardest 105 days of my life",
    "resource_id": 5124,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 22
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.75
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Paul Nicklen: Animal tales from icy wonderlands",
    "resource_id": 7699,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.58
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.08
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "05. Malcolm Gladwell: Choice, happiness and spaghetti sauce",
    "resource_id": 5056,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.83
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Crime in America Week 1 Quiz",
    "resource_id": 8001,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.16
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 17.58
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.67
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 10
  },
  {
    "person_key": 169785,
    "user_name": "User169785",
    "first_name": "User",
    "last_name": 169785,
    "doc_id": 12345,
    "learning_object": "Kevin Briggs: The bridge between suicide and life",
    "resource_id": 7543,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ancora Psychiatric Hospital",
    "location_id": 1697442,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 6
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Stephen Hawking: Questioning the universe",
    "resource_id": 7752,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 8.33
  },
  {
    "person_key": 164005,
    "user_name": "User164005",
    "first_name": "User",
    "last_name": 164005,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "03. Rick Warren: A life of purpose",
    "resource_id": 5235,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 12
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.83
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "Old Testament",
    "resource_id": 7385,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.92
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "T. Morgan Dixon and Vanessa Garrison: When Black women walk, things change",
    "resource_id": 7638,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.25
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 27.25
  },
  {
    "person_key": 164005,
    "user_name": "User164005",
    "first_name": "User",
    "last_name": 164005,
    "doc_id": 12345,
    "learning_object": "Nalini Nadkarni: Life science in prison",
    "resource_id": 7658,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 9.67
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 12.5
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 4: Energy and Light",
    "resource_id": 7495,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 10
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "11. Carol Dweck: The power of believing that you can improve",
    "resource_id": 5024,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.75
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 31.33
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "06. Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 5030,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "10. Sally Kohn: Let’s try emotional correctness",
    "resource_id": 5035,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.67
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "04. Ash Beckham: We're all hiding something. Let's find the courage to open up",
    "resource_id": 5028,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.17
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 23.33
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 8.58
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "05. Lesley Hazleton: The doubt essential to faith",
    "resource_id": 5237,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 17.58
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 13.08
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Clint Smith: How to raise a black son in America",
    "resource_id": 7643,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Crime in America Week 1",
    "resource_id": 8002,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Alicia Garza, Patrisse Cullors and Opal Tometi: An interview with the founders of Black Lives Matter",
    "resource_id": 7640,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 8.75
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 23.41
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section One",
    "resource_id": 7975,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 58.08
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.75
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.33
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.92
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 13.42
  },
  {
    "person_key": 167857,
    "user_name": "User167857",
    "first_name": "User",
    "last_name": 167857,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 5.58
  },
  {
    "person_key": 168329,
    "user_name": "User168329",
    "first_name": "User",
    "last_name": 168329,
    "doc_id": 12345,
    "learning_object": "Old Testament",
    "resource_id": 7385,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7A",
    "location_id": 1697455,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "12. Sarah Jones: A one-woman global village",
    "resource_id": 5037,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.42
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 4.5
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "08. Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 5240,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 18.92
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Jedidah Isler: How I fell in love with quasars, blazars and our incredible universe",
    "resource_id": 7750,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 14.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.34
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.58
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 5.16
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.91
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.16
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.17
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 22.67
  },
  {
    "person_key": 162948,
    "user_name": "User162948",
    "first_name": "User",
    "last_name": 162948,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ancora Psychiatric Hospital",
    "location_id": 1697442,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 11
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 59.84
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "The Way of the Orisha",
    "resource_id": 6128,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.17
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "10. Joshua Walters: On being just crazy enough",
    "resource_id": 4965,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 6
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 46.17
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 7.92
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 5.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "04. Karen Armstrong: Let's revive the Golden Rule",
    "resource_id": 5236,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "09. Sarah Lewis: Embrace the near win",
    "resource_id": 5022,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.5
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Brian Greene: Is our universe the only universe?",
    "resource_id": 7751,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.17
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 2: Base Units and the Scientific Method",
    "resource_id": 7493,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 8.42
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.34
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.91
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 53.67
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 6.33
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.839996
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Zaria Forman: Drawings that show the beauty and fragility of Earth",
    "resource_id": 7683,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.42
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Psychology of Incarceration Post Test",
    "resource_id": 8085,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 39.92
  },
  {
    "person_key": 166108,
    "user_name": "User166108",
    "first_name": "User",
    "last_name": 166108,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.83
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "02. Dan Ariely: Are we in control of our own decisions?",
    "resource_id": 5053,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.5
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 12.33
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 30
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.92
  },
  {
    "person_key": 162948,
    "user_name": "User162948",
    "first_name": "User",
    "last_name": 162948,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ancora Psychiatric Hospital",
    "location_id": 1697442,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 8.5
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: PC Components",
    "resource_id": 8228,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 30.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.75
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 9.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "10. Paul Nicklen: Tales of ice-bound wonderlands",
    "resource_id": 5123,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 18.5
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "01. Kwame Anthony Appiah: Is religion good or bad? (This is a trick question)",
    "resource_id": 5233,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 14.67
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.75
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 4: Cost Behavior and Cost-Volume-Profit Analysis",
    "resource_id": 7958,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 13.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Adam Galinsky: How to speak up for yourself",
    "resource_id": 7759,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 4.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Richard Dawkins: Why the universe seems so strange",
    "resource_id": 7733,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 23.67
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "01. Dan Gilbert: Why we make bad decisions",
    "resource_id": 5052,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 30.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Brené Brown: Listening to shame",
    "resource_id": 5014,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.33
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "CRIJ 3337 Juvenile Justice",
    "resource_id": 7974,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.08
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.16
  },
  {
    "person_key": 164005,
    "user_name": "User164005",
    "first_name": "User",
    "last_name": 164005,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 6.25
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 16.42
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "07. Mustafa Akyol: Faith versus tradition in Islam",
    "resource_id": 5239,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 27.25
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 5: Motion and Introduction to the Periodic Table",
    "resource_id": 7496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.67
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Lesson Three/Domain 2: Marital-Family Relations",
    "resource_id": 8065,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.75
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "03. Margaret Heffernan: Dare to disagree",
    "resource_id": 5016,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 5.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.75
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "06. Lesley Hazleton: On reading the Koran",
    "resource_id": 5238,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 13.58
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.08
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 43.75
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.67
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.33
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "02. Alain de Botton: Atheism 2.0",
    "resource_id": 5234,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 33.42
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Margaret Wertheim: The beautiful math of coral",
    "resource_id": 7692,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "JORDAN RIDES THE BUS",
    "resource_id": 8496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 57.17
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.91
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.08
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 39.33
  },
  {
    "person_key": 162948,
    "user_name": "User162948",
    "first_name": "User",
    "last_name": 162948,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ancora Psychiatric Hospital",
    "location_id": 1697442,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 5.83
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 4.92
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Andrés Ruzo: The boiling river of the Amazon",
    "resource_id": 7685,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 12.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "06. Manal al-Sharif: A Saudi woman who dared to drive",
    "resource_id": 5019,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 21.67
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Josh Luber: Why sneakers are a great investment",
    "resource_id": 7695,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 6.58
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 6.08
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.58
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.5
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 5.91
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.42
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "04. Barry Schwartz: The paradox of choice",
    "resource_id": 5055,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.33
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Man's Search for Meaning",
    "resource_id": 8084,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 5.33
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 128.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "02. Rita Pierson: Every kid needs a champion",
    "resource_id": 5041,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.17
  },
  {
    "person_key": 166849,
    "user_name": "User166849",
    "first_name": "User",
    "last_name": 166849,
    "doc_id": 12345,
    "learning_object": "01. Hannah Fry: The mathematics of love",
    "resource_id": 5091,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 18.42
  },
  {
    "person_key": 171191,
    "user_name": "User171191",
    "first_name": "User",
    "last_name": 171191,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 14.75
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 62.08
  },
  {
    "person_key": 156293,
    "user_name": "User156293",
    "first_name": "User",
    "last_name": 156293,
    "doc_id": 12345,
    "learning_object": "Joshua Walters: On being just crazy enough",
    "resource_id": 7539,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.17
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.17
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.16
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 66.17
  },
  {
    "person_key": 160041,
    "user_name": "User160041",
    "first_name": "User",
    "last_name": 160041,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 6.5
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 5
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.17
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 145.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 7.75
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.42
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 51
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction to Physical Sciences",
    "resource_id": 7464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 203.91
  },
  {
    "person_key": 156293,
    "user_name": "User156293",
    "first_name": "User",
    "last_name": 156293,
    "doc_id": 12345,
    "learning_object": "Temple Grandin: The world needs all kinds of minds",
    "resource_id": 7538,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.92
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Printers",
    "resource_id": 8230,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 30.25
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "08. Ruth Chang: How to make hard choices",
    "resource_id": 5059,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "03. Faith Jegede: What I've learned from my autistic brothers",
    "resource_id": 5062,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.339996
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.75
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Nagin Cox: What time is it on Mars?",
    "resource_id": 7749,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "08. Stella Young: I’m not your inspiration, thank you very much",
    "resource_id": 5033,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 16.34
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.92
  },
  {
    "person_key": 164005,
    "user_name": "User164005",
    "first_name": "User",
    "last_name": 164005,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.67
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "James Green: 3 moons and a planet that could have alien life",
    "resource_id": 7754,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.25
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "07. David Chalmers: How do you explain consciousness?",
    "resource_id": 5085,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.92
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 56.25
  },
  {
    "person_key": 168329,
    "user_name": "User168329",
    "first_name": "User",
    "last_name": 168329,
    "doc_id": 12345,
    "learning_object": "Historical Books",
    "resource_id": 7384,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7A",
    "location_id": 1697455,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.92
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Simon Sinek: How great leaders inspire action",
    "resource_id": 7758,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.25
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "03. Renata Salecl: Our unhealthy obsession with choice",
    "resource_id": 5054,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.08
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "07. Sheena Iyengar: The art of choosing",
    "resource_id": 5058,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Lesson Two/Domain 1: Employment-Education",
    "resource_id": 8064,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2.42
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.75
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.42
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Lesson Four/Domain 3: Associations and Social Interaction",
    "resource_id": 8066,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 15.08
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction to Physical Sciences",
    "resource_id": 7464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 43.08
  },
  {
    "person_key": 164005,
    "user_name": "User164005",
    "first_name": "User",
    "last_name": 164005,
    "doc_id": 12345,
    "learning_object": "Jackson Katz: Violence against women -- it's a men's issue",
    "resource_id": 7575,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.17
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 24.92
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 32.42
  },
  {
    "person_key": 162948,
    "user_name": "User162948",
    "first_name": "User",
    "last_name": 162948,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ancora Psychiatric Hospital",
    "location_id": 1697442,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.58
  },
  {
    "person_key": 157054,
    "user_name": "User157054",
    "first_name": "User",
    "last_name": 157054,
    "doc_id": 12345,
    "learning_object": "Andrés Ruzo: The boiling river of the Amazon",
    "resource_id": 7685,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.58
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.0899963
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 30.41
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "06. Sheena Iyengar: How to make choosing easier",
    "resource_id": 5057,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.5
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 64.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 15.5
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction to Physical Sciences",
    "resource_id": 7464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.09
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.5
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.25
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 39.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "10. Kathryn Schulz: On being wrong",
    "resource_id": 5023,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 30
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 18
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "Ladder Safety",
    "resource_id": 8289,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 29.58
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 32.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "The Hunter Audiobook",
    "resource_id": 5818,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 5.58
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.25
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Alice Goffman: How we're priming some kids for college — and others for prison",
    "resource_id": 7636,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 19.58
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 1 Technology in Business",
    "resource_id": 7460,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 5.67
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 3: Matter and the Gas Laws",
    "resource_id": 7494,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 8.33
  },
  {
    "person_key": 164005,
    "user_name": "User164005",
    "first_name": "User",
    "last_name": 164005,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.33
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.5
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Rajiv Maheswaran: The math behind basketball's wildest moves",
    "resource_id": 7673,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.83
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 30.67
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 14.25
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 1.08
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 16.58
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7976,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 33.58
  },
  {
    "person_key": 164005,
    "user_name": "User164005",
    "first_name": "User",
    "last_name": 164005,
    "doc_id": 12345,
    "learning_object": "Alain de Botton: Atheism 2.0",
    "resource_id": 7808,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 3.75
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "11. JD Schramm: Break the silence for suicide attempt survivors",
    "resource_id": 4966,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 4.75
  },
  {
    "person_key": 166849,
    "user_name": "User166849",
    "first_name": "User",
    "last_name": 166849,
    "doc_id": 12345,
    "learning_object": "Beyonce",
    "resource_id": 6995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Woodfield Cottage",
    "location_id": 1697377,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 0.92
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 9.08
  },
  {
    "person_key": 154572,
    "user_name": "User154572",
    "first_name": "User",
    "last_name": 154572,
    "doc_id": 12345,
    "learning_object": "01. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5060,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 2
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "06. Amy Webb: How I hacked online dating",
    "resource_id": 5065,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-23",
    "time_spent_min": 30
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L13 Slope (CS)",
    "resource_id": 10348,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 228
  },
  {
    "person_key": 171203,
    "user_name": "User171203",
    "first_name": "User",
    "last_name": 171203,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 38.5
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "07. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5146,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L2: Interpret the Constitution",
    "resource_id": 10434,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 14
  },
  {
    "person_key": 171198,
    "user_name": "User171198",
    "first_name": "User",
    "last_name": 171198,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 35.92
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 15.75
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 6.5
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "02. Beau Lotto: Optical illusions show how we see",
    "resource_id": 5204,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171207,
    "user_name": "User171207",
    "first_name": "User",
    "last_name": 171207,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 51.17
  },
  {
    "person_key": 156293,
    "user_name": "User156293",
    "first_name": "User",
    "last_name": 156293,
    "doc_id": 12345,
    "learning_object": "Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 7813,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.58
  },
  {
    "person_key": 160163,
    "user_name": "User160163",
    "first_name": "User",
    "last_name": 160163,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 15.42
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Workbook - L1: Interpret Diagrams",
    "resource_id": 10474,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 546
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 13.75
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L5 Composite Plane Figures (CS)",
    "resource_id": 10357,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 205
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Negin Farsad: A highly scientific taxonomy of haters",
    "resource_id": 7569,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 30
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 17.83
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 6.92
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.330002
  },
  {
    "person_key": 171193,
    "user_name": "User171193",
    "first_name": "User",
    "last_name": 171193,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 41.17
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 40
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L3 Polygons",
    "resource_id": 10355,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 302
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L14 Using Slope to Solve Geometric Problems",
    "resource_id": 10349,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 270
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "Using Punctuation Marks",
    "resource_id": 8295,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 29.92
  },
  {
    "person_key": 171212,
    "user_name": "User171212",
    "first_name": "User",
    "last_name": 171212,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 42.75
  },
  {
    "person_key": 171193,
    "user_name": "User171193",
    "first_name": "User",
    "last_name": 171193,
    "doc_id": 12345,
    "learning_object": "Part 2 - Conflict Resolution Exam Friday",
    "resource_id": 5642,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.08
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L12 Graphing Linear Equations",
    "resource_id": 10347,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2
  },
  {
    "person_key": 156293,
    "user_name": "User156293",
    "first_name": "User",
    "last_name": 156293,
    "doc_id": 12345,
    "learning_object": "Zak Ebrahim: I am the son of a terrorist. Here's how I chose peace.",
    "resource_id": 7814,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 36.75
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 20.67
  },
  {
    "person_key": 171205,
    "user_name": "User171205",
    "first_name": "User",
    "last_name": 171205,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Wednesday",
    "resource_id": 5732,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.08
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Rich Benjamin: My road trip through the whitest towns in America",
    "resource_id": 7562,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 12.58
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 4.33
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "Agreement #4",
    "resource_id": 8089,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Adam Savage: My love letter to cosplay",
    "resource_id": 7612,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.42
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 71.33
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "08. Johanna Blakley: Social media and the end of gender",
    "resource_id": 5010,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.59
  },
  {
    "person_key": 171197,
    "user_name": "User171197",
    "first_name": "User",
    "last_name": 171197,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 42.25
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L17 Comparison of Functions",
    "resource_id": 10352,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 273
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Brené Brown: Listening to shame",
    "resource_id": 5014,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L7 Prisms and Cylinders",
    "resource_id": 10359,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 158
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Networking",
    "resource_id": 8098,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 32.42
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 127.75
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 12.42
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 20.83
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 6.83
  },
  {
    "person_key": 156293,
    "user_name": "User156293",
    "first_name": "User",
    "last_name": 156293,
    "doc_id": 12345,
    "learning_object": "Using Parts of Speech",
    "resource_id": 8288,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 17.5
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L2 Pythagorean Theorem",
    "resource_id": 10354,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 189
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "24. Joshua Prager: In search of the man who broke my neck",
    "resource_id": 5164,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 18.5
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 13.33
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 9.75
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "09. Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 5249,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 59.83
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Kenneth Lacovara: Hunting for dinosaurs showed me our place in the universe",
    "resource_id": 7829,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 4.17
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "06. Damon Horowitz: Philosophy in prison",
    "resource_id": 5075,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.58
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.83
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.67
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "10. Freeman Hrabowski: 4 pillars of college success in science",
    "resource_id": 5101,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 5
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "05. J.J. Abrams: The mystery box",
    "resource_id": 5185,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 16.17
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.66
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 8.83
  },
  {
    "person_key": 171190,
    "user_name": "User171190",
    "first_name": "User",
    "last_name": 171190,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 16.58
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Safwat Saleem: Why I keep speaking up, even when people mock my accent",
    "resource_id": 7571,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.92
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.92
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Celeste Headlee: 10 ways to have a better conversation",
    "resource_id": 7770,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 8.67
  },
  {
    "person_key": 171192,
    "user_name": "User171192",
    "first_name": "User",
    "last_name": 171192,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Wednesday",
    "resource_id": 5732,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 30
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 8.5
  },
  {
    "person_key": 171194,
    "user_name": "User171194",
    "first_name": "User",
    "last_name": 171194,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 40.08
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 4.25
  },
  {
    "person_key": 154239,
    "user_name": "User154239",
    "first_name": "User",
    "last_name": 154239,
    "doc_id": 12345,
    "learning_object": "What happens when you sign a Music Contract",
    "resource_id": 8378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 12.92
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.58
  },
  {
    "person_key": 157598,
    "user_name": "User157598",
    "first_name": "User",
    "last_name": 157598,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171191,
    "user_name": "User171191",
    "first_name": "User",
    "last_name": 171191,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 26.58
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.41
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 44.75
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Tasos Frantzolas: Everything you hear on film is a lie",
    "resource_id": 7565,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 30
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Lucianne Walkowicz: Let's not use Mars as a backup planet",
    "resource_id": 7757,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.58
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.33
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.5
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.75
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.58
  },
  {
    "person_key": 160041,
    "user_name": "User160041",
    "first_name": "User",
    "last_name": 160041,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.08
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "17. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5157,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 12
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "23. Joseph Kim: The family I lost in North Korea. And the family I gained.",
    "resource_id": 5163,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 9.17
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "18. Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 5158,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.33
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "13. Richard Dawkins: Why the universe seems so strange",
    "resource_id": 5215,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 25
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "07. Anne Curzan: What makes a word \"real\"?",
    "resource_id": 5187,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.5
  },
  {
    "person_key": 171209,
    "user_name": "User171209",
    "first_name": "User",
    "last_name": 171209,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 23.08
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 30.92
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "05. Eleanor Longden: The voices in my head",
    "resource_id": 4960,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction to Physical Sciences",
    "resource_id": 7464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.58
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 105.75
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 21.75
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.5
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 26
  },
  {
    "person_key": 171202,
    "user_name": "User171202",
    "first_name": "User",
    "last_name": 171202,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 23
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.75
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1",
    "resource_id": 7992,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.42
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Cesar Kuriyama: One second every day",
    "resource_id": 7560,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 5.17
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "02. Elizabeth Gilbert: Your elusive creative genius",
    "resource_id": 5182,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Gabriela González: How LIGO discovered gravitational waves -- and what might be next",
    "resource_id": 7773,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.25
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 5.17
  },
  {
    "person_key": 171190,
    "user_name": "User171190",
    "first_name": "User",
    "last_name": 171190,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Wednesday",
    "resource_id": 5732,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.42
  },
  {
    "person_key": 167866,
    "user_name": "User167866",
    "first_name": "User",
    "last_name": 167866,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167866,
    "user_name": "User167866",
    "first_name": "User",
    "last_name": 167866,
    "doc_id": 12345,
    "learning_object": "Mundano: Trash cart superheroes",
    "resource_id": 7551,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.33
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "07. Rebecca MacKinnon: Let's take back the Internet!",
    "resource_id": 5171,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.67
  },
  {
    "person_key": 171196,
    "user_name": "User171196",
    "first_name": "User",
    "last_name": 171196,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 41.33
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 6
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3",
    "resource_id": 7989,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.67
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 2 - Developing Your Coaching Objectives",
    "resource_id": 6517,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.42
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "18. Dan Cobley: What physics taught me about marketing",
    "resource_id": 5220,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 33.08
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 7
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 30.08
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.67
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Ben Wellington: How we found the worst place to park in New York City -- using big data",
    "resource_id": 7686,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 5.17
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.25
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.5
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 6.83
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.17
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Negin Farsad: A highly scientific taxonomy of haters",
    "resource_id": 7569,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 13.75
  },
  {
    "person_key": 171204,
    "user_name": "User171204",
    "first_name": "User",
    "last_name": 171204,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 24.17
  },
  {
    "person_key": 171213,
    "user_name": "User171213",
    "first_name": "User",
    "last_name": 171213,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 18.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 16.67
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "09. Ben Goldacre: Battling bad science",
    "resource_id": 5211,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 17.83
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Sarah Kay: If I should have a daughter ...",
    "resource_id": 7550,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 28.75
  },
  {
    "person_key": 154830,
    "user_name": "User154830",
    "first_name": "User",
    "last_name": 154830,
    "doc_id": 12345,
    "learning_object": "Damon Horowitz: Philosophy in prison",
    "resource_id": 7656,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.75
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "13. Rajesh Rao: A Rosetta Stone for a lost language",
    "resource_id": 5253,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.42
  },
  {
    "person_key": 154596,
    "user_name": "User154596",
    "first_name": "User",
    "last_name": 154596,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 4.42
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 20.92
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 17.41
  },
  {
    "person_key": 164079,
    "user_name": "User164079",
    "first_name": "User",
    "last_name": 164079,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "01. Hannah Fry: The mathematics of love",
    "resource_id": 5091,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.33
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "08. Nalini Nadkarni: Life science in prison",
    "resource_id": 5077,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 4.83
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Adam Driver: My journey from Marine to actor",
    "resource_id": 7559,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 24.5
  },
  {
    "person_key": 167102,
    "user_name": "User167102",
    "first_name": "User",
    "last_name": 167102,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 34.92
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.59
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.16
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Sanford Biggers: An artist's unflinching look at racial violence",
    "resource_id": 7544,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.83
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 16.17
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.5
  },
  {
    "person_key": 168501,
    "user_name": "User168501",
    "first_name": "User",
    "last_name": 168501,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 10.08
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2",
    "resource_id": 7993,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.08
  },
  {
    "person_key": 154239,
    "user_name": "User154239",
    "first_name": "User",
    "last_name": 154239,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 17.5
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "04. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5143,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.5
  },
  {
    "person_key": 167866,
    "user_name": "User167866",
    "first_name": "User",
    "last_name": 167866,
    "doc_id": 12345,
    "learning_object": "Sarah Kay: If I should have a daughter ...",
    "resource_id": 7550,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 7.34
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.67
  },
  {
    "person_key": 171205,
    "user_name": "User171205",
    "first_name": "User",
    "last_name": 171205,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 48
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.92
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Frances Larson: Why public beheadings get millions of views",
    "resource_id": 7828,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 6.08
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 29.92
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 5
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction to Physical Sciences",
    "resource_id": 7464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 17.42
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 2: Base Units and the Scientific Method",
    "resource_id": 7493,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.75
  },
  {
    "person_key": 159055,
    "user_name": "User159055",
    "first_name": "User",
    "last_name": 159055,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.33
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Chris Anderson: TED's secret to great public speaking",
    "resource_id": 7767,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 5.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 9
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3",
    "resource_id": 7997,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.83
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 10.09
  },
  {
    "person_key": 171212,
    "user_name": "User171212",
    "first_name": "User",
    "last_name": 171212,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Wednesday",
    "resource_id": 5732,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 7.67
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 21.16
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 82.84
  },
  {
    "person_key": 171194,
    "user_name": "User171194",
    "first_name": "User",
    "last_name": 171194,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Wednesday",
    "resource_id": 5732,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 44.75
  },
  {
    "person_key": 154596,
    "user_name": "User154596",
    "first_name": "User",
    "last_name": 154596,
    "doc_id": 12345,
    "learning_object": "Kevin Breel: Confessions of a depressed comic",
    "resource_id": 7534,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.67
  },
  {
    "person_key": 171201,
    "user_name": "User171201",
    "first_name": "User",
    "last_name": 171201,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 42.08
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1",
    "resource_id": 7995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.75
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 7.59
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 13.34
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 9.33
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.58
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.25
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 31.75
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.83
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 36.5
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "01. Dan Gilbert: Why we make bad decisions",
    "resource_id": 5052,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.5
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 9.66
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.67
  },
  {
    "person_key": 171201,
    "user_name": "User171201",
    "first_name": "User",
    "last_name": 171201,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Wednesday",
    "resource_id": 5732,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Arthur Benjamin: The magic of Fibonacci numbers",
    "resource_id": 7689,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 7.75
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 41.83
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 29.92
  },
  {
    "person_key": 171200,
    "user_name": "User171200",
    "first_name": "User",
    "last_name": 171200,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 76
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 5
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 13.83
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "02. Dan Ariely: Are we in control of our own decisions?",
    "resource_id": 5053,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.08
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 24.25
  },
  {
    "person_key": 167953,
    "user_name": "User167953",
    "first_name": "User",
    "last_name": 167953,
    "doc_id": 12345,
    "learning_object": "Polishing Your Skills for Excellent Customer Service",
    "resource_id": 8236,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "05. Eleanor Longden: The voices in my head",
    "resource_id": 4960,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 4.83
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "08. Stephen Hawking: Questioning the universe",
    "resource_id": 5210,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "04. Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 5206,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.5
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.08
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 10.75
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2",
    "resource_id": 7999,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.17
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Jedidah Isler: How I fell in love with quasars, blazars and our incredible universe",
    "resource_id": 7750,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 13.92
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.08
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.58
  },
  {
    "person_key": 171211,
    "user_name": "User171211",
    "first_name": "User",
    "last_name": 171211,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 15.33
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "MGMT 3377 - Entrepreneurship",
    "resource_id": 4302,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.75
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1",
    "resource_id": 7998,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 8
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 8.92
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1
  },
  {
    "person_key": 163891,
    "user_name": "User163891",
    "first_name": "User",
    "last_name": 163891,
    "doc_id": 12345,
    "learning_object": "Unit 1 Marketing",
    "resource_id": 7444,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 6.16
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "03. Daniel Reisel: The neuroscience of restorative justice",
    "resource_id": 5072,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 6.67
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Quiz - Unit 1 Review",
    "resource_id": 10147,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 4820
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "09. Tony Porter: A call to men",
    "resource_id": 5148,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 11.67
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "09. Kiran Bedi: A police chief with a difference",
    "resource_id": 5078,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 8.5
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.75
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 29
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3",
    "resource_id": 7994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 2.75
  },
  {
    "person_key": 165478,
    "user_name": "User165478",
    "first_name": "User",
    "last_name": 165478,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 26.42
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: The doubt essential to faith",
    "resource_id": 7809,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 13.25
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Sarah Jones: One woman, five characters, and a sex lesson from the future",
    "resource_id": 7599,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 30.08
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Crime in America Week 1 Quiz",
    "resource_id": 8001,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "06. Manal al-Sharif: A Saudi woman who dared to drive",
    "resource_id": 5019,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 25.08
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 23.33
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 29.92
  },
  {
    "person_key": 171195,
    "user_name": "User171195",
    "first_name": "User",
    "last_name": 171195,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 15.75
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Clint Smith: The danger of silence",
    "resource_id": 7555,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 8.67
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "10. Toby Eccles: Invest in social change",
    "resource_id": 5079,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.5
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 36.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 11.58
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 12.92
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 4.42
  },
  {
    "person_key": 171199,
    "user_name": "User171199",
    "first_name": "User",
    "last_name": 171199,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 74.5
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "05. Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 5144,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 9.83
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.589996
  },
  {
    "person_key": 154324,
    "user_name": "User154324",
    "first_name": "User",
    "last_name": 154324,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Louisiana Claiborne Parish Detention Center",
    "location_id": 1697375,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171210,
    "user_name": "User171210",
    "first_name": "User",
    "last_name": 171210,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 39
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "06. Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 5145,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 10.42
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 19.83
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Christopher Bell: Bring on the female superheroes!",
    "resource_id": 7600,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 15.16
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "06. Parul Sehgal: An ode to envy",
    "resource_id": 5186,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Mundano: Trash cart superheroes",
    "resource_id": 7551,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 12.08
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "14. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5154,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 15.75
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Workbook - L2: Interpret the Constitution (CS)",
    "resource_id": 10435,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 691
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 121.92
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.25
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 7813,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 5.42
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Jim Holt: Why does the universe exist?",
    "resource_id": 7651,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.67
  },
  {
    "person_key": 154239,
    "user_name": "User154239",
    "first_name": "User",
    "last_name": 154239,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 36.17
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 6.17
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "16. David Gallo: Underwater astonishments",
    "resource_id": 5156,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "03. Arthur Benjamin: The magic of Fibonacci numbers",
    "resource_id": 5093,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 6.33
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.34
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Euna Lee: What I learned as a prisoner in North Korea",
    "resource_id": 7667,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171206,
    "user_name": "User171206",
    "first_name": "User",
    "last_name": 171206,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 41.08
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L2: Interpret the Constitution - Quiz",
    "resource_id": 10471,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 419
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "Living Wicca: A Guide for the Solitary",
    "resource_id": 6116,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week 5 Unit Five: Beowulf",
    "resource_id": 6511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 30.33
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "Exodus",
    "resource_id": 6806,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 36.75
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "Literacy Math Formative Assessment",
    "resource_id": 9445,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 553
  },
  {
    "person_key": 171196,
    "user_name": "User171196",
    "first_name": "User",
    "last_name": 171196,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Wednesday",
    "resource_id": 5732,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 11.17
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 5.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.5
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2",
    "resource_id": 7988,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.83
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 6.83
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 132.83
  },
  {
    "person_key": 158710,
    "user_name": "User158710",
    "first_name": "User",
    "last_name": 158710,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 132.5
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "OK Go: How to find a wonderful idea",
    "resource_id": 7561,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 36.75
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.92
  },
  {
    "person_key": 171208,
    "user_name": "User171208",
    "first_name": "User",
    "last_name": 171208,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 44.08
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.5
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 7.83
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L4 Circles",
    "resource_id": 10356,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 190
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L6 Scale Drawings (CS)",
    "resource_id": 10358,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 172
  },
  {
    "person_key": 167091,
    "user_name": "User167091",
    "first_name": "User",
    "last_name": 167091,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "9A",
    "location_id": 1697459,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 9
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 9.83
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 13
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "Lesson 1",
    "resource_id": 9762,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 32
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "11. Amy Tan: Where does creativity hide?",
    "resource_id": 5191,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 17.92
  },
  {
    "person_key": 171199,
    "user_name": "User171199",
    "first_name": "User",
    "last_name": 171199,
    "doc_id": 12345,
    "learning_object": "Part 1 - Use of Force Exam Wednesday",
    "resource_id": 5732,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.25
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L16 Evaluation of Functions",
    "resource_id": 10351,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 266
  },
  {
    "person_key": 171192,
    "user_name": "User171192",
    "first_name": "User",
    "last_name": 171192,
    "doc_id": 12345,
    "learning_object": "Part 2 - Use of Force Exam Wednesday",
    "resource_id": 5658,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 16.75
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169567,
    "user_name": "User169567",
    "first_name": "User",
    "last_name": 169567,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 21.75
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 77.58
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 46.75
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Second Chance Pell Program Instructions",
    "resource_id": 7671,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "05. David R. Dow: Lessons from death row inmates",
    "resource_id": 5074,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 10.5
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "eL Seed: Street art with a message of hope and peace",
    "resource_id": 7563,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Anand Giridharadas: A tale of two Americas. And the mini-mart where they collided",
    "resource_id": 7822,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Adam Driver: My journey from Marine to actor",
    "resource_id": 7559,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 12.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 30
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "eL Seed: Street art with a message of hope and peace",
    "resource_id": 7563,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 12
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3",
    "resource_id": 7989,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 1.25
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 1 Technology in Business",
    "resource_id": 7460,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "David Casarett: A doctor's case for medical marijuana",
    "resource_id": 7626,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 18.58
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: PC Components",
    "resource_id": 8228,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 46.17
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 5
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 3.08
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "TED Talks: Secrets of Successful Writers",
    "resource_id": 4459,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 0.17
  },
  {
    "person_key": 160163,
    "user_name": "User160163",
    "first_name": "User",
    "last_name": 160163,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 11.75
  },
  {
    "person_key": 167357,
    "user_name": "User167357",
    "first_name": "User",
    "last_name": 167357,
    "doc_id": 12345,
    "learning_object": "10. Joshua Walters: On being just crazy enough",
    "resource_id": 4965,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility - Shakopee",
    "location_id": 1697427,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155856,
    "user_name": "User155856",
    "first_name": "User",
    "last_name": 155856,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 8.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 30.58
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Tasos Frantzolas: Everything you hear on film is a lie",
    "resource_id": 7565,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 30
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "OK Go: How to find a wonderful idea",
    "resource_id": 7561,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 18.92
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L1 Triangles and Quadrilaterals",
    "resource_id": 10353,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 221
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L15 Graphing Quadratic Equations",
    "resource_id": 10350,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 714
  },
  {
    "person_key": 164288,
    "user_name": "User164288",
    "first_name": "User",
    "last_name": 164288,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-22",
    "time_spent_min": 30.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.08
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 4
  },
  {
    "person_key": 159168,
    "user_name": "User159168",
    "first_name": "User",
    "last_name": 159168,
    "doc_id": 12345,
    "learning_object": "Bob Marley Book",
    "resource_id": 6137,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3
  },
  {
    "person_key": 167681,
    "user_name": "User167681",
    "first_name": "User",
    "last_name": 167681,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "09. Onora O'Neill: What we don't understand about trust",
    "resource_id": 5087,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 9.83
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 413,
    "user_name": "User413",
    "first_name": "User",
    "last_name": 413,
    "doc_id": 12345,
    "learning_object": "JORDAN RIDES THE BUS",
    "resource_id": 8496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "9B",
    "location_id": 1697460,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.83
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "ACCT 2322 - Principles of Accounting II",
    "resource_id": 4434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 29.91
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 16.75
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "My Name",
    "resource_id": 8368,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 30.75
  },
  {
    "person_key": 154435,
    "user_name": "User154435",
    "first_name": "User",
    "last_name": 154435,
    "doc_id": 12345,
    "learning_object": "13. Rajesh Rao: A Rosetta Stone for a lost language",
    "resource_id": 5253,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 17.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "09. Cesar Kuriyama: One second every day",
    "resource_id": 4974,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 8.17
  },
  {
    "person_key": 167681,
    "user_name": "User167681",
    "first_name": "User",
    "last_name": 167681,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.67
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 30.17
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3",
    "resource_id": 7989,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.42
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 6.83
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 5.41
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.09
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 6.66
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Brené Brown: Listening to shame",
    "resource_id": 5014,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 5.33
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 60.08
  },
  {
    "person_key": 164220,
    "user_name": "User164220",
    "first_name": "User",
    "last_name": 164220,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 56.58
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 52.92
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.41
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.33
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.58
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156647,
    "user_name": "User156647",
    "first_name": "User",
    "last_name": 156647,
    "doc_id": 12345,
    "learning_object": "Protestant Bible KJV",
    "resource_id": 6148,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.5
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.17
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 71.25
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 42
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 18.42
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.92
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 30.5
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 53.91
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 4- Law Enforcement in Action",
    "resource_id": 7438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.580002
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Eight:  Chapter 27: Special Occasion Speeches",
    "resource_id": 6837,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.83
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 10.67
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "11. John Hunter: Teaching with the World Peace Game",
    "resource_id": 5050,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 19.08
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.17
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2",
    "resource_id": 7988,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 12
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3",
    "resource_id": 7989,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 9.33
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 27.58
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1",
    "resource_id": 7995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.5
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 12.92
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 13.25
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 16.58
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 9
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 19.25
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1",
    "resource_id": 7998,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.17
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1",
    "resource_id": 7992,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 11.58
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.33
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 7.59
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3",
    "resource_id": 7997,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.58
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "10. Clint Smith: The danger of silence",
    "resource_id": 4975,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 43.25
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 6639,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.67
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "08. Kevin Breel: Confessions of a depressed comic",
    "resource_id": 4963,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 6.5
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "01. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5221,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 5.75
  },
  {
    "person_key": 154435,
    "user_name": "User154435",
    "first_name": "User",
    "last_name": 154435,
    "doc_id": 12345,
    "learning_object": "08. Kevin Breel: Confessions of a depressed comic",
    "resource_id": 4963,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 11.25
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week Two: Unit Two Greek Mythology",
    "resource_id": 6505,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 20.58
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.08
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 6.25
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "05. David R. Dow: Lessons from death row inmates",
    "resource_id": 5074,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 8.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Part Two: Writing Your Informative Speech",
    "resource_id": 6834,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "02. Robert Gupta: Music is medicine, music is sanity",
    "resource_id": 4949,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.83
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 9.33
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 4.92
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "09. Onora O'Neill: What we don't understand about trust",
    "resource_id": 5087,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167866,
    "user_name": "User167866",
    "first_name": "User",
    "last_name": 167866,
    "doc_id": 12345,
    "learning_object": "Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 7549,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 15.92
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.75
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.5
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Chapter 1 - Don't Sweat the Small Stuff",
    "resource_id": 7983,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 4.42
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "11. JD Schramm: Break the silence for suicide attempt survivors",
    "resource_id": 4966,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 33.5
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3.75
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 81.83
  },
  {
    "person_key": 168501,
    "user_name": "User168501",
    "first_name": "User",
    "last_name": 168501,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 17.17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2",
    "resource_id": 7993,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.67
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 7.42
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 22.08
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 3 - Victory with Honor",
    "resource_id": 6518,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.42
  },
  {
    "person_key": 158001,
    "user_name": "User158001",
    "first_name": "User",
    "last_name": 158001,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 15
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 43
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3.17
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162659,
    "user_name": "User162659",
    "first_name": "User",
    "last_name": 162659,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 32.08
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 6638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2",
    "resource_id": 7999,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.58
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1",
    "resource_id": 7992,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 4.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 7.08
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Mobile Devices",
    "resource_id": 8248,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 30
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Mac Barnett: Why a good book is a secret door",
    "resource_id": 7598,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.08
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 30
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Workbook - L2: Interpret Tables",
    "resource_id": 10453,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 320
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L3: Main Idea and Details - Quiz",
    "resource_id": 10457,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 322
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Workbook - L1: Relate Geography and History",
    "resource_id": 10437,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 19006
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.42
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Workbook - L4: Categorize",
    "resource_id": 10460,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 25
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Laptops",
    "resource_id": 8247,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.42
  },
  {
    "person_key": 168305,
    "user_name": "User168305",
    "first_name": "User",
    "last_name": 168305,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "10A",
    "location_id": 1697461,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Workbook - L3: Main Idea and Details (CS)",
    "resource_id": 10456,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 13
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Marcus Garvey and Rasta Movement",
    "resource_id": 6144,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3.67
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 30.25
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "08. Johanna Blakley: Social media and the end of gender",
    "resource_id": 5010,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.75
  },
  {
    "person_key": 169852,
    "user_name": "User169852",
    "first_name": "User",
    "last_name": 169852,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 10.83
  },
  {
    "person_key": 160163,
    "user_name": "User160163",
    "first_name": "User",
    "last_name": 160163,
    "doc_id": 12345,
    "learning_object": "Second Chance Pell Program Instructions",
    "resource_id": 7671,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.83
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "14. Rose George: Let's talk crap. Seriously.",
    "resource_id": 4991,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 23.75
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 47.5
  },
  {
    "person_key": 154435,
    "user_name": "User154435",
    "first_name": "User",
    "last_name": 154435,
    "doc_id": 12345,
    "learning_object": "06. Andrew Solomon: Depression, the secret we share",
    "resource_id": 4961,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 15.17
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L4: Categorize (CS)",
    "resource_id": 10458,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 10
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164017,
    "user_name": "User164017",
    "first_name": "User",
    "last_name": 164017,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 61.92
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 10.92
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Brian Greene: Is our universe the only universe?",
    "resource_id": 7751,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.67
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 34
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.75
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "Prayers to the Orishas",
    "resource_id": 6135,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 30.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 29.34
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 32.67
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.83
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - U2 L1: Relate Geography and History - Quiz",
    "resource_id": 10452,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 558
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3.25
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Latif Nasser: You have no idea where camels really come from",
    "resource_id": 7614,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 18.42
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 14.75
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3.67
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 34.58
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 17.5
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: The doubt essential to faith",
    "resource_id": 7809,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 16.75
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L3: Main Idea and Details",
    "resource_id": 10455,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 15
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 40.5
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Monitors and Output Devices",
    "resource_id": 8229,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 30
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 53.75
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu Hyms of the Samveda",
    "resource_id": 6166,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 5
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 51.16
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3",
    "resource_id": 7994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.42
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 7806,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 10.5
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L2: Interpret Tables",
    "resource_id": 10438,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 85
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "08. Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 4985,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 29.92
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3",
    "resource_id": 7997,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.08
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 16.42
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L4: Categorize (CS) - Quiz",
    "resource_id": 10459,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 43
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154435,
    "user_name": "User154435",
    "first_name": "User",
    "last_name": 154435,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.5
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.17
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 6.58
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Sequence (CS)",
    "resource_id": 10164,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 11
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.33
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3.67
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: On reading the Koran",
    "resource_id": 7810,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 14.33
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.75
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "Second Chance Pell Program Instructions",
    "resource_id": 7671,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L2: Interpret Tables - Quiz",
    "resource_id": 10454,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 219
  },
  {
    "person_key": 466,
    "user_name": "User466",
    "first_name": "User",
    "last_name": 466,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 90.5
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 12.58
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Sequence (CS) - Quiz",
    "resource_id": 10461,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 50
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Six:  Outlining Your Persuasive Speech: Chapters 24-26",
    "resource_id": 6835,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.58
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "Interview Videos",
    "resource_id": 8417,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "10. Rick Smolan: The story of a girl",
    "resource_id": 5069,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 5.41
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.84
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.17
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 8.58
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "James Green: 3 moons and a planet that could have alien life",
    "resource_id": 7754,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 30
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 6.5
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 16.33
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "HIST 1302",
    "resource_id": 4661,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "David Christian: The history of our world in 18 minutes",
    "resource_id": 7788,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 22.17
  },
  {
    "person_key": 157753,
    "user_name": "User157753",
    "first_name": "User",
    "last_name": 157753,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Seven: The Persuasive Speech - Writing Your Final Manuscript",
    "resource_id": 6836,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.83
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.34
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.33
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 15.83
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 5.58
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "Prayers to the Orishas",
    "resource_id": 6135,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.42
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 13.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 10.42
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 6. Acción",
    "resource_id": 8160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3.33
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 11
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 14.83
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction to Physical Sciences",
    "resource_id": 7464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.67
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.08
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169852,
    "user_name": "User169852",
    "first_name": "User",
    "last_name": 169852,
    "doc_id": 12345,
    "learning_object": "Marcus Garvey and Rasta Movement",
    "resource_id": 6144,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.92
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8091,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 44.75
  },
  {
    "person_key": 154939,
    "user_name": "User154939",
    "first_name": "User",
    "last_name": 154939,
    "doc_id": 12345,
    "learning_object": "01. Kelly McGonigal: How to make stress your friend",
    "resource_id": 4993,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3
  },
  {
    "person_key": 154435,
    "user_name": "User154435",
    "first_name": "User",
    "last_name": 154435,
    "doc_id": 12345,
    "learning_object": "11. Anupam Mishra: The ancient ingenuity of water harvesting",
    "resource_id": 5251,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 17.33
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 8.67
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.34
  },
  {
    "person_key": 165796,
    "user_name": "User165796",
    "first_name": "User",
    "last_name": 165796,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 42.08
  },
  {
    "person_key": 168073,
    "user_name": "User168073",
    "first_name": "User",
    "last_name": 168073,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3A",
    "location_id": 1697447,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 29.58
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1",
    "resource_id": 7995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.67
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 5.83
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.75
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 16.08
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 32
  },
  {
    "person_key": 169852,
    "user_name": "User169852",
    "first_name": "User",
    "last_name": 169852,
    "doc_id": 12345,
    "learning_object": "02. Robert Gupta: Music is medicine, music is sanity",
    "resource_id": 4949,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3.92
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.08
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 18.17
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2",
    "resource_id": 7999,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 3.33
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 8.25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.75
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 5.67
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 21.25
  },
  {
    "person_key": 413,
    "user_name": "User413",
    "first_name": "User",
    "last_name": 413,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "9B",
    "location_id": 1697460,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 160404,
    "user_name": "User160404",
    "first_name": "User",
    "last_name": 160404,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8B",
    "location_id": 1697458,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.75
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 4 - Coaching Philosophy",
    "resource_id": 6987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Islam The Hadith",
    "resource_id": 6170,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.92
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Workbook - L2 Operations",
    "resource_id": 9891,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 322
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Student Book - U1 L1 Whole Numbers (CS) - Quiz",
    "resource_id": 9864,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 153
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Workbook - L1 Whole Numbers (CS)",
    "resource_id": 9860,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 4680
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Workbook - L4 Fractions",
    "resource_id": 9916,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2387
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "02. Rita Pierson: Every kid needs a champion",
    "resource_id": 5041,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 8
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 7
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 454,
    "user_name": "User454",
    "first_name": "User",
    "last_name": 454,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 8.5
  },
  {
    "person_key": 159218,
    "user_name": "User159218",
    "first_name": "User",
    "last_name": 159218,
    "doc_id": 12345,
    "learning_object": "Unit 1b: INTRODUCTION, THE BUILDING BLOCKS OF LANGUAGE, AND THE WRITING PROCESS-\"Shame\" and Rough Draft of Narrative Essay",
    "resource_id": 6414,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 5.33
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.75
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 89.75
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3",
    "resource_id": 7994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.67
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1",
    "resource_id": 7998,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.92
  },
  {
    "person_key": 159198,
    "user_name": "User159198",
    "first_name": "User",
    "last_name": 159198,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 71
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "01. Eric Sanderson: New York -- before the City",
    "resource_id": 5241,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 59.83
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 16.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "09. Sarah Lewis: Embrace the near win",
    "resource_id": 5022,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 8.83
  },
  {
    "person_key": 154435,
    "user_name": "User154435",
    "first_name": "User",
    "last_name": 154435,
    "doc_id": 12345,
    "learning_object": "14. Suzanne Talhouk: Don't kill your language",
    "resource_id": 5254,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 14.33
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.5
  },
  {
    "person_key": 157264,
    "user_name": "User157264",
    "first_name": "User",
    "last_name": 157264,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.58
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.41
  },
  {
    "person_key": 413,
    "user_name": "User413",
    "first_name": "User",
    "last_name": 413,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8449,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "9B",
    "location_id": 1697460,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 15.17
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 12.5
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 15.92
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2",
    "resource_id": 7988,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 16.08
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu Artharva Veda",
    "resource_id": 6167,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 5
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 48.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Five:  Considerations for the Informative Speech: Chapters 14-16",
    "resource_id": 6833,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.33
  },
  {
    "person_key": 166840,
    "user_name": "User166840",
    "first_name": "User",
    "last_name": 166840,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 2.42
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "08. Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 4985,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 33.58
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 1.17
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 0.92
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 5. La familia",
    "resource_id": 8159,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 10.58
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Bhagavada Gita",
    "resource_id": 6164,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 5
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2",
    "resource_id": 7993,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 9.67
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-21",
    "time_spent_min": 33.08
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "05. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 4997,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.33
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Rig Vega",
    "resource_id": 6163,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 4.33
  },
  {
    "person_key": 169983,
    "user_name": "User169983",
    "first_name": "User",
    "last_name": 169983,
    "doc_id": 12345,
    "learning_object": "Lesson 1",
    "resource_id": 9810,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 604
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 6
  },
  {
    "person_key": 169317,
    "user_name": "User169317",
    "first_name": "User",
    "last_name": 169317,
    "doc_id": 12345,
    "learning_object": "03. Daniel Reisel: The neuroscience of restorative justice",
    "resource_id": 5072,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 14.25
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 4.42
  },
  {
    "person_key": 159410,
    "user_name": "User159410",
    "first_name": "User",
    "last_name": 159410,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7.92
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "10. Joshua Walters: On being just crazy enough",
    "resource_id": 4965,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 3.5
  },
  {
    "person_key": 157,
    "user_name": "User157",
    "first_name": "User",
    "last_name": 157,
    "doc_id": 12345,
    "learning_object": "10. Rick Smolan: The story of a girl",
    "resource_id": 5069,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7.17
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "05. Marcus du Sautoy: Symmetry, reality's riddle",
    "resource_id": 5095,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 19.17
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.17
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 19.58
  },
  {
    "person_key": 158665,
    "user_name": "User158665",
    "first_name": "User",
    "last_name": 158665,
    "doc_id": 12345,
    "learning_object": "07. Oliver Sacks: What hallucination reveals about our minds",
    "resource_id": 4962,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "Eleanor Longden: The voices in my head",
    "resource_id": 7535,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.42
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169983,
    "user_name": "User169983",
    "first_name": "User",
    "last_name": 169983,
    "doc_id": 12345,
    "learning_object": "Lesson 1 Quiz",
    "resource_id": 9811,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 41
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 54.67
  },
  {
    "person_key": 169954,
    "user_name": "User169954",
    "first_name": "User",
    "last_name": 169954,
    "doc_id": 12345,
    "learning_object": "Lesson 1",
    "resource_id": 9813,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2
  },
  {
    "person_key": 169983,
    "user_name": "User169983",
    "first_name": "User",
    "last_name": 169983,
    "doc_id": 12345,
    "learning_object": "Literacy Reading Formative Assessment",
    "resource_id": 9745,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 85
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "Prayers to the Orishas",
    "resource_id": 6135,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.58
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "01. Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 5025,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7.75
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2",
    "resource_id": 7993,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.33
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7.58
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.58
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "07. Hannah Brencher: Love letters to strangers",
    "resource_id": 5066,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5.17
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "03. Rick Warren: A life of purpose",
    "resource_id": 5235,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.33
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "01. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5221,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 23.25
  },
  {
    "person_key": 167035,
    "user_name": "User167035",
    "first_name": "User",
    "last_name": 167035,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.67
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5.92
  },
  {
    "person_key": 169452,
    "user_name": "User169452",
    "first_name": "User",
    "last_name": 169452,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.75
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.08
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "10. Rick Smolan: The story of a girl",
    "resource_id": 5069,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 25.5
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Beyonce",
    "resource_id": 6995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29.92
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1",
    "resource_id": 7992,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 46.58
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7.58
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "09. Tania Luna: How a penny made me feel like a millionaire",
    "resource_id": 5112,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5.83
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "01. Amy Cuddy: Your body language shapes who you are",
    "resource_id": 5126,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Chris Brown",
    "resource_id": 6996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 23.5
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "07. Julian Treasure: How to speak so that people want to listen",
    "resource_id": 5132,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 10.17
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 5: Budgeting",
    "resource_id": 7959,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 38.08
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.92
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "I AM NOT YOUR NEGRO",
    "resource_id": 7336,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.58
  },
  {
    "person_key": 165441,
    "user_name": "User165441",
    "first_name": "User",
    "last_name": 165441,
    "doc_id": 12345,
    "learning_object": "01. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5060,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.17
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1",
    "resource_id": 7995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 26.5
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Fighter",
    "resource_id": 6885,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.83
  },
  {
    "person_key": 156397,
    "user_name": "User156397",
    "first_name": "User",
    "last_name": 156397,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8091,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.25
  },
  {
    "person_key": 159410,
    "user_name": "User159410",
    "first_name": "User",
    "last_name": 159410,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.08
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Workbook - L5: Movement on Maps",
    "resource_id": 10449,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 133
  },
  {
    "person_key": 159410,
    "user_name": "User159410",
    "first_name": "User",
    "last_name": 159410,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.58
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "06. Cameron Herold: Let's raise kids to be entrepreneurs",
    "resource_id": 5197,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 7806,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29.92
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.83
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "04. Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 5206,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 34.25
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Sarah Jones: One woman, five characters, and a sex lesson from the future",
    "resource_id": 7599,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 27.75
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "TED Talks 2019: Breaking Through Bias",
    "resource_id": 4433,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169983,
    "user_name": "User169983",
    "first_name": "User",
    "last_name": 169983,
    "doc_id": 12345,
    "learning_object": "Review Part One",
    "resource_id": 9736,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 11
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "16. Bruno Torturra: Got a smartphone? Start broadcasting",
    "resource_id": 5180,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 16.92
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Movement on Maps (CS) - Quiz",
    "resource_id": 10448,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 4588
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 12
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169983,
    "user_name": "User169983",
    "first_name": "User",
    "last_name": 169983,
    "doc_id": 12345,
    "learning_object": "Lesson 26 Quiz",
    "resource_id": 9812,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 89
  },
  {
    "person_key": 156397,
    "user_name": "User156397",
    "first_name": "User",
    "last_name": 156397,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.67
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29.92
  },
  {
    "person_key": 167035,
    "user_name": "User167035",
    "first_name": "User",
    "last_name": 167035,
    "doc_id": 12345,
    "learning_object": "Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 7739,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "02. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5026,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.42
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Johanna Blakley: Social media and the end of gender",
    "resource_id": 7589,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156397,
    "user_name": "User156397",
    "first_name": "User",
    "last_name": 156397,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Chapter 1 - Don't Sweat the Small Stuff",
    "resource_id": 7983,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.42
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "04. Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 5006,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.67
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Quiz - Unit 2 Review",
    "resource_id": 10463,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7092
  },
  {
    "person_key": 158665,
    "user_name": "User158665",
    "first_name": "User",
    "last_name": 158665,
    "doc_id": 12345,
    "learning_object": "Ingrid Betancourt: What six years in captivity taught me about fear and faith",
    "resource_id": 7804,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 9.83
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 36.67
  },
  {
    "person_key": 169448,
    "user_name": "User169448",
    "first_name": "User",
    "last_name": 169448,
    "doc_id": 12345,
    "learning_object": "Lesson Three: The Informative Speech",
    "resource_id": 6595,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 30.58
  },
  {
    "person_key": 158665,
    "user_name": "User158665",
    "first_name": "User",
    "last_name": 158665,
    "doc_id": 12345,
    "learning_object": "02. Robert Gupta: Music is medicine, music is sanity",
    "resource_id": 4949,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 8.33
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week Three Unit 3 Greek Mythology",
    "resource_id": 6506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3",
    "resource_id": 7997,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.75
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Workbook - L1: Interpret Diagrams",
    "resource_id": 10474,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 6886
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "06. Amy Webb: How I hacked online dating",
    "resource_id": 5065,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 20.67
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1",
    "resource_id": 7998,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.17
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Jim Holt: Why does the universe exist?",
    "resource_id": 7651,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 23.25
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Lil Wayne",
    "resource_id": 7004,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29.92
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "03. Lisa Bu: How books can open your mind",
    "resource_id": 5183,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.42
  },
  {
    "person_key": 169317,
    "user_name": "User169317",
    "first_name": "User",
    "last_name": 169317,
    "doc_id": 12345,
    "learning_object": "02. Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 5071,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 6.75
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 18.92
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3",
    "resource_id": 7989,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7.92
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "11. Ben Saunders: To the South Pole and back — the hardest 105 days of my life",
    "resource_id": 5124,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.5
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "09. Yoruba Richen: What the gay rights movement learned from the civil rights movement",
    "resource_id": 5034,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.67
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 3.58
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "11. Carol Dweck: The power of believing that you can improve",
    "resource_id": 5024,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2",
    "resource_id": 7988,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 17.42
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7.17
  },
  {
    "person_key": 159410,
    "user_name": "User159410",
    "first_name": "User",
    "last_name": 159410,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 3.08
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 32.17
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.17
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "02. Beau Lotto: Optical illusions show how we see",
    "resource_id": 5204,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 17.33
  },
  {
    "person_key": 158665,
    "user_name": "User158665",
    "first_name": "User",
    "last_name": 158665,
    "doc_id": 12345,
    "learning_object": "05. Eleanor Longden: The voices in my head",
    "resource_id": 4960,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.58
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.25
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 31.5
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 4.59
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.83
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Workbook - L7: Interpret Timelines (CS)",
    "resource_id": 10469,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 9502
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 22.33
  },
  {
    "person_key": 167102,
    "user_name": "User167102",
    "first_name": "User",
    "last_name": 167102,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 20.08
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 3.92
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "10. Paul Nicklen: Tales of ice-bound wonderlands",
    "resource_id": 5123,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 18.41
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - U3 L1: Interpret Diagrams - Quiz",
    "resource_id": 10439,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 575
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.17
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Paul Knoepfler: The ethical dilemma of designer babies",
    "resource_id": 7801,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.33
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "TED Talks: How We Love",
    "resource_id": 4629,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 158848,
    "user_name": "User158848",
    "first_name": "User",
    "last_name": 158848,
    "doc_id": 12345,
    "learning_object": "Buddhist Becoming your Own Therapist",
    "resource_id": 6157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 23.5
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3",
    "resource_id": 7994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.67
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "07. Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 5198,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5.67
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 15.34
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3",
    "resource_id": 7989,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.08
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 19.58
  },
  {
    "person_key": 167035,
    "user_name": "User167035",
    "first_name": "User",
    "last_name": 167035,
    "doc_id": 12345,
    "learning_object": "08. Johanna Blakley: Social media and the end of gender",
    "resource_id": 5010,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7.17
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.169998
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169317,
    "user_name": "User169317",
    "first_name": "User",
    "last_name": 169317,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.92
  },
  {
    "person_key": 155610,
    "user_name": "User155610",
    "first_name": "User",
    "last_name": 155610,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5.08
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29.67
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "Jane Eyre Audiobook",
    "resource_id": 5855,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 30
  },
  {
    "person_key": 167035,
    "user_name": "User167035",
    "first_name": "User",
    "last_name": 167035,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.75
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 25
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.58
  },
  {
    "person_key": 167035,
    "user_name": "User167035",
    "first_name": "User",
    "last_name": 167035,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.42
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "06. Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 5030,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 32.09
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "03. Faith Jegede: What I've learned from my autistic brothers",
    "resource_id": 5062,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 9.42
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Rajiv Maheswaran: The math behind basketball's wildest moves",
    "resource_id": 7673,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 6.09
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 15.5
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 10.75
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7.58
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5.58
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - U3 L1: Interpret Diagrams",
    "resource_id": 10470,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 44
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Ingrid Betancourt: What six years in captivity taught me about fear and faith",
    "resource_id": 7804,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 4.58
  },
  {
    "person_key": 167035,
    "user_name": "User167035",
    "first_name": "User",
    "last_name": 167035,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.75
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.34
  },
  {
    "person_key": 165441,
    "user_name": "User165441",
    "first_name": "User",
    "last_name": 165441,
    "doc_id": 12345,
    "learning_object": "01. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5221,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 12.25
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Mobile Devices",
    "resource_id": 8248,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 21.25
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.17
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 5-To the Ends of the Earth",
    "resource_id": 7928,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 39.83
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156397,
    "user_name": "User156397",
    "first_name": "User",
    "last_name": 156397,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.08
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.17
  },
  {
    "person_key": 169983,
    "user_name": "User169983",
    "first_name": "User",
    "last_name": 169983,
    "doc_id": 12345,
    "learning_object": "Intermediate Math Formative Assessment",
    "resource_id": 9809,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 8
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 32.17
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Exam 1: Units 1 and 2",
    "resource_id": 7954,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Student Book - L7 Percent (CS)",
    "resource_id": 9893,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Student Book - L7 Percent (CS) - Quiz",
    "resource_id": 10119,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 135
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 16
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.75
  },
  {
    "person_key": 156397,
    "user_name": "User156397",
    "first_name": "User",
    "last_name": 156397,
    "doc_id": 12345,
    "learning_object": "Living Wicca: A Guide for the Solitary",
    "resource_id": 6116,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170389,
    "user_name": "User170389",
    "first_name": "User",
    "last_name": 170389,
    "doc_id": 12345,
    "learning_object": "Workbook - L5 Bar and Line Graphs",
    "resource_id": 10118,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 10839
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "SPCH 2300",
    "resource_id": 4722,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.42
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3",
    "resource_id": 7994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 30.75
  },
  {
    "person_key": 156397,
    "user_name": "User156397",
    "first_name": "User",
    "last_name": 156397,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 53
  },
  {
    "person_key": 159410,
    "user_name": "User159410",
    "first_name": "User",
    "last_name": 159410,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.08
  },
  {
    "person_key": 167035,
    "user_name": "User167035",
    "first_name": "User",
    "last_name": 167035,
    "doc_id": 12345,
    "learning_object": "14. Rose George: Let's talk crap. Seriously.",
    "resource_id": 4991,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.75
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2",
    "resource_id": 7993,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 31.92
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 4-No Prophet Can Die Outside of Jerusalem",
    "resource_id": 7927,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 10.67
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1",
    "resource_id": 7992,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 14.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "03. David Pogue: Cool tricks your phone can do",
    "resource_id": 5167,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 20.83
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "11. Aziz Abu Sarah: For more tolerance, we need more ... tourism?",
    "resource_id": 5036,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.25
  },
  {
    "person_key": 167676,
    "user_name": "User167676",
    "first_name": "User",
    "last_name": 167676,
    "doc_id": 12345,
    "learning_object": "Santeria Religion",
    "resource_id": 6030,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center ONE",
    "location_id": 1697349,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29.92
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 8 Unit 6: The Renaissance",
    "resource_id": 6510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.5
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "06. Neil MacGregor: 2600 years of history in one object",
    "resource_id": 5246,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.83
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 108.92
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 20.92
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Workbook - L4: Political Maps (CS)",
    "resource_id": 10446,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 47
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "11. Bunker Roy: Learning from a barefoot movement",
    "resource_id": 5202,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169452,
    "user_name": "User169452",
    "first_name": "User",
    "last_name": 169452,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Workbook - L5: Movement on Maps",
    "resource_id": 10449,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 6
  },
  {
    "person_key": 159291,
    "user_name": "User159291",
    "first_name": "User",
    "last_name": 159291,
    "doc_id": 12345,
    "learning_object": "Unit 3: Job Order Costing",
    "resource_id": 7957,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155627,
    "user_name": "User155627",
    "first_name": "User",
    "last_name": 155627,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.92
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Dan Cobley: What physics taught me about marketing",
    "resource_id": 7797,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Movement on Maps (CS)",
    "resource_id": 10447,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7
  },
  {
    "person_key": 156397,
    "user_name": "User156397",
    "first_name": "User",
    "last_name": 156397,
    "doc_id": 12345,
    "learning_object": "11. Norman Spack: How I help transgender teens become who they want to be",
    "resource_id": 5013,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 3
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.17
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "04. Pico Iyer: The art of stillness",
    "resource_id": 4996,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.75
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "10. Sally Kohn: Let’s try emotional correctness",
    "resource_id": 5035,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.67
  },
  {
    "person_key": 169448,
    "user_name": "User169448",
    "first_name": "User",
    "last_name": 169448,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.17
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "19. David Pogue: 10 top time-saving tech tips",
    "resource_id": 5159,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 4.67
  },
  {
    "person_key": 165441,
    "user_name": "User165441",
    "first_name": "User",
    "last_name": 165441,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Quiz - Unit 1 Review",
    "resource_id": 10450,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 656
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Kathryn Schulz: Don't regret regret",
    "resource_id": 7826,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 20.58
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 11.67
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Movement on Maps (CS) - Quiz",
    "resource_id": 10448,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 72
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "09. Kenneth Shinozuka: My simple invention, designed to keep my grandfather safe",
    "resource_id": 5200,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 6
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "My Name",
    "resource_id": 8368,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7.41
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.92
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L3: Physical Maps (CS) - Quiz",
    "resource_id": 10442,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 35
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "01. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5060,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 10.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 43.08
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "09. Alexis Ohanian: How to make a splash in social media",
    "resource_id": 5173,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.17
  },
  {
    "person_key": 169983,
    "user_name": "User169983",
    "first_name": "User",
    "last_name": 169983,
    "doc_id": 12345,
    "learning_object": "Literacy Math Formative Assessment",
    "resource_id": 9735,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 121
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 33.17
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Reference - Study Skills",
    "resource_id": 9882,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 10
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L4: Political Maps (CS) - Quiz",
    "resource_id": 10445,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 66
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 7834,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.08
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 3.58
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L4 Exponents and Scientific Notation (CS) - Quiz",
    "resource_id": 10291,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 792
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "03. Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 5243,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 16.08
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 74.17
  },
  {
    "person_key": 164377,
    "user_name": "User164377",
    "first_name": "User",
    "last_name": 164377,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 59.83
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L4 Exponents and Scientific Notation (CS)",
    "resource_id": 10292,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 375
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Week Four Unit 4: The Middle Ages",
    "resource_id": 6507,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29.92
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "MGMT 3377 - Entrepreneurship",
    "resource_id": 4302,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "10. William Kamkwamba: How I harnessed the wind",
    "resource_id": 5201,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5.33
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Chapter 1 - Don't Sweat the Small Stuff",
    "resource_id": 7983,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Elizabeth Lev: The unheard story of the Sistine Chapel",
    "resource_id": 7839,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.67
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "08. Stella Young: I’m not your inspiration, thank you very much",
    "resource_id": 5033,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5.17
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "06. Damon Horowitz: Philosophy in prison",
    "resource_id": 5075,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 4.17
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L3: Physical Maps (CS)",
    "resource_id": 10441,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 16
  },
  {
    "person_key": 169448,
    "user_name": "User169448",
    "first_name": "User",
    "last_name": 169448,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 4.5
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Formative Assessment - Social Studies",
    "resource_id": 9883,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 9302
  },
  {
    "person_key": 155742,
    "user_name": "User155742",
    "first_name": "User",
    "last_name": 155742,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.17
  },
  {
    "person_key": 158665,
    "user_name": "User158665",
    "first_name": "User",
    "last_name": 158665,
    "doc_id": 12345,
    "learning_object": "Alain de Botton: Atheism 2.0",
    "resource_id": 7808,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 30
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 7.17
  },
  {
    "person_key": 158665,
    "user_name": "User158665",
    "first_name": "User",
    "last_name": 158665,
    "doc_id": 12345,
    "learning_object": "1.1 What is Restorative Justice?: Graded",
    "resource_id": 4758,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.5
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "12. Christopher McDougall: Are we born to run?",
    "resource_id": 4989,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169448,
    "user_name": "User169448",
    "first_name": "User",
    "last_name": 169448,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6591,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169452,
    "user_name": "User169452",
    "first_name": "User",
    "last_name": 169452,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "11. John Hunter: Teaching with the World Peace Game",
    "resource_id": 5050,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 16.42
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 3-Is Confucianism a Religion",
    "resource_id": 7926,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 20.33
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "02. Alain de Botton: A kinder, gentler philosophy of success",
    "resource_id": 5015,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.75
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L5 Patterns and Functions",
    "resource_id": 10293,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "04. Mac Barnett: Why a good book is a secret door",
    "resource_id": 5184,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "Hindu The Yajur Veda",
    "resource_id": 6161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.75
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - L4: Political Maps (CS)",
    "resource_id": 10444,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 11
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.75
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "08. Ludwick Marishane: A bath without water",
    "resource_id": 5199,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 6.17
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Workbook - L4 Exponents and Scientific Notation",
    "resource_id": 10301,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 6857
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Optional Extended Response",
    "resource_id": 9885,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 13
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Tomás Saraceno: Would you live in a floating city in the sky?",
    "resource_id": 7572,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.42
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "02. Beau Lotto: Optical illusions show how we see",
    "resource_id": 5204,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 14.75
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "12. Sarah Jones: A one-woman global village",
    "resource_id": 5037,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5.75
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.42
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "01. Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 5192,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 4.17
  },
  {
    "person_key": 155031,
    "user_name": "User155031",
    "first_name": "User",
    "last_name": 155031,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.09
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "Prayers to the Orishas",
    "resource_id": 6135,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169452,
    "user_name": "User169452",
    "first_name": "User",
    "last_name": 169452,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "SPAN/FREN 2301 - Elementary Spanish/Elementary French I",
    "resource_id": 4496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 5.5
  },
  {
    "person_key": 167035,
    "user_name": "User167035",
    "first_name": "User",
    "last_name": 167035,
    "doc_id": 12345,
    "learning_object": "09. Myriam Sidibe: The simple power of hand-washing",
    "resource_id": 4986,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.5
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "09. George Monbiot: For more wonder, rewild the world",
    "resource_id": 5122,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.42
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Quiz - Unit 1 Review",
    "resource_id": 10147,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 303
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2",
    "resource_id": 7988,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.33
  },
  {
    "person_key": 155864,
    "user_name": "User155864",
    "first_name": "User",
    "last_name": 155864,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2",
    "resource_id": 7999,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 1.58
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Workbook - L3: Physical Maps",
    "resource_id": 10443,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 242
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1",
    "resource_id": 7995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 2.83
  },
  {
    "person_key": 158745,
    "user_name": "User158745",
    "first_name": "User",
    "last_name": 158745,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 88.58
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Reference - Using the Calculator",
    "resource_id": 9881,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 22
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 6.58
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "12. Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 5125,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 3.08
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Reference - Social Studies Formative Assessment Analysis Chart",
    "resource_id": 9884,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 12
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Workbook - L7 Percent (CS)",
    "resource_id": 9926,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 6
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "05. Morgana Bailey: The danger of hiding who you are",
    "resource_id": 5029,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 0.33
  },
  {
    "person_key": 87,
    "user_name": "User87",
    "first_name": "User",
    "last_name": 87,
    "doc_id": 12345,
    "learning_object": "03. David Pogue: Cool tricks your phone can do",
    "resource_id": 5167,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 8.08
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Workbook - L2: Understand Map Components (CS)",
    "resource_id": 10440,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 213
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Memory and Storage",
    "resource_id": 8227,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-20",
    "time_spent_min": 30.75
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "My Name",
    "resource_id": 8368,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 2.67
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 3.67
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "09. Ben Goldacre: Battling bad science",
    "resource_id": 5211,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "06. Lesley Hazleton: On reading the Koran",
    "resource_id": 5238,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 9.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 7739,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "06. AJ Jacobs: How healthy living nearly killed me",
    "resource_id": 4983,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "10. Naomi Oreskes: Why we should trust scientists",
    "resource_id": 5212,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1.25
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 6.42
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "17. Munir Virani: Why I love vultures",
    "resource_id": 5219,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 6.75
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "Sarah Jones: One woman, five characters, and a sex lesson from the future",
    "resource_id": 7599,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.5
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 25.08
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Astro Teller: The unexpected benefit of celebrating failure",
    "resource_id": 7766,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 30
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.66
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 7680,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 8.83
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "09. Sheryl Sandberg: Why we have too few women leaders",
    "resource_id": 5011,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 2.25
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 3 - Victory with Honor",
    "resource_id": 6518,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 20.83
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "Islam The Hadith",
    "resource_id": 6170,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 61.58
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 31.42
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 6.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Brian Little: Who are you, really? The puzzle of personality",
    "resource_id": 7742,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 3.67
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "20,0000 Leagues Under the Sea Audiobook",
    "resource_id": 5848,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 30
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Camille Seaman: Haunting photos of polar ice",
    "resource_id": 7679,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 4.25
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "11. Anupam Mishra: The ancient ingenuity of water harvesting",
    "resource_id": 5251,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 30
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 5.17
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "07. Michael Shermer: The pattern behind self-deception",
    "resource_id": 5209,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 5.08
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 2.5
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 7547,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "02. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5026,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.58
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 4- Law Enforcement in Action",
    "resource_id": 7438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 43.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 7596,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1.58
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "07. Mustafa Akyol: Faith versus tradition in Islam",
    "resource_id": 5239,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.92
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "16. Michael Dickinson: How a fly flies",
    "resource_id": 5218,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Two:  Chapter Two:  Preparing Your First Speech",
    "resource_id": 6830,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 37
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Kang Lee: Can you really tell if a kid is lying?",
    "resource_id": 7747,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 8.33
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 5.08
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "Rich Benjamin: My road trip through the whitest towns in America",
    "resource_id": 7562,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "08. Johanna Blakley: Social media and the end of gender",
    "resource_id": 5010,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.83
  },
  {
    "person_key": 163074,
    "user_name": "User163074",
    "first_name": "User",
    "last_name": 163074,
    "doc_id": 12345,
    "learning_object": "04. Elyn Saks: A tale of mental illness -- from the inside",
    "resource_id": 4959,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "04. Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 5206,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.67
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "13. AJ Jacobs: The world's largest family reunion … we're all invited!",
    "resource_id": 5038,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 6
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "10. Annie Murphy Paul: What we learn before we're born",
    "resource_id": 5049,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 5.42
  },
  {
    "person_key": 157827,
    "user_name": "User157827",
    "first_name": "User",
    "last_name": 157827,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 30.33
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "02. Alice Dreger: Is anatomy destiny?",
    "resource_id": 5004,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 29.92
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "06. Sugata Mitra: Build a School in the Cloud",
    "resource_id": 5045,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "03. Faith Jegede: What I've learned from my autistic brothers",
    "resource_id": 5062,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 8.42
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "08. Sue Austin: Deep sea diving ... in a wheelchair",
    "resource_id": 5228,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 10.33
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 23.58
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 8.92
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 2",
    "resource_id": 7999,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 67.67
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "05. Lesley Hazleton: The doubt essential to faith",
    "resource_id": 5237,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "05. William Li: Can we eat to starve cancer?",
    "resource_id": 4982,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Chris Burkard: The joy of surfing in ice-cold wate",
    "resource_id": 7697,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 3
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 68.33
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 3.66
  },
  {
    "person_key": 159003,
    "user_name": "User159003",
    "first_name": "User",
    "last_name": 159003,
    "doc_id": 12345,
    "learning_object": "Sally Kohn: Let’s try emotional correctness",
    "resource_id": 7576,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 58.5
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 5.5
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Chapter 1 - Don't Sweat the Small Stuff",
    "resource_id": 7983,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "The Quran",
    "resource_id": 6168,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 7.5
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "Living with your Body and other things you Hate",
    "resource_id": 5921,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 7580,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 10.17
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 3.5
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "Man's Search for Meaning",
    "resource_id": 8084,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 10.92
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "13. Richard Dawkins: Why the universe seems so strange",
    "resource_id": 5215,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "05. Pearl Arredondo: My story, from gangland daughter to star teacher",
    "resource_id": 5044,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.67
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Jackson Katz: Violence against women -- it's a men's issue",
    "resource_id": 7575,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 40.83
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Rebecca MacKinnon: Let's take back the Internet!",
    "resource_id": 7728,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 15.25
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.33
  },
  {
    "person_key": 163074,
    "user_name": "User163074",
    "first_name": "User",
    "last_name": 163074,
    "doc_id": 12345,
    "learning_object": "African Cultural Values",
    "resource_id": 6125,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1.08
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "02. Russell Foster: Why do we sleep?",
    "resource_id": 4979,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 7.58
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 29.25
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "14. Rose George: Let's talk crap. Seriously.",
    "resource_id": 4991,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "06. Colin Stokes: How movies teach manhood",
    "resource_id": 5008,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 4.42
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "02. Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 5071,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 25.75
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 9.92
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 14.58
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "22. Jill Bolte Taylor: My stroke of insight",
    "resource_id": 5162,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1.33
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "05. Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 5207,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.58
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 63.75
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Four:  Chapter 13: Outlining the Speech",
    "resource_id": 6832,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 5.58
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 37
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "18. Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 5158,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.25
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "The Way of the Orisha",
    "resource_id": 6128,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168585,
    "user_name": "User168585",
    "first_name": "User",
    "last_name": 168585,
    "doc_id": 12345,
    "learning_object": "Prayers to the Orishas",
    "resource_id": 6135,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.5
  },
  {
    "person_key": 52,
    "user_name": "User52",
    "first_name": "User",
    "last_name": 52,
    "doc_id": 12345,
    "learning_object": "01. Brené Brown: Listening to shame",
    "resource_id": 5014,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1.92
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 16.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Alice Goffman: How we're priming some kids for college — and others for prison",
    "resource_id": 7636,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 16.17
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Tony Robbins: Why we do what we do",
    "resource_id": 7763,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 23
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "10. iO Tillett Wright: Fifty shades of gay",
    "resource_id": 5012,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 10.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "10. Nadine Burke Harris: How childhood trauma affects health across a lifetime",
    "resource_id": 5149,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 5.33
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Zaria Forman: Drawings that show the beauty and fragility of Earth",
    "resource_id": 7683,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 7.33
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.0899999
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "Bone Yard Audiobook",
    "resource_id": 5797,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 30
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Carol Dweck: The power of believing that you can improve",
    "resource_id": 7748,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 2.17
  },
  {
    "person_key": 166579,
    "user_name": "User166579",
    "first_name": "User",
    "last_name": 166579,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.91
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Workbook - L6 Decimals",
    "resource_id": 10109,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1085
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 92.33
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Workbook - L7 Percent (CS)",
    "resource_id": 9926,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1074
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Student Book - L7 Percent (CS) - Quiz",
    "resource_id": 10119,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 434
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Student Book - L7 Percent (CS)",
    "resource_id": 9893,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 18
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1.83
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Nagin Cox: What time is it on Mars?",
    "resource_id": 7749,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "06. Amy Webb: How I hacked online dating",
    "resource_id": 5065,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 2.42
  },
  {
    "person_key": 170389,
    "user_name": "User170389",
    "first_name": "User",
    "last_name": 170389,
    "doc_id": 12345,
    "learning_object": "Student Book - U2 L1 Measurement and Units of Measure - Quiz",
    "resource_id": 10146,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 256
  },
  {
    "person_key": 170389,
    "user_name": "User170389",
    "first_name": "User",
    "last_name": 170389,
    "doc_id": 12345,
    "learning_object": "Student Book - L5 Bar and Line Graphs",
    "resource_id": 10114,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1713
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Andrés Ruzo: The boiling river of the Amazon",
    "resource_id": 7685,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.83
  },
  {
    "person_key": 170389,
    "user_name": "User170389",
    "first_name": "User",
    "last_name": 170389,
    "doc_id": 12345,
    "learning_object": "Part 1: Formative Assessment",
    "resource_id": 9859,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1506
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "03. Faith Jegede: What I've learned from my autistic brothers",
    "resource_id": 5062,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 14.75
  },
  {
    "person_key": 170389,
    "user_name": "User170389",
    "first_name": "User",
    "last_name": 170389,
    "doc_id": 12345,
    "learning_object": "Workbook - L4 Circles",
    "resource_id": 10356,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 437
  },
  {
    "person_key": 170389,
    "user_name": "User170389",
    "first_name": "User",
    "last_name": 170389,
    "doc_id": 12345,
    "learning_object": "Workbook - L1 Measurement and Units of Measure (CS)",
    "resource_id": 10113,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 2106
  },
  {
    "person_key": 170389,
    "user_name": "User170389",
    "first_name": "User",
    "last_name": 170389,
    "doc_id": 12345,
    "learning_object": "Workbook - L5 Bar and Line Graphs",
    "resource_id": 10118,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 2259
  },
  {
    "person_key": 170389,
    "user_name": "User170389",
    "first_name": "User",
    "last_name": 170389,
    "doc_id": 12345,
    "learning_object": "Workbook - L1 Algebraic Expressions and Variables (CS)",
    "resource_id": 10287,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 2108
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "APDS Issue Report",
    "resource_id": 5464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Workbook - L4 Fractions",
    "resource_id": 9916,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 10184
  },
  {
    "person_key": 163074,
    "user_name": "User163074",
    "first_name": "User",
    "last_name": 163074,
    "doc_id": 12345,
    "learning_object": "05. Eleanor Longden: The voices in my head",
    "resource_id": 4960,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "07. Dan Buettner: How to live to be 100+",
    "resource_id": 4984,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1.75
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Part 2: Summative Assessment",
    "resource_id": 10362,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 30
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 6.17
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "03. Kevin Briggs: The bridge between suicide and life",
    "resource_id": 4958,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L2 Equations (CS) - Quiz",
    "resource_id": 10278,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 615
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Workbook - L2 Equations",
    "resource_id": 10289,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1199
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L3 Squaring, Cubing, and Taking Roots",
    "resource_id": 10290,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 43
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L4 Exponents and Scientific Notation (CS)",
    "resource_id": 10292,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 7256
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "TED Talks 2019: What Makes Us Stronger",
    "resource_id": 4429,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Workbook - L3 Squaring, Cubing, and Taking Roots (CS)",
    "resource_id": 10300,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 2935
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - U3 L1 Algebraic Expressions and Variables - Quiz",
    "resource_id": 10277,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 4686
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L2 Equations (CS)",
    "resource_id": 10288,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 157
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 10.92
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "14. Boaz Almog: The levitating superconductor",
    "resource_id": 5216,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 3
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "iO Tillett Wright: Fifty shades of gay",
    "resource_id": 7592,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 6.42
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "02. Rita Pierson: Every kid needs a champion",
    "resource_id": 5041,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Richard Dawkins: Why the universe seems so strange",
    "resource_id": 7733,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169819,
    "user_name": "User169819",
    "first_name": "User",
    "last_name": 169819,
    "doc_id": 12345,
    "learning_object": "Radio Survey",
    "resource_id": 5689,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.75
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 79.17
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week 6 Unit 5 Beowulf",
    "resource_id": 6513,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.67
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 6.17
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "TED Talks 2019: Your Emotional Self",
    "resource_id": 4389,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.08
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 1.17
  },
  {
    "person_key": 161011,
    "user_name": "User161011",
    "first_name": "User",
    "last_name": 161011,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 7.33
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "Sue Austin: Deep sea diving ... in a wheelchair",
    "resource_id": 7817,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson Three:  Writing the Personal History Story Speech",
    "resource_id": 6831,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 12.25
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 4 - Coaching Philosophy",
    "resource_id": 6987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "15. Anthony Atala: Printing a human kidney",
    "resource_id": 5217,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 0.5
  },
  {
    "person_key": 161559,
    "user_name": "User161559",
    "first_name": "User",
    "last_name": 161559,
    "doc_id": 12345,
    "learning_object": "Unit 2: Statement of Cash Flow",
    "resource_id": 7955,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-19",
    "time_spent_min": 66.67
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Laptops",
    "resource_id": 8247,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 35.75
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Student Book - L7 Percent (CS) - Quiz",
    "resource_id": 10119,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 627
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Student Book - U1 L1 Whole Numbers (CS)",
    "resource_id": 9863,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 55
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Student Book - L6 Decimals (CS)",
    "resource_id": 10108,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 16
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Joy Buolamwini: How I'm fighting bias in algorithms",
    "resource_id": 7721,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 21.58
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Student Book - U1 L1 Whole Numbers (CS) - Quiz",
    "resource_id": 9864,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 10
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "06. Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 5030,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.25
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 4- Law Enforcement in Action",
    "resource_id": 7438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 29.92
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Workbook - L6 Decimals",
    "resource_id": 10109,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1080
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "09. Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 5134,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Student Book - L2 Operations - Quiz",
    "resource_id": 9935,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 3- Policing Issues",
    "resource_id": 7437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 91.42
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "04. Dan Pink: The puzzle of motivation",
    "resource_id": 5129,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 18.42
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Student Book - L3 Integers",
    "resource_id": 9936,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 65
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Student Book - U3 L1 Algebraic Expressions and Variables - Quiz",
    "resource_id": 10277,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 94
  },
  {
    "person_key": 169564,
    "user_name": "User169564",
    "first_name": "User",
    "last_name": 169564,
    "doc_id": 12345,
    "learning_object": "Unit 1 Solving Equations",
    "resource_id": 7905,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Workbook - L7 Percent (CS)",
    "resource_id": 9926,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 799
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 18.25
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.58
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Workbook - L5 Ratios and Proportions",
    "resource_id": 10107,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 7241
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Workbook - L2 Operations",
    "resource_id": 9891,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 7
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Student Book - U3 L1 Algebraic Expressions and Variables",
    "resource_id": 10286,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 35
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Workbook - L1 Algebraic Expressions and Variables (CS)",
    "resource_id": 10287,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 6
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 13.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2.5
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 13.5
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Josh Luber: Why sneakers are a great investment",
    "resource_id": 7695,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 3.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5060,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 5.75
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "Agriculture Industry Overview",
    "resource_id": 8020,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2.67
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Tony Porter: A call to men",
    "resource_id": 7590,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.33
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "01. Ken Robinson: How schools kill creativity",
    "resource_id": 5040,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.75
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.67
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Alix Generous: How I learned to communicate my inner life with Asperger's",
    "resource_id": 7537,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 11.17
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Chris Anderson: TED's secret to great public speaking",
    "resource_id": 7767,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 16.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "08. Stella Young: I’m not your inspiration, thank you very much",
    "resource_id": 5033,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 6 - Motivation in Sport",
    "resource_id": 7065,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 36.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "03. Faith Jegede: What I've learned from my autistic brothers",
    "resource_id": 5062,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 15.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 4978,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.42
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Jim Holt: Why does the universe exist?",
    "resource_id": 7651,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 18.67
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Latif Nasser: You have no idea where camels really come from",
    "resource_id": 7614,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "08. Nalini Nadkarni: Life science in prison",
    "resource_id": 5077,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 32.08
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 3.67
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "08. Dan Meyer: Math class needs a makeover",
    "resource_id": 5047,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 12.33
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "MGMT 3377 - Entrepreneurship",
    "resource_id": 4302,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.33
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.08
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Student Book - L3 Integers - Quiz",
    "resource_id": 9938,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 70
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Sue Austin: Deep sea diving ... in a wheelchair",
    "resource_id": 7817,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 9.25
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Quiz - Unit 1 Review",
    "resource_id": 10147,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 9331
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "13. Kare Anderson: Be an opportunity maker",
    "resource_id": 5138,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 8.83
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Paul Knoepfler: The ethical dilemma of designer babies",
    "resource_id": 7801,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 7.5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.83
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 5 Section 1",
    "resource_id": 7998,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 101.42
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 4 - Coaching Philosophy",
    "resource_id": 6987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Lesley Hazleton: The doubt essential to faith",
    "resource_id": 7809,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Stephen Cave: The 4 stories we tell ourselves about death",
    "resource_id": 7657,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 6.42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.5
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Movement on Maps (CS) - Quiz",
    "resource_id": 10448,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 165
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Jessica Jackley: Poverty, money -- and love",
    "resource_id": 7712,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.67
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "11. JD Schramm: Break the silence for suicide attempt survivors",
    "resource_id": 4966,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165126,
    "user_name": "User165126",
    "first_name": "User",
    "last_name": 165126,
    "doc_id": 12345,
    "learning_object": "04. Rishi Manchanda: What makes us get sick? Look upstream.",
    "resource_id": 4981,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 24.58
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Siamak Hariri: How do you build a sacred space?",
    "resource_id": 7812,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.25
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "A Buddhist Bible",
    "resource_id": 6160,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Rick Smolan: The story of a girl",
    "resource_id": 7627,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 37.5
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "11. Norman Spack: How I help transgender teens become who they want to be",
    "resource_id": 5013,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2.33
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 13.5
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "08. Shawn Achor: The happy secret to better work",
    "resource_id": 5133,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 12.75
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.09
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Kevin Breel: Confessions of a depressed comic",
    "resource_id": 7534,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.58
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 30.42
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Alain de Botton: Atheism 2.0",
    "resource_id": 7808,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Karen Armstrong: Let's revive the Golden Rule",
    "resource_id": 7807,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Johanna Blakley: Social media and the end of gender",
    "resource_id": 7589,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Alice Goffman: How we're priming some kids for college — and others for prison",
    "resource_id": 7636,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 3.75
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 18.83
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "14. Melissa Marshall: Talk nerdy to me",
    "resource_id": 5139,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 5.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Temple Grandin: The world needs all kinds of minds",
    "resource_id": 7538,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 44.67
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Julian Treasure: How to speak so that people want to listen",
    "resource_id": 7765,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 23.33
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "02. Rita Pierson: Every kid needs a champion",
    "resource_id": 5041,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 13
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 8.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Varieties of Homicide",
    "resource_id": 7916,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 19.58
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 28.75
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: How the worst moments in our lives make us who we are",
    "resource_id": 7818,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 8.33
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Joshua Walters: On being just crazy enough",
    "resource_id": 7539,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 6.42
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "09. George Monbiot: For more wonder, rewild the world",
    "resource_id": 5122,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.08
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "03. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5128,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 6.08
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 14.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "02. Michael Norton: How to buy happiness",
    "resource_id": 5105,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 9.42
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "11. John Hunter: Teaching with the World Peace Game",
    "resource_id": 5050,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 43.58
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Celeste Headlee: 10 ways to have a better conversation",
    "resource_id": 7770,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 12.33
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.08
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 2",
    "resource_id": 7993,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 36.83
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Julian Treasure: How to speak so that people want to listen",
    "resource_id": 7765,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "J.J. Abrams: The mystery box",
    "resource_id": 7606,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 54.42
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 9.58
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "JD Schramm: Break the silence for suicide attempt survivors",
    "resource_id": 7557,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 6.25
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 2",
    "resource_id": 7996,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 63.33
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 3 - Victory with Honor",
    "resource_id": 6518,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 7580,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Negin Farsad: A highly scientific taxonomy of haters",
    "resource_id": 7569,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 25.92
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "02. Rita Pierson: Every kid needs a champion",
    "resource_id": 5041,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 36
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "Contractor Safety",
    "resource_id": 8284,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "12. Sarah Jones: A one-woman global village",
    "resource_id": 5037,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 83.08
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 6.5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.59
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 7.33
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Jack Conte: How artists can (finally) get paid in the digital age",
    "resource_id": 7785,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Mac Barnett: Why a good book is a secret door",
    "resource_id": 7598,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 27
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "iO Tillett Wright: Fifty shades of gay",
    "resource_id": 7592,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "09. Kiran Bedi: A police chief with a difference",
    "resource_id": 5078,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.58
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 76.5
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Ludwick Marishane: A bath without water",
    "resource_id": 7781,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.75
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Tony Robbins: Why we do what we do",
    "resource_id": 7763,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 13.83
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "08. Ruth Chang: How to make hard choices",
    "resource_id": 5059,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 8.42
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 3.5
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: Depression, the secret we share",
    "resource_id": 7536,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 5.33
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Sarah Jones: One woman, five characters, and a sex lesson from the future",
    "resource_id": 7599,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Jackson Katz: Violence against women -- it's a men's issue",
    "resource_id": 7575,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "UNIT 1: INTRODUCTION TO ACCOUNTING AND BUSINESS",
    "resource_id": 7410,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 29.92
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "06. Dan Ariely: What makes us feel good about our work?",
    "resource_id": 5131,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 15.67
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Johanna Blakley: Social media and the end of gender",
    "resource_id": 7589,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.42
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 3- Policing Issues",
    "resource_id": 7437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 60.42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Lesson One:  Chapter One :  Becoming a Public Speaker",
    "resource_id": 6829,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 70.33
  },
  {
    "person_key": 158848,
    "user_name": "User158848",
    "first_name": "User",
    "last_name": 158848,
    "doc_id": 12345,
    "learning_object": "Foreward/Intro/First Pillar",
    "resource_id": 8488,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 17.92
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "03. Christopher Emdin: Teach teachers how to create magic",
    "resource_id": 5042,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 7.75
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "11. Susan Colantuono: The career advice you probably didn’t get",
    "resource_id": 5136,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 10.58
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Astro Teller: The unexpected benefit of celebrating failure",
    "resource_id": 7766,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 17.5
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 7964,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 13.08
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 7580,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Ingrid Betancourt: What six years in captivity taught me about fear and faith",
    "resource_id": 7804,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 17.42
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Melissa Marshall: Talk nerdy to me",
    "resource_id": 7771,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 8
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Aimee Mullins: The opportunity of adversity",
    "resource_id": 7816,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2.83
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.67
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Kevin Briggs: The bridge between suicide and life",
    "resource_id": 7543,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2.67
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "12. Tyler DeWitt: Hey science teachers -- make it fun",
    "resource_id": 5051,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 19.08
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Keren Elazari: Hackers: the Internet's immune system",
    "resource_id": 7724,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 25.75
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "06. Andrew Solomon: Depression, the secret we share",
    "resource_id": 4961,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 30
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 63.67
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2.25
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Yves Morieux: How too many rules at work keep you from getting things done",
    "resource_id": 7772,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 11.25
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 4- Law Enforcement in Action",
    "resource_id": 7438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2.67
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Dilip Ratha: The hidden force in global economics: sending money home",
    "resource_id": 7711,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 19.5
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 12.67
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Christopher Bell: Bring on the female superheroes!",
    "resource_id": 7600,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 17
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Paul Tudor Jones II: Why we need to rethink capitalism",
    "resource_id": 7710,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 30.08
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 7813,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 14.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Islam The Hadith",
    "resource_id": 6170,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 42.58
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 1",
    "resource_id": 7995,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 42.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Jedidah Isler: How I fell in love with quasars, blazars and our incredible universe",
    "resource_id": 7750,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 5.17
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 36.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 7.58
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "Buddhist Becoming your Own Therapist",
    "resource_id": 6157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.42
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 4- Venue and Courtroom Specifics",
    "resource_id": 7915,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 4.58
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 14.25
  },
  {
    "person_key": 167260,
    "user_name": "User167260",
    "first_name": "User",
    "last_name": 167260,
    "doc_id": 12345,
    "learning_object": "14. Misha Glenny: Hire the hackers!",
    "resource_id": 5178,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "05. Morgana Bailey: The danger of hiding who you are",
    "resource_id": 5029,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.92
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Nabila Alibhai: Why people of different faiths are painting their houses of worship yellow",
    "resource_id": 7811,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 10.25
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 9.25
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.92
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 71.25
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Student Book - L2 Operations",
    "resource_id": 9892,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Rachel Sussman: The world's oldest living things",
    "resource_id": 7682,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 15.25
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "04. Shlomo Benartzi: Saving for tomorrow, tomorrow",
    "resource_id": 5107,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 8.33
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "10. Sally Kohn: Let’s try emotional correctness",
    "resource_id": 5035,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 7593,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2.17
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.5
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 4- Law Enforcement in Action",
    "resource_id": 7438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 1.42
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Adam Galinsky: How to speak up for yourself",
    "resource_id": 7759,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 22
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 2.5
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 49.09
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 23
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "12. Julian Treasure: 5 ways to listen better",
    "resource_id": 5137,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 7.75
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Kevin Breel: Confessions of a depressed comic",
    "resource_id": 7534,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 11.5
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "02. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5026,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 3",
    "resource_id": 7994,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 79
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 8.42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 4 Section 3",
    "resource_id": 7997,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 52.08
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Internet Connectivity",
    "resource_id": 8099,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 30.33
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 3- Policing Issues",
    "resource_id": 7437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Communicating with Your Athletes",
    "resource_id": 7031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 14.33
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 4 - Coaching Philosophy",
    "resource_id": 6987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 15.08
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 3 - The Roots of the AMLS",
    "resource_id": 7914,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 3.42
  },
  {
    "person_key": 162567,
    "user_name": "User162567",
    "first_name": "User",
    "last_name": 162567,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 13.42
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Islam The Meanings of Nobel Quran",
    "resource_id": 6169,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 22.75
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 3 - Victory with Honor",
    "resource_id": 6518,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 21.67
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Rebecca MacKinnon: Let's take back the Internet!",
    "resource_id": 7728,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 2-  Historical Implications of Justice",
    "resource_id": 7913,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 6.08
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Kathryn Schulz: Don't regret regret",
    "resource_id": 7826,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 12.25
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Simon Sinek: How great leaders inspire action",
    "resource_id": 7758,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.25
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Emilie Wapnick: Why some of us don't have one true calling",
    "resource_id": 7761,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 17.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "05. David R. Dow: Lessons from death row inmates",
    "resource_id": 5074,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 30
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 7961,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 15.58
  },
  {
    "person_key": 169877,
    "user_name": "User169877",
    "first_name": "User",
    "last_name": 169877,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Memory and Storage",
    "resource_id": 8227,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 7963,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 8.92
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 7960,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 21.33
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 5025,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 47.67
  },
  {
    "person_key": 153808,
    "user_name": "User153808",
    "first_name": "User",
    "last_name": 153808,
    "doc_id": 12345,
    "learning_object": "Melissa Marshall: Talk nerdy to me",
    "resource_id": 7771,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 5.58
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Lucianne Walkowicz: Let's not use Mars as a backup planet",
    "resource_id": 7757,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 6.42
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "05. Tony Robbins: Why we do what we do",
    "resource_id": 5130,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 21.5
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 7962,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 6.5
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "10. Ricardo Semler: How to run a company with (almost) no rules",
    "resource_id": 5135,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 7593,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Gabriela González: How LIGO discovered gravitational waves -- and what might be next",
    "resource_id": 7773,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.58
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 11.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "06. Parul Sehgal: An ode to envy",
    "resource_id": 5186,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 13.92
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 22
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 2 - Developing Your Coaching Objectives",
    "resource_id": 6517,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 24.25
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "09. Tania Luna: How a penny made me feel like a millionaire",
    "resource_id": 5112,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.67
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 29.92
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "07. Julian Treasure: How to speak so that people want to listen",
    "resource_id": 5132,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 10
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Workbook - L1 Whole Numbers (CS)",
    "resource_id": 9860,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 11
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Reference - Mathematics Formative Assessment Performance Analysis Chart",
    "resource_id": 9924,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 15
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Devdutt Pattanaik: East vs. West -- the myths that mystify",
    "resource_id": 7806,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 0.58
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Student Book - L15 Graphing Quadratic Equations (CS)",
    "resource_id": 10316,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-18",
    "time_spent_min": 129
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167953,
    "user_name": "User167953",
    "first_name": "User",
    "last_name": 167953,
    "doc_id": 12345,
    "learning_object": "03. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5128,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 14.25
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 16.5
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 8 Essay",
    "resource_id": 6355,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Tasos Frantzolas: Everything you hear on film is a lie",
    "resource_id": 7565,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167953,
    "user_name": "User167953",
    "first_name": "User",
    "last_name": 167953,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 6.25
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "CPI Letter",
    "resource_id": 6368,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 11 Essay",
    "resource_id": 6362,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "06. Amy Webb: How I hacked online dating",
    "resource_id": 5065,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 3.17
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 4.25
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Cesar Kuriyama: One second every day",
    "resource_id": 7560,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.67
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Workbook - L7: Interpret Timelines (CS)",
    "resource_id": 10469,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 5749
  },
  {
    "person_key": 167680,
    "user_name": "User167680",
    "first_name": "User",
    "last_name": 167680,
    "doc_id": 12345,
    "learning_object": "02. Russell Foster: Why do we sleep?",
    "resource_id": 4979,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.83
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 7549,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.67
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.67
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "02. Mellody Hobson: Color blind or color brave?",
    "resource_id": 5026,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 2.5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Negin Farsad: A highly scientific taxonomy of haters",
    "resource_id": 7569,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 9.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Suzanne Simard: How trees talk to each other",
    "resource_id": 7687,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.58
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 7791,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 5.5
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 7690,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 10.5
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 6.08
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Tabetha Boyajian: The most mysterious star in the universe",
    "resource_id": 7753,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 1",
    "resource_id": 6634,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.42
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "09. Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 5189,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 6.75
  },
  {
    "person_key": 167953,
    "user_name": "User167953",
    "first_name": "User",
    "last_name": 167953,
    "doc_id": 12345,
    "learning_object": "Workplace Safety Orientation",
    "resource_id": 8094,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 22
  },
  {
    "person_key": 171121,
    "user_name": "User171121",
    "first_name": "User",
    "last_name": 171121,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 29.91
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.92
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 7831,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 17.25
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Joy Buolamwini: How I'm fighting bias in algorithms",
    "resource_id": 7721,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 30.25
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Kang Lee: Can you really tell if a kid is lying?",
    "resource_id": 7747,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 16.75
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "MGMT 3377 - Entrepreneurship",
    "resource_id": 4302,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Susan Pinker: The secret to living longer may be your social life",
    "resource_id": 7641,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Kathryn Schulz: Don't regret regret",
    "resource_id": 7826,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 13 Essay",
    "resource_id": 6364,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Alice Goffman: How we're priming some kids for college — and others for prison",
    "resource_id": 7636,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Ben Goldacre: Battling bad science",
    "resource_id": 7793,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 29.75
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 11.58
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Welcome and Important Documents List",
    "resource_id": 6378,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "01. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5060,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 5.33
  },
  {
    "person_key": 261,
    "user_name": "User261",
    "first_name": "User",
    "last_name": 261,
    "doc_id": 12345,
    "learning_object": "Ways To Not Go Broke As A New Artist And How The Music Industry Really Works",
    "resource_id": 8379,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 4.33
  },
  {
    "person_key": 162192,
    "user_name": "User162192",
    "first_name": "User",
    "last_name": 162192,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 99.67
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Faith Jegede Cole: What I've learned from my autistic brothers",
    "resource_id": 7623,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.67
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.33
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 10.08
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "05. Pearl Arredondo: My story, from gangland daughter to star teacher",
    "resource_id": 5044,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 8.33
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Workbook - L5 Ratios and Proportions",
    "resource_id": 10107,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 329
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Alice Dreger: Is anatomy destiny?",
    "resource_id": 7587,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170363,
    "user_name": "User170363",
    "first_name": "User",
    "last_name": 170363,
    "doc_id": 12345,
    "learning_object": "Student Book - L5 Ratios and Proportions (CS)",
    "resource_id": 9937,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 5473
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Paul Piff: Does money make you mean?",
    "resource_id": 7703,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Michael Dickinson: How a fly flies",
    "resource_id": 7796,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 15.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.0900002
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 7681,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 3.83
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Anil Gupta: India's hidden hotbeds of invention",
    "resource_id": 7778,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.75
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 9.42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.83
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Thomas Peschak: Dive into an ocean photographer's world",
    "resource_id": 7677,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 5.08
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Adam Driver: My journey from Marine to actor",
    "resource_id": 7559,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.17
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 19.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 261,
    "user_name": "User261",
    "first_name": "User",
    "last_name": 261,
    "doc_id": 12345,
    "learning_object": "2019-03-1957",
    "resource_id": 8530,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169369,
    "user_name": "User169369",
    "first_name": "User",
    "last_name": 169369,
    "doc_id": 12345,
    "learning_object": "Unit 3 - Victory with Honor",
    "resource_id": 6518,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 5.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Guillaume Néry: The exhilarating peace of freediving",
    "resource_id": 7704,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 20.17
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 15
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 2.17
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Unit 6: MLA Practice",
    "resource_id": 6850,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.25
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Neil MacGregor: 2600 years of history in one object",
    "resource_id": 7835,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 5.92
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L11 The Coordinate Grid (CS)",
    "resource_id": 10346,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1505
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 7547,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.17
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "10. Elif Shafak: The politics of fiction",
    "resource_id": 5190,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Boniface Mwangi: The day I stood up alone",
    "resource_id": 7823,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Sarah Kay: If I should have a daughter ...",
    "resource_id": 7550,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 18.92
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "About High School Equivalency Tests",
    "resource_id": 8755,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 282
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 7547,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.92
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 7593,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 8.83
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Nalini Nadkarni: Life science in prison",
    "resource_id": 7658,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 2 Essay",
    "resource_id": 6324,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.5
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Workbook - L6: Cause and Effect (CS)",
    "resource_id": 10466,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 2004
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Lucianne Walkowicz: Let's not use Mars as a backup planet",
    "resource_id": 7757,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 2.5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Alicia Garza, Patrisse Cullors and Opal Tometi: An interview with the founders of Black Lives Matter",
    "resource_id": 7640,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Sheryl Sandberg: Why we have too few women leaders",
    "resource_id": 7591,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Michael Norton: How to buy happiness",
    "resource_id": 7706,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: Laptops",
    "resource_id": 8247,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 3
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 1",
    "resource_id": 8790,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 57
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 7681,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 6 Essay",
    "resource_id": 6353,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.33
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Zaria Forman: Drawings that show the beauty and fragility of Earth",
    "resource_id": 7683,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.33
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Nagin Cox: What time is it on Mars?",
    "resource_id": 7749,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 167798,
    "user_name": "User167798",
    "first_name": "User",
    "last_name": 167798,
    "doc_id": 12345,
    "learning_object": "Sarah Parcak: Help discover ancient ruins -- before it's too late",
    "resource_id": 7755,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155610,
    "user_name": "User155610",
    "first_name": "User",
    "last_name": 155610,
    "doc_id": 12345,
    "learning_object": "Ismael Nazario: What I learned as a kid in jail",
    "resource_id": 7648,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 4.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "OK Go: How to find a wonderful idea",
    "resource_id": 7561,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 9 Essay",
    "resource_id": 6360,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170357,
    "user_name": "User170357",
    "first_name": "User",
    "last_name": 170357,
    "doc_id": 12345,
    "learning_object": "Student Book - L3 Integers",
    "resource_id": 9936,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 158
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.17
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "11. Amy Tan: Where does creativity hide?",
    "resource_id": 5191,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 4.67
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 12 Essay",
    "resource_id": 6363,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 95.92
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 6637,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 6.75
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Nadia Lopez: Why open a school? To close a prison",
    "resource_id": 7633,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 7.83
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Kandice Sumner: How America's public schools keep kids in poverty",
    "resource_id": 7645,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Zak Ebrahim: I am the son of a terrorist. Here's how I chose peace.",
    "resource_id": 7814,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 19
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.42
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 3 Section 1",
    "resource_id": 7992,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 76.17
  },
  {
    "person_key": 170357,
    "user_name": "User170357",
    "first_name": "User",
    "last_name": 170357,
    "doc_id": 12345,
    "learning_object": "Student Book - L3 Integers - Quiz",
    "resource_id": 9938,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 820
  },
  {
    "person_key": 170357,
    "user_name": "User170357",
    "first_name": "User",
    "last_name": 170357,
    "doc_id": 12345,
    "learning_object": "Student Book - L4 Fractions - Quiz",
    "resource_id": 9915,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1505
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 5 Essay",
    "resource_id": 6352,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.92
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "08. Erin McKean: Go ahead, make up new words!",
    "resource_id": 5188,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 7.67
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Student Book - L3 Integers",
    "resource_id": 9936,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 180
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Student Book - L2 Operations",
    "resource_id": 9892,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 57
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Student Book - L3 Integers - Quiz",
    "resource_id": 9938,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 5313
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "Boaz Almog: The levitating superconductor",
    "resource_id": 7795,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.33
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 3- Policing Issues",
    "resource_id": 7437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 44.17
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.08
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Workbook - L2 Operations",
    "resource_id": 9891,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 94
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Sanford Biggers: An artist's unflinching look at racial violence",
    "resource_id": 7544,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.75
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Student Book - L2 Operations - Quiz",
    "resource_id": 9935,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 2537
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Ivan Coyote: Why we need gender-neutral bathrooms",
    "resource_id": 7586,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.5
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "04. Module 1: Fundamentals of Environmental Literacy",
    "resource_id": 4931,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 38.5
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Suzanne Simard: How trees talk to each other",
    "resource_id": 7687,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.83
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.42
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Reference - Test Taking Tips",
    "resource_id": 9890,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 29
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 9.5
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 6636,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Jill Tarter: Join the SETI search",
    "resource_id": 7734,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "OK Go: How to find a wonderful idea",
    "resource_id": 7561,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.67
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Rick Smolan: The story of a girl",
    "resource_id": 7627,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.67
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Gabriela González: How LIGO discovered gravitational waves -- and what might be next",
    "resource_id": 7773,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Josh Luber: Why sneakers are a great investment",
    "resource_id": 7695,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 18
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Richard Turere: My invention that made peace with lions",
    "resource_id": 7776,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 6.58
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Edith Widder: How we found the giant squid",
    "resource_id": 7691,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 9.33
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "David Gallo: Underwater astonishments",
    "resource_id": 7688,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Mary Bassett: Why your doctor should care about social justice",
    "resource_id": 7639,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.67
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 7 Essay",
    "resource_id": 6354,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Marlon Peterson: Am I not human? A call for criminal justice reform",
    "resource_id": 7646,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 8.08
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Frances Larson: Why public beheadings get millions of views",
    "resource_id": 7828,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 5.42
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Unit Overview",
    "resource_id": 8758,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1397
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 31.33
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Rick Smolan: The story of a girl",
    "resource_id": 7627,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 14.25
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Ron Finley: A guerilla gardener in South Central LA",
    "resource_id": 7775,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 10.75
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Mundano: Trash cart superheroes",
    "resource_id": 7551,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.67
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Midterm Exam",
    "resource_id": 6356,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Sanford Biggers: An artist's unflinching look at racial violence",
    "resource_id": 7544,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 18.09
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "George Takei: Why I love a country that once betrayed me",
    "resource_id": 7830,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 16.92
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 1 Essay",
    "resource_id": 6318,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169541,
    "user_name": "User169541",
    "first_name": "User",
    "last_name": 169541,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 6635,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.67
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 16.42
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 15 Essay",
    "resource_id": 6366,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "TED Talks: How Failure Leads to Success",
    "resource_id": 4354,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 57.66
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 3: Quiz",
    "resource_id": 8904,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 2782
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 7834,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 16.92
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 7680,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Johanna Blakley: Social media and the end of gender",
    "resource_id": 7589,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Ingrid Betancourt: What six years in captivity taught me about fear and faith",
    "resource_id": 7804,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 12.5
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 13.75
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L7: Interpret Timelines",
    "resource_id": 10467,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 98
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.75
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Final Exam",
    "resource_id": 6377,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Kandice Sumner: How America's public schools keep kids in poverty",
    "resource_id": 7645,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 14.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Safwat Saleem: Why I keep speaking up, even when people mock my accent",
    "resource_id": 7571,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "01. Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 5181,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 18.58
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 31.16
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167953,
    "user_name": "User167953",
    "first_name": "User",
    "last_name": 167953,
    "doc_id": 12345,
    "learning_object": "01. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5221,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 2.58
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Boaz Almog: The levitating superconductor",
    "resource_id": 7795,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 11.25
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Sarah Kay: If I should have a daughter ...",
    "resource_id": 7550,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.83
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "Zak Ebrahim: I am the son of a terrorist. Here's how I chose peace.",
    "resource_id": 7814,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.33
  },
  {
    "person_key": 166509,
    "user_name": "User166509",
    "first_name": "User",
    "last_name": 166509,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8091,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 33
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Haas&Hahn: How painting can transform communities",
    "resource_id": 7553,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.92
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 10 Essay",
    "resource_id": 6361,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 75.83
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Neha Narula: The future of money",
    "resource_id": 7722,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 7552,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.75
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 7541,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.25
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.83
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Kiran Bedi: A police chief with a difference",
    "resource_id": 7660,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "Native American Healing Traditions",
    "resource_id": 6155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168446,
    "user_name": "User168446",
    "first_name": "User",
    "last_name": 168446,
    "doc_id": 12345,
    "learning_object": "Broke",
    "resource_id": 8464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 20.33
  },
  {
    "person_key": 167798,
    "user_name": "User167798",
    "first_name": "User",
    "last_name": 167798,
    "doc_id": 12345,
    "learning_object": "James Green: 3 moons and a planet that could have alien life",
    "resource_id": 7754,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 2: Quiz",
    "resource_id": 8903,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1435
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "07. Anne Curzan: What makes a word \"real\"?",
    "resource_id": 5187,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 11.08
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Siegfried Woldhek: The search for the true face of Leonardo",
    "resource_id": 7833,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 5.58
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.75
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170059,
    "user_name": "User170059",
    "first_name": "User",
    "last_name": 170059,
    "doc_id": 12345,
    "learning_object": "Lesson 1: Quiz",
    "resource_id": 8782,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1587
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "12. Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 5125,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169556,
    "user_name": "User169556",
    "first_name": "User",
    "last_name": 169556,
    "doc_id": 12345,
    "learning_object": "Chapter 3 Essay",
    "resource_id": 6336,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Daniel Reisel: The neuroscience of restorative justice",
    "resource_id": 7652,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "01. Eric Sanderson: New York -- before the City",
    "resource_id": 5241,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 8.33
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Beau Lotto: Optical illusions show how we see",
    "resource_id": 7790,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 17.33
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Andrés Ruzo: The boiling river of the Amazon",
    "resource_id": 7685,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 15.42
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Ronald Sullivan: How I help free innocent people from prison",
    "resource_id": 7634,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 13.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Euna Lee: What I learned as a prisoner in North Korea",
    "resource_id": 7667,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Elizabeth Loftus: How reliable is your memory?",
    "resource_id": 7792,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 8.58
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Ed Yong: Zombie roaches and other parasite tales",
    "resource_id": 7789,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 21.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 23.75
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.25
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L7: Interpret Timelines - Quiz",
    "resource_id": 10468,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 270
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 2.58
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "T. Morgan Dixon and Vanessa Garrison: When Black women walk, things change",
    "resource_id": 7638,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Kevin Breel: Confessions of a depressed comic",
    "resource_id": 7534,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 11.25
  },
  {
    "person_key": 169847,
    "user_name": "User169847",
    "first_name": "User",
    "last_name": 169847,
    "doc_id": 12345,
    "learning_object": "09. Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 5249,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.33
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Onora O'Neill: What we don't understand about trust",
    "resource_id": 7666,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "04. Angela Lee Duckworth: The key to success? Grit",
    "resource_id": 5143,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 4.42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Siyanda Mohutsiwa: How young Africans found a voice on Twitter",
    "resource_id": 7714,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 6.42
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 11.33
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Clint Smith: The danger of silence",
    "resource_id": 7555,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.75
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 16.5
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 2.08
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 7680,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 8.67
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 11.92
  },
  {
    "person_key": 155842,
    "user_name": "User155842",
    "first_name": "User",
    "last_name": 155842,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 6.42
  },
  {
    "person_key": 169771,
    "user_name": "User169771",
    "first_name": "User",
    "last_name": 169771,
    "doc_id": 12345,
    "learning_object": "CompTIA A+ 220-901: BIOS Motherboards",
    "resource_id": 8226,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.42
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.34
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "02. Elizabeth Gilbert: Your elusive creative genius",
    "resource_id": 5182,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 3.67
  },
  {
    "person_key": 154026,
    "user_name": "User154026",
    "first_name": "User",
    "last_name": 154026,
    "doc_id": 12345,
    "learning_object": "Guillaume Néry: The exhilarating peace of freediving",
    "resource_id": 7704,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Alice Goffman: How we're priming some kids for college — and others for prison",
    "resource_id": 7636,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 30
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Elyn Saks: A tale of mental illness -- from the inside",
    "resource_id": 7548,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 1.58
  },
  {
    "person_key": 165082,
    "user_name": "User165082",
    "first_name": "User",
    "last_name": 165082,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171130,
    "user_name": "User171130",
    "first_name": "User",
    "last_name": 171130,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 29.92
  },
  {
    "person_key": 156090,
    "user_name": "User156090",
    "first_name": "User",
    "last_name": 156090,
    "doc_id": 12345,
    "learning_object": "eL Seed: Street art with a message of hope and peace",
    "resource_id": 7563,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.67
  },
  {
    "person_key": 157047,
    "user_name": "User157047",
    "first_name": "User",
    "last_name": 157047,
    "doc_id": 12345,
    "learning_object": "iO Tillett Wright: Fifty shades of gay",
    "resource_id": 7592,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-17",
    "time_spent_min": 6.5
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Movement on Maps (CS) - Quiz",
    "resource_id": 10448,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 4214
  },
  {
    "person_key": 171118,
    "user_name": "User171118",
    "first_name": "User",
    "last_name": 171118,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 2",
    "resource_id": 7988,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 46.08
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.5
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 44.58
  },
  {
    "person_key": 171128,
    "user_name": "User171128",
    "first_name": "User",
    "last_name": 171128,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 37
  },
  {
    "person_key": 171121,
    "user_name": "User171121",
    "first_name": "User",
    "last_name": 171121,
    "doc_id": 12345,
    "learning_object": "DOC Academy - Safe Crisis Management Exam - Thursday",
    "resource_id": 4466,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171113,
    "user_name": "User171113",
    "first_name": "User",
    "last_name": 171113,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 34.58
  },
  {
    "person_key": 171134,
    "user_name": "User171134",
    "first_name": "User",
    "last_name": 171134,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171126,
    "user_name": "User171126",
    "first_name": "User",
    "last_name": 171126,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 38.08
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "30 for 30 GRVC",
    "resource_id": 4581,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.33
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 26.59
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 29.42
  },
  {
    "person_key": 169438,
    "user_name": "User169438",
    "first_name": "User",
    "last_name": 169438,
    "doc_id": 12345,
    "learning_object": "Unit 1: Managerial Accounting Concepts and Principles",
    "resource_id": 7953,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 2.08
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2-Sharp Tools Are underappreciated",
    "resource_id": 7917,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8
  },
  {
    "person_key": 171132,
    "user_name": "User171132",
    "first_name": "User",
    "last_name": 171132,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 2.25
  },
  {
    "person_key": 171115,
    "user_name": "User171115",
    "first_name": "User",
    "last_name": 171115,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 3.41
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 2",
    "resource_id": 8127,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 5.83
  },
  {
    "person_key": 261,
    "user_name": "User261",
    "first_name": "User",
    "last_name": 261,
    "doc_id": 12345,
    "learning_object": "Lesson Two/Domain 1: Employment-Education",
    "resource_id": 8064,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 171136,
    "user_name": "User171136",
    "first_name": "User",
    "last_name": 171136,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 39.17
  },
  {
    "person_key": 171124,
    "user_name": "User171124",
    "first_name": "User",
    "last_name": 171124,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 35
  },
  {
    "person_key": 171121,
    "user_name": "User171121",
    "first_name": "User",
    "last_name": 171121,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 39.42
  },
  {
    "person_key": 171136,
    "user_name": "User171136",
    "first_name": "User",
    "last_name": 171136,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.42
  },
  {
    "person_key": 159471,
    "user_name": "User159471",
    "first_name": "User",
    "last_name": 159471,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 171122,
    "user_name": "User171122",
    "first_name": "User",
    "last_name": 171122,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 9.75
  },
  {
    "person_key": 171135,
    "user_name": "User171135",
    "first_name": "User",
    "last_name": 171135,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 171124,
    "user_name": "User171124",
    "first_name": "User",
    "last_name": 171124,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.33
  },
  {
    "person_key": 171132,
    "user_name": "User171132",
    "first_name": "User",
    "last_name": 171132,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 5 - Communicating with Your Athletes",
    "resource_id": 7031,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 4.67
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 2.75
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 73.42
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "MGMT 3377 - Entrepreneurship",
    "resource_id": 4302,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.75
  },
  {
    "person_key": 159648,
    "user_name": "User159648",
    "first_name": "User",
    "last_name": 159648,
    "doc_id": 12345,
    "learning_object": "David Casarett: A doctor's case for medical marijuana",
    "resource_id": 7626,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 13.42
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "11. Ben Saunders: To the South Pole and back — the hardest 105 days of my life",
    "resource_id": 5124,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 19
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "01. Dan Gilbert: Why we make bad decisions",
    "resource_id": 5052,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.25
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "07. Edith Widder: How we found the giant squid",
    "resource_id": 5120,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.5
  },
  {
    "person_key": 171129,
    "user_name": "User171129",
    "first_name": "User",
    "last_name": 171129,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "02. Beau Lotto: Optical illusions show how we see",
    "resource_id": 5204,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 17.75
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "05. Louie Schwartzberg: Nature. Beauty. Gratitude.",
    "resource_id": 5118,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 18.17
  },
  {
    "person_key": 171130,
    "user_name": "User171130",
    "first_name": "User",
    "last_name": 171130,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 35.08
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.67
  },
  {
    "person_key": 171130,
    "user_name": "User171130",
    "first_name": "User",
    "last_name": 171130,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "PHED 3385",
    "resource_id": 4536,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 36
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 4",
    "resource_id": 8173,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 4.92
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Rich Benjamin: My road trip through the whitest towns in America",
    "resource_id": 7562,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 14.5
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 3 - Victory with Honor",
    "resource_id": 6518,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 11.83
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Misha Glenny: Hire the hackers!",
    "resource_id": 7723,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156907,
    "user_name": "User156907",
    "first_name": "User",
    "last_name": 156907,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 21.75
  },
  {
    "person_key": 171133,
    "user_name": "User171133",
    "first_name": "User",
    "last_name": 171133,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 160246,
    "user_name": "User160246",
    "first_name": "User",
    "last_name": 160246,
    "doc_id": 12345,
    "learning_object": "12. Daniel Kahneman: The riddle of experience vs. memory",
    "resource_id": 5090,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Anna M. Kross Center",
    "location_id": 1697441,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.75
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "12. Laurie Garrett: Lessons from the 1918 flu",
    "resource_id": 5252,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 21.08
  },
  {
    "person_key": 169567,
    "user_name": "User169567",
    "first_name": "User",
    "last_name": 169567,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 12.83
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Joshua Walters: On being just crazy enough",
    "resource_id": 7539,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 3.5
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 5",
    "resource_id": 7991,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 18.42
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 23.83
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "07. Michael Shermer: The pattern behind self-deception",
    "resource_id": 5209,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Sanford Biggers: An artist's unflinching look at racial violence",
    "resource_id": 7544,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 18.83
  },
  {
    "person_key": 156907,
    "user_name": "User156907",
    "first_name": "User",
    "last_name": 156907,
    "doc_id": 12345,
    "learning_object": "01. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5221,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 7.5
  },
  {
    "person_key": 171121,
    "user_name": "User171121",
    "first_name": "User",
    "last_name": 171121,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Aimee Mullins: The opportunity of adversity",
    "resource_id": 7816,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 29.92
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.92
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 10.83
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 5.75
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 3",
    "resource_id": 7989,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 43.25
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Keren Elazari: Hackers: the Internet's immune system",
    "resource_id": 7724,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 17.33
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 5",
    "resource_id": 8174,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 5.5
  },
  {
    "person_key": 156907,
    "user_name": "User156907",
    "first_name": "User",
    "last_name": 156907,
    "doc_id": 12345,
    "learning_object": "07. Aimee Mullins: The opportunity of adversity",
    "resource_id": 5227,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 3.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "TED Talks 2019: All Kinds of Minds",
    "resource_id": 4324,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "08. Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 4985,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 21.25
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.58
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "11. JD Schramm: Break the silence for suicide attempt survivors",
    "resource_id": 4966,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.67
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "06. Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 5030,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "10. Paul Nicklen: Tales of ice-bound wonderlands",
    "resource_id": 5123,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 11.08
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "06. Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 5119,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.58
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "David Casarett: A doctor's case for medical marijuana",
    "resource_id": 7626,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.92
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Susan Pinker: The secret to living longer may be your social life",
    "resource_id": 7641,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 18.42
  },
  {
    "person_key": 171129,
    "user_name": "User171129",
    "first_name": "User",
    "last_name": 171129,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 12.25
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 16.08
  },
  {
    "person_key": 167152,
    "user_name": "User167152",
    "first_name": "User",
    "last_name": 167152,
    "doc_id": 12345,
    "learning_object": "Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 7580,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming Medium Correctional Institution",
    "location_id": 1697397,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 5.83
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Rebecca Brachman: Could a drug prevent depression and PTSD?",
    "resource_id": 7619,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 20.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Stella Young: I'm not your inspiration, thank you very much",
    "resource_id": 7584,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "01. Chimamanda Ngozi Adichie: The danger of a single story",
    "resource_id": 5181,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 4
  },
  {
    "person_key": 169446,
    "user_name": "User169446",
    "first_name": "User",
    "last_name": 169446,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 7",
    "resource_id": 8176,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.75
  },
  {
    "person_key": 171128,
    "user_name": "User171128",
    "first_name": "User",
    "last_name": 171128,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156907,
    "user_name": "User156907",
    "first_name": "User",
    "last_name": 156907,
    "doc_id": 12345,
    "learning_object": "02. Alice Dreger: Is anatomy destiny?",
    "resource_id": 5004,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.58
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 89.92
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Euna Lee: What I learned as a prisoner in North Korea",
    "resource_id": 7667,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 12.83
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 4.67
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.08
  },
  {
    "person_key": 155849,
    "user_name": "User155849",
    "first_name": "User",
    "last_name": 155849,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 11.67
  },
  {
    "person_key": 171127,
    "user_name": "User171127",
    "first_name": "User",
    "last_name": 171127,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 6
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 7.58
  },
  {
    "person_key": 158019,
    "user_name": "User158019",
    "first_name": "User",
    "last_name": 158019,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.33
  },
  {
    "person_key": 166588,
    "user_name": "User166588",
    "first_name": "User",
    "last_name": 166588,
    "doc_id": 12345,
    "learning_object": "Geena Rocero: Why I must come out",
    "resource_id": 7585,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.58
  },
  {
    "person_key": 169567,
    "user_name": "User169567",
    "first_name": "User",
    "last_name": 169567,
    "doc_id": 12345,
    "learning_object": "Unit 3- Policing Issues",
    "resource_id": 7437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.41
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "06. AJ Jacobs: How healthy living nearly killed me",
    "resource_id": 4983,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.58
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "04. Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 5206,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 46.25
  },
  {
    "person_key": 162666,
    "user_name": "User162666",
    "first_name": "User",
    "last_name": 162666,
    "doc_id": 12345,
    "learning_object": "Aomawa Shields: How we'll find life on other planets",
    "resource_id": 7730,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.17
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 1",
    "resource_id": 7987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 6
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 18.59
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "TED Talks: The Most Amazing Facts",
    "resource_id": 4305,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "03. Brian Greene: Is our universe the only universe?",
    "resource_id": 5205,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 27.5
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 1:  Strategic Human Resources Management",
    "resource_id": 7472,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.92
  },
  {
    "person_key": 171135,
    "user_name": "User171135",
    "first_name": "User",
    "last_name": 171135,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 6.92
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 14.58
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 22.92
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 2 - Developing Your Coaching Objectives",
    "resource_id": 6517,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 15.75
  },
  {
    "person_key": 159648,
    "user_name": "User159648",
    "first_name": "User",
    "last_name": 159648,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 16.25
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "11. Jamie Oliver: Teach every child about food",
    "resource_id": 4988,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 30
  },
  {
    "person_key": 171116,
    "user_name": "User171116",
    "first_name": "User",
    "last_name": 171116,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 13.83
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "09. Cesar Kuriyama: One second every day",
    "resource_id": 4974,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 6.25
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "11. Anupam Mishra: The ancient ingenuity of water harvesting",
    "resource_id": 5251,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 17.33
  },
  {
    "person_key": 156907,
    "user_name": "User156907",
    "first_name": "User",
    "last_name": 156907,
    "doc_id": 12345,
    "learning_object": "06. Jill Bolte Taylor: My stroke of insight",
    "resource_id": 5226,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.17
  },
  {
    "person_key": 171120,
    "user_name": "User171120",
    "first_name": "User",
    "last_name": 171120,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 40.5
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "03. Faith Jegede: What I've learned from my autistic brothers",
    "resource_id": 5062,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.33
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "04. Rachel Sussman: The world's oldest living things",
    "resource_id": 5117,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 20.92
  },
  {
    "person_key": 171116,
    "user_name": "User171116",
    "first_name": "User",
    "last_name": 171116,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "08. George Takei: Why I love a country that once betrayed me",
    "resource_id": 5248,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 16
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "Nagin Cox: What time is it on Mars?",
    "resource_id": 7749,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Civics 101",
    "resource_id": 4699,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.33
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 81.17
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 2.42
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "18. Dan Cobley: What physics taught me about marketing",
    "resource_id": 5220,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.92
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Alix Generous: How I learned to communicate my inner life with Asperger's",
    "resource_id": 7537,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.58
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 1-Dealing With Dirty Words",
    "resource_id": 7925,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 22.25
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 12.33
  },
  {
    "person_key": 167953,
    "user_name": "User167953",
    "first_name": "User",
    "last_name": 167953,
    "doc_id": 12345,
    "learning_object": "01. Dan Pacholke: How prisons can help inmates live meaningful lives",
    "resource_id": 5070,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 27.67
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "09. George Monbiot: For more wonder, rewild the world",
    "resource_id": 5122,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 5.42
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "CRIJ 2332: Intro to Correction",
    "resource_id": 4702,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156907,
    "user_name": "User156907",
    "first_name": "User",
    "last_name": 156907,
    "doc_id": 12345,
    "learning_object": "06. Amy Webb: How I hacked online dating",
    "resource_id": 5065,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 9.75
  },
  {
    "person_key": 171127,
    "user_name": "User171127",
    "first_name": "User",
    "last_name": 171127,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.33
  },
  {
    "person_key": 159648,
    "user_name": "User159648",
    "first_name": "User",
    "last_name": 159648,
    "doc_id": 12345,
    "learning_object": "Thordis Elva and Tom Stranger: Our story of rape and reconciliation",
    "resource_id": 7820,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 13
  },
  {
    "person_key": 162565,
    "user_name": "User162565",
    "first_name": "User",
    "last_name": 162565,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 15.75
  },
  {
    "person_key": 161009,
    "user_name": "User161009",
    "first_name": "User",
    "last_name": 161009,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "03. Frans Lanting: Photos that give voice to the animal kingdom",
    "resource_id": 5116,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 3.92
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "12. Eddy Cartaya: My glacier cave discoveries",
    "resource_id": 5125,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 10.25
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 14.92
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 3",
    "resource_id": 8172,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 14.42
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.92
  },
  {
    "person_key": 167087,
    "user_name": "User167087",
    "first_name": "User",
    "last_name": 167087,
    "doc_id": 12345,
    "learning_object": "11. Ben Saunders: To the South Pole and back — the hardest 105 days of my life",
    "resource_id": 5124,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "8A",
    "location_id": 1697457,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 14.67
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "01. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5060,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 9.92
  },
  {
    "person_key": 171115,
    "user_name": "User171115",
    "first_name": "User",
    "last_name": 171115,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 3.67
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "01. Amy Cuddy: Your body language shapes who you are",
    "resource_id": 5126,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 25.83
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "02. Andrew Solomon: Love, no matter what",
    "resource_id": 5061,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Oliver Sacks: What hallucination reveals about our minds",
    "resource_id": 7554,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 30.42
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "08. Stephen Hawking: Questioning the universe",
    "resource_id": 5210,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.5
  },
  {
    "person_key": 171118,
    "user_name": "User171118",
    "first_name": "User",
    "last_name": 171118,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.83
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 7564,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 4 - Coaching Philosophy",
    "resource_id": 6987,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.58
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "AJ Jacobs: The world's largest family reunion ... we're all invited!",
    "resource_id": 7597,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 156907,
    "user_name": "User156907",
    "first_name": "User",
    "last_name": 156907,
    "doc_id": 12345,
    "learning_object": "12. Jill Tarter: Join the SETI search",
    "resource_id": 5214,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.92
  },
  {
    "person_key": 167953,
    "user_name": "User167953",
    "first_name": "User",
    "last_name": 167953,
    "doc_id": 12345,
    "learning_object": "Workplace Safety Orientation",
    "resource_id": 8094,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 15.42
  },
  {
    "person_key": 167953,
    "user_name": "User167953",
    "first_name": "User",
    "last_name": 167953,
    "doc_id": 12345,
    "learning_object": "Writing Effective Emails and Instant Messages",
    "resource_id": 8255,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.25
  },
  {
    "person_key": 156907,
    "user_name": "User156907",
    "first_name": "User",
    "last_name": 156907,
    "doc_id": 12345,
    "learning_object": "04. Ziauddin Yousafzai: My daughter, Malala",
    "resource_id": 5006,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.75
  },
  {
    "person_key": 171134,
    "user_name": "User171134",
    "first_name": "User",
    "last_name": 171134,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 5.75
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 5.41
  },
  {
    "person_key": 171126,
    "user_name": "User171126",
    "first_name": "User",
    "last_name": 171126,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Student Book - L3 Squaring, Cubing, and Taking Roots - Quiz",
    "resource_id": 10279,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 185
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Workbook - L10 Solving and Graphing Inequalities",
    "resource_id": 10345,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1807
  },
  {
    "person_key": 169962,
    "user_name": "User169962",
    "first_name": "User",
    "last_name": 169962,
    "doc_id": 12345,
    "learning_object": "Student Book - U2 L1: Relate Geography and History",
    "resource_id": 10451,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 3612
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Workbook - L4: Political Maps (CS)",
    "resource_id": 10446,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 2450
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Movement on Maps (CS)",
    "resource_id": 10447,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 143
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Student Book - U1 L1 Whole Numbers (CS)",
    "resource_id": 9863,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 6748
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Workbook - L1 Whole Numbers (CS)",
    "resource_id": 9860,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1164
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Student Book - U1 L1 Whole Numbers (CS) - Quiz",
    "resource_id": 9864,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 368
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Summative Assessment - Science Simulated Test",
    "resource_id": 10433,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 12
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Science Formative Assessment",
    "resource_id": 9910,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 19
  },
  {
    "person_key": 170379,
    "user_name": "User170379",
    "first_name": "User",
    "last_name": 170379,
    "doc_id": 12345,
    "learning_object": "Reference - Study Skills",
    "resource_id": 9877,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 2
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Part 2: Summative Assessment",
    "resource_id": 10362,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 12945
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Part 1: Summative Assessment",
    "resource_id": 10344,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 401
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Reference - Mathematics Formative Assessment Performance Analysis Chart",
    "resource_id": 9924,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 7
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Part 2: Formative Assessment",
    "resource_id": 9862,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 5127
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Student Book - L6 Decimals (CS)",
    "resource_id": 10108,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 32
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Student Book - L7 Percent (CS)",
    "resource_id": 9893,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 12
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Student Book - L6 Decimals (CS) - Quiz",
    "resource_id": 10105,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 629
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Workbook - L6 Decimals",
    "resource_id": 10109,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 998
  },
  {
    "person_key": 170424,
    "user_name": "User170424",
    "first_name": "User",
    "last_name": 170424,
    "doc_id": 12345,
    "learning_object": "Student Book - L7 Percent (CS) - Quiz",
    "resource_id": 10119,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 2
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L3: Main Idea and Details",
    "resource_id": 10455,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 958
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 2.5
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Workbook - L6: Cause and Effect (CS)",
    "resource_id": 10466,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 6622
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Zaria Forman: Drawings that show the beauty and fragility of Earth",
    "resource_id": 7683,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.25
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "13. Rajesh Rao: A Rosetta Stone for a lost language",
    "resource_id": 5253,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 17.17
  },
  {
    "person_key": 168585,
    "user_name": "User168585",
    "first_name": "User",
    "last_name": 168585,
    "doc_id": 12345,
    "learning_object": "CompTIA Security+ SY0-501: The Present Threat Landscape",
    "resource_id": 8113,
    "content_area": "Workforce Skills",
    "content_id": 5,
    "housing_area": "7B",
    "location_id": 1697456,
    "service_name": "Skillsoft",
    "service_id": 118,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.67
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "01. The LXD: In the Internet age, dance evolves ...",
    "resource_id": 4957,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 3.83
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "30 for 30 NIC",
    "resource_id": 4700,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.42
  },
  {
    "person_key": 159648,
    "user_name": "User159648",
    "first_name": "User",
    "last_name": 159648,
    "doc_id": 12345,
    "learning_object": "TED Talks 2019: What Makes Us Stronger",
    "resource_id": 4429,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 167152,
    "user_name": "User167152",
    "first_name": "User",
    "last_name": 167152,
    "doc_id": 12345,
    "learning_object": "Rachel Sussman: The world's oldest living things",
    "resource_id": 7682,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming Medium Correctional Institution",
    "location_id": 1697397,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.25
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "14. Suzanne Talhouk: Don't kill your language",
    "resource_id": 5254,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 3.17
  },
  {
    "person_key": 169518,
    "user_name": "User169518",
    "first_name": "User",
    "last_name": 169518,
    "doc_id": 12345,
    "learning_object": "Unit 6",
    "resource_id": 8175,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 5.42
  },
  {
    "person_key": 171120,
    "user_name": "User171120",
    "first_name": "User",
    "last_name": 171120,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "02. Simon Sinek: How great leaders inspire action",
    "resource_id": 5127,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 29.92
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "A Deadly Game Audiobook",
    "resource_id": 5795,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.17
  },
  {
    "person_key": 167152,
    "user_name": "User167152",
    "first_name": "User",
    "last_name": 167152,
    "doc_id": 12345,
    "learning_object": "Suzanne Simard: How trees talk to each other",
    "resource_id": 7687,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming Medium Correctional Institution",
    "location_id": 1697397,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 5
  },
  {
    "person_key": 161009,
    "user_name": "User161009",
    "first_name": "User",
    "last_name": 161009,
    "doc_id": 12345,
    "learning_object": "01. Paul Piff: Does money make you mean?",
    "resource_id": 5104,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.83
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "The Water's Edge Audiobook",
    "resource_id": 5773,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 20.25
  },
  {
    "person_key": 171123,
    "user_name": "User171123",
    "first_name": "User",
    "last_name": 171123,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 5
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 154406,
    "user_name": "User154406",
    "first_name": "User",
    "last_name": 154406,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Temple Grandin: The world needs all kinds of minds",
    "resource_id": 4948,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169567,
    "user_name": "User169567",
    "first_name": "User",
    "last_name": 169567,
    "doc_id": 12345,
    "learning_object": "Unit 1:  Monitoring the Future:  National Survey Result on Drug Use 1975-2016",
    "resource_id": 7527,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 8.42
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 31.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "01. David Gallo: Underwater astonishments",
    "resource_id": 5114,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "AJ Jacobs: How healthy living nearly killed me",
    "resource_id": 7618,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 9.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: Depression, the secret we share",
    "resource_id": 7536,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 30
  },
  {
    "person_key": 156907,
    "user_name": "User156907",
    "first_name": "User",
    "last_name": 156907,
    "doc_id": 12345,
    "learning_object": "08. Sue Austin: Deep sea diving ... in a wheelchair",
    "resource_id": 5228,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.58
  },
  {
    "person_key": 164041,
    "user_name": "User164041",
    "first_name": "User",
    "last_name": 164041,
    "doc_id": 12345,
    "learning_object": "A Horse Called Courage Audiobook",
    "resource_id": 5796,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center Young Adult",
    "location_id": 1697396,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.58
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "David Casarett: A doctor's case for medical marijuana",
    "resource_id": 7626,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 15.92
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "30 for 30 OBCC",
    "resource_id": 4638,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.58
  },
  {
    "person_key": 171133,
    "user_name": "User171133",
    "first_name": "User",
    "last_name": 171133,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6029,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 30
  },
  {
    "person_key": 167152,
    "user_name": "User167152",
    "first_name": "User",
    "last_name": 167152,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming Medium Correctional Institution",
    "location_id": 1697397,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 20.42
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 1.17
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169396,
    "user_name": "User169396",
    "first_name": "User",
    "last_name": 169396,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction to Physical Sciences",
    "resource_id": 7464,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160025,
    "user_name": "User160025",
    "first_name": "User",
    "last_name": 160025,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.08
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "09. Ben Goldacre: Battling bad science",
    "resource_id": 5211,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 2.83
  },
  {
    "person_key": 171113,
    "user_name": "User171113",
    "first_name": "User",
    "last_name": 171113,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6025,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 0.33
  },
  {
    "person_key": 160807,
    "user_name": "User160807",
    "first_name": "User",
    "last_name": 160807,
    "doc_id": 12345,
    "learning_object": "Unit 6 - Motivation in Sport",
    "resource_id": 7065,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-16",
    "time_spent_min": 22
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Johanna Blakley: Social media and the end of gender",
    "resource_id": 7589,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 8.75
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Paul Nicklen: Animal tales from icy wonderlands",
    "resource_id": 7699,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 154239,
    "user_name": "User154239",
    "first_name": "User",
    "last_name": 154239,
    "doc_id": 12345,
    "learning_object": "${date}",
    "resource_id": 8524,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "ENGL 1301",
    "resource_id": 4698,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "T. Morgan Dixon and Vanessa Garrison: When Black women walk, things change",
    "resource_id": 7638,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "08. Jessica Jackley: Poverty, money -- and love",
    "resource_id": 5111,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.17
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "SPAN/FREN 2301 - Elementary Spanish/Elementary French I",
    "resource_id": 4496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 11.58
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "01. Eric Sanderson: New York -- before the City",
    "resource_id": 5241,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 8
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Julian Treasure: How to speak so that people want to listen",
    "resource_id": 7765,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Lee Mokobe: A powerful poem about what it feels like to be transgender",
    "resource_id": 7578,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 19.75
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: How the worst moments in our lives make us who we are",
    "resource_id": 7818,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.25
  },
  {
    "person_key": 166912,
    "user_name": "User166912",
    "first_name": "User",
    "last_name": 166912,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 5.5
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Morgana Bailey: The danger of hiding who you are",
    "resource_id": 7582,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.17
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Amy Cuddy: Your body language may shape who you are",
    "resource_id": 7769,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.58
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 29.42
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Aimee Mullins: The opportunity of adversity",
    "resource_id": 7816,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.5
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Alicia Garza, Patrisse Cullors and Opal Tometi: An interview with the founders of Black Lives Matter",
    "resource_id": 7640,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 4.5
  },
  {
    "person_key": 167268,
    "user_name": "User167268",
    "first_name": "User",
    "last_name": 167268,
    "doc_id": 12345,
    "learning_object": "07. Oliver Sacks: What hallucination reveals about our minds",
    "resource_id": 4962,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 18.75
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "Agreement #4",
    "resource_id": 8089,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 2.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Karen Armstrong: Let's revive the Golden Rule",
    "resource_id": 7807,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.75
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Latif Nasser: You have no idea where camels really come from",
    "resource_id": 7614,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.33
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.58
  },
  {
    "person_key": 167260,
    "user_name": "User167260",
    "first_name": "User",
    "last_name": 167260,
    "doc_id": 12345,
    "learning_object": "09. Sheryl Sandberg: Why we have too few women leaders",
    "resource_id": 5011,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Megan Washington: Why I live in mortal dread of public speaking",
    "resource_id": 7768,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 5.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Cristina Domenech: Poetry that frees the soul",
    "resource_id": 7545,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Josh Luber: Why sneakers are a great investment",
    "resource_id": 7695,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 12.59
  },
  {
    "person_key": 164324,
    "user_name": "User164324",
    "first_name": "User",
    "last_name": 164324,
    "doc_id": 12345,
    "learning_object": "Adam Foss: A prosecutor's vision for a better justice system",
    "resource_id": 7632,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.67
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "07. Lemon Andersen: Please don't take my Air Jordans",
    "resource_id": 4972,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 4.08
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "01. David Christian: The history of our world in 18 minutes",
    "resource_id": 5203,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 17.67
  },
  {
    "person_key": 169369,
    "user_name": "User169369",
    "first_name": "User",
    "last_name": 169369,
    "doc_id": 12345,
    "learning_object": "Unit 3 - Victory with Honor",
    "resource_id": 6518,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 6
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 1. El español en el mundo",
    "resource_id": 8155,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 4.92
  },
  {
    "person_key": 169567,
    "user_name": "User169567",
    "first_name": "User",
    "last_name": 169567,
    "doc_id": 12345,
    "learning_object": "Unit 1:  Monitoring the Future:  National Survey Result on Drug Use 1975-2016",
    "resource_id": 7527,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 4
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Nalini Nadkarni: Life science in prison",
    "resource_id": 7658,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "David Christian: The history of our world in 18 minutes",
    "resource_id": 7788,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 11.17
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: How the worst moments in our lives make us who we are",
    "resource_id": 7818,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 16.58
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Rebecca Brachman: Could a drug prevent depression and PTSD?",
    "resource_id": 7619,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.92
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "05. McKenna Pope: Want to be an activist? Start with your toys",
    "resource_id": 5007,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.83
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "01. Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 5060,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 30
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.08
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "17. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5157,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.25
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Stella Young: I'm not your inspiration, thank you very much",
    "resource_id": 7584,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.58
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 4: The State of the United States' Correctional System",
    "resource_id": 7510,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 3- Policing Issues",
    "resource_id": 7437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 58
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Sarah Jones: One woman, five characters, and a sex lesson from the future",
    "resource_id": 7599,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.17
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "05. Sarah Kay: If I should have a daughter ...",
    "resource_id": 4970,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.5
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.83
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "02. Elizabeth Gilbert: Your elusive creative genius",
    "resource_id": 5182,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 29.92
  },
  {
    "person_key": 163381,
    "user_name": "User163381",
    "first_name": "User",
    "last_name": 163381,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 45.83
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 5: Probation, Parole, Other sanctions and Reentry",
    "resource_id": 7511,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "02. Bryan Stevenson: We need to talk about an injustice",
    "resource_id": 5071,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 21.58
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 12
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Susan Pinker: The secret to living longer may be your social life",
    "resource_id": 7641,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.67
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Alice Goffman: How we're priming some kids for college — and others for prison",
    "resource_id": 7636,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.67
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "ENGL 2311",
    "resource_id": 4506,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 7609,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.58
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "Aimee Mullins: The opportunity of adversity",
    "resource_id": 7816,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 29.92
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 79.5
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 45.25
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Mary Bassett: Why your doctor should care about social justice",
    "resource_id": 7639,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.58
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "04. Module 1: Fundamentals of Environmental Literacy",
    "resource_id": 4931,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 95.67
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Brené Brown: Listening to shame",
    "resource_id": 7824,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Clint Smith: How to raise a black son in America",
    "resource_id": 7643,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 6
  },
  {
    "person_key": 170941,
    "user_name": "User170941",
    "first_name": "User",
    "last_name": 170941,
    "doc_id": 12345,
    "learning_object": "Part 1 - Safe Crisis Management Exam - START HERE",
    "resource_id": 6021,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Brian Little: Who are you, really? The puzzle of personality",
    "resource_id": 7742,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 2.42
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Camille A. Brown: A visual history of social dance in 25 moves",
    "resource_id": 7546,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 5.42
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Keren Elazari: Hackers: the Internet's immune system",
    "resource_id": 7724,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 19.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Carol Dweck: The power of believing that you can improve",
    "resource_id": 7748,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169369,
    "user_name": "User169369",
    "first_name": "User",
    "last_name": 169369,
    "doc_id": 12345,
    "learning_object": "Unit 2 - Developing Your Coaching Objectives",
    "resource_id": 6517,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 14.92
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "My Name",
    "resource_id": 8368,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 2.58
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "The Psychology of Incarceration",
    "resource_id": 4725,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 7625,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.67
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Rishi Manchanda: What makes us get sick? Look upstream",
    "resource_id": 7617,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.25
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Guillaume Néry: The exhilarating peace of freediving",
    "resource_id": 7704,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.67
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "09. Alexis Ohanian: How to make a splash in social media",
    "resource_id": 5173,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.5
  },
  {
    "person_key": 157588,
    "user_name": "User157588",
    "first_name": "User",
    "last_name": 157588,
    "doc_id": 12345,
    "learning_object": "Start Here",
    "resource_id": 6838,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 6.25
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Onora O'Neill: What we don't understand about trust",
    "resource_id": 7666,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 261,
    "user_name": "User261",
    "first_name": "User",
    "last_name": 261,
    "doc_id": 12345,
    "learning_object": "Lesson Three/Domain 2: Marital-Family Relations",
    "resource_id": 8065,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 30.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 4. ¿Cómo te llamas?",
    "resource_id": 8158,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.5
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "08. Haas&Hahn: How painting can transform communities",
    "resource_id": 4973,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 8.75
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.83
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Week One Unit 1: Welcome to English 2311 World Literature",
    "resource_id": 6504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.75
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "04. Barry Schwartz: The paradox of choice",
    "resource_id": 5055,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 19.83
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Salil Dudani: How jails extort the poor",
    "resource_id": 7655,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 15
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "02. Andrew Solomon: Love, no matter what",
    "resource_id": 5061,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Monica Lewinsky: The price of shame",
    "resource_id": 7741,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 26.08
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "04. Arthur Benjamin: A performance of \"Mathemagic",
    "resource_id": 5094,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 16.25
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "J.J. Abrams: The mystery box",
    "resource_id": 7606,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.67
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Daniel Reisel: The neuroscience of restorative justice",
    "resource_id": 7652,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.25
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "07. Anne Curzan: What makes a word \"real\"?",
    "resource_id": 5187,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.92
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "07. Geena Rocero: Why I must come out",
    "resource_id": 5009,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 6.67
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "04. Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 5206,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1
  },
  {
    "person_key": 167268,
    "user_name": "User167268",
    "first_name": "User",
    "last_name": 167268,
    "doc_id": 12345,
    "learning_object": "2.1 U.S. HISTORY : Fighting World War II",
    "resource_id": 4794,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Kang Lee: Can you really tell if a kid is lying?",
    "resource_id": 7747,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "08. Erin McKean: Go ahead, make up new words!",
    "resource_id": 5188,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Lisa Genova: What you can do to prevent Alzheimer's",
    "resource_id": 7611,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "09. Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 5189,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 2.5
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "No Mas",
    "resource_id": 8087,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 39.92
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Zak Ebrahim: I am the son of a terrorist. Here's how I chose peace.",
    "resource_id": 7814,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.83
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 2- Policing: The Legal Aspect",
    "resource_id": 7436,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.83
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "14. Hans and Ola Rosling: How not to be ignorant about the world",
    "resource_id": 5039,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 12
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.5
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "07. Jared Diamond: Why do societies collapse?",
    "resource_id": 5247,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 18.17
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Simon Sinek: How great leaders inspire action",
    "resource_id": 7758,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 23.33
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "Michael Dickinson: How a fly flies",
    "resource_id": 7796,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 15
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Adam Galinsky: How to speak up for yourself",
    "resource_id": 7759,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 12.25
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "10. Clint Smith: The danger of silence",
    "resource_id": 4975,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "Tristram Wyatt: The smelly mystery of the human pheromone",
    "resource_id": 7791,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 14.83
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "09. Jennifer Senior: For parents, happiness is a very high bar",
    "resource_id": 5068,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 6.17
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "Agreement #3",
    "resource_id": 8088,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 2.08
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 1: Introduction, The Building Blocks of Language, and The Writing Process",
    "resource_id": 6413,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Celeste Headlee: 10 ways to have a better conversation",
    "resource_id": 7770,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 2.17
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Ashley Judd: How online abuse of women has spiraled out of control",
    "resource_id": 7577,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 8.75
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "05. William Noel: Revealing the lost codex of Archimedes",
    "resource_id": 5245,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 29.25
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Rose George: Let's talk crap. Seriously.",
    "resource_id": 7635,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 30
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Sue Austin: Deep sea diving ... in a wheelchair",
    "resource_id": 7817,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.42
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 4- Law Enforcement in Action",
    "resource_id": 7438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 35.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.33
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Jill Heinerth: The mysterious world of underwater caves",
    "resource_id": 7798,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.08
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Karoliina Korppoo: How a video game might help us build better cities",
    "resource_id": 7613,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 2.17
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "12. Christopher McDougall: Are we born to run?",
    "resource_id": 4989,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 17.92
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "The LXD: In the Internet age, dance evolves ...",
    "resource_id": 7540,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Kandice Sumner: How America's public schools keep kids in poverty",
    "resource_id": 7645,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 5.83
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "04. Mick Ebeling: The invention that unlocked a locked-in artist",
    "resource_id": 4969,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 8.08
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "04. Malcolm Gladwell: The strange tale of the Norden bombsight",
    "resource_id": 5244,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 14.75
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Sharon Brous: It's time to reclaim religion",
    "resource_id": 7803,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 3",
    "resource_id": 7986,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 62.83
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.33
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Elizabeth Gilbert: Success, failure and the drive to keep creating",
    "resource_id": 7603,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Safwat Saleem: Why I keep speaking up, even when people mock my accent",
    "resource_id": 7571,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "03. Shane Koyczan: To This Day ... for the bullied and beautiful",
    "resource_id": 4968,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 25.25
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "Unit 5- Intro to Private and Homeland Security",
    "resource_id": 7439,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 27.75
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "Beau Lotto: Optical illusions show how we see",
    "resource_id": 7790,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 20.67
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Melissa Marshall: Talk nerdy to me",
    "resource_id": 7771,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.83
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "07. Gayle Tzemach Lemmon: Women entrepreneurs, example not exception",
    "resource_id": 5198,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 5.25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 22.25
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "02. Siegfried Woldhek: The search for the true face of Leonardo",
    "resource_id": 5242,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 6.17
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Kaki King: A musical escape into a world of light and color",
    "resource_id": 7558,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 6.25
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Workbook - L4 Fractions",
    "resource_id": 9916,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 905
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Student Book - L4 Fractions",
    "resource_id": 9894,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 49
  },
  {
    "person_key": 170361,
    "user_name": "User170361",
    "first_name": "User",
    "last_name": 170361,
    "doc_id": 12345,
    "learning_object": "Workbook - L3 Integers (CS)",
    "resource_id": 9939,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 11742
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 2. El alfabeto",
    "resource_id": 8156,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.66
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "11. Terry Moore: Why is 'x' the unknown?",
    "resource_id": 5102,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 4.17
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "10. Edward Tenner: Unintended consequences",
    "resource_id": 5250,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169302,
    "user_name": "User169302",
    "first_name": "User",
    "last_name": 169302,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 29.92
  },
  {
    "person_key": 167268,
    "user_name": "User167268",
    "first_name": "User",
    "last_name": 167268,
    "doc_id": 12345,
    "learning_object": "2.1 U.S. HISTORY : Origins of World War II",
    "resource_id": 4791,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170417,
    "user_name": "User170417",
    "first_name": "User",
    "last_name": 170417,
    "doc_id": 12345,
    "learning_object": "Student Book - U1 L1 Interpret Ilustrations (CS) - Quiz",
    "resource_id": 9907,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 292
  },
  {
    "person_key": 170417,
    "user_name": "User170417",
    "first_name": "User",
    "last_name": 170417,
    "doc_id": 12345,
    "learning_object": "Student Book - L2 Identify Main Idea and Details (CS) - Quiz",
    "resource_id": 9909,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 134
  },
  {
    "person_key": 170417,
    "user_name": "User170417",
    "first_name": "User",
    "last_name": 170417,
    "doc_id": 12345,
    "learning_object": "Student Book - L4 Understand Chemical Equations (CS) - Quiz",
    "resource_id": 10381,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 199
  },
  {
    "person_key": 170417,
    "user_name": "User170417",
    "first_name": "User",
    "last_name": 170417,
    "doc_id": 12345,
    "learning_object": "Student Book - L3 Interpret Tables (CS) - Quiz",
    "resource_id": 10121,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 12
  },
  {
    "person_key": 170417,
    "user_name": "User170417",
    "first_name": "User",
    "last_name": 170417,
    "doc_id": 12345,
    "learning_object": "Workbook - L2 Identify Main Idea and Details (CS)",
    "resource_id": 9911,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 6
  },
  {
    "person_key": 170417,
    "user_name": "User170417",
    "first_name": "User",
    "last_name": 170417,
    "doc_id": 12345,
    "learning_object": "Student Book - L2 Identify Main Idea and Details (CS)",
    "resource_id": 9908,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 12
  },
  {
    "person_key": 170417,
    "user_name": "User170417",
    "first_name": "User",
    "last_name": 170417,
    "doc_id": 12345,
    "learning_object": "Student Book - L8 Apply Scientific Laws - Quiz",
    "resource_id": 10388,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 905
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Kathryn Schulz: Don't regret regret",
    "resource_id": 7826,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Kimberlé Crenshaw: The urgency of intersectionality",
    "resource_id": 7644,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 19
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Melati and Isabel Wijsen: Our campaign to ban plastic bags in Bali",
    "resource_id": 7780,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 11.75
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "03. James A. White Sr.: The little problem I had renting a house",
    "resource_id": 5027,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 23.75
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Kelly McGonigal: How to make stress your friend",
    "resource_id": 7735,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 5.08
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.17
  },
  {
    "person_key": 170389,
    "user_name": "User170389",
    "first_name": "User",
    "last_name": 170389,
    "doc_id": 12345,
    "learning_object": "Student Book - U2 L1 Measurement and Units of Measure - Quiz",
    "resource_id": 10146,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 301
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Linda Cliatt-Wayman: How to fix a broken school? Lead fearlessly, love hard",
    "resource_id": 7630,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.42
  },
  {
    "person_key": 170389,
    "user_name": "User170389",
    "first_name": "User",
    "last_name": 170389,
    "doc_id": 12345,
    "learning_object": "Student Book - U2 L1 Measurement and Units of Measure",
    "resource_id": 10148,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 44
  },
  {
    "person_key": 430,
    "user_name": "User430",
    "first_name": "User",
    "last_name": 430,
    "doc_id": 12345,
    "learning_object": "JORDAN RIDES THE BUS",
    "resource_id": 8496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 29.92
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Workbook - L2: Understand Map Components (CS)",
    "resource_id": 10440,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 6
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Student Book - L5: Interpret Multi-Bar and Line Graphs (CS) - Quiz",
    "resource_id": 10527,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 371
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Student Book - L8 Apply Scientific Laws - Quiz",
    "resource_id": 10388,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 4517
  },
  {
    "person_key": 170388,
    "user_name": "User170388",
    "first_name": "User",
    "last_name": 170388,
    "doc_id": 12345,
    "learning_object": "Student Book - L6 Express Scientific Information (CS)",
    "resource_id": 10425,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 4817
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "10. Rick Smolan: The story of a girl",
    "resource_id": 5069,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.17
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "01. Hannah Fry: The mathematics of love",
    "resource_id": 5091,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.83
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Amy Tan: Where does creativity hide?",
    "resource_id": 7610,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156361,
    "user_name": "User156361",
    "first_name": "User",
    "last_name": 156361,
    "doc_id": 12345,
    "learning_object": "Mac Stone: Stunning photos of the endangered Everglades",
    "resource_id": 7698,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 25.16
  },
  {
    "person_key": 167260,
    "user_name": "User167260",
    "first_name": "User",
    "last_name": 167260,
    "doc_id": 12345,
    "learning_object": "Angela Patton: A father-daughter dance ... in prison",
    "resource_id": 7621,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Otis Bantum Correctional Center TWO",
    "location_id": 1697428,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Theo E.J. Wilson: A black man goes undercover in the alt-right",
    "resource_id": 7729,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 30
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "09. Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 5249,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 16.42
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Cheyenne Cochrane: A celebration of natural hair",
    "resource_id": 7595,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 30
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Anne Curzan: What makes a word \"real\"?",
    "resource_id": 7607,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.33
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Anne Lamott: 12 truths I learned from life and writing",
    "resource_id": 7602,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.58
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "03. Faith Jegede: What I've learned from my autistic brothers",
    "resource_id": 5062,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 8.58
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "Agreement #2",
    "resource_id": 8055,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.08
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Workbook - L2: Understand Map Components (CS)",
    "resource_id": 10440,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 429
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Workbook - L3: Physical Maps",
    "resource_id": 10443,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1204
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Celeste Headlee: 10 ways to have a better conversation",
    "resource_id": 7770,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L3: Physical Maps (CS)",
    "resource_id": 10441,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 16
  },
  {
    "person_key": 158287,
    "user_name": "User158287",
    "first_name": "User",
    "last_name": 158287,
    "doc_id": 12345,
    "learning_object": "06. Maz Jobrani: Did you hear the one about the Iranian-American?",
    "resource_id": 5030,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 25.75
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Workbook - L4: Political Maps (CS)",
    "resource_id": 10446,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 4697
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Reference - Social Studies Formative Assessment Analysis Chart",
    "resource_id": 9884,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 11
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L4: Political Maps (CS)",
    "resource_id": 10444,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 38
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L3: Physical Maps (CS) - Quiz",
    "resource_id": 10442,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 10088
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Optional Extended Response",
    "resource_id": 9885,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 11
  },
  {
    "person_key": 169964,
    "user_name": "User169964",
    "first_name": "User",
    "last_name": 169964,
    "doc_id": 12345,
    "learning_object": "Student Book - L4: Political Maps (CS) - Quiz",
    "resource_id": 10445,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 4634
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: Love, no matter what",
    "resource_id": 7620,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.42
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 18.92
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Danielle Feinberg: The magic ingredient that brings Pixar movies to life",
    "resource_id": 7604,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169369,
    "user_name": "User169369",
    "first_name": "User",
    "last_name": 169369,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 18.17
  },
  {
    "person_key": 155938,
    "user_name": "User155938",
    "first_name": "User",
    "last_name": 155938,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "APDS Internal",
    "location_id": 1697346,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 163547,
    "user_name": "User163547",
    "first_name": "User",
    "last_name": 163547,
    "doc_id": 12345,
    "learning_object": "02. Elizabeth Gilbert: Your elusive creative genius",
    "resource_id": 5182,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Regina Hartley: Why the best hire might not have the perfect resume",
    "resource_id": 7762,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.58
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 2 Section 4",
    "resource_id": 7990,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.75
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "02. Jamila Lyiscott: 3 ways to speak English",
    "resource_id": 4967,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 6.08
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Unit 3: The Introduction of Private Prisons",
    "resource_id": 7504,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 2.33
  },
  {
    "person_key": 167268,
    "user_name": "User167268",
    "first_name": "User",
    "last_name": 167268,
    "doc_id": 12345,
    "learning_object": "02. Robert Gupta: Music is medicine, music is sanity",
    "resource_id": 4949,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Tiq Milan and Kim Katrin Milan: A queer vision of love and marriage",
    "resource_id": 7588,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "08. George Takei: Why I love a country that once betrayed me",
    "resource_id": 5248,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 15.92
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Ronald Sullivan: How I help free innocent people from prison",
    "resource_id": 7634,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.0799999
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "06. Neil MacGregor: 2600 years of history in one object",
    "resource_id": 5246,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 19.33
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Arthur Benjamin: The magic of Fibonacci numbers",
    "resource_id": 7689,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 7.25
  },
  {
    "person_key": 167978,
    "user_name": "User167978",
    "first_name": "User",
    "last_name": 167978,
    "doc_id": 12345,
    "learning_object": "Agreement #1",
    "resource_id": 8046,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "3B",
    "location_id": 1697448,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 9.25
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Dalia Mogahed: What it's like to be Muslim in America",
    "resource_id": 7647,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Vanessa Ruiz: The spellbinding art of human anatomy",
    "resource_id": 7567,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.67
  },
  {
    "person_key": 156122,
    "user_name": "User156122",
    "first_name": "User",
    "last_name": 156122,
    "doc_id": 12345,
    "learning_object": "Serena Williams and Gayle King: On tennis, love and motherhood",
    "resource_id": 7628,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 20.67
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "08. Camille Seaman: Haunting photos of polar ice",
    "resource_id": 5121,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 2.42
  },
  {
    "person_key": 163520,
    "user_name": "User163520",
    "first_name": "User",
    "last_name": 163520,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1",
    "resource_id": 7924,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 30.25
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "12. Daniel Kahneman: The riddle of experience vs. memory",
    "resource_id": 5090,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 11.67
  },
  {
    "person_key": 261,
    "user_name": "User261",
    "first_name": "User",
    "last_name": 261,
    "doc_id": 12345,
    "learning_object": "Lesson One: The Psychology of Incarceration Intro",
    "resource_id": 8063,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.09
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 7566,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 7629,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.92
  },
  {
    "person_key": 156002,
    "user_name": "User156002",
    "first_name": "User",
    "last_name": 156002,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 2",
    "resource_id": 7985,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 19.5
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "08. Ruth Chang: How to make hard choices",
    "resource_id": 5059,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Jamie Bartlett: How the mysterious dark net is going mainstream",
    "resource_id": 7720,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 16.33
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "Joshua Prager: In search of the man who broke my neck",
    "resource_id": 7815,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 19.25
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "17. Munir Virani: Why I love vultures",
    "resource_id": 5219,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 5.5
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "07. Randall Munroe: Comics that ask \"what if?",
    "resource_id": 5098,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Jeff Smith: Lessons in business ... from prison",
    "resource_id": 7662,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 7.58
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Sue Klebold: My son was a Columbine shooter. This is my story",
    "resource_id": 7821,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Lidia Yuknavitch: The beauty of being a misfit",
    "resource_id": 7746,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 19.92
  },
  {
    "person_key": 261,
    "user_name": "User261",
    "first_name": "User",
    "last_name": 261,
    "doc_id": 12345,
    "learning_object": "Lesson Two/Domain 1: Employment-Education",
    "resource_id": 8064,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "George R. Vierno Center",
    "location_id": 1697386,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.25
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 7. Ser o no ser",
    "resource_id": 8161,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 3.25
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "03. Malcolm Gladwell: The unheard story of David and Goliath",
    "resource_id": 5243,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 15.67
  },
  {
    "person_key": 168376,
    "user_name": "User168376",
    "first_name": "User",
    "last_name": 168376,
    "doc_id": 12345,
    "learning_object": "Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 7680,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.58
  },
  {
    "person_key": 167146,
    "user_name": "User167146",
    "first_name": "User",
    "last_name": 167146,
    "doc_id": 12345,
    "learning_object": "Andrew Pelling: This scientist makes ears out of apples",
    "resource_id": 7787,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "George R. Vierno Center Education",
    "location_id": 1697437,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 8
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Reference - Science Simulated Test Performance Analysis Chart",
    "resource_id": 9940,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 15
  },
  {
    "person_key": 357,
    "user_name": "User357",
    "first_name": "User",
    "last_name": 357,
    "doc_id": 12345,
    "learning_object": "Summative Assessment - Science Simulated Test",
    "resource_id": 10433,
    "content_area": "Education",
    "content_id": 6,
    "housing_area": "Arkansas Fayetteville",
    "location_id": 1697373,
    "service_name": "Paxen/HMH",
    "service_id": 105,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 10
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Adam Savage: My love letter to cosplay",
    "resource_id": 7612,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 8.67
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Sanford Biggers: An artist's unflinching look at racial violence",
    "resource_id": 7544,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1
  },
  {
    "person_key": 168278,
    "user_name": "User168278",
    "first_name": "User",
    "last_name": 168278,
    "doc_id": 12345,
    "learning_object": "JORDAN RIDES THE BUS",
    "resource_id": 8496,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "15B",
    "location_id": 1697471,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 52.25
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Myriam Sidibe: The simple power of hand-washing",
    "resource_id": 7631,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 1.83
  },
  {
    "person_key": 155208,
    "user_name": "User155208",
    "first_name": "User",
    "last_name": 155208,
    "doc_id": 12345,
    "learning_object": "UNIT 1: Criminal Punishment and Prison Reform",
    "resource_id": 7483,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 0.5
  },
  {
    "person_key": 169256,
    "user_name": "User169256",
    "first_name": "User",
    "last_name": 169256,
    "doc_id": 12345,
    "learning_object": "Niki Okuk: When workers own companies, the economy is more resilient",
    "resource_id": 7764,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-15",
    "time_spent_min": 8.75
  },
  {
    "person_key": 155666,
    "user_name": "User155666",
    "first_name": "User",
    "last_name": 155666,
    "doc_id": 12345,
    "learning_object": "Andrew Solomon: Depression, the secret we share",
    "resource_id": 7536,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Roxbury Correctional Institution",
    "location_id": 1697402,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 17
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "11. Cristina Domenech: Poetry that frees the soul",
    "resource_id": 4976,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.58
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Elif Shafak: The revolutionary power of diverse thought",
    "resource_id": 7601,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.92
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "03. Rick Warren: A life of purpose",
    "resource_id": 5235,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 16.58
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Noel Bairey Merz: The single biggest health threat women face",
    "resource_id": 7629,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.67
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "Unit 1 Section 1 - The Criminal Justice System",
    "resource_id": 7469,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 170941,
    "user_name": "User170941",
    "first_name": "User",
    "last_name": 170941,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6022,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 9.17
  },
  {
    "person_key": 155666,
    "user_name": "User155666",
    "first_name": "User",
    "last_name": 155666,
    "doc_id": 12345,
    "learning_object": "Elyn Saks: A tale of mental illness -- from the inside",
    "resource_id": 7548,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Roxbury Correctional Institution",
    "location_id": 1697402,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 17.42
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Naomi Oreskes: Why we should trust scientists",
    "resource_id": 7794,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 169567,
    "user_name": "User169567",
    "first_name": "User",
    "last_name": 169567,
    "doc_id": 12345,
    "learning_object": "Unit 1- Policing Purpose and Organization",
    "resource_id": 7434,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.17
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Alain de Botton: A kinder, gentler philosophy of success",
    "resource_id": 7825,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 1.83
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "Second Chance Pell Program Instructions",
    "resource_id": 7671,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155661,
    "user_name": "User155661",
    "first_name": "User",
    "last_name": 155661,
    "doc_id": 12345,
    "learning_object": "Greg Gage: Electrical experiments with plants that count and communicate",
    "resource_id": 7802,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 9.92
  },
  {
    "person_key": 170958,
    "user_name": "User170958",
    "first_name": "User",
    "last_name": 170958,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6022,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 14.67
  },
  {
    "person_key": 170937,
    "user_name": "User170937",
    "first_name": "User",
    "last_name": 170937,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6022,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 48
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Euna Lee: What I learned as a prisoner in North Korea",
    "resource_id": 7667,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 1.17
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "01. Kwame Anthony Appiah: Is religion good or bad? (This is a trick question)",
    "resource_id": 5233,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 9.17
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Tania Luna: How a penny made me feel like a millionaire",
    "resource_id": 7713,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 18.33
  },
  {
    "person_key": 168362,
    "user_name": "User168362",
    "first_name": "User",
    "last_name": 168362,
    "doc_id": 12345,
    "learning_object": "04. Esther Perel: The secret to desire in a long-term relationship",
    "resource_id": 5063,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.67
  },
  {
    "person_key": 156505,
    "user_name": "User156505",
    "first_name": "User",
    "last_name": 156505,
    "doc_id": 12345,
    "learning_object": "04. Module 1: Fundamentals of Environmental Literacy",
    "resource_id": 4931,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 36.08
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "16. Michael Dickinson: How a fly flies",
    "resource_id": 5218,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 17.75
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Michael Dickinson: How a fly flies",
    "resource_id": 7796,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 4.58
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "Melissa Marshall: Talk nerdy to me",
    "resource_id": 7771,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 4.42
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Robert Gupta: Between music and medicine",
    "resource_id": 7542,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 2.83
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 7813,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 14.08
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "George Takei: Why I love a country that once betrayed me",
    "resource_id": 7830,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.5
  },
  {
    "person_key": 156393,
    "user_name": "User156393",
    "first_name": "User",
    "last_name": 156393,
    "doc_id": 12345,
    "learning_object": "06. David Brooks: Should you live for your résumé ... or your eulogy?",
    "resource_id": 5084,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 5
  },
  {
    "person_key": 167808,
    "user_name": "User167808",
    "first_name": "User",
    "last_name": 167808,
    "doc_id": 12345,
    "learning_object": "Sue Austin: Deep sea diving ... in a wheelchair",
    "resource_id": 7817,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Moose Lake",
    "location_id": 1697476,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "14. Rose George: Let's talk crap. Seriously.",
    "resource_id": 4991,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.75
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "LaToya Ruby Frazier: A visual history of inequality in industrial America",
    "resource_id": 7837,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 5.08
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 7609,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.5
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Susan Pinker: The secret to living longer may be your social life",
    "resource_id": 7641,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 16.83
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "05. Derek Sivers: Keep your goals to yourself",
    "resource_id": 5196,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 3.83
  },
  {
    "person_key": 153774,
    "user_name": "User153774",
    "first_name": "User",
    "last_name": 153774,
    "doc_id": 12345,
    "learning_object": "Eleanor Longden: The voices in my head",
    "resource_id": 7535,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 15.67
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "04. Elyn Saks: A tale of mental illness -- from the inside",
    "resource_id": 4959,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 4.92
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "Edith Widder: The weird, wonderful world of bioluminescence",
    "resource_id": 7693,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 12
  },
  {
    "person_key": 166718,
    "user_name": "User166718",
    "first_name": "User",
    "last_name": 166718,
    "doc_id": 12345,
    "learning_object": "02. Louie Schwartzberg: Hidden miracles of the natural world",
    "resource_id": 5115,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 7.17
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 7813,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.25
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "09. Andrew Solomon: How the worst moments in our lives make us who we are",
    "resource_id": 5229,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 5.75
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Paul Knoepfler: The ethical dilemma of designer babies",
    "resource_id": 7801,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 2.08
  },
  {
    "person_key": 155210,
    "user_name": "User155210",
    "first_name": "User",
    "last_name": 155210,
    "doc_id": 12345,
    "learning_object": "Frances Larson: Why public beheadings get millions of views",
    "resource_id": 7828,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.0800018
  },
  {
    "person_key": 169792,
    "user_name": "User169792",
    "first_name": "User",
    "last_name": 169792,
    "doc_id": 12345,
    "learning_object": "Clint Smith: How to raise a black son in America",
    "resource_id": 7643,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.25
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Laurie Santos: A monkey economy as irrational as ours",
    "resource_id": 7705,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 8.83
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Kathryn Schulz: Don't regret regret",
    "resource_id": 7826,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 1.42
  },
  {
    "person_key": 155610,
    "user_name": "User155610",
    "first_name": "User",
    "last_name": 155610,
    "doc_id": 12345,
    "learning_object": "Theo E.J. Wilson: A black man goes undercover in the alt-right",
    "resource_id": 7729,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 10.25
  },
  {
    "person_key": 169771,
    "user_name": "User169771",
    "first_name": "User",
    "last_name": 169771,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 27.08
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Tracy Chevalier: Finding the story inside the painting",
    "resource_id": 7609,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 18.92
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "11. Bunker Roy: Learning from a barefoot movement",
    "resource_id": 5202,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 23.25
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "06. Cameron Herold: Let's raise kids to be entrepreneurs",
    "resource_id": 5197,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.33
  },
  {
    "person_key": 170940,
    "user_name": "User170940",
    "first_name": "User",
    "last_name": 170940,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6022,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 12
  },
  {
    "person_key": 155661,
    "user_name": "User155661",
    "first_name": "User",
    "last_name": 155661,
    "doc_id": 12345,
    "learning_object": "David Christian: The history of our world in 18 minutes",
    "resource_id": 7788,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 6.83
  },
  {
    "person_key": 157749,
    "user_name": "User157749",
    "first_name": "User",
    "last_name": 157749,
    "doc_id": 12345,
    "learning_object": "04. Joseph Kim: The family I lost in North Korea. And the family I gained.",
    "resource_id": 5224,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 14.75
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "03. Joshua Prager: In search of the man who broke my neck",
    "resource_id": 5223,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "10. Joshua Walters: On being just crazy enough",
    "resource_id": 4965,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 11
  },
  {
    "person_key": 169777,
    "user_name": "User169777",
    "first_name": "User",
    "last_name": 169777,
    "doc_id": 12345,
    "learning_object": "Jessica Jackley: Poverty, money -- and love",
    "resource_id": 7712,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "DC Jails",
    "location_id": 1697477,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 21.33
  },
  {
    "person_key": 155661,
    "user_name": "User155661",
    "first_name": "User",
    "last_name": 155661,
    "doc_id": 12345,
    "learning_object": "Hannah Fry: The mathematics of love",
    "resource_id": 7674,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 3
  },
  {
    "person_key": 155661,
    "user_name": "User155661",
    "first_name": "User",
    "last_name": 155661,
    "doc_id": 12345,
    "learning_object": "Carrie Poppy: A scientific approach to the paranormal",
    "resource_id": 7800,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 24.92
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "14. Boaz Almog: The levitating superconductor",
    "resource_id": 5216,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 24.5
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Student Learning Outcomes",
    "resource_id": 8437,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 1.83
  },
  {
    "person_key": 160834,
    "user_name": "User160834",
    "first_name": "User",
    "last_name": 160834,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Wyoming State Penitentiary",
    "location_id": 1697405,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 11.08
  },
  {
    "person_key": 168362,
    "user_name": "User168362",
    "first_name": "User",
    "last_name": 168362,
    "doc_id": 12345,
    "learning_object": "06. Amy Webb: How I hacked online dating",
    "resource_id": 5065,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.83
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Megan Phelps-Roper: I grew up in the Westboro Baptist Church. Here's why I left",
    "resource_id": 7819,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 1.33
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Roxane Gay: Confessions of a bad feminist",
    "resource_id": 7583,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 5.75
  },
  {
    "person_key": 169401,
    "user_name": "User169401",
    "first_name": "User",
    "last_name": 169401,
    "doc_id": 12345,
    "learning_object": "Crime in America Week 1 Quiz",
    "resource_id": 8001,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "11. Bunker Roy: Learning from a barefoot movement",
    "resource_id": 5202,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 23.33
  },
  {
    "person_key": 169567,
    "user_name": "User169567",
    "first_name": "User",
    "last_name": 169567,
    "doc_id": 12345,
    "learning_object": "Unit 4- Law Enforcement in Action",
    "resource_id": 7438,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.92
  },
  {
    "person_key": 169395,
    "user_name": "User169395",
    "first_name": "User",
    "last_name": 169395,
    "doc_id": 12345,
    "learning_object": "Unit 2: Early Prison Reforms",
    "resource_id": 7489,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Adam Savage: My love letter to cosplay",
    "resource_id": 7612,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 21.25
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Vernā Myers: How to overcome our biases? Walk boldly toward them",
    "resource_id": 7566,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168362,
    "user_name": "User168362",
    "first_name": "User",
    "last_name": 168362,
    "doc_id": 12345,
    "learning_object": "05. Helen Fisher: Why we love, why we cheat",
    "resource_id": 5064,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "UDC Adult Probation and Parole Internal",
    "location_id": 1697422,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 1.5
  },
  {
    "person_key": 155666,
    "user_name": "User155666",
    "first_name": "User",
    "last_name": 155666,
    "doc_id": 12345,
    "learning_object": "David R. Dow: Lessons from death row inmates",
    "resource_id": 7649,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Roxbury Correctional Institution",
    "location_id": 1697402,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 15.67
  },
  {
    "person_key": 156589,
    "user_name": "User156589",
    "first_name": "User",
    "last_name": 156589,
    "doc_id": 12345,
    "learning_object": "Lisa Bu: How books can open your mind",
    "resource_id": 7605,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 30.75
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "01. Shaka Senghor: Why your worst deeds don’t define you",
    "resource_id": 5221,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.42
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Emily Balcetis: Why some people find exercise harder than others",
    "resource_id": 7608,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169471,
    "user_name": "User169471",
    "first_name": "User",
    "last_name": 169471,
    "doc_id": 12345,
    "learning_object": "Unit 1 - Coaching for Character",
    "resource_id": 6516,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "David Wade Correctional Center",
    "location_id": 1697368,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 168284,
    "user_name": "User168284",
    "first_name": "User",
    "last_name": 168284,
    "doc_id": 12345,
    "learning_object": "17. Munir Virani: Why I love vultures",
    "resource_id": 5219,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5A",
    "location_id": 1697384,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 44.75
  },
  {
    "person_key": 169443,
    "user_name": "User169443",
    "first_name": "User",
    "last_name": 169443,
    "doc_id": 12345,
    "learning_object": "Unit 5: Quiz 3, Logos, Ethos, and Pathos",
    "resource_id": 6428,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.58
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "08. Ludwick Marishane: A bath without water",
    "resource_id": 5199,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 30
  },
  {
    "person_key": 169567,
    "user_name": "User169567",
    "first_name": "User",
    "last_name": 169567,
    "doc_id": 12345,
    "learning_object": "Unit 1-  American Legal System Basics",
    "resource_id": 7906,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 2.75
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "Chimamanda Ngozi Adichie: We should all be feminists",
    "resource_id": 7574,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.25
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "Jennifer 8. Lee: The hunt for General Tso",
    "resource_id": 7831,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 16.42
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Hannah Fry: The mathematics of love",
    "resource_id": 7674,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 1.25
  },
  {
    "person_key": 155661,
    "user_name": "User155661",
    "first_name": "User",
    "last_name": 155661,
    "doc_id": 12345,
    "learning_object": "Dan Cobley: What physics taught me about marketing",
    "resource_id": 7797,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 156935,
    "user_name": "User156935",
    "first_name": "User",
    "last_name": 156935,
    "doc_id": 12345,
    "learning_object": "Kang Lee: Can you really tell if a kid is lying?",
    "resource_id": 7747,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.42
  },
  {
    "person_key": 168006,
    "user_name": "User168006",
    "first_name": "User",
    "last_name": 168006,
    "doc_id": 12345,
    "learning_object": "George Monbiot: For more wonder, rewild the world",
    "resource_id": 7694,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "County Jail 2 A",
    "location_id": 1697379,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 29.25
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Shivani Siroya: A smart loan for people with no credit history (yet)",
    "resource_id": 7708,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 2.42
  },
  {
    "person_key": 155137,
    "user_name": "User155137",
    "first_name": "User",
    "last_name": 155137,
    "doc_id": 12345,
    "learning_object": "Rajiv Maheswaran: The math behind basketball's wildest moves",
    "resource_id": 7673,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 29.92
  },
  {
    "person_key": 169553,
    "user_name": "User169553",
    "first_name": "User",
    "last_name": 169553,
    "doc_id": 12345,
    "learning_object": "Unit 3. En la universidad",
    "resource_id": 8157,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.08
  },
  {
    "person_key": 155051,
    "user_name": "User155051",
    "first_name": "User",
    "last_name": 155051,
    "doc_id": 12345,
    "learning_object": "01. Tony Porter: A call to men",
    "resource_id": 5003,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 2.17
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Aimee Mullins: The opportunity of adversity",
    "resource_id": 7816,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 3.17
  },
  {
    "person_key": 169163,
    "user_name": "User169163",
    "first_name": "User",
    "last_name": 169163,
    "doc_id": 12345,
    "learning_object": "03. Richard Turere: My invention that made peace with lions",
    "resource_id": 5194,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 7.33
  },
  {
    "person_key": 160179,
    "user_name": "User160179",
    "first_name": "User",
    "last_name": 160179,
    "doc_id": 12345,
    "learning_object": "Lisa Nip: How humans could evolve to survive in space",
    "resource_id": 7731,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.58
  },
  {
    "person_key": 161816,
    "user_name": "User161816",
    "first_name": "User",
    "last_name": 161816,
    "doc_id": 12345,
    "learning_object": "10. Alberto Cairo: There are no scraps of men",
    "resource_id": 5230,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "North Infirmary Command",
    "location_id": 1697367,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 19
  },
  {
    "person_key": 155547,
    "user_name": "User155547",
    "first_name": "User",
    "last_name": 155547,
    "doc_id": 12345,
    "learning_object": "Elon Musk: The future we're building -- and boring",
    "resource_id": 7774,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.25
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "02. Robert Gupta: Music is medicine, music is sanity",
    "resource_id": 4949,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 3.08
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "06. Andrew Solomon: Depression, the secret we share",
    "resource_id": 4961,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.42
  },
  {
    "person_key": 166009,
    "user_name": "User166009",
    "first_name": "User",
    "last_name": 166009,
    "doc_id": 12345,
    "learning_object": "Boaz Almog: The levitating superconductor",
    "resource_id": 7795,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Minnesota Correctional Facility Stillwater",
    "location_id": 1697371,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 17.17
  },
  {
    "person_key": 170945,
    "user_name": "User170945",
    "first_name": "User",
    "last_name": 170945,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6022,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 13.17
  },
  {
    "person_key": 158494,
    "user_name": "User158494",
    "first_name": "User",
    "last_name": 158494,
    "doc_id": 12345,
    "learning_object": "Alexis Ohanian: How to make a splash in social media",
    "resource_id": 7718,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Raymond Laborde Correctional Center",
    "location_id": 1697340,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 0.17
  },
  {
    "person_key": 164177,
    "user_name": "User164177",
    "first_name": "User",
    "last_name": 164177,
    "doc_id": 12345,
    "learning_object": "02. Derek Sivers: How to start a movement",
    "resource_id": 5193,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "San Bruno 5B",
    "location_id": 1697415,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 3.75
  },
  {
    "person_key": 170944,
    "user_name": "User170944",
    "first_name": "User",
    "last_name": 170944,
    "doc_id": 12345,
    "learning_object": "Part 2 - Safe Crisis Management Exam",
    "resource_id": 6022,
    "content_area": "Miscellaneous",
    "content_id": 7,
    "housing_area": "NYCDOC Academy",
    "location_id": 1697403,
    "service_name": "Opigno",
    "service_id": 126,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 39.17
  },
  {
    "person_key": 155661,
    "user_name": "User155661",
    "first_name": "User",
    "last_name": 155661,
    "doc_id": 12345,
    "learning_object": "Robert Sapolsky: The biology of our best and worst selves",
    "resource_id": 7799,
    "content_area": "Enrichment",
    "content_id": 2,
    "housing_area": "Ashland University DC Jails",
    "location_id": 1697418,
    "service_name": "TED",
    "service_id": 120,
    "time_spent_date": "2019-05-14",
    "time_spent_min": 16
  }
]
export default TOTUSERS