export default (theme) => ({
    appBar: {
        position: "relative"
    },
    infoHeader: {
        maxHeight: 20,
        borderBottom: "1px solid #dcdcdc",
        display: "flex",
        alignItems: "center"
    },
    messageViewHead: {
        backgroundColor: "#f5f5f5",
        color: "#8491a7",
        fontSize: 14,
        padding: "10px 61px"
    },
    auditViewHead: {
        padding: "10px 30px",
    },
    auditHeading: {
        color: theme.palette.primary.jetBlack,
        ...theme.typography.t20,
        ...theme.typography.medium
    },
    auditViewContainer: {
        backgroundColor: "#edf0f5",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
    },
    messageViewContainer: {
        boxShadow: "5px -2px 5px #c6c9cf",
        zIndex: 99
    },
    auditTableView: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1
    },
    tableHeading: {
        padding: "15px 30px",
        color: "##8491a7",
        ...theme.typography.t12
    },
    tableFilterBox: {
        padding: "10px 30px",
        borderTop: "1px solid #a2a2a3",
        borderBottom: "1px solid #a2a2a3"
    },
    tagFilter: {
        width: 70
    },
    dialogContent: {
        flex: 1
    },
    auditContent: {
        flex: 1,
        overflow: "auto",
        padding: "10px 0px 0px 0px",
        maxHeight: "calc(100% - 220px)"
    },
    auditMessageContainer: {
        display: "flex",
        padding: "10px 30px",
        borderBottom: "1px solid #a2a2a3"
    },
    iconContainer: {
        paddingRight: 70
    },
    icon: {
        width: 35,
        height: 35,
        borderRadius: "100%",
        backgroundColor: "#d4d7dc",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    nameContainer: {
        color: theme.palette.primary.cobalt,
        ...theme.typography.t20,
        ...theme.typography.medium,
        marginBottom: 5,
    },
    dateContainer: {
        color: "#8b97ac",
        ...theme.typography.t14,
        marginBottom: 5
    },
    descContainer: {
        ...theme.typography.t14,
        color: theme.palette.primary.jetBlack
    }
});