import {
  getSchedule,
  getFeatures,
  getManagementAttributes,
  getTimezone,
  getUserAccess,
  listLocations,
  updateSchedule,
  updateFeatures,
  updateManagementAttributes,
  updateTimezone,
  updateUserAccess,
  getLearnerFeatures,
  updateLearnerFeatures,
} from '../../util/APIUtils';
import { FEATURES, USER_CONTEXT } from '../../constants';
import moment from 'moment';

const SCHEDULE_MOCK_DATA = JSON.stringify([
  {
    dayOfWeek: 'MONDAY',
    slots: [],
  },
  {
    dayOfWeek: 'TUESDAY',
    slots: [],
  },
  {
    dayOfWeek: 'WEDNESDAY',
    slots: [],
  },
  {
    dayOfWeek: 'THURSDAY',
    slots: [],
  },
  {
    dayOfWeek: 'FRIDAY',
    slots: [],
  },
  {
    dayOfWeek: 'SATURDAY',
    slots: [],
  },
  {
    dayOfWeek: 'SUNDAY',
    slots: [],
  },
]);

export class ManagementService {
  Learner_Access = 'Learner_Portal_Access';
  Learner_Access_Schedule = 'Learner_Portal_Access_Schedule';
  Admin_Access = 'Admin_Portal_Access';
  Incentive_Engine = 'Incentive_Engine';
  Pathway = 'Pathways';
  Explore = 'Explore';
  Form = 'Forms';
  Video = 'Video';
  Radio = 'Radio';
  Game = 'Game';
  Play = 'Play';
  Play_Schedule = 'Play_Schedule';
  Video_Schedule = 'Video_Schedule';
  Game_Schedule = 'Game_Schedule';
  Radio_Schedule = 'Radio_Schedule';
  Video_Url = 'Video Url';
  Game_Url = 'Game Url';
  Messaging = 'Messaging';
  FFMessaging = 'FF_Messaging';
  VoiceCalls = 'Voice_Calls';
  Voice_Calls_Schedule = 'Voice_Calls_Schedule';
  UserDevicePairing = 'User_Device_Pairing';
  MessagingPerson = 'Messaging_Person';
  MessagingAttorney = 'MESSAGING_ATTORNEY';
  ManageRelationships = 'Manage_Relationships';
  AutoCreateRelationships = 'Auto_Create_Relationships';
  ClickTileToLaunch = 'Click_Tile_To_Launch';
  ExplorePageIndividualized = 'Explore_Page_Individualized';
  ClearDevicePairingOnMove = 'Clear_Device_Pairing_On_Move';
  Radio_Url = 'Radio Url';
  DASH_KEYCODE = 189;
  ResumeBuilder = 'Resume_Builder';
  CareerCompass = 'Career_Compass';
  static MANAGEMENT_FEATURE_ENUM = {
    General: 'General',
    Pathways: 'Pathways',
    Explore: 'Explore',
    IncentiveEngine: 'IncentiveEngine',
    Play: 'Play',
    Forms: 'Forms',
    Messaging: 'Messaging',
    FFMessaging: 'FFMessaging',
    UserDevicePairing: 'UserDevicePairing',
    VoiceCalls: 'Voice_Calls',
    ResumeBuilder: 'Resume_Builder',
    CareerCompass: 'Career_Compass'
  };
  static PLAY_FEATURE_ENUM = {
    Play_Status_Only: 'Play_Status_Only',
    ALL: 'ALL',
  };

  /**
   * @name filterValue
   * @param {object} event Event of text input.
   * @return {boolean} isValid
   */
  filterValue = (event) => {
    let isValid = true;
    const { keyCode } = event;
    if (keyCode === this.DASH_KEYCODE) {
      isValid = false;
    }

    if (!isValid) {
      event.preventDefault();
    }
  };

  /**
   * @name getGeneralToggle
   * @param {number} locationId
   * @desc Fetche's general toggle data based upon location.
   * @return {Promise}
   */
  getGeneralToggle = (locationId) => {
    return getUserAccess(locationId).then((_successLog) => {
      const MOCK_DATA = [
        {
          locationId: 1697457,
          userAccess: false,
          userRole: 19,
        },
      ];
      const learnerData = MOCK_DATA.find((generalData) => generalData.userRole === this.LEARNER_ID);
      const adminData = MOCK_DATA.find((generalData) => generalData.userRole === this.ADMIN_ID);
      const payload = {
        learnerAccess: learnerData ? learnerData.userAccess : false,
        adminAccess: adminData ? adminData.userAccess : false,
      };
      payload['access'] = payload.learnerAccess && payload.adminAccess;
      return payload;
    });
  };

  /**
   * @name updateGeneralToggle
   * @param {number} locationId
   * @param {object} state Current app. state
   * @param {string} featureKey
   * @desc Update's general toggle data based upon location.
   * @return {Promise}
   */
  updateGeneralToggle = (locationId, state, featureKey) => {
    let status = false;
    if (featureKey === this.Admin_Access) {
      status = state.general.adminAccess;
    } else if (featureKey === this.Learner_Access) {
      status = state.general.learnerAccess;
    }
    return updateUserAccess({
      locationId,
      status,
      featureKey,
    });
  };

  /**
   * @name getLocations
   * @desc Fetches location from server.
   * @return {Promise}
   */
  getLocations = () => {
    return new Promise((resolve, reject) => {
      listLocations(JSON.stringify({ requestorId: 178244, filterLocationTypeIds: [], filterLocationIds: [] })).then(
        (data) => {
          resolve(data.locations);
        }
      );
    });
  };

  /**
   * @name getLocationTimezone
   * @param {number} locationId
   * @desc Fetche's user time zone from service.
   * @return {Promise}
   */
  getLocationTimezone = (locationId) => {
    return getTimezone(locationId).then((_successLog) => {
      return {
        timezone: _successLog.timeZone,
      };
    });
  };

  /**
   * @name updateLocationTimezone
   * @param {number} locationId
   * @param {object} state Current state of app. .
   * @desc Update's timezone with respect to location.
   * @return {Promise}
   */
  updateLocationTimezone = (locationId, state) => {
    const payload = {
      locationId,
      timeZone: state.general.timezone,
    };
    return updateTimezone(payload);
  };

  /**
   * @name checkFeatureStatus
   * @param {array} features
   * @param {string} featureName
   * @param {string} dataType Data type of returning value
   * @param {string} keyCheckName Name of key against which that needs to be checked.
   * @desc Returns feature status case
   * @return {boolean} status
   */
  checkFeatureStatus = (features, featureName, dataType, keyCheckName) => {
    const feature = features.find((feature) => feature[keyCheckName] === featureName);
    switch (dataType) {
      case 'boolean':
        if (feature) {
          return feature.status;
        }
        return false;
      case 'object':
        return feature || { [keyCheckName]: featureName };
      default:
        return null;
    }
  };

  getFeatureKey = (features, featureName, keyCheckName) => {
    const feature = features.find((feature) => feature[keyCheckName] === featureName);
    if (feature) {
      return feature.featureKey;
    }
    return null;
  };

  /**
   * @name preScheduleRefine
   * @param {array} schedule
   * @desc Adds keys required for UI purpose.
   * @return {array} refineSchedule
   */
  preScheduleRefine = (schedule) => {
    return schedule.map((feature) => {
      feature.schedules = JSON.parse(SCHEDULE_MOCK_DATA).map((_mockSchedule) => {
        const serverSchedule = feature.schedules.find(
          (_featureSchedule) => _featureSchedule.dayOfWeek === _mockSchedule.dayOfWeek
        );
        if (serverSchedule) {
          _mockSchedule.slots = serverSchedule.slots.map((slot) => {
            slot['startTimePickerOpen'] = false;
            slot['endTimePickerOpen'] = false;
            slot['startTime'] = slot.startTime.split('.')[0];
            slot['endTime'] = slot.endTime.split('.')[0];
            return slot;
          });
        } else {
          _mockSchedule.slots = [];
        }
        return _mockSchedule;
      });

      return feature;
    });
  };

  /**
   * @name updateAttribute
   * @param {number} locationId ID of particular location.
   * @param {object} state Current state of app.
   * @desc Creates a payload for server from current app. state.
   * @return {Promise}
   */
  updateManagementAttributes = (locationId, state) => {
    const payload = {
      locationId: locationId,
      locationAttributes: [
        // Video URL
        {
          ...state.play.video._cacheAttributeData,
          attributeValue: state.play.video.url,
        },
        // Radio URL
        {
          ...state.play.radio._cacheAttributeData,
          attributeValue: state.play.radio.url,
        },
        // Game URL
        {
          ...state.play.game._cacheAttributeData,
          attributeValue: state.play.game.url,
        },
      ],
    };
    return updateManagementAttributes(payload);
  };

  /**
   * @name updateFacilityFeatures
   * @param {number} locationId ID of particular location.
   * @param {object} data Delta data that needs to be updated.
   * @desc Creates a payload for server from current app. state.
   * @return {Promise}
   */
  updateFacilityFeatures = (locationId, data) => {
    const payload = {
      locationId: locationId,
      locationFeatures: data,
    };
    return updateFeatures(payload);
  };

  /**
   * @name updateLearnerFeature
   * @param {number} personId ID of specific person.
   * @param {object} data Delta data that needs to be updated.
   * @desc Creates a payload for server from current app. state.
   * @return {Promise}
   */
  updateLearnerFeatures = (personId, data) => {
    personId = parseInt(personId);
    const payload = {
      personId: personId,
      personFeatures: data,
    };
    return updateLearnerFeatures(payload);
  };

  /**
   * @name updateFeatures
   * @param {number} id (facility => locationId, learner => personId)
   * @param {object} state Current state of app.
   * @param {string} type Type of update (facility, feature).
   * @desc Creates a payload for server from current app. state.
   * @return {Promise}
   */
  updateFeatures = (id, state, type) => {
    switch (type) {
      case 'facility':
        return this.updateFacilityFeatures(id, state);

      case 'learner':
        state = state.map((_data) => ({
          personId: parseInt(id),
          featureId: parseInt(_data.featureKey),
          featureName: _data.featureName,
          status: _data.status,
        }));
        return this.updateLearnerFeatures(id, state);
    }
  };

  /**
   * @name getFactilityFeatures
   * @param {number} locationId
   * @param {object} state Current app state
   * @desc Fethces location features
   * @return {Promise}
   */
  getFacilityFeatures = (locationId, state) => {
    return new Promise((resolve, reject) => {
      Promise.all([getFeatures(locationId), getManagementAttributes(locationId), this.getLocationTimezone(locationId)])
        .then((_successLog) => {
          const payload = {
            pathways: {
              _cacheData: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Pathway, 'object', 'featureName'),
              status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Pathway, 'boolean', 'featureName'),
            },
            explore: {
              _cacheData: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Explore, 'object', 'featureName'),
              status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Explore, 'boolean', 'featureName'),
            },
            forms: {
              _cacheData: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Form, 'object', 'featureName'),
              status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Form, 'boolean', 'featureName'),
            },
            messaging: {
              _cacheData: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Messaging, 'object', 'featureName'),
              status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Messaging, 'boolean', 'featureName'),
            },
            ffmessaging: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.FFMessaging,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.FFMessaging, 'boolean', 'featureName'),
            },
            messagingPerson: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.MessagingPerson,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.MessagingPerson,
                'boolean',
                'featureName'
              ),
            },
            manageRelationships: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.ManageRelationships,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.ManageRelationships,
                'boolean',
                'featureName'
              ),
            },
            messagingAttorney: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.MessagingAttorney,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.MessagingAttorney,
                'boolean',
                'featureName'
              ),
            },
            autoCreateRelationships: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.AutoCreateRelationships,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.AutoCreateRelationships,
                'boolean',
                'featureName'
              ),
            },
            clickTileToLaunch: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.ClickTileToLaunch,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.ClickTileToLaunch,
                'boolean',
                'featureName'
              ),
            },
            explorePageIndividualized: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.ExplorePageIndividualized,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.ExplorePageIndividualized,
                'boolean',
                'featureName'
              ),
            },
            general: {
              ...state.general,
              _cacheDataLearner: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.Learner_Access,
                'object',
                'featureName'
              ),
              _cacheDataAdmin: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.Admin_Access,
                'object',
                'featureName'
              ),
              learnerAccess: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.Learner_Access,
                'boolean',
                'featureName'
              ),
              adminAccess: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.Admin_Access,
                'boolean',
                'featureName'
              ),
              status:
                this.checkFeatureStatus(_successLog[0].locationFeatures, this.Admin_Access, 'boolean', 'featureName') &&
                this.checkFeatureStatus(_successLog[0].locationFeatures, this.Learner_Access, 'boolean', 'featureName'),
              ..._successLog[2],
            },
            learnerAccessSchedule: {
              ...state.learnerAccessSchedule,
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.Learner_Access_Schedule,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.Learner_Access_Schedule,
                'boolean',
                'featureName'
              ),
            },
            incentiveEngine: {
              ...state.incentiveEngine,
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.Incentive_Engine,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.Incentive_Engine,
                'boolean',
                'featureName'
              ),
            },
            postLearnerAccess: {
              ...this.checkFeatureStatus(_successLog[1].locationAttributes, 'Portal_Access', 'object', 'attributeName'),
            },
            play: {
              ...state.play,
              play: {
                _cacheData: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Play, 'object', 'featureName'),
                _cacheDataSchedule: this.checkFeatureStatus(
                  _successLog[0].locationFeatures,
                  this.Play_Schedule,
                  'object',
                  'featureName'
                ),
                status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Play, 'boolean', 'featureName'),
                value: {
                  status: false,
                  value:
                    this.checkFeatureStatus(_successLog[0].locationFeatures, this.Play, 'object', 'featureName').price || 0,
                },
                scheduleStatus: this.checkFeatureStatus(
                  _successLog[0].locationFeatures,
                  this.Play_Schedule,
                  'boolean',
                  'featureName'
                ),
                url: null,
              },
              video: {
                _cacheData: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Video, 'object', 'featureName'),
                _cacheDataSchedule: this.checkFeatureStatus(
                  _successLog[0].locationFeatures,
                  this.Video_Schedule,
                  'object',
                  'featureName'
                ),
                _cacheAttributeData: this.checkFeatureStatus(
                  _successLog[1].locationAttributes,
                  this.Video_Url,
                  'object',
                  'attributeName'
                ),
                status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Video, 'boolean', 'featureName'),
                value: {
                  status: false,
                  value:
                    this.checkFeatureStatus(_successLog[0].locationFeatures, this.Video, 'object', 'featureName').price || 0,
                },
                scheduleStatus: this.checkFeatureStatus(
                  _successLog[0].locationFeatures,
                  this.Video_Schedule,
                  'boolean',
                  'featureName'
                ),
                url: this.checkFeatureStatus(_successLog[1].locationAttributes, this.Video_Url, 'object', 'attributeName')
                  .attributeValue,
              },
              radio: {
                _cacheData: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Radio, 'object', 'featureName'),
                _cacheDataSchedule: this.checkFeatureStatus(
                  _successLog[0].locationFeatures,
                  this.Radio_Schedule,
                  'object',
                  'featureName'
                ),
                _cacheAttributeData: this.checkFeatureStatus(
                  _successLog[1].locationAttributes,
                  this.Radio_Url,
                  'object',
                  'attributeName'
                ),
                status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Radio, 'boolean', 'featureName'),
                value: {
                  status: false,
                  value:
                    this.checkFeatureStatus(_successLog[0].locationFeatures, this.Radio, 'object', 'featureName').price || 0,
                },
                scheduleStatus: this.checkFeatureStatus(
                  _successLog[0].locationFeatures,
                  this.Radio_Schedule,
                  'boolean',
                  'featureName'
                ),
                url: this.checkFeatureStatus(_successLog[1].locationAttributes, this.Radio_Url, 'object', 'attributeName')
                  .attributeValue,
              },
              game: {
                _cacheData: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Game, 'object', 'featureName'),
                _cacheDataSchedule: this.checkFeatureStatus(
                  _successLog[0].locationFeatures,
                  this.Game_Schedule,
                  'object',
                  'featureName'
                ),
                _cacheAttributeData: this.checkFeatureStatus(
                  _successLog[1].locationAttributes,
                  this.Game_Url,
                  'object',
                  'attributeName'
                ),
                status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.Game, 'boolean', 'featureName'),
                value: {
                  status: false,
                  value:
                    this.checkFeatureStatus(_successLog[0].locationFeatures, this.Game, 'object', 'featureName').price || 0,
                },
                scheduleStatus: this.checkFeatureStatus(
                  _successLog[0].locationFeatures,
                  this.Game_Schedule,
                  'boolean',
                  'featureName'
                ),
                url: this.checkFeatureStatus(_successLog[1].locationAttributes, this.Game_Url, 'object', 'attributeName')
                  .attributeValue,
              },
            },
            userDevicePairing: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.UserDevicePairing,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.UserDevicePairing,
                'boolean',
                'featureName'
              ),
            },
            clearDevicePairingOnMove: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.ClearDevicePairingOnMove,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.ClearDevicePairingOnMove,
                'boolean',
                'featureName'
              ),
            },
            voiceCalls: {
              _cacheData: this.checkFeatureStatus(_successLog[0].locationFeatures, this.VoiceCalls, 'object', 'featureName'),
              status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.VoiceCalls, 'boolean', 'featureName'),
            },
            voiceCallsSchedule: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.Voice_Calls_Schedule,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.Voice_Calls_Schedule,
                'boolean',
                'featureName'
              ),
            },
            resumeBuilder: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.ResumeBuilder,
                'object',
                'featureName'
              ),
              status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.ResumeBuilder, 'boolean', 'featureName'),
            },
            careerCompass: {
              _cacheData: this.checkFeatureStatus(
                _successLog[0].locationFeatures,
                this.CareerCompass, 
                'object', 
                'featureName'
              ),
              status: this.checkFeatureStatus(_successLog[0].locationFeatures, this.CareerCompass, 'boolean', 'featureName'),
            },
          };
          resolve(payload);
        })
        .catch((e) => reject(e));
    });
  };

  /**
   * @name getLearnerFeatures
   * @param {number} personId
   * @param {object} state Current app state
   * @desc Fethces location features
   * @return {Promise}
   */
  getLearnerFeatures = (personId, state) => {
    return new Promise((resolve, reject) => {
      getLearnerFeatures(personId)
        .then((_successLog) => {
          const payload = {
            messaging: {
              _cacheData: this.checkFeatureStatus(_successLog, this.MessagingPerson, 'object', 'featureName'),
              status: this.checkFeatureStatus(_successLog, this.MessagingPerson, 'boolean', 'featureName'),
            },
            ffmessaging: {
              _cacheData: this.checkFeatureStatus(_successLog, this.FFMessaging, 'object', 'featureName'),
              status: this.checkFeatureStatus(_successLog, this.FFMessaging, 'boolean', 'featureName'),
            },
            play: {
              ...state.play,
              play: {
                _cacheData: this.checkFeatureStatus(_successLog, this.Play, 'object', 'featureName'),
                _cacheDataSchedule: this.checkFeatureStatus(_successLog, this.Play_Schedule, 'object', 'featureName'),
                status: this.checkFeatureStatus(_successLog, this.Play, 'boolean', 'featureName'),
                value: {
                  status: false,
                  value: this.checkFeatureStatus(_successLog, this.Play, 'object', 'featureName').price || 0,
                },
                scheduleStatus: this.checkFeatureStatus(_successLog, this.Play_Schedule, 'boolean', 'featureName'),
                url: null,
              },
            },
            userDevicePairing: {
              _cacheData: this.checkFeatureStatus(_successLog, this.UserDevicePairing, 'object', 'featureName'),
              status: this.checkFeatureStatus(_successLog, this.UserDevicePairing, 'boolean', 'featureName'),
            },
            voiceCalls: {
              _cacheData: this.checkFeatureStatus(_successLog, this.VoiceCalls, 'object', 'featureName'),
              status: this.checkFeatureStatus(_successLog, this.VoiceCalls, 'boolean', 'featureName'),
            },
            voiceCallsSchedule: {
              _cacheData: this.checkFeatureStatus(_successLog, this.Voice_Calls_Schedule, 'object', 'featureName'),
              status: this.checkFeatureStatus(_successLog, this.Voice_Calls_Schedule, 'boolean', 'featureName'),
            },
            reEntryPreparation: {
              _cacheData: this.checkFeatureStatus(_successLog, this.ResumeBuilder, 'object', 'featureName'),
              status: this.checkFeatureStatus(_successLog, this.ResumeBuilder, 'boolean', 'featureName'),
           },
           careerCompass: {
            _cacheData: this.checkFeatureStatus(_successLog, this.CareerCompass, 'object', 'featureName'),
            status: this.checkFeatureStatus(_successLog, this.CareerCompass, 'boolean', 'featureName'),
          },
          };

          resolve(payload);
        })
        .catch((e) => reject(e));
    });
  };

  /**
   * @name getFeatures
   * @param {number} id (facility => locationId, learner => personId)
   * @param {object} state Current app state
   * @param {string} type Type of fetch (learner, facility)
   * @desc Fethces location features
   * @return {Promise}
   */
  getFeatures = (id, state, type) => {
    switch (type) {
      case 'facility':
        return this.getFacilityFeatures(id, state);

      case 'learner':
        return this.getLearnerFeatures(id, state);
    }
  };

  /**
   * @name postScheduleRefine
   * @param {array} schedule
   * @desc A schedule refinding mechanism after all tasks are done.
   * @return {array}
   */
  postScheduleRefine = (features) => {
    return features.map((_feature) => {
      _feature.schedules = _feature.schedules.map((_schedule) => {
        _schedule.slots = _schedule.slots.map((_slot) => {
          _slot.startTime = moment(_slot.startTime).format('YYYY-MM-DDTHH:mm:ss');
          _slot.endTime = moment(_slot.endTime).format('YYYY-MM-DDTHH:mm:ss');
          return _slot;
        });
        _feature.featureKey = FEATURES.find((item) => item.featureName === _feature.featureName).featureKey;
        return _schedule;
      });
      return _feature;
    });
  };

  /**
   * @name updateSchedule
   * @param {number} locationId
   * @desc Update's schedule based upon location.
   * @return {Promise}
   */
  updateSchedule = (locationId, locationSchedule, showSnackBar) => {
    return new Promise((resolve, reject) => {
      updateSchedule({
        locationId,
        locationSchedule: this.postScheduleRefine(locationSchedule),
      }).then(
        (_successLog) => resolve(true),
        (_errorLog) => {
          showSnackBar('Something went wrong while updating schedule, please contact server adminstrator.');
          reject(false);
        }
      );
    });
  };

  /**
   * @name getSchedule
   * @param {number} locationId ID of location whose schedule needs to be fetched.
   * @desc Fetches schedule data from service.
   * @return {Promise}
   */
  getSchedule = (locationId) => {
    return new Promise((resolve, reject) => {
      getSchedule(locationId)
        .then((_successLog) => {
          const learnerAccessSchedule = this.checkFeatureStatus(
            _successLog.schedules,
            this.Learner_Access_Schedule,
            'object',
            'featureName'
          );
          const playSchedule = this.checkFeatureStatus(_successLog.schedules, this.Play_Schedule, 'object', 'featureName');
          const videoSchedule = this.checkFeatureStatus(_successLog.schedules, this.Video_Schedule, 'object', 'featureName');
          const radioSchedule = this.checkFeatureStatus(_successLog.schedules, this.Radio_Schedule, 'object', 'featureName');
          const gameSchedule = this.checkFeatureStatus(_successLog.schedules, this.Game_Schedule, 'object', 'featureName');
          const voiceCallsSchedule = this.checkFeatureStatus(
            _successLog.schedules,
            this.Voice_Calls_Schedule,
            'object',
            'featureName'
          );

          if (!learnerAccessSchedule.hasOwnProperty('schedules')) {
            learnerAccessSchedule['schedules'] = JSON.parse(SCHEDULE_MOCK_DATA);
          }
          if (!playSchedule.hasOwnProperty('schedules')) {
            playSchedule['schedules'] = JSON.parse(SCHEDULE_MOCK_DATA);
          }
          if (!videoSchedule.hasOwnProperty('schedules')) {
            videoSchedule['schedules'] = JSON.parse(SCHEDULE_MOCK_DATA);
          }
          if (!radioSchedule.hasOwnProperty('schedules')) {
            radioSchedule['schedules'] = JSON.parse(SCHEDULE_MOCK_DATA);
          }
          if (!gameSchedule.hasOwnProperty('schedules')) {
            gameSchedule['schedules'] = JSON.parse(SCHEDULE_MOCK_DATA);
          }
          if (!voiceCallsSchedule.hasOwnProperty('schedules')) {
            voiceCallsSchedule['schedules'] = JSON.parse(SCHEDULE_MOCK_DATA);
          }
          resolve(
            this.preScheduleRefine([
              playSchedule,
              videoSchedule,
              radioSchedule,
              gameSchedule,
              learnerAccessSchedule,
              voiceCallsSchedule,
            ])
          );
        })
        .catch((e) => reject(e));
    });
  };
}
