import React from 'react';
import { Chip } from '@mui/material';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './flagged-keywords.style';
import { withUnleash } from '../../../../v2/core/unleash';
import { UnleashService } from '../../../../v2/services';

const FlaggedKeywords = (props) => {
  const { classes, keywordsData, unleashProps } = props;

  return (
    unleashProps.isFlagEnabled() && (
      <Box className={classes.container}>
        <Typography id="keywords-section-label" variant="h3" className={classes.title}>
          Keywords Detected
        </Typography>
        {keywordsData?.length > 0 ? (
          <Box className={classes.keywordContainer}>
            {keywordsData.map((keyword, index) => (
              <Chip
                key={index}
                label={keyword}
                variant="outlined"
                sx={{
                  color: '#9E162F',
                  flex: '0 0 calc(14.2857% - 8px)',
                  margin: '4px',
                  borderColor: '#9E162F',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '19.36px',
                  letterSpacing: '0.02em',
                  textAlign: 'center',
                  borderRadius: '4px',
                  minWidth: '94px',
                  height: '24px',
                }}
              />
            ))}
          </Box>
        ) : (
          <Typography variant="body1" id="no-keywords-label">
            No keywords detected
          </Typography>
        )}
      </Box>
    )
  );
};

export default withStyles(styles)(withUnleash(FlaggedKeywords, UnleashService.FLAGS.KEYWORD_MATCH_CONFIG));
