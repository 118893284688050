import { useMemo, useState } from 'react';
import { updateManagementAttributes } from '../../../../util/APIUtils';
export const useMinuteManagementHook = ({locationId,maxMinutesAllowedPerCallAttribute, maxMinPerDayPerLearnerAttribute ,setMaxMinPerDayPerLearnerAttribute,setMaxMinutesAllowedPerCallAttribute}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(0);
  const [isSuccessSnackbar, setIsSuccessSnackbar] = useState(false);
  const onMaxMinutesAllowedChange = e => {
    const minutesAllowed = e.target.value;
    setMaxMinutesAllowedPerCallAttribute(prevState => ({
      ...prevState,
      attributeValue: minutesAllowed
    }));
  };

  const onMaxMinutesPerDayChange = e => {
    const minutesAllowed = e.target.value;
    setMaxMinPerDayPerLearnerAttribute(prevState => ({
      ...prevState,
      attributeValue: minutesAllowed
    }));
  };

  const handleSubmit = async (attribute, setMessage, successMessage, errorMessage) => {
    const minutesAllowed = attribute.attributeValue;
    if (!/^(?!0\d*$)\d+$/.test(minutesAllowed) || minutesAllowed <= 0) {
      handleSnackbar('Maximum Minutes allowed should be a positive integer without any special characters and trailing zeros.', false, 3000);
      return;
    }
    try {
      await updateManagementAttributes({
        locationId: locationId,
        locationAttributes: [attribute]
      });
      handleSnackbar(successMessage + minutesAllowed + ' minutes. ', true, 2000);
    } catch (error) {
      handleSnackbar(errorMessage, false, 2000);
    }
  };
  
  const onMaxMinutesAllowedSubmit = () => {
    handleSubmit(maxMinutesAllowedPerCallAttribute, setSnackbarMessage, 'Minutes allowed updated to ', 'Failed to update minutes allowed.');
  };
  
  const onMaxMinutesAllowedPerDaySubmit = () => {
    handleSubmit(maxMinPerDayPerLearnerAttribute, setSnackbarMessage, 'Minutes allowed updated to ', 'Failed to update minutes allowed.');
  };

  const handleSnackbar = useMemo(() => (message, success, duration) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
    setSnackbarAutoHideDuration(duration);
    setIsSuccessSnackbar(success);
  }, []);


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  return {
    openSnackbar,
    snackbarMessage,
    snackbarAutoHideDuration,
    isSuccessSnackbar,
    onMaxMinutesAllowedSubmit,
    onMaxMinutesAllowedChange,
    onMaxMinutesPerDayChange,
    onMaxMinutesAllowedPerDaySubmit,
    maxMinutesAllowedPerCallAttribute,
    maxMinPerDayPerLearnerAttribute,
    handleCloseSnackbar
  };
};
