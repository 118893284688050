export default (theme) => ({
    root: {
        padding: "0px 20px"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        padding: 20
    },
    heading: {
        ...theme.typography.t24,
        ...theme.typography.bold
    },
    checkBoxWrapper: {
        display: "flex",
        alignItems: "center"
    },
    checkBoxText: {
        ...theme.typography.bold,
        color: theme.palette.primary.teal
    },
    editBtn: {
        ...theme.typography.bold,
        minWidth: 100
    },
    groupMemberNames: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: 300
    }
});