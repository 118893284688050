import { theme } from '../../../v2/core/containers/theme';
import { CustomTheme } from '@orijinworks/frontend-commons';

export default () => ({
  tabName: {
    letterSpacing: '0.77px',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.text.navy,
    display: 'inline-block',
    borderBottom: '4px solid ' + CustomTheme.palette.primary.main,
    padding: 10
  },
  datatable: {
    '& td': {
      fontSize: 14,
      letterSpacing: '0.04px',
      color: '#000000'
    }
  },
  disable: {
    opacity: '0.5'
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '6px',
    padding: '20px 15px 0 15px'
  },
  roleName: {
    fontSize: 18,
    textAlign: 'left',
    maxWidth: 200,
    color: theme.palette.text.navy,
    letterSpacing: '0.43px',
    textTransform: 'capitalize'
  },
  addBtn: {
    minWidth: 0,
    width: 39,
    height: 39,
    borderRadius: '50%'
  }
});
