import { withStyles } from '@material-ui/styles';
import React from 'reactn';
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import SortIcon from '@material-ui/icons/Sort'
import FilterListIcon from '@material-ui/icons/FilterList'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Datatable, { MTableCell } from 'material-table'

import styles from './explore.style';
import { Box, Switch, Tooltip } from '@material-ui/core';
import { manageStudentServices, getUsersList } from '../../../../util/APIUtils';
import { USER_CONTEXT } from '../../../../constants';
import { UserIcon } from '../../../UserIcon';

class ExploreListing extends React.Component {
    columns = [
        {
            title: 'Service',
            field: 'serviceDisplayName',
        },
        {
            title: "Assignment State",
            field: "active",
            lookup: { "true": 'Active', "false": 'Inactive' },
            render: (rowData) => {
                return (
                    <Tooltip title={rowData.active === "true" ? "Assigned" : "Unassigned"}>
                        <Switch
                            id={`learner-service-status-${rowData.serviceKey}`}
                            checked={rowData.active == "true"}
                            onChange={(event) => this.toggleServiceStatus(event, rowData)}
                        />
                    </Tooltip>
                );
            }
        }
    ];
    state = {
        dataCollection: []
    };

    componentDidMount() {
        this.getUserServices(this.props.userDetail.userId);
    };

    /**
     * @name getResponses
     * @param {object} response
     * @desc Prepares error responses from response received from server.
     * @return {array} 
     */
    getResponses = (response) => {
        const _responses = [];
        response.provisionServiceResponses
            .forEach(
                (service) => {
                    if (service.statusCode !== 0) {
                        _responses.push({
                            statusCode: service.statusCode,
                            text: service.statusMessage,
                        });
                    }
                    service.provisionServiceResults.forEach(
                        (childService) => {
                            if (service.statusCode !== 0) {
                                _responses.push({
                                    statusCode: service.statusCode,
                                    text: service.statusMessage,
                                });
                            } else if (childService.statusCode !== 0 || childService.statusMessage.toLowerCase() !== "ok") {
                                _responses.push({
                                    statusCode: 1,
                                    text: childService.statusMessage,
                                });
                            }
                        }
                    );
                }
            );
        return _responses;
    };

    /**
     * @name toggleServiceStatus
     * @param {object} event HTML event
     * @param {object} rowData service rowData.
     * @desc Toggles service status based upon check box value.
     * @return {void}
     */
    toggleServiceStatus = async (event, rowData) => {
        try {
            this.setGlobal({
                isLoading: true
            });
            const servicePayload = {
                locationId: this.props.userLocation.locationId,
                provisionServiceRequests: [
                    {
                        requestorId: USER_CONTEXT.personId,
                        serviceName: rowData.serviceName,
                        active: event.target.checked,
                        users: [
                            {
                                personId: this.props.userDetail.userId
                            }
                        ]
                    }
                ]
            };
            const _response = await manageStudentServices(servicePayload);
            setTimeout(() => {
                this.getUserServices(this.props.userDetail.userId);
                const responses = this.getResponses(_response);
                if (responses.length > 0) {
                    this.props.setResponse(
                        {
                            isOpen: true,
                            data: responses,
                            heading: "Service Un-assignment",
                        })
                }
            }, 3000);
        } catch (e) {
            console.log(e);
            this.setGlobal({
                isLoading: false
            });
        };

    };

    /**
     * @name getUserServices
     * @desc Fetches user services.
     * @return {void}
     */
    getUserServices = (userId) => {
        const exemptedServices = [124, 125];
        this.setGlobal({
            isLoading: true
        });
        const requestPayload = {
            "studentIds": [userId],
            "showUnAssignableServicesOnly": true
        };
        this.setState({
            showEmptyDataSourceMessage: false
        });
        getUsersList(requestPayload)
            .then(
                (_successLog) => {
                    this.setState({
                        showEmptyDataSourceMessage: true,
                        dataCollection: _successLog.userCards
                            .map(
                                user => {
                                    return user.userServices
                                        .map(
                                            service => service
                                        )
                                        .filter(
                                            service => exemptedServices.indexOf(service.serviceKey) === -1
                                        );
                                }
                            )
                            .flat()
                    });
                    this.setGlobal({
                        isLoading: false
                    });
                },
                (_errorLog) => {
                    console.error("Something went wrong while fetching learners list");
                    this.setGlobal({
                        isLoading: false
                    });
                }
            );
    };
    

    render() {
        return (
            <Box width="100%">
                <Datatable
                    title="Learner Services"
                    data={this.state.dataCollection}
                    columns={this.columns}
                    components={{
                        Cell: props => (
                            <MTableCell
                                id={`learner-service-${props.columnDef.field}-${props.rowData.serviceKey}`}
                                {...props}
                            />
                        )
                    }}
                    icons={{
                        Search: SearchIcon,
                        ResetSearch: CloseIcon,
                        FirstPage: FirstPageIcon,
                        LastPage: LastPageIcon,
                        NextPage: NavigateNextIcon,
                        PreviousPage: NavigateBeforeIcon,
                        Filter: FilterListIcon,
                        Sort: SortIcon,
                        SortArrow: ArrowDownwardIcon,
                        Export: SaveAltIcon,
                        Edit: EditIcon,
                        User: UserIcon
                    }}
                    options={{
                        showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
                        sorting: true,
                        filtering: true,
                        pageSize: 20,
                        exportButton: true,
                        search: false,
                        exportFileName: `${this.props.userDetail.userName}-Services`,
                    }}
                />
            </Box>
        );
    };
};

export default withStyles(styles)(ExploreListing);