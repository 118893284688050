import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import { commonStyles } from "../common";
import { duplicateRole } from "../../../util/APIUtils";
import styles from "./index.styles";

const useStyles = makeStyles((theme) => ({
  ...commonStyles(theme),
  ...styles(theme),
}));

const DuplicateRoleDialog = ({
  open,
  handleClose,
  roleName,
  roleId,
  history,
  roleTypeName,
}) => {
  const classes = useStyles();
  const [duplicateRoleName, setDuplicateRoleName] = React.useState(
    `${roleName} (Copy)`
  );

  const [duplicateError, setDuplicateError] = React.useState(false);

  const excessRoleNameChar = duplicateRoleName.length > 128;
  const isRoleNameEmpty = duplicateRoleName.length === 0;
  const regex = /[^a-zA-z0-9()/ -]/g;
  const hasSpecialChar = regex.test(duplicateRoleName);
  let helperText = "";

  React.useEffect(() => {
    setDuplicateRoleName(`${roleName} (Copy)`);
  }, [roleName]);

  const handleRoleNameChange = (event) => {
    setDuplicateRoleName(event.target.value);
  };

  const onDuplicateClick = async () => {
    try {
      const res = await duplicateRole({
        roleId,
        roleName: duplicateRoleName,
      });
      handleCancelClick();
      history.push(
        `/manageRoles/${encodeURIComponent(duplicateRoleName)}/${res.roleId}`
      );
    } catch (error) {
      if (error.status === 409) {
        setDuplicateError(true);
      }
    }
  };

  const handleCancelClick = () => {
    handleClose();
    setDuplicateRoleName(`${roleName} (Copy)`);
    setDuplicateError(false);
  };

  if (excessRoleNameChar) {
    helperText = "Role name should not exceed 128 characters";
  }

  if (hasSpecialChar) {
    helperText = "Role name cannot contain special characters";
  }

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={handleCancelClick}>
      <DialogTitle disableTypography className={classes.title}>
        Duplicate Role
      </DialogTitle>

      <DialogContent>
        <TextField
          className={classes.field}
          error={excessRoleNameChar || hasSpecialChar}
          helperText={helperText}
          id="outlined-error-helper-text"
          label="Role Name"
          variant="outlined"
          onChange={handleRoleNameChange}
          value={duplicateRoleName}
          InputProps={{
            classes: {
              root: classes.outlinedField,
              input: classes.input,
            },
          }}
        />
        <TextField
          disabled
          size="small"
          className={classes.field}
          id="outlined-disabled"
          label="Role Type"
          defaultValue={roleTypeName}
          variant="outlined"
          InputProps={{
            classes: {
              root: classes.outlinedField,
              input: classes.input,
            },
          }}
        />
        {duplicateError && (
          <div className={classes.errorMessage}>
            Role name already exists. Please try a different name
          </div>
        )}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          onClick={onDuplicateClick}
          color="primary"
          variant="contained"
          disabled={excessRoleNameChar || isRoleNameEmpty || hasSpecialChar}
        >
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(DuplicateRoleDialog);
