import { RELATIVE_POSITIONS } from '../../../../theme';

const messageContainerHeight =
  Number(RELATIVE_POSITIONS.HEADER_HEIGHT.slice(0, 3)) +
  RELATIVE_POSITIONS.MESSAGE_MODULE_PADDING * 2 +
  RELATIVE_POSITIONS.LOCATION_SELECTOR;

export default theme => ({
  container: {
    display: 'flex',
    width: '87%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '83%'
    },
    marginTop: 10
  },
  chatContainer: {
    display: 'flex',
    width: '100%',
    height: `calc(100vh - ${messageContainerHeight}px)`,
    backgroundColor: theme.palette.primary.white //'#F7F8FA'
  },
  messageContainer: {
    borderRadius: 10,
    border: `1px solid #DFE6E9`,
    background: 'white',
    position: 'relative'
  },
  messagesActions: {
    width: '100%',
    marginRight: 10,
    marginLeft: 10,
    alignItems: 'center'
  },
  mainGrid: {
    flex: 1
  },
  messagePanel: {
    background: 'white',
    borderRadius: 10,
    border: `1px solid #DFE6E9`,
    display: 'flex',
    flexDirection: 'column',
    height: "100%"
  },
  messageViewer: {
    borderRadius: 10,
    border: `1px solid #DFE6E9`,
    background: 'white',
    position: 'relative',
    marginTop: '58px'
  },
  cardRoot: {
    width: '100%',
    marginTop: 20
  },
  autocompleteRoot: {
    width: '100%'
  },
  locationContainer: {
    maxWidth: 700,
    width: '100%'
  },
  sidebar: {
    maxWidth: 350,
    minWidth: 350
  },
  mainContainer: {
    height: "100%"
  },
  sidebarStyle: {
    background: 'green',
    margin: '0rem 1.2rem 0rem 1.2rem',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid #DFE6E9` // #DFE6E9
    // border: `1px solid ${theme.palette.greyScale.platinum}` // #DFE6E9
    // width: '579px'
  },
  rightSideStyle: {
    background: 'red',
    borderRadius: 10,
    border: `1px solid red` // #DFE6E9
  }
});
