export default (theme) => ({
  description: {
    '&.MuiTypography-root': {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.colors.grey110,
      marginLeft: '10px',
      marginBottom: '12px',
    },
  },
});
