import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FnFMessagingService } from '../../FnFMessagingService';
import MessageBox from './MessageBox';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
export default class MessageHistory extends Component {
  FnFMessagingService = new FnFMessagingService();
  MAX_RECORDS = 100;
  eventListenerAdded = false;
  initialState = {
    open: false,
    isLoading: false,
    messages: [],
    relationshipStatus: '',
    afterEnded: false,
    beforeEnded: false,
  };
  state = this.initialState;
  id = Math.floor(Math.random() * 100);
  scrollBox = null;
  scrollObserver = null;
  appendScrollListener = () => {
    try {
      const { open, messages } = this.state;
      if (open && messages.length > 0) {
        this.scrollBox = document.querySelector(`.Box-${this.id}`);
        if (this.scrollBox) {
          const { scrollHeight, clientHeight } = this.scrollBox;
          if (scrollHeight > clientHeight && !this.eventListenerAdded) {
            this.eventListenerAdded = true;
            this.scrollBox.addEventListener('scroll', (e) => {
              const { scrollTop } = e.target;
              if (scrollTop === 0 && !this.state.isLoading && !this.state.beforeEnded) {
                this.getScrollableMsgs('before', messages[0].messageId, null);
              }
              if (e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight) {
                const { isLoading, afterEnded } = this.state;
                if (!isLoading && !afterEnded) {
                  this.getScrollableMsgs('after', null, messages[messages.length - 1].messageId);
                }
              }
            });
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  getScrollableMsgs = (type, beforeId, afterId) => {
    this.setState({
      isLoading: true,
    });
    let { afterEnded, beforeEnded } = this.state;
    this.FnFMessagingService.getLearnerMessages(
      this.props.locationId,
      this.props.learnerPersonId,
      this.props.contactPersonId,
      this.MAX_RECORDS,
      beforeId,
      afterId,
    ).then((data) => {
      const { payload } = data;
      const { messages } = this.state;
      if (type === 'before') {
        messages.unshift(...payload);
      } else {
        messages.push(...payload);
      }
      if (payload.length < this.MAX_RECORDS && type == 'before') {
        beforeEnded = true;
      } else if (payload.length < this.MAX_RECORDS && type == 'after') {
        afterEnded = true;
      }
      this.setState({ messages, isLoading: false, beforeEnded, afterEnded }, () => {
        this.appendScrollListener();
      });
    });
  };

  fetchRelations = () => {
    this.FnFMessagingService.getLearnerContactRelation(
      this.props.locationId,
      this.props.learnerPersonId,
      this.props.contactPersonId,
    ).then((data) => {
      const status = data && data.payload ? data.payload.status : '';
      if(status) {
        this.setState({ relationshipStatus: status['admin'] });
      }
    });
  };
  fetchMessages = () => {
    this.FnFMessagingService.getLearnerMessages(
      this.props.locationId,
      this.props.learnerPersonId,
      this.props.contactPersonId,
      this.MAX_RECORDS,
      null,
      this.props.messageId,
    ).then((data) => {
      const { payload } = data;
      if (this.props.firstMessage) {
        this.setState({ messages: [this.props.firstMessage, ...payload] });
      } else {
        this.setState({ messages: payload });
      }
      this.appendScrollListener();
    });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
    this.fetchMessages();
    this.fetchRelations();
  };

  handleClose = () => {
    this.setState(this.initialState);
    if(this.props.closeMessageHistory) {
      this.props.closeMessageHistory();
    }
  };

  handleDownloadMessages = () => {
    const { messages } = this.state;
    if (messages && messages.length) {
      const { learner, contact } = messages[0];
      const fileName = `${learner.personId}_${contact.personId}_message_history.csv`;
      this.FnFMessagingService.downloadMessages(this.props.locationId, learner.personId, contact.personId).then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
    }
  };
  handleBlock = () => {
    const { relationshipStatus } = this.state;
    const statusAction = this.FnFMessagingService.getStatusAction(relationshipStatus);
    this.FnFMessagingService.changeConnectionStatus(this.props.locationId, {
      status: statusAction.value.toUpperCase(),
      learnerPersonId: this.props.learnerPersonId,
      contactPersonId: this.props.contactPersonId,
    }).then((data) => {
      const {
        payload: { status },
      } = data;
      this.setState({ relationshipStatus: status['admin'] });
      this.props.refreshTable();
    });
  };

  render() {
    const statusAction = this.FnFMessagingService.getStatusAction(this.state.relationshipStatus);
    const { isLoading, beforeEnded } = this.state;
    return (
      <div>
        {this.props.openFromConnections === undefined && 
          <Link onClick={this.handleClickOpen} style={{ cursor: 'pointer' }}>
            View Conversation
          </Link>
        }
        <Dialog
          open={this.state.open || this.props.openFromConnections}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          scroll="paper"
          fullWidth={true}
          maxWidth={'md'}
        >
          <DialogTitle id="responsive-dialog-title">{'Message History'}</DialogTitle>
          <DialogContent
            classes={{
              root: `Box-${this.id}`,
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              {!beforeEnded && (
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    try {
                      const { messages } = this.state;
                      if (this.props.messageId) {
                        this.getScrollableMsgs('before', this.props.messageId, null);
                      } else {
                        this.getScrollableMsgs('before', messages[messages.length - 1].messageId, null);
                      }
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                >
                  Show Old Messages
                </Link>
              )}
            </Box>
            {isLoading && (
              <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            )}

            {this.state.messages.map((message) => (
              <MessageBox message={message} key={message.messageId} timezoneName={this.props.timezoneName} />
            ))}
            {isLoading && (
              <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
            <Box left="10px" position="absolute">
              {statusAction.show && statusAction.label === 'Block' ? (
                <Button onClick={this.handleBlock} color="primary">
                  Block Contact
                </Button>
              ) : statusAction.label === 'Unblock' ? (
                <Button color="primary" onClick={this.handleBlock}>
                  Unblock Contact
                </Button>
              ) : null}
            </Box>
            <div>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>
            </div>
            <Box right="10px" position="absolute">
              <IconButton aria-label="download" onClick={this.handleDownloadMessages}>
                <GetAppIcon color="primary" />
              </IconButton>
            </Box>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
