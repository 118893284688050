import React, { forwardRef } from 'react';
import { Modal } from '@orijinworks/frontend-commons';
import { TextField, withStyles, Typography, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import styles from './create-schedule-modal.style';
import useCreateScheduleHook from './useCreateScheduleHook';
const CreateScheduleModal = forwardRef(
  ({ classes, open, onClose, locationId, isNewScheduleAdded, setIsNewScheduleAdded }, ref) => {
    const {
      scheduleName,
      scheduleError,
      handleScheduleNameChange,
      createSchedule,
      snackbarOpen,
      snackbarContent,
      handleCloseSnackbar,
    } = useCreateScheduleHook({
      ref,
      locationId,
      isNewScheduleAdded,
      setIsNewScheduleAdded,
    });
    return (
      <>
        <Modal
          ref={ref}
          open={open}
          onClose={onClose}
          heading="Create Group Schedule"
          showCrossIcon={false}
          actionBtnText="Create Schedule"
          cancelBtnText="Cancel"
          onContinue={createSchedule}
          styles={{
            mainWrapperStyles: {
              height: 'auto',
            },
          }}
        >
          <Typography className={classes.mainBody}>
            You will be able to edit schedule details after creating a group name.
          </Typography>
          <TextField
            id="schedule-name"
            label="Group Name"
            variant="outlined"
            value={scheduleName}
            error={scheduleError}
            helperText={scheduleError}
            onChange={handleScheduleNameChange}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.inputLabel,
              },
            }}
            fullWidth
          />
        </Modal>

        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <MuiAlert elevation={6} variant="filled" severity={snackbarContent.severity ? 'success' : 'error'}>
            {snackbarContent.message}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
);

export default withStyles(styles)(CreateScheduleModal);
