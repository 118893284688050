import { CustomTheme } from '@orijinworks/frontend-commons';

export default (theme) => ({
  copyDialog: {
    "& button": {
      display: "flex",
      margin: "5px auto 0",
    },
    "& fieldset": {
      padding: 10,
    },
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
    },
  },
  dayOfWeek: {
    fontSize: 15,
    color: "#3d3d3d",
    textTransform: "uppercase",
    letterSpacing: "0.98px",
  },
  dayOfWeekWrapper: {
    minWidth: 125,
    marginRight: 10,
  },
  scheduleWrapper: {
    minHeight: 40,
    flexWrap: "wrap",
    "& .MuiAutocomplete-listbox li": {
      padding: 5,
    },
  },
  scheduleTextInput: {
    maxWidth: 95.5,
    "& .MuiInputAdornment-root": {
      display: "none",
    },
  },
  scheduleHeader: {
    display: "flex",
    padding: "10px 20px 10px 0",
    textAlign: "right",
  },
  scheduleIconWrapper: {
    marginRight: 10,
  },
  activeIcon: {
    color: CustomTheme.palette.primary.main,
  },
  divider: {
    width: 7.5,
    margin: "0px 10px",
    backgroundColor: "black",
    height: 1,
  },
  verticalDivider: {
    width: 1,
    height: 36,
    marginLeft: 10,
    marginRight: 25,
    backgroundColor: "#979797",
  },
  slotReadOnly: {
    minWidth: 94,
    boxSizing: "border-box",
    textAlign: "center",
  },
  readOnlyDivider: {
    margin: 0,
  },
  label: {
    width: 123.3,
  },
  editButtons: {
    marginLeft: "auto",
  },
  errorMessage: {
    display: "flex",
    fontSize: 16,
    color: "#A30000",
    letterSpacing: "0.29px",
    margin: "auto 0",
    "& svg": {
      color: "#C9190B",
      fontSize: "35px",
      height: "34.5px",
      margin: "auto 15px auto 28px;",
      width: "40px",
    },
    "& p": {
      margin: "auto 0",
    },
  },
});
