import React from 'reactn'
import {Paper, Typography, Dialog, Button, DialogContent, DialogActions, DialogTitle, IconButton } from '@material-ui/core'
import Datatable from 'material-table'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import SortIcon from '@material-ui/icons/Sort'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import FilterListIcon from '@material-ui/icons/FilterList'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import CompareIcon from '@material-ui/icons/Compare'
import ReactChartkick, {  LineChart, ColumnChart } from 'react-chartkick'

class UserTotModal extends React.Component {

    state={
        fullscreen: false,
        showSummary: true
    }

    componentDidMount(){
       this.populateColumns()
       this.populateLinechart()
       this.generateTableData()
    }

    generateTableData(){
    }

    populateLinechart(){
        var user = []
        var education = {"name": "Education"}
        var job = {"name":"Workforce Skills"}
        var reentry = {"name": "Reentry"}
        var rehab = {"name": "Rehabilitation"}
        var enrichment = {"name": "Enrichment"}
        var misc = {"name": "Miscellaneous"}
        var entertainment = {"name": "Entertainment"}
        enrichment.data = {}
        job.data = {}
        reentry.data = {}
        rehab.data ={}
        education.data = {}
        misc.data = {}
        entertainment.data={}

        this.props.user.map((_user)=>{
            _user.contentAreas.map((c)=>{
                c.timeOnTaskList.map((t)=>{
                    if (c.contentAreaName === "Enrichment") {
                        enrichment.data[t.date] = t.timespent
                    }
                    if (c.contentAreaName === "Education") {
                        education.data[t.date] = t.timespent
                    }
                    if (c.contentAreaName === "Rehabilitation") {
                        rehab.data[t.date] = t.timespent
                    }
                    if (c.contentAreaName === "Workforce Skills") {
                        job.data[t.date] = t.timespent
                    }
                    if (c.contentAreaName === "Miscellaneous") {
                        misc.data[t.date] = t.timespent
                    }
                    if (c.contentAreaName === "Reentry") {
                        reentry.data[t.date] = t.timespent
                    }
                    if (c.contentAreaName === "Entertainment") {
                        entertainment.data[t.date] = t.timespent
                    }
                })
            }) 
        })

        user.push(education, rehab, job, reentry, enrichment, entertainment, misc)
        this.setState({
            linechartData: user
        })
    }

    populateColumns(){
        var chartData = []
        var total = 0
        var totalArr = ["Total"]
        var enrichment = 0
        var enrichmentArr = ["Enrichment"]
        var education = 0
        var educationArr = ["Education"]
        var rehab = 0
        var rehabArr = ["Rehab"]
        var reentryArr = ["Reentry"]
        var reentry = 0
        var miscArr = ["Misc."]
        var misc = 0
        var jobArr = ["Job"]
        var entertainment =0
        var entArr = ["Entertainment"]
        var job = 0
        this.props.user.map((_user)=>{
            _user.contentAreas.map((c)=>{
                if (c.contentAreaName === "Enrichment") {
                    enrichment += (c.totalTimespent)
                }
                if (c.contentAreaName === "Education") {
                    education += (c.totalTimespent)
                }
                if (c.contentAreaName === "Rehabilitation") {
                    rehab += (c.totalTimespent)
                }
                if (c.contentAreaName === "Workforce Skills") {
                    job += (c.totalTimespent)
                }
                if (c.contentAreaName === "Miscellaneous") {
                    misc += (c.totalTimespent)
                }
                if (c.contentAreaName === "Reentry") {
                    reentry += (c.totalTimespent)
                }
                if (c.contentAreaName === "Entertainment") {
                    entertainment += (c.totalTimespent)
                }
                total += (c.totalTimespent)
            })
            })
        enrichmentArr.push(enrichment)
        educationArr.push(education)
        jobArr.push(job)
        reentryArr.push(reentry)
        rehabArr.push(rehab)
        miscArr.push(misc)
        totalArr.push(total)
        entArr.push(entertainment)

        chartData.push(totalArr)
        chartData.push(educationArr)
        chartData.push(jobArr)
        chartData.push(reentryArr)
        chartData.push(rehabArr)
        chartData.push(enrichmentArr)
        chartData.push(entArr)
        chartData.push(miscArr)
        this.setState({chartData:chartData})
    }

    openFullscreen = () =>{
        this.setState({
            fullscreen: true
        })
    }

    closeFullscreen = () =>{
        this.setState({
            fullscreen: false
        })
    }

    render(){
        const columns=[
            {title: 'User Name',field: 'displayName'},
            {title: 'User Id', field: 'personId'},
            {title: 'Learning Object', field: 'learning_object'},
            {title: 'Content Area', field:'content_area'},
            {title: 'Service Name', field: 'service_name'},
            {title: 'Time Spent', field: 'time_spent_min'},
            {title: 'Date', field: 'time_spent_date'},
        ]
        return(
            <Dialog open={this.props.open} fullScreen={this.state.fullscreen} onClose={this.props.close}>
                <DialogTitle>
                    <Typography variant="h3" style={{float:'left'}}>{this.props.user[0].displayName}</Typography>
                    <IconButton style={{float:"right"}}>{this.state.fullscreen ? <FullscreenExitIcon onClick={this.closeFullscreen}/> : <FullscreenIcon onClick={this.openFullscreen}/>}</IconButton>
                </DialogTitle>
                <DialogContent>
                    <div style={{float:'left'}}>
                        <Button color={this.state.showSummary ? "inherit" : "primary"} onClick={()=>this.setState({showSummary: true, showDetails:false})}>Summary</Button>
                        <Button color={this.state.showDetails ? "inherit" : "primary"} onClick={()=>this.setState({showSummary:false, showDetails:true})}>Details</Button>
                    </div>
                    <div style={{float:'right'}}>
                        <Button color="primary" variant="text" onClick={()=>{this.setGlobal({selectedUser:this.props.user[0].personId, openProfileView: true}) }}>Profile</Button>
                    </div>
                    {this.state.chartData && this.state.showSummary ? 
                        <ColumnChart ytitle="Time Spent (hours)" data={this.state.chartData}/>
                        : 
                        <LineChart stacked={true} ytitle="Time Spent (hours)" data={this.state.linechartData}/>
                    }
                    {/* <Datatable
                        title={`${this.props.user[0].user_name.toUpperCase()} Time on Task`}
                        data={this.props.user}
                        columns={columns}
                        icons={{
                        Search: SearchIcon,
                        ResetSearch: CloseIcon,
                        FirstPage: FirstPageIcon,
                        LastPage: LastPageIcon,
                        NextPage: NavigateNextIcon,
                        PreviousPage: NavigateBeforeIcon,
                        Filter: FilterListIcon,
                        Sort: SortIcon,
                        Export: SaveAltIcon,
                        Edit: EditIcon,
                        User: AccountCircleIcon
                        }}
                        options = {{ 
                        pageSize: 20,
                        selection: true,
                        sorting: true,
                        exportButton: true,
                        exportAllData: true,
                        
                        }}
                   /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick = {this.props.close}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }

}

export default UserTotModal