import React from 'react';
import useUnleashClientHook from './useUnleashClientHook';
import { connect } from 'react-redux';

/**
 * HOC to attach the unleash client to the wrapped component. 
 * Can be used to check the status of multiple unleash feature
 * flags in the wrapped component.
 * 
 * @param {component} WrappedComponent - The component to wrap 
 * @returns {component} HOC - The higher order component
 */
const withUnleashClient = (WrappedComponent) => {
  const HOC = (props) => {
    const { userDetail, selectedLocation } = props;
    const { getClient } = useUnleashClientHook({
      userId: userDetail.userId,
      username: userDetail.userName,
      selectedLocation: selectedLocation,
    });

    return <WrappedComponent {...props} unleashClient={getClient} />;
  };

  const mapStateToProps = ({ app }) => ({
    userDetail: app.userDetail,
    selectedLocation: app.selectedLocation,
  });

  return connect(mapStateToProps)(HOC);
};

export default withUnleashClient;
