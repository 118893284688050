import React, { useEffect, useState, useCallback, useMemo } from "react";
import Connections from "../management/friends-and-family-messaging/containers/connections/connections";
import Messages from "../management/friends-and-family-messaging/containers/messages/messages";
import { History, Contacts } from "@material-ui/icons";
import { SELECTED_HOUSING_UNIT, SELECTED_LOCATION, setJanusToken } from "../../constants";
import { fetchJanusToken } from "../../util/APIUtils";

const MemoizedConnections = React.memo(Connections);
const MemoizedMessages = React.memo(Messages);

export const UseFriendsAndFamilyHook = props => {

  const [isLoading, setLoading] = useState(true);
  const [timezoneName, setTimeZone] = useState('UTC');
  const [location, setLocation] = useState(0);
  
  const fetchJanusTokenData = useCallback(async () => {
    try {
      let location = 0;
      if(SELECTED_HOUSING_UNIT && SELECTED_HOUSING_UNIT.locationId) {
        location = SELECTED_HOUSING_UNIT;
      } else if(SELECTED_LOCATION && SELECTED_LOCATION.locationId) {
        location = SELECTED_LOCATION;
      }
      if(location.locationId) {
        const janusToken = await fetchJanusToken(location.locationId);
        setJanusToken(janusToken.token || '');  
        setLoading(false);  
        setLocation(location);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getLocationTimezone = useCallback(() => {
    if(location && location.attributes) {
      const locationAttribute = location.attributes.find(attr => attr.attributeName === 'TimeZone');
      if (locationAttribute) {
        setTimeZone(locationAttribute.attributeValue);
      }    
    }
  }, [location]);
  
  useEffect(() => {
    fetchJanusTokenData();
    getLocationTimezone();
  }, [SELECTED_HOUSING_UNIT && SELECTED_HOUSING_UNIT.locationId, SELECTED_LOCATION && SELECTED_LOCATION.locationId]);


  const tabConfig = useMemo(() => ([
    {
      label: 'Connections',
      value: '1',
      id: 'connections',
      component: () => <MemoizedConnections timezoneName={timezoneName} locationId={location.locationId}/>,
      Icon: Contacts,
    },
    {
      label: 'Message Auditing',
      value: '2',
      id: 'messages',
      component: () => <MemoizedMessages timezoneName={timezoneName} locationId={location.locationId} />,
      Icon: History,
    },
  ]), [timezoneName, location.locationId]);


  return {
    isLoading,
    tabConfig
  }
};

