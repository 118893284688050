export default theme => ({
  content: {
    padding: '20px 30px 0px',
    textAlign: 'left',
    marginBottom: 30,
    backgroundColor: '#f3f3f3',
    [theme.breakpoints.down('md')]: {
      padding: '0px 30px'
    }
  },
  tabContent: {
    marginTop: 40,
    backgroundColor: '#f3f3f3'
  },
  collapseBtn: {
    color: theme.palette.text.red,
    borderColor: '#d73434'
  },
  overrideTransform: {
    transform: 'none !important'
  },
  card: {
    width: '100%',
    borderRadius: 0,
    marginBottom: 1
  },
  cardContent: {
    padding: '0px 25px'
  },
  cardHeading: {
    fontWeight: 700,
    fontSize: 20,
    color: '#3d3d3d',
    letterSpacing: '0.35px'
  },
  warnCard: {
    background: theme.palette.secondary.dark,
    color: 'white',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center'
  },
  boxHeading1: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#3d3d3d',
    letterSpacing: '0.36px'
  },
  icon: {
    color: '#3d3d3d',
    transition: 'all 0.2s',
    transform: 'initial'
  },
  expanded: {
    transform: 'rotate(90deg)'
  },
  subHeading1: {
    fontSize: 12,
    color: '#747474',
    letterSpacing: '0.21px'
  },
  outlinedComponent: {
    width: '100%',
    borderRadius: 0,
    marginBottom: 1
  },
  largeIcon: {
    width: '1.2em',
    height: '1.2em'
  },
  disabledComponent: {
    backgroundColor: '#f2f2f2',
    pointerEvents: 'none',
    cursor: 'not-allowed'
  },
  root: {
    minWidth: 610,
    borderRadius: 0
  },
  dialogTitle: {
    ...theme.typography.t16,
    height: 31,
    fontWeight: 501,
    fontStretch: 'normal;',
    fontStyle: 'normal;',
    lineHeight: 'normal;',
    letterSpacing: '0.07px;',

    marginTop: 8,
    marginLeft: 8
  },
  dialogcontent: {
    marginTop: 8,
    marginLeft: 8,
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 14
  },
  subHeaderChildContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  chip: {
    backgroundColor: '#d3d3d3',
    padding: '5px 10px',
    borderRadius: 3,
    marginLeft: 5
  },
  blue: {
    backgroundColor: theme.palette.primary.cobalt,
    color: theme.palette.primary.white
  },
  chipText: {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.25px'
  },
  chipActive: {
    color: 'white'
  },
  chipInActive: {
    color: 'black'
  }
});
