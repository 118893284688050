import { useEffect, useState } from 'react';
import { createContact, listUsers } from '../../../../util/APIUtils';
import store from '../../../../v2/core/store';
import UnleashService from '../../../../v2/services/unleash/unleash.service';
import isFeatureFlagOn from '../../../../v2/services/useFeatureFlagOnHook';
import { VOICE_CALL } from '../../constants/voice-call-constants';

const useAddContactModalHook = (locationId, learnerProfile = {}, setDataRefresh) => {
  const { app } = store.getState();
  const { userDetail } = app;
  const [showAddContactSnackBar, setShowAddContactSnackbar] = useState(false);
  const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(6000);
  const [snackbarMessageSnackbar, setSnackbarMessage] = useState('');
  const [addContactSuccessSnackbar, setAddContactSuccess] = useState(false);
  const [learners, setLearners] = useState([]);
  const [searchLearnerLoading, setSearchLearnerLoading] = useState(false);
  const [learnerListFetched, setLearnerListFetched] = useState(false);
  const isFeatureFlagEnabledForArchiveUnArchiveToggle = isFeatureFlagOn(UnleashService.FLAGS.ARCHIVE_UNARCHIVE_TOGGLE);
  const [addContactModal, setAddContactModal] = useState(false);
  const [contact, setContact] = useState({
    personId: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    relationshipType: '',
    preferredLanguage: '',
    contactStatus: VOICE_CALL.STATUS.PENDING,
    errors: {},
    isSubmitted: false,
  });

  useEffect(() => {
    if (learnerProfile && learnerProfile.learnerId) {
      setContact((prevContact) => ({
        ...prevContact,
        personId: learnerProfile.learnerId,
      }));
    }
  }, [learnerProfile, addContactModal]);

  const handleAddModalOpen = () => {
    if (learners.length === 0) {
      getUsers();
    }
    setAddContactModal(true);
  };

  const handleSearch = (value) => {
    if (value && value.personId !== undefined) {
      setContact((prevContact) => ({
        ...prevContact,
        personId: value.personId,
      }));
    }
  };

  const handleAddModalClose = () => {
    setAddContactModal(false);
    setContact({
      personId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      relationshipType: '',
      preferredLanguage: '',
      contactStatus: VOICE_CALL.STATUS.PENDING,
      errors: {},
      isSubmitted: false,
    });
  };

  const handleContactChange = (event) => {
    const { name, value } = event.target;
    setContact((prevContact) => ({
      ...prevContact,
      [name]: value,
    }));
  };

  const getUsers = async () => {
    setSearchLearnerLoading(true);
    try {
      let postBody = {
        requestorId: userDetail.personId,
        requestorLocationId: locationId,
        filterLocationTypeIds: [],
        filterRoleTypeIds: [19, 30],
        filterLocationIds: [],
        filterUserStatus: 'active',
      };

      const data = await listUsers(postBody);
      setSearchLearnerLoading(false);
      setLearnerListFetched(true);
      setLearners(data.users);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLearners([]);
    }
  };

  const handleAddContact = async () => {
    try {
      const { isValid, errors } = validateFields(contact);
      if (!isValid) {
        setContact((prevContact) => ({
          ...prevContact,
          isSubmitted: true,
          errors,
        }));

        return false;
      }
      const { firstName, lastName, personId, contactStatus, phoneNumber, relationshipType, preferredLanguage } = contact;

      const payload = {
        firstName,
        lastName,
        phoneNumber: '+1' + phoneNumber,
        relationshipType,
        preferredLanguage,
        learnerPersonKey: personId,
        status: contactStatus,
      };
      const response = await createContact(payload);
      if (response && response.errorMessage) {
        setAddContactSuccess(false);
        handleSnackbar(response.errorMessage);
      } else {
        setAddContactSuccess(true);
        setAddContactModal(false);
        setDataRefresh(true);
        setContact({
          personId: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          relationshipType: '',
          preferredLanguage: '',
          contactStatus: VOICE_CALL.STATUS.PENDING,
          errors: {},
          isSubmitted: false,
        });
        handleSnackbar('Contact added successfully!');
      }
    } catch (e) {
      handleSnackbar(e.message);
    }
  };

  const validateFields = (contactValidate) => {
    let isValid = true;
    const fields = [
      'firstName',
      'lastName',
      'phoneNumber',
      'relationshipType',
      'personId',
      'preferredLanguage',
      'contactStatus',
    ];
    const errorMsgs = {
      personId: 'Please select the learner',
      preferredLanguage: 'Please enter the preferred language',
      contactStatus: 'Please enter the contact status',
      firstName: 'Please enter the first name.',
      lastName: 'Please enter the last name.',
      phoneNumber: 'Please enter a valid US phone number.',
      relationshipType: 'Please select a relationship.',
    };
    const errors = {};

    // EMPTY FIELD CHECK
    fields.forEach((title) => {
      if (!contactValidate[title]) {
        isValid = false;
        errors[title] = errorMsgs[title];
      }
    });

    // VALIDATING US PHONE NUMBER
    if (!VOICE_CALL.isValidUSPhoneNumber(contactValidate.phoneNumber)) {
      isValid = false;
      errors['phoneNumber'] = errorMsgs['phoneNumber'];
    }
    return { errors, isValid };
  };

  const handleSnackbar = (message) => {
    setSnackbarMessage(message);
    setShowAddContactSnackbar(true);
    setSnackbarAutoHideDuration(3000);
  };

  const handleAddModalCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowAddContactSnackbar(false);
  };

  function getRelationshipTypes() {
    return VOICE_CALL.getRelationshipTypes();
  }

  return {
    showAddContactSnackBar,
    snackbarAutoHideDuration,
    snackbarMessageSnackbar,
    addContactSuccessSnackbar,
    isFeatureFlagEnabledForArchiveUnArchiveToggle,
    addContactModal,
    contact,
    learners,
    searchLearnerLoading,
    learnerListFetched,
    handleAddModalOpen,
    handleAddModalClose,
    handleContactChange,
    handleAddContact,
    handleAddModalCloseSnackbar,
    handleSnackbar,
    handleSearch,
    setContact,
    getRelationshipTypes,
  };
};

export default useAddContactModalHook;
