import React, {Component} from 'react'


class ProgramSettings extends Component{

    render(){

        return(

            <div>
                <h2>Program Settings</h2>
            </div>


        )
    }
}


export default ProgramSettings