export default theme => ({
    rolesDetailContainer: {
      color: '#000',
      textAlign: 'initial',
      padding: '10px 40px',
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      minHeight: '80vh'
    }
});