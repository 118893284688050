import React from "react";

const ChatIcon = props => {
  const { size, stroke } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_1123" data-name="Rectangle 1123" width="24" height="20.753" fill="none" stroke={stroke} strokeWidth="2"/>
        </clipPath>
        <clipPath id="clip-Staff_Messaging_24x24">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Staff_Messaging_24x24" data-name="Staff Messaging – 24x24" clipPath="url(#clip-Staff_Messaging_24x24)">
        <g id="Group_1216" data-name="Group 1216" transform="translate(0 1.624)">
          <g id="Group_1215" data-name="Group 1215" clipPath="url(#clip-path)">
            <path id="Path_519" data-name="Path 519" d="M6.522,2H4.4A2.4,2.4,0,0,0,2,4.4v9.911a2.4,2.4,0,0,0,2.4,2.4H8.164a2.4,2.4,0,0,1,1.7.7L12.8,20.353l2.939-2.939a2.4,2.4,0,0,1,1.7-.7H21.2a2.4,2.4,0,0,0,2.4-2.4V4.4A2.4,2.4,0,0,0,21.2,2H9.451" transform="translate(-0.8 -0.8)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <ellipse id="Ellipse_66" data-name="Ellipse 66" cx="0.261" cy="0.272" rx="0.261" ry="0.272" transform="translate(16.611 8.667)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <ellipse id="Ellipse_67" data-name="Ellipse 67" cx="0.261" cy="0.272" rx="0.261" ry="0.272" transform="translate(7.295 8.667)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <ellipse id="Ellipse_68" data-name="Ellipse 68" cx="0.261" cy="0.272" rx="0.261" ry="0.272" transform="translate(11.953 8.667)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

ChatIcon.defaultProps = {
  size: 24,
  stroke: "inherit"
}

export default ChatIcon;
