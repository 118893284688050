
import {CustomTheme} from "@orijinworks/frontend-commons";
export default theme => ({
  content: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      padding: '0px 30px'
    }
  },
  locationSelectorWrapper: {
    width: 491,
    height: 74,
    backgroundColor: 'white',
    boxShadow: '0px 4px 12px 1px #848080eb',
    padding: '0px 30px',
    boxSizing: 'border-box'
  },
  tabContent: {
    backgroundColor: '#f3f3f3',
    flex: 1
  },
  tabItem: {
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '0.77px',
    marginLeft: 40,
    whiteSpace: 'nowrap',
    fontFamily: theme.typography.fontFamily
  },
  activeTab: {
    borderBottom: '2px solid ' + CustomTheme.palette.primary.main,
    color: theme.palette.text.navy,
    fontWeight: '700'
  },
  locationIcon: {
    color: CustomTheme.palette.primary.main
  },
  locationSelectBox: {
    width: '100%',
    color: theme.palette.text.navy,
    fontSize: 25,
    fontWeight: 'bold',
    marginLeft: 5,
    '&:before': {
      borderBottom: 'none'
    },
    '& > svg': {
      color: '#000000',
      fontSize: 40,
      top: 0
    }
  }
});
