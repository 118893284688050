export default theme => ({
    mainDialog: {
      width: 293,
      maxWidth: "100%",
      padding: 20
    },
    bodyText: {
      ...theme.typography.t14,
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.6)",
      margin: "10px 0px"
    },
    darkText: {
      ...theme.typography.bold,
      ...theme.typography.t14,
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: theme.palette.primary.jetBlack
    },
    keyValuePairText: {
      margin: "0px 0px"
    },
    cartWrapper: {
      margin: "0px 0px"
    },
    dialogFooter: {
      marginTop: 4
    },
    confirmBtn: {
      textTransform: "capitalize",
      ...theme.typography.t18,
      ...theme.typography.bold,
      lineHeight: "16px",
      letterSpacing: "1px"
    }
  });
  