import React from 'reactn';
import { useGlobal } from 'reactn';
import StaffProfile from './staff-profile';
import Learner from '../../../users/learner/Learner';
import { withRouter } from "react-router-dom";

const Profile = (props) => {
  const [global] = useGlobal();

  return (
    <>
        {global.selectedUser ?
            <Learner data-testid="learner-component" />
            : 
            <StaffProfile data-testid="staff-profile-component" {...props} history={ props.history } />
        }
    </>
  );
}

export default withRouter(Profile);
