import React, { Component, Suspense } from 'reactn';
import {
    withStyles,
    Card,
    CardActionArea,
    Grid,
    Snackbar
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Loader } from '@orijinworks/frontend-commons';
import styles from './index.style'
import WarningIcon from '@material-ui/icons/Warning'
import { PermissionControl } from '../../permission-control/permission-control';
import { fetchJanusToken } from '../../../util/APIUtils';
import { setJanusToken } from '../../../constants';

const EditLocation = React.lazy(() => import('./editLocation'));
const ManageHousingUnits = React.lazy(() => import('./manageHousingUnits'))
const Settings = React.lazy(() => import('../../management/settings'));
const FacialAuthenticationSettings = React.lazy(() => import('./facialAuthenticationSettings'))

export class LocationSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoading: false,
            selectedLocations: [],
            snackBar: {
                open: false,
                snackBarMsg: null
            }
        }
    }

    async componentDidMount() {
        if (this.props.location.state) {
            const selectedLocations = this.props.location.state
            const janusToken = await fetchJanusToken(selectedLocations[0].locationId);
            setJanusToken(janusToken.token || "");
            this.setState({ selectedLocations })
        } else {
            this.props.history.push("/manageLocations")
        }
    }

    toggleSnackBar = (msg, state = true) => {
        this.setState({
            snackBar: {
                open: state,
                snackBarMsg: msg
            }
        });
    };

    render() {
        const { classes } = this.props
        return (
            <Suspense fallback={<Loader isLoading={true} />}>
                <Grid
                    container
                    item
                    component="div"
                    className={classes.content}
                >
                    <Snackbar
                        onClose={() => this.toggleSnackBar(null, false)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.snackBar.open}
                        autoHideDuration={2000}
                        message={this.state.snackBar.snackBarMsg}
                    />
                  <Grid container spacing={3}>
                    <Grid item container>
                        <Loader isLoading={this.state.dataLoading} />
                    </Grid>
                        {
                            this.state.selectedLocations.length > 0 &&
                            <Grid item container>
                                {
                                    this.state.selectedLocations.length === 1
                                    ? <EditLocation
                                        selectedLocation={this.state.selectedLocations[0]}
                                    />
                                    : <Card elevation={7} style={{width: '100%'}}>
                                        <CardActionArea className={classes.warnCard}>
                                            <WarningIcon style={{fontSize:30, marginRight: '15px'}}/>
                                            {
                                                "This will overwrite current settings for selected locations. Any existing settings will be lost.\n" +
                                                "If facility settings are off, they will take precedence over housing units settings."
                                            }
                                        </CardActionArea>
                                    </Card>
                                }
                                <Grid container item>
                                    <PermissionControl permissionName="Access_Location_Settings">
                                        <Settings
                                            showSnackBar={this.toggleSnackBar}
                                            facility
                                            location={{
                                                ...this.state.selectedLocations[0],
                                                active: this.state.selectedLocations[0].locationId,
                                                type: this.state.selectedLocations[0].locationTypeId === 15 ? 'hu' : 'facility',
                                                parentLocationName: this.state.selectedLocations[0].parent
                                            }}
                                            generalSectionTitle="Access"
                                        />
                                    </PermissionControl>
                                </Grid>
                                {
                                    this.global.isSuperAdmin &&
                                    <FacialAuthenticationSettings
                                        locationId={this.state.selectedLocations[0].locationId}
                                    />
                                }
                                {
                                    this.state.selectedLocations.length === 1 && this.state.selectedLocations[0].locationTypeId === 3 &&
                                    <ManageHousingUnits
                                        locationId={this.state.selectedLocations[0].locationId}
                                        locationName={this.state.selectedLocations[0].locationName}
                                        showSnackBar={this.toggleSnackBar}
                                    />
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Suspense>
        );
    };
}

export default withStyles(styles)(withRouter(LocationSettings));
