import React from 'react';
import { useResetBioAuthModal } from './useResetBioAuthModal';
import { Modal, Typography } from '@orijinworks/frontend-commons';
import { Box, withStyles } from '@material-ui/core';
import styles from './reset-bioauth-modal.styles';
import { ReplayIcon } from '../MaterialUIIcons';

const ResetBioauthModal = (props) => {
  const { successfulUsers, failedUsers, classes, isOpen } = props;
  const modalRef = React.useRef();
  const { onContinue, onCloseHandler } = useResetBioAuthModal(props, modalRef);

  return (
    <Modal
      ref={modalRef}
      isSnackBarShown={false}
      cancelBtnText="Close"
      actionBtnText={
        <>
          <ReplayIcon size={15} /> &nbsp; Retry
        </>
      }
      heading="Reset Biometric Authentication"
      labelledBy="Reset Biometric Authentication"
      describedBy="Reset Biometric Authentication"
      open={isOpen}
      onClose={onCloseHandler}
      onContinue={onContinue}
    >
      <Box display="flex" flexDirection="column" padding="0px 10px" height="100%">
        <Box>
          <Typography color="secondary" variant="h6">
            Partial Reset Successful
          </Typography>
          <Typography
          id="reset-bioauth-modal-description"
          className={classes.typo} variant="body6">
            Biometric Authentication reset was successsful for{' '}
            <Box className={classes.successfulUserCount} component="span">
              {successfulUsers.length}
            </Box>{' '}
            learners but failed for{' '}
            <Box className={classes.failedUserCount} component="span">
              {failedUsers.length}
            </Box>{' '}
            learners.
          </Typography>
        </Box>
        <Box className={[classes.failedUserBox, ''].join(' ')}>
          <Typography color="secondary" variant="h6">
            Reset failed for the following learners:
          </Typography>
          <Box className={'side-menu-scrollbar'} height="100%" overflow="auto" display="flex" flexDirection="column">
            {failedUsers.map((user) => (
              <Typography key={user.username} 
              id={`failed-user-${user.username}`}
              className={[classes.username, classes.typo].join(' ')} variant="body6">
                {user.username}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(ResetBioauthModal);
