import React from "react";

import {
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import RoleTypes from "./RoleTypes";
import styles from "./index.styles";
import { createRole } from "../../../util/APIUtils";

const useStyles = makeStyles((theme) => styles(theme));

const AddNewRoleDialog = ({ open, handleClose, history }) => {
  const classes = useStyles();
  const [roleName, setRoleName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [role_type_id, setRoleType] = React.useState("");
  const [duplicateError, setDuplicateError] = React.useState(false);

  const excessRoleNameChar = roleName.length > 128;
  const excessDescriptionChar = description.length > 512;
  const isRoleNameEmpty = roleName.length === 0;
  const isRoleTypeEmpty = role_type_id !== "" ? false : true;
  const regex = /[^a-zA-z0-9()/ -]/g;
  const hasSpecialChar = regex.test(roleName);

  let roleNameHelperText = "";
  let descriptionHelperText = "";

  if (excessRoleNameChar) {
    roleNameHelperText = "Role name should not exceed 128 characters";
  } else if (duplicateError) {
    roleNameHelperText =
      "Role name already exists. Please try a different name";
  } else if (hasSpecialChar) {
    roleNameHelperText = "Role name cannot contain special characters";
  }

  if (excessDescriptionChar) {
    descriptionHelperText = "Description should not exceed 512 characters";
  }

  const handleRoleNameChange = (event) => {
    setRoleName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleRoleTypeChange = (event) => {
    setRoleType(event.target.value);
  };

  const handleCancelClick = () => {
    setRoleName("");
    setDescription("");
    setDuplicateError(false);
    setRoleType("");
    handleClose();
  };

  const handleSaveClick = async () => {
    try {
      const res = await createRole({
        roleDesc: description,
        roleName,
        roleTypeKey: role_type_id,
      });
      history.push(
        `/manageRoles/${encodeURIComponent(roleName)}/${res.roleId}`
      );
      handleCancelClick();
    } catch (error) {
      if (error.status === 409) {
        setDuplicateError(true);
      }
    }
  };
  return (
    <Dialog fullWidth open={open} onClose={handleCancelClick}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Add New Role
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCancelClick}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent className={classes.dialogContent}>
        <TextField
          autoFocus
          className={classes.field}
          error={excessRoleNameChar || duplicateError || hasSpecialChar}
          id="outlined-error-helper-text"
          label="Role"
          helperText={roleNameHelperText}
          variant="outlined"
          onChange={handleRoleNameChange}
          value={roleName}
          InputProps={{
            classes: {
              root: classes.outlinedField,
              input: classes.input,
            },
          }}
        />

        <TextField
          multiline
          rows={3}
          error={excessDescriptionChar}
          helperText={descriptionHelperText}
          value={description}
          label="Description"
          variant="outlined"
          onChange={handleDescriptionChange}
          classes={{
            root: classes.textAreaRoot,
          }}
          InputProps={{
            classes: {
              root: classes.outlinedTextArea,
              input: classes.textAreaFont,
            },
          }}
        />
        <RoleTypes handleRoleTypeChange={handleRoleTypeChange} />
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          onClick={handleSaveClick}
          color="primary"
          variant="contained"
          className={classes.saveBtn}
          disabled={
            excessRoleNameChar ||
            excessDescriptionChar ||
            isRoleNameEmpty ||
            hasSpecialChar ||
            isRoleTypeEmpty
          }
        >
          Add Role
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(AddNewRoleDialog);
