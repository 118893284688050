import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ITEM_HEIGHT = 48;
const CustomMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = !!anchorEl;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        id="menu-button"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {props.buttonIcon ? props.buttonIcon : <MoreVertIcon />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {(props.options || []).map((option, index) => (
          <MenuItem
            disabled={option.disabled}
            data-cy-name={option.cyprusId}
            key={option}
            id={`menu-item-${index}`}
            onClick={() => {
              setAnchorEl(null);
              option.onClick();
            }}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CustomMenu;
