import React from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import useStyles from "./snack-bar-toast.style";

function SnackBarToast(props) {
  const classes = useStyles();
  const snackBarHidden = props.isCourseActive ? true : false;
  const action = null;
  const message = "This course has been disabled. Task items can no longer be launched";

  if (snackBarHidden) return null;

  return (
    <SnackbarContent
      className={classes.snackBar}
      message={message}
      action={action}
    />
  );
}

export default SnackBarToast;
