import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    padding: "40px 40px 10px 40px",
    flexDirection: "column",
    backgroundColor: "white",
    borderBottom: "30px solid #0F445A"
  },
  reportHeader: {
    display: "flex",
    justifyContent: "left",
    width: "100%",
    alignItems: "center",
    padding: "0px 10px",
    color: "#0E445A", // TODO: with new theme use theme.palette.primary.main
    fontFamily: "DM Sans" // TODO: with new theme remove this and inherit it from the body
  },
  brandLogo: {
    width: 70,
    height: 70,
    margin: 0
  },
  titleContainer: {
    marginLeft: 15,
    display: "flex",
    flexDirection: "column",
    flexGrow: 9
  },
  title: {
    fontWeight: theme.typography.bold.fontWeight,
    margin: 0
  },
  subTitle: {
    fontWeight: theme.typography.bold.fontWeight,
    margin: 0,
    marginTop: 5,
    textTransform: "uppercase"
  },
  bold: {
    fontWeight: 700
  },
  semiBold: {
    fontWeight: 500
  },
  fontDMSans: {
    fontFamily: "DM Sans" // TODO: with new theme remove this and inherit it from the body
  },
  detailContainer: {
    display: "flex",
    marginRight: 35,
    alignSelf: "flex-end"
  },
  detailLabels: {
    marginRight: 10,
    fontSize: theme.typography.t7.fontSize,
    fontWeight: theme.typography.bolder.fontWeight,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    textTransform: "uppercase",
    letterSpacing: "1.05px"
  },
  detailValues: {
    fontSize: theme.typography.t12.fontSize,
    fontWeight: theme.typography.regular.fontWeight,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.21px"
  },
  detailText: {
    margin: 0,
    height: 20,
    display: "flex",
    alignItems: "center"
  },
  contentAlignRight: {
    justifyContent: "flex-end"
  },
  headerSeperator: {
    width: "100%",
    height: 1,
    backgroundColor: "#0F445A",
    margin: "30px 0px"
  },
  reportBody: {
    display: "flex",
    minHeight: 480,
    flexDirection: "column-reverse"
  },
  reportLeftPane: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
    marginBottom: 5,
    marginTop: 30
  },
  reportRightPane: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 350,
    flex: 1
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    paddingRight: 10,
    paddingLeft: 10,
    color: "#0E445A", // TODO: with new theme use theme.palette.primary.main
    fontFamily: "DM Sans" // TODO: with new theme remove this and inherit it from the body
  },
  tableSubHeader: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "DM Sans", // TODO: with new theme remove this and inherit it from the body
    color: "#3B928C", // TODO: with new theme use theme.palette.text.secondary
    backgroundColor: "#E2E9EF",
    padding: 10,
    alignItems: "center",
    marginTop: 10,
    borderRadius: 5
  },
  courseItem: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    borderBottom: "1px solid #EFF0F4"
  },
  courseRow: {
    display: "flex",
    justifyContent: "space-between"
  },
  courseSubRow: {
    display: "flex",
    justifyContent: "space-between"
  },
  courseField: {
    fontWeight: 500,
    color: "#617C88" // TODO: with new theme use theme.palette.text.sub
  },
  courseSubFieldContainer: {
    display: "flex",
    alignItems: "center",
    width: 100
  },
  courseSubField: {
    fontSize: theme.typography.t9.fontSize,
    fontWeight: theme.typography.regular.fontWeight,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1,
    letterSpacing: "0.16px",
    margin: 0,
    color: "#617C88" // TODO: with new theme use theme.palette.text.sub
  },
  textAlignRight: {
    textAlign: "right"
  },
  textAlignCenter: {
    textAlign: "center"
  },
  width50p: {
    width: "50%"
  },
  width40p: {
    width: "40%"
  },
  width30p: {
    width: "30%"
  },
  width10p: {
    width: "10%"
  },
  tableMidColumnContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  noMargin: {
    margin: 0
  },
  categoryBox: {
    backgroundColor: theme.palette.primary.white,
    padding: "10px 20px",
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    border: "1px solid #DFE6E9",
    borderRadius: 5
  },
  categoryItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px"
  },
  categoryIconFieldContainer: {
    display: "flex",
    alignItems: "center"
  },
  categoryField: {
    color: "#617C88", // TODO: with new theme use theme.palette.text.sub
    fontFamily: "DM Sans" // TODO: with new theme remove this and inherit it from the body
  },
  categorySeperator: {
    width: "100%",
    height: 1,
    backgroundColor: "#DFE6E9",
    margin: 0
  },
  marginLeft2: {
    marginLeft: 2
  },
  marginLeft10: {
    marginLeft: 10
  },
  footerSeperator: {
    width: "100%",
    height: 2,
    backgroundColor: theme.palette.primary.hippieBlue
  },
  footerNote: {
    fontWeight: 500,
    marginTop: 30,
    color: "#0E445A" // TODO: with new theme use theme.palette.primary.main
  },
  seperatorSubSpace: {
    height: 10
  }
}));
