import React, { Component, Suspense } from 'reactn';
import Datatable from 'material-table'
import {
  Grid,
  withStyles,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styles from './index.style';
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import SortIcon from '@material-ui/icons/Sort'
import { Loader } from '@orijinworks/frontend-commons';
import AddNewRoleDialog from '../add-new-role-dialog';
import Hoverable from '../../hoverable';
import { UserIcon } from '../../UserIcon';

class ManageRoles extends Component {
  state = {
    isNewRoleDialogOpen: false
  }
  rolesListColumns = [
    {
      title: 'Roles',
      field: 'roleName',
      render: (rowData) => (
        <Hoverable title={rowData.roleName}>
          <Button
            className={this.props.classes.roleName}
            color="primary"
            style={{
              opacity: rowData.systemDefault ? 0.5 : 1
            }}
            onClick={() => this.props.history.push({
              pathname: `/manageRoles/${encodeURIComponent(rowData.roleName)}/${rowData.roleId}`,
              state: {
                rowData
              }
            })}
          >
            {rowData.roleName}
          </Button>
        </Hoverable>
      ),
    },
    {
      title: 'No of Users',
      field: 'totalUsers',
      render: (rowData) => (
        <Hoverable title={rowData.totalUsers}>
          <Typography
            component="p"
            style={rowData.systemDefault ? { opacity: 0.5 } : null}
          >
            {rowData.totalUsers}
          </Typography>
        </Hoverable>
      ),
    },
    {
      title: 'Description',
      field: 'roleDescription',
      render: (rowData) => (
        <Hoverable title={rowData.roleDescription}>
          <Typography
            component="p"
            style={rowData.systemDefault ? { opacity: 0.5 } : null}
          >
            {rowData.roleDescription}
          </Typography>
        </Hoverable>
      ),


    },
    {
      title: 'Location',
      field: 'locationNames',
      render: (rowData) => (
        <Hoverable title={rowData.locationNames}>
          <Typography
            component="p"
            style={rowData.systemDefault ? { opacity: 0.5 } : null}
          >
            {rowData.locationNames}
          </Typography>
        </Hoverable>
      ),
    },
    {
      title: "Status",
      field: "active",
      lookup: { true: "Active", false: "Inactive" },
      render: rowData => (
        <Hoverable title={rowData.active ? "Active" : "Inactive"}>
          <Button color="primary"> <FiberManualRecordIcon style={rowData.active ? { marginRight: 6, color: "#01980b", height: 11 } : { marginRight: 6, color: "#9b9b9b", height: 11 }} />{rowData.active ? "Active" : "Inactive"}</Button>
        </Hoverable>
      )
    },

  ];

  handleNewRoleDialogClose = () => {
    this.setState({
      isNewRoleDialogOpen: false
    });
  }

  handleNewRoleDialogOpen = () => {
    this.setState({
      isNewRoleDialogOpen: true
    });
  }

  render() {
    const { classes, data, showEmptyDataSourceMessage } = this.props;
    return (
      <Suspense fallback={<Loader isLoading={true} />}>

        <Grid
          container
          item
          className={classes.datatable}>
          <Datatable
            style={{ width: '100%' }}
            columns={this.rolesListColumns}
            data={data}
            components={{
              Toolbar: props => (
                <div>
                  <Box className={classes.titleBox}>
                    <Typography
                      component="span"
                      className={classes.tabName}
                    >
                      Roles
                                    </Typography>

                    <Button
                      className={classes.addBtn}
                      color="primary"
                      variant="contained"
                      onClick={this.handleNewRoleDialogOpen}
                    >
                      <AddIcon />
                    </Button>
                  </Box>
                </div>
              )
            }}
            options={{
              sorting: true,
              pageSize: 20,
              selection: true,
              showSelectAllCheckbox: true,
              filtering: true,
              showEmptyDataSourceMessage: showEmptyDataSourceMessage,
              exportButton: false,
              Sort: SortIcon,
              SortArrow: ArrowDownwardIcon,
              actionsColumnIndex: 0,
              search: false,
              showTitle: false
            }}
            icons={{
              Search: SearchIcon,
              ResetSearch: CloseIcon,
              FirstPage: FirstPageIcon,
              LastPage: LastPageIcon,
              NextPage: NavigateNextIcon,
              PreviousPage: NavigateBeforeIcon,
              Filter: FilterListIcon,
              SortArrow: ArrowUpwardIcon,
              Edit: EditIcon,
              User: UserIcon,
            }}
          />

          <AddNewRoleDialog
            open={this.state.isNewRoleDialogOpen}
            handleClose={this.handleNewRoleDialogClose}
          />
        </Grid>

      </Suspense >
    );
  };
};

export default withStyles(styles)(withRouter(ManageRoles));
