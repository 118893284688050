import React from 'react';
import { Box, Button, Card, Paper, Typography, withStyles } from '../../../../v2/shared/components/MaterialUIComponents';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons/CustomIcons';
import Datatable from 'material-table';
import styles from './learner-group.styles';
import { useLearnerGroupHook } from './useLearnerGroupHook';
import AddNewLearnerGroupDialog from '../add-new-learner-group-dialog/index';

const LearnerGroup = props => {
  const { classes } = props;
  const {
    learnerGroups,
    isLoading,
    editLearnerGroupId,
    isNewLearnerGroupDialogOpen,
    setNewLearnerGroupDialog,
    onDialogClose,
    getLearnerColumns,
    updatedGroupData
  } = useLearnerGroupHook(props);

  return (
    <Card
      classes={{
        root: classes.root
      }}
    >
      <Box className={classes.header}>
        <Box id="module-title">
          <Typography className={classes.heading}>Your Learner Groups</Typography>
        </Box>
        <Box>
          <Button
            id="new-group-button"
            onClick={() => setNewLearnerGroupDialog(true)}
            variant="contained"
            disableElevation
            color="primary"
          >
            <CustomIcons icon={icons.AddIcon} />
            New Group
          </Button>
        </Box>
      </Box>
      <Box>
        <Datatable
          data={learnerGroups || []}
          isLoading={isLoading}
          columns={getLearnerColumns()}
          options={{
            search: false,
            sorting: true,
            filtering: true,
            exportAllData: true,
            toolbar: false,
            pageSize: 20
          }}
          components={{
            Container: props => <Paper {...props} elevation={0} />
          }}
        />

          <AddNewLearnerGroupDialog
            open={isNewLearnerGroupDialogOpen}
            handleClose={onDialogClose}
            editLearnerGroupId={editLearnerGroupId}
            updatedGroupData={updatedGroupData}
          />
      </Box>
    </Card>
  );
};

export default withStyles(styles)(LearnerGroup);
