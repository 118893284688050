import store from '../core/store';
import useUnleashHook from '../core/unleash/useUnleashHook';

const useFeatureFlagOnHook = (flag) => {
    const { app } = store.getState();
    const {userDetail , selectedLocation} = app;

    const unleash = useUnleashHook(flag, userDetail.userName, userDetail.userId, selectedLocation );
    return unleash.isFlagEnabled();
}

export default useFeatureFlagOnHook;