import React from 'react';
import { withStyles, Box, Typography, FormControl } from '../../../shared/components/MaterialUIComponents';

import styles from './personality.style';

const Personality = props => {
  const { classes, questions, getAnswerText } = props;

  return (
    <Box className={classes.root}>
      <Box id="personality-questions-container" className={classes.content}>
        <Typography id="personality-title" color="primary" className={classes.title}>
          Career Goals and Interests
        </Typography>

        {questions.map((question, index) => (
          <FormControl key={question.id} fullWidth variant="outlined" className={index > 0 ? classes.gutterTop : ''}>
            <Typography variant="h6" gutterBottom={!question.helpText}>
              {`${index + 1}. ` + question.question}
            </Typography>
            <Typography variant="h6" className={classes.disabled}>
              {getAnswerText(question.answer, question.answerOptions)}
            </Typography>
          </FormControl>
        ))}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Personality);
