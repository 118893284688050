import { SOCKETIO_RECONNECT_INTERVAL } from '../../../constants';
import { getAccessToken } from '../../core/auth/utils';

const URL = process.env.REACT_APP_MERCURY_URL;
const VOICE_NAMESPACE_URL = `${URL}/voice`;
const { io } = require('socket.io-client');

let voiceSocket = null;
let isConnecting = false;
let pendingRooms = []; // Store rooms to join after connection

const voiceRegisteredFunctions = {
  voice_keyword_detected: null,
};

async function getTokenAndConnect() {
  try {
    const token = await getAccessToken();
    if (!token) throw new Error("Token not found");

    voiceSocket = io(VOICE_NAMESPACE_URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
      reconnection: true,  // Use Socket.IO built-in reconnection
      reconnectionAttempts: 5,
      reconnectionDelay: SOCKETIO_RECONNECT_INTERVAL,
    });

    setupSocketEvents();
  } catch (error) {
    console.error('Failed to connect voice socket:', error);
    throw error;
  }
}

function setupSocketEvents() {
  voiceSocket.on('connect', function () {
    console.log(`Voice Socket ${voiceSocket.id} CONNECTED`);
    processPendingRooms(); // Join any rooms that were queued during connection
  });

  voiceSocket.on('disconnect', function (reason) {
    console.log(`Voice Socket ${voiceSocket.id} DISCONNECTED. Reason: ${reason}`);
  });

  voiceSocket.on('connect_error', (error) => {
    console.error(`Voice Socket connection error:`, error);
  });

  voiceSocket.on('voice_keyword_detected', function (data) {
    handleSocketMessage(data, voiceRegisteredFunctions);
  });
}

function handleSocketMessage(data, handlers) {
  const { action, payload } = data;
  if (handlers[action]) {
    handlers[action](payload);
  }
}

export async function establishVoiceSocketConnection() {
  if (isConnecting || (voiceSocket && voiceSocket.connected)) {
    return;  // If already connecting or connected, skip
  }

  isConnecting = true;
  try {
    await getTokenAndConnect();
  } catch (error) {
    console.error('Error establishing voice socket connection:', error);
  } finally {
    isConnecting = false;
  }
}

export async function joinVoiceRoom(voiceRoomId) {
  if (!voiceRoomId) {
    return;
  }

  if (!voiceSocket || !voiceSocket.connected) {
    console.log('Voice Socket not connected. Queuing room to join after connection...');
    pendingRooms.push(voiceRoomId);
    await establishVoiceSocketConnection();
  } else {
    voiceSocket.emit('message', {
      action: 'join_room',
      room_id: voiceRoomId,
    });
    console.log(`Joined voiceRoomId: ${voiceRoomId} with Voice Socket ${voiceSocket.id}`);
  }
}

async function processPendingRooms() {
  while (pendingRooms.length > 0) {
    const room = pendingRooms.shift();
    console.log(`Processing pending room: ${room}`);
    voiceSocket.emit('message', {
      action: 'join_room',
      room_id: room,
    });
  }
}

export async function joinVoiceRooms(voiceRoomIds) {
  for (const voiceRoomId of voiceRoomIds) {
    await joinVoiceRoom(voiceRoomId);
  }
}

export function registerVoiceAction(callback) {
  voiceRegisteredFunctions[callback.event] = callback.action;
}

export function removeVoiceAction(event) {
  voiceRegisteredFunctions[event] = null;
}

export function disconnectVoiceSocket() {
  if (voiceSocket) {
    voiceSocket.disconnect();
  }
}
