import React from "react";

const SkillsIcon = props => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-Skills">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Skills" clipPath="url(#clip-Skills)">
        <g id="Group_1275" data-name="Group 1275" transform="translate(-676 -343)">
          <path id="Fill_1" data-name="Fill 1" d="M20.4,16.727H3.6A3.6,3.6,0,0,1,0,13.142V3.584A3.6,3.6,0,0,1,3.6,0H20.4A3.6,3.6,0,0,1,24,3.584v9.558A3.6,3.6,0,0,1,20.4,16.727ZM3.6,2.389A1.2,1.2,0,0,0,2.4,3.584v9.558a1.2,1.2,0,0,0,1.2,1.195H20.4a1.2,1.2,0,0,0,1.2-1.195V3.584a1.2,1.2,0,0,0-1.2-1.195Z" transform="translate(676 347.784)" fill={fill} stroke="none"/>
          <path id="Fill_2" data-name="Fill 2" d="M2.39,1.2A1.2,1.2,0,1,1,1.2,0,1.2,1.2,0,0,1,2.39,1.2Z" transform="translate(686.854 353.756)" fill={fill} stroke="none"/>
          <path id="Fill_3" data-name="Fill 3" d="M21.511,2.39H0V0H21.511Z" transform="translate(677.294 357.341)" fill={fill} stroke="none"/>
          <path id="Fill_4" data-name="Fill 4" d="M2.742,3.608A6.193,6.193,0,0,0,2.39,5.975H0q0-.018,0-.038A8.4,8.4,0,0,1,.545,2.666,4.327,4.327,0,0,1,2.02.8,4.691,4.691,0,0,1,4.78,0a4.691,4.691,0,0,1,2.76.8,4.327,4.327,0,0,1,1.475,1.87,8.4,8.4,0,0,1,.545,3.271q0,.019,0,.038H7.17a6.193,6.193,0,0,0-.352-2.368,1.946,1.946,0,0,0-.654-.857A2.325,2.325,0,0,0,4.78,2.39,2.325,2.325,0,0,0,3.4,2.751,1.946,1.946,0,0,0,2.742,3.608Z" transform="translate(683.269 343)" fill={fill} stroke="none"/>
        </g>
      </g>
    </svg>
  );
};

SkillsIcon.defaultProps = {
  size: 24,
  fill: "inherit"
}

export default SkillsIcon;
