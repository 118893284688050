import React from 'react';
import { Box, Typography, Tooltip, withStyles } from '@material-ui/core';
import styles from './voice-call-settings.style';
import { CustomCheckbox } from '../../../custom-checkbox';
import Loader from '../../../loader';
import Schedule from '../../../management/schedule';
import MinuteManagement from '../minute-management/minute-management';
import ContactManagement from '../contact-management/contact-management';
import { VOICE_CALLS_FEATURE_NAME, VOICE_CALLS_LOADER } from '../../../../v2/services/constants';
import { useVoiceCallSettingsHook } from './useVoiceCallSettingsHook';
import useFeatureFlagOnHook from '../../../../v2/services/useFeatureFlagOnHook';
import { UnleashService } from '../../../../v2/services';
import withUnleash from '../../../../v2/core/unleash/withUnleash';
import IndividualLearnerSettings from './components/individual-learner-settings/individual-learner-settings';
import CommunicationSecuritySettings from './components/communication-security-settings/communication-security-settings';
import GroupScheduleSettings from './components/group-schedule-settings/group-schedule-settings';
import KeywordsDictionaryBanner from './components/keywords-dictionary-banner/keywords-dictionary-banner';
import KeywordsDictionary from './components/keyword-dictionary/keywords-dictionary';
import { Skeleton } from '@material-ui/lab';

const VoiceCallSettingsSkeleton = ({ classes }) => (
  <Box className={classes.root}>
    <Box className={classes.heading}>
      <Skeleton variant="text" width="40%" height={40} />
    </Box>
    <Box className={classes.content}>
      <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: '20px' }} />
      <Skeleton variant="rectangular" width="100%" height={200} style={{ marginBottom: '20px' }} />
      <Skeleton variant="rectangular" width="100%" height={200} />
    </Box>
  </Box>
);

const VoiceCallSettings = (props) => {
  const { classes, unleashProps } = props;
  let featureData = { classes: [classes.level1Box] };

  const {
    setMaxMinutesAllowedPerCallAttribute,
    setMaxMinPerDayPerLearnerAttribute,
    maxMinutesAllowedPerCallAttribute,
    maxMinPerDayPerLearnerAttribute,
    voiceCallStatus,
    isLoading,
    schedules,
    updateSchedulesData,
    updateSettingsState,
    attributeEnabledState,
    keywordToggle,
    openKeywordsDictionary,
    closeKeywordsDictionary,
  } = useVoiceCallSettingsHook(props.locationId);
  const isVoiceGroupSchedule = useFeatureFlagOnHook(UnleashService.FLAGS.VOICE_GROUP_SCHEDULE);
  const isCommunicationSecuritySettings = useFeatureFlagOnHook(UnleashService.FLAGS.COMMUNICATION_SECURITY_SETTINGS);
  if (unleashProps.isFlagEnabled() && keywordToggle) {
    return <KeywordsDictionary closeKeywordsDictionary={closeKeywordsDictionary} locationId={props.locationId} />;
  }
  return (
    <>
      {isLoading ? (
        <VoiceCallSettingsSkeleton classes={classes} />
      ) : isVoiceGroupSchedule ? (
        <Box className={classes.root}>
          <Box className={classes.heading}>
            <Typography variant="h1" className={classes.h1HeadingStyles}>
              Communications Settings
            </Typography>
          </Box>
          <Box className={classes.content}>
            {unleashProps.isFlagEnabled() && <KeywordsDictionaryBanner openKeywordsDictionary={openKeywordsDictionary} />}{' '}
            {isCommunicationSecuritySettings && <CommunicationSecuritySettings locationId={props.locationId} />}
            <IndividualLearnerSettings locationId={props.locationId} />
            <GroupScheduleSettings locationId={props.locationId} />
          </Box>
        </Box>
      ) : (
        <>
          <Box component="div" className={classes.settingsWrapper}>
            <Box component="div" justifyContent="space-between" className={featureData.classes.join(' ')} display="flex">
              <Box component="div" textAlign="left">
                <Typography className={classes.boxHeading1}>Voice Calls Schedule</Typography>
                <Typography className={classes.subHeading1}>
                  Control the availability of Voice Calls to learners by creating a schedule below.
                </Typography>
              </Box>
              <Tooltip title={featureData.tooltipText}>
                <Box display="flex" alignItems="center" id="loader-id" component="div">
                  {isLoading && <Loader isLoading={isLoading} />}
                  <CustomCheckbox id="status-checkbox" onChange={() => updateSettingsState()} checked={voiceCallStatus} />
                </Box>
              </Tooltip>
            </Box>
            {schedules.length ? (
              <Schedule
                loaderKey={VOICE_CALLS_LOADER}
                id="schedule-component"
                updateSchedule={updateSchedulesData}
                featureName={VOICE_CALLS_FEATURE_NAME}
                updateSettingSchedule={updateSchedulesData}
                schedule={schedules}
                className={classes.level3BoxAlt}
              />
            ) : (
              <Loader isLoading={isLoading} />
            )}
          </Box>
          <MinuteManagement
            maxMinutesAllowedPerCallAttribute={maxMinutesAllowedPerCallAttribute}
            setMaxMinutesAllowedPerCallAttribute={setMaxMinutesAllowedPerCallAttribute}
            maxMinPerDayPerLearnerAttribute={maxMinPerDayPerLearnerAttribute}
            setMaxMinPerDayPerLearnerAttribute={setMaxMinPerDayPerLearnerAttribute}
            locationId={props.locationId}
            attributeEnabledState={attributeEnabledState}
          />
          <ContactManagement locationId={props.locationId} />
        </>
      )}
    </>
  );
};

export default withStyles(styles)(withUnleash(VoiceCallSettings, UnleashService.FLAGS.KEYWORD_MATCH_CONFIG));
