import React from "react";

const MissingDataIcon = props => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-Missing_Data">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Missing_Data" data-name="Missing Data" clipPath="url(#clip-Missing_Data)">
        <g id="file-circle-question-duotone" transform="translate(0 1.333)">
          <path id="Path_92709" data-name="Path 92709" d="M224,6.667V0l6.667,6.667Zm2.667,8.667a6,6,0,1,1,6,6A6,6,0,0,1,226.667,15.333Zm6,4a1,1,0,1,0-1-1A1,1,0,0,0,232.667,19.333ZM230,13.667a.667.667,0,0,0,1.333,0V13.4a.4.4,0,0,1,.4-.4h1.688a.578.578,0,0,1,.579.579.593.593,0,0,1-.308.517l-1.333.7a.665.665,0,0,0-.358.587V16a.667.667,0,0,0,1.333,0v-.213l.979-.512a1.96,1.96,0,0,0,1.021-1.7,1.915,1.915,0,0,0-1.913-1.913h-1.687A1.732,1.732,0,0,0,230,13.4Z" transform="translate(-214.667 0)" fill={fill}/>
          <path id="Path_92710" data-name="Path 92710" d="M0,2.667A2.666,2.666,0,0,1,2.667,0H9.333V6.667H16V8.275A7.338,7.338,0,0,0,13.738,21.3a3.18,3.18,0,0,1-.4.029H2.667A2.668,2.668,0,0,1,0,18.667Z" transform="translate(0 0)" fill={fill} opacity="0.4"/>
        </g>
      </g>
    </svg>
  );
};

MissingDataIcon.defaultProps = {
    size: 24,
    fill: "inherit"
}

export default MissingDataIcon;
