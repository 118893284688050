import { useEffect, useState } from 'react';
import { getLearnerTranscript, getLearnerTranscriptBinary } from '../../../../util/APIUtils';
import { TranscriptConstants } from '@orijinworks/frontend-commons';
import { convertBase64ToBlob, downloadBlobFile } from '../../../services/utilty';
import store, { showToast } from '../../../core/store';

export const useTranscriptHook = (props) => {
  const { user } = props;
  const [transcriptData, setTranscriptData] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchTranscriptData(parseInt(user.userDetail.userId));
  }, []);

  const onVersionChange = () => {
    setIsDisabled(false);
  };

  const preProcessTranscriptData = (transcriptResponse) => {
    transcriptResponse.contents = transcriptResponse.contents.filter((content) => content.programs.length > 0);
    return transcriptResponse;
  };

  const doesTranscriptHaveContents = (data) => {
    console.log(data, "DFDF")
    let flag = false;
    if (data.length > 0 && data.filter((item) => item.programs.length > 0).length > 0) {
      flag = true;
    }
    return flag;
  };

  const fetchTranscriptData = async (userId) => {
    try {
      const response = await getLearnerTranscript(userId);
      if (response.payload) {
        if (!doesTranscriptHaveContents(response.payload.contents)) {
          setIsDisabled(true);
        }
        setTranscriptData(preProcessTranscriptData(response.payload));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadV1Transcript = () => {
    try {
      document.querySelector('#legacy-transcript-download-btn').click();
    } catch (e) {
      console.error(e);
    }
  };

  const downloadV2Transcript = async (userId) => {
    setIsLoading(true);
    const fileName = `Transcript_${user.userDetail.userName}`;
    try {
      const { payload } = await getLearnerTranscriptBinary(userId);
      downloadBlobFile(convertBase64ToBlob(payload, 'application/pdf'), fileName);
    } catch (e) {
      console.log(e);
      store.dispatch(showToast(`Something went wrong while fetching certificate.`));
    }
    setIsLoading(false);
  };

  const onDownloadClick = (version) => {
    switch (version) {
      case TranscriptConstants.V1:
        downloadV1Transcript();
        break;
      default:
        downloadV2Transcript(parseInt(user.userDetail.userId));
    }
  };
  return { transcriptData, isLoading, isDisabled, setIsDisabled, onDownloadClick, onVersionChange };
};
