export default (theme) => ({
  mainWrapper: {
    backgroundColor: theme.textWhite ? theme.textWhite.main : '#FFF',
    margin: '15px 10px 0 40px',
  },
  heading: {
    fontWeight: 700,
  },
  innerWrapper: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  innerWrapperLeft: {
    textAlign: 'left',
    margin: '20px 20px',
  },
  minutesSection: {
    display: 'flex',
    alignItems: 'center',
  },
  minutesInput: {
    width: '30%',
    marginLeft: 'auto',
  },
  saveButton: {
    margin: '15px',
    padding: '8px',
  },
  innerWrapperLeftSubHeading: {
    fontSize: 20,
    color: theme.palette.text.sub,
    letterSpacing: '0.21px',
    width: 'max-content',
  },
  switchSection: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px',
  },
});
