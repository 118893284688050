import Datatable, { MTableToolbar } from 'material-table';
import { Grid, Box, Button, List, ListItem, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SortIcon from '@material-ui/icons/Sort';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import moment from 'moment';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Menu } from '../management';
import { getCommunicationListAtLocation, getCommunicationVisualization, getStaffList, listUsers, logMsgEvent } from '../../util/APIUtils';
import { COMMUNICATION_FILTER, COMMUNICATION_SORT_FIELDS, LOCATIONS } from '../../constants';
import { isPermitted } from '../permission-control/permission-control';
import Hoverable from '../hoverable';
import { parseTimeToNewZone } from '../users/learner/transcript/utils';
import { UserIcon } from '../UserIcon';
import { COMMUNICATION_TABS } from '../../v2/services/constants';
import { Typography } from '@orijinworks/frontend-commons';
import { CustomIcons, icons } from '../../v2/shared/components/custom-icons';
import DaysFilter from '../days-filter';
import { Filter } from '../management/communications/components';
import { Button as CommonsButton } from '@orijinworks/frontend-commons';
import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';

export const useCommunicationHook = ({ userLocations, userActiveLocation, profileUser, unleashProps, classes, history, selectedLocation, tableRef }) => {
  const [timezoneName, setTimeZone] = useState('UTC');
  const [location, setLocation] = useState(null);
  const [snippet, setSnippet] = useState({
    isOpen: false,
    title: null
  });
  const [messageViewDialog, setMessageViewDialog] = useState({
    isOpen: false,
    title: null
  });
  const [communicationList, setCommunicationList] = useState([]);
  const [filters, setFilters] = useState({
    searchTerm: '',
    location: '',
    people: '',
    status: ''
  });

  const [filterData, setFilterData] = useState({
    locations: [],
    user: []
  });
  const [pagination, setPagination] = useState({
    currentPage: 0,
    pageSize: 20
  });
  
  const [totalElements, setTotalElements] = useState(0);

  const [global, setGlobal] = useGlobal();

  const [showEmptyDataSourceMessage, setShowEmptyDataSourceMessage] = useState(false);
  const [sorting, setSorting] = useState({ active: null });
  const [communicationListLoading, setCommunicationListLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(COMMUNICATION_TABS.messages);
  const [messagesSummary, setMessagesSummary] = useState([]);
  const [isGraphDetailOpen, setGraphDetailOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const exportPermission = isPermitted(global.cbacConfigFlag, global.permissions, 'Export_Messages');

  useEffect(() => {
    initPropToState();
  }, []);

  const communicationColumns = [
    {
      title: 'Sender',
      field: 'senderPersonName',
      render: rowData => (
        <Hoverable title={rowData.senderPersonName}>
          <Button
            color="primary"
            onClick={() => openProfile(rowData.senderRoleName, rowData.senderPersonId, rowData.locationId)}
          >
            {rowData.senderRoleName && getIcon(rowData.senderRoleName)}
            {rowData.senderPersonName}
          </Button>
        </Hoverable>
      )
    },
    {
      title: 'Recipient',
      field: 'recipientPersonName',
      render: rowData => (
        <Hoverable title={rowData.recipientPersonName}>
          <Button
            color="primary"
            onClick={() => openProfile(rowData.recipientRoleName, rowData.recipientPersonId, rowData.locationId)}
          >
            {rowData.recipientRoleName && getIcon(rowData.recipientRoleName)}
            {rowData.recipientPersonName}
          </Button>
        </Hoverable>
      )
    },
    {
      title: 'Location',
      field: 'locationName',
      render: rowData => <Hoverable title={rowData.locationName}>{rowData.locationName}</Hoverable>
    },
    {
      title: 'Date',
      field: 'createdDate',
      render: rowData => (
        <Hoverable title={parseTimeToNewZone(rowData.createdDate, timezoneName)}>
          {parseTimeToNewZone(rowData.createdDate, timezoneName)}
        </Hoverable>
      )
    },
    {
      title: 'Status',
      field: 'recordStatus',
      render: rowData =>
        !rowData.recordStatus && (
          <Box display="flex">
            <Box className={[classes.chip]}>
              <Typography className={classes.chipText} component="p">
                {rowData.recordStatus ? null : 'Hidden'}
              </Typography>
            </Box>
          </Box>
        )
    },
    {
      title: 'Message',
      field: 'clearBody',
      hidden: true
    },
    {
      title: 'Actions',
      sorting: false,
      permissionName: 'View_Student_Messages_In_Other_Relationships',
      render: rowData => {
        const options = [];
        const View_Messages_Snippets = isPermitted(
          global.cbacConfigFlag,
          global.permissions,
          'View_Messages_Snippets'
        );
        const View_Threads = isPermitted(global.cbacConfigFlag, global.permissions, 'View_Threads');
        if (View_Messages_Snippets) {
          options.push({
            title: 'View Snippet',
            onClick: () =>
              handleSnippetDialog(true, {
                ...rowData,
                timezoneName: timezoneName
              })
          });
        }
        if (View_Threads) {
          options.push({
            title: 'View Full Thread',
            onClick: () =>
              handleDialog(true, {
                title: `${rowData.senderPersonName} & ${rowData.recipientPersonName}`,
                data: rowData
              })
          });
        }
        return (
          <Hoverable>
            <Menu options={[...options]} />
          </Hoverable>
        );
      }
    }
  ];

  const onTabChange = (event, value) => {
    setSelectedTab(value);
  };

  const getIcon = roleName => {
    switch (roleName) {
      case 'Attorney':
        return (
          <CustomIcons icon={icons.AttorneyIcon} className={classes.attorneyIcon} />
        );
      case 'Learner':
        return <UserIcon />;

      default:
        return <VerifiedUserIcon />;
    }
  };

  /**
   * @name openProfile
   * @param {string} type
   * @param {number} personId
   * @desc Open's respective profile based upon type passed.
   * @return {void}
   */
  const openProfile = (type, personId, locationId) => {
    switch (type) {
      case null:
        break;
      case 'Learner':
        setGlobal({
          selectedUser: personId,
          openProfileView: true,
          staffProfileID: null,
          staffSelectedLocation: null,
          isStaffProfileOpen: false
        });
        break;
      default:
        setGlobal({
          staffProfileID: personId,
          staffSelectedLocation: locationId,
          isStaffProfileOpen: unleashProps.isFlagEnabled() ? false : true,
          staffRole: type,
          selectedUser: null,
          openProfileView: false
        });
        if (unleashProps.isFlagEnabled() && history) {
          history.push(`/users/${personId}`);
        }
    }
  };

  /**
   * @name handleSnippetDialog
   * @param {boolean} isOpen
   * @param {object} snippetData (optional)
   * @desc Handle's dialog box open state.
   * @return {void}
   */
  const handleSnippetDialog = (isOpen, snippetData, isAnnouncement) => {
    setSnippet({
      ...snippet,
      ...snippetData,
      isOpen,
      isAnnouncement
    });
  };

  /**
   * @name handleDialog
   * @param {boolean} isOpen
   * @desc Handle's all dialog toggle state.
   * @return {void}
   */
  const handleDialog = (isOpen, additionalData) => {
    setMessageViewDialog({ ...additionalData, isOpen });
  };

  /**
   * @name getPaginatedData
   * @param {object} query
   * @desc Prepare's pagination data for server side pagination
   * @return {void}
   */
  const getPaginatedData = query => {
    const paginationData = {
      limit: query.pageSize || pagination.pageSize,
      page: query.page,
      orderBy: query.orderBy ? COMMUNICATION_SORT_FIELDS[query.orderBy.field] : 'created_date',
      sortBy: query.orderDirection || 'desc'
    };
    return paginationData;
  };

  const getDateRange = range => {
    return {
      startDate: moment(range ? range.startDate : new Date() - 7).format('YYYY-MM-DD'),
      endDate: moment(range ? range.endDate : new Date()).format('YYYY-MM-DD')
    };
  };

  /**
   * @desc Prepares datatable columns w.r.t to pre-processing required.
   * @return {array}
   */
  const getDatatableColumns = () => {
    const columns = communicationColumns.map(column => {
      if (sorting.active === column.field) {
        column['defaultSort'] = sorting[column.field];
      } else {
        column['defaultSort'] = null;
      }
      return column;
    });
    return columns;
  }

  const getCommunicationEndpoint = query => {
    const locationByID = (userLocations || []).map(location => location.locationId);
    const locationID = filters.location !== '' ? filters.location : locationByID.join(',');
    return getCommunicationListAtLocation(
      locationID,
      getDateRange(global.range),
      filters.searchTerm,
      profileUser.userName,
      getPaginatedData(query),
      filters.status
    );
  };

  /**
   * @name getCommunicationList
   * @param {number} locationID
   * @desc Fetche's communication list w.r.t locationID
   * @return {void}
   */
  const getCommunicationList = async (query) => {
    try {
      const sorted = sorting;
      if (query.orderBy) {
        sorted['active'] = query.orderBy.field;
        sorted[query.orderBy.field] = query.orderDirection;
      } else {
        sorted['active'] = null;
      }
      setCommunicationListLoading(true);
      setSorting(sorted);
      setShowEmptyDataSourceMessage(false);

      const response = await getCommunicationEndpoint(query);
      if (response) {
        const { page } = query;
        const { messageSummaryDtos, totalRecordsCount } = response;
        if (page < 0) {
          page = 1;
        }
        setTotalElements(totalRecordsCount);
        return {
          data: messageSummaryDtos,
          totalCount: totalRecordsCount,
          page
        };
      } else {
        return {
          data: []
        };
      }
    } catch (error) {
      console.error("Error fetching communication list:", error);
      return {
        data: [],
        page: 0,
        totalCount: 0
      };
    }
  };

  const getStaff = locationID => {
    return new Promise((resolve, reject) => {
      getStaffList(locationID).then(
        _successLog => resolve(_successLog.staffList),
        () => resolve([])
      );
    });
  };

  const getUsers = locationID => {
    return new Promise((resolve, reject) => {
      let postBody = {
        requestorId: 191439,
        requestorLocationId: locationID,
        filterLocationTypeIds: [],
        filterRoleTypeIds: [19, 30],
        filterLocationIds: [],
        filterUserStatus: 'all'
      };
      listUsers(postBody).then(
        data => resolve(data.users),
        () => resolve([])
      );
    });
  };

  /**
   * @name getFiltersData
   * @desc A wrapper method used to fetch filters data.
   * @return {void}
   */
  const getFiltersData = locationID => {
    const userActiveLocations = (userLocations || []).filter(location => location.active);
    if (!locationID && userActiveLocations.length > 0) {
      locationID = userActiveLocations[0].locationId;
    }

    let permissionArray = [userActiveLocations];
    if (isPermitted(global.cbacConfigFlag, global.permissions, 'View_User_List')) {
      permissionArray = [...permissionArray, getUsers(locationID)];
    }
    if (isPermitted(global.cbacConfigFlag, global.permissions, 'Access_Staff_List')) {
      permissionArray = [...permissionArray, getStaff(locationID)];
    }
    setGlobal({ isLoading: true });
    Promise.all(permissionArray).then(
      dataCollection => {
        let users = [];

        if (dataCollection[1]) {
          users = [...dataCollection[1]];
        }
        if (dataCollection[2]) {
          users = [...users, ...dataCollection[2]];
        }
        setFilterData({ ...filterData, users });
        setGlobal({ isLoading: false });
      },
      _errorLog => {
        setGlobal({
          isLoading: false
        });
      }
    );
  };

  /**
   * @name filterUpdateHandler
   * @param {object} filterData
   * @desc Callback fire's when filter is updated.
   * @return {void}
   */

  const filterUpdateHandler = (newFilters) => {
    setFilters(newFilters);
    setCommunicationList([]);
    if (tableRef.current) {
      tableRef.current.onChangePage(null, 0);
    }
  };

  /**
   * @name dateChangeHandler
   * @param {object} range
   * @desc Fire's when date change is done.
   * @return {void}
   */
  const dateChangeHandler = range => {
    if (tableRef.current) tableRef.current.onChangePage(null, 0);
  };

  /**
   * @name scrollListener
   * @param {string} type (attach | dettach)
   * @param {callback} callback Function that needs to be attached / detached.
   * @desc Attach's / detach's listener based upon type passed.
   * @return {void}
   */
  const scrollListner = (type, callback) => {
    const element = document.querySelector('.scroller-view');
    switch (type) {
      case 'attach':
        element.addEventListener('scroll', callback);
        break;
      case 'detach':
        element.removeEventListener('scroll', callback);
    }
  };

  /**
   * @name exportFile
   * @param {string} fileName
   * @param {string} csvContent
   * @desc Handle's DOM file export functionality.
   * @return {void}
   */
  const exportFile = (fileName, csvContent) => {
    var link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "${fileName}.csv".
    document.body.removeChild(link);
  };

  /**
   * @name exportCommunicationList
   * @param {array} columns Datatable columns.
   * @param {array} data Datatable data items.
   * @desc Exports communication table with relevant underlying auditing
   * functionality too.
   * @return {void}
   */
  const exportCommunicationList = async (columns, data, fileName) => {
    const exemptedColumnsList = ['Actions'];
    setGlobal({ isLoading: true });
    try {
      const userActiveLocation = (userLocations || []).filter(location => location.active);
      logMsgEvent({
        messaging_event_type_name: 'Messages Exported',
        details: `${window.navigator.userAgent} \n ${userActiveLocation.length > 0 ? `LocationName: ${userActiveLocation[0].locationName}` : ''
          }`,
        locationKey: userActiveLocation[0].locationId
      });
      const dataset = await getCommunicationEndpoint({
        page: 0,
        pageSize: totalElements
      });
      let csvContent = '';
      const columnString = columns
        .filter(column => exemptedColumnsList.indexOf(column.title) === -1)
        .map(column => column.title)
        .join(',');
      csvContent += columnString + '\r\n';
      dataset.messageSummaryDtos.forEach(item => {
        const rowString = columns
          .map(column => {
            if (column.field) {
              if (item[column.field] || typeof item[column.field] === 'boolean') {
                if (column.field === 'clearBody') {
                  const domParser = new DOMParser();
                  const document = domParser.parseFromString(item[column.field], 'text/html');
                  return '"' + document.body.innerText + '"';
                } else if (column.field === 'createdDate') {
                  return parseTimeToNewZone(item[column.field], timezoneName, 'MM/DD/YYYY hh:mm:ss A');
                }
                return '"' + (item[column.field].toString() || '').split('"').join('""') + '"';
              }
              return 'N/A';
            }
          })
          .join(',');
        csvContent += rowString + '\r\n';
      });
      const universalBOM = '\uFEFF';
      const csvData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM + csvContent);
      exportFile(fileName, csvData);
    } catch (e) {
      console.error(e);
    } finally {
      setGlobal({
        isLoading: false
      });
    }
  };

  /**
   * @name getLocationTimezone
   * @desc Fetches location timezone from saved locations and props active locations.
   * @return {void}
   */
  const getLocationTimezone = () => {
    const { locationId } = userActiveLocation;
    const activeLocation = LOCATIONS.find(location => location.locationId === locationId);
    const locationAttribute = activeLocation
      ? activeLocation.attributes.find(attr => attr.attributeName === 'TimeZone')
      : null;
    if (locationAttribute) {
      setTimeZone(locationAttribute.attributeValue);
    }
  };

  const initPropToState = () => {
    setLocation({
      active: selectedLocation ? selectedLocation.locationId : null,
      collection: LOCATIONS,
      locationName: selectedLocation ? selectedLocation.locationName : null
    });
    getLocationTimezone();
    getFiltersData(selectedLocation ? selectedLocation.locationId : null);
  };

  const getVisualizationData = () => {
    setLoading(true);
    const { locationID, userName } = getPayloadData();
    getCommunicationVisualization(
      locationID,
      getDateRange(global.range),
      filters.searchTerm,
      userName || ''
    ).then(
      (response) => {
        setGraphDetailOpen(true);
        setMessagesSummary(response.messagesSummary);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      }
    );
  };

  const getPayloadData = () => {
    if (userLocations && userLocations.length) {
      return {
        locationID: (userLocations.map(location => location.locationId)).join(','),
        userName: profileUser && profileUser.userName
      };
    }
    return { locationID: filters.location || location.active };
  }

  const onGraphToggleHandler = () => setGraphDetailOpen(!isGraphDetailOpen);

  const MessagesTable = ({ filteredCommunicationColumn, classes, exportPermission }) => {
    return (
      <Grid container item className={classes.datatable}>
        <CustomToolbar hideTabs={false}/>
        <Datatable
          tableRef={tableRef}
          style={{ width: '100%' }}
          columns={filteredCommunicationColumn}
          data={getCommunicationList}
          options={{
            pageSize: pagination.pageSize,
            selection: false,
            showSelectAllCheckbox: true,
            showEmptyDataSourceMessage: showEmptyDataSourceMessage,
            filtering: false,
            showTitle: false,
            Sort: SortIcon,
            SortArrow: ArrowDownwardIcon,
            actionsColumnIndex: 0,
            search: false,
            exportFileName: `MessageExport-${moment().format('MM-DD-YYYY')}`,
            exportCsv: (columns, data) =>
              exportCommunicationList(
                columns,
                data,
                `MessageExport-${moment().format('MM-DD-YYYY')}`,
                'Messages',
              ),
            selectionProps: rowData => ({
              id: `checkbox-${rowData.formId}`
            })
          }}
          icons={{
            Search: SearchIcon,
            ResetSearch: CloseIcon,
            FirstPage: FirstPageIcon,
            LastPage: LastPageIcon,
            NextPage: NavigateNextIcon,
            PreviousPage: NavigateBeforeIcon,
            Filter: FilterListIcon,
            SortArrow: ArrowUpwardIcon,
            Edit: EditIcon,
            User: UserIcon
          }}
        />
      </Grid>
    );
  };

  const TabSelector = () => {

    return (
      <>
        <Typography variant="h6" className={classes.selectionListHeading}>
          COMMUNICATION TYPE
        </Typography>
        <Box>
          <List className={classes.selectionList}>
            <ListItem
              onClick={(e) => onTabChange(e, COMMUNICATION_TABS.messages)}
              button
              className={[
                classes.selectionItem,
                selectedTab == COMMUNICATION_TABS.messages ? classes.selectedItem : '',
              ].join(' ')}
            >
              Messages
            </ListItem>
            <ListItem
              onClick={(e) => onTabChange(e, COMMUNICATION_TABS.announcement)}
              button
              className={[
                classes.selectionItem,
                selectedTab == COMMUNICATION_TABS.announcement ? classes.selectedItem : null,
              ].join(' ')}
            >
              Announcements
            </ListItem>
          </List>
        </Box>
      </>
    );
  };

  const CustomToolbar = ({hideTabs}) => {
    let allowedPermissions = [COMMUNICATION_FILTER.Location, COMMUNICATION_FILTER.People, COMMUNICATION_FILTER.Status];
    const options = tableRef.current ? tableRef.current.props.options : null;
    const dataManager = tableRef.current ? tableRef.current.dataManager : null;

    if (isPermitted(global.cbacConfigFlag, global.permissions, 'View_Student_Messages_In_Other_Relationships')) {
      allowedPermissions = [...allowedPermissions, COMMUNICATION_FILTER.SearchTerm];
    }

    return (
      <Paper elevation={2} className={classes.toolbarPaper}>
        <Grid container>
            <Grid item md={6}>
              {!hideTabs &&
                <TabSelector />
              } 
            </Grid>
          <Grid item md={6}>
            <Box width="100%" display="flex" flexDirection="column" alignItems="flex-end">
              <Typography
                variant="h6"
                className={classes.selectionListHeading}
                sx={{
                  width: 525
                }}
              >
                TIME FRAME
              </Typography>
              <Box display="flex" justifyContent="flex-end" width="100%">
                <DaysFilter
                  dateChangeHandler={dateChangeHandler}
                  dateRangePickerProps={{
                    left: '-195px',
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid md={6}>
            <Box display="flex" justifyContent="flex-start">
              <Filter
                filterUpdateHandler={filterUpdateHandler}
                {...filterData}
                cbacConfigFlag={global.cbacConfigFlag}
                permissions={global.permissions}
                allowedFilters={allowedPermissions}
              />
            </Box>
          </Grid>

          <Grid md={6}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <CommonsButton id="export-csv-btn" onClick={() => options.exportCsv(dataManager.columns, dataManager.data)} color="secondary">
                <SaveAltIcon />
              </CommonsButton>
              <CommonsButton id="view-graph-btn" onClick={getVisualizationData} color="secondary">
                View Graph
              </CommonsButton>
            </Box>
          </Grid>
        </Grid>
        </Paper>
    );
  }


  const filteredCommunicationColumn = getDatatableColumns().filter(item => {
    return (
      !item.permissionName ||
      isPermitted(global.cbacConfigFlag, global.permissions, 'View_Student_Messages_In_Other_Relationships')
    );
  });

  return {
    isLoading,
    snippet,
    filters,
    messageViewDialog,
    location,
    timezoneName,
    global,
    pagination,
    selectedTab,
    showEmptyDataSourceMessage,
    messagesSummary,
    isGraphDetailOpen,
    exportPermission,
    filteredCommunicationColumn,
    handleSnippetDialog,
    handleDialog,
    getCommunicationList,
    exportCommunicationList,
    onTabChange,
    MessagesTable,
    onGraphToggleHandler,
    getDateRange,
    TabSelector,
    CustomToolbar
  }
};

