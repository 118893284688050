export default (theme) => ({
  mainBody: {
    margin: '0px 10px 8px 10px',
    color: theme.palette.text.primary,
    fontSize: 14,
  },
  inputLabel: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    transform: 'translate(14px, 20px) scale(1)',
  },
});
