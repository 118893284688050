import React from 'react';
import ReactDOM from 'react-dom';

  const data2012 = [
    {quarter: 1, earnings: 13000},
    {quarter: 2, earnings: 16500},
    {quarter: 3, earnings: 14250},
    {quarter: 4, earnings: 19000},
    {quarter: 5, earnings: 11500},
    {quarter: 6, earnings: 13250},
    {quarter: 7, earnings: 20000},
    {quarter: 8, earnings: 15500}
  ]
  const data2013 = [
    {quarter: 1, earnings: 15000},
    {quarter: 2, earnings: 12500},
    {quarter: 3, earnings: 19500},
    {quarter: 4, earnings: 13000},
    {quarter: 5, earnings: 11500},
    {quarter: 6, earnings: 13250},
    {quarter: 7, earnings: 20000},
    {quarter: 8, earnings: 15500}
  ]
  const data2014 = [
    {quarter: 1, earnings: 11500},
    {quarter: 2, earnings: 13250},
    {quarter: 3, earnings: 20000},
    {quarter: 4, earnings: 15500},
    {quarter: 5, earnings: 11500},
    {quarter: 6, earnings: 13250},
    {quarter: 7, earnings: 20000},
    {quarter: 8, earnings: 15500}
  ]
  const data2015 = [
    {quarter: 1, earnings: 18000},
    {quarter: 2, earnings: 13250},
    {quarter: 3, earnings: 15000},
    {quarter: 4, earnings: 12000},
    {quarter: 5, earnings: 11500},
    {quarter: 6, earnings: 13250},
    {quarter: 7, earnings: 20000},
    {quarter: 8, earnings: 15500}
  ]
  
  class RedemptionChart extends React.Component {
    render() {
      return (
      <div></div>
      )
    }
  }

  export default RedemptionChart