import React from 'react';
import PropTypes from 'prop-types';
import { KeycloakProvider as KeycloakAuthProvider } from '@react-keycloak/web';
import { KeycloakService } from '../../../../../keycloak';

const KeycloakProvider = ({ children }) => {
  const keycloak = new KeycloakService();

  return (
    <KeycloakAuthProvider keycloak={KeycloakService.Keycloak} onEvent={keycloak.keyCloakEvent}>
      {children}
    </KeycloakAuthProvider>
  );
};

KeycloakProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default KeycloakProvider;
