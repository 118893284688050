import React from 'reactn';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import PropTypes from 'prop-types';
import {
  TextField,
  Box,
  MenuItem,
  withStyles,
  InputBase,
  ButtonBase,
  Paper,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Zoom,
  Slide,
  List,
  ListItem
} from '@material-ui/core';
import ReactChartkick, { ColumnChart } from 'react-chartkick';
import Unit from './unit';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import {
  USER_CONTEXT,
  CONTENT_AREA,
  CONTENT_AREAS_ENUM,
  TOT_DETAILS_ENUM,
  setHousingUnit,
  HOUSING_UNITS
} from '../../constants';
import { Link } from 'react-router-dom';
import { setLocation, LOCATIONS, SELECTED_LOCATION } from '../../constants';
import { gettimeontask, getPermittedLocations } from '../../util/APIUtils';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TimerIcon from '@material-ui/icons/Timer';
import { CustomChart } from '../chart';
import moment from 'moment';
import { DateRangePicker } from 'materialui-daterange-picker';
import TotDetails from '../tot-details';
import { isContentAreaExempted } from '../users/learner/transcript/utils';
import { setLocation as selectLocation, setHousingUnit as selectHousingUnit } from '../../v2/core/store/.';
import { withRouter } from 'react-router-dom';
import { theme as AdminTheme } from '../../v2/core/containers/theme';
import { CustomTheme } from '@orijinworks/frontend-commons';

///Styles Constants
const styles = theme => ({
  daysSelectionList: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16
  },
  daysSelectionItem: {
    width: 'initial',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    height: 40,
    fontSize: 18,
    color: theme.palette.primary.cobalt,
    fontWeight: 'bold',
    '&:first-child': {
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20
    },
    '&:last-child': {
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20
    }
  },
  activeDay: {
    backgroundColor: theme.palette.primary.cobalt,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.cobalt
    }
  },
  root: {
    padding: theme.spacing.unit * 2,
    flexGrow: 1
  },
  appBar: {
    background: theme.palette.secondary.main
  },
  timeOnTaskChart: {
    height: 280
  },
  locationsContainer: {
    maxWidth: '100vw',
    margin: 0,
    padding: 0
  },
  summaryCard: {
    height: 280,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center'
  },
  locationCard: {
    height: 300,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  locationHeader: {
    width: '100%',
    flexGrow: 1,
    background: theme.palette.background.blue,
    padding: '.5rem'
  },
  locationFooter: {
    width: '100%',
    height: '70%',
    flexGrow: 1,
    padding: 15
  },
  statisticsContainer: {
    flex: 1,
    backgroundColor: theme.palette.colors.purpleMain, 
    margin: 6,
    color: 'white',
    borderRadius: 4
  },
  timeSpentContainer: {
    width: '100%',
    backgroundColor: theme.palette.colors.purpleMain, 
    margin: 6,
    color: 'white',
    borderRadius: 4
  }
});

const ONE_HOUR = 60 * 60 * 1000;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Facility extends React.Component {
  _chart = new CustomChart();
  state = {
    dateRangePicker: {
      isOpen: false
    },
    dateRange: 7,
    selectedView: 'totals',
    search: '',
    isLoading: false,
    isCustom: false,
    totDetailsDialog: {
      isOpen: false
    },
    locData: null,
    locationName: null,
    isHousingUnitPermitted: true
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (
      !this.props.selectedLocation ||
      (typeof this.props.selectedLocation === 'object' && Object.keys(this.props.selectedLocation).length === 0)
    ) {
      const params = this.props.match.params;
      const location = {
        locationId: params.locationId.split('&')[0],
        locationName: params.locationName
      };
      this.props.selectLocation(location);
    }
    this.getData();
    this.getPermittedLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedLocation && prevProps.selectedLocation !== this.props.selectedLocation) {
      this.changeLocationHandler(this.props.selectedLocation);
    }

    if (
      this.props.selectedHousingUnit &&
      prevProps.selectedHousingUnit !== this.props.selectedHousingUnit &&
      this.props.selectedLocation
    ) {
      this.openFacilityView(this.props.selectedHousingUnit);
    }

    this.getData();
  }

  /**
   * @name changeLocationHandler
   * @param {object} event
   * @desc Fires when location change handler is fired.
   * @return {void}
   */
  changeLocationHandler = async loc => {
    this.props.history.push(`/outcomes-and-progress/`);
  };

  getData = () => {
    const { locData, isLoading } = this.state;
    if (locData || isLoading || LOCATIONS.length === 0) {
      return null;
    }
    var myFacilities = [];
    const selectedLoc = this.props.match.params.locationId.split('&')[0];
    var _facilities = LOCATIONS.filter(l => {
      return l.parentLocationId == selectedLoc;
    });
    _facilities.map(f => {
      myFacilities.push(f.locationId);
    });
    if (myFacilities.length < 1) {
      myFacilities.push(selectedLoc);
    }
    this.setState(prevState => {
      return {
        facilities: myFacilities,
        openFacilityView: false,
        dateRange: this.global.selectedDateRange,
        locationName: this.props.match.params.locationName
      };
    });

    let locationData = this.global.locationDataStore
      .filter(l => {
        return l.locationId === selectedLoc;
      })
      .filter(i => i.dateRange === this.global.selectedDateRange);

    var now = Date.now();
    if (locationData.length > 0 && now - locationData[0].timestamp <= ONE_HOUR * 12) {
      this.createTotViewData(locationData[0].data);
      this.setState({
        locData: locationData[0].data
      });
    } else {
      this.fetchTimeonTask(myFacilities);
    }
  };

  fake___componentWillReceiveProps() {
    var myFacilities = [];
    var _facilities = LOCATIONS.filter(l => {
      return l.parentLocationId === this.global.totSelectedFacility.locationId;
    });
    _facilities.map(f => {
      myFacilities.push(f.locationId);
    });
    if (myFacilities.length < 1) {
      myFacilities.push(SELECTED_LOCATION.locationId);
    }
    this.setState(prevState => {
      return {
        facilities: myFacilities,
        openFacilityView: false,
        dateRange: this.global.selectedDateRange
      };
    });

    let locationData = this.global.locationDataStore
      .filter(l => {
        return l.locationId === this.global.totSelectedFacility.locationId;
      })
      .filter(i => i.dateRange === this.global.selectedDateRange);

    var now = Date.now();
    if (locationData.length > 0 && now - locationData[0].timestamp <= ONE_HOUR * 12) {
      this.createTotViewData(locationData[0].data);
      this.setState({
        locData: locationData[0].data
      });
    } else {
      if (!this.state.isLoading) {
        // this.fetchTimeonTask(myFacilities);
      }
      // this.getTimeonTask(myFacilities, this.global.selectedDateRange)
    }
  }

  fetchTimeonTask = fac => {
    if ([7, 30, 90, 365].indexOf(this.global.selectedDateRange) === -1) {
      this.getTimeonTask(fac, this.global.range, false);
    } else {
      this.getTimeonTask(fac, this.global.selectedDateRange);
    }
  };

  getDateRangeModelFromInteger = range => {
    var d = new Date();
    var _range = range || this.state.dateRange;
    d.setDate(d.getDate() - _range);
    return {
      startDate: d.toISOString(),
      endDate: new Date().toISOString()
    };
  };

  getTimeonTask = (fac, range, isDefiniteRange = true) => {
    let pBody = '';
    var qryDate;
    this.setState({
      locData: null,
      isLoading: true
    });
    let filterDateRange = null;
    if (isDefiniteRange) {
      filterDateRange = this.getDateRangeModelFromInteger(range);
    } else {
      filterDateRange = range;
    }
    this.setGlobal({
      range: filterDateRange
    });
    let postBody = {
      requestorId: USER_CONTEXT.personId,
      requestorLocationId: 1697386,
      filterLocationIds: fac,
      filterDateRange,
      retLearnerDetails: false
    };

    pBody = JSON.stringify(postBody);
    gettimeontask(postBody).then(data => {
      var facilitiesAverages = [];
      var facilityAve = [];
      var locations = data.locations.map(l => {
        facilityAve = [];
        var name = l.locationName;
        var ave = l.totalTimespent;
        facilityAve.push(name, ave);
        facilitiesAverages.push(facilityAve);
        var contentAreasChart = [];
        var arr = [];
        var _arr = [];
        this.arr = arr;
        l.contentAreas.map(c => {
          _arr = [];
          var name = c.contentAreaName;
          var ave = c.totalTimespent;
          var id = c.contentAreaKey;
          _arr.push(name, ave, id);

          this.arr.push(_arr);
        });
        l.contentAverageChart = arr;
      });
      var facilitiesTotals = this.generateLocationTotals(data.locations);
      data.facilityTotalsChart = facilitiesTotals;
      var facilitiesAverages = this.generateLocationAverages(data.locations);
      data.facilitiesAveragesChart = facilitiesAverages;
      var details = this.generateSummaryDetails(data.locations);
      data.totalTimespent = details.totalTimespent;
      data.numberOfUsers = details.numberOfUsers;
      this.setState({
        locData: data,
        isLoading: false
      });
      var globalLocationData = {
        locationId: this.props.match.params.locationId,
        dateRange: this.global.selectedDateRange,
        data: data,
        timestamp: Date.now()
      };
      var _globalDataStore = this.global.locationDataStore;
      _globalDataStore.push(globalLocationData);
      this.setGlobal({
        locationDataStore: _globalDataStore
      });
    });
  };
  getPermittedLocations = () => {
    const locationId = this.props.match.params.locationId;
    const permissionName = 'View_Aggregate_Data_At_Hu';
    if (this.global.cbacConfigFlag) {
      getPermittedLocations(locationId, permissionName)
        .then(_success => {
          if (_success && _success.length) {
            this.setState({ isHousingUnitPermitted: true });
          } else {
            this.setState({ isHousingUnitPermitted: false });
          }
        })
        .catch(_error => {
          this.setState({ isHousingUnitPermitted: false });
        });
    } else {
      this.setState({ isHousingUnitPermitted: true });
    }
  };

  createTotViewData(data) {
    var facilitiesAverages = [];
    var facilityAve = [];
    var locations = data.locations.map(l => {
      facilityAve = [];
      var name = l.locationName;
      var ave = l.totalTimespent;
      facilityAve.push(name, ave);
      facilitiesAverages.push(facilityAve);
      var contentAreasChart = [];
      var arr = [];
      var _arr = [];
      this.arr = arr;
      l.contentAreas.map(c => {
        _arr = [];
        var name = c.contentAreaName;
        var ave = c.totalTimespent;
        var id = c.contentAreaKey;
        _arr.push(name, ave, id);

        this.arr.push(_arr);
      });
      l.contentAverageChart = arr;
    });
    var facilitiesTotals = this.generateLocationTotals(data.locations);
    data.facilityTotalsChart = facilitiesTotals;
    var facilitiesAverages = this.generateLocationAverages(data.locations);
    data.facilitiesAveragesChart = facilitiesAverages;
    var details = this.generateSummaryDetails(data.locations);
    data.totalTimespent = details.totalTimespent;
    data.numberOfUsers = details.numberOfUsers;
  }

  generateSummaryDetails = loc => {
    var totalTimespent = 0;
    var numOfUsers = 0;
    var details = {};
    loc.map(l => {
      totalTimespent += l.totalTimespent;
      numOfUsers += l.numberOfUsers;
    });
    details.totalTimespent = totalTimespent.toFixed(2);
    details.numberOfUsers = numOfUsers;
    return details;
  };

  generateLocationTotals = loc => {
    var facilitiesTotals = [];
    var facilityTot = [];
    var locations = loc.map(l => {
      facilityTot = [];
      var name = l.locationName;
      var ave = l.totalTimespent;
      facilityTot.push(name, ave);
      facilitiesTotals.push(facilityTot);
      var contentAreasChart = [];
      var arr = [];
      var _arr = [];
      l.contentAreas.map(c => {
        if (isContentAreaExempted(c.contentAreaName)) {
          return;
        }
        _arr = [];
        var name = c.contentAreaName;
        var ave = c.totalTimespent;
        var id = c.contentAreaKey;
        _arr.push(name, ave, id);

        arr.push(_arr);
      });
      l.contentAverageChart = arr;
    });
    return facilitiesTotals;
  };

  generateLocationAverages = loc => {
    var facilitiesAverages = [];
    var numOfLocations = loc.length;
    var edTotals = 0;
    var rehabTotals = 0;
    var jobTotals = 0;
    var reentryTotals = 0;
    var miscTotals = 0;
    var enrichmentTotals = 0;
    var entertainmentTotals = 0;
    var communicationTotals = 0;
    var education = ['Education'];
    var rehab = ['Rehab'];
    var job = ['Job'];
    var reentry = ['Reentry'];
    var enrichment = ['Enrichment'];
    var entertainment = ['Entertainment'];
    var misc = ['Miscellaneous'];

    var locations = loc.map(l => {
      l.contentAreas.map(c => {
        var name = c.contentAreaName;
        var ave = c.averageTimespent;
        if (name === 'Education') {
          edTotals += ave;
        }
        if (name === 'Rehabilitation') {
          rehabTotals += ave;
        }
        if (name === 'Workforce Skills') {
          jobTotals += ave;
        }
        if (name === 'Reentry') {
          reentryTotals += ave;
        }
        if (name === 'Enrichment') {
          enrichmentTotals += ave;
        }
        if (name === 'Entertainment') {
          entertainmentTotals += ave;
        }
        if (name === 'Miscellaneous') {
          miscTotals += ave;
        }
      });
    });

    var edAve = edTotals / numOfLocations;
    var rehabAve = rehabTotals / numOfLocations;
    var jobAve = jobTotals / numOfLocations;
    var reentryAve = reentryTotals / numOfLocations;
    var enrichmentAve = enrichmentTotals / numOfLocations;
    var miscAve = miscTotals / numOfLocations;
    var entAve = entertainmentTotals / numOfLocations;

    education.push(edAve);
    rehab.push(rehabAve);
    job.push(jobAve);
    reentry.push(reentryAve);
    enrichment.push(enrichmentAve);
    entertainment.push(entAve);
    misc.push(miscAve);

    facilitiesAverages.push(education, rehab, job, reentry, enrichment, entertainment, misc);

    return facilitiesAverages;
  };
  openFacilityView = fac => {
    // console.log(this.props.location.pathname)
    this.props.selectHousingUnit(fac);
    this.props.history.push(
      `${this.props.location.pathname}/${fac.locationName
        .split(' ')
        .join('-')
        .replace(/[^a-zA-Z0-9-_]/g, '')}/${fac.locationId}`
    );
    // this.setGlobal({
    //   totSelectedUnit: fac,
    //   openUnitView: true
    // })
  };

  handleClose = () => {
    this.setGlobal({
      openFacilityView: false,
      openUnitView: false
    });
  };

  /**
   * @name handleToTDetailsDialog
   * @param {boolean} isOpen
   * @desc Handles time on task dialog open flag.
   * @return {void}
   */
  handleToTDetailsDialog = isOpen => {
    this.setState({
      totDetailsDialog: {
        ...this.state.totDetailsDialog,
        isOpen
      }
    });
  };

  handleCloseFacilityView = () => {
    this.setGlobal({
      openFacilityView: false
    });
  };
  handleSearchChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChange = value => {
    this.setState(prevState => {
      return {
        dateRange: value,
        isCustom: false
      };
    });
    var range = value;
    let locationData = this.global.locationDataStore
      .filter(l => {
        return l.locationId === this.props.match.params.locationId;
      })
      .filter(i => i.dateRange === range);
    var now = Date.now();
    if (locationData.length > 0 && now - locationData[0].timestamp <= ONE_HOUR * 12) {
      this.createTotViewData(locationData[0].data);
      this.setState({
        locData: locationData[0].data
      });
      this.setGlobal({
        selectedDateRange: range,
        range: this.getDateRangeModelFromInteger(range)
      });
    } else if (locationData.length <= 0) {
      var fac = this.state.facilities;
      this.setGlobal({
        selectedDateRange: range,
        range: this.getDateRangeModelFromInteger(range)
      });
      this.getTimeonTask(fac, range);
    }
  };

  handleDateRangePicker = isOpen => {
    this.setState({
      dateRangePicker: {
        ...this.state.dateRangePicker,
        isOpen
      }
    });
  };

  handleDateRangeChange = range => {
    range = {
      startDate: moment.utc(range.startDate),
      endDate: moment.utc(range.endDate)
    };
    const daysCount = moment.duration(range.endDate.diff(range.startDate)).asDays();

    const formattedRange = {
      startDate: range.startDate.format(),
      endDate: range.endDate.format()
    };
    this.setGlobal({
      selectedDateRange: daysCount,
      range: formattedRange
    });
    this.setState({
      dateRange: daysCount,
      isCustom: true
    });
    this.handleDateRangePicker(false);
    var fac = this.state.facilities;
    this.getTimeonTask(
      fac,
      {
        startDate: moment.utc(range.startDate).format(),
        endDate: moment.utc(range.endDate).format()
      },
      false
    );
  };

  render() {
    const { classes } = this.props;
    let filteredLocations = this.state.locData
      ? this.state.locData.locations.sort().filter(location => {
          return location.locationName.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
        })
      : [];
    const sortedLocations = filteredLocations.sort(function(a, b) {
      var keyA = a.locationName,
        keyB = b.locationName;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    return (
      <div style={{ padding: '1rem' }}>
        <Box display="flex" position="relative" alignItems="center" justifyContent="center" className={classes.actionBar}>
          <List className={classes.daysSelectionList}>
            <ListItem
              button
              onClick={() => this.handleChange(7)}
              className={[
                classes.daysSelectionItem,
                this.global.selectedDateRange === 7 && !this.state.isCustom ? classes.activeDay : null
              ].join(' ')}
            >
              7 Days
            </ListItem>
            <ListItem
              onClick={() => this.handleChange(30)}
              button
              className={[
                classes.daysSelectionItem,
                this.global.selectedDateRange === 30 && !this.state.isCustom ? classes.activeDay : null
              ].join(' ')}
            >
              30 Days
            </ListItem>
            <ListItem
              onClick={() => this.handleChange(90)}
              button
              className={[
                classes.daysSelectionItem,
                this.global.selectedDateRange === 90 && !this.state.isCustom ? classes.activeDay : null
              ].join(' ')}
            >
              90 Days
            </ListItem>
            <ListItem
              onClick={() => this.handleChange(365)}
              button
              className={[
                classes.daysSelectionItem,
                this.global.selectedDateRange === 365 && !this.state.isCustom ? classes.activeDay : null
              ].join(' ')}
            >
              365 Days
            </ListItem>
            <ListItem
              onClick={() => this.handleDateRangePicker(true)}
              button
              className={[classes.daysSelectionItem, this.state.isCustom ? classes.activeDay : null].join(' ')}
            >
              Custom
            </ListItem>
          </List>
          <Box position="absolute" top="0px">
            <DateRangePicker
              open={this.state.dateRangePicker.isOpen}
              closeOnClickOutside={true}
              maxDate={new Date()}
              toggle={() => this.handleDateRangePicker(!this.state.dateRangePicker.isOpen)}
              onChange={this.handleDateRangeChange}
            />
          </Box>
        </Box>
        <Grid container spacing={24}>
          {this.state.locData ? (
            <Grid item sm={3} xs={12}>
              <div style={{ display: 'flex', flex: 1, flexFlow: 'row wrap' }}>
                <div style={{ flex: 1, display: 'flex', flexFlow: 'row wrap' }}>
                  <Paper style={{ width: '100%', borderRadius: 4, margin: 4 }}>
                    <Typography variant="h6" style={{ margin: 10, color: AdminTheme.palette.text.navy }}>
                      {this.state.locationName}
                    </Typography>
                  </Paper>
                  <Paper className={classes.statisticsContainer}>
                    <LocationOnIcon style={{ width: 18, height: 18, float: 'left', margin: 4 }} />
                    <p style={{ textAlign: 'left', margin: 6, marginTop: 3 }}> areas</p>
                    <Typography variant="h5" style={{ margin: 8, color: 'white', textAlign: 'center' }}>
                      {this.state.locData.locations.length}
                    </Typography>
                  </Paper>
                  <Paper className={classes.statisticsContainer}>
                    <SupervisedUserCircleIcon style={{ width: 18, height: 18, float: 'left', margin: 4 }} />
                    <p style={{ textAlign: 'left', margin: 6, marginTop: 3 }}> users</p>
                    <Typography variant="h5" style={{ margin: 8, color: 'white', textAlign: 'center' }}>
                      {this.state.locData.numberOfUsers}
                    </Typography>
                  </Paper>
                  <Paper className={classes.timeSpentContainer}>
                    <TimerIcon style={{ width: 22, height: 22, float: 'left', margin: 4 }} />
                    <p style={{ textAlign: 'left', margin: 6, marginTop: 7 }}> total hours spent</p>
                    <Typography variant="h6" style={{ margin: 10, color: 'white', textAlign: 'center' }}>
                      {this.state.locData.totalTimespent}
                    </Typography>
                  </Paper>
                  <Box width="100%" display="flex" justifyContent="flex-end">
                    <Button onClick={() => this.handleToTDetailsDialog(true)} style={{ width: '100%' }} color="primary">
                      View Time On Task Details
                    </Button>
                    <TotDetails
                      contentAreaVisible
                      heading={`${this.state.locationName} Time on Task`}
                      exportFileName={this.state.locationName}
                      locations={this.state.locData.locations}
                      activeLocationId={this.props.match.params.locationId}
                      open={this.state.totDetailsDialog.isOpen}
                      type={TOT_DETAILS_ENUM.Location}
                      closeHandler={this.handleToTDetailsDialog}
                      locationType="FACILITY"
                    />
                  </Box>
                </div>
              </div>
            </Grid>
          ) : (
            <Paper style={{ padding: '1rem', width: '100%' }}>
              <CircularProgress className={classes.progress} />

              <h1>Loading time on task data...</h1>
            </Paper>
          )}

          <Grid item sm={9} xs={12}>
            {this.state.locData ? (
              <Paper className={classes.timeOnTaskChart}>
                <div style={{ float: 'left' }}>
                  <Button
                    onClick={() => {
                      this.setState({ selectedView: 'totals' });
                    }}
                    color={this.state.selectedView === 'totals' ? 'primary' : 'inherited'}
                  >
                    Totals
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ selectedView: 'averages' });
                    }}
                    color={this.state.selectedView === 'averages' ? 'primary' : 'inherited'}
                  >
                    Average
                  </Button>
                </div>
                {this.state.selectedView === 'totals' ? (
                  <div>
                    <Typography variant="h6" style={{ textAlign: 'center', padding: '.5rem', fontSize: 18 }}>
                      Total Time on Task by Housing Area
                    </Typography>
                    <ColumnChart
                      dataset={{
                        ...this._chart.getDefaultDatasetOptions,
                        backgroundColor: this.state.locData.facilityTotalsChart.map(
                          data => AdminTheme.palette.background.blue
                        )
                      }}
                      library={this._chart.getDefaultOptions}
                      height={220}
                      adapter="chartjs"
                      data={this.state.locData.facilityTotalsChart}
                    />
                  </div>
                ) : (
                  <div>
                    <Typography variant="h6" style={{ textAlign: 'center', padding: '.5rem', fontSize: 18 }}>
                      Time on Task Average by Pathways per User
                    </Typography>
                    <ColumnChart
                      height={220}
                      adapter="chartjs"
                      dataset={{
                        ...this._chart.getDefaultDatasetOptions,
                        backgroundColor: [
                          CONTENT_AREA[CONTENT_AREAS_ENUM.Education].bgColor,
                          CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].bgColor,
                          CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].bgColor,
                          CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].bgColor,
                          CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].bgColor,
                          CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].bgColor,
                          CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].bgColor,
                          CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].bgColor
                        ]
                      }}
                      library={this._chart.getDefaultOptions}
                      data={this.state.locData.facilitiesAveragesChart}
                    />
                  </div>
                )}
              </Paper>
            ) : null}
          </Grid>
        </Grid>
        <div className={classes.locationsContainer}>
          {filteredLocations ? (
            <div>
              <h3>Housing Areas</h3>
              <Paper
                style={{
                  float: 'right',
                  padding: '2px 12px',
                  display: 'flex',
                  alignItems: 'center',
                  width: 320
                }}
              >
                <InputBase
                  id="search"
                  style={{ flexGrow: 1 }}
                  placeholder="Search Housing Areas"
                  value={this.state.search}
                  onChange={this.handleSearch}
                  inputProps={{ 'aria-label': 'Search Housing Areas' }}
                />
                <IconButton className={classes.iconButton} aria-label="Search">
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Grid container spacing={24}>
                {filteredLocations.map(l => (
                  <Slide direction="up" in unmountOnExit style={{ transitionDelay: 500 }}>
                    <Grid key={l.locationId} item sm={6} xs={12} md={4} style={{ padding: 6 }}>
                      <ButtonBase
                        disabled={!this.state.isHousingUnitPermitted}
                        onClick={() => {
                          setHousingUnit(l);
                          this.props.selectHousingUnit(l);
                          this.openFacilityView(l);
                        }}
                        style={{ width: '100%' }}
                      >
                        <Paper className={classes.locationCard} style={{ width: '100%' }}>
                          <div className={classes.locationHeader}>
                            <Typography variant="body1" style={{ color: 'white', textAlign: 'left' }}>
                              <strong>{l.locationName}</strong>
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ color: 'white', textAlign: 'left', fontSize: 12 }}
                            >{`Total Hours Spent: ${
                              l.totalTimespent ? l.totalTimespent.toFixed(2) : (0).toFixed(2)
                            }`}</Typography>
                          </div>
                          <div className={classes.locationFooter}>
                            <ColumnChart
                              height={200}
                              dataset={{
                                ...this._chart.getDefaultDatasetOptions,
                                backgroundColor: l.contentAverageChart.map(data =>
                                  CONTENT_AREA[data[2]]
                                    ? CONTENT_AREA[data[2]].bgColor
                                    : AdminTheme.palette.background.blue
                                )
                              }}
                              library={this._chart.getDefaultOptions}
                              data={l.contentAverageChart}
                            />
                          </div>
                        </Paper>
                      </ButtonBase>
                    </Grid>
                  </Slide>
                ))}
              </Grid>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

Facility.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return state.app;
};

const mapDispatchToProps = dispatch => {
  return {
    selectLocation: location => dispatch(selectLocation(location)),
    selectHousingUnit: hu => dispatch(selectHousingUnit(hu))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Facility)));
