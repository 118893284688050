export default theme => ({
  title: {
    fontSize: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary.main,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.colors.grey50
  },
  label: {
    '&[for="phoneNumber"]': {
      color: theme.palette.primary.blue.dark,
      fontWeight: 700,
      width: 'max-content'
    }
  },
  privilegeText: {
    marginLeft: '10px',
    fontSize: 13,
    display: 'flex',
    alignItems: 'center'
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3, 2),
    backgroundColor: theme.palette.background.blue
  },
  modalHeading: {
    color: 'white',
    fontSize: '1.5rem',
    fontWeight: 'normal'
  },
  contactForm: {
    padding: theme.spacing(2, 4, 0)
  },
  formField: {
    width: '100%'
  },
  phone: {
    '& > div': {
      paddingLeft: 50
    }
  },
  phoneDiv: {
    position: 'relative'
  },
  USAFlag: {
    width: 30,
    height: 20,
    marginRight: 8
  },
  phoneSideElement: {
    display: 'flex',
    position: 'absolute',
    top: 17,
    left: 10,
    fontSize: 16,
    marginRight: 8
  },
  modalBtns: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancelBtn: {
    color: '#10445a'
  },
  circularProgressStyle: {
    color: theme.palette.primary.teal.main,
    height: '25px !important',
    width: '25px !important'
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    margin: '8px'
  },
  addContactSection: {
    padding: '10 24px'
  }
});
