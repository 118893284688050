import React, {Component} from 'react'
import IncentiveOverall from './incentivecharts'
import IncentiveCount from './incentivecount'
import RedemptionChart from './redemptiontotal'
import Grid from '@material-ui/core/Grid';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled'

///This component is for the Incentive Engine

class Incentives extends Component{

    render(){

        return(
            <div>
                <h1 style={{marginTop:'3rem'}}><br/>Incentive Engine</h1>
            
               {/* 
                <Grid container spacing={24}>
                        <Grid sm={3} xs={12} item style={{height:300, width:'100%'}}><IncentiveCount/><br/><span>Total Transactions</span></Grid>
                        <Grid sm={6} xs={12} item style={{height:300, width:'100%'}}><RedemptionChart/><br/><span>Total Redemptions</span></Grid>
                        <Grid sm={3} xs={12} item style={{height:300, width:'100%'}}><IncentiveOverall/><br/><span>Points Breakdown</span></Grid>
                </Grid> */}

                <img src="https://s3.amazonaws.com/apds-public/incentives.svg" style={{padding:'1.5rem', filter:'grayscale(100%)', width:250}}/>
                <h3 style={{marginTop:'3rem', color: 'gray'}}>Incentive Engine has been disabled for this demo.</h3>
            </div>
        )


    }


}

export default Incentives