export default (theme) => ({
    tabName: {
        color: '#000000',
        letterSpacing: '0.15px',
        fontSize: 20,
        lineHeight: '24px',
        fontWeight: 500,
        color: "black",
        marginLeft: '12px'
    },
    titleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: "6px",
        padding: '20px 15px 0 15px'
    },
    addBtn: {
        minWidth: 0,
        width: 39,
        height: 39,
        borderRadius: '50%'
    }
});