import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FilterListIcon from '@material-ui/icons/FilterList';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import WarningIcon from '@material-ui/icons/Warning';
import AddIcon from '@material-ui/icons/Add';
import { GroupAdd } from '@material-ui/icons';
import GroupIcon from '@material-ui/icons/Group';
import { UserIcon } from '../../../components/UserIcon';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LaunchIcon from '@mui/icons-material/Launch';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import ContactsIcon from '@mui/icons-material/Contacts';
import ErrorIcon from "@material-ui/icons/Error";
import EventNoteIcon from '@mui/icons-material/EventNote';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReplayIcon from '@mui/icons-material/Replay';

export {
  AccountCircle,
  ArrowUpwardIcon,
  CheckIcon,
  CloseIcon,
  CreateIcon,
  DeleteIcon,
  EditIcon,
  ExpandLess,
  FiberManualRecordIcon,
  FilterListIcon,
  FirstPageIcon,
  LastPageIcon,
  MoreVertIcon,
  NavigateBeforeIcon,
  NavigateNextIcon,
  PowerSettingsNewIcon,
  RefreshIcon,
  SaveAltIcon,
  Search,
  SearchIcon,
  SendIcon,
  VerifiedUserIcon,
  WarningIcon,
  AddIcon,
  GroupAdd,
  GroupIcon,
  UserIcon,
  FilterAltIcon,
  LaunchIcon,
  TabletAndroidIcon,
  ContactsIcon,
  ErrorIcon,
  EventNoteIcon,
  LocationOnIcon,
  ReplayIcon
};
