import React from "react";
import {
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Button,
  useTheme,
  useMediaQuery,
  DialogActions,
} from "@material-ui/core";

const DeleteFileDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.isOpen}
      onClose={props.handleClose}
    >
      <DialogContent>
        <DialogContentText>Are you sure you want to delete?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id="no-btn" onClick={props.handleClose} color="primary">
          No
        </Button>
        <Button
          id="yes-btn"
          onClick={() => {
            props.onAgree();
            props.handleClose();
          }}
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFileDialog;
