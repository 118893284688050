import React from "react";

const RehabilitationIcon = props => {
  const { size, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-Rehabilitation">
          <rect width="24" height="24"/>
        </clipPath>
      </defs>
      <g id="Rehabilitation" clipPath="url(#clip-Rehabilitation)">
        <g id="Group_1267" data-name="Group 1267" transform="translate(-2096.494 -4317.345)">
          <path id="Fill_1" data-name="Fill 1" d="M9.787,9.9H2.446A2.464,2.464,0,0,1,0,7.427V2.475A2.464,2.464,0,0,1,2.446,0h7.34a2.464,2.464,0,0,1,2.446,2.475V7.427A2.464,2.464,0,0,1,9.787,9.9ZM2.446,2.475V7.427h7.34V2.475Z" transform="translate(2102.018 4331.442)" fill={fill}/>
          <path id="Fill_2" data-name="Fill 2" d="M0,1.221A1.221,1.221,0,0,1,1.221,0H15.873a1.221,1.221,0,1,1,0,2.442H1.221A1.221,1.221,0,0,1,0,1.221Z" transform="translate(2099.253 4331.577)" fill={fill}/>
          <path id="Fill_3" data-name="Fill 3" d="M1.432,16.676l-.2,0L.1,16.647.04,15.517a21.337,21.337,0,0,1,.825-7.6,12.308,12.308,0,0,1,2.853-4.9A9.706,9.706,0,0,1,10.579,0c.244,0,.487.011.72.034l.968.092.126.964c.692,5.273-.438,9.551-3.268,12.37A11.2,11.2,0,0,1,1.432,16.676ZM10.086,2.463A7.33,7.33,0,0,0,5.467,4.719c-2.53,2.6-3.042,6.569-3.025,9.444A8.776,8.776,0,0,0,7.4,11.73,8.837,8.837,0,0,0,9.535,8.209,14.472,14.472,0,0,0,10.086,2.463Z" transform="translate(2106.54 4317.345)" fill={fill}/>
          <path id="Fill_4" data-name="Fill 4" d="M9.207,13.545a7.372,7.372,0,0,1-4.1-1.156A8.544,8.544,0,0,1,2.219,9.022,24.117,24.117,0,0,1,.271,2.012V2L.263,1.963.257,1.927C.236,1.8.216,1.675.2,1.557L0,.36,1.195.155A10.3,10.3,0,0,1,2.931,0,7.181,7.181,0,0,1,6.783,1.041,8.089,8.089,0,0,1,9.829,4.726a18.7,18.7,0,0,1,1.335,7.6l-.027,1.092-1.089.093C9.764,13.532,9.481,13.545,9.207,13.545ZM2.933,2.442H2.818A19.43,19.43,0,0,0,4.395,7.912a6.147,6.147,0,0,0,2.043,2.43,4.728,4.728,0,0,0,2.274.742A15.18,15.18,0,0,0,7.6,5.724,5.708,5.708,0,0,0,5.494,3.115,4.772,4.772,0,0,0,2.933,2.442Z" transform="translate(2097.856 4320.506)" fill={fill}/>
        </g>
      </g>
    </svg>
  );
};

RehabilitationIcon.defaultProps = {
  size: 24,
  fill: "inherit"
}

export default RehabilitationIcon;
