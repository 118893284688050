export default theme => ({
  selectInput:{
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textAreaRoot: {
    width: '100%',
    height: 135,
  },
  outlinedTextArea: {
    borderRadius: 0
  },
  textAreaFont: {
    fontSize: 12,
  },
  saveBtn: {
    marginLeft: 10,
    paddingLeft: 31,
    paddingRight: 31
  },
  dialogContent: {
    paddingTop: 40,
    paddingBottom: 40
  },
  dialogActions: {
    paddingBottom: 25,
    paddingTop: 0,
    justifyContent: 'center'
  },
  field: {
    width: '100%',
    height: 78,

    '& .MuiInputLabel-outlined': {
      fontSize: 14,
      transform: 'translate(14px, 17px) scale(1)',

      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -5px) scale(0.75)'
      }
    }
  },
  outlinedField: {
    fontSize: 12.8,
  },
  input: {
    padding: '15px 14px'
  }
})