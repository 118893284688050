import React from "react";
import {
  withStyles,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import classNames from "classnames";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import styles from "./index.style";

const Accordion = (props) => {
  const { classes } = props;
  const [isOpen, setOpen] = React.useState(false);
  return (
    <>
      <Card
        id="accordion-main-card"
        onClick={() => setOpen(!isOpen)}
        className={classes.root}
      >
        <CardContent
          className={classNames(classes.cardContent, classes.accordionItem)}
        >
          <Box display="flex" justifyContent="space-between" component="div">
            <Typography className={classes.accordionHeading}>
              {props.title}
            </Typography>
            {isOpen && (
              <Button
                variant="outlined"
                className={classes.collapseBtn}
                data-cy-name={props.cyprusId}
              >
                Collapse
              </Button>
            )}
            {!isOpen && (
              <Button
                variant="outlined"
                color="primary"
                data-cy-name={props.cyprusId}
              >
                Expand
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
      {isOpen && (
        <Box className={classes.accrodionContent}>{props.children}</Box>
      )}
    </>
  );
};

export default withStyles(styles)(Accordion);
