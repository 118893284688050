export default (theme) => ({
    fieldWrapper: {
        backgroundColor: theme.palette.primary.white,
        flex: 1,
        marginRight: 10
    },
    filterField: {
        width: "100%",
    },
    filterBtn: {
        height: "100%"
    },
    statusAdornment: {
        marginRight: 15
    }
});