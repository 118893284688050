import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

import styles from './index.styles';

function DateSeparator(props) {
    const { classes, text } = props;
    
    return (
        <div className={classes.dateSection}>
            <div className={classes.line}/>
            <Typography className={classes.date}>{text}</Typography>
            <div className={classes.line}/>
        </div>
    )
}

export default withStyles(styles)(DateSeparator);