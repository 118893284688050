export default theme => ({

  root: {
    width: "130px",
    height: "30px",
    padding: "0px",
  },
  switchBase: {
    padding: "0px",
  },
  thumb: {
    color: "white",
    width: "30px",
    height: "30px"
  },
  trackOn: {
    borderRadius: "20px !important",
    backgroundColor: `${theme.palette.colors.greenMain} !important`,
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "15px",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      top: "6px",
    },
    "&:after": {
      content: "'Access ON'",
      right: "8px",
    },
  },

  trackOff: {
    borderRadius: "20px",
    backgroundColor: theme.palette.colors.redMain,
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "15px",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      top: "6px",
    },
    "&:before": {
      content: "'Access OFF'",
      left: "8px",
    },
  },
  checked: {
    color: "green !important",
    transform: "translateX(100px) !important",
  },

  thumbIcon: {
    fontSize: "30px !important",
    color: "white",
  },

});