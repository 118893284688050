import React from "react";

const FilledBookmarkIcon = props => {
  const { variant, fill, stroke } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30.487" viewBox="0 0 24 30.487">
      <defs>
        <clipPath id="clip-Filled_Bookmark_">
          <rect width="24" height="30.486"/>
        </clipPath>
      </defs>
      <g id="Filled_Bookmark_" data-name="Filled Bookmark " clipPath="url(#clip-Filled_Bookmark_)">
        {
          variant === "filled"
          ? <g id="Group_1587" data-name="Group 1587">
            <path id="rectangle" d="M0,0H24a0,0,0,0,1,0,0V23.2a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V0A0,0,0,0,1,0,0Z" fill={fill} opacity="0.22"/>
            <path id="Icon_awesome-bookmark" data-name="Icon awesome-bookmark" d="M0,12V1.125A1.125,1.125,0,0,1,1.125,0h6.75A1.125,1.125,0,0,1,9,1.125V12L4.5,9.375Z" transform="translate(7.5 10.8)" fill={fill}/>
          </g>
          : variant === "outlined" &&
          <g id="Group_1587" data-name="Group 1587">
            <path id="rectangle" d="M0,0H24a0,0,0,0,1,0,0V23.2a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V0A0,0,0,0,1,0,0Z" stroke="none" fill={fill} opacity="0.05"/>
            <path id="Icon_awesome-bookmark" data-name="Icon awesome-bookmark" d="M0,12V1.125A1.125,1.125,0,0,1,1.125,0h6.75A1.125,1.125,0,0,1,9,1.125V12L4.5,9.375Z" transform="translate(7.5 10.8)" stroke={stroke} fill="none" strokeWidth="0.5"/>
          </g>
        }
      </g>
    </svg>
  );
};

FilledBookmarkIcon.defaultProps = {
  variant: "filled",
  fill: "inherit",
  stroke: "inherit"
}

export default FilledBookmarkIcon;
