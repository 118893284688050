import React from 'react';

import {
    Dialog,
    DialogContent,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dialogContentRoot: {
    //   padding: 60,
    },
    exportBtnWrapper: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end'
    },
    submissionData: {
        margin: '20px 0'
    },
    field: {
        margin: '10px 0'
    },
    key: {
        display: 'inline-block',
        width: '40%',
        position: 'relative',
        paddingRight: 20,
        fontWeight: 500,

        '&::after': {
            content: '":"',
            position: 'absolute',
            right: 25
        }
    },
    value: {
        marginLeft: 5
    },
    formEmpty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '50px 0'
    }
}));

const FormSubmissionDetailDialog = ({ isOpen, handleClose, selectedFormSubmission, username }) => {
    const classes = useStyles();
    
    if (!selectedFormSubmission) return null;

    const formData = JSON.parse(selectedFormSubmission.response);

    const onExportClick = () => {
        let csvContent = "data:text/csv;charset=utf-8,";

        csvContent += `${username}'s Form Submission \r\n`;
        csvContent += `${selectedFormSubmission.name} \r\n`;
        csvContent += `timestamp,${moment(selectedFormSubmission.submissionDate).format('MM/DD/YYYY [at] hh:mmA')} \r\n`

        Object.entries(formData)
            .forEach(
                ([fieldKey, fieldValue]) => {
                    const csvRowData = [
                        `"${fieldKey}"`,
                        `"${fieldValue.join(',')}"`
                    ];
                    csvContent += csvRowData.join(",") + "\r\n";
                }
            );
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("id", "form-submission-download-link");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "form-submission-data.csv");
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(document.querySelector("#form-submission-download-link"));
    }

    const formFields = Object.keys(formData).map(x => {
        if (x === 'form_key' || x === 'person_key') {
            return null;
        }
        return (
            <div className={classes.field}>
                <div className={classes.key}>
                    {x}
                </div>
                <span className={classes.valule}>
                    {formData[x].join(", ")}
                </span>
            </div>
        )
    }).filter(Boolean);

    const isFormEmpty = formFields.length === 0;

    return (
        <Dialog maxWidth="xs" fullWidth={true} open={isOpen} onClose={handleClose}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {`${selectedFormSubmission.name}`}
                </Typography>
              </Toolbar>
            </AppBar>

            <DialogContent classes={{
              root: classes.dialogContentRoot
            }}>
               {!isFormEmpty ? (
                    <>
                        <div className={classes.exportBtnWrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onExportClick}
                            >
                                Export
                            </Button>
                        </div>
                        <div className={classes.submissionData}>
                            {formFields}
                        </div>
                    </>
                    ) : (
                        <div className={classes.formEmpty}>
                            This form does not have any fields.
                        </div>
                    )
                }
            </DialogContent>
        </Dialog>
    )
}

export default FormSubmissionDetailDialog;