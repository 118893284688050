import React from 'react';
import { Box, makeStyles } from './MaterialUIComponents';
import moment from 'moment';
import { getUSAFormattedDate } from '../../../components/users/learner/transcript/utils';

const useStyle = makeStyles(theme => ({
  dateStyle: {
    marginRight: '20px',
    color: '#7896A2',
    fontSize: '14px',
    marginTop: '-25px',
    '& span': {
      float: 'right',
      fontSize: '12px'
    },
    fontFamily: 'DM Sans'
  }
}));

export const DateDisplay = ({ inDate }) => {
  const date = inDate || new Date();

  const currentDate = moment().startOf('day');
  const targetDate = moment(date).startOf('day');
  const useClasses = useStyle();

  const isToday = currentDate.isSame(targetDate);

  return (
    <Box className={useClasses.dateStyle}>
      <span>{getUSAFormattedDate(date, 'hh:mm A')}</span>
      <br />
      {!isToday && <span>{getUSAFormattedDate(date)}</span>}
    </Box>
  );
};
