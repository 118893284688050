import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './keywords-dictionary-banner.style';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import TagIcon from '@mui/icons-material/Tag';
import { PillButton } from '@orijinworks/frontend-commons';

const KeywordsDictionaryBanner = (props) => {
  const { classes, openKeywordsDictionary } = props;

  return (
    <Box className={classes.keywordsManagementSection}>
      <Box className={classes.keywordsManagementSectionHeading}>
        <TagIcon />
        <Box display="flex" flexDirection="column" flex="1 0 0" alignItems="flex-start">
          <Typography variant="h2" className={classes.keywordManagementHeading}>Keyword Management</Typography>
          <Typography variant="body2">
            Manage facility-level keywords for call and transcription flagging by adding, removing, or updating as needed.
          </Typography>
        </Box>
      </Box>
      <Box className={classes.keywordsManagementButtonSection}>
        <PillButton variant="contained" className={classes.dictionaryBtn} onClick={openKeywordsDictionary}>
          Keyword Dictionary <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
        </PillButton>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(KeywordsDictionaryBanner);
