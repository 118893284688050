export default (theme) => ({
    roleTag: {
        display: 'flex', 
        alignItems: 'center',
        background: '#e3e8f2',
        borderRadius: 5,
        padding: 5,
        margin: 2,
        boxShadow: '1px 1px 1px #b6bac2',
        fontSize: 14,
    },
    roleTagClose: {
        height: 20,
        color: '#80868b',
        marginLeft: 5,
        cursor: 'pointer',
    },
});