import React from 'react';
import { Typography } from '@orijinworks/frontend-commons';
import moment from 'moment';
import { LOCATION_STATUSES, LOCATION_TYPE_IDS } from '../../../../v2/services/constants';
import { Box } from '@material-ui/core';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';
export const useLocationHistoryHook = ({classes}) => {

  const getLocationHistoryColumns = () => {
    const columns = [
      {
        field: 'locationName',
        title: 'Facility',
        render: ({ locationName, locationTypeId, parentLocationName }) => (
          <>
            {locationTypeId === LOCATION_TYPE_IDS.FACILITY ?
              <Typography variant='h6' >
                {locationName}
              </Typography>
              : 
              <Typography variant='h6' >
                {parentLocationName}
              </Typography>
            }
          </>
        ),
      },
      {
        field: 'locationName',
        title: 'Housing Unit',
        render: ({ locationName, locationTypeId }) => (
          <>
            {locationTypeId === LOCATION_TYPE_IDS.HOUSING_UNIT ?
              <Typography variant='h6' >
                {locationName}
              </Typography>
              : 'N/A'}
          </>
        ),
      },
      {
        field: 'startDate',
        title: 'Start Date',
        render: ({ startDate }) => (
          <Typography variant='typography-body1' >
            {startDate ? moment(startDate).format('YYYY-MM-DD') : 'N/A'}
          </Typography>
        ),
      },
      {
        field: 'endDate',
        title: 'End Date',
        render: ({ endDate }) => (
          <Typography variant='typography-body1' >
            {endDate ? moment(endDate).format('YYYY-MM-DD') : 'N/A'}
          </Typography>
        ),
      },
      {
        field: 'status',
        title: 'Status',
        render: ({ tableData: { id }, active }) => {
          let statusText,boxStyleClass;
      
          if (id === 0) {
            statusText = active ? LOCATION_STATUSES.ACTIVE : LOCATION_STATUSES.DEACTIVATED;
            boxStyleClass = active ? 'activeBox' : 'deactivatedBox';
          } else {
            statusText = LOCATION_STATUSES.MOVED;
            boxStyleClass = 'movedBox';
          }
      
          return (
            <Box id={`status-box-${id}`} className={[classes.statusWrapper, classes[boxStyleClass]].join(' ')}>
              {boxStyleClass !== 'movedBox' &&
                <CustomIcons icon={icons.FiberManualRecordIcon} className={classes.statusIcon} />
              }
              <Typography id={`text-box-${id}`} variant='typography-body1'>{statusText}</Typography>
            </Box>
          );
        },
      },
    ];

    return columns;
  };

  return { getLocationHistoryColumns }
};

