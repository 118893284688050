import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import { Typography } from '@orijinworks/frontend-commons';
import styles from './transcript.style';
import { formatTranscriptTime, highlightKeywords } from '../../utils/utils';
import { withUnleash } from '../../../../v2/core/unleash';
import { UnleashService } from '../../../../v2/services';

const renderHighlight = (classes) => (part, index) => (
  <span key={index} className={classes.highlighted}>
    {part}
  </span>
);

const Transcript = ({ classes, data, learnerName, contactName, detectedKeywords, unleashProps }) => {
  const getDisplayName = (userType) => {
    if (userType === 'learner') return learnerName;
    if (userType === 'admin') return 'Admin';
    return contactName;
  };

  return (
    unleashProps.isFlagEnabled() && (
      <Box className={classes.container}>
        {data?.map((message, index) => (
          <Box
            key={index}
            className={index % 2 === 0 ? classes.messageBox : classes.messageBoxColored}
          >
            <Box className={classes.messageHeader}>
              <Typography id="timestamp" variant="body2" className={classes.timestamp}>
                {formatTranscriptTime(message.timestamp)}
              </Typography>
              <Typography id="sender" variant="body2" className={classes.sender}>
                {getDisplayName(message?.externalUserId?.split('-')[0])}
              </Typography>
            </Box>
            <Typography id="message" variant="body2" className={classes.message}>
              {highlightKeywords(message.transcript, detectedKeywords, renderHighlight(classes))}
            </Typography>
          </Box>
        ))}
      </Box>
    )
  );
};

export default withStyles(styles)(withUnleash(Transcript, UnleashService.FLAGS.KEYWORD_MATCH_CONFIG));
