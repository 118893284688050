import React, { Component } from 'reactn'
import Fade from '@material-ui/core/Fade';
import { connect } from 'react-redux';
import { Grid} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from "@material-ui/core/LinearProgress";
import Logo from './logo';

const styles = theme => ({
  contentWrapper: {
    minHeight: 155,
    maxWidth: "100%",
    width: 450
  },
  loaderContainer: {
    alignItems: "center",
    position: "fixed",
    width: "100vw",
    height: "100vh",
    display: "flex",
    zIndex: 8000,
    justifyContent: "center",
    background: "black",
    opacity: 0.9
  },
  brandLogo: {
    display: "block",
    maxWidth: "100%",
    width: 135,
    margin: "0 auto 60px",
    "& p": {
      background: "black",
      opacity: 0.9,
      top: 19
    }
  },
  progressBar: {
    backgroundColor: theme.palette.primary.pastelBlueBg,
    "& div": {
      backgroundColor: theme.palette.primary.pastelBlue
    }
  }
});

class Loader extends Component {

    constructor(props){
        super(props);
    }

        
  render() {
    const { classes } = this.props;
    return (
       <div>
           {
            this.global.isLoading || this.props.isLoading ?
              <Fade in unmountOnExit>
                <div style={{top: 0, left: 0, position:'fixed', width:'100vw', height:'100vh', display:'flex', zIndex:8000, justifyContent:'center', background:'black', opacity:.7}}>
                  <div className={classes.loaderContainer}>
                    <Grid className={classes.contentWrapper}>
                      <Logo className={classes.brandLogo} />
                      <LinearProgress className={classes.progressBar} />
                    </Grid>
                  </div>
                </div>
              </Fade> 
            : 
            null
          }
       </div>
    );
  }
}

export default withStyles(styles)(Loader);
