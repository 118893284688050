export default (theme) => ({
    locationSelectionBox: {
        width: 140,
        maxWidth: "100%",
        marginRight: 20
    },
    contentAreaContainer: {
        borderBottom: `1px solid ${theme.palette.primary.grey}`
    },
    contentSectionPadding: {
        padding: '10px 30px'
    },
    blueBox: {
        backgroundColor: theme.palette.primary.main
    },
    blueBoxTitle: {
        ...theme.typography.t14,
        letterSpacing: '0.07px',
        color: theme.palette.primary.white,
        display: 'flex',
        alignItems: 'center'
    },
    blueBoxValue: {
        ...theme.typography.bold,
        ...theme.typography.t30,
        letterSpacing: '0.07px',
        color: theme.palette.primary.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
});