import React, { Component } from 'reactn';
import { Box } from '@material-ui/core';
import { SISENSE_CONFIGURATION, SUPPORT_EMAIL, USER_CONTEXT } from '../../constants';
import { Loader } from '@orijinworks/frontend-commons';
import { getSisenseUser, sisenseLogout } from '../../util/APIUtils';

const { url, dashboardID, settings } = SISENSE_CONFIGURATION[0];
const sisenseElementID = 'sisense-iframe';
class SisenseDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, loggedInUser: false, userExist: false, errorMsg: null };
  }
  hideLoader = () => {
    this.setState({ isLoading: false });
  };

  async componentDidMount() {
    this.getSisenseUser();
  }

  /**
   * Fetch's sisense user from service and shows the dashboard
   * or error message respectively
   * @return {void}
   */
  getSisenseUser = async () => {
    try {
      const { preferred_username } = USER_CONTEXT;

      const user = await getSisenseUser(preferred_username);

      if (user.length > 0) {
        this.setState(
          {
            userExist: true,
          },
          () => {
            if (url && dashboardID) {
              const { SisenseFrame, enums } = window['sisense.embed'];
              this.setGlobal({
                sisenseInitialize: true, // if user sign
              });

              this.setState({ isLoading: true });
              this.sisenseFrame = new SisenseFrame({
                // Sisense application URL, including protocol and port if required
                url,
                // OID of dashboard to load initially
                dashboard: dashboardID,
                // Which panels to show in the iFrame
                settings: {
                  showToolbar: settings.showToolbar,
                  showLeftPane: settings.showLeftPane,
                  showRightPane: settings.showRightPane,
                },
                // Existing iFrame DOM element
                element: document.getElementById(sisenseElementID),
              });

              // Calling render() will apply the above configuration to the existing iFrame element
              this.sisenseFrame.render().then(async () => {
                this.setState({ isLoading: true, loggedInUser: true });
                console.log('Sisense iFrame ready!');
              });

              this.sisenseFrame.app.on(enums.ApplicationEventType.LOADED, () => {
                this.setState({ isLoading: true, loggedInUser: true });
              });

              this.sisenseFrame.dashboard.on(enums.DashboardEventType.LOADED, (args) => {
                this.setState({ ...this.state, isLoading: false });
              });

              // hide the loader once iframe is loaded
              document.querySelector('iframe').addEventListener('load', this.hideLoader);
            }
          }
        );
      } else {
        this.hideLoader();
        this.setState({
          userExist: false,
          errorMsg: `<span>Sisense Dashboards provide access to advanced analytics. For assistance with access, please take a screenshot of the error you are seeing and share it via email at <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>.</span>`,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  componentWillUnmount() {
    this.setGlobal({
      sisenseInitialize: false, // if user sign-out from application this this should also sign-out the sisense
    });
    if (this.state.loggedInUser) {
      sisenseLogout();
    }
  }

  Dashboard = () => {
    const { isLoading, userExist, errorMsg } = this.state;

    return (
      <>
        <Loader isLoading={isLoading} />
        {userExist && (
          <Box>
            <iframe title="Sisense-Dashboard" id={sisenseElementID} height="100vh" style={{ height: '100vh' }}></iframe>
          </Box>
        )}
        {!userExist && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
            dangerouslySetInnerHTML={{ __html: errorMsg }}
          />
        )}
      </>
    );
  };

  render() {
    return url && dashboardID ? <this.Dashboard /> : <h1>Nothing Do Display</h1>;
  }
}

export default SisenseDashboard;
