import React, {Component} from 'react'
import LocationData from '../../data/locationData'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'



class LocationSettings extends Component{

    
        render() {
          const collapsedTree = this.state.collapsedTree;

            return (
              <div>
              </div>
            );
          }

        }

export default LocationSettings