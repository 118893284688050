export default theme => ({
  container: {
    position: 'relative',
  },
  headingRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '19px 27px',
    borderBottom: '0.5px solid #979797'
  },
  firstHeadingRow: {
    paddingTop: 10,
    borderBottom: 'none'
  },
  smallHeading: {
    fontSize: 12,
    color: '#747474',
  },
  boldHeading: {
    fontSize: 15,
    fontWeight: 500,
  },
  chevronDownIcon: {
    order: 1,
  },
  permissionRow: {
    borderBottom: 'none'
  },
  permissionText: {
    width: '25ch',
    fontSize: 14,
    marginRight: 140
  },
  permissionCheckbox: {
    padding: 0
  },
  editPermissionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '0.5px dashed #979797',
    borderRadius: 5,
    padding: '10px',
    margin: '10px 0'
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 14px',
    marginTop: 5,
    boxShadow: ' 0 -1px 6px -1px rgba(0, 0, 0, 0.35)',
  },
  accordionSummary: {
    borderTop: '0.5px solid #979797',

    '&$expanded': {
      minHeight: 48,

      '&:first-of-type': {
        borderBottom: '0.5px solid #979797'
      }
    }
  },
  accordionSummaryContentWrapper: {
    order: 2,

    '&$expanded': {
      margin: '12px 0'
    }
  },
  accordionSummaryContent: {
    width: '100%',
    borderBottom: 'none',
    padding: '7px 11px'
  },
  expandIcon: {
    padding: 5,
    marginRight: 2
  },
  accordion: {
    boxShadow: 'none',
    '&$expanded': {
      margin: 0
    }
  },
  accordionDetails: {
    flexDirection: 'column',
    maxHeight: 550,
    overflowY: 'auto'
  },
  expanded: {},
});