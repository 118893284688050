import React from "react";
import ConfirmationModal from "../confirmation-modal";
import WarningIcon from "@material-ui/icons/Warning";
import { Box } from "@material-ui/core";

const getLiterals = (type) => {
    switch (type) {
        case "PORTAL_ACCESS_ON":
            return `Turning this setting ON will immediately block Learner Portal access for ALL learners on site at this facility.
            If you need to log into a test/demo account or impersonate a learner at this facility, leave this setting OFF and turn "Enable Access for Internal Testing" ON for a specific learner account instead.`;

        case "PORTAL_ACCESS_OFF":
            return `Turning this setting OFF means that learners at this location can only access the Learner Portal if they log in from devices on site at this facility.`;
    }
};
const postReleaseConfirmation = ({ isOpen, updatingValue, onContinue, styles, onClose }) => {
    return (
        <>
            <ConfirmationModal
                styles={styles}
                continueText="PROCEED"
                cancelText="CANCEL"
                isOpen={isOpen}
                onContinue={onContinue}
                onClose={onClose}
                heading={(
                    <>
                        <Box display="flex" alignItems="center" justifyContent="center" marginBottom={10}>
                            <WarningIcon style={{ marginRight: 5, color: "#f57c00" }} />
                            Are you sure you want to change this setting?
                        </Box>
                    </>
                )}
                description={getLiterals(updatingValue ? "PORTAL_ACCESS_ON" : "PORTAL_ACCESS_OFF")}
                continueButtonStyles={
                    {
                        backgroundColor: "#ea9999",
                        borderColor: "#b07979",
                        color: "black",
                        marginTop: 20,
                        padding: "5px 20px"
                    }
                }
                cancelButtonStyles={
                    {
                        backgroundColor: "#dddddd",
                        borderColor: "#7d7d7d",
                        color: "black",
                        marginRight: 10,
                        marginTop: 20,
                        padding: "5px 20px"
                    }
                }
                alignActionButtons="center"
            />
        </>
    );
};

export default postReleaseConfirmation;