import {Storage} from "./Storage";

export class UserStorage extends Storage {
    USER_ID = null;

    constructor(userId) {
        super();
        this.USER_ID = userId;
    }
    
    setItem(key, value) {
        this.setStorage(`USER-${this.USER_ID}-${key}`, value);
    }

    getItem(key) {
        return this.getStorage(`USER-${this.USER_ID}-${key}`);
    };

    removeItem(key) {
        return this.clearItem(`USER-${this.USER_ID}-${key}`);
    };
 };